
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CatalogModel } from './new-product-catalog-model';

@Injectable({
  providedIn: 'root'
})


export class CatalogService {
  catalog_form: FormGroup;


  constructor(
    public _formBuilder: FormBuilder,
    public catalogModel: CatalogModel
  ) { }

  catalogForm(data: any): void {
    if (!this.catalog_form) {
      this.catalog_form = this._formBuilder.group(this.catalogModel.mapDataToModel(data));

    }

  }

}









//   prepareTransactionRefferalForm(data:any): void {
//     if (!this.referral_information_form) {
//       this.referral_information_form = this._formBuilder.group(data);
//     }
//   }









