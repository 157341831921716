import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
@Component({
  selector: 'app-edit-content-purpose-operation',
  templateUrl: './edit-content-purpose-operation.component.html',
  styleUrls: ['./edit-content-purpose-operation.component.css']
})
export class EditContentPurposeOperationComponent implements OnInit {
  activeCategory = 1;

  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  contentPurposeOperationForm: FormGroup;
  contentPurposeOperation: any;
  show: boolean;
  product: any;
  contentOperations: any;
  contentOperationsArray: any[]=[];
  roleTypeIdContent: any;
  roleTypeIdContentArray: any[]=[];
  ContentPurposeType: any;
  ContentPurposeTypeArray: any[]=[];
  privilegeEnumID: any;
  privilegeEnumIDArray:any[]=[];
  statusID: any;
  statusIDArray: any[]=[];
  productData: any;

  constructor(
    readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,

    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,

  ) {
    this.contentPurposeOperationForm = this._FormBuilder.group({
      contentPurposeTypeID: "",
      roleTypeID: "",
      statusID: "",
      privilegeEnumID: "",
      contentOperationID: "",
    })
  }

  ngOnInit(): void {
    this.getContentPurposeOperation();
    this.getContentOperations();
    this.getRoleTypeId();
    this.getContentPurposeType();
    this.getPrivilegeEnumIDStatusId();
    this.getPrivilegeEnumID();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  getPrivilegeEnumID() {
    this.spinner.show();
    this.myContactsService.getPrivilegeEnumID().subscribe(res => {
      this.privilegeEnumID = res.data.getPrivilegeEnumID;
      this.spinner.hide();
      for (const value of this.privilegeEnumID) {
        this.privilegeEnumIDArray.push({
          label: value.enumId,
          value: value.enumId
        })
      }
    })
  }
  getPrivilegeEnumIDStatusId() {
    this.spinner.show();
    this.myContactsService.getPrivilegeEnumIDStatusId().subscribe(res => {
      this.statusID = res.data.getPrivilegeEnumIDStatusId;
      this.spinner.hide();
      for (const value of this.statusID) {
        this.statusIDArray.push({
          label: value.statusId,
          value: value.statusId
        })
      }
    })
  }
  getContentPurposeType() {
    this.spinner.show();
    this.myContactsService.getContentPurposeType().subscribe(res => {
      this.ContentPurposeType = res.data.getContentPurposeById;
      this.spinner.hide();
      for (const value of this.ContentPurposeType) {
        this.ContentPurposeTypeArray.push({
          label: value.description ,
          value: value.contentPurposeTypeId
        })
      }
    })
  }
  getRoleTypeId() {
    this.spinner.show();
    this.myContactsService.getRoleTypeIdContent().subscribe(res => {
      this.roleTypeIdContent = res.data[0].node;
      this.spinner.hide();
      for (const value of this.roleTypeIdContent) {
        this.roleTypeIdContentArray.push({
          label: value.description ,
          value: value.roleTypeId
        })
      }
    })
  }
  getContentOperations() {
    this.spinner.show();
    this.myContactsService.getContentOperations().subscribe(res => {
      this.contentOperations = res.data.getContentOperation;
      this.spinner.hide();
      for (const value of this.contentOperations) {
        this.contentOperationsArray.push({
          label: value.description ,
          value: value.contentOperationId
        })
      }
    })
  }
  removeContentPurposeOperation(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "contentPurposeOperationNewId": product.ContentPurposeOperationNewId
        }
        this.myContactsService.removeContentPurposeOperation(req).subscribe(res => {
          this.getContentPurposeOperation();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  getContentPurposeOperation() {
    this.spinner.show();
    this.myContactsService.getContentPurposeOperation().subscribe(res => {
      this.contentPurposeOperation = res.data.getContentPurposeOperation;
      this.spinner.hide();

    })
  }

  cancelSubmit() {
    this._Router.navigate(["/fsm/fsmSetting/work-order"])
  }

  addContentPurposeOperation() {
    this.spinner.show();
    const requestData = {
      "basicWorkOrderTypeId": "",
      "contentOperationId": this.contentPurposeOperationForm.value.contentOperationID,
      "contentPurposeTypeId": this.contentPurposeOperationForm.value.contentPurposeTypeID,
      "privilegeEnumId": this.contentPurposeOperationForm.value.privilegeEnumID,
      "roleTypeId": this.contentPurposeOperationForm.value.roleTypeID,
      "statusId": this.contentPurposeOperationForm.value.statusID,
      "contentPurposeOperationNewId": ""

    }

    this.myContactsService.addContentPurposeOperation(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.contentPurposeOperationForm.reset();
        this.getContentPurposeOperation();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  reset(){
        this.contentPurposeOperationForm.reset();
        this.show = false;
  }
  update(product) {
    this.show = true;
    this.productData = product;
    setTimeout(() => {
      const formValue = this.contentPurposeOperationForm;
      formValue.patchValue({
        contentPurposeTypeID:product.contentPurposeTypeId,
        roleTypeID:product.roleTypeId,
        statusID: product.statusId,
        privilegeEnumID: product.privilegeEnumId,
        contentOperationID: product.contentOperationId,
      })
    }, 2000);

  }
  updateFSMDispatchCenterWorkZone() {
    this.spinner.show();
    const requestData = {
      "contentOperationId": this.contentPurposeOperationForm.value.contentOperationID,
      "contentPurposeTypeId": this.contentPurposeOperationForm.value.contentPurposeTypeID,
      "privilegeEnumId": this.contentPurposeOperationForm.value.privilegeEnumID,
      "roleTypeId": this.contentPurposeOperationForm.value.roleTypeID,
      "statusId": this.contentPurposeOperationForm.value.statusID,
      "contentPurposeOperationNewId":  this.productData.ContentPurposeOperationNewId,
    }
    this.myContactsService.updateContentPurposeOperation(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.contentPurposeOperationForm.reset();
        this.getContentPurposeOperation();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  openPurposeOperation() {
    this._Router.navigate(['/content/content-setup/edit-content-purpose-operation']);

  }

  openContentOperation() {
    this._Router.navigate(['/content/content-setup/edit-content-operation']);

  }

  openAssociationPredicate() {
    this._Router.navigate(['/content/content-setup/edit-content-assoication-predicate']);

  }

  openTypeAttribute() {
    this._Router.navigate(['/content/content-setup/edit-content-type-attribute']);

  }

  openPurposeType() {
    this._Router.navigate(['/content/content-setup/edit-content-purpose-type']);

  }

  openAssociationType() {
    this._Router.navigate(['/content/content-setup/edit-content-association-type']);

  }

  openPermission() {
    this._Router.navigate(['/content/content-setup/permission']);

  }

  openContentType() {
    this._Router.navigate(['/content/content-setup']);

  }


  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });


  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;

  }


}
