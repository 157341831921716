import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
@Component({
  selector: 'app-create-purchase-invoice-gl-account',
  templateUrl: './create-purchase-invoice-gl-account.component.html',
  styleUrls: ['./create-purchase-invoice-gl-account.component.css']
})
export class CreatePurchaseInvoiceGlAccountComponent implements OnInit {
  createPurchase:FormGroup;
  activeCategory=2;
  partyId: any;
  idPur: string;
  id: string;
  glAccountId: any;
  glAccountIdArray: any[]=[];
  invoiceItemTypeId: any;
  invoiceItemTypeIdArray: any[]=[];
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
  
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createPurchase = this._FormBuilder.group({
        invoiceItemType: [''],
        overrideRevenue:[''],
      
       
        
      });
    
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
    
    
     
    });
    this.getInvoiceItemTypeListApiData();
    this.getInvoiceItemTypeInitialData();
  }
  
  getInvoiceItemTypeInitialData() {
    this.spinner.show();
    this.idPur='PURCHASE'
    this.accountsService.getInvoiceItemTypeInitialData(this.partyId,this.idPur).subscribe((res: any) => {

      this.glAccountId = res.data;
      this.spinner.hide();
      for (const value of this.glAccountId) {
        this.glAccountIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }





    })
   
  }
  getInvoiceItemTypeListApiData() {
    this.spinner.show();
    this.id='PURCHASE'
    this.accountsService.getInvoiceItemTypeListApiData(this.id).subscribe((res: any) => {

      this.invoiceItemTypeId = res.data;
      this.spinner.hide();
      for (const value of this.invoiceItemTypeId) {
        this.invoiceItemTypeIdArray.push({
          label: value.description,
          value: value.invoiceItemTypeId
        })
      }
    })
   
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
     
      "glAccountId":this.createPurchase.value.overrideRevenue,
      "invoiceItemTypeId": this.createPurchase.value.invoiceItemType,
      "organizationPartyId":  this.partyId
    }
    this.accountsService.addPurInvoiceItemTypeGlAssignments(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  
  
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
  }

}

