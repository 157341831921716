import { Component, OnInit, OnDestroy } from '@angular/core';
import {HeaderService} from '../../../header/header.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService} from '../../../crm/accounts/accounts.service';
import {DropdownModule} from 'primeng/dropdown';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-new-return',
  templateUrl: './create-new-return.component.html',
  styleUrls: ['./create-new-return.component.css']
})
export class CreateNewReturnComponent implements OnInit {
  products3: any;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  createReturn: FormGroup;
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  personId: any;
  partyIdArray: any[]=[];
  facilities: any;
  dateIdArray: { label: string; value: string; }[];
  headerArray: any[]=[];
  statusIdArray: any[]=[];
  currencyListArray: any[]=[];
  facilityArray: any[]=[];
  estimatedReadyDate: string;
  updateId: any;
  show: boolean;
  pageNo:number = 1;
  activeCategory = 1;
  Date: string;
  createdBy: any;
  constructor(
    readonly accountsService: AccountsService,
    readonly  HeaderService: HeaderService,
    public addSkillService:AddSkillService,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly activatedRoute:ActivatedRoute,
    readonly router: Router  ) {
      this.createReturn = this._FormBuilder.group({
        billingAccountId: [''],
        currencyUomId: [''],
        destinationFacilityId: [''],
        entryDate: [''],
        finAccountId: [''],
        fromPartyId: [''],
        needsInventoryReceive: [''],
        originContactMechId: [''],
        paymentMethodId: [''],
        returnHeaderTypeId: [''],
        statusId: [''],
        supplierRmaId: [''],
        toPartyId: [''],
        
      });
      this.dateIdArray = [
        {
          label: 'Y',
          value: 'Y'
        },
        {
          label: 'N',
          value: 'N'
        },
       
       
      
       
      ];
     } ;
     offerShowingLineColoumns = [
      { field: 'status', header: 'Return Id' },
      { field: 'name', header: 'Entry Date' },
      { field: 'email', header: 'Return From Party' },
      { field: 'phone', header: 'Destination Facility' },
      { field: 'type', header: 'Status' },
    ];
    							
    offerShowingLineData=[{
      status:'10061',
      name:'2020-11-30 19:59:14.632',
      email:'THE ADMINISTRATOR',
      phone:'Web Store Warehouse',
      type:'Requested',
    }  ,
    {
      status:'10065',
      name:'2020-11-30 19:59:14.632',
      email:'THE ADMINISTRATOR',
      phone:'Web Store Warehouse',
      type:'Accepted',
    }  ,
    {
      status:'10060',
      name:'2020-11-30 19:59:14.632',
      email:'THE ADMINISTRATOR',
      phone:'Web Store Warehouse',
      type:'Requested',
    }  ,
    {
      status:'10062',
      name:'2020-11-30 19:59:14.632',
      email:'THE ADMINISTRATOR',
      phone:'Web Store Warehouse',
      type:'Accepted',
    }  ,
    {
      status:'10063',
      name:'2020-11-30 19:59:14.632',
      email:'THE ADMINISTRATOR',
      phone:'Web Store Warehouse',
      type:'Requested',
    }  ,
    {
      status:'10064',
      name:'2020-11-30 19:59:14.632',
      email:'THE ADMINISTRATOR',
      phone:'Web Store Warehouse',
      type:'Accepted',
    }  ,
 ]
 ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails()
  
    this.getReturnHeader();
    this.getReturnStatusId();
    this.getPartyId();
    this.getCurrency();
    this.getDestinationFacility();
    this.activatedRoute.queryParams.subscribe(params=> [
      this.updateId = params["updateId"],
      
    ])
    if(this.updateId) {
      this.show=true;
      this.getReturnOrderValue()
    
     
    }
    else {
      this.show = false;
    }
  }
  getReturnOrderValue() {
    this.spinner.show();
    let req = {
    "pageNo":this.pageNo,
    "pageSize":this.pageSize
    }
    let form ={
      billingAccountId: "",
      entryDateFrom: "",
      entryDateFromSearchType: "Contains",
      entryDateTo: "",
      entryDateToSearchType: "Contains",
      billingAccountIdSearchType: "Contains",
      fromPartyId: "",
      returnHeaderTypeId: "",
      returnId: this.updateId,
      returnIdSearchType: "Contains",
      statusIds: [""],
    }
  
    
    this.accountsService.getOrderReturn(req,form).subscribe(res=> {
      this.Date=this.datePipe.transform(res.data[0].entryDate,"yyyy-MM-dd")
      this.createdBy=res.data[0].createdBy;
       setTimeout(() => {
        const formValue = this.createReturn;
      formValue.patchValue({
        billingAccountId:res.data[0].billingAccountId,
        currencyUomId: res.data[0].currencyUomId,
        destinationFacilityId:res.data[0].destinationFacilityId,
        entryDate:this.Date,
        finAccountId:res.data[0].finAccountId,
        fromPartyId: res.data[0].fromPartyId,
        needsInventoryReceive: res.data[0].needsInventoryReceive,
        originContactMechId:res.data[0].originContactMechId,
        paymentMethodId:res.data[0].paymentMethodId,
        returnHeaderTypeId: res.data[0].returnHeaderTypeId,
        statusId: res.data[0].statusId,
        supplierRmaId: res.data[0].supplierRmaId,
        toPartyId: res.data[0].toPartyId
       
      })
      }, 3000); 
    })
    this.spinner.hide();
  }
  getDestinationFacility(): void {
    this.spinner.show();
    this.accountsService.getDestinationFacility().subscribe((res) => {
      let facility = res.data;
      this.spinner.hide();
    for (const value of facility) {
        this.facilityArray.push({
          label: value.facilityName,
          value: value.facilityId,
        });
      } 
    });
   
  }
  getReturnHeader(): void {
    this.spinner.show();
    this.accountsService.getReturnHeader().subscribe((res) => {
      let header = res.data;
      this.spinner.hide();
    for (const value of header) {
        this.headerArray.push({
          label: value.description,
          value: value.returnHeaderTypeId,
        });
      } 
    });
   
  }
  getReturnStatusId(): void {
    this.spinner.show();
    this.accountsService.getReturnStatusId().subscribe((res) => {
      let statusId = res.data;
      this.spinner.hide();
      for (const value of statusId) {
        this.statusIdArray.push({
          label: value.description,
          value: value.statusId,
        });
      } 
    });
  
  }
  getCurrency(): void {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe((res) => {
      let currencyList = res.data.currencyList;
      this.spinner.hide();
       for (const value of currencyList) {
        this.currencyListArray.push({
          label: value.description,
          value: value.uomId,
        });
      } 
    });
    
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res=> {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.personId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
   
  }
  submit(): void {
    this.spinner.show();
   this.estimatedReadyDate=this.datePipe.transform(this.createReturn.value.entryDate,"yyyy-MM-dd hh:mm:ss")
    const requestData ={
    
        "billingAccountId":this.createReturn.value.billingAccountId,
        "currencyUomId": this.createReturn.value.currencyUomId,
        "destinationFacilityId": this.createReturn.value.destinationFacilityId,
        "entryDate": this.estimatedReadyDate,
        "finAccountId": this.createReturn.value.finAccountId,
        "fromPartyId": this.createReturn.value.fromPartyId,
        "needsInventoryReceive": this.createReturn.value.needsInventoryReceive,
        "originContactMechId": this.createReturn.value.originContactMechId,
        "paymentMethodId": this.createReturn.value.paymentMethodId,
        "returnHeaderTypeId": this.createReturn.value.returnHeaderTypeId,
        "statusId":this.createReturn.value.statusId,
        "supplierRmaId": this.createReturn.value.supplierRmaId,
        "toPartyId": this.createReturn.value.toPartyId
     
    }
    this.accountsService.createOrderReturn(requestData).subscribe((res: any) => {
 
      if (res.success) {
        this.toastr.success("Successfully Created");
        this.spinner.hide();
        this.router.navigate(['order/return-order'])
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    this.spinner.hide();
  }
  cancel(){
    this.router.navigate(['order/return-order'])
  }
  update(): void {
    this.spinner.show();
    this.estimatedReadyDate=this.datePipe.transform(this.createReturn.value.entryDate,"yyyy-MM-dd hh:mm:ss")
     const requestData ={
     
         "billingAccountId":this.createReturn.value.billingAccountId,
         "currencyUomId": this.createReturn.value.currencyUomId,
         "destinationFacilityId": this.createReturn.value.destinationFacilityId,
         "entryDate": this.estimatedReadyDate,
         "finAccountId": this.createReturn.value.finAccountId,
         "fromPartyId": this.createReturn.value.fromPartyId,
         "needsInventoryReceive": this.createReturn.value.needsInventoryReceive,
         "originContactMechId": this.createReturn.value.originContactMechId,
         "paymentMethodId": this.createReturn.value.paymentMethodId,
         "returnHeaderTypeId": this.createReturn.value.returnHeaderTypeId,
         "statusId":this.createReturn.value.statusId,
         "supplierRmaId": this.createReturn.value.supplierRmaId,
         "toPartyId": this.createReturn.value.toPartyId
    
     }
     this.accountsService.updateReturn(requestData,this.updateId,this.createdBy).subscribe((res: any) => {
    
       if (res.success) {
        this.spinner.hide();
         this.toastr.success("Updated Successfully");
         this.router.navigate(['order/return-order'])
       }
     }, (err) => {
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
     this.spinner.hide();
   }
}