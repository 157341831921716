<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">CompDoc</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="openCreateNewRoot()"><a>
                                Create New Root CompDoc Template</a></li>
            
                            <li [ngClass]="activeCategory==2?'active':''"  (click)="openApprovals()"><a>
                                View Waiting Approvals</a></li>
                            
                           

                        </ul>
                        
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">

                                    <div class="w3-card-4  w-100">
                                        <div class="accordion classCard" id="myAccordion">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                        <span class="headingAccords"> Search</span>
                                                        <!-- <button type="button" class="btn btn-outline-secondary active"   routerLinkActive="active"
                                                        data-target="#creatContentPopup" data-toggle="modal">Create Content</button>
                                             -->
                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 88%!important; padding-top: 11px !important;
                                                            padding-bottom: 11px !important;">
                                                        </button>									
            
                                                    </div>
                
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                    <div class="card-body">
                                                        <form class="w-100" [formGroup]="compDocForm">

                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" >
                                                                            <label for="exampleInputEmail1">
                                                                                Content ID	</label>
                                                                        </div>
                                                                        
                                                                        <div class="col-lg-2" >
                                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Enter  Content ID"
                                                                            formControlName="contentID">
                                                                        </div>
                                                                      
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Content Name		
                                                                            </label>
                                                                        </div>
                                                                        
                                                                        <div class="col-lg-2" >
                                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Enter  Content Name"
                                                                            formControlName="contentName">
                                                                        
                                                                        </div>
                                                
                                                                        
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" >
                                                                            <label for="accountLimit">Content Type ID</label>
                                                                        </div>
                                                                        
                                                                        <div class="col-lg-2">
                    
                                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Enter Content Type ID"
                                                                            formControlName="contentTypeID">
                    
                                                                  
                                                                        </div>
                                                                        
                                                
                                                                    </div>
                                                                </div>
                                                               
                                                            </div>
                                                        </form>
    
                                                        <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -25%;">
                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="FindCompDoc()">Find</button>&nbsp;
                                                            <button type="button" class="btn btn-danger ml-2" (click)="resetForm()">Reset</button>
                                                        </div>
    
    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <!-- <div class="w3-card-4 classCard"  style="width:98%!important; margin-left:1% ;">
                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                             <button type="button" class="btn btn-outline-secondary active" data-toggle="modal" 
                                            data-target="#createNewCompDocPopup">Create New CompDoc</button> 
                                        </div><br>
                                    <form class="w-100" [formGroup]="compDocForm">

                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Content ID	</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter  Content ID"
                                                        formControlName="contentID">
                                                    </div>
                                                  
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Content Name		
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter  Content Name"
                                                        formControlName="contentName">
                                                    
                                                    </div>
                            
                                                    
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="accountLimit">Content Type ID</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">

                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Content Type ID"
                                                        formControlName="contentTypeID">

                                              
                                                    </div>
                                                    
                            
                                                </div>
                                            </div>
                                           
                                            
                                            
                                            
                                        </div>
                                    </form>
                                        <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -25%;">
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="FindCompDoc()">Find</button>&nbsp;
                                            <button type="button" class="btn btn-danger ml-2" (click)="resetForm()">Reset</button>
                                        </div>
                                    </div> -->
                                    
                                    <br>

                                    <div class="w3-card-4 classCardB"  style="width:98%!important; margin-left:1%; margin-top: 0% !important;">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                            <h4 class=" common-styling h4M">
                                                Comp Doc
                                            </h4>
                                            
                                            </div>
                                        </header>
                                    <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select" style="display: block!important;">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">
                                                
                                                <div class="card own-account-table">
                                                    <p-table [value]="FindCompDocAll" [paginator]="true" [rows]="rows"
                                                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible"><input
                                                                                        type="checkbox" name="undefined"
                                                                                        value="undefined"></div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </p-checkbox>
                                                                        
                                                                        Content ID	 <p-sortIcon field="code"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                    <div style="color: white;">Content Type ID<p-sortIcon field="name">
                                                                    </p-sortIcon></div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'170px'}">
                                                                    <div style="color: white;"> Content Name <p-sortIcon
                                                                        field="category">
                                                                    </p-sortIcon></div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                    <div style="color: white;">Action</div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Tree
                                                                </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible"><input
                                                                                        type="checkbox" name="undefined"
                                                                                        value="undefined"></div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </p-checkbox>
                                                                        <span class=""
                                                                            >{{product.dataNodes.contentId}}</span>
                                                                    </div>
                                                                </td>
                                                            
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.dataNodes.contentTypeId}}</td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.dataNodes.contentName}}</td>
                                                                <td [ngStyle]="{'width':'170px'}"> 
                                                                    <button
                                                                    type="submit"
                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                    (click)="referToEdit(product)">Edit</button>
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}"> 
                                                                    <button
                                                                    type="submit"
                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                    (click)="referToTree(product)">Tree</button>
                                                                </td>
                                                            </tr>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                            <h4 class="ml-2">No Record Found</h4>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                    
                                        

                </div>
            </div>
        </div>
    </div> 
</div>
            
 <div class="modal fade" id="createNewCompDocPopup" tabindex="-1" role="dialog" aria-labelledby="createNewCompDocPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create New CompDoc</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create New CompDoc</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="compDocForm">

                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="exampleInputEmail1">
                                                            Content ID	</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter  Content ID"
                                                        formControlName="contentID">
                                                    </div>
                                                  
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Content Name		
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter  Content Name"
                                                        formControlName="contentName">
                                                    
                                                    </div>
                            
                                                    
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group classInput" >
                                                        <label for="accountLimit">Content Type ID</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">

                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Content Type ID"
                                                        formControlName="contentTypeID">

                                              
                                                    </div>
                                                    
                            
                                                </div>
                                            </div>
                                           
                                            
                                            
                                            
                                        </div>
                                    </form>                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 
 <ngx-spinner></ngx-spinner> 

