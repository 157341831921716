import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddEmployeePositionService } from 'src/app/human-resource/add-employee-position/add-employee-position.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-detail-shipment-config',
  templateUrl: './detail-shipment-config.component.html',
  styleUrls: ['./detail-shipment-config.component.css']
})
export class DetailShipmentConfigComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  activeCategory=0;
  activeCategoryA=2;
  createShipmentConfig:FormGroup;
  shipmentGatewayConfTypeId: any;
  description: any;
  show: boolean;
  ConfigTypeID: any;
  ConfigTypeIDArray: any[]=[];
  ShipmentDescription: any;
  ShipmentType: any;
  createShipmentConfigTypeDHL: FormGroup;
  LabelTypeArray: any[]=[];
  LabelType: any;
  labelFormat: any;
  APISchema: any;
  APISchemaArray: any[]=[];
  actionAttributeArray: any[]=[];
  actionAttribute: any;
  ShipmentConfigFEDEXform: FormGroup;
  labelTypeArray: any[]=[];
  labelType: any;
  DropoffType: any;
  DropoffTypeArray: any[]=[];
  PackingType: any;
  PackingTypeArray: any[]=[];
  ShipmentConfigUPSform: FormGroup;
  ShipmentConfigUSPSform: FormGroup;
  TestModeArray: any[]=[];
  TestMode: any;
  certInfoArray: any[]=[];
  certInfo: any;
  ClassificationArray: any;
  Classification: any;
  shipmentGatewayConfigId: any;
  editDataShipment: any;
  data: any;
  labelFormatArray: any[]=[];
  editDataShipmentFedex: any;
  editDataShipmentUPS: any;
  editDataShipmentUSPS: any;
  FundsCashArray: any[]=[];
  FundsCash: any;
  SurchargeApplyArray: any[]=[];
  SurchargeApply: any;

  constructor(
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly addEmployeePositionService: AddEmployeePositionService,
  private _location: Location,
   
    readonly addSkillService: AddSkillService,
    readonly activatedRoute: ActivatedRoute,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
   
    readonly router: Router
  ) { 

    this.createShipmentConfig = this._FormBuilder.group({
      ShipmentDescription: [''],
      ShipmentType: [''],
      ShipmentTypeID:['']
    
    });

    this.createShipmentConfigTypeDHL = this._FormBuilder.group({
      ConntectURL: [''],
      ConnectTimeout: [''],
      Headattribute: [''],
      actionAttribute: [''],
      ShipITUserID: [''],
      Password: [''],
      AccountNumber: [''],
      key: [''],
      labelFormat: [''],
      APISchema: [''],
    
    
    });

    this.ShipmentConfigFEDEXform = this._FormBuilder.group({
      ConnectURL: [''],
      SoapURL: [''],
      Timeout: [''],
      AccountNumber: [''],
      MeterNumber: [''],
      password: [''],
      key: [''],
      labelType: [''],
      DropoffType: [''],
      PackingType: [''],
      ShipmentLocation: [''],
      Subscription: [''],
      RateEstimate: [''],
    
    
    });


    this.ShipmentConfigUPSform = this._FormBuilder.group({
      ConnectURL: [''],
      Timeout: [''],
      ShipperNumber: [''],
      billShipper: [''],
      License: [''],
      UserID: [''],
      password: [''],
      certInfo: [''],
      path: [''],
      PickupType: [''],
      Classification: [''],
      weight: [''],
      MinimumWeight: [''],
      Delivery: [''],
      SurchargeAmount: [''],
      SurchargeCurr: [''],
      SurchargeApply: [''],
      FundsCash: [''],
      memo: [''],
      subject: [''],
    
    
    });

    this.ShipmentConfigUSPSform = this._FormBuilder.group({
      ConnectURL: [''],
      Labels: [''],
      Timeout: [''],
      UserID: [''],
      password: [''],
      EstimateSplit: [''],
      TestMode: [''],
    });

  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.shipmentGatewayConfTypeId = params["shipmentGatewayConfTypeId"];
      this.shipmentGatewayConfigId = params["shipmentGatewayConfigId"];
      this.description = params["description"];
   
     
      
    })
  
  this.show=true;
  this.getLabelImageFormat();
  this.getHeadActionAttribute();
  this.getRateEstimateAPISchema();
  this.getShipmentGatewayConfigTypeIdList();
  this.getShipmentGatewayDhlConfigFedexDropoffType();
  this.getShipmentGatewayDhlConfigFedexLabelImageType();
  this.getShipmentGatewayDhlConfigFedexPackingType();
  this.getShipmentGatewayDhlUspsConfigTestMode();
  this.getShipmentGatewayDhlUspConfigSaveCertInfo();
  this.getShipmentGatewayDhlUspConfigCustomerClassification();
  this.getShipmentGatewayDhlConfig();
  this.getShipmentGatewayDhlConfigFedex();
  this.getShipmentGatewayDhlUpsConfig();
  this.getShipmentGatewayDhlUspsConfig();
 this.getShipmentGatewayDhlUspConfigFundsCod();
 this.getShipmentGatewayDhlUspConfigSurchargeApply();  

}
homeButton(){
  this.router.navigate(['/facilities/Dashboard'])

}
cancelSubmit(){
  this._location.back();
}



  edit(){

    setTimeout(() => {
      const formValue = this.createShipmentConfig;
    formValue.patchValue({
     
      ShipmentType:  this.shipmentGatewayConfTypeId ,
      ShipmentTypeID: this.shipmentGatewayConfigId,
      ShipmentDescription: this.description,
    })
    }, 2000);

  }

  editDHL(){

    setTimeout(() => {
      const formValue = this.createShipmentConfig;
    formValue.patchValue({
      ShipmentDescription: this.description,
      ShipmentType: this.shipmentGatewayConfTypeId,
    })
    }, 1000);

  }

  getShipmentGatewayDhlConfig(){
      this.spinner.show();
    
     this.accountsService.getShipmentGatewayDhlConfig(this.shipmentGatewayConfigId).subscribe((res:any)=> {
      this.editDataShipment= res.data[0];
      setTimeout(() => {

       
         const formValue = this.createShipmentConfigTypeDHL;
         formValue.patchValue({
           ConntectURL: this.editDataShipment.connectUrl,
           ConnectTimeout:this.editDataShipment.connectTimeout,
           Headattribute: this.editDataShipment.headVersion,
           actionAttribute: this.editDataShipment.headAction,
           ShipITUserID: this.editDataShipment.accessUserId,
           Password: this.editDataShipment.accessPassword,
           AccountNumber:this.editDataShipment.accessAccountNbr,
           key: this.editDataShipment.accessShippingKey,
           labelFormat: this.editDataShipment.labelImageFormat,
           APISchema: this.editDataShipment.rateEstimateTemplate,
         })
      
       }, 1000);
       this.spinner.hide();
     })  
    }

    

    getShipmentGatewayDhlUpsConfig(){
      this.spinner.show();
    
     this.accountsService.getShipmentGatewayDhlUpsConfig(this.shipmentGatewayConfigId).subscribe((res:any)=> {
      this.editDataShipmentUPS= res.data[0];
      setTimeout(() => {

       
         const formValue = this.ShipmentConfigUPSform;
         formValue.patchValue({
          ConnectURL: this.editDataShipmentUPS.connectUrl,
          Timeout: this.editDataShipmentUPS.connectTimeout,
          ShipperNumber:this.editDataShipmentUPS.shipperNumber,
          billShipper: this.editDataShipmentUPS.billShipperAccountNumber,
          License: this.editDataShipmentUPS.accessLicenseNumber,
          UserID: this.editDataShipmentUPS.accessUserId,
          password: this.editDataShipmentUPS.accessPassword,
          certInfo: this.editDataShipmentUPS.saveCertInfo,
          path: this.editDataShipmentUPS.saveCertPath,
          PickupType:this.editDataShipmentUPS.shipperPickupType,
          Classification: this.editDataShipmentUPS.customerClassification,
          weight: this.editDataShipmentUPS.maxEstimateWeight,
          MinimumWeight:this.editDataShipmentUPS.minEstimateWeight,
          Delivery: this.editDataShipmentUPS.codAllowCod,
          SurchargeAmount:this.editDataShipmentUPS.codSurchargeAmount,
          SurchargeCurr: this.editDataShipmentUPS.codSurchargeCurrencyUomId,
          SurchargeApply: this.editDataShipmentUPS.codSurchargeApplyToPackage,
          FundsCash: this.editDataShipmentUPS.codFundsCode,
          memo: this.editDataShipmentUPS.defaultReturnLabelMemo,
          subject: this.editDataShipmentUPS.defaultReturnLabelSubject,
         
         })
      
       }, 1000);
       this.spinner.hide();
     })  
    }

    getShipmentGatewayDhlConfigFedex(){
      this.spinner.show();
     this.accountsService.getShipmentGatewayDhlConfigFedex(this.shipmentGatewayConfigId).subscribe((res:any)=> {
      this.editDataShipmentFedex= res.data[0];
      setTimeout(() => {

       
         const formValue = this.ShipmentConfigFEDEXform;
         formValue.patchValue({
          ConnectURL: this.editDataShipmentFedex.connectUrl,
          SoapURL:this.editDataShipmentFedex.connectSoapUrl,
          Timeout: this.editDataShipmentFedex.connectTimeout,
          AccountNumber:"",
          MeterNumber: "",
          password: "",
          key: "",
          labelType:this.editDataShipmentFedex.labelImageType,
          DropoffType:this.editDataShipmentFedex.defaultDropoffType,
          PackingType: this.editDataShipmentFedex.defaultPackagingType,
          ShipmentLocation: this.editDataShipmentFedex.templateShipment,
          Subscription:this.editDataShipmentFedex.templateSubscription,
          RateEstimate: this.editDataShipmentFedex.rateEstimateTemplate,
         })
      
       }, 1000);
       this.spinner.hide();
     })  
    }



    
getShipmentGatewayDhlUspsConfig(){
      this.spinner.show();
      
    
     this.accountsService.getShipmentGatewayDhlUspsConfig(this.shipmentGatewayConfigId).subscribe((res:any)=> {
      this.editDataShipmentUSPS= res.data[0];
      setTimeout(() => {

       
         const formValue = this.ShipmentConfigUSPSform;
         formValue.patchValue({
          ConnectURL: this.editDataShipmentUSPS.connectUrl,
          Labels:this.editDataShipmentUSPS.connectUrlLabels,
          Timeout: this.editDataShipmentUSPS.connectTimeout,
          UserID: this.editDataShipmentUSPS.accessUserId,
          password: this.editDataShipmentUSPS.accessPassword,
          EstimateSplit: this.editDataShipmentUSPS.maxEstimateWeight,
          TestMode:this.editDataShipmentUSPS.test,
         })
      
       }, 1000);
       this.spinner.hide();
     })  
    }

   
updateShipmentGatewayDhl(){
    this.spinner.show();
  
  const formdata={
    "accessAccountNbr": this.createShipmentConfigTypeDHL.value.AccountNumber,
    "accessPassword": this.createShipmentConfigTypeDHL.value.Password,
    "accessShippingKey":this.createShipmentConfigTypeDHL.value.key,
    "accessUserId": this.createShipmentConfigTypeDHL.value.ShipITUserID,
    "connectTimeout":this.createShipmentConfigTypeDHL.value.ConnectTimeout,
    "connectUrl": this.createShipmentConfigTypeDHL.value.ConntectURL,
    "headAction": this.createShipmentConfigTypeDHL.value.actionAttribute,
    "headVersion": this.createShipmentConfigTypeDHL.value.Headattribute,
    "labelImageFormat":this.createShipmentConfigTypeDHL.value.labelFormat,
    "rateEstimateTemplate":this.createShipmentConfigTypeDHL.value.ShipmentDescription,
    "shipmentGatewayConfigId": this.shipmentGatewayConfigId

  
  }
  
    this.accountsService.updateShipmentGatewayDhl(formdata).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton2.nativeElement.click();


      }      }
      ,(err) => {
        for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }}
      )
      this.spinner.hide();
  }

  
updateShipmentGatewayFedex(){
    this.spinner.show();
  
  const formdata={
  
    "accessAccountNbr":this.ShipmentConfigFEDEXform.value.AccountNumber,
    "accessMeterNumber":this.ShipmentConfigFEDEXform.value.MeterNumber,
    "accessUserKey": this.ShipmentConfigFEDEXform.value.key,
    "accessUserPwd":this.ShipmentConfigFEDEXform.value.password,
    "connectSoapUrl": this.ShipmentConfigFEDEXform.value.SoapURL,
    "connectTimeout": this.ShipmentConfigFEDEXform.value.Timeout,
    "connectUrl": this.ShipmentConfigFEDEXform.value.ConnectURL,
    "defaultDropoffType": this.ShipmentConfigFEDEXform.value.DropoffType,
    "defaultPackagingType": this.ShipmentConfigFEDEXform.value.PackingType,
    "labelImageType": this.ShipmentConfigFEDEXform.value.labelType,
    "rateEstimateTemplate": this.ShipmentConfigFEDEXform.value.RateEstimate,
    "shipmentGatewayConfigId":  this.shipmentGatewayConfigId,
    "templateShipment": this.ShipmentConfigFEDEXform.value.ShipmentLocation,
    "templateSubscription":  this.ShipmentConfigFEDEXform.value.Subscription,

    
  }
  
    this.accountsService.updateShipmentGatewayFedex(formdata).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton3.nativeElement.click();


      }      }
      ,(err) => {
        for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }}
      )
      this.spinner.hide();
  }

  
updateShipmentGatewayUps(){
    this.spinner.show();
  
  const formdata={
  
    "accessLicenseNumber":this.ShipmentConfigUPSform.value.License,
    "accessPassword": this.ShipmentConfigUPSform.value.password,
    "accessUserId": this.ShipmentConfigUPSform.value.UserID,
    "billShipperAccountNumber": this.ShipmentConfigUPSform.value.billShipper,
    "codAllowCod": this.ShipmentConfigUPSform.value.Delivery,
    "codFundsCode": this.ShipmentConfigUPSform.value.FundsCash,
    "codSurchargeAmount": this.ShipmentConfigUPSform.value.SurchargeAmount,
    "codSurchargeApplyToPackage": this.ShipmentConfigUPSform.value.SurchargeApply,
    "codSurchargeCurrencyUomId": this.ShipmentConfigUPSform.value.SurchargeCurr,
    "connectTimeout": this.ShipmentConfigUPSform.value.Timeout,
    "connectUrl": this.ShipmentConfigUPSform.value.ConnectURL,
    "customerClassification": this.ShipmentConfigUPSform.value.Classification,
    "defaultReturnLabelMemo": this.ShipmentConfigUPSform.value.memo,
    "defaultReturnLabelSubject": this.ShipmentConfigUPSform.value.subject,
    "maxEstimateWeight": this.ShipmentConfigUPSform.value.weight,
    "minEstimateWeight": this.ShipmentConfigUPSform.value.MinimumWeight,
    "saveCertInfo": this.ShipmentConfigUPSform.value.certInfo,
    "saveCertPath": this.ShipmentConfigUPSform.value.path,
    "shipmentGatewayConfigId": this.shipmentGatewayConfigId,
    "shipperNumber": this.ShipmentConfigUPSform.value.ShipperNumber,
    "shipperPickupType": this.ShipmentConfigUPSform.value.PickupType,


    
    
  }
  
    this.accountsService.updateShipmentGatewayUps(formdata).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton4.nativeElement.click();


      }      }
      ,(err) => {
        for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }}
      )
      this.spinner.hide();
  }

  UpdateShipmentGatewayConfig(){
    this.spinner.show();
  
  const formdata={
    "shipmentGatewayConfTypeId": this.createShipmentConfig.value.ShipmentType,
    "shipmentGatewayConfigId":this.createShipmentConfig.value.ShipmentTypeID,
    "description": this.createShipmentConfig.value.ShipmentDescription,
  }
  
    this.accountsService.UpdateShipmentGatewayConfig(formdata).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();


      }      }
      ,(err) => {
        for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }}
      )
      this.spinner.hide();
  }


  
updateShipmentGatewayUsps(){
    this.spinner.show();
  
  const formdata={
    "accessPassword":this.ShipmentConfigUSPSform.value.password,
    "accessUserId": this.ShipmentConfigUSPSform.value.UserID,
    "connectTimeout": this.ShipmentConfigUSPSform.value.Timeout,
    "connectUrl": this.ShipmentConfigUSPSform.value.ConnectURL,
    "connectUrlLabels": this.ShipmentConfigUSPSform.value.Labels,
    "maxEstimateWeight": this.ShipmentConfigUSPSform.value.EstimateSplit,
    "shipmentGatewayConfigId": this.shipmentGatewayConfigId,
    "test": this.ShipmentConfigUSPSform.value.TestMode,

   
  }
  
    this.accountsService.updateShipmentGatewayUsps(formdata).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton5.nativeElement.click();


      }      }
      ,(err) => {
        for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }}
      )
      this.spinner.hide();
  }
  

  getRateEstimateAPISchema() {
    this.spinner.show();
    this.accountsService.getRateEstimateAPISchema().subscribe(res => {
      this.APISchema = res.data;
      this.spinner.hide();
      for (const value of this.APISchema) {
        this.APISchemaArray.push({
          label: value.rateEstimateTemplate,
          value: value.rateEstimateTemplate
        })
      }
    })
  }

  
getHeadActionAttribute() {
    this.spinner.show();
    this.accountsService.getHeadActionAttribute().subscribe(res => {
      this.actionAttribute = res.data;
      this.spinner.hide();
      for (const value of this.actionAttribute) {
        this.actionAttributeArray.push({
          label: value.headAction,
          value: value.headAction
        })
      }
    })
  }

  
getShipmentGatewayDhlUspConfigSurchargeApply() {
    this.spinner.show();
    this.accountsService.getShipmentGatewayDhlUspConfigSurchargeApply().subscribe(res => {
      this.SurchargeApply = res.data;
      this.spinner.hide();
      for (const value of this.SurchargeApply) {
        this.SurchargeApplyArray.push({
          label: value.codSurchargeCurrencyUomId,
          value: value.codSurchargeCurrencyUomId
        })
      }
    })
  }
  getLabelImageFormat() {
    this.spinner.show();
    this.accountsService.getLabelImageFormat().subscribe(res => {
      this.labelFormat = res.data;
      this.spinner.hide();
      for (const value of this.labelFormat) {
        this.labelFormatArray.push({
          label: value.labelImageFormat,
          value: value.labelImageFormat
        })
      }
    })
  }


  getShipmentGatewayConfigTypeIdList() {
    this.spinner.show();
    this.accountsService.getShipmentGatewayConfigTypeIdList().subscribe(res => {
      this.ConfigTypeID = res.data;
      this.spinner.hide();
      for (const value of this.ConfigTypeID) {
        this.ConfigTypeIDArray.push({
          label: value.shipmentGatewayConfTypeId,
          value: value.shipmentGatewayConfTypeId
        })
      }
    })
  }

  
getShipmentGatewayDhlConfigFedexLabelImageType() {
    this.spinner.show();
    this.accountsService.getShipmentGatewayDhlConfigFedexLabelImageType().subscribe(res => {
      this.labelType = res.data;
      this.spinner.hide();
      for (const value of this.labelType) {
        this.labelTypeArray.push({
          label: value.labelImageType,
          value: value.labelImageType
        })
      }
    })
  }

  
getShipmentGatewayDhlConfigFedexDropoffType() {
    this.spinner.show();
    this.accountsService.getShipmentGatewayDhlConfigFedexDropoffType().subscribe(res => {
      this.DropoffType = res.data;
      this.spinner.hide();
      for (const value of this.DropoffType) {
        this.DropoffTypeArray.push({
          label: value.defaultDropoffType,
          value: value.defaultDropoffType
        })
      }
    })
  }

    

  getShipmentGatewayDhlUspConfigFundsCod() {
  this.spinner.show();
  this.accountsService.getShipmentGatewayDhlUspConfigFundsCod().subscribe(res => {
    this.FundsCash = res.data;
    this.spinner.hide();
    for (const value of this.FundsCash) {
      this.FundsCashArray.push({
        label: value.codFundsCode,
        value: value.codFundsCode
      })
    }
  })
}

  getShipmentGatewayDhlConfigFedexPackingType() {
    this.spinner.show();
    this.accountsService.getShipmentGatewayDhlConfigFedexPackingType().subscribe(res => {
      this.PackingType = res.data;
      this.spinner.hide();
      for (const value of this.PackingType) {
        this.PackingTypeArray.push({
          label: value.defaultPackagingType,
          value: value.defaultPackagingType
        })
      }
    })
  }

  getShipmentGatewayDhlUspsConfigTestMode() {
    this.spinner.show();
    this.accountsService.getShipmentGatewayDhlUspsConfigTestMode().subscribe(res => {
      this.TestMode = res.data;
      this.spinner.hide();
      for (const value of this.TestMode) {
        this.TestModeArray.push({
          label: value.test,
          value: value.test
        })
      }
    })
  }

  getShipmentGatewayDhlUspConfigSaveCertInfo() {
    this.spinner.show();
    this.accountsService.getShipmentGatewayDhlUspConfigSaveCertInfo().subscribe(res => {
      this.certInfo = res.data;
      this.spinner.hide();
      for (const value of this.certInfo) {
        this.certInfoArray.push({
          label: value.saveCertInfo,
          value: value.saveCertInfo
        })
      }
    })
  }

  getShipmentGatewayDhlUspConfigCustomerClassification() {
    this.spinner.show();
    this.accountsService.getShipmentGatewayDhlUspConfigCustomerClassification().subscribe(res => {
      this.Classification = res.data;
      this.spinner.hide();
      for (const value of this.Classification) {
        this.ClassificationArray.push({
          label: value.customerClassification,
          value: value.customerClassification
        })
      }
    })
  }

  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }



}
