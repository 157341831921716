import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { PaymentGroupService } from '../accounting-ap/payable-payment-group/payment-group.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-main-payable',
  templateUrl: './main-payable.component.html',
  styleUrls: ['./main-payable.component.css']
})
export class MainPayableComponent implements OnInit {
  total=0;
    rowNumber=0;
    isShown= false; 
    pageSize = 10;
    rows = 50;
    activeCategory=4;
    pageNo = 1;
    opportunityIdArray: any = [
        {
            label: 'Equals',
            value: 'Equals'
        },
        {
            label: 'Contains',
            value: 'Contains'
        },
        {
            label: 'Is Empty',
            value: 'Is Empty'
        },
        {
            label: 'Begins With',
            value: 'Begins With'
        },
    ];
    searchPaymentGroupForm: FormGroup;
    paymentGroups: any[];
   
    agreementType: string;
  getApAccount: any;
  invoicesDueSoon: any;
  pastDueInvoicestotalAmount: any;
    constructor(
        readonly  _Router: Router,
        readonly _FormBuilder: FormBuilder,
        readonly spinner: NgxSpinnerService,
        readonly accountsService: AccountsService,
        readonly _PaymentGroupService: PaymentGroupService,
        readonly _ToastrService: ToastrService,
        private _location: Location,
    ) {
        this.searchPaymentGroupForm = this._FormBuilder.group({
            paymentGroupId: [''],
            paymentGroupIdSearchType: ['Contains'],
            paymentGroupName: [''],
            paymentGroupNameSearchType: ['Contains']
        });
    }
   
    ngOnInit(): void {
      this.getApAccountInvoice();
      this.getAPAccountPastDueInvoice();
    }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/financial/main']);
  }
  
    referTodetailPage(id){
      this._Router.navigate(["/financial/payable/invoices/detailed-summary"],{queryParams:{invoiceId:id}})
    }
    getApAccountInvoice() {
      this.spinner.show();
    
      this.accountsService.getApAccountInvoice().subscribe((res: any) => {
  
        this.getApAccount = res.data.pastDueInvoices;
        this.invoicesDueSoon = res.data.invoicesDueSoon;
        this.spinner.hide();
      
  
  
      })
     
  
    }
    getAPAccountPastDueInvoice() {
      this.spinner.show();
    
      this.accountsService.getAPAccountPastDueInvoice().subscribe((res: any) => {
  
        this.pastDueInvoicestotalAmount = res.data.pastDueInvoicestotalAmount;
       
        this.spinner.hide();
      
  
  
      })
     
  
    }
    
    customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;
  
        if (value1 == null && value2 != null) {
          result = -1;
        }
        else if (value1 != null && value2 == null) {
          result = 1;
        }
        else if (value1 == null && value2 == null) {
          result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        }
        else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
      });
    }
    main(){
        this._Router.navigate(["/financial/main-payable"])
      }
    invoiceType(){
        this.isShown = !this.isShown;
      }
   
    enterBills(){
        this._Router.navigate(["/financial/payable/invoices"])
      }
      payableMakePayment(){
        this._Router.navigate(["/financial/payable/payment-payables"])
      }
      payableMakeGroupPayment(){
        this._Router.navigate(["/financial/payable/payment-group"])
      }
      salesInvoice(){
        this._Router.navigate(["/financial/receiveable/invoices"])
      }
      receivePayment(){
        this._Router.navigate(["/financial/receiveable/payments"])
      }
      receiveGroupPayments(){
        this._Router.navigate(["/financial/receiveable/payment-group"])
      }
  
}