<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Category </span>
           
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
              
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">

                        <li [ngClass]="activeCategory==2?'active':''" (click)="activeTab(2)"><a>
                                Create Product Category</a></li>
                        <ng-container *ngIf="editMode">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="activeTab(1)"><a>
                                    Upload Category Image</a></li>
                        </ng-container>

                    </ul>
                </div>

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <ng-container *ngIf="activeCategory==2">
                                <form class="w-100" [formGroup]="categoryForm">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                              
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Product Category ID</label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="productCategoryId"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Product Category ID">
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Product Category Name</label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="categoryName" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Product Category Name">
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div class="col-lg-12">
                                            <div class="row">
                                              
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Product Category Type<span
                                                        style="color:red">*</span></label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" formControlName="productCategoryTypeId"
                                                    [options]="categoryArray" optionlabel="label"
                                                    placeholder="Product Category Type">
                                                </p-dropdown>
                                                <small class="text-danger"
                                                    *ngIf="!!categoryForm.controls.productCategoryTypeId.invalid && !!categoryForm.controls.productCategoryTypeId.touched"></small>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Product Category Description</label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <textarea class="form-control" formControlName="description"
                                                    id="exampleFormControlTextarea1" rows="1"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="col-lg-12">
                                            <div class="row">
                                              
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Category Image URL </label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="categoryImageUrl"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Category Image URL">
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Link One Image URL </label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="linkOneImageUrl"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Link One Image URL">
                                                </div>
                                            </div>
                                        </div>
                                     
                                        <div class="col-lg-12">
                                            <div class="row">
                                              
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Link Two Image URL</label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="linkTwoImageUrl"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Link Two Image URL">
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Detail Screen</label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="detailScreen" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Detail Screen">
                                                </div>
                                            </div>
                                        </div>
                                     
                                        <div class="col-lg-12">
                                            <div class="row">
                                              
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Primary Parent Category</label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <ng-select [items]="categories" plcaeholder="Primary Parent Category"
                                                    formControlName="primaryParentCategoryId" bindLabel="categoryName"
                                                    bindValue="productCategoryId" class="ng-select-width"></ng-select>
                                                </div>
                                            </div>
                                        </div>
                                        
                                       
                                       
                                    </div>
                                    <div class="main-submit-button" style="    margin-right: 24%;">
                                        <button type="submit" (click)="onSubmit()"
                                            class="btn btn-secondary submit-btn">{{editMode ?'Update':'Submit'}}</button>


                                    </div>
                                </form>
                            </ng-container>
                            <ng-container *ngIf="activeCategory==1 && editMode">
                                <form class="w-100">
                                    <div class="container row">
                                        <div class="col-lg-12">
                                            <div class="row">
                                              
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="">Category Image File</label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <input type="file" class="custom-file-input"
                                                            (change)="setImage($event)" id="inputGroupFile01">
                                                        <label class="custom-file-label" for="inputGroupFile01">Choose
                                                            file</label>
                                                </div>
                                                
                                            </div>
                                        </div>
                                     
                                        <div class="col-md-6">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="exampleRadios"
                                                    (click)="setCategoryImageType('category')" id="exampleRadio1">
                                                <label class="form-check-label" for="exampleRadio1">
                                                    Category Image URL
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="exampleRadios"
                                                    (click)="setCategoryImageType('linkOne')" id="exampleRadio2">
                                                <label class="form-check-label" for="exampleRadio2">
                                                    Link One Image URL
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="exampleRadios"
                                                    (click)="setCategoryImageType('linkTwo')" id="exampleRadios1"
                                                    value="option1">
                                                <label class="form-check-label" for="exampleRadios1">
                                                    Link Two Image URL
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="d-flex justify-content-end">
                                                <button class="btn btn-primary cursor-pointer ml-2"
                                                    (click)="uploadImage()">Upload
                                                    Image</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ng-container>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>