import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

import { Location } from '@angular/common';

import Swal from 'sweetalert2'
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AddEmployeePositionService } from '../add-employee-position/add-employee-position.service';
@Component({
  selector: 'app-detail-qualification',
  templateUrl: './detail-qualification.component.html',
  styleUrls: ['./detail-qualification.component.css']
})
export class DetailQualificationComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory=0;
  products3: any;
  paymentPartyGroup: any;
  createReview:FormGroup;
  createPrefItem:FormGroup;
  downloading: boolean;
  total = 0;
  activeCategoryMain=2;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  show: boolean;
  createQualification:FormGroup;
  PartyIdArray: any[]=[];
  VerifStatusIDArray: any[] = [];
  VerifStatusID: any;
  PartyQualTypeIdArray:any[] = [];
  PartyQualTypeId: any;
  PartyId: any;
  actualThroughDate: string;
  statusId: any;
  verifStatusId: any;
  partyId: any;
  partyQualTypeId: any;
  fromDate: any;
  qualificationDesc: any;
  title: any;
  thruDate: any;
  getEstimateFrom: string;
  getEstimateTo: string;
  employeeList: any;
  actualFromDate: string;
  FromDate: string;
  ThroughDate: string;
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly addEmployeePositionService: AddEmployeePositionService,
   
      private _location: Location,
      readonly addSkillService: AddSkillService,
    readonly activatedRoute: ActivatedRoute,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
   
    readonly router: Router){ 
      this.createQualification = this._FormBuilder.group({
        QualificationDesc: [''],
        Title: [''],
        StatusID: [''],
        VerifStatusID: [''],
        ThroughDate: [''],
        PartyId: [''],
        fromDate: [''],
        PartyQualTypeId: [''],
        Comments:['']
       
      
      });
    }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params.partyId;
      this.partyQualTypeId = params.partyQualTypeId;
      this.fromDate = params["fromDate"];
      this.qualificationDesc = params.qualificationDesc;
      this.title = params.title;
      this.statusId = params["statusId"];
      this.verifStatusId = params.verifStatusId;
      this.thruDate = params.thruDate;
      
    })
  
  this.show=true;

  this.getPartyId();
  this.getPartyQualType();
  this.getStatusVerifId();
   
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  edit(){
    this.FromDate = this.datePipe.transform(this.fromDate, "yyyy-MM-dd");
    this.ThroughDate = this.datePipe.transform(this.thruDate, "yyyy-MM-dd");

    setTimeout(() => {
      const formValue = this.createQualification;
    formValue.patchValue({
        QualificationDesc: this.qualificationDesc,
        Title: this.title,
        StatusID: this.statusId,
        VerifStatusID: this.verifStatusId,
        ThroughDate: this.ThroughDate,
        PartyId: this.partyId,
        fromDate: this.FromDate,
        PartyQualTypeId: this.partyQualTypeId ,
      

    })
    }, 2000);

  }

  

  updatePartyQual(){
    this.spinner.show();
    let fromActualdate = this.createQualification.get('fromDate').value;
    this.actualFromDate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    let fromThroughdate = this.createQualification.get('ThroughDate').value;
    this.actualThroughDate = this.datePipe.transform(fromThroughdate, "yyyy-MM-dd hh:mm:ss");
  
  const formdata={
    "_rowSubmit": "Y",
    "fromDate": this.actualFromDate,
    "partyId": this.createQualification.value.PartyId,
    "partyQualTypeId": this.createQualification.value.PartyQualTypeId,
    "qualificationDesc": this.createQualification.value.QualificationDesc,
    "statusId": this.createQualification.value.StatusID,
    "thruDate": this.actualThroughDate,
    "title":this.createQualification.value.Title,
    "verifStatusId":this.createQualification.value.VerifStatusID,

    
  }
  
    this.accountsService.updatePartyQual(formdata).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();


      }      }
      ,(err) => {
        for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }}
      )
      this.spinner.hide();
  }

  getPartyQualType() {
    this.spinner.show();
    this.accountsService.getPartyQualType().subscribe(res => {
      this.PartyQualTypeId = res.data;
      this.spinner.hide();
      for (const value of this.PartyQualTypeId) {
        this.PartyQualTypeIdArray.push({
          label: value.partyQualTypeId,
          value: value.partyQualTypeId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.PartyId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getStatusVerifId() {
    this.spinner.show();
    this.accountsService.getStatusVerifId().subscribe(res => {
      this.VerifStatusID = res.data;
      this.spinner.hide();
      for (const value of this.VerifStatusID) {
        this.VerifStatusIDArray.push({
          label: value.statusId,
          value: value.statusId
        })
      }
    })
  }

  
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }

}
