import { Component, OnInit, OnDestroy,ViewChild } from '@angular/core';
import{HeaderService} from '../../header/header.service';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { SortEvent } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { CustomerAgreementsService } from 'src/app/accounting/accounting-ar/customer-agreements/customer-agreements.service';
import { NewWarehouseShipmentService } from 'src/app/warehouse/new-warehouse-shipment/new-warehouse-shipment.service';

@Component({
  selector: 'app-accounting-document',
  templateUrl: './accounting-document.component.html',
  styleUrls: ['./accounting-document.component.css']
})
export class AccountingDocumentComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  products3: any;
  total=0;
  rowNumber=0;
  activeCategoryPay=1;
  activeCategoryA=1;
  activeCategoriess=3;
  activeCategoryInvoice=1;
  activeCategory=1;
  pageSize = 10;
  getMainOrg: any;
  show: boolean;
  rows = 50;
  createOrganization:FormGroup;
  orgId: any;
  orgIdArray: any[]=[];
  faInfoCircle = faInfoCircle;
  isShown = false; 
  activeCategoryMain=2;
  statusIds: any[];
  agreementType: string;
  invoiceTypes: any[];
  invoiceStatusArray = [
    {
      label: 'Un-Paid',
      value: 'INVOICE_IN_PROCESS'
    },
    {
      label: 'Paid',
      value: 'INVOICE_PAID'
    },

  ];
  invoiceDateArray = [
    {
      label: 'Past due date',
      value: 'past due date'
    },
    {
      label: 'Within Due date',
      value: 'within due date'
    },
  ];
  opportunityIdArray = [
    {
      label: 'Contains',
      value: 'Contains'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    },
    {
      label: 'Begins With',
      value: 'Begins With'
    },
  ];
  greaterArray = [
    {
      label: 'Equals',
      value: 'equal'
    },
    {
      label: 'Same Day',
      value: 'Same Day'
    },
    {
      label: 'Greater Than From Day Start',
      value: 'Greater Than From Day Start'
    },
    {
      label: 'Greater Than',
      value: 'Greater Than'
    }
  ];
  smallerArray = [
    {
      label: 'Less Than',
      value: 'Less Than'
    },
    {
      label: 'Up To Day',
      value: 'Up To Day'
    },
    {
      label: 'Up Through Day',
      value: 'Up Through Day'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    }
  ];
  
  searchInvoiceForm: FormGroup;
  invoices: any[];
  partyIds: any[];
  invoiceType: string;
  agreementTypeValue: string;
  advanceSearch: { description: string; fromPartyId: string; invoiceDateFrom: string; invoiceDateTo: string; invoiceId: string; invoiceType: string; statusId: string; toPartyId: string; };
  getPurchaseInvoicesData: any;
  filterInvoiceDateForm: FormGroup;
  filterInvoiceStatusForm: FormGroup;
  CustomerAgreements: FormGroup;
  agreementList: any;
  PersonsAndPartyGroupsListArray: any[]=[];
  productListArray: any[]=[];
  AgreementTypeListArray: any[]=[];
  fromDateType: { label: string; value: string; }[];
  toDateType: { label: string; value: string; }[];
  constructor(
    readonly accountsService: AccountsService,
    readonly  HeaderService: HeaderService,  
    readonly CustomerAgreementsService: CustomerAgreementsService,
    readonly _FormBuilder: FormBuilder,
    readonly _AccountingApService: AccountingApService,
    readonly _ToastrService: ToastrService,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly  _Router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _GlobalResourceService: GlobalResourceService,
    private _location: Location,
    readonly router: Router,
    ) {

      this.filterInvoiceDateForm = this._FormBuilder.group({
        invoiceDate: [''],
      })
      this.filterInvoiceStatusForm = this._FormBuilder.group({
        invoiceStatus: [''],
      })
      this.filterInvoiceStatusForm = this._FormBuilder.group({
        description: [''],
        descriptionSearchType: ['Contains'],
        fromPartyId: [''],
        invoiceDateFrom: [''],
        invoiceDateFromSearchType: ['equal'],
        invoiceDateTo: [''],
        invoiceDateToSearchType: ['oplessthen'],
        invoiceId: [''],
        invoiceIdSearchType: ['Contains'],
        invoiceType: ['PURCHASE_INVOICE', [Validators.required]],
        statusId: [''],
        toPartyId: ['']
      });
      this.searchInvoiceForm = this._FormBuilder.group({
        description: [''],
        descriptionSearchType: ['Contains'],
        fromPartyId: [''],
        invoiceDateFrom: [''],
        invoiceDateFromSearchType: ['equal'],
        invoiceDateTo: [''],
        invoiceDateToSearchType: ['oplessthen'],
        invoiceId: [''],
        invoiceIdSearchType: ['Contains'],
        invoiceType: ['PURCHASE_INVOICE', [Validators.required]],
        statusId: [''],
        toPartyId: ['']
      });
      this.advanceSearch = {
  
        description: "",
        fromPartyId: "",
        invoiceDateFrom: "",
        invoiceDateTo: "",
        invoiceId: "",
        invoiceType:"",
        statusId: "",
        toPartyId:""
  
      };
      this.invoiceTypes = [];
      this.statusIds = [];
      this.invoices = [];
      this.partyIds = [];
      this.invoiceType = 'payable';

      this.CustomerAgreements = this._FormBuilder.group({
        agreementId: [''],
        agreementIdSearchType: ['Contains'],
        agreementTypeId: [''],
        fromDateFrom: [''],
        fromDateFromSearchType: ['Greater Than'],
        fromDateTo: [''],
        fromDateToSearchType: ['Less Than'],
        partyIdFrom: [''],
        partyIdTo: [''],
        productId: [''],
      });

     
      this.fromDateType = [
        {
          label: 'Equals',
          value: 'Equals'
        },
        {
          label: 'Greater Than',
          value: 'Greater Than'
        },
        {
          label: 'Greater Than Equals',
          value: ' Greater Than Equal To'
        },
  
      ];
      this.toDateType = [
        {
          label: 'Less Than',
          value: 'LessThan'
        },
        {
          label: 'Is Empty',
          value: 'Is Empty'
        },
        {
          label: 'Less Than Equals',
          value: ' Less Than Equal To'
        },
  
      ];

     }
 

  ngOnInit(): void {
   
    this.products3 = this.accountsService.customerDetails()

    this.agreementTypeValue = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    this.invoiceType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    this.getInvoiceTypeAndStatus();
    this.getPartyIds();
    if (window.location.pathname.includes('receiveable')) {
      this.searchInvoiceForm.patchValue({
        invoiceType: 'SALES_INVOICE'
      });
    }
    if(this.invoiceType=='payable'){
     this.searchInvoicePay();
    }
    else{
    this.searchInvoice();
    }
    this.findPurchaseInvoices();
    this.getGlAccountListData();
    this.getGlAccountInitialData();
    this.getPersonsAndPartyGroups();
    this.getProducts();
    this.submit();
    this.getAgreementTypeList();
   
  }

  mainPage()
  {
    this._Router.navigate(['/financial/main-accounting']);
  }
  accountingPage()
  {
    this._Router.navigate(['/financial/Accounting']);
  }
  invoicePage()
  {
    this._Router.navigate(['/financial/Invoices']);
  }
  paymentPage()
  {
    this._Router.navigate(['/financial/Payments']);
  }
  paymentGrpPage()
  {
    this._Router.navigate(['/financial/Payment-group']);
  }
  billingAccountPage()
  {
    this._Router.navigate(['/financial/Billing-Account']);
  }
  agreementPage()
  {
    this._Router.navigate(['/financial/Agreements']);
  }
  commisionReportPage()
  {
    this._Router.navigate(['/financial/Commission-Report']);
  }
  mainAgreementPage()
  {
    this._Router.navigate(['/financial/main-agreements']);
  } 
  mainBillingAccountPage()
  {
    this._Router.navigate(['/financial/main-Billing-Accounts']);
  }
  mainFixedAssetPage()
  {
    this._Router.navigate(['/financial/main-Fixed-Assets']);
  } 
  
  mainInvoicePage()
  {
    this._Router.navigate(['/financial/main-Invoices']);
  }
   mainPaymentPage()
  {
    this._Router.navigate(['/financial/main-Payment']);
  }

  cancelAgreement(agreementId: string): void {
    this.spinner.show();
    this.CustomerAgreementsService.cancelAgreement(agreementId)
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Cancelled');
          this.spinner.hide();
          this.submit();
        }
      });
    
  }
  summary(agreementID, toPartyId: string) {
    window.localStorage.setItem('toPartyId', toPartyId);
    this.router.navigate([`/financial/payable/customer-agreeement/summary-customer-agreeement`], { queryParams: { agreementID } });
  }
  getAgreementTypeList() {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementTypeList().subscribe((responce) => {

      this.AgreementTypeListArray = responce.data.map(value => {
        return {
          label: value.description,
          value: value.agreementTypeId
        };
      });
    });
    this.spinner.hide();
  }
  getPersonsAndPartyGroups() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getPersonsAndPartyGroups().subscribe((responce) => {
      this.PersonsAndPartyGroupsListArray = responce.data.PersonsGroups.map(value => {
        return {
          label: value.partyId,
          value: value.partyId
        };
      });
    });
    this.spinner.hide();
  }
  restForm(){
    this.CustomerAgreements.reset();
    this.CustomerAgreements.reset();
  }
  getProducts() {
    this.spinner.show();
    const requestData = {
      internalName: '',
      internalNameSearchType: 'Contains',
      productId: '',
      productIdSearchType: 'Contains'
    };
    this.CustomerAgreementsService.getProducts(requestData).subscribe((responce) => {

      this.productListArray = responce.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
    });
    this.spinner.hide();
  }
  submit(): void {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementList({
      agreementDate: this.CustomerAgreements.value.agreementDate ? moment(this.CustomerAgreements.value.agreementDate).format('YYYY-MM-DD HH:mm:ss') : '',
      agreementId: this.CustomerAgreements.value.agreementId?this.CustomerAgreements.value.agreementId:'',
      agreementIdSearchType: this.CustomerAgreements.value.agreementIdSearchType?this.CustomerAgreements.value.agreementIdSearchType:'',
      agreementTypeId: this.CustomerAgreements.value.agreementTypeId?this.CustomerAgreements.value.agreementTypeId:'',
      fromDateFrom: this.CustomerAgreements.value.fromDateFrom ? moment(this.CustomerAgreements.value.fromDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
      fromDateFromSearchType: this.CustomerAgreements.value.fromDateFromSearchType?this.CustomerAgreements.value.fromDateFromSearchType:'',
      fromDateTo: this.CustomerAgreements.value.fromDateTo ? moment(this.CustomerAgreements.value.fromDateTo).format('YYYY-MM-DD HH:mm:ss') : '',
      fromDateToSearchType: this.CustomerAgreements.value.fromDateToSearchType?this.CustomerAgreements.value.fromDateToSearchType:'',
      partyIdFrom: this.CustomerAgreements.value.partyIdFrom?this.CustomerAgreements.value.partyIdFrom:'',
      partyIdTo: this.CustomerAgreements.value.partyIdTo?this.CustomerAgreements.value.partyIdTo:'',
      productId: this.CustomerAgreements.value.productId? this.CustomerAgreements.value.productId:''
    }).subscribe((res: any) => {
     
      if (res.success) {
       
        this.agreementList = res.data;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      }
    });
   
  }
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/financial/main']);
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getOrganizationDropdown().subscribe((res: any) => {
      this.spinner.hide();
      this.orgId = res.data.partyRoleAndPartyDetail;
     
      for (const value of this.orgId) {
        this.orgIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
     
    })
   
  }
 
  onSubmit() {
    this.show=true;
    this.orgId=this.createOrganization.value.organizationPartyId;
    if(this.orgId == ""){
      this._ToastrService.error("Organization Party Id is blank");
      this.show=false;
    }
    else{
      this.closebutton.nativeElement.click();
      this.show=false;
      this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId: this.orgId,show:this.show } });

    }
  

  }
  AccountingCompany(){
    this._Router.navigate(['/financial/create-organization-gl-setting']);
  }
  AccountingInfo(){
    this._Router.navigate(['/financial/organization-gl-setting']);
  }
  glOrganizationSummary(id){
    this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId: id } });
  }
  glOrganizationAccountingSummary(id){
    this._Router.navigate(['/financial/detail-accounting-summary'], { queryParams: { partyId: id } });
  }
  getGlAccountListData() {
    this.spinner.show();
    this.accountsService.getOrganizationMain().subscribe((res: any) => {
      this.spinner.hide();
      this.getMainOrg = res.data;
    })
   
  }
  
  invoiceFilter(event){
    this.searchInvoiceForm.value.statusId=event.value;
    this.spinner.show();
    if (this.searchInvoiceForm.valid) {
      this._AccountingApService.searchInvoice({ pageNo: 1, pageSize: 100 }, {
        ... this.searchInvoiceForm.value, ...{
          invoiceDateFrom: this.searchInvoiceForm.value.invoiceDateFrom ? moment(this.searchInvoiceForm.value.invoiceDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
          invoiceDateTo: this.searchInvoiceForm.value.invoiceDateTo ? moment(this.searchInvoiceForm.value.invoiceDateTo).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          this.spinner.hide();
          this.invoices = data.data;
        })
        .catch(err => {
          this.spinner.hide();
          if (err.errors) {
            err.errors.forEach(value => {
              this._ToastrService.success(value.fieldName);
            });
          }
        });

    }
  }


  mainReceive(){
    this.router.navigate(["/financial/main-receivable"])
  }
  main(){
    this.router.navigate(["/financial/main-payable"])
  }
  enterBills(){
    this.router.navigate(["/financial/payable/invoices"])
  }
  payableMakePayment(){
    this.router.navigate(["/financial/payable/payment-payables"])
  }
  payableMakeGroupPayment(){
    this.router.navigate(["/financial/payable/payment-group"])
  }
  salesInvoice(){
    this.router.navigate(["/financial/receiveable/invoices"])
  }
  receivePayment(){
    this.router.navigate(["/financial/receiveable/payments"])
  }
  receiveGroupPayments(){
    this.router.navigate(["/financial/receiveable/payment-group"])
  }
  invoiceTypeFunction(){
    this.isShown = !this.isShown;
  }
  changeactiveCategoryFunctionPurchae(){
    this.router.navigate([`/financial/payable/invoices`]);
  }
  changeactiveCategoryFunctionCredit(){
    this.router.navigate([`/financial/payable/credit-memo`]);
  }
  changeactiveCommission(){
    this.router.navigate([`/financial/payable/commission-invoice`]);
  }
  getInvoiceTypeAndStatus(): void {
    this.spinner.show();
    this._AccountingApService.getInvoiceTypeAndStatus()
      .then(data => {
        this.spinner.hide();
        this.statusIds = data.data.invoiceStatus.map(value => {
          return {
            label: value.description,
            value: value.statusId
          };
        });
        this.invoiceTypes = data.data.invoiceType.map(value => {
          return {
            label: value.description,
            value: value.invoiceTypeId
          };
        });
      });
     
  }



  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.spinner.hide();
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });
     
  }
  findPurchaseInvoices(): void {
    this.spinner.show();
    const req = {
      "billingAccountId": "",
      "description": this.searchInvoiceForm.value.description?this.searchInvoiceForm.value.description:'',
      "invoiceDate": this.searchInvoiceForm.value.invoiceDateFrom?this.searchInvoiceForm.value.invoiceDateFrom:'',
      "invoiceId": this.searchInvoiceForm.value.invoiceId?this.searchInvoiceForm.value.invoiceId:'',
      "invoiceTypeId": this.searchInvoiceForm.value.invoiceType?this.searchInvoiceForm.value.invoiceType:'',
      "partyId": "",
      "partyIdFrom": this.searchInvoiceForm.value.fromPartyId?this.searchInvoiceForm.value.fromPartyId:'',
      "referenceNumber": "",
      "statusId": this.searchInvoiceForm.value.statusId?this.searchInvoiceForm.value.statusId:'',
    }
    this.accountsService.findPurchaseInvoices(req).subscribe(res => {
      if (res.success) {
        this.spinner.hide();
        this.getPurchaseInvoicesData = res.data;
      }
    });

  }

  searchInvoicePay(): void {
    this.spinner.show();
    if (this.searchInvoiceForm.valid) {
      this._AccountingApService.searchInvoice({ pageNo: 1, pageSize: 100 }, {
        ... this.searchInvoiceForm.value, ...{
          invoiceDateFrom: this.searchInvoiceForm.value.invoiceDateFrom ? moment(this.searchInvoiceForm.value.invoiceDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
          invoiceDateTo: this.searchInvoiceForm.value.invoiceDateTo ? moment(this.searchInvoiceForm.value.invoiceDateTo).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          this.spinner.hide();
          this.invoices = data.data;
        })
        .catch(err => {
          this.spinner.hide();
          if (err.errors) {
            err.errors.forEach(value => {
              this._ToastrService.success(value.fieldName);
            });
          }
        });

    }
   
  }
  resetPerInvoice(){
    this.searchInvoiceForm.reset();
  }

  reset() {

    this.searchInvoiceForm.controls['description'].reset();
    this.searchInvoiceForm.controls['fromPartyId'].reset();
    this.searchInvoiceForm.controls['invoiceDateFrom'].reset();
    this.searchInvoiceForm.controls['invoiceDateTo'].reset();
    this.searchInvoiceForm.controls['invoiceId'].reset();
    this.searchInvoiceForm.controls['invoiceType'].reset();
    this.searchInvoiceForm.controls['statusId'].reset();
    this.searchInvoiceForm.controls['toPartyId'].reset();

    this.searchInvoice();

  }
 
  searchInvoice(): void {
    this.spinner.show();
    
 if (this.searchInvoiceForm.valid) {
      this._AccountingApService.searchInvoice({ pageNo: 1, pageSize: 100 }, {
        ... this.searchInvoiceForm.value, ...{
          invoiceDateFrom: this.searchInvoiceForm.value.invoiceDateFrom ? moment(this.searchInvoiceForm.value.invoiceDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
          invoiceDateTo: this.searchInvoiceForm.value.invoiceDateTo ? moment(this.searchInvoiceForm.value.invoiceDateTo).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          this.spinner.hide();
          this.invoices = data.data;
        })
        .catch(err => {
          this.spinner.hide();
          if (err.errors) {
            err.errors.forEach(value => {
              this._ToastrService.success(value.fieldName);
            });
          }
        });

    }
   
  }

  changeActiveCategoryInvoice(tab)
  {
    this.activeCategoryInvoice =tab;
  }
  changeActiveCategoryFunctions(tab)
  {
    this.activeCategoriess=tab;
  }
  changeActiveCategory(tab)
  {
    this.activeCategory=tab;
  }
  changeActiveCategoryPayment(tab)
  {
    this.activeCategoryPay =tab;
  }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }

}