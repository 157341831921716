import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-credit-card-processing-import',
  templateUrl: './credit-card-processing-import.component.html',
  styleUrls: ['./credit-card-processing-import.component.css']
})
export class CreditCardProcessingImportComponent implements OnInit {

  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  stateArray: any[]=[];
  state: any;
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  userLogin: any;
  findPartyArray: any[]=[];
  identification: any;
  identificationArray: any[]=[];
  fileData: File;
  constructor(readonly router: Router) { }

  ngOnInit(): void {
  }

  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    
  }
  prevPage(){
    this.router.navigate(['/users/costing-method-import']);
  }
  nextPage(){
    this.router.navigate(['/users/tax-component-import']);
  }

}
