import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-create-global-cost',
  templateUrl: './create-global-cost.component.html',
  styleUrls: ['./create-global-cost.component.css']
})
export class CreateGlobalCostComponent implements OnInit {
  show: boolean;
  glAccountType: any;
  glAccountTypeArray: any[] = [];
  costCustomMethodID: any;
  costCustomMethodIDArray: any[] = [];
  uom: any;
  uomArray: any[] = [];
  createCost: FormGroup;
  activeCategory = 2;
  currencyArray: any[] = [];
  costComponentCalcId: any;
  constructor(readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
   ) {
    this.createCost = this._FormBuilder.group({
      costComponentCalcId: [''],
      costCustomMethodId: [''],
      costGlAccountTypeId: ['',[Validators.required]],
      currencyUomId: ['',[Validators.required]],
      description: [''],
      fixedCost: [''],
      offsettingGlAccountTypeId: [''],
      perMilliSecond: [''],
      variableCost: ['']




    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.costComponentCalcId = params["costComponentCalcId"];
    
    });
    if(this.costComponentCalcId){
      this.show=true;
      this.getGlCostById();
 
    }
    else{
      this.show=false;
    }
    this.getInitialDataCost();
    this.getCurrency();
  }
  cancelSubmit() {
    this.router.navigate(["financial/global-gl-cost"])
  }
  
  getGlCostById() {
    this.spinner.show();
    this.accountsService.getGlCostById(this.costComponentCalcId).subscribe(res => {
      this.spinner.hide();
      setTimeout(() => {
       
      
        const formValue = this.createCost;
        formValue.patchValue({
          costComponentCalcId:res.data.costComponentCalcId,
          costCustomMethodId: res.data.costCustomMethodId,
          costGlAccountTypeId: res.data.costGlAccountTypeId,
          currencyUomId:res.data.currencyUomId,
          description:res.data.description,
          fixedCost:res.data.fixedCost,
          offsettingGlAccountTypeId: res.data.offsettingGlAccountTypeId,
          perMilliSecond: res.data.perMilliSecond,
          variableCost: res.data.variableCost,
  
  
        })
      
      }, 3000);
     
    })
    
  }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  getInitialDataCost() {
    this.spinner.show();
    this.accountsService.getInitialDataCost().subscribe(res => {
      this.glAccountType = res.data.glAccountType;
      this.costCustomMethodID = res.data.costCustomMethodID;
      this.uom = res.data.uom;
      this.spinner.hide();
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }
      for (const value of this.costCustomMethodID) {
        this.costCustomMethodIDArray.push({
          label: value.description,
          value: value.customMethodId
        })
      }
      for (const value of this.uom) {
        this.uomArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "costComponentCalcId":"",
      "costCustomMethodId": this.createCost.value.costCustomMethodId,
      "costGlAccountTypeId": this.createCost.value.costGlAccountTypeId,
      "currencyUomId":this.createCost.value.currencyUomId,
      "description": this.createCost.value.description,
      "fixedCost": this.createCost.value.fixedCost,
      "offsettingGlAccountTypeId": this.createCost.value.offsettingGlAccountTypeId,
      "perMilliSecond": this.createCost.value.perMilliSecond,
      "variableCost": this.createCost.value.variableCost,
    }
    this.accountsService.postGlCost(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/global-gl-cost"]);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "costComponentCalcId":this.costComponentCalcId,
      "costCustomMethodId": this.createCost.value.costCustomMethodId,
      "costGlAccountTypeId": this.createCost.value.costGlAccountTypeId,
      "currencyUomId":this.createCost.value.currencyUomId,
      "description": this.createCost.value.description,
      "fixedCost": this.createCost.value.fixedCost,
      "offsettingGlAccountTypeId": this.createCost.value.offsettingGlAccountTypeId,
      "perMilliSecond": this.createCost.value.perMilliSecond,
      "variableCost": this.createCost.value.variableCost,
    }
    this.accountsService.updateGlCost(requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Updated Successfully");
        this.router.navigate(["financial/global-gl-cost"]);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
