import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { EmployeeLeaveService } from "./employee-leave.service";
import { DatePipe } from '@angular/common'
import { AddLeaveService } from '../add-new-leaves/add-new-leaves-service';
import { AddLeaveEmpService } from '../add-new-leaves/add-new-leaves.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'

import { Location } from '@angular/common';

@Component({
  selector: 'app-employee-leave',
  templateUrl: './employee-leave.component.html',
  styleUrls: ['./employee-leave.component.css'],
  providers: [DatePipe]
})
export class EmployeeLeaveComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  products3: any;
  activeCategory=2;
  activeCategoryMain=1;
  leaveTypeId: any;
  leaveId: any = [];
  leaveReason: any;
  leaveReasonArray: any = [];
  partyIdArray: any = [];
  partyId: any;
  leaveTypeIds: any;
  fromDate: any;
  show = false;
  leaves: {};
  updateefromdate: string;
  updateetodate: string;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  public leaveSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize
  }
  employeeLeave: any;

  public array: any = [];
  advanceSearch: {
    approverPartyId: string,
    fromDateFrom: string,
    fromDateFromSearchType: string,
    fromDateTo: string,
    fromDateToSearchType: string,
    leaveReasonTypeId: string,
    leaveStatusID: [
      string
    ],
    leaveTypeId: string,
    partyId: string,
    throughDateFrom: string,
    throughDateTo: string,
    thruDateFromSearchType: string,
    thruDateToSearchType: string
  }
  dateIdArray: { label: string; value: string; }[];
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  leaveStatusReoort: any;
  leaveStatusReoortArray: any = [];
  arrayStatus: any = [];
  isApproval: any;
  constructor(readonly accountsService: AccountsService,
    readonly router: Router,
    public datePipe: DatePipe,
    readonly employeeLeaveService: EmployeeLeaveService,
    public addLeaveService: AddLeaveService,
      private _location: Location,
      readonly toastr: ToastrService,
    readonly spinner:NgxSpinnerService,
    readonly addLeaveEmpService: AddLeaveEmpService,) {
      this.isApproval = false;
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.dateIdArray = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },

      {
        label: 'Greater Than',
        value: 'GreaterThan'
      },
      {
        label: 'Greater Than Equal To',
        value: 'GreaterThanEqualTo'
      },
      {
        label: 'Less Than Equal To',
        value: 'LessThanEqualTo'
      },
      {
        label: 'Is Empty',
        value: 'IsEmpty'
      }

    ];
    this.advanceSearch = {
      approverPartyId: "",
      fromDateFrom: "",
      fromDateFromSearchType: "Equals",
      fromDateTo: "",
      fromDateToSearchType: "Equals",
      leaveReasonTypeId: "",
      leaveStatusID: [
        ""
      ],
      leaveTypeId: "",
      partyId: "",
      throughDateFrom: "",
      throughDateTo: "",
      thruDateFromSearchType: "Equals",
      thruDateToSearchType: "Equals"
    }
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.addLeaveService.add_leave_form = null;
    this.addLeaveService.addLeaveForm(this.addLeaveService.add_leave_form);

    this.getLeaveReasonId();
    this.getPartyId();
    this.leavereason();
    this.leaveStatus();
    this.getLeaveList();

    this.getLeaveReasonIdNew();
    this.getPartyIdNew();
    this.leavereasonNew();
    this.leaveStatusNew();


  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  leaveStatusNew() {
    this.spinner.show();
    this.employeeLeaveService.getLeaveStatus().subscribe(res => {
      this.leaveStatusReoort = res.data;
      this.spinner.hide();
      for (const value of this.leaveStatusReoort) {
        this.leaveStatusReoortArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
   
  }
  getLeaveReasonIdNew() {
    this.spinner.show();
    this.addLeaveEmpService.getLeaveReasonType().subscribe(res => {
      this.leaveTypeIds = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.leaveTypeIds) {
        this.leaveId.push({
          label: value.description,
          value: value.leaveTypeId
        })
      }
    })
 
  }
  leavereasonNew() {
    this.spinner.show();
    this.addLeaveEmpService.getLeaveReason().subscribe(res => {
      this.leaveReason = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.leaveReason) {
        this.leaveReasonArray.push({
          label: value.description,
          value: value.emplLeaveReasonTypeId
        })
      }
    })
 
  }

  getPartyIdNew() {
    this.spinner.show();
    this.addLeaveEmpService.getPartyId().subscribe(res => {
      this.partyId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  onSubmit() {
    this.spinner.show();
    const fromdate = this.addLeaveService.add_leave_form.get('fromDate').value;
    this.updateefromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const todate = this.addLeaveService.add_leave_form.get('thruDate').value;
    this.updateetodate = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");

    const formDate = {
      approverPartyId: this.addLeaveService.add_leave_form.value.approverPartyId,
      description: this.addLeaveService.add_leave_form.value.description,
      emplLeaveReasonTypeId: this.addLeaveService.add_leave_form.value.emplLeaveReasonTypeId,
      fromDate: this.updateefromdate,
      leaveTypeId: this.addLeaveService.add_leave_form.value.leaveTypeId,
      partyId: this.addLeaveService.add_leave_form.value.partyId,
      thruDate: this.updateetodate,
      leaveStatus: this.addLeaveService.add_leave_form.value.leaveStatus,
      
    }
    this.addLeaveEmpService.AddLeave(formDate).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.addLeaveService.add_leave_form.reset();
        this.getLeaveList();
        this.closebutton.nativeElement.click();
        this.spinner.hide();
        if (this.isApproval) {
          this.router.navigate(['/hr/employee-leave/leave-approval']);
        } else {
          this.router.navigate(['/hr/employee-leave']);
        }
      }
      else if (res.success == false) {
        this.spinner.hide();
        this.toastr.error(res.message.debugMessage);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
      
    })
   
   
  }

  empleave() {
    this.router.navigate(["/hr/employee-leave"])
  }
  leaveapproval() {
    this.router.navigate(["/hr/employee-leave/leave-approval"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  getLeaveReasonId() {
    this.spinner.show();
    this.addLeaveEmpService.getLeaveReasonType().subscribe(res => {
      this.leaveTypeIds = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.leaveTypeIds) {
        this.leaveId.push({
          label: value.description,
          value: value.leaveTypeId
        })
      }
    })
   
  }
  leavereason() {
    this.spinner.show();
    this.addLeaveEmpService.getLeaveReason().subscribe(res => {
      this.leaveReason = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.leaveReason) {
        this.leaveReasonArray.push({
          label: value.description,
          value: value.emplLeaveReasonTypeId
        })
      }
    })
   
  }

  getPartyId() {
    this.spinner.show();
    this.addLeaveEmpService.getPartyId().subscribe(res => {
      this.partyId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getLeaveList() {
    this.spinner.show();
    const searchEmployrrLeaveForm =
    {
      approverPartyId: "",
      fromDateFrom: "",
      fromDateFromSearchType: "Contains",
      fromDateTo: "",
      fromDateToSearchType: "Contains",
      leaveReasonTypeId: "",
      leaveStatusID: [
        ""
      ],
      leaveTypeId: "",
      partyId: "",
      throughDateFrom: "",
      throughDateTo: "",
      thruDateFromSearchType: "Contains",
      thruDateToSearchType: "Contains"
    }

    this.employeeLeaveService.employeeLeaveList(this.leaveSize, searchEmployrrLeaveForm).subscribe(res => {
      this.employeeLeave = res.data;
      this.spinner.hide();
    })
   
  }
  leaveStatus() {
    this.spinner.show();
    this.employeeLeaveService.getLeaveStatus().subscribe(res => {
      this.leaveStatusReoort = res.data;
      this.spinner.hide();
      for (const value of this.leaveStatusReoort) {
        this.leaveStatusReoortArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
   
  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    const searchEmployrrLeaveForm =
    {
      approverPartyId: this.advanceSearch.approverPartyId ? this.advanceSearch.approverPartyId : '',
      fromDateFrom: this.datePipe.transform(this.advanceSearch.fromDateFrom, "yyyy-MM-dd HH:mm:ss") ? this.datePipe.transform(this.advanceSearch.fromDateFrom, "yyyy-MM-dd HH:mm:ss") : '',
      fromDateFromSearchType: this.advanceSearch.fromDateFromSearchType ? this.advanceSearch.fromDateFromSearchType : '',
      fromDateTo: this.datePipe.transform(this.advanceSearch.fromDateTo, "yyyy-MM-dd HH:mm:ss") ? this.datePipe.transform(this.advanceSearch.fromDateTo, "yyyy-MM-dd HH:mm:ss") : '',
      fromDateToSearchType: this.advanceSearch.fromDateToSearchType ? this.advanceSearch.fromDateToSearchType : '',
      leaveReasonTypeId: this.advanceSearch.leaveReasonTypeId ? this.advanceSearch.leaveReasonTypeId : '',
      leaveStatusID: this.arrayStatus.length ? this.arrayStatus : [''],
      leaveTypeId: this.advanceSearch.leaveTypeId ? this.advanceSearch.leaveTypeId : '',
      partyId: this.advanceSearch.partyId ? this.advanceSearch.partyId : '',
      throughDateFrom: this.datePipe.transform(this.advanceSearch.throughDateFrom, "yyyy-MM-dd HH:mm:ss") ? this.datePipe.transform(this.advanceSearch.throughDateFrom, "yyyy-MM-dd HH:mm:ss") : '',
      throughDateTo: this.datePipe.transform(this.advanceSearch.throughDateTo, "yyyy-MM-dd HH:mm:ss") ? this.datePipe.transform(this.advanceSearch.throughDateTo, "yyyy-MM-dd HH:mm:ss") : '',
      thruDateFromSearchType: this.advanceSearch.thruDateFromSearchType ? this.advanceSearch.thruDateFromSearchType : '',
      thruDateToSearchType: this.advanceSearch.thruDateToSearchType ? this.advanceSearch.thruDateToSearchType : ''
    }

    this.employeeLeaveService.employeeLeaveList(this.leaveSize, searchEmployrrLeaveForm).subscribe(res => {
      this.employeeLeave = res.data;
      this.spinner.hide();
    })
   
  }


  onDelete(partyId, leaveTypeId, fromDate) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.employeeLeaveService.deleteLeave(partyId, leaveTypeId, fromDate).subscribe(res => {
          this.getLeaveList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  // detailPage(id) {
  //   this.router.navigate(["/hr/employee-leave/leave-summary"], { queryParams: { leaveId: id } })
  // }
  detailPage(epartyId, eleaveTypeId, efromDate, ethruDate, eAppId, eDescription, eReasonid) {
    this.router.navigate(["/hr/employee-leave/leave-summary"], { queryParams: { partyId: epartyId , leaveTypeId: eleaveTypeId, fromDate: efromDate, thruDate: ethruDate, AppId: eAppId, Description: eDescription, Reasonid: eReasonid, approval: false } })
  }
  onUpdate(epartyId, eleaveTypeId, efromDate, ethruDate, eAppId, eDescription, eReasonid) {


    this.router.navigate(['/hr/employee-leave/add-new-leaves'], { queryParams: { partyId: epartyId, leaveTypeId: eleaveTypeId, fromDate: efromDate, thruDate: ethruDate, AppId: eAppId, Description: eDescription, Reasonid: eReasonid, approval: false } })
  }

  reset() {
    this.spinner.show();
    this.arrayStatus = []
    this.advanceSearch.approverPartyId = "";
    this.advanceSearch.fromDateFrom = "";
    this.advanceSearch.fromDateTo = "";
    this.advanceSearch.leaveReasonTypeId = "";
    this.advanceSearch.leaveStatusID = [""];
    this.advanceSearch.leaveTypeId = "";
    this.advanceSearch.partyId = "";
    this.advanceSearch.throughDateFrom = "";
    this.advanceSearch.throughDateTo = "";
    const searchEmployrrLeaveForm =
    {
      approverPartyId: "", fromDateFrom: "", fromDateFromSearchType: "Contains", fromDateTo: "",
      fromDateToSearchType: "Contains",leaveReasonTypeId: "",
      leaveStatusID: [
        ""
      ],
      leaveTypeId: "",partyId: "",throughDateFrom: "",throughDateTo: "",thruDateFromSearchType: "Contains",
      thruDateToSearchType: "Contains"
    }

    this.employeeLeaveService.employeeLeaveList(this.leaveSize, searchEmployrrLeaveForm).subscribe(res => {
      this.employeeLeave = res.data;
      this.spinner.hide();
    }) }
  onChange(value) {
    this.arrayStatus = []
    this.arrayStatus.push(value);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
