<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Vendor Id  of {{ this.partyId}} </span>
               <span>
                <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>

                </span> 
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                </div>
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px;">
                        <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                            General
                            
                            </a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                Contact</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                                Address</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                                    Financial</a>
                            </li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                                Contract</a>
                            </li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategoryFunction(6)"><a>
                                    Documents</a>
                            </li>

                                    <span class="mt-1" style="margin-left:auto !important;">
                                    <button type="submit" style="margin: 0 !important;"
                                     class="btn btn-secondary submit-btn ml-4" 
                                     data-toggle="modal" data-target="#exampleModalCenter">
                                     Update</button>

                                    </span>


                       
                    </ul>
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">
                                            <div *ngIf="activeCategory==1">
                                              
                                                  
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="color-black container">
                                                            <div class="row" *ngIf="vendor">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">Vendor ID</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.partyId}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">
                                                                            Name</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{vendor.name}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">Manifest Company Name</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{vendor.manifestCompanyName}}</span>
                                                                     
                                                                    </div>
                                    
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">Manifest Company Title</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{vendor.manifestCompanyTitle}}</span>
                                                                    </div>
                                    
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">Manifest Logo Url</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{vendor.manifestLogoUrl}}</span>
                                                                    </div>
                                    
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">
                                                                            Manifest Policies</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{vendor.manifestPolicies}}</span>
                                                                    </div>
    
    
    
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">
                                                                            Vendor Type</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{vendor.typeVendor}}</span>
                                                                    </div>
                                                                   
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">
                                                                            Vendor Name
                                                                        </p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{vendor.vendorName}}</span>
                                                                    </div>
                                                                    
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">
                                                                            Vendor Email</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{vendor.VendorEmail}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">
                                                                            Company Website
                                                                        </p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{vendor.CompanyWebsite}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">
                                                                            Vendor is Active</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{vendor.isActive}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">
                                                                            Date Created</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{vendor.createdStamp | date:"yyyy-MM-dd"}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">
                                                                            Vendor Forms</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{vendor.VendorForms}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">
                                                                            Map</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{vendor.Map}}</span>
                                                                    </div>
    
                                                                    
                                                                   
                                                                    <div class="article">
                                                                        <p class="col-lg-5 col-12">
                                                                            Individual</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{vendor.individual}}</span>
                                                                    </div>
                                                                   
                                                                  
                                                                   
                                                                    
                                                                  </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                               
                                            </div>
                                            <div *ngIf="activeCategory==2">
                                              
                                                  
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="color-black container">
                                                        <div class="row">
                                                            <div class="article-container">
                          

                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Vendor Phone</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{vendor.VendorPhone}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Vendor Fax</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{vendor.VendorFax}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Primary Fax Area Code</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{InformationSupplier.primaryFaxAreaCode}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Primary Fax Country Code</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{InformationSupplier.primaryFaxCountryCode}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Primary Fax Extension</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{InformationSupplier.primaryFaxExtension}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Primary Fax Number</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{InformationSupplier.primaryFaxNumber}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Primary Phone Area Code</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{InformationSupplier.primaryPhoneAreaCode}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Primary Phone Country Code</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{InformationSupplier.primaryPhoneCountryCode}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Primary Phone Extension</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{InformationSupplier.primaryPhoneExtension}}</span>
                                                                </div>

                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        Primary Phone Number</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{InformationSupplier.primaryPhoneNumber}}</span>
                                                                </div>
                                                               
                                                               
                                                              
                                                               
                                                              
                                                               
                                                                
                                                              </div>
                                                        </div>
                                                    </div>
                                                </div>
                                           
                                            </div>
                                            <div *ngIf="activeCategory==3">
                                              
                                                  
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="color-black container">
                                                        <div class="row">
                                                            <div class="article-container">
                          

                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        General Address1</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{addressSupplier.generalAddress1}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        General Address2</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{addressSupplier.generalAddress2}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        General State Province Geo Id</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{addressSupplier.generalStateProvinceGeoId}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        General City</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{addressSupplier.generalCity}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        General Country Geo Id</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{addressSupplier.generalCountryGeoId}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-5 col-12">
                                                                        General Postal Code</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{addressSupplier.generalPostalCode}}</span>
                                                                </div>
                                                              
                                                               
                                                              
                                                               
                                                                
                                                              </div>
                                                        </div>
                                                    </div>
                                                </div>
                                           
                                            </div>
                                            <div *ngIf="activeCategory==4">
                                              
                                                  
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="color-black container">
                                                    <div class="row">
                                                        <div class="article-container">

                                                            <div class="article">
                                                                <p class="col-lg-5 col-12">
                                                                    Company</p>
                                                                <span
                                                                    class="color-grey pl-20 col-lg-10 col-12">{{vendor.company}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-5 col-12">
                                                                    Company Tax ID
                                                                </p>
                                                                <span
                                                                    class="color-grey pl-20 col-lg-10 col-12">{{vendor.taxId}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-5 col-12">
                                                                    Vendor Eligible
                                                                </p>
                                                                <span
                                                                    class="color-grey pl-20 col-lg-10 col-12">{{vendor.eligibleFor}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-5 col-12">
                                                                    Terms of Agreements
                                                                </p>
                                                                <span
                                                                    class="color-grey pl-20 col-lg-10 col-12">{{vendor.terms}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-5 col-12">
                                                                    Default Payables Account
                                                                </p>
                                                                <span
                                                                    class="color-grey pl-20 col-lg-10 col-12">{{vendor.accountNo}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-5 col-12">
                                                                    Print Check As</p>
                                                                <span
                                                                    class="color-grey pl-20 col-lg-10 col-12">{{vendor.printCheckAs}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-5 col-12">
                                                                    Credit Limit
                                                                </p>
                                                                <span
                                                                    class="color-grey pl-20 col-lg-10 col-12">{{vendor.creditLimit}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-5 col-12">
                                                                    Group Name</p>
                                                                <span
                                                                    class="color-grey pl-20 col-lg-10 col-12">{{vendor.groupName}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-5 col-12">
                                                                    General To Name
                                                                </p>
                                                                <span
                                                                    class="color-grey pl-20 col-lg-10 col-12">{{addressSupplier.generalToName}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-5 col-12">
                                                                    Contact Mech Purpose Type Id</p>
                                                                <span
                                                                    class="color-grey pl-20 col-lg-10 col-12">{{addressSupplier.contactMechPurposeTypeId}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-5 col-12">
                                                                    Tax Id
                                                                </p>
                                                                <span
                                                                    class="color-grey pl-20 col-lg-10 col-12">{{vendor.taxId}}</span>
                                                            </div>
                                                            
                                                          </div>
                                                    </div>
                                                </div>
                                                </div>
                                       
                                            </div>
                                            <div *ngIf="activeCategory==5">
                                                <div class="w3-card-4 classCard">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Contract List
                                                            </h4>

                                                            <button type="button" data-toggle="modal" class="btn btn-outline-secondary "
                                                            (click)="resetDoc()" data-target="#exampleModalCenterContract">Create Contract</button>
        
                                                        </div>
                                                    </header>
                                                    <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
        
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="agreementsList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                           
                                                                                        </p-checkbox>
                                                                                        Agreement ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Product ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> From Party ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> To Party ID
                                                                                        <p-sortIcon field="category"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'200px'}" width="11%" pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Role Type Id To
                                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'200px'}" width="11%" pSortableColumn="price">
                                                                                    <div style="color: white;"> Agreement Type ID
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> From Date
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Through Date
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Description
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Created Date
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" width="10%" style="text-align: center;" pSortableColumn="price">
                                                                                    <div style="color: white;"> Action</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                           
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button">{{product.agreementId}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">{{product.productId}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">{{product.partyIdFrom}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">{{product.partyIdTo}}</td>
                                                                                <td [ngStyle]="{'width':'200px'}" width="11%">
                                                                                    {{product.roleTypeIdTo}}</td>
                                                                                <td [ngStyle]="{'width':'200px'}" width="11%">
                                                                                    {{product.agreementTypeId}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.fromDate | date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.thruDate | date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    {{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}">{{product.description}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}" width="10%" style="text-align: center;">
                                                                                    <button type="button" data-toggle="modal" class="btn btn-outline-secondary "
                                                                                    (click)="updateAgree(product)" data-target="#exampleModalCenterContract">Update</button>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'150px'}" width="10%" style="text-align: center;">
                                                                                    <div>
        
                                                                                        <svg (click)="cancelAgreement(product.agreementId)" xmlns="http://www.w3.org/2000/svg" pTooltip="Cancel" tooltipPosition="Bottom" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                                                    <defs>
                                                                                                                        <style>
                                                                                                                            .a {
                                                                                                                                fill: #f44336;
                                                                                                                            }
                                            
                                                                                                                            .b {
                                                                                                                                fill: #fafafa;
                                                                                                                                margin-left: 20px;
                                                                                                                            }
                                                                                                                        </style>
                                                                                                                    </defs>
                                                                                                                    <path class="a fill-color"
                                                                                                                        d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                    <path class="b"
                                                                                                                        d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                        transform="translate(-147.576 -147.576)" />
                                                                                                                </svg>
                                                                                    </div>
                                                                                </td>
        
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                                                <h4 class="ml-2">No Record Found</h4>
                                                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
        
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="activeCategory==6">
                                                <div class="w3-card-4 classCard">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Documenet List
                                                            </h4>
                                                    <button type="button" data-toggle="modal" class="btn btn-outline-secondary mr-2"
                                                    (click)="resetDoc()" data-target="#exampleModalCenterDoc">Upload Document</button>

                
                                                        </div>
                                                    </header>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
    
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="attachDocumentId"
                                                                        [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [responsive]="true"
                                                                        [totalRecords]="total"
                                                                        [scrollable]="true"
                                                                        styleClass="p-datatable-customers"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        Attach DocumentId
                                                                                        <p-sortIcon
                                                                                            field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Party Id
                                                                                        <p-sortIcon
                                                                                            field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                    pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Created Date
    
    
                                                                                        <p-sortIcon
                                                                                            field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Download
                                                                                    <p-sortIcon
                                                                                        field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>                                                                         
    
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body"
                                                                            let-product>
                                                                            <tr>
                                                                                <td
                                                                                    [ngStyle]="{'width':'200px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span class="account-button" >{{product.attachDocumentId}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    {{product.partyId}}
                                                                                </td>
                                                                                <td
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    {{product.createdStamp | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td
                                                                                [ngStyle]="{'width':'190px'}">
                                                                                  

                                                                                <button type="submit" class="btn btn-secondary submit-btn"> 
                                                                                <a href="{{product.url}}" target="_blank"  style="color:#fff;text-decoration:none;">View File</a>
                                                                             </button>
                                                                            </td>
                                                                               
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No
                                                                                            Record Found</h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                             
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Vendor</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="container-fluid main-container-dialog">
                    <div class="row">
                        <div class="col-12">
                            <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                                <div class=" bg-white color-grey" style="width: 100%;">
                                    <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                            <li [ngClass]="activeCategoryA==2?'active':''"
                                                ><a>
                                                    General</a></li>
                                            <li [ngClass]="activeCategoryA==3?'active':''"
                                               ><a>
                                                    Contact</a></li>
                                            <li [ngClass]="activeCategoryA==4?'active':''"><a>
                                                    Address</a></li>
                                            <li [ngClass]="activeCategoryA==5?'active':''"
                                               ><a>
                                                    Financial</a></li>



                                        </ul>
                                    </div>

                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100" [formGroup]="createVendor">
                                                    <div *ngIf="activeCategoryA==2">
                                                        <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Vendor
                                                                        ID<span style="color:red">*</span></label>
                                                                </div>
                             
                                                                <div class="col-lg-3">
                                                                    <p-dropdown formControlName="partyId"
                                                                        placeholder="Choose Vendor ID" filter="true"
                                                                        [options]="partyIdArray"
                                                                        optionlabel="label">

                                                                    </p-dropdown>
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Name

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" formControlName="name"
                                                                        formControlName="name" class="form-control"
                                                                        placeholder="Enter Name">
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Type of Product
                                                                        or Service Offered

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email" formControlName="type"
                                                                        id="exampleInputEmail1"
                                                                        placeholder="Enter Type of Product or Service Offered"
                                                                        class="form-control">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Vendor Name

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="vendorName"
                                                                        placeholder="Enter Vendor Name"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Vendor
                                                                        Email

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="vendorEmail"
                                                                        placeholder="Enter Vendor Email"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Company Website

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="companyWebsite"
                                                                        id="exampleInputEmail1" placeholder="Enter Company Website "
                                                                        class="form-control">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Vendor is
                                                                        Active

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="isActive"
                                                                        id="exampleInputEmail1" placeholder="Enter Vendor is Active"
                                                                        class="form-control">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Individual

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="text" formControlName="individual"
                                                                        id="exampleInputEmail1" placeholder="Enter Individual"
                                                                        class="form-control">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Vendor
                                                                        Forms

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="vendorForms"
                                                                        placeholder="Enter Vendor Forms" id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Map

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email" formControlName="map"
                                                                        id="exampleInputEmail1"
                                                                        placeholder="Enter Map"
                                                                        class="form-control">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Manifest Company
                                                                        Title

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="manifestCompanyTitle"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Manifest Company Title">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Manifest
                                                                        Policies

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="manifestPolicies"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Manifest Policies">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Manifest Company
                                                                        Name

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="manifestCompanyName"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Manifest Company Name">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Manifest Logo
                                                                        Url

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="manifestLogoUrl"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter Manifest Logo Url">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Billing Rate Level
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="billingRateLevel"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter billing Rate Level">
                                                                </div>


                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Phone
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control"
                                                                    formControlName="phone"
                                                                    placeholder="Enter Phone"
                                                                    id="exampleInputEmail1">
                                                                </div>



                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Web Url Id
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="webUrlId"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter web Url Id">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Type Vendor
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="typeVendor"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter type Vendor">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Primary Web Url
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="PrimaryWebUrl"
                                                                        class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Enter primary Web Url">
                                                                </div>



                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="main-submit-button" style="margin-right:11%;">
                                                        <button type="submit"
                                                            (click)="changeactiveCategoryFunctionA(3)"
                                                            class="btn btn-secondary submit-btn">Continue</button>

                                                    </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryA==3">
                                                        <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">

                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Vendor
                                                                        Phone

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="vendorPhone"
                                                                        placeholder="Enter Vendor Phone"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Vendor Fax

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="vendorFax"
                                                                        placeholder="Enter Vendor Fax"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Primary Email
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="primaryEmail"
                                                                        placeholder="Enter primary Email"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Primary Fax Area Code

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="primaryFaxAreaCode"
                                                                        placeholder="Enter Primary Fax Area Code"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Primary Fax Country Code
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="primaryFaxCountryCode"
                                                                        placeholder="Enter Primary Fax Country Code"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Primary Fax Extension

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="primaryFaxExtension"
                                                                        placeholder="Enter primary Fax Extension"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Primary Fax Number
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="primaryFaxNumber"
                                                                        placeholder="Enter Primary Fax Number"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Primary Phone Area Code

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="primaryPhoneAreaCode"
                                                                        placeholder="Enter Primary Phone Area Code"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Primary Phone Country Code
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="primaryPhoneCountryCode"
                                                                        placeholder="Enter Primary Phone Country Code"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Primary Phone Extension

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="primaryPhoneExtension"
                                                                        placeholder="Enter Primary Phone Extension"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Primary Phone Number
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="primaryPhoneNumber"
                                                                        placeholder="Enter Primary Phone Number"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">General Attn Name
                                                                        

                                                                    </label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="email"
                                                                        formControlName="generalAttnName"
                                                                        placeholder="Enter General Attn Name"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="main-submit-button" style="margin-right: 13%;">
                                                        <button type="submit"
                                                            (click)="changeactiveCategoryFunctionA(2)"
                                                            class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                        <button type="submit"
                                                            (click)="changeactiveCategoryFunctionA(4)"
                                                            class="btn btn-secondary submit-btn">Continue</button>

                                                    </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryA==4">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">General Address1
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalAddress1"
                                                                            placeholder="Enter General Address1"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">General Address2

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalAddress2"
                                                                            placeholder="Enter General Address2"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">General State Province Geo Id
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                        formControlName="generalStateProvinceGeoId"
                                                                        placeholder="Enter General State Province Geo Id"
                                                                        id="exampleInputEmail1"
                                                                        class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">General City

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalCity"
                                                                            placeholder="Enter General City"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">General Country Geo Id
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalCountryGeoId"
                                                                            placeholder="Enter General Country Geo Id"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">General Postal Code

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalPostalCode"
                                                                            placeholder="Enter general Postal Code"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                           


                                                        </div>
                 
                                                        <div class="main-submit-button" style="margin-right: 13%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunctionA(3)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunctionA(5)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>
                                                   
                                                    <div *ngIf="activeCategoryA==5">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Credit Limit

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="creditLimit"
                                                                            placeholder="Enter Credit Limit"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Terms
                                                                            


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Terms"
                                                                            formControlName="terms"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Info String

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="infoString"
                                                                            placeholder="Enter Info String"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">is Incorporated
                                                                            


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="isIncorporated"
                                                                            id="exampleInputEmail1" class="form-control"
                                                                            placeholder="Enter is Incorporated">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Group Name

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="groupName"
                                                                            placeholder="Enter group Name"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">General To Name
                                                                            


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="generalToName"
                                                                            id="exampleInputEmail1" class="form-control"
                                                                            placeholder="Enter general To Name">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Company


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">

                                                                        <input type="email" formControlName="company"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Company"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Federal Tax ID

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="federalTaxId"
                                                                            id="exampleInputEmail1"
                                                                            placeholder="Enter Company Tax ID"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Vendor
                                                                            Eligible
                                                                           

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Vendor Eligible"
                                                                            formControlName="eligibleFor"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Contact Mech Purpose Type Id
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter contact Mech Purpose Type Id"
                                                                            formControlName="contactMechPurposeTypeId"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Requires 1099
                                                                            

                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="requires1099"
                                                                            placeholder="Enter Requires 1099"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Tax Id


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email" placeholder="Enter Tax Id"
                                                                            formControlName="taxId"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>
                                      


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">


                                                                    <div class="col-lg-3 form-group classInputE">
                                                                        <label for="exampleInputEmail1">Default Payables
                                                                            Account


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            placeholder="Enter Default Payables Account"
                                                                            formControlName="accountNo"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Print Check As


                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <input type="email" placeholder="Enter Print Check As"
                                                                            formControlName="printCheckAs"
                                                                            id="exampleInputEmail1"
                                                                            class="form-control">
                                                                    </div>


                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 12%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunctionA(4)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit" (click)="onUpdate()"
                                                                class="btn btn-secondary submit-btn">Save</button>

                                                        </div>
                                                    </div>



                                                </form>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="exampleModalCenterDoc" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterDoc"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Upload Document</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="container-fluid main-container-dialog">
                    <div class="row">
                        <div class="col-12">
                            <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                                <div class=" bg-white color-grey" style="width: 100%;">
                                    <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                            <li [ngClass]="activeCategory==2?'active':''"
                                                ><a>
                                                    Upload Document 
                                                </a></li>
                                        </ul>
                                    </div>
                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100" [formGroup]="uploadDocForm">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Upload Document
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input 
                                                                        (change)="fileProgress($event)"
                                                                            type="file"
                                                                            class="form-control"
                                                                            id="exampleInputEmail1"
                                                                            formControlName="upload"
                                                                            aria-describedby="emailHelp"
                                                                            >
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="main-submit-button" style="margin-right:58%;">
                                                            <button type="submit"
                                                                (click)="attachDocument()"
                                                                class="btn btn-secondary submit-btn">Create</button>                         
                                                        </div>
                                                   
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenterContract" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterContract" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Agreement</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryMainTs==1?'active':''"><a>
                            Create Agreement</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCustomerAgreements">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Product ID<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="productList" optionLabel="productId" filter="true" placeholder="Select" formControlName="productId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Party Id From<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="PersonsAndPartyGroupsList" optionLabel="partyId" filter="true" placeholder="Select" formControlName="partyIdFrom">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Party Id To<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="PersonsAndPartyGroupsList" optionLabel="partyId" filter="true" placeholder="Select" formControlName="partyIdTo">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Role Type Id From<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="RoleTypesList" optionLabel="description" filter="true" placeholder="Select" formControlName="roleTypeIdFrom">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Role Type Id To</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="RoleTypesList" optionLabel="description" filter="true" placeholder="Select" formControlName="roleTypeIdTo">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Agreement Type ID<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="AgreementTypeList" optionLabel="agreementTypeId" filter="true" placeholder="Select" formControlName="agreementTypeId">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createCustomerAgreements.controls.agreementTypeId.invalid && !!createCustomerAgreements.controls.agreementTypeId.touched">Agreement
                                                        Type ID is required</small>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Agreement Date</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" formControlName="agreementDate" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">From Date<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" formControlName="fromDate" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Through Date<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" formControlName="thruDate" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="examCreate Commission Agreement
                                                pleInputEmail1">Description<span
                                                style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Text Data</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <textarea name="" id="exampleInputEmail1" formControlName="textData" class="form-control" cols="30" rows="10" placeholder="Enter Text"></textarea>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -11%;">
                                            <button _ngcontent-cta-c128="" type="submit" 
                                            (click)="submitData()" class="btn btn-secondary submit-btn">Create</button>



                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner> 