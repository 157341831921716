<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Bom Simulation </span>
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
             
            </div>
         
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium classHead">
                <div class="submenu">
                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn stylesubmenu"
                    routerLinkActive="active" routerLink="/production/bom-simulation-list">Bom
                    Simulation</button>&nbsp;
                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn stylesubmenu"
                    routerLinkActive="active" routerLink="/production/create-product-bom">Edit Bom</button>&nbsp;
                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn stylesubmenu"
                    routerLinkActive="active" style="width: 14%!important;"  routerLink="/production/manufacturing-rules">Manufacturing
                    Rules</button>&nbsp;


                </div>
            </div>
            <div class=" bg-white color-grey">
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <button type="button" class="btn btn-outline-secondary" routerLinkActive="active"
                                        (click)="createSimulation()">Create New Simulation</button>
            
                                </div>
                              
                               
                                       
                                           
                                <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                          
                                            <div class="card own-account-table">
                                                <p-table [value]="financialList" [paginator]="true" [rows]="rows"
                                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                    [totalRecords]="total" [scrollable]="true"
                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                    styleclass="custom-checkbox" binary="true"
                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                    <div
                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                        <div class="ui-helper-hidden-accessible">
                                                                            <input type="checkbox" name="undefined"
                                                                                value="undefined"></div>
                                                                        <div role="checkbox"
                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                            <span
                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                        </div>
                                                                    </div>
                                                                    <!---->
                                                                </p-checkbox>
                                                                   Product Level <p-sortIcon field="code">
                                                                    </p-sortIcon>
                                                                </div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                             <div style="color: white;"> 	Product ID <p-sortIcon field="name"></p-sortIcon>
                                                             </div>
                                                            </th>
                                                          
                                                         
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                <div style="color: white;"> Virtual <p-sortIcon field="price"></p-sortIcon></div>
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;"> 	Product Name <p-sortIcon field="name"></p-sortIcon>
                                                                </div>
                                                               </th>
                                                             
                                                            
                                                               <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                   <div style="color: white;"> Quantity<p-sortIcon field="price"></p-sortIcon></div>
                                                               </th>
                                                               <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                <div style="color: white;"> Action<p-sortIcon field="name"></p-sortIcon>
                                                                </div>
                                                               </th>
                                                              
                                                          
                                                          
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'180px'}"
                                                               >
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                   <span></span>
                                                                </div>
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                              
                                                            </td>
                                                           
                                                            <td [ngStyle]="{'width':'170px'}">
                                                               
                                                            </td>
                                                            <td [ngStyle]="{'width':'170px'}">
                                                              
                                                            </td>
                                                           
                                                            <td [ngStyle]="{'width':'170px'}">
                                                                
                                                            </td>
                                                           
                                                            
                                                            
                                                   
                                                          
                                                    </ng-template>
                                                </p-table>
                                                <p class="paginate_data">
                                                    View per page </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 