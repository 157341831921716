
<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of {{contactName}} [{{contactPartyId}}] </span>
              </div>
              <div class="w3-card-4 classCard" >
              <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                  <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                    New Party Note</a></li>
                </ul>
              </div>
            <div class="panel-group">
                <div *ngIf="activeCategory==1">
                   
                      <div class="panel-body">
                          <form [formGroup]="fileUploadForm">
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-12" >
                                <div class="row">
                                    <div class="col-lg-3 form-group rightForm">
                                        <label for="exampleInputEmail1">Note Name</label>
                                    </div>
                                    <div class="col-lg-2">
                                        <input type="email" [(ngModel)]="noteNameForUpdate" formControlName="noteName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                    </div>
                                    
                                    <div class="col-lg-3 form-group rightFormA">
                                        <label for="exampleInputEmail1">Note</label>
                                    </div>
                                    <div class="col-lg-4">
                                        <textarea id="w3review" style="height: 100%!important;width: 143%;" 
                                        [(ngModel)]="noteNameForUpdate" formControlName="note" class="form-control"  placeholder="Note"
                                        rows="3" cols="30">
                                        </textarea> 
                                     
                                    </div>
                                </div>
                            </div>
            

                          
                        </div>
                        <div class="proper">
                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showCreateBtn">
                                <button type="submit" (click)="onSubmit()"  class="btn btn-secondary submit-btn">Create</button>
                                <button  type="submit"(click)="navigateFromCreate()" class="btn btn-danger ">Cancel</button>
                            </div>
                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showUpdateBtn">
                                <button type="submit" (click)="onUpdate()"  class="btn btn-secondary submit-btn">Update</button>
                                <button  type="submit" (click)="navigate()"class="btn btn-danger ">Cancel</button>
                            </div>
                       
                        </div>
                    </form>
                    </div>
                  </div>
                  </div>
                  </div>

            </div>
        </div>
</div>
<ngx-spinner></ngx-spinner>
          