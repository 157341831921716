<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <a><span class="color-grey" [routerLink]="['/crm/my-contacts']">Contact </span></a>
                <span class="color-black pl-1"> > New</span>
                <button type="button" routerLink="/crm/my-contacts" routerLinkActive="active"
                    class="btn btn-danger text-white buttonclass">Cancel</button>

            </div>
            <div class="w3-card-4 classCard">
                <div class=" bg-white color-grey">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                    General Information</a></li>
                            <li [ngClass]="activeCategory==3?'active':''"
                                (click)="generalInfo(createContactService.create_contact_form.value.firstName,createContactService.create_contact_form.value.lastName)">
                                <a>
                                    Address</a></li>

                            <li [ngClass]="activeCategory==4?'active':''" (click)="addressInformation(createContactService.create_contact_form.value.address1,createContactService.create_contact_form.value.countryGeoId,
                      createContactService.create_contact_form.value.stateProvinceGeoId,createContactService.create_contact_form.value.city
                      ,createContactService.create_contact_form.value.postalCode)"><a>
                                    Phone information </a></li>
                            <li [ngClass]="activeCategory==5?'active':''"
                                (click)="phoneInfo(createContactService.create_contact_form.value.contactNumber)"><a>
                                    E-Mail Address Information </a></li>
                            <li [ngClass]="activeCategory==8?'active':''"
                                (click)="emailInformationAdded(createContactService.create_contact_form.value.emailAddress)">
                                <a>
                                    Contact List </a></li>



                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form *ngIf="createContactService.create_contact_form"
                                    [formGroup]="createContactService.create_contact_form" class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">

                                                <div *ngIf="activeCategory==2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            General Information
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">First name <span
                                                                                style="color:red">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="firstName"
                                                                            class="form-control small-input"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="First name">


                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Last Name <span
                                                                                style="color:red">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="lastName"
                                                                            class="form-control small-input"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Last name">

                                                                       
                                                                    </div>

                                                                </div>
                                                            </div>
                                                           
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Suffix</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="suffix"
                                                                            class="form-control small-input"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Suffix">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Gender</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown styleClass="small-dropdown"
                                                                            filter="true" formControlName="gender"
                                                                            appendTo="body" [options]="gender"
                                                                            optionlabel="label"
                                                                            placeholder="Select Gender">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12" *ngIf="show">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Comments</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="comments"
                                                                            class="form-control small-input"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Comments">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">External
                                                                            Id</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="externalId"
                                                                            class="form-control small-input"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter External Id">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            
                                                          
                                                            <div class="col-lg-12" *ngIf="show">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Status
                                                                            Id</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown styleClass="small-dropdown"
                                                                            *ngIf="show" filter="true"
                                                                            formControlName="statusId"
                                                                            [options]="crmArray" optionlabel="label"
                                                                            placeholder="Select Status Id">
                                                                        </p-dropdown>
                                                                    </div>


                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 35%;">
                                                            <button type="submit"
                                                                (click)="generalInformation(createContactService.create_contact_form.value.firstName,createContactService.create_contact_form.value.lastName)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Address
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Address 1 <span
                                                                                style="color:red">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="address1"
                                                                            class="form-control small-input"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Address 1">

                                                                      
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Address
                                                                            2</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="address2"
                                                                            class="form-control small-input"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Address 2">
                                                                    </div>

                                                                </div>
                                                            </div>
                                                           
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Country <span
                                                                                style="color:red">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown styleClass="small-dropdown"
                                                                            filter="true" [options]="array"
                                                                            formControlName="countryGeoId"
                                                                            (ngModelChange)="onCountryChange($event)">
                                                                        </p-dropdown>
                                                                       
                                                                    </div>

                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">State <span
                                                                                style="color:red">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown styleClass="small-dropdown"
                                                                            filter="true"
                                                                            formControlName="stateProvinceGeoId"
                                                                            appendTo="body" [options]="stateArray"
                                                                            (ngModelChange)="onStateChange($event)"
                                                                            placeholder="Select State"
                                                                            optionlabel="label">
                                                                        </p-dropdown>

                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">City <span
                                                                                style="color:red">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="city"
                                                                            class="form-control small-input"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter City">

                                                                       
                                                                    </div>
                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Zip/Postal Code
                                                                            <span style="color:red">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="postalCode"
                                                                            class="form-control small-input"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Zip Code">

                                                                       
                                                                    </div>


                                                                </div>
                                                            </div>
                                                          

                                                        </div>
                                                        <div class="main-submit-button" style="    margin-right: 35%;">
                                                            <button type="submit" (click)="changeactiveCategoryFunction(2)"
                                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                            <button type="submit" (click)="addressInfo(createContactService.create_contact_form.value.address1,createContactService.create_contact_form.value.countryGeoId,
                                                createContactService.create_contact_form.value.stateProvinceGeoId,createContactService.create_contact_form.value.city
                                                ,createContactService.create_contact_form.value.postalCode)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==4">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Phone Information
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Country
                                                                            Code</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="countryCode"
                                                                            class="form-control small-input"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter country Code">
                                                                    </div>

                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Area
                                                                            Code</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="areaCode"
                                                                            class="form-control small-input"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Area Code">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                           
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Phone Number
                                                                            <span style="color:red">*</span>
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="contactNumber"
                                                                            class="form-control small-input"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Phone Number">

                                                                       
                                                                    </div>

                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label
                                                                            for="exampleInputEmail1">Extension</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email" formControlName="extension"
                                                                            class="form-control small-input"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Extension">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            


                                                        </div>
                                                        <div class="main-submit-button" style="    margin-right: 35%;">
                                                            <button type="submit" (click)="changeactiveCategoryFunction(3)"
                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                            <button type="submit"
                                                                (click)="phoneInformation(createContactService.create_contact_form.value.contactNumber)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==5">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            E-Mail Address Information
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightFormC">
                                                                        <label for="exampleInputEmail1">Email <span
                                                                                style="color:red">*</span></label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="email"
                                                                            formControlName="emailAddress"
                                                                            class="form-control small-input"
                                                                            id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Email Address">

                                                                        
                                                                    </div>


                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 77%;">
                                                            <button type="submit" (click)="changeactiveCategoryFunction(4)"
                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                            <button type="submit"
                                                                (click)="emailInformation(createContactService.create_contact_form.value.emailAddress)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==8">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Contact List
                                                        </h4>


                                                    </div>
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Contact
                                                                            List</label>

                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown styleClass="small-dropdown"
                                                                            filter="true"
                                                                            formControlName="contactListId"
                                                                            [options]="contactListArray"
                                                                            optionlabel="label"></p-dropdown>
                                                                    </div>


                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 73%;">
                                                            <button type="submit" (click)="changeactiveCategoryFunction(5)"
                                                            class="btn btn-secondary submit-btn">Previous</button>&nbsp;
                                                            <button type="submit" *ngIf="!show" (click)="onSubmit()"
                                                                class="btn btn-secondary submit-btn">Save</button>
                                                            <button *ngIf="show" type="submit" (click)="onUpdate()"
                                                                class="btn btn-secondary submit-btn">Update</button>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>








                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>