import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
@Component({
  selector: 'app-create-tax-party',
  templateUrl: './create-tax-party.component.html',
  styleUrls: ['./create-tax-party.component.css']
})
export class CreateTaxPartyComponent implements OnInit {
  createProduct:FormGroup;
  activeCategory=2;
  personId: any;
  partyIdArray: any[]=[];
  promotion: { label: string; value: string; }[];
  party: any;
  geo: any;
  actualfromdate: any;
  actualthroughdate: any;
  partyId: any;
  fromDate: any;
  show: boolean;
  getEstimateFrom: string;
  getEstimateTo: string;
  constructor( readonly  _Router: Router,
    readonly datePipe: DatePipe,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly addSkillService: AddSkillService,
  
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createProduct = this._FormBuilder.group({
        PartyID: [''],
        PartyTaxID: [''],
        IsExempt: [''],
        IsNexus: [''],
       
        fromDate: [''],
        throughDate: [''],
   
      });
      this.promotion=[{
        "label":"Y",
        "value":"Y"
      },
      {
        "label":"N",
        "value":"N"
      }]
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getPartyId();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.geo = params["geo"];
      this.partyId = params["partyId"];
      this.fromDate = params["fromDate"];
    
    });
    if(this.partyId){
      this.show=true;
      this.getPartyDetail();
     
          }else{
            this.show=false;
          }
  }
  
  getPartyDetail() {
    this.spinner.show();
   
    this.accountsService.getPartyDetail(this.fromDate,this.partyId,this.geo,this.party).subscribe(res => {
     
      this.spinner.hide();
      setTimeout(() => {
       
        const fromdate=res.data.fromDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=res.data.thruDate
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
      
        const formValue = this.createProduct;

        formValue.patchValue({
          PartyID: res.data.partyId,
          PartyTaxID: res.data.partyTaxId,
          IsExempt: res.data.isExempt,
          IsNexus: res.data.isNexus,
         
          fromDate:  this.getEstimateFrom,
          throughDate:  this.getEstimateTo,
     
        })
      
      }, 3000);
    })
    
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }

  onUpdate() {
    this.spinner.show();
    const throughActualdate=this.createProduct.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":this.fromDate,
      "isExempt":this.createProduct.value.IsExempt,
      "isNexus": this.createProduct.value.IsNexus,
      "partyId": this.createProduct.value.PartyID,
      "partyTaxId": this.createProduct.value.PartyTaxID,
      "taxAuthGeoId": this.geo,
      "taxAuthPartyId":this.party,
      "thruDate": this.actualthroughdate
    }
    this.accountsService.updateTaxParty(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-tax-authority'],{ queryParams: { geo : this.geo,party : this.party}});

      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onSubmit() {
    this.spinner.show();
    const fromActualdate=this.createProduct.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createProduct.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":  this.actualfromdate,
      "isExempt":this.createProduct.value.IsExempt,
      "isNexus": this.createProduct.value.IsNexus,
      "partyId": this.createProduct.value.PartyID,
      "partyTaxId": this.createProduct.value.PartyTaxID,
      "taxAuthGeoId": this.geo,
      "taxAuthPartyId":this.party,
      "thruDate": this.actualthroughdate
    }
    this.accountsService.postTaxParty(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-tax-authority'],{ queryParams: { geo : this.geo,party : this.party}});
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-tax-authority'],{ queryParams: { geo : this.geo,party : this.party}});

  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
