import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { PaymentGroupService } from '../accounting-ap/payable-payment-group/payment-group.service';
import Swal from 'sweetalert2'
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';
@Component({
  selector: 'app-detail-tax-authority',
  templateUrl: './detail-tax-authority.component.html',
  styleUrls: ['./detail-tax-authority.component.css']
})
export class DetailTaxAuthorityComponent implements OnInit {
  activeCategory=0;
  products3: any;
  paymentPartyGroup: any;
  downloading: boolean;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  @ViewChild('closebuttonA') closebuttonA;
  @ViewChild('closebuttonB') closebuttonB;
  @ViewChild('closebuttonC') closebuttonC;
  @ViewChild('closebuttonD') closebuttonD;
  @ViewChild('closebuttonE') closebuttonE;
  @ViewChild('closebutton') closebutton;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  glChartOfAccount:any[]=[];
  party: any;
  geo: any;
  CreditCardTypeGlAccounts: any;
  categoryList: any;
  associationList: any;
  taxGlAccount: any;
  productRate: any;
  smallerArray: { label: string; value: string; }[];
  greaterArray: { label: string; value: string; }[];
  opportunityIdArray: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[]=[];
  partyForm:FormGroup;
  promotion: { label: string; value: string; }[];
  taxParty: any;
  fromDateFrom: string;
  fromDateTo: string;
  thruDateFrom: string;
  thruDateTo: string;
  reportForm:FormGroup;
  ReportTax: any;
  invoiceDateFrom: string;
  invoiceDateTo: string;
  taxInfoDetail: any;

  createTax:FormGroup;
  exemption: { label: string; value: string; }[];
 
  getGeoTy: any;
  getGeoTyArray: any[]=[];
 
  show: boolean;
  createCate:FormGroup;
  activeCategoryMain=2;
 
  categoryListArray: any[]=[];
  showAssoc: boolean;
  createASS:FormGroup;
 
  assType: any;
  assTypeArray: any[]=[];
  actualfromdate: string;
  actualthroughdate: string;
  toGeo: any;
  toParty: any;
  fromdate: any;
  createCateGLAccount:FormGroup;
  getEstimateFrom: string;
  getEstimateTo: string;
  fromdateSave: any;
  createProduct:FormGroup;
  createProductRateForm:FormGroup;
  partyId: any;
  fromDate: any;
 
  rateType: any;
  rateTypeArray: any[]=[];
 
  store: any;
  storeArray: any[]=[];
  enumDataArrayList: any[]=[];
  enumId: string;

  authProductSeqid: any;
  
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  updatethroughdate: string;
  showRate: boolean;
  showParty: boolean;
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
   
    readonly addSkillService: AddSkillService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
   
    readonly router: Router) { 
      this.createTax = this._FormBuilder.group({
        Geo: [''],
        Party:[''],
        exemption:[''],
        pattern:[''],
        price:['']
       
        
      });
      this.exemption=[{"label":"Y","value":"Y"},{"label":"N","value":"N"}]
      this.opportunityIdArray = [
        {
            label: 'Contains',
            value: 'Contains'
        },
        {
            label: 'Equals',
            value: 'Equals'
        },
        {
            label: 'Is Empty',
            value: 'Is Empty'
        },
        {
            label: 'Begins With',
            value: 'Begins With'
        },
    ];
    this.greaterArray = [
        {
            label: 'Equals',
            value: 'Equals'
        },
        {
            label: 'Same Day',
            value: 'Same Day'
        },
        {
            label: 'Greater Than From Day Start',
            value: 'Greater Than From Day Start'
        },
        {
            label: 'Greater Than',
            value: 'Greater Than'
        }
    ];
    this.smallerArray = [
        {
            label: 'Less Than',
            value: 'Less Than'
        },
        {
            label: 'Up To Day',
            value: 'Up To Day'
        },
        {
            label: 'Up Through Day',
            value: 'Up Through Day'
        },
        {
            label: 'Is Empty',
            value: 'Is Empty'
        }
    ];
    this.promotion=[{
      "label":"Y",
      "value":"Y"
    },
    {
      "label":"N",
      "value":"N"
    }]
    this.partyForm = this._FormBuilder.group({
      fromDateFrom:  [''],
      fromDateFromType: ['Equals'],
      fromDateTo:  [''],
      fromDateToType: ['Less Than'],
      isExempt: [''],
      isNexus:  [''],
      partyId: [''],
      partyTaxId:  [''],
      partyTaxIdSearchType: ['Contains'],
      thruDateFrom:  [''],
      thruDateFromType: ['Equals'],
      thruDateTo:  [''],
      thruDateToType: ['Less Than'],

  });
  this.reportForm = this._FormBuilder.group({
    invoiceDateFrom:  [''],
    invoiceDateFromType: ['Equals'],
    invoiceDateTo:  [''],
    invoiceDateToType: ['Less Than'],
   

});
this.createCate = this._FormBuilder.group({
  categoryId: [''],

 
  
});
this.createASS = this._FormBuilder.group({
  toTaxAuthGeo: [''],
  toParty:[''],
  fromDate:[''],
  throughDate:[''],
  Type:['']

 
  
});
this.createProductRateForm = this._FormBuilder.group({
  PartyID: [''],
  PartyTaxID: [''],
  IsExempt: [''],
  IsNexus: [''],
 
  fromDate: [''],
  throughDate: [''],

});
this.createCateGLAccount = this._FormBuilder.group({
  orgPartyId: [''],
  glAccount: [''],

 
  
});
this.createProduct = this._FormBuilder.group({
  Type: [''],
  StoreID: [''],
  Category: [''],
  TitleTransfer: [''],
  MinItemPrice: [''],
  MinPurchase: [''],
  TaxShipping: [''],
  TaxPercentage: [''],
  TaxPromotions: [''],
  fromDate: [''],
  throughDate: [''],
  Description: [''],
  IsTaxShippingPrice: [''],

 
  
});
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.geo = params["geo"];
   
    });
    this.getPartyId();
    this.getgeoByType();
    this.showAssoc=false;
    this.showRate=false;
    this.showParty=false;

      this.show=true;

  
    this.taxCategoryList();
    this.postAssociationList();
    this.getTaxGlAccounts();
    this.getProductRate();
    this.getPartyId();
    this.getTaxParty();
    this.getReportTax();
    this.taxAuthorityDetail();
    this.getCategorySearch();
    this.getgeoByTypeData();
    this.getPartyIdData();
    this.getAssocTypes();
    this.getGlAccountInitialData();
    this.getRateTypes();
    this.taxCategoryListData();
    this.getStoreId();
    this.getEenumByTypeData();
  }
  getPartyDetail() {
    this.spinner.show();
   
    this.accountsService.getPartyDetail(this.fromDate,this.partyId,this.geo,this.party).subscribe(res => {
     
      this.spinner.hide();
      setTimeout(() => {
       
        const fromdate=res.data.fromDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=res.data.thruDate
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
      
        const formValue = this.createProduct;

        formValue.patchValue({
          PartyID: res.data.partyId,
          PartyTaxID: res.data.partyTaxId,
          IsExempt: res.data.isExempt,
          IsNexus: res.data.isNexus,
         
          fromDate:  this.getEstimateFrom,
          throughDate:  this.getEstimateTo,
     
        })
      
      }, 3000);
    })
    
  }


  onUpdateParty() {
    this.spinner.show();
    const throughActualdate=this.createProduct.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":this.fromDate,
      "isExempt":this.createProductRateForm.value.IsExempt,
      "isNexus": this.createProductRateForm.value.IsNexus,
      "partyId": this.createProductRateForm.value.PartyID,
      "partyTaxId": this.createProductRateForm.value.PartyTaxID,
      "taxAuthGeoId": this.geo,
      "taxAuthPartyId":this.party,
      "thruDate": this.actualthroughdate
    }
    this.accountsService.updateTaxParty(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getPartyId();
        this.getTaxParty();
        this.getReportTax();
        this.closebuttonE.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
    });

  }
  onSubmitParty() {
    this.spinner.show();
    const fromActualdate=this.createProduct.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createProduct.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":  this.actualfromdate,
      "isExempt":this.createProductRateForm.value.IsExempt,
      "isNexus": this.createProductRateForm.value.IsNexus,
      "partyId": this.createProductRateForm.value.PartyID,
      "partyTaxId": this.createProductRateForm.value.PartyTaxID,
      "taxAuthGeoId": this.geo,
      "taxAuthPartyId":this.party,
      "thruDate": this.actualthroughdate
    }
    this.accountsService.postTaxParty(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getPartyId();
    this.getTaxParty();
    this.getReportTax();
    this.closebuttonE.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
    });

  }
  getproductRatesDetail() {
    this.spinner.show();
    this.accountsService.getproductRatesDetail(this.authProductSeqid).subscribe(res => {
      this.spinner.hide();
      setTimeout(() => {
        const fromdate=res.data.fromDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=res.data.thruDate
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        const formValue = this.createProduct;
        formValue.patchValue({
          Type:res.data.taxAuthorityRateTypeId,
          StoreID: res.data.productStoreId,
          Category: res.data.productCategoryId,
          TitleTransfer: res.data.titleTransferEnumId,
          MinItemPrice: res.data.minItemPrice,
          MinPurchase: res.data.minPurchase,
          TaxShipping: res.data.taxShipping,
          TaxPercentage: res.data.taxPercentage,
          TaxPromotions:res.data.taxPromotions,
          fromDate:  this.getEstimateFrom,
          throughDate:  this.getEstimateTo,
          Description: res.data.description,
          IsTaxShippingPrice: res.data.isTaxInShippingPrice,
        })
      }, 3000);
    })
    
  }
  getEenumByTypeData(): void {
    this.spinner.show();
    this.enumId="PTSOFTTFR"
    this.accountsService.getEenumByType(this.enumId).subscribe((res) => {
      const enumDa = res.data;
      this.spinner.hide();
      for (const value of enumDa) {
        this.enumDataArrayList.push({
          label: value.description,
          value: value.enumId,
        });
      }
    });
   
  }
  taxCategoryListData() {
    this.spinner.show();
    const req={
      "productCategoryId": "",
      "taxAuthGeoId":this.geo,
      "taxAuthPartyId":this.party
    }
    this.accountsService.taxCategoryList(this.finSize,req).subscribe((res: any) => {
      this.categoryList = res.data;
      this.spinner.hide();
      for (const value of this.categoryList) {
        this.categoryListArray.push({
          label: value.productCategoryId,
          value: value.productCategoryId
        })
      }
    })
  
  }
  
  getStoreId() {
    this.spinner.show();
    this.accountsService.getStoreId().subscribe((res: any) => {

      this.store = res.data.productStores;
      this.spinner.hide();
      for (const value of this.store) {
        this.storeArray.push({
          label: value.storeName,
          value: value.productStoreId
        })
      }

    })
   
  }
  getRateTypes() {
    this.spinner.show();
    this.accountsService.getRateTypes().subscribe((res: any) => {

      this.rateType = res.data;
      this.spinner.hide();
      for (const value of this.rateType) {
        this.rateTypeArray.push({
          label: value.description,
          value: value.taxAuthorityRateTypeId
        })
      }

    })
   
  }
 
  onSubmitRate() {
    this.spinner.show();
    const fromActualdate=this.createProduct.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createProduct.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "description": this.createProduct.value.Description,
      "fromDate":  this.actualfromdate,
      "isTaxInShippingPrice": this.createProduct.value.IsTaxShippingPrice,
      "minItemPrice": this.createProduct.value.MinItemPrice,
      "minPurchase": this.createProduct.value.MinPurchase,
      "productCategoryId":this.createProduct.value.Category,
      "productStoreId":this.createProduct.value.StoreID,
      "taxAuthGeoId": this.geo,
      "taxAuthPartyId":this.party,
      "taxAuthorityRateSeqId":"",
      "taxAuthorityRateTypeId": this.createProduct.value.Type,
      "taxPercentage":this.createProduct.value.TaxPercentage,
      "taxPromotions": this.createProduct.value.TaxPromotions,
      "taxShipping": this.createProduct.value.TaxShipping,
      "thruDate":  this.actualthroughdate,
      "titleTransferEnumId":this.createProduct.value.TitleTransfer,
   
    }
    this.accountsService.postproductRates(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.closebuttonD.nativeElement.click();
        this.spinner.hide();
        this.getProductRate();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
    });

  }
  onUpdateRate() {
    this.spinner.show();
    const fromActualdate=this.createProduct.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createProduct.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "description": this.createProduct.value.Description,
      "fromDate":  this.actualfromdate,
      "isTaxInShippingPrice": this.createProduct.value.IsTaxShippingPrice,
      "minItemPrice": this.createProduct.value.MinItemPrice,
      "minPurchase": this.createProduct.value.MinPurchase,
      "productCategoryId":this.createProduct.value.Category,
      "productStoreId":this.createProduct.value.StoreID,
      "taxAuthGeoId": this.geo,
      "taxAuthPartyId":this.party,
      "taxAuthorityRateSeqId":this.authProductSeqid,
      "taxAuthorityRateTypeId": this.createProduct.value.Type,
      "taxPercentage":this.createProduct.value.TaxPercentage,
      "taxPromotions": this.createProduct.value.TaxPromotions,
      "taxShipping": this.createProduct.value.TaxShipping,
      "thruDate":  this.actualthroughdate,
      "titleTransferEnumId":this.createProduct.value.TitleTransfer,
    }
    this.accountsService.updateproductRates(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.closebuttonD.nativeElement.click();
        this.spinner.hide();
        this.getProductRate();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
    });

  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }

    })
   
  }

  
  onSubmitGLAcc() {
    this.spinner.show();
    const requestData = {
      "glAccountId": this.createCateGLAccount.value.glAccount,
      "organizationPartyId": this.createCateGLAccount.value.orgPartyId,
      "taxAuthGeoId":  this.geo,
      "taxAuthPartyId":  this.party
    }
    this.accountsService.postglAccountsTax(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonC.nativeElement.click();
        this.getTaxGlAccounts();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this.toastr.error(err.error.message);
        }
    });

  }
  getAssociationById() {
    this.spinner.show();
    const req={
      "fromDate": this.fromdate,
      "taxAuthGeoId":this.geo,
      "taxAuthPartyId": this.party,
      "toTaxAuthGeoId":this.toGeo,
      "toTaxAuthPartyId":  this.toParty
    }
    this.accountsService.getAssociationById(req).subscribe(res => {
      this.spinner.hide();
      setTimeout(() => {
        this.fromdateSave=res.data.fromDate;
        const fromdate=res.data.fromDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=res.data.thruDate
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        const formValue = this.createASS;
        formValue.patchValue({
        toTaxAuthGeo: res.data.toTaxAuthGeoId,
        toParty:res.data.toTaxAuthPartyId,
        fromDate: this.getEstimateFrom,
        throughDate:this.getEstimateTo,
        Type:res.data.taxAuthorityAssocTypeId
        })
      }, 3000);
    })  
  }
  getgeoByTypeData() {
    this.spinner.show();
    this.accountsService.getgeoByType().subscribe(res => {
      this.getGeoTy = res.data;
      this.spinner.hide();
      for (const value of this.getGeoTy) {
        this.getGeoTyArray.push({
          label: value.geoId,
          value: value.geoId
        })
      }
    })
    
  }
  getAssocTypes() {
    this.spinner.show();
    this.accountsService.getAssocTypes().subscribe(res => {
      this.assType = res.data;
      this.spinner.hide();
      for (const value of this.assType) {
        this.assTypeArray.push({
          label: value.description,
          value: value.taxAuthorityAssocTypeId
        })
      }
    })
    
  }
  
  getPartyIdData() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  submitAssoc(): void {
    this.spinner.show();
    const fromActualdate=this.createASS.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createASS.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss"); 
    const requestData = {
      "fromDate": this.actualfromdate,
      "taxAuthGeoId":this.geo,
      "taxAuthPartyId":this.party,
      "taxAuthorityAssocTypeId": this.createASS.value.Type,
      "thruDate":  this.actualthroughdate,
      "toTaxAuthGeoId":this.createASS.value.toTaxAuthGeo,
      "toTaxAuthPartyId": this.createASS.value.toParty,       
    }
    this.accountsService.saveAssociation(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.postAssociationList();
        this.closebuttonB.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  updateAssoc(): void {
    this.spinner.show();
    const throughActualdate=this.createASS.get('throughDate').value;
    this.updatethroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":this.fromdateSave,
      "taxAuthGeoId":this.geo,
      "taxAuthPartyId":this.party,
      "taxAuthorityAssocTypeId": this.createASS.value.Type,
      "thruDate":  this.updatethroughdate,
      "toTaxAuthGeoId":this.createASS.value.toTaxAuthGeo,
      "toTaxAuthPartyId": this.createASS.value.toParty,
    }
    this.accountsService.updateTaxAuthorityAssoc(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.postAssociationList();
        this.closebuttonB.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  getCategorySearch() {
    this.spinner.show();
    const req={
   "categoryId": "",
   "categoryIdSearchType": "Contains",
   "categoryName": "",
   "categoryNameSearchType": "Contains"
    }
    this.accountsService.getCategorySearch(this.finSize,req).subscribe((res: any) => {
      this.categoryList = res.data;
      this.spinner.hide();
      for (const value of this.categoryList) {
        this.categoryListArray.push({
          label: value.productCategoryId,
          value: value.productCategoryId
        })
      }
    })
  
  }
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "productCategoryId": this.createCate.value.categoryId,
      "taxAuthGeoId":  this.geo,
      "taxAuthPartyId":  this.party
    }
    this.accountsService.postTaxCategory(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.closebuttonA.nativeElement.click();
        this.spinner.hide();
        this.taxCategoryList();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
    });

  }
  taxAuthorityDetailEdit() {
    this.spinner.show();
    this.accountsService.taxAuthorityDetail(this.geo,this.party).subscribe(res => {
      this.spinner.hide();
      setTimeout(() => {
       
        const formValue = this.createTax;
        formValue.patchValue({
          Geo: res.data.taxAuthGeoId,
          Party:res.data.taxAuthPartyId,
          exemption:res.data.requireTaxIdForExemption,
          pattern:res.data.taxIdFormatPattern,
          price:res.data.includeTaxInPrice,
  
        })
      
      }, 3000);
    
    })
    
  }
  getgeoByType() {
    this.spinner.show();
    this.accountsService.getgeoByType().subscribe(res => {
      this.getGeoTy = res.data;
      this.spinner.hide();
      for (const value of this.getGeoTy) {
        this.getGeoTyArray.push({
          label: value.geoId,
          value: value.geoId
        })
      }
    })
    
  }

  
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "includeTaxInPrice": this.createTax.value.price,
      "requireTaxIdForExemption":this.createTax.value.exemption,
      "taxAuthGeoId": this.createTax.value.Geo,
      "taxAuthPartyId":this.createTax.value.Party,
      "taxIdFormatPattern": this.createTax.value.pattern,
    }
    this.accountsService.updateTaxAuthorities(requestData).subscribe((res: any) => {  
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.taxAuthorityDetail();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
    });

  }

   
  taxAuthorityDetail() {
    this.spinner.show();
    this.accountsService.taxAuthorityDetail(this.geo,this.party).subscribe(res => {
    this.taxInfoDetail=res.data;
    this.spinner.hide();
    })
    
  }
  resetReport(){
    this.reportForm.reset();
    this.getReportTax();
  }
  cancelSubmit(){
    this.router.navigate(["/financial/tax-authority"])
  }  
  advanceReport(){
    const invoiceDateFrom=this.reportForm.get('invoiceDateFrom').value;
      this.invoiceDateFrom=this.datePipe.transform(invoiceDateFrom,"yyyy-MM-dd hh:mm:ss");
      const invoiceDateTo=this.reportForm.get('invoiceDateTo').value;
      this.invoiceDateTo=this.datePipe.transform(invoiceDateTo,"yyyy-MM-dd hh:mm:ss");
    this.spinner.show();
    const req={
      "invoiceDateFrom":  this.invoiceDateFrom,
      "invoiceDateFromType": this.reportForm.value.invoiceDateFromType,
      "invoiceDateTo":  this.invoiceDateTo,
      "invoiceDateToType": this.reportForm.value.invoiceDateToType,
      "taxAuthGeoId": this.geo,
      "taxAuthPartyId":this.party
    }
    this.accountsService.getReportTax(this.finSize,req).subscribe((res: any) => {
      this.ReportTax = res.data;
      this.spinner.hide();
    })
  }
  getReportTax() {
    this.spinner.show();
    const req={
      "invoiceDateFrom": "",
      "invoiceDateFromType": "Equal",
      "invoiceDateTo": "",
      "invoiceDateToType": "Equal",
      "taxAuthGeoId": this.geo,
      "taxAuthPartyId":this.party
    
    }
    this.accountsService.getReportTax(this.finSize,req).subscribe((res: any) => {
      this.ReportTax = res.data;
      this.spinner.hide();
    })
  
  }
  
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  taxCategoryList() {
    this.spinner.show();
    const req={
      "productCategoryId": "",
      "taxAuthGeoId":this.geo,
      "taxAuthPartyId":this.party
    }
    this.accountsService.taxCategoryList(this.finSize,req).subscribe((res: any) => {
      this.categoryList = res.data;
      this.spinner.hide();
    })
  
  }
  getTaxGlAccounts() {
    this.spinner.show();
    
    this.accountsService.getTaxGlAccounts(this.finSize,this.geo,this.party).subscribe((res: any) => {
      this.taxGlAccount = res.data;
      this.spinner.hide();
    })
  
  }
  reset(){
    this.partyForm.reset();
    this.getTaxParty();
  }
  advanceSearch(){
    const fromDateFrom=this.partyForm.get('fromDateFrom').value;
    this.fromDateFrom=this.datePipe.transform(fromDateFrom,"yyyy-MM-dd hh:mm:ss");
    const fromDateTo=this.partyForm.get('fromDateTo').value;
    this.fromDateTo=this.datePipe.transform(fromDateTo,"yyyy-MM-dd hh:mm:ss");
    const thruDateFrom=this.partyForm.get('thruDateFrom').value;
    this.thruDateFrom=this.datePipe.transform(thruDateFrom,"yyyy-MM-dd hh:mm:ss");
    const thruDateTo=this.partyForm.get('thruDateTo').value;
    this.thruDateTo=this.datePipe.transform(thruDateTo,"yyyy-MM-dd hh:mm:ss");
    this.spinner.show();
    const req={
      
        "fromDateFrom":  this.fromDateFrom,
        "fromDateFromType": this.partyForm.value.fromDateFromType,
        "fromDateTo":  this.fromDateTo,
        "fromDateToType": this.partyForm.value.fromDateToType,
        "isExempt": this.partyForm.value.isExempt,
        "isNexus":this.partyForm.value.isNexus,
        "partyId": this.partyForm.value.partyId,
        "partyTaxId":this.partyForm.value.partyTaxId,
        "partyTaxIdSearchType": this.partyForm.value.partyTaxIdSearchType,
        "thruDateFrom": this.thruDateFrom,
        "thruDateFromType":  this.partyForm.value.thruDateFromType,
        "thruDateTo":  this.thruDateTo,
        "thruDateToType":  this.partyForm.value.thruDateToType,
      
    }
    this.accountsService.getTaxParty(this.finSize,this.geo,this.party,req).subscribe((res: any) => {
      this.taxParty = res.data;
      this.spinner.hide();
    })
  }
  getTaxParty() {
    this.spinner.show();
    const req={
      
        "fromDateFrom": "",
        "fromDateFromType": "Contains",
        "fromDateTo": "",
        "fromDateToType": "Contains",
        "isExempt": "",
        "isNexus": "",
        "partyId": "",
        "partyTaxId": "",
        "partyTaxIdSearchType": "Contains",
        "thruDateFrom": "",
        "thruDateFromType": "Contains",
        "thruDateTo": "",
        "thruDateToType": "Contains"
      
    }
    this.accountsService.getTaxParty(this.finSize,this.geo,this.party,req).subscribe((res: any) => {
      this.taxParty = res.data;
      this.spinner.hide();
    })
  
  }
  
  getProductRate() {
    this.spinner.show();
    
    this.accountsService.getProductRate(this.finSize,this.geo,this.party).subscribe((res: any) => {
      this.productRate = res.data;
      this.spinner.hide();
    })
  
  }
  
  
  postAssociationList() {
    this.spinner.show();
    const req={
      "productCategoryId": "",
      "taxAuthGeoId":this.geo,
      "taxAuthPartyId":this.party
    }
    this.accountsService.postAssociationList(this.finSize,req).subscribe((res: any) => {
      this.associationList = res.data;
      this.spinner.hide();
    })
  
  }
  
  deleteTaxParty(partyId,fromdate) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteTaxParty(fromdate,partyId,this.geo,this.party).subscribe((res: any) => {   
          this.getTaxParty();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    }) 
  }
  deleteProductRate(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteProductRate(id,this.geo,this.party).subscribe((res: any) => {
          this.getProductRate();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteTaxGlAccounts(glAcc,orgId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "glAccountId": glAcc, "organizationPartyId":orgId,"taxAuthGeoId": this.geo,"taxAuthPartyId":this.party
        }
        this.accountsService.deleteTaxGlAccounts(req).subscribe((res: any) => { 
          this.getTaxGlAccounts();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )} else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })}
  deleteAssociationList(fromdate,toTaxAuthPartyId,toTaxAuthGeoId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "fromDate":fromdate,"taxAuthGeoId":  this.geo,"taxAuthPartyId": this.party,"toTaxAuthGeoId": toTaxAuthGeoId,"toTaxAuthPartyId": toTaxAuthPartyId
        }
        this.accountsService.deleteAssociationList(req).subscribe((res: any) => { 
          this.postAssociationList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
         Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )} else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })}
  taxCategoryListDelete(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "productCategoryId":id,
          "taxAuthGeoId": this.geo,
          "taxAuthPartyId":  this.party
        }
        this.accountsService.taxCategoryListDelete(req).subscribe((res: any) => {
          this.taxCategoryList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )} else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )} })}
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  createCategory(){
    this.router.navigate(['/financial/create-tax-category'],{ queryParams: { geo : this.geo,party : this.party}});
  }
  createAssociation(){
    this.router.navigate(['/financial/create-tax-association'],{ queryParams: { geo : this.geo,party : this.party}});
  }
  updateAssociation(efromdate,etoParty,etoGeo){
    this.router.navigate(['/financial/create-tax-association'],{ queryParams: { geo : this.geo,party : this.party,fromdate:efromdate,toParty:etoParty,toGeo:etoGeo}});
  }
  createGlAccount(){
    this.router.navigate(['/financial/create-tax-gl-account'],{ queryParams: { geo : this.geo,party : this.party}});
  }
  createProductRate(){
    this.router.navigate(['/financial/create-tax-product-rates'],{ queryParams: { geo : this.geo,party : this.party}});
  }
  updateProductRate(eauthProductSeqid){
    this.router.navigate(['/financial/create-tax-product-rates'],{ queryParams: { geo : this.geo,party : this.party,authProductSeqid:eauthProductSeqid}});
  }
  createParty(){
    this.router.navigate(['/financial/create-tax-party'],{ queryParams: { geo : this.geo,party : this.party}});
  }
  updateParty(epartyId,efromDate){
    this.router.navigate(['/financial/create-tax-party'],{ queryParams: { geo : this.geo,party : this.party,partyId:epartyId,fromDate:efromDate}});
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
