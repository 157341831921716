import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddFormModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        applicationDate:(data && data.applicationDate) ? data.applicationDate : null,
        approverPartyId:(data && data.approverPartyId) ? data.approverPartyId : null,
        jobRequisitionId:(data && data.jobRequisitionId) ? data.jobRequisitionId : null,
        applicationId:(data && data.applicationId) ? data.applicationId:null,
        statusId:(data && data.statusId) ? data.statusId:null
    });

    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   
   return ({
    applicationDate: new FormControl(data.applicationDate),
    approverPartyId: new FormControl(data.approverPartyId),
    jobRequisitionId: new FormControl(data.jobRequisitionId),
    applicationId:new FormControl(data.applicationId),
    statusId:new FormControl(data.statusId)
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
