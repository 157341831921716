import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ToastrService } from 'ngx-toastr';import { Location } from '@angular/common';
import Swal from 'sweetalert2'
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-shipment-plan-list',
  templateUrl: './shipment-plan-list.component.html',
  styleUrls: ['./shipment-plan-list.component.css']
})
export class ShipmentPlanListComponent implements OnInit {
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  shipmentPlans: any;
  financialList:any[]=[]
  shipmentPlansById: any;
  show=false;
  shipid: any;
  shipmentArray: any[]=[];
  downloading: boolean;

  constructor(readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService, readonly toastr: ToastrService,

    readonly _ToastrService: ToastrService, private _location: Location,
    readonly router: Router) {
  
  }
 
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getshipmentPlans();
  }
  
  getshipmentPlans() {
    this.spinner.show();
  
    this.productionService.getshipmentPlans(this.finSize).subscribe((res: any) => {
      this.shipmentPlans = res.data;
     
      this.spinner.hide();
    })
  
  }
  
  getshipmentPlansById(id) {
    this.spinner.show();
    this.shipmentArray=[]
    this.show=true;
  this.shipid=id;
    this.productionService.getshipmentPlansById(id).subscribe((res: any) => {
      this.shipmentPlansById = res.data.shipmentPlan;
      if(this.shipmentPlansById==null){
        this._ToastrService.success("No Record Found..");
      }
      else{
        this.shipmentArray.push(this.shipmentPlansById)
      }
      this.spinner.hide();
    })
  
  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  onSubmit() {
    this.spinner.show();

    const requestData = {
      "shipmentId":  this.shipid,
    }

    this.productionService.createProductionRunForShipment(requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
       
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  shipmentPlanStockPDF(): void {
    this.spinner.show();
   
    this.downloading = true;
    
    this.productionService.shipmentPlanStockPDF(this.shipid)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'application/application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
           link.download = `${this.shipid}.pdf`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });
      
}
  packageLabelPDF(): void {
    this.spinner.show();
   
    this.downloading = true;
    
    this.productionService.packageLabelPDF(this.shipid)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'application/application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
           link.download = `${this.shipid}.pdf`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });
      
}
  ship(id){
    this.router.navigate(["facilities/warehouse-shipment/warehouse-shipment-summary"], { queryParams: { data: id } })
  }
  createWeek() {
    this.router.navigate(["production/create-new-week"])

  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
  