import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';

import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddEmployeeFormService } from '../add-new-employee/add-new-employment-service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { EmployeeService } from '../employees/employess.service';
import { AddEmployeePositionService } from '../add-employee-position/add-employee-position.service';
import { ReallocationsService } from '../reallocation/reallocations.service';
import { DatePipe } from '@angular/common';

import { Location } from '@angular/common';

@Component({
  selector: 'app-hrm-performance-review',
  templateUrl: './hrm-performance-review.component.html',
  styleUrls: ['./hrm-performance-review.component.css']
})
export class HrmPerformanceReviewComponent implements OnInit {
  @ViewChild('closebutton') closebutton;

  products3: any;
  activeCategory=2;
  total=0;personId: any;
  partyIdArray: any[]=[];

  rowNumber=0;
  pageSize= 100;
  employeeList=[{
    "party":"ss"
  }]
  advanceSearch: {

    EmployeePartyID: string;
    PerfReviewID: string;
    ManagerPartyID: string;
    PaymentID: string;
    fromdateFrom: string;
    fromDateTo: string;
    throughDateFrom: string,
    throughDateTo: string,
    EmplPositionID: string,
    Comments: string,
  };
  pageNo = 1;
  rows = 50;
  search= ""
  private employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search

  }
  show: boolean;
  createReview:FormGroup;
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  positionType: any;
  positionArray: any[]=[];
  PerfReview: any;
  PerfReviewArray: any[]=[];
  actualfromdate: string;
  actualthroughdate: string;
  PaymentID: any;
  PaymentIDArray: any[]=[];
  PerfReviewData: any;
  constructor(readonly accountsService: AccountsService,
    readonly router: Router,
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly spinner: NgxSpinnerService,
    readonly employeeService: EmployeeService,
      private _location: Location,
      readonly _FormBuilder: FormBuilder,
    public addEmployeeFormService: AddEmployeeFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly toastr: ToastrService,
    readonly datePipe:DatePipe,
    readonly reallocationsService: ReallocationsService,
    ) { 
      this.createReview = this._FormBuilder.group({
        EmployeePartyID: [''],
        PerfReviewID: [''],
        ManagerPartyID: [''],
        ManagerRoleTypeID: [''],
        PaymentID: [''],
        EmplPositionID: [''],
        fromDate: [''],
        ThroughDate: [''],
        Comments:['']
      
      });
      this.advanceSearch={

        EmployeePartyID: "",
        PerfReviewID:"",
        ManagerPartyID: "",
        PaymentID:"",
        fromdateFrom: "",
        fromDateTo:"",
        throughDateFrom: "",
        throughDateTo: "",
        EmplPositionID:"",
        Comments: "",
      };
    }

  ngOnInit(): void {
    this.show=false;
    this.getPartyId();
    this.getRoleAllType();
    this.getPositionType();
    this.getPerfReview();
    this.getPaymentID();
    this.pageLoad();
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  reset(){
    this.spinner.show();
    this.advanceSearch.Comments="";
    this.advanceSearch.EmployeePartyID="";
    this.advanceSearch.ManagerPartyID="";
    this.advanceSearch.PaymentID="";
    this.advanceSearch.PerfReviewID="";
    this.advanceSearch.fromdateFrom="";
    this.advanceSearch.throughDateFrom=""
   
      this.accountsService.findPerfReviewDataList().subscribe((res:any)=> {
        this.PerfReviewData = res.data;
        this.spinner.hide();
      
      
      }) 
  }
  pageLoad(){
    this.spinner.show();
    this.advanceSearch.Comments="";
    this.advanceSearch.EmployeePartyID="";
    this.advanceSearch.ManagerPartyID="";
    this.advanceSearch.PaymentID="";
    this.advanceSearch.PerfReviewID="";
    this.advanceSearch.fromdateFrom="";
    this.advanceSearch.throughDateFrom=""
   
      this.accountsService.findPerfReviewDataList().subscribe((res:any)=> {
        this.PerfReviewData = res.data;
        this.spinner.hide();
      }) 
  }
  findPerfReviewData(){
    this.spinner.show();
    const req = {
      "comments": this.advanceSearch.Comments ? this.advanceSearch.Comments : '',
      "employeePartyId": this.advanceSearch.EmployeePartyID ? this.advanceSearch.EmployeePartyID : '',
      "managerPartyId": this.advanceSearch.ManagerPartyID ? this.advanceSearch.ManagerPartyID : '',
      "paymentId": this.advanceSearch.PaymentID ? this.advanceSearch.PaymentID : '',
      "perfReviewId": this.advanceSearch.PerfReviewID ? this.advanceSearch.PerfReviewID : '',
      "thruDate":this.datePipe.transform(this.advanceSearch.fromdateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.fromdateFrom, "yyyy-MM-dd hh:mm:ss") : '',
      "fromDate":this.datePipe.transform(this.advanceSearch.throughDateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.throughDateFrom, "yyyy-MM-dd hh:mm:ss") : '',
    }
      this.accountsService.findPerfReviewData(req.comments,req.employeePartyId,req.fromDate,req.managerPartyId,req.paymentId,req.perfReviewId,req.thruDate).subscribe((res:any)=> {
        this.PerfReviewData = res.data;
        this.spinner.hide();
      
      
      })
     
     }
     
   

  onSubmit() { 
    this.spinner.show();
    const fromActualdate=this.createReview.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createReview.get('ThroughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "comments": this.createReview.value.Comments,
  "emplPositionId": this.createReview.value.EmplPositionID,
  "employeePartyId": this.createReview.value.EmployeePartyID,
  // "employeeRoleTypeId": this.createReview.value.PartyTaxID,
  "employeeRoleTypeId":"EMPLOYEE",
  "fromDate": this.actualfromdate ,
  "fromDate_i18n": "",
  "managerPartyId":this.createReview.value.ManagerPartyID,
  "managerRoleTypeId": this.createReview.value.ManagerRoleTypeID,
  "paymentId": this.createReview.value.PaymentID,
  "perfReviewId": this.createReview.value.PerfReviewID,
  "thruDate": this.actualthroughdate,
  "thruDate_i18n": ""

    }
    this.accountsService.createPerfReview(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();

       
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
    });

  }

  getPositionType() {
    this.spinner.show();
    let emplPosition = {
      actualFromDateFrom: "",
      actualFromDateTo: "",
      actualThruDateFrom: "",
      actualThruDateTo: "",
      budgetId: "",
      budgetIdSearchType: "Contains",
      budgetItemSeqId: "",
      budgetItemSeqIdSearchType: "Contains",
      emplPositionId: "",
      emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.reallocationsService.getEmployeePosition(this.employessSize, emplPosition).subscribe(res => {

      this.positionType = res.data;
      this.spinner.hide();
      for (const value of this.positionType) {
        this.positionArray.push({
          label: value.emplPositionId,
          value: value.emplPositionId
        })
      }
    })
   
  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
     
     getPerfReview() {
      this.spinner.show();
      this.accountsService.getPerfReview().subscribe(res => {
        this.PerfReview = res.data;
        this.spinner.hide();
        for (const value of this.PerfReview) {
          this.PerfReviewArray.push({
            label: value.data.perfReviewId,
            value: value.data.perfReviewId
          })
        }
      })
    }

    getPaymentID() {
      this.spinner.show();
      this.accountsService.getPaymentID().subscribe(res => {
        this.PaymentID = res.data;
        this.spinner.hide();
        for (const value of this.PaymentID) {
          this.PaymentIDArray.push({
            label: value.data.paymentId,
            value: value.data.paymentId
          })
        }
      })
    }
    
  getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  employementDetail(id) {
    this.router.navigate(['/hr/detail-performce-review'], { queryParams: { previewId: id } })
  }
  employeeProfile(partyId){
    this.router.navigate(['/hr/hrm-main'],{queryParams:{partyId:partyId}})
  }

  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}

