<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" *ngIf="!payGradeId">The Skill Type of {{ this.skillType}} </span>
               <span class="color-black pl-1 titlePanels" *ngIf="payGradeId"> Add Salary Step [  {{payGradeId}}  ] </span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard" >
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px;">
                        <li *ngIf="skillType" [ngClass]="activeCategory==1?'active':''" ><a>
                            Skill Type Information</a></li>
                        <li *ngIf="payGradeId" [ngClass]="activeCategory==1?'active':''" ><a>
                            Salary Step</a></li>
                        <span class="edit-buttons create_skill">
                            <a  data-toggle="modal" *ngIf="payGradeId" (click)="updateFalse()"
                            data-target="#seqModal" class="leaveBtn">Create New Skill Type</a>
                        </span>
                    </ul>
                    
                </div>
                <div #target></div>

                <div class="modal fade" id="seqModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModal Title" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength p-width">
            <div class="modal-header">
                <span *ngIf="!updateSalary"> Add Salary Step [  {{payGradeId}}  ]</span>
                <span *ngIf="updateSalary"> Update Salary Step [  {{payGradeId}}  ]</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active">
                                    <a>
                                        Salary Step</a>
                                </li>


                            </ul>
                        </div>
                        <div class="panel-group">
                            <div>
                                <div class="panel-body">
                                    <form class="w-100" [formGroup]="salaryStepForm">
                                        <div *ngIf="activeCategory==1">
                                       
                                            <div class="panel-body">
                                                <div
                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="color-black container" *ngIf="!payGradeId">
                                                        <div class="row">
                                                            <div class="article-container" >
                                                                <div class="article" >
                                                                    <p class="col-lg-6 col-12">Skill Type ID</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{ this.skillType}}</span>
                                                                </div>
                                                                <div class="article">
                                                                    <p class="col-lg-6 col-12">Description</p>
                                                                    <span
                                                                        class="color-grey pl-20 col-lg-10 col-12">{{this.Des}}</span>
                                                                 
                                                                </div>
                                                              </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <ng-container *ngIf="payGradeId">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputAD">
                                                                    <label for="exampleInputEmail1">PayGrade Id</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="text" formControlName="payGradeId"  class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" disabled>
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputAD">
                                                                    <label for="exampleInputEmail1">Salary Step SeqId</label>
                                                                </div>
                                                                <div class="col-lg-3" *ngIf="!updateSalary">
                                                                    <input type="text" formControlName="salaryStepSeqId"  class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" placeholder="Enter salary Step SeqId">
                                                                </div>
                                                                <div class="col-lg-3" *ngIf="updateSalary">
                                                                    <input type="text" formControlName="salaryStepSeqId"  class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" placeholder="Enter salary Step SeqId">
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">From Date
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-3" >
                                                                    <input type="date" formControlName="fromDate"  class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" >
                                                  
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputAD">
                                                                    <label for="exampleInputEmail1">Through Date</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="date" formControlName="thruDate"  class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputAD">
                                                                    <label for="exampleInputEmail1">Date Modified</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="date" formControlName="dateModified"  class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" >
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputAD">
                                                                    <label for="exampleInputEmail1">Amount</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" formControlName="amount"  class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" placeholder="Enter Amount">
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInputAD">
                                                                    <label for="exampleInputEmail1">Created By User	</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" formControlName="createdByUserLogin"  class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" >
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputAD">
                                                                    <label for="exampleInputEmail1">Last Modified By User</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" formControlName="lastModifiedByUserLogin"  class="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" >
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div class="main-submit-button" style="margin-right: 10%;" *ngIf="!updateSalary">
                                                    <button type="submit" *ngIf="payGradeId"  (click)="onCreateSalaryStep()"
                                                    class="btn btn-secondary submit-btn">Create</button>
                                                </div>
                                                <div *ngIf="updateSalary" style="margin-left: -71%;" class="col-lg-12 col-12 main-submit-button">
                                                    <button type="submit" (click)="updateSalary()"
                                                        class="btn btn-secondary submit-btn">Update
                                                    </button>
                                                    <!-- <button type="submit" (click)="cancelSalary()"class="btn btn-danger ">Cancel
                                                    </button> -->
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
                </div>

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">
                                            <!-- <form class="w-100" [formGroup]="salaryStepForm">
                                                <div *ngIf="activeCategory==1">
                                               
                                                    <div class="panel-body">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container" *ngIf="!payGradeId">
                                                                <div class="row">
                                                                    <div class="article-container" >
                                                                        <div class="article" >
                                                                            <p class="col-lg-6 col-12">Skill Type ID</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{ this.skillType}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Description</p>
                                                                            <span
                                                                                class="color-grey pl-20 col-lg-10 col-12">{{this.Des}}</span>
                                                                         
                                                                        </div>
                                                                      </div>
                                                                </div>
                                                                
                                                            </div>
                                                            <ng-container *ngIf="payGradeId">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputAD">
                                                                            <label for="exampleInputEmail1">PayGrade Id</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="text" formControlName="payGradeId"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" disabled>
                                                                        </div>
    
                                                                        <div class="col-lg-3 form-group classInputAD">
                                                                            <label for="exampleInputEmail1">Salary Step SeqId</label>
                                                                        </div>
                                                                        <div class="col-lg-3" *ngIf="!updateSalary">
                                                                            <input type="text" formControlName="salaryStepSeqId"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Enter salary Step SeqId">
                                                                        </div>
                                                                        <div class="col-lg-3" *ngIf="updateSalary">
                                                                            <input type="text" formControlName="salaryStepSeqId"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Enter salary Step SeqId">
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">From Date
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-3" >
                                                                            <input type="date" formControlName="fromDate"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" >
                                                          
                                                                        </div>
    
                                                                        <div class="col-lg-3 form-group classInputAD">
                                                                            <label for="exampleInputEmail1">Through Date</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="date" formControlName="thruDate"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" >
                                                                        </div>
                                                                    </div>
    
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputAD">
                                                                            <label for="exampleInputEmail1">Date Modified</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="date" formControlName="dateModified"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" >
                                                                        </div>
    
                                                                        <div class="col-lg-3 form-group classInputAD">
                                                                            <label for="exampleInputEmail1">Amount</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="amount"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Enter Amount">
                                                                        </div>
                                                                    </div>
    
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputAD">
                                                                            <label for="exampleInputEmail1">Created By User	</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="createdByUserLogin"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" >
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputAD">
                                                                            <label for="exampleInputEmail1">Last Modified By User</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="email" formControlName="lastModifiedByUserLogin"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" >
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 10%;" *ngIf="!updateSalary">
                                                            <button type="submit" *ngIf="payGradeId"  (click)="onCreateSalaryStep()"
                                                            class="btn btn-secondary submit-btn">Create</button>
                                                        </div>
                                                        <div *ngIf="updateSalary" style="margin-left: -71%;" class="col-lg-12 col-12 main-submit-button">
                                                            <button type="submit" (click)="updateSalary()"
                                                                class="btn btn-secondary submit-btn">Update
                                                            </button>
                                                            <button type="submit" (click)="cancelSalary()"class="btn btn-danger ">Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form> -->
                                            
                                            <div class="w3-card-4 classCard">
                                                <div
                                                    class="all-users-infomation border-top-grey font-13 font-medium own-user-select userClass">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="salaryStepList"
                                                                [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total"
                                                                [scrollable]="true"
                                                                (sortFunction)="customSort($event)"
                                                                [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox
                                                                                    _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Salary Step SeqId
                                                                                <p-sortIcon
                                                                                    field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox"
                                                                                aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Amount
                                                                                <p-sortIcon
                                                                                    field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>

                                                                        <th pSortableColumn="price">
                                                                            <div style="color: white;">
                                                                                Action
                                                                            </div>
                                                                            <p-sortIcon field="price">
                                                                            </p-sortIcon>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value=""
                                                                                    autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body"
                                                                    let-product>
                                                                    <tr>
                                                                        <td>
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox
                                                                                    _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  
                                                                                  >
                                                                                 {{product.salaryStepSeqId}}
                                                                                </span>

                                                                            </div>
                                                                        </td>
                                                                        <td>{{product.amount}}
                                                                        </td>
                                                                        <td>
                                                                            <div>
                                                                                <svg class="hover" data-toggle="modal" data-target="#seqModal"
                                                                                    (click)="onUpdateSalary(product); scroll(target)"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="16"
                                                                                    height="16"
                                                                                    viewBox="0 0 21.223 21.222">
                                                                                    <g
                                                                                        transform="translate(0 -0.004)">
                                                                                        <g
                                                                                            transform="translate(0 1.52)">
                                                                                            <path
                                                                                                d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                transform="translate(0 -34.137)" />
                                                                                        </g>
                                                                                        <g
                                                                                            transform="translate(4.548 0.004)">
                                                                                            <g
                                                                                                transform="translate(0 0)">
                                                                                                <path
                                                                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                    transform="translate(-102.409 -0.004)" />
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                                <svg (click)="ondeleteSalaryStep(product)"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    class="ml-20"
                                                                                    width="16"
                                                                                    height="16"
                                                                                    viewBox="0 0 26 26">
                                                                                    <defs>
                                                                                        <style>
                                                                                            .a {
                                                                                                fill: #f44336;
                                                                                            }

                                                                                            .b {
                                                                                                fill: #fafafa;
                                                                                                margin-left: 20px;
                                                                                            }
                                                                                        </style>
                                                                                    </defs>
                                                                                    <path
                                                                                        class="a fill-color"
                                                                                        d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                    <path class="b"
                                                                                        d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                        transform="translate(-147.576 -147.576)" />
                                                                                </svg>
                                                                                <!-- <span [innerHtml]='appSvgIcons.delete' (click)="deleteTerminationType(product.terminationTypeId)"></span> -->
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <!-- <p class="paginate_data">
                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner> 