import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { WarehouseShipmentService } from '../warehouse-shipment/warehouse-shipment.service';
import { NewWarehouseShipmentService } from '../new-warehouse-shipment/new-warehouse-shipment.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-update-warehouse-shipment',
  templateUrl: './update-warehouse-shipment.component.html',
  styleUrls: ['./update-warehouse-shipment.component.css']
})
export class UpdateWarehouseShipmentComponent implements OnInit {
  activeCategory = 2;
  editMode: boolean;
  OrderIdsList: any[];
  PersonsAndPartyGroupsList: any[];
  getCurrencyList: any[];
  StatusIdList: any[];
  ShipmentTypeIdList: any[];

  facilities: any[];
  facilityId: string;
  shipmentId: any = [];
  updateWarehouseShipment: FormGroup;
  estimatedReadyDate: string;
  estimatedShipDate: string;
  estimatedArrivalDate: string;
  latestCancelDate: string;
  eventDate: string;
  url: string;
  constructor(readonly router: Router,
    readonly toastr: ToastrService,
  private _location: Location,
  readonly spinner: NgxSpinnerService,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly WarehouseShipmentService: WarehouseShipmentService,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe) {
      this.url='facilities/warehouse-shipment'
    this.updateWarehouseShipment = this._FormBuilder.group({
      statusId: [''],
      primaryOrderId: [''],
      primaryReturnId: [''],
      primaryShipGroupSeqId: [''],
      picklistBinId: [''],
      estimatedReadyDate: [''],
      estimatedShipDate: [''],
      estimatedArrivalDate: [''],
      latestCancelDate: [''],
      estimatedShipCost: [''],
      currencyUomId: [''],
      handlingInstructions: [''],
      originFacilityId: [''],
      originFacility: [''],
      originTelecomNumberId: [''],
      destinationFacilityId: [''],
      destinationTelecomNumberId: [''],
      partyIdTo: [''],
      partyIdFrom: [''],
      additionalShippingCharge: [''],
      addtlShippingChargeDesc: [''],
      eventDate: [''],
      shipmentTypeId: [''],
      shipmentId: [''],
      adress: ['']
    });
    this.editMode = false;
    this.facilities = [];
    this.StatusIdList = [];
    this.getCurrencyList = [];
    this.OrderIdsList = [];
    this.PersonsAndPartyGroupsList = [];
    this.ShipmentTypeIdList = []
    this.facilityId = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.router.routerState.root.queryParams.subscribe(params => {
      this.shipmentId = JSON.parse(params.data);

    });

    this.getOrderIds();
    this.getPersonsAndPartyGroups();
    this.getCurrency();
    this.getStatusId();
    this.getFacility();
    this.getShipmentTypeId();
    this.getAgreementById();
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getOrderIds() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getOrderIds().subscribe((responce) => {
      this.spinner.hide();
      this.OrderIdsList = responce.data.map(value => {
        return {
          label: value.orderId,
          value: value.orderId
        };
      });
    });
    
  }

  getPersonsAndPartyGroups() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getPersonsAndPartyGroups().subscribe((responce) => {
      this.spinner.hide();
      this.PersonsAndPartyGroupsList = responce.data.PersonsGroups.map(value => {
        return {
          label: value.groupName,
          value: value.partyId
        };
      });
    });
  
  }

  getCurrency() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getCurrency().subscribe((responce) => {
      this.spinner.hide();
      this.getCurrencyList = responce.data.currencyList.map(value => {
        return {
          label: value.description,
          value: value.uomId
        };
      });
    });
   
  }

  getStatusId() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getStatusId().subscribe((responce) => {
      this.spinner.hide();
      this.StatusIdList = responce.data.map(value => {
        return {
          label: value.description,
          value: value.statusId
        };
      });

    });
   
  }

  getFacility(): void {
    this.spinner.show();
    this.WarehouseShipmentService.getFacility()
      .then(data => {
        this.spinner.hide();
        this.facilities = data.data.map(value => {
          return {
            label: value.facilityName,
            value: value.facilityId
          };
        });
      });
     
  }
  getShipmentTypeId() {
    this.spinner.show();
    this.WarehouseShipmentService.getShipmentTypeIdList().subscribe((responce) => {
      this.ShipmentTypeIdList = responce.data.map(value => {
        this.spinner.hide();
        return {
          label: value.shipmentTypeId,
          value: value.shipmentTypeId
        };
      });
    });
   
  }

  submit(): void {
    this.spinner.show();
    var shipmentId = this.shipmentId;
    this.estimatedReadyDate = this.datePipe.transform(this.updateWarehouseShipment.value.estimatedReadyDate, "yyyy-MM-dd hh:mm:ss"),
      this.estimatedShipDate = this.datePipe.transform(this.updateWarehouseShipment.value.estimatedShipDate, "yyyy-MM-dd hh:mm:ss"),
      this.estimatedArrivalDate = this.datePipe.transform(this.updateWarehouseShipment.value.estimatedArrivalDate, "yyyy-MM-dd hh:mm:ss"),
      this.latestCancelDate = this.datePipe.transform(this.updateWarehouseShipment.value.latestCancelDate, "yyyy-MM-dd hh:mm:ss"),
      this.eventDate = this.datePipe.transform(this.updateWarehouseShipment.value.eventDate, "yyyy-MM-dd hh:mm:ss")
    if (this.updateWarehouseShipment.value.destinationTelecomNumberId == null) {
      this.updateWarehouseShipment.value.destinationTelecomNumberId = ""
    }
    if (this.updateWarehouseShipment.value.handlingInstructions == null) {
      this.updateWarehouseShipment.value.handlingInstructions = "";
    }
    if (this.updateWarehouseShipment.value.originTelecomNumberId == null) {
      this.updateWarehouseShipment.value.originTelecomNumberId = "";
    }
    if (this.updateWarehouseShipment.value.picklistBinId == null) {
      this.updateWarehouseShipment.value.picklistBinId = "";
    }

    const requestData = {
      statusId: this.updateWarehouseShipment.value.statusId,
      primaryOrderId: this.updateWarehouseShipment.value.primaryOrderId,
      primaryReturnId: this.updateWarehouseShipment.value.primaryReturnId,
      primaryShipGroupSeqId: this.updateWarehouseShipment.value.primaryShipGroupSeqId,
      picklistBinId: this.updateWarehouseShipment.value.picklistBinId,
      estimatedReadyDate: this.estimatedReadyDate,
      estimatedShipDate: this.estimatedShipDate,
      estimatedArrivalDate: this.estimatedArrivalDate,
      latestCancelDate: this.latestCancelDate,
      estimatedShipCost: this.updateWarehouseShipment.value.estimatedShipCost,
      currencyUomId: this.updateWarehouseShipment.value.currencyUomId,
      handlingInstructions: this.updateWarehouseShipment.value.handlingInstructions,
      originFacilityId: this.updateWarehouseShipment.value.originFacilityId,

      originContactMechId: this.updateWarehouseShipment.value.adress,
      originTelecomNumberId: this.updateWarehouseShipment.value.originTelecomNumberId,
      destinationContactMechId: this.updateWarehouseShipment.value.destinationFacilityId,
      destinationFacilityId: this.updateWarehouseShipment.value.originFacility,
      destinationTelecomNumberId: this.updateWarehouseShipment.value.destinationTelecomNumberId,
      partyIdTo: this.updateWarehouseShipment.value.partyIdTo,
      partyIdFrom: this.updateWarehouseShipment.value.partyIdFrom,
      additionalShippingCharge: this.updateWarehouseShipment.value.additionalShippingCharge,
      addtlShippingChargeDesc: this.updateWarehouseShipment.value.addtlShippingChargeDesc,
      eventDate: this.eventDate,
      shipmentTypeId: this.updateWarehouseShipment.value.shipmentTypeId,
    };
    this.NewWarehouseShipmentService.updateFacilityShipment(shipmentId, requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Updated");
        this.spinner.hide();
        this.router.navigate([this.url])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }

  cancel() {
    this.updateWarehouseShipment.reset();
    this.router.navigate([this.url]);
  }

  getAgreementById() {
    this.spinner.show();
    const shipmentId = this.shipmentId;
    this.NewWarehouseShipmentService.getFacilityShipmentById(shipmentId).subscribe((responce: any) => {

      this.estimatedReadyDate = this.datePipe.transform(responce.data.shipment.estimatedReadyDate, "yyyy-MM-dd"),
        this.estimatedShipDate = this.datePipe.transform(responce.data.shipment.estimatedShipDate, "yyyy-MM-dd"),
        this.estimatedArrivalDate = this.datePipe.transform(responce.data.shipment.estimatedArrivalDate, "yyyy-MM-dd"),
        this.latestCancelDate = this.datePipe.transform(responce.data.shipment.latestCancelDate, "yyyy-MM-dd"),
        this.eventDate = this.datePipe.transform(responce.data.shipment.eventDate, "yyyy-MM-dd"),
        setTimeout(() => {
          this.updateWarehouseShipment.patchValue({
            statusId: responce.data.shipment.statusId,
            primaryOrderId: responce.data.shipment.primaryOrderId,
            primaryReturnId: responce.data.shipment.primaryReturnId,
            primaryShipGroupSeqId: responce.data.shipment.primaryShipGroupSeqId,
            picklistBinId: responce.data.shipment.picklistBinId,
            estimatedReadyDate: this.estimatedReadyDate,
            estimatedShipDate: this.estimatedShipDate,
            estimatedArrivalDate: this.estimatedArrivalDate,
            latestCancelDate: this.latestCancelDate,
            estimatedShipCost: responce.data.shipment.estimatedShipCost,
            currencyUomId: responce.data.shipment.currencyUomId,
            handlingInstructions: responce.data.shipment.handlingInstructions,
            originFacilityId: responce.data.shipment.originFacilityId,
            adress: responce.data.shipment.originContactMechId,
            originTelecomNumberId: responce.data.shipment.originTelecomNumberId,
            originFacility: responce.data.shipment.destinationFacilityId,
            destinationFacilityId: responce.data.shipment.destinationContactMechId,
            destinationTelecomNumberId: responce.data.shipment.destinationTelecomNumberId,
            partyIdTo: responce.data.shipment.partyIdTo,
            partyIdFrom: responce.data.shipment.partyIdFrom,
            additionalShippingCharge: responce.data.shipment.additionalShippingCharge,
            addtlShippingChargeDesc: responce.data.shipment.addtlShippingChargeDesc,
            eventDate: this.eventDate,
            shipmentTypeId: responce.data.shipment.shipmentTypeId,
            shipmentId: responce.data.shipment.shipmentId,
          });


        }, 3000);
        this.spinner.hide();

    });
  
  }

  back() {
    this.router.navigate([this.url])
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

