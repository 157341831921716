
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { EmpPhoneModel } from './update-phone-model';

@Injectable({
  providedIn: 'root'
})

export class EmpPhoneFormService {
    emp_phone_form: FormGroup;

  constructor(public _formBuilder: FormBuilder,
    public empPhoneModel: EmpPhoneModel
  ) { }

  createEventForm(data: any): void {
    if (!this.emp_phone_form) {
      this.emp_phone_form = this._formBuilder.group(this.empPhoneModel.mapDataToModel(data));

    }

  }

}
