<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12 pad_0">
            
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Current Project : {{fixedAssetId?fixedAssetId:''}} </span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>

            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard border_wdth_1">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                <a>Summary</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                <a>Fixed Asset</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                <a>Children</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                <a>Products</a>
                            </li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)">
                                <a>Calendar</a>
                            </li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)">
                                <a>Standard Costs</a>
                            </li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)">
                                <a>Identifications</a>
                            </li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategory(8)">
                                <a>Registrations</a>
                            </li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategory(9)">
                                <a>Meter Readings</a>
                            </li>
                            <li [ngClass]="activeCategory==10?'active':''" (click)="changeactiveCategory(10)">
                                <a>Maintenances</a>
                            </li>
                            <li [ngClass]="activeCategory==11?'active':''" (click)="changeactiveCategory(11)">
                                <a>Assignments</a>
                            </li>
                            <li [ngClass]="activeCategory==12?'active':''" (click)="changeactiveCategory(12)">
                                <a>Depreciation</a>
                            </li>
                            <li [ngClass]="activeCategory==13?'active':''" (click)="changeactiveCategory(13)">
                                <a>Geo Location</a>
                            </li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div *ngIf="activeCategory==1" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                                                    <p class="h-text">Summary</p>
                                                    <span>
                                                  <!--   <button type="submit" style="margin: 0 !important;" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="">Update</button> -->
                                            </span>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="color-black container">
                                                        <div class="row" *ngIf="this.fixAsset">
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Fixed Asset Id</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.fixAsset.fixedAssetId}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Fixed Asset Name</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.fixAsset.fixedAssetName}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Fixed Asset Type ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.fixAsset.fixedAssetTypeId}}   
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Instance Of Product ID</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.fixAsset.instanceOfProductId}}  
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Role Type Id</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.fixAsset.roleTypeId}}    
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Party Id</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.fixAsset.partyId}}  
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Umo Id</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.fixAsset.uomId}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Calendar Id</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.fixAsset.calendarId}}  
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Class Enum Id</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.fixAsset.classEnumId}}  
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Salvage Value</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.fixAsset.salvageValue}}  
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Purchase Cost</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.fixAsset.purchaseCost}}  
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6">Production Capacity</p>
                                                                        <span class="color-grey pl-20 font-weight-bold">
                                                                            {{this.fixAsset.productionCapacity}} 
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==2" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Fixed Asset</p>
                                                </div>
                                                <form class="w-100" [formGroup]="createFixAssetForm">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Fixed Asset ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="fixedAssetId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fixed Asset ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Fixed Asset Type ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown formControlName="fixedAssetTypeId" [options]="fixedAssetTypes" placeholder="Enter Fixed Asset Type ID" filter="true">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Fixed Asset Parent ID </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown formControlName="parentFixedAssetId" [options]="fixAssetIds" placeholder="Enter Fixed Asset Parent ID" filter="true">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Instance Of Product ID </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown formControlName="instanceOfProductId" [options]="productIds" placeholder="Enter Instance Of Product ID" filter="true">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Class Enum ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown formControlName="classEnumId" [options]="classEnumId" placeholder="Enter Class Enum ID" filter="true">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Customer ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown formControlName="partyId" placeholder="Enter Customer ID" filter="true" [options]="partyIds">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Role Type ID
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="roleTypeIds" formControlName="roleTypeId" placeholder="Enter RoleType ID" filter="true">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Fixed Asset Name </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="fixedAssetName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fixed Asset Name">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Acquire Order ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="acquireOrderId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Acquire Order ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Acquire Order Item Seq ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" formControlName="acquireOrderItemSeqId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Acquire Order Item Seq ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Date Acquired</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="dateAcquired" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Date Acquired">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Date Last Serviced
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="dateLastServiced" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Date Last Serviced">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Date Next Service</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="dateNextService" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Date Next Service">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Expected End Of Life </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="expectedEndOfLife" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Expected End Of Life">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Actual End Of Life</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="actualEndOfLife" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Actual End Of Life">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Production Capacity</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="productionCapacity" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Production Capacity">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">UOM</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown formControlName="uomId" [options]="uomIds" placeholder="Enter UOM" filter="true">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Calendar</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown formControlName="calendarId" [options]="calendar" placeholder="Enter Calendar" filter="true">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Located At Facility ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown formControlName="locatedAtFacilityId" [options]="facilityId" placeholder="Enter Located At Facility ID" filter="true">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Located At Location Seq ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown formControlName="locatedAtLocationSeqId" [options]="facilityLocation" placeholder="Enter Located At Location Seq ID" filter="true">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Salvage Value</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="salvageValue" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Salvage Value">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Depreciation</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="depreciation" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Depreciation">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Purchase Cost</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="purchaseCost" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase Cost">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Purchase Cost Uom ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" formControlName="partyId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase Cost Uom ID">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-10 main-submit-button">
                                                        <button type="submit" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==3" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Fixed Asset Children </p>
                                                </div>
                                                <div class="panel-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="color-black container">
                                                            <p-tree [value]="FixedAssetChildren" selectionMode="single" [(selection)]="selectedFiles" (onNodeSelect)="nodeSelect($event)"></p-tree>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==4" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">List Fixed Asset Products</p>
                                                    <button (click)="productReset()" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#linkProductpopUp">Link New Product</button>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="fixedAssetProductData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Product
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Fixed Asset Product Type
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span class="account-button">{{product.productId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.fixedAssetProductType}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.fromDate}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>
                                                                                    <a (click)="updateProductData(product)"   class="account-button"  data-toggle="modal" data-target="#linkProductpopUp" style="color: #0d3769!important;cursor: pointer;">Update</a>&nbsp;
                                                                                    <a (click)="removefixedAssetProduct(product)" class="detail-btn ml-2" style="color: #0d3769!important;cursor: pointer;">Remove</a>
                                                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                    </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==5" class="w-100">

                                            <div class="w3-card-4 classCard">
                                                <div class="row text-center d-flex align-items-center" style="padding-top:20px">
                                                    <div class="col-md-4 d-flex" style="padding-left:30px">
                                                        <div class="btn-group">
                                                            <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                                                Previous
                                                            </div>
                                                            <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                                                                Today
                                                            </div>
                                                            <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                                                Next
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <h3 class="mb-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                                                    </div>
                                                    <div class="col-md-4 d-flex justify-content-end" style=" padding-right:40px">
                                                        <div class="btn-group">
                                                            <div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
                                                                Month
                                                            </div>
                                                            <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                                                                Week
                                                            </div>
                                                            <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                                                                Day
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div [ngSwitch]="view">
                                                    <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                                                    </mwl-calendar-month-view>
                                                    <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                                                    </mwl-calendar-week-view>
                                                    <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                                                    </mwl-calendar-day-view>
                                                </div>

                                                <!-- Everything you see below is just for the demo, you don't need to include it in your app -->

                                                <br /><br /><br />

                                                <h3>
                                                    <!-- <span class="text-black" *ngIf="isForm">Add/modify a calendar event</span> -->
                                                    <button class="btn btn-primary float-right" *ngIf="isButton" (click)="addEvent()">
                                                    Add new
                                                  </button>
                                                    <div class="clearfix"></div>
                                                </h3>
                                                <ng-container *ngIf="isForm">
                                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">

                                                        <div class="w3-card-4 classCard w-100">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                                                                <p class="h-text">Add/modify a calendar event</p>
                                                                <span>
                                                                    <button type="submit" style="margin: 0 !important;"  *ngIf="isUpdate" (click)="cancelCalenderEvent()" class="btn btn-secondary submit-btn mr-2">Cancel a calendar event</button>
                                                                    <button type="submit" style="margin: 0 !important;" *ngIf="isForm" (click)="addNew()" class="btn btn-secondary submit-btn">Add New</button>
                                                        </span>
                                                            </div>
                                                            <form [formGroup]="calendarEventForm1">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Event Name</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" formControlName="eventName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Event Name">
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Desription</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="text" formControlName="desc" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Desription">
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Event Type
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown formControlName="eventType" 
                                                                                [options]="EventTypeArray"
                                                                                placeholder="Enter Event Type" filter="true">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Status
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown formControlName="status" [options]="CalStatusListArray" placeholder="Enter Status" filter="true">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Scope
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown formControlName="scope" 
                                                                               [options]="ScopeListArray" placeholder="Enter Scope" filter="true">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Estimated Start Date</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" formControlName="startDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Start Date">
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Estimated Completion date</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" formControlName="compDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Completion date">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-10 main-submit-button">
                                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                                    (click)="submit()">{{isUpdate ? 'Update' : 'Add'}}</button>
                          
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div class="port-header-contact create-lead font-medium mb-0 w-100" *ngIf="isUpdate">
                                                        <div class="w3-card-4 classCard mt-2 w-100">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                                                                <p class="h-text">Participants</p>
                                                                <span>
                                                           </span>
                                                            </div>
                                                            <form [formGroup]="ParticipantsForm">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-md-5 ml-4">
                                                                        <div class="form-group">
                                                                            <div class="d-flex flex-column">
                                                                                <label for="training">
                                                                                    Party ID</label>
                                                                                <p-dropdown [options]="PartyIdArray" filter="true"
                                                                                 formControlName="PartyId" placeholder=" Party ID"> </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-5 ml-4">
                                                                        <div class="form-group">
                                                                            <label for="description">Role Type ID <span class="text-danger">*</span></label>
                                                                            <p-dropdown [options]="CalenderRoleArray" filter="true" formControlName="RoleTypeID" 
                                                                            placeholder="Role Type ID"> </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                 
                                                                    <div class="col-md-10">
                                                                        <div class="d-flex flex-row-reverse bd-highlight">
                                                                            <button class="btn btn-secondary cursor-pointer ml-2 p-2 bd-highlight"
                                                                             (click)="createMyPortalParticipants()">Add</button>
                                                                            <!-- <button class="btn btn-primary cursor-pointer ml-2 p-2 bd-highlight" (click)="submit()">{{isUpdate ? 'Update' : 'Add'}}</button> -->
                                                                        </div>
                                                                    </div>
    
    
                                                                </div>
                                                            </form>
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="ParticipantsList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                
Party ID
                                                                                                <p-sortIcon field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
            
                                                                                        <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Role Type ID
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Status ID
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
            
                                                                                        <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Remove
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
            
                                                                                     
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{ 'width': '180px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                <span class="account-button">{{product.partyId}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{ 'width': '180px'}">
                                                                                            <span>{{product.roleTypeId}}</span>
                                                                                        </td>
                                                                                        <td [ngStyle]="{ 'width': '180px'}">
                                                                                            <span>{{product.statusId}}</span>
                                                                                        </td>
                                                                                      
                                                                                        <td [ngStyle]="{ 'width': '180px'}">
                                                                                            <span>
                                                                                                <a style="color: #0d3769!important;cursor: pointer;" (click)="deleteMyPortalAParticipants(product)" 
                                                                                                  class="account-button"  >Remove</a>
                                                                                        </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found</h4>
                                                                                                </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>
            
                                                                            <p class="paginate_data">
                                                                                View per page
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="mt-5 mb-5">
                                                       
                                                        <ng-container *ngIf="isUpdate">
                                                            <div class="mt-5 mb-5">
                                                                <h4 class="text-black">Participants</h4>
                                                                <hr />
                                                                <form>
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <div class="form-group">
                                                                                <div class="d-flex flex-column">
                                                                                    <label for="partyId">Party Id</label>
                                                                                    <div class="d-flex justify-content-between">
                                                                                        <p-dropdown [options]="partyIds" filter="true" [(ngModel)]="partyId" [ngModelOptions]="{standalone:true}" placeholder="Party Id"></p-dropdown>
                                                                                        <button class="btn btn-primary cursor-pointer" (click)="assignEvent()">Assign</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </ng-container>
                                                    </div> -->
                                                </ng-container>


                                                <!-- <ng-container *ngIf="isUpdate">
                                                    <div class="table-responsive">
                                                        <table class="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Employee Party ID</th>
                                                                    <th> Training Request ID</th>
                                                                    <th>Training Class Type ID</th>
                                                                    <th> Approver Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let event of assignList">
                                                                    <td>
                                                                        {{event.partyId}}
                                                                    </td>
                                                                    <td>
                                                                        {{event.trainingRequestId}}
                                                                    </td>
                                                                    <td>
                                                                        {{event.trainingClassTypeId}}
                                                                    </td>
                                                                    <td>{{event.approvalStatus}}
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="!assignList.length">
                                                                    <td>No Data</td>
                                                                    <td>No Data</td>
                                                                    <td>No Data</td>
                                                                    <td>No Data</td>

                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </ng-container> -->

                                                <ng-template #modalContent let-close="close">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title">Event action occurred</h5>
                                                        <button type="button" class="close" (click)="close()">
                                                      <span aria-hidden="true">&times;</span>
                                                    </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div>
                                                            Action:
                                                            <pre>{{ modalData?.action }}</pre>
                                                        </div>
                                                        <div>
                                                            Event:
                                                            <pre>{{ modalData?.event | json }}</pre>
                                                        </div>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-outline-secondary" (click)="close()">
                                                      OK
                                                    </button>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==6" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Fixed Asset Standard Cost</p>
                                                    <button (click)="resetStandard()" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addStandardCostPopup">Add Standard Cost</button>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="StandardCostList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Standard Cost Type
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Amount Uom ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Amount
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span class="account-button">{{product.data.fixedAssetStdCostTypeId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.data.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.data.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.data.amountUomId}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.data.amount}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>
                                                                                    <a (click)="updateStandardData(product)"   class="account-button"  data-toggle="modal" data-target="#addStandardCostPopup">Update</a>&nbsp;<a (click)="cancelFixedAssetStdCost(product)"  class="account-button" >Remove</a>
                                                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                    </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==7" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Fixed Asset Identificaions</p>
                                                    <button (click)="resetIdent()" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addIdentificationPopup">Add Identification</button>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="IdentificationsList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Identification Type
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Value
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span class="account-button">{{product.identData[0].description}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.data.idValue}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>
                                                                                    <a (click)="updateIdentificationData(product)"   class="account-button"  data-toggle="modal" data-target="#addIdentificationPopup">Update</a>&nbsp;<a (click)="removeFixedAssetIdent(product)"  class="account-button" >Remove</a>
                                                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                    </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==8" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Fixed Asset Registrations</p>
                                                    <button type="submit" (click)="resetRegistartion()" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addRegistrationpopUp">Add Registration</button>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="FixedAssetRegistrationList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    From Date
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Registration Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Government Agency
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Registration Number
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    License Number
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span class="account-button">{{product.fromDate}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.thruDate}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.registrationDate}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.data.govAgencyPartyId}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.data.registrationNumber}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>{{product.data.licenseNumber}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '180px'}">
                                                                                <span>
                                                                                    <a (click)="updateRegistartion(product)" style="color: #0d3769!important;cursor: pointer;"   class="account-button"  data-toggle="modal" data-target="#addRegistrationpopUp">Update</a>&nbsp;
                                                                                    <a (click)="deleteFixedAssetRegistration(product)" style="color: #0d3769!important;cursor: pointer;" class="detail-btn ml-2">Remove</a>
                                                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                    </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==9" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Fixed Asset Meter Readings</p>
                                                    <button type="submit" (click)="resetMeter();" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addMeterReadingspopUp">Add Meter Reading</button>
                                                    <!--                                                   <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addMaintenancepopUp">Add Maintenance</button> -->
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="MeterReadingList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width': '150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Product Meter Type ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{ 'width': '250px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Reading Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Meter Value
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span class="account-button">{{product.data.productMeterTypeId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.readingDate}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.data.meterValue}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>
                                                                                    <a (click)="updateMeterData(product)" style="color: #0d3769!important;cursor: pointer;"    class="account-button"   data-toggle="modal" data-target="#addMeterReadingspopUp">Update</a>&nbsp;
                                                                                    <a (click)="deleteFixedAssetMeter(product)" style="color: #0d3769!important;cursor: pointer;" class="detail-btn ml-2">Remove</a>
                                                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                    </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==10" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">List Fixed Asset Maintenances </p>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addMaintenancepopUp">Add Maintenance</button>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="ListFixedAssetMaints" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width': '150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Maintenance History Seq ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{ 'width': '250px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Product Maintenance Type ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Status
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Interval Meter Type
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Interval Quantity
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Interval UOM
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span class="account-button">{{product.findFixedAssetMaint.maintHistSeqId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.findFixedAssetMaint.productMaintSeqId}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.findFixedAssetMaint.statusId}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.findFixedAssetMaint.intervalMeterTypeId}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.findFixedAssetMaint.intervalQuantity}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.findFixedAssetMaint.intervalUomId}}</span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                    </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==11" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Edit Party Fixed Asset Assignments</p>
                                                    <button (click)="resetAssign()" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addAssignmentpopUp">Add Assignment</button>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="AssignmentList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width': '170px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Party ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Role Type ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Allocated Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Status ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Comments
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span class="account-button">{{product.partyId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.roleTypeId}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.allocatedDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.statusId}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.comments}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>
                                                                                    <a  (click)="updateAssignMent(product)"  class="account-button"  data-toggle="modal" data-target="#addAssignmentpopUp">Update</a>&nbsp;<a (click)="deleteAssignmentList(product)"  class="account-button" >Remove</a>
                                                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                    </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==12" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Fixed Asset Depreciation Report</p>
                                                </div>
                                                <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                                    <div class="w3-card-4 classCard w-100 mt-2">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Depreciation History</p>
                                                        </div>
                                                        <div class="color-black container">
                                                            <div class="row">
                                                                <div class="w-100" style="padding: 0 0 10px 10px;">
                                                                    <p>Purchase Cost<span class="color-grey pl-20 font-weight-bold">0</span></p><br />
                                                                    <p>Depreciation<span class="color-grey pl-20 font-weight-bold">0</span></p><br />
                                                                    <p>Salvage Value<span class="color-grey pl-20 font-weight-bold">0</span></p><br />
                                                                    <p>Date Acquired<span class="color-grey pl-20 font-weight-bold"></span></p><br />
                                                                    <p>Expected End of Life<span class="color-grey pl-20 font-weight-bold"></span></p><br />
                                                                    <p>Next Depreciation Amount<span class="color-grey pl-20 font-weight-bold"></span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!-- <div class="panel-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="color-black container">
                                                            <div class="row">
                                                                <div class="w-100" style="padding: 0 0 10px 10px;">
                                                                    <p>Purchase Cost<span class="color-grey pl-20 font-weight-bold">hjhj</span></p><br />
                                                                    <p>Depreciation<span class="color-grey pl-20 font-weight-bold">hjhj</span></p><br />
                                                                    <p>Salvage Value<span class="color-grey pl-20 font-weight-bold">hjhj</span></p><br />
                                                                    <p>Date Acquired<span class="color-grey pl-20 font-weight-bold">hjhj</span></p><br />
                                                                    <p>Expected End of Life<span class="color-grey pl-20 font-weight-bold">hjhj</span></p><br />
                                                                    <p>Next Depreciation Amount<span class="color-grey pl-20 font-weight-bold">hjhj</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                            </div>
                                            <div class="w3-card-4 classCard w-100 mt-2">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Fixed Asset Depreciation Method</p>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addDepreciationpopup">Add Depreciation Method</button>
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="DepreciationCustomMethodList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width': '170px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Depreciation custom method
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>



                                                                            <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                            ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span class="account-button">{{product.data.depreciationCustomMethodId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.data.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                            </td>
                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>{{product.data.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                            </td>


                                                                            <td [ngStyle]="{ 'width': '170px'}">
                                                                                <span>
                                                                                   <a (click)="deleteFixedAssetDepMethod(product)" style="color: #0d3769!important;cursor: pointer;"  class="account-button" >Remove</a>
                                                                            </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                    </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w3-card-4 classCard w-100 mt-2">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">GL Mappings</p>
                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addGLpopup">Add GL Mapping</button>
                                                </div>
                                                <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                                    <div class="w-100">
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs" >
                                                                <h4 class="common-styling h4Margin">
                                                                    Fixed Asset Mappings
                                                                </h4>
                                                            </div>
                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs" >
                                                                <h4 class="common-styling h4Margin">
                                                                    Global Mappings
                                                                </h4>
                                                            </div>
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width': '150px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Fixed Asset Type ID
                                                                                                <p-sortIcon field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{ 'width': '250px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Asset GL Account
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Accumulated depreciation GL Account
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Depreciation GL Account
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Profit GL Account
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{ 'width': '170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Loss GL Account
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                        ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{ 'width': '170px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                <!-- <span>{{product}}</span> -->
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{ 'width': '170px'}">
                                                                                            <!-- <span>{{product}}</span> -->
                                                                                        </td>
                                                                                        <td [ngStyle]="{ 'width': '170px'}">
                                                                                            <!-- <span>{{product}}</span> -->
                                                                                        </td>
                                                                                        <td [ngStyle]="{ 'width': '170px'}">
                                                                                            <!-- <span>{{product}}</span> -->
                                                                                        </td>
                                                                                        <td [ngStyle]="{ 'width': '170px'}">
                                                                                            <!-- <span>{{product}}</span> -->
                                                                                        </td>
                                                                                        <td [ngStyle]="{ 'width': '170px'}">
                                                                                            <!-- <span>{{product}}</span> -->
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found</h4>
                                                                                                </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                View per page
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="w3-card-4 classCard w-100 mt-2">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Fixed Asset Depreciation Report</p>
                                                </div>
                                            </div>
                                            <div class="w3-card-4 classCard w-100 mt-2">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Transactions</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==13" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <p class="h-text">Fixed Asset Geo Location </p>

                                                </div>
                                                <div class="port-header-contact create-lead font-medium mb-0 w-100" style="padding-bottom: 0;">
                                                    <label><span>Latitude:{{this.GeoLocation.geoPointsData[0].latitude}}</span>
                                                    &nbsp;&nbsp;<span>Longitude:{{this.GeoLocation.geoPointsData[0].longitude}}</span>
                                                    &nbsp;&nbsp; <span>Elevation:{{this.GeoLocation.geoPointsData[0].elevation}}</span></label>
                                                    <br>
                                                    <br>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="linkProductpopUp" tabindex="-1" role="dialog" aria-labelledby="linkProductpopUpTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Link New Product to Fixed Asset</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Link New Product to Fixed Asset</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="linkProduct">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown placeholder="Enter Product ID" filter="true" [options]="productIds" formControlName="productID">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Type of Usage</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown placeholder="Enter Type of usage" filter="true" [options]="productTypeIds" formControlName="typeUsage">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="fromDate" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="thruDate" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="comments" placeholder="Enter Comments">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sequence Num</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="seqNum" placeholder="Enter Sequence Num">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quantity</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="quantity" placeholder="Enter Quantity">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Quantity UOM ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown placeholder="Enter Quantity Uom ID" filter="true" [options]="uomIds" formControlName="quantityUOMID">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 main-submit-button">
                                                <button *ngIf="!this.showProduct" (click)="postfixedAssetProduct()" type="submit" class="btn btn-secondary submit-btn">Save</button>
                                                <button *ngIf="this.showProduct" (click)="putfixedAssetProduct()" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addStandardCostPopup" tabindex="-1" role="dialog" aria-labelledby="addStandardCostPopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Fixed Asset Standard Cost</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonA>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Fixed Asset Standard Cost</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addStandardCost">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Standard Cost Type</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="StandardCostTypeArray" optionlabel="label" formControlName="costType" placeholder="Enter Standard Cost Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="fromDate" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="thruDate" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Amount UOM ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="currencyArray" optionlabel="label" formControlName="amountUOMID" placeholder="Enter Amount UOM ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Amount</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="amount" placeholder="Enter Amount">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 main-submit-button">
                                                <button *ngIf="!this.showStandard" (click)="createFixedAssetStdCost();" type="submit" class="btn btn-secondary submit-btn">Add</button>
                                                <button *ngIf="this.showStandard" (click)="updateFixedAssetStdCost();" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addIdentificationPopup" tabindex="-1" role="dialog" aria-labelledby="addIdentificationPopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Fixed Asset Identification</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonB>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Fixed Asset Identification</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addIdentification">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Identification Type</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="IdentificationsTypeArray" filter="true" optionlabel="label" formControlName="identificationType" placeholder="Enter Identification Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Value</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="value" placeholder="Enter Value">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 main-submit-button">
                                                <button *ngIf="!this.showIdentification" (click)="createFixedAssetIdent();" type="submit" class="btn btn-secondary submit-btn">Add</button>
                                                <button *ngIf="this.showIdentification" (click)="updateFixedAssetIdent();" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addRegistrationpopUp" tabindex="-1" role="dialog" aria-labelledby="addRegistrationpopUpTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Fixed Asset Registration</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonC>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Fixed Asset Registration</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addRegistration">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="fromDate" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="thruDate" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Registration Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="regDate" placeholder="Enter Registration Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Government Agency</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="partyIds" filter="true" optionlabel="label" formControlName="govAgency" placeholder="Enter Government Agency">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Registration Number</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="regNumber" placeholder="Enter Registration Number">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">License Number</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="licenseNumber" placeholder="Enter License Number">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 main-submit-button">
                                                <button *ngIf="!this.showRegist" (click)="createFixedAssetRegistration()" type="submit" class="btn btn-secondary submit-btn">Add</button>
                                                <button *ngIf="this.showRegist" (click)="updateFixedAssetRegistration()" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addMeterReadingspopUp" tabindex="-1" role="dialog" aria-labelledby="addMeterReadingspopUpTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Fixed Asset Meter Reading</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonG>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Fixed Asset Meter Reading</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addMeterReadings">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Meter Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="intervalMeterTypeArray" filter="true" optionlabel="label" formControlName="meterTypeID" placeholder="Enter Product Meter Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reading Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="readingDate" placeholder="Enter Reading Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Meter Value</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="meterValue" placeholder="Enter Meter Value">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reading Reason Enum ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="enumID" placeholder="Enter Reading Reason Enum ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Work Effort ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="workEffortID" placeholder="Enter Work Effort ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 main-submit-button">
                                                <button *ngIf="!this.meterShow" (click)="createFixedAssetMeter()" type="submit" class="btn btn-secondary submit-btn">Add</button>
                                                <button *ngIf="this.meterShow" (click)="updateFixedAssetMeter()" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addMaintenancepopUp" tabindex="-1" role="dialog" aria-labelledby="addMaintenancepopUpTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Fixed Asset Maintenance</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonJ>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Fixed Asset Maintenance</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createTaskList">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                         
                                                         Estimated Start Date </label>
                                                    </div>

                                                    <div class="col-lg-3">

                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="EstimatedStartDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                  
                                                         Estimated Completion Date</label>
                                                    </div>

                                                    <div class="col-lg-3">

                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="EstimatedCompletionDate">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                         Status</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Status" filter="true" [options]="StatusListArray" optionlabel="label" formControlName="Status">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Maintenance Type </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Maintenance Type" [options]="MaintenanceTypeArray" optionlabel="label" filter="true" formControlName="MaintenanceType">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                         Product Maintenance</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Product Maintenance" [options]="productMaintAllArray" filter="true" formControlName="ProductMaintenance">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Interval Meter Type </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Interval Meter Type" filter="true" [options]="intervalMeterTypeArray" optionlabel="label" formControlName="IntervalMeterType">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                         Maintenance Template</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Maintenance Template" filter="true" [options]="MaintenanceTemplateIdArray" optionlabel="label" formControlName="MaintenanceTemplate">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Purchase Order ID </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Purchase Order ID" filter="true" [options]="PurchaseOrderIdArray" optionlabel="label" formControlName="PurchaseOrderID">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                         Interval Quantity</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Interval Quantity" formControlName="IntervalQuantity">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Party ID" filter="true" [options]="partyIdArray" optionlabel="label" formControlName="PartyID">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                         Interval Uom</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Interval Uom" filter="true" [options]="IntervalUomArray" optionlabel="label" formControlName="IntervalUom">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                               
                                                         Actual Completion Date</label>
                                                    </div>

                                                    <div class="col-lg-3">

                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="ActualCompletionDate">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                      
                                                         Actual Start Date </label>
                                                    </div>

                                                    <div class="col-lg-3">

                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="ActualStartDate">
                                                    </div>




                                                </div>
                                            </div>
                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 82%;">

                                        <button type="submit" (click)="createFixedAssetMaintUpdateWorkEffort()" class="btn btn-secondary submit-btn">Save</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addAssignmentpopUp" tabindex="-1" role="dialog" aria-labelledby="addAssignmentpopUpTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Party Fixed Asset Assignment</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonD>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Party Fixed Asset Assignment</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addAssignment">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="partyIds" ormControlName="partyID" placeholder="Enter Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Role Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="roleTypeIds" optionlabel="label" formControlName="roleTypeID" placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="fromDate" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="throughDate" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Allocated  Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="allocatedDate" placeholder="Enter Allocated  Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" [options]="statusIds" optionlabel="label" formControlName="statusID" placeholder="Enter Status ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="comments" placeholder="Enter Comments">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 main-submit-button">
                                                <button *ngIf="!this.showAssign" (click)="postAssignmentList()" type="submit" class="btn btn-secondary submit-btn">Add</button>
                                                <button *ngIf="this.showAssign" (click)="putAssignmentList()" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addDepreciationpopup" tabindex="-1" role="dialog" aria-labelledby="addDepreciationpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Fixed Asset Depreciation Method</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonF>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Fixed Asset Depreciation Method</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addDepreciation">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Depreciation Custom Method</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown [options]="DepreciationCustomMethodArray" filter="true" optionlabel="label" formControlName="custMethod" placeholder="Enter Depreciation Custom Method">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="fromDate" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="throughDate" placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 main-submit-button">
                                                <button (click)="createFixedAssetDepMethod();" type="submit" class="btn btn-secondary submit-btn">Add</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="addGLpopup" tabindex="-1" role="dialog" aria-labelledby="addGLpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add GL Mappings</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add GL Mappings</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addGL">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Asset GL Account</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="glAcc" placeholder="Enter Asset GL Account">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Accumulated Depreciation GL Account</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="accumulatedDep" placeholder="Enter Accumulated Depreciation GL Account">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Depreciation GL Account</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="depGLAcc" placeholder="Enter Depreciation GL Account">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Profit GL Account</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="profitAcc" placeholder="Enter Profit GL Account">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Loss GL Account</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="lossAcc" placeholder="Enter Loss GL Account">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-10 main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn">Add</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>