<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Payroll</span>

               <span>
                <button type="submit" class="btn btn-secondary submit-btn mr-2"
                    (click)="toDashboard()">Home</button>
                <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
            </span>


            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="findPaycheck()"><a>
                                Find Paycheck</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="createPaychecks()" ><a>
                                Create Paycheck</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="paycheckConfiguration()" ><a>
                                Paycheck Configuration</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="employeess()"><a>
                                Employees</a></li>

                            <li [ngClass]="activeCategory==5?'active':''"  (click)="contractReferencees()"><a>
                                Contract Reference</a></li>

                            <li [ngClass]="activeCategory==6?'active':''" (click)="reports()"><a>
                                Reports</a></li>

                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                            <div *ngIf="activeCategory==1">
                                            <form class="w-100">

                                                <div class="w3-card-4">
                                                    <div class="accordion classCard" id="myAccordion">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="headingOne">
                                                                <div
                                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="background:#F5F7FA!important; padding: 4px !important;">
                                                                <span style="margin-left: 12px!important;"> Find Paycheck</span>
                                                                <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 82%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;"></button>									

                                                            </div>

                                                            </h2>
                                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                <div class="card-body">
                                                                    
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row" style="height: 32px;">
                                
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Payment Id</label>
                                                                                </div>
                                
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control small-input"
                                                                                        [(ngModel)]="advanceSearch.PaymentId" id="exampleInputEmail1"
                                                                                        name="PaymentId" aria-describedby="emailHelp">
                                                                                </div>
                
                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                    <label for="exampleInputEmail1">Paycheck Type</label>
                                                                                </div>
                                
                                                                                <div class="col-lg-2 dropdowns">
                                                                                    <p-dropdown filter="true" [options]="PaycheckTypeArray"
                                                                                        name="PaycheckType" [(ngModel)]="advanceSearch.PaycheckType"
                                                                                        optionlabel="label"></p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                
                                                                        <div class="col-lg-12">
                                                                            <div class="row" style="height: 32px;">
                                
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Pay to Party</label>
                                                                                </div>
                                
                                                                                <div class="col-lg-2 dropdowns">
                                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.PaytoParty"
                                                                                        name="PaytoParty" [options]="PaytoPartyArray"
                                                                                        optionlabel="label">
                                
                                                                                    </p-dropdown>
                                                                                </div>
                
                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                    <label for="exampleInputEmail1">Status </label>
                                                                                </div>
                                
                                                                                <div class="col-lg-2 dropdowns">
                                                                                    <p-dropdown filter="true" [options]="statusArray" name="Status	"
                                                                                        [(ngModel)]="advanceSearch.Status" optionlabel="label">
                                
                                                                                    </p-dropdown>
                                                                                </div>
                                
                                
                                                                            </div>
                                                                        </div>
                                
                                                                        <div class="col-lg-12" style="height: 32px;">
                                                                            <div class="row">
                                
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1" (click)="focusOnInput()">From :
                                                                                    </label> 
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input #inputField type="date"  class="form-control small-input"
                                                                                        [(ngModel)]="advanceSearch.FromDate" id="exampleInputEmail1"
                                                                                        name="FromDate" aria-describedby="emailHelp"> 
                                                                                </div> 
                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                    <label for="exampleInputEmail1">Thru
                                                                                        :</label>
                                                                                </div>
                                
                                                                                <div class="col-lg-2">
                                                                                    <input type="date" class="form-control small-input"
                                                                                        [(ngModel)]="advanceSearch.ThruDate" id="exampleInputEmail1"
                                                                                        name="ThruDate" aria-describedby="emailHelp">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                
                                                                        <div class="col-lg-12">
                                                                            <div class="row" style="height: 32px;">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Payment Method</label>
                                                                                </div>
                                                                                <div class="col-lg-2 dropdowns">
                                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.PaymentMethod"
                                                                                        name="PaymentMethod" [options]="PaymentMethodIDArray"
                                                                                        optionlabel="label">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInputC">
                                                                                    <label for="exampleInputEmail1">Check/Ref Num</label>
                                                                                </div> 
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" [(ngModel)]="advanceSearch.RefNum"
                                                                                        class="form-control small-input" id="exampleInputEmail1"
                                                                                        name="RefNum" aria-describedby="emailHelp">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 col-12 main-submit-button"
                                                                        style="margin-left: 53%;height: 21px;font-size: 11px; width: 158px;">
                                                                        <button _ngcontent-cta-c128="" type="submit" (click)="FindPayCheck()"
                                                                            class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                        <button type="submit" class="btn btn-danger ml-2" (click)="reset()"
                                                                            >Reset</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                </div>

                                                <!-- <div class="w3-card-4 classCard">
                                                    <div
                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                         <button type="button" class="btn btn-outline-secondary" data-toggle="modal"
                                                            data-target="#exampleModalCenter">Create New Fixed Asset</button> 
                                                        Find Paycheck
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row" style="height: 32px;">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Payment Id</label>
                                                                </div>
                
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control small-input"
                                                                        [(ngModel)]="advanceSearch.PaymentId" id="exampleInputEmail1"
                                                                        name="PaymentId" aria-describedby="emailHelp">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputC">
                                                                    <label for="exampleInputEmail1">Paycheck Type</label>
                                                                </div>
                
                                                                <div class="col-lg-2 dropdowns">
                                                                    <p-dropdown filter="true" [options]="PaycheckTypeArray"
                                                                        name="PaycheckType" [(ngModel)]="advanceSearch.PaycheckType"
                                                                        optionlabel="label"></p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                
                                                       
                
                
                                                        <div class="col-lg-12">
                                                            <div class="row" style="height: 32px;">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Pay to Party</label>
                                                                </div>
                
                                                                <div class="col-lg-2 dropdowns">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.PaytoParty"
                                                                        name="PaytoParty" [options]="PaytoPartyArray"
                                                                        optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputC">
                                                                    <label for="exampleInputEmail1">Status </label>
                                                                </div>
                
                                                                <div class="col-lg-2 dropdowns">
                                                                    <p-dropdown filter="true" [options]="statusArray" name="Status	"
                                                                        [(ngModel)]="advanceSearch.Status" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                
                
                                                            </div>
                                                        </div>
                
                                     
                
                
                
                                                        <div class="col-lg-12" style="height: 32px;">
                                                            <div class="row">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1" (click)="focusOnInput()">From :
                                                                    </label> 
                                                                    
                
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input #inputField type="date"  class="form-control small-input"
                                                                        [(ngModel)]="advanceSearch.FromDate" id="exampleInputEmail1"
                                                                        name="FromDate" aria-describedby="emailHelp"> 
                                                                </div> 
                                            
                                                                
                
                                                                <div class="col-lg-3 form-group classInputC">
                                                                    <label for="exampleInputEmail1">Thru
                                                                        :</label>
                                                                </div>
                
                                                                <div class="col-lg-2">
                                                                    <input type="date" class="form-control small-input"
                                                                        [(ngModel)]="advanceSearch.ThruDate" id="exampleInputEmail1"
                                                                        name="ThruDate" aria-describedby="emailHelp">
                
                
                                                                </div>
                
                
                
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row" style="height: 32px;">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Payment Method</label>
                                                                </div>
                                                                <div class="col-lg-2 dropdowns">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.PaymentMethod"
                                                                        name="PaymentMethod" [options]="PaymentMethodIDArray"
                                                                        optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputC">
                                                                    <label for="exampleInputEmail1">Check/Ref Num</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" [(ngModel)]="advanceSearch.RefNum"
                                                                        class="form-control small-input" id="exampleInputEmail1"
                                                                        name="RefNum" aria-describedby="emailHelp">
                
                
                                                                </div>
                                                            </div>
                                                        </div>
                
              
                                                    </div>
                                                    <div class="col-lg-12 col-12 main-submit-button"
                                                        style="margin-left: 55%;height: 21px;font-size: 11px; width: 158px;">
                                                        <button _ngcontent-cta-c128="" type="submit" (click)="FindPayCheck()"
                                                            class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                        <button type="submit" class="btn btn-danger ml-2" (click)="reset()"
                                                            >Reset</button>
                                                    </div>
                                                </div> -->
                                                <br>
                
                                                <div class="w3-card-4 classCard">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Payment(s) Found
                
                                                            </h4>
                
                                                        </div>
                                                    </header>
                                                    <div
                                                        class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                
                                                                <div class="card own-account-table small-table-height">
                                                                    <p-table [value]="PayCheckTable  " [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr class="table-row"
                                                                                style="background: #0d3769; margin-left: 10px !important;">
                                                                                <th [ngStyle]="{'width':'200px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                
                                                                                        </p-checkbox>
                
                                                                                        Payment Id<p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;"> Effective Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                
                                                                                </th>
                
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Ref
                                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Status
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;">Pay to Party<p-sortIcon
                                                                                            field="price"></p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Amount
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                
                                                                                    </div>
                                                                                </th>
                
                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                    pSortableColumn="price">
                                                                                    <div style="color: white;"> Outstanding<p-sortIcon
                                                                                            field="price"></p-sortIcon>
                
                                                                                    </div>
                                                                                </th>
                
                
                
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr class="table-row">
                                                                                <td
                                                                                    [ngStyle]="{'width':'177px','padding-left':'15px !important'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                
                                                                                        </p-checkbox>
                                                                                        
                                                                                        <span style="padding-left: 10px;"  class="account-button" (click)="detailedPage(product.PayChecks.paymentId)">
                                                                                            {{product.PayChecks.paymentId}}
                                                                                        </span>
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.effectiveDate}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.PayChecks.paymentRefNum}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.PayChecks.statusId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.PayChecks.partyIdTo}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels"> </span>

            </div>
            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <div
                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <button type="button" class="btn btn-outline-secondary" data-toggle="modal"
                                            data-target="#exampleModalCenter">Create New Fixed Asset</button> 
                                        Find Paycheck
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row" style="height: 32px;">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Payment Id</label>
                                                </div>

                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control small-input"
                                                        [(ngModel)]="advanceSearch.PaymentId" id="exampleInputEmail1"
                                                        name="PaymentId" aria-describedby="emailHelp">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row" style="height: 32px;">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Paycheck Type</label>
                                                </div>

                                                <div class="col-lg-2 dropdowns">
                                                    <p-dropdown filter="true" [options]="PaycheckTypeArray"
                                                        name="PaycheckType" [(ngModel)]="advanceSearch.PaycheckType"
                                                        optionlabel="label"></p-dropdown>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-lg-12">
                                            <div class="row" style="height: 32px;">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Pay to Party</label>
                                                </div>

                                                <div class="col-lg-2 dropdowns">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.PaytoParty"
                                                        name="PaytoParty" [options]="PaytoPartyArray"
                                                        optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row" style="height: 32px;">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Status </label>
                                                </div>

                                                <div class="col-lg-2 dropdowns">
                                                    <p-dropdown filter="true" [options]="statusArray" name="Status	"
                                                        [(ngModel)]="advanceSearch.Status" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>



                                        <div class="col-lg-12" style="height: 32px;">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Effective Date</label>
                                                </div>
                                                <div class="col-lg-2 form-group classInput" style="margin-left: -10%;">
                                                    <label for="exampleInputEmail1" style="font-weight: 400;" (click)="focusOnInput()">From :
                                                    </label> 
                                                   

                                                </div>
                                                <div class="col-lg-2">
                                                    <input #inputField type="date"  class="form-control small-input"
                                                        [(ngModel)]="advanceSearch.FromDate" id="exampleInputEmail1"
                                                        name="FromDate" aria-describedby="emailHelp"> 
                                                </div> 
                            
                                                

                                                <div class="col-lg-2 form-group classInput">
                                                    <label for="exampleInputEmail1" style="font-weight: 400;">Thru
                                                        :</label>
                                                </div>

                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control small-input"
                                                        [(ngModel)]="advanceSearch.ThruDate" id="exampleInputEmail1"
                                                        name="ThruDate" aria-describedby="emailHelp">


                                                </div>




                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row" style="height: 32px;">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Payment Method</label>
                                                </div>
                                                <div class="col-lg-2 dropdowns">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.PaymentMethod"
                                                        name="PaymentMethod" [options]="PaymentMethodIDArray"
                                                        optionlabel="label">
                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row" style="height: 32px;">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Check/Ref Num</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" [(ngModel)]="advanceSearch.RefNum"
                                                        class="form-control small-input" id="exampleInputEmail1"
                                                        name="RefNum" aria-describedby="emailHelp">


                                                </div>



                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button"
                                        style="margin-left: 16%;height: 21px;font-size: 11px; width: 158px;">
                                        <button _ngcontent-cta-c128="" type="submit" (click)="FindPayCheck()"
                                            class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                        <button type="submit" class="btn btn-danger ml-2" (click)="reset()"
                                            >Reset</button>
                                    </div>
                                </div>
                                <br>

                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Payment(s) Found

                                            </h4>

                                        </div>
                                    </header>
                                    <div
                                        class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table small-table-height">
                                                    <p-table [value]="PayCheckTable  " [paginator]="true" [rows]="rows"
                                                        scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr class="table-row"
                                                                style="background: #0d3769; margin-left: 10px !important;">
                                                                <th [ngStyle]="{'width':'200px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>

                                                                        </p-checkbox>

                                                                        Payment Id<p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;"> Effective Date
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>

                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="quantity">
                                                                    <div style="color: white;"> Ref
                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;"> Status
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;">Pay to Party<p-sortIcon
                                                                            field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;"> Amount
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>

                                                                    </div>
                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="price">
                                                                    <div style="color: white;"> Outstanding<p-sortIcon
                                                                            field="price"></p-sortIcon>

                                                                    </div>
                                                                </th>



                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr class="table-row">
                                                                <td
                                                                    [ngStyle]="{'width':'200px','padding-left':'15px !important'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>

                                                                        </p-checkbox>
                                                                        <span
                                                                            (click)="detailedPage(product.PayChecks.paymentId)">{{product.PayChecks.paymentId}}</span>
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.effectiveDate}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.PayChecks.paymentRefNum}}</td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.PayChecks.statusId}}</td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.PayChecks.partyIdTo}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">

                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">

                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->