import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BlockableUI, SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { Color, Label } from 'ng2-charts';
import { ChartType } from 'chart.js';   import { Location } from '@angular/common';
import { ChartDataSets, ChartOptions } from 'chart.js';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detail-overview-page-admin',
  templateUrl: './detail-overview-page-admin.component.html',
  styleUrls: ['./detail-overview-page-admin.component.css']
})
export class DetailOverviewPageAdminComponent implements OnInit {
  activeCategory = 1;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  productId: any;
  ProductMembersList: any;
  ProductInformation: any;
  ProductContentList: any;
  ownerCompany: any;
  EmailsProductBacklogs: any;
  ProjectAndSprintList: any;
  productUpdateForm: FormGroup;
  CompanyandProductOwner: any;
  CompanyandProductOwnerArray: any[] = [];
  statusArray: { label: string; value: string; }[];
  CompanyandProductOwnerForm: FormGroup;
  PersonArray: { label: string; value: string; }[];
  RoleType: any;
  RoleTypeArray: any[] = [];
  addProductMemberForm: FormGroup;
  backlogStatus: { label: string; value: string; }[];
  yesNOArray: { label: string; value: string; }[];
  fromDate: string;
  TotalBacklogList: any;
  advanceSearch: { ProductBacklogID: string; RequesterName: string; ProductBacklogsName: string; BacklogItemCategory: string; RequestDate: string; Billed: string; Planned: string; Status: string; };
  EditBacklogsProductItemList: any;
  editBacklogForm: FormGroup;
  parentCustRequest = "";
  internalName: any;
  billFromParty: any;
  custRequestId: any;
  billingList: any;
  billingForm: FormGroup;
  fromdate: string;
  thruDate: string;
  advanceSearchA: { ThroughDate: string; FromDate: string; IncludeMeeting: string; };
  CompanyandProduct: any;
  CompanyandProductArray: any[] = [];
  partyIdArray: any[] = [];
  partyId: any;
  roleTypeId: any;
  roleTypeIdArray: any[] = [];
  PartyId: any;
  BacklogItemCategoryArray: any[] = [];
  BacklogItemCategory: any;
  editDetailForm: FormGroup;
  EditDetailBacklogs: any;
  productList: any;
  billingInvoiceList: any;
  custRequestIdEditBacklog: any;
  priority = "";
  DefaultTask: any;
  DefaultTaskArray: any[] = [];
  defaultTaskEditForm: FormGroup;
  defaultTaskCreateForm: FormGroup;
  AssignTo: any;
  AssignToArray: any[] = [];
  TaskList: any[];
  RequesterNameArray: any[] = [];
  RequesterName: any;
  workEffortId: any;
  Status: any;
  description: any;
  categoryCreateForm: FormGroup;
  CategoryList: any;
  contentLists:any;
  categoryUpdateForm: FormGroup;
  MimeTypeId: any;
  mimeTypeIdArray: any[] = [];
  contentTypeId: any;
  contentTypeIdArray: any[] = [];
  contentForm: FormGroup;
  ContentList: any;
  fileData: File;
  contentID: any;
  newProjectForm: FormGroup;
  CommunicationList: any;
  UploadedFileForm: FormGroup;
  NewEmailForm: FormGroup;
  partyIdFrom = "";
  SprintOverview:any;
  communicationEventId: any;
  ContentDataList: any;
  NewProjectProductName: any;
  ProductUpdateEmail: any;
  communicationEventIds: any;
  datetimeStarted: any;
  statusId: any;
  partyIdTo: any;
  dateTimeStart: string;
  tooString: any;
  fromString: any;
  CommunicationOverviewEvent: any;
  ChildCommunicationOverviewEvent: any;
  CommunicationOverviewEventRoles: any;
  TaskworkEffortId: any;
  statusValue: string;
  CompanyandProductOwnerValue: any;
  showUnplanned  = false;
  UnplanBacklogList: any;
  addNewTaskForm: FormGroup;
  UpdateProductBacklogForm: FormGroup;
  RoleTypeList: any;
  showUnplannedTab =false;
  activeFlag: any;
  sprintTaskList: any;
  productBacklogItemStatusList: any;
  BacklogItemNoteList: any;
  backlogItemContentList: any;

  chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }
  pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    tooltips: {
      enabled: true,
      mode: 'single',
      callbacks: {
        label: function (tooltipItems, data) {
          return data.datasets[0].data[tooltipItems.index] + ' %';
        }
      }
    },
  };


  pieChartLabels: Label[] = [''];

  pieChartData: number[] = [];

  pieChartType: ChartType = 'pie';

  pieChartLegend = true;

  pieChartPlugins = [];

  pieChartColors = [
    {
      backgroundColor: ['#007bff', '#007bff', '#007bff'],
    },
  ];

  constructor(    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,    
    private _location: Location,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.productId = params.productId;
      this.internalName = params.internalName;
      this.billFromParty = params.billFromParty + "[" + params.partyId + "]";
      this.partyId = params.partyId;
      this.Status = params.Status;
      this.description = params.description;
      this.workEffortId = params.workEffortId;
      this.custRequestId = params.custRequestId;
      this.communicationEventId = params.communicationEventId;
      this.activeFlag = params.activeFlag;

    })
    this.getProductOverviewMemberList();
    this.getProductOverviewProductContent();
    this.getEmailsProductBacklogs();
    this.getProjectAndSprintList();
    this.getProductList();
    this.getSprintOverview();
    this.getScrumProductContentListData();
    this.getSprintTask();
    this.getProductBacklogItemStatusList();
    this.getBacklogItemNoteList();
    this.BacklogItemContentList();
  } 
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  BacklogItemContentList() {
    this.spinner.show();
    this.accountsService.BacklogItemContentList(this.custRequestId).subscribe(res => {
      this.backlogItemContentList = res.data;
      this.spinner.hide();
    })
  }
  EmailContentDownload(imageUrl) {
    this.spinner.show();
    this.accountsService.getEmailContentDownload(imageUrl).subscribe(res => {
      const blob = new Blob([res], { type: 'document/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'CONTENT';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })
    this.spinner.hide();

  }
  getBacklogItemNoteList() {

    this.spinner.show();
    this.accountsService.getBacklogItemNoteList(this.custRequestId).subscribe(res => {
      this.BacklogItemNoteList = res.data;
      this.spinner.hide();
    })
  }
  getProductBacklogItemStatusList() {
    this.spinner.show();
    this.accountsService.getProductBacklogItemStatusList(this.productId,this.custRequestId).subscribe(res => {
      this.productBacklogItemStatusList = res.data;
      this.spinner.hide();
    })
  }
  getSprintTask() {
    this.spinner.show();
    this.accountsService.getSprintTask(this.productId,this.custRequestId).subscribe(res => {
      this.sprintTaskList = res.data;
      this.spinner.hide();
    })
  }

  getScrumProductContentListData() {
    this.spinner.show();
    this.accountsService.getScrumProductContentListData(this.productId).subscribe(res => {
      this.contentLists = res.data.data;
      this.spinner.hide();
    })
  }

  getSprintOverview() {

    this.spinner.show();
    this.accountsService.getSprintOverview(this.productId, this.custRequestId).subscribe(res => {
      this.SprintOverview = res.data[0];
      this.spinner.hide();
    })
  }
  getProjectAndSprintList() {

    this.spinner.show();
    this.accountsService.getProjectAndSprintList().subscribe(res => {
      this.ProjectAndSprintList = res.data.scrumSprint;
      this.spinner.hide();
    })
  }
  getProductOverviewProductContent() {

    this.spinner.show();
    this.accountsService.getProductOverviewProductContent(this.productId).subscribe(res => {
      this.ProductContentList = res.data;
      this.spinner.hide();
    })
  }
  getEmailsProductBacklogs() {

    this.spinner.show();
    this.accountsService.getEmailsProductBacklogs().subscribe(res => {
      this.EmailsProductBacklogs = res.data.data;
      this.spinner.hide();
    })
  }
  getProductList() {

    this.spinner.show();
    this.accountsService.getProductList().subscribe(res => {
      this.productList = res.data;
      this.spinner.hide();
    })
  }
  getProductOverviewMemberList() {

    this.spinner.show();
    this.accountsService.getProductOverviewMemberList(this.productId).subscribe(res => {
      this.ProductMembersList = res.data[0].MemberList;
      this.spinner.hide();
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
 

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
}
