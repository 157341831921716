import { DatePipe, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import Swal from 'sweetalert2';
import { Settings } from '../setting/settings.service';

import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-skill-type',
  templateUrl: './detail-skill-type.component.html',
  styleUrls: ['./detail-skill-type.component.css']
})
export class DetailSkillTypeComponent implements OnInit {
 
  rows = 50;
  total = 0;
  activeCategory=1;
  skillType: any;
  Des: any;
  payGradeId: any;
  payGradeName: any;
  comments: any;
  salaryStepList : any[];
  salaryStepForm : FormGroup;
  updateSalary: boolean = false;

  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly settings:Settings,
    readonly _FormBuilder: FormBuilder,
      private _location: Location,
      readonly toastr:ToastrService,
    readonly datePipe: DatePipe,
    @Inject(DOCUMENT) readonly document: Document
  ) { }
 
  ngOnInit(): void {
   
    this.activatedRoute.queryParams.subscribe(params=> {
      if(params.SkillType){
        this.skillType = params["SkillType"];
        this.Des=params["des"];
      }
      if(params.payGradeId){
        this.payGradeId = params.payGradeId ; 
        this.payGradeName = params.payGradeName ;
        this.comments = params.comments ;
      }
      // this.skillType = params["SkillType"] || '',
      // this.Des=params["des"] || '',
      // this.payGradeId = params.payGradeId || '', 
      // this.payGradeName = params.payGradeName || '',
      // this.comments = params.comments || ''

      this.salaryStepForm = this._FormBuilder.group({
        fromDate: [''],
        thruDate: [''],
        dateModified: [''],
        amount: [''],
        createdByUserLogin: [''],
        lastModifiedByUserLogin: [''],
        salaryStepSeqId: [''],
        payGradeId: [this.payGradeId],
        });

    
  })
  console.log(this.payGradeId,this.payGradeName,this.comments)
   this.getSalaryStep();
  }
  getSalaryStep() {
    this.spinner.show();
    this.salaryStepList=[]
    this.settings.getSalaryStepList().subscribe((res:any)=>{
      this.salaryStepList = res.data;
      console.log("salaryStepList",res)
      this.spinner.hide();
    })
  }

  onCreateSalaryStep(){
    this.salaryStepForm.value.payGradeId = this.payGradeId
    let salaryStepData = { ...this.salaryStepForm.value };
    this.spinner.show();
    this.settings.createSalaryStep(salaryStepData).subscribe((res:any)=> {  
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.getSalaryStep()
        this.spinner.hide();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err) => {
      console.warn("error",err);
      this.spinner.hide();
      for(const data of err.error.errors){
        this.toastr.error(data.fieldName + ' ' + data.fieldError)
      }
   })
    
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  ondeleteSalaryStep(product){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "amount": product.amount,
          "createdByUserLogin": product.createdByUserLogin,
          "dateModified":this.datePipe.transform(product.dateModified, "yyyy-MM-dd hh:mm:ss"),
          "fromDate": this.datePipe.transform(product.fromDate, "yyyy-MM-dd hh:mm:ss"),
          "lastModifiedByUserLogin": product.lastModifiedByUserLogin,
          "payGradeId": product.payGradeId,
          "salaryStepSeqId": product.salaryStepSeqId,
          "thruDate": this.datePipe.transform(product.thruDate, "yyyy-MM-dd hh:mm:ss")
        }
        this.settings.deleteSalaryStep(req).subscribe((res:any)=> {
          if(res.success) { 
            this.getSalaryStep();
            this.document.location.reload();
          }
          else if(res.success == false) {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )  }
    }) }

  updateTerminationType(){
    this.spinner.show();
    const form = {...this.salaryStepForm.value};

    let req = {
      "fromDate": this.salaryStepForm.value.fromDate,
      "thruDate": this.salaryStepForm.value.thruDate,
      "dateModified": this.salaryStepForm.value.dateModified,
      "amount": this.salaryStepForm.value.amount,
      "createdByUserLogin": this.salaryStepForm.value.createdByUserLogin,
      "lastModifiedByUserLogin": this.salaryStepForm.value.lastModifiedByUserLogin,
      "salaryStepSeqId": this.salaryStepForm.value.salaryStepSeqId,
      "payGradeId": this.salaryStepForm.value.payGradeId
    }
      this.settings.updateSalaryStep(req).subscribe((res:any)=> {
        if(res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.getSalaryStep();        }
        else if(res.success == false) {
          this.toastr.error("Error");
          this.spinner.hide();
        }
      })
  }
  onUpdateSalary(product){
    this.spinner.show();
     let salarySeqId = product.salaryStepForm;
    const form = this.salaryStepForm;
     form.patchValue({
        salaryStepSeqId:salarySeqId,
        fromDate: product.fromDate,
        thruDate: product.thruDate,
        dateModified: product.dateModified,
        amount: product.amount,
        createdByUserLogin: product.createdByUserLogin,
        lastModifiedByUserLogin: product.lastModifiedByUserLogin,
        payGradeId: product.payGradeId,
     });
     this.spinner.hide();
     if(salarySeqId){
       this.updateSalary = true;
     }
     else {
       this.updateSalary=false;
     }
  }
  updateFalse(){
    this.updateSalary=false;
  }
  cancelSalary(){
    this.salaryStepForm.reset();
    this.updateSalary=false;
  }


 
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }

}
