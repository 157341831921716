<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Rates
                </span>
                <span class="color-black pl-1" > >Edit Party Rates
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show" ><a>
                        Edit Party Rates
                    </a></li>
                    
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createParty">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">
                                                    Rate Type<span style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true"  formControlName="rateType"
                                                placeholder="Enter Rate Type"
                                                name="Parent Type ID"
                                                [options]="rateTypesArray" optionlabel="label">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Period Type ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <label>Rate amount per Hour</label>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">
                                                    Rate Amount</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"   formControlName="rateAmount"
                                                 class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Rate Amount">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Currency Uom ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true"  formControlName="currency"
                                                placeholder="Enter Currency Uom ID"
                                                [options]="currencyArray" optionlabel="label"
                                                name="Parent Type ID">
                                            </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">
                                                    Percentage Used</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="percentageUsed"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Percentage Used">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Default Rate</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true"  formControlName="defaultRate"
                                              placeholder="Enter Default Rate" 
                                              [options]="default" optionlabel="label"
                                              name="Parent Type ID">
                                          </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    
                                
                                    
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 62%;">
                               
                                <button type="submit" (click)="onSubmit();"  class="btn btn-secondary submit-btn">Create</button>
                                
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div> -->

<div class="modal-header">
    <span class="color-black pl-1">Edit Party Rates</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close"
        (click)="bsModalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden" #closebutton>&times;</span>
    </button>
</div>
<div class="modal-body d-flex">
    <div class=" bg-white color-grey" style="width: 100%;">
        <div class="w3-card-4 classCardPopUp" style="width: 100%;">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass">

                    <li class="active"><a>
                            Edit Party Rates</a></li>
                </ul>
            </div>

            <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100" [formGroup]="createParty">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">
                                                Rate Type<span style="color:red">*</span></label>
                                        </div>

                                        <div class="col-lg-3">
                                            <p-dropdown filter="true" formControlName="rateType"
                                                placeholder="Enter Rate Type" name="Parent Type ID"
                                                [options]="rateTypesArray" optionlabel="label">
                                            </p-dropdown>
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Period Type ID</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <label>Rate amount per Hour</label>
                                        </div>



                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">
                                                Rate Amount</label>
                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" formControlName="rateAmount" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Rate Amount">
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Currency Uom ID</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown filter="true" formControlName="currency"
                                                placeholder="Enter Currency Uom ID" [options]="currencyArray"
                                                optionlabel="label" name="Parent Type ID">
                                            </p-dropdown>
                                        </div>



                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">
                                                Percentage Used</label>
                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" formControlName="percentageUsed" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Percentage Used">
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Default Rate</label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown filter="true" formControlName="defaultRate"
                                                placeholder="Enter Default Rate" [options]="default" optionlabel="label"
                                                name="Parent Type ID">
                                            </p-dropdown>
                                        </div>



                                    </div>
                                </div>




                            </div>


                        </form>
                        <div class="main-submit-button" style="margin-left: 72%;">

                            <button type="submit" (click)="onSubmit();"
                                class="btn btn-secondary submit-btn">Create</button>




                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>