
              
              <br><div class="w3-card-4 classCard" >
             <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass">
              <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                Return Status History </a></li>
             <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
              Return Type History </a></li>
              <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                Return Reason History </a></li>
             <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
              Return Quantity History </a></li>
              <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                Return Price History </a></li>
              </ul>
              </div>
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div *ngIf="activeCategory==1">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Return Status History
                          </h4>
                         
                        </div>
                        <div class="panel-body a">
                          <div class="mt-2">
                            <div class="">
                              <div class="row m-0">
                                <div class="card return-history-table own-account-tables">
                                <p-table [resizableColumns]="true" columnResizeMode="expand" scrollWidth="100%" [paginator]="false" [rows]="rows"
                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [lazy]="true" [totalRecords]="total" [value]="orderHistory"
                                [scrollable]="true" styleClass="tags-table   offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                <ng-template pTemplate="colgroup" let-columns>
                                    <colgroup>
                                        <col *ngFor="let col of offerShowingLineColoumns">
                                    </colgroup>
                                </ng-template>
                                <ng-template pTemplate="header">
                                  <tr class="activity-rows" >
                                    <th id="tableHead" class="tabTh" *ngFor="let col of offerShowingLineColoumns">
                                      <ng-container style="color: white!important;">
                                  
                                          {{col.header}}
                                      </ng-container>
                                  </th>
                                  </tr>
                              </ng-template>
                              <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-activity>
                                  <tr class="activity-data">
                                      <td *ngFor="let col of offerShowingLineColoumns">
                                        <ng-container *ngIf="col.field === 'status'">
                                          <span>{{activity.returnId}}</span> 
                                        </ng-container>  
                                        <ng-container *ngIf="col.field === 'name'">
                                          <span class="d-flex red-border align-items-center">
                                          <div class="name_text">
                                            <a>
                                              {{activity.returnItemSeqId}}
                                            </a>
                                          </div>
                                        </span>
                                        </ng-container>
                                        <ng-container *ngIf="col.field === 'email'">
                                          <span  class="d-block align-items-center">
                                           <div  class="name_text">
                                             {{activity.statusDescription}}
                                         </div>
                                       </span>
                                         </ng-container>  
                                        <ng-container *ngIf="col.field === 'phone'"><span>
                                           {{activity.statusDatetime|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                        </ng-container>
                                        <ng-container *ngIf="col.field === 'type'">
                                         <span>{{activity.changeByUserLoginId}}</span>
                                       </ng-container>
                                       
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                            </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="activeCategory==2">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Return Type History 
                          </h4>
                         
                        </div>
                        <div class="panel-body">
                          <div class="mt-2">
                            <div class="">
                              <div class="row">
                                <form class="w-100">
                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <p class="col-lg-12  col-12" style="color:#65787e;font-size:14px;">History not available</p>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="activeCategory==3">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Return Reason History 
                          </h4>
                         
                        </div>
                        <div class="panel-body">
                          <div class="mt-2">
                            <div class="">
                              <div class="row">
                                <form class="w-100">
                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <p class="col-lg-12  col-12" style="color:#65787e;font-size:14px;">History not available</p>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="activeCategory==4">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Return Quantity History
                          </h4>
                         
                        </div>
                        <div class="panel-body">
                          <div class="mt-2">
                            <div class="">
                              <div class="row">
                                <form class="w-100">
                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <p class="col-lg-12  col-12" style="color:#65787e;font-size:14px;">History not available</p>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="activeCategory==5">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Return Price History
                          </h4>
                         
                        </div>
                        <div class="panel-body">
                          <div class="mt-2">
                            <div class="">
                              <div class="row">
                                <form class="w-100">
                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <p class="col-lg-12  col-12" style="color:#65787e;font-size:14px;">History not available</p>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>

