import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-position-type',
  templateUrl: './detail-position-type.component.html',
  styleUrls: ['./detail-position-type.component.css']
})
export class DetailPositionTypeComponent implements OnInit {

  activeCategory=1;
  skillType: any;
  Des: any;

  constructor(
    readonly activatedRoute:ActivatedRoute,
      private _location: Location,
      readonly spinner: NgxSpinnerService,
    readonly router: Router,
  ) { }
 
  ngOnInit(): void {
  
    this.activatedRoute.queryParams.subscribe(params=> [
      this.skillType = params["PosType"],
      this.Des=params["des"],
    
    ])
   
  }
  
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])

  }
  cancelSubmit(){
    this._location.back();
  }
  

}
