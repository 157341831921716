import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-party-group',
  templateUrl: './party-group.component.html',
  styleUrls: ['./party-group.component.css']
})
export class PartyGroupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
