<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
             
                <span class="color-black pl-1" >Edit Personal Information </span>
                <button type="submit" class="btn btn-danger buttonclass" 
                 (click)="cancelSubmit();">Cancel</button>
            </div>
            <div class="w3-card-4 classCard" >
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard" >
                    <div class="container-fluid">
                      <ul class="tabbing-section tabbing-accordians tabClass">
            
                        <li [ngClass]="activeCategory==2?'active':''" ><a>
                          Update Personal Information</a></li>
              
                      </ul>
                    </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="updateContact">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Party Id 
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="partyId"  class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  readonly>
                                           
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">External Id </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   formControlName="externalId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External Id">
                                            </div>
                                           
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">First Name</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="firstName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First name">
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Last Name</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="lastName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last name">
                                            </div>
                                           
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Comments</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                            </div>
                                            
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Postal Code</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="postalCode" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Country </label>
                                            </div>
                                            <div class="col-lg-2 dropdowns">
                                                <p-dropdown filter="true" [options]="array"
                                                formControlName="countryGeoId"
                                                (onChange)="onCountryChange($event.value)">
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">State </label>
                                            </div>
                                            <div class="col-lg-2 dropdowns">
                                                <p-dropdown filter="true" formControlName="stateProvinceGeoId"
                                                    [options]="stateArray"
                                                  
                                                    placeholder="Select State" >
                                                </p-dropdown>
                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">City </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="city" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter City">
                                            </div>
                                            
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Email Address</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="emailAddress" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                            </div>
                                            
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Address</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="address1" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Status Id</label>
                                            </div>
                                            <div class="col-lg-2 dropdowns">
                                                <p-dropdown [options]="crmArray"  formControlName="statusId"></p-dropdown>
                                               
                                            </div>
                                           
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Phone Number</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="phoneNumber" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Phone Number">
                                            </div>
                                            
                                            
                                           
                                        </div>
                                    </div> -->
                                   
                                   
                               
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: -31%;">
                                <button type="submit"  (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                               
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>
</div>
            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
