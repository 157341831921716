import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';

@Component({
  selector: 'app-create-tax-authority',
  templateUrl: './create-tax-authority.component.html',
  styleUrls: ['./create-tax-authority.component.css']
})
export class CreateTaxAuthorityComponent implements OnInit {
  createTax:FormGroup;
  activeCategory=2;
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  taxAuthorityGlAccountormData: any;
  taxAuthorityGlAccountormDataArray: any[]=[];
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  taxAuthorityGlAccountorm: any;
  taxAuthorityGlAccountormArray: any[]=[];
  glAccountId: any;
  taxAuthGeoId: any;
  taxAuthPartyId: any;
  show: boolean;
  glChartOfAccount: any;
  glChartOfAccountArray: any[]=[];
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
  
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createTax = this._FormBuilder.group({
        taxAuthParty: [''],
        taxAuth: [''],
        glAccountType:['']
       
        
      });
    
  }

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
      this.glAccountId = params["glAccountId"];
      this.taxAuthGeoId = params["taxAuthGeoId"];
      this.taxAuthPartyId = params["taxAuthPartyId"];
    
    });
    this.getGlAccountInitialData();
    this.taxAuthorityGlAccountInitialFormData();
    this.getChartAc();
    if(this.taxAuthGeoId){
      this.show=true;
      setTimeout(() => {
        const formValue = this.createTax;
      formValue.patchValue({
        taxAuthParty:  this.taxAuthPartyId,
        taxAuth: this.taxAuthGeoId,
        glAccountType:this.glAccountId
       
       
      })
      }, 2000);

    }else{
      this.show=false;
    }
  }
  getChartAc() {
    this.spinner.show();
    this.accountsService.getOrganizationMainChartOfAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.glChartOfAccount = res.data;
      this.spinner.hide();
      for (const value of this.glChartOfAccount) {
        this.glChartOfAccountArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
    })
  
  }
  taxAuthorityGlAccountInitialFormData() {
    this.spinner.show();
    this.accountsService.taxAuthorityGlAccountInitialFormData(this.finSize).subscribe((res: any) => {

      this.taxAuthorityGlAccountormData = res.data;
      this.taxAuthorityGlAccountorm = res.data;
      this.spinner.hide();
     
      for (const value of this.taxAuthorityGlAccountorm) {
        this.taxAuthorityGlAccountormArray.push({
          label: value.taxAuthGeoIdWithCountryName,
          value: value.taxAuthGeoId
        })
      }

    })
   
  }
  taxAuthorityGlAccountInitialFormDataParty() {
    this.spinner.show();
    this.accountsService.taxAuthorityGlAccountInitialFormData(this.finSize).subscribe((res: any) => {

      this.taxAuthorityGlAccountormData = res.data;
      this.taxAuthorityGlAccountorm = res.data;
      this.spinner.hide();
      
      for (const value of this.taxAuthorityGlAccountormData) {
        if(this.createTax.value.taxAuth===value.taxAuthGeoId){
        this.taxAuthorityGlAccountormDataArray.push({
          label: value.taxAuthPartyIdWithDescription,
          value: value.taxAuthPartyId
        })
      }}
    

    })
   
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }

    })
   
  }
  
  onUpdate() {
    this.spinner.show();
    const requestData = {
     
      "glAccountId":this.createTax.value.glAccountType,
      "taxAuthGeoId": this.createTax.value.taxAuth,
      "taxAuthPartyId": this.createTax.value.taxAuthParty,
      "organizationPartyId":  this.partyId,
    }
    this.accountsService.updateOrganizationTaxAuthorityGlAccounts(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "glAccountId":this.createTax.value.glAccountType,
      "taxAuthGeoId": this.createTax.value.taxAuth,
      "taxAuthPartyId": this.createTax.value.taxAuthParty,
      "organizationPartyId":  this.partyId,
    }
    this.accountsService.createOrganizationTaxAuthorityGlAccount(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }

}
