import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { AccountsService } from '../accounts/accounts.service';
import { MyContactsService } from '../contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from '../events/create-events/create-event-service';
import { CreateEventServices } from '../events/create-events/create-event.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
@Component({
  selector: 'app-new-forecast',
  templateUrl: './new-forecast.component.html',
  styleUrls: ['./new-forecast.component.css']
})
export class NewForecastComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  activeCategories=10;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  findForecast: { forecastID: string; parentForecastID: string; organizationPartyID: string; internalPartyID: string; customTimeID: string; currency: string; quotaAmount: string; forecastAmount: string; bestCaseAmount: string; closedAmount: string; quotaPercent: string; quotaClosed: string; pipelineAmount: string; };
  createForecast: any;
  findSalesForecastList: any;
  parentSalesForecastID: any;
  parentSalesForecastIDArray: any[] = [];
  currencyArray: any[]=[];
  PartyId: any;
  PartyIdArray: any[] = [];
  salesForecastId: any;
  personId: any;
  partyIdArray: any[]=[];
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly addSkillService: AddSkillService,
    private _location: Location,
  ) {
    this.findForecast = {
      forecastID: "",
      parentForecastID: "",
      organizationPartyID: "",
      internalPartyID: "",
      customTimeID: "",
      currency: "",
      quotaAmount: "",
      forecastAmount: "",
      bestCaseAmount: "",
      closedAmount: "",
      quotaPercent: "",
      quotaClosed: "",
      pipelineAmount: ""
    };
    this.createForecast = this._FormBuilder.group({
      
      ParentSalesForecastID: [''],
      parentForecastID: [''],
      organizationPartyID: [''],
      internalPartyID: [''],
      customTimeID: [''],
      currency: [''],
      quotaAmount: [''],
      forecastAmount: [''],
      bestCaseAmount: [''],
      closedAmount: [''],
      quotaPercent: [''],
      quotaClosed: [''],
      pipelineAmount: [''],
      changeNote: ['']
    })
  }

  ngOnInit(): void {
   
    this.getFindSalesForecast();
    this.ParentSalesForecastID();
    this.getCurrencyRate();
    this.getPartyIdList();
    this.getPartyId();

    const patchData = this.createForecast;
      setTimeout(() => {
        patchData.patchValue({
          organizationPartyID:"admin",
          internalPartyID:"admin",
          currency:"USD"
        })
      
      }, 1000);
  }
  toForecast(){
    
    this._Router.navigate(['/crm/sales-forecast']);

  }
  toEmails(){
    
    this._Router.navigate(['/communication-overview/Dashboard']);

  }
  toCalendar(){
    
    this._Router.navigate(['crm/sales-calendar']);

  }
  
  toDocuments(){
    
    this._Router.navigate(['crm/sales-documents']);

  }
  toNotes(){
    this._Router.navigate(['/crm/sales-notes']);
  }
  toTasks(){
    this._Router.navigate(['/crm/Tasks']);
  }
  toLead()
  {
    this._Router.navigate(['/crm/leads']);

  }
  toAccount()
  {
    this._Router.navigate(['/crm/accounts']);
  }
  toContact()
  {
    this._Router.navigate(['/crm/my-contacts']);

  }
  toOpportunity()
  {
    this._Router.navigate(['/crm/opportunities']);

  }
  
  toCases(){
    this._Router.navigate(['/crm/Cases']);
  }
  toActivities()
  {
    this._Router.navigate(['/crm/events']);

  }
  toQuotes()
  {
    this._Router.navigate(['/order/quotations']);

  }
  toSalesOrder()
  {
    this._Router.navigate(['/order/create-sales-order']);

  }

  changeactiveCategoryAction(tab: number) {
    this.activeCategories = tab;
  }
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  // getCustomTimePeriodDrop() {
  //   this.spinner.show();
  //   this.accountsService.getCustomTimePeriodDropdownValue().subscribe((res: any) => {
  //     this.periodType = res.data.periodType;
  //     this.parentPeriodId = res.data.parentPeriodId;
  //     this.spinner.hide();
  //     for (const value of this.periodType) {
  //       this.periodTypeArray.push({
  //         label: value.description,
  //         value: value.periodTypeId
  //       })
  //     }
  //     for (const value of this.parentPeriodId) {
  //       this.parentPeriodIdArray.push({
  //         label:value.organizationPartyId + ':' + value.parentPeriodId + ' ' + value.periodTypeId +':'+ value.periodNum + ' [' + value.customTimePeriodId + ']',
  //         value: value.customTimePeriodId
  //       })
  //     }
  

  //   })
   
  // }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  detailPage(id){
    this._Router.navigate(["crm/Forecast/viewForecast"], 
    { queryParams: {salesForecastId:id} })
  }
  updateSalesForecast(): void {
    this.spinner.show();

    const requestData = {
      "bestCaseAmount": this.createForecast.value.bestCaseAmount  ,
      "changeNote": this.createForecast.value.changeNote  ,
      "closedAmount": this.createForecast.value.closedAmount  ,
      "createdByUserLoginId": "" ,
      "currencyUomId": this.createForecast.value.currency  ,
      "customTimePeriodId": this.createForecast.value.customTimeID  ,
      "forecastAmount": this.createForecast.value.forecastAmount  ,
      "internalPartyId": this.createForecast.value.internalPartyID  ,
      "modifiedByUserLoginId": ""  ,
      "organizationPartyId": this.createForecast.value.organizationPartyID  ,
      "parentSalesForecastId": this.createForecast.value.ParentSalesForecastID  ,
      "percentOfQuotaClosed": this.createForecast.value.quotaClosed  ,
      "percentOfQuotaForecast": this.createForecast.value.quotaPercent  ,
      "pipelineAmount": this.createForecast.value.pipelineAmount  ,
      "quotaAmount": this.createForecast.value.quotaAmount  ,
      "salesForecastId": this.salesForecastId
    }

    this.myContactsService.updateSalesForecast(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getFindSalesForecast();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createSalesForecast(): void {
    this.spinner.show();

    const requestData = {
      "bestCaseAmount": this.createForecast.value.bestCaseAmount  ,
      "changeNote": this.createForecast.value.changeNote  ,
      "closedAmount": this.createForecast.value.closedAmount  ,
      "createdByUserLoginId": "admin" ,
      "currencyUomId": this.createForecast.value.currency  ,
      "customTimePeriodId": this.createForecast.value.customTimeID  ,
      "forecastAmount": this.createForecast.value.forecastAmount  ,
      "internalPartyId": this.createForecast.value.internalPartyID  ,
      "modifiedByUserLoginId": "admin"  ,
      "organizationPartyId": this.createForecast.value.organizationPartyID  ,
      "parentSalesForecastId": this.createForecast.value.ParentSalesForecastID  ,
      "percentOfQuotaClosed": this.createForecast.value.quotaClosed  ,
      "percentOfQuotaForecast": this.createForecast.value.quotaPercent  ,
      "pipelineAmount": this.createForecast.value.pipelineAmount  ,
      "quotaAmount": this.createForecast.value.quotaAmount  ,
    }

    this.myContactsService.createSalesForecast(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.createForecast.reset();
        this.getFindSalesForecast();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getPartyIdList() {
    this.spinner.show();
    this.myContactsService.getPartyIdList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }
  getCurrencyRate() {
    this.spinner.show();
    this.myContactsService.getCurrencyDataList().subscribe(res => {
      const currencyList = res.data.currencyList;
      this.spinner.hide();
      for (const value of currencyList) {
        this.currencyArray.push({
          label: value.description + "-" + value.uomId,
          value: value.uomId
        })
      }
    })
  }

  ParentSalesForecastID() {
    this.spinner.show();
    this.myContactsService.ParentSalesForecastID().subscribe(res => {
      this.parentSalesForecastID = res.data[0].parentSalesForecastID;
      this.spinner.hide();
      for (const value of this.parentSalesForecastID) {
        this.parentSalesForecastIDArray.push({
          label: value.salesForecastId,
          value: value.salesForecastId
        })
      }
    })
  }
  reset() {
    this.spinner.show();
    this.findForecast.bestCaseAmount = "";
    this.findForecast.closedAmount = "";
    this.findForecast.parentForecastID = "";
    this.findForecast.organizationPartyID = "";
    this.findForecast.internalPartyID = "";
    this.findForecast.forecastID = "";
    this.findForecast.customTimeID = "";
    this.findForecast.currency = "";
    this.findForecast.quotaAmount = "";
    this.findForecast.quotaPercent = "";
    this.findForecast.quotaClosed = "";
    this.findForecast.pipelineAmount = "";
    this.findForecast.forecastAmount = "";

    const formData = {
      "bestCaseAmount": "",
      "closedAmount": "",
      "currency": "",
      "customTimePeriodId": "",
      "forecastAmount": "",
      "internalPartyId": "",
      "organizationPartyId": "",
      "parentSalesForecastId": "",
      "percentOfQuotaClosed": "",
      "percentOfQuotaForecast": "",
      "pipelineAmount": "",
      "quotaAmount": "",
      "salesForecastId": ""
    }

    this.myContactsService.FindSalesForecast(formData).subscribe((res: any) => {
      this.findSalesForecastList = res.data[0].node;
      this.spinner.hide();
    })
  }
  getFindSalesForecast() {
    this.spinner.show();
    const formData = {
      "bestCaseAmount": "",
      "closedAmount": "",
      "currency": "",
      "customTimePeriodId": "",
      "forecastAmount": "",
      "internalPartyId": "",
      "organizationPartyId": "",
      "parentSalesForecastId": "",
      "percentOfQuotaClosed": "",
      "percentOfQuotaForecast": "",
      "pipelineAmount": "",
      "quotaAmount": "",
      "salesForecastId": ""
    }

    this.myContactsService.FindSalesForecast(formData).subscribe(res => {
      this.findSalesForecastList = res.data[0].node;
      this.spinner.hide();

    })
  }
  FindSalesForecast() {
    this.spinner.show();
    const formData = {
      "bestCaseAmount": this.findForecast.bestCaseAmount ? this.findForecast.bestCaseAmount : '',
      "closedAmount": this.findForecast.closedAmount ? this.findForecast.closedAmount : '',
      "currency": this.findForecast.currency ? this.findForecast.currency : '',
      "customTimePeriodId": this.findForecast.customTimeID ? this.findForecast.customTimeID : '',
      "forecastAmount": this.findForecast.forecastAmount ? this.findForecast.forecastAmount : '',
      "internalPartyId": this.findForecast.internalPartyID ? this.findForecast.internalPartyID : '',
      "organizationPartyId": this.findForecast.organizationPartyID ? this.findForecast.organizationPartyID : '',
      "parentSalesForecastId": this.findForecast.parentForecastID ? this.findForecast.parentForecastID : '',
      "percentOfQuotaClosed": this.findForecast.quotaClosed ? this.findForecast.quotaClosed : '',
      "percentOfQuotaForecast": this.findForecast.quotaPercent ? this.findForecast.quotaPercent : '',
      "pipelineAmount": this.findForecast.pipelineAmount ? this.findForecast.pipelineAmount : '',
      "quotaAmount": this.findForecast.quotaAmount ? this.findForecast.quotaAmount : '',
      "salesForecastId": this.findForecast.forecastID ? this.findForecast.forecastID : '',

    }

    this.myContactsService.FindSalesForecast(formData).subscribe(res => {
      this.findSalesForecastList = res.data[0].node;
      this.spinner.hide();

    })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
