import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { SortEvent } from 'primeng/api';
import { Location } from '@angular/common';

@Component({
  selector: 'app-payment-gateway-response',
  templateUrl: './payment-gateway-response.component.html',
  styleUrls: ['./payment-gateway-response.component.css']
})
export class PaymentGatewayResponseComponent implements OnInit {
  activeCategory=3;
  total=0;
  isShown= false; 
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  pageNo = 1;

  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  paymentSearch:FormGroup;
  billingAccounts:any[]=[];
  opportunityIdArray: any = [
    {
        label: 'Equals',
        value: 'Equals'
    },
    {
        label: 'Contains',
        value: 'Contains'
    },
    {
        label: 'Is Empty',
        value: 'Is Empty'
    },
    {
        label: 'Begins With',
        value: 'Begins With'
    },
];
    gateway: any;
    getPaymentMethodType: any;
    getPaymentMethodTypeArray: any[]=[];
    userProfile: any;
    enumId: string;
    enumDataArray: any[]=[];
    enumDataArrayList: any[]=[];

  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly accountsService: AccountsService,
private _location: Location,
   ) { 
      this.paymentSearch = this._FormBuilder.group({
        transCode: [''],
        ReferenceNum: [''],
        refContains: ['Contains'],
        billingContains: ['Contains'],
        responseId: [''],
        paymnetPref:[''],
        accountingContains: ['Contains'],
        servicePayment: [''],
        paymnetMethodType: ['']
    });
    
  }
  ngOnInit(): void {
      this.getPaymentResponse();
      this.getPaymentMethodTypeGlSetting();
      this.getEenumByType();
      this.getEenumByTypeData();
  
  }
  backWindow(){
    this._location.back();
  }
    toDashboard()
  {
  this._Router.navigate(['/financial/main']);
  }
  getEenumByTypeData(): void {
    this.spinner.show();
    this.enumId="PRDS_PAYSVC"
    this.accountsService.getEenumByType(this.enumId).subscribe((res) => {
      const enumDa = res.data;
      this.spinner.hide();
      for (const value of enumDa) {
        this.enumDataArrayList.push({
          label: value.description,
          value: value.enumId,
        });
      }
    });
   
  }
  Authorize(){
    this._Router.navigate(["/financial/authorize-transaction"])
  }
  Capture(){
    this._Router.navigate(["/financial/capture"])
  }
  GatewayResponses(){
    this._Router.navigate(["/financial/payment-gateway-response"])
  }
  ManualElectronicTransaction(){
    this._Router.navigate(["/financial/manual-electronic-transaction"])
  }
  getPaymentResponse() {
    this.spinner.show();
    const req = {
        "orderPaymentPreferenceId": "",
        "orderPaymentPreferenceIdSearchType": "Contains",
        "paymentGatewayResponseId": "",
        "paymentGatewayResponseIdSearchType": "Contains",
        "paymentMethodTypeId": "",
        "paymentServiceTypeEnumId": "",
        "referenceNum": "",
        "referenceNumSearchType": "Contains",
        "transCodeEnumId": ""

    }
    this.accountsService.getGatewayResponse(this.finSize, req).subscribe((res: any) => {
      this.gateway = res.data;
      this.spinner.hide();

    })
   
  }
  getEenumByType(): void {
    this.spinner.show();
    this.enumId="PGT_CODE"
    this.accountsService.getEenumByType(this.enumId).subscribe((res) => {
      const enumData = res.data;
      this.spinner.hide();
      for (const value of enumData) {
        this.enumDataArray.push({
          label: value.description,
          value: value.enumId,
        });
      }
    });
   
  }
 
  reset(){
    this.paymentSearch.reset();
    this.getPaymentResponse();
  }
  getPaymentMethodTypeGlSetting() {
    this.spinner.show();
    this.accountsService.getPaymentMethodTypeGlSetting(this.finSize).subscribe((res: any) => {
      this.getPaymentMethodType = res.data;
      this.spinner.hide();
      for (const value of this.getPaymentMethodType) {
        this.getPaymentMethodTypeArray.push({
          label: value.description,
          value: value.paymentMethodTypeId
        })
      }
    })
  
  }
  onSubmit() {
   
      this.gateway=[];
    this.spinner.show();
    const req = {
        "orderPaymentPreferenceId":this.paymentSearch.value.paymnetPref,
        "orderPaymentPreferenceIdSearchType":this.paymentSearch.value.accountingContains,
        "paymentGatewayResponseId": this.paymentSearch.value.responseId,
        "paymentGatewayResponseIdSearchType": this.paymentSearch.value.billingContains,
        "paymentMethodTypeId": this.paymentSearch.value.paymnetMethodType,
        "paymentServiceTypeEnumId":this.paymentSearch.value.servicePayment,
        "referenceNum": this.paymentSearch.value.ReferenceNum,
        "referenceNumSearchType": this.paymentSearch.value.refContains,
        "transCodeEnumId": this.paymentSearch.value.transCode,

    }
    this.accountsService.getGatewayResponse(this.finSize, req).subscribe((res: any) => {
      this.gateway = res.data;
      this.spinner.hide();

    })
   
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
}
viewPayment(eid){
    this._Router.navigate(['financial/view-gateway-response'],{ queryParams: { id: eid} });
}
}


