import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ThesaurusService {
    constructor(
        readonly _HttpClient: HttpClient
    ) { }
    getRelationShipList(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/catalog/thesaurus/relationships`).toPromise();
    }
    createThesaurus(formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/catalog/thesaurus`, formData).toPromise();
    }
    editKeywordThesaurus(firstLetter: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/catalog/thesaurus/editKeywordThesaurus?firstLetter=${firstLetter}`).toPromise();
    }
    getKeywordByEnteredKeyword(enteredKeyword: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/catalog/thesaurus/${enteredKeyword}`).toPromise();
    }
    deleteAll(enteredKeyword: string): Promise<any> {
        return this._HttpClient.delete(`/phyCorp/control/api/v1/catalog/thesaurus/${enteredKeyword}`).toPromise();
    }
    deleteAlternate(alternateKeyword: string, enteredKeyword: string): Promise<any> {
        return this._HttpClient.delete(`/phyCorp/control/api/v1/catalog/thesaurus/${enteredKeyword}/${alternateKeyword}`).toPromise();
    }
}