import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import {TreeNode} from 'primeng/api';
@Component({
  selector: 'app-child-work-effort',
  templateUrl: './child-work-effort.component.html',
  styleUrls: ['./child-work-effort.component.css']
})
export class ChildWorkEffortComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 3;
  addNewChild: FormGroup;
  addExistingChild: FormGroup;
  personId: any;
  partyIdArray: any[]=[];
  Type: any;
  TypeArray: any[]=[];
  Purpose: any;
  PurposeArray: any[]=[];
  CurrencyList: any;
  CurrencyListArray: any[]=[];
  TemporalExpression: any;
  TemporalExpressionArray: any[]=[];
  StatusNewWorkEffort: any;
  StatusNewWorkEffortArray: any[]=[];
  CommunicationEventId: any;
  CommunicationEventIdArray: any[]=[];
  FacilityIdList: any;
  FacilityIdListArray: any[]=[];
  RequirementList: any;
  RequirementListArray: any[]=[];
  yesNOArray: { label: string; value: string; }[];
  statusArray: { label: string; value: string; }[];
  scopeArray: { label: string; value: string; }[];
  PriorityArray: { label: string; value: string; }[];
  WorkEffortAssocType: any;
  WorkEffortAssocTypeArray: any[]=[];
  workEffortId: any;
  ActualStartDate: string;
  ActualCompletionDate: string;
  EstimatedStartDate: string;
  EstimatedCompletionDate: string;
  FromDate: string;
  ThroughDate: string;
  getTaskListData: any;
  FixedAssetChildren: TreeNode[];
  ChildrenWorkeffort: TreeNode[];
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly createEventServices: CreateEventServices,
    readonly addSkillService: AddSkillService,
    readonly myContactsService: MyContactsService,
    private _location: Location,
    
    readonly toastr: ToastrService,
    readonly datePipe: DatePipe,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
        readonly _FormBuilder: FormBuilder,
    private _Router : Router
  ) { 

    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.statusArray = [

      {
        label: 'Any',
        value: 'Any'
      },
      {
        label: 'Active',
        value: 'PRODUCT_ACTIVE'
      },
      {
        label: 'Closed',
        value: 'PRODUCT_CLOSED'
      },


    ]; 
    this.scopeArray = [

      {
        label: 'General, public access',
        value: 'WES_PUBLIC'
      },
      {
        label: 'Restricted, private access',
        value: 'WES_PRIVATE'
      },
      {
        label: 'Very restricted, confidential access',
        value: 'WES_CONFIDENTIAL'
      },
    ]; 
    this.PriorityArray = [

      {
        label: '1 (High)',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9 (Low)',
        value: '9'
      },

    ];
    this.addNewChild = this._FormBuilder.group({
      fromID: [''],
      assocTypeID: [''],
      sequenceNum: [''],
      fromDate: [''],
      throughDate: [''],
      toID: [''],
      name: [''],
      description: [''],
      quickAssignPartyID: [''],
      type: [''],
      purpose: [''],
      status: [''],
      percentComplete: [''],
      priority: [''],
      scope: [''],
      estimatedStartDate: [''],
      estimatedCompletionDate: [''],
      actualStartDate: [''],
      actualCompletionDate: [''],
      commEventID: [''],
      reason: [''],
      websiteID: [''],
      parentID: [''],
      enumID: [''],
      sendMail: [''],
      loactionDesc: [''],
      EstimatedMilliSeconds: [''],
      EstimatedSetupMillis: [''],
      estimateCalc: [''],
      ActualMilliSeconds: [''],
      ActualSetupMillis: [''],
      TotalMillisecondsAllowed: [''],
      totalMoneyAllowed: [''],
      Currency: [''],
      specialTerms: [''],
      timeTransparency: [''],
      universalID: [''],
      sourceReferenceID: [''],
      fixedAssetID: [''],
      FacilityID: [''],
      infoURL: [''],
      TemporalExpression: [''],
      serviceLoaderName: [''],
      qualityToProduce: [''],
      quantityProduced: [''],
      quantityRejected: [''],
      reservPersons: [''],
      reserv2nd: [''],
      reservnth: [''],
      accMapID: [''],
      accSpotID: [''],
      RequirementID: [''],
      WorkEffortIDFrom: [''],
      WorkEffortAssocTypeID: [''],
      WorkEffortIDTo: [''],
      QuickAssignPartyID: [''],
      SendNotificationMail: [''],
      CommunicationEventID: [''],

    })
    this.addExistingChild = this._FormBuilder.group({
      WorkEffortIDFrom: [''],
      WorkEffortIDTo: [''],
      WorkEffortAssocTypeID: [''],
      sequenceNum: [''],
      throughDate: [''],
      fromDate: [''],
    })
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params['workEffortId'];

    });
    this.getPartyId();
    this.getType();
    this.getPurpose();
    this.getCurrencyList();
    this.getTemporalExpression();
    this.getStatusNewWorkEffort();
    this.getCommunicationEventId();
    this.getFacilityIdList();
    this.getRequirementList();
    this.getWorkEffortAssocType();
    this.getChildrenWorkeffort();
  }
  nodeSelect(event){
  
  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  getChildrenWorkeffort() {
    this.spinner.show();
    this.myContactsService.getChildrenWorkeffort(this.workEffortId).subscribe(res => {
      this.ChildrenWorkeffort = res.data;
      this.spinner.hide();
  
    })
  }
  createWorkEffortAndAssoc(): void {
    this.spinner.show();
    const actualStartDate=this.addNewChild.get('actualStartDate').value;
    this.ActualStartDate=this.datePipe.transform(actualStartDate,"yyyy-MM-dd hh:mm:ss");
    const actualCompletionDate=this.addNewChild.get('actualCompletionDate').value;
    this.ActualCompletionDate=this.datePipe.transform(actualCompletionDate,"yyyy-MM-dd hh:mm:ss");
    const estimatedStartDate=this.addNewChild.get('estimatedStartDate').value;
    this.EstimatedStartDate=this.datePipe.transform(estimatedStartDate,"yyyy-MM-dd hh:mm:ss");
    const estimatedCompletionDate=this.addNewChild.get('estimatedCompletionDate').value;
    this.EstimatedCompletionDate=this.datePipe.transform(estimatedCompletionDate,"yyyy-MM-dd hh:mm:ss");
    const fromDate=this.addNewChild.get('fromDate').value;
    this.FromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const throughDate=this.addNewChild.get('throughDate').value;
    this.ThroughDate=this.datePipe.transform(throughDate,"yyyy-MM-dd hh:mm:ss");
    
     const formData ={
  "accommodationMapId": this.addNewChild.value.accMapID,
  "accommodationSpotId": this.addNewChild.value.accSpotID,
  "actualCompletionDate": this.addNewChild.value.actualCompletionDate,
  "actualMilliSeconds": this.addNewChild.value.ActualMilliSeconds,
  "actualSetupMillis": this.addNewChild.value.ActualSetupMillis,
  "actualStartDate": this.addNewChild.value.actualStartDate,
  "communicationEventId": this.addNewChild.value.CommunicationEventID,
  "currentStatusId": this.addNewChild.value.status,
  "description": this.addNewChild.value.description,
  "estimateCalcMethod": this.addNewChild.value.estimateCalc,
  "estimatedCompletionDate": this.addNewChild.value.estimatedCompletionDate,
  "estimatedMilliSeconds": this.addNewChild.value.EstimatedMilliSeconds,
  "estimatedSetupMillis": this.addNewChild.value.EstimatedSetupMillis,
  "estimatedStartDate": this.addNewChild.value.estimatedStartDate,
  "facilityId": this.addNewChild.value.FacilityID,
  "fixedAssetId": this.addNewChild.value.fixedAssetID,
  "fromDate": this.addNewChild.value.fromDate,
  "infoUrl": this.addNewChild.value.infoURL,
  "locationDesc": this.addNewChild.value.loactionDesc,
  "moneyUomId": this.addNewChild.value.Currency,
  "percentComplete": this.addNewChild.value.percentComplete,
  "priority": this.addNewChild.value.priority,
  "quantityProduced": this.addNewChild.value.quantityProduced,
  "quantityRejected": this.addNewChild.value.quantityRejected,
  "quantityToProduce": this.addNewChild.value.qualityToProduce,
  "quickAssignPartyId": this.addNewChild.value.QuickAssignPartyID,
  "reason": this.addNewChild.value.reason,
  "requirementId": this.addNewChild.value.RequirementID,
  "reserv2ndPPPerc": this.addNewChild.value.reserv2nd,
  "reservNthPPPerc": this.addNewChild.value.reservnth,
  "reservPersons": this.addNewChild.value.reservPersons,
  "scopeEnumId": this.addNewChild.value.scope,
  "sendNotificationEmail": this.addNewChild.value.SendNotificationMail,
  "sequenceNum": this.addNewChild.value.sequenceNum,
  "serviceLoaderName": this.addNewChild.value.serviceLoaderName,
  "showAsEnumId": this.addNewChild.value.enumID,
  "sourceReferenceId": this.addNewChild.value.sourceReferenceID,
  "specialTerms": this.addNewChild.value.specialTerms,
  "tempExprId": this.addNewChild.value.TemporalExpression,
  "thruDate": this.addNewChild.value.throughDate,
  "timeTransparency": this.addNewChild.value.timeTransparency,
  "totalMilliSecondsAllowed": this.addNewChild.value.TotalMillisecondsAllowed,
  "totalMoneyAllowed": this.addNewChild.value.totalMoneyAllowed,
  "universalId": this.addNewChild.value.universalID,
  "webSiteId": this.addNewChild.value.websiteID,
  "workEffortAssocTypeId": this.addNewChild.value.WorkEffortAssocTypeID,
  "workEffortIdFrom": this.addNewChild.value.WorkEffortIDFrom,
  "workEffortIdTo": this.addNewChild.value.WorkEffortIDTo,
  "workEffortName": this.addNewChild.value.name,
  "workEffortParentId": this.addNewChild.value.parentID,
  "workEffortPurposeTypeId": this.addNewChild.value.purpose,
  "workEffortTypeId": this.addNewChild.value.type
         
     }
     this.myContactsService.createWorkEffortAndAssoc(formData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.closebutton.nativeElement.click();
         this.addNewChild.reset();
         this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"],{ queryParams: { workEffortId: this.workEffortId} })
           
        
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
   createWorkEffortAssoc(): void {
    this.spinner.show();
    const fromDate=this.addExistingChild.get('fromDate').value;
    this.FromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd HH:mm:ss");
    const throughDate=this.addExistingChild.get('throughDate').value;
    this.ThroughDate=this.datePipe.transform(throughDate,"yyyy-MM-dd HH:mm:ss");
    
     const formData ={
      "fromDate": this.FromDate,
      "sequenceNum": this.addExistingChild.value.sequenceNum,
      "thruDate": this.ThroughDate,
      "workEffortAssocTypeId": this.addExistingChild.value.WorkEffortAssocTypeID,
      "workEffortIdFrom": this.addExistingChild.value.WorkEffortIDFrom,
      "workEffortIdTo": this.addExistingChild.value.WorkEffortIDTo
         
     }
     this.myContactsService.createWorkEffortAssoc(formData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.closebutton.nativeElement.click();
         this.addExistingChild.reset();
         this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"],{ queryParams: { workEffortId: this.workEffortId} })
         
        
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
  addWorkEffort(){
    this._Router.navigate(["/psa/work-effort/add-work-effort"])
  }
  editWorkEffort1(){
    this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  summary(){
    this._Router.navigate(["/psa/work-effort/task-list-view/summary"],{ queryParams: { workEffortId: this.workEffortId} })
  }

  childWorkEfforts(){
    this._Router.navigate(["/psa/work-effort/task-list-view/childWorkEfforts"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  partyAssigns(){
    this._Router.navigate(["/psa/work-effort/task-list-view/PartyAssigns"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  rates(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Rates"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  commEvents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/CommEvents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  shopLists(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ShopLists"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requests(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requests"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requirements(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requirements"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  quotes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Quotes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  orderHeaders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/OrderHeaders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  timeEntries(){
    this._Router.navigate(["/psa/work-effort/task-list-view/TimeEntries"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  notes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Notes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Contents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  products(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Products"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  reviews(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Reviews"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  keywords(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Keywords"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contactMechs(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ContactMechs"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  agreementApplics(){
    this._Router.navigate(["/psa/work-effort/task-list-view/AgreementApplics"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  fixedAssets(){
    this._Router.navigate(["/psa/work-effort/task-list-view/FixedAssets"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  attributes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Attributes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  eventReminders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/EventReminders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   }
   getWorkEffortAssocType() {
    this.spinner.show();
    this.addSkillService.getWorkEffortAssocType().subscribe(res => {
      this.WorkEffortAssocType = res.data[0].data;
      this.spinner.hide();
      for (const value of this.WorkEffortAssocType) {
        this.WorkEffortAssocTypeArray.push({
          label: value.description,
          value: value.workEffortAssocTypeId
        })
      }
    })
  }
  getType() {
    this.spinner.show();
    this.addSkillService.getType().subscribe(res => {
      this.Type = res.data[0].type;
      this.spinner.hide();
      for (const value of this.Type) {
        this.TypeArray.push({
          label: value.description,
          value: value.workEffortTypeId
        })
      }
    })
   }
   getPurpose() {
    this.spinner.show();
    this.addSkillService.getPurpose().subscribe(res => {
      this.Purpose = res.data[0].Purpose;
      this.spinner.hide();
      for (const value of this.Purpose) {
        this.PurposeArray.push({
          label: value.description,
          value: value.workEffortPurposeTypeId
        })
      }
    })
   }
   getCurrencyList() {
    this.spinner.show();
    this.addSkillService.getCurrencyList().subscribe(res => {
      this.CurrencyList = res.data[0].node;
      this.spinner.hide();
      for (const value of this.CurrencyList) {
        this.CurrencyListArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  getTemporalExpression() {
    this.spinner.show();
    this.addSkillService.getTemporalExpression().subscribe(res => {
      this.TemporalExpression = res.data[0].temporalExpression;
      this.spinner.hide();
      for (const value of this.TemporalExpression) {
        this.TemporalExpressionArray.push({
          label: value.tempExprId,
          value: value.tempExprId
        })
      }
    })
  }
  getStatusNewWorkEffort() {
    this.spinner.show();
    this.addSkillService.getStatusNewWorkEffort().subscribe(res => {
      this.StatusNewWorkEffort = res.data[0].data;
      this.spinner.hide();
      for (const value of this.StatusNewWorkEffort) {
        this.StatusNewWorkEffortArray.push({
          label: value.statusValue,
          value: value.description
        })
      }
    })
  }
  getCommunicationEventId() {
    this.spinner.show();
    this.addSkillService.getCommunicationEventId().subscribe(res => {
      this.CommunicationEventId = res.data[0].communicationEventId;
      this.spinner.hide();
      for (const value of this.CommunicationEventId) {
        this.CommunicationEventIdArray.push({
          label: value.communicationEventId,
          value: value.statusId
        })
      }
    })
  }
  getFacilityIdList() {
    this.spinner.show();
    this.addSkillService.getFacilityIdList().subscribe(res => {
      this.FacilityIdList = res.data;
      this.spinner.hide();
      for (const value of this.FacilityIdList) {
        this.FacilityIdListArray.push({
          label: value.node1.facilityName,
          value: value.node1.facilityId

        })
      }
    })
  }
  getRequirementList() {
    this.spinner.show();
    this.addSkillService.getRequirementList().subscribe(res => {
      this.RequirementList = res.data[0].list;
      this.spinner.hide();
      for (const value of this.RequirementList) {
        this.RequirementListArray.push({
          label: value.requirementId,
          value: value.description
        })
      }
    })
  }
}
