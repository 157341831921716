<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;"> Skill
                </span>
                <span class="color-black pl-1" > >Add New Skill
                </span>

                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''"  *ngIf="!this.show"><a>
                        Add Skill
                    </a></li>
                 
                       
                      <li [ngClass]="activeCategory==2?'active':''"  *ngIf="this.show"><a>
                        Update Skill
                    </a></li>
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createSkill">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRightSSS"  >
                                                <label for="exampleInputEmail1">
                                                    Skill Type ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2" *ngIf="!this.show">
                                                <p-dropdown  placeholder="Enter Skill Type ID" filter="true"
                                                formControlName="SkillTypeID"
                                                [options]="skillTypeArray" optionlabel="label" >
                                                                
                                                </p-dropdown>

                                            </div>
                                            <div class="col-lg-2" *ngIf="this.show">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Estimated Num People"
                                                formControlName="SkillTypeID" readonly>

                                            </div>
                                           
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Estimated Num People</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Estimated Num People"
                                            formControlName="EstimatedNumPeople" >
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRightSSS"  >
                                                <label for="exampleInputEmail1">
                                                    Estimated Duration</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Estimated Duration"
                                            formControlName="EstimatedDuration" >

                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Estimated Cost</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Estimated cost"
                                                formControlName="Estimatedcost" >
                                            </div>
                                        </div>
                                    </div>
                                 
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: -28%!important;">
                                <button type="submit" *ngIf="!this.show" (click)="onSubmit()"      class="btn btn-secondary submit-btn">Save</button>
                                <button type="submit" *ngIf="this.show" (click)="onUpdate()"      class="btn btn-secondary submit-btn">Update</button>
                             
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
