<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact bg-white mt-20 mb-20 font-medium">
                <span class="color-grey float-left">Agreement ID: {{agreementId}} </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>
            </div>
            <div class=" bg-white color-grey">

                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">
                    </div>
                </div>


                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">
                                            <div class="divA">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling hCard">
                                                            Agreeement Information
                                                        </h4>
                                                        <span class="px-2 edit-buttons " style="float: left !important;cursor: pointer;" data-toggle="modal" data-target="#exampleModalCenter" (click)="getAgreementById()">Update</span>
                                                    </div>
                                                    <div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div *ngIf="agreementList" class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Agreeement ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{agreementList.agreementId ?
                                          agreementList.agreementId:'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Agreeement Date</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{agreementList.agreementDate ?
                                          (agreementList.agreementDate|date:'yyyy-MM-d hh:mm:ss') :'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Agreeement Type Id
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{agreementList.agreementTypeId
                                          ? agreementList.agreementTypeId:'N/A'}}</span>

                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Party Id From</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{agreementList.partyIdFrom ?
                                          agreementList.partyIdFrom:'N/A'}}</span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Party Id To</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{agreementList.partyIdTo ?
                                          agreementList.partyIdTo:'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Product Id</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{agreementList.productId ?
                                          agreementList.productId:'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Role Type Id From</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{agreementList.roleTypeIdFrom ?
                                          agreementList.roleTypeIdFrom:'N/A'}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Role Type Id To </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{agreementList.roleTypeIdTo ?
                                          agreementList.roleTypeIdTo:'N/A' }}</span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Text Data </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{agreementList.textData ?
                                          agreementList.textData:'N/A' }}</span>
                                                                            </div>


                                                                        </div>



                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling hCard">
                                                            Agreement Terms
                                                        </h4>
                                                        <span class="edit-buttons  hCreate" data-toggle="modal" data-target="#exampleModalCenterTermData">Create</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="AgreementTermList"   scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Term Type ID
                                                                                    <p-sortIcon field="facilityGroupId"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                <div style="color: white;"> Agreement Item Sequential ID
                                                                                    <p-sortIcon field="facilityGroupTypeId"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                                <div style="color: white;"> Invoice Item Type
                                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                                <div style="color: white;"> From Date
                                                                                    <p-sortIcon field="quantity"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Through Date
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Term Value
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Text Value
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Min Quantity
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Max Quantity
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Description
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'190px'}" style="text-align: center;" pSortableColumn="price">
                                                                                <div style="color: white;"> Action</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    {{product.termTypeId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">{{product.agreementItemSeqId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">{{product.invoiceItemTypeId}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                {{product.thruDate | date:'yyyy-MM-d hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'190px'}">{{product.termValue}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">{{product.textValue}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">{{product.minQuantity}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">{{product.maxQuantity}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">{{product.description}}</td>
                                                                            <td [ngStyle]="{'width':'190px'}">
                                                                                <div>
                                                                                    <svg class="hover" xmlns="http://www.w3.org/2000/svg" width="16" routerLink="/financial/payable/customer-agreeement/create-agreement-terms" [queryParams]="{agreementID:agreementId ,agreementTermId:product.agreementTermId}" height="16" viewBox="0 0 21.223 21.222">
                                            <g transform="translate(0 -0.004)">
                                              <g transform="translate(0 1.52)">
                                                <path
                                                  d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                  transform="translate(0 -34.137)" />
                                              </g>
                                              <g transform="translate(4.548 0.004)">
                                                <g transform="translate(0 0)">
                                                  <path
                                                    d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                    transform="translate(-102.409 -0.004)" />
                                                </g>
                                              </g>
                                            </g>
                                          </svg>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" (click)="deleteAgreementTerm(product.agreementTermId)" viewBox="0 0 26 26">
                                            <defs>
                                              <style>
                                                .a {
                                                  fill: #f44336;
                                                }

                                                .b {
                                                  fill: #fafafa;
                                                  margin-left: 20px;
                                                  cursor: pointer;
                                                }
                                              </style>
                                            </defs>
                                            <path class="a fill-color"
                                              d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                            <path class="b"
                                              d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                              transform="translate(-147.576 -147.576)" />
                                          </svg>
                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                          <h4 class="ml-2">No Record Found</h4>
                                        </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>

                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling hCard">
                                                            Agreement Items
                                                        </h4>
                                                        <span class="edit-buttons  hCreate" data-toggle="modal" data-target="#exampleModalCenterItems">Create</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                                            <div class="form-group" style="width: 100%;">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="agreementItemList"   scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Agreement Item Sequential ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Agreement Item Type ID
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'120px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Currency
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Agreement Text
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Agreement Image
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Action</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span (click)="setNavigation(product.agreementItemSeqId,product.currencyUomId)"  class="account-button" >{{product.agreementItemSeqId}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">{{product.agreementItemTypeId}}</td>
                                                                                <td [ngStyle]="{'width':'120px'}">{{product.currencyUomId}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">{{product.agreementText}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">{{product.agreementImage}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    <fa-icon [icon]="faEdit"  class="account-button"  routerLink="/financial/{{agreementType}}/customer-agreeement/create-agreement-item" [queryParams]="{agreementId:agreementId,agreementItemSeqId:product.agreementItemSeqId}">
                                                                                    </fa-icon>
                                                                                    <fa-icon [icon]="faTrash" class="text-danger ml-2 cursor-pointer" (click)="deleteAgreementItem(product.agreementItemSeqId)"></fa-icon>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                            <h4 class="ml-2">No Record Found</h4>
                                          </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>





                                                    </div>

                                                </div>
                                                <br>

                                            </div>

                                            <div class="divB">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling hCard">
                                                            Agreement Roles
                                                        </h4>
                                                        <span class="edit-buttons  hCreate" data-toggle="modal" data-target="#exampleModalCenterRoles">Create</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="form-group">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">

                                                                <div class="card own-account-table mt-2 borderTable">
                                                                    <p-table [value]="agreementRoles"   scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Party ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Role Type ID
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.partyId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'190px'}">{{product.roleTypeId}}</td>
                                                                                <td [ngStyle]="{'width':'190px'}">

                                                                                    <fa-icon [icon]="faTrash" class="text-danger ml-2 cursor-pointer" (click)="removeAgreementRole(product.partyId, product.roleTypeId)">
                                                                                    </fa-icon>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                            <h4 class="ml-2">No Record Found</h4>
                                          </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling hCard">
                                                            Agreement Contents
                                                        </h4>
                                                        <span class="edit-buttons  hCreate" data-toggle="modal" data-target="#exampleModalCenterContent">Create</span>
                                                    </div>
                                                    <div class="panel-body allUserCardDetail" style="width: 100%;">
                                                        <div class="card own-account-table borderTable">
                                                            <p-table [value]="agreementContents"   scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Agreement Seq ID
                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> Content Name
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> Agreement Content Type ID
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> Status ID
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> Content Type ID
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> Form Date
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> Thru Date
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> Action
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                {{product.agreementItemSeqId}}
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">{{product.contentName}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">{{product.agreementContentTypeId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">{{product.statusId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">{{product.contentTypeId}}</td>
                                                                        <td [ngStyle]="{'width':'190px'}">{{product.fromDate | date:'yyyy-MM-d hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">{{product.thruDate | date:'yyyy-MM-d hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            <fa-icon [icon]="faFileDownload" class="text-parimary cursor-pointer" (click)="downloadAgreementContent(product.contentId,product.contentName)">
                                                                            </fa-icon>
                                                                            <fa-icon [icon]="faTrash" class="text-danger ml-2 cursor-pointer" (click)="  removeAgreementContent(product.agreementContentTypeId, product.agreementItemSeqId, product.contentId, product.contentName, product.contentTypeId, product.fromDate, product.statusId, product.thruDate)">
                                                                            </fa-icon>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                        <h4 class="ml-2">No Record Found</h4>
                                      </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>






                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update {{agreementType==='receiveable' ? 'Customer' :'Commission'}}
          Agreement</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                    Update Customer {{agreementType==='receiveable' ? 'Customer' :'Commission'}} Agreement</a></li>

                            </ul>
                        </div>
                        <div class="container-fluid main-container-dialog">
                            <div class="row">
                                <div class="col-12">
                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100" [formGroup]="updateCustomerAgreements">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Agreement Id</label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="text" class="form-control" formControlName="agreementId" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Agreement Id" readonly>
                                                                </div>


                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Product ID</label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <p-dropdown [options]="productList" optionlabel="productId" filter="true" placeholder="Select" formControlName="productId" placeholder="Select Product Id">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Party Id From</label>

                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown [options]="PersonsAndPartyGroupsList" optionlabel="partyId" placeholder="Select Party Id" filter="true" placeholder="Select" formControlName="partyIdFrom">
                                                                    </p-dropdown>
                                                                </div>


                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Party Id To</label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <p-dropdown [options]="PersonsAndPartyGroupsList" optionlabel="partyId" placeholder="Select party Id" filter="true" placeholder="Select" formControlName="partyIdTo">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Role Type Id From</label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <p-dropdown [options]="RoleTypesList" optionlabel="description" placeholder="Select Role Type Id" filter="true" placeholder="Select" formControlName="roleTypeIdFrom">
                                                                    </p-dropdown>
                                                                </div>


                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Role Type Id To</label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <p-dropdown [options]="RoleTypesList" optionlabel="description" placeholder="Select Role Type Id" filter="true" placeholder="Select" formControlName="roleTypeIdTo">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Agreement Type ID</label>

                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <p-dropdown [options]="AgreementTypeList" optionlabel="agreementTypeId" filter="true" placeholder="Select" formControlName="agreementTypeId">
                                                                    </p-dropdown>
                                                                </div>


                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Agreement Date</label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <p-calendar formControlName="agreementDate"></p-calendar>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">From Date</label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <p-calendar formControlName="fromDate"></p-calendar>
                                                                </div>


                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Through Date</label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <p-calendar formControlName="thruDate"></p-calendar>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="examCreate Commission Agreement
                          pleInputEmail1">Description</label>

                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="text" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Description">
                                                                </div>


                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Text Data</label>
                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <textarea name="" id="exampleInputEmail1" formControlName="textData" placeholder="Text Data" class="form-control" cols="30" rows="10"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>



                                                        <div class="col-lg-12 col-12 main-submit-button ">
                                                            <button _ngcontent-cta-c128="" type="submit" (click)="submitData()" class="btn btn-secondary submit-btn" style="margin-right: 13%;">Update</button>

                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterRoles" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterRoles" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Agreement Role</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebuttonA>&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians" style="margin-top: 0px!important;background: #cce0e95e!important;">

                                <li [ngClass]="activeCategoryMain==2?'active':''"><a>
                  Agreement Role</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white" data-toggle="collapse">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                    <div class="panel panel-default">

                                                        <div aria-labelledby="headingOne">
                                                            <div class="panel-body">


                                                                <form [formGroup]="roleFrom">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group " style="text-align: right!important;
                                                                  padding-right: 17px!important;
                                                                  margin-left: -16%;
                                                                  font-size: 10px;">
                                                                                    <label for="partyId">Party Id</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" formControlName="partyId" [options]="partyIds" optionlabel="label" placeholder="select Party ID">
                                                                                    </p-dropdown>
                                                                                    <small class="text-danger" *ngIf="!!roleFrom.controls.partyId.invalid && !!roleFrom.controls.partyId.touched">Party
                                                                      Id is required</small>
                                                                                </div>

                                                                                <div class="col-lg-3 form-group" style="text-align: right!important;
                                                                  padding-right: 17px!important;
                                                                 
                                                                  font-size: 10px;">
                                                                                    <label for="partyId">Role Type Id</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" formControlName="roleTypeId" [options]="roleTypeIds" optionlabel="label" placeholder="select Role Type ID ">
                                                                                    </p-dropdown>
                                                                                    <small class="text-danger" *ngIf="!!roleFrom.controls.roleTypeId.invalid && !!roleFrom.controls.roleTypeId.touched">Role
                                                                          Type Id is required</small>
                                                                                </div>


                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </form>
                                                                <div class="main-submit-button" style="margin-right: 34%;">
                                                                    <button type="submit" (click)="submitRole()" class="btn btn-secondary submit-btn cursor-pointer">Add</button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterItems" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterItems" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Agreement Item</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebuttonB>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp" >
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians" style="margin-top: 0px!important;background: #cce0e95e!important;">

                                <li [ngClass]="activeCategoryMain==2?'active':''"><a>
                  Agreement Item</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white" data-toggle="collapse">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                    <div class="panel panel-default">

                                                        <div aria-labelledby="headingOne">
                                                            <div class="panel-body">
                                                                <form [formGroup]="createAgreementItem">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">


                                                                                <div class="col-lg-3 form-group" style="    text-align: right!important;
                                                                  padding-right: 17px!important;
                                                                  margin-left: -7%;
                                                                  font-size: 10px;">
                                                                                    <label for="agreementInvoiceType">Agreement
                                                                          Item Type ID</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" formControlName="agreementItemTypeId" [options]="agreementItemTypes" optionlabel="label" placeholder="Select Agreement Type">
                                                                                    </p-dropdown>
                                                                                </div>

                                                                                <div class="col-lg-3 form-group " *ngIf="editItem" style="    text-align: right!important;
                                                                  padding-right: 17px!important;
                                                                 
                                                                  font-size: 10px;">
                                                                                    <label for="Id">Agreement Item Seq Id :
                                                                          {{agreementItemSeqId}}</label>
                                                                                </div>

                                                                                <div class="col-lg-2" *ngIf="editItem">

                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group" style="    text-align: right!important;
                                                                  padding-right: 17px!important;
                                                                  margin-left: -7%;
                                                                  font-size: 10px;">
                                                                                    <label for="currency">Currency</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="currencyList" formControlName="currencyUomId" optionlabel="label" placeholder="Select Currency">
                                                                                    </p-dropdown>
                                                                                </div>

                                                                                <div class="col-lg-3 form-group" style="    text-align: right!important;
                                                                  padding-right: 17px!important;
                                                                 
                                                                  font-size: 10px;">
                                                                                    <label for="agreementText">Agreement
                                                                          Text</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" formControlName="agreementText" placeholder="Agreement Text">
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group " style="    text-align: right!important;
                                                                  padding-right: 17px!important;
                                                                  margin-left: -7%;
                                                                  font-size: 10px;">
                                                                                    <label for="agreementImage">Agreement
                                                                          Image</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="text" class="form-control" formControlName="agreementImage" placeholder="Agreement Image">
                                                                                </div>


                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div class="main-submit-button" style="
                                                      margin-right: 25%;
                                                  ">
                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="submitItem()">{{editItem ? 'Update':'Create'}}</button>

                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterContent" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterContent" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Agreement Content</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebuttonC>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp" >
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians" style="margin-top: 0px!important;background: #cce0e95e!important;">

                                <li [ngClass]="activeCategoryMain==2?'active':''"><a>
                  Agreement Content</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white" data-toggle="collapse">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                    <div class="panel panel-default">

                                                        <div aria-labelledby="headingOne">
                                                            <div class="panel-body">
                                                                <form [formGroup]="createAgreementContentForm">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group" style="  text-align: right!important;
                                                                  padding-right: 17px!important;
                                                                  margin-left: -7%;
                                                                  font-size: 10px;">
                                                                                    <label for="agreementInvoiceType">Agreement
                                                                          Item Seq ID</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="itemSeqIds" optionlabel="label" formControlName="agreementItemSeqId" placeholder="Select Item">
                                                                                    </p-dropdown>
                                                                                </div>

                                                                                <div class="col-lg-3 form-group" style="  text-align: right!important;
                                                                  padding-right: 17px!important;
                                                                
                                                                  font-size: 10px;">
                                                                                    <label for="currency">Agreement Content Type
                                                                          ID</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="contentTypeIds" optionlabel="label" formControlName="agreementContentTypeId" placeholder="Select Content Type">
                                                                                    </p-dropdown>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group" style="  text-align: right!important;
                                                                  padding-right: 17px!important;
                                                                  margin-left: -7%;
                                                                  font-size: 10px;">
                                                                                    <label for="agreementText">Content Type
                                                                          ID</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="contentIds" formControlName="contentTypeId" optionlabel="label" placeholder="Select Content Id">
                                                                                    </p-dropdown>
                                                                                </div>

                                                                                <div class="col-lg-3 form-group" style="  text-align: right!important;
                                                                  padding-right: 17px!important;
                                                                
                                                                  font-size: 10px;">
                                                                                    <label for="agreementImage">Status
                                                                          ID</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [options]="statusIds" formControlName="statusId" optionlabel="label" placeholder="Select Status">
                                                                                    </p-dropdown>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group" style="  text-align: right!important;
                                                                  padding-right: 17px!important;
                                                                  margin-left: -7%;
                                                                  font-size: 10px;">
                                                                                    <label for="fromDate">From Date</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-calendar formControlName="fromDate">
                                                                                    </p-calendar>
                                                                                </div>

                                                                                <div class="col-lg-3 form-group" style="  text-align: right!important;
                                                                  padding-right: 17px!important;
                                                                  
                                                                  font-size: 10px;">
                                                                                    <label for="fromDate">Through Date</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <p-calendar formControlName="thruDate">
                                                                                    </p-calendar>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">

                                                                                <div class="col-lg-3 form-group" style="  text-align: right!important;
                                                                  padding-right: 17px!important;
                                                                  margin-left: -7%;
                                                                  font-size: 10px;">
                                                                                    <label for="file">File</label>
                                                                                </div>

                                                                                <div class="col-lg-2">
                                                                                    <input type="file" class="form-control" formControlName="file" (change)="setFile($event)">
                                                                                </div>


                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                    <div class="main-submit-button" style="
                                                      margin-right: 24%;
                                                  ">
                                                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="submitContent()">Upload</button>

                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterTermData" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTermData" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Agreement Term</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebuttonD>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryMain==2?'active':''"><a>
                  Create Customer Agreement Term</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="AgreementsTerms">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputK">
                                                        <label for="exampleInputEmail1">Term Type ID <span
                                              class="text-danger">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" name="party" [options]="TermTypeIdList" formControlName="termTypeId" optionlabel="label" placeholder="Term Type ID">
                                                        </p-dropdown>

                                                        <small class="text-danger" *ngIf="!!AgreementsTerms.controls.termTypeId.invalid && !!AgreementsTerms.controls.termTypeId.touched">Term
                                      Type is required</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputN">
                                                        <label for="exampleInputEmail1">Invoice Item Type</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" name="party" [options]="InvoiceItemTypeList" optionlabel="label" formControlName="invoiceItemTypeId" placeholder="Invoice Item Type">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputK">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-calendar formControlName="fromDate" placeholder="Select Date">
                                                        </p-calendar>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputN">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-calendar formControlName="thruDate" placeholder="Select Date">
                                                        </p-calendar>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputK">
                                                        <label for="exampleInputEmail1">Term Value</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" formControlName="termValue" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Term Value">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputN">
                                                        <label for="exampleInputEmail1">Term Days</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" formControlName="termDays" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Term Days">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputK">
                                                        <label for="exampleInputEmail1">Text Value</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" formControlName="textValue" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Text Value">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputN">
                                                        <label for="exampleInputEmail1">Min Quantity</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" formControlName="minQuantity" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Min Quantity">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputK">
                                                        <label for="exampleInputEmail1">Max Quantity</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" formControlName="maxQuantity" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Max Quantity">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputN">
                                                        <label for="examCreate Commission Agreement
                                          pleInputEmail1">Description</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Description">
                                                    </div>


                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -19%;">
                                            <button _ngcontent-cta-c128="" type="submit" (click)="submitTerm()" class="btn btn-secondary submit-btn">{{editTerm ? 'Update':'Create'}} </button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>