import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class CreateEventServices {

  constructor(readonly http: HttpClient) { }

  createEvent(formData): Observable<any> {
   
    const postEvent = URLS.createEvent;
    return this.http.post(postEvent, formData)
  }
  completeEvent(id): Observable<any> {
    const completeEvent = URLS.completeEvent.replace(":workEffortId", id);
    return this.http.put(completeEvent, "");
  }
  getPersonAndPartyGroup(): Observable<any> {
    const getPartyId = URLS.personsAndPartyGroups;
    return this.http.get(getPartyId);
  }
  updateEvent(formData, id): Observable<any> {
    
    const updateEvent = URLS.updateEvent.replace(":workEffortId", id);
    return this.http.put(updateEvent, formData);
  }
  eventDetail(id): Observable<any> {
   
    const eventDetail = URLS.eventDetail.replace(":workEffortId", id);
    return this.http.get(eventDetail);
  }
  assignedToMe(searchWorkEffortsForm): Observable<any> {
   
    const assignedToMe = URLS.WorkEffortsAssignedToMe;
    return this.http.post(assignedToMe, searchWorkEffortsForm);
  }
  assignedByMe(req): Observable<any> {
   
    const asignedByMe = URLS.WorkEffortsAssignedByMe;
    return this.http.post(asignedByMe, req);
  }
  getEvent(): Observable<any> {
    const getEvent = URLS.getEventList;
    return this.http.get(getEvent);
  }

  getEventById(id): Observable<any> {
    const getEvent = URLS.eventDetail.replace(":workEffortId", id);
    return this.http.get(getEvent);
  }

  getEventPurpose(): Observable<any> {
    const getPurpose = URLS.getEventPurpose;
    return this.http.get(getPurpose);
  }

  getCurrency(): Observable<any> {
    const getCurrency = URLS.getCurrency;
    return this.http.get(getCurrency);
  }


  deleteEvent(id): Observable<any> {
    const deleteEvent = URLS.deleteEvent.replace(":workEffortId", id);
  
    return this.http.delete(deleteEvent);
  }

  getEvents(size): Observable<any> {
   
    const getQuetoList = URLS.getEventList + "?pageSize=" + size.pagesize + "&pageNo=" + size.pageNo;
    return this.http.get(getQuetoList)
  }

  updateEvents(workEffortId: string, formData: any): Observable<any> {
    return this.http.put(`/phyCorp/control/api/v1/crm/event/${workEffortId}`, formData);
  }

  getPartyIcsAvsOver(id): Observable<any> {
    const getPartyIcsAvsOver = URLS.getPartyIcsAvsOver + "?partyId=" + id;
    return this.http.get(getPartyIcsAvsOver);
  }
  getProfileAccountsList(searchAccountForm, all): Observable<any> {
    const getProfileAccountsList = URLS.getProfileAccountsList + "?all=" + all;
    return this.http.post(getProfileAccountsList, searchAccountForm);
  }
  RelatedAccount(formData): Observable<any> {
    const postData = URLS.RelatedAccount;
    return this.http.post(postData, formData)
  }
}
