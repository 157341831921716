import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faEdit, faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import Swal from 'sweetalert2'
import { AccountingApService } from '../../accounting-ap.service';
import { Location } from '@angular/common';
@Component({
    selector: 'app-summary-credit-memo',
    templateUrl: './summary-credit-memo.component.html',
    styleUrls: ['./summary-credit-memo.component.css']
})
export class SummaryCreditMemoComponent implements OnInit {
    total=0;
    rowNumber=0;
    pageSize = 10;
    rows = 50;
    invoiceId: string;
    activeCategory = 1;
    invoiceValue: any;
    invoiceRoles: any[];
    downloading: boolean;
    faSpinner = faSpinner;
    faTrash = faTrash;
    faEdit = faEdit;
    invoiceTerms: any[];
    invoiceItems: any[];
    invoiceStatus: any[];
    invoiceType: string;
    transactions: any[];
    appliedPayments: any[];
    statusId: string;
    invoiceStatusValue: string;
    isEditable: boolean;
    appliedAmount: {
        appliedAmount: number,
        invoiceAmount: number,
        notAppliedAmount: number
    };
    constructor(
        readonly  _Router: Router,
        readonly _FormBuilder: FormBuilder,
        readonly spinner: NgxSpinnerService,
        private _location: Location,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _AccountingApService: AccountingApService,
        readonly _ToastrService: ToastrService
    ) {
        this.invoiceId = '';
        this.invoiceRoles = [];
        this.invoiceTerms = [];
        this.invoiceItems = [];
        this.invoiceStatus = [];
        this.invoiceType = 'payable';
        this.statusId = '';
        this.invoiceStatusValue = '';
        this.isEditable = true;
        this.appliedPayments = [];
        this.transactions = [];
        this.downloading = false;
        this.appliedAmount = {
            appliedAmount: 0,
            invoiceAmount: 0,
            notAppliedAmount: 0
        };
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.invoiceId = this._ActivatedRoute.snapshot.queryParams.invoiceId;
        this.invoiceType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this.getInvoiceById();
        this.getInvoiceRoles();
        this.getInvoiceTerms();
        this.getInvoiceItems();
        this.getInvoiceStatus();
    }

    backWindow(){
      this._location.back();
    }
      toDashboard(){
    this._Router.navigate(['/financial/main']);
    }
    cancelSubmit(){
        this._location.back();
      }
    getInvoiceTerms(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceTerms(this.invoiceId, { pageNo: 1, pageSize: 200 })
            .then(data => {
                this.invoiceTerms = data.data;
            });
            this.spinner.hide();
    }
    getInvoiceStatus(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceTypeAndStatus()
            .then(data => {
                this.invoiceStatus = data.data.invoiceStatus.map(value => {
                    return {
                        label: value.description,
                        value: value.statusId
                    };
                });
            });
            this.spinner.hide();
    }
    getInvoiceById(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceById(this.invoiceId)
            .then(data => {
                this.invoiceValue = data.data;
                this.transactions = data.data.transactions;
                this.spinner.hide();
                this.appliedPayments = data.data.appliedPayments.invoiceApplications ? data.data.appliedPayments.invoiceApplications : [];
                this.appliedAmount = {
                    appliedAmount: data.data.appliedPayments.appliedAmount,
                    invoiceAmount: data.data.appliedPayments.invoiceAmount,
                    notAppliedAmount: data.data.appliedPayments.notAppliedAmount
                };
                data.data.status.filter(value => {
                    if (value.statusId === data.data.statusId) {
                        this.invoiceStatusValue = value.status;
                    }
                });
                this.isEditable = data.data.statusId === 'INVOICE_IN_PROCESS' ? true : false;
            });
           
    }
    getInvoiceRoles(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceRole(this.invoiceId)
            .then(data => {
                this.invoiceRoles = data.data;
            });
            this.spinner.hide();
    }
    deleteInvoiceRole(partyId: string, roleTypeId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._AccountingApService.deleteInvoiceRole(this.invoiceId, partyId, roleTypeId)
                .then(data => {
                    if (data.success) {
                      this.getInvoiceRoles(); 
                 } });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          })   
    }
    deleteInvoiceTerm(invoiceTermId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._AccountingApService.deleteInvoiceTerm(invoiceTermId)
                .then(data => {
                    if (data.success) {
                        this.getInvoiceTerms();
                    }
                });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              ) 
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )}
          })       
    }
    getInvoiceItems(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceItems(this.invoiceId)
            .then(data => {
                this.invoiceItems = data.data;
            });
            this.spinner.hide();
    }
    deleteInvoiceItem(invoiceItemSeqId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._AccountingApService.deleteInvoiceItem(this.invoiceId, invoiceItemSeqId)
                .then(data => {
                    if (data.success) {  
                        this.getInvoiceItems();
                    }
                });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )}
          })
    }

    downloadPDF(): void {
        this.spinner.show();
        this.downloading = true;
        this._AccountingApService.generateInvoicePDF(this.invoiceId)
            .subscribe(value => {
                const blob = new Blob([value], { type: 'application/application/pdf' });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }
                const data = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = data;
                this.spinner.hide();
                link.download = `${this.invoiceId}.pdf`;
                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                setTimeout(() => {
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
                this.downloading = false;
            });
          
    }


    setStatus(event: any): void {
        this.spinner.show();
        this._AccountingApService.setInvoiceStatus(this.invoiceId, this.statusId)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Status Updated');
                    this.getInvoiceById();
                }
            });
            this.spinner.hide();
    }
    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    changeactiveCategoryFunction(tab: number) {
        this.activeCategory = tab;
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}