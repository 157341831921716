import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from 'src/app/header/header.component';
import { HeaderService } from 'src/app/header/header.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { DatePipe } from '@angular/common';
import { PaymentGroupService } from '../accounting-ap/payable-payment-group/payment-group.service';
import Swal from 'sweetalert2'
import { constrainPoint } from '@fullcalendar/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-financial-summary',
  templateUrl: './detail-financial-summary.component.html',
  styleUrls: ['./detail-financial-summary.component.css']
})
export class DetailFinancialSummaryComponent implements OnInit {
  products3: any;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  paymentPartyGroup: any;
  downloading: boolean;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search = ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  activeCategory = 2;
  financialList: any;
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  finAccountId: any;
  financialRoleList: any;
  FinancialAccountReconciliations: any;
  FinancialAccountReconciliationsArray: any[] = [];
  personId: any;
  partyIdArray: any[] = [];
  giAccountArray: any[] = [];
  FinancialAccountIntialDataArray: any[] = [];
  advanceSearch: {

    finAccountId: string,
    glAccountId: string,
    glReconciliationId: string,
    glReconciliationName: string,
    organizationPartyId: string,
    statusId: string


  };
  advanceSearchTransaction: {

    finAccountId: string,
    finAccountTransTypeId: string,
    fromEntryDate: string,
    fromTransactionDate: string,
    glReconciliationId: string,
    statusId: string,
    thruEntryDate: string,
    thruTransactionDate: string

  };
  advanceSearchAccountDeposit: {

    "cardType": string,
    "finAccountId": string,
    "fromDate": string,
    "organizationPartyId": string,
    "partyIdFrom": string,
    "paymentMethodTypeId": string,
    "thruDate": string

  };
  advanceSearchDepostSlip: {

    "paymentGroupId": string,
    "paymentGroupIdSearchType": string,
    "paymentGroupName": string,
    "paymentGroupNameSearchType": string

  };
  FinancialTransaction: any;
  stausIdArray: any[] = [];
  finTypeArray: any[] = [];
  giTransArray: any[] = [];
  getDepositOrWithdraw: any;
  organizationPartyId: any;
  cardTypeArray: any[] = [];
  paymentMethodTypeArray: any[] = [];
  getdataWithdraw: any;
  payIdArray: any[] = [];
  group: string;
  groupNameValue: string;
  payIdArrayWith: any[] = [];
  groupNameValueWith: string;
  paymentPartyGroupWith: any;
  grandTotal: any;
  searchedNumberOfRecords: any;
  createdGrandTotal: any;
  totalCreatedTransactions: any;
  approvedGrandTotal: any;
  totalApprovedTransactions: any;
  createdApprovedGrandTotal: any;
  totalCreatedApprovedTransactions: any;

  show: boolean;
  createFinancial: FormGroup;
  FinAccountTypeList: any;
  FinAccountTypeListArray: any[] = [];
  FinAccountStatusList: any;
  FinAccountStatusListArray: any[] = [];
  currencyArray: any[] = [];
  refundable: { label: string; value: string; }[];

  actualfromdate: string;
  actualthroughdate: string;

  getEstimateFrom: string;
  getEstimateTo: string;

  id: any;

  createRoles: FormGroup;
  allrole: any;
  allroleArray: any[] = [];
  rid: any;
  pid: any;
  fromdate: any;
  throughdate: any;


  orderIdArray: any[] = [];
  createTransaction: FormGroup;

  createRecon: FormGroup;
  reconciliationForm:FormGroup;
  depositPaymentTypeArray: any[] = [];
  paymentMethodIdArray: any[] = [];
  isDepositWithDrawPayment: string;

  createWithdraw: FormGroup;
  paymentGroupTypes: any[];

  paymentType: string;
  paymentGroupForm: FormGroup;
  paymentGroupId: string;
  editMode: boolean;
  paymentGroup: any;

  lastModifiedDate: string;
  reconciledDate: string;
  gid: any;
  rDate: string;
  reconciledBalance: any;
  findReconciliationsNewData: any;
  depositSlipsData: any;
  finAccountTransData: any;
  getDepositData: any;
  showAmount: boolean;
  product_Amount: any;
  product_Amount_with: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    readonly _PaymentGroupService: PaymentGroupService,
    readonly addSkillService: AddSkillService,
    readonly toastr: ToastrService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
    private _location: Location,

  ) {

    this.reconciliationForm = this._FormBuilder.group({

      createdDate: [''],
      description: [''],
      glReconciliationName: [''],
      lastModifiedDate: [''],
      openingBalance: [''],
      organizationPartyId: ['', [Validators.required]],
      reconciledDate: [''],
      statusId: ['']



    });


    this.paymentType = '';
    this.paymentGroupId = '';
    this.paymentGroupTypes = [];
    this.paymentGroupForm = this._FormBuilder.group({
      paymentGroupName: ['', [Validators.required]],
      paymentGroupTypeId: ['CHECK_RUN']
    });
    this.editMode = false;

    this.createWithdraw = this._FormBuilder.group({


      actualCurrencyAmount: [''],
      actualCurrencyUomId: [''],
      amount: ['',[Validators.required]],
      comments: [''],
      currencyUomId: [''],
      effectiveDate: [''],
      finAccountTransId: [''],
      finAccountTransTypeId: [''],
      finAccountTypeId: [''],
      isDepositWithDrawPayment: [''],
      overrideGlAccountId: [''],
      partyIdFrom: ['',[Validators.required]],
      partyIdTo: ['',[Validators.required]],
      paymentGroupTypeId: [''],
      paymentId: [''],
      paymentMethodId: ['',[Validators.required]],
      paymentRefNum: [''],
      paymentTypeId: ['',[Validators.required]],
      /*  "statusId": "string" */
    });

    this.createRecon = this._FormBuilder.group({
      actualCurrencyAmount: [''],
      actualCurrencyUomId: [''],
      amount: ['', [Validators.required]],
      comments: [''],
      currencyUomId: [''],
      effectiveDate: [''],
      finAccountTransId: [''],
      finAccountTransTypeId: [''],
      finAccountTypeId: [''],
      isDepositWithDrawPayment: [''],
      overrideGlAccountId: [''],
      partyIdFrom: ['', [Validators.required]],
      partyIdTo: ['', [Validators.required]],
      paymentGroupTypeId: [''],
      paymentId: [''],
      paymentMethodId: ['', [Validators.required]],
      paymentRefNum: [''],
      paymentTypeId: ['', [Validators.required]],

    });

    this.createTransaction = this._FormBuilder.group({
      amount: [''],
      comments: [''],
      entryDate: [''],

      finAccountTransTypeId: ['', [Validators.required]],
      glAccountId: ['', [Validators.required]],
      glReconciliationId: [''],
      orderId: [''],
      orderItemSeqId: [''],
      partyId: [''],
      paymentId: [''],
      reasonEnumId: [''],
      statusId: ['', [Validators.required]],
      transactionDate: [''],


    });

    this.createRoles = this._FormBuilder.group({

      fromDate: ['', [Validators.required]],
      partyId: ['', [Validators.required]],
      roleTypeId: ['', [Validators.required]],
      thruDate: [''],



    });

    this.refundable = [{
      label: "Y",
      value: "Y"
    },
    {
      label: "N",
      value: "N"
    }]
    this.createFinancial = this._FormBuilder.group({

      actualBalance: [''],
      availableBalance: [''],
      currencyUomId: ['', [Validators.required]],
      finAccountCode: [''],
      finAccountName: [''],
      finAccountPin: [''],
      finAccountTypeId: ['', [Validators.required]],
      fromDate: ['', [Validators.required]],
      isRefundable: ['', [Validators.required]],
      organizationPartyId: ['', [Validators.required]],
      ownerPartyId: [''],
      postToGlAccountId: [''],
      replenishLevel: [''],
      replenishPaymentId: [''],
      statusId: ['', [Validators.required]],
      thruDate: ['']


    });

    this.advanceSearch = {
      finAccountId: "", glAccountId: "", glReconciliationId: "", glReconciliationName: "", organizationPartyId: "", statusId: ""
    };
    this.advanceSearchTransaction = {
      finAccountId: "", finAccountTransTypeId: "", fromEntryDate: "", fromTransactionDate: "", glReconciliationId: "", statusId: "", thruEntryDate: "", thruTransactionDate: ""
    };
    this.advanceSearchAccountDeposit = {
      "cardType": "", "finAccountId": "", "fromDate": "", "organizationPartyId": "", "partyIdFrom": "", "paymentMethodTypeId": "", "thruDate": ""
    };
    this.advanceSearchDepostSlip = {
      "paymentGroupId": "", "paymentGroupIdSearchType": "Contains", "paymentGroupName": "", "paymentGroupNameSearchType": "Contains"
    };
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
  }

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.finAccountId = params["finAccountId"];
    });
    this.showAmount=false;
    this.show = true;
    this.getFinancailAccount();
    this.getFinancialRoleAccount();
    this.getFinancialAccountReconciliations();
    this.getPartyId();
    this.getGiAccountId();
    this.getFinAccountTypeList();
    this.getFinAccountStatusList();
    this.getCurrency();

    this.getFinancialAccountIntialData();
    this.searchFinancialTransaction();
    this.getfinancialTransactionStatus();
    this.getfinAccountTransTypeId();
    this.getTransglReconciliationID();
    this.depositOrWithdrawInitailData();
    this.searchDepositSlip();
    this.depositOrWithdrawInitailDataPayment();
    this.findReconciliations();
    setTimeout(() => {

      this.searchDepositOrWithdraw();
      this.searchOrWithdraw();


    }, 2000);

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.finAccountId = params["finAccountId"];
      this.rid = params["rid"];
      this.pid = params["pid"];
      this.fromdate = params["fromdate"];
      this.throughdate = params["throughdate"];
    });
    if (this.rid) {
      this.show = true;
      setTimeout(() => {
        const fromdate = this.fromdate
        this.getEstimateFrom = this.datePipe.transform(fromdate, "yyyy-MM-dd");
        const toEstdate = this.throughdate
        this.getEstimateTo = this.datePipe.transform(toEstdate, "yyyy-MM-dd");

        const formValue = this.createRoles;
        formValue.patchValue({
          roleTypeId: this.rid,
          fromDate: this.getEstimateFrom,
          partyId: this.pid,
          thruDate: this.getEstimateTo
        })
      }, 3000);
    } else {
      this.show = false;
    }
    this.getPartyIdRole();
    this.getRoleAllType();

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.finAccountId = params["finAccountId"];

    });
    this.getfinAccountTransTypeId();
    this.getfinancialTransactionStatus();
    this.getPartyId();
    this.getGiAccountId();
    this.financialOrderId();


    this._ActivatedRoute.queryParams.subscribe(params => {
      this.finAccountId = params["finAccountId"];

    });
    this.getPartyId();
    this.depositOrWithdrawInitailDataDeposit();
    this.getGiAccountId();
    this.getCurrency();

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.finAccountId = params["finAccountId"];

    });
    this.getPartyId();
    this.depositOrWithdrawInitailData();
    this.getGiAccountId();
    this.getCurrency();
  
    this.paymentGroupId = this._ActivatedRoute.snapshot.queryParams.paymentGroupId;
    if (this.paymentGroupId) {
      this.editMode = true;
      this._PaymentGroupService.getPayablePaymentById(this.paymentGroupId)
        .then(data => {
          if (data) {
            this.paymentGroup = {
              paymentGroupName: data.data.paymentGroupName,
              paymentGroupTypeId: data.data.paymentGroupTypeId
            };
            this.paymentGroupForm.patchValue({
              paymentGroupName: data.data.paymentGroupName,
              paymentGroupTypeId: data.data.paymentGroupTypeId
            });
          }
        });
      this.paymentGroupForm.controls.paymentGroupTypeId.disable();
      this.paymentGroupForm.controls.paymentGroupTypeId.updateValueAndValidity();

    }
    this.paymentType = window.location.pathname.includes('receiveable') ? '/financial/receiveable/payment-group' : '/financial/payable/payment-group';
    this.getPaymentTypes();

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.finAccountId = params["finAccountId"];
      this.gid = params["gid"];

    });
    if (this.gid) {
      this.show = true;
      this.getFinancialAccountReconciliation();

    }
    else {
      this.show = false;
    }
    this.getPartyId();
    this.getFinancialAccountIntialData();
    this.findPaymentGroupById();
    this.FindFinAccountTrans();
    this.findDepositOrWithdrawNew();

  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/financial/main']);
  }
  

  //////////////////////////Functions//////////////////////////////////////////////////////

  findDepositOrWithdrawNew(): void {
    this.spinner.show();
    const requestData = {
      "cardType": this.advanceSearchAccountDeposit.cardType ? this.advanceSearchAccountDeposit.cardType : '',
      "fromDate": this.datePipe.transform(this.advanceSearchAccountDeposit.fromDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchAccountDeposit.fromDate, "yyyy-MM-dd hh:mm:ss") : '',
      "partyIdFrom": this.advanceSearchAccountDeposit.partyIdFrom ? this.advanceSearchAccountDeposit.partyIdFrom : '',
      "paymentMethodTypeId": this.advanceSearchAccountDeposit.paymentMethodTypeId ? this.advanceSearchAccountDeposit.paymentMethodTypeId : '',
      "thruDate": this.datePipe.transform(this.advanceSearchAccountDeposit.thruDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchAccountDeposit.thruDate, "yyyy-MM-dd hh:mm:ss") : '',
    }

    this.accountsService.findDepositOrWithdrawNew(requestData).subscribe((res: any) => {
      if (res.success) {
        this.getDepositData=res.data;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  FindFinAccountTrans(): void {
    this.spinner.show();
    const requestData = {
      "entryDate": this.datePipe.transform(this.advanceSearchTransaction.thruEntryDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchTransaction.thruEntryDate, "yyyy-MM-dd hh:mm:ss") : '',
      "finAccountId": this.finAccountId,
      "finAccountTransTypeId": this.advanceSearchTransaction.finAccountTransTypeId ? this.advanceSearchTransaction.finAccountTransTypeId : '',
      "glReconciliationId": this.advanceSearchTransaction.glReconciliationId ? this.advanceSearchTransaction.glReconciliationId : '',
      "statusId": this.advanceSearchTransaction.statusId ? this.advanceSearchTransaction.statusId : '',
      "transactionDate": this.datePipe.transform(this.advanceSearchTransaction.thruTransactionDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchTransaction.thruTransactionDate, "yyyy-MM-dd hh:mm:ss") : '',

    }

    this.accountsService.FindFinAccountTrans(requestData).subscribe((res: any) => {
      if (res.success) {
        this.finAccountTransData=res.data;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  findPaymentGroupById(): void {
    this.spinner.show();
    const requestData = {
      "paymentGroupId": this.advanceSearchDepostSlip.paymentGroupId ? this.advanceSearchDepostSlip.paymentGroupId : '',
      "paymentGroupName": this.advanceSearchDepostSlip.paymentGroupName ? this.advanceSearchDepostSlip.paymentGroupName : '',
    }

    this.accountsService.findPaymentGroupById(requestData).subscribe((res: any) => {
      if (res.success) {
        this.depositSlipsData=res.data[0].getPaymentGroupById;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getFinancialAccountReconciliation() {
    this.spinner.show();
    const req = {
      "finAccountId": this.finAccountId,
      "glAccountId": "",
      "glReconciliationId": this.gid, "glReconciliationName": "",
      "organizationPartyId": "", "statusId": ""
    }
    this.accountsService.getFinancialAccountReconciliations(this.finSize, req).subscribe((res: any) => {
      this.spinner.hide();
      setTimeout(() => {
        const fromdate = res.data[0].createdDate
        this.getEstimateFrom = this.datePipe.transform(fromdate, "yyyy-MM-dd");
        const toEstdate = res.data[0].lastModifiedDate
        this.getEstimateTo = this.datePipe.transform(toEstdate, "yyyy-MM-dd");
        const reconciledDate = res.data[0].reconciledDate
        this.rDate = this.datePipe.transform(reconciledDate, "yyyy-MM-dd");
        this.reconciledBalance = res.data[0].reconciledBalance
        const formValue = this.reconciliationForm;
        formValue.patchValue({
          createdDate: this.getEstimateFrom,
          description: res.data[0].description,
          glReconciliationName: res.data[0].glReconciliationName,
          lastModifiedDate: this.getEstimateTo,
          openingBalance: res.data[0].openingBalance,
          organizationPartyId: res.data[0].organizationPartyId,
          reconciledDate: this.rDate,
          statusId: res.data[0].statusId
        })
      }, 3000);
    })
  }
  getFinancialAccountIntialData() {
    this.spinner.show();
    this.accountsService.getFinancialAccountIntialData().subscribe(res => {
      let FinancialAccountIntialData = res.data.reconciliationStatus;
      this.spinner.hide();
      for (const value of FinancialAccountIntialData) {
        this.FinancialAccountIntialDataArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })

  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }


  onSubmitReconciliation(): void {
    this.spinner.show();
    const fromActualdate = this.reconciliationForm.get('createdDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.reconciliationForm.get('lastModifiedDate').value;
    this.lastModifiedDate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const reconciledDateTo = this.reconciliationForm.get('reconciledDate').value;
    this.reconciledDate = this.datePipe.transform(reconciledDateTo, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "createdDate": this.actualfromdate,
      "description": this.reconciliationForm.value.description,
      "glReconciliationId": "",
      "glReconciliationName": this.reconciliationForm.value.glReconciliationName,
      "lastModifiedDate": this.lastModifiedDate,
      "openingBalance": this.reconciliationForm.value.openingBalance,
      "organizationPartyId": this.reconciliationForm.value.organizationPartyId,
      "reconciledBalance": "",
      "reconciledDate": this.reconciledDate,
      "statusId": ""
    }
    this.accountsService.createReconciliation(requestData, this.finAccountId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.advanceSearchContacts(false);
        this.findReconciliations();
        this.closebutton7.nativeElement.click();
        this.reconciliationForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  onUpdateReconciliation(): void {
    this.spinner.show();
    const fromActualdate = this.reconciliationForm.get('createdDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.reconciliationForm.get('lastModifiedDate').value;
    this.lastModifiedDate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const reconciledDateTo = this.reconciliationForm.get('reconciledDate').value;
    this.reconciledDate = this.datePipe.transform(reconciledDateTo, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "createdDate": this.actualfromdate,
      "description": this.reconciliationForm.value.description,
      "glReconciliationId": this.gid,
      "glReconciliationName": this.reconciliationForm.value.glReconciliationName,
      "lastModifiedDate": this.lastModifiedDate,
      "openingBalance": this.reconciliationForm.value.openingBalance,
      "organizationPartyId": this.reconciliationForm.value.organizationPartyId,
      "reconciledBalance": this.reconciledBalance,
      "reconciledDate": this.reconciledDate,
      "statusId": this.reconciliationForm.value.statusId,
    }
    this.accountsService.updateReconciliation(requestData, this.finAccountId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.findReconciliations();
        this.closebutton7.nativeElement.click();
              this.reconciliationForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }


  getPaymentTypes(): void {
    this.spinner.show();
    this._PaymentGroupService.getPaymentGroupTypes()
      .then(data => {
        this.spinner.hide();
        this.paymentGroupTypes = data.data.map(value => {
          return {
            label: value.description,
            value: value.paymentGroupTypeId
          };
        });
      });
     
  }
  update(): void {
    this.spinner.show();
    if (this.paymentGroupForm.valid) {
      this._PaymentGroupService.updatePaymentGroup(this.paymentGroupId, {
        ...this.paymentGroup, ...this.paymentGroupForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.paymentGroupForm.reset();
            this.paymentGroupForm.controls.paymentGroupTypeId.enable();
            this.paymentGroupForm.controls.paymentGroupTypeId.updateValueAndValidity();
          }
        });
    }
    this.spinner.hide();
  }
  submitSlip(): void {
    this.spinner.show();
    if (this.editMode) {
      this.update();
    } else {
      if (this.paymentGroupForm.valid) {
        this._PaymentGroupService.createPaymentGroup(this.paymentGroupForm.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.paymentGroupForm.reset();
              this.advanceSearchSlip(false);
              this.findPaymentGroupById();
              this.spinner.hide();
              this.closebutton6.nativeElement.click();
              this.paymentGroupForm.reset();
            }
          });
      }
    }
    this.spinner.hide();
  }
  
  
  getGiAccountId() {
    this.spinner.show();
    this.accountsService.getGioAccountId().subscribe(res => {
      const giAccount = res.data.glAccounts;
      this.spinner.hide();
      for (const value of giAccount) {
        this.giAccountArray.push({
          label: value.glAccountId,
          value: value.glAccountId
        })
      }
    })
  
  }

  onUpdateWithdraw() {

  }
  selectDeposit(e){
    if(e.target.ariaChecked==="true"){
      this.isDepositWithDrawPayment="Y"
    }
    else{
      this.isDepositWithDrawPayment="N"
    }
  }
  onSubmitWithdraw(): void {
    this.spinner.show();
    const fromActualdate = this.createWithdraw.get('effectiveDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "actualCurrencyAmount": this.createWithdraw.value.actualCurrencyAmount,
      "actualCurrencyUomId":this.createWithdraw.value.actualCurrencyUomId,
      "amount":  this.createWithdraw.value.amount,
      "comments": this.createWithdraw.value.comments,
      "currencyUomId": "USD",
      "effectiveDate":  this.actualfromdate,
      "finAccountTransId":  this.createWithdraw.value.finAccountTransId,
      "finAccountTransTypeId": "WITHDRAWAL","finAccountTypeId": "BANK_ACCOUNT",
      "isDepositWithDrawPayment":this.isDepositWithDrawPayment,
      "overrideGlAccountId":  this.createWithdraw.value.overrideGlAccountId,
      "partyIdFrom":  this.createWithdraw.value.partyIdFrom,
      "partyIdTo":  this.createWithdraw.value.partyIdTo,"paymentId": "",
      "paymentMethodId":  this.createWithdraw.value.paymentMethodId,
      "paymentRefNum":  this.createWithdraw.value.paymentRefNum,
      "paymentTypeId":  this.createWithdraw.value.paymentTypeId, "statusId": "PMNT_SENT"
    }
    this.accountsService.postDepositOrWithdraw(requestData, this.finAccountId).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.advanceSearchWithdraw(false);
        this.findDepositOrWithdrawNew();
        this.closebutton5.nativeElement.click();
        this.createWithdraw.reset();

      } }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      } }); }


  depositOrWithdrawInitailDataDeposit() {
    this.spinner.show();
    this.accountsService.depositOrWithdrawInitailData().subscribe(res => {
      const depositPaymentType = res.data.depositPaymentType;
      const paymentMethodId = res.data.paymentMethodId;
      this.spinner.hide();
      for (const value of depositPaymentType) {
        this.depositPaymentTypeArray.push({
          label: value.description,
          value: value.paymentTypeId
        })
      }
      for (const value of paymentMethodId) {
        this.paymentMethodIdArray.push({
          label: value.paymentMethodId,
          value: value.paymentMethodId
        })
      }

    })

  }
 

  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })

  }

  onUpdateDeposit() {

  }
  
  onSubmitDeposit(): void {
    this.spinner.show();
    const fromActualdate = this.createRecon.get('effectiveDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "actualCurrencyAmount": this.createRecon.value.actualCurrencyAmount,
      "actualCurrencyUomId": this.createRecon.value.actualCurrencyUomId,
      "amount": this.createRecon.value.amount,
      "comments": this.createRecon.value.comments,
      "currencyUomId": "USD",
      "effectiveDate": this.actualfromdate,
      "finAccountTransId": this.createRecon.value.finAccountTransId,
      "finAccountTransTypeId": "DEPOSIT",
      "finAccountTypeId": "BANK_ACCOUNT",
      "isDepositWithDrawPayment": this.isDepositWithDrawPayment,
      "overrideGlAccountId": this.createRecon.value.overrideGlAccountId,
      "partyIdFrom": this.createRecon.value.partyIdFrom,
      "partyIdTo": this.createRecon.value.partyIdTo,
      "paymentGroupTypeId": "BATCH_PAYMENT",
      "paymentId": "",
      "paymentMethodId": this.createRecon.value.paymentMethodId,
      "paymentRefNum": this.createRecon.value.paymentRefNum,
      "paymentTypeId": this.createRecon.value.paymentTypeId,
      "statusId": "PMNT_RECEIVED"
    }
    this.accountsService.postDepositOrWithdraw(requestData, this.finAccountId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.advanceSearchDeposit(false);
        this.findDepositOrWithdrawNew();
        this.searchDepositOrWithdraw();
        this.closebutton4.nativeElement.click();
        this.createRecon.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }


  financialOrderId() {
    this.spinner.show();
    const req = {
      "billingAccountId": "", "correspondingPoId": "", "countryGeoId": "", "createdBy": "", "externalId": "",
      "filterInventoryProblems": "", "filterPOsOpenPastTheirETA": "", "filterPOsWithRejectedItems": "", "filterPartiallyReceivedPOs": "",
      "gatewayAvsResult": "", "gatewayScoreResult": "", "goodIdentificationIdValue": "", "goodIdentificationTypeId": "",
      "hasBackOrders": "", "hideFields": "Y", "includeCountry": "Y", "internalCode": "", "inventoryItemId": "", "isViewed": "",
      "lookupFlag": "Y", "maxDate": "", "minDate": "", "orderId": "", "orderName": "",
      "orderStatusId": [
        ""
      ], "orderTypeId": "", "orderWebSiteId": "", "partyId": "", "paymentStatusId": "", "productId": "", "productStoreId": "", "roleTypeId": "",
      "salesChannelEnumId": "", "serialNumber": "", "shipmentMethod": "", "showAll": "Y", "softIdentifier": "",
      "userLoginId": ""
    }
    this.accountsService.financialOrderId(this.finSize, req).subscribe((res: any) => {
      const orderId = res.data.listOrders;
      this.spinner.hide();
      for (const value of orderId) {
        this.orderIdArray.push({
          label: value.orderId,
          value: value.orderId
        })
      }
    })

  }

  getfinAccountTransTypeId() {
    this.spinner.show();
    this.accountsService.finAccountTransTypeId().subscribe(res => {
      const finType = res.data;
      this.spinner.hide();
      for (const value of finType) {
        this.finTypeArray.push({
          label: value.description,
          value: value.finAccountTransTypeId
        })
      }
    })

  }
  onUpdateTransaction() {

  }

  onSubmitTransaction() {
    this.spinner.show();
    const fromActualdate = this.createTransaction.get('entryDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.createTransaction.get('transactionDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "amount": this.createTransaction.value.amount,
      "comments": this.createTransaction.value.comments,
      "entryDate": this.actualfromdate, "finAccountId": this.finAccountId,
      "finAccountTransTypeId": this.createTransaction.value.finAccountTransTypeId,
      "glAccountId": this.createTransaction.value.glAccountId,
      "glReconciliationId": this.createTransaction.value.glReconciliationId,
      "orderId": this.createTransaction.value.orderId,
      "orderItemSeqId": this.createTransaction.value.orderItemSeqId,
      "partyId": this.createTransaction.value.partyId,
      "paymentId": this.createTransaction.value.paymentId,
      "reasonEnumId": this.createTransaction.value.reasonEnumId,
      "statusId": this.createTransaction.value.statusId,
      "transactionDate": this.actualthroughdate
    }
    this.accountsService.createFinancialAccountTransaction(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.FindFinAccountTrans();
        // this.advanceSearchTransition(false);
        this.closebutton3.nativeElement.click();
        this.createTransaction.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  getRoleAllType() {
    this.spinner.show();
    this.id = "ALL"
    this.accountsService.getRoleMainType(this.id).subscribe((res: any) => {
      this.allrole = res.data;
      this.spinner.hide();
      for (const value of this.allrole) {
        this.allroleArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }


    })

  }
  getPartyIdRole() {
    this.spinner.show();
    this.addSkillService.getPartyIdRole().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  onSubmit(): void {
    this.spinner.show();
    const fromActualdate = this.createRoles.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.createRoles.get('thruDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "finAccountId": this.finAccountId,
      "partyId": this.createRoles.value.partyId,
      "roleTypeId": this.createRoles.value.roleTypeId,
      "fromDate": this.actualfromdate,
      "thruDate": this.actualthroughdate
    }

    this.accountsService.postFinancialRoleAccount(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getFinancialRoleAccount();
        this.closebutton2.nativeElement.click();
        this.createRoles.reset();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onUpdateFinancial(): void {
    this.spinner.show();
    const fromActualdate = this.createRoles.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.createRoles.get('thruDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "finAccountId": this.finAccountId,
      "partyId": this.createRoles.value.partyId,
      "roleTypeId": this.createRoles.value.roleTypeId,
      "fromDate": this.actualfromdate,
      "thruDate": this.actualthroughdate
    }

    this.accountsService.updateFinancialRoleAccount(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.searchDepositSlip();
        this.createRoles.reset();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getFinancailAccountEdit() {
    this.spinner.show();
    const req = {
      "finAccountId": this.finAccountId,
      "finAccountIdSearchType": "Contains",
      "finAccountName": "",
      "finAccountNameSearchType": "Contains",
      "finAccountTypeId": "",
    }
    this.accountsService.getFinancailAccount(this.finSize, req).subscribe((res: any) => {
      setTimeout(() => {
        const fromdate = res.data[0].fromDate
        this.getEstimateFrom = this.datePipe.transform(fromdate, "yyyy-MM-dd");
        const toEstdate = res.data[0].thruDate
        this.getEstimateTo = this.datePipe.transform(toEstdate, "yyyy-MM-dd");
        const formValue = this.createFinancial;
        formValue.patchValue({
          actualBalance: res.data[0].actualBalance, availableBalance: res.data[0].availableBalance, currencyUomId: res.data[0].currencyUomId,
          finAccountCode: res.data[0].finAccountCode, finAccountName: res.data[0].finAccountName, finAccountPin: res.data[0].finAccountPin,
          finAccountTypeId: res.data[0].finAccountTypeId, fromDate: this.getEstimateFrom, isRefundable: res.data[0].isRefundable,
          organizationPartyId: res.data[0].organizationPartyId, ownerPartyId: res.data[0].ownerPartyId,
          postToGlAccountId: res.data[0].postToGlAccountId, replenishLevel: res.data[0].replenishLevel,
          replenishPaymentId: res.data[0].replenishPaymentId, statusId: res.data[0].statusId, thruDate: this.getEstimateTo
        })

      }, 3000);
      this.spinner.hide();
    })
  }





  getFinAccountStatusList() {
    this.spinner.show();
    this.accountsService.getFinAccountStatusList().subscribe(res => {
      this.FinAccountStatusList = res.data;
      for (const value of this.FinAccountStatusList) {
        this.FinAccountStatusListArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
    this.spinner.hide();
  }
  getFinAccountTypeList() {
    this.spinner.show();
    this.accountsService.getFinAccountTypeList().subscribe(res => {
      this.FinAccountTypeList = res.data;
      this.spinner.hide();
      for (const value of this.FinAccountTypeList) {
        this.FinAccountTypeListArray.push({
          label: value.description,
          value: value.finAccountTypeId
        })
      }
    })
  }
  onUpdate(): void {
    this.spinner.show();
    const fromActualdate = this.createFinancial.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.createFinancial.get('thruDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "actualBalance": 0, "availableBalance": 0, "currencyUomId": this.createFinancial.value.currencyUomId,
      "finAccountCode": this.createFinancial.value.finAccountCode, "finAccountName": this.createFinancial.value.finAccountName, "finAccountPin": this.createFinancial.value.finAccountPin,
      "finAccountTypeId": this.createFinancial.value.finAccountTypeId,
      "fromDate": this.actualfromdate, "isRefundable": this.createFinancial.value.isRefundable, "organizationPartyId": this.createFinancial.value.organizationPartyId,
      "ownerPartyId": this.createFinancial.value.ownerPartyId, "postToGlAccountId": this.createFinancial.value.postToGlAccountId,
      "replenishLevel": this.createFinancial.value.replenishLevel, "replenishPaymentId": this.createFinancial.value.replenishPaymentId,
      "statusId": this.createFinancial.value.statusId, "thruDate": this.actualthroughdate
    }
    this.accountsService.updateFinancailAccount(requestData, this.finAccountId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getFinancailAccount();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }


  cancelSubmit() {
    this.router.navigate(["/financial/financial-account"])
  }


  getTransglReconciliationID() {
    this.spinner.show();
    this.accountsService.getTransglReconciliationID(this.finAccountId).subscribe(res => {
      const giTrans = res.data;
      for (const value of giTrans) {
        this.giTransArray.push({
          label: value.glReconciliationId,
          value: value.glReconciliationId
        })
      }
    })
    this.spinner.hide();
  }

  getfinancialTransactionStatus() {
    this.spinner.show();
    this.accountsService.financialTransactionStatus().subscribe(res => {
      const stausId = res.data;
      for (const value of stausId) {
        this.stausIdArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
    this.spinner.hide();
  }

  depositOrWithdrawInitailData() {
    this.spinner.show();
    this.accountsService.depositOrWithdrawInitailData().subscribe(res => {
      const cardType = res.data.cardType;

      for (const value of cardType) {
        this.cardTypeArray.push({
          label: value.enumCode,
          value: value.enumCode
        })
      }

    })
    this.spinner.hide();
  }
  depositOrWithdrawInitailDataPayment() {
    this.spinner.show();
    this.accountsService.depositOrWithdrawInitailData().subscribe(res => {

      const paymentMethodType = res.data.paymentMethodType;

      for (const value of paymentMethodType) {
        this.paymentMethodTypeArray.push({
          label: value.description,
          value: value.paymentMethodTypeId
        })
      }
    })
    this.spinner.hide();
  }

 

  getFinancialRoleAccount() {
    this.spinner.show();
    this.accountsService.getFinancialRoleAccount(this.finSize, this.finAccountId).subscribe((res: any) => {
      this.financialRoleList = res.data;
      this.spinner.hide();

    })


  }

  searchFinancialTransaction() {
    this.spinner.show();
    const req = {
      "finAccountId": this.finAccountId,
      "finAccountTransTypeId": "",
      "fromEntryDate": "",
      "fromTransactionDate": "",
      "glReconciliationId": "",
      "statusId": "",
      "thruEntryDate": "",
      "thruTransactionDate": ""
    }
    this.accountsService.searchFinancialTransaction(req).subscribe((res: any) => {
      this.FinancialTransaction = res.data.finAccountTransList;
      this.spinner.hide();
      this.grandTotal = res.data.grandTotal;
      this.searchedNumberOfRecords = res.data.searchedNumberOfRecords;
      this.createdGrandTotal = res.data.createdGrandTotal;
      this.totalCreatedTransactions = res.data.totalCreatedTransactions;
      this.approvedGrandTotal = res.data.approvedGrandTotal;
      this.totalApprovedTransactions = res.data.totalApprovedTransactions;
      this.createdApprovedGrandTotal = res.data.createdApprovedGrandTotal;
      this.totalCreatedApprovedTransactions = res.data.totalCreatedApprovedTransactions;


    })

  }
  advanceSearchDeposit(isPopUp: boolean): void {
    this.spinner.show();
    this.getDepositOrWithdraw = [];
    const req = {
      "finAccountId": this.finAccountId,
      "statusId": "PMNT_RECEIVED",
      "cardType": this.advanceSearchAccountDeposit.cardType ? this.advanceSearchAccountDeposit.cardType : '',
      "fromDate": this.datePipe.transform(this.advanceSearchAccountDeposit.fromDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchAccountDeposit.fromDate, "yyyy-MM-dd hh:mm:ss") : '',
      "organizationPartyId": this.organizationPartyId,
      "partyIdFrom": this.advanceSearchAccountDeposit.partyIdFrom ? this.advanceSearchAccountDeposit.partyIdFrom : '',
      "paymentMethodTypeId": this.advanceSearchAccountDeposit.paymentMethodTypeId ? this.advanceSearchAccountDeposit.paymentMethodTypeId : '',
      "thruDate": this.datePipe.transform(this.advanceSearchAccountDeposit.thruDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchAccountDeposit.thruDate, "yyyy-MM-dd hh:mm:ss") : '',


    }
    this.accountsService.searchDepositOrWithdraw(this.finSize, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.getDepositOrWithdraw = resp.data[0].paymentList;
        } else {

        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {

        }
      });

  }
  advanceSearchWithdraw(isPopUp: boolean): void {
    this.spinner.show();
    this.getdataWithdraw = [];
    const req = {
      "finAccountId": this.finAccountId,
      "statusId": "PMNT_SENT",
      "cardType": this.advanceSearchAccountDeposit.cardType ? this.advanceSearchAccountDeposit.cardType : '',
      "fromDate": this.datePipe.transform(this.advanceSearchAccountDeposit.fromDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchAccountDeposit.fromDate, "yyyy-MM-dd hh:mm:ss") : '',
      "organizationPartyId": this.organizationPartyId,
      "partyIdFrom": this.advanceSearchAccountDeposit.partyIdFrom ? this.advanceSearchAccountDeposit.partyIdFrom : '',
      "paymentMethodTypeId": this.advanceSearchAccountDeposit.paymentMethodTypeId ? this.advanceSearchAccountDeposit.paymentMethodTypeId : '',
      "thruDate": this.datePipe.transform(this.advanceSearchAccountDeposit.thruDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchAccountDeposit.thruDate, "yyyy-MM-dd hh:mm:ss") : '',


    }
    this.accountsService.searchDepositOrWithdraw(this.finSize, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.getdataWithdraw = resp.data[0].paymentList;
        } else {

        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {

        }
      });

  }
  resetWithdrawl() {
    this.spinner.show();
    this.advanceSearchAccountDeposit.cardType = "";
    this.advanceSearchAccountDeposit.fromDate = "";
    this.advanceSearchAccountDeposit.partyIdFrom = "";
    this.advanceSearchAccountDeposit.paymentMethodTypeId = "";
    this.advanceSearchAccountDeposit.thruDate = "";
    const req = {
      "finAccountId": this.finAccountId,
      "cardType": "",
      "fromDate": "",
      "organizationPartyId": this.organizationPartyId,
      "partyIdFrom": "",
      "paymentMethodTypeId": "",
      "thruDate": "",
      "statusId": "PMNT_SENT",
    }
    this.accountsService.searchDepositOrWithdraw(this.finSize, req).subscribe((res: any) => {
      this.getdataWithdraw = res.data[0].paymentList;
      this.spinner.hide();

    })

  }
  searchOrWithdraw() {
    this.spinner.show();
    const req = {
      "finAccountId": this.finAccountId,
      "cardType": "",
      "fromDate": "",
      "organizationPartyId": this.organizationPartyId,
      "partyIdFrom": "",
      "paymentMethodTypeId": "",
      "thruDate": "",
      "statusId": "PMNT_SENT",
    }
    this.accountsService.searchDepositOrWithdraw(this.finSize, req).subscribe((res: any) => {
      this.getdataWithdraw = res.data[0].paymentList;
      this.spinner.hide();

    })

  }
  resetDiposit() {
    this.spinner.show();
    this.advanceSearchAccountDeposit.cardType = "";
    this.advanceSearchAccountDeposit.fromDate = "";
    this.advanceSearchAccountDeposit.partyIdFrom = "";
    this.advanceSearchAccountDeposit.paymentMethodTypeId = "";
    this.advanceSearchAccountDeposit.thruDate = "";
    const req = {
      "finAccountId": this.finAccountId,
      "cardType": "",
      "fromDate": "",
      "organizationPartyId": this.organizationPartyId,
      "partyIdFrom": "",
      "paymentMethodTypeId": "",
      "thruDate": "",
      "statusId": "PMNT_RECEIVED",
    }
    this.accountsService.searchDepositOrWithdraw(this.finSize, req).subscribe((res: any) => {
      this.getDepositOrWithdraw = res.data[0].paymentList;
      this.spinner.hide();

    })

  }
  searchDepositOrWithdraw() {
    this.spinner.show();
    const req = {
      "finAccountId": this.finAccountId,
      "cardType": "",
      "fromDate": "",
      "organizationPartyId": this.organizationPartyId,
      "partyIdFrom": "",
      "paymentMethodTypeId": "",
      "thruDate": "",
      "statusId": "PMNT_RECEIVED",
    }
    this.accountsService.searchDepositOrWithdraw(this.finSize, req).subscribe((res: any) => {
      this.getDepositOrWithdraw = res.data[0].paymentList;
      this.spinner.hide();

    })

  }
  getFinancialAccountReconciliations() {
    this.spinner.show();
    const req = {
      "finAccountId": this.finAccountId,
      "glAccountId": "",
      "glReconciliationId": "",
      "glReconciliationName": "",
      "organizationPartyId": "",
      "statusId": ""
    }
    this.accountsService.getFinancialAccountReconciliations(this.finSize, req).subscribe((res: any) => {
      this.FinancialAccountReconciliations = res.data;
      this.spinner.hide();
      for (const value of this.FinancialAccountReconciliations) {
        this.FinancialAccountReconciliationsArray.push({
          label: value.glReconciliationId,
          value: value.glReconciliationId
        })
      }

    })

  }

  getFinancailAccount() {
    this.spinner.show();
    const req = {
      "finAccountId": this.finAccountId,
      "finAccountIdSearchType": "Contains",
      "finAccountName": "",
      "finAccountNameSearchType": "Contains",
      "finAccountTypeId": "",

    }
    this.accountsService.getFinancailAccount(this.finSize, req).subscribe((res: any) => {

      this.financialList = res.data[0];
      this.organizationPartyId = res.data[0].organizationPartyId
      this.spinner.hide();

    })

  }
  advanceSearchSlip(isPopUp: boolean): void {
    this.spinner.show();
    this.getDepositOrWithdraw = [];
    const req = {

      "paymentGroupId": this.advanceSearchDepostSlip.paymentGroupId ? this.advanceSearchDepostSlip.paymentGroupId : '',
      "paymentGroupIdSearchType": this.advanceSearchDepostSlip.paymentGroupIdSearchType ? this.advanceSearchDepostSlip.paymentGroupIdSearchType : '',
      "paymentGroupName": this.advanceSearchDepostSlip.paymentGroupName ? this.advanceSearchDepostSlip.paymentGroupName : '',
      "paymentGroupNameSearchType": this.advanceSearchDepostSlip.paymentGroupNameSearchType ? this.advanceSearchDepostSlip.paymentGroupNameSearchType : '',
    }
    this.accountsService.searchDepositSlip(req, this.finAccountId)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.searchDepositSlip = resp.data;
        } else {

        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {

        }
      });

  }
  resetSlip() {
    this.spinner.show();
    this.advanceSearchDepostSlip.paymentGroupId = "";
    this.advanceSearchDepostSlip.paymentGroupName = "";
    const req = {
      "paymentGroupId": "",
      "paymentGroupIdSearchType": "Contains",
      "paymentGroupName": "",
      "paymentGroupNameSearchType": "Contains"

    }
    this.accountsService.searchDepositSlip(req, this.finAccountId).subscribe((res: any) => {

      this.searchDepositSlip = res.data;
      this.spinner.hide();


    })

  }
  searchDepositSlip() {
    this.spinner.show();
    const req = {
      "paymentGroupId": "",
      "paymentGroupIdSearchType": "Contains",
      "paymentGroupName": "",
      "paymentGroupNameSearchType": "Contains"

    }
    this.accountsService.searchDepositSlip(req, this.finAccountId).subscribe((res: any) => {

      this.searchDepositSlip = res.data;
      this.spinner.hide();


    })

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  createRole() {
    this.router.navigate(["financial/create-financial-role"], { queryParams: { finAccountId: this.finAccountId } })

  }
  onUpdateRole(erid, epid, efromdate, ethroughdate) {
    this.router.navigate(["financial/create-financial-role"], { queryParams: { finAccountId: this.finAccountId, rid: erid, pid: epid, fromdate: efromdate, throughdate: ethroughdate } })
  }
  deleteFinancialRoleAccount(fromdate, partyId, roleId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteFinancialRoleAccount(this.finAccountId, fromdate, partyId, roleId).subscribe((res: any) => {
          this.getFinancialRoleAccount();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  findReconciliations(): void {
    this.spinner.show();
    const req = {
      "finAccountId": '',
      "glAccountId": this.advanceSearch.glAccountId ? this.advanceSearch.glAccountId : '',
      "glReconciliationId": this.advanceSearch.glReconciliationId ? this.advanceSearch.glReconciliationId : '',
      "glReconciliationName": this.advanceSearch.glReconciliationName ? this.advanceSearch.glReconciliationName : '',
      "organizationPartyId": this.advanceSearch.organizationPartyId ? this.advanceSearch.organizationPartyId : '',
      "statusId": this.advanceSearch.statusId ? this.advanceSearch.statusId : '',

    }
    this.accountsService.findReconciliations(req).subscribe(res => {
        if (res.success) {
          this.spinner.hide();
          this.findReconciliationsNewData = res.data[0].findReconciliations;
        }
      });

  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.FinancialAccountReconciliations = [];
    const req = {
      "finAccountId": this.finAccountId,
      "glAccountId": this.advanceSearch.glAccountId ? this.advanceSearch.glAccountId : '',
      "glReconciliationId": this.advanceSearch.glReconciliationId ? this.advanceSearch.glReconciliationId : '',
      "glReconciliationName": this.advanceSearch.glReconciliationName ? this.advanceSearch.glReconciliationName : '',
      "organizationPartyId": this.advanceSearch.organizationPartyId ? this.advanceSearch.organizationPartyId : '',
      "statusId": this.advanceSearch.statusId ? this.advanceSearch.statusId : '',

    }
    this.accountsService.getFinancialAccountReconciliations(this.finSize, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.FinancialAccountReconciliations = resp.data;
        }
      });

  }
  advanceSearchTransition(isPopUp: boolean): void {
    this.spinner.show();
    this.FinancialTransaction = [];
    const req = {
      "finAccountId": this.finAccountId,
      "finAccountTransTypeId": this.advanceSearchTransaction.finAccountTransTypeId ? this.advanceSearchTransaction.finAccountTransTypeId : '',
      "fromEntryDate": this.datePipe.transform(this.advanceSearchTransaction.fromEntryDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchTransaction.fromEntryDate, "yyyy-MM-dd hh:mm:ss") : '',
      "fromTransactionDate": this.datePipe.transform(this.advanceSearchTransaction.fromTransactionDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchTransaction.fromTransactionDate, "yyyy-MM-dd hh:mm:ss") : '',
      "glReconciliationId": this.advanceSearchTransaction.glReconciliationId ? this.advanceSearchTransaction.glReconciliationId : '',
      "statusId": this.advanceSearchTransaction.statusId ? this.advanceSearchTransaction.statusId : '',
      "thruEntryDate": this.datePipe.transform(this.advanceSearchTransaction.thruEntryDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchTransaction.thruEntryDate, "yyyy-MM-dd hh:mm:ss") : '',
      "thruTransactionDate": this.datePipe.transform(this.advanceSearchTransaction.thruTransactionDate, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearchTransaction.thruTransactionDate, "yyyy-MM-dd hh:mm:ss") : '',

    }
    this.accountsService.searchFinancialTransaction(req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.FinancialTransaction = resp.data.finAccountTransList;
        }
      });

  }
  resetTransaction() {
    this.spinner.show();
    this.advanceSearchTransaction.finAccountTransTypeId = "";
    this.advanceSearchTransaction.fromEntryDate = "";
    this.advanceSearchTransaction.fromTransactionDate = "";
    this.advanceSearchTransaction.glReconciliationId = "";
    this.advanceSearchTransaction.statusId = "";
    this.advanceSearchTransaction.thruEntryDate = "";
    this.advanceSearchTransaction.thruTransactionDate = "";
    const req = {
      "finAccountId": this.finAccountId,
      "finAccountTransTypeId": "",
      "fromEntryDate": "",
      "fromTransactionDate": "",
      "glReconciliationId": "",
      "statusId": "",
      "thruEntryDate": "",
      "thruTransactionDate": ""
    }
    this.accountsService.searchFinancialTransaction(req).subscribe((res: any) => {
      this.FinancialTransaction = res.data.finAccountTransList;

      this.spinner.hide();
    })

  }
  reset() {
    this.spinner.show();
    this.advanceSearch.glAccountId = "";
    this.advanceSearch.glReconciliationId = "";
    this.advanceSearch.glReconciliationName = "";
    this.advanceSearch.organizationPartyId = "";
    this.advanceSearch.statusId = ""
    const req = {
      "finAccountId": this.finAccountId,
      "glAccountId": "",
      "glReconciliationId": "",
      "glReconciliationName": "",
      "organizationPartyId": "",
      "statusId": ""
    }
    this.accountsService.getFinancialAccountReconciliations(this.finSize, req).subscribe((res: any) => {
      this.FinancialAccountReconciliations = res.data;
      this.spinner.hide();
      for (const value of this.FinancialAccountReconciliations) {
        this.FinancialAccountReconciliationsArray.push({
          label: value.glReconciliationId,
          value: value.glReconciliationId
        })
      }

    })

  }
  createTransactions() {
    this.router.navigate(["financial/create-financial-transaction"], { queryParams: { finAccountId: this.finAccountId } })

  }

  bankCon() {
    this.router.navigate(["financial/bank-reconcl"], { queryParams: { finAccountId: this.finAccountId } })

  }
  financialNewDepositPayment() {
    this.router.navigate(["financial/add-financial-new-deposit-payment"], { queryParams: { finAccountId: this.finAccountId } })

  }
  financialNewWithdrawPayment() {
    this.router.navigate(["financial/add-financial-new-withdraw-payment"], { queryParams: { finAccountId: this.finAccountId } })

  }
  financialReconciliation() {
    this.router.navigate(["financial/new-financial-reconciliation"], { queryParams: { finAccountId: this.finAccountId } })

  }
  onUpdateRecon(id) {
    this.router.navigate(["financial/new-financial-reconciliation"], { queryParams: { finAccountId: this.finAccountId, gid: id } })
  }
  summaryReconciliation(id) {
    this.router.navigate(["financial/reconciliation-detail-summary"], { queryParams: { finAccountId: this.finAccountId, gid: id } })



  }
  cancelReconciliation(id) {
    this.spinner.show();

    this.accountsService.cancelReconciliation(this.finAccountId, id).subscribe((res: any) => {
      this.toastr.success("Cancelled Successfully");
      this.spinner.hide();
      this.getFinancialAccountReconciliations();
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.message);
    })

  }

  cancelFinancialAccountTransaction(id) {
    this.spinner.show();
    const statusCancel = 'FINACT_TRNS_CANCELED'
    this.accountsService.cancelFinancialAccountTransaction(id, this.finAccountId, statusCancel).subscribe((res: any) => {
      this.toastr.success("Cancelled Successfully");
      this.spinner.hide();
      this.searchFinancialTransaction();
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.message);
    })

  }
  depositCheckboxClick(product) {
    this.showAmount=true;
    this.product_Amount=product;
    const index = this.payIdArray.findIndex(x => x == product)
    if (index === -1) {
      this.payIdArray.push(product);

    }
    else if (index === 0) {

      this.payIdArray.splice(index, 1);
    }
    else {
      this.payIdArray.splice(index, 1);

    }
  }
  groupInOne(e) {

    if (e.target.ariaChecked === "true") {
      this.groupNameValue = 'Y'
    }
    else {
      this.groupNameValue = 'N'
    }
  }
  selectAllDeposit(e) {

    if (e.target.ariaChecked === "true") {
      for (const r of this.getDepositOrWithdraw) {
        this.payIdArray.push(r.paymentId);
      }
    }
    else {
      this.payIdArray = []
    }
  }

  batchDepositWithdrawPaymentsSubmit(): void {
    this.spinner.show();
    const requestData = {
      "cardType": "",
      "finAccountId": this.finAccountId,
      "fromDate": "",
      "groupInOneTransaction": this.groupNameValue,
      "organizationPartyId": "Company",
      "partyIdFrom": "",
      "paymentGroupName": this.paymentPartyGroup,
      "paymentGroupTypeId": "BATCH_PAYMENT",
      "paymentIds": this.payIdArray,
      "paymentMethodTypeId": "",
      "thruDate": ""
    }
    this.accountsService.batchDepositWithdrawPayments(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Created Successfully");
        this.payIdArray = []

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  depositCheckboxClickWith(product) {
    this.showAmount=true;
    this.product_Amount_with=product;
    const index = this.payIdArrayWith.findIndex(x => x == product)
    if (index === -1) {
      this.payIdArrayWith.push(product);

    }
    else if (index === 0) {

      this.payIdArrayWith.splice(index, 1);
    }
    else {
      this.payIdArrayWith.splice(index, 1);

    }
  }
  groupInOneWith(e) {

    if (e.target.ariaChecked === "true") {
      this.groupNameValueWith = 'Y'
    }
    else {
      this.groupNameValueWith = 'N'
    }
  }
  selectAllDepositWith(e) {

    if (e.target.ariaChecked === "true") {
      for (const r of this.getdataWithdraw) {
        this.payIdArrayWith.push(r.paymentId);
      }
    }
    else {
      this.payIdArrayWith = []
    }
  }

  batchDepositWithdrawPaymentsSubmitWith(): void {
    this.spinner.show();
    const requestData = {
      "cardType": "",
      "finAccountId": this.finAccountId,
      "fromDate": "",
      "groupInOneTransaction": this.groupNameValueWith,
      "organizationPartyId": "Company",
      "partyIdFrom": "",
      "paymentGroupName": this.paymentPartyGroupWith,
      "paymentGroupTypeId": "BATCH_PAYMENT",
      "paymentIds": this.payIdArrayWith,
      "paymentMethodTypeId": "",
      "thruDate": ""
    }
    this.accountsService.batchDepositWithdrawPayments(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Created Successfully");
        this.payIdArrayWith = []
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  redirectPage(id) {
    this.router.navigate(["financial/receiveable/payment-group/summary-payment-group"], { queryParams: { paymentGroupId: id } })
  }
  financialDepositSlip() {
    this.router.navigate(["financial/receiveable/payment-group/create-payment-group"])
  }
  downloadPDF(id): void {
    this.spinner.show();
    this.downloading = true;
    this._PaymentGroupService.depositSlip(id)
      .subscribe(value => {
        if (value) {
          const blob = new Blob([value], { type: 'application/application/pdf' });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob);
            return;
          }
          const data = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = data; this.spinner.hide();
          link.download = `${id}.pdf`;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(() => {
            window.URL.revokeObjectURL(data);
            link.remove();
          }, 100);
          this.downloading = false;
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          this.downloading = false;
          err.error.errors.formEach(value => {
            this.toastr.error(`${value.fieldName} ${value.fieldError}`);
          });
        }
      });
  }
  cancelBatchPayment(paymentGroupId: string): void {
    this.spinner.show();
    this._PaymentGroupService.cancelBatchPayment(paymentGroupId)
      .then(data => {
        if (data.success) {
          this.toastr.success('Canceled');
          // this.searchDepositSlip();
          this.findPaymentGroupById();
          this.spinner.hide();
        }
      });

  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
