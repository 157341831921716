import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ProductionService } from '../production.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
    import { Location } from '@angular/common';
    import Swal from 'sweetalert2';
@Component({
  selector: 'app-bill-of-material-list',
  templateUrl: './bill-of-material-list.component.html',
  styleUrls: ['./bill-of-material-list.component.css']
})
export class BillOfMaterialListComponent implements OnInit {
  createBom:FormGroup;
  @ViewChild('closebutton') closebutton;
  activeCategory=2;
  total=0;
  rowNumber=0;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  financialList = [{
    "product": "1"
  }];
  bomTypes: any;
  formulas: any;
  bomTypesArray: any[]=[];
  formulasArray: any[]=[];
  productListArray: any[]=[];
  taskList: any;
  taskListArray: any[]=[];
  fromDate: string;
  ThroughDate: string;
  productId: any;
  productAssocTypeId: any;
  show: boolean;
  assocFromProducts: any;
  fromDateEdit: any;
  getEstimateFrom: string;
  estimatedCompletionDate: string;
  bomSimulation: any;
  assocToProducts: any;
  date: Date;
  BOM: any;
  buttonBOM=false;
  advanceSearch: {

    ProductID: string,
    ProductIDTo: string,
    BomType: string,



  };
  activeCategoryMain=1;
  products3: any;
 
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
 
  BOMData: any;
  constructor(readonly spinner: NgxSpinnerService, readonly accountsService: AccountsService, 
  
    readonly productionService:ProductionService,
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,  private _location: Location,
  
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,
    readonly router: Router) {
      this.createBom = this._FormBuilder.group({
        BomTypeA: [''],
        ProductIDA:[''],
        ToProductIDA:[''],
        BomType: [''],
        ProductID: [''],
        ToProductID:[''],
        FromDate:[''],
        ThroughDate: [''],
        SequenceNum: [''],
        Reason:[''],
        Instruction:[''],
        Quantity: [''],
        ScrapFactor:[''],
        Formula:[''],
        RoutingTask: [''],  
      });
    this.advanceSearch = {

      ProductID: '',
      ProductIDTo: '',
      BomType: '',

    };

  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
   this.getProducts();
   this.getBOMInitialData();
   this.getBOMData();
   this._ActivatedRoute.queryParams.subscribe(params => {
    this.productId = params["productId"];
    this.productAssocTypeId = params["productAssocTypeId"];
    this.BOM=params["BOM"] 
  });
  if(this.BOM){
    this.buttonBOM=true;
  }
  this.getBOMInitialDataEdit();
  this.getProductsDetails();
  this.getroutingTaskList();
  if(this.productId){
    this.show=false;
    this.getProductBOMData();
    setTimeout(() => {   
       const formValue = this.createBom;
       formValue.patchValue({
      BomType:this.productAssocTypeId,
      ProductID: this.productId,
      BomTypeA: this.productAssocTypeId,
      ProductIDA:this.productId,
       })
     }, 2000);
  }else{
    this.show=false;
    setTimeout(() => {
      const formValue = this.createBom;
      formValue.patchValue({
        BomType:'MANUF_COMPONENT',
        BomTypeA:'MANUF_COMPONENT',
      })
    
    }, 2000);
  }
  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  deleteBOMData(fromDate,productId,productAssocTypeId,productIdTo) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) { 
        this.productionService.deleteBOMData(fromDate,productId,productAssocTypeId,productIdTo).subscribe((res: any) => { 
          this.getProductBOMData();
        }, (err) => {
          this._ToastrService.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        ) } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})}
  showProductBOMData(){
    this.spinner.show();
    this.show=false;
    this.productionService.getProductBOMData(this.createBom.value.BomTypeA,this.createBom.value.ProductIDA).subscribe((res: any) => {
      this.assocFromProducts = res.data.assocFromProducts;
      this.assocToProducts=res.data.assocToProducts;
     
      this.spinner.hide();
    })
  }
    getProductBOMData(){
      this.spinner.show();
    
      this.productionService.getProductBOMData(this.productAssocTypeId,this.productId).subscribe((res: any) => {
        this.assocFromProducts = res.data.assocFromProducts;
        this.assocToProducts=res.data.assocToProducts;
       
        this.spinner.hide();
      })
    }
  
    onSubmit() {
      this.spinner.show();
     
    
      const FromDate = this.createBom.get('FromDate').value;
      this.fromDate = this.datePipe.transform(FromDate, "yyyy-MM-dd hh:mm:ss");
      const ThroughDate = this.createBom.get('ThroughDate').value;
      this.ThroughDate = this.datePipe.transform(ThroughDate, "yyyy-MM-dd hh:mm:ss");
   
      const requestData = {
        "estimateCalcMethod":  this.createBom.value.Formula,
        "fromDate":  this.fromDate,
        "instruction":  this.createBom.value.Instruction,
        "productAssocTypeId":  this.createBom.value.BomType,
        "productId":  this.createBom.value.ProductID,
        "productIdTo": this.createBom.value.ToProductID,
        "quantity":  this.createBom.value.Quantity,
        "reason":  this.createBom.value.Reason,
        "routingWorkEffortId":  this.createBom.value.RoutingTask,
        "scrapFactor":  this.createBom.value.ScrapFactor,
        "sequenceNum":  this.createBom.value.SequenceNum,
        "thruDate": this.ThroughDate
      }
      this.productionService.postBOMData(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.spinner.hide();
          this._ToastrService.success("Created Successfully");
          this.getBOMData();
          this.closebutton.nativeElement.click();
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    }
  
    getroutingTaskList() {
      this.spinner.show();
      
      this.productionService.getroutingTaskList().subscribe(res => {
        this.taskList = res.data.lookupResult;
        this.spinner.hide();
        for (const value of this.taskList) {
          this.taskListArray.push({
            label: value.workEffortId,
            value: value.workEffortId
          })
        }
      })
      
    }
    getProductsDetails(): void {
      this.spinner.show();
      const data = {
        internalName: "",
        internalNameSearchType: "Contains",
        productId: "",
        productIdSearchType: "Contains",
      };
      this.accountsService.getProductsList(data).subscribe((res) => {
        const productList = res.data;
        this.spinner.hide();
        this.productListArray = res.data.map(value => {
          return {
            label: value.productId,
            value: value.productId
          };
        });
     
      });
    
    }
    getBOMInitialDataEdit() {
      this.spinner.show();
      this.productionService.getBOMInitialData(this.finSize).subscribe(res => {
        this.bomTypes = res.data.bomTypes;
        this.formulas = res.data.formulas;
        this.spinner.hide();
        for (const value of this.bomTypes) {
          this.bomTypesArray.push({
            label: value.description,
            value: value.productAssocTypeId
          })
        }
        for (const value of this.formulas) {
          this.formulasArray.push({
            label: value.description,
            value: value.customMethodId
          })
        }
      })
      this.date=new Date();
      let latest_date =this.datePipe.transform(this.date, 'yyyy-MM-dd');
      const formValue = this.createBom;
      formValue.patchValue({
     FromDate:latest_date
      })   
    }
  BomList() {
    this.router.navigate(["/production/create-bom-simulation"])

  }
  ManufacturingRules() {
    this.router.navigate(["/production/manufacturing-rules"])

  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.BOMData = [];
    const req = {
      "productAssocTypeId": this.advanceSearch.BomType ? this.advanceSearch.BomType : '',
      "productId": this.advanceSearch.ProductID ? this.advanceSearch.ProductID : '',
      "productIdTo":this.advanceSearch.ProductIDTo ? this.advanceSearch.ProductIDTo : '',
    }
    this.productionService.getBOMData(this.finSize, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.BOMData = resp.data;
        } 
      });
     
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.ProductID = "";
    this.advanceSearch.ProductIDTo = "";
    this.advanceSearch.BomType="";
   
    this.BOMData = []
    const req = {
      "productAssocTypeId": "",
      "productId": "",
      "productIdTo": ""
    }
    this.productionService.getBOMData(this.finSize,req).subscribe((res: any) => {
      this.BOMData = res.data;
      this.spinner.hide();

    })
   
  }
  getBOMData() {
    this.spinner.show();
    const req={
      "productAssocTypeId": "",
      "productId": "",
      "productIdTo": ""
    }
    this.productionService.getBOMData(this.finSize,req).subscribe((res: any) => {
      this.BOMData = res.data;
      this.spinner.hide();
    })
  
  }
  getProducts(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      const productList = res.data;
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
   
    });
  
  }
  getBOMInitialData() {
    this.spinner.show();
    this.productionService.getBOMInitialData(this.finSize).subscribe(res => {
      this.bomTypes = res.data.bomTypes;
      this.formulas = res.data.formulas;
      this.spinner.hide();
      for (const value of this.bomTypes) {
        this.bomTypesArray.push({
          label: value.description,
          value: value.productAssocTypeId
        })
      }
      for (const value of this.formulas) {
        this.formulasArray.push({
          label: value.description,
          value: value.customMethodId
        })
      }
    })
   
  }
  createSimulation() {
    this.router.navigate(["production/bom-simulation-list"]);
  }
  createManufacture() {
    this.router.navigate(["production/manufacturing-rules"])
  }
  createBOM() {
    this.router.navigate(["production/create-product-bom"])

  }
  detailBillSummary(productId,internalName,productAssocTypeDesc,productAssocTypeId){
    this.router.navigate(["/production/detail-bill-summary"],{ queryParams: { productId : productId,internalName:internalName,productAssocTypeDesc:productAssocTypeDesc,productAssocTypeId:productAssocTypeId,BOM:"Y"}})
   }
  updateBOM(eproductId,eproductAssocTypeId) {
    this.router.navigate(["production/create-product-bom"],{ queryParams: { productId : eproductId,productAssocTypeId:eproductAssocTypeId,BOM:"Y"}})

  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

}


