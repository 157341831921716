<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">
    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            <div class="col-md-12 p-0" >
                <div class="w3-card-4 classCard  w-100">

                    <div class="col-lg-12">
                        <div class="row d-flex mt-3 justify-content-between">
                            <div style="margin-left:21px !important">
                                <label style="font-size:15px !important; font-weight:bold">My Services Service Packages</label>
                            </div>
        
                            <div style="margin-right: 17px !important;">
                                <button class="btn btn-secondary submit-btn" (click)="toBack()">Back</button>
                            </div>
        
                        </div>
                    </div>

                    
                    <div class="col-lg-12">
                        <div class="row">
                            <a class="btn btn-link mt-2" style="margin-top: -1% !important; 
                            margin-left: 8px !important;  font-size: 14px !important;"  data-target="#addNewServicesCategoryPopup" 
                            data-toggle="modal"  >Add a New Service Category</a>

                        </div>

                    </div>

                       <!-- <div class="col-lg-12">
                           <div class="row">
                                <a class="btn btn-link mt-2" style=" font-weight: bold; font-size: 14px !important ; 
                                margin-left: 8px !important;" data-target="#addCommonServicesPopup" data-toggle="modal">Add Common Problems Service</a>
        
                           </div>

                       </div> -->

                        
                        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: -2% !important;">
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                    <div class="card own-account-table" style="border: 1px solid lightcyan !important;">
                                        <div class="d-flex justify-content-start" style="background-color:rgb(206, 214, 218);color: black !important;
                                            border: 1px solid lightcyan !important; margin: 0px !important ; padding: 3px !important;
                                                font-size: 15px ;  font-weight: bold;
                                            padding-left: 7px !important">
                                                <span style="padding-top: 8px;" >Common Problems</span>
                                            <span class="ml-5" style="padding-top: 8px; font-weight: bold;">|</span>
                                            <span><a class="ml-2 btn btn-link" data-toggle="modal" style="font-weight:bold;"  data-target="#addCommonServicesPopup">Add Common Problems Service</a></span>
        
                                        </div>
                                        
                                        
                                        <p-table [value]="commonproblems" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                            <ng-template pTemplate="header">
                                                <tr style="background:rgb(206, 214, 218);">
                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                        <div class="checkbox-align" style="color: black;">
                                                            <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                    </div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                            </p-checkbox>
                                                            Name
                                                            <p-sortIcon field="code">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>
                                                    
                                                

                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                        <div style="color: black;">
                                                            Priority
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>

                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Expected Duration
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Skill
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Action
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-product>
                                                <tr>
                                                    <td [ngStyle]="{'width':'150px'}">
                                                        <div class="checkbox-align">
                                                            <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                    </div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                            </p-checkbox>
                                                            <span  class="account-button"  >
                                                                {{product.teamMemberPartyId}}
                                                            </span>

                                                        </div>
                                                    </td>
                                                    <td [ngStyle]="{'width':'180px'}">{{product.securityGroupId}}
                                                    </td>
                                                    <td [ngStyle]="{'width':'180px'}">
                                                    <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer; "  data-toggle="modal" data-target="#EditPrioritiesPopup">Update</small>
                                                    <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer; " >Delete</small>
                                                    
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage" let-columns>
                                                <tr>
                                                    <td>
                                                        <span class="text-center">
                                                            <h4 class="ml-2">No Record Found</h4>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                        <p class="paginate_data">
                                            View per page

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="row">
                                 <a class="btn btn-link mt-2" style=" font-size: 14px !important ; 
                                 margin-left: 8px !important;" data-target="#addInstallationPopup" data-toggle="modal">Add Installation Service</a>
         
                            </div>
 
                        </div>

                        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: -1%;">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="border: 1px solid lightcyan !important;">
                                        <div class="d-flex justify-content-start" style="background-color:rgb(206, 214, 218);color: black !important;
                                    border: 1px solid lightcyan !important; margin: 0px !important ; padding: 3px !important;
                                        font-size: 15px ;  font-weight: bold;
                                    padding-left: 7px !important">
                                        <span style="padding-top: 8px;" >Installation</span>
                                    <span class="ml-5" style="padding-top: 8px; font-weight: bold;">|</span>
                                    <span><a class="ml-2 btn btn-link" data-toggle="modal" style="font-weight:bold;"  data-target="#editServiceTeamMemberPopup">Rename</a></span>
                                    <span><a class="ml-2 btn btn-link" data-toggle="modal" style="font-weight:bold;"  data-target="#editServiceTeamMemberPopup">Delete</a></span>

                                    </div>
                                
                                

                                    <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background:rgb(206, 214, 218);">
                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: black;">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            
                                                        </p-checkbox>
                                                        Name
                                                        <p-sortIcon field="code">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                
                                                

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Priority
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Expected Duration
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Skill
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Action
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'150px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        <span  class="account-button"  >
                                                            {{product.teamMemberPartyId}}
                                                        </span>

                                                    </div>
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.securityGroupId}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">
                                                    <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer; "  data-toggle="modal" data-target="#EditPrioritiesPopup">Update</small>
                                                    <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer; " >Delete</small>
                                                    
                                                    </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">No Record Found</h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <p class="paginate_data">
                                        View per page

                                    </p>
                                </div>

                                </div>
                            </div>
                       
                       
                        </div>

                        <div class="col-lg-12">
                            <div class="row">
                                 <a class="btn btn-link mt-2" style="  font-size: 14px !important ;
                                 margin-left: 8px !important;" data-target="#addTestServicesPopup" data-toggle="modal">Add Test Service</a>
         
                            </div>
 
                        </div>
                        
                        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: -1%;">
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                    <div class="card own-account-table" style="border: 1px solid lightcyan !important;">

                                        <div class="d-flex justify-content-start" style="background-color:rgb(206, 214, 218);color: black !important;
                                            border: 1px solid lightcyan !important; margin: 0px !important ; padding: 3px !important;
                                                font-size: 15px ;  font-weight: bold;
                                            padding-left: 7px !important">
                                                <span style="padding-top: 8px;" >Test</span>
                                            <span class="ml-5" style="padding-top: 8px; font-weight: bold;">|</span>
                                            <span><a class="ml-2 btn btn-link" data-toggle="modal" style="font-weight:bold;"  data-target="#editServiceTeamMemberPopup">Rename</a></span>
                                            <span><a class="ml-2 btn btn-link" data-toggle="modal" style="font-weight:bold;"  data-target="#editServiceTeamMemberPopup">Delete</a></span>
                                        </div>
                                    
    
                                        <p-table [value]="test" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                            <ng-template pTemplate="header">
                                                <tr style="background:rgb(206, 214, 218);">
                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                        <div class="checkbox-align" style="color: black;">
                                                            <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                    </div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                
                                                            </p-checkbox>
                                                            Name
                                                            <p-sortIcon field="code">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>
                                                    
                                                    
    
                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                        <div style="color: black;">
                                                            Priority
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>
    
                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                        <div style="color: black;">
                                                            Expected Duration
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>
    
                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                        <div style="color: black;">
                                                            Skill
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>
    
                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                        <div style="color: black;">
                                                            Action
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-product>
                                                <tr>
                                                    <td [ngStyle]="{'width':'150px'}">
                                                        <div class="checkbox-align">
                                                            <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                    </div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            <span  class="account-button"  >
                                                                {{product.teamMemberPartyId}}
                                                            </span>
    
                                                        </div>
                                                    </td>
                                                    <td [ngStyle]="{'width':'180px'}">{{product.securityGroupId}}
                                                    </td>
                                                    <td [ngStyle]="{'width':'180px'}">
                                                        <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer; "  data-toggle="modal" data-target="#EditPrioritiesPopup">Update</small>
                                                        <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor: pointer; " >Delete</small>
                                                        
                                                        </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage" let-columns>
                                                <tr>
                                                    <td>
                                                        <span class="text-center">
                                                            <h4 class="ml-2">No Record Found</h4>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                        <p class="paginate_data">
                                            View per page
    
                                        </p>
                                    
                                    </div>
                                </div>
                            </div>

                        </div>
                     </div>
                </div>
            </div>
    </div>



<div class="modal fade" id="addCommonServicesPopup" tabindex="-1" role="dialog" aria-labelledby="addCommonServicesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Common Problem</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Common Problem</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addCommonProblemForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                            aria-describedby="" placeholder="Enter Name"
                                                            formControlName="name">
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Priority</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            optionlabel="label"
                                                            placeholder="Choose Priority"
                                                            formControlName="priority">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Expected Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="time" class="form-control" id="exampleInputEmail1" name=""
                                                        aria-describedby="" 
                                                        formControlName="expectedDuration">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Skill</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                        aria-describedby="" placeholder="Enter Skill"
                                                        formControlName="skill">
                                                    </div>

                                                </div>
                                            </div>

                                            
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"  >Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addInstallationPopup" tabindex="-1" role="dialog" aria-labelledby="addInstallationPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Installation Service Package</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Installation Service Package</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addInstallationServiceForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                            aria-describedby="" placeholder="Enter Name"
                                                            formControlName="name">
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Priority</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            optionlabel="label"
                                                            placeholder="Choose Priority"
                                                            formControlName="priority">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Expected Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="time" class="form-control" id="exampleInputEmail1" name=""
                                                        aria-describedby="" 
                                                        formControlName="expectedDuration">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Skill</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                        aria-describedby="" placeholder="Enter Skill"
                                                        formControlName="skill">
                                                    </div>

                                                </div>
                                            </div>

                                            
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addTestServicesPopup" tabindex="-1" role="dialog" aria-labelledby="addTestServicesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Test Service Package</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Test Service Package</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addTestServiceForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                            aria-describedby="" placeholder="Enter Name"
                                                            formControlName="name">
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Priority</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            optionlabel="label"
                                                            placeholder="Choose Priority"
                                                            formControlName="priority">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Expected Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="time" class="form-control" id="exampleInputEmail1" name=""
                                                        aria-describedby="" 
                                                        formControlName="expectedDuration">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Skill</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                        aria-describedby="" placeholder="Enter Skill"
                                                        formControlName="skill">
                                                    </div>

                                                </div>
                                            </div>

                                            
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addNewServicesCategoryPopup" tabindex="-1" role="dialog" aria-labelledby="addNewServicesCategoryPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add New Service Category</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add New Service Category</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addServiceCategoryForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Display As</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" name=""
                                                            aria-describedby="" placeholder="Enter Display As"
                                                            formControlName="displayAs">
                                                        
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: -13%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Save</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <ngx-spinner></ngx-spinner> -->
