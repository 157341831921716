<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Setup</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span>  
             </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''"><a>
                                    Email Configuration Setup</a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">
                                                    <div class="panel-body">
                                                        <form>
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-4 d-flex">
                                                                            <div style="margin-top: -6px; margin-right: 10px;">
                                                                                <input type="checkbox" name="emailcheck" id="emailcheck">
                                                                            </div>
                                                                            <div>
                                                                                <label style="color: black !important;">Configure email settings</label></div></div><div class="col-lg-2"><div class="form-group">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">SMTP
                                                                                    Server</label></div></div><div class="col-lg-2"><div class="form-group">
                                                                                <input type="text" name="partyId"
                                                                                    class="form-control"
                                                                                    formControlName1="partyId"
                                                                                    id="partyId"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Fiscal Year">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">SMTP
                                                                                    Port</label></div></div><div class="col-lg-2"><div class="form-group">
                                                                                <input type="text" name="partyId"
                                                                                    class="form-control"
                                                                                    formControlName1="partyId"
                                                                                    id="partyId"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter SMTP Port">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">SMTP
                                                                                    Encryption</label></div></div><div class="col-lg-2"><div class="form-group">
                                                                                <p-dropdown
                                                                                    [options]="identificationArray"
                                                                                    formControlName1="partyIdentificationTypeId"
                                                                                    filter="true"
                                                                                    placeholder="Enter SMTP Encryption">
                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">User
                                                                                    Name</label></div></div><div class="col-lg-2"><div class="form-group">
                                                                                <input type="text" name="partyId"
                                                                                    class="form-control"
                                                                                    formControlName1="partyId"
                                                                                    id="partyId"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter User Name">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    for="exampleInputEmail1">Password</label></div></div><div class="col-lg-2"><div class="form-group">
                                                                                <input type="text" name="externalId"
                                                                                    class="form-control"
                                                                                    formControlName1="externalId"
                                                                                    id="externalId"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Password">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                        <div class="col-lg-2">
                                                                            <div class="form-group">
                                                                                <label for="exampleInputEmail1">Confirm
                                                                                    Password</label></div></div><div class="col-lg-2"><div class="form-group">
                                                                                <input type="text" name="externalId"
                                                                                    class="form-control"
                                                                                    formControlName1="externalId"
                                                                                    id="externalId"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Confirm Password">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12 col-12 main-submit-button " style="margin-left:-16%!important;">
                                                                <button _ngcontent-cta-c128="" type="submit"
                                                                    (click)="goToCompanyInfo()"
                                                                    class="btn btn-secondary submit-btn">Previous</button>&nbsp;&nbsp;
                                                                <button _ngcontent-cta-c128="" type="submit" (click)="goToSetupUsers()"
                                                                    class="btn btn-secondary submit-btn">Save &
                                                                    Next</button>&nbsp;&nbsp;
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>