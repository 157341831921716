import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {FormGroup, FormBuilder} from '@angular/forms';
import { CRMAccountService } from 'src/app/services/crm-account.service'
import { GlobalResourceService } from 'src/app/services/global-resource.service'
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';

    import { Location } from '@angular/common';

@Component({
  selector: 'app-my-portal-page-admin',
  templateUrl: './my-portal-page-admin.component.html',
  styleUrls: ['./my-portal-page-admin.component.css']
})
export class MyPortalPageAdminComponent implements OnInit {
  advanceSearch: {
    description:string,
    description_ic: string,
    description_op: string,
    noConditionFind: string,
    originalPortalPageId: string,
    originalPortalPageId_ic:string,
    originalPortalPageId_op: string,
    parentPortalPageId: string,
    parentPortalPageId_ic:string,
    parentPortalPageId_op:string,
    portalPageId: string,
    portalPageId_ic:string,
    portalPageId_op: string,
    portalPageName:string,
    portalPageName_ic: string,
    portalPageName_op:string,
    securityGroupId: string,
    securityGroupId_ic:string,
    securityGroupId_op: string,
   
  };
  activeCategory=1;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  userLoginIdArray:any[]=[];
  opportunityIdArray: { label: string; value: string; }[];
  PortalPage: any;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
      private _location: Location,
      readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,) { 
      this.opportunityIdArray = [
        {
          label: 'Contains',
          value: 'contains'
        },
        {
          label: 'Equals',
          value: 'Equals'
        },
        {
          label: 'Is Empty',
          value: 'Is Empty'
        },
        {
          label: 'Begins With',
          value: 'Begins With'
        },
      ];
      this.advanceSearch = {

        description: "",
        description_ic: "Y",
        description_op: "contains",
        noConditionFind: "Y",
        originalPortalPageId: "",
        originalPortalPageId_ic: "Y",
        originalPortalPageId_op: "contains",
        parentPortalPageId: "",
        parentPortalPageId_ic: "Y",
        parentPortalPageId_op: "contains",
        portalPageId: "",
        portalPageId_ic: "Y",
        portalPageId_op: "contains",
        portalPageName: "",
        portalPageName_ic: "Y",
        portalPageName_op: "contains",
        securityGroupId: "",
        securityGroupId_ic: "Y",
        securityGroupId_op: "contains"
  
  
      };
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
     this.findPortalPage();
  }
  findPortalPage() {
    this.spinner.show();
    const req = {
      description: "",
        description_ic: "Y",
        description_op: "contains",
        noConditionFind: "Y",
        originalPortalPageId: "",
        originalPortalPageId_ic: "Y",
        originalPortalPageId_op: "contains",
        parentPortalPageId: "",
        parentPortalPageId_ic: "Y",
        parentPortalPageId_op: "contains",
        portalPageId: "",
        portalPageId_ic: "Y",
        portalPageId_op: "contains",
        portalPageName: "",
        portalPageName_ic: "Y",
        portalPageName_op: "contains",
        securityGroupId: "",
        securityGroupId_ic: "Y",
        securityGroupId_op: "contains"
    
    }
    this.accountsService.findPortalPage(this.finSize,req).subscribe(res => {
      this.PortalPage = res.data;
      this.spinner.hide();
    })
  
  }
  homeButton(){
    this._Router.navigate(['/myPortal/my-portal-my-profile'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
      
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.PortalPage = [];
    const req = {
      "description": this.advanceSearch.description ? this.advanceSearch.description : '',
      "description_ic": "Y",
      "description_op":this.advanceSearch.description_op ? this.advanceSearch.description_op : '',
      "noConditionFind": "Y",
      "originalPortalPageId": this.advanceSearch.originalPortalPageId ? this.advanceSearch.originalPortalPageId : '',
      "originalPortalPageId_ic": "Y",
      "originalPortalPageId_op": this.advanceSearch.originalPortalPageId_op ? this.advanceSearch.originalPortalPageId_op : '',
      "parentPortalPageId": this.advanceSearch.parentPortalPageId ? this.advanceSearch.parentPortalPageId : '',
      "parentPortalPageId_ic": "Y",
      "parentPortalPageId_op": this.advanceSearch.parentPortalPageId_op ? this.advanceSearch.parentPortalPageId_op : '',
      "portalPageId": this.advanceSearch.portalPageId ? this.advanceSearch.portalPageId : '',
      "portalPageId_ic": "Y",
      "portalPageId_op": this.advanceSearch.portalPageId_op ? this.advanceSearch.portalPageId_op : '',
      "portalPageName":this.advanceSearch.portalPageName ? this.advanceSearch.portalPageName : '',
      "portalPageName_ic": "Y",
      "portalPageName_op": this.advanceSearch.portalPageName_op ? this.advanceSearch.portalPageName_op : '',
      "securityGroupId":this.advanceSearch.securityGroupId ? this.advanceSearch.securityGroupId : '',
      "securityGroupId_ic": "Y",
      "securityGroupId_op": this.advanceSearch.securityGroupId_op ? this.advanceSearch.securityGroupId_op : '',

    
    }
    this.accountsService.findPortalPage(this.finSize, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.PortalPage = resp.data;
        } 
      });
     
  }
  reset(): void {
    this.spinner.show();
    this.PortalPage = [];
    this.advanceSearch = {

      description: "",
      description_ic: "Y",
      description_op: "contains",
      noConditionFind: "Y",
      originalPortalPageId: "",
      originalPortalPageId_ic: "Y",
      originalPortalPageId_op: "contains",
      parentPortalPageId: "",
      parentPortalPageId_ic: "Y",
      parentPortalPageId_op: "contains",
      portalPageId: "",
      portalPageId_ic: "Y",
      portalPageId_op: "contains",
      portalPageName: "",
      portalPageName_ic: "Y",
      portalPageName_op: "contains",
      securityGroupId: "",
      securityGroupId_ic: "Y",
      securityGroupId_op: "contains"


    };
   
    const req = {
      description: "",
        description_ic: "Y",
        description_op: "contains",
        noConditionFind: "Y",
        originalPortalPageId: "",
        originalPortalPageId_ic: "Y",
        originalPortalPageId_op: "Contains",
        parentPortalPageId: "",
        parentPortalPageId_ic: "Y",
        parentPortalPageId_op: "Contains",
        portalPageId: "",
        portalPageId_ic: "Y",
        portalPageId_op: "Contains",
        portalPageName: "",
        portalPageName_ic: "Y",
        portalPageName_op: "Contains",
        securityGroupId: "",
        securityGroupId_ic: "Y",
        securityGroupId_op: "Contains"
    
    }
    this.accountsService.findPortalPage(this.finSize, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.PortalPage = resp.data;
        } 
      });
     
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  detailMainPage(portalPageId,portalPageName,description,parentPortalPageId,sequenceNum,originalPortalPageId,ownerUserLoginId,securityGroupId){
    this._Router.navigate(['/myPortal/detail-portal-page-admin'],{ queryParams: { portalPageId:portalPageId,portalPageName:portalPageName,description:description,parentPortalPageId:parentPortalPageId,sequenceNum:sequenceNum,originalPortalPageId:originalPortalPageId,ownerUserLoginId:ownerUserLoginId,securityGroupId:securityGroupId}});
  }
 
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
