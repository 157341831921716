<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Responsibilities of {{ this.resId}} </span>
                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Back</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                </div>
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians" style="padding-left: 9px;">
                        <li [ngClass]="activeCategory==1?'active':''" ><a>
                            Responsibilities Information</a></li>
                       
                    </ul>
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">
                                            <div *ngIf="activeCategory==1">
                                              
                                                <div class="panel-body">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="color-black container">
                                                            <div class="row">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Responsibility Type Id</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{ this.resId}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Empl Position Type Id</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.empId}}</span>
                                                                     
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">From Date</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.date| date :'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                     
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Through Date</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.tDate| date :'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                     
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Comments</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.comment}}</span>
                                                                     
                                                                    </div>
                                    
                                    
                                    
                                                                 
                                    
                                                                    
                                                                  </div>

                                                               
                                                               
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner> 