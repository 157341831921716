import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountingApService } from '../../../accounting-ap.service';

@Component({
    selector: 'app-create-credit-term',
    templateUrl: './create-credit-term.component.html'
})
export class CreateCreditTermComponent implements OnInit {
    uomIds: any[];
    invoiceTypeIds: any[];
    invoiceTermForm: FormGroup;
    invoiceId: string;
    invoiceTermValue: any;
    editMode: boolean;
    invoiceTermId: string;
    invoiceType: string;
    constructor(
        readonly _FormBuilder: FormBuilder,
        readonly spinner: NgxSpinnerService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _AccountingApService: AccountingApService,
        readonly _ToastrService: ToastrService,
        readonly  _Router: Router
    ) {
        this.invoiceTermForm = this._FormBuilder.group({
            description: [''],
            invoiceItemSeqId: [''],
            invoiceTermId: [''],
            termDays: [''],
            termTypeId: [''],
            termValue: [''],
            textValue: [''],
            uomId: ['']
        });
        this.invoiceTypeIds = [];
        this.uomIds = [];
        this.invoiceTermId = '';
        this.editMode = false;
        this.invoiceType = 'payable';
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.invoiceId = this._ActivatedRoute.snapshot.queryParams.invoiceId;
        this.invoiceType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this.invoiceTermId = this._ActivatedRoute.snapshot.queryParams.invoiceTermId;
        if (this.invoiceTermId) {
            this.editMode = true;
            this._AccountingApService.getInvoiceTermById(this.invoiceTermId)
                .then(data => {
                    this.invoiceTermValue = data.data;
                    this.invoiceTermForm.patchValue({
                        ...data.data, ...{
                            invoiceItemSeqId: data.data.itemNo
                        }
                    });
                });
            this.invoiceTermForm.controls.invoiceTermId.disable();
            this.invoiceTermForm.controls.invoiceTermId.updateValueAndValidity();
        }
        this.getInvoiceTypeIds();
    }
    getInvoiceTypeIds(): void {
        this.spinner.show();
        this._AccountingApService.getTermType()
            .then(data => {
                this.uomIds = data.data.uom.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
                this.invoiceTypeIds = data.data.termTypes.map(value => {
                    return {
                        label: value.description,
                        value: value.termTypeId
                    };
                });
            });
            this.spinner.hide();
    }
    update(): void {
        this.spinner.show();
        this._AccountingApService.updateInvoiceTerm(this.invoiceId, {
            ...this.invoiceTermValue, ...this.invoiceTermForm.value
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.invoiceTermForm.reset();
                    this.spinner.hide();
                    this._Router.navigate(['/financial/payable/credit-memo/summary-credit-memo'], { queryParams: { invoiceId: this.invoiceId } });
                }
            });
          
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            this._AccountingApService.createInvoiceTerm(this.invoiceId, this.invoiceTermForm.value)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.invoiceTermForm.reset();
                        this.spinner.hide();
                        this._Router.navigate([`/financial/${this.invoiceType}/credit-memo/summary-credit-memo`], { queryParams: { invoiceId: this.invoiceId } });
                    }
                });
        }
      
    }
    reset(): void {
        this.invoiceTermForm.reset();
        this._Router.navigate([`/financial/${this.invoiceType}/credit-memo/summary-credit-memo`], { queryParams: { invoiceId: this.invoiceId } });
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}