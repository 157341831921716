import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class QuotesService {

  constructor(readonly http: HttpClient, readonly _ApiService: ApiService) { }
  getstatusList(): Observable<any> {
    const statusData = URLS.getStatusIdsList;
    return this.http.get(statusData);
  }
  getCurrency(): Observable<any> {
    const getCurrency = URLS.getCurrency;
    return this.http.get(getCurrency);
  }
  getCurrencyData(): Promise<any> {
    return this.http.get(URLS.getCurrency).toPromise();
  }
  getContactNotes(id): Observable<any> {
    const getContactNotes = URLS.getContactNotes.replace(":partyId", id);
    return this.http.get(getContactNotes);
  }
  getQuotoId(): Observable<any> {
    const quotoId = URLS.quotoTypeId;
    return this.http.get(quotoId);
  }
  getQuoteStatus(): Promise<any> {
    const quotoId = URLS.quotoTypeId;
    return this.http.get(quotoId).toPromise();
  }
  getPartygroup(): Observable<any> {
    const getPartyId = URLS.getPersonsPartyId;
    return this.http.get(getPartyId)
  }
  postQuotes(formData): Observable<any> {
  
    const postQuotes = URLS.createQuote;
    return this.http.post(postQuotes, formData)
  }

  updateQuotes(formData, id): Observable<any> {

    const updateQuote = URLS.updateQuote.replace(":quoteId", id);
    return this.http.put(updateQuote, formData)
  }
  status(): Observable<any> {
    const statusId = URLS.quotoTypeId;
    return this.http.get(statusId);
  }
  getQueto(size, form): Observable<any> {
  
    const getQuetoList = URLS.getQuoteList + "?pageSize=" + size.pagesize + "&pageNo=" + size.pageNo;
    return this.http.post(getQuetoList, form)
  }

  getQuetoDetail(id): Observable<any> {
    const getQuetoDetail = URLS.getQuote.replace(':quoteId', id);
    return this.http.get(getQuetoDetail)
  }
  quoteNoteList(quoteId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crm/quoteNote/${quoteId}`);
  }
  generateQuotePDF(quoteId: string): Observable<any> {
    return this.http.get(`/phyCorp/control/api/v1/crm/quote/pdf?quoteId=${quoteId}`, { responseType: 'blob' });
  }
  quoteEmail(formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/sendQuoteReportMail`, formData);
  }

}
