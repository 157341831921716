import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';

import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
@Component({
  selector: 'app-create-avs-string',
  templateUrl: './create-avs-string.component.html',
  styleUrls: ['./create-avs-string.component.css']
})
export class CreateAvsStringComponent implements OnInit {
  createClassification:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
 
  ethruDate: any;
  getthruDate: string;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,) { 
    
      this.createClassification = this._FormBuilder.group({
        avs: [''],
        
       
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
 
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      
    });
   
 this.getPartyIcsAvsOverRide();
  }
  
  getPartyIcsAvsOverRide() {
    this.spinner.show();
    this.accountsService.getPartyIcsAvsOverRide(this.party).subscribe(res => {
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.createClassification;
      formValue.patchValue({
        avs:res.data[0].avsDeclineStringList.avsDeclineString,
       
      })
      }, 500); 
     
    })
  
  }
  
   onUpdate() {
    this.spinner.show();

    this.accountsService.updateAvsOverride(this.createClassification.value.avs,this.party,).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }  
   
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }}

