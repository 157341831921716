import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';

@Component({
  selector: 'app-commerce-dashboard',
  templateUrl: './commerce-dashboard.component.html',
  styleUrls: ['./commerce-dashboard.component.css']
})
export class CommerceDashboardComponent implements OnInit {
  userPermissions: any;
  isAccount: boolean;
  userProfile: any;
  adminSecurity: string;
  fmsRole: string;
  showFSM: boolean;
  showFSMTechnician: boolean;
  showFSMtechnician: boolean;
  partyId: string;
  checkPOSToken: string;
  productStoreIdNew: string;
  posTerminalIdNew: string;
  constructor(
    private _location: Location,
    readonly router: Router, 
    readonly accountsService: AccountsService
  ) { }

  ngOnInit(): void {
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    const security: string = localStorage.getItem('adminSecurity');
    this.fmsRole = localStorage.getItem('fmsRole');
    this.partyId = localStorage.getItem('partyId');
    this.adminSecurity=security;
    this.getUserProfile();


  }
  getUserProfile() {

    this.accountsService.getUserProfile().subscribe((res: any) => {
      this.userProfile = res.data[0];


    })
  }
  logoutPOS() {
    this.checkPOSToken = localStorage.getItem('posToken');
    if(this.checkPOSToken){
      this.productStoreIdNew = localStorage.getItem('productStoreId');
      this.posTerminalIdNew = localStorage.getItem('posTerminalId');
      this.router.navigate(["/webpos/control/main"],{queryParams:{ posTerminalId :  this.posTerminalIdNew, productStoreId:this.productStoreIdNew}});
    }
    else{
      localStorage.removeItem("token");
      localStorage.removeItem('posToken');
      localStorage.removeItem('productStoreId');
      localStorage.removeItem('posTerminalId');
      localStorage.removeItem('userLoginId');
      this.router.navigate(["/login"],{queryParams:{ webPos :  "webPos"}});
    }
   

  }
  homeButton(){
    this.router.navigate(['/CommerceDashboardComponent'])

  }
  cancelSubmit(){
    this._location.back();
  }

}
