import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from '../accounts/accounts.service';

@Component({
  selector: 'app-create-crm-return',
  templateUrl: './create-crm-return.component.html',
  styleUrls: ['./create-crm-return.component.css']
})
export class CreateCrmReturnComponent implements OnInit {
  activeCategory=2;
  createReturn:FormGroup
  commEventId: any;
  returnArray: any[]=[];
  return: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  search='';
  accountsList:any[]=[]
  relatedContact:any[]=[]
  rows = 50;

  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  constructor(readonly router: Router,
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,

    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly accountsService: AccountsService,
    readonly spinner: NgxSpinnerService,) { 
    this.createReturn = this._FormBuilder.group({
      returnId: ['']
    
     
 
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); } 
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.commEventId = params['commEventId'];
     
    });
    this.getReturnOrderValue();
  }
  getReturnOrderValue() {
    this.spinner.show();
    const req = {
    "pageNo":this.pageNo,
    "pageSize":this.pageSize
    }
    const form ={
      billingAccountId: "",
      entryDateFrom: "",
      entryDateFromSearchType: "Contains",
      entryDateTo: "",
      entryDateToSearchType: "Contains",
      billingAccountIdSearchType: "Contains",
      fromPartyId: "",
      returnHeaderTypeId: "",
      returnId: "",
      returnIdSearchType: "Contains",
      statusIds: [""],
    }
  
    
    this.accountsService.getOrderReturn(req,form).subscribe((res: any) => {

      this.return = res.data;
      this.spinner.hide();
      for (const value of this.return) {
        this.returnArray.push({
          label: value.returnId,
          value: value.returnId
        })
      }
    })
   
  }
  cancelSubmit(){
    this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:this.commEventId} });
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "returnId": this.createReturn.value.returnId,
     
    }

    this.accountsService.postCrmEventReturnList(this.commEventId,requestData.returnId).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:this.commEventId} });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  ngOnDestroy(): void {

    this.spinner.hide();
      } 
}

