<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Related Accounts
                </span>
                <span class="color-black pl-1" > >Add Related Account
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''"><a>
                        Add Related Account
                    </a></li>
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="fileUploadForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    Account Party ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="accountPartyId" optionlabel="label" filter="true"
                                                placeholder="Enter Account Party ID"
                                                name="Parent Type ID" [options]="accountArray">
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Comments</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="comments"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Comments">
                                            </div>
        
                                           
        
                                        </div>
                                    </div>
                                  
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: 65%;">
                               
                                <button type="submit" (click)="onSubmit();"  class="btn btn-secondary submit-btn">Add</button>
                                
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div> -->


<div class="modal-header">
    
    <span class="color-black pl-1">  New Promotion Code </span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
      </button>
</div>
<div class="modal-body d-flex">
    <div class=" bg-white color-grey w-100" >
        <div class="w3-card-4 classCardPopUp" style="width: 100%;">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass">

                    <li class="active"><a>
                        New Promotion Code</a></li>
                </ul>
            </div>
            
            <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100" [formGroup]="updatePromotionCode">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Product promo Code ID
                                            </label>
                                        </div>
                                        <div class="col-lg-3 form-group">
                                            <input type="text" disabled class="form-control" formControlName="promoCodeID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product promo Code ID">
                                        </div>
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Product Promo ID
                                            </label>
                                        </div>
                                        <div class="col-lg-3 form-group">
                                            <p-dropdown filter="true" formControlName="promoID" [options]="PromotionCodeListArray" optionlabel="label" placeholder="Enter Product Promo ID">
                                            </p-dropdown>
                                        </div>
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">User Entered
                                            </label>
                                        </div>
                                        <div class="col-lg-3 form-group">
                                            <p-dropdown filter="true" formControlName="userEntered"
                                            [options]="yesNoArray" optionlabel="label" placeholder="Enter User Entered">
                                            </p-dropdown>
                                        </div>
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Require Email or Party
                                            </label>
                                        </div>
                                        <div class="col-lg-3 form-group">
                                            <p-dropdown filter="true" [options]="yesNoArray"  formControlName="reqEmailParty" optionlabel="label" placeholder="Enter Require Email or Party">
                                            </p-dropdown>
                                        </div>
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Use Limit per Code
                                            </label>
                                        </div>
                                        <div class="col-lg-3 form-group">
                                            <input type="text" class="form-control" formControlName="limitCode" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Limit per Code">
                                        </div>
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Use Limit per Customer
                                            </label>
                                        </div>
                                        <div class="col-lg-3 form-group">
                                            <input type="text" class="form-control" formControlName="limitCust" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Limit per Customer">
                                        </div>
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">From Date
                                            </label>
                                        </div>
                                        <div class="col-lg-3 form-group">
                                            <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                        </div>
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Through Date
                                            </label>
                                        </div>
                                        <div class="col-lg-3 form-group">
                                            <input type="date" formControlName="thruDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                        </div>
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Created Date
                                            </label>
                                        </div>
                                        <div class="col-lg-3 form-group">
                                            <input type="date" formControlName="createdDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Created Date">
                                        </div>
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Created by User
                                            </label>
                                        </div>
                                        <div class="col-lg-3 form-group">
                                            <input type="text" class="form-control" formControlName="createdUser" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Created by User">
                                        </div>
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Last Modified Date
                                            </label>
                                        </div>
                                        <div class="col-lg-3 form-group">
                                            <input type="date" formControlName="modifiyDate" 
                                            class="form-control" id="exampleInputEmail1" 
                                            aria-describedby="emailHelp" 
                                            placeholder="Enter Last Modified Date">
                                        </div>
                                        <div class="col-lg-3 form-group classInput">
                                            <label for="exampleInputEmail1">Last Modified by User
                                            </label>
                                        </div>
                                        <div class="col-lg-3 form-group">
                                            <input type="date" formControlName="modifyUser"
                                             class="form-control" id="exampleInputEmail1" 
                                             aria-describedby="emailHelp"
                                              placeholder="Enter Last Modified by User">
                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-11 col-11 main-submit-button">
                                <button type="submit" class="btn btn-secondary submit-btn" 
                                (click)="updateProductCode()">Update</button>
                            </div>
                        </form>

                    </div>
                </div>

            </div>
            </div>
    </div>
</div>
      




