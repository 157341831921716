<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12 pad_0">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Request List Summary
                </span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                    <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span> 
            </div>

            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard border_wdth_1">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCategory==1?'active':''"
                             (click)="changeactiveCategory(1)">
                                <a>Overview</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                <a>Request</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                <a>Request Roles</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                <a>Request Items</a>
                            </li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)">
                                <a>Content</a>
                            </li>

                            <li  style="margin-left: 38%;">
                                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>
                            </li>

                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCard w-100">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">{{this.custRequestName}} [ ID : {{this.custRequestId}} ]
                                                </p>
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="reset()" 
                                                data-toggle="modal" data-target="#exampleRequestList">Create New
                                                    Request</button>

                                                <button type="submit" class="btn btn-secondary submit-btn mr-2
                                                " *ngIf="this.statusID=='CRQ_SUBMITTED'" (click)="quotePage()">Create Quote from
                                                    request

                                                </button>
                                            </div>
                                            <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                                <div *ngIf="activeCategory==1" class="w-100">
                                                    <div style="width: 49%; float: left;">
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    Request Information
                                                                </h4>
                                                            </div>
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="row">
                                                                            <div class="article-container" *ngIf="this.RequestInformation">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Type </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">{{this.RequestInformation.custRequestTypeId}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Status</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.RequestInformation.statusId}}
                                                                                    </span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Party ID</p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.RequestInformation.fromPartyId}}</span>
                                                                                </div>

                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Name </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.RequestInformation.custRequestName}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Description </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.RequestInformation.description}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        ProductProductStore
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.RequestInformation.productStoreId}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Internal Comment
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.RequestInformation.internalComment}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Reason </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.RequestInformation.reason}}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Request Date </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.RequestInformation.requiredByDate
                                                                                        | date:'yyyy-MM-d hh:mm:ss'
                                                                                        }}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Created Date </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.RequestInformation.createdDate
                                                                                        | date:'yyyy-MM-d hh:mm:ss'
                                                                                        }}</span>
                                                                                </div>
                                                                                <div class="article">
                                                                                    <p class="col-lg-6 classInput">
                                                                                        Last Modified Date
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.RequestInformation.lastModifiedDate
                                                                                        | date:'yyyy-MM-d hh:mm:ss'
                                                                                        }}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style="width: 49.8%; float: right;">
                                                        <div class="w3-card-4 classCardView ">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    Request Roles
                                                                </h4>
                                                            </div>

                                                            <div class="panel-body allStyleUser">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="requestRolesById" [paginator]="true" [rows]="rows"
                                                                    scrollHeight="100px"  scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>



                                                                                        Party ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Role Type ID</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        From Date</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Through Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.data.partyId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.data.roleTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.data.fromDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.data.thruDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                   
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    Communication Events

                                                                </h4>

                                                            </div>

                                                            <div class="panel-body allStyleUser">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="CommunicationEvents"  scrollHeight="100px"  [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Subject
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Type
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Status ID</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Party ID</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Role Type ID</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Role Status ID</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Created</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Sent</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>




                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.nodeData.subject}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.nodeData.statusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.nodeData.partyIdFrom}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.nodeData.roleTypeIdTo}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.nodeData.roleStatusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.nodeData.entryDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.nodeData.datetimeStarted | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                               
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    Work Efforts
                                                                </h4>
                                                            </div>

                                                            <div class="panel-body allStyleUser">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="WorkEfforts" [paginator]="true"  scrollHeight="100px"  [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Work Effort ID


                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Current Status ID

                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;"> Start Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">Completion Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>




                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.nodeData.workEffortId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.nodeData.currentStatusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.nodeData.estimatedStartDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.nodeData.estimatedCompletionDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No
                                                                                            Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                   
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    Content

                                                                </h4>

                                                            </div>

                                                            <div class="panel-body allStyleUser">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="CustRequestContentById"  scrollHeight="100px"  [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Content ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Mime Type ID
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        From Date</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>





                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        {{product.data.contentId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.nodeName[0].mimeTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.data.fromDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                   
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div style="width: 100%; float: left;">
                                                        <div class="w3-card-4 classCardView mt-2">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling hleft">
                                                                    Request Items

                                                                </h4>

                                                            </div>

                                                            <div class="panel-body allStyleUser">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="RequestItemData"  scrollHeight="100px"  
                                                                    [paginator]="false" [rows]="rows" scrollWidth="100%" 
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" 
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                     (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        Cust Request Item Seq ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Description
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Add Note</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>





                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span type="submit" (click)="updateRequestItem(product)" data-toggle="modal" data-target="#updateRequestItem">
                                                                                            {{product.data.custRequestItemSeqId}}</span>
                                                                                    </div>
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.data.description}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" 
                                                                                    (click)="notePage(product.data.custRequestItemSeqId, product.data.custRequestId)">Add
                                                                                        Note</button>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                  
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==2" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                Request
                                                            </h4>
                                                        </div>
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <form class="w-100" [formGroup]="updateRequestForm">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Cust
                                                                                    Request Type ID
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">

                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                [options]="CustRequestTypeIDArray" 
                                                                                formControlName="CustRequestTypeID" 
                                                                                placeholder="Choose Cust Request Type ID">

                                                                                </p-dropdown>

                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="">Cust Request Category ID	

                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                 [options]="custRequestCategoryIdArray" formControlName="CustRequestCategoryID"
                                                                                  placeholder="Choose Cust Request Category ID	">

                                                                                </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Status ID	
                                                                                   
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">

                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                 [options]="StatusArray" 
                                                                                 formControlName="StatusID" 
                                                                                 placeholder="Choose Status ID	">

                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Requesting
                                                                                    Party
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label" [options]="partyIdArray"
                                                                                 formControlName="RequestingParty" placeholder="Choose Requesting Party">
                                                                                </p-dropdown>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Priority
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">

                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                 [options]="PriorityListArray" formControlName="Priority" placeholder="Choose Priority">

                                                                                </p-dropdown>

                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="">Request Date

                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control"
                                                                                 id="exampleInputEmail1" placeholder="Choose Request Date" 
                                                                                 aria-describedby="emailHelp" formControlName="RequestDate" 
                                                                                 >

                                                                            </div>


                                                                            

                                                                            
                                                                        </div>
                                                                    </div>
                                                                          
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="">Response Required
                                                                                    Date

                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control"
                                                                                 id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                                 formControlName="ResponseRequiredDate" placeholder="Enter Response Required Date">

                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Cust
                                                                                    Request Name
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control" id="exampleInputEmail1" 
                                                                                aria-describedby="emailHelp" formControlName="CustRequestName"
                                                                                 placeholder="Enter Cust
                                                                                 Request Name">


                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">


                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="">Description

                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control" id="exampleInputEmail1" 
                                                                                aria-describedby="emailHelp" formControlName="Description" 
                                                                                placeholder="Enter Description">

                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Maximum Amount Uom ID	
                                                                                   
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">

                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                [options]="currencyArray" 
                                                                                formControlName="MaximumAmountUomID"
                                                                                 placeholder="Choose Maximum Amount Uom ID ">

                                                                                </p-dropdown>

                                                                            </div>

                                                                            
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">


                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Product Store	
                                                                                   
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">

                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                 [options]="productStoreIdReportArray"
                                                                                  formControlName="ProductStore" 
                                                                                 placeholder="Choose Product Store">

                                                                                </p-dropdown>

                                                                            </div>

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Sales Channel	
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">

                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                [options]="salesChannelArray" 
                                                                                formControlName="SalesChannel"
                                                                                 placeholder="Choose Sales Channel	">

                                                                                </p-dropdown>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">


                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Fulfill Contact Mech ID	

                                                                                   
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">

                                                                                <p-dropdown filter="true" optionlabel="label"
                                                                                 [options]="ContactMechIdArray" 
                                                                                 formControlName="FulfillContactMechID"
                                                                                  placeholder="Choose Fulfill Contact Mech ID">

                                                                                </p-dropdown>

                                                                            </div>

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Currency
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">

                                                                                <p-dropdown filter="true" optionlabel="label" [options]="currencyArray" 
                                                                                formControlName="Currency" placeholder="Choose Currency">

                                                                                </p-dropdown>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">


                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="">Open Date Time	
                                                                                 
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control" 
                                                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                                 formControlName="OpenDateTime" placeholder="Enter Open Date Time">

                                                                            </div>

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="">Closed Date Time	

                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                                                 aria-describedby="emailHelp" formControlName="ClosedDateTime"
                                                                                  placeholder="Enter Closed Date Time">

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">
                                                                                    Internal Comment	
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">

                                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" formControlName="InternalComment"
                                                                                 placeholder="Enter Internal Comment">
                                                                            </div>

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Reason
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" formControlName="Reason"
                                                                                 placeholder="Enter Reason">

                                                                                

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                              
                                                                    
                                                                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">


                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="">Cust Estimated
                                                                                    Milli Seconds
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                 id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                                 formControlName="CustEstimatedMilliSeconds" 
                                                                                 placeholder="Enter Cust Estimated Milli Seconds">

                                                                            </div>

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="">Cust Sequence Num

                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control" 
                                                                                id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                                formControlName="CustSequenceNum" placeholder="Enter Cust Sequence Num">

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">


                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="">Parent Cust
                                                                                    Request ID

                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" optionlabel="label" 
                                                                                [options]="ParentCustRequestIdArray" placeholder="Choose ParentCustRequestID"
                                                                                 formControlName="ParentCustRequestID">
                                                                                </p-dropdown>

                                                                            </div>

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="">Billed
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">

                                                                                <p-dropdown filter="true" optionlabel="label" [options]="yesNOArray" 
                                                                                formControlName="Billed" placeholder="Choose Billed">

                                                                                </p-dropdown>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">


                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="">Story

                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control"
                                                                                 id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                                 formControlName="Story" placeholder="Enter Story">

                                                                            </div>

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="">Web Site ID

                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <input type="email" class="form-control" id="exampleInputEmail1" 
                                                                                aria-describedby="emailHelp" formControlName="WebSiteID" 
                                                                                placeholder="Enter Web Site ID">

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -17%;">
                                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                                         (click)="updaterequest()">Submit</button>

                                                                    </div>
                                                                    
                                                                </div>
                                                            </form>


                                                        </div>






                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==3" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                Request Roles
                                                                <button type="submit" class="btn btn-secondary submit-btn mr-2" data-toggle="modal" data-target="#requestRoles" (click)="resetRequest()">Create New Request Roles</button>

                                                            </h4>


                                                        </div>

                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">

                                                                <p-table [value]="requestRolesById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>



                                                                                    Party ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Role Type</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    From Date</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'90px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Update </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'90px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Expire Link</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'90px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Delete Link </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    {{product.partyId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.roleTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.fromDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.thruDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'90px'}">
                                                                                <button type="submit" class="btn btn-secondary "
                                                                                 data-toggle="modal" data-target="#requestRoles" 
                                                                                 (click)="updateRequest(product)">Update</button>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'90px'}">
                                                                                <button type="submit" class="btn btn-secondary " 
                                                                                (click)="expireCustRequestParty(product)">Expire</button>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'90px'}">
                                                                                <button type="submit" class="btn btn-danger buttonclass" 
                                                                                (click)="deleteCustRequestParty(product)">Delete
                                                                                </button>
                                                                            </td>


                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==4" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                Request Items
                                                                <button type="submit" class="btn btn-secondary submit-btn mr-2" data-toggle="modal" data-target="#requestItem">New
                                                                    Request Item</button>

                                                            </h4>


                                                        </div>

                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">

                                                                <p-table [value]="RequestItemData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>


                                                                                    Cust Request Item Seq ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'125px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Status ID
                                                                                </div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Priority</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Sequence Num</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Required By Date
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Product</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Product Id</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Quantity</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Selected Amount
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Maximum Amount</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Reserv Start</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Reserv Length</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Reserv Persons</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Config ID</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Description
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Story
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Add Note</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>





                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>

                                                                                    <span type="submit" style="color:#0d3769;" (click)="updateRequestItem(product)" data-toggle="modal" data-target="#updateRequestItem">
                                                                                        {{product.data.custRequestItemSeqId}}</span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.statusId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.priority | date:'yyyy-MM-d hh:mm:ss' }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.sequenceNum}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.requiredByDate}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.productId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.quantity}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.selectedAmount}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.maximumAmount}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.reservStart}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.reservLength}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.reservPersons}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.configId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.description}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.story}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="notePage(product.data.custRequestItemSeqId, product.data.custRequestId)">Add
                                                                                    Note</button>
                                                                            </td>


                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==5" class="w-100">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">
                                                                Add Content
                                                                <button type="submit" class="btn btn-secondary submit-btn mr-2" data-toggle="modal" data-target="#contentPopup">Create New Content
                                                                </button>
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="CustRequestContentById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>


                                                                                    Content ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Mime Type ID
                                                                                </div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    From Date</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Delete</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>





                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    {{product.data.contentId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.nodeName[0].mimeTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.data.fromDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}"> <button type="submit" (click)="deleteCustRequestContents(product)" class="btn btn-danger buttonclass">Delete


                                                                                </button>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==6" class="w-100">

                                                    <div class="w3-card-4 classCardView mt-2">

                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling hleft">

                                                                <button type="submit" class="btn btn-secondary submit-btn mr-2" data-toggle="modal" data-target="#notes">Create New Notes</button>

                                                            </h4>
                                                        </div>
                                                        <div class="panel-body allStyleUser">
                                                            <div class="card own-account-table borderTable">
                                                                <p-table [value]="RequestItemNote" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>


                                                                                    Cust Request Item Seq ID
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Note Info
                                                                                </div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Note Date Time</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Party ID</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Name</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>





                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    {{product.data.custRequestItemSeqId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.nodeData.noteInfo}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.nodeData.noteDateTime | date:'yyyy-MM-d hh:mm:ss' }}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.nodeData.noteName}}
                                                                            </td>


                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleRequestList" tabindex="-1" role="dialog" aria-labelledby="exampleRequestListTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Request
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                        Request
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newrequestForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Cust Request Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                         [options]="CustRequestTypeIDArray" placeholder="Choose Cust Request Name" formControlName="CustRequestTypeID">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Cust Request Category ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        [options]="custRequestCategoryIdArray" placeholder="Choose Cust Request Category ID" 
                                                        formControlName="CustRequestCategoryID">

                                                        </p-dropdown>

                                                    </div>
                                                   
                                                </div>
                                            </div>
                                           
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Requesting Party
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="partyIdArray"
                                                         placeholder="Choose Requesting Party" formControlName="RequestingParty">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Priority
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" placeholder="Choose Priority " 
                                                        [options]="PriorityListArray" formControlName="Priority">

                                                        </p-dropdown>

                                                    </div>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="productId">Request Date

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" placeholder="Enter Request Date " aria-describedby="emailHelp" formControlName="RequestDate" >

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="productId">Response Required Date

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Response Required Date" formControlName="ResponseRequiredDate" >

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Cust Request Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Choose Cust Request Name" formControlName="CustRequestName" >
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="productId">Description

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Description " aria-describedby="emailHelp" formControlName="Description" >

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Maximum Amount Uom ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                         [options]="currencyArray"
                                                         placeholder="Choose Maximum Amount Uom ID " formControlName="MaximumAmountUomID">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Product Store
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="productStoresListArray"
                                                         placeholder="Choose Product Store" formControlName="ProductStore">
                                                        </p-dropdown>

                                                    </div>

                                                   
                                                    
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Sales Channel
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                         [options]="salesChannelArray"
                                                         placeholder="Choose Sales Channel" 
                                                         formControlName="SalesChannel">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Fulfill Contact Mech ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                         [options]="ContactMechIdArray" placeholder="Choose Fulfill Contact Mech ID "
                                                          formControlName="FulfillContactMechID">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                          


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="productId">Currency
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                         [options]="currencyArray" placeholder="Choose Currency"
                                                          formControlName="Currency">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="productId">Open Date Time

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Open Date Time" 
                                                        formControlName="OpenDateTime" >

                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="productId">Closed Date Time

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" 
                                                        placeholder="Enter Closed Date Time" aria-describedby="emailHelp" 
                                                        formControlName="ClosedDateTime">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="productId">Internal Comment

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" 
                                                        id="exampleInputEmail1" placeholder="Enter Internal Comment"
                                                         aria-describedby="emailHelp" formControlName="InternalComment">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="productId">Reason

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Reason" 
                                                        formControlName="Reason" >

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="productId">Cust Estimated Milli Seconds
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter  Cust Estimated Milli Seconds" 
                                                        formControlName="CustEstimatedMilliSeconds" >
                                                    </div>

                                                   
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="productId">Cust Sequence Num

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Cust Sequence Num" 
                                                        formControlName="CustSequenceNum" >

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="productId">Parent Cust Request ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        [options]="ParentCustRequestIdArray" placeholder="Choose ParentCustRequestID"
                                                         formControlName="ParentCustRequestID">

                                                        </p-dropdown>


                                                    </div>

                                                   
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Billed
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label"
                                                         placeholder="Choose Billed" [options]="yesNOArray" formControlName="Billed">

                                                        </p-dropdown>

                                                    </div>


                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="productId">Story

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Story" aria-describedby="emailHelp" formControlName="Story" >

                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="productId">Web Site ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Web Site ID" aria-describedby="emailHelp" formControlName="WebSiteID">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -27%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                 (click)="createRequest()">Submit</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 
<!-- 
<div class="modal fade" id="newrequestList" tabindex="-1" role="dialog" aria-labelledby="newrequestListTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Request
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Request
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newrequestForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Requesting Party
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="partyIdArray" placeholder="Choose Requesting Party" formControlName="RequestingParty">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Cust Request Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Choose Cust Request Name" formControlName="CustRequestName" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Cust Request Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="CustRequestTypeIDArray" placeholder="Choose Cust Request Name" formControlName="CustRequestTypeID">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Short Description

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Short Description " 
                                                        formControlName="ShortDescription" >

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Description

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Description " aria-describedby="emailHelp" formControlName="Description" >

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Priority
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" placeholder="Choose Priority " [options]="PriorityListArray" formControlName="Priority">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Project and Phase Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="ProjectAndPhaseNameArray" placeholder="Choose Project and Phase Name " formControlName="ProjectandPhaseName">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="PartyIdArray" placeholder="Choose Party " formControlName="Party">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Request Date

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" placeholder="Enter Request Date " aria-describedby="emailHelp" formControlName="RequestDate" >

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Response Required Date

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Response Required Date" formControlName="ResponseRequiredDate" >

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Cust Estimated Milli Seconds
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Cust Estimated Milli Seconds" formControlName="CustEstimatedMilliSeconds" >

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Cust Sequence Num

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Cust Sequence Num" formControlName="CustSequenceNum" >

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Parent Cust Request ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="ParentCustRequestIdArray" placeholder="Choose ParentCustRequestID" formControlName="ParentCustRequestID">

                                                        </p-dropdown>


                                                    </div>

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Billed
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" placeholder="Choose Billed" [options]="yesNOArray" formControlName="Billed">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Story

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Story" aria-describedby="emailHelp" formControlName="Story" >

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Web Site ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Enter Web Site ID" aria-describedby="emailHelp" formControlName="WebSiteID">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -17%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                 (click)="createRequest()">Submit</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="modal fade" id="requestRoles" tabindex="-1" role="dialog" aria-labelledby="requestRolesTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Request Roles
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Request Roles
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="requestRoleForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAC">
                                                        <label for="exampleInputEmail1">Party
                                                            ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="PartyID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party ID" [options]="partyIdArray" filter="true" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Role
                                                            Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="RoleType" id="exampleInputEmail1" aria-describedby="emailHelp" [options]="RoleTypeIdArray" placeholder="Enter Role Type" filter="true" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAC">
                                                        <label for="productId">From Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="FromDate" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="productId">Through
                                                            Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ThroughDate" placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12  main-submit-button" style="margin-left: -21%;">
                                                <button type="submit" *ngIf="!this.showRequest" class="btn btn-secondary submit-btn" (click)="createCustRequestParty()">Create</button>
                                                <button type="submit" *ngIf="this.showRequest" class="btn btn-secondary submit-btn" (click)="updateCustRequestParty()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="notes" tabindex="-1" role="dialog" aria-labelledby="notes" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Notes</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Notes</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="noteForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputN">
                                                        <label for="productId">Note


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="Note" placeholder="Enter Note">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12  main-submit-button" style="margin-left: -76%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createRequestItemNote()">Submit</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="requestItem" tabindex="-1" role="dialog" aria-labelledby="requestItemTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Request Item
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Request Item
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="requestItemForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Status ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="StatusID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Status ID" [options]="StatusIdArray" filter="true" optionlabel="label">
                                                        </p-dropdown>



                                                    </div>

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Priority


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="Priority" id="exampleInputEmail1" aria-describedby="emailHelp" [options]="PriorityListArray" placeholder="Enter Priority" filter="true" optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="productId">Sequence Num


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="SequenceNum" placeholder="Enter Sequence Num">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="productId">Required By Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="RequiredByDate" placeholder="Enter Required By Date">

                                                    </div>

                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Product ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="ProductID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product ID" [options]="ProductIdArray" filter="true" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="productId">Quantity


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="Quantity" placeholder="Enter Quantity">

                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="productId">Selected Amount


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="SelectedAmount" placeholder="Enter Selected Amount ">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="productId">Maximum Amount


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="MaximumAmount" placeholder="Enter Maximum Amount">

                                                    </div>

                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="productId">Reserv Start


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ReservStart" placeholder="Enter Reserv Start">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="productId">Reserv Length


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ReservLength" placeholder="Enter Reserv Length">

                                                    </div>

                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="productId">Reserv Persons


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ReservPersons" placeholder="Enter Reserv Persons">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="productId">Config ID


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ConfigID" placeholder="Enter Config ID">

                                                    </div>

                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="productId">Description


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="Description" placeholder="Enter Description">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="productId">Story


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="Story" placeholder="Enter Story">

                                                    </div>

                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12  main-submit-button" style="margin-left: -19%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createrequestitem()">Create</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updateRequestItem" tabindex="-1" role="dialog" aria-labelledby="updateRequestItemTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Edit Request Item
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                        Edit Request Item
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateRequestItemForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="exampleInputEmail1">Status ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="StatusID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Status ID" [options]="StatusIdArray" filter="true" optionlabel="label">
                                                        </p-dropdown>



                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="exampleInputEmail1">Priority


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown formControlName="Priority" id="exampleInputEmail1" aria-describedby="emailHelp" [options]="PriorityListArray" placeholder="Enter Priority" filter="true" optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="productId">Sequence Num


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="SequenceNum" placeholder="Enter Sequence Num">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="productId">Required By Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="RequiredByDate" placeholder="Enter Required By Date">

                                                    </div>

                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="exampleInputEmail1">Product ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown formControlName="ProductID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product ID" [options]="ProductIdArray" filter="true" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="productId">Quantity


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="Quantity" placeholder="Enter Quantity">

                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="productId">Selected Amount


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="SelectedAmount" placeholder="Enter Selected Amount ">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="productId">Maximum Amount


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="MaximumAmount" placeholder="Enter Maximum Amount">

                                                    </div>

                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="productId">Reserv Start


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ReservStart" placeholder="Enter Reserv Start">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="productId">Reserv Length


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ReservLength" placeholder="Enter Reserv Length">

                                                    </div>

                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="productId">Reserv Persons


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ReservPersons" placeholder="Enter Reserv Persons">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="productId">Config ID


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ConfigID" placeholder="Enter Config ID">

                                                    </div>

                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="productId">Description


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="Description" placeholder="Enter Description">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="productId">Story


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="Story" placeholder="Enter Story">

                                                    </div>

                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12  main-submit-button" style="margin-left: -17%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="updaterequestitem()">Submit</button>

                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm1">
                                                        <label for="productId">Duplicate Request Item


                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2"> Quote Items
                                                        <input type="checkbox">

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12  main-submit-button" style="margin-left: -66%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="copyCustRequestItem()">Copy</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="contentPopup" tabindex="-1" role="dialog" aria-labelledby="contentPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Task Content</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Task Content</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="AddTaskContentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Existing Content
                                                            ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="existingContentIdListArray" formControlName="ExistingContent" optionlabel="label" placeholder=" Enter Existing Content ID">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">
                                                            Content Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="contentTypeIdArray" 
                                                        formControlName="WorkEffortContentTypeID" optionlabel="label" placeholder="Choose Content Type ID">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Status Id
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="StatusId" optionlabel="label" 
                                                        placeholder="Choose Status">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Upload
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="file" class="form-control" id="exampleInputEmail1" (change)="fileProgressCom($event)" formControlName="Upload" aria-describedby="emailHelp" placeholder=" Enter Upload">
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Parent Content ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="parentContentIdArray" formControlName="ParentContent" optionlabel="label" placeholder="Choose Parent Content ID  ">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div class="col-lg-12  main-submit-button" style="margin-left: -19%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="uploadFormContent()">Create</button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>