<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels"> Address Match Map</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="w3-container">
                        <div class="header-tabs px-2" style="background-color: #cce0e95e !important;">
                            <h4 class=" common-styling">
                                Address Match Map
                            </h4>
                            <span class="px-2 edit-buttons" style="float: left !important;cursor: pointer;">
                                <a  
                                data-toggle="modal" data-target="#exampleModalCenter" *ngIf="!this.Hidebtn">&nbsp; &nbsp;Create</a>|<a (click)="import()" *ngIf="!this.Hidebtn">&nbsp;&nbsp;Import</a>|<a (click)="clearAddressMatchMap()" *ngIf="!this.Hidebtn">&nbsp; &nbsp; Clear All</a></span>
                        </div>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div *ngIf="activeCategory==1">

                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">First Name</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" [(ngModel)]="advanceSearch.firstName" [ngModelOptions]="{standalone:true}" name="firstName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                                    </div>


                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Last Name</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" [(ngModel)]="advanceSearch.lastName" [ngModelOptions]="{standalone:true}" name="lastName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Address 1</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" [(ngModel)]="advanceSearch.address1" [ngModelOptions]="{standalone:true}" name="address1" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address 1">
                                                                    </div>


                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">Address 2</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" [(ngModel)]="advanceSearch.address2" [ngModelOptions]="{standalone:true}" name="address2" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address 2">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">City</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" [(ngModel)]="advanceSearch.city" [ngModelOptions]="{standalone:true}" name="city" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter City">
                                                                    </div>


                                                                    <div class="col-lg-3 form-group rightFormA">
                                                                        <label for="exampleInputEmail1">State</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="stateArray" [(ngModel)]="advanceSearch.stateProvinceGeoId" [ngModelOptions]="{standalone:true}" name="stateProvinceGeoId" filter="true" placeholder="Enter State/Province">
                                                                        </p-dropdown>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightForm">
                                                                        <label for="exampleInputEmail1">Zip/Postal Code</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="text" [(ngModel)]="advanceSearch.postalCode" [ngModelOptions]="{standalone:true}" name="postalCode" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Zip/Postal Code">
                                                                    </div>





                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -30%;">
                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearchDeposit(false)">Find</button>&nbsp;
                                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>&nbsp;

                                                        </div>
                                                        <div>
                                                            <header class="w3-container">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Search Results
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="AddressMatchMap" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Match key
                                                                                                <p-sortIcon field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                =>
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Match value
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Sequence
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Action
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span class="account-button summary" (click)="detailAddress(product.data.mapKey,product.data.mapValue,product.data.sequenceNum)" >{{product.data.mapKey}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            =>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            {{product.data.mapValue}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            {{product.data.sequenceNum}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" (click)="removeAddressMatchMap(product.data.mapKey,product.data.mapValue)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                    <defs>
                                                                                      <style>
                                                                                        .a {
                                                                                          fill: #f44336;
                                                                                        }
                                  
                                                                                        .b {
                                                                                          fill: #fafafa;
                                                                                          margin-left: 20px;
                                                                                        }
                                                                                      </style>
                                                                                    </defs>
                                                                                    <path class="a fill-color"
                                                                                      d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                    <path class="b"
                                                                                      d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                      transform="translate(-147.576 -147.576)" />
                                                                                  </svg>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">No
                                                                                                    Record Found
                                                                                                </h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Add New Address Match Map</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                        Add New Address Match Map
                                    </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                        Update New Address Match Map
                                    </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createClassification">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Match Key</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Match Key" formControlName="matchKey">
                                                    </div>


                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Match Value</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Match Value" formControlName="matchValue">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Sequence</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sequence" formControlName="sequenceNum">
                                                    </div>




                                                </div>
                                            </div>


                                            <!--  <div class="col-lg-4 col-12">
                                                <div class="form-group">
                                                    <label for="exampleInputEmail1">Choose File</label>
                                                    <input type="file" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Choose File"
                                                        formControlName="Justification">
                                                </div>
                                            </div> -->
                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 76%;">
                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="onSubmit()">Save</button>&nbsp;
                                        <button type="submit" *ngIf="this.show" class="btn btn-secondary submit-btn">Update</button>&nbsp;
                                    </div>
                                    <br>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>