import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/catalog-mgmt/new-products/product.service';
import { FeaturesService } from '../../features.service';
import { Location } from '@angular/common';
@Component({
    selector: 'app-create-edit-feature-maintenance',
    templateUrl: './create-edit-feature-maintenance.component.html'
})
export class CreateEditFeatureMaintenanceComponent implements OnInit {
    productFeatureForm: FormGroup;
    featureTypeIds: any[];
    featureCategories: any[];
    editMode: boolean;
    featureMaintenanceId: string;
    productUOMIds: any[];
    featureMaintenanceData: any;
    id: any;
    productFeatureCategoryId: string;
    url: string;

    constructor(
        readonly _FormBuilder: FormBuilder,
        readonly _FeaturesService: FeaturesService,  
        readonly _ToastrService: ToastrService,
        readonly  _Router: Router,
        readonly spinner: NgxSpinnerService, 
        private _location: Location,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _ProductService: ProductService
    ) {
        this.url='/facilities/catalog/feature-category/detailed-summary-feature-category'
        this.productFeatureForm = this._FormBuilder.group({
            abbrev: [''],
            defaultAmount: [0],
            defaultSequenceNum: [0],
            description: ['', [Validators.required]],
            idCode: [''],
            numberSpecified: [0],
            productFeatureCategoryId: [''],
            productFeatureTypeId: ['', [Validators.required]],
            uomId: ['']
        });
        this.featureTypeIds = [];
        this.featureCategories = [];
        this.editMode = false;
        this.featureMaintenanceId = '';
        this.productUOMIds = [];
        this.productFeatureCategoryId = '';
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.featureMaintenanceId = this._ActivatedRoute.snapshot.queryParams.featureMaintenanceId;
        this.id = this._ActivatedRoute.snapshot.queryParams.id;

        if (this.featureMaintenanceId && this.id) {
            this.editMode = true;
            this.getFeatureMaintenance();
        }
        else {
            setTimeout(() => {
                const formValue = this.productFeatureForm;
                formValue.patchValue({
                    productFeatureCategoryId: this.featureMaintenanceId

                })
            }, 3000);
        }
        this.parentType();
        this.getCategories();
        this.getUOMId();
    }
    getFeatureMaintenance(): void {
        this.spinner.show();
        if (this.id) {
            this._FeaturesService.getFeatureMaintenance(this.id)
                .then(data => {
                    this.spinner.hide();
                    this.featureMaintenanceData = data.data;
                    this.productFeatureForm.patchValue({
                        ...data.data, ...{
                            numberSpecified: data.data.quantity,
                            defaultSequenceNum: data.data.IDSeqNum
                        }
                    });
                   
                });
        }
       
    }
    homeButton(){
        this._Router.navigate(['/facilities/Dashboard'])
      
      }
      cancelSubmit(){
        this._location.back();
      }
      
     
    
    parentType(): void {
        this.spinner.show();
        this._FeaturesService.getProductParentType()
            .then(data => {
                this.spinner.hide();
                this.featureTypeIds = data.data.parentTypes.map(value => {
                    return {
                        label: value.description,
                        value: value.productFeatureTypeId
                    }
                });
            });
           
    }
    getCategories(): void {
        this.spinner.show();
        this._FeaturesService.getFeatureCategories({ pageNo: 1, pageSize: 100 }, '')
            .then(data => {
                this.spinner.hide();
                this.featureCategories = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.parentCategoryId
                    };
                });
            });
           
    }
    getUOMId(): void {
        this.spinner.show();
        this._ProductService.getProductMenu()
            .then(data => {
                this.spinner.hide();
                this.productUOMIds = data.data.quantityUomID.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
            });
           
    }
    submit(): void {
        this.spinner.show();
        if (this.productFeatureForm.valid) {
            if (this.editMode) {
                this._FeaturesService.updateFeatureMaintenance({
                    ...this.productFeatureForm.value
                }, this.id)
                    .then(data => {
                        if (data.success) {
                            this.productFeatureCategoryId = this.productFeatureForm.get('productFeatureCategoryId').value;
                            this._ToastrService.success('Updated');
                            this.spinner.hide();
                            this.productFeatureForm.reset();
                            this.productFeatureForm.controls.productFeatureTypeId.enable();
                            this.productFeatureForm.controls.productFeatureTypeId.updateValueAndValidity();
                            this._Router.navigate([this.url], { queryParams: { featureId: this.productFeatureCategoryId ? this.productFeatureCategoryId : this.featureMaintenanceId } });
                        }
                    });
                   
            } else { this._FeaturesService.createFeatureMaintenance(this.productFeatureForm.value)
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.spinner.hide();
                            this.productFeatureCategoryId = this.productFeatureForm.get('productFeatureCategoryId').value;
                            this.productFeatureForm.reset();
                            this._Router.navigate([this.url], { queryParams: { featureId: this.productFeatureCategoryId ? this.productFeatureCategoryId : this.featureMaintenanceId } });
                        } });} } else {
            this.spinner.hide();
            this._ToastrService.error('Please fill required fields');} }
    reset(): void {
        this.productFeatureForm.reset();
        this._Router.navigate([this.url], { queryParams: { featureId: this.featureMaintenanceId } });
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}