import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-subscriptions-catalog',
  templateUrl: './subscriptions-catalog.component.html',
  styleUrls: ['./subscriptions-catalog.component.css']
})
export class SubscriptionsCatalogComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  activeCat=13;
  pageSize = 100;
  pageNo = 1;
  findSubscription: { subscriptionID: string; description: string; subscriptionResourceID: string; partyID: string; orderID: string; orderItemSeqID: string; productID: string; subscriptionType: string; externalSubscriptionID: string; fromDate: string; throughDate: string; automaticExtend: string; gracePeriodExpiry: string; gracePeriodExpiryUomID: string; expirationCompletionDate: string; };
  newSubscription: any;
  orderById: any;
  orderByIdArray: any[] = [];
  ProductId: any;
  ProductIdArray: any[] = [];
  subscriptionTypeArray: any[] = [];
  subscriptionType: any;
  subscriptionResourceId: any;
  subscriptionResourceIdArray: any[] = [];
  personId: any;
  partyIdArray: any[] = [];
  yesNoArray: { label: string; value: string; }[];
  findSubscriptionList: any;
  OriginatedFromRoleTypeId: any;
  OriginatedFromRoleTypeIdArray: any[]=[];
  useTimeUom: any;
  useTimeUomArray: any[]=[];
  productCategory: any;
  productCategoryArray: any[]=[];
  RoleTypeId: any;
  RoleTypeIdArray: any[]=[];
  currencyArray: any[]=[];
  ContactMechId: any;
  ContactMechIdArray: any[]=[];
  inventoryItemId: any;
  inventoryItemIdArray: any[]=[];
  CommunicationEventId: any;
  CommunicationEventIdArray: any[]=[];
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,    private _location: Location,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
    this.findSubscription = {
      subscriptionID: "",
      description: "",
      subscriptionResourceID: "",
      partyID: "",
      orderID: "",
      orderItemSeqID: "",
      productID: "",
      subscriptionType: "",
      externalSubscriptionID: "",
      fromDate: "",
      throughDate: "",
      automaticExtend: "",
      gracePeriodExpiry: "",
      gracePeriodExpiryUomID: "",
      expirationCompletionDate: "",
    };
    this.newSubscription = this._FormBuilder.group({
      description: [''],
      subsResourceID: [''],
      commEventID: [''],
      contactMechID: [''],
      originPartyID: [''],
      originRoleTypeID: [''],
      partyID: [''],
      roleTypeID: [''],
      partyNeedID: [''],
      needTypeID: [''],
      orderID: [''],
      orderItemSeqID: [''],
      productID: [''],
      productCategoryID: [''],
      inventoryItemID: [''],
      subsType: [''],
      extSubsID: [''],
      fromDate: [''],
      throughDate: [''],
      purchaseFromDate: [''],
      purchaseThroughDate: [''],
      maxLifeTime: [''],
      maxUomID: [''],
      availTime: [''],
      availUomID: [''],
      countLimit: [''],
      useTime: [''],
      useTimeUom: [''],
      autoExtend: [''],
      cancelTime: [''],
      cancelUomTime: [''],
      gracePeriodExpiry: [''],
      gracePeriodUomID: [''],
      expCompletedDate: [''],
    })
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this.getOrderById();
    this.getProductIds();
    this.getSubscriptionType();
    this.getSubscriptionResourceId();
    this.getPartyId();
    this.getfindSubscription();
    this.getOriginatedFromRoleTypeId();
    this.getUseTimeUom();
    this.getProductCategorySubscription();
    this.getUseRoleTypeId();
    this.getCurrencyValue();
    this.getContactmechID();
    this.getUseRoleTypeId();
    this.getInventoryItemId();
    this.getCommunicationEventId();
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getCommunicationEventId() {
    this.spinner.show();
    this.myContactsService.getCommunicationEventIdSubs().subscribe(res => {
      this.CommunicationEventId = res.data[0].communicationEventId;
      this.spinner.hide();
      for (const value of this.CommunicationEventId) {
        this.CommunicationEventIdArray.push({
          label: value.communicationEventId,
          value: value.communicationEventId
        })
      }
    })
  }
  getContactmechID() {
    this.spinner.show();
    this.myContactsService.getContactmechID().subscribe(res => {
      this.ContactMechId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContactMechId) {
        this.ContactMechIdArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
   
  }
  getInventoryItemId() {
    this.spinner.show();
    this.myContactsService.getInventoryItemId().subscribe(res => {
      this.inventoryItemId = res.data.getInventoryItemId;
      this.spinner.hide();
      for (const value of this.inventoryItemId) {
        this.inventoryItemIdArray.push({
          label: value.inventoryItemId,
          value: value.inventoryItemId
        })
      }
    })
  }
  getCurrencyValue() {
    this.spinner.show();
    this.myContactsService.getCurrencyValue().subscribe(res => {
      const currencyList = res.data.currencyList;
      this.spinner.hide();
      for (const value of currencyList) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })

  }
  detailSubsPage(subscriptionId){
    this._Router.navigate(["/facilities/catalog/subscriptions/editSubscriptions"],{queryParams:{subscriptionId:subscriptionId}})
  }
  detailPage(subscriptionResourceId){
    this._Router.navigate(["/facilities/catalog/subscriptions/detail-subscription-resource"],{queryParams:{subscriptionResourceId:subscriptionResourceId}})
  }
  getUseRoleTypeId() {
    this.spinner.show();
    this.myContactsService.getUseRoleTypeId().subscribe(res => {
      this.RoleTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.RoleTypeId) {
        this.RoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  createSubscription(): void {
    this.spinner.show();
    const requestData = {
    
      "automaticExtend": this.newSubscription.value.autoExtend,
  "availableTime": this.newSubscription.value.availTime,
  "availableTimeUomId": this.newSubscription.value.availUomID,
  "canclAutmExtTime": this.newSubscription.value.cancelTime,
  "canclAutmExtTimeUomId": this.newSubscription.value.cancelUomTime,
  "communicationEventId": this.newSubscription.value.commEventID,
  "contactMechId": this.newSubscription.value.contactMechID,
  "description": this.newSubscription.value.description,
  "expirationCompletedDate": this.datePipe.transform(this.newSubscription.value.expCompletedDate,"yyyy-MM-dd HH:mm:ss") ,
  "expirationCompletedDate_i18n": this.datePipe.transform(this.newSubscription.value.expCompletedDate,"yyyy-MM-dd HH:mm:ss") ,
  "externalSubscriptionId": this.newSubscription.value.extSubsID,
  "fromDate":this.datePipe.transform(this.newSubscription.value.fromDate,"yyyy-MM-dd HH:mm:ss") ,
  "gracePeriodOnExpiry": this.newSubscription.value.gracePeriodExpiry ,
  "gracePeriodOnExpiryUomId": this.newSubscription.value.gracePeriodUomID ,
  "inventoryItemId": this.newSubscription.value.inventoryItemID,
  "maxLifeTime": this.newSubscription.value.maxLifeTime,
  "maxLifeTimeUomId": this.newSubscription.value.maxUomID,
  "needTypeId": "",
  "orderId": this.newSubscription.value.orderID,
  "orderItemSeqId": this.newSubscription.value.orderItemSeqID,
  "originatedFromPartyId": this.newSubscription.value.originPartyID,
  "originatedFromRoleTypeId": this.newSubscription.value.originRoleTypeID,
  "partyId": this.newSubscription.value.partyID,
  "partyNeedId": this.newSubscription.value.partyNeedID,
  "productCategoryId": this.newSubscription.value.productCategoryID,
  "productId": this.newSubscription.value.productID,
  "purchaseFromDate": this.datePipe.transform(this.newSubscription.value.purchaseFromDate,"yyyy-MM-dd HH:mm:ss") ,
  "purchaseThruDate": this.datePipe.transform(this.newSubscription.value.purchaseThroughDate,"yyyy-MM-dd HH:mm:ss") ,
  "roleTypeId":this.newSubscription.value.roleTypeID,
  "subscriptionResourceId": this.newSubscription.value.subsResourceID,
  "subscriptionTypeId": this.newSubscription.value.subsType,
  "thruDate":this.datePipe.transform(this.newSubscription.value.throughDate,"yyyy-MM-dd HH:mm:ss") ,
  "useCountLimit": this.newSubscription.value.countLimit,
  "useTime": this.newSubscription.value.useTime ,
  "useTimeUomId": this.newSubscription.value.useTimeUom
     
    }
    this.myContactsService.createSubscription(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.newSubscription.reset();
        this.getfindSubscription();
       
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getfindSubscription() {
    this.spinner.show();
    let req = {
      "automaticExtend": "",
      "description": "",
      "expirationCompletedDate": "",
      "externalSubscriptionId": "",
      "fromDate": "",
      "gracePeriodOnExpiry": "",
      "gracePeriodOnExpiryUomId": "",
      "orderId": "",
      "orderItemSeqId": "",
      "partyId": "",
      "productId": "",
      "subscriptionId": "",
      "subscriptionResourceId": "",
      "subscriptionType": "",
      "throughDate": ""
    }
    this.myContactsService.findSubscription(req).subscribe((res: any) => {
      this.findSubscriptionList = res.data;
      this.spinner.hide();
    })

  }
  find(): void {
    this.spinner.show();
    this.findSubscriptionList = [];
    const req = {

      "configItemId": "" ? "" : '',
      "automaticExtend": this.findSubscription.automaticExtend ? this.findSubscription.automaticExtend : '',
      "description": this.findSubscription.description ? this.findSubscription.description : '',
      "expirationCompletedDate": this.findSubscription.expirationCompletionDate ? this.findSubscription.expirationCompletionDate : '',
      "externalSubscriptionId": this.findSubscription.externalSubscriptionID ? this.findSubscription.externalSubscriptionID : '',
      "fromDate": this.findSubscription.fromDate ? this.findSubscription.fromDate : '',
      "gracePeriodOnExpiry": this.findSubscription.gracePeriodExpiry ? this.findSubscription.gracePeriodExpiry : '',
      "gracePeriodOnExpiryUomId": this.findSubscription.gracePeriodExpiryUomID ? this.findSubscription.gracePeriodExpiryUomID : '',
      "orderId": this.findSubscription.orderID ? this.findSubscription.orderID : '',
      "orderItemSeqId": this.findSubscription.orderItemSeqID ? this.findSubscription.orderItemSeqID : '',
      "partyId": this.findSubscription.partyID ? this.findSubscription.partyID : '',
      "productId": this.findSubscription.productID ? this.findSubscription.productID : '',
      "subscriptionId": this.findSubscription.subscriptionID ? this.findSubscription.subscriptionID : '',
      "subscriptionResourceId": this.findSubscription.subscriptionResourceID ? this.findSubscription.subscriptionResourceID : '',
      "subscriptionType": this.findSubscription.subscriptionType ? this.findSubscription.subscriptionType : '',
      "throughDate": this.findSubscription.throughDate ? this.findSubscription.throughDate : '',
    }
    this.myContactsService.findSubscription(req).subscribe(resp => {
      if (resp.success) {
        this.spinner.hide();
        this.findSubscriptionList = resp.data;
      }
    });

  }
  reset() {
    this.spinner.show();
    this.findSubscription.subscriptionID = "";
    this.findSubscription.description = "";
    this.findSubscription.subscriptionResourceID = "";
    this.findSubscription.partyID = "";
    this.findSubscription.orderID = "";
    this.findSubscription.orderItemSeqID = "";
    this.findSubscription.productID = "";
    this.findSubscription.subscriptionType = "";
    this.findSubscription.externalSubscriptionID = "";
    this.findSubscription.fromDate = "";
    this.findSubscription.throughDate = "";
    this.findSubscription.automaticExtend = "";
    this.findSubscription.gracePeriodExpiry = "";
    this.findSubscription.gracePeriodExpiryUomID = "";
    this.findSubscription.expirationCompletionDate = "";

    let req = {
      "automaticExtend": "",
      "description": "",
      "expirationCompletedDate": "",
      "externalSubscriptionId": "",
      "fromDate": "",
      "gracePeriodOnExpiry": "",
      "gracePeriodOnExpiryUomId": "",
      "orderId": "",
      "orderItemSeqId": "",
      "partyId": "",
      "productId": "",
      "subscriptionId": "",
      "subscriptionResourceId": "",
      "subscriptionType": "",
      "throughDate": ""

    }
    this.myContactsService.findSubscription(req).subscribe((res: any) => {
      this.findSubscriptionList = res.data;
      this.spinner.hide();
    })

  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  getProductIds() {
    this.spinner.show();
    this.myContactsService.getProductIds().subscribe(res => {
      this.ProductId = res.data[0].getProductId;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.internalName,
          value: value.productId 
        })
      }
    })
  }
  getUseTimeUom() {
    this.spinner.show();
    this.myContactsService.getUseTimeUom().subscribe(res => {
      this.useTimeUom = res.data[0].data;
      this.spinner.hide();
      for (const value of this.useTimeUom) {
        this.useTimeUomArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })

  }
  getProductCategorySubscription() {
    this.spinner.show();
    this.myContactsService.getProductCategorySubscription().subscribe(res => {
      this.productCategory = res.data;
      this.spinner.hide();
      for (const value of this.productCategory) {
        this.productCategoryArray.push({
          label: value.data.productCategoryTypeId,
          value: value.data.primaryParentCategoryId
        })
      }
    })
  }
  getOriginatedFromRoleTypeId() {
    this.spinner.show();
    this.myContactsService.getOriginatedFromRoleTypeId().subscribe(res => {
      this.OriginatedFromRoleTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.OriginatedFromRoleTypeId) {
        this.OriginatedFromRoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })

  }
  getSubscriptionType() {
    this.spinner.show();
    this.myContactsService.getSubscriptionType().subscribe(res => {
      this.subscriptionType = res.data[0].subscriptionType;
      this.spinner.hide();
      for (const value of this.subscriptionType) {
        this.subscriptionTypeArray.push({
          label: value.description,
          value: value.subscriptionTypeId
        })
      }
    })

  }
  getSubscriptionResourceId() {
    this.spinner.show();
    this.myContactsService.getSubscriptionResourceId().subscribe(res => {
      this.subscriptionResourceId = res.data;
      this.spinner.hide();
      for (const value of this.subscriptionResourceId) {
        this.subscriptionResourceIdArray.push({
          label: value.description,
          value: value.subscriptionResourceId
        })
      }
    })

  }

  getOrderById() {
    this.spinner.show();
    this.myContactsService.getOrderById().subscribe(res => {
      this.orderById = res.data[0].orderHeader;
      this.spinner.hide();
      for (const value of this.orderById) {
        this.orderByIdArray.push({
          label: value.orderId,
          value: value.orderId
        })
      }
    })

  }
  subResource() {
    this._Router.navigate(["/facilities/catalog/subscriptions/suscription-resource"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  mainOpen(){
    this.router.navigate(['/facilities/catalog/main-catalog']);
  }
  catalogOpen(){
    this.router.navigate(['/facilities/catalog/catalogs']);
  }
  categoriesOpen(){
    this.router.navigate(['/facilities/catalog/catagories']);
  }
  productsOpen(){
    this.router.navigate(['/facilities/catalog/products']);
  }
  featuresOpen(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  promosOpen(){
    this.router.navigate(['/facilities/catalog/promos']);
  }
  priceRulesOpen(){
    this.router.navigate(['/facilities/catalog/price-rules']);
  }
  storesOpen(){
    this.router.navigate(['/facilities/catalog/stores']);
  }
  productStoreGroupsOpen(){
    this.router.navigate(['/facilities/catalog/product-store-groups']);
  }
  thesaurusOpen(){
    this.router.navigate(['/facilities/catalog/thesaurus']);
  }
  reviewsOpen(){
    this.router.navigate(['/facilities/catalog/reviews']);
  }
  configurationsOpen(){
    this.router.navigate(['/facilities/catalog/configurations']);
  }
  subscriptionsOpen(){
    this.router.navigate(['/facilities/catalog/subscriptions']);
  }
  shippingOpen(){
    this.router.navigate(['/facilities/catalog/shipment']);
  }
  imageManagementOpen(){
    this.router.navigate(['/facilities/catalog/image-management']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
