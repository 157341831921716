import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class DetaledLeadService {

  constructor(readonly http: HttpClient) { }
  getLeadNotesList(id):Observable<any>{
    const getContactNotes = URLS.getContactNotes.replace(":partyId",id);
    return this.http.get(getContactNotes);
  }
  createLeadSource(formData):Observable<any>{
   
    const createLeadSource = URLS.createLeadSource;
    return this.http.post(createLeadSource,formData);
  }
  downloadView(id): Observable<any> {
    const getDownload = URLS.downloadPartyContent  + "?contentId=" + id
    return this.http.get(getDownload,{responseType:'blob'}) 
   }
  getPartyContentList(id):Observable<any>{
    const getPartyContent = URLS.getPartyContentLists.replace(":partyId",id);
    return this.http.get(getPartyContent);
  }
  attachPartyContent(formData,partyContentTypeId,partyId):Observable<any>{
    const getPartyContent = URLS.attachPartyContent + "?partyContentTypeId=" + partyContentTypeId + "&partyId=" + partyId;
    return this.http.post(getPartyContent,formData);
  }
  getParty():Observable<any>{
    const getPartyContent = URLS.partyContentDropdown;
    return this.http.get(getPartyContent);
  }
  getLeadSourceList(id):Observable<any> {
    const getLeadSourceList = URLS.getLeadSourceList + "?partyId=" + id;
    return this.http.get(getLeadSourceList);
  }
  getLeadSourceData():Observable<any>{
    const getLeadSource = URLS.getLeadSource;
    return this.http.get(getLeadSource);
  }
  getLeadSource():Observable<any> {
    const getLeadSource = URLS.getLeads;
    return this.http.get(getLeadSource);
  }
   getLeadDetail(id) :Observable<any> {
    const leadDetails = URLS.getLeadDetails.replace(':personId',id);
    return this.http.get(leadDetails)
   }
  convertedlead(partyGroupId,partyId){
    const convertedLead = URLS.convertLead + '?partyGroupId=' + partyGroupId + "&partyId=" + partyId;
    return this.http.post(convertedLead,null);
  }
   getOpportunityLeadDetail(id) :Observable<any>{
    const getOppurtunityDto = URLS.getOppurtunityDto.replace(':oppourtunityId',id);
     return this.http.get(getOppurtunityDto)
   }
 

}
