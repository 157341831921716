import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { FeaturesService } from '../features.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner'; import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-feature-interaction',
  templateUrl: './new-feature-interaction.component.html',
  styleUrls: ['./new-feature-interaction.component.css']
})
export class NewFeatureInteractionComponent implements OnInit {
  products3: any;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;

  total=0;
  activeCategorytab=2;

  activeCat=5;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  activeCategoryMain=4;
  featureCategories: any[];
  opportunityIdArray: any[];
  searchFilter: FormGroup;
  productFeatureId:string;
  productFeatureIdTo:string;
  interactionType:string;

  addFeaturePriceForm:FormGroup;
  updateFeatureForm:FormGroup;
  createInformationForm:FormGroup;
  priceType:string;
  fromDate:string;
  thorughDate:string;
  price:string;
  currency:string;
  supplier:string;
  description:string;
  idCode:string;
  featureTypes:string;
  featureCategorys:string;
  measuereID:string;
  numberQuantity:string;
  DefaultAmount:string;
  defaultSequenceNumber:string;
  abbreviation:string;


  activeCategory = 2;
  featureCategoryForm: FormGroup;
  featureCategoryId: string;
  editMode: boolean;
  parentCategory: any[];
  featureCategory: any;
  url: string;
  productPriceTypeById: any;
  productPriceTypeByIdArray: any[]=[];
  getFeaturePriceData: any;
  show: boolean;
  currencyArray: any[]=[];
  readOnlyCode=false;
  fromDateCatePatch: string;
  throughDateCatePatch: string;
  updateFeaturePricePat: any;
  getSupplierProductFeatureById: any;
  updateSupplierProductFeaturePat: any;
  supplierList: any;
  supplierListArray: any[]=[];
  getProductFeatureByIdAll: any;
  getFeatureCategoryIdList: any;
  getFeatureCategoryIdListArray: any[]=[];
  getFeatureTypeIdList: any;
  getFeatureTypeIdListArray: any[]=[];
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;

  
  constructor(
    readonly _FeaturesService: FeaturesService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
      private _location: Location,
      readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute:ActivatedRoute,
    readonly accountsService: AccountsService,
    public datepipe: DatePipe,
    readonly myContactsService: MyContactsService,


 
  ) 

  {

    this.addFeaturePriceForm = this._FormBuilder.group({
      priceType:"",
      fromDate:"",
      thorughDate:"",
      price:"",
      currency:"",
      
    });

    this.updateFeatureForm = this._FormBuilder.group({
      featureID:"",
      featureTypes:"",
      featureCategorys:"",
      description:"",
      measuereID:"",
      numberQuantity:"",
      DefaultAmount:"",
      defaultSequenceNumber:"",
      abbreviation:"",
      idCode:"",
      
    });

    this.createInformationForm = this._FormBuilder.group({
      supplier:"",
      description:"",
      currency:"",
      idCode:"",
      
    });

    this.featureCategoryForm = this._FormBuilder.group({
      description: ['', [Validators.required]],
      parentCategoryId: ['']
    });
    this.featureCategoryId = '';
    this.editMode = false;
    this.parentCategory = [];
    this.opportunityIdArray = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Begins With',
        text: 'Begins With'
      },
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      }
    ];
    this.searchFilter = this._FormBuilder.group({
      description: [''],
      descriptionSearchType: ['Contains'],
      productFeatureCategoryId: [''],
      productFeatureCategoryIdSearchType: ['Contains']
    });
  }
  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.productFeatureId = params["productFeatureId"];
    });
    this.show=true;
    this.productPriceType();
    this.getFeaturePrice();
    this.getCurrencyRate();
    this.findSupplierProductFeatureById();
    this.getSupplierList();
    this.getProductFeatureCategoryIdList();
    this.getProductFeatureTypeIdList();
    this.getProductFeatureById();

  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  resetForm(){
    this.show=true;
    this.readOnlyCode=false;
    this.addFeaturePriceForm.reset();
    this.createInformationForm.reset();

  }
  updateProductFeature(): void {
    this.spinner.show();
    const requestData = {
      "abbrev": this.updateFeatureForm.value.abbreviation,
      "defaultAmount": this.updateFeatureForm.value.DefaultAmount,
      "defaultSequenceNum": this.updateFeatureForm.value.defaultSequenceNumber,
      "description": this.updateFeatureForm.value.description,
      "idCode": this.updateFeatureForm.value.idCode,
      "numberSpecified": this.updateFeatureForm.value.numberQuantity,
      "productFeatureCategoryId": this.updateFeatureForm.value.featureCategorys,
      "productFeatureId": this.updateFeatureForm.value.featureID,
      "productFeatureTypeId": this.updateFeatureForm.value.featureTypes,
      "uomId": this.updateFeatureForm.value.measuereID,
    }
    this.accountsService.updateProductFeature(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getProductFeatureById();
        this.updateFeatureForm.reset();
        this.closebutton4.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProductFeaturePatch() {
      this.show=false;
      this.readOnlyCode=true;
      this.spinner.show();
      setTimeout(() => {
        const formValue = this.updateFeatureForm;
        formValue.patchValue({
            featureID: this.getProductFeatureByIdAll.productFeatureId,
            featureTypes: this.getProductFeatureByIdAll.productFeatureTypeId,
            featureCategorys: this.getProductFeatureByIdAll.productFeatureCategoryId,
            description: this.getProductFeatureByIdAll.description,
            measuereID: this.getProductFeatureByIdAll.uomId,
            numberQuantity: this.getProductFeatureByIdAll.quantity,
            DefaultAmount: this.getProductFeatureByIdAll.amount,
            defaultSequenceNumber: this.getProductFeatureByIdAll.IDSeqNum,
            abbreviation: this.getProductFeatureByIdAll.abbrev,
            idCode: this.getProductFeatureByIdAll.idCode,
        })

      }, 1000);
      this.spinner.hide();
  }
  getProductFeatureById() {
    this.spinner.show();
    this.accountsService.getProductFeatureById(this.productFeatureId).subscribe(res => {
      this.getProductFeatureByIdAll = res.data;
      this.spinner.hide();
    })
  }
  getProductFeatureCategoryIdList() {
    this.spinner.show();
    this.accountsService.getProductFeatureCategoryIdList().subscribe(res => {
      this.getFeatureCategoryIdList = res.data.getProductFeatureCategoryIdList ;
      this.spinner.hide();
      for (const value of this.getFeatureCategoryIdList) {
        this.getFeatureCategoryIdListArray.push({
          label: value.description+"["+value.productFeatureCategoryId+"]",
          value: value.productFeatureCategoryId
        })
      }
    })
  }
  getProductFeatureTypeIdList() {
    this.spinner.show();
    this.accountsService.getProductFeatureTypeIdList().subscribe(res => {
      this.getFeatureTypeIdList = res.data.getProductFeatureTypeIdList ;
      this.spinner.hide();
      for (const value of this.getFeatureTypeIdList) {
        this.getFeatureTypeIdListArray.push({
          label: value.description,
          value: value.productFeatureTypeId
        })
      }
    })
  }
  deleteSupplierProductFeature(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "partyId": product.partyId,
          "productFeatureId": product.productFeatureId,
          "view_INDEX_2": "",
          "view_SIZE_2": ""
        }
        this.accountsService.deleteSupplierProductFeature(req).subscribe((res: any) => {
          if (res.success) {
            this.findSupplierProductFeatureById();
         
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateSupplierProductFeature(): void {
    this.spinner.show();
    const requestData = {
      "deleteLink": "",
      "description": this.createInformationForm.value.description,
      "idCode": this.createInformationForm.value.idCode,
      "partyId": this.createInformationForm.value.supplier,
      "productFeatureId": this.productFeatureId,
      "uomId": this.createInformationForm.value.currency,
    }
    this.accountsService.updateSupplierProductFeature(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.findSupplierProductFeatureById();
        this.closebutton3.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateSupplierProductFeaturePatch(product) {
      this.show=false;
      this.readOnlyCode=true;
      this.updateSupplierProductFeaturePat = product;
      this.spinner.show();
      const fromDateCatePatch=product.fromDate;
      this.fromDateCatePatch=this.datepipe.transform(fromDateCatePatch,"yyyy-MM-dd");
      const throughDateCatePatch=product.thruDate;
      this.throughDateCatePatch=this.datepipe.transform(throughDateCatePatch,"yyyy-MM-dd");

      setTimeout(() => {
        const formValue = this.createInformationForm;
        formValue.patchValue({
            supplier: product.partyId,
            description: product.description,
            currency: product.uomId,
            idCode: product.idCode,
        })

      }, 1000);
      this.spinner.hide();
  }
  addSupplierProductFeature(): void {
    this.spinner.show();
    const requestData = {
      "description": this.createInformationForm.value.description,
      "idCode": this.createInformationForm.value.idCode,
      "partyId": this.createInformationForm.value.supplier,
      "productFeatureId": this.productFeatureId,
      "uomId": this.createInformationForm.value.currency,
    }
    this.accountsService.addSupplierProductFeature(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.findSupplierProductFeatureById();
        this.createInformationForm.reset();
        this.closebutton3.nativeElement.click();
       
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  findSupplierProductFeatureById() {
    this.spinner.show();
    this.accountsService.findSupplierProductFeatureById(this.productFeatureId).subscribe(res => {
      this.getSupplierProductFeatureById = res.data[0].data;
      this.spinner.hide();
    })
  }
  getSupplierList() {
    this.spinner.show();
    this.myContactsService.getSupplierList().subscribe(res => {
      this.supplierList = res.data.getCostComponentCalcId ;
      this.spinner.hide();
      for (const value of this.supplierList) {
        this.supplierListArray.push({
          label: value.groupName+"["+value.partyId+"]",
          value: value.partyId
        })
      }
    })
  }
  deleteFeaturePrice(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const fromDateCatePatch=product.fromDate;
        this.fromDateCatePatch=this.datepipe.transform(fromDateCatePatch,"yyyy-MM-dd");
        let req = {
          "currencyUomId": product.currencyUomId,
          "fromDate": this.fromDateCatePatch,
          "productFeatureId": product.productFeatureId,
          "productPriceTypeId": product.productPriceTypeId,
          "view_INDEX_1": "",
          "view_SIZE_1": "" 
        }
        this.accountsService.deleteFeaturePrice(req).subscribe((res: any) => {
          if (res.success) {
            this.getFeaturePrice();
         
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateFeaturePrice(): void {
      this.spinner.show();
      const requestData = {
        "currencyUomId": this.addFeaturePriceForm.value.currency,
        "fromDate": this.datepipe.transform(this.addFeaturePriceForm.value.fromDate, "yy-MM-dd HH:mm:ss"),
        "price": this.addFeaturePriceForm.value.price,
        "productFeatureId": this.productFeatureId,
        "productPriceTypeId": this.addFeaturePriceForm.value.priceType,
        "thruDate": this.datepipe.transform(this.addFeaturePriceForm.value.thorughDate, "yy-MM-dd HH:mm:ss"),
      }
      this.accountsService.updateFeaturePrice(requestData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.getFeaturePrice();
          this.closebutton2.nativeElement.click();
          this.show=true;

        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
  }
  updateFeaturePricePatch(product) {
      this.show=false;
      this.readOnlyCode=true;
      this.updateFeaturePricePat = product;
      this.spinner.show();
      const fromDateCatePatch=product.fromDate;
      this.fromDateCatePatch=this.datepipe.transform(fromDateCatePatch,"yyyy-MM-dd");
      const throughDateCatePatch=product.thruDate;
      this.throughDateCatePatch=this.datepipe.transform(throughDateCatePatch,"yyyy-MM-dd");

      setTimeout(() => {
        const formValue = this.addFeaturePriceForm;
        formValue.patchValue({
            priceType: this.updateFeaturePricePat.productPriceTypeId,
            thorughDate: this.throughDateCatePatch,
            price: this.updateFeaturePricePat.price,
            currency: this.updateFeaturePricePat.currencyUomId,
            fromDate: this.fromDateCatePatch,
        })

      }, 1000);
      this.spinner.hide();
  }
  createFeaturePrice(): void {
    this.spinner.show();
    const requestData = {
      "currencyUomId": this.addFeaturePriceForm.value.currency,
      "fromDate": this.datepipe.transform(this.addFeaturePriceForm.value.fromDate, "yy-MM-dd HH:mm:ss"),
      "price": this.addFeaturePriceForm.value.price,
      "productFeatureId": this.productFeatureId,
      "productPriceTypeId": this.addFeaturePriceForm.value.priceType,
      "thruDate": this.datepipe.transform(this.addFeaturePriceForm.value.thorughDate, "yy-MM-dd HH:mm:ss"),
    }
    this.accountsService.createFeaturePrice(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getFeaturePrice();
        this.addFeaturePriceForm.reset();
        this.closebutton2.nativeElement.click();
       
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFeaturePrice() {
    this.spinner.show();
    this.accountsService.getFeaturePrice(this.productFeatureId).subscribe(res => {
      this.getFeaturePriceData = res.data[0].data;
      this.spinner.hide();
    })
  }
  getCurrencyRate() {
    this.spinner.show();
    this.myContactsService.getCurrencyDataList().subscribe(res => {
      const currencyList = res.data.currencyList;
      this.spinner.hide();
      for (const value of currencyList) {
        this.currencyArray.push({
          label: value.description+"["+value.uomId+"]",
          value: value.uomId
        })
      }
    })

  }
  productPriceType() {
      this.spinner.show();
      this.accountsService.productPriceType().subscribe(res => {
        this.productPriceTypeById = res.data;
        this.spinner.hide();
        for (const value of this.productPriceTypeById) {
          this.productPriceTypeByIdArray.push({
            label: value.description,
            value: value.productPriceTypeId
          })
        }
      })
    }
  backBtn(){
    this.router.navigate(['/facilities/catalog/feature-interaction']);

  }
    getFeatureCategories(): void {
      this.spinner.show();
      this._FeaturesService.getFeatureCategoriesList({ pageNo: 1, pageSize: 200 }, this.searchFilter.value)
        .then(data => {
          this.featureCategories = data.data;
          this.spinner.hide();
        });
      
    }
    featureType(){
      this.router.navigate(['/facilities/catalog/feature-type']);
    }
    featureCategoryGroup(){
      this.router.navigate(['/facilities/catalog/feature-category']);
    }
    featureGroup(){
      this.router.navigate(['/facilities/catalog/feature-group']);
    }
  
    featureInteraction(){
      this.router.navigate(['/facilities/catalog/feature-interaction']);
    }

    toSummaryPage()
    {
      this.router.navigate(['/facilities/catalog/feature-interaction/new-feature-interaction']);
    }
  
    // navigateToEdit(featureId: string): void {
    //   this.router.navigate(['/facilities/catalog/feature-category/new-feature-category'], { queryParams: { featureCategoryId: featureId } });
    // }
    customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;
  
        if (value1 == null && value2 != null) {
          result = -1;
        }
        else if (value1 != null && value2 == null) {
          result = 1;
        }
        else if (value1 == null && value2 == null) {
          result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
          result = value1.localeCompare(value2);
        }
        else {
          result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
      });
    }
    mainOpen(){
      this.router.navigate(['/facilities/catalog/main-catalog']);
    }
    catalogOpen(){
      this.router.navigate(['/facilities/catalog/catalogs']);
    }
    categoriesOpen(){
      this.router.navigate(['/facilities/catalog/catagories']);
    }
    productsOpen(){
      this.router.navigate(['/facilities/catalog/products']);
    }
    featuresOpen(){
      this.router.navigate(['/facilities/catalog/feature-type']);
    }
    promosOpen(){
      this.router.navigate(['/facilities/catalog/promos']);
    }
    priceRulesOpen(){
      this.router.navigate(['/facilities/catalog/price-rules']);
    }
    storesOpen(){
      this.router.navigate(['/facilities/catalog/stores']);
    }
    productStoreGroupsOpen(){
      this.router.navigate(['/facilities/catalog/product-store-groups']);
    }
    thesaurusOpen(){
      this.router.navigate(['/facilities/catalog/thesaurus']);
    }
    reviewsOpen(){
      this.router.navigate(['/facilities/catalog/reviews']);
    }
    configurationsOpen(){
      this.router.navigate(['/facilities/catalog/configurations']);
    }
    subscriptionsOpen(){
      this.router.navigate(['/facilities/catalog/subscriptions']);
    }
    shippingOpen(){
      this.router.navigate(['/facilities/catalog/shipment']);
    }
    imageManagementOpen(){
      this.router.navigate(['/facilities/catalog/image-management']);
    }
    ngOnDestroy(): void {
      this.spinner.hide();
        }

    
    
  
  
  
  
    changeactiveCategory(tab: number) {
      this.activeCategory = tab;
    }
  
  
  
  

}



