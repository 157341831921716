<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            
           <div class="col-md-12 p-0" >
               <div class="w3-card-4 classCard  w-100">
                   
                    <div class="mt-3" style="margin-bottom: 10px !important; ">
                        <span style="font-size: 15px; font-weight: bold; margin-left: 16px!important;">Configure Posting Invoices </span>

                    </div>                    

                    
                    


                    <div class="row " style="width: 100%; margin-top: 1%; margin-bottom: 1%;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color:black; font-weight: bold !important;">
                                        Posting Invoices </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12">
                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mb-2 modals">
                                                            <div class="classInput">
                                                                <label>How would you like to post invoices ?</label>

                                                            </div>  
                                                            
                                                            <a class="ml-3" href="#">Edit</a>                                                       

                                                            

                                                        </div>

                                                    </div>
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    <div class="d-flex justify-content-end  mb-1" style="margin-right: 1%;">
                        <button class="btn btn-secondary ml-2 submit-btn">Cancel</button>
                        <button class="btn btn-secondary ml-2 submit-btn">Ok</button>
                    </div>


                   

            
                    

                   
               </div>
           </div>
                        
        
        
        
        
        </div>
    
    </div>
</div>


       

<!-- <ngx-spinner></ngx-spinner> -->








