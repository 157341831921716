import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TrainingTypeModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        description:(data && data.description) ? data.description : null,
        parentTypeId:(data && data.parentTypeId) ? data.parentTypeId : null,
        trainingClassTypeId:(data && data.trainingClassTypeId) ? data.trainingClassTypeId : null,

    });

    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   return ({
    description: new FormControl(data.description,[Validators.required]),
    parentTypeId: new FormControl(data.parentTypeId),
    trainingClassTypeId: new FormControl(data.trainingClassTypeId),

   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
