<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Add a new task to one of my projects.
                </span>
  

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
               
               
               
                <div >
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
              
                        
                        <li [ngClass]="activeCategory==2?'active':''" ><a>
                            Add a new task to one of my projects.
                        </a></li>
                           
                       
                        </ul>
                      </div>
                   
                   
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form  class="w-100" [formGroup]="addTask">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Task Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text"  formControlName="TaskName"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Task Name">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Parent Phase</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="TaskNameArray" filter="true" formControlName="ParentPhase"
                                                    placeholder="Enter Parent Phase"
                                                    name="Parent Type ID">
                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Sequence Num</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text"  formControlName="SequenceNum"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        My Role For This Task</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="RoleByIdArray" filter="true" formControlName="MyRoleForThisTask"
                                                    placeholder="Enter My Role For This Task"
                                                    name="Parent Type ID">
                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Estimated Hours</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text"  formControlName="EstimatedHours"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Estimated Hours">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Skill Type ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="SkillTypeArray" filter="true" formControlName="SkillTypID"
                                                    placeholder="Enter Skill Type ID"
                                                    name="Parent Type ID">
                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Priority</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="priority" filter="true" formControlName="Priority"
                                                    placeholder="Enter Priority"
                                                    name="Parent Type ID">
                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Type</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="taskArray" filter="true" formControlName="Type"
                                                    placeholder="Enter Type"
                                                    name="Parent Type ID">
                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Description</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="text"  formControlName="Description"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Description">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Scope</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="ScopeArray" filter="true" formControlName="Scope"
                                                    placeholder="Enter Scope"
                                                    name="Parent Type ID">
                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Estimated Start Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date"  formControlName="EstimatedStartDate"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Estimated Start Date">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Estimated Completion Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date"  formControlName="EstimatedCompletionDate"  class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Estimated Completion Date">
                                                </div>
                                            </div>
                                        </div>

                                       
                                    </div>
                                    
    
                                </form>
                                <div class="main-submit-button" style="margin-left: 66%;">
                                   
                                    <button type="submit" (click)="onSubmit();"  class="btn btn-secondary submit-btn">Add</button>
                                     
                                   
                                    
                                  
                                </div>
                            </div>
                        </div>
    
                    </div>

                </div>
                </div>
             

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>




