<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-grey " (click)="cancelSubmit();" style="cursor: pointer;">JobShop


                </span>
                <span class="color-black pl-1"> >Add New JobShop



                </span>

                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                    Add JobShop

                                </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                    Update JobShop

                                </a></li>



                        </ul>
                    </div>


                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="createJob">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group formRight">
                                                    <label for="exampleInputEmail1">
                                                        Product ID<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2" *ngIf="!this.show">
                                                    <p-dropdown placeholder="Enter Product ID" filter="true"
                                                    formControlName="productId"
                                                    [options]="productIdArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-2" *ngIf="this.show">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                    formControlName="productId" readonly>
                                                </div>
                                                <div class="col-lg-3 form-group formRightA">
                                                    <label for="exampleInputEmail1">
                                                        Quantity<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                    formControlName="Quantity">
                                                </div>
                                              

                                               
                                            </div>
                                        </div>
                                     
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group formRight"  *ngIf="this.show">
                                                    <label for="exampleInputEmail1">
                                                        Manufacturer ID</label>
                                                </div>
                                                <div class="col-lg-2"  *ngIf="this.show">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                    formControlName="ManufacturerID" readonly>
                                                </div>
                                                <div class="col-lg-3 form-group formRightA"  *ngIf="this.show">
                                                    <label for="exampleInputEmail1">
                                                        Current Status ID</label>
                                                </div>
                                                <div class="col-lg-2"  *ngIf="this.show">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                    formControlName="statusId" readonly>
                                              

                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group formRight"  >
                                                    <label for="exampleInputEmail1">
                                                        Start Date<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2" >
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                    formControlName="startDate">
                                                </div>
                                                <div class="col-lg-3 form-group formRightA" *ngIf="this.show">
                                                    <label for="exampleInputEmail1">
                                                        Calculated Completion Date</label>
                                                </div>
                                                <div class="col-lg-2" *ngIf="this.show">
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                    formControlName="CalculatedCompletionDate" readonly>
                                                </div>
                                            </div>
                                        </div>
                                     
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group formRight"  >
                                                    <label for="exampleInputEmail1">
                                                        Facility ID<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Facility ID" filter="true"
                                                    formControlName="FacilityID"
                                                    [options]="facilityIdArray" optionlabel="label">

                                                </p-dropdown>

                                                </div>
                                                <div class="col-lg-3 form-group formRightA" *ngIf="!this.show">
                                                    <label for="exampleInputEmail1">
                                                        Routing ID</label>
                                                </div>
                                                <div class="col-lg-2" *ngIf="!this.show">
                                                    <p-dropdown placeholder="Enter Routing ID" filter="true"
                                                    formControlName="RoutingID"
                                                    [options]="routingIdArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                     
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group formRight"  >
                                                    <label for="exampleInputEmail1">
                                                        Production Run Name</label>
                                                </div>
                                                <div class="col-lg-2" >
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Production Run Name"
                                                    formControlName="ProductionRunName">

                                                </div>
                                                <div class="col-lg-3 form-group formRightA">
                                                    <label for="exampleInputEmail1">
                                                        Description</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Description"
                                                    formControlName="Description">
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group formRight" *ngIf="!this.show" >
                                                    <label for="exampleInputEmail1">
                                                        Create Dependent Production Runs</label>
                                                </div>
                                                <div class="col-lg-2" *ngIf="!this.show">
                                                    <p-dropdown placeholder="Enter Create Dependent Production Runs"
                                                    filter="true" formControlName="dependent"
                                                    [options]="createDependent" optionlabel="label">

                                                </p-dropdown>

                                                </div>
                                                <div class="col-lg-2"></div>
                                               
                                            </div>
                                        </div>
                                 



                                    </div>


                                </form>
                                <div class="main-submit-button" style="margin-left: 74%;">

                                    <button type="submit" *ngIf="!this.show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>

                                    <button type="submit" *ngIf="this.show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>