import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder,FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MergeContactsService } from 'src/app/crm/contacts/merge-contacts/merge-contacts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-view-mrp-main',
  templateUrl: './view-mrp-main.component.html',
  styleUrls: ['./view-mrp-main.component.css']
})
export class ViewMrpMainComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 9;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  viewMRPFormForm: FormGroup;
  viewMRPForm: FormGroup;
  runMrpList: any;
  ProductId: any;
  ProductIdArray: any[]=[];
  facility: any;
  facilityArray: any[]=[];
  suppliers: any[];
  viewMRPList: any;
  MrpEventTypeId: any;
  MrpEventTypeIdArray: any[]=[];

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly _WarehouseService: WarehouseService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly mergeContactsService: MergeContactsService,    private _location: Location,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _Router: Router
  ) 
  {
    this.viewMRPForm=this._FormBuilder.group({
      facility:"",
      supplier:"",
      productStore:"",
      MRPStoreGroup:"",
      product:"",
      runMrpId:""
      
    })
   }

  ngOnInit(): void {
    this.getviewMRPList();
    this.getProductIds();
    this.getFacilityId();
    this.getSuppliers();
    this.MrpEventType();
  }
  MrpEventType() {
    this.spinner.show();
    this.mergeContactsService.MrpEventType().subscribe(res => {
      this.MrpEventTypeId = res.data[0].MrpEventType;
      this.spinner.hide();
      for (const value of this.MrpEventTypeId) {
        this.MrpEventTypeIdArray.push({
          label: value.description,
          value: value.mrpEventTypeId
        })
      }
    })
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getSuppliers(): void {
    this.spinner.show();
    this._WarehouseService.getSuppliers()
        .then(data => {
            this.spinner.hide();
            this.suppliers = data.data.map(value => {
                return {
                    label: value.partyId,
                    value: value.partyId
                };
            });
        });
}
  getFacilityId() {
    this.spinner.show();
    this.myContactsService.getFacilityId().subscribe(res => {
      this.facility = res.data[0].getFacilityId;
      this.spinner.hide();
      for (const value of this.facility) {
        this.facilityArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
    })
   
  }
  getProductIds() {
    this.spinner.show();
    this.myContactsService.getProductIds().subscribe(res => {
      this.ProductId = res.data[0].getProductId;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.internalName,
          value: value.productId
        })
      }
    })
  }
  reset() {
    this.viewMRPForm.reset();
    this.getviewMRPList();

  }
  getviewMRPList() {
    this.spinner.show();
    this.mergeContactsService.getviewMRPList().subscribe(res => {
      this.viewMRPList = res.data;
      this.spinner.hide();

    })
  } 
  viewMRP() {
    this.spinner.show();
    const req = {
      "facilityId": this.viewMRPForm.value.facility ? this.viewMRPForm.value.facility : '',
      "mrpEventTypeId": this.viewMRPForm.value.MRPStoreGroup ? this.viewMRPForm.value.MRPStoreGroup : '',
      "productId":  this.viewMRPForm.value.product ? this.viewMRPForm.value.product : '',
      "productStoreId":  this.viewMRPForm.value.productStore ? this.viewMRPForm.value.productStore : '',
      "runMrpId":  this.viewMRPForm.value.runMrpId ? this.viewMRPForm.value.runMrpId : '',
    }
    this.mergeContactsService.viewMRP(req.facilityId,req.mrpEventTypeId,req.productId,req.productStoreId,req.runMrpId).subscribe(res => {
      this.viewMRPList = res.data;
      this.spinner.hide();

    })
  }
  openInventory()
  {
    this._Router.navigate(['/facilities/Planning']);

  }
  openFindRequirements()
  {
    this._Router.navigate(['/facilities/Planning/find-requirements']);

  }
  openRequirements()
  {
    this._Router.navigate(['/facilities/Planning/open-requirements']);

  }
  openProductRequirements()
  {
    this._Router.navigate(['/facilities/Planning/product-requirements']);
    
  }
  openInternalRequirements()
  {
    this._Router.navigate(['/facilities/Planning/internal-requirements']);

  }
  openTransferRequirements()
  {
    this._Router.navigate(['/facilities/Planning/transfer-requirements']);

  }
  openSalesOrderHistory()
  {
    this._Router.navigate(['/facilities/Planning/upload-sales-order-history']);

  }
  openviewMRPForm()
  {
    this._Router.navigate(['/facilities/Planning/run-mrp']);

  }
  openRunMRP()
  {
    this._Router.navigate(['/facilities/Planning/run-mrp']);

  }
  openViewMRP()
  {
    this._Router.navigate(['/facilities/Planning/view-mrp']);

  }
  openConfigureWarehouses()
  {
    this._Router.navigate(['/facilities/Planning/configure-warehouse']);

  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  

}
