import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CustomerAgreementsService } from 'src/app/accounting/accounting-ar/customer-agreements/customer-agreements.service';
import { DatePipe, Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../../accounting-ap.service';
import { PayablePaymentService } from '../../payables-payment/payables-payment.service';
import { BillingAccountService } from '../billing-account.service';
import * as moment from 'moment';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
@Component({
  selector: 'app-summary-billing-account',
  templateUrl: './summary-billing-account.component.html',
  styleUrls: ['./summary-billing-account.component.css']
})
export class SummaryBillingAccountComponent implements OnInit {
  total = 0;
  billingAccountForm: FormGroup;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  currencyUomIds: any[];
  partyIds: any[];
  editMode: boolean;
  billingAccountId: string;
  billingAccount: any;
  contactMechIds: any[];
  rowNumber = 0;
  pageSize = 10;
  activeCategory = 1;
  rows = 3;
  pageNo = 1;
  product:any;
  statusIds: any[];
  paymentTypes: any[];
  paymentMethodTypes: any[];
  array = [
    {
      label: 'Your Company Name',
      value: 'Company'
    }
  ];
  editModeRole: boolean;

  invoices: any[];
  payments: any[];
  orders: any[];
  statusId: string;
  createPaymentForm: FormGroup;
  currencyId: string;
  BillingAccountRole: any;
  rolesForm: FormGroup;
  termForm:FormGroup;
  billingAccountTerms: any;
  TermTypeIdList:any[]=[];
  editTerm:boolean;
  personId: any;
  partyIdArray: any[] = []; PartyRolesArray: any[]=[];
  partyId:any;
  PartyRoles: any;
  constructor(

    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _PayablePaymentService: PayablePaymentService,
    readonly _FormBuilder: FormBuilder,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly CustomerAgreementsServices: CustomerAgreementsService,
    readonly _BillingAccountService: BillingAccountService,
    readonly _AccountingApService: AccountingApService,
    private _location: Location,
    readonly accountsService: AccountsService,
  ) {
    this.currencyUomIds = [];



    this.partyIds = [];
    this.editMode = false;
    this.contactMechIds = [];
    this.billingAccountForm = this._FormBuilder.group({
      accountCurrencyUomId: [''],
      accountLimit: [''],
      availableBalance: [0],
      contactMechId: [''],
      description: ['', [Validators.required]],
      externalAccountId: [''],
      fromDate: [''],
      partyId: ['', [Validators.required]],
      thruDate: [''],
      name: [''],
      accountType: [''],
    });
    this.rolesForm = this._FormBuilder.group({
      PartyID: [''],
      RoleTypeID: [''],
      fromDate: [''],
      thruDate: [''],
      IsDeleted: ['']
    });
    this.termForm = this._FormBuilder.group({
      termTypeId: [''],
      termValue: [''],
      uomId: [''],

    });
    this.billingAccountId = '';

    this.billingAccountId = '';
    this.invoices = [];
    this.payments = [];
    this.orders = [];
    this.statusId = '';
    this.statusIds = [];
    this.createPaymentForm = this._FormBuilder.group({
      amount: ['', [Validators.required]],
      partyIdFrom: [''],
      partyIdTo: ['Company'],
      paymentMethodTypeId: [''],
      paymentTypeId: ['']
    });
    this.paymentTypes = [];
    this.paymentMethodTypes = [];
    this.partyIds = [];
    this.currencyId = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.billingAccountId = this._ActivatedRoute.snapshot.queryParams.billingAccountId;
    this._BillingAccountService.getBillingAccountById(this.billingAccountId)
      .then(data => {
        this.currencyId = data.data.accountCurrencyUomId;
      });

    this.getContactMechId();
    this.getCurrency();
    this.getPartyIds();
    this.getStatus();
    this.searchInvoices();
    this.getPartyIds();
    this.getPaymentType();
    this.getPaymentMethodType();
    this.searchPayments();
    this.searchOrders();
    this.billingInformation();
    this.getAllBillingAccountRole();
    this.getAllBillingAccountTerms();
    this.getPartyId();
    this.getTermTypeIdList();
  }
  detailMainPage(paymentId,statusIdWithDescription,comments,partyIdFrom,partyIdTo,effectiveDate,amount){
    this.router.navigate(['/myPortal/detail-payment-portal-summary'],{ queryParams: { paymentId:paymentId,statusIdWithDescription:statusIdWithDescription,comments:comments,partyIdFrom:partyIdFrom,partyIdTo:partyIdTo,effectiveDate:effectiveDate,amount:amount}});
  }
 
  referToInvoice(id){
    this.router.navigate(['/financial/receiveable/invoices/detailed-summary'],{queryParams: { invoiceId:id}})
  }
  referToparty(id){
    this.router.navigate(["/partyQuickLink/profile-party-quick-link"],{ queryParams: { party:id} })

  }
  generateBillingPdf(): void {
    this.spinner.show();
    this.accountsService.generateBillingPdf(this.billingAccountId)
      .subscribe(value => {
        const blob = new Blob([value], { type: 'application/application/pdf' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        this.spinner.hide();
        link.download = `${this.billingAccountId}.pdf`;
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });

  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/financial/main']);
  }
  onChange(event) {
    console.log(event, "evnt")
    console.log(event.partyId, "evnt party id")
    this.PartyRolesArray = [];
    this.partyId = event;
    if (event) {
      this.getPartyRoles();
    }
    console.log(this.partyId, "par id")

  }

  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  getPartyRoles() {
    this.spinner.show();
    this.myContactsService.getPartyRoles(this.partyId).subscribe(res => {
      this.PartyRoles = res.data[0].entityQuery;
      this.spinner.hide();
      for (const value of this.PartyRoles) {
        this.PartyRolesArray.push({
          label: value.roleTypeId,
          value: value.roleTypeId
        })
      }
    })
  }
  getTermTypeIdList() {
    this.spinner.show();
    this.CustomerAgreementsServices.getTermTypeIdList().subscribe((responce) => {
      this.spinner.hide();
      this.TermTypeIdList = responce.data.map(value => {
        return {
          label: value.description,
          value: value.termTypeId
        };
      });
    });
   
  }
  deleteBillingAccountTerms(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       
        this.myContactsService.deleteBillingAccountTerm(product.billingAccountId,product.billingAccountTermsId).subscribe(res => {
          this._ToastrService.success("Delete Successfully");
          this.getAllBillingAccountTerms();
        }, (err) => {
          this._ToastrService.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }

  getAllBillingAccountTerms() {
    this.spinner.show();
    this.myContactsService.getAllBillingAccountTerms(this.billingAccountId).subscribe(res => {
      this.billingAccountTerms = res.data.data;
    })
    this.spinner.hide();
  }
  createBillingAccountTerm() {
    this.spinner.show();
    const requestData = {
      "billingAccountId": this.billingAccountId,
      "termTypeId":  this.termForm.value.termTypeId,
      "termValue":  this.termForm.value.termValue,
      "uomId": this.termForm.value.uomId,
    }
    this.myContactsService.createBillingAccountTerms(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebutton3.nativeElement.click();
        this.termForm.reset();
        this.getAllBillingAccountTerms();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  updateTerm(product) {
    this.editTerm = true;
    this.product = product;
    setTimeout(() => {
      const form = this.termForm;
      form.patchValue({
        termTypeId: product.termTypeId,
        termValue:product.termValue,
        uomId:product.uomId,
      })
    }, 2000);
  }
  updateBillingAccountTerm() {
    this.spinner.show();
    const requestData = {
      "billingAccountId": this.billingAccountId,
      "termTypeId":  this.termForm.value.termTypeId,
      "termValue":  this.termForm.value.termValue,
      "uomId": this.termForm.value.uomId,
    }
    this.myContactsService.updateBillingAccountTerm(requestData,this.product.billingAccountTermId).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebutton3.nativeElement.click();
        this.termForm.reset();
        this.getAllBillingAccountTerms();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  editrole() {
    this.editModeRole = false;
    this.rolesForm.reset();
  }
  updateRole(product) {
    this.editModeRole = true;
    setTimeout(() => {
      const form = this.rolesForm;
      form.patchValue({
        PartyID: product.partyId,
        RoleTypeID: product.roleTypeId,
        fromDate:this.datePipe.transform(product.stringFromDate,"yyyy-MM-dd") , 
        thruDate: this.datePipe.transform( product.stringThruDate,"yyyy-MM-dd") , 
        IsDeleted: product.isDeleted,
      })
    }, 2000);
  }
  createBillingAccountRole() {
    this.spinner.show();
    const requestData = {
      "billingAccountId": this.billingAccountId,
      "fromDate":this.datePipe.transform(this.rolesForm.value.fromDate,"yyyy-MM-dd HH:mm:ss") ,
      "partyId": this.rolesForm.value.PartyID,
      "roleTypeId": this.rolesForm.value.RoleTypeID,
      "IsDeleted": this.rolesForm.value.IsDeleted,
      "thruDate":this.datePipe.transform( this.rolesForm.value.thruDate,"yyyy-MM-dd HH:mm:ss")
    }
    this.myContactsService.createBillingAccountRole(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebutton2.nativeElement.click();
        this.rolesForm.reset();
        this.getAllBillingAccountRole();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  updateBillingAccountRole() {
    this.spinner.show();
    const requestData = {
      "billingAccountId": this.billingAccountId,
      "fromDate":this.datePipe.transform(this.rolesForm.value.fromDate,"yyyy-MM-dd HH:mm:ss") ,
      "partyId": this.rolesForm.value.PartyID,
      "roleTypeId": this.rolesForm.value.RoleTypeID,
      "IsDeleted": this.rolesForm.value.IsDeleted,
      "thruDate":this.datePipe.transform( this.rolesForm.value.thruDate,"yyyy-MM-dd HH:mm:ss")
    }
    this.myContactsService.updateBillingAccountRole(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebutton2.nativeElement.click();
        this.rolesForm.reset();
        this.getAllBillingAccountRole();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  deleteBillingAccountRole(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "billingAccountId": product.billingAccountId,
          "fromDate": product.fromDate,
          "partyId": product.partyId,
          "roleTypeId": product.roleTypeId,
          "thruDate": product.thruDate
        }
        this.myContactsService.deleteBillingAccountRole(req).subscribe(res => {
          this._ToastrService.success("Delete Successfully");
          this.getAllBillingAccountRole();
        }, (err) => {
          this._ToastrService.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  getAllBillingAccountRole() {
    this.spinner.show();
    this.myContactsService.getAllBillingAccountRole(this.billingAccountId).subscribe(res => {
      this.BillingAccountRole = res.data.data;
    })
    this.spinner.hide();
  }

  edit() {
    if (this.billingAccountId) {
      this.editMode = true;
      this._BillingAccountService.getBillingAccountById(this.billingAccountId)
        .then(data => {
          this.billingAccount = data.data;
          this.billingAccountForm.patchValue({
            ...data.data, ...{
              fromDate: data.data.fromDate ? new Date(data.data.fromDate) : '',
              thruDate: data.data.thruDate ? new Date(data.data.thruDate) : ''
            }
          });
        });
    }
  }
  getContactMechId(): void {
    this.spinner.show();
    this._BillingAccountService.getBillingAccountContactMechIdList(this.billingAccountId)
      .then(data => {
        this.spinner.hide();
        this.contactMechIds = data.data.map(value => {
          return {
            label: value.description,
            value: value.contactMechId
          };
        });
      });

  }

  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        this.spinner.hide();
        this.currencyUomIds = data.data.currencyList.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });

  }
  update(): void {
    this.spinner.show();
    console.log(this.billingAccountForm.value.accountType);
    console.log(this.billingAccountForm.value.name);

    if (this.billingAccountForm.valid) {
      this._BillingAccountService.updateBillingAccount(this.billingAccountId, {
        ...this.billingAccount, ...this.billingAccountForm.value, ...{
          fromDate: this.billingAccountForm.value.fromDate ? moment(this.billingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
          thruDate: this.billingAccountForm.value.thruDate ? moment(this.billingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.billingAccountForm.reset();
            this.closebutton1.nativeElement.click();
            this.billingInformation();

          }
        });
    }

  }
  submitBilling(): void {
    this.spinner.show();
    if (this.editMode) {
      this.update();
    } else {
      if (this.billingAccountForm.valid) {
        this._BillingAccountService.createBillingAccount({
          ...this.billingAccountForm.value, ...{
            fromDate: this.billingAccountForm.value.fromDate ? moment(this.billingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
            thruDate: this.billingAccountForm.value.thruDate ? moment(this.billingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
          }
        })
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.billingAccountForm.reset();
              this.spinner.hide();
              this.router.navigate(['/financial/payable/billing-account']);
            }
          });
      }
    }

  }
  billingInformation() {
    this._BillingAccountService.getBillingAccountById(this.billingAccountId)
      .then(data => {
        this.billingAccount = data.data;

      });
  }
  cancelSubmit() {
    this.router.navigate(["/financial/payable/billing-account"])
  }
  getStatus(): void {
    this.spinner.show();
    this._AccountingApService.getInvoiceTypeAndStatus()
      .then(data => {
        this.statusIds = data.data.invoiceStatus.map(value => {
          return {
            label: value.description,
            value: value.statusId
          };
        });
      });
    this.spinner.hide();
  }
  getPaymentMethodType(): void {
    this.spinner.show();
    this._BillingAccountService.getBillingAccountPaymentMethodTypes()
      .then(data => {
        this.paymentMethodTypes = data.data.map(value => {
          return {
            label: value.description,
            value: value.paymentMethodTypeId
          };
        });
      });
    this.spinner.hide();
  }
  getPaymentType(): void {
    this.spinner.show();
    this._PayablePaymentService.getPaymentType()
      .then(data => {
        this.paymentTypes = data.data.map(value => {
          return {
            label: value.description,
            value: value.paymentTypeId
          };
        });
      });
    this.spinner.hide();
  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });
    this.spinner.hide();
  }
  searchPayments(): void {
    this.spinner.show();
    this._BillingAccountService.getBillingAccountPaymentList(this.billingAccountId, { pageNo: 1, pageSize: 200 })
      .then(data => {
        this.payments = data.data;
        this.spinner.hide();
      });

  }
  searchInvoices(): void {
    this.spinner.show();
    this._BillingAccountService.getBillingAccountInvoiceList(this.billingAccountId, this.statusId, { pageNo: 1, pageSize: 200 })
      .then(data => {
        this.invoices = data.data[0].dataNode;
        this.spinner.hide();
      });

  }

  reset(): void {
    this.createPaymentForm.reset();
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  searchOrders(): void {
    this.spinner.show();
    this._BillingAccountService.getBillingAccountOrderList(this.billingAccountId)
      .then(data => {
        if (data.success) {
          this.orders = data.data;
          this.spinner.hide();
        }
      });

  }
  submit(): void {
    this.spinner.show();
    if (this.createPaymentForm.valid) {
      this._BillingAccountService.createBillingAccountPayment({
        ...this.createPaymentForm.value, ...{
          billingAccountId: this.billingAccountId,
          currencyUomId: this.currencyId,
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.searchPayments();
          }
        });
    }

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}