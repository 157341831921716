import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-create-gl-time-period',
  templateUrl: './create-gl-time-period.component.html',
  styleUrls: ['./create-gl-time-period.component.css']
})
export class CreateGlTimePeriodComponent implements OnInit {
  createTimePeriod: FormGroup;
  partyId: any;
  activeCategory=2;
  DataTimePeriod: any;
  DataTimePeriodArray: any[]=[];
  parentPeriodId: any;
  parentPeriodIdArray: any[]=[];
  actualfromdate: string;
  actualthroughdate: string;
  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
   ) {
    this.createTimePeriod = this._FormBuilder.group({
      fromDate: [''],
      isClosed: [''],
      parentPeriodId: [''],
      periodName: [''],
      periodNum: [''],
      periodTypeId: [''],
      thruDate: [''],

    });
  }

  closed = [{
    label: 'Y',
    value: 'Y',
  },
  {
    label: 'N',
    value: 'N',
  }]
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
     
    });
    this.initialDataTimePeriod();
  }
  
  initialDataTimePeriod() {
    this.spinner.show();
    this.accountsService.initialDataTimePeriod().subscribe((res: any) => {

      this.DataTimePeriod = res.data.periodType;
      this.parentPeriodId = res.data.parentPeriodId;
      this.spinner.hide();
      for (const value of this.DataTimePeriod) {
        this.DataTimePeriodArray.push({
          label: value.description,
          value: value.periodTypeId
        })
      }

      for (const value of this.parentPeriodId) {
        this.parentPeriodIdArray.push({
          label:value.customTimePeriodId,
          value: value.customTimePeriodId
        })
      }
    })
   
  }
  
  onSubmit() {
    this.spinner.show();
    const fromActualdate = this.createTimePeriod.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.createTimePeriod.get('thruDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate": this.actualfromdate,
      "isClosed": this.createTimePeriod.value.isClosed,
      "organizationPartyId": this.partyId,
      "parentPeriodId": this.createTimePeriod.value.parentPeriodId,
      "periodName":  this.createTimePeriod.value.periodName,
      "periodNum": this.createTimePeriod.value.periodNum,
      "periodTypeId":this.createTimePeriod.value.periodTypeId,
      "thruDate": this.actualthroughdate
    }
    this.accountsService.createCustomTimePeriods(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Created Successfully");
        this.router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  cancelSubmit(){
    this.router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
  }

}
