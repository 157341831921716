<div class="container-fluid main-container-wrapper-new">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Dashboard</span>
               <span class="d-flex">
               <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

               <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
        </div>
    </div>
   
    <div class="row">
        <div class="col-md-6 pr-2">
           
            <div class=" mt-2">
                <div class="w3-card-4 cardEffect w-100 pr-2" style="height: 130px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <h4 class="common-styling h4Margin m-0" style="color: #242222b5;
                        font-size: 13px;
                        font-weight: 600;">POS</h4>
                    </div>
                    <div class=" font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <span style="cursor: pointer;    color: #0000FF;" class=""  *ngIf="this.userPermissions.WEBPOS_ADMIN || this.userPermissions.PHYCORP_ADMIN"
                                        routerLinkActive="active"    routerLink="/webpos/control/main" (click)="logoutPOS()" >
                                           POS
                                        </span>
                                      
                                    </div>
                                   

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
           
          
        </div>
        <div class="col-md-6" style="padding-left: 0;">
            <div class="mt-2">
                <div class="w3-card-4 cardEffect w-100" style="height: 130px;">
                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <h4 class="common-styling h4Margin m-0" style="color: #242222b5;
                        font-size: 13px;
                        font-weight: 600;">eCommerce</h4>
                    </div>
                    <div class=" font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="background-color: #fafafa !important;">
                                    <div class="article">
                                        <a style="cursor: pointer;    color: #0000FF;text-decoration:none;" class=""
                                          href="http://34.196.47.126:82/" target="_blank">
                                          eCommerce
                                    </a>
                                       
                                    </div>
                                   
                                    

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
       
    </div>
</div>    

<ngx-spinner></ngx-spinner>