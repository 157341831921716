<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Update Shipment Gateway Config</span>
            
               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==0?'active':''" (click)="changeactiveCategoryFunction(0)"><a>
                                    Update Shipment Gateway Config Information</a></li>



                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div *ngIf="activeCategory==0">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Update Shipment Gateway Config

                                                        </h4>
                                                        <span class="edit-buttons" style="margin-left: 68%;">
                                                            <a (click)="edit()" data-toggle="modal"
                                                                data-target="#exampleModalCenter">Update</a></span>

                                                        <span *ngIf="shipmentGatewayConfigId=='DHL_CONFIG'" class="edit-buttons">
                                                            <a (click)="editDHL()" data-toggle="modal"
                                                                data-target="#exampleModal">Update DHL</a></span>

                                                        <span *ngIf="shipmentGatewayConfigId=='FEDEX_CONFIG'" class="edit-buttons">
                                                            <a data-toggle="modal"
                                                                data-target="#exampleModalFedex">Update FEDEX</a></span>

                                                        <span *ngIf="shipmentGatewayConfigId=='UPS_CONFIG'" class="edit-buttons">
                                                            <a data-toggle="modal" data-target="#exampleModalUPS">Update
                                                                UPS</a></span>

                                                        <span *ngIf="shipmentGatewayConfigId=='USPS_CONFIG'" class="edit-buttons">
                                                            <a data-toggle="modal"
                                                                data-target="#exampleModalUpdateUsps">Update
                                                                USPS</a></span>

                                                    </div>
                                                    <br>
                                                    <div>
                                                        <!--  <button type="button" style="width: 132px;"
                                                        class="btn btn-outline-secondary bg-brown ">Export as CSV</button>
                                                    &nbsp; <button type="button" style="width: 132px;"
                                                        class="btn btn-danger" routerLinkActive="active">Export as PDF</button> -->
                                                    </div>
                                                    <div class="w3-container">
                                                        <div class="panel-body">
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">
                                                                                    Shipment Gateway Config Description
                                                                                </p>

                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.description}}</span>

                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Shipment Gateway Config Type ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.shipmentGatewayConfTypeId}}</span>

                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">{{show?'Update':'Create'}} New Party Qualification</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Create Shipment Gateway Config </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update Shipment Gateway Config</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="createShipmentConfig" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">



                                                    <div class="col-lg-3 form-group classInputAD ">
                                                        <label for="exampleInputEmail1">Shipment Gateway Config Type
                                                            ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown optionDisabled="inactive" filter="true" name="ShipmentType" optionlabel="label" formControlName="ShipmentType" [options]="ConfigTypeIDArray" aria-disabled="true" placeholder="Enter Shipment Gateway Config Type ID">
                                                        </p-dropdown>

                                                    </div>

                                                    <div hidden class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">
                                                            Shipment Gateway Config ID</label>
                                                    </div>
                                                    <div hidden class="col-lg-3">
                                                        <input type="email" formControlName="ShipmentTypeID" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Qualification Desc">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes" style="margin-left: -2%;">
                                                        <label for="exampleInputEmail1">
                                                            Shipment Gateway Config Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="ShipmentDescription" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Qualification Desc">
                                                    </div>
                                                </div>
                                            </div>




                                        </div>

                                        <div class="main-submit-button" style="margin-right: 4%;">
                                            <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn" (click)="UpdateShipmentGatewayConfig()">Update</button>
                                            <button type="submit" *ngIf="!show" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Shipment Gateway Config Type</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Create Shipment Gateway Config FedEx </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update Shipment Gateway Config FedEx</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="createShipmentConfigTypeDHL" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">



                                                    <div class="col-lg-3 form-group classInputAB ">
                                                        <label for="exampleInputEmail1">
                                                            Connect URL</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="ConntectURL" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Connect URL">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputBA ">
                                                        <label for="exampleInputEmail1">
                                                            Connect Timeout</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="ConnectTimeout" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Connect Timeout">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputAB ">
                                                        <label for="exampleInputEmail1">
                                                            Head version attribute</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Headattribute" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Head version attribute">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputBA ">
                                                        <label for="exampleInputEmail1">
                                                            Head action attribute</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="actionAttribute" [options]="actionAttributeArray" aria-disabled="true" placeholder="Enter Head action attribute">
                                                        </p-dropdown>
                                                    </div>


                                                    <div class="col-lg-3 form-group classInputAB ">
                                                        <label for="exampleInputEmail1">
                                                            Your DHL ShipIT User ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="ShipITUserID" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your DHL ShipIT User ID">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputBA ">
                                                        <label for="exampleInputEmail1">
                                                            Your DHL ShipIT Access Password</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="password" formControlName="Password" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your DHL ShipIT Access Password">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">
                                                            Your DHL ShipIT Account Number</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="AccountNumber" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your DHL ShipIT Account Number">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputBA">
                                                        <label for="exampleInputEmail1">
                                                            Your DHL ShipIT Shipping Key</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="key" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your DHL ShipIT Shipping Key">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputAB ">
                                                        <label for="exampleInputEmail1">
                                                            Label Image Format</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="labelFormat" [options]="labelFormatArray" aria-disabled="true" placeholder="Enter Label Image Format">
                                                        </p-dropdown>
                                                    </div>



                                                    <div class="col-lg-3 form-group classInputBA ">
                                                        <label for="exampleInputEmail1">
                                                            Rate Estimate API schema</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="APISchema" [options]="APISchemaArray" aria-disabled="true" placeholder="Enter Rate Estimate API schema">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>




                                        </div>

                                        <div class="main-submit-button" style="margin-right: 7%;">
                                            <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn" (click)="updateShipmentGatewayDhl()">Update</button>
                                            <button type="submit" *ngIf="!show" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalFedex" tabindex="-1" role="dialog" aria-labelledby="exampleModalFedexTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Shipment Gateway Config Type</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Create Shipment Gateway Config FedEx </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update Shipment Gateway Config FedEx</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="ShipmentConfigFEDEXform" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">



                                                    <div class="col-lg-3 form-group classInputA ">
                                                        <label for="exampleInputEmail1">
                                                            Connect URL</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="ConnectURL" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Connect URL">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputB ">
                                                        <label for="exampleInputEmail1">
                                                            Connect Soap URL</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="SoapURL" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Connect Soap URL">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA ">
                                                        <label for="exampleInputEmail1">
                                                            Conntect Timeout</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Timeout" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Connect Timeout">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputB ">
                                                        <label for="exampleInputEmail1">
                                                            Access Account Number</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="AccountNumber" [options]="actionAttributeArray" aria-disabled="true" placeholder="Enter Access Account Number  ">
                                                        </p-dropdown>
                                                    </div>




                                                    <div class="col-lg-3 form-group classInputA ">
                                                        <label for="exampleInputEmail1">
                                                            Access Meter Number</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="MeterNumber" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Access Meter Number">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputB ">
                                                        <label for="exampleInputEmail1">
                                                            Access User Key</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="key" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Access User Key">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Access User Password</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="password" formControlName="password" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Access User Password">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">
                                                            Label Image Type</label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" formControlName="labelType" [options]="labelTypeArray" aria-disabled="true" placeholder="Enter Label Image Format">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA ">
                                                        <label for="exampleInputEmail1">
                                                            Dropoff Type</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="DropoffType" [options]="DropoffTypeArray" aria-disabled="true" placeholder="Enter Label Image Format">
                                                        </p-dropdown>
                                                    </div>



                                                    <div class="col-lg-3 form-group classInputB ">
                                                        <label for="exampleInputEmail1">
                                                            Packing Type </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="PackingType" [options]="PackingTypeArray" aria-disabled="true" placeholder="Enter Packing Type">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA ">
                                                        <label for="exampleInputEmail1">
                                                            Shipment Template Location </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="ShipmentLocation" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your DHL ShipIT Shipping Key">
                                                    </div>


                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">
                                                            Subscription Template Location</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Subscription" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your DHL ShipIT Shipping Key">

                                                    </div>


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Rate Estimate Template Location </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="RateEstimate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Your DHL ShipIT Shipping Key">

                                                    </div>


                                                </div>
                                            </div>




                                        </div>

                                        <div class="main-submit-button" style="margin-right: 4%;">
                                            <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn" (click)="updateShipmentGatewayFedex()">Update</button>
                                            <button type="submit" *ngIf="!show" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalUpdateUsps" tabindex="-1" role="dialog" aria-labelledby="exampleModalUpdateUspsTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Update Shipment Gateway Config USPS</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Create Shipment Gateway Config USPS </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update Shipment Gateway Config USPS</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="ShipmentConfigUSPSform" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">



                                                    <div class="col-lg-3 form-group classInputA ">
                                                        <label for="exampleInputEmail1">
                                                            Connect URL</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="ConnectURL" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Connect URL">


                                                    </div>



                                                    <div class="col-lg-3 form-group classInputB ">
                                                        <label for="exampleInputEmail1">
                                                            Connect Url Labels</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Labels" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Connect Url Labels">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA ">
                                                        <label for="exampleInputEmail1">
                                                            Connect Timeout</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Timeout" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Connect Timeout">


                                                    </div>


                                                    <div class="col-lg-3 form-group classInputB ">
                                                        <label for="exampleInputEmail1">
                                                            Access User ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="UserID" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Access User Key">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Access Password</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="password" formControlName="password" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Access Password">

                                                    </div>



                                                    <div class="col-lg-3 form-group classInputB ">
                                                        <label for="exampleInputEmail1">
                                                            Estimate split into packages</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="EstimateSplit" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimate split into packages">
                                                    </div>




                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Test Mode </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="TestMode" [options]="TestModeArray" aria-disabled="true" placeholder="Enter Test Mode">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                        </div>

                                        <div class="main-submit-button" style="margin-right: 4%;">
                                            <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn" (click)="updateShipmentGatewayUsps()">Update</button>
                                            <button type="submit" *ngIf="!show" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalUPS" tabindex="-1" role="dialog" aria-labelledby="exampleModalUPSTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Shipment Gateway Config Type</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Create Shipment Gateway Config UPS </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update Shipment Gateway Config UPS</a></li>

                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="ShipmentConfigUPSform" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">



                                                    <div class="col-lg-3 form-group classInputA ">
                                                        <label for="exampleInputEmail1">
                                                            Connect URL</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="ConnectURL" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Connect URL">


                                                    </div>



                                                    <div class="col-lg-3 form-group classInputB ">
                                                        <label for="exampleInputEmail1">
                                                            Conntect Timeout</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Timeout" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Connect Timeout">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA ">
                                                        <label for="exampleInputEmail1">
                                                            Shipper Number</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="ShipperNumber" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Connect Soap URL">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputB ">
                                                        <label for="exampleInputEmail1">
                                                            Bill Shipper Account Number</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="billShipper" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Bill Shipper Account Number">
                                                    </div>


                                                    <div class="col-lg-3 form-group classInputA ">
                                                        <label for="exampleInputEmail1">
                                                            Access License Number</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="License" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Access Meter Number">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputB ">
                                                        <label for="exampleInputEmail1">
                                                            Access User ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="UserID" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Access User Key">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Access Password</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="password" formControlName="password" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Access Password">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">
                                                            Save Cert Info</label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" formControlName="certInfo" [options]="certInfoArray" aria-disabled="true" placeholder="Enter Save Cert Info">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA ">
                                                        <label for="exampleInputEmail1">
                                                            Save Cert Path</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="path" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Save Cert Path ">
                                                    </div>




                                                    <div class="col-lg-3 form-group classInputB ">
                                                        <label for="exampleInputEmail1">
                                                            Shipper Pickup Type </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="PickupType" [options]="PackingTypeArray" aria-disabled="true" placeholder="Enter Shipper Pickup Type">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA ">
                                                        <label for="exampleInputEmail1">
                                                            Customer Classification </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="Classification" [options]="ClassificationArray" aria-disabled="true" placeholder="Enter Customer Classification">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputB ">
                                                        <label for="exampleInputEmail1">
                                                            Max weight per package</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="weight" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Max weight per package">
                                                    </div>


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Minimum weight for a package</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="MinimumWeight" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Minimum weight for a package">

                                                    </div>


                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">
                                                            Allow Cash On Delivery</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Delivery" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Allow Cash On Delivery">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Surcharge Amount for Cash On Delivery</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="SurchargeAmount" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Surcharge Amount for Cash On Delivery">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">
                                                            Surcharge Currency</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="SurchargeCurr" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter  Surcharge Currency">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA ">
                                                        <label for="exampleInputEmail1">
                                                            Surcharge Apply to Package</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="SurchargeApply" [options]="SurchargeApplyArray" aria-disabled="true" placeholder="Enter Surcharge Apply to Package">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputB ">
                                                        <label for="exampleInputEmail1">
                                                            Funds for Cash on Delivery</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" optionlabel="label" formControlName="FundsCash" [options]="FundsCashArray" aria-disabled="true" placeholder="Enter Funds for Cash on Delivery">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Default Return Label Memo</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="memo" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Return Label Memo">

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">
                                                            Default Return Label Subject</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="subject" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Default Return Label Subject">

                                                    </div>


                                                </div>
                                            </div>

                                        </div>

                                        <div class="main-submit-button" style="margin-right: 4%;">
                                            <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn" (click)="updateShipmentGatewayUps()">Update</button>
                                            <button type="submit" *ngIf="!show" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<ngx-spinner></ngx-spinner>