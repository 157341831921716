import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  activeCategory = 1;

  contentForm: FormGroup;
  createContentForm: FormGroup;
  advanceSearchForm: FormGroup;
  activeCategoryMain: number;
  show: boolean;
  findContentFilterData: any;
  personId: any;
  partyIdArray: any[] = [];
  CalatalogContentTypeId: any;
  CalatalogContentTypeIdArray: any[] = [];
  CalatalogMimeTypeId: any;
  CalatalogMimeTypeIdArray: any[] = [];
  DataResourceId: any;
  DataResourceIdArray: any[] = [];
  CatalogContentId: any;
  CatalogContentIdArray: any[] = [];
  CharacterSetId: any;
  CharacterSetIdArray: any[] = [];
  contentId: any;
  contentIdparams: any;
  navigateContentData: any;
  navigateContentDataArray: any[] = [];
  navigateForm: FormGroup;
  navigateContentTable: any;
  contentSearchResults: any;
  sortOption: { label: string; value: string; }[];
  contentSearchOptions: any;
  contentSearchOptionsArray: any[]=[];
  roleTypes: any;
  roleTypesArray: any[]=[];
  statusArray: { label: string; value: string; }[];
  privilegeEnumID: any;
  privilegeEnumIDArray: any[]=[];
  statusID: any;
  statusIDArray: any[]=[];
  contentIdContent: any;
  contentIdContentArray: any[]=[];
  ContentId: any;
  ContentIdArray: any[]=[];
  localString: any;
  localStringArray: any[]=[];

  constructor(
    readonly _FormBuilder: FormBuilder,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    readonly addSkillService: AddSkillService,
    readonly _ActivatedRoute: ActivatedRoute,
    private _location: Location,
  ) {
    this.navigateForm = this._FormBuilder.group({
      navigateContent: "",
    });
    this.contentForm = this._FormBuilder.group({
      contentId: "",
      contentName: "",
      description: "",
      contentTypeId: "",
      mimeTypeId: "",
      dataResourceId: "",
      ownerContentID: "",
      statusID: "",
      createdByUser: "",
      localeString: "",
    });

    this.createContentForm = this._FormBuilder.group({
      contentID: "",
      contentTypeId: "",
      ownerContentID: "",
      decoratorContentID: "",
      instanceContentID: "",
      dataResourceId: "",
      templateDataResourceID: "",
      dataSourceID: "",
      privilegeEnumID: "",
      serviceName: "",
      customMethodID: "",
      contentName: "",
      description: "",
      localeString: "",
      mimeTypeID: "",
      characterSetID: "",
      childLeafCount: "",
      childBranchCount: "",
      createdDate: "",
      createdByUser: "",
      lastModifiedDate: "",
      lastModifiedByUser: "",
    });

    this.advanceSearchForm = this._FormBuilder.group({
      keywords: "",
      contentID: "",
      contentAssocTypeID: "",
      roleTypeID: "",
      sortedBy: "",
      lastUpdatedDateFilter: "",
      partyID:"",
      throughDate:""
    })
    this.sortOption=[
      {
        label: "Keyword Relevancy",
        value:"SortKeywordRelevancy"
      },
      {
        label: "Content Name",
        value:"SortContentField:contentName"
      }
    ]
    this.statusArray=[
      {
        label: "Available",
        value:"CTNT_AVAILABLE"
      },
      {
        label: "Deactivated",
        value:"CTNT_DEACTIVATED"
      },
      {
        label: "Final Draft",
        value:"CTNT_FINAL_DRAFT"
      },
      {
        label: "In Progress",
        value:"CTNT_IN_PROGRESS"
      },
      {
        label: "Initial Draft",
        value:"CTNT_INITIAL_DRAFT"
      },
      {
        label: "Published",
        value:"CTNT_PUBLISHED"
      },
      {
        label: "Revised Draft",
        value:"CTNT_REVISED_DRAFT"
      },
    ]
  }

  ngOnInit(): void {
    this.show = true;
    this.findContent();
    this.getPartyId();
    this.getCatalogContentId();
    this.getDataResourceId();
    this.getCalatalogMimeTypeId();
    this.getCalatalogContentTypeId();
    this.getCharacterSet();
    this.navigateContent();
    this.getroleTypes();
    this.contentAssocTypes();
    this.getContentId();
    this.getLocalString();

  
  }


backWindow(){
  this._location.back();
}
  toDashboard()
{
this._Router.navigate(['/hub/Dashboard']);
}

  getLocalString() {
    this.spinner.show();
    this.myContactsService.getLocalString().subscribe(res => {
      this.localString = res.data[0].getLocalString;
      this.spinner.hide();
      for (const value of this.localString) {
        this.localStringArray.push({
          label: value.localeString,
          value: value.localeString
        })
      }
    })
  }
  findAdvanceSearch(): void {
    this.spinner.show();
    const requestData = {
      "contentAssocTypeId":  this.advanceSearchForm.value.contentAssocTypeID ? this.advanceSearchForm.value.contentAssocTypeID : '',
      "partyId":  this.advanceSearchForm.value.partyID ? this.advanceSearchForm.value.partyID : '',
      "contentId":   this.advanceSearchForm.value.contentID ? this.advanceSearchForm.value.contentID : '',
      "keyword":  this.advanceSearchForm.value.keywords ? this.advanceSearchForm.value.keywords : '',
      "roleTypeId":   this.advanceSearchForm.value.roleTypeID ? this.advanceSearchForm.value.roleTypeID : '',
    }
    this.myContactsService.findAdvanceSearch(requestData).subscribe((res: any) => {
      if (res.success) {
        this.contentSearchResults = res.data;
        this.spinner.hide();
          this.router.navigate(["/content/content-page/find-detail"],
           { queryParams: { contentAssocTypeID: this.advanceSearchForm.value.contentAssocTypeID, 
            partyID: this.advanceSearchForm.value.partyID, 
            contentID: this.advanceSearchForm.value.contentID, 
            roleTypeID: this.advanceSearchForm.value.roleTypeID, 
            sortedBy: this.advanceSearchForm.value.sortedBy, 
            keywords: this.advanceSearchForm.value.keywords,  } })
        // setTimeout(() => {
        //   localStorage.setItem('contentAssocTypeID',this.advanceSearchForm.value.contentAssocTypeID);
        //   localStorage.setItem('partyID', this.advanceSearchForm.value.partyID);
        //   localStorage.setItem('contentID',this.advanceSearchForm.value.contentID);
        //   localStorage.setItem('contentID',this.advanceSearchForm.value.keywords);
        //   localStorage.setItem('roleTypeID',this.advanceSearchForm.value.roleTypeID);
        //   localStorage.setItem('sortedBy',this.advanceSearchForm.value.sortedBy);
        // }, 1000);
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getContentId() {
    this.spinner.show();
    this.addSkillService.getContentId().subscribe(res => {
      this.ContentId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContentId) {
        this.ContentIdArray.push({
          label: value.contentName,
          value: value.contentId
        })
      }
    })
  }
  contentAssocTypes() {
    this.spinner.show();
    this.myContactsService.ContentSearchOptions().subscribe(res => {
      this.contentSearchOptions = res.data.contentAssocTypes;
      this.spinner.hide();
      for (const value of this.contentSearchOptions) {
        this.contentSearchOptionsArray.push({
          label: value.description,
          value: value.contentAssocTypeId,
          
        })
      }
    })
  }
  getroleTypes() {
    this.spinner.show();
    this.myContactsService.ContentSearchOptions().subscribe(res => {
      this.roleTypes = res.data.roleTypes;
      this.spinner.hide();
      for (const value of this.roleTypes) {
        this.roleTypesArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  reset(){
    this.advanceSearchForm.reset();
    this.findAdvanceSearch();
  }
  ContentSearchResults(): void {
    this.spinner.show();
    const requestData = {
  "contentAssocTypeId":  this.advanceSearchForm.value.contentAssocTypeID ? this.advanceSearchForm.value.contentAssocTypeID : '',
  "fromDate":  this.advanceSearchForm.value.lastUpdatedDateFilter ? this.advanceSearchForm.value.lastUpdatedDateFilter : '',
  "partyId":  this.advanceSearchForm.value.partyID ? this.advanceSearchForm.value.partyID : '',
  "partyRoleTypeId":  this.advanceSearchForm.value.roleTypeID ? this.advanceSearchForm.value.roleTypeID : '',
  "search_CONTENT_ID":  this.advanceSearchForm.value.contentID ? this.advanceSearchForm.value.contentID : '',
  "search_OPERATOR":  "OR" ? "OR": '',
  "search_STRING":  this.advanceSearchForm.value.keywords ? this.advanceSearchForm.value.keywords : '',
  "search_SUB_CONTENTS":   "Y" ?  "Y"  : '',
  "sortAscending":  "Y" ? "Y"  : '',
  "sortOrder":  this.advanceSearchForm.value.sortedBy ? this.advanceSearchForm.value.sortedBy : '',
  "thruDate":  this.advanceSearchForm.value.throughDate ? this.advanceSearchForm.value.throughDate : '',
  "view_SIZE":"25" ? "25" : '',
    }
    this.myContactsService.ContentSearchResults(requestData).subscribe((res: any) => {
      if (res.success) {
        this.contentSearchResults = res.data;
        this.spinner.hide();
      
      
        this.router.navigate(['/content/content-page/find-detail'])

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateAllContentKeywords(): void {
    this.spinner.show();
   
    this.myContactsService.updateAllContentKeywords().subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  removeDocumentFromTree(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "contentAssocTypeId": "SUB_CONTENT",
          "contentId": product.navigateContentNode[0].contentId,
          "contentIdTo": product.data[0].contentIdTo,
          "fromDate":this.datePipe.transform(product.data[0].fromDate,"yyyy-MM-dd hh:mm:ss") 
        }
        this.myContactsService.removeDocumentFromTree(product).subscribe(res => {
          this.navigateContentC();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  navigateContentC() {
    this.spinner.show();
    this.myContactsService.navigateContentC(this.navigateForm.value.navigateContent).subscribe(res => {
      this.navigateContentTable = res.data;
      this.spinner.hide();

    })
  }
  navigateContent() {
    this.spinner.show();
    this.myContactsService.navigateContent().subscribe(res => {
      this.navigateContentData = res.data.getEditContent;
      this.spinner.hide();
      for (const value of this.navigateContentData) {
        this.navigateContentDataArray.push({
          label: value.contentIdTo,
          value: value.contentIdTo
        })
      }
    })
  }
  route_detail_page(id: any) {
    this._Router.navigate(['/content/content-page/content-detail-page'], { queryParams: { contentId: id } });
  }

  createContent(): void {
    this.spinner.show();
    const requestData = {
      "characterSetId": this.createContentForm.value.characterSetID,
      "childBranchCount": this.createContentForm.value.childBranchCount,
      "childLeafCount": this.createContentForm.value.childLeafCount,
      "contentId": this.createContentForm.value.contentID,
      "contentName": this.createContentForm.value.contentName,
      "contentTypeId": this.createContentForm.value.contentTypeId,
      "createdByUserLogin": this.createContentForm.value.createdByUser,
      "createdDate": this.datePipe.transform(this.createContentForm.value.createdDate, "yyyy-MM-dd HH:mm:ss"),
      "customMethodId": this.createContentForm.value.customMethodID,
      "dataResourceId": this.createContentForm.value.dataResourceId,
      "dataSourceId": this.createContentForm.value.dataSourceID,
      "decoratorContentId": this.createContentForm.value.decoratorContentID,
      "description": this.createContentForm.value.description,
      "instanceOfContentId": this.createContentForm.value.instanceContentID,
      "lastModifiedByUserLogin": this.createContentForm.value.lastModifiedByUser,
      "lastModifiedDate": this.datePipe.transform(this.createContentForm.value.lastModifiedDate, "yyyy-MM-dd HH:mm:ss"),
      "localeString": this.createContentForm.value.localeString,
      "mimeTypeId": this.createContentForm.value.mimeTypeID,
      "ownerContentId": this.createContentForm.value.ownerContentID,
      "privilegeEnumId": this.createContentForm.value.privilegeEnumID,
      "serviceName": this.createContentForm.value.serviceName,
      "templateDataResourceId": this.createContentForm.value.templateDataResourceID,
    }
    this.accountsService.createContent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.findContent();
        this.createContentForm.reset();
        this.closebutton1.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCharacterSet() {
    this.spinner.show();
    this.accountsService.getCharacterSet().subscribe(res => {
      this.CharacterSetId = res.data.getCharacterSet;
      this.spinner.hide();
      for (const value of this.CharacterSetId) {
        this.CharacterSetIdArray.push({
          label: value.description,
          value: value.characterSetId
        })
      }
    })
  }
  getCatalogContentId() {
    this.spinner.show();
    this.accountsService.getCatalogContentId().subscribe(res => {
      this.CatalogContentId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.CatalogContentId) {
        this.CatalogContentIdArray.push({
          label: value.contentId,
          value: value.contentId
        })
      }
    })
  }
  getDataResourceId() {
    this.spinner.show();
    this.accountsService.getDataResourceId().subscribe(res => {
      this.DataResourceId = res.data.getDataResourceId;
      this.spinner.hide();
      for (const value of this.DataResourceId) {
        this.DataResourceIdArray.push({
          label: value.dataResourceId,
          value: value.dataResourceId
        })
      }
    })
  }
  getCalatalogMimeTypeId() {
    this.spinner.show();
    this.accountsService.getCalatalogMimeTypeId().subscribe(res => {
      this.CalatalogMimeTypeId = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.CalatalogMimeTypeId) {
        this.CalatalogMimeTypeIdArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }
  getCalatalogContentTypeId() {
    this.spinner.show();
    this.accountsService.getCalatalogContentTypeId().subscribe(res => {
      this.CalatalogContentTypeId = res.data.contentTypeId;
      this.spinner.hide();
      for (const value of this.CalatalogContentTypeId) {
        this.CalatalogContentTypeIdArray.push({
          label: value.description,
          value: value.contentTypeId
        })
      }
    })
  }

  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  resetContent() {
    this.contentForm.reset();
  }
  findContent(): void {
    this.spinner.show();
    const requestData = {
      "contentId": this.contentForm.value.contentId ? this.contentForm.value.contentId : '',
      "contentName": this.contentForm.value.contentName ? this.contentForm.value.contentName : '',
      "contentTypeId": this.contentForm.value.contentTypeId ? this.contentForm.value.contentTypeId : '',
      "createdByUserLogin": this.contentForm.value.createdByUser ? this.contentForm.value.createdByUser : '',
      "dataResourceId": this.contentForm.value.dataResourceId ? this.contentForm.value.dataResourceId : '',
      "description": this.contentForm.value.description ? this.contentForm.value.description : '',
      "localeString": this.contentForm.value.localeString ? this.contentForm.value.localeString : '',
      "mimeTypeId": this.contentForm.value.mimeTypeId ? this.contentForm.value.mimeTypeId : '',
      "ownerContentId": this.contentForm.value.ownerContentId ? this.contentForm.value.ownerContentId : '',
      "statusId": this.contentForm.value.statusID ? this.contentForm.value.statusID : '',
    }
    this.accountsService.findContent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.findContentFilterData = res.data;
        this.spinner.hide();
        this.contentForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

  changeactiveCategory(tab: number) {
    this.show = true;
    this.activeCategoryMain = 0;
    this.activeCategory = tab;

  }
  changeactiveCategoryMain(tab: number) {
    this.show = false;
    this.activeCategory = 0;
    this.activeCategoryMain = tab;

  }

}
