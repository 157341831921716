import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { Location } from '@angular/common';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-entries-timesheet',
  templateUrl: './entries-timesheet.component.html',
  styleUrls: ['./entries-timesheet.component.css']
})
export class EntriesTimesheetComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  activeCategory = 3;
  addTimesheet: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  workEffortId: any;
  TimeSheetEntryByIddata: any;
  FromDate: string;
  ThroughDate: string;
  WorkEffortId: any;
  personId: any;
  WorkEffortRate: any;
  WorkEffortIdArray: any[]=[];
  WorkEffortRateArray: any[]=[];
  partyIdArray: any[]=[];
  statusArray: { label: string; value: string; }[];
  actualfromdate: string;
  actualthroughdate: string;
  timesheetId: any;
  show: boolean;
  updateThroughDate: any;
  Fromdate: string;
  ;
  PartyRolesArray:any[]=[];
  PartyRoles:any;
  partyId:any;

  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly createEventServices: CreateEventServices,
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    private _location: Location,
    
    readonly toastr: ToastrService,
    readonly addSkillService: AddSkillService,
    readonly myContactsService: MyContactsService,
        readonly _FormBuilder: FormBuilder, private _Router : Router
  ) {
    this.addTimesheet = this._FormBuilder.group({
      party: "",
      clientPartyID: "",
      fromDate: "",
      throughDate: "",
      status: "",
      approvedByUserLoginID: "",
      comments: "",
    });
    this.statusArray = [

      {
        label: 'Approved',
        value: 'TIMESHEET_APPROVED'
      },
      {
        label: 'Completed',
        value: 'TIMESHEET_COMPLETED'
      },
      {
        label: 'In-Process',
        value: 'TIMESHEET_IN_PROCESS'
      },
      
    ];
    this.addTimesheet = this._FormBuilder.group({
      party: "",
      fromDate: "",
      throughDate: "",
      rateType: "",
      workEffortID: "",
      hours: "",
      comments: "",
      planHours: "",
    })
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.timesheetId = params['timesheetId'];
      this.workEffortId = params["workEffortId"];
    });
    this.getTimeSheetEntryById();
    this.getWorkEffortId();
    this.getWorkEffortRate();
    this.getPartyId();
  }
 
  onChange(event) {
    console.log(event,"evnt")
    console.log(event.partyId,"evnt party id")
    this.PartyRolesArray = [];
    this.partyId = event;
    if (event) {
      this.getPartyRoles();
    }
    console.log(this.partyId,"par id")

  }
  getPartyRoles() {
    this.spinner.show();
    this.myContactsService.getPartyRoles(this.partyId).subscribe(res => {
      this.PartyRoles = res.data[0].entityQuery;
      this.spinner.hide();
      for (const value of this.PartyRoles) {
        this.PartyRolesArray.push({
          label: value.roleTypeId,
          value: value.roleTypeId
        })
      }
    })
  }
  detailsummarypage(timesheetId){
    this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"],{ queryParams: { workEffortId: timesheetId} })
  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  deleteTimesheetEntry(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "timesheetId":product.data.timesheetId,
          "timeEntryId":product.data.timeEntryId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.deleteTimesheetEntry(req).subscribe(res => {
          this.getTimeSheetEntryById(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  updateTimesheetEntry(): void {
    this.spinner.show();
    const fromDate=this.addTimesheet.get('fromDate').value;
    this.Fromdate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const throughDate=this.addTimesheet.get('throughDate').value;
    this.ThroughDate=this.datePipe.transform(throughDate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
  
  "comments": this.addTimesheet.value.comments,
  "fromDate": this.Fromdate,
  "hours": this.addTimesheet.value.hours,
  "partyId": this.addTimesheet.value.party,
  "planHours": this.addTimesheet.value.planHours,
  "rateTypeId": this.addTimesheet.value.rateType,
  "thruDate": this.ThroughDate,
  "timeEntryId": "11931",
  "timesheetId": this.timesheetId,
  "workEffortId": this.addTimesheet.value.workEffortID
  
   
      }
     
    this.myContactsService.updateTimesheetEntry(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.getTimeSheetEntryById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  updateEvent(product){
    this.show=true;
    this.addTimesheet.reset();
    this.spinner.show();
   
      this.updateThroughDate=product.throughDate;
      setTimeout(() => {
        const fromdate=product.fromDate;
        this.FromDate=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const thrudate=product.throughDate;
        this.ThroughDate=this.datePipe.transform(thrudate,"yyyy-MM-dd");
        const formValue = this.addTimesheet;
        formValue.patchValue({

      "party": product.data.partyId,
      "fromDate": this.FromDate,
      "throughDate": this.ThroughDate,
      "rateType": product.data.rateTypeId,
      "workEffortID": product.data.workEffortId,
      "hours": product.data.hours,
      "comments": product.data.comments,
      "planHours": product.data.planHours,
        })
      
      }, 2000);
  
  }
  createTimesheetEntry(): void {
    this.spinner.show();
    const fromDate=this.addTimesheet.get('fromDate').value;
    this.FromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const throughDate=this.addTimesheet.get('throughDate').value;
    this.ThroughDate=this.datePipe.transform(throughDate,"yyyy-MM-dd hh:mm:ss");
    
     const formData ={
   
      "combineInvoiceItem": "",
      "comments": this.addTimesheet.value.comments,
      "fromDate": this.FromDate,
      "hours": this.addTimesheet.value.hours,
      "partyId": this.addTimesheet.value.party,
      "planHours": this.addTimesheet.value.planHours,
      "rateTypeId": this.addTimesheet.value.rateType,
      "thruDate": this.ThroughDate,
      "timesheetId": this.timesheetId,
      "workEffortId": this.addTimesheet.value.workEffortID
         
     }
     this.myContactsService.createTimesheetEntry(formData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.closebutton1.nativeElement.click();
         this.addTimesheet.reset();
         this.getTimeSheetEntryById();
         
         
        
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
   getTimeSheetEntryById(){
    this.spinner.show();
    this.myContactsService.getTimeSheetEntryById(this.timesheetId).subscribe((res:any)=> {
      this.TimeSheetEntryByIddata = res.data;
      this.spinner.hide();
    
    })   
   }
   createTimeSheetData(): void {
    this.spinner.show();
    const fromActualdate=this.addTimesheet.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.addTimesheet.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
     const requestData ={
   "approvedByUserLoginId": this.addTimesheet.value.approvedByUserLoginID,
  "clientPartyId": this.addTimesheet.value.clientPartyID,
  "comments":this.addTimesheet.value.comments,
  "fromDate":  this.actualfromdate,
  "partyId": this.addTimesheet.value.party,
  "statusId": this.addTimesheet.value.status,
  "thruDate":this.actualthroughdate
       
         
     }
     this.myContactsService.createTimeSheetData(requestData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.addTimesheet.reset();
         this.closebutton1.nativeElement.click();
      
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
   openTimesheet(){
    this._Router.navigate(["/psa/work-effort/timesheet-view/EditTimesheet"],{ queryParams: { timesheetId: this.timesheetId} })
  }
  openParties(){
    this._Router.navigate(["/psa/work-effort/timesheet-view/EditTimesheetRoles"],{ queryParams: { timesheetId: this.timesheetId} })
  }
  
  openEntries(){
    this._Router.navigate(["/psa/work-effort/timesheet-view/EditTimesheetEntries"],{ queryParams: { timesheetId: this.timesheetId} })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  getWorkEffortId() {
    this.spinner.show();
    this.addSkillService.getWorkEffortId().subscribe(res => {
      this.WorkEffortId = res.data[0].getWorkEffortId;
      this.spinner.hide();
      for (const value of this.WorkEffortId) {
        this.WorkEffortIdArray.push({
          label: value.workEffortId,
          value: value.workEffortName
        })
      }
    })
  }
  getWorkEffortRate() {
    this.spinner.show();
    this.addSkillService.getWorkEffortRate().subscribe(res => {
      this.WorkEffortRate = res.data[0].workEffortRate;
      this.spinner.hide();
      for (const value of this.WorkEffortRate) {
        this.WorkEffortRateArray.push({
          label: value.rateTypeId,
          value: value.rateTypeId

        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   }
}
