<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Employees</span>

               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class=" bg-white color-grey">


                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">

                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" style="white-space: nowrap;" >
                                                    <p class="h-text">Find Employees</p>
                                                    <!--new code-->
                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" style="width: 113px;" data-toggle="modal" data-target="#exampleModalCenter">Create New Employee</button>
                                                    <!-- <button style="width: 113px;" type="button"
                                                    class="btn btn-outline-secondary mr-auto" routerLinkActive="active"
                                                    routerLink="/hr/employees/add-employee">Create New Employee</button> -->
                                                    <button style="width: 100px;" type="button" class="btn btn-outline-secondary text-white bg-brown" (click)="importPage()">Import</button>
                                                    <button style="width: 100px;" type="button" class="btn btn-danger text-white" (click)="export()">Export</button>
                                                        
                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 51%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>

                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium  own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Employee ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.partyIdSearchType" 
                                                                    [ngModelOptions]="{standalone:true}" name="party" 
                                                                    [options]="opportunityIdArray" optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="email" [(ngModel)]="advanceSearch.partyId" name="partyId" 
                                                                    [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"  class="pl-1"
                                                                    aria-describedby="emailHelp" placeholder="Enter Employee ID">
                                                                </div>
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">User Login</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.userLogin" [ngModelOptions]="{standlone:true}" name="firstNameFilter" [options]="opportunityIdArray" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="email" [(ngModel)]="advanceSearch.login" name="login"  class="pl-1" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter User Login">
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">First Name</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.firstNameSearchType" [ngModelOptions]="{standlone:true}" name="firstNameFilter" [options]="opportunityIdArray" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="email" [(ngModel)]="advanceSearch.firstName" name="firstName"  class="pl-1" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                                </div>
                
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Last Name</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.lastNameSearchType"  name="lastName" [ngModelOptions]="{standlone:true}" filter="true" name="lastNameFilter" [options]="opportunityIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="email" [(ngModel)]="advanceSearch.lastName"  class="pl-1" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                
                
                
                                                    </div>

                                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -30%;"><button type="submit" (click)="advanceSearchContacts()" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                        <button type="button" class="btn btn-danger ml-2" (click)="resetFilter()">Reset</button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                <!-- <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <p class="h-text">Find Employees</p>
                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" style="width: 113px;" data-toggle="modal" data-target="#exampleModalCenter">Create New Employee</button>
                                        <button style="width: 113px;" type="button"
                                        class="btn btn-outline-secondary mr-auto" routerLinkActive="active"
                                        routerLink="/hr/employees/add-employee">Create New Employee</button>
                                        <button style="width: 100px;" type="button" class="btn btn-outline-secondary text-white bg-brown" (click)="importPage()">Import</button>
                                        <button style="width: 100px;" type="button" class="btn btn-danger text-white" (click)="export()">Export</button>
                                    
                                    </div>
                                    <br>
                                    <div class="all-users-infomation font-13 font-medium  own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Employee ID</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.partyIdSearchType" 
                                                    [ngModelOptions]="{standalone:true}" name="party" 
                                                    [options]="opportunityIdArray" optionlabel="label">
                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="email" [(ngModel)]="advanceSearch.partyId" name="partyId" 
                                                    [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"  class="pl-1"
                                                    aria-describedby="emailHelp" placeholder="Enter Employee ID">
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">User Login</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.userLogin" [ngModelOptions]="{standlone:true}" name="firstNameFilter" [options]="opportunityIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="email" [(ngModel)]="advanceSearch.login" name="login"  class="pl-1" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter User Login">
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">First Name</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.firstNameSearchType" [ngModelOptions]="{standlone:true}" name="firstNameFilter" [options]="opportunityIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="email" [(ngModel)]="advanceSearch.firstName" name="firstName"  class="pl-1" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name">
                                                </div>


                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Last Name</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.lastNameSearchType"  name="lastName" [ngModelOptions]="{standlone:true}" filter="true" name="lastNameFilter" [options]="opportunityIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="email" [(ngModel)]="advanceSearch.lastName"  class="pl-1" name="lastName" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name">
                                                </div>

                                            </div>
                                        </div>




                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -30%;"><button type="submit" (click)="advanceSearchContacts()" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                        <button type="button" class="btn btn-danger ml-2" (click)="resetFilter()">Reset</button>
                                    </div>
                                </div> -->
                                <br>
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Employees List
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">


                                                <div class="card own-account-table">
                                                    <p-table [value]="employeeList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Employee ID
                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="name" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;"> User Login
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;">Name
                                                                        <p-sortIcon field="category"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th pSortableColumn="quantity" [ngStyle]="{'width':'190px'}">
                                                                    <div style="color: white;"> Type
                                                                        <p-sortIcon field="quantity"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td routerLinkActive="active" [ngStyle]="{'width':'190px','text-decoration':'none'}">
                                                                    <div class="checkbox-align">

                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span class="account-button"
                                                                         (click)="employeeDetail(product.partyId)">
                                                                        {{product.partyId}}
                                                                    </span>
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'190px'}">{{product.userLogin}}</td>
                                                                <td [ngStyle]="{'width':'190px'}">{{product.firstName}} {{product.lastName}}</td>
                                                                <td [ngStyle]="{'width':'190px'}">{{product.description}}</td>

                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">{{editMode?'Update':'Create'}} New Employee</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="port-header-contact create-lead font-medium mb-0">
                        {{editMode?'Update':'Create'}} New Employee
                    </div>
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                    Personal Information </a></li>
                                <li [ngClass]="activeCategory==3?'active':''" (click)="statusInfo(employeeForm.value.firstName,employeeForm.value.lastName)"><a>
                            Status</a></li>

                                <li [ngClass]="activeCategory==4?'active':''" (click)="addressValid(employeeForm.value.statusId)"><a>
                            Address </a></li>
                                <li [ngClass]="activeCategory==5?'active':''" (click)="contcatValid(employeeForm.value.address1,employeeForm.value.city,employeeForm.value.postalCode)"><a>
                                    Contact Information  </a></li>
                                <li [ngClass]="activeCategory==6?'active':''" (click)="additionalValida(employeeForm.value.contactNumber)"><a>
                            Additional Info  </a></li>



                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="employeeForm" class="w-100">
                                        <div *ngIf="activeCategory==2">
                                            <!-- <div class="header-tabs">
                                          <h4 class=" common-styling hRight" >
                                            Legal Name
                                          </h4>
                                        
                
                                        </div> -->
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Mr./Ms</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="title" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="title" placeholder="Title">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">First Name <span
                                                                style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="firstName" class="form-control" formControlName="firstName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First Name">
                                                                <small class="text-danger" *ngIf="!!employeeForm.controls.firstName.invalid && !!employeeForm.controls.firstName.touched">First
                                                            Name is required</small>


                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Middle initial</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="middleInitial" class="form-control" formControlName="middleInitial" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Middle initial">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Last Name <span
                                                                style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="lastName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="lastName" placeholder="Last Name">
                                                                <small class="text-danger" *ngIf="!!employeeForm.controls.lastName.invalid && !!employeeForm.controls.lastName.touched">Last
                                                            Name is required</small>


                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">SS No.</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="text" formControlName="lastName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="socialSecurityNumber" placeholder="Enter SS No.">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Gender</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <p-dropdown placeholder="Gender" [options]="gender" formControlName="gender" optionlabel="label">
                                                                </p-dropdown>


                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Date of Birth</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="date" class="form-control" aria-describedby="emailHelp" formControlName="birthDate" placeholder="Date of Birth">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Marital Status</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <p-dropdown placeholder="Select Maritial Status" [options]="maritalStatus" formControlName="maritalStatusEnumId" optionlabel="label">
                                                                </p-dropdown>


                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">U.S. Citizen</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <p-dropdown placeholder="Select" [options]="array" formControlName="usCitizen">
                                                                </p-dropdown>
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Ethnicity</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="text" class="form-control" placeholder="Enter Ethnicity" id="exampleInputEmail1" formControlName="ethnicity">


                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="main-submit-button buttonclass" style="margin-right: 15%; margin-top: 28px;">
                                                        <button type="submit" (click)="legalName(employeeForm.value.firstName,employeeForm.value.lastName)" class="btn btn-secondary submit-btn">Continue</button>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==3">
                                            <!-- <div class="header-tabs">
                                          <h4 class=" common-styling hRight">
                                            Status
                                          </h4>
                                        
                
                                        </div> -->
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-2 form-group classInputA">
                                                                <label for="exampleInputEmail1">Status<span
                                                                style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" formControlName="statusId" [options]="status" placeholder="Status">
                                                                </p-dropdown>
                                                                <small class="text-danger" *ngIf="!!employeeForm.controls.statusId.invalid && !!employeeForm.controls.statusId.touched">
                                                            Status is required</small>
                                                            </div>
                                                            <div class="col-lg-2"></div>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 68%;">
                                                    <button type="submit" (click)="selectStatus(employeeForm.value.statusId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==4">
                                            <!-- <div class="header-tabs">
                                          <h4 class=" common-styling hRight">
                                            Address
                                          </h4>
                                        
                
                                        </div> -->
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInputB">
                                                                <label for="exampleInputEmail1">Address 1 <span
                                                                style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="address1" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Address 1">
                                                                <small class="text-danger" *ngIf="!!employeeForm.controls.address1.invalid && !!employeeForm.controls.address1.touched">Address
                                                            1 is
                                                            required</small>
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Address 2</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="address2" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Address 2">


                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInputB">
                                                                <label for="exampleInputEmail1">City <span
                                                                style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="city" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="City">
                                                                <small class="text-danger" *ngIf="!!employeeForm.controls.city.invalid && !!employeeForm.controls.city.touched">City
                                                            is
                                                            required</small>
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Country</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <p-dropdown placeholder="Country" [options]="countryArray" formControlName="countryGeoId" filter="true" optionlabel="label">
                                                                </p-dropdown>


                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInputB">
                                                                <label for="exampleInputEmail1">State</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <p-dropdown placeholder="State" [options]="stateArray" formControlName="stateProvinceGeoId" filter="true" optionlabel="label">

                                                                </p-dropdown>
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Zip/Postal Code <span
                                                                style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="postalCode" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Postal Code">
                                                                <small class="text-danger" *ngIf="!!employeeForm.controls.postalCode.invalid && !!employeeForm.controls.postalCode.touched">Postal
                                                            is
                                                            required</small>


                                                            </div>


                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="main-submit-button" style="
                                          margin-right: 16%; ">
                                                    <button type="submit" (click)="selectAddress(employeeForm.value.address1,employeeForm.value.city,employeeForm.value.postalCode)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==5">
                                            <!-- <div class="header-tabs">
                                          <h4 class=" common-styling hRight">
                                            Contact
                                          </h4>
                                        
                
                                        </div> -->
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInputB">
                                                                <label for="exampleInputEmail1">Area Code</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="areaCode" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Area Code">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Contact Number <span
                                                                style="color:red">*</span></label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="text" formControlName="contactNumber" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Contact Number">
                                                                <small class="text-danger" *ngIf="!!employeeForm.controls.contactNumber.invalid && !!employeeForm.controls.contactNumber.touched">Contact
                                                            Number is
                                                            required</small>


                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInputB">
                                                                <label for="exampleInputEmail1">Extension </label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="text" formControlName="extension" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Extension">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="exampleInputEmail1">Email</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="emailAddress" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email">


                                                            </div>

                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="main-submit-button" style="
                                          margin-right: 16%;">
                                                    <button type="submit" (click)="selectContact(employeeForm.value.contactNumber)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==6">
                                            <!-- <div class="header-tabs">
                                          <h4 class=" common-styling hRight">
                                            Additional Info 
                                          </h4>
                                        
                
                                        </div> -->
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInputC">
                                                                <label for="exampleInputEmail1">Account Number/Employee ID</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="email" formControlName="emailAddress" class="form-control" formControlName="accountNo" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Account Number/Employee ID">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                                <label for="billing">Billing Rate Level</label>
                                                            </div>
                                                            <div class="col-lg-3">
                                                                <input type="text" class="form-control" placeholder="Billing Rate Level" formControlName="billingRateLevel" id="exampleInputEmail1">


                                                            </div>

                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 5%;">
                                                    <button type="submit" *ngIf="editMode" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                                    <button type="submit" *ngIf="!editMode" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>

                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>