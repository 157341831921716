<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Payments </span>
                <span class="color-black pl-1"> > Create New</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    New incoming payment
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100"  [formGroup]="createPaymentFormService.create_payment_form">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Organization Party ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown 
                                            [options]="OrganizationPartyIdArray" >
                                      
                                           </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">From Party ID</label><span style="color:red">*</span>
                                            </div>
                                           
                                          
                                            <div class="col-lg-2">
                                                <p-dropdown *ngIf="showCreateBtn"  [options]="allPartyIdList" formControlName="partyIdFrom"  optionlabel="label"></p-dropdown>
                                                <input *ngIf="showUpdateBtn" type="email" class="form-control" [(ngModel)]="fromPartyIdByApi" formControlName="partyIdFrom" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Payment Type</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  [options]="allPartyIdList" formControlName="paymentTypeId"  optionlabel="label"></p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInput" *ngIf="showCreateBtn">
                                                <label for="exampleInputEmail1">Payment Method ID</label>
                                            </div>
                                           
                                          
                                            <div class="col-lg-2" *ngIf="showCreateBtn">
                                                <p-dropdown  [options]="allpaymentMethodId" formControlName="paymentMethodId"  optionlabel="label"></p-dropdown>
                                            </div>
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Payment Method ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" [(ngModel)]="fromPartyIdByApi" formControlName="paymentMethodId" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Reference No</label>
                                            </div>
                                           
                                          
                                            <div class="col-lg-2" >
                                                <input type="email" class="form-control" formControlName="paymentRefNum" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Override Gl Account ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" formControlName="overrideGlAccountId" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Amount <span style="color:red">*</span></label>
                                            </div>
                                           
                                          
                                            <div class="col-lg-2" >
                                                <input type="email" class="form-control" formControlName="amount" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Comments</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" formControlName="comments" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                            <div class="col-lg-3 form-group classInput" *ngIf="showCreateBtn">
                                                <label for="exampleInputEmail1">Fin Account ID</label>
                                            </div>
                                           
                                          
                                            <div class="col-lg-2" *ngIf="showCreateBtn">
                                                <p-dropdown  [options]="allFinAccountIdList" formControlName="finAccountTransId"   optionlabel="label"></p-dropdown>
                                            </div>
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12" *ngIf="showUpdateBtn">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Actual Currency Amount</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" formControlName="comments" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Effective Date</label>
                                            </div>
                                           
                                          
                                            <div class="col-lg-2" >
                                                <input type="date" formControlName="effectiveDate" id="birthday" name="birthday">
                                            </div>
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12" *ngIf="showUpdateBtn">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Fin Account Trans Id</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control"  [(ngModel)]="finAccountTransId" formControlName="finAccountTransId" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Status Id</label>
                                            </div>
                                           
                                          
                                            <div class="col-lg-2" >
                                                <input type="email" class="form-control" [(ngModel)]="statusId" formControlName="statusId" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12" *ngIf="showUpdateBtn">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">To Party Id</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" [(ngModel)]="toPartyIdByApi" formControlName="partyIdTo" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Currency</label>
                                            </div>
                                           
                                          
                                            <div class="col-lg-2" >
                                                <p-dropdown filter="true" formControlName="actualCurrencyAmount" [options]="currencyArray" optionlabel="label"  placeholder="Enter Currency"></p-dropdown>
                                            </div>
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12" *ngIf="showUpdateBtn">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Actual Currency Uom Id</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" [(ngModel)]="toPartyIdByApi" formControlName="partyIdTo" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Currency</label>
                                            </div>
                                           
                                          
                                            <div class="col-lg-2" >
                                                <p-dropdown filter="true" formControlName="actualCurrencyUomId" [options]="currencyArray" optionlabel="label"  placeholder="Enter Currency"></p-dropdown>
                                            </div>
                    
                                        </div>
                                    </div>
                                   
                                </div>
                                
                                <div class="col-lg-12 col-12 main-submit-button" *ngIf="showCreateBtn"><button _ngcontent-cta-c128="" type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button></div>
                                <div class="col-lg-12 col-12 main-submit-button" *ngIf="showUpdateBtn"><button _ngcontent-cta-c128="" type="submit" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button></div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 