import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'app-create-work-effort',
  templateUrl: './create-work-effort.component.html',
  styleUrls: ['./create-work-effort.component.css']
})
export class CreateWorkEffortComponent implements OnInit {
  activeCategory=2;
  createWorkEffort:FormGroup
  constructor(readonly router: Router,
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,

    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    
    readonly spinner: NgxSpinnerService,) { 
    this.createWorkEffort = this._FormBuilder.group({
      commEventId: [''],
      workEffortId:[''],
      quickAssignParty: [''],
      name: [''],
      description: [''],
      type: [''],
      status: [''],
      purpose: [''],
      priority: [''],
      scope: [''],
      percentComplete: [''],
      estimStartDate: [''],
      actualStartDate: [''],
      estComleteDate: [''],
      actualCompleteDate: [''],
      reason: [''],
      websiteId: [''],
      workEffortParentID: [''],

      showAsEnumID: [''],
      sendNotificationEmail: [''],
      locationDesc: [''],
      estimatedMillSeconds: [''],
      estimatedSetupMillis: [''],
      estimateCalcMethod: [''],
      actualMilliSeconds: [''],
      actualSetupMillis: [''],
      totalMilliSecond: [''],
      totalMoney: [''],
      currency: [''],
      specialTerms: [''],
      timeTransparency: [''],
      universalID: [''],
      sourceReferenceID: [''],
      fixedAssetID: [''],
      facilityID: [''],
      infoURL: [''],
      temporalExpression: [''],
      serviceLoader: [''],
      quantityToProduce: [''],
      quantityProduced: [''],
      quantityRejected: [''],
      reservPersons: [''],
      reserv2nPPPerc: [''],
      reservNthPPPerc: [''],
      accommodationMapID: [''],
      spotId: [''],
      sequenceNum: [''],
      requirementID: [''],
    
     
 
    });
  }

  ngOnInit(): void {
  }
  cancelSubmit(){
    this.router.navigate(['/crm/crm-overview']);
  }
}
