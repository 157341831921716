<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Document type template</span>
                <span class="color-black pl-1" > >Add document type template </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Add document type template</a></li>
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createDoc">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" *ngIf="this.show">
                                                <label for="exampleInputEmail1">
                                                
                                                    Document Type</label>
                                            </div>
                                         
                                            <div class="col-lg-2" *ngIf="this.show">
                                                <p-dropdown  placeholder="Enter Document Type" filter="true"
                                                formControlName="documentType" 
                                                [options]="dropdownValue" optionlabel="label" 
                                                (onChange)="onChange($event)">
                                                                
                                                </p-dropdown>
                                        

                                            </div>
                                            <div class="col-lg-3 form-group classInput" *ngIf="!this.show">
                                                <label for="exampleInputEmail1" *ngIf="this.InvoiceDropdown">
                                                
                                                    Invoice Type<span
                                                    style="color:red">*</span></label>
                                                    <label for="exampleInputEmail1" *ngIf="this.OrderDropdown">
                                                
                                                        Order Type<span
                                                        style="color:red">*</span></label>
                                                        <label for="exampleInputEmail1" *ngIf="this.QuoteDropdowm">
                                                
                                                            Quote Type<span
                                                            style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown *ngIf="this.InvoiceDropdown"  placeholder="Enter Type" filter="true"
                                                formControlName="invoiceTypeId" 
                                                [options]="typeIdArray" optionlabel="label" 
                                              >
                                                                
                                                </p-dropdown>
                                                <p-dropdown *ngIf="this.OrderDropdown"  placeholder="Enter Type" filter="true"
                                                formControlName="orderTypeId" 
                                                [options]="typeIdArrayOrder" optionlabel="label" 
                                              >
                                                                
                                                </p-dropdown>
                                                <p-dropdown *ngIf="this.QuoteDropdowm"  placeholder="Enter Type" filter="true"
                                                formControlName="quoteTypeId" 
                                                [options]="quoteTypeIdArray" optionlabel="label" 
                                              >
                                                                
                                                </p-dropdown>
                                            </div>

                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12" *ngIf="!this.show">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                
                                                    Form Date<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date"  formControlName="fromDate"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter From Date"
                                              >

                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                
                                                    Custom screen</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Custom screen" filter="true"
                                            formControlName="customScreenId" 
                                            [options]="customerScreen" optionlabel="label" 
                                          >
                                                            
                                            </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>

                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" (click)="onSubmit();" style="margin-left: 58%;">
                               
                                <button type="submit"   class="btn btn-secondary submit-btn">Select Button</button>
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
