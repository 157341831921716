<div class="container-fluid main-container-wrapper" style="margin-top: 53px !important;">

    <!-- <div class="row " style="background-color: #0492C2; padding-top: 6px !important;">
        

        <div class="col-lg-1">
           <span class="color-black pl-1 titlePanels" style="cursor: pointer; color: white; font-weight: bold;">Find </span>
        </div>
            <div class="col-lg-2" style="margin-left: -4% !important;">
                <p-dropdown filter="true"
                [options]="" optionlabel="label"
                placeholder="Work Order Number"
                >
                </p-dropdown>

            </div>

            <div class="col-lg-2" style="margin-left: -2% !important;">
                <input type="text" class="form-control" id=""  >
            </div>

            <div class="col-lg-1" style="margin-left: -2% !important;">
                <button type="submit" class="btn btn-secondary submit-btn">Search</button>

            </div>
        

        <div class="col-lg-4">
        </div>
            <div class="col-lg-2" style="margin-left: 7% !important;">
                <p-dropdown filter="true"
                [options]="" optionlabel="label"
                placeholder="Recent Items"
                >
                </p-dropdown>

            </div>

        

    

</div> -->

<div class="row" style="background-color:  #0492C2; padding-bottom: 6px !important; padding-top: 4px !important;">

<!-- <div class="col-lg-3   ">
    <button type="submit" class="btn btn-secondary submit-btn" style="margin-left: -2%;">
        Dispatch Board
     </button>/usr/share/code/resources/app/out/vs/code/electron-browser/workbench/workbench.html
</div> -->

<div class="col-lg-2 " style="margin-left: 60% !important;">
    <button (click)="toWorkOrder()" style="background-color: #1338BE; 
    color: white !important; font-weight: bold;"> Work Orders</button>
</div>

<div class="col-lg-2 " style="margin-left: -8% !important;">
    <button (click)="toCustomer()" style="background-color: #48AAAD; 
    color: white !important; font-weight: bold;">Customer</button>

</div>               
   
<div class="col-lg-1 " style="margin-left: -10% !important;">
    <button (click)="toInvoices()" style="background-color:#016064;
     color: white; font-weight: bold;">Invoices</button>

</div>                
    
<!-- <div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toReports()" style="background-color: #1520A6; 
    color: white; font-weight: bold;">Reports</button>

</div>               

<div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toPeople()" style="background-color:#59788E;
     color: white !important; font-weight: bold; padding-left: 12px; padding-right: 12px;">Staff</button>

</div> -->
<div class="col-lg-2 " style="margin-left: -2% !important;">
    <button (click)="toTimeSheet()" style="background-color: #1F456E;
     color: white !important; font-weight: bold;">Time Sheet</button>

</div>                 

<div class="col-lg-1 " style="margin-left: -9% !important;">
    <button (click)="toSetting()" style="background-color:#757c88 ; 
    color: white; font-weight: bold; ">Settings</button>

</div>



</div>

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            <div class="col-md-12 p-0" >
                <div class="w3-card-4 classCard  w-100" style="padding-bottom: 2%;"> 
           

                   
                
                                <div>
                                    <a class="btn btn-link" style="font-size:19px !important; font-weight: bold;">Mobile Settings</a>
                
                                </div>
                            <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                            " (click)="toMyWorkSettings()"  >My Work Settings</a>
                            <p>You can choose the type and order of fields that appear on the device's 
                                My Work screen.
                            </p>
                    
                            <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                            " (click)="toPrimaryWorkDetails()">Primary Work Order Details</a>
                            <p>You can choose the type and order of fields that appear on the devices Work Order Details 
                                screen.
                             
                            </p>
                    
                            <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                            " (click)="toNonPrimaryWorkDetails()">Non-pri. Work Order Details</a>
                            <p>You can choose the type and order of fields that appear on the device's Non-primary Work
                                Order Details screen.
                                
                            </p>

                            <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                            " (click)="toDeviceSettings()">Device Settings</a>
                            <p>You can adjust GPS, Polling and Auto Start settings.
                                
                            </p>
                </div>    
            </div> 
                        
                    
                        
        </div>
</div>
