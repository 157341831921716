<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class=" bg-white color-grey">
                <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">

                    <span class="color-black pl-1">Edit Personal Information </span>
                    <button type="submit" class="btn btn-danger buttonclass" (click)="onCancel()">Cancel</button>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form *ngIf="leadPersonalFormService.lead_personal_form" [formGroup]="leadPersonalFormService.lead_personal_form" class="w-100">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Party Id 
                                                </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="partyId" [(ngModel)]="partyIds" [ngModelOptions]="{standalone: true}" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  readonly>

                                                </div>

                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">External Id </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" [(ngModel)]="externalId" formControlName="externalId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External Id">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">First Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" [(ngModel)]="firstName" formControlName="firstName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First name">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Last Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" [(ngModel)]="lastName" formControlName="lastName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last name">
                                                </div>


                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Comments</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" [(ngModel)]="comments" formControlName="comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Status Id</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="crmArray" [(ngModel)]="preSelectedstatus" formControlName="statusId"></p-dropdown>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                    <div class="main-submit-button" style="margin-right: 33%;">
                        <button style="margin-left: 82%;" type="submit" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>


                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>