

<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            
              
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">TimeSheet</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                    <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span> 
            </div>
            <div class=" bg-white color-grey">


                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                TimeSheet List
                                            </h4>

                                            <button type="submit" class="btn btn-secondary submit-btn mr-2 " (click)="reset()"
                                                data-target="#timeSheetPopup" data-toggle="modal">
                                                Add TimeSheet
                                            </button>
                                            
                                        </div>
                                    </header>
                                
                                    <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">
                                                <div class="card own-account-table">
                                                    <p-table [value]="fsmTimeCard" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                                        [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                                            binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                    
                                                                        Time Sheet ID
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'125px'}" pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Technician ID
                                                                    </div>
                                                                    <div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'125px'}" pSortableColumn="category">
                                                                    <div style="color: white;">
                                                                        Technician Name
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                                                    <div style="color: white;">
                                                                        Check-In</div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                                                    <div style="color: white;">
                                                                        Check-Out
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th> <th [ngStyle]="{'width':'200px'}" pSortableColumn="category">
                                                                    <div style="color: white;">
                                                                        Action
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                    
                    
                    
                    
                    
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                                            binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        <span (click)="referToSummary(product.timeCardId)" class="account-button">
                                                                            {{product.timeCardId}}</span>
                    
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'125px'}">
                                                                    {{product.technicianId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'125px'}">
                                                                    {{product.technicianName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'150px' }">
                                                                    {{product.createdStamp| date:'yyyy-MM-dd hh:mm:ss'}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'150px'}">
                                                                    {{product.lastUpdatedStamp | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                </td>
                    
                                                                <td [ngStyle]="{'width':'200px'}">
                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2 "
                                                                        (click)="getFsmTimeCardById(product)" data-target="#timeSheetPopup"
                                                                        data-toggle="modal">
                                                                        Update
                                                                    </button>
                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2 "
                                                                        (click)="deleteFsmTimeCard(product) ">
                                                                        Delete
                                                                    </button>
                                                                </td>
                    
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                        <h4 class="ml-2">No
                                                                            Record Found
                                                                        </h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page
                    
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<div class="modal fade" id="timeSheetPopup" tabindex="-1" role="dialog" aria-labelledby="timeSheetPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add TimeSheet</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add TimeSheet</a>
                                </li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="timeSheetForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                   

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Technician ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                       
                                                        <p-dropdown filter="true" (onChange)="onChangeTechId($event.value)"
                                                        formControlName="technicianId"
                                                        [options]="technicalAllArray" optionlabel="label">
                                                    </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm" *ngIf="!show">
                                                        <label for="exampleInputEmail1" >Check-In</label>
                                                    </div>
                                                    <div class="col-lg-1"  *ngIf="!show">
                                                        <p-checkbox     formControlName="checkInDateTime" styleclass="custom-checkbox"
                                                        binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                           <div class="custom-checkbox ui-chkbox ui-widget">
                                                               <div class="ui-helper-hidden-accessible">
                                                                   <input type="checkbox" name="undefined"
                                                                    value="undefined"></div>
                                                               <div role="checkbox" class="ui-chkbox-box ui-widget
                                                                ui-corner-all ui-state-default">
                                                                   <span class="ui-chkbox-icon ui-clickable"></span>
                                                               </div>
                                                           </div>
                                                          
                                                       </p-checkbox>
                                                      

                                                          
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm"  *ngIf="show">
                                                        <label for="exampleInputEmail1">Check-Out</label>
                                                    </div>
                                                    <div class="col-lg-1"  *ngIf="show">

                                                        <p-checkbox   formControlName="checkOutDateTime" styleclass="custom-checkbox"
                                                         binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                     value="undefined"></div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget
                                                                 ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                           
                                                        </p-checkbox>
                                                      

                                                       

                                                    </div>
                                                </div>
                                            </div>

                                         

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 38%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="createFsmTimeCard()" *ngIf="!show">Create</button>

                                                <button type="submit" class="btn btn-secondary submit-btn" *ngIf="show"
                                                    (click)="updateFsmTimeCard()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>