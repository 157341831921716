import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
@Component({
  selector: 'app-add-tax-auth-detail',
  templateUrl: './add-tax-auth-detail.component.html',
  styleUrls: ['./add-tax-auth-detail.component.css']
})
export class AddTaxAuthDetailComponent implements OnInit {
  activeCategory=2;
  createTax:FormGroup;
  exemption: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[]=[];
  getGeoTy: any;
  getGeoTyArray: any[]=[];
  party: any;
  geo: any;
  show: boolean;
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
  
    readonly addSkillService: AddSkillService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createTax = this._FormBuilder.group({
        Geo: [''],
        Party:[''],
        exemption:[''],
        pattern:[''],
        price:['']
       
        
      });
      this.exemption=[{"label":"Y","value":"Y"},{"label":"N","value":"N"}]
    }

  ngOnInit(): void {
    this.getPartyId();
    this.getgeoByType();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.geo = params["geo"];
   
    });
    if(this.party){
      this.show=true;
      this.taxAuthorityDetail();
    }else{
      this.show=false;
    }
  }
  
  taxAuthorityDetail() {
    this.spinner.show();
    this.accountsService.taxAuthorityDetail(this.geo,this.party).subscribe(res => {
      setTimeout(() => {
       
        const formValue = this.createTax;
        formValue.patchValue({
          Geo: res.data.taxAuthGeoId,
          Party:res.data.taxAuthPartyId,
          exemption:res.data.requireTaxIdForExemption,
          pattern:res.data.taxIdFormatPattern,
          price:res.data.includeTaxInPrice,
  
        })
      
      }, 3000);
    
    })
    
  }
  getgeoByType() {
    this.spinner.show();
    this.accountsService.getgeoByType().subscribe(res => {
      this.getGeoTy = res.data;
      this.spinner.hide();
      for (const value of this.getGeoTy) {
        this.getGeoTyArray.push({
          label: value.geoId,
          value: value.geoId
        })
      }
    })
    
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "includeTaxInPrice": this.createTax.value.price,
      "requireTaxIdForExemption":this.createTax.value.exemption,
      "taxAuthGeoId": this.createTax.value.Geo,
      "taxAuthPartyId":this.createTax.value.Party,
      "taxIdFormatPattern": this.createTax.value.pattern,
    }
    this.accountsService.updateTaxAuthorities(requestData).subscribe((res: any) => {  
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(["financial/tax-authority"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "includeTaxInPrice": this.createTax.value.price,
      "requireTaxIdForExemption":this.createTax.value.exemption,
      "taxAuthGeoId": this.createTax.value.Geo,
      "taxAuthPartyId":this.createTax.value.Party,
      "taxIdFormatPattern": this.createTax.value.pattern,
    }
    this.accountsService.postTaxAuthorities(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(["financial/tax-authority"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
   
  cancelSubmit(){
    this._Router.navigate(["financial/tax-authority"])
}}
