import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.css']
})
export class CreateEmployeeComponent implements OnInit {
  solict = [
    {
        label: 'Y',
        value: 'Y'
    },
    {
        label: 'N',
        value: 'N'

    }
];
  show=false;
  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  createNewProspect: FormGroup;
  stateArray: any = [];
  stateData: any;
  countryArray:any[]=[];
  countryId: any;
  country: any;
  productStoresListArray: any[]=[];
  

  constructor(readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService, 
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    private _location: Location,
    ) {
    this.createNewProspect = this._FormBuilder.group({

      partyId: [''],
      title:[''],
      firstName:[''],
      middleInitial:[''],
      lastName:[''],
      suffix:[''],
      faxSolict:[''],
      homeSolic:[''],
      userAddress1:[''],
      userAddress2:[''],
      userCity:[''],
      userZip:[''],
      country:[''],
      state:[''],
      workSolic:[''],
      homeCountry:[''],
      homeArea:[''],
      homePhone:[''],
      homeExt:[''],
      emailsolicitation:[''],
      mobileSolict:[''],
      workCountry:[''],
      workArea:[''],
      workPhone:[''],
      workExt:[''],
      addSolic:[''],
      faxCountry:[''],
      faxArea:[''],
      faxPhone:[''],
      faxExt:[''],

      mobileCountry:[''],
      mobileArea:[''],
      mobilePhone:[''],
      mobileExt:[''],

      email:[''],
      userName:[''],
      pass:[''],
      confirmPass:[''],
      productStore:['']
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getCountryList();
    this.getOrderListItems();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/partyQuickLink/party-dashboard']);
  }

  changeactiveCategoryFunction(tab: number) {
    
    this.activeCategory = tab;
    
   
  }
  GoToQuickLink(){
    this.router.navigate(['/partyQuickLink']);
  }




  getCountryList() {
    this.spinner.show();
    this.accountsService.getCountry().subscribe(res => {
  
      var country = res.data.countryList;
      this.spinner.hide();
      this.spinner.hide();
      country.forEach(element => {
        this.countryArray.push({
          label: element.split(':')[0].trim(),
          value: element.split(':').pop().trim()
        });
      })
    })
  }



  onCountryChange(event) {
    this.stateArray = [];
    this.country = event;
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  }
 
  getStateList() {
    this.spinner.show();
    this.accountsService.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        const splitState = stateLists.split(":");
        const stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    }) 
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  getOrderListItems(): void {
    this.spinner.show();
    this.accountsService.getOrderList().subscribe((res) => {
      let productStores = res.data.productStores;
      this.spinner.hide();
      for (const value of productStores) {
        this.productStoresListArray.push({
          label: value.storeName,
          value: value.productStoreId,
        });
      }
    });
   
  }

  onSubmit(): void {
    this.spinner.show();
    const requestData = {

        "CONFIRM_PASSWORD": this.createNewProspect.value.confirmPass,
        "PASSWORD": this.createNewProspect.value.pass,
        "PRODUCT_STORE_ID": this.createNewProspect.value.productStore ,
        "USERNAME": this.createNewProspect.value.userName,
        "USER_ADDRESS1": this.createNewProspect.value.userAddress1,
        "USER_ADDRESS2": this.createNewProspect.value.userAddress2,
        "USER_ADDRESS_ALLOW_SOL":this.createNewProspect.value.addSolic,
        "USER_CITY": this.createNewProspect.value.userCity ,
        "USER_COUNTRY": this.createNewProspect.value.country ,
        "USER_EMAIL": this.createNewProspect.value.email ,
        "USER_EMAIL_ALLOW_SOL":this.createNewProspect.value.emailsolicitation,
        "USER_FAX_ALLOW_SOL": this.createNewProspect.value.faxSolict,
        "USER_FAX_AREA": this.createNewProspect.value.faxArea ,
        "USER_FAX_CONTACT": this.createNewProspect.value.faxPhone ,
        "USER_FAX_COUNTRY": this.createNewProspect.value.faxCountry ,
        "USER_FAX_EXT": this.createNewProspect.value.faxExt ,
        "USER_FIRST_NAME": this.createNewProspect.value.firstName ,
        "USER_HOME_ALLOW_SOL":this.createNewProspect.value.homeSolic,
        "USER_HOME_AREA": this.createNewProspect.value.homeArea ,
        "USER_HOME_CONTACT": this.createNewProspect.value.homePhone,
        "USER_HOME_COUNTRY":  this.createNewProspect.value.homeCountry,
        "USER_HOME_EXT": this.createNewProspect.value.homeExt,
        "USER_LAST_NAME": this.createNewProspect.value.lastName,
        "USER_MIDDLE_NAME": this.createNewProspect.value.middleInitial,
        "USER_MOBILE_ALLOW_SOL": this.createNewProspect.value.mobileSolict,
        "USER_MOBILE_AREA": this.createNewProspect.value.mobileArea ,
        "USER_MOBILE_CONTACT": this.createNewProspect.value.mobilePhone ,
        "USER_MOBILE_COUNTRY": this.createNewProspect.value.mobileCountry,
        "USER_POSTAL_CODE": this.createNewProspect.value.userZip,
        "USER_STATE": this.createNewProspect.value.state ,
        "USER_SUFFIX": this.createNewProspect.value.suffix,
        "USER_TITLE": this.createNewProspect.value.title,
        "USER_WORK_ALLOW_SOL":this.createNewProspect.value.workSolic,
        "USER_WORK_AREA": this.createNewProspect.value.workArea,
        "USER_WORK_CONTACT": this.createNewProspect.value.workContact,
        "USER_WORK_COUNTRY": this.createNewProspect.value.workCountry,
        "USER_WORK_EXT": this.createNewProspect.value.workExt,
        "USE_ADDRESS": "",
        "require_email": ""
       
    }

    this.accountsService.postCreateEmployee(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/partyQuickLink/find-party']);
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.debugMessage);
        }
    });
  
  }
  Address(firstName,lastName){
    if(firstName && lastName){
      this.activeCategory=2;
    }
    else{
      this.toastr.error("Fill all mandatory fields.");
    }
  }
  mailAddress(address,city,postal,){
    if(address && city && postal){
      this.activeCategory=3;
    }
    else{
      this.toastr.error("Fill all mandatory fields.");
    }
  }
  homeAddress(code,area,phone,solic){
    if(code&&area&&phone&&solic){
      this.activeCategory=4;
    }
    else{
      this.toastr.error("Fill all mandatory fields.");
    }
  }
  workAddress(code,area,phone,solic){
    if(code&&area&&phone&&solic){
      this.activeCategory=5;
    }
    else{
      this.toastr.error("Fill all mandatory fields.");
    }
  }
  faxAddress(code,area,phone,solic){
    if(code&&area&&phone&&solic){
      this.activeCategory=6;
    }
    else{
      this.toastr.error("Fill all mandatory fields.");
    }
  }
  mobileAddress(code,area,phone,solic){
    if(code&&area&&phone&&solic){
      this.activeCategory=7;
    }
    else{
      this.toastr.error("Fill all mandatory fields.");
    }
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
