<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
                <span class="color-black pl-1 titlePanels">Summary</span>
           
                <span class="">
 
                 <button type="submit" (click)="homeButton();"
                     class=" btn btn-outline-secondary mr-2">Home</button>
         
                 <button type="submit" (click)="cancelSubmit();"
                     class="btn btn-danger buttonclass ">Back</button>
             </span>  </div>

            <div class="bg-white color-grey w-100 mt-2">
                <div class="container-fluid">
                    <div class="row">
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div class="d-flex bd-highlight justify-content-between port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Open Requirement
                                    <button type="submit" class="btn btn-danger ml-2" (click)="toMain()">Cancel</button>
                                </div>
                                <div class="create-new-leade">
                                    <div class="container-fluid" style="padding: 0;">
                                        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div class="w-100">
                                                <div class="w3-card-4 classCard w-100 mt-2">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                        style="justify-content: space-between;">
                                                        <p class="h-text">Summary</p>
                                                        <span>
                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                style="margin: 0;" data-toggle="modal"
                                                                data-target="#updatePopup">Update</button>
                                                            
                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        
                                                        <div class="color-black container">
                                                            <div class="row" *ngIf="requirementPlanningById">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Requirement Type Id :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">
                                                                                {{requirementPlanningById.requirementTypeId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Facility Id
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.facilityId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Deliverable Id
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.deliverableId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Fixed Asset Id
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.fixedAssetId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Product Id
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.productId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Status Id
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.statusId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Description:</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.description}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Requirement Start Date
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.requirementStartDate | date:"yyyy-MM-dd hh:mm:ss"}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Required By Date
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.requiredByDate | date:"yyyy-MM-dd hh:mm:ss"}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Estimated Budget
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.estimatedBudget}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Quantity :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.quantity}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Use Case
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.useCase}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Reason :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.reason}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Order Item Type Id
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.orderItemTypeId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Facility Id To :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.facilityIdTo}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Cust Request Id :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.custRequestId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Cust Request Item Seq Id :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.custRequestItemSeqId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                               </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="updatePopup" tabindex="-1" role="dialog" aria-labelledby="updatePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Open Requirement

                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update Open Requirement
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <form [formGroup]="createRequirementForm">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Requirement Type Id
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" optionlabel="label" 
                                                    formControlName="requirementTypeId" [options]="requirementTypeIdArray"
                                                    placeholder="Choose Requirement Type Id">
        
                                                    </p-dropdown>
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Facility Id
                                                    </label>
                                                </div>
        
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" optionlabel="label" 
                                                    formControlName="facilityId"  [options]="facilityArray"
                                                    placeholder="Choose Facility Id">
        
                                                    </p-dropdown>
                                                    
                                                </div>
                                            </div>
                                        </div>
        
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Deliverable Id
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="deliverableId" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Deliverable Id">
                                        
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Fixed Asset Id
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" optionlabel="label" 
                                                    formControlName="fixedAssetId"  [options]="fixedAssetIdArray"
                                                    placeholder="Choose Fixed Asset Id">
        
                                                    </p-dropdown>
                                                    
                                                </div>
        
                                            
                                            </div>
                                        </div>
        
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Product Id
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">

                                                    <p-dropdown filter="true" optionlabel="label" 
                                                    formControlName="productId"  [options]="ProductIdArray"
                                                    placeholder="Choose Product Id">
        
                                                    </p-dropdown>
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Status Id
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" optionlabel="label" 
                                                    formControlName="statusId"  [options]="statusIdArray"
                                                    placeholder="Choose Status Id">
        
                                                    </p-dropdown>
                                                </div>
        
                                            
                                            </div>
                                        </div>
        
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Description
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Description">
                                    
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Requirement Start Date
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date"  formControlName="requirementStartDate" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Requirement Start Date">
                                    
                                                </div>
        
                                            
                                            </div>
                                        </div>
        
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Required By Date
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date"  formControlName="requiredByDate" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Required By Date">
                                    
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Estimated Budget

                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="estimatedBudget" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Estimated Budget">
                                    
                                                    
                                        
                                                </div>
        
                                            
                                            </div>
                                        </div>
        
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Quantity
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="quantity" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Quantity">
                                    
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Use Case</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="useCase" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Use Case">
                                    
                                                    
                                        
                                                </div>
        
                                            
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Reason
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="reason" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Reason">
                                    
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Order Item Type Id</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" optionlabel="label" 
                                                    formControlName="orderItemTypeId"  [options]="orderItemTypeIdArray"
                                                    placeholder="Enter Order Item Type Id">

                                                    </p-dropdown>
                                    
                                                </div>
        
                                            
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Facility Id To

                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="facilityIdTo" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Facility Id To">
                                    
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Cust Request Id</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="custRequestId" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Cust Request Id">
                                    
                                                </div>
        
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Cust Request Item Seq Id

                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="custRequestItemSeqId" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Cust Request Item Seq Id">
                                    
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Group Name

                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="groupName"
                                                     class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Group Name">
                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="UpdateRequirementPlanning()">Update</button>
                                        </div>
        
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 
