<div class="container-fluid main-container-wrapper">
    <div class="row">
         <div class="col-12 pad_0">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels">
                </span>

                <span class="">

                    <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

                    <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
                </span>

            </div>
            <div class=" bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard mt-20 border_wdth_1">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="summary()"><a> Summary </a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="editWorkEffort1()"><a> Work Effort </a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="childWorkEfforts()"><a> Children </a></li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="partyAssigns()"><a> Parties </a></li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="rates()"><a> Rates </a></li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="commEvents()"><a> Communications </a></li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="shopLists()"><a> Shopping Lists </a></li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="requests()"><a> Requests </a></li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="requirements()"><a> Requirements </a></li>
                            <li [ngClass]="activeCategory==10?'active':''" (click)="quotes()"><a> Quotes </a></li>
                            <li [ngClass]="activeCategory==11?'active':''" (click)="orderHeaders()"><a> Orders </a></li>
                            <li [ngClass]="activeCategory==12?'active':''" (click)="timeEntries()"><a> Time Entries </a></li>
                            <li [ngClass]="activeCategory==13?'active':''" (click)="notes()"><a> Notes </a></li>
                            <li [ngClass]="activeCategory==14?'active':''" (click)="contents()"><a> Content </a></li>
                            <li [ngClass]="activeCategory==15?'active':''" (click)="products()"><a> Product </a></li>
                            <li [ngClass]="activeCategory==16?'active':''" (click)="reviews()"><a> Reviews </a></li>
                            <li [ngClass]="activeCategory==17?'active':''" (click)="keywords()"><a> Keywords </a></li>
                            <li [ngClass]="activeCategory==18?'active':''" (click)="contactMechs()"><a> Contact Mechs </a></li>
                            <li [ngClass]="activeCategory==19?'active':''" (click)="agreementApplics()"><a> Agreement Applics </a></li>
                            <li [ngClass]="activeCategory==20?'active':''" (click)="fixedAssets()"><a> Fixed Assets </a></li>
                            <li [ngClass]="activeCategory==21?'active':''" (click)="attributes()"><a> Attributes </a></li>
                            <li [ngClass]="activeCategory==22?'active':''" (click)="eventReminders()"><a> Event Reminders </a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" (click)="addWorkEffort()"> Create New Work Effort</button>
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCardView mt-2 w-100">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">Work Effort Quotes
                                                <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#update"> Add Quote </button>
                                            </div>
                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="card own-account-table">
                                                        <p-table [value]="QuoteByIddata" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white; padding-left: 5px !important;">
                                                                            Quote ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>

                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white; padding-left: 5px !important;">
                                                                            Quote Name
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>

                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white; padding-left: 5px !important;">
                                                                            Description
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>

                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white; padding-left: 5px !important;">
                                                                            Status
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>

                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white; padding-left: 5px !important;">
                                                                            Issue Date
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>

                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Action
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                        <span>{{product.node1.quoteId}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                        <span>{{product.node2[0].quoteName}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                        <span>{{product.node2[0].description}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                        <span>{{product.node2[0].statusId}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                        <span>{{product.node2[0].issueDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <a (click)="deleteWorkEffortQuote(product)"  class="account-button" >Remove</a>
                                                                    </td>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td colspan="6">
                                                                        <span class="text-center">
                                                                                <h4 class="ml-2">No Record Found
                                                                                </h4>
                                                                            </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCardView mt-2 w-100">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#updateItem"> Add Quote Item </button>
                                            </div>
                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="card own-account-table">
                                                        <p-table [value]="QuoteItemListdata" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white; padding-left: 5px !important;">
                                                                            Quote ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Quote Item Seq ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white; padding-left: 5px !important;">
                                                                            Product ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white; padding-left: 5px !important;">
                                                                            Cust Request ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white; padding-left: 5px !important;">
                                                                            Cust Request Item Seq ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white; padding-left: 5px !important;">
                                                                            Est. Deliver Date
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white; padding-left: 5px !important;">
                                                                            Comments
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Action
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                        <span>{{product.node1.quoteId}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                        <span>{{product.node1.quoteItemSeqId}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                        <span>{{product.node1.productId}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                        <span>{{product.node1.custRequestId}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                        <span>{{product.node1.custRequestItemSeqId}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                        <span>{{product.node1.estimatedDeliveryDate}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                        <span>{{product.node1.comments}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <a *ngIf="!this.Hidebtn" (click)="deleteWorkEffortQuoteItem(product)"  class="account-button" >Remove</a>
                                                                    </td>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td colspan="8">
                                                                        <span class="text-center">
                                                                            <h4 class="ml-2">No Record Found
                                                                            </h4>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="update" tabindex="-1" role="dialog" aria-labelledby="update" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Work Effort Quote </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Quote </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addQuote">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Quote ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="quoteID" [options]="QuoteIdListArray" optionlabel="label" placeholder="Enter Quote ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 main-submit-button">
                                            <button type="submit" (click)="createWorkEffortQuote()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updateItem" tabindex="-1" role="dialog" aria-labelledby="update" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Work Effort Quote Item </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important; flex-wrap: wrap !important; display: flex !important;">
                                <li><a>Add Work Effort Quote Item</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addQuoteItem">

                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Quote ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="quoteID" [options]="QuoteIdListArray" optionlabel="label" placeholder="Enter Quote ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Quote Item Seq ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="quoteItemSeqID" [options]="QuoteItemSeqIdArray" optionlabel="label" placeholder="Enter Quote Item Seq ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 main-submit-button1">
                                            <button type="submit" (click)="createWorkEffortQuoteItem()" class="btn btn-secondary submit-btn">Add</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>