import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
    selector: 'app-update-invoice',
    templateUrl: './update-invoice.component.html'
})
export class UpdateInvoiceComponent implements OnInit {
    updateSalesInvoiceForm: FormGroup;
    roleTypeIds: any[];
    partyIds: any[];
    activeCategory = 2;
    currencies: any[];
    invoiceValue: any;
    invoiceId: string;
    invoiceType: string;
    billingAccounts: any[];
    constructor(
        readonly _FormBuilder: FormBuilder,
        readonly spinner: NgxSpinnerService,
        readonly _GlobalResourceService: GlobalResourceService,
        readonly _AccountingApService: AccountingApService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _ToastrService: ToastrService,
        readonly  _Router: Router
    ) {
        this.partyIds = [];
        this.currencies = [];
        this.roleTypeIds = [];
        this.updateSalesInvoiceForm = this._FormBuilder.group({
            billingAccountsId: [''],
            currencyUomId: [''],
            description: [''],
            dueDate: [new Date()],
            invoiceDate: [new Date()],
            invoiceMessage: [''],
            invoiceTypeId: [''],
            partyId: [''],
            partyIdFrom: [''],
            recurrenceInfoId: [''],
            referenceNumber: [''],
            roleTypeId: [''],
            statusId: ['']
        });
        this.roleTypeIds = [];
        this.invoiceId = '';
        this.invoiceType = '';
        this.billingAccounts = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.invoiceId = this._ActivatedRoute.snapshot.queryParams.invoiceId;
        this.invoiceType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this._AccountingApService.getInvoiceById(this.invoiceId)
            .then(data => {
                this.invoiceValue = data.data;
                this.updateSalesInvoiceForm.patchValue({
                    ...data.data, ...{
                        dueDate: data.data.dueDate ? new Date(data.data.dueDate) : data.data.dueDate,
                        invoiceDate: data.data.invoiceDate ? new Date(data.data.invoiceDate) : data.data.invoiceDate
                    }
                });
            });
        this.getPartyIds();
        this.getCurrency();
        this.getRoleType();
        this.getBillingAccount();
    }
    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.PersonsGroups.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
          
    }
    getCurrency(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                this.spinner.hide();
                this.currencies = data.data.currencyList.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
            });
           
    }
    getRoleType(): void {
        this.spinner.show();
        this._GlobalResourceService.getRoleType()
            .then(data => {
                this.spinner.hide();
                this.roleTypeIds = data.data.roleType.map(value => {
                    return {
                        label: value.description,
                        value: value.roleTypeId
                    };
                });
            });
           
    }
    getBillingAccount(): void {
        this.spinner.show();
        this._AccountingApService.getBillingAccount({ pageNo: 1, pageSize: 200 }, {
            accountLimit: '', billingAccountId: '', billingAccountIdSearchType: 'Contains', description: '', descriptionSearchType: 'Contains', fromDate: '', thruDate: ''
        })
            .then(data => {
                this.spinner.hide();
                this.billingAccounts = data.data.map(value => {
                    return {
                        label: value.billingAccountId,
                        value: value.billingAccountId
                    };
                });
            });
           
    }
    submit(): void {
        this.spinner.show();
        this._AccountingApService.updateInvoice(this.invoiceId, {
            ...this.updateSalesInvoiceForm.value, ...{
                dueDate: moment(this.updateSalesInvoiceForm.value.dueDate).format('YYY-MM-DD HH:mm:ss'),
                invoiceDate: moment(this.updateSalesInvoiceForm.value.invoiceDate).format('YYYY-MM-DD HH:mm:ss')
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.updateSalesInvoiceForm.reset();
                    this._Router.navigate([`/financial/${this.invoiceType}/invoices`]);
                }
            });
           
    }



    reset(): void {
        this.updateSalesInvoiceForm.reset();
        this._Router.navigate([`/financial/${this.invoiceType}/invoices`]);
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}