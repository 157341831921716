<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="hover" (click)="prevScrenn()" class="color-grey">Department </span>
                <span class="color-black pl-1"> > Add New</span>
                <button type="submit"  (click)="prevScrenn()" class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class="w3-card-4 classCard" >
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''" ><a>
                        Add New Department </a></li>
                        <li *ngIf="show"  [ngClass]="activeCategory==2?'active':''" ><a>
                            Update Department </a></li>
                     
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form *ngIf="createDepartmentFormService.job_requistion_form" [formGroup]="createDepartmentFormService.job_requistion_form" class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Department Name <span style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" formControlName="departmentName" placeholder="Enter Department name">
                                                <small class="text-danger"
                                                *ngIf="!!createDepartmentFormService.job_requistion_form.controls.departmentName.invalid && !!createDepartmentFormService.job_requistion_form.controls.departmentName.touched">  Department Name is required!</small>
                                            </div>


                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Manager</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" [options]="partyIdArray"
                                                    optionlabel="label" placeholder="Enter Manager" formControlName="active">
                                                </p-dropdown>
                                            </div>

                                          
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                         
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Company</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Enter Company"  formControlName="companyNameId" [options]="companyArray" filter="true" optionlabel="label">
                                                </p-dropdown>
                                                    

                                            </div>
                                        </div>
                                    </div>
                                 

                                   
                            
                                </div>
                                <div class="main-submit-button" style="margin-right: 27%;">
                                    <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                    <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>
                                   
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
            </div>
          
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 