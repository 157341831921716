import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { SortEvent } from 'primeng/api';
import { CreateContactsService } from 'src/app/crm/contacts/create-contacts/create-contacts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-manual-electronic-transaction',
  templateUrl: './manual-electronic-transaction.component.html',
  styleUrls: ['./manual-electronic-transaction.component.css']
})
export class ManualElectronicTransactionComponent implements OnInit {
  activeCategory=4;
  createAuth:FormGroup;
  productStoresListArray: any[]=[];
  enumDataArray: any[]=[];
  enumId: string;
  creditCard: { label: string; value: string; }[];
  showFirst=false;
  showSecond=false;
  prefixCard: { label: string; value: string; }[];
  sufixCard: { label: string; value: string; }[];
  CardTypeAccounts: any;
  CardTypeAccountsArray: any[]=[];
  months: { label: string; value: string; }[];
  years: { label: string; value: string; }[];
  stateArray: any[]=[];
  countryGeoId: any;
  countryId: any;
  stateData: any;
  array: any[]=[];
  paymentethodType: any;
  productStoreType: any;
  transactionType: any;
  userProfile: any;
  showThird: boolean;
  showFour: boolean;
  showFive: boolean;
  refund=false;
  release=false;
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
  
    readonly accountsService: AccountsService,
    
    public createContact: CreateContactsService,
    readonly _ToastrService: ToastrService,
    private _location: Location,
    ) { 
      this.createAuth = this._FormBuilder.group({
        paymentMethodType: [''],
        productStore:[''],
        transactionType:[''],
        Forename:[''],
        Surname:[''],
        EMailAddress:[''],
        CompanyNameCard:[''],
        prefixCard: [''],
        FirstNameCard:[''],
        MiddleNameCard:[''],
        LastNameCard:[''],
        SuffixonCard:[''],
        CardType:[''],
        CardNumber:[''],
        CardDay:[''],
        Month: [''],
        Description:[''],
        ForenameA:[''],
        SurnameA:[''],
        BilToAddress1:[''],
        BillToAddress2:[''],
        City:[''],
        state:[''],
        zip:[''],
        Country:[''],
        Amount:[''],
        ReferenceNumber:[''],
        OrderPaymentPreferenceID:['']
       
        
      });
      this.creditCard=[{
        "label":"Credit Card",
        "value":"CREDIT_CARD"
      }]
      this.prefixCard=[{
        "label":"Mr.",
        "value":"Mr"
      },
      {
        "label":"Mrs.",
        "value":"Mrs"
      },
      {
        "label":"Ms.",
        "value":"Ms"
      },
      {
        "label":"Dr.",
        "value":"Dr"
      }]
      this.sufixCard=[{
        "label":"Jr.",
        "value":"Jr"
      },
      {
        "label":"Sr.",
        "value":"Sr"
      },
      {
        "label":"I",
        "value":"I"
      },
      {
        "label":"II",
        "value":"II"
      }
      ,
      {
        "label":"III",
        "value":"III"
      }
      ,
      {
        "label":"IV",
        "value":"IV"
      },
      {
        "label":"V",
        "value":"V"
      }]

      this.months=[{"label":"1","value":"1"},
      {"label":"2","value":"2"},
      {"label":"3","value":"3"},
      {"label":"4","value":"4"},
      {"label":"5","value":"5"},
      {"label":"6","value":"6"},
      {"label":"7","value":"7"},
      {"label":"8","value":"8"},
      {"label":"9","value":"9"},{"label":"10","value":"10"},{"label":"11","value":"11"}
    ,{"label":"12","value":"12"}]
    
    this.years=[{"label":"2021","value":"2021"},
    {"label":"2022","value":"2022"},
    {"label":"2023","value":"2023"},
    {"label":"2024","value":"2024"},
    {"label":"2025","value":"2025"},
    {"label":"2026","value":"2026"},
    {"label":"2027","value":"2027"},
    {"label":"2028","value":"2028"},
    {"label":"2029","value":"2029"},{"label":"2030","value":"2030"},{"label":"2031","value":"2031"}
  ,{"label":"2032","value":"2032"}]
  }
  ngOnInit(): void {
    this.getOrderListItems();
    this.getEenumByType();
    this.getCardTypeAccounts();
    this.getCountryList();
    this.getUserProfile();
    this.showFirst=true
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/financial/main']);
  }
  getUserProfile() {

    this.accountsService.getUserProfile().subscribe((res: any) => {
      this.userProfile = res.data[0];
      setTimeout(() => {
        const formValue = this.createAuth;
      formValue.patchValue({
        Forename: this.userProfile.firstName,
        Surname:this.userProfile.lastName,
        ForenameA: this.userProfile.firstName,
        SurnameA:this.userProfile.lastName,
       
      })
      }, 2000);
    

    })
  }
  
  Authorize(){
    this._Router.navigate(["/financial/authorize-transaction"])
  }
  Capture(){
    this._Router.navigate(["/financial/capture"])
  }
  GatewayResponses(){
    this._Router.navigate(["/financial/payment-gateway-response"])
  }
  ManualElectronicTransaction(){
    this._Router.navigate(["/financial/manual-electronic-transaction"])
  }
  getEenumByType(): void {
    this.spinner.show();
    this.enumId="PRDS_PAYSVC"
    this.accountsService.getEenumByType(this.enumId).subscribe((res) => {
      const enumData = res.data;
      this.spinner.hide();
      for (const value of enumData) {
        this.enumDataArray.push({
          label: value.description,
          value: value.enumId,
        });
      }
    });
   
  }
  onCountryChange(event) {
    this.stateArray = [];
    this.countryGeoId = event;
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  }
 
  getStateList() {
    this.spinner.show();
    this.createContact.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        const splitState = stateLists.split(":");
        const stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
   
  }
  getCountryList() {
    this.spinner.show();
    this.createContact.getCountry().subscribe(res => {
      const country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0].trim(),
          value: element.split(":").pop().trim()
        })
      })
    })
   
  }
  getCardTypeAccounts() {
    this.spinner.show();
    this.accountsService.getCardTypeAccounts().subscribe((res: any) => {

      this.CardTypeAccounts = res.data;
      this.spinner.hide();
      for (const value of this.CardTypeAccounts) {
        this.CardTypeAccountsArray.push({
          label: value.enumCode,
          value: value.enumId
        })
      }

    })
   
  }
  getOrderListItems(): void {
    this.spinner.show();
    this.accountsService.getOrderList().subscribe((res) => {
      const productStores = res.data.productStores;
      this.spinner.hide();
      for (const value of productStores) {
        this.productStoresListArray.push({
          label: value.storeName,
          value: value.productStoreId,
        });
      }
    });
   
  }
  onChange(event){
   
    setTimeout(() => {
      const formValue = this.createAuth;
    formValue.patchValue({
      Country: "USA",
    
     
    })
    }, 2000);
    this.countryId = "USA";
    this.getStateList();
  

    this.paymentethodType=this.createAuth.value.paymentMethodType;
    this.productStoreType=this.createAuth.value.productStore;
    this.transactionType=this.createAuth.value.transactionType;
    if(event.value==="PRDS_PAY_AUTH"){
      this.showFirst=false;
      this.showSecond=true;
      this.showThird=false;
      this.showFour=false;
      this.showFive=false;
     
    }
    else if(event.value==="PRDS_PAY_REAUTH"){
      this.showFirst=false;
      this.showSecond=false;
      this.showThird=true;
      this.showFour=false;
      this.showFive=false;
    }
    else if(event.value==="PRDS_PAY_RELEASE"){
      this.showFirst=false;
      this.showSecond=false;
      this.showThird=false;
      this.showFour=true;
      this.showFive=false;
      this.release=true;
    }
    else if(event.value==="PRDS_PAY_CAPTURE"){
      this.showFirst=false;
      this.showSecond=false;
      this.showThird=false;
      this.showFour=true;
      this.showFive=false;
    }
    else if(event.value==="PRDS_PAY_REFUND"){
      this.showFirst=false;
      this.showSecond=false;
      this.showThird=false;
      this.showFour=true;
      this.showFive=false;
      this.refund=true;
    }
    else if(event.value==="PRDS_PAY_AUTH_VERIFY"){
      this.showFirst=false;
      this.showSecond=false;
      this.showThird=true;
      this.showFour=false;
      this.showFive=false;
    }
    else if(event.value==="PRDS_PAY_EXTERNAL"){
      this.showFirst=false;
      this.showSecond=false;
      this.showThird=true;
      this.showFour=false;
      this.showFive=false;
    }
    else if(event.value==="PRDS_PAY_CREDIT"){
      this.showFirst=false;
      this.showSecond=false;
      this.showThird=false;
      this.showFour=false;
      this.showFive=true;
    }
  }
  
  processReleaseTransaction() {
    this.spinner.show();  
    const requestData = {
      "amount":this.createAuth.value.Amount,
      "orderPaymentPreferenceId":this.createAuth.value.OrderPaymentPreferenceID,
      "paymentMethodTypeId":this.createAuth.value.paymentMethodType,
      "productStoreId":this.createAuth.value.productStore,
      "referenceNum":this.createAuth.value.ReferenceNumber,
      "transactionType": this.createAuth.value.transactionType,
    }
    this.accountsService.processReleaseTransaction(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();  
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  processRefundTransaction() {
    this.spinner.show(); 
    const requestData = {
      "amount":this.createAuth.value.Amount,
      "orderPaymentPreferenceId":this.createAuth.value.OrderPaymentPreferenceID,
      "paymentMethodTypeId":this.createAuth.value.paymentMethodType,
      "productStoreId":this.createAuth.value.productStore,
      "referenceNum":this.createAuth.value.ReferenceNumber,
      "transactionType": this.createAuth.value.transactionType,
    }
    this.accountsService.processRefundTransaction(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();  
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onSubmit() {
    this.spinner.show();
    
    const requestData = {
      "address1": this.createAuth.value.BilToAddress1,
      "address2": this.createAuth.value.BillToAddress2,
      "amount": this.createAuth.value.Amount,
      "cardNumber": this.createAuth.value.CardNumber,
      "cardType": this.createAuth.value.CardType,
      "city": this.createAuth.value.City,
      "companyNameOnCard": this.createAuth.value.CompanyNameCard,
      "countryGeoId": this.createAuth.value.Country,
      "description": this.createAuth.value.Description,
      "expMonth": this.createAuth.value.CardDay,
      "expYear": this.createAuth.value.Month,
      "firstName": this.createAuth.value.Forename,
      "firstNameOnCard": this.createAuth.value.FirstNameCard,
      "infoString": this.createAuth.value.EMailAddress,
      "lastName": this.createAuth.value.Surname,
      "lastNameOnCard": this.createAuth.value.LastNameCard,
      "middleNameOnCard": this.createAuth.value.MiddleNameCard,
      "paymentMethodTypeId": this.createAuth.value.paymentMethodType,
      "postalCode":this.createAuth.value.zip,
      "productStoreId": this.createAuth.value.productStore,
      "stateProvinceGeoId": this.createAuth.value.state,
      "suffixOnCard": this.createAuth.value.SuffixonCard,
      "titleOnCard":"",
      "transactionType": this.createAuth.value.transactionType,
     
    
      
     

    }

    this.accountsService.processManualTransaction(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onSubmitCredit() {
    this.spinner.show();
    
    const requestData = {
      "orderPaymentPreferenceId":  this.createAuth.value.OrderPaymentPreferenceID,
      "referenceNum":  this.createAuth.value.ReferenceNumber,
      "address1": this.createAuth.value.BilToAddress1,
      "address2": this.createAuth.value.BillToAddress2,
      "amount": this.createAuth.value.Amount,
      "cardNumber": this.createAuth.value.CardNumber,
      "cardType": this.createAuth.value.CardType,
      "city": this.createAuth.value.City,
      "companyNameOnCard": this.createAuth.value.CompanyNameCard,
      "countryGeoId": this.createAuth.value.Country,
      "description": this.createAuth.value.Description,
      "expMonth": this.createAuth.value.CardDay,
      "expYear": this.createAuth.value.Month,
      "firstName": this.createAuth.value.Forename,
      "firstNameOnCard": this.createAuth.value.FirstNameCard,
      "infoString": this.createAuth.value.EMailAddress,
      "lastName": this.createAuth.value.Surname,
      "lastNameOnCard": this.createAuth.value.LastNameCard,
      "middleNameOnCard": this.createAuth.value.MiddleNameCard,
      "paymentMethodTypeId": this.createAuth.value.paymentMethodType,
      "postalCode":this.createAuth.value.zip,
      "productStoreId": this.createAuth.value.productStore,
      "stateProvinceGeoId": this.createAuth.value.state,
      "suffixOnCard": this.createAuth.value.SuffixonCard,
      "titleOnCard":"",
      "transactionType": this.createAuth.value.transactionType,
     
    
      
     

    }

    this.accountsService.processManualTransaction(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
}
