import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { QuotesService } from '../quotes.service';

@Injectable()
export class MyQuotesResolver implements Resolve<[any, any]> {

    constructor(
        private _QuotesService: QuotesService
    ) { }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<[any, any]> {
        return Promise.all([
            this._QuotesService.getQuoteStatus().then(data => data.data),
            this._QuotesService.getCurrencyData().then(data => data.data.currencyList)
        ]);
    }
}