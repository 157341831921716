import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-roles-page',
  templateUrl: './roles-page.component.html',
  styleUrls: ['./roles-page.component.css']
})
export class RolesPageComponent implements OnInit {
  activeCategory=1;

  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  gateway:any;
  rolesForm: FormGroup;
  createContentForm:FormGroup

  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly addSkillService: AddSkillService,
  ) 
  {
    this.rolesForm = this._FormBuilder.group({
      contentID:"",
      partyID:"",
      roleTypeID:"",
      fromDate:"",
      throughDate:"",
     });

     this.createContentForm = this._FormBuilder.group({
      contentID:"",
      contentTypeId:"",
      ownerContentID:"",
      decoratorContentID:"",
      instanceContentID:"",
      dataResourceId:"",
      templateDataResourceID:"",
      dataSourceID:"",
      privilegeEnumID:"",
      serviceName:"",
      customMethodID:"",
      contentName:"",
      description:"",
      localeString:"",
      mimeTypeID:"",
      characterSetID:"",
      childLeafCount:"",
      childBranchCount:"",
      createdDate:"",
      createdByUser:"",
      lastModifiedDate:"",
      lastModifiedByUser:"",
    });

  }

  ngOnInit(): void {
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

    changeactiveCategory(tab: number)
    {
      this.activeCategory = tab;
    
    }



}
