import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { isHidden } from '@amcharts/amcharts4/.internal/core/utils/DOM';
import { Location } from '@angular/common';
@Component({
  selector: 'app-detail-sprint-project',
  templateUrl: './detail-sprint-project.component.html',
  styleUrls: ['./detail-sprint-project.component.css']
})
export class DetailSprintProjectComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  activeCategory = 1;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  EditProjectForm: FormGroup;
  fileData: File;
  ContentList: any;
  productId: any;
  MimeTypeId: any;
  mimeTypeIdArray: any[] = [];
  contentTypeId: any;
  contentTypeIdArray: any[] = [];
  contentForm: FormGroup;
  ProjectSprintOverview: any;
  projectId: any;
  yesNOArray: { label: string; value: string; }[];
  statusArray: { label: string; value: string; }[];
  product: any;
  CreateSprintForm: FormGroup;
  startDate: string;
  MoveToProduct: any;
  MoveToProductArray: any[] = [];
  ProjectName: any;
  internalName: any;
  ProjectGoal: any;
  Billed: any;
  billFromParty: any;
  SprintLists: any;
  workEffortId: any;
  ListofSprintBacklogs: any;
  numberArray: { label: string; value: string; }[];
  ProductName: any;
  productID: any;
  currentStatusId: any;
  showStatus: boolean = true ;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService, private _location: Location,
    readonly _Router: Router

  ) {
    this.EditProjectForm = this._FormBuilder.group({
      MoveToProduct: [''],
      ProductName: [''],
      ProjectName: [''],
      Billed: [''],
      Status: [''],
      ProjectGoal: ['']

    });
    this.contentForm = this._FormBuilder.group({
      ContentTypeID: [''],
      MimeTypeID: [''],
      Upload: [''],
    });

    this.CreateSprintForm = this._FormBuilder.group({
      SprintName: [''],
      SprintGoal: [''],
      StartDate: [''],
      SprintLengthWeeks: [''],
      SprintBacklog: [''],
    });
    this.numberArray = [

      {
        label: '1',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
     


    ];
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];

    this.statusArray = [
      {
        label: 'Active',
        value: 'SPJ_ACTIVE'
      },
      {
        label: 'Closed',
        value: 'SPJ_CLOSED'
      },
    ];
  }

  ngOnInit(): void {
    this.spinner.hide();
    this.activatedRoute.queryParams.subscribe(params => {
      this.projectId = params.projectId;

      this.productId = params.productId;
      this.ProjectName = params.ProjectName;
      this.internalName = params.internalName;
      this.ProjectGoal = params.ProjectGoal;
      this.Billed = params.Billed;
      this.billFromParty = params.billFromParty;
      this.ProductName = params.ProductName;
    })
    this.showStatus = true;
    this.getScrumProductContentListData();
    this.getMimeTypeId();
    this.getContentTypeId();
    this.getProjectSprintOverview();
    this.getMoveToProduct();
    this.getSprintLists();
    this.getListofSprintBacklogs();
  }

homeButton(){
  this._Router.navigate(['/psa/Dashboard'])

}
cancelSubmit(){
  this._location.back();
}
  backToProductBacklog(productId){
    this._Router.navigate(["/psa/product-overview"], { queryParams: { productId: productId } })

  }
  detailNewSprint(productId,custRequestId,description){
    this._Router.navigate(["/psa/detail-new-sprint"], { queryParams: { productId: productId ,custRequestId:custRequestId,description:description} })

  }

  getListofSprintBacklogs() {
    this.spinner.show();
    this.accountsService.getListofSprintBacklogs().subscribe(res => {
      this.ListofSprintBacklogs = res.data;
      this.spinner.hide();
    })
  }

  updateSprintActive() {
    this.spinner.show();
    const Startdate = this.CreateSprintForm.get('StartDate').value;
    this.startDate = this.datePipe.transform(Startdate, "yyyy-MM-dd hh:mm:ss");

    const requestData = {

      "currentStatusId": "SPJ_ACTIVE",
      "productId": this.productId,
      "projectId": this.workEffortId,
      "workEffortId":this.workEffortId

    }

    this.accountsService.updateSprintClose(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
       // this.getProjectSprintOverview();
        this.activeCategory = 1;

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }


  updateSprintClose() {
    this.spinner.show();
    const Startdate = this.CreateSprintForm.get('StartDate').value;
    this.startDate = this.datePipe.transform(Startdate, "yyyy-MM-dd hh:mm:ss");

    const requestData = {

      "currentStatusId": "SPJ_CLOSED",
      "productId": this.productId,
      "projectId": this.workEffortId,
      "workEffortId":this.workEffortId

    }

    this.accountsService.updateSprintClose(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
       // this.getProjectSprintOverview();
        this.activeCategory = 1;

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  createSprint() {
    this.spinner.show();
    const Startdate = this.CreateSprintForm.get('StartDate').value;
    this.startDate = this.datePipe.transform(Startdate, "yyyy-MM-dd hh:mm:ss");

    const requestData = {

      "currentStatusId": "CRQ_DRAFT",
      "maxNum": "0",
      "projectId": this.projectId,
      "sprintBacklogs": this.CreateSprintForm.value.SprintBacklog,
      "sprintDescription": this.CreateSprintForm.value.SprintGoal,
      "sprintDuration": this.CreateSprintForm.value.SprintLengthWeeks,
      "sprintEstimatedStart": this.startDate,
      "sprintId": "",
      "sprintName": this.CreateSprintForm.value.SprintName,
      "workEffortTypeId": "SCRUM_SPRINT"

    }

    this.accountsService.createSprint(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.activeCategory = 10;
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    this.spinner.hide();
  }

  updateScrumProject() {
    this.spinner.show();

    const requestData = {

      "billed": this.EditProjectForm.value.Billed,
      "currentStatusId": this.EditProjectForm.value.Status,
      "description": this.EditProjectForm.value.ProjectGoal,
      "partyId": "admin",
      "productId": this.productId,
      "productName": this.productId,
      "productOwnerId": "",
      "projectId":  this.workEffortId ,
      "workEffortId":  this.workEffortId ,
      "workEffortName": this.EditProjectForm.value.ProjectName,
      "workEffortTypeId": "SCRUM_PROJECT"

    }

    this.accountsService.updateScrumProject(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.activeCategory = 1;
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  getSprintLists() {
    this.spinner.show();
    this.accountsService.getSprintLists(this.productId).subscribe(res => {
      this.SprintLists = res.data;
      this.spinner.hide();
    })
  }


  getProjectSprintOverview() {
    this.spinner.show();
    this.accountsService.getProjectSprintOverview(this.productId).subscribe(res => {
      this.ProjectSprintOverview = res.data[0];
      this.workEffortId = res.data[0].productOverviewDetails[0].workEffortId;
      this.currentStatusId = res.data[0].productOverviewDetails[0].currentStatusId;
      this.internalName = res.data[0].productRoles.internalName;
      this.spinner.hide();

      if(this.currentStatusId=='SPJ_CLOSED'){
        this.showStatus = false;
      }
      else{
        this.showStatus = true;
      }
      setTimeout(() => {
        const form = this.EditProjectForm;
        form.patchValue({
          MoveToProduct: this.ProjectSprintOverview.billToParty,
          ProductName: this.internalName,
          ProjectName: this.ProjectName,
          Billed: this.ProjectSprintOverview.billToParty,
          Status: this.ProjectSprintOverview.productOverviewDetails[0].currentStatusId,
          ProjectGoal: this.ProjectSprintOverview.billToParty,
        })
      }, 2000);
    })
    
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  oncreateScrumProductContent() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.accountsService.createScrumProductContent(formData, this.contentForm.value.ContentTypeID, this.contentForm.value.MimeTypeID, this.productId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getScrumProductContentListData();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }
  getScrumProductContentListData() {
    this.spinner.show();
    this.accountsService.getScrumProductContentListData(this.productId).subscribe(res => {
      this.ContentList = res.data.data;
      this.spinner.hide();
    })
  }

  getContentTypeId() {
    this.spinner.show();
    this.accountsService.getContentTypeId().subscribe(res => {
      this.contentTypeId = res.data.contentTypeId;
      this.spinner.hide();
      for (const value of this.contentTypeId) {
        this.contentTypeIdArray.push({
          label: value.description,
          value: value.contentTypeId
        })
      }
    })
  }

  getMoveToProduct() {
    this.spinner.show();
    this.accountsService.getMoveToProduct().subscribe(res => {
      this.MoveToProduct = res.data;
      this.spinner.hide();
      for (const value of this.MoveToProduct) {
        this.MoveToProductArray.push({
          label: value.productName,
          value: value.productName
        })
      }
    })
  }

  getMimeTypeId() {
    this.spinner.show();
    this.accountsService.getMimeTypeId().subscribe(res => {
      this.MimeTypeId = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.MimeTypeId) {
        this.mimeTypeIdArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }
  deleteScrumProductContent(contentID) {


    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.accountsService.deleteScrumProductContent(contentID).subscribe((res: any) => {
          this.getScrumProductContentListData();

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  DownloadImageProductContent(imageURL) {
    this.spinner.show();
    this.accountsService.getDownloadImageProductContent(imageURL).subscribe(res => {
      const blob = new Blob([res], { type: 'document/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'DEMO-CONTENT.xml';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })

  }



  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
