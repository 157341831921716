import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2'
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { DatePipe, Location } from '@angular/common';
import { GlobalResourceService } from 'src/app/services/global-resource.service';

@Component({
  selector: 'app-find-user-login',
  templateUrl: './find-user-login.component.html',
  styleUrls: ['./find-user-login.component.css']
})
export class FindUserLoginComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonA') closebuttonA;
  products3: any;
  total = 0;
  rowNumber = 0;
  pageSize = 500;
  rows = 50;
  pageNo = 1;
  activeCategory = 1;
  search = "";
  public user = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search
  }

  userList: any;
  loginIdArray: any[] = [];
  partIdArray: any[] = [];
  status: string;
  adminSecurity: string;
  userEmail: string;
  personId: any;
  partyIdArray: any[] = [];
  catalogId: string;
  party: any;
  AddUserLoginToSecurityGroup: any;
  loginid: any;
  UserLogin: any;
  securtiyList: any;

  createUser:FormGroup;
  activeCategorymain=2;
  partyId: any;
  jid: any;
  jname: any;
  userLabel=[{
    "label":"Yes",
    "value":"Y"
  },
  {
    "label":"No",
    "value":"N"
  }]
  
  public cols: any[];
  getContacts: any = [];
  createGroup: FormGroup;
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  show: boolean;
  groupId: any;
  userPermissions: any;
  Hidebtn: boolean=false;

  constructor(
    private accountsService: AccountsService,
    private headerService: HeaderService,
    readonly _ActivatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    private addSkillService: AddSkillService,
    readonly router: Router,
    readonly  _Router: Router,
    private _location: Location,
    readonly _FormBuilder: FormBuilder,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly datePipe: DatePipe,
    readonly activatedRoute:ActivatedRoute,
  ) {
    this.createUser = this._FormBuilder.group({
      loginId: [''],
      currentpass: [''],
      verifypass: [''],
      passHint: [''],
      passrequire: [''],
      extAuthId: [''],
     
      
    });
    this.createGroup = this._FormBuilder.group({
      description: [''],
      groupId: ['',[Validators.required]],
      groupName: [''],
      
      
    });

   }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
         this.Hidebtn=false;
       }
       else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
         this.Hidebtn=true;
       }
   this.FindUserLogin();
   this.getUserDetails();


   this.activatedRoute.queryParams.subscribe(params=> [
     this.groupId = params["groupId"],
   ])
   if(this.groupId) {
     this.show=true;
     this.getUserDetail()
   }
   else {
     this.show = false;
   }
  }

  backWindow(){
      this._location.back();
    }
      
    toDashboard()
  {
    this.router.navigate(['/security/security-dashboard']);
  }

  updatePassword(loginId){
    this.router.navigate(["security/update-password"], { queryParams: {loginid:loginId} });
  }
  detailSecurity(id,des){
    this.router.navigate(["/security/detail-party-security"],{queryParams:{groupId:id,description:des}})
  }
  getUserDetails(){
    this.spinner.show();
    const req={
  "description": "",
  "descriptionSearchType": "Contains",
  "groupId": "",
  "groupIdSearchType": "Contains"
     
    }
    this.accountsService.getSecurityGroupList(this.user,req).subscribe((res:any)=> {
      this.securtiyList = res.data;
      this.spinner.hide();
      
    })   
   }

   editSecurity(id){
    this.router.navigate(["/security/create-new-security-group"],{queryParams:{groupId:id}})
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  FindUserLogin() {
    this.spinner.show();

    this.accountsService.FindUserLogin().subscribe((res: any) => {
      this.UserLogin = res.data;
      this.spinner.hide();

    })

  }



  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "currentPassword": this.createUser.value.currentpass,
      "currentPasswordVerify":this.createUser.value.verifypass,
      "externalAuthId": this.createUser.value.extAuthId,
      "partyId": "",
      "passwordHint": this.createUser.value.passHint,
      "requirePasswordChange": this.createUser.value.passrequire,
      "userLoginId":this.createUser.value.loginId,
    
    }
    this.accountsService.createUserLogin(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._location.back();
        this.closebutton.nativeElement.click();
        this.FindUserLogin();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.debugMessage);
        }
    });

  }
  cancelSubmit(){
    this._location.back();
  }
  getUserDetail(){
    const req={
  "description": "",
  "descriptionSearchType": "Contains",
  "groupId": this.groupId,
  "groupIdSearchType": "Contains"
     
    }
    this.accountsService.getSecurityGroupList(this.user,req).subscribe((res:any)=> {
     setTimeout(() => {
      const formValue = this.createGroup;
    formValue.patchValue({
      description:res.data[0].description,
      groupId: res.data[0].groupId,
      groupName:res.data[0].groupName,
     
     
    })
    }, 1000); 
     
    })
   
   }
  submit(): void {
    this.spinner.show();
     const requestData ={
     
         "description": this.createGroup.value.description,
         "groupId": this.createGroup.value.groupId,
         "groupName":this.createGroup.value.groupName    
         
     }
     this.accountsService.saveSecurityGroup(requestData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.closebuttonA.nativeElement.click();
    this.getUserDetails();
        // this.router.navigate(['/partyQuickLink/security'],)
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
   update(): void {
    this.spinner.show();
      const requestData ={
      
          "description": this.createGroup.value.description,
          "groupId": this.createGroup.value.groupId,
          "groupName":this.createGroup.value.groupName    
          
      }
      this.accountsService.putSecurityGroup(requestData).subscribe((res: any) => {
       
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          
          this.router.navigate(['/partyQuickLink/security'],)
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
     
    }
   cancel(){
     this.router.navigate(['/partyQuickLink/security'],)
   }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
