import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddEmployeePositionService } from 'src/app/human-resource/add-employee-position/add-employee-position.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-detail-shipment-config-types',
  templateUrl: './detail-shipment-config-types.component.html',
  styleUrls: ['./detail-shipment-config-types.component.css']
})
export class DetailShipmentConfigTypesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  createShipmentConfigType: FormGroup;
  createShipmentConfigTypeDHL:FormGroup;
  activeCategory=0;
  show: boolean;
  ParentTypeID: any;
  ParentTypeIDArray: any[]=[];
  HasTableArray: { label: string; value: string; }[]=[];
  ShipmentDescription: any;
  shipmentGatewayConfTypeId: any;
  HasTable: any;
  description: any;
  hasTable: any;
  parentTypeId: any;

  constructor(
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly addEmployeePositionService: AddEmployeePositionService,
   
    readonly addSkillService: AddSkillService,
    readonly activatedRoute: ActivatedRoute,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
   
    readonly router: Router
  ) {

    this.createShipmentConfigType = this._FormBuilder.group({
      ShipmentDescription: [''],
      ParentTypeID: [''],
      HasTable: [''],
      ShipmentGatewayConfigTypeId:['']
    
    });

  

    this.HasTableArray = [
      {
        label: 'Yes',
        value: 'Yes'
      },
      {
        label: 'No',
        value: 'No'
      },
     
    ];
   }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.description = params["description"];
      this.hasTable = params["hasTable"];
      this.shipmentGatewayConfTypeId = params["shipmentGatewayConfTypeId"];
      this.parentTypeId = params["parentTypeId"];
   
     
      
    })
  
  this.show=true;
    this.getParentTypeID();
  }

  edit(){


    setTimeout(() => {
      const formValue = this.createShipmentConfigType;
    formValue.patchValue({
     
      ShipmentDescription:  this.description,
      ParentTypeID:  this.parentTypeId,
      HasTable: this.hasTable,
      ShipmentGatewayConfigTypeId: this.shipmentGatewayConfTypeId,
      
    
       
      

    })
    }, 1000);

  }


  UpdateShipmentGatewayConfigType(){
    this.spinner.show();
  
  const formdata={
    "description": this.createShipmentConfigType.value.ShipmentDescription,
    "parentTypeId": this.createShipmentConfigType.value.ParentTypeID,
    "shipmentGatewayConfTypeId": this.createShipmentConfigType.value.ShipmentGatewayConfigTypeId
  }
  
    this.accountsService.UpdateShipmentGatewayConfigType(formdata).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();


      }      }
      ,(err) => {
        for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
        }}
      )
      this.spinner.hide();
  }

  
  getParentTypeID() {
    this.spinner.show();
    this.accountsService.getParentTypeID().subscribe(res => {
      this.ParentTypeID = res.data;
      this.spinner.hide();
      for (const value of this.ParentTypeID) {
        this.ParentTypeIDArray.push({
          label: value.description,
          value: value.parentTypeId
        })
      }
    })
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  cancelSubmit(){
    this.router.navigate(['/facilities/shipment-config.component'])
  }

}
