<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
             
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Edit Marketing Campaign</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                    <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span> 
            </div>
            <div class=" bg-white color-grey mt-20">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid ">
                        <div>
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                    <a>Summary</a>
                                </li>
                                <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                    <a>Marketing Campaign</a>
                                </li>
                                <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                    <a>Roles</a>
                                </li>
                                <li class="d-flex justify-content-end" style="margin-left: 67%;">
                                    <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>
                                </li>
                            </ul>
                            
                            
                        </div>
                        
                    </div>
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div *ngIf="activeCategory==1">
                                <div class="w3-card-4 classCardView mt-2">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Summary
                                            </h4>
                                            <span style="margin-right: 10px;">
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#updateCampaignPopup">Update</button>
                                                </span>
                                        </div>
                                    </header>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                                <div class="row" *ngIf="this.editValue">
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Marketing Campaign ID </p>
                                                                <span class="color-grey pl-20 font-weight-bold">{{this.marketId}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Parent Campaign ID </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.editValue.parentCampaignId}}
                                                                        
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Status </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.editValue.statusId}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Campaign Name </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.editValue.campaignName}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Campaign Summary </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{ this.editValue.campaignSummary}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Budgeted Cost </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.editValue.budgetedCost}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Actual Cost </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.editValue.actualCost}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Estimated Cost </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.editValue.estimatedCost}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Currency </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.editValue.currencyUomId}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">From Date </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.getEstimateFrom}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Through Date </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.getEstimateTo}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Is Active </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.editValue.isActive}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Converted Leads </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.editValue.convertedLeads}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Expected Response Percent </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.editValue.expectedResponsePercent}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Expected Revenue </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.editValue.expectedRevenue}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Num Sent </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.editValue.numSent}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Start Date </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.startDateValue}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Created By </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.editValue.createdByUserLogin}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6">Last Modified By </p>
                                                                <span class="color-grey pl-20 font-weight-bold">
                                                                    {{this.editValue.lastModifiedByUserLogin}}
                                                                    </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="activeCategory==2">
                                <div class="w3-card-4 classCardView mt-2">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Edit Marketing Campaign
                                            </h4>
                                        </div>
                                    </header>
                                    <form class="w-100" [formGroup]="CreateMarketingCampaignForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-left: 2%;">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Marketing Campaign ID
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{this.marketId}}
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Parent Campaign ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="ParentCampaignID" [options]="MarketCampaignIdsArray" optionlabel="label" placeholder="Enter Parent Campaign ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="Status" optionlabel="label" [options]="StatusListssArray" placeholder="Enter Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Campaign Name
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="CampaignName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Campaign Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Campaign Summary
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="CampaignSummary" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Campaign Summary">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Budgeted Cost
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="BudgetedCost" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budgeted Cost">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Cost
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="ActualCost" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Actual Cost">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated cost
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="Estimatedcost" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated cost">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Currency</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="Currency" optionlabel="label" [options]="CurrencyListsArray" placeholder="Enter Currency">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="FromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="ThroughDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Is Active
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="IsActive" [options]="yesNOArray" optionlabel="label" placeholder="Enter Is Active">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Converted Leads
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="ConvertedLeads" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Converted Leads">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Expected Response Percent
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="ExpectedResponsePercent" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Expected Response Percent">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Expected Revenue
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="ExpectedRevenue" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Expected Revenue">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Num Sent	
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="NumSent" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Num Sent">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Start Date
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="StartDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Start Date">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Created By User		
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="CreatedByUser" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Created By User">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Last Modified By User	
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="LastModifiedByUser" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Modified By User">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createMarketingCampaign()" class="btn btn-secondary submit-btn">Save</button>&nbsp;
                                            <button type="button" (click)="back()" class="btn btn-danger ml-2">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div *ngIf="activeCategory==3">
                                <div class="w3-card-4 classCardView mt-2">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                List Marketing Campaign Role
                                            </h4>
                                            <span style="margin-right: 10px;">
                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleCreateMarketingCampaignRole"> 
                                                            Create Marketing Campaign Role</button>
                                                </span>
                                        </div>
                                    </header>

                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="MarketingCampaignRole" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Marketing Campaign ID


                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;">Party ID

                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;">Role Type ID

                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;">Action

                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                </th>


                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'150px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                </div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span  class="account-button"  >
                                                                                    {{product.datas.marketingCampaignId}}
                                                                                    </span>

                                                                    </div>
                                                                </td>

                                                                <td [ngStyle]="{'width':'180px'}">
                                                                    {{product.datas.partyId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'180px'}">
                                                                    {{product.datas.roleTypeId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'180px'}">
                                                                    <span>
                                                                              <a style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                                              pointer; " (click)="expireMarketingCampaignRole(product)"  class="account-button" >Remove</a>
                                                                        </span>
                                                                </td>

                                                            </tr>
                                                        </ng-template>

                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                            <h4
                                                                                class="ml-2">
                                                                                No Record
                                                                                Found</h4>
                                                                        </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleCreateMarketingCampaignRole" tabindex="-1" role="dialog" aria-labelledby="exampleCreateMarketingCampaignRoleTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Marketing Campaign Role</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Create Marketing Campaign Role</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="CreateMarketingCampaignRoleForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Marketing Campaign ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="MarketingCampaignID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Marketing Campaign ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown placeholder="Enter Party ID" [options]="partyIdArray" optionlabel="label" filter="true" formControlName="PartyID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Role Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="RoleTypeID" optionlabel="label" [options]="RoleTypeIdArray" placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="FromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="ThroughDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button (click)="createMarketingCampaignRole()" *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn">Save</button>&nbsp;
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="updateCampaignPopup" tabindex="-1" role="dialog" aria-labelledby="updateCampaignPopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Marketing Campaign</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Update Marketing Campaign</a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="CreateMarketingCampaignForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-left: 2%;">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Marketing Campaign ID
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        {{this.marketId}}
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Parent Campaign ID</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="ParentCampaignID" [options]="MarketCampaignIdsArray" optionlabel="label" placeholder="Enter Parent Campaign ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Status</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="Status" optionlabel="label" [options]="StatusListssArray" placeholder="Enter Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Campaign Name
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="CampaignName" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Campaign Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Campaign Summary
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="CampaignSummary" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Campaign Summary">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Budgeted Cost
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="BudgetedCost" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budgeted Cost">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Cost
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="ActualCost" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Actual Cost">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated cost
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="Estimatedcost" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated cost">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Currency</label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="Currency" optionlabel="label" [options]="CurrencyListsArray" placeholder="Enter Currency">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="FromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="ThroughDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Is Active
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="IsActive" [options]="yesNOArray" optionlabel="label" placeholder="Enter Is Active">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Converted Leads
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="ConvertedLeads" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Converted Leads">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Expected Response Percent
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="ExpectedResponsePercent" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Expected Response Percent">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Expected Revenue
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="ExpectedRevenue" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Expected Revenue">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Num Sent	
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="NumSent" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Num Sent">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Start Date
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" class="form-control" formControlName="StartDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Start Date">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Created By User		
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="CreatedByUser" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Created By User">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Last Modified By User	
                                                                </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="LastModifiedByUser" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Modified By User">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" (click)="createMarketingCampaign()" class="btn btn-secondary submit-btn">Save</button>&nbsp;
                                            <button type="button" (click)="back()" class="btn btn-danger ml-2">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>