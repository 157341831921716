import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '../../header/header.service';
import { JobInterviewService } from "./job-interview.service";
import { JobInterviewFormService } from "./job-interview-service"
import { ToastrService } from 'ngx-toastr';
import { AddSkillService } from '../add-skills/add-skill.service';
import { CreateInternalService } from '../recuitment/create-internal-job-posting/create-internal-job.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import * as moment from 'moment';

import { Location } from '@angular/common';

@Component({
  selector: 'app-job-interview',
  templateUrl: './job-interview.component.html',
  styleUrls: ['./job-interview.component.css']
})
export class JobInterviewComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 2;
  products3: any;
  activeCateg=1;
  activeCategoryMain=2;
  enumId: any;
  enumArray: any = [];
  jobInterviewId: any;
  jobArray: any = [];
  resultStatus: any = [];
  interviewId: any;
  show = false;
  jobRequestion: any;
  getJobRequistionArray: any = [];
  personId: any;
  partyIdArray: any = [];
  updateestifromdate: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  rows = 50;
  pageNo = 1;
  public size = {
    pageSize: this.pageSize,
    pageNo: this.pageNo
  }

  jIDdArray: any = [];
  advanceSearch: {
    gradeSecuredEnumId: string,
    jobInterviewDateFrom: string,
    jobInterviewDateTo: string,
    jobInterviewId: string,
    jobInterviewIdSearchType: string,
    jobInterviewResult: string,
    jobInterviewTypeId: string,
    jobIntervieweePartyId: string,
    jobInterviewerPartyId: string,
    jobRequisitionId: string,
    jobInterviewDateToSearch: string,
    jobInterviewDateFromSearch: string
  }
  dateIdArray: any = [];
  opportunityIdArray: any = []

  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly accountsService: AccountsService, 
    readonly router: Router,
    readonly jobInterviewService: JobInterviewService,
    public addSkillService: AddSkillService,
    public jobInterviewFormService: JobInterviewFormService,
    readonly HeaderService: HeaderService,
      private _location: Location,
      readonly toastr: ToastrService, 
    readonly datePipe: DatePipe,
    readonly spinner:NgxSpinnerService,
    readonly createInternalService: CreateInternalService,) {
    this.advanceSearch = {
      gradeSecuredEnumId: "",
      jobInterviewDateFrom: "",
      jobInterviewDateTo: "",
      jobInterviewId: "",
      jobInterviewIdSearchType: "Contains",
      jobInterviewResult: "",
      jobInterviewTypeId: "",
      jobIntervieweePartyId: "",
      jobInterviewerPartyId: "",
      jobRequisitionId: "",
      jobInterviewDateToSearch: "Equals",
      jobInterviewDateFromSearch: "Equals"
    }
    this.dateIdArray = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },

      {
        label: 'Greater Than',
        value: 'GreaterThan'
      },
      {
        label: 'Greater Than Equal To',
        value: 'GreaterThanEqualTo'
      },
      {
        label: 'Less Than Equal To',
        value: 'LessThanEqualTo'
      },
      {
        label: 'Is Empty',
        value: 'IsEmpty'
      }

    ];
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.jobInterviewFormService.job_interview_form = null;
    this.jobInterviewFormService.jobInterviewForm(this.jobInterviewFormService.job_interview_form);
    this.jobInterviewList();
    this.getJobInterviewDropdown();
    this.getPartyId();
    this.jobInterviewParametre();
    this.getJobRequistionList();
    this.resultStatus = [{
      "label": "Pass",
      "value": "Pass"
    },
    {
      "label": "Fail",
      "value": "Fail"
    }]

    this.jobInterviewParametreNew();
    this.jobInterviewFormService.job_interview_form=null;
    this.jobInterviewFormService.jobInterviewForm(this.jobInterviewFormService.job_interview_form);
    
    this.getPartyIdNew();
    this.getJobRequistionListNew();
 
  }

  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  jobInterviewParametreNew(){
    this.spinner.show();
    this.jobInterviewService.getJobInterviewParameter().subscribe(res=> {
      this.enumId = res.data.gradeSecuredEnumID;
      this.jobInterviewId = res.data.jobInterviewTypeId;
      this.spinner.hide();
      for(const value of this.enumId){
        this.enumArray.push({
          label:value.description,
          value:value.enumId
        })
      }
      for(const value of this.jobInterviewId){
        this.jobArray.push({
          label:value.jobInterviewTypeId,
          value:value.jobInterviewTypeId
        })
      }
    })
  }
  getJobRequistionListNew() {
    this.spinner.show();
    const jobRequistion = {
      examTypeEnumId: "",
      experienceMonthSearchType: "Contains",
      experienceMonthSecond: "",
      experienceMonthSecondSearchType: "Contains",
      experienceMonths: "",
      experienceYearSecond: "",
      experienceYearSecondSearchType: "Contains",
      experienceYears: "",
      experienceYearsSearchType: "Contains",
      jobLocation: "",
      jobLocationSearchType: "Contains",
      jobPostingTypeEnumId: "",
      jobRequisitionId: "",
      skillTypeId: ""
    }
    this.createInternalService.getJobRequistionList(jobRequistion).subscribe(res=> {
      this.jobRequestion=res.data;
      this.spinner.hide();
     for(const value of this.jobRequestion){
       this.getJobRequistionArray.push({
         label:value.jobRequisitionId,
         value:value.jobRequisitionId
       })
     }
    
    })
  }
  onSubmit(){
    this.spinner.show();
   let fromdate=moment(this.jobInterviewFormService.job_interview_form.value.interviewDate).format('YYYY-MM-DD HH:mm:ss')
  
    const formData={
      "gradeSecuredEnum":this.jobInterviewFormService.job_interview_form.value.gradeSecuredEnum,
      "interviewDate": fromdate,
      "interviewResult": this.jobInterviewFormService.job_interview_form.value.interviewResult,
      "interviewTypeId": this.jobInterviewFormService.job_interview_form.value.interviewTypeId,
      "intervieweePartyId": this.jobInterviewFormService.job_interview_form.value.intervieweePartyId,
      "interviewerPartyId":this.jobInterviewFormService.job_interview_form.value.interviewerPartyId,
      "jobRequisitionId": this.jobInterviewFormService.job_interview_form.value.jobRequisitionId
    }

   this.jobInterviewService.createJobInterview(formData).subscribe((res:any)=> {
    if(res.success) {
      this.spinner.hide();
      this.toastr.success("Job Interview Saved Successfully");
      this.jobInterviewFormService.job_interview_form.reset();
      this.getJobInterviewDropdown();
      this.closebutton.nativeElement.click();
      // this.router.navigate(['/hr/job-requisition/job-interview'])
    }
    else if(res.success == false) {
      this.toastr.error(res.data.message);
      this.spinner.hide();
    }
   },(err) => {
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
    this.spinner.hide();
  
 }
 
 )
 this.spinner.hide();
  }
  getPartyIdNew() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res=> {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.personId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
  }
  JobRequisition() {
    this.router.navigate(["/hr/job-requisition"])
  }
  InternalJobPostingApplication() {
    this.router.navigate(["/hr/job-requisition/internal-job-posting"])
  }
  jobInterview() {
    this.router.navigate(["/hr/job-requisition/job-interview"])
  }
  approval() {
    this.router.navigate(["/hr/job-requisition/approval"])
  }
  Relocation() {
    this.router.navigate(["/hr/job-requisition/reAllocations"])
  }
  getJobRequistionList() {
    this.spinner.show();
    const jobRequistion = {
      examTypeEnumId: "",
      experienceMonthSearchType: "Contains",
      experienceMonthSecond: "",
      experienceMonthSecondSearchType: "Contains",
      experienceMonths: "",
      experienceYearSecond: "",
      experienceYearSecondSearchType: "Contains",
      experienceYears: "",
      experienceYearsSearchType: "Contains",
      jobLocation: "",
      jobLocationSearchType: "Contains",
      jobPostingTypeEnumId: "",
      jobRequisitionId: "",
      skillTypeId: ""
    }
    this.createInternalService.getJobRequistionList(jobRequistion).subscribe(res => {
      this.jobRequestion = res.data;
      this.spinner.hide();
      for (const value of this.jobRequestion) {
        this.getJobRequistionArray.push({
          label: value.jobRequisitionId,
          value: value.jobRequisitionId
        })
      }
     
    })
   
  }
  jobInterviewList() {
    this.spinner.show();
    const job = {
      gradeSecuredEnumId: "",
      jobInterviewDateFrom: "",
      jobInterviewDateTo: "",
      jobInterviewId: "",
      jobInterviewIdSearchType: "Contains",
      jobInterviewResult: "",
      jobInterviewTypeId: "",
      jobIntervieweePartyId: "",
      jobInterviewerPartyId: "",
      jobRequisitionId: ""
    }
    this.jobInterviewService.getJobInterviewList(this.size, job).subscribe(res => {
      this.interviewId = res.data;
      this.spinner.hide();
    })
   
  }
  getJobInterviewDropdown() {
    this.spinner.show();
    const job = {
      gradeSecuredEnumId: "",
      jobInterviewDateFrom: "",
      jobInterviewDateTo: "",
      jobInterviewId: "",
      jobInterviewIdSearchType: "Contains",
      jobInterviewResult: "",
      jobInterviewTypeId: "",
      jobIntervieweePartyId: "",
      jobInterviewerPartyId: "",
      jobRequisitionId: ""
    }
    this.jobInterviewService.getJobInterviewList(this.size, job).subscribe(res => {

      this.jobInterviewId = res.data;
      this.spinner.hide();
      for (const value of this.jobInterviewId) {
        this.jIDdArray.push({
          label: value.jobInterviewId,
          value: value.jobInterviewId
        })
      }
    })
   
  }
  onUpdate(id) {
    this.router.navigate(["/hr/job-requisition/add-job-interview"], { queryParams: { interviewId: id } })
  }
  detailPage(id) {
    this.router.navigate(["/hr/job-requisition/detail-job-interview-summary"], { queryParams: { interviewId: id } })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  onDelete(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.jobInterviewService.deleteJobInterview(id).subscribe((res: any) => {
          this.jobInterviewList();
         
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
 

  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  jobInterviewParametre() {
    this.spinner.show();
    this.jobInterviewService.getJobInterviewParameter().subscribe(res => {
      this.enumId = res.data.gradeSecuredEnumID;
      this.jobInterviewId = res.data.jobInterviewTypeId;
      this.spinner.hide();
      for (const value of this.enumId) {
        this.enumArray.push({
          label: value.description,
          value: value.enumId
        })
      }
      for (const value of this.jobInterviewId) {
        this.jobArray.push({
          label: value.jobInterviewTypeId,
          value: value.jobInterviewTypeId
        })
      }
    })
   
  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.interviewId = [];

    const req = {
      "jobInterviewDateFrom": this.datePipe.transform(this.advanceSearch.jobInterviewDateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.jobInterviewDateFrom, "yyyy-MM-dd hh:mm:ss") : '',
      "jobInterviewDateTo": this.datePipe.transform(this.advanceSearch.jobInterviewDateTo, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.jobInterviewDateTo, "yyyy-MM-dd hh:mm:ss") : '',

      "gradeSecuredEnumId": this.advanceSearch.gradeSecuredEnumId ? this.advanceSearch.gradeSecuredEnumId : '',
      "jobInterviewId": this.advanceSearch.jobInterviewId ? this.advanceSearch.jobInterviewId : '',
      "jobInterviewIdSearchType": this.advanceSearch.jobInterviewIdSearchType ? this.advanceSearch.jobInterviewIdSearchType : '',
      "jobInterviewResult": this.advanceSearch.jobInterviewResult ? this.advanceSearch.jobInterviewResult : '',
      "jobInterviewTypeId": this.advanceSearch.jobInterviewTypeId ? this.advanceSearch.jobInterviewTypeId : '',
      "jobIntervieweePartyId": this.advanceSearch.jobIntervieweePartyId ? this.advanceSearch.jobIntervieweePartyId : '',
      "jobInterviewerPartyId": this.advanceSearch.jobInterviewerPartyId ? this.advanceSearch.jobInterviewerPartyId : '',
      "jobRequisitionId": this.advanceSearch.jobRequisitionId ? this.advanceSearch.jobRequisitionId : '',


    }
    this.jobInterviewService.getJobInterviewList(this.size, req).subscribe(res => {
      this.interviewId = res.data;
      this.spinner.hide();
    })
   
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.jobRequisitionId = "";
    this.advanceSearch.jobInterviewerPartyId = "";
    this.advanceSearch.jobIntervieweePartyId = "";
    this.advanceSearch.jobInterviewTypeId = "";
    this.advanceSearch.jobInterviewResult = "";
    this.advanceSearch.jobInterviewId = "";
    this.advanceSearch.jobInterviewDateTo = "";
    this.advanceSearch.jobInterviewDateFrom = "";
    this.advanceSearch.gradeSecuredEnumId = ""
    const job = {
      gradeSecuredEnumId: "",
      jobInterviewDateFrom: "",
      jobInterviewDateTo: "",
      jobInterviewId: "",
      jobInterviewIdSearchType: "Contains",
      jobInterviewResult: "",
      jobInterviewTypeId: "",
      jobIntervieweePartyId: "",
      jobInterviewerPartyId: "",
      jobRequisitionId: ""
    }
    this.jobInterviewService.getJobInterviewList(this.size, job).subscribe(res => {
      this.interviewId = res.data;
      this.spinner.hide();
    })
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
