<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Find Revision
                </span>
                <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            
            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                        <li [ngClass]="activeCategory==1?'active':''" (click)="Backlog()"><a>
                                Product Backlog </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="Sprint()"><a>
                                Project/Sprint
                            </a></li>
                        <li [ngClass]="activeCategory==3?'active':''" (click)="MyWork()"><a>
                                My Work</a></li>
                        <li [ngClass]="activeCategory==4?'active':''" (click)="Tasks()"><a>
                                Tasks
                            </a></li>
                        <li [ngClass]="activeCategory==5?'active':''" (click)="OpenTest()"><a>
                                Open Test
                            </a></li>
                        <li [ngClass]="activeCategory==6?'active':''" (click)="Revision()"><a>
                                Revision
                            </a></li>


                    </ul>
                </div>
                <div class=" bg-white color-grey">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">

                                    <div class="w3-card-4  w-100">
                                        <div class="accordion classCard" id="myAccordion">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                        <span class="headingAccords"> Find Revision

                                                        </span>
                                                       
                                                       
                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 74%!important; padding-top: 11px !important;
                                                            padding-bottom: 11px !important;">
                                                        </button>									
            
                                                    </div>
                
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                    <div class="card-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightFormG">
                                                                        <label for="exampleInputEmail1">
                                                                            Product ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                
                                                                        <p-dropdown filter="true" name="ProductID" optionlabel="label" [options]="ProductNameArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ProductID" placeholder="Choose Product ID">
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                    <div class="col-lg-3 form-group rightFormH">
                                                                        <label for="exampleInputEmail1">Backlog ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.BacklogID" name="BacklogID" aria-describedby="emailHelp" placeholder="Enter Backlog ID">
                                                                    </div>
                
                                                                </div>
                                                            </div>
                
                                                            
                
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group rightFormG">
                                                                        <label for="exampleInputEmail1">Task ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.TaskID" name="TaskID" aria-describedby="emailHelp" placeholder="Enter Task ID">
                                                                    </div>
                                                                </div>
                                                            </div>
                
                
                
                
                                                        </div>

                                                        <div class="col-lg-12  main-submit-button" style="
                                                                   margin-left: -11%;">
                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="FindTaskRevision()">Find</button>&nbsp;
                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger" (click)="reset()">Reset</button>&nbsp;


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>


                                    <!-- <div class="w3-card-4 classCard">
                                        <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                        flex-wrap: wrap !important;
                        display: flex !important;">
                                                <li><a>
                                                        Find Revision

                                                    </a>
                                                </li>

                                            </ul>
                                        </div>


                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">
                                                            Product ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" name="ProductID" optionlabel="label" [options]="ProductNameArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.ProductID" placeholder="Choose Product ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Backlog ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.BacklogID" name="BacklogID" aria-describedby="emailHelp" placeholder="Enter Backlog ID">
                                                    </div>

                                                </div>
                                            </div>

                                            

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Task ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email" class="form-control" id="exampleInputEmail1" [ngModelOptions]="{standalone:true}" [(ngModel)]="advanceSearch.TaskID" name="TaskID" aria-describedby="emailHelp" placeholder="Enter Task ID">
                                                    </div>
                                                </div>
                                            </div>




                                        </div>
                                        <div class="col-lg-12  main-submit-button" style="
                                                                   margin-left: -11%;">
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="FindTaskRevision()">Find</button>&nbsp;
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger" (click)="reset()">Reset</button>&nbsp;


                                        </div>

                                    </div> -->

                                    <div class="w3-card-4 classCardView mt-2">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Revision List
                                                </h4>

                                            </div>
                                        </header>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="revisionList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Revision
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Message
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Product Name
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Product Backlog
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Task Name
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            From Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Committer
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>



                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span *ngIf="product.contentName" >
                                                                                {{product.contentName[0].contentName}}</span>

                                                                        </div>
                                                                    </td>


                                                                    <td [ngStyle]="{'width':'150px'}" *ngIf="product.des">
                                                                        {{product.des[0].description}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'150px'}" *ngIf="product.des">
                                                                        {{product.des[0].internalName}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        {{product.namess.custRequestId}}

                                                                    </td>

                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        {{product.workname[0].workEffortName}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        {{product.ProductBacklogsItemLists.fromDate | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        {{product.ProductBacklogsItemLists.partyId}}
                                                                    </td>



                                                                </tr>
                                                            </ng-template>

                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                        <h4
                                                                            class="ml-2">
                                                                            No Record
                                                                            Found</h4>
                                                                    </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>