import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-detail-portal-communication',
  templateUrl: './detail-portal-communication.component.html',
  styleUrls: ['./detail-portal-communication.component.css']
})
export class DetailPortalCommunicationComponent implements OnInit {
 
  activeCategory=1;
  subject: any;
  entryDate: any;
  communicationEventTypeDesc: any;
  statusDesc: any;
  partyIdFrom: any;
  roleTypeId: any;
  roleStatusId: any;

  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router) { }
   
  ngOnInit(): void {
  
    this.activatedRoute.queryParams.subscribe(params=> [
      this.subject = params["subject"],
      this.communicationEventTypeDesc=params["communicationEventTypeDesc"],
      this.statusDesc=params["statusDesc"],
      this.partyIdFrom=params["partyIdFrom"],
      this.roleTypeId = params["roleTypeId"],
      this.roleStatusId=params["roleStatusId"],
      this.entryDate=params["entryDate"],
     
    ])
   
  }
  cancelSubmit(){
    this.router.navigate(["/myPortal/my-portal-other-party-comms"])
  }

}

