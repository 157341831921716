import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

@Component({
  selector: 'app-tracking-id-access',
  templateUrl: './tracking-id-access.component.html',
  styleUrls: ['./tracking-id-access.component.css']
})
export class TrackingIdAccessComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  updateProfile: FormGroup;
  createUserLogIn: FormGroup;
  addRelatedAccount: FormGroup;
  createNewContactInformation: FormGroup;
  partyAttributes: FormGroup;
  addCheckAccount: FormGroup;
  createBillingAccount: FormGroup;
  addNewCreditCard: FormGroup;
  createGiftCard: FormGroup;
  createEFTAcc: FormGroup;
  CreateDataSourceForm: FormGroup;
  CreateDataSourceTypeForm: FormGroup;
  CreateMarketingCampaignForm: FormGroup;
  CreateMarketingCampaignRoleForm: FormGroup;
  CreateTrackingCodeForm: FormGroup;
  CreateTrackingCodeTypeForm: FormGroup;
  TrackingCodeTypesIds: any;
  TrackingCodeTypesIdsArray: any[]=[];
  MarketingCampaignsIdsLists: any;
  MarketingCampaignsIdsListsArray: any[]=[];
  ProductCatalogsIds: any;
  ProductCatalogsIdsArray: any[]=[];

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
    this.CreateTrackingCodeForm = this._FormBuilder.group({
      TrackingCodeID: [''],
      Description: [''],
      Comments: [''],
      TrackingCodeTypeID: [''],
      MarketingCampaignID: [''],
      DefaultProductCatalogID: [''],
      FromDate: [''],
      ThroughDate: [''],
      RedirectURL: [''],
      OverrideLogoURL: [''],
      OverrideCSSURL: [''],
      TrackableLifetime: [''],
      BillableLifetime: [''],
      GroupID: [''],
      SubGroupID: [''],
      
    });
    this.CreateTrackingCodeTypeForm = this._FormBuilder.group({
      TrackingCodeTypeID: [''],
      Description: [''],
      
    });
   }

  ngOnInit(): void {
    this.getTrackingCodeTypesIds();
    this.getMarketingCampaignsIdsLists();
    this.getdefaultProductCatalogsIds();
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  getTrackingCodeTypesIds() {
    this.spinner.show();
    this.myContactsService.getTrackingCodeTypesIds().subscribe(res => {
      this.TrackingCodeTypesIds = res.data[0].data;
      this.spinner.hide();
      for (const value of this.TrackingCodeTypesIds) {
        this.TrackingCodeTypesIdsArray.push({
          label: value.description,
          value: value.trackingCodeTypeId
        })
      }
    })
  }
  getMarketingCampaignsIdsLists() {
    this.spinner.show();
    this.myContactsService.getMarketingCampaignsIdsLists().subscribe(res => {
      this.MarketingCampaignsIdsLists = res.data[1].data;
      this.spinner.hide();
      for (const value of this.MarketingCampaignsIdsLists) {
        this.MarketingCampaignsIdsListsArray.push({
          label: value.campaignName,
          value: value.marketingCampaignId
        })
      }
    })
  }
  getdefaultProductCatalogsIds() {
    this.spinner.show();
    this.myContactsService.defaultProductCatalogsIds().subscribe(res => {
      this.ProductCatalogsIds = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ProductCatalogsIds) {
        this.ProductCatalogsIdsArray.push({
          label: value.catalogName,
          value: value.prodCatalogId
        })
      }
    })
  }
}
