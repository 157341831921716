<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div>
          <div class="container-fluid">
            <div class="row">
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title port-header d-flex justify-content-between" style="background:#0d3769">
                          <a class=" w-100 position-relative  tabs-header"
                            style="display: flex !important; justify-content: space-between;">
                            Purchase Order : Order Entry Payment Settings
                            <div class="icon-wrapper sales-properties custom-properties">
                              <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                                href="#seo-collapse">
                              </span>
                              <span class="header-labels continue-btn" routerLinkActive="active"
                                routerLink="/order/create-purchase-continue"> Order Items</span>
                              <span class="header-labels continue-btn" routerLinkActive="active"
                                routerLink="/order/purchase-finalize-order">Shipping</span>
                              <span class="header-labels">Options</span>
                              <span class="header-labels">Order Terms</span>
                              <span class="header-labels">Payment</span>
                              <span class="header-labels header-labels-opacity"> Parties</span>
                              <span class="header-labels header-labels-opacity"> Reviews Order</span>




                              <span class="header-labels continue-btn" routerLinkActive="active"
                                (click)="finalizeOrder()"> Continue</span>
                            </div>
                          </a>
                        </h4>
                      </div>
                      <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                        <div class="panel-body">
                          <div class="mt-2">
                            <div class="">
                              <h4 class="detail-heading">Select Payment Mehtod
                              </h4>
                              <div class="radios-sections">
                                <ng-template ngFor let-payment [ngForOf]="paymentMethodTypes" let-index="index">
                                  <div class="col-lg-9 pl-0 col-12">
                                    <div class="form-group  d-flex radio-boxes-wrapper">
                                      <input type="radio" [id]="index+'Guaranteed'" name="radio"
                                        [value]="payment.paymentMethodTypeId"
                                        (change)="setPaymentMethod($event.target.value)">
                                      <label class="common-labels"
                                        [for]="index+'Guaranteed'">{{payment.paymentMethodType}}</label>
                                    </div>
                                  </div>
                                </ng-template>
                              
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner> 