<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w3-card-4 classCard">
                                        <div class=" bg-white color-grey">
                                            <div class="create-new-leade">

                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                                                        <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                                            <a>Tracking code</a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                                            <a>Tracking Code Orders</a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                                            <a>Tracking Code Visits</a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                                            <a>Tracking Code Type</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                    <div class="create-new-leade ">
                                                        <div class="container-fluid">

                                                            <div *ngIf="activeCategory==1">

                                                                <div class="w3-card-4 classCardView mt-2">
                                                                    <header class="w3-container w3-blue">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling h4Margin">
                                                                                Edit Tracking Code
                                                                            </h4>
                                                                            <span style="margin-right: 10px;">
                                                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleCreateTrackingCode"> 
                                                                                    Create Tracking Code</button>
                                                                            </span>
                                                                        </div>
                                                                    </header>
                                                                    <div class="all-users-infomation font-13 font-medium  own-user-select" style="margin-left: 2%;">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Tracking Code ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">

                                                                                </div>
                                                                                <div class="col-lg-2 form-group">
                                                                                    <input type="email" class="form-control" name="TrackingCodeID" [ngModelOptions]="" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tracking Code ID">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Description</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">

                                                                                </div>
                                                                                <div class="col-lg-2 form-group">
                                                                                    <input type="email" class="form-control" name="Description" [ngModelOptions]="" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Comments</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">

                                                                                </div>
                                                                                <div class="col-lg-2 form-group">
                                                                                    <input type="email" class="form-control" name="Comments" [ngModelOptions]="" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">
                                                                                        Tracking Code Type ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2 form-group">

                                                                                    <p-dropdown filter="true" placeholder="Enter Tracking Code Type ID" optionlabel="label" [options]="TrackingCodeTypeIdsArray" [ngModelOptions]="" name="TrackingCodeTypeID">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Marketing Campaign ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">

                                                                                </div>
                                                                                <div class="col-lg-2 form-group">
                                                                                    <p-dropdown filter="true" [ngModelOptions]="" name="MarketingCampaignID" optionlabel="label" [options]="MarketingCampaignIdsListsArray" placeholder="Enter Marketing Campaign ID">

                                                                                    </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Default Product Catalog ID </label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">

                                                                                </div>
                                                                                <div class="col-lg-2 form-group">
                                                                                    <p-dropdown filter="true" [ngModelOptions]="" name="DefaultProductCatalogID" optionlabel="label" [options]="ProductCatalogIdsArray" placeholder="Enter Default Product Catalog ID">

                                                                                    </p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">From Date</label>
                                                                                </div>
                                                                                <div class="col-lg-2 form-group">
                                                                                    <input type="date" class="form-control" name="FromDate" [ngModelOptions]="" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Through Date</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="date" class="form-control" name="ThroughDate" [ngModelOptions]="" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Redirect URL</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">

                                                                                </div>
                                                                                <div class="col-lg-2 form-group">
                                                                                    <input type="email" class="form-control" name="RedirectURL" [ngModelOptions]="" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Redirect URL">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Override Logo URL</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">

                                                                                </div>
                                                                                <div class="col-lg-2 form-group">
                                                                                    <input type="email" class="form-control" name="OverrideLogoURL" [ngModelOptions]="" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Override Logo URL">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Override CSS URL</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">

                                                                                </div>
                                                                                <div class="col-lg-2 form-group">
                                                                                    <input type="email" class="form-control" name="OverrideCSSURL" [ngModelOptions]="" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Override CSS URL">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Trackable Lifetime</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">

                                                                                </div>
                                                                                <div class="col-lg-2 form-group">
                                                                                    <input type="email" class="form-control" name="TrackableLifetime" [ngModelOptions]="" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Trackable Lifetime">
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Billable Lifetime</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">

                                                                                </div>
                                                                                <div class="col-lg-2 form-group">
                                                                                    <input type="email" class="form-control" name="BillableLifetime" [ngModelOptions]="" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Billable Lifetime">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Group ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">

                                                                                </div>
                                                                                <div class="col-lg-2 form-group">
                                                                                    <input type="email" class="form-control" name="GroupID" [ngModelOptions]="" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Group ID">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Sub-Group ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">

                                                                                </div>
                                                                                <div class="col-lg-2 form-group">
                                                                                    <input type="email" class="form-control" name="Sub-GroupID" [ngModelOptions]="" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sub-Group ID">
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -65%;">
                                                                        <button type="submit" class="btn btn-secondary submit-btn">Save</button>&nbsp;

                                                                    </div>
                                                                </div>







                                                            </div>
                                                            <div *ngIf="activeCategory==2">
                                                                <div class="panel-group">


                                                                    <div class="w3-card-4 classCardView mt-2">
                                                                        <header class="w3-container w3-blue">
                                                                            <div class="header-tabs">
                                                                                <h4 class=" common-styling h4Margin">
                                                                                    List Tracking Code Order for Tracking Code ID=9001
                                                                                </h4>
                                                                                <span style="margin-right: 10px;">
                                                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleCreateTrackingCode"> 
                                                                                    Create Tracking Code</button>
                                                                                    
                                                                            </span>
                                                                            </div>
                                                                        </header>

                                                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                            <div class="col-lg-12 col-12">
                                                                                <div class="form-group">

                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>


                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;">

                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>


                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            <span *ngFor="let r of product.contentName"  class="account-button"  >
                                                                                                            </span>

                                                                                                        </div>
                                                                                                    </td>

                                                                                                    <td [ngStyle]="{'width':'180px'}" *ngFor="let r of product.des">

                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'180px'}" *ngFor="let r of product.des">

                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>

                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                                    <h4
                                                                                                        class="ml-2">
                                                                                                        No Record
                                                                                                        Found</h4>
                                                                                                </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>

                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>






                                                                </div>
                                                            </div>
                                                            <div *ngIf="activeCategory==3">
                                                                <div class="panel-group">


                                                                    <div class="w3-card-4 classCardView mt-2">
                                                                        <header class="w3-container w3-blue">
                                                                            <div class="header-tabs">
                                                                                <h4 class=" common-styling h4Margin">
                                                                                    List Tracking Code Visit for Tracking Code ID=9001
                                                                                </h4>
                                                                                <span style="margin-right: 10px;">
                                                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleCreateTrackingCode"> 
                                                                                    Create Tracking Code</button>
                                                                                    
                                                                            </span>
                                                                            </div>
                                                                        </header>

                                                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                            <div class="col-lg-12 col-12">
                                                                                <div class="form-group">

                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>


                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;">

                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>


                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            <span *ngFor="let r of product.contentName"  class="account-button"  >
                                                                                                            </span>

                                                                                                        </div>
                                                                                                    </td>

                                                                                                    <td [ngStyle]="{'width':'180px'}" *ngFor="let r of product.des">

                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'180px'}" *ngFor="let r of product.des">

                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>

                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                                    <h4
                                                                                                        class="ml-2">
                                                                                                        No Record
                                                                                                        Found</h4>
                                                                                                </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>

                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>






                                                                </div>
                                                            </div>
                                                            <div *ngIf="activeCategory==4">
                                                                <div class="panel-group">


                                                                    <div class="w3-card-4 classCardView mt-2">
                                                                        <header class="w3-container w3-blue">
                                                                            <div class="header-tabs">
                                                                                <h4 class=" common-styling h4Margin">
                                                                                    List Tracking Code Type
                                                                                </h4>
                                                                                <span style="margin-right: 10px;">
                                                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleCreateTrackingCode"> 
                                                                                    Create Tracking Code</button>
                                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleCreateTrackingCodeType"> 
                                                                                        Create Tracking Code Type</button>
                                                                            </span>
                                                                            </div>
                                                                        </header>

                                                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                                            <div class="col-lg-12 col-12">
                                                                                <div class="form-group">

                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>

                                                                                                            Tracking Code Type ID
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;">
                                                                                                            Description
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>


                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            <span *ngFor="let r of product.contentName"  class="account-button"  >
                                                                                                            {{r.contentName}}</span>

                                                                                                        </div>
                                                                                                    </td>

                                                                                                    <td [ngStyle]="{'width':'180px'}" *ngFor="let r of product.des">

                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'180px'}" *ngFor="let r of product.des">
                                                                                                        <span>
                                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCenter"> 
                                                                                                            delete</button>
                                                                                                    </span>
                                                                                                    </td>

                                                                                                </tr>
                                                                                            </ng-template>

                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                                    <h4
                                                                                                        class="ml-2">
                                                                                                        No Record
                                                                                                        Found</h4>
                                                                                                </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>

                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>






                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleCreateTrackingCode" tabindex="-1" role="dialog" aria-labelledby="exampleCreateTrackingCodeTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">


                <span class="color-black pl-1">Add New Tracking Code</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                    Add New Tracking Code</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="CreateTrackingCodeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">



                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Tracking Code ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="TrackingCodeID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tracking Code ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="Description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Comments
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="Comments" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Tracking Code Type ID
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="TrackingCodeTypeID" optionlabel="label" [options]="TrackingCodeTypeIdsArray" placeholder="Enter Tracking Code Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Marketing Campaign ID
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="MarketingCampaignID" optionlabel="label" [options]="MarketingCampaignIdsListsArray" placeholder="Enter Marketing Campaign ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Default Product Catalog ID
                                                            
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="DefaultProductCatalogID" optionlabel="label" [options]="ProductCatalogIdsArray" placeholder="Enter Default Product Catalog ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="FromDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="date" class="form-control" formControlName="ThroughDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Redirect URL		
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="RedirectURL" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Redirect URL">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Override Logo URL	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="OverrideLogoURL" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Override Logo URL">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Override CSS URL	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="OverrideCSSURL" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Override CSS URL">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Trackable Lifetime	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="TrackableLifetime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Trackable Lifetime">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Billable Lifetime	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="BillableLifetime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Billable Lifetime">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Group ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="GroupID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Group ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Sub Group ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="SubGroupID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Sub Group ID">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button" style="margin-left: -9%;">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleCreateTrackingCodeType" tabindex="-1" role="dialog" aria-labelledby="exampleCreateTrackingCodeTypeTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">


                <span class="color-black pl-1">Add New Tracking Code Type</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                    Add New Tracking Code Type</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="CreateTrackingCodeTypeForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-left: -6%;">



                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group classInput">
                                                        <label for="exampleInputEmail1">Tracking Code Type ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="TrackingCodeTypeID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tracking Code Type ID">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-4 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <input type="text" class="form-control" formControlName="Description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-6 col-6 main-submit-button" style="margin-left: -3%;">
                                            <button type="submit" class="btn btn-secondary submit-btn">Save</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>