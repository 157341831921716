import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {FormGroup, FormBuilder} from '@angular/forms';
import { CRMAccountService } from 'src/app/services/crm-account.service'
import { GlobalResourceService } from 'src/app/services/global-resource.service'
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
@Component({
  selector: 'app-create-new-portal-page',
  templateUrl: './create-new-portal-page.component.html',
  styleUrls: ['./create-new-portal-page.component.css']
})
export class CreateNewPortalPageComponent implements OnInit {

  show=false;
  portalPage:FormGroup;
  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize= 100;
  rows = 50;
  pageNo=1;
  search="";
  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  userLoginIdArray:any[]=[];
  array:any[]=[];
  partyId: string;
  securtiyList: any;
  groupIdIdArray: any[]=[];
  descriptionIdArray: any[]=[];
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,) {
      this.partyId=localStorage.getItem("partyId")
    this.portalPage = this._FormBuilder.group({
      Description: [''],
      SecurityGroupID:[''],
      SequenceNum:[''],
      Portalpagename:[''],
      OwnerUserLoginID:[''],
      OriginalPage:[''],
      Parentpage:[''],
      PortalPageID:['']  
    });
   }

  ngOnInit(): void {
    this.getUserDetails();
  }

  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  getUserDetails(){
    this.spinner.show();
    const req={
  "description": "",
  "descriptionSearchType": "Contains",
  "groupId": "",
  "groupIdSearchType": "Contains"
     
    }
    this.accountsService.getSecurityGroupList(this.user,req).subscribe((res:any)=> {
      this.securtiyList = res.data;
      this.spinner.hide();
      for(const value of this.securtiyList){
      this.groupIdIdArray.push({
        label:value.groupName,
        value:value.groupId
      })
    } 
    for(const value of this.securtiyList){
      this.descriptionIdArray.push({
        label:value.description,
        value:value.description
      })
    } 
    })   
   }
  onSubmit() {
    this.spinner.show();
   
    const requestData = {
      "description": this.portalPage.value.Description,
      "originalPortalPageId": this.portalPage.value.OriginalPage,
      "ownerUserLoginId": this.portalPage.value.OwnerUserLoginID,
      "parentPortalPageId": this.portalPage.value.Parentpage,
      "portalPageId": this.portalPage.value.PortalPageID,
      "portalPageName": this.portalPage.value.Portalpagename,
      "securityGroupId":this.portalPage.value.SecurityGroupID,
      "sequenceNum": this.portalPage.value.SequenceNum,
     
  
     
    }
    this.accountsService.createPortalPageAdm(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/myPortal/my-portal-preferences']);
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  GoToMyPortal(){
    this._Router.navigate(['/myPortal/my-portal-preferences']);
  }

}
