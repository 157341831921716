<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
              
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    Edit Group Information
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" *ngIf="editContactFormService.edit_group_form"
                                [formGroup]="editContactFormService.edit_group_form">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Customer ID</label>
                                            <input type="email" id="exampleInputEmail1" class="form-control"
                                                [(ngModel)]="partyIdValue" [ngModelOptions]="{standalone: true}"
                                                aria-describedby="emailHelp" >
                                           
                                        </div>

                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Group Name <span
                                                    style="color:red">*</span></label>
                                            <input type="email" [(ngModel)]="groupName" formControlName="groupName"
                                                class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Group Name">
                                        </div>

                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Group Name local</label>
                                            <input type="email" [(ngModel)]="groupLocalName"
                                                formControlName="groupLocalName" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Group Name Local">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Office Site Name</label>
                                            <input type="email" [(ngModel)]="officeSiteName"
                                                formControlName="officeSiteName" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Office Site Name">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Annual revenue</label>
                                            <input type="email" [(ngModel)]="annualRevenue"
                                                formControlName="annualRevenue" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Annual Revenue">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Number of employess</label>
                                            <input type="email" [(ngModel)]="numEmployees"
                                                formControlName="noOfEmployees" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Number Of Employess">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Ticker Symbol</label>
                                            <input formControlName="tickerSymbol" [(ngModel)]="tickerSymbol"
                                                class="form-control" optionlabel="label" placeholder="Ticker symbol">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Comments</label>
                                            <input type="email" [(ngModel)]="comments" formControlName="comments"
                                                class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Comments">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Logo Image Url</label>
                                            <input [(ngModel)]="logoImageUrl" formControlName="logoImageUrl"
                                                optionlabel="label" class="form-control" placeholder="Logo Image Url">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Description</label>
                                            <input type="email" [(ngModel)]="description" formControlName="description"
                                                class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Description">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Currency</label>
                                            <div>
                                                <p-dropdown filter="true" [(ngModel)]="preferredCurrencyUomId"
                                                    [options]="currencyArray" formControlName="currencyId"
                                                    optionlabel="label" placeholder="Select Currency"></p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">External Id</label>
                                            <input type="email" [(ngModel)]="externalId" formControlName="externalId"
                                                class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter External Id">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Status Id</label>
                                            <p-dropdown filter="true" [(ngModel)]="statusId" formControlName="statusId"
                                                [options]="crmArray" optionlabel="label" placeholder="Select Status Id">
                                            </p-dropdown>
                                        </div>
                                    </div>
                                  
                                </div>


                                <div class="main-submit-button">
                                    <button type="submit" (click)="onUpdate()"
                                        class="btn btn-secondary submit-btn">Update</button>
                                    <button type="button" routerLink="/crm/my-contacts" routerLinkActive="active"
                                        (click)="onCancel()" class="btn btn-degault cncl-btn">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>