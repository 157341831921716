<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between 
          bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Supply Chain Management</span>
                <span>
                    <button type="submit" class="btn btn-danger buttonclass" (click)="cancelSubmit()">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div>
                    <div class="container-fluid">
                        <div class="row" >
                            <form class="w-100">
                                <div class="w-100">
                                    <div class="panel-group">
                                        <div *ngIf="activeCategoryValue==2">
                                            <div style="width: 49.8%; float:left">
                                                <div class="w3-card-4 cardEffect"
                                                    >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Product Management
                                                            </h4>
                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/product-management/overview">
                                                                                    Product Management Overview
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                             

                                                <div class="w3-card-4 cardEffect mt-2"
                                                    >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Web POS
                                                            </h4>

                                                        </div>
                                                    </header>
                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/webpos/control/main"
                                                                                    (click)="logoutPOS()"
                                                                                    routerLink="/login?webPos=webPos">
                                                                                    Web POS Login</p>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                <div class="w3-card-4 cardEffect mt-2"
                                                    >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Order
                                                            </h4>
                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/order/Dashboard">Order
                                                                                    Overview
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                          
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Warehouse
                                                            </h4>
                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/warehouse-dashborad-overview">
                                                                                    Warehouse Dashboard
                                                                                </p>
                                                                                <span routerLink="/facilities/warehouse"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    style="cursor: pointer;   
                                                     color: #0000FF;">Facility
                                                                                </span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/facility-group">
                                                                                    Facility Group
                                                                                </p>
                                                                                <span
                                                                                    style=" cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/inventory-labels">Inventory
                                                                                    Item
                                                                                </span>

                                                                            </div>
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/shipment-config">
                                                                                    Shipping</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br>
                                            </div>

                                            <div style="width: 49.5%; float:right">
                                                <div class="w3-card-4 cardEffect" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Distribution
                                                            </h4>
                                                        </div>
                                                    </header>
                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/Distribution">
                                                                                    Distributor Details

                                                                                </p>
                                                                                <span style="    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/Distribution">
                                                                                    Distributor Order</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;    color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/Distribution">
                                                                                    Track Distribution</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                                <div class="w3-card-4 cardEffect mt-2" >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Vendor </h4>
                                                        </div>
                                                    </header>
                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <p routerLink="/vendor/Dashboard"
                                                                            style="cursor: pointer;    color: #0000FF;"
                                                                            class="color-grey pl-20 col-lg-10 col-12">
                                                                            Vendor Dashboard</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                           
                                                <div class="w3-card-4 cardEffect mt-2"  >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Production </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <p style="cursor: pointer;    color: #0000FF;"
                                                                        class="color-grey pl-20 col-lg-10 col-12"
                                                                        routerLink="/production/main-production">
                                                                        Production </p>




                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                
                                                <div class="w3-card-4 cardEffect mt-2"
                                                    >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Shipment

                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/shipment-config">
                                                                                    Shipment Gateway Config
                                                                                </p>

                                                                                <span
                                                                                    style=" cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/warehouse-shipment">Shipments
                                                                                </span>
                                                                            </div>


                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                             
                                                <div class="w3-card-4 cardEffect mt-2"
                                                    >
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Planning

                                                            </h4>

                                                        </div>
                                                    </header>

                                                    <div class="w3-container">
                                                        <div class="panel-body" style="background: white;">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/Planning">
                                                                                    Inventory</p>
                                                                                <span
                                                                                    style="cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/Planning/find-requirements">Find
                                                                                    Requirements </span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/Planning/open-requirements">
                                                                                    Open Requirements </p>
                                                                                <span
                                                                                    style=" cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/Planning/product-requirements">Approved
                                                                                    Product Requirements</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/Planning/internal-requirements">
                                                                                    Approved Internal Requirements</p>
                                                                                <span
                                                                                    style="cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/Planning/transfer-requirements">Approved
                                                                                    Transfer Requirements </span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/Planning/run-mrp">
                                                                                    Run MRP </p>
                                                                                <span
                                                                                    style=" cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/Planning/view-mrp">View
                                                                                    MRP </span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p style="cursor: pointer;color: #0000FF;"
                                                                                    class="color-grey pl-20 col-lg-10 col-12"
                                                                                    routerLink="/facilities/Planning/configure-warehouse">
                                                                                    Configure Warehouse</p>
                                                                            </div>

                                                                        </div>


                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <br>

                                            </div>



                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>


                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>