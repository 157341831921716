import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { WarehouseShipmentService } from '../../warehouse-shipment/warehouse-shipment.service';
import { NewWarehouseShipmentService } from '../../new-warehouse-shipment/new-warehouse-shipment.service';
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { WarehouseService } from '../../warehouse/warehouse.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-show-return',
  templateUrl: './show-return.component.html',
  styleUrls: ['./show-return.component.css']
})
export class ShowReturnComponent implements OnInit {
  facilityId: any;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  returnNumber: any;
  reasonid: any;
  received: any;
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  status: { label: string; value: string; }[];
  savedarray: any[]=[];
  date: Date;

  constructor(readonly router: Router,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly WarehouseShipmentService: WarehouseShipmentService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly _WarehouseService: WarehouseService) { 
      this.status=[{
        
          label: 'Returned ',
          value: 'INV_RETURNED'
        },
        {
          label: 'Available',
          value: 'INV_AVAILABLE'
        },
        {
          label: 'Defective',
          value: 'INV_NS_DEFECTIVE'
        
       
      }]
      this.opportunityIdArray = [
        {
          label: 'Serialized',
          value: 'SERIALIZED_INV_ITEM'
        },
        {
          label: 'Non-Serialized',
          value: 'NON_SERIAL_INV_ITEM'
        },
       
        
      ];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
   
    this.facilityId = this._ActivatedRoute.snapshot.queryParams.facilityId;
    this.returnNumber = this._ActivatedRoute.snapshot.queryParams.receiveReturnNumber;
  this.getReturnView();
  }
 
  getReturnView(): void {
    this.spinner.show();
    this._WarehouseService.getReturnView(this.facilityId,this.returnNumber)
      .then(data => {

        this.spinner.hide();
        this.reasonid = data.data.returnItemList;
        this.received=data.data.receivedItems
      });
     
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
}
submitData(value){
  this.spinner.show();
  this.date=new Date();

  let req={
    
      "comments": "",
      "datetimeReceived": this.datePipe.transform(this.date,"yyyy-MM-dd hh:mm:ss"),
      "inventoryItemTypeId": value.product.inventoryItemTypeId,
      "locationSeqId": value.facilityLocation,
      "productId": value.productId,
      "quantityAccepted": value.receivedQuantity,
      "quantityRejected":0,
      "returnItemSeqId":value.returnItemSeqId,
      "shipmentId": "",
      "statusId":value.statusId,
      "unitCost":value.returnPrice
    }
    this.savedarray.push(req);
    this.spinner.hide();
  }
  productValueSelectAll(e){
    this.savedarray=[]
 if(e.target.ariaChecked=="true"){
 for(let r of this.reasonid){
     let req={
      "comments": "",
      "datetimeReceived": this.datePipe.transform(this.date,"yyyy-MM-dd hh:mm:ss"),
      "inventoryItemTypeId": r.product.inventoryItemTypeId,
      "locationSeqId": r.facilityLocation,
      "productId": r.productId,
      "quantityAccepted": r.receivedQuantity,
      "quantityRejected":0,
      "returnItemSeqId":r.returnItemSeqId,
      "shipmentId": "",
      "statusId":r.statusId,
      "unitCost":r.returnPrice
     }
     this.savedarray.push(req)
 }}
 else{
    this.savedarray=[]
 }
}
  completeSubmit(): void {
    this.spinner.show();
    if ( this.savedarray.length) {

        this._WarehouseService.createReceiveReturn(this.facilityId, this.returnNumber,this.savedarray)
            .then(data => {
                if (data.success) {
                    this.toastr.success('Completed');
                    this.spinner.hide();
                    this.getReturnView();
                    this.savedarray=[];
                 
                }
            });
    } else {
      this.spinner.hide();
        this.toastr.error('Select to proceed');
    }
   
}
ngOnDestroy(): void {
  this.spinner.hide();
    }
}
