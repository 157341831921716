import { DatePipe,Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-edit-party-rates',
  templateUrl: './edit-party-rates.component.html',
  styleUrls: ['./edit-party-rates.component.css']
})
export class EditPartyRatesComponent implements OnInit {
  default=[{
    "label":"Y",
    "Value":"Y"
  },
  {
    "label":"N",
    "Value":"N"
  }]
  createParty:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  SegmentGroup: any;
  SegmentGroupArray: any[]=[];
  currencyArray: any[]=[];
  rateTypes: any;
  rateTypesArray: any[]=[];
  periodTypes: any;
  periodTypesArray: any[]=[];
  @ViewChild('closebutton') closebutton;
  constructor(  readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    public bsModalRef: BsModalRef) { 
      this.createParty = this._FormBuilder.group({
        rateType: [''],
        PeriodTypeID:[''],
        rateAmount: [''],
        currency:[''],
        percentageUsed: [''],
        defaultRate:['']
       
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.show=false;
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
     
      
    });
   this. getCurrency();
   this.getRatesInitialData();
  
  }
  getRatesInitialData() {
    this.spinner.show();
    this.accountsService.getRatesInitialData().subscribe(res => {
      
      this.periodTypes = res.data.periodTypes; 
      this.rateTypes = res.data.rateTypes;
     
      this.spinner.hide();
     
      for (const value of this.periodTypes) {
        this.periodTypesArray.push({
          label: value.description,
          value: value.periodTypeId
        })
      } 
      for (const value of this.rateTypes) {
        this.rateTypesArray.push({
          label: value.description,
          value: value.rateTypeId
        })
      }
     }) }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  
  }
 
  onSubmit() {
    this.spinner.show();
    let requestData = {
      "partyId": this.party,
      "defaultRate": this.createParty.value.defaultRate,
     
      "percentageUsed": this.createParty.value.percentageUsed,
      "periodTypeId":"RATE_HOUR",
      "rateAmount": this.createParty.value.rateAmount,
      "rateCurrencyUomId": this.createParty.value.currency,
      "rateTypeId": this.createParty.value.rateType,
    }

    this.accountsService.createPartyRate(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.closebutton.nativeElement.click();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.debugMessage);
        }
    });

  }
 
  
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
