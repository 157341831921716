import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sales-tax-items',
  templateUrl: './sales-tax-items.component.html',
  styleUrls: ['./sales-tax-items.component.css']
})
export class SalesTaxItemsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;

  activeCategory = 9;
  activeCategories=2;
  resData: any;
  total=0;

  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;

  totalRecords:any;
  salesTaxItems:any;
  salesTaxItemsForm:FormGroup;
  fsmMyWorkSettingsMSList: any;
  fsmMyWorkSettingsMS: any;
  financeSalesTaxItems: any;
  financeSalesTaxItemsById: any;
  show: boolean;
 
 
  
  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) 
  {
    this.salesTaxItemsForm = this._FormBuilder.group({
      itemName:"",
      description:"",
      taxRate:"",
    });
    
  }
  ngOnInit(): void {
    this.getFinanceSalesTaxItems();
  }
  reset(){
    this.show = false;
    this.salesTaxItemsForm.reset();
  }
  getFinanceSalesTaxItemsById(product) {
    this.show = true;
    this.spinner.show();
    this.myContactsService.getFinanceSalesTaxItemsById(product.financeSalesTaxItemsId).subscribe(res => {
      this.financeSalesTaxItemsById = res.data[0].getFinanceSalesTaxItemsById[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.salesTaxItemsForm;
        formValue.patchValue({
          itemName: this.financeSalesTaxItemsById.itemName ,
          description: this.financeSalesTaxItemsById.description ,
          taxRate: this.financeSalesTaxItemsById.taxRate 
        })
      }, 2000);
    })
  }
  updateFinanceSalesTaxItems() {
    this.spinner.show();
    const requestData = {
        "description": this.salesTaxItemsForm.value.description,
        "financeSalesTaxItemsId":  this.financeSalesTaxItemsById.financeSalesTaxItemsId ,
        "itemName": this.salesTaxItemsForm.value.itemName,
        "taxRate": this.salesTaxItemsForm.value.taxRate ,
    }
    this.myContactsService.updateFinanceSalesTaxItems(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.salesTaxItemsForm.reset();
        this.getFinanceSalesTaxItems();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createFinanceSalesTaxItems() {
    this.spinner.show();
    const requestData = 
      {
        "description":this.salesTaxItemsForm.value.description,
        "financeSalesTaxItemsId": "",
        "itemName": this.salesTaxItemsForm.value.itemName,
        "taxRate": this.salesTaxItemsForm.value.taxRate ,
      }
    this.myContactsService.createFinanceSalesTaxItems(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.salesTaxItemsForm.reset();
        this.getFinanceSalesTaxItems();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFinanceSalesTaxItems() {
    this.spinner.show();
    this.myContactsService.getFinanceSalesTaxItems().subscribe(res => {
      this.financeSalesTaxItems = res.data[0].getFinanceSalesTaxItems;
      this.spinner.hide();
    })
  }
  deleteFinanceSalesTaxItems(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "financeSalesTaxItemsId": product.financeSalesTaxItemsId
        }
        this.myContactsService.deleteFinanceSalesTaxItems(req).subscribe(res => {
          this.getFinanceSalesTaxItems();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
