import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HeaderComponent } from 'src/app/header/header.component';
import { HeaderService } from 'src/app/header/header.service';
import { DetaledLeadService } from './detaled-lead.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { LeadSourceService } from './leadSource-service';
import { LeadNotesService } from "../../leadNotes/lead-notes/lead-notes.service";
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { detailedContactsService } from '../contacts/detailed-contacts-summary/detailed-contacts-summary.service';
import { DOCUMENT } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { LeadPersonalLeadServices } from 'src/app/leadPersonal/lead-personal/lead-personal.service';
import { LeadNotesFormService } from 'src/app/leadNotes/lead-notes-service';
import { LeadPersonalServices } from 'src/app/leadPersonalGroup/lead-personal-group/lead-personal-group.service';
import { LeadPersonalFormService } from 'src/app/leadPersonal/lead-personal-service';
import { LeadNewService } from 'src/app/leadPersonalGroup/lead-new.service';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-detaled-lead',
  templateUrl: './detaled-lead.component.html',
  styleUrls: ['./detaled-lead.component.css']
})
export class DetaledLeadComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;

  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;
  @ViewChild('closebutton11') closebutton11;
  @ViewChild('closebutton12') closebutton12;
  @ViewChild('closebutton13') closebutton13;
  @ViewChild('closebutton14') closebutton14;
  @ViewChild('closebutton15') closebutton15;
  @ViewChild('closebutton16') closebutton16;
  @ViewChild('closebutton20') closebutton20;
  products3: any;
  activeCategory = 1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 3;
  partyId: any;
  public datailedData: any;
  public opportunityDatailedData: any;
  name: any;
  id: any;
  fileURL: any;
  leadArray: any = [];
  leadSourceData: any;
  leadSourcearray: any = [];
  isCreate: any = [];
  leadSourceList: any;
  leadNoteId: any;
  public partyGroupInformation: any;
  contactForLead: any;
  contactForLead1: any;
  acountForLead1: any;
  statusId: any;
  Editform: FormGroup;
  fileData: File;
  partyContentData: any;
  partyContentArray: any = [];
  partyContentTypeId: any;
  contentList: any;
  opportunityName: any;
  estimatedAmount: Number;
  status: String;
  public selectedIsCreate = 'Y'
 
  tableArray = [{}]
  openNoRecordFoundPartyContent = false;
  openNoRecordFoundContactInfo= false;
  openNoRecordFoundNote = false;
  openNoRecordFoundLeadSourceList = false;
  isConvertedLead= true;
  partyIds: any;
  currency: any;
  personId: any;
  externalId;
  firstName;
  lastName;
  crmArray: any = [];
  preSelectedstatus = ''
  updateData: any[];
  comments;
  showUpdateBtn=false;
  showCreateBtn:Boolean=false;
  noteName='';
  note='';
  partyIdForCreate: any;
  noteId: any;
  partyGroupId: any;
  partyIdValue: any;
  crmStatus: any;
  getCurrencyList: any;
  currencyArray: any[]=[];
  createNotesForm: FormGroup;
  LogTaskEmail: any;
  partyIdArray :any[]=[]
  showLogEmail: boolean;
  productLogEmail: any;
  showLogCall: boolean;
  productLogCall: any; 
  logTaskCallPartnerId: any;
  logTaskForm: FormGroup;
  logEmailForm: FormGroup;
   addBookmark: FormGroup;
  fileUpload: FormGroup;
  CatalogForm:FormGroup;
  Cases: any; 
  catalogProduct:any;
  catalogRequestById:any;
  showCatalog:boolean;
   hours: { label: string; value: string; }[];
  amPM: { label: string; value: string; }[];
  minute: { label: string; value: string; }[];
  PurposeWebUrlArray: { label: string; value: string; }[];
  yesNoArray: { label: string; value: string; }[];
  AvailabilityArray: { label: string; value: string; }[];
  securityArray: { label: string; value: string; }[];
  PurposeArray: { label: string; value: string; }[];
  CasesArray: any[]=[];
  SalesOpportunity: any;
  SalesOpportunityArray: any[]=[];
  OutboundArray: { label: string; value: string; }[];
  productNote:any;
  notePartnerBIdLead: any;
  showNote: boolean;bookmarksUploadPartner: any;
  ClassificationBookmark: any;  BookUpload: any;
  showUpload: boolean;
  bookMarksUrlPartner: any;
  showBookmark: boolean;
  ClassificationBookmarkArray: any[]=[];
  productBook: any;
  taskForm: FormGroup;
  eventForm: FormGroup;
  showActivityEvent: boolean;
  productActivityEvent: any;
  productActivityTask: any;
  showActivityTask: boolean;
  PendingActivityTask: any;
  pendingActivityEvent: any;
  constructor(
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly router: Router,
    readonly activatedRoute: ActivatedRoute,
    readonly detaledLeadService: DetaledLeadService,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    public leadSourceService: LeadSourceService,
    readonly _detailedContactsService: detailedContactsService,
    readonly leadNotesServices: LeadNotesService,
    readonly myContactsService: MyContactsService,
    public leadPersonalFormService: LeadPersonalFormService,
    readonly leadPersonalLeadServices: LeadPersonalLeadServices,
    @Inject(DOCUMENT) readonly document: Document,
    public leadNotesFormService:LeadNotesFormService,
    readonly datePipe: DatePipe,
    readonly leadPersonalServices:LeadPersonalServices,
    public leadNewService: LeadNewService,
    readonly _FormBuilder: FormBuilder,
    private _location: Location,

  ) {

    this.createNotesForm=this._FormBuilder.group({
      noteInfo:"",
      createdBy:"",
      noteDateTime:"",
    })

    this.logTaskForm = this._FormBuilder.group({
      Type: [''] ,
      Outbound: [''] ,
      InternalParty: [''] ,
      ExternalParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Subject: [''] ,
      Message: [''] ,
      StartDateandTime: [''] ,
      hourFrom: [''] ,
      Duration: [''] ,
      minuteFrom: [''] ,
      amPmFrom: [''] ,
    });
    this.logEmailForm = this._FormBuilder.group({
      Type: [''] ,
      Outbound: [''] ,
      InternalParty: [''] ,
      ExternalParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Subject: [''] ,
      Message: [''] ,
      StartDateandTime: [''] ,
      Duration: [''] ,
      hourFrom: [''] ,
      minuteFrom: [''] ,
      amPmFrom: [''] 
    });

    this.PurposeWebUrlArray = [
      {
        label: 'Primary Website URL',
        value: 'PRIMARY_WEB_URL'
      },
      {
        label: 'iCalendar URL',
        value: 'ICAL_URL'
      },

    ];
    this.PurposeArray = [
      {
        label: 'Email',
        value: 'WEPT_TASK_EMAIL'
      },
      {
        label: 'Phone Call',
        value: 'WEPT_TASK_PHONE_CALL'
      },

    ];
    this.AvailabilityArray =[
    {
      label: 'Available',
      value: 'WEPA_AV_AVAILABLE'
    },
    {
      label: 'Busy',
      value: 'WEPA_AV_BUSY'
    },
    {
      label: 'Away',
      value: 'WEPA_AV_AWAY'
    },
  ];
  this.securityArray =[
    {
      label: 'General, public access',
      value: 'WES_PUBLIC'
    },
    {
      label: 'Very restricted, confidential access',
      value: 'WES_CONFIDENTIAL'
    },
  
  ];
    this.hours = [
      {
        label: '1',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9',
        value: '9'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },

    ];
    this.minute = [
      {
        label: '00',
        value: '00'
      },
      {
        label: '01',
        value: '01'
      },
      {
        label: '02',
        value: '02'
      },
      {
        label: '03',
        value: '03'
      },
      {
        label: '04',
        value: '04'
      },
      {
        label: '05',
        value: '05'
      },
      {
        label: '06',
        value: '06'
      },
      {
        label: '07',
        value: '07'
      },
      {
        label: '08',
        value: '08'
      },
      {
        label: '09',
        value: '09'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },
      {
        label: '13',
        value: '13'
      },
      {
        label: '14',
        value: '14'
      },
      {
        label: '15',
        value: '15'
      },
      {
        label: '16',
        value: '16'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '19',
        value: '19'
      },
      {
        label: '20',
        value: '20'
      },
      {
        label: '21',
        value: '21'
      },
      {
        label: '22',
        value: '22'
      },
      {
        label: '23',
        value: '23'
      },
      {
        label: '24',
        value: '24'
      },
      {
        label: '25',
        value: '25'
      },
      {
        label: '26',
        value: '26'
      },
      {
        label: '27',
        value: '27'
      },
      {
        label: '28',
        value: '28'
      },
      {
        label: '29',
        value: '29'
      },
      {
        label: '30',
        value: '30'
      },
      {
        label: '31',
        value: '31'
      },
      {
        label: '32',
        value: '32'
      },
      {
        label: '33',
        value: '33'
      }, {
        label: '34',
        value: '34'
      },
      {
        label: '35',
        value: '35'
      }, {
        label: '36',
        value: '36'
      },
      {
        label: '37',
        value: '37'
      }, {
        label: '38',
        value: '38'
      },
      {
        label: '39',
        value: '39'
      },
      {
        label: '40',
        value: '40'
      }, {
        label: '41',
        value: '41'
      },
      {
        label: '42',
        value: '42'
      },
      {
        label: '43',
        value: '43'
      }, {
        label: '44',
        value: '44'
      },
      {
        label: '45',
        value: '45'
      },
      {
        label: '46',
        value: '46'
      }, {
        label: '47',
        value: '47'
      },
      {
        label: '48',
        value: '48'
      },
      {
        label: '49',
        value: '49'
      }, {
        label: '50',
        value: '50'
      },
      {
        label: '51',
        value: '51'
      },
      {
        label: '52',
        value: '52'
      },
      {
        label: '53',
        value: '53'
      }, {
        label: '54',
        value: '54'
      }, {
        label: '55',
        value: '55'
      }, {
        label: '56',
        value: '56'
      }, {
        label: '57',
        value: '57'
      },
      {
        label: '58',
        value: '58'
      },
      {
        label: '59',
        value: '59'
      }


    ];
    this.amPM = [
      {
        label: 'AM',
        value: 'AM'
      },
      {
        label: 'PM',
        value: 'PM'
      },
    ];
    this.OutboundArray = [
      {
        label: 'Outbound',
        value: 'Y'
      },
      {
        label: 'Inbound',
        value: 'N'
      },

    ];
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];

    this.addBookmark = this._FormBuilder.group({
      classification: [''],
      url: [''],
      description: [''],
    });
    this.fileUpload = this._FormBuilder.group({
      classification: [''],
      file: [''],
      description: [''],
    });

    this.CatalogForm = this._FormBuilder.group({

      activity: [''],
      address: [''],
      catalogId: [''],
      date: [''],
      fullFilled: [''],
      partnerId: [''],
      takenBy: [''],
    });

    this.taskForm = this._FormBuilder.group({
      Subject: [''] ,
      Security: [''] ,
      RelatedParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Purpose: [''] ,
      EndDateandTime: [''] ,
      Duration: [''] ,
      StartDateandTime: [''] ,
      Description: [''] ,
      Availability: [''] ,
      IgnoreSchedulingConflicts: [''] ,
      CompletionDateandTime: [''] ,
      CompletionDateHour: [''] ,
      CompletionAmPm: [''] ,
      CompletionDateMinutes: [''] ,
      StartDateAmPm: [''] ,
      StartDateandHour: [''] ,
      StartDateMinutes: [''] ,
    });

    this.logTaskForm = this._FormBuilder.group({
      Type: [''] ,
      Outbound: [''] ,
      InternalParty: [''] ,
      ExternalParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Subject: [''] ,
      Message: [''] ,
      StartDateandTime: [''] ,
      hourFrom: [''] ,
      Duration: [''] ,
      minuteFrom: [''] ,
      amPmFrom: [''] ,
    });
    this.eventForm = this._FormBuilder.group({
      Name: [''] ,
      Security: [''] ,
      RelatedParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      ScheduledStart: [''] ,
      EndDateandTime: [''] ,
      Duration: [''] ,
      Location: [''] ,
      Description: [''] ,
      Availability: [''] ,
      IgnoreSchedulingConflicts: [''] ,
      CompletionDateandTime: [''] ,
      CompletionDateHour: [''] ,
      CompletionAmPm: [''] ,
      CompletionDateMinutes: [''] ,
      StartDateAmPm: [''] ,
      StartDateandHour: [''] ,
      StartDateandTime: [''] ,
      StartDateMinutes: [''] ,
    });
  }

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    /*newcode*/
    this.activatedRoute.queryParams.subscribe(params=> {
     
      this.partyId = params['leadId'];
     
    });

    if(this.partyId){
      this.partyIdValue=this.partyId;
    }
    this.getCrmStatus();
    this.getLeadDataByPartyIdNew();
    this.leadNewService.lead_group_form=null;
    this.leadNewService.leadPersonaltGroup(this.leadNewService.lead_group_form);
    this.getCurrency();
    /*newcode*/

    /*newcode*/
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params['leadId'];
      let partyId=this.partyId
      if(partyId)
      {
        this.showCreateBtn=true;   
      }
    });
    this.leadNotesFormService.lead_notes_form=null;
    this.leadNotesFormService.createEventForm(this.leadNotesFormService.lead_notes_form);
    /*newcode*/





    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params['leadId'];
     
    } );
    this.getStatusCrm();
    this.getLeadDataByPartyId();

    this.leadPersonalFormService.lead_personal_form = null;
    this.leadPersonalFormService.createEventForm(this.leadPersonalFormService.lead_personal_form);

    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
      partyContentTypeId: new FormControl('')
    });
    this.leadSourceService.lead_source_form = null;
    this.leadSourceService.leadSourceForm(this.leadSourceService.lead_source_form);
    
  
    this.getLeadNote();
    this.getLeadById();
    this.getPartyContent();
 
   // this.getLeadId();
    this.getLeadSourcedata();
    this.getLeadSourceList();
    this.getPartyContentsList();
    this.isCreate.push({
      'label': 'Y',
      'value': 'Y'
    },
      {
        'label': 'N',
        'value': 'N'
      })
      const patchData = this.logTaskForm;
      setTimeout(() => {
        patchData.patchValue({
          InternalParty:"admin",
          ExternalParty:"admin",
          })
      
      }, 1000);
  
      const patchDatas = this.logEmailForm;
      setTimeout(() => {
        patchDatas.patchValue({
          InternalParty:"admin",
          ExternalParty:"admin",
          })
      
      }, 1000);
      this.getPartyId();
      this.getCurrency();
     
      this.getLogTaskCallPartnerId();
      this.getLogTaskEmailPartnerId();
      this.getCases();
      this.getSalesOpportunity();
      this.getNotePartnerBIdLead();
      this.getClassificationBookmark();
      this.getBookMarksUrlIdPartner();
      this.getBookmarksUploadPartner();
      this.getCatalogRequestByIdLead();
      this.getLogTaskEmailPartnerId();
      this.getPendingActivityEventPartner();
     


  }
  deletePendingActivityTaskPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partyId,
          "pendingActivityTaskId": product.pendingActivityTaskId
         
        }
        this.myContactsService.deletePendingActivityTaskPartnerLead(formData).subscribe(res => {
          this.getLogTaskEmailPartnerId (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deletePendingActivityEventPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partyId,
    "pendingActivitiesId": product.pendingActivitiesId
        }
        this.myContactsService.deletePendingActivityEventPartnerLead(formData).subscribe(res => {
          this.getLogTaskEmailPartnerId (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updatePendingActivityEvent(product) {
    this.spinner.show();
    this.showActivityEvent = true ;
    this.productActivityEvent = product ;
   
      setTimeout(() => {
        const formValue = this.eventForm;
        formValue.patchValue({
          Security:  product.scopeEnumId ,
          RelatedParty:  product.relatedPartyId ,
          Opportunity:  product.lookupOpportunity ,
          Case: "" ,
          Purpose:  product.workEffortPurposeTypeId ,
          Duration:  product.duration ,
          StartDateandTime: this.datePipe.transform(product.estimatedStartDateDate ,"yyyy-MM-dd hh:mm:ss") , 
          Description:  product.description ,
          Availability:  product.availability ,
          IgnoreSchedulingConflicts:  "",
          CompletionDateandTime: this.datePipe.transform(product.estimatedCompletionDate ,"yyyy-MM-dd hh:mm:ss") , 
          CompletionDateHour:  product.estimatedCompletionDateHour ,
          CompletionAmPm:  product.estimatedCompletionDateAmPm ,
          CompletionDateMinutes:  product.estimatedCompletionDateMinutes ,
          StartDateAmPm:  product.estimatedStartDateAmPm ,
          StartDateandHour:  product.estimatedStartDateHour ,
          StartDateMinutes:  product.estimatedStartDateMinutes ,
          Name:   product.workEffortName ,
          Location:  product.location ,
        })
      }, 2000);
      this.spinner.hide();
  }
  UpdatePendingActivityEventPartner(){
    this.spinner.show();
    const requestData = {
      "availability": this.eventForm.value.Availability,
      "currentStatusId": "EVENT_SCHEDULED",
      "description": this.eventForm.value.Description,
      "duration": this.eventForm.value.Duration,
      "estimatedCompletionCompositeType": "Timestamp",
      "estimatedCompletionDate": this.datePipe.transform(this.eventForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedCompletionDateAmPm": this.eventForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.eventForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.eventForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.eventForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedStartDateDate":this.datePipe.transform(this.eventForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedStartDateHour": this.eventForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.eventForm.value.StartDateMinutes,
      "location": this.eventForm.value.Location,
      "lookupCase": "",
      "lookupOpportunity": this.eventForm.value.Opportunity,
      "pendingActivitiesId":this.productActivityEvent.pendingActivitiesId,
      "relatedPartyId": this.eventForm.value.RelatedParty,
      "scopeEnumId": this.eventForm.value.Security,
      "type": "",
      "workEffortName": this.eventForm.value.Name,
      "workEffortPurposeTypeId": "",
      "workEffortTypeId": "EVENT",
      "partnerId": this.partyId,
    }
    this.myContactsService.UpdatePendingActivityEventPartnerLead(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivityEvent Updated Successfully");
        this.spinner.hide();
        this.closebutton13.nativeElement.click();
        this.eventForm.reset();
        this.getPendingActivityEventPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  CreatePendingActivityEventPartner(){
    this.spinner.show();
    const requestData = {
      "availability": this.eventForm.value.Availability,
      "currentStatusId": "EVENT_SCHEDULED",
      "description": this.eventForm.value.Description,
      "duration": this.eventForm.value.Duration, 
      "estimatedCompletionCompositeType": "Timestamp",
      "estimatedCompletionDate": this.datePipe.transform(this.eventForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedCompletionDateAmPm": this.eventForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.eventForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.eventForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.eventForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedStartDateDate":this.datePipe.transform(this.eventForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedStartDateHour": this.eventForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.eventForm.value.StartDateMinutes,
      "location": this.eventForm.value.Location,
      "lookupCase": "",
      "lookupOpportunity": this.eventForm.value.Opportunity,
      "pendingActivitiesId":"",
      "relatedPartyId": this.eventForm.value.RelatedParty,
      "scopeEnumId": this.eventForm.value.Security,
      "type": "",
      "workEffortName": this.eventForm.value.Name,
      "workEffortPurposeTypeId": "",
      "workEffortTypeId": "EVENT",
      "partnerId": this.partyId,
 
    }
    this.myContactsService.CreatePendingActivityEventPartnerLead(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivityEvent Created Successfully");
        this.spinner.hide();
        this.closebutton13.nativeElement.click();
        this.eventForm.reset();
        this.getPendingActivityEventPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updatePendingActivityTask(product) {
    this.spinner.show();
    this.showActivityTask = true ;
    this.productActivityTask = product ;
   
      setTimeout(() => {
        const formValue = this.taskForm;
        formValue.patchValue({
          Subject: product.workEffortName ,
          Security:  product.scopeEnumId ,
          RelatedParty:  product.relatedPartyId ,
          Opportunity:  product.lookupOpportunity ,
          Case: "" ,
          Purpose:  product.workEffortPurposeTypeId ,

          Duration:  product.duration ,
          StartDateandTime: this.datePipe.transform(product.estimatedStartDateDate ,"yyyy-MM-dd hh:mm:ss") , 
          Description:  product.description ,
          Availability:  product.availability ,
          IgnoreSchedulingConflicts:  "",
          CompletionDateandTime: this.datePipe.transform(product.estimatedCompletionDate ,"yyyy-MM-dd hh:mm:ss") , 
          CompletionDateHour:  product.estimatedCompletionDateHour ,
          CompletionAmPm:  product.estimatedCompletionDateAmPm ,
          CompletionDateMinutes:  product.estimatedCompletionDateMinutes ,
          StartDateAmPm:  product.estimatedStartDateAmPm ,
          StartDateandHour:  product.estimatedStartDateHour ,
          StartDateMinutes:  product.estimatedStartDateMinutes ,
        })
      }, 2000);
      this.spinner.hide();
  }
  UpdatePendingActivityTaskPartner(){
    this.spinner.show();
    const requestData = {
      "availability":this.taskForm.value.Availability,
      "currentStatusId": "TASK_SCHEDULED"  ,
      "description": this.taskForm.value.Description,
      "duration": this.taskForm.value.Duration,
      "estimatedCompletionCompositeType":"Timestamp",
      "estimatedCompletionDate": this.datePipe.transform(this.taskForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedCompletionDateAmPm": this.taskForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.taskForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.taskForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.taskForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedStartDateDate":this.datePipe.transform(this.taskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedStartDateHour": this.taskForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.taskForm.value.StartDateMinutes,
      "ignoreSchedulingConflicts": this.taskForm.value.IgnoreSchedulingConflicts,
      "lookupCase": "",
      "lookupOpportunity": this.taskForm.value.Opportunity,
      "pendingActivityTaskId":  this.productActivityTask.pendingActivityTaskId,
      "relatedPartyId": this.taskForm.value.RelatedParty,
      "scopeEnumId": this.taskForm.value.Security,
      "type": "",
      "workEffortName": this.taskForm.value.Subject,
      "workEffortPurposeTypeId": this.taskForm.value.Purpose,
      "workEffortTypeId": "TASK",
      "partnerId": this.partyId,
    }

    this.myContactsService.UpdatePendingActivityTaskPartnerLead(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivityTask Update successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.taskForm.reset();
        this.getPendingActivityTaskPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createPendingActivityTaskPartner(){
    this.spinner.show();
    const requestData = {
      "availability":this.taskForm.value.Availability,
      "currentStatusId": "TASK_SCHEDULED"  ,
      "description": this.taskForm.value.Description,
      "duration": this.taskForm.value.Duration,
      "estimatedCompletionCompositeType":"Timestamp",
      "estimatedCompletionDate": this.datePipe.transform(this.taskForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedCompletionDateAmPm": this.taskForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.taskForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.taskForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.taskForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedStartDateDate":this.datePipe.transform(this.taskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedStartDateHour": this.taskForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.taskForm.value.StartDateMinutes,
      "ignoreSchedulingConflicts": this.taskForm.value.IgnoreSchedulingConflicts,
      "lookupCase": "",
      "lookupOpportunity": this.taskForm.value.Opportunity,
      "pendingActivityTaskId": "",
      "relatedPartyId": this.taskForm.value.RelatedParty,
      "scopeEnumId": this.taskForm.value.Security,
      "type": "",
      "workEffortName": this.taskForm.value.Subject,
      "workEffortPurposeTypeId": this.taskForm.value.Purpose,
      "workEffortTypeId": "TASK",
      "partnerId": this.partyId,
  
    }

    this.myContactsService.createPendingActivityTaskPartnerLead(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivityTask Created successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.taskForm.reset();
        this.getPendingActivityTaskPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getPendingActivityEventPartner() {
    this.spinner.show();
    this.myContactsService.getPendingActivityEventPartnerLead(this.partyId).subscribe(res => {
      this.pendingActivityEvent = res.data[0].getPendingActivityEventPartner;
      setTimeout(() => {
        const formValue = this.eventForm;
        formValue.patchValue({
          RelatedParty: this.partyId,
          ScheduledStart : this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
          Duration : "1:00"
        })
  
      }, 2000);
      this.spinner.hide();
    })
  }
  getPendingActivityTaskPartner() {
    this.spinner.show();
    this.myContactsService.getPendingActivityTaskPartnerLead(this.partyId).subscribe(res => {
      this.PendingActivityTask = res.data[0].getPendingActivityTaskPartner;
      this.spinner.hide();
    })
  }
  deleteCatalogRequestLead(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partyId,
          "catalogId": product.catalogId,
         
        }
        this.myContactsService.deleteCatalogRequestLead(formData).subscribe(res => {
          this.getCatalogRequestByIdLead (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  getCatalogRequestByIdLead() {
    this.spinner.show();
    this.myContactsService.getCatalogRequestByIdLead(this.partyId).subscribe(res => {
      this.catalogRequestById = res.data[0].getCatalogRequestById;
      this.spinner.hide();
    })
  }

  createCatalogRequestLead(){
    this.spinner.show();
    const requestData = {
      "activity":this.CatalogForm.value.activity,
      "address": this.CatalogForm.value.address,
      "catalogId": "",
      "date": this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
      "fullFilled": this.CatalogForm.value.fullFilled,
      "partnerId":this.partyId,
      "takenBy": this.CatalogForm.value.takenBy,
    }

    this.myContactsService.createCatalogRequestLead(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton20.nativeElement.click();
        this.CatalogForm.reset();
        this.getCatalogRequestByIdLead();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  UpdateCatalogRequestLead(){
    this.spinner.show();
    const requestData = {
      "activity":this.CatalogForm.value.activity,
      "address": this.CatalogForm.value.address,
      "catalogId":   this.catalogProduct.catalogId,
      "date": this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
      "fullFilled": this.CatalogForm.value.fullFilled,
      "partnerId":this.partyId,
      "takenBy": this.CatalogForm.value.takenBy,
    }

    this.myContactsService.UpdateCatalogRequestLead(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton20.nativeElement.click();
        this.CatalogForm.reset();
        this.getCatalogRequestByIdLead();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  resetCtalog(){
    this.showCatalog = false ;
    this.CatalogForm.reset();
  }
  updateCatalog(product) {
    this.spinner.show();
    this.showCatalog = true ;
    this.catalogProduct = product ;
   
      setTimeout(() => {
        const formValue = this.CatalogForm;
        formValue.patchValue({
          activity: product.activity,
          address:  product.address,
          fullFilled:  product.fullFilled,
          takenBy:  product.takenBy,
        })
      }, 2000);
      this.spinner.hide();
  }
  resetUpload(){
    this.showUpload = false ;
    this.fileUpload.reset();
  }
  updateBookUpload(product) {
    this.spinner.show();
    this.showUpload = true ;
    this.BookUpload = product ;
   
      setTimeout(() => {
        const formValue = this.fileUpload;
        formValue.patchValue({
          classification: product.classification,
          file: product.url,
          description: product.description,
        })
      }, 2000);
      this.spinner.hide();
  }
  deleteBookmarksUploadPartner(bookmarksUploadId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       
        this.myContactsService.deleteBookmarksUploadPartnerLead(bookmarksUploadId).subscribe(res => {
          this.getBookmarksUploadPartner(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateBookmarksUploadPartner(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);
    this.myContactsService.updateBookmarksUploadPartnerLead(formData,this.BookUpload.bookmarksUploadId,this.fileUpload.value.classification,
    this.fileUpload.value.description,this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton9.nativeElement.click();
        this.fileUpload.reset();
        this.getBookmarksUploadPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }  
  updateBookmarkUpload(){
    this.onSubmitFile();

    setTimeout(() => {
      this.updateBookmarksUploadPartner();
    }, 2000);
   
  }
  getBookmarksUploadPartner() {
    this.spinner.show();
    this.myContactsService.getBookmarksUploadPartnerLead(this.partyId).subscribe(res => {
      this.bookmarksUploadPartner = res.data[0].getBookmarksUploadPartner;
      this.spinner.hide();
    })
  }
  createBookmarksUploadPartner(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.myContactsService.createBookmarksUploadPartnerLead(formData,this.fileUpload.value.classification,
      this.fileUpload.value.description,this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton9.nativeElement.click();
        this.fileUpload.reset();
        this.getBookmarksUploadPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }   
  fileProgressCom(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }
  onSubmitFile() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.accountsService.attachFile(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.responseMessage);
        this.fileURL = res.data.URL;
      }
    }, (err) => {
      this.toastr.error(err.message);
      this.spinner.hide();
    })

  }

  createBookmarkUpload(){
    this.onSubmitFile();

    setTimeout(() => {
      this.createBookmarksUploadPartner();
    }, 2000);
   
  }
  getBookMarksUrlIdPartner() {
    this.spinner.show();
    this.myContactsService.getBookMarksUrlIdPartnerLead(this.partyId).subscribe(res => {
      this.bookMarksUrlPartner = res.data[0].getBookMarksUrlIdPartner;
      this.spinner.hide();
    })
  }
  getClassificationBookmark(){
    this.spinner.show();
    this.myContactsService.getClassificationBookmark().subscribe(res=>{
      this.ClassificationBookmark = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ClassificationBookmark) {
        this.ClassificationBookmarkArray.push({
          label: value.description,
          value: value.classificationEnumId
        })
      }
    })
  }
  resetBookUrl(){
    this.showBookmark = false ;
    this.addBookmark.reset();
  }
  deleteBookMarksUrlPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "bookmarksUrlId": product.bookmarksUrlId,
          "partnerId": this.partyId
         
        }
        this.myContactsService.deleteBookMarksUrlPartnerLead(formData).subscribe(res => {
          this.getBookMarksUrlIdPartner(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateBook(product) {
    this.spinner.show();
    this.showBookmark = true ;
    this.productBook = product ;
   
      setTimeout(() => {
        const formValue = this.addBookmark;
        formValue.patchValue({
          classification: product.classification,
          url: product.urlAddress,
          description: product.description,
        })
      }, 2000);
      this.spinner.hide();
  }
  UpdateBookMarksUrlPartner(){
    this.spinner.show();
    const requestData = {
      "partnerId": this.partyId,
      "bookmarksUrlId":this.productBook.bookmarksUrlId,
      "classification": this.addBookmark.value.classification,
     
      "description":this.addBookmark.value.description,
      "urlAddress": this.addBookmark.value.url,

    }
    this.myContactsService.UpdateBookMarksUrlPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.addBookmark.reset();
        this.getBookMarksUrlIdPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createBookMarksUrlPartner(){
    this.spinner.show();
    const requestData = {
    
      "bookmarksUrlId":this.addBookmark.value.description,
      "classification": this.addBookmark.value.classification,
      "partnerId": this.partyId,
      "description":this.addBookmark.value.description,
      "urlAddress": this.addBookmark.value.url,
    }
    this.myContactsService.createBookMarksUrlPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("BookmarksUrl created successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.addBookmark.reset();
        this.getBookMarksUrlIdPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  deleteNotePartnerLead(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partyId,
          "noteId": product.noteId
         
        }
        this.myContactsService.deleteNotePartnerLead(formData).subscribe(res => {
          this.getNotePartnerBIdLead (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateNote(product) {
    this.spinner.show();
    this.showNote = true ;
    this.productNote = product ;
   
      setTimeout(() => {
        const formValue = this.createNotesForm;
        formValue.patchValue({
          noteInfo: product.noteInformation ,

         
        })
      }, 2000);
      this.spinner.hide();
  }
  resetNote(){
    this.showNote = false ;
    this.createNotesForm.reset();
  }
  getNotePartnerBIdLead(){
    this.spinner.show();
    this.myContactsService.getNotePartnerBIdLead(this.partyId).subscribe(res=>{
      this.notePartnerBIdLead = res.data[0].getNotePartnerAll;
      this.spinner.hide();
    
    })
  }
  UpdateNotePartnerLead(){
    this.spinner.show();
   
    const requestData = {
  "noteId": this.productNote.noteId,
  "noteInformation": this.createNotesForm.value.noteInfo,
  "partnerId": this.partyId
 
    }

    this.myContactsService.UpdateNotePartnerLead(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.createNotesForm.reset();
        this.getLogTaskCallPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createNotePartnerLead(){
    this.spinner.show();
   
    const requestData = {
      "noteId": "",
      "noteInformation": this.createNotesForm.value.noteInfo,
      "partnerId": this.partyId
 
    }

    this.myContactsService.createNotePartnerLead(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.createNotesForm.reset();
        this.getLogTaskCallPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getSalesOpportunity(){
    this.spinner.show();
    this.myContactsService.getSalesOpportunity().subscribe(res=>{
      this.SalesOpportunity = res.data[0].getSalesOpportunity;
      this.spinner.hide();
      for (const value of this.SalesOpportunity) {
        this.SalesOpportunityArray.push({
          label: value.opportunityName,
          value: value.salesOpportunityId
        })
      }
    })
  }
  getCases(){
    this.spinner.show();
    this.myContactsService.getCases().subscribe(res=>{
      this.Cases = res.data[0].getCasesById;
      this.spinner.hide();
      for (const value of this.Cases) {
        this.CasesArray.push({
          label: value.custRequestName,
          value: value.custRequestId
        })
      }
    })
  }
  UpdateLogTaskCallPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logTaskForm.value.amPmFrom,
  "actualStartDateCompositeType":"Timestamp",
  "actualStartDateDate": this.datePipe.transform(this.logTaskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss"),
  "actualStartDateHour": this.logTaskForm.value.hourFrom,
  "actualStartDateMinutes":  this.logTaskForm.value.minuteFrom,
  "caseId":"",
  "completionStartDateAmPm":"",
  "completionStartDateCompositeType":"",
  "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
  "completionStartDateHour":  "23",
  "completionStartDateMinutes":  "59",
  "content":this.logTaskForm.value.Message,
  "currentStatusId":"EXST_DEFINED",
  "duration":this.logTaskForm.value.Duration,
  "externalPartyId":this.logTaskForm.value.ExternalParty,
  "inboundAndOutBound":this.logTaskForm.value.Outbound,
  "internalPartyId":this.logTaskForm.value.InternalParty,
  "logTaskCasesCallId":this.productLogCall.logTaskCasesCallId ,
  "salesOpportunityId":this.logTaskForm.value.Opportunity,
  "workEffortName":this.logTaskForm.value.Subject,
  "workEffortPurposeTypeId": "WEPT_TASK_PHONE_CALL",
  "partnerId": this.partyId,
 
    }

    this.myContactsService.UpdateLogTaskCallPartnerLead(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.logTaskForm.reset();
        this.getLogTaskCallPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createLogTaskCallPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logTaskForm.value.amPmFrom,
  "actualStartDateCompositeType":"Timestamp",
  "actualStartDateDate": this.datePipe.transform(this.logTaskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss"),
  "actualStartDateHour": this.logTaskForm.value.hourFrom,
  "actualStartDateMinutes":  this.logTaskForm.value.minuteFrom,
  "caseId":"",
  "completionStartDateAmPm":"",
  "completionStartDateCompositeType":"",
  "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
  "completionStartDateHour":  "23",
  "completionStartDateMinutes":  "59",
  "content":this.logTaskForm.value.Message,
  "currentStatusId":"EXST_DEFINED",
  "duration":this.logTaskForm.value.Duration,
  "externalPartyId":this.logTaskForm.value.ExternalParty,
  "inboundAndOutBound":this.logTaskForm.value.Outbound,
  "internalPartyId":this.logTaskForm.value.InternalParty,
  "logTaskCasesCallId":"",
  "salesOpportunityId":this.logTaskForm.value.Opportunity,
  "workEffortName":this.logTaskForm.value.Subject,
  "workEffortPurposeTypeId": "WEPT_TASK_PHONE_CALL",
  "partnerId": this.partyId,
 
    }

    this.myContactsService.createLogTaskCallPartnerLead(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.logTaskForm.reset();
        this.getLogTaskCallPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  updateLogCall(product) {
    this.spinner.show();
    this.showLogCall = true ;
    this.productLogCall = product ;
   
      setTimeout(() => {
        const formValue = this.logTaskForm;
        formValue.patchValue({
          Type: product.workEffortPurposeTypeId ,
          Outbound:  product.inboundAndOutBound ,
          InternalParty:  product.internalPartyId ,
          ExternalParty:  product.externalPartyId ,
          Opportunity:  product.salesOpportunityId ,
          Case:  product.partnerId ,
          Subject:  "",
          Message:  "",
          StartDateandTime:  product.actualStartDateDate ,
          Duration:  product.duration ,
          hourFrom:  product.actualStartDateHour ,
          minuteFrom:  product.actualStartDateMinutes ,
          amPmFrom:  product.actualStartDateAmPm ,

         
        })
      }, 2000);
      this.spinner.hide();
  }
  resetLogCall(){
    this.showLogCall = false ;
    this.logTaskForm.reset();
  }
  resetLogEmail(){
    this.showLogEmail = false ;
    this.logEmailForm.reset();
  }
  updateLogEmail(product) {
    this.spinner.show();
    this.showLogEmail = true ;
    this.productLogEmail = product ;
   
      setTimeout(() => {
        const formValue = this.logEmailForm;
        formValue.patchValue({
          Type: product.workEffortPurposeTypeId ,
          Outbound:  product.inboundAndOutBound ,
          InternalParty:  product.internalPartyId ,
          ExternalParty:  product.externalPartyId ,
          Opportunity:  product.salesOpportunityId ,
          Case:  product.partnerId ,
          Subject:  "",
          Message:  "",
          StartDateandTime:  product.actualStartDateDate ,
          Duration:  product.duration ,
          hourFrom:  product.actualStartDateHour ,
          minuteFrom:  product.actualStartDateMinutes ,
          amPmFrom:  product.actualStartDateAmPm ,
        })
      }, 2000);
      this.spinner.hide();
  }
  createLogTaskEmailPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logEmailForm.value.amPmFrom,
      "actualStartDateCompositeType":"Timestamp",
      "actualStartDateDate":this.datePipe.transform(this.logEmailForm.value.StartDateandTime, "yyyy-MM-dd"),
      "actualStartDateHour": this.logEmailForm.value.hourFrom,
      "actualStartDateMinutes": this.logEmailForm.value.minuteFrom,
      "caseId": "",
      "completionStartDateAmPm": "",
      "completionStartDateCompositeType": "",
      "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
      "completionStartDateHour": "24",
      "completionStartDateMinutes": "60",
      "content": this.logEmailForm.value.Message ,
      "currentStatusId": "EXST_DEFINED",
      "duration":this.logEmailForm.value.Duration,
      "externalPartyId": this.logEmailForm.value.ExternalParty,
      "inboundAndOutBound": this.logEmailForm.value.Outbound,
      "internalPartyId": this.logEmailForm.value.InternalParty,
      "logTaskEmailId": "",
      "salesOpportunityId":this.logEmailForm.value.Opportunity,
      "workEffortName":this.logEmailForm.value.Subject,
      "workEffortPurposeTypeId":"WEPT_TASK_EMAIL",
      "partnerId":  this.partyId
    }

    this.myContactsService.createLogTaskEmailPartnerLead(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton12.nativeElement.click();
        this.logEmailForm.reset();
        this.getLogTaskEmailPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  UpdateLogTaskEmailPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logEmailForm.value.amPmFrom,
      "actualStartDateCompositeType":"Timestamp",
      "actualStartDateDate":this.datePipe.transform(this.logEmailForm.value.StartDateandTime, "yyyy-MM-dd"),
      "actualStartDateHour": this.logEmailForm.value.hourFrom,
      "actualStartDateMinutes": this.logEmailForm.value.minuteFrom,
      "caseId": "",
      "completionStartDateAmPm": "",
      "completionStartDateCompositeType": "",
      "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
      "completionStartDateHour": "24",
      "completionStartDateMinutes": "60",
      "content": this.logEmailForm.value.Message ,
      "currentStatusId": "EXST_DEFINED",
      "duration":this.logEmailForm.value.Duration,
      "externalPartyId": this.logEmailForm.value.ExternalParty,
      "inboundAndOutBound": this.logEmailForm.value.Outbound,
      "internalPartyId": this.logEmailForm.value.InternalParty,
      "logTaskEmailId": this.productLogEmail.logTaskEmailId ,
      "salesOpportunityId":this.logEmailForm.value.Opportunity,
      "workEffortName":this.logEmailForm.value.Subject,
      "workEffortPurposeTypeId":"WEPT_TASK_EMAIL",
      "partnerId":  this.partyId,

    }

    this.myContactsService.UpdateLogTaskEmailPartnerLead(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton12.nativeElement.click();
        this.logEmailForm.reset();
        this.getLogTaskEmailPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteLogTaskEmailPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partyId,
          "logTaskEmailId": product.logTaskEmailId,
         
        }
        this.myContactsService.deleteLogTaskEmailPartnerLead(formData).subscribe(res => {
          this.getLogTaskEmailPartnerId (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  deleteLogTaskCallPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "logTaskCasesCallId": product.logTaskCasesCallId,
          "partnerId": this.partyId
         
        }
        this.myContactsService.deleteLogTaskCallPartnerLead(formData).subscribe(res => {
          this.getLogTaskCallPartnerId(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getLogTaskCallPartnerId() {
    this.spinner.show();
    this.myContactsService.getLogTaskCallPartnerIdLead(this.partyId).subscribe(res => {
      this.logTaskCallPartnerId = res.data[0].getLogTaskCallPartnerId;
      this.spinner.hide();
    })
  }
  getLogTaskEmailPartnerId() {
    this.spinner.show();
    this.myContactsService.getLogTaskEmailPartnerIdLead(this.partyId).subscribe(res => {
      this.LogTaskEmail = res.data[0].getLogTaskEmailPartnerId;
      this.spinner.hide();
    })
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/crm/main-overview']);
  }
  /*newcode*/
  getLeadDataByPartyIdNew() {
    this.spinner.show();
    this.leadPersonalLeadServices.getLeadByPartyId(this.partyId).subscribe(res => {
      this.spinner.hide();
      setTimeout(() => {
        
      
         const formData = this.leadNewService.lead_group_form;
        formData.patchValue({
          groupName: res.data.partyGrpInformation[0].company,
          groupLocalName: res.data.partyGrpInformation[0].groupNameLocal,
          officeSiteName: res.data.partyGrpInformation[0].officeSiteName,
          annualRevenue: res.data.partyGrpInformation[0].annualRevenue,
          noOfEmployees: res.data.partyGrpInformation[0].numEmployees,
          tickerSymbol: res.data.partyGrpInformation[0].tickerSymbol,
          comments: res.data.partyGrpInformation[0].comments,
  
        })
      
      }, 3000);
   
    })
   
  }

  getCrmStatus(){
    this.spinner.show();
    this.leadPersonalServices.getCrmStatus().subscribe(res=> {
      this.crmStatus = res.data;
      this.spinner.hide();
      for(const value of this.crmStatus){
        this.crmArray.push({
          label:value.description,
          value:value.statusId
        })
      }
    })
  
  }
  getCurrency() {
    this.spinner.show();
    this.leadPersonalServices.getCurrency().subscribe(res=> {
      this.getCurrencyList = res.data.currencyList;
      this.spinner.hide();
     this.getCurrencyList.forEach(currencyList=> {
       this.currencyArray.push({
         label:currencyList.description + "-" +  currencyList.uomId,
         value:currencyList.uomId
       })
     })
      })
     
    }
  onUpdateNew(){
    this.spinner.show();
    const formData = {...this.leadNewService.lead_group_form.value};
    this.leadPersonalServices.updatePartyGroup(this.partyGroupInformation[0].partyIdFrom,formData).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
       this.router.navigate(['/crm/leads/details-lead'],{queryParams:{leadId:this.partyId}})
      }    },
      (err)=> {
        this.spinner.hide();
      
      })
      this.spinner.hide();
  }
  /*newcode*/

  /*newcode*/
  onCreateNew(){
    this.spinner.show();
    const form = { ...this.leadNotesFormService.lead_notes_form.value}
    this.leadNotesServices.createContactNote(form,this.partyId).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.leadNotesFormService.lead_notes_form.reset();
        this.closebutton1.nativeElement.click();
        this.router.navigate(['/crm/leads/details-lead'], { queryParams: { leadId:this.partyId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  /*newcode*/

  cancelSubmit(){
    this.router.navigate(['/crm/leads'])
  }
  getLeadSourceList() {
    this.spinner.show();
    this.detaledLeadService.getLeadSourceList(this.partyId).subscribe(res => {
      this.leadSourceList = res.data;
      this.spinner.hide();
      if (this.leadSourceList.length == 0) {
        this.openNoRecordFoundLeadSourceList = true;
      }
    })
  
  }

  getLeadSourcedata() {
    this.spinner.show();
    this.detaledLeadService.getLeadSourceData().subscribe(res => {
      this.leadSourceData = res.data.leadSourceList;
      this.spinner.hide();
      for (const value of this.leadSourceData) {
        this.leadSourcearray.push({
          label: value.description,
          value: value.dataSourceId
        })
      }

    })
  
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  updatePersonal() {
    this.router.navigate(['/crm/leads/leadPersonal'],
      { queryParams: { partyGroupId: this.contactForLead1, partyId: this.partyId } })
  }
  // click1(){
  //   this.router.navigate(['/crm/leads/details-lead'],
  //   { queryParams: { partyGroupId: this.contactForLead1, partyId1: this.partyId } })
  // }

  onCreate() {
    this.spinner.show();
    this.leadSourceService.lead_source_form.patchValue({ partyId: this.partyId });
    this.leadSourceService.lead_source_form.patchValue({ roleTypeId: this.partyId });

    const formData = { ...this.leadSourceService.lead_source_form.value };
    this.detaledLeadService.createLeadSource(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        window.location.reload();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    }
    )
   
  }
  onpartyContent(event) {
    this.partyContentTypeId = event;
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  noteNavigate() {
    this.router.navigate(['/crm/leads/leadNotes'], { queryParams: { partyId: this.partyId } });
  }
  clicK(){
    this.router.navigate(['/crm/leads/details-lead'], { queryParams: { partyId: this.partyId } });
  }
  getLeadNote() {
    this.spinner.show();
    this.detaledLeadService.getLeadNotesList(this.partyId).subscribe(res => {

      this.leadNoteId = res.data;
      this.spinner.hide();
      if (this.leadNoteId.length == 0) {
        this.openNoRecordFoundNote = true;
      }
    });
   
  }
  getPartyContentsList() {
    this.spinner.show();
    this.detaledLeadService.getPartyContentList(this.partyId).subscribe(res => {
      this.contentList = res.data;
      this.spinner.hide();
      if (this.contentList.length == 0) {
        this.openNoRecordFoundPartyContent = true;
      }
    });
 
  }
  export(id: any, contentName: any) {
    this.spinner.show();
    this.accountsService.downloadView(id).subscribe(res => {
      const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = contentName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
    
  }
  onDelete(contentId, fromDate, partyContent, partyId): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const modifiedDate = moment(fromDate).format('YYYY-MM-DD HH:mm:ss.SSS');
        this._detailedContactsService.deleteContent(contentId, modifiedDate, partyContent, partyId)
          .subscribe(res => {
            if (res['data'].responseMessage == 'success') {
          
            }
            this.getPartyContentsList();
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  updatePerson() {
    this.router.navigate(['/crm/leads/personal-leads'], { queryParams: { personId: this.partyId } })
  }
  getLeadById() {
    this.spinner.show();
    this.detaledLeadService.getLeadDetail(this.partyId).subscribe(res => {
      this.datailedData = res.data;
      this.spinner.hide();
      this.name = res.data.personalInformation.firstName + ' ' + res.data.personalInformation.lastName;
      this.id = res.data.personalInformation.partyId;
      this.statusId = res.data.personalInformation.statusId;
      const convertedLead = res.data.personalInformation.status;
      if (convertedLead == "Converted") {
        this.isConvertedLead = false;
      }
      this.acountForLead1 = res.data.personalInformation.partyId;
      // array
      this.partyGroupInformation = res.data.partyGrpInformation;
      // value
      this.contactForLead1 = res.data.partyGrpInformation[0].partyIdFrom;
     
   
      // array
      this.opportunityDatailedData = res.data.opportunities;

    })
   
  }
  onSubmit() {
    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.fileData);
    this.partyContentTypeId = this.Editform.get('partyContentTypeId').value;
    this.detaledLeadService.attachPartyContent(formData, this.partyContentTypeId, this.partyId)
      .subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          window.location.reload();
          this.getLeadById()

        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error(err.message);
      });
     
  }
  navigate() {
    if (this.statusId === 'LEAD_CONVERTED') {
      this.toastr.error('Already Converted');
    }
    else {
      this.router.navigate(['/crm/leads/convertLead'], { queryParams: { partyGroupId: this.contactForLead1, partyId: this.acountForLead1 } })
    }
  }
  getPartyContent() {
    this.spinner.show();
    this.detaledLeadService.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        })
      }
    })
    
  }
  getLeadId() {
    this.spinner.show();
    this.detaledLeadService.getLeadSource().subscribe(res => {
      this.spinner.hide();
      for (const value of res.data) {
        this.leadArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
 

  onUpdateNotes(enoteId, enoteName, enote) {
    this.router.navigate(['/crm/leads/leadNotes'], { queryParams: { noteId: enoteId, leadId: this.partyId, noteName: enoteName, note: enote } })
  }

  onDeleteNotes(noteId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.leadNotesServices.deleteNote(noteId).subscribe(res => {
          if (res.success) {
          
            this.getLeadNote();
          }
        }, (err) => {
          this.toastr.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})  }

  checkValidation(data): any {
    if (data !== null && data !== undefined) {
      return data;
    } else {
      return '';
    }
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  getStatusCrm() {
    this.spinner.show();
    this.leadPersonalLeadServices.getStatusCrm().subscribe(res => {
      let crmStatus = res.data;
      this.spinner.hide();
      for (const value of crmStatus) {
        this.crmArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
   
  }

  getLeadDataByPartyId() {
    this.spinner.show();
    this.leadPersonalLeadServices.getLeadByPartyId(this.partyId).subscribe(res => {
      let personalInfo = res.data.personalInformation;
      this.preSelectedstatus = personalInfo.statusId;
      this.externalId = personalInfo.externalId;
      this.firstName = personalInfo.firstName;
      this.lastName = personalInfo.lastName;
      this.comments = personalInfo.comments;
      this.spinner.hide();
    })
   
  }

 

  onUpdate() {
    this.spinner.show();
    const formData = this.leadPersonalFormService.lead_personal_form.value;
    this.leadPersonalLeadServices.updateLead(this.partyId, formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.closebutton.nativeElement.click();
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.success == false) {
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }
        }
        else if (err.error.success == false) {
          this.toastr.error(err.error.message);
        }
      }
    })
    this.spinner.hide();
  }
  onCancel() {
    this.router.navigate(["/crm/leads/details-lead"], { queryParams: { leadId: this.partyId } });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
