<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">The Profile of [{{oppourtunityId}}]</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>

            <div class=" bg-white color-grey">
                 <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
<!-- Removed class divA -->
                                            <div class="divA">
                                                <div class="w3-card-4 classCard">
                                                    <div class=" bg-white color-grey">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling GRight ml-1">
                                                                Opportunity Information
                                                            </h4>
                                                            <span class="edit-buttons hCreate">
                                                                <a data-toggle="modal" data-target="#exampleModalCenter"
                                                                    (click)="eventDetailEditData()">Update</a>
                                                            </span>
                                    
                                                        </div>
                                    
                                                        <div class="mt-2" style="height: 255px !important;">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                                                            <div class="w-100">
                                                                                <div class="panel-group">
                                                                                    <div *ngIf="activeCategory==2">
                                    
                                                                                        <div class="panel-body" *ngIf="this.oppourtunityDetail">
                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                                <div *ngIf="oppourtunityDetail" class="color-black container">
                                                                                                    <div class="row">
                                                                                                        <div class="article-container">
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Sales Opportunity ID
                                                                                                                </p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.salesOpportunityId
                                                                                                                    ? oppourtunityDetail.salesOpportunityId:
                                                                                                                    'NA'}}</span>
                                                                                                            </div>
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Opportunity Name
                                                                                                                </p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.opportunityName
                                                                                                                    ? oppourtunityDetail.opportunityName :
                                                                                                                    'NA'}}</span>
                                                                                                            </div>
                                    
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Initial Stage</p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.salesOpportunityStage
                                                                                                                    ?
                                                                                                                    oppourtunityDetail.salesOpportunityStage
                                                                                                                    : 'NA'}}</span>
                                                                                                            </div>
                                    
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Lead</p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.leadName.partyId
                                                                                                                    ? oppourtunityDetail.leadName.partyId :
                                                                                                                    'NA'}}</span>
                                                                                                            </div>
                                    
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Estimated Amount
                                                                                                                </p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.estimatedAmount
                                                                                                                    ? oppourtunityDetail.estimatedAmount :
                                                                                                                    'NA'}}</span>
                                                                                                            </div>
                                    
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Next Step Date
                                                                                                                </p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{nextStepDate | date}}</span>
                                                                                                            </div>
                                    
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Marketing Campaign
                                                                                                                </p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.marketingCampaign
                                                                                                                    ? oppourtunityDetail.marketingCampaign :
                                                                                                                    'NA'}}</span>
                                                                                                            </div>
                                    
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Source</p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.source
                                                                                                                    ? oppourtunityDetail.source :
                                                                                                                    'NA'}}</span>
                                                                                                            </div>
                                    
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Type</p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.type
                                                                                                                    ? oppourtunityDetail.type :
                                                                                                                    'NA'}}</span>
                                                                                                            </div>
                                    
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Next Step</p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.nextStep
                                                                                                                    ? oppourtunityDetail.nextStep :
                                                                                                                    'NA'}}</span>
                                                                                                            </div>
                                    
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Probability(%)
                                                                                                                </p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.estimatedProbability
                                                                                                                    ?
                                                                                                                    (oppourtunityDetail.estimatedProbability
                                                                                                                    | number : '.2-2') : 'NA'}}</span>
                                                                                                            </div>
                                    
                                                                                                            <div class="article">
                                                                                                                <p class="col-lg-6 col-12">Close Date</p>
                                                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{estimatedCloseDate}}</span>
                                                                                                            </div>
                                    
                                    
                                    
                                                                                                        </div>
                                    
                                    
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                    
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                                <!-- New Table Added -->

                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Cases
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#createCasesPopup" style="margin-left:65%!important;">Create New  Cases</span>
                                                        
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table ">
                                                                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Priority
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">Subject
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Status
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Type
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Reason
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td (click)="contactsDetail()">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.partyIdFrom}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.company}}</td>
                                                                                    <td>{{product.comments}}</td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>

                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Activities History
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#" style="margin-left:54%!important;">Log Call</span>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#" >Log Email</span>
                                                        
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table ">
                                                                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Types
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">Purpose
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Activity
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Status
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Started Date
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Completion Date
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Remove
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td (click)="contactsDetail()">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.partyIdFrom}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.company}}</td>
                                                                                    <td>{{product.comments}}</td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>

                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Catalog Requests
                                                        </h4>

                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#" style="margin-left:54%!important;">Create New Catalog</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table ">
                                                                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Date
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">Address
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Activity
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Taken By
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">FullFilled
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td (click)="contactsDetail()">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.partyIdFrom}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.company}}</td>
                                                                                    <td>{{product.comments}}</td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>

                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Document and Files
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#" style="margin-left:44%!important;">Bookmark URL</span>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#">Upload File</span>
                                                        
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table ">
                                                                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Names
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">Classificaion
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Description
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Created Date
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td (click)="contactsDetail()">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.partyIdFrom}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.company}}</td>
                                                                                    <td>{{product.comments}}</td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>

                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Notes
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#createNotesPopup" style="margin-left:65%!important;">Create New Note</span>
                                                        
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table ">
                                                                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Note Info
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">Created By
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Note Date Time
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    
    
                                                                                    
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td (click)="contactsDetail()">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.partyIdFrom}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.company}}</td>
                                                                                    <td>{{product.comments}}</td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>

                                                
                                            </div>
<!-- Removed class divB -->
                                            <div class="divB">
                                                
                                                

                                                <!-- New Code Added -->

                                                <div class="w3-card-4 classCard ">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Pending Activities
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#" style="margin-left:53%!important;">New Event</span>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#">New Task</span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
    
    
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table borderTable">
                                                                        <div class="card own-account-table ">
                                                                            <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                Types 
                                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="name">
                                                                                            <div style="color: white;">Purpose
                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
                                                                                        <th pSortableColumn="category">
                                                                                            <div style="color: white;">Activity
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
        
                                                                                        <th pSortableColumn="category">
                                                                                            <div style="color: white;">Status
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
        
                                                                                        <th pSortableColumn="category">
                                                                                            <div style="color: white;">Scheduled Date
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
        
                                                                                        <th pSortableColumn="category">
                                                                                            <div style="color: white;">Due Date
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
        
                                                                                        <th pSortableColumn="category">
                                                                                            <div style="color: white;">Action
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
        
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td (click)="contactsDetail()">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                {{product.partyIdFrom}}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>{{product.company}}</td>
                                                                                        <td>{{product.comments}}</td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                
        
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
    
    
                                                        </div>
                                                    </div>
    
                                                </div>
                                                
                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Accounts
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#createAccountsPopup" style="margin-left:53%!important;">Create New Accounts</span>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#">Assign Accounts</span>
                                                        
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table borderTable">
                                                                        <div class="card own-account-table ">
                                                                            <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                Account Name 
                                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="name">
                                                                                            <div style="color: white;">City
                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
                                                                                        <th pSortableColumn="category">
                                                                                            <div style="color: white;">Primary Email
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
        
                                                                                        <th pSortableColumn="category">
                                                                                            <div style="color: white;">Priamry Phone
                                                                                                <p-sortIcon field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
        
                                                                                        
        
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td (click)="contactsDetail()">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                {{product.partyIdFrom}}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>{{product.company}}</td>
                                                                                        <td>{{product.comments}}</td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                
        
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>

                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Open Orders
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#" style="margin-left:65%!important;">Orders </span>
                                                        
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table ">
                                                                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Order Date 
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">Order Name and ID
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">PO#
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Customer
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Status
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Ship Before Date
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Amount
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    
    
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td (click)="contactsDetail()">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.partyIdFrom}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.company}}</td>
                                                                                    <td>{{product.comments}}</td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>
                                                
                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Opportunities
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#createOpportunityPopup" style="margin-left:65%!important;">Create New Opportunity</span>
                                                        
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table ">
                                                                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Opportunity Name 
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">Stage
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Opportunity Amonut
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Estimated Close Date
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    
    
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td (click)="contactsDetail()">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.partyIdFrom}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.company}}</td>
                                                                                    <td>{{product.comments}}</td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>

                                                <div class="w3-card-4 classCard mt-3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard">
                                                            Payment and Shipping Accounts
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#" style="margin-left:44%!important;">Create New  Payment and Shipping Account</span>
                                                        
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select notes">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <!-- <div class="card own-account-table ">
                                                                        <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Opportunity Name 
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name">
                                                                                        <div style="color: white;">Stage
                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Opportunity Amonut
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    <th pSortableColumn="category">
                                                                                        <div style="color: white;">Estimated Close Date
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
    
                                                                                    
    
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td (click)="contactsDetail()">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            {{product.partyIdFrom}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{product.company}}</td>
                                                                                    <td>{{product.comments}}</td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            
                                                                        </p>
                                                                    </div> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
    
                                                </div>

                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling hCard ml-2">
                                                            Team Members Assigned To This Account
                                                        </h4>
                                                    </div>

                                                    <div class="panel-body allStyleUser">
                                                        <div class="card own-account-table borderTable">
                                                            <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                Name
                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">Role-Update
                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">Action
                                                                                <p-sortIcon field="category">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>

                                                                        
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                {{product.contentId}}
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">{{product.contentName}}</td>
                                                                        <td [ngStyle]="{'width':'170px'}">{{product.contentType}}</td>

                                                                        <td [ngStyle]="{'width':'170px'}">{{product.partyContentTypeId}}</td>
                                                                        <td [ngStyle]="{'width':'170px'}">{{product.status}}</td>
                                                                        <td [ngStyle]="{'width':'170px'}">{{product.fromDate | date:'medium'}}</td>
                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                        
<!-- 
                                                                            <svg (click)="export(product.contentId,product.contentName)" width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-arrow-down-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                          <path fill-rule="evenodd"
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                                            </svg>
                                                                            <svg (click)="onDelete(product.contentId,product.fromDate,product.partyContentTypeId,product.partyId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                            <defs>
                                                                                <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }
                                        
                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                                </style>
                                                                            </defs>
                                                                            <path class="a fill-color"
                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                            <path class="b"
                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                transform="translate(-147.576 -147.576)" />
                                                                            </svg> -->

                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                

                                                            </p>
                                                        </div>
                                                        
                                                        <form [formGroup]="teamMemberForm">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                 <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">New Team Member 
                                                                        </label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <p-dropdown filter="true"  placeholder="Select New Team Member" formControlName="newTeamMember"  optionlabel="label"></p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA" style="margin-left: -9% !important;">
                                                                            <label for="exampleInputEmail1">Role </label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <p-dropdown filter="true"  placeholder="Select Role" formControlName="role"   optionlabel="label"></p-dropdown>

                                                                        </div>
                        
                                                                    </div>
                                                                </div>

                                                                <div style="margin-left:78% !important">
                                                                    <button type="submit"  class="btn btn-outline-secondary">Add</button>

                                                                </div>
                                                               
                                                                <!-- <div class="row">
                                                                    <div class="col-lg-12">
                                                                        <div class="col-lg-4">
                                                                            <div class="">

                                                                                <label for="exampleInputEmail1">New Team Member</label>
                                                                                <div class="suppliers-wrapper" >
                                                                                    <p-dropdown filter="true"  placeholder="Select New Team Member" formControlName="newTeamMember"  optionlabel="label"></p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-4">
                                                                            <br><br>
                                                                            <div class="">
                                                                                <label for="exampleInputEmail1">Role</label>
                                                                                <div class="suppliers-wrapper" >
                                                                                    <p-dropdown filter="true"  placeholder="Select Role" formControlName="role"   optionlabel="label"></p-dropdown>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-4">
                                                                            <br><br>
                                                                            <div class="form-group">


                                                                                <button type="submit" (click)="onSubmit()" class="btn btn-outline-secondary">Add</button>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div> -->

                                                            </div>
                                                        </form>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of [{{oppourtunityId}}]</span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>

            </div>
            <div class="w3-card-4 classCard">
                <div class=" bg-white color-grey">
                    <div class="header-tabs">
                        <h4 class=" common-styling GRight ml-1">
                            Opportunity Information
                        </h4>
                        <span class="edit-buttons hCreate">
                            <a data-toggle="modal" data-target="#exampleModalCenter"
                                (click)="eventDetailEditData()">Update</a>
                        </span>

                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group">
                                                <div *ngIf="activeCategory==2">

                                                    <div class="panel-body" *ngIf="this.oppourtunityDetail">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div *ngIf="oppourtunityDetail" class="color-black container">
                                                                <div class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Sales Opportunity ID
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.salesOpportunityId
                                                                                ? oppourtunityDetail.salesOpportunityId:
                                                                                'NA'}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Opportunity Name
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.opportunityName
                                                                                ? oppourtunityDetail.opportunityName :
                                                                                'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Initial Stage</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.salesOpportunityStage
                                                                                ?
                                                                                oppourtunityDetail.salesOpportunityStage
                                                                                : 'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Lead</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.leadName.partyId
                                                                                ? oppourtunityDetail.leadName.partyId :
                                                                                'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Estimated Amount
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.estimatedAmount
                                                                                ? oppourtunityDetail.estimatedAmount :
                                                                                'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Next Step Date
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{nextStepDate | date}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Marketing Campaign
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.marketingCampaign
                                                                                ? oppourtunityDetail.marketingCampaign :
                                                                                'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Source</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.source
                                                                                ? oppourtunityDetail.source :
                                                                                'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Type</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.type
                                                                                ? oppourtunityDetail.type :
                                                                                'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Next Step</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.nextStep
                                                                                ? oppourtunityDetail.nextStep :
                                                                                'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Probability(%)
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{oppourtunityDetail.estimatedProbability
                                                                                ?
                                                                                (oppourtunityDetail.estimatedProbability
                                                                                | number : '.2-2') : 'NA'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Close Date</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{estimatedCloseDate}}</span>
                                                                        </div>



                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div> -->

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update-Opportunities</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="container-fluid main-container-dialog">
                    <div class="row">
                        <div class="col-12">
                            <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                                <div class=" bg-white color-grey" style="width: 100%;">
                                    <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                                    Update Opportunity</a></li>
                                        </ul>
                                    </div>

                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form *ngIf="createOppourtunityFormService.create_oppourtunity_form" [formGroup]="createOppourtunityFormService.create_oppourtunity_form" class="w-100">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Sales Opportunity
                                                                        ID</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="text" filter="true" id="exampleInputEmail1" formControlName="oppurtunityId" class="form-control small-input" placeholder="Select Oppourtunity Id" optionlabel="label">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Oppourtuinty
                                                                        Name</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <textarea style="font-size: 11px;" class="form-control small-input" formControlName="opportunityName" id="exampleFormControlTextarea1" rows="1" placeholder="Oppourtuinty Name"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Initial
                                                                        Customer</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown" [options]="initialAccountArray" formControlName="accountPartyId" placeholder="Initial Account" filter="true">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Lead</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown" filter="true" [options]="leadPartyIdd" formControlName="leadPartyId" placeholder="Initial Lead">
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Esitmated
                                                                        Amount</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" formControlName="estimatedamount" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Estimated Amount">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Probability</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" formControlName="probablity" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter probability">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Initial Stage <span
                                                                            style="color:red">*</span></label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown" filter="true" [options]="initialArray" formControlName="initialStage" optionlabel="label">
                                                                    </p-dropdown>
                                                                    <!--  <ng-container
                                                                            *ngIf="this.createOppourtunityFormService.create_oppourtunity_form.controls['initialStage'].invalid && (this.createOppourtunityFormService.create_oppourtunity_form.controls['initialStage'].dirty || this.createOppourtunityFormService.create_oppourtunity_form.controls['initialStage'].touched)">
                                                                            <p class="validate-field"
                                                                                *ngIf="this.createOppourtunityFormService.create_oppourtunity_form.controls['initialStage'].errors.required">
                                                                                Initial Stage is required!
                                                                            </p>
                                                                        </ng-container> -->
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Next Step</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" formControlName="nextStep" class="form-control small-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Next Step">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Next Step Date</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input class="small-input" type="date" id="exampleInputEmail1" [ngModel]="nextStepDate | date:'yyyy-MM-dd'" [value]="nextStepDate" formControlName="nextStepDate">
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Type</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown" filter="true" [options]="initialTypeArray" formControlName="type" optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Marketing
                                                                        Campaign</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown" filter="true" [options]="marketCompaignArray" formControlName="marketing_campaign" optionlabel="label">

                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Currency <span
                                                                            style="color:red">*</span></label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown" filter="true" [options]="currencyArray" optionlabel="label" formControlName="currencyId" placeholder="select currency">
                                                                    </p-dropdown>
                                                                    <!--  <ng-container
                                                                            *ngIf="this.createOppourtunityFormService.create_oppourtunity_form.controls['currencyId'].invalid && (this.createOppourtunityFormService.create_oppourtunity_form.controls['currencyId'].dirty || this.createOppourtunityFormService.create_oppourtunity_form.controls['currencyId'].touched)">
                                                                            <p class="validate-field"
                                                                                *ngIf="this.createOppourtunityFormService.create_oppourtunity_form.controls['currencyId'].errors.required">
                                                                                Currency is required!
                                                                            </p>
                                                                        </ng-container> -->
                                                                </div>


                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Source</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown" filter="true" [options]="sourceArray" formControlName="source" optionlabel="label">

                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1"> Close Date</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input class="small-input" type="date" id="exampleInputEmail1" [ngModel]="closeDate | date:'yyyy-MM-dd'" [value]="closeDate" formControlName="closeDate">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormD">
                                                                    <label for="exampleInputEmail1">Description</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <textarea style="font-size: 11px;" formControlName="description" placeholder="Enter Description" class="form-control small-input" id="exampleFormControlTextarea1" rows="1"></textarea>
                                                                </div>


                                                            </div>
                                                        </div>




                                                    </div>
                                                    <div class="main-submit-button" style="margin-right: 14%;">
                                                        <button style="width:159px" type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button>
                                                        <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--newcode1 by RA-->


<div class="modal fade" id="createAccountsPopup" tabindex="-1" role="dialog" aria-labelledby="createAccountsPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Accounts
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Accounts</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createAccountsForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Account Name	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="accountName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Account Name">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">City</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="city" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter City">
                                                    
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Primary Eamil</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="email" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Primary Eamil">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Primary Phone</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="primaryPhone" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Primary Phone">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 32%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" >Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createOpportunityPopup" tabindex="-1" role="dialog" aria-labelledby="createOpportunityPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Opportunity
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Opportunity</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCasesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Opportunity Name	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="opportunityName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Opportunity Name">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Stage</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="stage" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Stage">
                                                    
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Opportunity Amount
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="opportunityAmount" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Opportunity Amount">
                                                    
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Estimated Close Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="estimatedCloseDate" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" >Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createNotesPopup" tabindex="-1" role="dialog" aria-labelledby="createNotesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Notes
                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Notes</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createNotesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Note Info	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="noteInfo" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Note Info">
                                               
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormAA">
                                                        <label for="exampleInputEmail1">Created By</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="createdBy" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Enter Created By">
                                                    
                                                        </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Note Date Time
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="noteDateTime" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" >
                                                    
                                                        
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" >Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>