<div class="container-fluid main-container-wrapper ">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Add New Party Group</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <!-- <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;">Parties
                </span>
                <span class="color-black pl-1"> >Add New Party Group
                </span>
                <button type="submit" class="btn btn-danger buttonclass" (click)="GoToQuickLink()">Cancel</button>&nbsp;
            </div> -->
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard"  style="margin-top:1%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                    Add New Party Group
                                </a></li>
                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                    Update New Party Group
                                </a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100"  [formGroup]="createPartyGroup">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Party ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Party ID"
                                                    formControlName="partyId">
                                                    <br>
                                                    <label>ID sequence will be generated if empty</label>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Group Name<span
                                                        style="color: red !important;">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Group name"
                                                    formControlName="groupName">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Group Name Local</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Group Name Local"
                                                    formControlName="groupNameLocal">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Office Site Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Office Site Name"
                                                    formControlName="officeSiteName">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Annual revenue</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Annual revenue"
                                                    formControlName="annualRevenue">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Number of employees</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Number of employees"
                                                    formControlName="numberOfEmployees">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Ticker symbol</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Ticker symbol"
                                                    formControlName="tickerSymbol">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Comments</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Comments"
                                                    formControlName="comments">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Logo Image Url</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Logo Image Url"
                                                    formControlName="logoImage">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">First Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter First Name"
                                                    formControlName="firstName">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Last Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Last Name"
                                                    formControlName="lastName">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Title</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Title"
                                                    formControlName="title">
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Description</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Description"
                                                    formControlName="description">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Preferred Currency Uom ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Currency"  [options]="currencyArray" optionlabel="label"
                                                    filter="true"
                                                  formControlName="preferredCurrency">
                                                     
                                                  </p-dropdown>
                                                </div>
                                               
                                            </div>
                                          </div>
                                          <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">External ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter User Name"
                                                    formControlName="externalId">
                                                </div>
                                              
                                               
                                            </div>
                                          </div>
                                       
 
                                    </div>
                                </form>
                                <div class="main-submit-button" style="margin-left: -27%;">
                                    <button type="submit"
                                        class="btn btn-secondary submit-btn" (click)="onSubmit()">Save</button>&nbsp;&nbsp;
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>