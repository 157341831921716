import { Component, OnInit, ViewChild } from '@angular/core';
declare var $: any;
import { SortEvent } from 'primeng/api';
import { ShippingService } from '../shipping.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-shipment-time-estimate',
  templateUrl: './shipment-time-estimate.component.html',
  styleUrls: ['./shipment-time-estimate.component.css']
})
export class ShipmentTimeEstimateComponent implements OnInit {
  products3: any;
  activeCategory = 5;
  activeCategorytab = 2;
  activeCat=14;
  total=0;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  EditShipmentForm: FormGroup;
  shipmentTimeList: any;
  ShipmentEstimatesForm: FormGroup;
  LeadTimeUomId: any;
  LeadTimeUomIdArray: any[]=[];
  GeoId: any;
  GeoIdArray: any[]=[];
  carrierShipmentMethod: any;
  carrierShipmentMethodArray: any[]=[];
  partyIdArray: any[]=[];
  personId: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(  readonly router: Router,
    readonly _ShippingService: ShippingService,
    readonly myContactsService: MyContactsService,
    readonly _FormBuilder: FormBuilder,    private _location: Location,
    readonly datePipe: DatePipe,
    readonly _ToastrService: ToastrService,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly  _Router: Router,) {

      this.EditShipmentForm = this._FormBuilder.group({
        CarrierShipmentMethod :['']
      })

      this.ShipmentEstimatesForm = this._FormBuilder.group({
        ShipmentMethodTypeID :[''],
        PartyID :[''],
        RoleTypeID :[''],
        GeoIdTo :[''],
        GeoIdFrom :[''],
        FromDate :[''],
        ThroughDate :[''],
        LeadTime :[''],
        LeadTimeUomID :[''],
      })
    }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this.getShipmentTimeEstimateById();
    this.getGeoId();
    this.getLeadTimeUomId();
    this.getCarrierShipmentMethod();
    this.getPartyId();
  } 
   getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getCarrierShipmentMethod() {
    this.spinner.show();
    this.myContactsService.getCarrierShipmentMethod().subscribe(res => {
      this.carrierShipmentMethod = res.data;
      this.spinner.hide();
      for (const value of this.carrierShipmentMethod) {
        this.carrierShipmentMethodArray.push({
          label: value.getCarrierShipmentMethodDescription,
          value: value.getCarrierShipmentMethodId
        })
      }
    })
  }
  getGeoId() {
    this.spinner.show();
    this.myContactsService.getGeoIds().subscribe(res => {
      this.GeoId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.GeoId) {
        this.GeoIdArray.push({
          label: value.geoId,
          value: value.geoId
        })
      }
    })
  }
  getLeadTimeUomId() {
    this.spinner.show();
    this.myContactsService.getLeadTimeUomId().subscribe(res => {
      this.LeadTimeUomId = res.data.getLeadTimeUomId;
      this.spinner.hide();
      for (const value of this.LeadTimeUomId) {
        this.LeadTimeUomIdArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  getShipmentTimeEstimateById() {
    this.spinner.show();
    this.myContactsService.getShipmentTimeEstimateById().subscribe(res => {
      this.shipmentTimeList = res.data.getShipmentTimeEstimateById;
      this.spinner.hide();
    })
  }
  updatePage(){
    this.router.navigate(["/facilities/catalog/shipment/detail-shipment-time-estimate"],
     { queryParams: { id:this.EditShipmentForm.value.CarrierShipmentMethod} })
     this.closebutton2.nativeElement.click();

  }

  expireShipmentTimeEstimate(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "fromDate": this.datePipe.transform(product.fromDate, 'yyyy-nn-dd hh:mm:ss')  ,
          "geoIdFrom":  product.geoIdFrom ,
          "geoIdTo":  product.geoIdTo ,
          "partyId": product.partyId ,
          "roleTypeId": product.roleTypeId ,
          "shipmentMethodTypeId":  product.shipmentMethodTypeId ,
        }
        this.myContactsService.expireShipmentTimeEstimate(formData).subscribe(res => {
        this.getShipmentTimeEstimateById(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createShipmentTimeEstimate(){
    this.spinner.show();
    const requestData = {
      "fromDate": this.ShipmentEstimatesForm.value.FromDate,
      "geoIdFrom": this.ShipmentEstimatesForm.value.GeoIdFrom,
      "geoIdTo": this.ShipmentEstimatesForm.value.GeoIdTo,
      "isDelete": "",
      "leadTime": this.ShipmentEstimatesForm.value.LeadTime,
      "leadTimeUomId": this.ShipmentEstimatesForm.value.LeadTimeUomID,
      "partyId": this.ShipmentEstimatesForm.value.PartyID,
      "roleTypeId": this.ShipmentEstimatesForm.value.RoleTypeID,
      "shipmentMethodTypeId": this.ShipmentEstimatesForm.value.ShipmentMethodTypeID,
      "thruDate":  this.ShipmentEstimatesForm.value.ThroughDate,

     
    }
    this.myContactsService.createShipmentTimeEstimate(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.ShipmentEstimatesForm.reset();
        this.getShipmentTimeEstimateById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  mainOpen(){
    this.router.navigate(['/facilities/catalog/main-catalog']);
  }
  catalogOpen(){
    this.router.navigate(['/facilities/catalog/catalogs']);
  }
  categoriesOpen(){
    this.router.navigate(['/facilities/catalog/catagories']);
  }
  productsOpen(){
    this.router.navigate(['/facilities/catalog/products']);
  }
  featuresOpen(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  promosOpen(){
    this.router.navigate(['/facilities/catalog/promos']);
  }
  priceRulesOpen(){
    this.router.navigate(['/facilities/catalog/price-rules']);
  }
  storesOpen(){
    this.router.navigate(['/facilities/catalog/stores']);
  }
  productStoreGroupsOpen(){
    this.router.navigate(['/facilities/catalog/product-store-groups']);
  }
  thesaurusOpen(){
    this.router.navigate(['/facilities/catalog/thesaurus']);
  }
  reviewsOpen(){
    this.router.navigate(['/facilities/catalog/reviews']);
  }
  configurationsOpen(){
    this.router.navigate(['/facilities/catalog/configurations']);
  }
  subscriptionsOpen(){
    this.router.navigate(['/facilities/catalog/subscriptions']);
  }
  shippingOpen(){
    this.router.navigate(['/facilities/catalog/shipment']);
  }
  imageManagementOpen(){
    this.router.navigate(['/facilities/catalog/image-management']);
  }
  ship() {
    this._Router.navigate(['/facilities/catalog/shipment']);
  }
  detailPage(shipmentMethodTypeId) {
    this.router.navigate(["/facilities/catalog/shipment/detail-shipment-time-estimate"], { queryParams: { shipmentMethodTypeId:shipmentMethodTypeId} })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
