<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of <span>{{this.partyId}}</span>


                </span>
                <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            
            </div>

            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="w3-card-4 classCardA ">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div class="create-new-leade ">
                                                    <div class="container-fluid">
                                                        <div class="panel-group">
                                                            <div class="w3-card-4 classCardView mt-2">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling hleft">
                                                                        Content List
                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModal">Create New Content
                                                                        </button>
                                                                    </h4>


                                                                </div>

                                                                <div class="panel-body allStyleUser">
                                                                    <div class="card own-account-table borderTable">

                                                                        <p-table [value]="PartyContentData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Content Id
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                        <div style="color: white;"> Content Name
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Party Content Type ID
                                                                                            <p-sortIcon field="quantity"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Description
                                                                                            <p-sortIcon field="quantity"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                        <div style="color: white;"> Status ID
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>



                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Locale String
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                        <div style="color: white;"> Content Type ID
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Mime Type ID
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Data Resource Name
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> From Date
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Through Date
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'100px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Action
                                                                                            <p-sortIcon field="quantity"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'100px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            <p-sortIcon field="quantity"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}" (click)="contactsDetail()">
                                                                                        <div class="checkbox-align" style="padding-left: 40px !important;">

                                                                                            {{product.data.contentId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.data.contentName}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.data.partyContentTypeId}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}"></td>

                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.data.statusId}}</td>
                                                                                    <td [ngStyle]="{'width':'170px'}"></td>
                                                                                    <td [ngStyle]="{'width':'170px'}"></td>
                                                                                    <td [ngStyle]="{'width':'170px'}"></td>
                                                                                    <!-- <td [ngStyle]="{'width':'170px'}" *ngIf="product.data"><span>{{product.data.contentTypeData.description}}</span></td>
                                                                            <td [ngStyle]="{'width':'170px'}" *ngIf="product.data">{{product.data.mimeTypeIdData.description}}</td> -->
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.data.contentName}}</td>

                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.fromDate|date:'yyyy-MM-dd hh:mm:ss' }}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">{{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModal" (click)=" edit(product)">Edit
                                                                                </button>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'100px'}">
                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="onDelete(product)">Remove
                                                                                </button>
                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Party Content</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closebutton>&times;</span>
        </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                    Attach Content
                </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="Attachform">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12" *ngIf="show">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                              Content Id</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <label>{{this.contentId}}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                               Parent Content Type ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown *ngIf="!show" filter="true" formControlName="partyContentTypeId" [options]="partyContentArray" placeholder="Parent Content Type ID" optionlabel="label">
                                                        </p-dropdown>
                                                        <input type="text" *ngIf="this.show" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Parent ContentTypeId" formControlName="partyContentTypeId" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Content Type ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder="Enter Content Type ID" name="Parent Type ID" formControlName="ContentTypeID" [options]="PartyContentTypeIdListArray" optionlabel="label">
                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                From Date</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" *ngIf="!this.show" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date" formControlName="fromDate">
                                                        <input type="date" *ngIf="this.show" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Parent ContentTypeId" formControlName="fromDate" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Through</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date" formControlName="throughDate">

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                Status ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="StatusID" [options]="PartyStatusIdListArray" placeholder="Status ID" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                Data Category ID</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder="Enter Data Category ID" name="Parent Type ID" formControlName="DataCategoryID" [options]="DataCategoryIdListArray" optionlabel="label">
                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                Is Public</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" formControlName="IsPublic" [options]="isPublic" placeholder="Is Public" optionlabel="label">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Attach Content</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input class="form-control" type="file" style="width: 100%!important;" id="exampleInputEmail1" formControlName="file" aria-describedby="emailHelp"  accept="" (change)="fileProgress($event)" style="line-height: 0.9!important;">
                                                    </div>



                                                </div>
                                            </div>




                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 66%;">

                                        <button type="submit" *ngIf="!show" (click)="onSubmitAttach();" class="btn btn-secondary submit-btn">Save</button>

                                        <button type="submit" *ngIf="show" (click)="onUpdateAttach(product);" class="btn btn-secondary submit-btn">Update</button>




                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner>