import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder ,FormGroup,Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';  import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MergeContactsService } from 'src/app/crm/contacts/merge-contacts/merge-contacts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-configure-warehouse-main',
  templateUrl: './configure-warehouse-main.component.html',
  styleUrls: ['./configure-warehouse-main.component.css']
})
export class ConfigureWarehouseMainComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  activeCategory = 10;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  configureWarehouseForm: FormGroup;
  addExistingForm: FormGroup;
  addNewForm: FormGroup;
  primaryWarehouse: any;
  primaryWarehouseArray: any[]=[];
  PrimaryWarehouseById: any;
  show: boolean;
  InitialManager: any;
  InitialManagerArray: any[]=[];
  inventoryItemArray: { label: string; value: string; }[];
  partyIdArray: any[]=[];
  personId: any;
  currencyUomIds: any[];
  facilityId: any;
  FacilityIdTo: any;
  FacilityIdToArray: any[]=[];
  primaryWarehouseId: string;
  existingSummaryPage: any;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly addSkillService: AddSkillService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly mergeContactsService: MergeContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
        readonly _GlobalResourceService: GlobalResourceService,    private _location: Location,
        readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _Router: Router
  ) 
  {
    this.configureWarehouseForm=this._FormBuilder.group({
      primaryWarehouse:"",
    })

    this.addExistingForm=this._FormBuilder.group({
      existingBackupWarehouse:"",
      backupWarehouse:"",
      Priority:"",
      From:"",
      Thru:"",
    })

    this.addNewForm=this._FormBuilder.group({
      primaryWarehouse:"",
      Name:"",
      Owner:"",
      Currency:"",
      inventoryItemType:"",
      initialManager:"",
      Priority:"",
      From:"",
      Thru:"",
    })
    this.inventoryItemArray =[
     {
      label:"Serialized",
      value:"SERIALIZED_INV_ITEM"
     },
      {
        label:"Non-Serialized",
        value:"NON_SERIAL_INV_ITEM"
      }
    ]
}

  ngOnInit(): void {
    this.primaryWarehouseId = localStorage.getItem("primaryWarehouseId");
    this.getPrimaryWarehouse();
    this.getInitialManager();
    this.getPrimaryWarehouse();
    this.getPartyId();
    this.getCurrency();
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  detailPage(planningWareHouseId,primaryWarehouse) {
    this._Router.navigate(['/facilities/Planning/configure-warehouse-detail'],
     { queryParams: { planningWareHouseId:planningWareHouseId,primaryWarehouseId: primaryWarehouse } });
}
  detailExistingPage(planningWareHouseExistingId,primaryWarehouse) {
    this._Router.navigate(['/facilities/Planning/configure-warehouse-existing-detail'],
     { queryParams: { planningWareHouseExistingId:planningWareHouseExistingId,primaryWarehouseId: primaryWarehouse } });
}
  getFacilityIdTo() {
    this.spinner.show();
    this.mergeContactsService.getFacilityIdTo(this.primaryWarehouseId).subscribe(res => {
      this.FacilityIdTo = res.data[0].getFacilityIdTo;
      this.spinner.hide();
      for (const value of this.FacilityIdTo) {
        this.FacilityIdToArray.push({
          label: value.facilityId,
          value: value.facilityId
        })
      }
    })
  }
  getPrimaryWarehouseExistingById() {
    this.show = true;
    this.spinner.show();
    this.mergeContactsService.getPlanningWareHousesExistingSummaryPage(this.configureWarehouseForm.value.primaryWarehouse).subscribe(res => {
      this.existingSummaryPage = res.data[0].getPlanningWareHousesExistingAll;
      this.spinner.hide();
   
    })
   
  }
  deletePlanningWareHousesExisting(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "planningWareHouseExistingId" : product.planningWareHouseExistingId 

        }
        this.mergeContactsService.deletePlanningWareHousesExisting(req).subscribe((res: any) => {
          this.getPrimaryWarehouseExistingById();

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deletePlanningWareHouse(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "planningWareHouseId":product.planningWareHouseId 

        }
        this.mergeContactsService.deletePlanningWareHouse(req).subscribe((res: any) => {
          this.getPrimaryWarehouseById();

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createPlanningWareHousesExisting(): void {
    this.spinner.show();
    const requestData = {
      "facilityIdTo": this.addExistingForm.value.backupWarehouse,
  "fromDate":this.datePipe.transform(this.addExistingForm.value.From,"yyyy-MM-dd hh:mm:ss") ,
  "planningWareHouseExistingId": "",
  "primaryWarehouse":this.primaryWarehouseId,
  "priority": this.addExistingForm.value.Priority,
  "thruDate":this.datePipe.transform(this.addExistingForm.value.Thru,"yyyy-MM-dd hh:mm:ss") ,


    }
    this.mergeContactsService.createPlanningWareHousesExisting(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.addExistingForm.reset();
        this.closebutton1.nativeElement.click();
        this.getPrimaryWarehouseExistingById();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  createPlanningWareHouse(): void {
    this.spinner.show();
    const requestData = {
      "currencyUomId":  this.addNewForm.value.Currency,
      "fromDate":this.datePipe.transform( this.addNewForm.value.From,"yyyy-MM-dd hh:mm:ss") , 
      "inventoryItemType":  this.addNewForm.value.inventoryItemType,
      "name":  this.addNewForm.value.Name,
      "partyId":  this.addNewForm.value.Owner,
      "planningWareHouseId":  "",
      "primaryWarehouse":  this.primaryWarehouseId,
      "priority":  this.addNewForm.value.Priority,
      "thruDate":this.datePipe.transform( this.addNewForm.value.Thru,"yyyy-MM-dd hh:mm:ss") , 
      "userLoginId":  this.addNewForm.value.initialManager,
    };
    this.mergeContactsService.createPlanningWareHouse(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.addNewForm.reset();
        this.closebutton2.nativeElement.click();
        this.getPrimaryWarehouseById();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        this.spinner.hide();
        this.currencyUomIds = data.data.currencyList.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });
    
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  showTable(){
    localStorage.setItem("primaryWarehouseId",this.configureWarehouseForm.value.primaryWarehouse)
    this.getPrimaryWarehouseById();
    this.getPrimaryWarehouseExistingById();
    this.getFacilityIdTo();

  }
  getPrimaryWarehouseById() {
    this.show = true;
    this.spinner.show();
    this.mergeContactsService.getPrimaryWarehouseById(this.configureWarehouseForm.value.primaryWarehouse).subscribe(res => {
      this.PrimaryWarehouseById = res.data[0].getPrimaryWarehouseById;
      this.spinner.hide();
    })
   
  }

  getInitialManager() {
    this.spinner.show();
    this.mergeContactsService.getInitialManager().subscribe(res => {
      this.InitialManager = res.data[0].getInitialManager;
      this.spinner.hide();
      for (const value of this.InitialManager) {
        this.InitialManagerArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getPrimaryWarehouse() {
    this.spinner.show();
    this.mergeContactsService.getPrimaryWarehouse().subscribe(res => {
      this.primaryWarehouse = res.data[0].getPrimaryWarehouse;
      this.spinner.hide();
      for (const value of this.primaryWarehouse) {
        this.primaryWarehouseArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
    })
  }
  openInventory()
  {
    this._Router.navigate(['/facilities/Planning']);

  }
  openFindRequirements()
  {
    this._Router.navigate(['/facilities/Planning/find-requirements']);

  }
  openRequirements()
  {
    this._Router.navigate(['/facilities/Planning/open-requirements']);

  }
  openProductRequirements()
  {
    this._Router.navigate(['/facilities/Planning/product-requirements']);
    
  }
  openInternalRequirements()
  {
    this._Router.navigate(['/facilities/Planning/internal-requirements']);

  }
  openTransferRequirements()
  {
    this._Router.navigate(['/facilities/Planning/transfer-requirements']);

  }
  openSalesOrderHistory()
  {
    this._Router.navigate(['/facilities/Planning/upload-sales-order-history']);

  }
  openRunMRP()
  {
    this._Router.navigate(['/facilities/Planning/run-mrp']);

  }
  openViewMRP()
  {
    this._Router.navigate(['/facilities/Planning/view-mrp']);

  }
  openConfigureWarehouses()
  {
    this._Router.navigate(['/facilities/Planning/configure-warehouse']);

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
