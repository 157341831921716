import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr'; import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-image-management-manage-detail',
  templateUrl: './image-management-manage-detail.component.html',
  styleUrls: ['./image-management-manage-detail.component.css']
})
export class ImageManagementManageDetailComponent implements OnInit {
  @ViewChild('closebuttonA') closebuttonA;
  resData: any;
  activeCat=15;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  productId: any;
  manageList :any;
  ManageForm: any;
  product: any;
  mnagedViewImage: any;
  iD: any;
  constructor(public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
      private _location: Location,
      readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router) {

      this.ManageForm = this._FormBuilder.group({
        Caption: [''],
        SortOrder: [''],
        Public: [''],
        Action :['']
      })
     }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.productId = params.productId;
    })
    this.getManageList();
  }
  getManagedViewImage() {
    this.spinner.show();
    this.myContactsService.getManagedViewImage(this.iD).subscribe(res => {
      this.mnagedViewImage = res.data[0].data;
      this.spinner.hide();
    })
  }
  viewImage(product){
    window.open(product.objectInfo, "_blank")
  }
  submitManage() {
    this.spinner.show();
   
    this.myContactsService.submitManage(this.ManageForm.value.Caption,
       this.product.id, this.ManageForm.value.SortOrder ).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Success");
        this.spinner.hide();
        this.getManageList();
        this.closebuttonA.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  submitPage(product){
    this.product = product;
    setTimeout(() => {

      const form = this.ManageForm;
      form.patchValue({
        Caption: product.description,
        SortOrder:  product.sequenceNum,
        Public: product.isPublic
      })
    }, 2000);
  }
  removeManageImage(product)  {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.myContactsService.removeManageImage(product.id).subscribe(res => {
        this.getManageList();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
 
  setDefaultImage(product) {
    this.spinner.show();
    const formData = {
      "id": product.id,
      "productId":product.productId,
      "setDefaultId": ""
    }
    this.myContactsService.setDefaultImage(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Success");
        this.spinner.hide();
        this.iD = product.id;
        this.getManagedViewImage();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getManageList() {
    this.spinner.show();
    this.myContactsService.getManageList(this.productId).subscribe(res => {
      this.manageList = res.data[0].data;
      this.spinner.hide();
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
