import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { Router } from '@angular/router';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from 'src/app/services/dashboard.service';

import { ViewChild } from "@angular/core";



import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexTitleSubtitle,
  ApexXAxis
} from "ng-apexcharts";



import {
  ApexDataLabels,
  ApexStroke
} from "ng-apexcharts";



export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
};
@Component({
  selector: 'app-crm-dashboard',
  templateUrl: './crm-dashboard.component.html',
  styleUrls: ['./crm-dashboard.component.css']
})

export class CrmDashboardComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  // private chart: am4charts.XYChart;
  chartValue: any[];
  activeCategory = 2;
  constructor(
    private accountsService: AccountsService,
    private _DashboardService: DashboardService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
  ) {
  
    this.chartOptions = {
      series: [
       
        {
          name: "Orders",
          data: []
        }
      ],
      chart: {
        height: 350,
        type: "area"
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth"
      },
      xaxis: {
        type: "category",
        categories: [
        
        ]
      },
      tooltip: {
        x: {
          format: "dd/MM/yy"
        }
      }
    };
  }

  ngOnInit(): void {
    this.getcrmDashboardChart();
  }
  getcrmDashboardChart() {
    this._DashboardService.getcrmDashboardChart().subscribe((res: any) => {

      this.chartOptions = {
        series: [
         
          {
            name: "Orders",
            data: res.data.value
          }
        ],
        chart: {
          height: 350,
          type: "area"
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        xaxis: {
          type:"category",
          categories: res.data.label
        },
        tooltip: {
          x: {
            format: "dd/MM/yyyy"
          }
        }
      };
  
    })

  }

  // ngAfterViewInit(): void {
  //   this._DashboardService.getCrmDashboard()
  //     .then(data => {
  //       for (let chartInfo in data.data) {
  //         this.chartValue.push({
  //           country: chartInfo,
  //           visits: data.data[chartInfo]
  //         });
  //       }
  //       // Themes begin
  //       am4core.useTheme(am4themes_animated);
  //       // Themes end

  //       // Create chart instance
  //       let chart = am4core.create("chartdiv7", am4charts.XYChart);
  //       chart.scrollbarX = new am4core.Scrollbar();

  //       // Add data
  //       chart.data = this.chartValue;


  //       let total = 0;

  //       for (var i = 0; i < chart.data.length; i++) {
  //         let value = chart.data[i].visits;
  //         total += value;
  //       }

  //       let sum = 0;
  //       for (var i = 0; i < chart.data.length; i++) {
  //         let value = chart.data[i].visits;
  //         sum += value;
  //         chart.data[i].pareto = sum / total * 100;
  //       }


  //       // Create axes
  //       let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  //       categoryAxis.dataFields.category = "country";
  //       categoryAxis.renderer.grid.template.location = 0;
  //       categoryAxis.renderer.minGridDistance = 60;
  //       categoryAxis.tooltip.disabled = true;

  //       let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  //       valueAxis.renderer.minWidth = 50;
  //       valueAxis.min = 0;
  //       valueAxis.cursorTooltipEnabled = false;

  //       // Create series
  //       let series = chart.series.push(new am4charts.ColumnSeries());
  //       series.sequencedInterpolation = true;
  //       series.dataFields.valueY = "visits";
  //       series.dataFields.categoryX = "country";
  //       series.tooltipText = "[{categoryX}: bold]{valueY}[/]";
  //       series.columns.template.strokeWidth = 0;

  //       series.tooltip.pointerOrientation = "vertical";

  //       series.columns.template.column.cornerRadiusTopLeft = 10;
  //       series.columns.template.column.cornerRadiusTopRight = 10;
  //       series.columns.template.column.fillOpacity = 0.8;

  //       // on hover, make corner radiuses bigger
  //       let hoverState = series.columns.template.column.states.create("hover");
  //       hoverState.properties.cornerRadiusTopLeft = 0;
  //       hoverState.properties.cornerRadiusTopRight = 0;
  //       hoverState.properties.fillOpacity = 1;

  //       series.columns.template.adapter.add("fill", function (fill, target) {
  //         return chart.colors.getIndex(target.dataItem.index);
  //       })


  //       let paretoValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  //       paretoValueAxis.renderer.opposite = true;
  //       paretoValueAxis.min = 0;
  //       paretoValueAxis.max = 100;
  //       paretoValueAxis.strictMinMax = true;
  //       paretoValueAxis.renderer.grid.template.disabled = true;
  //       paretoValueAxis.numberFormatter = new am4core.NumberFormatter();
  //       paretoValueAxis.numberFormatter.numberFormat = "#'%'"
  //       paretoValueAxis.cursorTooltipEnabled = false;

  //       let paretoSeries = chart.series.push(new am4charts.LineSeries())
  //       paretoSeries.dataFields.valueY = "visits";
  //       paretoSeries.dataFields.categoryX = "country";
  //       paretoSeries.yAxis = paretoValueAxis;
  //       paretoSeries.tooltipText = "pareto: {valueY.formatNumber('#.0')}%[/]";
  //       //    paretoSeries.tooltipText = "{valueY}";
  //       paretoSeries.bullets.push(new am4charts.CircleBullet());
  //       paretoSeries.strokeWidth = 2;
  //       paretoSeries.stroke = new am4core.InterfaceColorSet().getFor("alternativeBackground");
  //       paretoSeries.strokeOpacity = 0.5;

  //       // Cursor
  //       chart.cursor = new am4charts.XYCursor();
  //       chart.cursor.behavior = "panX";

  //     });
  // }

  ngOnDestroy(): void {
  }
}