import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-hub-dashboard',
  templateUrl: './hub-dashboard.component.html',
  styleUrls: ['./hub-dashboard.component.css']
})
export class HubDashboardComponent implements OnInit {

  constructor(
  private _location: Location,
  ) { }

  ngOnInit(): void {
  }


backWindow(){
  this._location.back();
}

}
