<div class="container-fluid main-container-wrapper">
    <div class="row">
         <div class="col-12 pad_0">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels">
                </span>

                <span class="">

                    <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

                    <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
                </span>

            </div>
            <div class=" bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard mt-20 border_wdth_1">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="summary()"><a> Summary </a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="editWorkEffort1()"><a> Work Effort </a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="childWorkEfforts()"><a> Children </a></li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="partyAssigns()"><a> Parties </a></li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="rates()"><a> Rates </a></li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="commEvents()"><a> Communications </a></li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="shopLists()"><a> Shopping Lists </a></li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="requests()"><a> Requests </a></li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="requirements()"><a> Requirements </a></li>
                            <li [ngClass]="activeCategory==10?'active':''" (click)="quotes()"><a> Quotes </a></li>
                            <li [ngClass]="activeCategory==11?'active':''" (click)="orderHeaders()"><a> Orders </a></li>
                            <li [ngClass]="activeCategory==12?'active':''" (click)="timeEntries()"><a> Time Entries </a></li>
                            <li [ngClass]="activeCategory==13?'active':''" (click)="notes()"><a> Notes </a></li>
                            <li [ngClass]="activeCategory==14?'active':''" (click)="contents()"><a> Content </a></li>
                            <li [ngClass]="activeCategory==15?'active':''" (click)="products()"><a> Product </a></li>
                            <li [ngClass]="activeCategory==16?'active':''" (click)="reviews()"><a> Reviews </a></li>
                            <li [ngClass]="activeCategory==17?'active':''" (click)="keywords()"><a> Keywords </a></li>
                            <li [ngClass]="activeCategory==18?'active':''" (click)="contactMechs()"><a> Contact Mechs </a></li>
                            <li [ngClass]="activeCategory==19?'active':''" (click)="agreementApplics()"><a> Agreement Applics </a></li>
                            <li [ngClass]="activeCategory==20?'active':''" (click)="fixedAssets()"><a> Fixed Assets </a></li>
                            <li [ngClass]="activeCategory==21?'active':''" (click)="attributes()"><a> Attributes </a></li>
                            <li [ngClass]="activeCategory==22?'active':''" (click)="eventReminders()"><a> Event Reminders </a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2" (click)="addWorkEffort()"> Create New Work Effort</button>
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCardView mt-2 w-100">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">Work Effort Reviews
                                                <button *ngIf="!this.Hidebtn" (click)="reset()" type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#update"> Add Reviews </button>
                                            </div>
                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="card own-account-table">
                                                        <p-table [value]="WorkEffortReviewById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white; padding-left: 5px !important;">
                                                                            User Login ID
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;">
                                                                            Review Date
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;">
                                                                            Status
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>

                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;">
                                                                            Posted Anonymous
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>

                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;">
                                                                            Rating
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>

                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;">
                                                                            Review Text
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'180px'}">
                                                                        <div style="color: white;">
                                                                            Action
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td (click)="detailParties(product.workEffortReviewById.userLoginId)" [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                        <span>{{product.workEffortReviewById.userLoginId}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px','color':'#0d3769 !important','cursor': 'pointer'}">
                                                                        <span>{{product.workEffortReviewById.reviewDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span>{{product.workEffortReviewById.statusId}}</span>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span>{{product.workEffortReviewById.postedAnonymous}}</span>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span>{{product.workEffortReviewById.rating}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <span>{{product.workEffortReviewById.reviewText}}</span>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <a *ngIf="!this.Hidebtn" (click)="updateEvent(product)"  class="account-button"  data-toggle="modal" data-target="#update">Update</a>&nbsp;
                                                                        <a *ngIf="!this.Hidebtn" (click)="deleteWorkEffortReview(product)"  class="account-button" >Remove</a>
                                                                    </td>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td colspan="7">
                                                                        <span class="text-center">
                                                                                <h4 class="ml-2">No Record Found
                                                                                </h4>
                                                                            </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="update" tabindex="-1" role="dialog" aria-labelledby="update" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add Work Effort Review </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add Review </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addReviews">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> User Login ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="UserLoginIdArray" formControlName="userLoginID" optionlabel="label" placeholder="Enter User Login ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Review Date </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" formControlName="reviewDate" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Review Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Status <span style="color: red;"></span> </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="status" formControlName="status" optionlabel="label" placeholder="Enter Status">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Posted Anonymous </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="posted" formControlName="postedAnonymous" optionlabel="label" placeholder="Enter Posted Anonymous">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Rating </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="rating" aria-describedby="emailHelp" placeholder="Enter Rating">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1"> Review Text </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" formControlName="reviewText" aria-describedby="emailHelp" placeholder="Enter Review Text">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6  main-submit-button">
                                            <button (click)="createWorkEffortReview();" *ngIf="!this.show" type="submit" class="btn btn-secondary submit-btn">Add</button>
                                            <button (click)="updateWorkEffortReview()" *ngIf="this.show" type="submit" class="btn btn-secondary submit-btn">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>