import { Component, OnInit ,ViewChild} from '@angular/core';
import { AccountsService } from '../../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { MainCatalogService } from '../../main-catalog-mgmt/catalog.service';
import { FinancialService } from 'src/app/services/financial.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
@Component({
  selector: 'app-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.css']
})
export class ConfigurationsComponent implements OnInit {
  @ViewChild('closebutton')closebutton;
  activeCategoryInv=2;
  dropdownValue: { label: string; value: string; }[];
  activeCat=12;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  advanceSearch: {
    ConfigItem: string,
    ConfigItemName: string,
    Description: string,
  };
  congigList: any;
  configForm: any;
  editProductConfigItem: any;
  productSimple: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(readonly spinner: NgxSpinnerService,

    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    private _location: Location,

    readonly myContactsService: MyContactsService,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FinancialService: FinancialService,
    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
    readonly _AccountingApService: AccountingApService,
    readonly _WarehouseService: WarehouseService,
    readonly addSkillService: AddSkillService,) { 
      this.dropdownValue=[{
        label: 'Single Choice',
        value: 'SINGLE'
    },
    {
        label: 'Multi Choice',
        value: 'MULTIPLE'
    },]
      
      this.advanceSearch = {
        ConfigItem: "",
        ConfigItemName: "",
        Description: "",
      };
      this.configForm = this._FormBuilder.group({
        ConfigItemType : [''] ,
        ConfigItemName : [''] ,
        Description : [''] ,
      })
    }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
  this.FindConfigItem();
  this.EditProductContent();
  }
  EditProductContent() {
    this.spinner.show();
    this.myContactsService.EditProductConfigItemContentContent("IMAGE_URL", "10082", "11971", "").subscribe(res => {
      this.editProductConfigItem = res.data;
     
    })
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  
  createProductConfigItem() {
    this.spinner.show();
    const formData = {
      "configItemName": this.configForm.value.ConfigItemName,
      "configItemTypeId":  this.configForm.value.ConfigItemType,
      "description":  this.configForm.value.Description,
    }
    this.myContactsService.createProductConfigItem(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.configForm.reset();
        this.closebutton.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  detailPage(id){
    this.router.navigate(['/facilities/catalog/configurations/detail-configurations'],{queryParams:{configItemId:id}});
  }
  FindConfigItem() {
    this.spinner.show();
    let req = {
      "configItemId": "",
      "configItemName": "",
      "description": "",
    }
    this.accountsService.getFindConfigItem(req).subscribe((res: any) => {
      this.congigList = res.data[0].getProductConfigItem;
      this.spinner.hide();
    })
  
  }
  advanceSearchContacts(): void {
    this.spinner.show();
    this.congigList = [];
    const req = {
      "configItemId": this.advanceSearch.ConfigItem ? this.advanceSearch.ConfigItem : '',
      "configItemName": this.advanceSearch.ConfigItemName ? this.advanceSearch.ConfigItemName : '',
      "description": this.advanceSearch.Description ? this.advanceSearch.Description : '',
     
    }
    this.accountsService.getFindConfigItem(req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.congigList = resp.data[0].getProductConfigItem;
        } 
      });
     
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.ConfigItem='';
    this.advanceSearch.ConfigItemName='';
    this.advanceSearch.Description='';
  
    let req = {
      "configItemId": "",
      "configItemName": "",
      "description": "",

    }
    this.accountsService.getFindConfigItem(req).subscribe((res: any) => {
      this.congigList = res.data[0].getProductConfigItem;
      this.spinner.hide();
    })
  
  }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  mainOpen(){
    this.router.navigate(['/facilities/catalog/main-catalog']);
  }
  catalogOpen(){
    this.router.navigate(['/facilities/catalog/catalogs']);
  }
  categoriesOpen(){
    this.router.navigate(['/facilities/catalog/catagories']);
  }
  productsOpen(){
    this.router.navigate(['/facilities/catalog/products']);
  }
  featuresOpen(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  promosOpen(){
    this.router.navigate(['/facilities/catalog/promos']);
  }
  priceRulesOpen(){
    this.router.navigate(['/facilities/catalog/price-rules']);
  }
  storesOpen(){
    this.router.navigate(['/facilities/catalog/stores']);
  }
  productStoreGroupsOpen(){
    this.router.navigate(['/facilities/catalog/product-store-groups']);
  }
  thesaurusOpen(){
    this.router.navigate(['/facilities/catalog/thesaurus']);
  }
  reviewsOpen(){
    this.router.navigate(['/facilities/catalog/reviews']);
  }
  configurationsOpen(){
    this.router.navigate(['/facilities/catalog/configurations']);
  }
  subscriptionsOpen(){
    this.router.navigate(['/facilities/catalog/subscriptions']);
  }
  shippingOpen(){
    this.router.navigate(['/facilities/catalog/shipment']);
  }
  imageManagementOpen(){
    this.router.navigate(['/facilities/catalog/image-management']);
  }

}
