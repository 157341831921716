<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Financial Account</span>
                <span class="color-black pl-1"> >Add New Financial Transaction </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Add New Financial Transaction</a></li>
                      
          
          
                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createTransaction">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Fin Account Trans Type ID
                                                    <span
                                                    style="color:red">*</span>
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  formControlName="finAccountTransTypeId" 
                                                placeholder="Enter Fin Account Trans Type ID" filter="true"
                                                [options]="finTypeArray" optionlabel="label" >
                                                               
                                               </p-dropdown>

                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Customer ID </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown   formControlName="partyId" 
                                                placeholder="Enter Customer ID"  filter="true" 
                                                [options]="partyIdArray" optionlabel="label">
                                                   
                                               </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Gl Reconciliation ID
                                               
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="glReconciliationId"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Gl Reconciliation ID">

                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Transaction Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date"  formControlName="transactionDate"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Transaction Date">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Entry Date</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date"  formControlName="entryDate"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Entry Date">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Amount</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="amount"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Amount">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Payment ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  formControlName="paymentId"  placeholder="Enter Payment ID"  filter="true">
                                                   
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Order ID
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  [options]="orderIdArray" optionlabel="label"  formControlName="orderId" placeholder="Enter Order ID"  filter="true">
                                                   
                                                </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Order Item Seq ID </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="orderItemSeqId" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Order Item Seq ID">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Reason Enum ID </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="reasonEnumId" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Reason Enum ID">
                                           
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Comments</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="comments" class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Comments">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Status
                                                    <span
                                                    style="color:red">*</span>
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="statusId" 
                                                [options]="stausIdArray" optionlabel="label"
                                                placeholder="Enter Status"  filter="true" >
                                               
                                                </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">GL Account
                                                    <span
                                                    style="color:red">*</span>
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="glAccountId"
                                            [options]="giAccountArray" optionlabel="label" 
                                            placeholder="Enter GL Account"  filter="true" >
                                                   
                                            </p-dropdown>
                                            </div>
                                           
                    
                                        </div>
                                    </div>
                                  
                                   
                                 
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button"  style="margin-left: 70%;">
                                <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit"  (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
