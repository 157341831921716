import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from '../../../app.constant';
import { ApiService } from 'src/app/api.service';
import { TrainingApprovalModel } from './training-approval.model';

@Injectable({
  providedIn: 'root'
})
export class TrainingApprovalService {
  constructor(
    readonly http: HttpClient,
    readonly _ApiService: ApiService
  ) { }
  getTrainingApproval(request): Observable<any> {
    const approvalList = URLS.trainingApprovalList + "?pageNo=" + request.pageNo + "&pageSize=" + request.pageSize;
    return this.http.get(approvalList);
  }
  getOverviewList(request,data): Observable<any> {
    const approvalList = URLS.overViewApproveTraining + "?pageNo=" + request.pageNo + "&pageSize=" + request.pageSize;
    return this.http.post(approvalList,data);
  }
  getApprovedTrainingList(pagination: { pageNo: number, pageSize: number }, searchFilter: TrainingApprovalModel): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/hr/training/trainingApproval?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter);
  }
  getTrainingCalendar(pagination: { pageNo: number, pageSize: number }, date: { startDate: string, endDate: string }): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/hr/training?endDateTime=${date.endDate}&pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}&startDateTime=${date.startDate}`);
  }
  createTrainingCalendar(formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/hr/training`, formData);
  }
  updateTrainingCalendar(workEffortId: string, formData: any): Promise<any> {
    return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/hr/training/${workEffortId}`, formData);
  }
  getCommunicationCalender(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crmPartner/getCommunicationCalender`);
    }
    getWorkEffortCalender(formData: any): Promise<any> {
      return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/assetMaintenance/getWorkEffortCalender`, formData);
    }
   
  // getAllDetailsByTechnicianId(technicianId: string): Promise<any> {
  //   return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/fmsModule/getAllDetailsByTechnicianId/${technicianId}`);
  // }
  getCalenderEntries(workEffortId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/assetMaintenance/getCalenderEntries/${workEffortId}`);
  }
  
  getAllDetailsByTechnicianId(technicianId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/fmsModule/getAllDetailsByTechnicianId/?technicianId=${technicianId}`);
  }
  getTrainingCalendarEventById(workEffortId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/hr/training/${workEffortId}`);
  }
  getTrainingClassTypeList(pagination: { pageNo: number, pageSize: number }): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/hr/trainingClassType?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
  }
  assignTraining(formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/hr/training/assignTraining`, formData);
  }
  getCalendarEventById(workEffortId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/hr/training/${workEffortId}`);
  }
}
