import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';


@Component({
  selector: 'app-fsminvoices',
  templateUrl: './fsminvoices.component.html',
  styleUrls: ['./fsminvoices.component.css']
})
export class FsminvoicesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 9;
  activeCategories=2;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  invoiceForm: FormGroup;
  show: boolean;
  invoiceList: any;
  FsmTechnicianFind: any;
  FsmTechnicianCreate: any;
  FsmTechnicianFindData: any;
  formValuenewData: any;
  FsmTechnicianCreateData: any;
  FsmCustomerFind: any;
  FsmCustomerCreate: any;
  FsmCustomerFindData: any;
  FsmCustomerCreateData: any;
  FsmProbliemCreate: any;
  FsmProbliemCreateData: any;
  FsmProblemCreate: any;
  FsmProblemCreateData: any;
  problemId: any;
  technicianId: string;
  customerId: any;
  customerIdNew: any;
  workOrderId: any;
  readOnlyCode=false;
  updateFsmInvoicePat: any;
  createdStamp: string;
  invoiceByIdData: any;

  constructor(
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) 
  {
    this.invoiceForm = this._FormBuilder.group({
      invoiceId:"",
      primaryContactEmail:"",
      workOrderId:"",
      customerId:"",
      customerName:"",
      technicianId:"",
      technicianName:"",
      technicianStatus:"",
      customerProblemDescription:"",
      email:"",
      technicianNumber:"",
      address1:"",
      address2:"",
      city:"",
      countryGeoId:"",
      stateProvinceGeoId:"",
      postalCode:"",
      equipmentRequired:"",
      equipmentRequiredPrice:"",
      directLabourPerBuilt:"",
      equipmentPartsPrice:"",
      subTotal:"",
      discounts:"",
      tax:"",
      total:"",
      primaryContactPrimaryPhone:""
    });
  }

  ngOnInit(): void {
    this.show=true;
    this._ActivatedRoute.queryParams.subscribe(res =>{
      console.log("rescheck",res)
      this.customerId = res["customerId"];
      this.customerIdNew = res["filterDataResutCustoNew"];

    });
    this.getInvoice();
    this.localStoreData();
  }
  getInvoiceById(id) {
    this.spinner.show();
    this.myContactsService.getInvoiceById(id).subscribe(res => {
      this.invoiceByIdData = res.data[0].getInvoiceById[0];
      this.spinner.hide();
    })
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  
  toStaffing(){
    this._Router.navigate(['fsm/staffing']);
  }
  reset(){
    this.show = false;
    this.invoiceForm.reset();
  }
  changeFormClick(){
    this.show=true;
  }
  
  deleteFsmInvoice(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "invoiceId": product.getInvoice.invoiceId

        }
        this.accountsService.deleteFsmInvoice(req).subscribe(res => {
          this.getInvoice();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateFsmInvoice(): void {
    this.spinner.show();
    const requestData ={
      "address1": this.invoiceForm.value.address1 ,
      "address2": this.invoiceForm.value.address2 ,
      "city":  this.invoiceForm.value.city ,
      "countryGeoId":  this.invoiceForm.value.countryGeoId ,
      "customerId":  this.invoiceForm.value.customerId ,
      "customerName":  this.invoiceForm.value.customerName ,
      "customerProblemDescription":  this.invoiceForm.value.customerProblemDescription ,  
      "directLabourPerBuilt":  this.invoiceForm.value.directLabourPerBuilt ,
      "discounts":  this.invoiceForm.value.discounts ,
      "email":  this.invoiceForm.value.email ,
      "equipmentPartsPrice":  this.invoiceForm.value.equipmentPartsPrice ,
      "equipmentRequired":  this.invoiceForm.value.equipmentRequired ,
      "equipmentRequiredPrice":  this.invoiceForm.value.equipmentRequiredPrice ,
      "invoiceId":  this.invoiceForm.value.invoiceId,
      "postalCode":  this.invoiceForm.value.postalCode ,
      "primaryContactEmail":  this.invoiceForm.value.primaryContactEmail ,
      "primaryContactPrimaryPhone":  this.invoiceForm.value.primaryContactPrimaryPhone ,  
      "stateProvinceGeoId":  this.invoiceForm.value.stateProvinceGeoId ,
      "subTotal":  this.invoiceForm.value.subTotal,
      "tax":  this.invoiceForm.value.tax ,
      "technicianId":  this.invoiceForm.value.technicianId ,
      "technicianName":  this.invoiceForm.value.technicianName ,
      "technicianNumber":  this.invoiceForm.value.technicianNumber ,
      "technicianStatus":  this.invoiceForm.value.technicianStatus ,
      "total": this.invoiceForm.value.total,
      "workOrderId": this.invoiceForm.value.workOrderId,
      }
     
    this.myContactsService.updateFsmInvoice(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getInvoice();
        this.closebutton.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateFsmInvoicePatch(product) {
    this.show=false;
    this.updateFsmInvoicePat = product ;
    this.spinner.show();
    const createdStamp=product.createdStamp;
    this.createdStamp=this.datePipe.transform(createdStamp,"yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.invoiceForm;
        formValue.patchValue({
          invoiceId: this.updateFsmInvoicePat.getInvoice.invoiceId,
          primaryContactEmail: this.updateFsmInvoicePat.getInvoice.primaryContactEmail,
          workOrderId: this.updateFsmInvoicePat.getInvoice.workOrderId,
          customerId: this.updateFsmInvoicePat.getInvoice.customerId,
          customerName: this.updateFsmInvoicePat.getInvoice.customerName,
          technicianId: this.updateFsmInvoicePat.getInvoice.technicianId,
          technicianName: this.updateFsmInvoicePat.getInvoice.technicianName,
          technicianStatus: this.updateFsmInvoicePat.technicianStatus[0].technicianStatus,
          customerProblemDescription: this.updateFsmInvoicePat.getInvoice.customerProblemDescription,
          email: this.updateFsmInvoicePat.getInvoice.email,
          technicianNumber: this.updateFsmInvoicePat.getInvoice.technicianNumber,
          address1: this.updateFsmInvoicePat.getInvoice.address1,
          address2: this.updateFsmInvoicePat.getInvoice.address2,
          city: this.updateFsmInvoicePat.getInvoice.city,
          countryGeoId: this.updateFsmInvoicePat.getInvoice.countryGeoId,
          stateProvinceGeoId: this.updateFsmInvoicePat.getInvoice.stateProvinceGeoId,
          postalCode: this.updateFsmInvoicePat.getInvoice.postalCode,
          equipmentRequired: this.updateFsmInvoicePat.getInvoice.equipmentRequired,
          equipmentRequiredPrice: this.updateFsmInvoicePat.getInvoice.equipmentRequiredPrice,
          directLabourPerBuilt: this.updateFsmInvoicePat.getInvoice.directLabourPerBuilt,
          equipmentPartsPrice: this.updateFsmInvoicePat.getInvoice.equipmentPartsPrice,
          subTotal: this.updateFsmInvoicePat.getInvoice.subTotal,
          discounts: this.updateFsmInvoicePat.getInvoice.discounts,
          tax: this.updateFsmInvoicePat.getInvoice.tax,
          total: this.updateFsmInvoicePat.getInvoice.total,
          primaryContactPrimaryPhone: this.updateFsmInvoicePat.getInvoice.primaryContactPrimaryPhone,
        })
      }, 2000);
      this.spinner.hide();
  }
  createFsmInvoicePatch() {
    this.spinner.show();
    this.show=false;
    setTimeout(() => {
      const formValue = this.invoiceForm;
      formValue.patchValue({
          invoiceId:"",
          primaryContactEmail: this.FsmCustomerFindData[0].fsmCustomer.primaryContactEmail ? this.FsmCustomerFindData[0].fsmCustomer.primaryContactEmail : this.FsmCustomerCreateData.primaryContactEmail,
          workOrderId: this.workOrderId ? this.workOrderId: "N/A",
          customerId: this.FsmCustomerFindData[0].fsmCustomer.customerId ? this.FsmCustomerFindData[0].fsmCustomer.customerId : this.customerId,
          customerName: this.FsmCustomerFindData[0].fsmCustomer.customerName ? this.FsmCustomerFindData[0].fsmCustomer.customerName : this.FsmCustomerCreateData.customerName,
          technicianId: this.FsmTechnicianFindData[0].data.technicianId ? this.FsmTechnicianFindData[0].data.technicianId : this.technicianId,
          technicianName: this.FsmTechnicianFindData[0].data.technicianName ? this.FsmTechnicianFindData[0].data.technicianName : this.FsmTechnicianCreateData.technicianName,
          technicianStatus: this.FsmTechnicianFindData[0].data.technicianStatus ? this.FsmTechnicianFindData[0].data.technicianStatus : this.FsmTechnicianCreateData.technicianStatus,
          customerProblemDescription: this.FsmProblemCreateData.dec ? this.FsmProblemCreateData.dec: "N/A",
          email: this.FsmTechnicianFindData[0].data.email ? this.FsmTechnicianFindData[0].data.email : this.FsmTechnicianCreateData.email,
          technicianNumber: this.FsmTechnicianFindData[0].data.technicianNumber ? this.FsmTechnicianFindData[0].data.technicianNumber : this.FsmTechnicianCreateData.technicianNumber,
          address1: this.FsmCustomerFindData[0].fsmCustomer.address1 ? this.FsmCustomerFindData[0].fsmCustomer.address1 : this.FsmCustomerCreateData.address1,
          address2: this.FsmCustomerFindData[0].fsmCustomer.address2 ? this.FsmCustomerFindData[0].fsmCustomer.address2 : this.FsmCustomerCreateData.address2,
          city: this.FsmCustomerFindData[0].fsmCustomer.city ? this.FsmCustomerFindData[0].fsmCustomer.city : this.FsmCustomerCreateData.city,
          countryGeoId: this.FsmCustomerFindData[0].fsmCustomer.countryGeoId ? this.FsmCustomerFindData[0].fsmCustomer.countryGeoId : this.FsmCustomerCreateData.countryGeoId,
          stateProvinceGeoId: this.FsmCustomerFindData[0].fsmCustomer.stateProvinceGeoId ? this.FsmCustomerFindData[0].fsmCustomer.stateProvinceGeoId : this.FsmCustomerCreateData.stateProvinceGeoId,
          postalCode: this.FsmCustomerFindData[0].fsmCustomer.postalCode ? this.FsmCustomerFindData[0].fsmCustomer.postalCode : this.FsmCustomerCreateData.postalCode,
          equipmentRequired:"",
          equipmentRequiredPrice:"",
          directLabourPerBuilt:"",
          equipmentPartsPrice:"",
          subTotal:"",
          discounts:"",
          tax:"",
          total:"",
          primaryContactPrimaryPhone: this.FsmCustomerFindData[0].fsmCustomer.primaryContactPrimaryPhone ? this.FsmCustomerFindData[0].fsmCustomer.primaryContactPrimaryPhone : this.FsmCustomerCreateData.primaryContactPrimaryPhone,
      })

    }, 2000);
    this.spinner.hide();
  }
  localStoreData(){
    this.FsmTechnicianFind = localStorage.getItem('FsmTechnicianFind');
    this.FsmTechnicianCreate = localStorage.getItem('FsmTechnicianCreate');
    this.FsmCustomerFind = localStorage.getItem('FsmCustomerFind');
    this.FsmCustomerCreate = localStorage.getItem('FsmCustomerCreate');
    this.FsmProblemCreate = localStorage.getItem('FsmProblemCreate');

    this.problemId = localStorage.getItem('problemId');
    this.technicianId = localStorage.getItem('technicianId');
    this.workOrderId = localStorage.getItem('workOrderId');
    this.customerId = localStorage.getItem('customerId');

    this.FsmTechnicianFindData = JSON.parse(`${this.FsmTechnicianFind}`);
    this.FsmTechnicianCreateData = JSON.parse(`${this.FsmTechnicianCreate}`);
    this.FsmCustomerFindData = JSON.parse(`${this.FsmCustomerFind}`);
    this.FsmCustomerCreateData = JSON.parse(`${this.FsmCustomerCreate}`);
    this.FsmProblemCreateData = JSON.parse(`${this.FsmProblemCreate}`);


    console.log("problemId=>",this.problemId);
    console.log("customerId=>",this.customerId);
    console.log("workOrderId=>",this.workOrderId);
    console.log("technicianId=>",this.technicianId);
    console.log("FsmTechnicianFindData=>",this.FsmTechnicianFindData);
    console.log("FsmTechnicianCreateData=>",this.FsmTechnicianCreateData);
    console.log("FsmCustomerFindData=>",this.FsmCustomerFindData);
    console.log("FsmCustomerCreateData=>",this.FsmCustomerCreateData);
    console.log("FsmProblemCreateData=>",this.FsmProblemCreateData);

  }

  createFsmInvoice() {
    this.spinner.show();
    const requestData = 
    {
      "address1": this.invoiceForm.value.address1 ,
      "address2": this.invoiceForm.value.address2 ,
      "city":  this.invoiceForm.value.city ,
      "countryGeoId":  this.invoiceForm.value.countryGeoId ,
      "customerId":  this.invoiceForm.value.customerId ,
      "customerName":  this.invoiceForm.value.customerName ,
      "customerProblemDescription":  this.invoiceForm.value.customerProblemDescription ,  
      "directLabourPerBuilt":  this.invoiceForm.value.directLabourPerBuilt ,
      "discounts":  this.invoiceForm.value.discounts ,
      "email":  this.invoiceForm.value.email ,
      "equipmentPartsPrice":  this.invoiceForm.value.equipmentPartsPrice ,
      "equipmentRequired":  this.invoiceForm.value.equipmentRequired ,
      "equipmentRequiredPrice":  this.invoiceForm.value.equipmentRequiredPrice ,
      "invoiceId":  "" ,
      "postalCode":  this.invoiceForm.value.postalCode ,
      "primaryContactEmail":  this.invoiceForm.value.primaryContactEmail ,
      "primaryContactPrimaryPhone":  this.invoiceForm.value.primaryContactPrimaryPhone ,  
      "stateProvinceGeoId":  this.invoiceForm.value.stateProvinceGeoId ,
      "subTotal":  '',
      "tax":  this.invoiceForm.value.tax ,
      "technicianId":  this.invoiceForm.value.technicianId ,
      "technicianName":  this.invoiceForm.value.technicianName ,
      "technicianNumber":  this.invoiceForm.value.technicianNumber ,
      "technicianStatus":  this.invoiceForm.value.technicianStatus ,
      "total": '',
      "workOrderId": this.workOrderId,
    }  
    this.myContactsService.createFsmInvoice(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.invoiceForm.reset();
        this.getInvoice();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getInvoice() {
    this.spinner.show();
    this.myContactsService.getInvoice().subscribe(res => {
      this.invoiceList = res.data;
      this.spinner.hide();
    })
  }
  fsmInvoiceGenerate(product) {
    if(product.technicianStatus[0].technicianStatus == "Completed"){
      this.spinner.show();
      this.myContactsService.fsmInvoiceGenerate(product.getInvoice.invoiceId).subscribe(res => {
        const blob = new Blob([res], { type:'invoice/pdf'});
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob);
          return;
        }
        const data = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = data;
        this.spinner.hide();
        link.download = "Invoice";
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(() => {
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });
    }
    else{
      this.toastr.error("Technician Status On Hold");
    }
   

  }
  deletePriceListGroup(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        const req = {
          priceListGroupId : product.priceListGroupId
        }
        this.myContactsService.deletePriceListGroup(product).subscribe(res => {
          this.getInvoice();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

 toCustomer()
  {
    this._Router.navigate(['fsm/customer'])
  }
  
  toReports()
  {
    this._Router.navigate(['fsm/reports'])
  }

  toInvoices()
  {
    this._Router.navigate(['fsm/invoices'])
  }

  toWorkOrder()
  {
    this._Router.navigate(['/fsm/main'])
  }

  toPeople()
  {
    this._Router.navigate(['/fsm/people'])
  }

  toTimeSheet()
  {
    this._Router.navigate(['/fsm/timesheet'])
  }

  toSetting()
  {
    this._Router.navigate(['/fsm/fsmSetting'])
  }

}