import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class OppourtunitiesService {

  constructor(readonly http: HttpClient) { }

  getCurrency(): Observable<any> {
    const getCurrency = URLS.getCurrency;
    return this.http.get(getCurrency);
  }
  getOpportunityLists(): Observable<any> {
    const getOppourtunityList = URLS.getOppourtunityList;
    return this.http.get(getOppourtunityList)
  }
  getAccountList(all,searchAccountForm):Observable<any>{
		const getAccountList = URLS.getAccountsList + "?all=" + all.all + "&pageNo=" + all.pageNo + "&pageSize=" + all.pageSize;
		return this.http.post(getAccountList,searchAccountForm)
    }
    closeoppourtunity(id):Observable<any>{
      const closeOppourtunity = URLS.closeOpportunity.replace(":opportunityId",id);
      return this.http.post(closeOppourtunity,"");
    }
  getOppourtunityDetails(id): Observable<any> {
    const getOppourtunityDetail = URLS.getOppourtunitySummary.replace(':oppurtunityId',id);
    return this.http.get(getOppourtunityDetail);
  }
  getLeadsList(data,formData):Observable<any> {
    const getLeadsList = URLS.getLeads + "?pageSize=" + data.pageSize + "&pageNo=" + data.pageNo +"&isMyLead=" + data.isMyLead;
    return this.http.post(getLeadsList,formData);
}

  createOppourtunity(formData): Observable<any> {
   
    const createOppourtunity = URLS.createOpportunity;
    return this.http.post(createOppourtunity,formData)
  }

  getInitialStage(): Observable<any> {
    const getInitialStage = URLS.initialStage;
    return this.http.get(getInitialStage);
  }


  updateOppourtunity(formData,id) :Observable<any> {
   
    const updateOppourtunity = URLS.updateProduct.replace(':oppurId',id);
    return this.http.put(updateOppourtunity,formData);
  }


  searchOppourtunity(request):Observable<any> {
    const searchOppourtunity = URLS.searchOpportunity;
    return this.http.post(searchOppourtunity,request);
  }







}
