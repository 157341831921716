import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-detail-reason-type',
  templateUrl: './detail-reason-type.component.html',
  styleUrls: ['./detail-reason-type.component.css']
})
export class DetailReasonTypeComponent implements OnInit {

  activeCategory=1;
  skillType: any;
  Des: any;

  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
  ) { }
 
  ngOnInit(): void {
  
    this.activatedRoute.queryParams.subscribe(params=> [
      this.skillType = params["leaveType"],
      this.Des=params["des"],
    
    ])
 
  }
  cancelSubmit(){
    this.router.navigate(["/hr/global-settings"])
  }

}

