<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class=" bg-white color-grey create-new-table">
                <div>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                                <div class="w-100">
                                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                        <div class="panel panel-default">
                                            <div class="panel-heading" role="tab" id="headingOne">
                                                <h4 class="panel-title port-header d-flex justify-content-between"
                                                    style="background:#0d3769">
                                                    <a class=" w-100 position-relative  tabs-header"
                                                        style="display: flex !important; justify-content: space-between;">
                                                        Receive Offline Payment(s)
                                                        <div class="icon-wrapper sales-properties custom-properties">
                                                            <span class="arrow-toggle toggle-arrow detail-created"
                                                                data-toggle="collapse" href="#seo-collapse">
                                                            </span>
                                                        </div>
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="seo-collapse"
                                                class="port-body custom-group-wrapper collpase show inner-sections">
                                                <div class="panel-body">
                                                    <div class="mt-2">
                                                        <div class="">
                                                            <div class="row">
                                                                <form class="w-100">
                                                                    <div class="col-lg-12 col-12 main-submit-button">
                                                                        <button type="submit"
                                                                            class="btn btn-secondary submit-btn cursor-pointer"
                                                                            routerLinkActive="active"
                                                                            (click)="navigation()">Back
                                                                            To Order</button>
                                                                        <button type="submit"
                                                                            (click)="createReceivePayment()"
                                                                            class="btn btn-secondary submit-btn cursor-pointet ml-2">Save</button>
                                                                    </div>
                                                                    <div
                                                                        class="container all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="table-responsive">
                                                                            <table class="table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">Payment Method
                                                                                        </th>
                                                                                        <th scope="col">Amount</th>
                                                                                        <th scope="col">Reference</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <ng-template ngFor let-payMethod
                                                                                        [ngForOf]="receivePaymentMethods?.paymentMethods"
                                                                                        let-index="index">
                                                                                        <tr>
                                                                                            <td>{{payMethod.paymentMethodId}}
                                                                                            </td>
                                                                                            <td>
                                                                                                <input type="text"
                                                                                                    [id]="index+'amount'"
                                                                                                    [(ngModel)]="paymentMethods[payMethod.paymentMethodId+'_amount']"
                                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                                    class="form-control">
                                                                                            </td>
                                                                                            <td> <input type="text"
                                                                                                    [id]="index+'reference'"
                                                                                                    [(ngModel)]="paymentMethods[payMethod.paymentMethodId+'_reference']"
                                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                                    class="form-control">
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </tbody>
                                                                            </table>
                                                                            <hr>
                                                                            <table class="table mt-4">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th scope="col">Payment Type
                                                                                        </th>
                                                                                        <th scope="col">Amount</th>
                                                                                        <th scope="col">Reference</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <ng-template ngFor let-payType
                                                                                        [ngForOf]="receivePaymentMethods?.paymentMethodTypes"
                                                                                        let-index="index">
                                                                                        <tr>
                                                                                            <td>{{payType.description}}
                                                                                            </td>
                                                                                            <td>
                                                                                                <input type="text"
                                                                                                    [(ngModel)]="paymentTypes[payType.paymentMethodTypeId+'_amount']"
                                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                                    [id]="index+'amount'"
                                                                                                    class="form-control">
                                                                                            </td>
                                                                                            <td> <input type="text"
                                                                                                    [(ngModel)]="paymentTypes[payType.paymentMethodTypeId+'_reference']"
                                                                                                    [ngModelOptions]="{standalone:true}"
                                                                                                    [id]="index+'reference'"
                                                                                                    class="form-control">
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12 col-12 main-submit-button">
                                                                        <button type="submit"
                                                                            (click)="createReceivePayment()"
                                                                            class="btn btn-secondary submit-btn">Save</button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <ngx-spinner></ngx-spinner> 