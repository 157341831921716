import { Component, OnInit } from '@angular/core';
import {ApprovalService} from "./approval.service";
import { SortEvent } from 'primeng/api';
import { AddSkillService } from '../add-skills/add-skill.service';
import { DatePipe } from '@angular/common';
import { JobRequistionService } from '../recuitment/job-requisition/job-requisition.service';
import { NewJobRequistionsService } from '../recuitment/new-job-requisition/new-job-requisition.service';
import { CreateInternalService } from '../recuitment/create-internal-job-posting/create-internal-job.service';
import { InternalJobService } from '../recuitment/internal-job-posting/internal-job-posting.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup ,Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

import { Location } from '@angular/common';

@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.css']
})
export class ApprovalComponent implements OnInit {
  pageNo=1;
  activeCateg=2;
  activeCategoryMain=2;
  total=0;
  rows = 50;
  pageSize=100;
  search="";
  show: boolean;

  public size = {
    pageNo:this.pageNo,
    pageSize:this.pageSize
  }
    public jobPost = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search

  }
  advanceSearch: {
    applicationDateFrom:string,
    applicationDateFromSearchType:string,
    applicationDateTo: string,
    applicationDateToSearchType: string,
    applicationId:string,
    applyingPartyId:string,

    approverPartyId:string,

    jobRequisitionId: string,
    statusId: string,


  };
  updateApprovalForm: FormGroup;

  approvalId: any;
  approveArray: any=[];
  personId: any;
  partyIdArray: any=[];
  dateIdArray: { label: string; value: string; }[];
  jobRequestion: any;
  internalJobPostList: any;
  appIdArray: any=[];
  rIdArray: any=[];
  approveParty: any;
  approveData: any=[];
  approveStatus: any;
  status: any=[];
  activeCategory = 2;
  statusIds: any[];
  updateValue: {
      applicationDate: string,
      approverPartyId: string,
      jobRequisitionId: string,
      applicationId: string,
      statusId: string
  };
  constructor(readonly approvalService:ApprovalService ,readonly datePipe: DatePipe,
    public addSkillService:AddSkillService,
    readonly router: Router,
    readonly jobRequistionService:JobRequistionService,
    readonly spinner:NgxSpinnerService,
    readonly newJobRequistionsService:NewJobRequistionsService,
     readonly internalJobService:InternalJobService,
    readonly createInternalService:CreateInternalService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
      private _location: Location,
      readonly  _Router: Router,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ApprovalService: ApprovalService,
    ) { 
      this.dateIdArray = [
        {
          label: 'Less Than',
          value: 'LessThan'
        },
        {
          label: 'Equals',
          value: 'Equals'
        },
       
        {
          label: 'Greater Than',
          value: 'GreaterThan'
        },
        {
          label:'Greater Than Equal To',
          value:'GreaterThanEqualTo'
        },
        {
          label:'Less Than Equal To',
          value:'LessThanEqualTo'
        },
        {
          label:'Is Empty',
          value:'IsEmpty'
        }
       
      ];
      this.advanceSearch = {
        applicationDateFrom: "",
        applicationDateFromSearchType: "Equals",
        applicationDateTo: "",
        applicationDateToSearchType: "Equals",
        applicationId: "",
        applyingPartyId: "",
        approverPartyId: "",
        jobRequisitionId: "",
        statusId: "",
      };
      this.updateApprovalForm = this._FormBuilder.group({
        applicationDate: [''],
        approverPartyId: [''],
        jobRequisitionId: [''],
        statusId: ['', [Validators.required]]
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getApprovalList();
    this.getPartyId();
    this.getJobRequistionListDropdown();
    this.getInternalJobDropdwon();
    this.getApproverParty();
    this.getStatus();
  
  this.getStatusIds();
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getStatusIds(): void {
    this.spinner.show();
    this._ApprovalService.getApprovalStatus()
        .then(data => {
            this.spinner.hide();
            if (data.success) {
                this.statusIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.statusId
                    };
                });
            }
        });
       
}
updateApp(product){
  this.updateValue = product;
  setTimeout(() => {
    const form = this.updateApprovalForm;
    form.patchValue({
      applicationDate: product.applicationDate ? moment(Number(product.applicationDate)).format('YYYY-MM-DD HH:mm:ss') : '',
      applicationId: product.applicationId,
      approverPartyId: product.approverPartyId ? product.approverPartyId : '',
      jobRequisitionId: product.jobRequisitionId ? product.jobRequisitionId : '',
      statusId: product.statusId ? product.statusId : ''
    })
  }, 1000);

}
update(): void {
    this.spinner.show();
    if (this.updateApprovalForm.valid) {
        this._ApprovalService.updateApproval(this.updateValue.applicationId, this.updateApprovalForm.value)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.cancelSubmit();
                    this.spinner.hide();
                }
            });
    }
    this.spinner.hide();
}

  detailPage(id){
    this.router.navigate(["/hr/job-requisition/approval-summary"],{queryParams:{interviewId:id}})
   
  }
  getStatus(){
    this.spinner.show();
    this.internalJobService.getStatus().subscribe((res)=> {
      this.approveStatus = res.data;
      this.spinner.hide();
      this.approveStatus.forEach(data=> {
        this.status.push({
          label:data.description,
          value:data.statusId
        })
        
      })
    })
   
  }
  getApproverParty(){
    this.spinner.show();
    this.createInternalService.getApproverParty().subscribe(res=> {
      this.approveParty = res.data.PersonsGroups;
      this.spinner.hide();
      this.approveParty.forEach(data=> {
        this.approveData.push({
          label:data.partyId,
          value:data.partyId
        })
        
      })
    })
   
  }
  JobRequisition() {
    this.router.navigate(["/hr/job-requisition"])
  }
  InternalJobPostingApplication() {
    this.router.navigate(["/hr/job-requisition/internal-job-posting"])
  }
  jobInterview() {
    this.router.navigate(["/hr/job-requisition/job-interview"])
  }
  approval() {
    this.router.navigate(["/hr/job-requisition/approval"])
  }
  Relocation() {
    this.router.navigate(["/hr/job-requisition/reAllocations"])
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res=> {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.personId) {
        this.partyIdArray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
   
  }
  getJobRequistionListDropdown() {
    this.spinner.show();
    const jobRequistion = {
      examTypeEnumId: "",
      experienceMonthSearchType: "Equals",
      experienceMonthSecond: "",
      experienceMonthSecondSearchType: "Equals",
      experienceMonths: "",
      experienceYearSecond: "",
      experienceYearSecondSearchType: "Equals",
      experienceYears: "",
      experienceYearsSearchType: "Equals",
      jobLocation: "",
      jobLocationSearchType: "Contains",
      jobPostingTypeEnumId: "",
      jobRequisitionId: "",
      skillTypeId: ""
    }
     this.jobRequistionService.getJobRequistionList(jobRequistion).subscribe(res=> {
       this.jobRequestion=res.data;
       this.spinner.hide();
       for(const value of  this.jobRequestion){
        this.rIdArray.push({
          label:value.jobRequisitionId,
          value:value.jobRequisitionId
        })
    }
     })
   }
   getInternalJobDropdwon() {
    this.spinner.show();
    const jobList ={
      applicationDateFrom: "",
      applicationDateTo: "",
      applicationId: "",
      applyingPartyId: "",
      approverPartyId: "",
      jobRequisitionId: "",
      statusId: "",
      applicationDateFromSearchType: "Equals",
      applicationDateToSearchType: "Equals"
    }
   this.internalJobService.getInternalJobList(this.jobPost,jobList).subscribe((res:any)=> {
     this.internalJobPostList = res.data;
     this.spinner.hide();
     for(const value of  this.internalJobPostList){
      this.appIdArray.push({
        label:value.applicationId,
        value:value.applicationId
      })
  }
   })
  
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  getApprovalList(){
    this.spinner.show();
    const data= {
      applicationDateFrom: "",
        applicationDateFromSearchType: "Equals",
        applicationDateTo: "",
        applicationDateToSearchType: "Equals",
        applicationId: "",
        applyingPartyId: "",
    
        approverPartyId: "",
   
        jobRequisitionId: "",
        statusId: "",
    
    }

    
    this.approvalService.getInternalJobList(this.size,data).subscribe((res:any)=> {
      this.approvalId = res.data;
      this.spinner.hide();
      this.approveArray.push({
        id:this.approvalId
      })
    })
   
  }
reset(){
  this.spinner.show();
  this.advanceSearch.statusId="";
  this.advanceSearch.applicationDateFrom="";
  this.advanceSearch.applicationDateTo="";
  this.advanceSearch.applicationId="";
  this.advanceSearch.applyingPartyId="";
  this.advanceSearch.approverPartyId="";
  this.advanceSearch.jobRequisitionId="";
  const data= {
    applicationDateFrom: "",
        applicationDateFromSearchType: "Equals",
        applicationDateTo: "",
        applicationDateToSearchType: "Equals",
        applicationId: "",
        applyingPartyId: "",
    
        approverPartyId: "",
   
        jobRequisitionId: "",
        statusId: "",
    
  }

  
  this.approvalService.getInternalJobList(this.size,data).subscribe((res:any)=> {
    this.approvalId = res.data;
    this.spinner.hide();
    this.approveArray.push({
      id:this.approvalId
    })
  })
}
advanceSearchContacts(isPopUp: boolean): void {
  this.spinner.show();
  const data = {

    "applicationDateFrom": this.datePipe.transform(this.advanceSearch.applicationDateFrom,"yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.applicationDateFrom,"yyyy-MM-dd hh:mm:ss") : '',
      "applicationDateTo":  this.datePipe.transform( this.advanceSearch.applicationDateTo,"yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform( this.advanceSearch.applicationDateTo,"yyyy-MM-dd hh:mm:ss") : '',

      "applicationDateFromSearchType":  this.advanceSearch.applicationDateFromSearchType ? this.advanceSearch.applicationDateFromSearchType : '',
      "applicationDateToSearchType":  this.advanceSearch.applicationDateToSearchType ? this.advanceSearch.applicationDateToSearchType : '',
      "applicationId":  this.advanceSearch.applicationId ? this.advanceSearch.applicationId : '',
      "applyingPartyId":  this.advanceSearch.applyingPartyId ? this.advanceSearch.applyingPartyId : '',
      "approverPartyId":  this.advanceSearch.approverPartyId ? this.advanceSearch.approverPartyId : '',
      "jobRequisitionId":  this.advanceSearch.jobRequisitionId ? this.advanceSearch.jobRequisitionId : '',
      "statusId":  this.advanceSearch.statusId ? this.advanceSearch.statusId : '',



  }
  
  this.approvalService.getInternalJobList(this.size,data).subscribe((res:any)=> {
    this.approvalId = res.data;
    this.spinner.hide();
  })}
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
