<div class="container-fluid main-container-wrapper" style="padding: 0;">

  <div class=" bg-white color-grey">
      <div class="port-header-contact create-lead font-medium mb-0">
         <span class="color-black pl-1 titlePanels">Warehouse Dashboard
          </span>

          <span class="">

            <button type="submit" (click)="homeButton();"
                class=" btn btn-outline-secondary mr-2">Home</button>
    
            <button type="submit" (click)="cancelSubmit();"
                class="btn btn-danger buttonclass ">Back</button>
        </span>
      </div>
      <div class="w3-card-4 classCard m-2" >
          <div class="container-fluid">
              <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                  <li [ngClass]="activeCat==0?'active':''" (click)="dashboardOpen()">
                      <a>Warehouse Dashboard</a>
                  </li>
                  <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                      <a>Facility</a>
                  </li>
                  <li [ngClass]="activeCat==2?'active':''" (click)="facilityGroupOpen()">
                      <a>Facility Group</a>
                  </li>
                  <li [ngClass]="activeCat==3?'active':''" (click)="inventoryItemOpen()">
                      <a>Inventory Item</a>
                  </li>
                  <li [ngClass]="activeCat==4?'active':''" (click)="ShippingOpen()">
                      <a>Shipping</a>
                  </li>
              </ul>
          </div>
          <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
              <div class="w-100">
                  

                  <div class="create-new-leade mt-2">
                      <div class="container-fluid">

                          <div class="panel-group">
                              <div *ngIf="activeCategory==0">
                                <div class=" bg-white color-grey">
            

                                  <div>
                                      <div class="container-fluid">
                                          <div class="row" >
                                         
                                        
                                        <form class="col-md-6" style="padding: 0;">
                                              
                                            <div class="w-100">
                                              <div class="panel-group">
                                                <div>
                                                  <div style="width: 100%; float:left">
                                                    <div class="w3-card-4 cardEffect" >
                                                    <header class="w3-container w3-blue" style="filter: brightness(1.16);
                                                    background: lightgray!important;">
                                                      <div class="header-tabs">
                                                        <h4 class=" common-styling" style="margin-left: 7px;">
                                                          Facility Group
                                                        </h4>
                                                     
                                                      </div>
                                                    </header>
                                                
                                                    <div class="w3-container">
                                                      <div class="panel-body" style="background: white;">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                          <div class="color-black container">
                                                            <div  class="row">
                                                              <div class="article-container">
                                                                <div class="article">
                                                                  <span style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-7 col-12" routerLink="/facilities/facility-group" >Create New Facility Group</span>

                                                                </div>
                                                              
               
                                                              </div>
                                                            
                                                              
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                
                                                   
                                                  </div>
                                                 
                                                 </div>
                                          
                                                 
                                                 
                                                
                                              
                  
                                            </div>
                                            </div>
                                          </div>
                  
                                        </form>
                                        <form class="col-md-6">
                                              
                                          <div class="w-100">
                                            <div class="panel-group">
                                              <div>
                                                <div style="width: 100%; float:left">
                                                  <div class="w3-card-4 cardEffect" >
                                                  <header class="w3-container w3-blue" style="filter: brightness(1.16);
                                                  background: lightgray!important;">
                                                    <div class="header-tabs">
                                                      <h4 class=" common-styling" style="margin-left: 7px;">
                                                        Inventory Item
                                                      </h4>
                                                   
                                                    </div>
                                                  </header>
                                              
                                                  <div class="w3-container">
                                                    <div class="panel-body" style="background: white;">
                                                      <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="color-black container">
                                                          <div  class="row">
                                                            <div class="article-container">
                                                              <div class="article">
                                                                <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-7 col-12" routerLink="/facilities/inventory-labels" >Inventory Item</p>
                                                                <span   class="color-grey pl-20 col-lg-7 col-12" style="cursor: pointer;    color: #0000FF;" routerLink="/facilities/inventory-labels/label-types">Label Types</span>

                                                              </div>
                                                            
             
                                                            </div>
                                                          
                                                            
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                              
                                                 
                                                </div>
                                               
                                               </div>
                                        
                                               
                                               
                                              
                                            
                
                                          </div>
                                          </div>
                                        </div>
                
                                        </form>
                                        <form class="col-md-6 mt-3" style="padding: 0;">
                                              
                                          <div class="w-100">
                                            <div class="panel-group">
                                              <div>
                                                <div style="width: 100%; float:left">
                                                  <div class="w3-card-4 cardEffect" >
                                                  <header class="w3-container w3-blue" style="filter: brightness(1.16);
                                                  background: lightgray!important;">
                                                    <div class="header-tabs">
                                                      <h4 class=" common-styling" style="margin-left: 7px;">
                                                        Facility
                                                      </h4>
                                                   
                                                    </div>
                                                  </header>
                                              
                                                  <div class="w3-container">
                                                    <div class="panel-body" style="background: white;">
                                                      <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="color-black container">
                                                          <div  class="row">
                                                            <div class="article-container">
                                                              <div class="article">
                                                                <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-7 col-12" (click)="referToMain(1)">Contact Information</p>
                                                                <span  (click)="referToMain(2)" class="color-grey pl-20 col-lg-7 col-12" style="cursor: pointer;    color: #0000FF;" >Locations</span>
                                                                <span  (click)="referToMain(3)" class="color-grey pl-20 col-lg-7 col-12" style="cursor: pointer;    color: #0000FF;" >Inventory</span>

                                                              </div>
                                                              <div class="article">
                                                                <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-7 col-12" (click)="referToMain(4)">Inventory Items</p>
                                                                <span  (click)="referToMain(5)" class="color-grey pl-20 col-lg-7 col-12" style="cursor: pointer;    color: #0000FF;" >Inventory Items Details</span>
                                                                <span  (click)="referToMain(6)" class="color-grey pl-20 col-lg-7 col-12" style="cursor: pointer;    color: #0000FF;" >Receive Inventory</span>

                                                              </div>
                                                              <div class="article">
                                                                <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-7 col-12" (click)="referToMain(7)">Physical Inventory</p>
                                                                <span  (click)="referToMain(8)" class="color-grey pl-20 col-lg-7 col-12" style="cursor: pointer;    color: #0000FF;" >Inventory Xfers</span>
                                                                <span  (click)="referToMain(9)" class="color-grey pl-20 col-lg-7 col-12" style="cursor: pointer;    color: #0000FF;" >Receive Return</span>

                                                              </div>
                                                              <div class="article">
                                                                <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-7 col-12" (click)="referToMain(10)">Picking</p>
                                                                <span  (click)="referToMain(11)" class="color-grey pl-20 col-lg-7 col-12" style="cursor: pointer;    color: #0000FF;" >Stock Moves</span>
                                                                <span  (click)="referToMain(12)" class="color-grey pl-20 col-lg-7 col-12" style="cursor: pointer;    color: #0000FF;" >Manage Picklists</span>

                                                              </div>
                                                              <div class="article">
                                                                <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-7 col-12" (click)="referToMain(13)">Verify Pick</p>
                                                                <span  (click)="referToMain(14)" class="color-grey pl-20 col-lg-7 col-12" style="cursor: pointer;    color: #0000FF;" >Packing</span>
                                                                <span class="color-grey pl-20 col-lg-7 col-12" style="cursor: pointer;    color: #0000FF;" routerLink="/facilities/warehouse" >Create New Facility</span>

                                                              </div>
             
                                                            </div>
                                                          
                                                            
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                              
                                                 
                                                </div>
                                               
                                               </div>
                                        
                                               
                                               
                                              
                                            
                
                                          </div>
                                          </div>
                                        </div>
                
                                      </form>
                                    
                                          </div>
                                        </div>
                                    
                  
                                  </div>
                  
                              </div>
                              </div>
                          



                          </div>


                      </div>
                  </div>
              </div>
          </div>
      </div>

  </div>
</div>

<ngx-spinner></ngx-spinner>

