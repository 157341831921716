import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { DatePipe,Location } from '@angular/common';
@Component({
    selector: 'app-account-additional-details',
    templateUrl: './create-account-additional-details.component.html'
})
export class CreateAccountAdditionalDetailsComponent implements OnInit {
    additionalDetailForm: FormGroup;
    partyId: string;
    editMode: boolean;
    additionalInfo: any;
    constructor(
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _ToastrService: ToastrService,
        readonly _FormBuilder: FormBuilder,
        readonly  _Router: Router,private _location: Location,
        readonly spinner:NgxSpinnerService,
        readonly _CRMAccountService: CRMAccountService
    ) {
        this.partyId = '';
        this.editMode = false;
        this.additionalDetailForm = this._FormBuilder.group({
            infoType: [''],
            partyId: [''],
            prefeferredSendMethod: [''],
            priceLevel: [''],
            rep: [''],
            resaleNo: [''],
            terms: ['']
        });
    }
    ngOnInit(): void {
        this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
        this.editMode = this._ActivatedRoute.snapshot.queryParams.isEditable === 'true' ? true : false;
        if (this.editMode) {
            this.spinner.show();
            this._CRMAccountService.getAccountById(this.partyId)
                .then(data => {
                    if (data.success) {
                        this.spinner.hide();
                        this.additionalInfo = data.data.additionalInformation;
                        this.additionalDetailForm.patchValue({
                            ...data.data.additionalInformation
                        });
                    }
                });
              
        }
    }

    update(): void {
        this.spinner.show();
        this._CRMAccountService.updateAdditionalDetail({
            ...this.additionalInfo, ...this.additionalDetailForm.value, ...{
                partyId: this.partyId
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.cancelSubmit();
                    this.spinner.hide();
                }
            });
          
    }
    submit(): void {
        this.spinner.show();
        this._CRMAccountService.createAdditionalDetail({
            ...this.additionalDetailForm.value, ...{
                partyId: this.partyId
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Created');
                    this.cancelSubmit();
                    this.spinner.hide();
                }
            });
           

    }

    cancelSubmit(): void {
        this.additionalDetailForm.reset();
        this._location.back();
    }
}