<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="bg-white color-grey w-100 mt-2">
                <div class="container-fluid">
                    <div class="row">
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div class="d-flex bd-highlight justify-content-between port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Find Requirement
                                    <span class="">
 
                                        <button type="submit" (click)="homeButton();"
                                            class=" btn btn-outline-secondary mr-2">Home</button>
                                
                                        <button type="submit" (click)="cancelSubmit();"
                                            class="btn btn-danger buttonclass ">Back</button>
                                    </span> 
                                </div>
                                <div class="create-new-leade">
                                    <div class="container-fluid" style="padding: 0;">
                                        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div class="w-100">
                                                <div class="w3-card-4 classCard w-100 mt-2">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                        style="justify-content: space-between;">
                                                        <p class="h-text">Summary</p>
                                                        <span>
                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                style="margin: 0;" data-toggle="modal"
                                                                data-target="#updatePopup">Update</button>
                                                            
                                                        </span>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        
                                                        <div class="color-black container">
                                                            <div class="row" *ngIf="requirementPlanningById">
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Requirement Type Id :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">
                                                                                {{requirementPlanningById.requirementTypeId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Facility Id
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.facilityId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Deliverable Id
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.deliverableId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Fixed Asset Id
                                                                                :</p>
                                                                             <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.fixedAssetId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Product Id
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.productId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Status Id
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.statusId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Description:</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.description}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Requirement Start Date
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.requirementStartDate | date:"yyyy-MM-dd hh:mm:ss"}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Required By Date
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.requiredByDate | date:"yyyy-MM-dd hh:mm:ss"}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Estimated Budget
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.estimatedBudget}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Quantity :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.quantity}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Use Case
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.useCase}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Reason :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.reason}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Order Item Type Id
                                                                                :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.orderItemTypeId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Facility Id To :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.facilityIdTo}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Cust Request Id :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.custRequestId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-6">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6" style="text-align: end;">Cust Request Item Seq Id :</p>
                                                                            <span class="color-grey pl-10 font-weight-bold">   {{requirementPlanningById.custRequestItemSeqId}}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> 
                                                <!-- <div class="w3-card-4 classCardView">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        Edit Requirement
        
                                                         <button type="button" class="btn btn-outline-secondary active"   routerLinkActive="active"
                                                            data-target="#createSurveyPopup" data-toggle="modal">Create Survey</button>
                                                 
                                                    </div>

                                                    <form [formGroup]="createRequirementForm">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Requirement Type Id
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" optionlabel="label" 
                                                                        formControlName="requirementTypeId" 
                                                                        placeholder="Choose Requirement Type Id">
                            
                                                                        </p-dropdown>
                                                                    </div>
                            
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Facility Id
                                                                        </label>
                                                                    </div>
                            
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" optionlabel="label" 
                                                                        formControlName="facilityId" 
                                                                        placeholder="Choose Facility Id">
                            
                                                                        </p-dropdown>
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                            
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Deliverable Id
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email"  formControlName="deliverableId" class="form-control" id="exampleInputEmail1" 
                                                                            aria-describedby="emailHelp" placeholder="Enter Deliverable Id">
                                                            
                                                                    </div>
                            
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Fixed Asset Id
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" optionlabel="label" 
                                                                        formControlName="fixedAssetId" 
                                                                        placeholder="Choose Fixed Asset Id">
                            
                                                                        </p-dropdown>
                                                                        
                                                                    </div>
                            
                                                                
                                                                </div>
                                                            </div>
                            
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Product Id
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">

                                                                        <p-dropdown filter="true" optionlabel="label" 
                                                                        formControlName="productId" 
                                                                        placeholder="Choose Product Id">
                            
                                                                        </p-dropdown>
                                                                    </div>
                            
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Status Id
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" optionlabel="label" 
                                                                        formControlName="statusId" 
                                                                        placeholder="Choose Status Id">
                            
                                                                        </p-dropdown>
                                                                    </div>
                            
                                                                
                                                                </div>
                                                            </div>
                            
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Description
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Description">
                                                        
                                                                    </div>
                            
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Requirement Start Date
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="date"  formControlName="requirementStartDate" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Requirement Start Date">
                                                        
                                                                    </div>
                            
                                                                
                                                                </div>
                                                            </div>
                            
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Required By Date
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="date"  formControlName="requiredByDate" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Required By Date">
                                                        
                                                                    </div>
                            
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Estimated Budget
	
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email"  formControlName="estimatedBudget" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Estimated Budget">
                                                        
                                                                        
                                                            
                                                                    </div>
                            
                                                                
                                                                </div>
                                                            </div>
                            
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Quantity
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email"  formControlName="quantity" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Quantity">
                                                        
                                                                    </div>
                            
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Use Case</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email"  formControlName="useCase" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Use Case">
                                                        
                                                                        
                                                            
                                                                    </div>
                            
                                                                
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Reason
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email"  formControlName="reason" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Reason">
                                                        
                                                                    </div>
                            
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Order Item Type Id</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email"  formControlName="orderItemTypeId" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Order Item Type Id">
                                                        
                                                                    </div>
                            
                                                                
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Facility Id To

                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email"  formControlName="facilityIdTo" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Facility Id To">
                                                        
                                                                    </div>
                            
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Cust Request Id</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email"  formControlName="custRequestId" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Cust Request Id">
                                                        
                                                                    </div>
                            
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Cust Request Item Seq Id

                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email"  formControlName="custRequestItemSeqId" class="form-control" id="exampleInputEmail1" 
                                                                        aria-describedby="emailHelp" placeholder="Enter Cust Request Item Seq Id">
                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                            
                                                        </div>
                                                    </form>

                                                    <div class="col-lg-10 main-submit-button" style="margin-left: -9% !important;">
                                                        <button type="submit"  class="btn btn-secondary submit-btn" >Submit</button>&nbsp;
                                                        <button type="button" class="btn btn-danger ml-2" >Reset</button>&nbsp;
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCardView mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Allocated Orders

                                                        </h4>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                        
                                                                                        Order Id 
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                        
                                                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Order Item Seq Id 
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Requirement Id 
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                        
                                                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Quantity   
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                        
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"    >
                                                                                      {{product.surveyId}}
                                                                                        </span>
                        
                                                                                    </div>
                                                                                </td>
                        
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.surveyName}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.description}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.comments}}
                                                                                </td>
                                                                                
                        
                                                                            </tr>
                                                                        </ng-template>
                        
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                <h4
                                                                                    class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                        
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>  
                                                </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="updatePopup" tabindex="-1" role="dialog" aria-labelledby="updatePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Update Find Requirement

                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Update Find Requirement
                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <form [formGroup]="createRequirementForm">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Requirement Type Id
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" optionlabel="label" 
                                                    formControlName="requirementTypeId" [options]="requirementTypeIdArray"
                                                    placeholder="Choose Requirement Type Id">
        
                                                    </p-dropdown>
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Facility Id
                                                    </label>
                                                </div>
        
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" optionlabel="label" 
                                                    formControlName="facilityId"  [options]="facilityArray"
                                                    placeholder="Choose Facility Id">
        
                                                    </p-dropdown>
                                                    
                                                </div>
                                            </div>
                                        </div>
        
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Deliverable Id
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="deliverableId" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Deliverable Id">
                                        
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Fixed Asset Id
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" optionlabel="label" 
                                                    formControlName="fixedAssetId"  [options]="fixedAssetIdArray"
                                                    placeholder="Choose Fixed Asset Id">
        
                                                    </p-dropdown>
                                                    
                                                </div>
        
                                            
                                            </div>
                                        </div>
        
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Product Id
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">

                                                    <p-dropdown filter="true" optionlabel="label" 
                                                    formControlName="productId"  [options]="ProductIdArray"
                                                    placeholder="Choose Product Id">
        
                                                    </p-dropdown>
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Status Id
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" optionlabel="label" 
                                                    formControlName="statusId"  [options]="requirementStatusIdArray"
                                                    placeholder="Choose Status Id">
        
                                                    </p-dropdown>
                                                </div>
        
                                            
                                            </div>
                                        </div>
        
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Description
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Description">
                                    
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Requirement Start Date
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date"  formControlName="requirementStartDate" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Requirement Start Date">
                                    
                                                </div>
        
                                            
                                            </div>
                                        </div>
        
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Required By Date
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date"  formControlName="requiredByDate" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Required By Date">
                                    
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Estimated Budget

                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="estimatedBudget" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Estimated Budget">
                                    
                                                    
                                        
                                                </div>
        
                                            
                                            </div>
                                        </div>
        
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Quantity
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="quantity" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Quantity">
                                    
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Use Case</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="useCase" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Use Case">
                                    
                                                    
                                        
                                                </div>
        
                                            
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Reason
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="reason" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Reason">
                                    
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Order Item Type Id</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" optionlabel="label" 
                                                    formControlName="orderItemTypeId"  [options]="orderItemTypeIdArray"
                                                    placeholder="Enter Order Item Type Id">

                                                    </p-dropdown>
                                    
                                                </div>
        
                                            
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Facility Id To

                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="facilityIdTo" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Facility Id To">
                                    
                                                </div>
        
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Cust Request Id</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="custRequestId"
                                                     class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Cust Request Id">
                                    
                                                </div>
        
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">Cust Request Item Seq Id

                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="custRequestItemSeqId" class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Cust Request Item Seq Id">
                                    
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">Group Name

                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"  formControlName="groupName"
                                                     class="form-control" id="exampleInputEmail1" 
                                                    aria-describedby="emailHelp" placeholder="Enter Group Name">
                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="UpdateRequirementPlanning()">Update</button>
                                        </div>
        
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner> 
