import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        description:(data && data.description) ? data.description : null,
        skillTypeId:(data && data.skillTypeId) ? data.skillTypeId : null,
    });

    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   
   return ({
    description: new FormControl(data.description,[Validators.required]),
    skillTypeId: new FormControl(data.skillTypeId),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
