import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { WarehouseShipmentService } from '../../warehouse-shipment/warehouse-shipment.service';
import { NewWarehouseShipmentService } from '../../new-warehouse-shipment/new-warehouse-shipment.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-physical-update-inventory',
  templateUrl: './physical-update-inventory.component.html',
  styleUrls: ['./physical-update-inventory.component.css']
})
export class PhysicalUpdateInventoryComponent implements OnInit {
  updateWarehouseShipment: FormGroup;
  inventoryItemId: any;
  facilityId: any;
  productId: any;
  internalName: any;
  availableToPromiseTotal: any;
  quantityOnHandTotal: any;
  productATP: any;
  productQOH: any;
  reasonid: any;
  reasinIdArray: any[] = [];
  url: string;
  constructor(readonly router: Router,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly WarehouseShipmentService: WarehouseShipmentService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe) {
      this.url='facilities/warehouse/detailed-summary-facilities'
    this.updateWarehouseShipment = this._FormBuilder.group({
      itemId: [''],
      productId: [''],
      internalName: [''],
      itemAtp: [''],
      itemQAH: [''],
      ProductATP: [''],
      ProductQOH: [''],
      vReasonId: ['', [Validators.required]],
      ATPVar: [''],
      QOHVar: [''],

    });

  }

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.getFacilityGroupMembers();
    this.facilityId = this._ActivatedRoute.snapshot.queryParams.facilityId;
    this.inventoryItemId = this._ActivatedRoute.snapshot.queryParams.inventoryItemId;

    this.productId = this._ActivatedRoute.snapshot.queryParams.productId;
    this.internalName = this._ActivatedRoute.snapshot.queryParams.internalName;
    this.availableToPromiseTotal = this._ActivatedRoute.snapshot.queryParams.availableToPromiseTotal;
    this.quantityOnHandTotal = this._ActivatedRoute.snapshot.queryParams.quantityOnHandTotal;
    this.productATP = this._ActivatedRoute.snapshot.queryParams.productATP;
    this.productQOH = this._ActivatedRoute.snapshot.queryParams.productQOH;
   
    setTimeout(() => {
      const formValue = this.updateWarehouseShipment;
      formValue.patchValue({
        itemId: this.inventoryItemId,
        productId: this.productId,
        internalName: this.internalName,
        itemAtp: this.availableToPromiseTotal,
        itemQAH: this.quantityOnHandTotal,
        ProductATP: this.productATP,
        ProductQOH: this.productQOH,

      })
    }, 3000);
  }
  getFacilityGroupMembers() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getVaraiceResonId().subscribe(res => {
      this.reasonid = res.data;
      this.spinner.hide();
      for (const value of this.reasonid) {
        this.reasinIdArray.push({
          label: value.description,
          value: value.varianceReasonId
        })
      }
    })
    
  }


  submit(): void {

    this.spinner.show();
    const requestData = {
      "availableToPromiseVar": this.updateWarehouseShipment.value.ATPVar,
      "comments": '',
      "quantityOnHandVar": this.updateWarehouseShipment.value.QOHVar,
      "varianceReasonId": this.updateWarehouseShipment.value.vReasonId
    }
    this.NewWarehouseShipmentService.updatePhysicalShipment(this.inventoryItemId, requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Updated");
        this.spinner.hide();
        this.router.navigate([this.url], { queryParams: { facilityId: this.facilityId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }
  cancel() {
    this.router.navigate([this.url], { queryParams: { facilityId: this.facilityId } })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
