<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of [{{groupId}}] </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>

            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
              Security Group Information</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                    Security Group Permissions</a></li>


                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                                <div *ngIf="activeCategory==2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Permission
                                                        </h4>
                                                        <span class="edit-buttons">
                                <a (click)="changeList()" data-toggle="modal" data-target="#listPermission">Add Permission (from list) to Security Group</a>
                                <a (click)="changeManaully()" data-toggle="modal" data-target="#manualPermission">| Add Permission (manually) to Security Group</a>
                              </span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="getAllPermisionData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Permission ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name">
                                                                                    <div style="color: white;"> From Date
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Through Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.permissionId}} {{product.description}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>{{product.fromDate | date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                                <td>{{product.thruDate | date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                                <td style="cursor: pointer;color: #0d3769;" (click)="deletePermision(product.fromDate,product.permissionId)">
                                                                                    <a  class="account-button"  routerLinkActive="active"> Remove</a></td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page
                                                                        <ng-container *ngIf="openNoRecordFoundRelatedAccount">
                                                                            <span style="margin-left: 400px;">No Record Found!....</span>
                                                                        </ng-container>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==1">
                                                    <div class="w3-card-4 classCard">
                                                        <div class="header-tabs">


                                                            <h4 class=" common-styling hCard">
                                                                Security Group Information
                                                            </h4>
                                                            <span class="edit-buttons hCreate">
                             
                                <a  data-toggle="modal"
                                data-target="#exampleModalCenter" (click)="getUserDetailsEdit()">Update</a>
                              </span>

                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Security Group ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="this.getSecurityInfo">{{this.getSecurityInfo.groupId}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12"> Name</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="this.getSecurityInfo"> {{this.getSecurityInfo.groupName}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Description</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12" *ngIf="this.getSecurityInfo">{{this.getSecurityInfo.description}}</span>

                                                                        </div>


                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="!show" class="color-black pl-1">  Create Security Group</span>
                <span *ngIf="show" class="color-black pl-1">  Update Security Group</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w-100 bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                          Create Security Group</a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                            Update Security Group</a></li>

                            </ul>

                        </div>
                        <div class=" create-new-leade">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="w-100  create-lead font-medium mb-0 text-white" style="border:0px;">
                                        <div class="w-100">
                                            <div class="panel-group" role="tablist" aria-multiselectable="true">
                                                <div class="panel panel-default">

                                                    <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                                                        <div class="panel-body">
                                                            <div class="mt-2">
                                                                <div class="">
                                                                    <div class="row">
                                                                        <form class="w-100" [formGroup]="createGroup">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select formWidth">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightFormD">
                                                                                            <label class="common-labels">
                                                            Security Group ID	<span
                                                            style="color:red">*</span></label>
                                                                                        </div>
                                                                                        <div class="col-lg-3">
                                                                                            <input type="text" class="form-control" formControlName="groupId" placeholder="Enter Security Group ID" id="exampleInputEmail1">
                                                                                            <small class="text-danger" *ngIf="!!createGroup.controls.groupId.invalid && !!createGroup.controls.groupId.touched"> Group Id is required!</small>


                                                                                        </div>
                                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                                            <label class="common-labels">Name</label>
                                                                                        </div>
                                                                                        <div class="col-lg-3">
                                                                                            <input type="text" class="form-control" formControlName="groupName" placeholder="Enter Name" id="exampleInputEmail1">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div class="col-lg-3 form-group rightFormD">
                                                                                            <label class="common-labels">Description</label>
                                                                                        </div>
                                                                                        <div class="col-lg-3">
                                                                                            <input type="text" class="form-control" formControlName="description" placeholder="Enter Description" id="exampleInputEmail1">
                                                                                        </div>
                                                                                        <div class="col-lg-2"></div>

                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                            <div class="submit-btn-sales main-submit-button" style="padding-right:8%;margin-top:-8px">
                                                                                <button *ngIf="!show" (click)="submit()" type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                                                                <button *ngIf="show" (click)="update()" type="submit" class="btn btn-secondary submit-btn">Update</button>

                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<app-add-permission-list *ngIf="showListModal" (listModalClosed)="plistModalClosed()"></app-add-permission-list>
<app-add-permision-manually *ngIf="showManualModal" (manualModalClosed)="pManualModalClosed()"></app-add-permision-manually>
<ngx-spinner></ngx-spinner>