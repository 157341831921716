<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Tax Authority Product Category



 
                </span>
                <span class="color-black pl-1" > >Add Tax Authority Product Category



 
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                        Add Tax Authority Product Category

                    </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                        Update Tax Authority Product Category

                    </a></li>

                   
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createProduct">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInputHSF">
                                                <label for="exampleInputEmail1">
                                        
                                                    Type<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Type" filter="true"
                                                formControlName="Type" 
                                                [options]="rateTypeArray" optionlabel="label" >
                                                                
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                             
                                                    Store ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Store ID" filter="true"
                                            formControlName="StoreID" 
                                            [options]="storeArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInputHSF">
                                                <label for="exampleInputEmail1">
                                           
                                                    Category</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Category" filter="true"
                                            formControlName="Category" 
                                            [options]="categoryListArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                             
                                                    Title Transfer</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Title Transfer" filter="true"
                                                formControlName="TitleTransfer" 
                                                [options]="enumDataArrayList" optionlabel="label" >
                                                                
                                                </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInputHSF">
                                                <label for="exampleInputEmail1">Min Item Price
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                    formControlName="MinItemPrice" aria-describedby="emailHelp"
                                                    placeholder="Enter Min Item Price">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Min Purchase
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                    formControlName="MinPurchase" aria-describedby="emailHelp"
                                                    placeholder="Enter Min Purchase">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInputHSF">
                                                <label for="exampleInputEmail1">
                                             
                                                    Tax Shipping</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Tax Shipping" filter="true"
                                            formControlName="TaxShipping" 
                                            [options]="promotion" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Tax Percentage
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                    formControlName="TaxPercentage" aria-describedby="emailHelp"
                                                    placeholder="Enter Tax Percentage">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInputHSF">
                                                <label for="exampleInputEmail1">
                                             
                                                    Tax Promotions</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Tax Promotions" filter="true"
                                                formControlName="TaxPromotions" 
                                                [options]="promotion" optionlabel="label"  >
                                                                
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">From Date
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                    formControlName="fromDate" aria-describedby="emailHelp"
                                                    placeholder="Enter Tax Percentage">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInputHSF">
                                                <label for="exampleInputEmail1">Through Date
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                formControlName="throughDate" aria-describedby="emailHelp"
                                                placeholder="Enter Tax Percentage">
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Description
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="Description" aria-describedby="emailHelp"
                                                placeholder="Enter Description">
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInputHSF">
                                                <label for="exampleInputEmail1">
                                             
                                                    Is Tax In Shipping Price</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Is Tax In Shipping Price" filter="true"
                                            formControlName="IsTaxShippingPrice" 
                                            [options]="promotion" optionlabel="label">
                                                            
                                            </p-dropdown>
                                            </div>
                                           
                    
                                        </div>
                                    </div>

                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 67%;">
                               
                                <button type="submit" (click)="onSubmit()" *ngIf="!this.show"  class="btn btn-secondary submit-btn">Save</button>
                                <button type="submit" (click)="onUpdate()" *ngIf="this.show"   class="btn btn-secondary submit-btn">Update</button>
                             
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
