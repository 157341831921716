import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { LeadPersonalFormService } from "../lead-personal-service";
import { LeadPersonalLeadServices } from "../lead-personal/lead-personal.service";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-lead-personal',
  templateUrl: './lead-personal.component.html',
  styleUrls: ['./lead-personal.component.css']
})
export class LeadPersonalComponent implements OnInit {
  partyId: any;
  partyIds: any;
  currency: any;
  externalId;
  firstName;
  lastName;
  crmArray: any = [];
  preSelectedstatus = ''
  updateData: any[];
  comments;


  constructor(
    public leadPersonalFormService: LeadPersonalFormService,
    readonly leadPersonalLeadServices: LeadPersonalLeadServices,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router) { }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params['personId'];
    })
    this.partyIds = this.partyId;
    this.getStatusCrm();
    this.getLeadDataByPartyId();

    this.leadPersonalFormService.lead_personal_form = null;
    this.leadPersonalFormService.createEventForm(this.leadPersonalFormService.lead_personal_form);

  }

  getStatusCrm() {
    this.spinner.show();
    this.leadPersonalLeadServices.getStatusCrm().subscribe(res => {
      let crmStatus = res.data;
      this.spinner.hide();
      for (const value of crmStatus) {
        this.crmArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })

  }

  getLeadDataByPartyId() {
    this.spinner.show();
    this.leadPersonalLeadServices.getLeadByPartyId(this.partyId).subscribe(res => {
      let personalInfo = res.data.personalInformation;
      this.preSelectedstatus = personalInfo.statusId;
      this.externalId = personalInfo.externalId;
      this.firstName = personalInfo.firstName;
      this.lastName = personalInfo.lastName;
      this.comments = personalInfo.comments;
      this.spinner.hide();
    })

  }



  onUpdate() {
    this.spinner.show();
    const formData = this.leadPersonalFormService.lead_personal_form.value;
    this.leadPersonalLeadServices.updateLead(this.partyId, formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(["/crm/leads/details-lead"], { queryParams: { leadId: this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.success == false) {
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }
        }
        else if (err.error.success == false) {
          this.toastr.error(err.error.message);
        }
      }
    })
    this.spinner.hide();
  }
  onCancel() {
    this.router.navigate(["/crm/leads/details-lead"], { queryParams: { leadId: this.partyId } });
  }
}
