<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of [{{partyId}}] </span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>

            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                            <div class="divA">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling hCard">
                                                            User Information
                                                        </h4>
                                                        <span class="edit-buttons hCreate">
                               
                                  <a  data-toggle="modal"
                                  data-target="#exampleModalCenter" (click)="getUserIdSummaryEdit()">Update</a>
                                </span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">First Name</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{ this.userSummary.firstName}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Last Name</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12"> {{this.userSummary.lastName}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Email</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{ this.userSummary.email}}</span>

                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Last Login Date</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{ this.userSummary.lastLoginDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Party Id</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{ this.userSummary.partyId}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Group Id</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{ this.userSummary.groupId}}</span>
                                                                    </div>

                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">User Login Id</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12">{{ this.userSummary.userLoginId}}</span>
                                                                    </div>







                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>

                                            </div>

                                            <div class="divB">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling hCard">
                                                            Member Roles
                                                        </h4>
                                                        <span class="edit-buttons hCreate">
                                    <a (click)="change()" data-toggle="modal" data-target="#addRoleModalCenter">Add To Role</a>
                                    <a (click)="changeRole()" data-toggle="modal" data-target="#newRoleModalCenter">| New Role Type</a>
                                  </span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                                            <div class="form-group" style="width: 100%!important;">
                                                                <div class="card own-account-table borderTable">
                                                                    <p-table [value]="roleList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        RoleType ID
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">Role
                                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category" [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">Parent Type ID
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category" [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">Action
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.roleTypeId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.description}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.parentTypeId}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}" style="cursor: pointer;color: #0d3769;" (click)="deleteRoleTypeId(product.roleTypeId)">
                                                                                    <a  class="account-button"  routerLinkActive="active"> Remove</a>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>








                                            </div>



                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span *ngIf="!this.show" class="color-black pl-1"> Create-User</span>
                <span *ngIf="this.show" class="color-black pl-1"> Update-User</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!this.show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Create User</a></li>
                                <li *ngIf="this.show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update User</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createUser">
                                        <div class="all-users-infomation font-13 font-medium own-user-select formWidth">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">First Name<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control address" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter First Name" formControlName="firstName">
                                                        <small class="text-danger" *ngIf="!!createUser.controls.firstName.invalid && !!createUser.controls.firstName.touched"> First Name is required!</small>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Last Name<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control address" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Name" formControlName="lastName">
                                                        <small class="text-danger" *ngIf="!!createUser.controls.lastName.invalid && !!createUser.controls.lastName.touched"> Last Name is required!</small>


                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">E-mail<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control address" pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email"
                                                            formControlName="email">
                                                        <small *ngIf="createUser.controls['email'].invalid && (createUser.controls['email'].dirty || createUser.controls['email'].touched)">
                                                            <p class="validate-field"
                                                                *ngIf="createUser.controls['email'].errors.required">
                                                                E-mail is required!</p>
                                                            <p class="validate-field"
                                                                *ngIf="createUser.controls['email'].errors.pattern">
                                                                E-mail is not valid!</p>
                                                    </small>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA" *ngIf="!this.show">
                                                        <label for="exampleInputEmail1">Password<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA" *ngIf="this.show">
                                                        <label for="exampleInputEmail1">Password<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Password" formControlName="password">
                                                        <small class="text-danger" *ngIf="!!createUser.controls.password.invalid && !!createUser.controls.password.touched"> Password is required!</small>


                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Group Id<span
                                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Group Id" formControlName="groupId" filter="true" [options]="arraySecurity" optionlabel="label">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createUser.controls.groupId.invalid && !!createUser.controls.groupId.touched"> Group Id is required!</small>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 76%;">
                                        <button *ngIf="!this.show" type="submit" (click)="submit()" class="btn btn-secondary submit-btn update-button">Submit</button>
                                        <button *ngIf="this.show" type="submit" (click)="update()" class="btn btn-secondary submit-btn update-button">Update</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<app-add-to-role *ngIf="showAddRoleModal" (addModalClosed)="addRoleModalClosed()"></app-add-to-role>
<app-new-role-type *ngIf="showNewRoleModal" (newModalClosed)="newRoleModalClosed()"></app-new-role-type>