<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="navigate()" style="cursor: pointer;">Quote Summary ></span>
                <span class="color-black pl-1"> {{editMode?'Update':'Create'}} Party Note</span>
            </div>
            <div class="w3-card-4 classCard"> 
                <header class="w3-container w3-blue">
                  <div class="header-tabs">
                    <h4 class=" common-styling h4Margin" style="margin-left: 11px;">
                        {{editMode? 'Update':'Create'}} Party Note
                    </h4>
                 
                  </div>
                </header>
            <div class=" bg-white color-grey">
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form [formGroup]="fileUploadForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Note Name <span
                                                    class="text-danger">*</span></label>
                                            <input type="email" formControlName="noteName" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            <small class="text-danger"
                                                *ngIf="!!fileUploadForm.controls.noteName.invalid && !!fileUploadForm.controls.noteName.touched">Note
                                                Name is required.</small>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Note <span
                                                    class="text-danger">*</span></label>
                                                    <textarea style="height: 100%!important;width: 148;" id="w3review" formControlName="noteInfo" rows="3" cols="30" placeholder="Note">
                                                    </textarea> 

                                         
                                            <small class="text-danger"
                                                *ngIf="!!fileUploadForm.controls.noteInfo.invalid && !!fileUploadForm.controls.noteInfo.touched">Note
                                                is required.</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="main-submit-button">
                                <button  type="submit" (click)="onSubmit()"
                                    class="btn btn-secondary submit-btn">Save</button>
                                <button type="submit" (click)="navigate()" class="btn btn-danger">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 