import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from '../accounts/accounts.service';
import { MyContactsService } from '../contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from '../events/create-events/create-event-service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';


@Component({
  selector: 'app-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.css']
})
export class CasesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total=0;
  activeCategories = 7;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  findMycases: { subject: string; priority: string; status: string; type: string; };
  findMyTeamcases: { subject: string; priority: string; status: string; type: string; };
  createCases: any;
  findCasesList: any;
  StatusCrm: any;
  StatusCrmArray: any[]=[];
  TypeCrm: any;
  TypeCrmArray: any[]=[];
  Priority: any;
  PriorityArray: any[]=[];
  MyCasesList: any;
  Reason: any;
  ReasonArray: any[]=[];
  PartyId: any;
  PartyIdArray: any[]=[];
  date: Date;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
     readonly spinner: NgxSpinnerService,
     private _location: Location,
  ) { 
    this.findMycases = {
      subject: "",
      priority: "",
      status: "",
      type: ""
    };
    this.findMyTeamcases = {
      subject: "",
      priority: "",
      status: "",
      type: ""
    };
    this.createCases = this._FormBuilder.group({
      initialAccount: [''],
      initialContact: [''],
      priority: [''],
      type: [''],
      Reasons: [''],
      subject: [''],
      description: [''],
      internalNote: [''],
    })
  }

  ngOnInit(): void {
    this.getFindCases();
    this.getStatusCrm();
    this.getTypeCrm();
    this.getPriority();
    this.getMyCases();
    this.getReason();
    this.getPartyIdList();
  }
  toForecast(){
    
    this._Router.navigate(['/crm/sales-forecast']);

  }
  toEmails(){
    
    this._Router.navigate(['/communication-overview/Dashboard']);

  } toCalendar(){
    
    this._Router.navigate(['crm/sales-calendar']);

  }
  toNotes(){
    this._Router.navigate(['/crm/sales-notes']);
  }
  toDocuments(){
    
    this._Router.navigate(['crm/sales-documents']);

  }
  toLead()
  {
    this._Router.navigate(['/crm/leads']);

  }
  toTasks(){
    this._Router.navigate(['/crm/Tasks']);
  }
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  
  changeactiveactiveCategories(tab: number) {
    this.activeCategories = tab;
  }

  detailPage(id){
    this._Router.navigate(["crm/Cases/viewCase"], 
    { queryParams: {custRequestId:id} })
  }
  getPartyIdList() {
    this.spinner.show();
    this.myContactsService.getPartyIdList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }
  getReason(){
    this.spinner.show();
    this.myContactsService.getReason().subscribe(res=>{
      this.Reason = res.data[0].data;
      this.spinner.hide();

      for (const value of this.Reason) {
        this.ReasonArray.push({
          label: value.description,
          value: value.statusValue
        })
      }
    })
  }
  getMyCases(){
    this.spinner.show();
    this.myContactsService.getMyCases().subscribe(res=>{
      this.MyCasesList = res.data[0].getCasesById;
      this.spinner.hide();
    })
  }
  createCase(){
    this.spinner.show();
    const requestData = {
     "accountPartyId": this.createCases.value.initialAccount  ,
      "contactPartyId":  this.createCases.value.initialContact  ,
      "custDate": this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
      "custRequestCategoryId":'',
      "custRequestId": this.createCases.value.Reasons ,
      "custRequestName":  this.createCases.value.subject  ,
      "custRequestTypeId":  this.createCases.value.type  ,
      "description":  this.createCases.value.description  ,
      "note":  this.createCases.value.internalNote  ,
      "priority":  this.createCases.value.priority  ,
      "statusId": "CRQ_SUBMITTED" ,
      "workEffortId": ""  ,
    }

    this.myContactsService.createCase(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.createCases.reset();
        this.getFindCases();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }


  getStatusCrm(){
    this.spinner.show();
    this.myContactsService.getStatusCrm().subscribe(res=>{
      this.StatusCrm = res.data[0].data;
      this.spinner.hide();

      for (const value of this.StatusCrm) {
        this.StatusCrmArray.push({
          label: value.description,
          value: value.statusValue
        })
      }
    })
  }
  getPriority(){
    this.spinner.show();
    this.myContactsService.getPriority().subscribe(res=>{
      this.Priority = res.data[0].data;
      this.spinner.hide();
      for (const value of this.Priority) {
        this.PriorityArray.push({
          label: value.description,
          value: value.priority
        })
      }
    })
  }

  getTypeCrm() {
    this.spinner.show();
    this.myContactsService.getTypeCrm().subscribe(res => {
      this.TypeCrm = res.data[0].data;
      this.spinner.hide();
      for (const value of this.TypeCrm) {
        this.TypeCrmArray.push({
          label: value.description,
          value: value.custRequestTypeId
        })
      }
    })
  }
  getFindCases(){
    this.spinner.show();
    this.myContactsService.getFindCases().subscribe(res=>{
      this.findCasesList = res.data;
    })
    this.spinner.hide();
  }

  reset(){
    this.spinner.show();

    this.findMycases.subject = "",
    this.findMycases.priority = "",
    this.findMycases.status = "",
    this.findMycases.type = "",

    this.myContactsService.getFindCases().subscribe(res=>{
      this.findCasesList = res.data;
    })
    this.spinner.hide();
  }

  FindCases(){
    this.spinner.show();
    if(this.findMycases.subject||this.findMycases.priority||this.findMycases.status||this.findMycases.type){
      const req ={
        "custRequestName" : this.findMycases.subject ?  this.findMycases.subject : '' ,
        "priority" : this.findMycases.priority ?  this.findMycases.priority : '' ,
        "statusId" : this.findMycases.status ?  this.findMycases.status : '' ,
        "custRequestTypeId" : this.findMycases.type ?  this.findMycases.type : '' ,
      }
      this.myContactsService.FindCases(req.custRequestName,req.custRequestTypeId,req.priority,req.statusId).subscribe(res=>{
        this.findCasesList = res.data;
      })
    }
    else{
      this.getFindCases();
    }
    this.spinner.hide();
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  toAccount()
  {
    this._Router.navigate(['/crm/accounts']);
  }
  toContact()
  {
    this._Router.navigate(['/crm/my-contacts']);

  }
  toOpportunity()
  {
    this._Router.navigate(['/crm/opportunities']);

  }
  toActivities()
  {
    this._Router.navigate(['/crm/events']);

  }
  toCases()
  {
    this._Router.navigate(['/crm/Cases']);
  }
  toQuotes()
  {
    this._Router.navigate(['/order/quotations']);

  }
  toSalesOrder()
  {
    this._Router.navigate(['/order/create-sales-order']);

  }

}
