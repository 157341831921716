import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';import { Location } from '@angular/common';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddEmployeePositionService } from 'src/app/human-resource/add-employee-position/add-employee-position.service';
import { FinancialService } from 'src/app/services/financial.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import Swal from 'sweetalert2';

@Component({

  selector: 'app-contractor',
  templateUrl: './contractor.component.html',
  styleUrls: ['./contractor.component.css'],

})
export class ContractorComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  contractorForm: FormGroup;
  WorkingScheduleForm: FormGroup;
  activeCategory = 5;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  show: boolean;
  pageNo = 1;
  rows = 50;
  employeeList: any;
  Department: any;
  DepartmentArray: any[] = [];
  EmployeeArray: any[] = [];
  Employee: any;
  JobPosition: any;
  JobPositionArray: any[] = [];
  ContractTypeArray: any[] = [];
  ContractType: any;
  Company: any;
  CompanyArray: any[] = [];
  abc: any;
  SalaryStructure: any;
  SalaryStructureArray: any[] = [];
  ResponsibleArray: any[] = [];
  Responsible: any;
  AnalyticArray: any[] = [];
  Analytic: any;
  NewDocumentTemplate: any;
  newContractDocumentTemplateArray: any[] = [];
  ContractUpdateDocument: any;
  ContractUpdateDocumentArray: any[] = [];
  ContractTemplateArray: any[] = [];
  ContractTemplate: any;
  contractId = "";
  StartDate: string;
  end_date: string;
  contractorDetailsForm: FormGroup;
  contractDetailsId = " ";
  WorkingScheduleArray: any[] = [];
  WorkingSchedule: any;
  WorkingScheduleTable: any;
  workingHoursTable: any;
  workingScheduleId: any;
  bsModalRef: any;
  DayPeriod: any;
  DayPeriodArray: any[] = [];
  DayOfWeek: any;
  DayOfWeekArray: any[] = [];
  workEntryArray: any[] = [];
  workEntry: any;
  workingHoursId: any;
  workingSchedule: FormGroup;
  workingscheduleId = "";
  workingHours: FormGroup;
  actualendDate: string;
  actualstartDate: string;
  WorkSchedule: any;
  workingScheduleGet: FormGroup;
  totalworkingHoursId: any;
  createworkingHoursId= " ";
  createWorkingHour: FormGroup;
  totalHours: any;
  TotalHours: any;
  constructor(
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,      
    private _location: Location,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FinancialService: FinancialService,
    readonly _Router: Router,
    readonly _ToastrService: ToastrService,
  ) {
    this.contractorForm = this._FormBuilder.group({
      Employee: [''],
      StartDate: [''],
      Department: [''],
      endDate: [''],
      JobPosition: [''],
      WorkingSchedule: [''],
      Company: [''],
      Responsible: [''],
      ContractType: [''],
      Analytic: [''],
      SalaryStructure: [''],
    });
    this.WorkingScheduleForm = this._FormBuilder.group({
      Company: [''],
      FullTime: [''],
      AverageHourDay: [''],
      workingSchedule: [''],

    });

    this.contractorDetailsForm = this._FormBuilder.group({
      ContractTemplate: [''],
      NewDocumentTemplate: [''],
      ContractUpdateDocument: [''],

    });

    this.workingSchedule = this._FormBuilder.group({
      WorkingScheduleName: [''],
      Company: [''],
      FullTime: [''],
      AverageHourperDay: [''],

    });

    this.workingScheduleGet = this._FormBuilder.group({
      WorkingScheduleName: [''],
      Company: [''],
      FullTime: [''],
      AverageHourperDay: [''],

    });

    this.workingHours = this._FormBuilder.group({
      Name: [''],
      dayOfWeek: [''],
      dayPeriod: [''],
      workFrom: [''],
      WorkTo: [''],
      startDate: [''],
      endDate: [''],
      WorkEntryType: [''],

    });

    this.createWorkingHour = this._FormBuilder.group({
      names: [''],
      dayOfWeek: [''],
      dayPeriod: [''],
      workFrom: [''],
      WorkTo: [''],
      startDate: [''],
      endDate: [''],
      WorkEntryType: [''],

    });
  }

  ngOnInit(): void {
    this.getDepartment();
    this.getEmployee();
    this.getJobPosition();
    this.getContractType();
    this.getCompany();
    this.getPayCheck();
    this.getAnalyticAccount();
    this.getHRResponsible();
    this.getNewContractDocumentTemplate();
    this.getContractUpdateDocumentTemplate();
    this.getContractTemplate();
    this.getWorkingSchedule();
    this.getDayPeriod();
    this.getDayOfWeek();
    this.getWorkingScheduleList();
    this.getWorkingHoursList();
    this.getWorkEntryType();
    this.getWorkSchedulePage();
    

  }

  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this._Router.navigate(['/financial/main']);
}
  contractReferencees()
  {
    this.router.navigate(['/financial/contractor']);

  }
  findPaycheck()
  {
    this.router.navigate(['financial/main-find-paycheck']);
  }

  createPaychecks()
  {
    this.router.navigate(['/financial/main-create-paycheck']);

  }
  paycheckConfiguration()
  {
    this.router.navigate(['/financial/main-configuration']);

  }
  employeess()
  {
    this.router.navigate(['/financial/employees-profile']);

  }
  reports()
  {
    this.router.navigate(['/financial/main-reports']);

  }
  edit() {


    setTimeout(() => {
      const formValue = this.WorkingScheduleForm;
      formValue.patchValue({
        workingSchedule: this.WorkSchedule.workingSchedule,

      });
    }, 2000);
  }

  getWorkSchedulePage() {
    this.accountsService.getWorkSchedulePage(this.contractorForm.value.WorkingSchedule).subscribe((res: any) => {
      this.spinner.show();
      this.WorkSchedule = res.data[0];
      this.edit();
      this.spinner.hide();
    })
  }


  oncreateWorkingHours() {
    this.spinner.show();

    const endDate = this.createWorkingHour.get('endDate').value;
    this.actualendDate = this.datePipe.transform(endDate, "yyyy-MM-dd hh:mm:ss");

    const startDate = this.createWorkingHour.get('startDate').value;
    this.actualstartDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");

    const formData = {

      "dayOfWeek":  this.createWorkingHour.value.dayOfWeek,
      "dayPeriod":  this.createWorkingHour.value.dayPeriod,
      "endDate":  this.actualendDate ,
      "name": this.createWorkingHour.value.names,
      "startDate":this.actualstartDate,
      "workEntryType":  this.createWorkingHour.value.WorkEntryType,
      "workFrom":  this.createWorkingHour.value.workFrom,
      "workTo":  this.createWorkingHour.value.WorkTo,
      "workingHoursId": this.createworkingHoursId
    }
    this.accountsService.createWorkingHours(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getWorkingHoursList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  onWorkingSchedule() {
    this.spinner.show();
    const formData = {
      "averageHourPerDay": this.workingSchedule.value.AverageHourperDay,
      "fullTime": this.workingSchedule.value.FullTime,
      "partyId": this.workingSchedule.value.Company,
      "workingSchedule": this.workingSchedule.value.WorkingScheduleName,
      "workingScheduleId": this.workingScheduleId
    }
    this.accountsService.createWorkingSchedule(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getWorkingScheduleList();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  deleteWorkingHours() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "workingHoursId":this.totalworkingHoursId 

        }
        this.accountsService.deleteWorkingHours(req).subscribe((res: any) => {
          this.getWorkingHoursList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }



  updateWorkingHours(product) {
    this.spinner.show();

    //const endDate = this.workingHoursTable.get('product.data.endDate').value;
   // this.actualendDate = this.datePipe.transform(endDate, "yyyy-MM-dd hh:mm:ss");

   // const startDate = this.workingHoursTable.get('product.data.startDate').value;
   // this.actualstartDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");


    const formdata = {
      "dayOfWeek": product.data.dayOfWeek,
      "dayPeriod": product.data.dayPeriod,
      "name": product.data.name,
      "workEntryType": product.data.workEntryType,
      "workFrom": product.data.workFrom,
      "workTo": product.data.workTo,
      "workingHoursId": this.totalworkingHoursId,
      "endDate": product.data.endDate,
      "startDate": product.data.startDate
    }

    this.accountsService.updateWorkingHours(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getWorkingHoursList();

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }

  updateWorkingSchedule(product) {
    this.spinner.show();
    //this.resumeDate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");

    const formdata = {
      "averageHourPerDay": product.averageHourPerDay,
      "fullTime": product.fullTime,
      "partyId": product.partyId,
      "workingSchedule": product.workingSchedule,
      "workingScheduleId": product.workingScheduleId,


    }

    this.accountsService.updateWorkingSchedule(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.getWorkingScheduleList();

      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }

  getWorkingScheduleList() {
    this.accountsService.getWorkingScheduleList().subscribe((res: any) => {
      this.spinner.show();
      this.WorkingScheduleTable = res.data;
   //   this.workingScheduleId = res.data.workingScheduleId;
      this.spinner.hide();
    })
  }

  getTotalHours() {
    this.accountsService.getTotalHours().subscribe((res: any) => {
      this.spinner.show();
      this.TotalHours = res.data[0].hours;
      this.spinner.hide();
    })
  }

  getWorkingHoursList() {
    this.accountsService.getWorkingHoursList().subscribe((res: any) => {
      this.spinner.show();
      this.workingHoursTable = res.data;
      this.totalworkingHoursId = res.data[0].data.workingHoursId;
      this.spinner.hide();
      this.getTotalHours();
    })
  }

  discardPopUp(): void {
    this.closebutton.nativeElement.click();

  }


  onsubmitWorkingSchedule() {
    this.spinner.show();
    const formData = {
      "averageHourPerDay": this.WorkingScheduleForm.value.AverageHourDay,
      "fullTime": this.WorkingScheduleForm.value.FullTime,
      "partyId": this.WorkingScheduleForm.value.Company,
      "workingSchedule": this.WorkingScheduleForm.value.workingSchedule,
      "workingScheduleId": this.workingScheduleId,

    }
    this.accountsService.createWorkingSchedule(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  onCreateContractDetails() {
    this.spinner.show();
    const formData = {
      "contractDetailsId": this.contractDetailsId,
      "contractTemplate": this.contractorDetailsForm.value.ContractTemplate,
      "contractUpdateDocumentTemplate": this.contractorDetailsForm.value.ContractUpdateDocument,
      "newContractDocumentTemplate": this.contractorDetailsForm.value.NewDocumentTemplate,

    }
    this.accountsService.createContractDetails(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  onCreateContract() {

    this.spinner.show();
    const EndDate = this.contractorForm.get('endDate').value;
    this.end_date = this.datePipe.transform(EndDate, "yyyy-MM-dd hh:mm:ss");

    const startDate = this.contractorForm.get('StartDate').value;
    this.StartDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");

    const formData = {


      "analyticAccount": this.contractorForm.value.Analytic,
      "contractId": this.contractId,
      "contractType": this.contractorForm.value.ContractType,
      "departmentName": this.contractorForm.value.Department,
      "employeeName": this.contractorForm.value.Employee,
      "endDate": this.end_date,
      "hrResponsible": this.contractorForm.value.Responsible,
      "jobPosition": this.contractorForm.value.JobPosition,
      "partyId": this.contractorForm.value.Company,
      "paycheckType": this.contractorForm.value.SalaryStructure,
      "startDate": this.StartDate,
      "workingSchedule": this.contractorForm.value.WorkingSchedule,


    }
    this.accountsService.createContract(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");

        this.spinner.hide();



      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }


  getNewContractDocumentTemplate() {

    this.spinner.show();
    this.accountsService.getNewContractDocumentTemplate().subscribe(res => {
      this.NewDocumentTemplate = res.data;
      this.spinner.hide();
      for (const value of this.NewDocumentTemplate) {
        this.newContractDocumentTemplateArray.push({
          label: value.newContractDocumentTemplate,
          value: value.newContractDocumentTemplate
        })
      }
    })
  }
  getContractTemplate() {

    this.spinner.show();
    this.accountsService.getContractTemplate().subscribe(res => {
      this.ContractTemplate = res.data;
      this.spinner.hide();
      for (const value of this.ContractTemplate) {
        this.ContractTemplateArray.push({
          label: value.contractTemplate,
          value: value.contractTemplate
        })
      }
    })
  }

  getContractUpdateDocumentTemplate() {

    this.spinner.show();
    this.accountsService.getContractUpdateDocumentTemplate().subscribe(res => {
      this.ContractUpdateDocument = res.data;
      this.spinner.hide();
      for (const value of this.ContractUpdateDocument) {
        this.ContractUpdateDocumentArray.push({
          label: value.contractUpdateDocumentTemplate,
          value: value.contractUpdateDocumentTemplate
        })
      }
    })
  }


  getWorkingSchedule() {

    this.spinner.show();
    this.accountsService.getWorkingSchedule().subscribe(res => {
      this.WorkingSchedule = res.data;
      this.spinner.hide();
      for (const value of this.WorkingSchedule) {
        this.WorkingScheduleArray.push({
          label: value.workingSchedule,
          value: value.workingSchedule
        })
      }
    })
  }
  getDayPeriod() {

    this.spinner.show();
    this.accountsService.getDayPeriod().subscribe(res => {
      this.DayPeriod = res.data;
      this.spinner.hide();
      for (const value of this.DayPeriod) {
        this.DayPeriodArray.push({
          label: value.DayPeriod,
          value: value.DayPeriod
        })
      }
    })
  }

  getWorkEntryType() {

    this.spinner.show();
    this.accountsService.getWorkEntryType().subscribe(res => {
      this.workEntry = res.data;
      this.spinner.hide();
      for (const value of this.workEntry) {
        this.workEntryArray.push({
          label: value.WorkEntryType,
          value: value.WorkEntryType
        })
      }
    })
  }
  getDayOfWeek() {

    this.spinner.show();
    this.accountsService.getDayOfWeek().subscribe(res => {
      this.DayOfWeek = res.data;
      this.spinner.hide();
      for (const value of this.DayOfWeek) {
        this.DayOfWeekArray.push({
          label: value.DayOfWeek,
          value: value.DayOfWeek
        })
      }
    })
  }

  getAnalyticAccount() {

    this.spinner.show();
    this.accountsService.getAnalyticAccount().subscribe(res => {
      this.Analytic = res.data;
      this.spinner.hide();
      for (const value of this.Analytic) {
        this.AnalyticArray.push({
          label: value.analyticAccount,
          value: value.analyticAccount
        })
      }
    })
  }
  getHRResponsible() {

    this.spinner.show();
    this.accountsService.getHRResponsible().subscribe(res => {
      this.Responsible = res.data;
      this.spinner.hide();
      for (const value of this.Responsible) {
        this.ResponsibleArray.push({
          label: value.hrResponsible,
          value: value.hrResponsible
        })
      }
    })
  }
  getEmployee() {
    this.spinner.show();
    this.accountsService.getEmployee().subscribe(res => {
      this.Employee = res.data;
      this.spinner.hide();
      for (const value of this.Employee) {
        this.EmployeeArray.push({
          label: value.firstName + " " + value.lastName,
          value: value.firstName + " " + value.lastName
        })
      }
    })
  }
  getDepartment() {

    this.spinner.show();
    this.accountsService.getDepartment().subscribe(res => {
      this.Department = res.data;
      this.spinner.hide();
      for (const value of this.Department) {
        this.DepartmentArray.push({
          label: value.departmentName,
          value: value.departmentId
        })
      }
    })
  }
  getJobPosition() {

    this.spinner.show();
    this.accountsService.getJobPosition().subscribe(res => {
      this.JobPosition = res.data;
      this.spinner.hide();
      for (const value of this.JobPosition) {
        this.JobPositionArray.push({
          label: value.description,
          value: value.emplPositionTypeId
        })
      }
    })
  }
  getContractType() {

    this.spinner.show();
    this.accountsService.getContractType().subscribe(res => {
      this.ContractType = res.data;
      this.spinner.hide();
      for (const value of this.ContractType) {
        this.ContractTypeArray.push({
          label: value.ContractType,
          value: value.ContractType
        })
      }
    })
  }

  getCompany() {

    this.spinner.show();
    this.accountsService.getCompany().subscribe(res => {
      this.Company = res.data;
      this.spinner.hide();
      for (const value of this.Company) {
        this.CompanyArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getPayCheck() {

    this.spinner.show();
    this.accountsService.getPayCheck().subscribe(res => {
      this.SalaryStructure = res.data;
      this.spinner.hide();
      for (const value of this.SalaryStructure) {
        this.SalaryStructureArray.push({
          label: value.paycheckType,
          value: value.paycheckType
        })
      }
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}
