import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';  import { Location } from '@angular/common';
import { MainCatalogService } from '../main-catalog-mgmt/catalog.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductService } from '../new-products/product.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { CategoryService } from '../new-catagory/category.service';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  products3: any;
  activeCategory = 2;
  activeCat=4;
  @ViewChild('closebutton') closebutton;
  productMenu: {
    productType: any[],
    virtualVariantMethod: any[],
    requirementMethodEnumID: any[],
    inventoryItemTypeID: any[],
    ratingTypeEnum: any[],
    amountUomTypeID: any[],
    measuresUOMID: any[],
    weightUomID: any[],
    quantityUomID: any[],
    defaultShipmentBoxTypeID: any[],
  };
  createEditProductForm: FormGroup;
  interrogativeArray: { label: string, value: string }[];
  commandingArray: { label: string, value: string }[];
  ratingArray: { label: string, value: string }[];
  editMode: boolean;
  product: any;
  categories: any[];
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  pagination: {
    pageNo: number,
    pageSize: number
  };
  productForm: FormGroup;
  opportunityIdArray: any;
  products: any;
  isShown: any=false;
  productDropdown: any;
 
 
  defaultShipmentBoxTypeIDArray: any[]=[];
  quantityUomIDArray: any[]=[];
  weightUomIDArray: any[]=[];
  measuresUOMIDArray: any[]=[];
  productTypeIdArray: any[]=[];
  virtualVariantMethodArray: any[]=[];
  requirementMethodEnumIDArray: any[]=[];
  inventoryItemTypeIDArray: any[]=[];
  ratingTypeEnumArray: any[]=[];
  amountUomTypeIDArray: any[]=[];
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FormBuilder: FormBuilder,    private _location: Location,
    readonly spinner: NgxSpinnerService,
  
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ProductService: ProductService,
    readonly _ToastrService: ToastrService,
    readonly  _Router: Router,
 
    readonly _CategoryService: CategoryService
  ) {
    this.pagination = {
      pageNo: 1,
      pageSize: 100
    };
    this.productForm = this._FormBuilder.group({
      internalName: [''],
      internalNameSearchType: ['Contains'],
      productId: [''],
      productIdSearchType: ['Contains']
    });
    this.opportunityIdArray = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Begins With',
        text: 'Begins With'
      },
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      }
    ];
    this.createEditProductForm = this._FormBuilder.group({
      amountUomTypeId: [null], autoCreateKeywords: ['Y'], brandName: [''], chargeShipping: ['N'], comments: [''], defaultShipmentBoxTypeId: [null],
      depthUomId: [null], diameterUomId: [null], heightUomId: [null], inShippingBox: ['N'], includeInPromotions: ['Y'], internalName: ['', [Validators.required]],
      introductionDate: [new Date()], inventoryItemTypeId: [null], inventoryMessage: [''], isVariant: ['N'], isVirtual: ['N'], lotIdFilledIn: [null],
      orderDecimalQuantity: ['N'], piecesIncluded: [0], primaryProductCategoryId: [''], productDepth: [0], productDiameter: [0], productHeight: [0],
      productId: [''], productName: [''], productRating: [0], productTypeId: ['', [Validators.required]], productWeight: [0],
      productWidth: [0], quantityIncluded: [0], quantityUomId: [null], ratingTypeEnum: [null], releaseDate: [new Date()], requireAmount: ['Y'],
      requireInventory: ['Y'], requirementMethodEnumId: [null], returnable: ['Y'], salesDiscWhenNotAvail: ['Y'], salesDiscontinuationDate: [new Date()], shippingDepth: [0],
      shippingHeight: [0], shippingWeight: [0], shippingWidth: [0], supportDiscontinuationDate: [new Date()], taxable: ['Y'], virtualVariantMethodEnum: [null],
      weightUomId: [null], widthUomId: [null]
    });
    this.constructorExt();
  }
  constructorExt(): void {
    this.categories = [];
    this.interrogativeArray = [
      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      }
    ];
    this.commandingArray = [
      {
        label: 'Allowed',
        value: 'Allowed'
      },
      {
        label: 'Mandatory',
        value: 'Mandatory'
      },
      {
        label: 'Forbidden',
        value: 'Forbidden'
      }
    ];
    this.editMode = false;
  // this.ext();
  }

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
   /*   if (this._ActivatedRoute.snapshot.queryParams.productId) {
      this.productForm.patchValue({
        productId: this._ActivatedRoute.snapshot.queryParams.productId
      });
    }  */
    this.getProductList();
 
    if (this.product) {
      this.editMode = true;
      this.createEditProductForm.controls.productId.disable();
      this.createEditProductForm.controls.productId.updateValueAndValidity();
      this.createEditProductForm.patchValue({
        ...this.product, ...{
          salesDiscontinuationDate: new Date(this.product.salesDiscontinuationDate),
          releaseDate: new Date(this.product.releaseDate),
          introductionDate: new Date(this.product.introductionDate),
          supportDiscontinuationDate: new Date(this.product.supportDiscontinuationDate)
        }
      });  }
    this.getCategories();
    this.getProductDropown();
    
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  resetProductForm(){
    this.productForm.reset();
  }
  getProductDropown(): void {
    this.spinner.show();
    this._ProductService.getProductMenu().then((res) => {
      let productTypeIdData = res.data.productType;
      let virtualVariantMethod = res.data.virtualVariantMethod;
      let requirementMethodEnumID = res.data.requirementMethodEnumID;
      let inventoryItemTypeID = res.data.inventoryItemTypeID;
      let ratingTypeEnum = res.data.ratingTypeEnum;
      let amountUomTypeID = res.data.amountUomTypeID;
      let measuresUOMID = res.data.measuresUOMID;
      let weightUomID = res.data.weightUomID;
      let quantityUomID = res.data.quantityUomID;
      let defaultShipmentBoxTypeID = res.data.defaultShipmentBoxTypeID;

      this.spinner.hide();
      for (const value of defaultShipmentBoxTypeID) {
        this.defaultShipmentBoxTypeIDArray.push({
          label: value.description,
          value: value.shipmentBoxTypeId,
        });
      }
      for (const value of quantityUomID) {
        this.quantityUomIDArray.push({
          label: value.description,
          value: value.uomId,
        });
      }
      for (const value of weightUomID) {
        this.weightUomIDArray.push({
          label: value.description,
          value: value.uomId,
        });
      }
      for (const value of measuresUOMID) {
        this.measuresUOMIDArray.push({
          label: value.description,
          value: value.uomId,
        });
      }
      for (const value of productTypeIdData) {
        this.productTypeIdArray.push({
          label: value.description,
          value: value.productTypeId,
        });
      }
      for (const value of virtualVariantMethod) {
        this.virtualVariantMethodArray.push({
          label: value.description,
          value: value.enumId,
        });
      }
      for (const value of requirementMethodEnumID) {
        this.requirementMethodEnumIDArray.push({
          label: value.description,
          value: value.enumId,
        });
      }
      for (const value of inventoryItemTypeID) {
        this.inventoryItemTypeIDArray.push({
          label: value.description,
          value: value.inventoryItemTypeId,
        });
      }
      for (const value of ratingTypeEnum) {
        this.ratingTypeEnumArray.push({
          label: value.description,
          value: value.enumId,
        });
      }
      for (const value of amountUomTypeID) {
        this.amountUomTypeIDArray.push({
          label: value.description,
          value: value.uomTypeId,
        });
      }
    });
   
  }
  getCategories(): void {
    this.spinner.show();
    this._CategoryService.getCategories({ pageNo: 1, pageSize: 200 }, { categoryId: '', categoryIdSearchType: 'Contains', categoryName: '', categoryNameSearchType: 'Contains' })
      .then(data => {
        this.spinner.hide();
        this.categories = data.data.map(value => {
          return {
            label: value.categoryName,
            value: value.productCategoryId
          };
        });
      });
      
  }
  update(): void {
    this.spinner.show();
    if (this.createEditProductForm.valid) {
      this._ProductService.updateProduct({
        ...this.product,
        ...this.createEditProductForm.value, ...{
          salesDiscontinuationDate: moment(this.createEditProductForm.value.salesDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss'),
          releaseDate: moment(this.createEditProductForm.value.releaseDate).format('YYYY-MM-DD HH:MM:ss'),
          introductionDate: moment(this.createEditProductForm.value.introductionDate).format('YYYY-MM-DD HH:MM:ss'),
          supportDiscontinuationDate: moment(this.createEditProductForm.value.supportDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss')
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated successfully');
            this.spinner.hide();
            this._Router.navigate(['/facilities/catalog/products']);
          }
        });
    }
  
  }
  submitProduct(): void {
    this.spinner.show();
    if (this.editMode) {
      this.update();
    } else {
      if (this.createEditProductForm.valid) {
        this._ProductService.createProduct({
          ...this.createEditProductForm.value, ...{
            salesDiscontinuationDate: moment(this.createEditProductForm.value.salesDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss'),
            releaseDate: moment(this.createEditProductForm.value.releaseDate).format('YYYY-MM-DD HH:MM:ss'),
            introductionDate: moment(this.createEditProductForm.value.introductionDate).format('YYYY-MM-DD HH:MM:ss'),
            supportDiscontinuationDate: moment(this.createEditProductForm.value.supportDiscontinuationDate).format('YYYY-MM-DD HH:MM:ss')
          }
        })
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created successfully');
              this.spinner.hide();
              this.getProductList();
              this.closebutton.nativeElement.click();
           
            }
          });
      }
    }
   
  }
  selectProduct(id) {
    
    if (id) {
      this.activeCategory = 3;
     

    }
    else {
      this._ToastrService.error("Product Type is required");
    }
  }
  selectworking(name) {
    if (name) {
      this.activeCategory = 4;
    }
    else {
      this._ToastrService.error("Internal Name is required");
    }
  }
  selectvirtual(vname) {
    if (vname) {
      this.activeCategory = 5;
    }
    else {
      this._ToastrService.error("Virtual Variant Method is required");
    }
  }
  selectPrimary(pc) {
    if (pc) {
      this.activeCategory = 6;
    }
    else {
      this._ToastrService.error("Primary Category is required");
    }
  }
  selectDate() {
    this.activeCategory = 7;
  }
  selecInventory(id) {
    if (id) {
      this.activeCategory = 8;
    }
    else {
      this._ToastrService.error("Inventory Item Type Id is required");
    }
   
  }
  selectRating(id) {
    if (id) {
      this.activeCategory = 9;
    }
    else {
      this._ToastrService.error("Rating Type Enum is required");
    }
    
  }
  selectAmount(id) {
    if (id) {
      this.activeCategory = 10;
    }
    else {
      this._ToastrService.error("Amount Uom Type ID is required");
    }
    

  }
  selectMeasure(width,height) {
    if (width && height) {
      this.activeCategory = 11;
    }
    else {
      this._ToastrService.error("Height and width Uom ID is required");
    }
   
  }
  selectShiping(id) {
    if (id) {
      this.activeCategory = 12;
    }
    else {
      this._ToastrService.error("Default Shipment Box Type ID is required");
    }
   
  }
  selectShopingCart() {
    this.activeCategory = 13;
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  wordingValid(id) {
    
    if (id) {
      this.activeCategory = 3;
     

    }
    else {
      this._ToastrService.error("Fill Product Form.");
    }
  }
  virtualValid(name) {
    if (name) {
      this.activeCategory = 4;
    }
    else {
      this._ToastrService.error("Fill Wording Form.");
    }
  }
  primaryValid(vname) {
    if (vname) {
      this.activeCategory = 5;
    }
    else {
      this._ToastrService.error("Fill Virtual Product Form.");
    }
  }
  dateValid(pc) {
    if (pc) {
      this.activeCategory = 6;
    }
    else {
      this._ToastrService.error("Fill Primary Category Form.");
    }
  }
  inventoryValid(pc) {
    if (pc) {
      this.activeCategory = 7;
    }
    else {
      this._ToastrService.error("Fill Primary Category Form.");
    }
   
  }
  ratingValid(id) {
    if (id) {
      this.activeCategory = 8;
    }
    else {
      this._ToastrService.error("Fill Inventory Form.");
    }
   
  }
  amountValid(id) {
    if (id) {
      this.activeCategory = 9;
    }
    else {
      this._ToastrService.error("Fill Rating Form.");
    }
    
  }
  measureValid(id) {
    if (id) {
      this.activeCategory = 10;
    }
    else {
      this._ToastrService.error("Fill Amount Form.");
    }
    

  }
  shipingValid(width,height) {
    if (width && height) {
      this.activeCategory = 11;
    }
    else {
      this._ToastrService.error("Fill Measure Form.");
    }
   
  }
  shopingValid(id) {
    if (id) {
      this.activeCategory = 12;
    }
    else {
      this._ToastrService.error("Fill Shipping Form.");
    }
   
  }
  shopingCartValid(id) {
    if (id) {
      this.activeCategory = 13;
    }
    else {
      this._ToastrService.error("Fill Shipping Cart Form.");
    }
   
  }
  
  getProductList(): void {
    this.spinner.show();
    this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 500 }, this.productForm.value)
      .then(data => {
        if (data.success) {
          this.products = data.data;
          this.spinner.hide();
        }
      });
     
  }
  feautureValue(){
    this.isShown = !this.isShown;
  }
  editProduct(id: string): void {
    this.router.navigate(['/facilities/catalog/products/new-products'], { queryParams: { productId: id } });
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  mainOpen(){
    this.router.navigate(['/facilities/catalog/main-catalog']);
  }
  catalogOpen(){
    this.router.navigate(['/facilities/catalog/catalogs']);
  }
  categoriesOpen(){
    this.router.navigate(['/facilities/catalog/catagories']);
  }
  productsOpen(){
    this.router.navigate(['/facilities/catalog/products']);
  }
  featuresOpen(){
    this.router.navigate(['/facilities/catalog/feature-type']);
  }
  promosOpen(){
    this.router.navigate(['/facilities/catalog/promos']);
  }
  priceRulesOpen(){
    this.router.navigate(['/facilities/catalog/price-rules']);
  }
  storesOpen(){
    this.router.navigate(['/facilities/catalog/stores']);
  }
  productStoreGroupsOpen(){
    this.router.navigate(['/facilities/catalog/product-store-groups']);
  }
  thesaurusOpen(){
    this.router.navigate(['/facilities/catalog/thesaurus']);
  }
  reviewsOpen(){
    this.router.navigate(['/facilities/catalog/reviews']);
  }
  configurationsOpen(){
    this.router.navigate(['/facilities/catalog/configurations']);
  }
  subscriptionsOpen(){
    this.router.navigate(['/facilities/catalog/subscriptions']);
  }
  shippingOpen(){
    this.router.navigate(['/facilities/catalog/shipment']);
  }
  imageManagementOpen(){
    this.router.navigate(['/facilities/catalog/image-management']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
