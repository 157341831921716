

<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" id="grey" (click)="navigate()">Opportunity</span>
                <span class="color-black pl-1"> > New</span>
                <button type="submit" routerLink="/crm/opportunities" routerLinkActive="active"
                class="btn btn-danger text-white buttonclass"  >Cancel</button>
            </div>
            <div class="w3-card-4 classCard" >
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Update Opportunity</a></li>
                     
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form *ngIf="createOppourtunityFormService.create_oppourtunity_form"
                                [formGroup]="createOppourtunityFormService.create_oppourtunity_form" class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                  <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Sales Opportunity ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" filter="true" formControlName="oppurtunityId"
                                                    class="form-control small-input" placeholder="Select Oppourtunity Id"
                                                    optionlabel="label">
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Oppourtuinty Name</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <textarea style="font-size: 11px;" class="form-control small-input" formControlName="opportunityName"
                                                id="exampleFormControlTextarea1" rows="1"
                                                placeholder="Oppourtuinty Name"></textarea>
                                            </div>                                                  
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Initial Customer</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown styleClass="small-dropdown" [options]="initialAccountArray" formControlName="accountPartyId"
                                                placeholder="Initial Account" filter="true"></p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Lead</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown styleClass="small-dropdown" filter="true" [options]="leadPartyIdd"
                                                formControlName="leadPartyId" placeholder="Initial Lead">
                                            </p-dropdown>
                                            </div>                                         
                                        </div>
                                    </div>
    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Esitmated Amount</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="estimatedamount" class="form-control small-input"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Estimated Amount">
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Probability</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="probablity" class="form-control small-input"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter probability">
                                            </div>     
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Initial Stage <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown styleClass="small-dropdown" filter="true" [options]="initialArray"
                                                    formControlName="initialStage" optionlabel="label">
                                                </p-dropdown>
                                               <!--  <ng-container
                                                    *ngIf="this.createOppourtunityFormService.create_oppourtunity_form.controls['initialStage'].invalid && (this.createOppourtunityFormService.create_oppourtunity_form.controls['initialStage'].dirty || this.createOppourtunityFormService.create_oppourtunity_form.controls['initialStage'].touched)">
                                                    <p class="validate-field"
                                                        *ngIf="this.createOppourtunityFormService.create_oppourtunity_form.controls['initialStage'].errors.required">
                                                        Initial Stage is required!
                                                    </p>
                                                </ng-container> -->
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Next Step</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="nextStep" class="form-control small-input"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Next Step">
                                            </div>                                             
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Next Step Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input class="small-input" type="date" [ngModel]="nextStepDate | date:'yyyy-MM-dd'"
                                                    [value]="nextStepDate" formControlName="nextStepDate">
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Type</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown styleClass="small-dropdown" filter="true" [options]="initialTypeArray"
                                                    formControlName="type" optionlabel="label">
                                                </p-dropdown>
                                            </div>
                                           
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Marketing Campaign</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown styleClass="small-dropdown" filter="true" [options]="marketCompaignArray"
                                                formControlName="marketing_campaign" optionlabel="label">

                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Currency <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown styleClass="small-dropdown" filter="true" [options]="currencyArray" optionlabel="label"
                                                    formControlName="currencyId" placeholder="select currency">
                                                </p-dropdown>
                                               <!--  <ng-container
                                                    *ngIf="this.createOppourtunityFormService.create_oppourtunity_form.controls['currencyId'].invalid && (this.createOppourtunityFormService.create_oppourtunity_form.controls['currencyId'].dirty || this.createOppourtunityFormService.create_oppourtunity_form.controls['currencyId'].touched)">
                                                    <p class="validate-field"
                                                        *ngIf="this.createOppourtunityFormService.create_oppourtunity_form.controls['currencyId'].errors.required">
                                                        Currency is required!
                                                    </p>
                                                </ng-container> -->
                                            </div>
                                            
                                           
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Source</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown styleClass="small-dropdown" filter="true" [options]="sourceArray"
                                                    formControlName="source" optionlabel="label">

                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1"> Close Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input class="small-input" type="date" [ngModel]="closeDate | date:'yyyy-MM-dd'"
                                                    [value]="closeDate" formControlName="closeDate">
                                            </div>                                                                           
                                        </div>
                                    </div>
                            
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Description</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <textarea style="font-size: 11px;" formControlName="description" placeholder="Enter Description"
                                                class="form-control small-input" id="exampleFormControlTextarea1"
                                                rows="1"></textarea>
                                            </div>
                                            
                                           
                                        </div>
                                    </div>
                           
                                 
                                  

                                </div>
                                <div class="main-submit-button" style="margin-right: 27%;">
                                    <button style="width:159px" type="submit" *ngIf="!show" (click)="onSubmit()"
                                        class="btn btn-secondary submit-btn">Create</button>
                                    <button type="submit" *ngIf="show" (click)="onUpdate()"
                                        class="btn btn-secondary submit-btn">Update</button>
                                  


                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 
<!-- <app-footer></app-footer>
</div>
</div>  -->