<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div style="margin: 4%;font-size: 11px;">
          <ng-wizard [config]="config">

            <ng-wizard-step [title]="'Step 1'" [description]="'Sales Order'"> 
            </ng-wizard-step>

            <ng-wizard-step [title]="'Step 2'" [description]="' Enter Order Currency, Agreements, and Ship Dates '">  
            </ng-wizard-step>
           
          <ng-wizard-step [title]="'Step 3'" [description]="'Create Sales Order'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 4'" [description]="'Shipping'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 5'" [description]="'Option'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 6'" [description]="'Order Term'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 7'" [description]="'Payment'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 8'" [description]="'Parties'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 9'" [description]="'Review Order'">     
            </ng-wizard-step>
           
          
         
          </ng-wizard>
        </div>
        <div>
          <div class="container-fluid">
            <div class="container-fluid">
              <ul class="tabbing-section  tabbing-accordians" style="padding-left: 9px;">
                <li [ngClass]="activeCategory==1?'active':''" ><a>
                  Sales Order : Additional Party Entry </a></li>
               
               
              
              </ul>
            </div>
            <div class="row">
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div *ngIf="activeCategory==1">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Sales Order : Additional Party Entry
                          </h4>
                          <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                          href="#seo-collapse">
                        </span>
                        <span style="margin-left: 26%!important" class="edit-buttons" routerLinkActive="active"
                          routerLink="/order/create-sales-continue"> Order Items</span>
                        <span class="edit-buttons" routerLinkActive="active"
                          routerLink="/order/sales-finalize-order">Shipping</span>
                        <span class="edit-buttons">Options</span>
                        <span class="edit-buttons">Order Terms</span>
                        <span class="edit-buttons">Payment</span>
                        <span class="edit-buttons"> Parties</span>
                        <span class="edit-buttons"> Reviews Order</span>
                        <span class="edit-buttons" (click)="finalizeOrder()"> Continue</span>

                         
                         
                        </div>
                        <div class="panel-body">
                          <div class="mt-2">
                            <div class="">
                              <h4 class="detail-heading">1) Select type of party to associate to order :</h4>
                              <div class="radios-sections">
                                <div class="col-lg-9 pl-0 col-12">
                                  <div class="form-group  d-flex radio-boxes-wrapper">
                                    <input type="radio" id="Guaranteed" name="radio" value="Guaranteed"
                                      (click)="setPartyId(true)">
                                    <label class="common-labels" for="Guaranteed">Person</label>
                                  </div>
                                </div>
                                <div class="col-lg-9 pl-0 col-12">
                                  <div class="form-group d-flex radio-boxes-wrapper">
                                    <input type="radio" id="Air" name="radio" value="Air" (click)="setPartyId(false)">
                                    <label class="common-labels" for="Air">Group</label>
                                  </div>
                                </div>
                                <div class="col-lg-9 pl-0 col-12">
                                  <div class="form-group d-flex radio-boxes-wrapper">
                                    <input type="radio" id="Ground" name="radio" value="Ground"
                                      (click)="setPartyId(false)">
                                    <label class="common-labels" for="Ground">I do not wish to add additional parties.
                                    </label>
                                  </div>
                                </div>
                                <ng-container *ngIf="isParty">
                                  <h4 class="detail-heading">2) Find Order :</h4>
                                  <div class="col-lg-4 col-12">
                                    <div class="form-group">
                                      <label class="common-labels identifier-label">Identifier
                                      </label>
                                      <p-dropdown [options]="partyIds" filter="true"
                                        [(ngModel)]="additionalPartyForm.additionalPartyId"
                                        [ngModelOptions]="{standAlone:true}"></p-dropdown>
                                    </div>
                                  </div>
                                  <div class="col-lg-12 col-12 main-submit-button">
                                    <button type="submit" class="btn btn-secondary submit-btn"
                                      (click)="isRole=true">Apply</button>
                                  </div>
                                </ng-container>
                                <ng-container *ngIf="isRole">
                                  <h4 class="detail-heading">3) Select Role :</h4>
                                  <div class="col-lg-4 col-12">
                                    <div class="form-group">
                                      <label class="common-labels identifier-label">Role
                                      </label>
                                      <p-dropdown [options]="roleValue" filter="true"
                                        [(ngModel)]="additionalPartyForm.additionalRoleTypeId"
                                        [ngModelOptions]="{standALone:true}"></p-dropdown>
                                    </div>
                                  </div>
                                  <div class="col-lg-12 col-12 main-submit-button">
                                    <button type="submit" class="btn btn-secondary submit-btn"
                                      (click)="addAdditionalParty()">Add</button>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner> 