import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { SortEvent } from 'primeng/api';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import { NewWarehouseShipmentService } from 'src/app/warehouse/new-warehouse-shipment/new-warehouse-shipment.service';
import { CustomerAgreementsService } from 'src/app/accounting/accounting-ar/customer-agreements/customer-agreements.service';

@Component({
  selector: 'app-detail-vendor-summary',
  templateUrl: './detail-vendor-summary.component.html',
  styleUrls: ['./detail-vendor-summary.component.css']
})
export class DetailVendorSummaryComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
 
  pageNo = 1;
  activeCategory=1;
  activeCategoryA=2;
  total=0;
  rows = 50;
  pageSize= 100;
  personId: any;
  partyIdArray: any[] = []
  public applicationSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize }
  trainingList: any;
  partyId: any;
  createVendor: FormGroup;
  listVendors: any;
  listDistributors:any;
  vendor: any;
  supplier: any;
  activeCategoryMainTs=1;
  emailSupplier: any;
  addressSupplier: any;
  InformationSupplier: any;
  attachDocumentId: any;
  fileData: File;
  uploadDocForm: FormGroup;
  createCustomerAgreements: FormGroup;
  agreementDate: any;
  fromDate: any;
  productList: any = [];
  thruDate: any;
  AgreementTypeListArray: any[]=[];
  PersonsAndPartyGroupsList: any = [];
  RoleTypesList: any = [];
  AgreementTypeList: any = [];
  agreementsList: any;
  product: any;
  productAgree: any;

  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly datePipe: DatePipe,
    readonly CustomerAgreementsService: CustomerAgreementsService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly addSkillService: AddSkillService,
    readonly router: Router,
    readonly spinner:NgxSpinnerService,
    readonly accountsService: AccountsService,
    private _location: Location,
  ) {
    this.createVendor = this._FormBuilder.group({
      accountNo:  [''],
      billingRateLevel:  [''],
      company:  [''],
      companyWebsite:  [''],
      contactMechPurposeTypeId:  [''],
      creditLimit:  [''],
      eligibleFor:  [''],
      federalTaxId:  [''],
      generalAddress1:  [''],
      generalAddress2:  [''],
      generalAttnName:  [''],
      generalCity:  [''],
      generalCountryGeoId:  [''],
      generalPostalCode:  [''],
      generalStateProvinceGeoId:  [''],
      generalToName:  [''],
      groupName:  [''],
      individual:  [''],
      info :  [''],
      isActive:  [''],
      isIncorporated:  [''],
      manifestCompanyName:  [''],
      manifestCompanyTitle:  [''],
      manifestLogoUrl:  [''],
      manifestPolicies:  [''],
      map:  [''],
      name:  [''],
      partyId:  [''],
      phone:  [''],
      primaryEmail:  [''],
      primaryFaxAreaCode:  [''],
      primaryFaxCountryCode:  [''],
      primaryFaxExtension:  [''],
      primaryFaxNumber:  [''],
      primaryPhoneAreaCode:  [''],
      primaryPhoneCountryCode:  [''],
      primaryPhoneExtension:  [''],
      primaryPhoneNumber:  [''],
      primaryWebUrl:  [''],
      printCheckAs:  [''],
      requires1099:  [''],
      taxId:  [''],
      terms:  [''],
      type:  [''],
      typeVendor:  [''],
      vendorEmail:  [''],
      vendorFax:  [''],
      vendorForms:  [''],
      vendorName:  [''],
      vendorPhone:  [''],
      webUrlId:  [''],
      PrimaryWebUrl:['']
    });
    this.uploadDocForm = this._FormBuilder.group({
      upload:  [''],
     
    });
    this.createCustomerAgreements = this._FormBuilder.group({
      statusId: [''],
      agreementTypeId: ['', [Validators.required]],
      agreementDate: [''],
      description: [''],
      fromDate: [''],
      partyIdFrom: [''],
      partyIdTo: [''],
      productId: [''],
      roleTypeIdFrom: [''],
      roleTypeIdTo: [''],
      textData: [''],
      thruDate: ['']
    });
   }

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.partyId = params["id"];
    });
    this.getVendors();
    this.editVendorsData();
    this.getPartyId();
    this.getAttachDocumentByPartyId();
    this.getAgreementTypeList();
    this.getProductsData();
    this.getPersonsAndPartyGroupsData();
    this.getRoleTypes();
    this.getAgreementTypeListData();
    this.getAgreementByPartyId();
  }

  downloadDocuments(product) {
    this.spinner.show();
    this.product = product.url;

  } 
  getAgreementByPartyId() {
    this.spinner.show();
    this.addSkillService.getAgreementByPartyId(this.partyId).subscribe((responce) => {
      this.spinner.hide();
      this.agreementsList = responce.data[0].getAgreementByPartyId;
    });

  }
  cancelAgreement(agreementId: string): void {
    this.spinner.show();
    this.addSkillService.cancelAgreements(agreementId).subscribe((responce) => {
      this.spinner.hide();
      this.toastr.success('Cancelled');

    });
   
  }
  getRoleTypes() {
    this.spinner.show();
    this.CustomerAgreementsService.getRoleTypes().subscribe((responce) => {
      this.spinner.hide();
      this.RoleTypesList = responce.data;
    });
   
  }
  getAgreementTypeListData() {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementTypeList().subscribe((responce) => {
      this.spinner.hide();
      this.AgreementTypeList = responce.data;
    });

  }
  getPersonsAndPartyGroupsData() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getPersonsAndPartyGroups().subscribe((responce) => {
      this.spinner.hide();
      this.PersonsAndPartyGroupsList = responce.data.PersonsGroups;
    });
  
  }
  getProductsData() {
    this.spinner.show();
    const requestData = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains"
    };
    this.CustomerAgreementsService.getProducts(requestData).subscribe((responce) => {
      this.spinner.hide();
      this.productList = responce.data;
    });
   
  }

  getAgreementTypeList() {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementTypeList().subscribe((responce) => {

      this.AgreementTypeListArray = responce.data.map(value => {
        return {
          label: value.description,
          value: value.agreementTypeId
        };
      });
    });
    this.spinner.hide();
  }
  submitData(): void {
    this.spinner.show();
    if (this.createCustomerAgreements.valid) {
      const agreementDate = this.createCustomerAgreements.get('agreementDate').value;
      this.agreementDate = this.datePipe.transform(agreementDate, "yyyy-MM-dd hh:mm:ss");
      const thruDate = this.createCustomerAgreements.get('thruDate').value;
      this.thruDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");
      const fromDate = this.createCustomerAgreements.get('fromDate').value;
      this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");
      const requestData = {
        agreementDate: this.agreementDate,
        agreementTypeId: this.createCustomerAgreements.value.agreementTypeId.agreementTypeId,
        description: this.createCustomerAgreements.value.description,
        fromDate: this.fromDate,
        partyIdFrom: this.createCustomerAgreements.value.partyIdFrom.partyId,
        partyIdTo: this.createCustomerAgreements.value.partyIdTo.partyId,
        productId: this.createCustomerAgreements.value.productId.productId,
        roleTypeIdFrom: this.createCustomerAgreements.value.roleTypeIdFrom.roleTypeId,
        roleTypeIdTo: this.createCustomerAgreements.value.roleTypeIdTo.roleTypeId,
        textData: this.createCustomerAgreements.value.textData,
        thruDate: this.thruDate,
        agreementId: "" ,
        partyId: this.partyId,

          
      };
      this.addSkillService.createAgreements(requestData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.closebutton3.nativeElement.click();
          this.getAgreementTypeList();
         // this.router.navigate([`/financial/${this.agreementType}/customer-agreeement`]);
        } }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          } } });
    } else {
      this.spinner.hide();
      this.toastr.error('Please fill required field');
    }}
    updateAgree(product){
this.productAgree = product;
setTimeout(() => {
  const formValue = this.createCustomerAgreements;
  formValue.patchValue({
    manifestCompanyName: product.data.manifestCompanyName,

    statusId: product.data.manifestCompanyName,
    agreementTypeId: product.data.manifestCompanyName,
    agreementDate: product.data.manifestCompanyName,
    description: product.data.manifestCompanyName,
    fromDate: product.data.manifestCompanyName,
    partyIdFrom: product.data.manifestCompanyName,
    partyIdTo: product.data.manifestCompanyName,
    productId: product.data.manifestCompanyName,
    roleTypeIdFrom: product.data.manifestCompanyName,
    roleTypeIdTo: product.data.manifestCompanyName,
    textData: product.data.manifestCompanyName,
    thruDate: product.data.manifestCompanyName,
  })

}, 3000);

    }
    updateAgreements(): void {
      this.spinner.show();
      if (this.createCustomerAgreements.valid) {
        const agreementDate = this.createCustomerAgreements.get('agreementDate').value;
        this.agreementDate = this.datePipe.transform(agreementDate, "yyyy-MM-dd hh:mm:ss");
        const thruDate = this.createCustomerAgreements.get('thruDate').value;
        this.thruDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");
        const fromDate = this.createCustomerAgreements.get('fromDate').value;
        this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");
        const requestData = {
          agreementDate: this.agreementDate,
          agreementTypeId: this.createCustomerAgreements.value.agreementTypeId.agreementTypeId,
          description: this.createCustomerAgreements.value.description,
          fromDate: this.fromDate,
          partyIdFrom: this.createCustomerAgreements.value.partyIdFrom.partyId,
          partyIdTo: this.createCustomerAgreements.value.partyIdTo.partyId,
          productId: this.createCustomerAgreements.value.productId.productId,
          roleTypeIdFrom: this.createCustomerAgreements.value.roleTypeIdFrom.roleTypeId,
          roleTypeIdTo: this.createCustomerAgreements.value.roleTypeIdTo.roleTypeId,
          textData: this.createCustomerAgreements.value.textData,
          thruDate: this.thruDate,
          agreementId: this.productAgree.agreementId ,
          partyId: this.partyId,
  
            
        };
        this.addSkillService.updateAgreements(requestData).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success("Updated Sucessfully");
            this.spinner.hide();
            this.closebutton3.nativeElement.click();
            this.getAgreementTypeList();
           // this.router.navigate([`/financial/${this.agreementType}/customer-agreeement`]);
          } }, (err) => {
          this.spinner.hide();
          if (err.error.errors) {
            for (const value of err.error.errors) {
              this.toastr.error(value.fieldName + ' ' + value.fieldError);
            } } });
      } else {
        this.spinner.hide();
        this.toastr.error('Please fill required field');
      }}
  downloadDocument(attachDocumentId) {
    this.spinner.show();
    this.addSkillService.downloadDocument(attachDocumentId).subscribe(res => {
      const blob = new Blob([res.data[0].url], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      console.log(blob,"blobb")
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "CONTENT.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  resetDoc(){
    this.uploadDocForm.reset();
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }
  attachDocument() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.addSkillService.attachDocument(formData, this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Attach Document Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.getAttachDocumentByPartyId();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }
  getAttachDocumentByPartyId() {
    this.spinner.show();
    this.addSkillService.getAttachDocumentByPartyId(this.partyId).subscribe(res => {
      this.attachDocumentId = res.data[0].getAttachDocumentByPartyId;
      this.spinner.hide();
     
    })

  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;
      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/vendor/Dashboard']);
  }
  

  editVendorsData() {
    this.spinner.show();
    this.accountsService.getVendorListById(this.partyId).subscribe((res: any) => {
      this.vendor=res.data[0][0].vendor;
      this.supplier=res.data[1][0].getWebUlSupplier[0];
      this.emailSupplier=res.data[2][0].getEmailSupplier[0];
      this.addressSupplier=res.data[3][0].getAddressSupplier[0];
      this.InformationSupplier=res.data[4][0].getContactInformationSupplier[0]

      setTimeout(() => {
        const formValue = this.createVendor;
        formValue.patchValue({
          accountNo: this.vendor.accountNo,
          billingRateLevel: this.vendor.billingRateLevel,
          company: this.vendor.company,
          companyWebsite: this.vendor.companyWebsite,
          contactMechPurposeTypeId: this.supplier.contactMechPurposeTypeId,
          creditLimit: this.vendor.creditLimit,
          eligibleFor: this.vendor.eligibleFor,
          federalTaxId: this.vendor.federalTaxId,
          generalAddress1: this.addressSupplier.generalAddress1,
          generalAddress2: this.addressSupplier.generalAddress2,
          generalAttnName: this.addressSupplier.generalAttnName,
          generalCity: this.addressSupplier.generalCity,
          generalCountryGeoId: this.addressSupplier.generalCountryGeoId,
          generalPostalCode: this.addressSupplier.generalPostalCode,
          generalStateProvinceGeoId: this.addressSupplier.generalStateProvinceGeoId,
          generalToName: this.addressSupplier.generalToName,
          groupName: this.vendor.groupName,
          individual: this.vendor.individual,
          isActive: this.vendor.isActive,
          isIncorporated: this.vendor.isIncorporated,
          manifestCompanyName:  this.vendor.manifestCompanyName,
          manifestCompanyTitle: this.vendor.manifestCompanyTitle,
          manifestLogoUrl: this.vendor.manifestLogoUrl,
          manifestPolicies: this.vendor.manifestPolicies,
          map: this.vendor.map,
          name: this.vendor.name,
          partyId: this.vendor.partyId,
          phone: this.vendor.phone,
          primaryEmail: this.emailSupplier.primaryEmail,
          primaryFaxAreaCode: this.InformationSupplier.primaryFaxAreaCode,
          primaryFaxCountryCode: this.InformationSupplier.primaryFaxCountryCode,
          primaryFaxExtension: this.InformationSupplier.primaryFaxExtension,
          primaryFaxNumber: this.InformationSupplier.primaryFaxNumber,
          primaryPhoneAreaCode: this.InformationSupplier.primaryPhoneAreaCode,
          primaryPhoneCountryCode: this.InformationSupplier.primaryPhoneCountryCode,
          primaryPhoneExtension: this.InformationSupplier.primaryPhoneExtension,
          primaryPhoneNumber: this.InformationSupplier.primaryPhoneNumber,
          primaryWebUrl: this.supplier.primaryWebUrl,
          printCheckAs: this.vendor.printCheckAs,
          requires1099: this.vendor.requires1099,
          taxId: this.vendor.taxId,
          terms: this.vendor.terms,
          type: this.vendor.type,
          typeVendor: this.vendor.typeVendor,
          vendorEmail: this.vendor.vendorEmail,
          vendorFax: this.vendor.vendorFax,
          vendorForms: this.vendor.vendorForms,
          vendorName: this.vendor.vendorName,
          vendorPhone: this.vendor.vendorPhone,
          webUrlId: this.supplier.webUrlId,
          PrimaryWebUrl: this.supplier.PrimaryWebUrl,
        })

      }, 3000);
      this.spinner.hide();
    })
  }

  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  getVendors() {
    this.spinner.show();
    const req = {
      "manifestCompanyName": "",
      "manifestCompanyNameSearchType": "Contains",
      "manifestCompanyTitle": "",
      "manifestCompanyTitleSearchType": "Contains",
      "manifestLogoUrl": "",
      "manifestLogoUrlSearchType": "Contains",
      "manifestPolicies": "",
      "manifestPoliciesSearchType": "Contains",
      "partyId": this.partyId,
      "partyIdSearchType": "Contains"
    }
    this.accountsService.getVendors(this.applicationSize, req).subscribe((res: any) => {
      this.listDistributors = res.data[0];

      this.spinner.hide();

    })

  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "manifestCompanyName": this.createVendor.value.manifestCompanyName,
      "manifestCompanyTitle": this.createVendor.value.manifestCompanyTitle,
      "manifestLogoUrl": this.createVendor.value.manifestLogoUrl,
      "manifestPolicies": this.createVendor.value.manifestPolicies,
      "partyId": this.createVendor.value.partyId,
      "accountNo": this.createVendor.value.accountNo,
      "billingRateLevel": this.createVendor.value.billingRateLevel,
      "creditLimit": this.createVendor.value.creditLimit,
      "eligibleFor": this.createVendor.value.eligibleFor,
      "printCheckAs": this.createVendor.value.printCheckAs,
      "taxId": this.createVendor.value.taxId,
      "terms": this.createVendor.value.terms,
      "type": this.createVendor.value.type,
      "company": this.createVendor.value.company,
      "companyWebsite": this.createVendor.value.companyWebsite,
      "individual": this.createVendor.value.individual,
      "isActive": this.createVendor.value.isActive,
      "map": this.createVendor.value.map,
      "name": this.createVendor.value.name,
      "phone": this.createVendor.value.phone,
      "typeVendor": "distributor",
      "vendorEmail": this.createVendor.value.vendorEmail,
      "vendorFax": this.createVendor.value.vendorFax,
      "vendorForms": this.createVendor.value.vendorForms,
      "vendorName": this.createVendor.value.vendorName,
      "vendorPhone": this.createVendor.value.vendorPhone,
      "contactMechPurposeTypeId": this.createVendor.value.contactMechPurposeTypeId,
      "federalTaxId": this.createVendor.value.federalTaxId,
      "generalAddress1": this.createVendor.value.generalAddress1,
      "generalAddress2": this.createVendor.value.generalAddress2,
      "generalAttnName": this.createVendor.value.generalAttnName,
      "generalCity": this.createVendor.value.generalCity,
      "generalCountryGeoId": this.createVendor.value.generalCountryGeoId,
      "generalPostalCode": this.createVendor.value.generalPostalCode,
      "generalStateProvinceGeoId": this.createVendor.value.generalStateProvinceGeoId,
      "generalToName": this.createVendor.value.generalToName,
      "groupName": this.createVendor.value.groupName,
      "infoString": this.createVendor.value.infoString,
      "isIncorporated": this.createVendor.value.isIncorporated,
      "primaryEmail": this.createVendor.value.primaryEmail,
      "primaryFaxAreaCode": this.createVendor.value.primaryFaxAreaCode,
      "primaryFaxCountryCode": this.createVendor.value.primaryFaxCountryCode,
      "primaryFaxExtension": this.createVendor.value.primaryFaxExtension,
      "primaryFaxNumber": this.createVendor.value.primaryFaxNumber,
      "primaryPhoneAreaCode": this.createVendor.value.primaryPhoneAreaCode,
      "primaryPhoneCountryCode": this.createVendor.value.primaryPhoneCountryCode,
      "primaryPhoneExtension": this.createVendor.value.primaryPhoneExtension,
      "primaryPhoneNumber": this.createVendor.value.primaryPhoneNumber,
      "primaryWebUrl": this.createVendor.value.primaryWebUrl,
      "requires1099": this.createVendor.value.requires1099,
      "webUrlId":this.createVendor.value.webUrlId,
      
    }
    this.accountsService.updateVendors(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Updated Successfully");
        this.closebutton1.nativeElement.click();
        this.getVendors();
        this.editVendorsData();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  cancelSubmit(){
    this.router.navigate(["/vendor/list-vendor"])
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  changeactiveCategoryFunctionA(tab: number) {
    this.activeCategoryA = tab;
  }
}
