<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Find Maintenances</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">

                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                    <span class="headingAccords"> Search</span>
                                                    <button type="button" class="btn btn-outline-secondary ml-2" data-toggle="modal" data-target="#exampleModalCenter">Create Maintenances</button>

                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 72%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>									
        
                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                                </div>
                
                
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control" id="exampleInputEmail1" name="fixedAssetId" [(ngModel)]="advanceSearch.fixedAssetId" [ngModelOptions]="{standalone:true}" aria-describedby="emailHelp" placeholder="Enter Fixed Asset ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Maint Hist Seq ID</label>
                                                                </div>
                
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control" name="MainHistSeqID" [(ngModel)]="advanceSearch.MainHistSeqID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Maint Hist Seq ID">
                                                                </div>
                
                
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Maintenance Type</label>
                                                                </div>
                
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" name="MaintenanceType" [(ngModel)]="advanceSearch.MaintenanceType" [ngModelOptions]="{standalone:true}" placeholder="Enter Maintenance Type" [options]="MaintenanceTypeArray" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1"> Status</label>
                                                                </div>
                
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" name="Status" [options]="StatusListArray" optionlabel="label" [(ngModel)]="advanceSearch.Status" [ngModelOptions]="{standalone:true}" placeholder="Enter Status">
                
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Actual Start Date
                                                                            </label>
                                                                </div>
                                                                <div class="col-lg-2">
                
                
                                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.startDate" [ngModelOptions]="{standalone:true}" name="startDate">
                                                                </div>
                
                                                                <div class="col-lg-2">
                
                                                                    <input type="date" class="form-control" name="userCreated_fld1_value">
                
                
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                
                                                    </div>

                                                    <div class="col-lg-12 col-12 main-submit-button" style="    margin-left: -29%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceFixedMaints()">Find</button>&nbsp;
                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                    </div>
                                                

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>

                                <!-- <div class="w3-card-4 classCard">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                        <button type="button" class="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalCenter">Create Maintenances</button>

                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Fixed Asset ID</label>
                                                </div>


                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1" name="fixedAssetId" [(ngModel)]="advanceSearch.fixedAssetId" [ngModelOptions]="{standalone:true}" aria-describedby="emailHelp" placeholder="Enter Fixed Asset ID">
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1">Maint Hist Seq ID</label>
                                                </div>

                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" name="MainHistSeqID" [(ngModel)]="advanceSearch.MainHistSeqID" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Maint Hist Seq ID">
                                                </div>


                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Maintenance Type</label>
                                                </div>

                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" name="MaintenanceType" [(ngModel)]="advanceSearch.MaintenanceType" [ngModelOptions]="{standalone:true}" placeholder="Enter Maintenance Type" [options]="MaintenanceTypeArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group classInputA">
                                                    <label for="exampleInputEmail1"> Status</label>
                                                </div>

                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" name="Status" [options]="StatusListArray" optionlabel="label" [(ngModel)]="advanceSearch.Status" [ngModelOptions]="{standalone:true}" placeholder="Enter Status">

                                                    </p-dropdown>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Actual Start Date
                                                            </label>
                                                </div>
                                                <div class="col-lg-2">


                                                    <input type="date" class="form-control" [(ngModel)]="advanceSearch.startDate" [ngModelOptions]="{standalone:true}" name="startDate">
                                                </div>

                                                <div class="col-lg-2">

                                                    <input type="date" class="form-control" name="userCreated_fld1_value">


                                                </div>

                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button" style="    margin-left: -29%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceFixedMaints()">Find</button>&nbsp;
                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                    </div>
                                </div> -->
                                
                                <br>

                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin" style="margin-left: 11px;">
                                                Search Results
                                            </h4>

                                        </div>
                                    </header>
                                    <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">

                                                <div class="card own-account-table">
                                                    <p-table [value]="FixedAssetMaints" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                    <div class="checkbox-align" style="color: white;">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Maint Hist Seq ID
                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                    <div style="color: white;"> Fixed Asset Name
                                                                        <p-sortIcon field="name"></p-sortIcon>
                                                                    </div>

                                                                </th>

                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                    <div style="color: white;"> Maintenance Type
                                                                        <p-sortIcon field="quantity"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Status
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;">Interval Meter Type
                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Interval Quantity
                                                                        <p-sortIcon field="price">
                                                                        </p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Interval Uom
                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Actual Start Date
                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                    </div>
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                    <div style="color: white;"> Actual Completion Date
                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                    </div>
                                                                </th>



                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'200px'}" (click)="accountDetails(product.findFixedAssetMaint.fixedAssetId,product.findFixedAssetMaint.maintHistSeqId,product.findFixedAssetMaint.scheduleWorkEffortId,product.fixedAssetName[0].instanceOfProductId)">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span  class="account-button">{{product.findFixedAssetMaint.maintHistSeqId}}</span>
                                                                    </div>
                                                                </td>

                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.fixedAssetName[0].fixedAssetName}}</td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.findFixedAssetMaint.productMaintTypeId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.findFixedAssetMaint.statusId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}"> {{product.findFixedAssetMaint.intervalMeterTypeId}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}"> {{product.findFixedAssetMaint.intervalQuantity}}

                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}"> {{product.findFixedAssetMaint.intervalUomId}}

                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.actualStartDate|date:'yyyy-MM-dd hh:mm:ss'}}

                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    {{product.actualCompletionDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                </td>


                                                        </ng-template>
                                                    </p-table>
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Add Fixed Asset Maintenance</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                            Add Fixed Asset Maintenance
           
           
                       </a></li>
                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createTaskList">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                         
                                                         Estimated Start Date </label>
                                                    </div>

                                                    <div class="col-lg-3">

                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="EstimatedStartDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                  
                                                         Estimated Completion Date</label>
                                                    </div>

                                                    <div class="col-lg-3">

                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="EstimatedCompletionDate">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                         Status</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Status" filter="true" [options]="StatusListArray" optionlabel="label" formControlName="Status">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Maintenance Type </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Maintenance Type" [options]="MaintenanceTypeArray" optionlabel="label" filter="true" formControlName="MaintenanceType">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                         Product Maintenance</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Product Maintenance" filter="true" formControlName="ProductMaintenance">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Interval Meter Type </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Interval Meter Type" filter="true" [options]="intervalMeterTypeArray" optionlabel="label" formControlName="IntervalMeterType">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                         Maintenance Template</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Maintenance Template" filter="true" [options]="MaintenanceTemplateIdArray" optionlabel="label" formControlName="MaintenanceTemplate">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Purchase Order ID </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Purchase Order ID" filter="true" [options]="PurchaseOrderIdArray" optionlabel="label" formControlName="PurchaseOrderID">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                         Interval Quantity</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Interval Quantity" formControlName="IntervalQuantity">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Party ID" filter="true" [options]="partyIdArray" optionlabel="label" formControlName="PartyID">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                         Interval Uom</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Interval Uom" filter="true" [options]="IntervalUomArray" optionlabel="label" formControlName="IntervalUom">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                               
                                                         Actual Completion Date</label>
                                                    </div>

                                                    <div class="col-lg-3">

                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="ActualCompletionDate">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                      
                                                         Actual Start Date </label>
                                                    </div>

                                                    <div class="col-lg-3">

                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern" formControlName="ActualStartDate">
                                                    </div>




                                                </div>
                                            </div>
                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 82%;">

                                        <button type="submit" (click)="createFixedAssetMaintUpdateWorkEffort()" class="btn btn-secondary submit-btn">Save</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>