<div class="container-fluid main-container-wrapper">
    <br>

    <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
           <span class="color-black pl-1 titlePanels">General Ledger Setting

            </span>
        </div>
        <div class="w3-card-4 classCard classCardType">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                    <li [ngClass]="activeCategory==1?'active':''" (click)="chartAccount()"><a>
                    Charts of Account</a></li>
                    <li [ngClass]="activeCategory==2?'active':''" (click)="timePeriod()"><a>
                    Custom Time Period</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="cost()"><a>
                        Cost</a></li>
                    <li [ngClass]="activeCategory==4?'active':''" (click)="paymentMethod()"><a>
                        Payment Method Type</a></li>
                    <li [ngClass]="activeCategory==5?'active':''" (click)="invoiceMethod()"><a>
                            Invoice Item Type</a></li>
                    <li [ngClass]="activeCategory==6?'active':''" (click)="rate()"><a>
                            Rates</a></li>
                    <li [ngClass]="activeCategory==7?'active':''" (click)="foreignExc()"><a>
                                Foreign Exchange Rates</a></li>
                    <li [ngClass]="activeCategory==8?'active':''" (click)="accountCat()"><a>
                                Gl Account Category</a></li>
                    <li [ngClass]="activeCategory==9?'active':''" (click)="costCenter()"><a>
                                    Cost Center</a></li>

                </ul>
            </div>

            <div class="create-new-leade mt-2">
                <div class="container-fluid">


                    <div class="panel-group">
                        <div *ngIf="activeCategory==8">


                            <div class="panel-collapse">
                                <br>
                                <div class="create-new-leade mt-2">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100">

                                                <div class="w3-card-4  w-100">
                                                    <div class="accordion classCard" id="myAccordion">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="headingOne">
                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn marginHeader" data-toggle="modal" data-target="#exampleModalCenter" >Create New Gl Account Category</button>

                                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 71%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;">
                                                                    </button>									
                        
                                                                </div>
                            
                                                            </h2>
                                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                <div class="card-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">GL Account Category ID</label>
                                                                                </div>
                
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" [(ngModel)]="advanceSearch.glAccountCategoryIdSearchType" [ngModelOptions]="{standalone:true}" name="glAccountCategoryIdSearchType"></p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" style="width: 100%;" name="groupName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter GL Account Category ID" [(ngModel)]="advanceSearch.glAccountCategoryId" [ngModelOptions]="{standalone:true}"
                                                                                        name="glAccountCategoryId">
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                    <label for="exampleInputEmail1">GL Account Category Type</label>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" placeholder="Enter GL Account Category Type" [(ngModel)]="advanceSearch.glAccountCategoryTypeId" [ngModelOptions]="{standalone:true}" name="glAccountCategoryTypeId" [options]="glAccountCategoryTypeArray" optionlabel="label">
                
                                                                                    </p-dropdown>
                                                                                </div>
                
                
                
                                                                            </div>
                                                                        </div>
                
                
                                                                    </div>
                        
                                                                    <div class="col-lg-12 col-12 main-submit-button" style="    margin-left: -23%; "><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger ml-2" (click)="reset();">Reset</button></div>
                                                                
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                <!-- <div class="w3-card-4 classCard">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New Gl Account Category</button>
                                                         <button type="button" class="btn btn-outline-secondary" routerLinkActive="active"
                                                                    (click)="createAccountCategory()">Create New Gl Account Category</button> 

                                                    </div><br>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">GL Account Category ID</label>
                                                                </div>

                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" [(ngModel)]="advanceSearch.glAccountCategoryIdSearchType" [ngModelOptions]="{standalone:true}" name="glAccountCategoryIdSearchType"></p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" style="width: 100%;" name="groupName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter GL Account Category ID" [(ngModel)]="advanceSearch.glAccountCategoryId" [ngModelOptions]="{standalone:true}"
                                                                        name="glAccountCategoryId">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">GL Account Category Type</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" placeholder="Enter GL Account Category Type" [(ngModel)]="advanceSearch.glAccountCategoryTypeId" [ngModelOptions]="{standalone:true}" name="glAccountCategoryTypeId" [options]="glAccountCategoryTypeArray" optionlabel="label">

                                                                    </p-dropdown>
                                                                </div>



                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div class="col-lg-12 col-12 main-submit-button" style="    margin-left: -23%; "><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger ml-2" (click)="reset();">Reset</button></div>
                                                
                                                    </div> -->
                                                <br>

                                                <div class="w3-card-4 classCard">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Gl Account Category List
                                                            </h4>

                                                        </div>
                                                    </header>
                                                    <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">

                                                                <div class="card own-account-table">
                                                                    <p-table [value]="GlAccountCategories" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>


                                                                                        GL Account Category ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> GL Account Category Type ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Description
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}" (click)="summaryDetail(product.glAccountCategoryId,product.glAccountCategoryTypeId,product.description);">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.glAccountCategoryId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.categoryType}}
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.description}}
                                                                                </td>



                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>


                </div>
            </div>
        </div>
    </div>
</div>

<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1" *ngIf="!show">Add Gl Account Category
            </span>
                <span class="color-black pl-1" *ngIf="show">Update Gl Account Category
            </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                   <span aria-hidden="true" #closebutton>&times;</span>
               </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==8?'active':''" *ngIf="!show"><a>
                        Add Gl Account Category</a></li>
                                <li [ngClass]="activeCategory==8?'active':''" *ngIf="show"><a>
                            Update Gl Account Category</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createAccountCategory">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">GL Account Category Type<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter GL Account Category Type" filter="true" [options]="glAccountCategoryTypeArray" optionlabel="label" formControlName="glAccountCategoryTypeId">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Description
                                               
                                                </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="description">

                                                    </div>



                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 87%;">
                                        <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>