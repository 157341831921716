import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-quote-from-request-list',
  templateUrl: './create-quote-from-request-list.component.html',
  styleUrls: ['./create-quote-from-request-list.component.css']
})
export class CreateQuoteFromRequestListComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;
  @ViewChild('closebutton11') closebutton11;
  activeCategory = 1;
  activeCategoryA = 1;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  quoteId: any;
  QuoteAttribute: any;
  MainQuote: any;
  QuoteRoles: any;
  custRequestId: any;
  WorkEfforts: any;
  issueDate: string;
  validThruDate: string;
  validFromDate: string;
  quoteForm: FormGroup;
  QuoteSkillTypeId: any;
  QuoteSkillTypeIdArray: any[] = [];
  QuoteItemLists: any;
  ProductProductStore: any;
  ProductProductStoreArray: any[] = [];
  QuoteTypeIdList: any;
  QuoteTypeIdListArray: any[] = [];
  QuoteUomIdArray: any[] = [];
  QuoteUomId: any;
  attributeForm: FormGroup;
  ChannelId: any;
  ChannelIdArray: any[] = [];
  personId: any;
  partyIdArray: any[] = [];
  statusArray: { label: string; value: string; }[];
  showAttribute: boolean;
  updateQuoteForm: FormGroup;
  QuoteCoefficient: any;
  QuoteCoefficientForm: FormGroup;
  showCoefficient: boolean;
  quoteCoefficient: any;
  quoteReportMailForm: FormGroup;
  DuplicateQuote: FormGroup;
  QuoteItemForm: FormGroup;
  ProductId: any;
  ProductIdArray: any[] = [];
  estimatedDeliveryDate: string;
  reservStart: string;
  updateQuoteItemForm: FormGroup;
  ProductFeatures: any;
  ProductFeaturesArray: any[] = [];
  QuoteTermForm: FormGroup;
  quoteTermList: any;
  showTerm: boolean;
  quoteRole: any;
  quoteRoleList: any;
  QuoteRoleForm: FormGroup;
  showRole: boolean;
  RoleTypeId: any;
  RoleTypeIdArray: any[] = [];
  noteForm: FormGroup;
  quoteNotesList: any;
  noteId: any;
  showNote: boolean;
  quoteAdjustmentByIdAndAllList: any;
  quoteAdjustmentId: any;
  QuoteAdjustmentByIdsList: any;
  quoteAdjustmentsForm: FormGroup;
  LastModifiedDate: string;
  yesNOArray: { label: string; value: string; }[];
  showAdjustment: boolean;
  quoteAdjustmentType: any;
  quoteAdjustmentTypeArray: any[] = [];
  AutoUpdateQuoteList: any;
  autoUpdateQuotePricesList: any;
  statusID: any;
  viewQuoteProfit: any;
  viewQuoteProfitSummary: any;
  quoteProfitForm: FormGroup;
  showQuote: boolean;
  qoteItem: any;
  workEffortId: any;
  quoteWorkEffort: any;
  actualcompletiondate: string;
  actualStartDate: string;
  estimatedCompletionDate: string;
  estimatedStartDate: string;
  addWorkEffort: FormGroup;
  Purpose: any;
  Type: any;
  TypeArray: any[] = [];
  PurposeArray: any[] = [];
  CurrencyList: any;
  CurrencyListArray: any[] = [];
  TemporalExpressionArray: any[] = [];
  TemporalExpression: any;
  StatusNewWorkEffortArray: any[] = [];
  StatusNewWorkEffort: any;
  CommunicationEventId: any;
  CommunicationEventIdArray: any[] = [];
  FacilityIdList: any;
  FacilityIdListArray: any[] = [];
  RequirementList: any;
  RequirementListArray: any[] = [];
  PriorityArray: { label: string; value: string; }[];
  scopeArray: { label: string; value: string; }[];
  statusarray: { label: string; value: string; }[];
  quoteItemSeqId: any;
  quoteItemById: any;
  TermTypeID: any;
  TermTypeIDArray: any[]=[];
  QuoteItemSeqIDArray: any[]=[];
  QuoteItemSeqID: any;
  MainQuoteList: any;

  constructor(public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly myContactsService: MyContactsService,
    private _location: Location,
    readonly accountsService: AccountsService,
    readonly addSkillService: AddSkillService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly router: Router) {
    this.quoteForm = this._FormBuilder.group({
      QuoteTypeID: "",
      PartyID: "",
      QuoteIssueDate: "",
      Status: "",
      Currency: "",
      ProductProductStore: "",
      SalesChannel: "",
      ValidFromDate: "",
      QuoteName: "",
      ValidThroughDate: "",
      Description: "",
    })
    this.updateQuoteForm = this._FormBuilder.group({
      quoteId: "",
      QuoteTypeID: "",
      PartyID: "",
      QuoteIssueDate: "",
      Status: "",
      Currency: "",
      ProductProductStore: "",
      SalesChannel: "",
      ValidFromDate: "",
      QuoteName: "",
      ValidThroughDate: "",
      Description: "",
    })
    this.attributeForm = this._FormBuilder.group({
      attrDescription: "",
      attrName: "",
      attrValue: "",
    })
    this.noteForm = this._FormBuilder.group({
      NoteName: "",
      NoteInfo: "",
    })
    this.QuoteCoefficientForm = this._FormBuilder.group({
      coeffName: "",
      coeffValue: "",
    })
    this.quoteReportMailForm = this._FormBuilder.group({
      SendTo: "",
      SendCc: "",
      Note: "",
    })
    this.DuplicateQuote = this._FormBuilder.group({
      QuoteItems: "",
      Adjustments: "",
      QuoteRoles: "",
      QuoteAttributes: "",
      Coefficients: "",
      QuoteTerm: "",

    })
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.statusArray = [

      {
        label: 'Created',
        value: 'QUO_CREATED'
      },
      {
        label: 'Approved',
        value: 'QUO_APPROVED'
      },
      {
        label: 'Ordered',
        value: 'QUO_ORDERED'
      },
      {
        label: 'Rejected',
        value: 'QUO_REJECTED'
      },
    ];
    this.QuoteItemForm = this._FormBuilder.group({
      comments: "",
      configId: "",
      custRequestId: "",
      custRequestItemSeqId: "",
      deliverableTypeId: "",
      estimatedDeliveryDate: "",
      leadTimeDays: "",
      productFeatureId: "",
      productId: "",
      quantity: "",
      quoteItemSeqId: "",
      quoteUnitPrice: "",
      reservLength: "",
      reservPersons: "",
      reservStart: "",
      selectedAmount: "",
      skillTypeId: "",
      uomId: "",
      workEffortId: ""

    })
    this.updateQuoteItemForm = this._FormBuilder.group({
      comments: "",
      configId: "",
      custRequestId: "",
      custRequestItemSeqId: "",
      deliverableTypeId: "",
      estimatedDeliveryDate: "",
      leadTimeDays: "",
      productFeatureId: "",
      productId: "",
      quantity: "",
      quoteItemSeqId: "",
      quoteUnitPrice: "",
      reservLength: "",
      reservPersons: "",
      reservStart: "",
      selectedAmount: "",
      skillTypeId: "",
      uomId: "",
      workEffortId: ""

    })
    this.QuoteTermForm = this._FormBuilder.group({
      description: "",
      quoteItemSeqId: "",
      termDays: "",
      termTypeId: "",
      termValue: "",
      textValue: "",
      uomId: ""
    })
    this.QuoteRoleForm = this._FormBuilder.group({
      Party: "",
      RoleType: "",
      FromDate: "",
      ThroughDate: "",
    })
    this.quoteAdjustmentsForm = this._FormBuilder.group({
      QuoteAdjustmentID: "",
      QuoteAdjustmentType: "",
      QuoteItemSeqID: "",
      Description: "",
      Amount: "",
      CorrespondingProductID: "",
      ExemptAmount: "",
      SourcePercentage: "",
      IncludeInTax: "",
      IncludeInShipping: "",
      LastModifiedDate: "",
      LastModifiedByUser: "",
    })
    this.quoteProfitForm = this._FormBuilder.group({
      QuoteItemSeqID: "",
      ProductProductId: "",
      Quantity: "",
      AverageCost: "",
      CosttoPrice: "",
      DefaultUnitPrice: "",
      QuoteUnitPrice: "",
      ManualUnitPrice: "",
      Selected: "",
    })
    this.addWorkEffort = this._FormBuilder.group({
      quickAssignPartyID: [''],
      name: [''],
      description: [''],
      type: [''],
      purpose: [''],
      currency: [''],
      status: [''],
      percentComplete: [''],
      priority: [''],
      scope: [''],
      estimatedStartDate: [''],
      estimatedCompletionDate: [''],
      actualStartDate: [''],
      actualCompletionDate: [''],
      communicationEventID: [''],
      reason: [''],
      webSiteID: [''],
      workEffortParentID: [''],
      showAsEnumID: [''],
      sendNotificationEmail: [''],
      locationDesc: [''],
      estimatedMilliSeconds: [''],
      estimatedSetupMilliSeconds: [''],
      estimatedCalcMethod: [''],
      actualMilliSeconds: [''],
      actualSetupMilliSeconds: [''],
      totalMilliSecondsAllowed: [''],
      totalMoneyAllowed: [''],
      specialTerms: [''],
      timeTransparency: [''],
      universalID: [''],
      sourceReferenceID: [''],
      fixedAssetID: [''],
      facilityID: [''],
      infoURL: [''],
      temporalExpression: [''],
      serviceLoaderName: [''],
      quantityToProduce: [''],
      quantityProduced: [''],
      quantityRejected: [''],
      reservPersons: [''],
      reserv2ndPerc: [''],
      reservNthPerc: [''],
      accommodationMapID: [''],
      accommodationSpotID: [''],
      sequenceNum: [''],
      requirementID: [''],
    });
    this.statusarray = [

      {
        label: 'Any',
        value: 'Any'
      },
      {
        label: 'Active',
        value: 'PRODUCT_ACTIVE'
      },
      {
        label: 'Closed',
        value: 'PRODUCT_CLOSED'
      },


    ];
    this.scopeArray = [

      {
        label: 'General, public access',
        value: 'WES_PUBLIC'
      },
      {
        label: 'Restricted, private access',
        value: 'WES_PRIVATE'
      },
      {
        label: 'Very restricted, confidential access',
        value: 'WES_CONFIDENTIAL'
      },
    ];
    this.PriorityArray = [

      {
        label: '1 (High)',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9 (Low)',
        value: '9'
      },

    ];
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.quoteId = params.quoteId;
      this.custRequestId = params.custRequestId;
      this.quoteItemSeqId = params.quoteItemSeqId;
    })

    this.getQuoteAttribute();
    this.getMainQuote();
    this.getChannelId();
    this.getQuoteRoles();
    this.getWorkEfforts();
    this.getQuoteSkillTypeId();
    this.getQuoteTypeIdList();
    this.getQuoteItemLists();
    this.getQuoteUomId();
    this.getProductProductStore();
    this.getPartyId();
    this.getQuoteCoefficient();
    this.getProductProductId();
    this.getProductFeatures();
    this.getQuoteTerm();
    this.getProductFeatures();
    this.getQuoteRole();
    this.getRoleTypeId();
    this.getQuoteNotes();
    this.getQuoteAdjustmentByIdAndAll();
    this.getQuoteAdjustmentByIds();
    this.getQuoteAdjustmentType();
    this.getViewQuoteProfit();
    this.getAutoUpdateQuotePrices();
    this.getAutoUpdateQuote();
    this.getViewQuoteProfitSummary();
    this.getQuoteItem();
    this.getQuoteWorkEffort();
    this.getTemporalExpression();
    this.getRequirementList();
    this.getStatusNewWorkEffort();
    this.getPurpose();
    this.getCurrencyList();
    this.getType();
    this.getFacilityIdList();
    this.getCommunicationEventId();
    this.getQuoteItemSeqID();
    this.getTermTypeID();
  }
  homeButton(){
    this.router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getQuoteItemSeqID() {
    this.spinner.show();
    this.myContactsService.getQuoteItemSeqID(this.quoteId).subscribe(res => {
      this.QuoteItemSeqID = res.data[0].getQuoteItemSeqID;
      this.spinner.hide();
      for (const value of this.QuoteItemSeqID) {
        this.QuoteItemSeqIDArray.push({
          label: value.quoteItemSeqId,
          value: value.quoteItemSeqId,
        })
      }
    })
  }
  getTermTypeID() {
    this.spinner.show();
    this.myContactsService.getTermTypeID().subscribe(res => {
      this.TermTypeID = res.data[0].getTermTypeID;
      this.spinner.hide();
      for (const value of this.TermTypeID) {
        this.TermTypeIDArray.push({
          label: value.description,
          value: value.termTypeId,
        })
      }
    })
  }
  createQuoteWorkEffort(): void {
    this.spinner.show();
    const completiondate = this.addWorkEffort.get('actualCompletionDate').value;
    this.actualcompletiondate = this.datePipe.transform(completiondate, "yyyy-MM-dd hh:mm:ss");
    const StartDate = this.addWorkEffort.get('actualStartDate').value;
    this.actualStartDate = this.datePipe.transform(StartDate, "yyyy-MM-dd hh:mm:ss");
    const estCompletionDate = this.addWorkEffort.get('estimatedCompletionDate').value;
    this.estimatedCompletionDate = this.datePipe.transform(estCompletionDate, "yyyy-MM-dd hh:mm:ss");
    const estStartDate = this.addWorkEffort.get('estimatedStartDate').value;
    this.estimatedStartDate = this.datePipe.transform(estStartDate, "yyyy-MM-dd hh:mm:ss");
    const formData = {

      "accommodationMapId": this.addWorkEffort.value.accommodationMapID,
      "accommodationSpotId": this.addWorkEffort.value.accommodationSpotID,
      "actualCompletionDate": this.actualcompletiondate,
      "actualMilliSeconds": this.addWorkEffort.value.actualMilliSeconds,
      "actualSetupMillis": this.addWorkEffort.value.actualSetupMilliSeconds,
      "actualStartDate": this.actualStartDate,
      "communicationEventId": this.addWorkEffort.value.communicationEventID,
      "currentStatusId": this.addWorkEffort.value.status,
      "description": this.addWorkEffort.value.description,
      "estimateCalcMethod": this.addWorkEffort.value.estimatedCalcMethod,
      "estimatedCompletionDate": this.estimatedCompletionDate,
      "estimatedMilliSeconds": this.addWorkEffort.value.estimatedMilliSeconds,
      "estimatedSetupMillis": this.addWorkEffort.value.estimatedSetupMilliSeconds,
      "estimatedStartDate": this.estimatedStartDate,
      "facilityId": this.addWorkEffort.value.facilityID,
      "fixedAssetId": this.addWorkEffort.value.fixedAssetID,
      "infoUrl": this.addWorkEffort.value.infoURL,
      "locationDesc": this.addWorkEffort.value.locationDesc,
      "moneyUomId": this.addWorkEffort.value.currency,
      "percentComplete": this.addWorkEffort.value.percentComplete,
      "priority": this.addWorkEffort.value.priority,
      "quantityProduced": this.addWorkEffort.value.quantityProduced,
      "quantityRejected": this.addWorkEffort.value.quantityRejected,
      "quantityToProduce": this.addWorkEffort.value.quantityToProduce,
      "quickAssignPartyId": this.addWorkEffort.value.quickAssignPartyID,
      "reason": this.addWorkEffort.value.reason,
      "requirementId": this.addWorkEffort.value.requirementID,
      "reserv2ndPPPerc": this.addWorkEffort.value.reserv2ndPerc,
      "reservNthPPPerc": this.addWorkEffort.value.reservNthPerc,
      "reservPersons": this.addWorkEffort.value.reservPersons,
      "scopeEnumId": this.addWorkEffort.value.scope,
      "sendNotificationEmail": this.addWorkEffort.value.sendNotificationEmail,
      "sequenceNum": this.addWorkEffort.value.sequenceNum,
      "serviceLoaderName": this.addWorkEffort.value.serviceLoaderName,
      "showAsEnumId": this.addWorkEffort.value.showAsEnumID,
      "sourceReferenceId": this.addWorkEffort.value.sourceReferenceID,
      "specialTerms": this.addWorkEffort.value.specialTerms,
      "tempExprId": this.addWorkEffort.value.temporalExpression,
      "timeTransparency": this.addWorkEffort.value.timeTransparency,
      "totalMilliSecondsAllowed": this.addWorkEffort.value.totalMilliSecondsAllowed,
      "totalMoneyAllowed": this.addWorkEffort.value.totalMoneyAllowed,
      "universalId": this.addWorkEffort.value.universalID,
      "webSiteId": this.addWorkEffort.value.webSiteID,
      "workEffortName": this.addWorkEffort.value.name,
      "workEffortParentId": this.addWorkEffort.value.workEffortParentID,
      "workEffortPurposeTypeId": this.addWorkEffort.value.purpose,
      "workEffortTypeId": this.addWorkEffort.value.type,
      "quoteId": this.quoteId,
      "workEffortId": "",

    }
    this.myContactsService.createQuoteWorkEffort(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.addWorkEffort.reset();
        this.getQuoteWorkEffort();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }


  getType() {
    this.spinner.show();
    this.myContactsService.getRequestType().subscribe(res => {
      this.Type = res.data[0].type;
      this.spinner.hide();
      for (const value of this.Type) {
        this.TypeArray.push({
          label: value.description,
          value: value.workEffortTypeId
        })
      }
    })
  }
  getPurpose() {
    this.spinner.show();
    this.myContactsService.getRequestPurpose().subscribe(res => {
      this.Purpose = res.data[0].Purpose;
      this.spinner.hide();
      for (const value of this.Purpose) {
        this.PurposeArray.push({
          label: value.description,
          value: value.workEffortPurposeTypeId
        })
      }
    })
  }
  getCurrencyList() {
    this.spinner.show();
    this.addSkillService.getCurrencyList().subscribe(res => {
      this.CurrencyList = res.data[0].node;
      this.spinner.hide();
      for (const value of this.CurrencyList) {
        this.CurrencyListArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  getTemporalExpression() {
    this.spinner.show();
    this.myContactsService.getRequestTemporalExpression().subscribe(res => {
      this.TemporalExpression = res.data[0].temporalExpression;
      this.spinner.hide();
      for (const value of this.TemporalExpression) {
        this.TemporalExpressionArray.push({
          label: value.tempExprId,
          value: value.tempExprId,
        })
      }
    })
  }
  getStatusNewWorkEffort() {
    this.spinner.show();
    this.addSkillService.getStatusNewWorkEffort().subscribe(res => {
      this.StatusNewWorkEffort = res.data[0].data;
      this.spinner.hide();
      for (const value of this.StatusNewWorkEffort) {
        this.StatusNewWorkEffortArray.push({
          label: value.description,
          value: value.statusValue,
        })
      }
    })
  }
  getCommunicationEventId() {
    this.spinner.show();
    this.addSkillService.getCommunicationEventId().subscribe(res => {
      this.CommunicationEventId = res.data[0].communicationEventId;
      this.spinner.hide();
      for (const value of this.CommunicationEventId) {
        this.CommunicationEventIdArray.push({
          label: value.communicationEventId,
          value: value.communicationEventId
        })
      }
    })
  }
  getFacilityIdList() {
    this.spinner.show();
    this.myContactsService.getRequestFacilityIdList().subscribe(res => {
      this.FacilityIdList = res.data;
      this.spinner.hide();
      for (const value of this.FacilityIdList) {
        this.FacilityIdListArray.push({
          label: value.node1.facilityName,
          value: value.node1.facilityId

        })
      }
    })
  }
  getRequirementList() {
    this.spinner.show();
    this.myContactsService.getRequestRequirementList().subscribe(res => {
      this.RequirementList = res.data[0].list;
      this.spinner.hide();
      for (const value of this.RequirementList) {
        this.RequirementListArray.push({
          label: value.description,
          value: value.requirementId,
        })
      }
    })
  }


  getQuoteWorkEffort() {
    this.spinner.show();
    this.myContactsService.getQuoteWorkEffort(this.quoteId).subscribe(res => {
      this.quoteWorkEffort = res.data[0];
      this.spinner.hide();
    })
  }

  getQuoteItem() {
    this.spinner.show();
    this.myContactsService.getQuoteItem(this.quoteId).subscribe(res => {
      this.qoteItem = res.data;
      this.spinner.hide();
    })
  }
  getViewQuoteProfitSummary() {
    this.spinner.show();
    this.myContactsService.getViewQuoteProfitSummary(this.quoteId).subscribe(res => {
      this.viewQuoteProfitSummary = res.data[0];
      this.spinner.hide();
    })
  }
  getViewQuoteProfit() {
    this.spinner.show();
    this.myContactsService.getViewQuoteProfit(this.quoteId).subscribe(res => {
      this.viewQuoteProfit = res.data;
      this.spinner.hide();
    })
  }


  updatePrice(product) {
    setTimeout(() => {
      const formValue = this.quoteProfitForm;
      formValue.patchValue({
        QuoteItemSeqID: product.data.quoteItemSeqId,
        ProductProductId: product.data.productId,
        Quantity: product.data.quantity,
        AverageCost:  product.averageCost.price,
        CosttoPrice:  product.CostToPrice,
        DefaultUnitPrice: product.DefaultUnitPrice,
        QuoteUnitPrice: product.data.quoteUnitPrice,
        ManualUnitPrice: "",
        Selected: "",
      })

    }, 2000);

  }

  autoUpdateQuotePrices() {
    this.spinner.show();
    const formData = {
      "_rowSubmit": this.quoteProfitForm.value.Selected,
      "averageCost": this.quoteProfitForm.value.AverageCost,
      "costToPriceMult": this.quoteProfitForm.value.CosttoPrice,
      "custRequestId": "",
      "custRequestItemSeqId": "",
      "defaultQuoteUnitPrice": this.quoteProfitForm.value.DefaultUnitPrice,
      "manualQuoteUnitPrice": this.quoteProfitForm.value.ManualUnitPrice,
      "productId": this.quoteProfitForm.value.ProductProductId,
      "quantity": this.quoteProfitForm.value.Quantity,
      "quoteId": this.quoteId,
      "quoteItemSeqId": this.quoteProfitForm.value.QuoteItemSeqID,
      "quoteUnitPrice": this.quoteProfitForm.value.QuoteUnitPrice,
      "submitButton": ""

    }
    this.myContactsService.autoUpdateQuotePrices(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.quoteProfitForm.reset();
        this.closebutton10.nativeElement.click();
        this.getAutoUpdateQuotePrices();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getAutoUpdateQuote() {
    this.spinner.show();
    this.myContactsService.getAutoUpdateQuote(this.quoteId).subscribe(res => {
      this.AutoUpdateQuoteList = res.data[2];
      this.spinner.hide();
    })
  }
  getAutoUpdateQuotePrices() {
    this.spinner.show();
    this.myContactsService.getAutoUpdateQuotePrices(this.quoteId).subscribe(res => {
      this.autoUpdateQuotePricesList = res.data;
      this.spinner.hide();
    })
  }
  getQuoteAdjustmentType() {
    this.spinner.show();
    this.myContactsService.getQuoteAdjustmentType().subscribe(res => {
      this.quoteAdjustmentType = res.data[0].quoteAdjustmentType;
      this.spinner.hide();
      for (const value of this.quoteAdjustmentType) {
        this.quoteAdjustmentTypeArray.push({
          label: value.description,
          value: value.orderAdjustmentTypeId
        })
      }
    })
  }
  removeQuoteAdjustment(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "quoteAdjustmentId": product.data.quoteAdjustmentId,
          "quoteId": this.quoteId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.removeQuoteAdjustment(req).subscribe(res => {
          this.getQuoteTerm();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  autoCreateQuoteAdjustments() {
    this.spinner.show();
    const formData = {
      "quoteId": this.quoteId
    }
    this.myContactsService.autoCreateQuoteAdjustments(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getQuoteAdjustmentByIdAndAll();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  resetAdjustment() {
    this.quoteAdjustmentsForm.reset();
    this.showAdjustment = false;
  }
  updateAdjustment(product) {
    this.showAdjustment = true;
    this.spinner.show();
    setTimeout(() => {
      const formValue = this.quoteAdjustmentsForm;
      formValue.patchValue({
        QuoteAdjustmentID: product.data.quoteAdjustmentId,
        QuoteAdjustmentType: product.data.quoteAdjustmentTypeId,
        QuoteItemSeqID: product.data.quoteItemSeqId,
        Description: product.data.description,
        Amount: product.data.amount,
        CorrespondingProductID: product.data.correspondingProductId,
        ExemptAmount: product.data.exemptAmount,
        SourcePercentage: product.data.sourcePercentage,
        IncludeInTax: product.data.includeInTax,
        IncludeInShipping: product.data.includeInShipping,
        LastModifiedDate: product.data.lastModifiedDate,
        LastModifiedByUser: product.data.lastModifiedByUserLogin,
      })

    }, 2000);
    this.spinner.hide();
  }
  updateQuoteOrderAdjustment() {
    this.spinner.show();
    const lastModifiedDate = this.quoteAdjustmentsForm.get('LastModifiedDate').value;
    this.LastModifiedDate = this.datePipe.transform(lastModifiedDate, "yyyy-MM-dd hh:mm:ss");
    const formData = {

      "amount": this.quoteAdjustmentsForm.value.Amount,
      "comments": "",
      "correspondingProductId": this.quoteAdjustmentsForm.value.CorrespondingProductID,
      "createdByUserLogin": "admin",
      "createdDate": "",
      "customerReferenceId": "",
      "description": this.quoteAdjustmentsForm.value.Description,
      "exemptAmount": this.quoteAdjustmentsForm.value.ExemptAmount,
      "includeInShipping": this.quoteAdjustmentsForm.value.IncludeInShipping,
      "includeInTax": this.quoteAdjustmentsForm.value.IncludeInTax,
      "lastModifiedByUserLogin": this.quoteAdjustmentsForm.value.LastModifiedByUser,
      "lastModifiedDate": this.LastModifiedDate,
      "overrideGlAccountId": "",
      "primaryGeoId": "",
      "productFeatureId": "",
      "productPromoActionSeqId": "",
      "productPromoId": "",
      "productPromoRuleId": "",
      "quoteAdjustmentId": this.quoteAdjustmentsForm.value.QuoteAdjustmentID,
      "quoteAdjustmentTypeId": this.quoteAdjustmentsForm.value.QuoteAdjustmentType,
      "quoteId": this.quoteId,
      "quoteItemSeqId": this.quoteAdjustmentsForm.value.QuoteItemSeqID,
      "secondaryGeoId": "",
      "sourcePercentage": this.quoteAdjustmentsForm.value.SourcePercentage,
      "sourceReferenceId": "",
      "taxAuthGeoId": "",
      "taxAuthPartyId": "",

    }
    this.myContactsService.updateQuoteOrderAdjustment(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.quoteAdjustmentsForm.reset();
        this.closebutton9.nativeElement.click();
        this.getQuoteAdjustmentByIdAndAll();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  createQuoteOrderAdjustment() {
    this.spinner.show();
    const lastModifiedDate = this.quoteAdjustmentsForm.get('LastModifiedDate').value;
    this.LastModifiedDate = this.datePipe.transform(lastModifiedDate, "yyyy-MM-dd hh:mm:ss");
    const formData = {

      "amount": this.quoteAdjustmentsForm.value.Amount,
      "comments": "",
      "correspondingProductId": this.quoteAdjustmentsForm.value.CorrespondingProductID,
      "createdByUserLogin": "admin",
      "createdDate": "",
      "customerReferenceId": "",
      "description": this.quoteAdjustmentsForm.value.Description,
      "exemptAmount": this.quoteAdjustmentsForm.value.ExemptAmount,
      "includeInShipping": this.quoteAdjustmentsForm.value.IncludeInShipping,
      "includeInTax": this.quoteAdjustmentsForm.value.IncludeInTax,
      "lastModifiedByUserLogin": this.quoteAdjustmentsForm.value.LastModifiedByUser,
      "lastModifiedDate": this.LastModifiedDate,
      "overrideGlAccountId": "",
      "primaryGeoId": "",
      "productFeatureId": "",
      "productPromoActionSeqId": "",
      "productPromoId": "",
      "productPromoRuleId": "",
      "quoteAdjustmentId": this.quoteAdjustmentsForm.value.QuoteAdjustmentID,
      "quoteAdjustmentTypeId": this.quoteAdjustmentsForm.value.QuoteAdjustmentType,
      "quoteId": this.quoteId,
      "quoteItemSeqId": this.quoteAdjustmentsForm.value.QuoteItemSeqID,
      "secondaryGeoId": "",
      "sourcePercentage": this.quoteAdjustmentsForm.value.SourcePercentage,
      "sourceReferenceId": "",
      "taxAuthGeoId": "",
      "taxAuthPartyId": ""

    }
    this.myContactsService.createQuoteOrderAdjustment(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.quoteAdjustmentsForm.reset();
        this.closebutton9.nativeElement.click();
        this.getQuoteAdjustmentByIdAndAll();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getQuoteAdjustmentByIdAndAll() {
    this.spinner.show();
    this.myContactsService.getQuoteAdjustmentByIdAndAll(this.quoteId).subscribe(res => {
      this.quoteAdjustmentByIdAndAllList = res.data;
      this.spinner.hide();
    })
  }

  getQuoteAdjustmentByIds() {
    this.spinner.show();
    this.myContactsService.getQuoteAdjustmentByIds(this.quoteId, this.quoteAdjustmentId).subscribe(res => {
      this.QuoteAdjustmentByIdsList = res.data;
      this.spinner.hide();
    })
  }
  updateNote(product) {
    this.showNote = true;
    this.spinner.show();
    setTimeout(() => {
      const formValue = this.noteForm;
      formValue.patchValue({
        NoteName: product.entityQueryNoteInfo[0].noteName,
        NoteInfo: product.entityQueryNoteInfo[0].noteInfo
      })

    }, 2000);
    this.spinner.hide();
  }
  getQuoteNotes() {
    this.spinner.show();
    this.myContactsService.getQuoteNotes(this.quoteId).subscribe(res => {
      this.quoteNotesList = res.data;
      this.spinner.hide();
    })
  }
  updateQuoteNote() {
    this.spinner.show();
    const formData = {
      "noteInfo": this.noteForm.value.NoteInfo,
      "noteName": this.noteForm.value.NoteName,

    }
    this.myContactsService.updateQuoteNote(formData, this.quoteId, this.noteId).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.noteForm.reset();
        this.closebutton8.nativeElement.click();
        this.getQuoteNotes();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  createQuoteNote() {
    this.spinner.show();
    const formData = {
      "noteInfo": this.noteForm.value.NoteInfo,
      "noteName": this.noteForm.value.NoteName,

    }
    this.myContactsService.createQuoteNote(formData, this.quoteId).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.noteForm.reset();
        this.closebutton8.nativeElement.click();
        this.getQuoteNotes();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getRoleTypeId() {
    this.spinner.show();
    this.myContactsService.getRoleTypeId().subscribe(res => {
      this.RoleTypeId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.RoleTypeId) {
        this.RoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  removeQuoteRole(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let formData = {
          "partyId": product.data.partyId,
          "quoteId": this.quoteId,
          "roleTypeId": product.data.roleTypeId,
          "view_INDEX_1": "20",
          "view_SIZE_1": "0"
        }
        this.myContactsService.removeQuoteRole(formData).subscribe(res => {
          this.getQuoteTerm();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  updateRole(product) {
    this.showRole = true;

    this.spinner.show();

    setTimeout(() => {
      const formValue = this.QuoteRoleForm;
      formValue.patchValue({
        Party: product.data.partyId,
        RoleType: product.data.roleTypeId,
        FromDate: this.datePipe.transform(product.data.fromDate, "yyyy-MM-dd hh:mm:ss"),
        ThroughDate: this.datePipe.transform(product.data.thruDate, "yyyy-MM-dd hh:mm:ss")

      })

    }, 2000);
    this.spinner.hide();
  }
  updateQuoteRole() {
    this.spinner.show();
    const formData = {
      "fromDate": this.datePipe.transform(this.QuoteRoleForm.value.FromDate, "yyyy-MM-dd hh:mm:ss"),
      "partyId": this.QuoteRoleForm.value.Party,
      "quoteId": this.quoteId,
      "roleTypeId": this.QuoteRoleForm.value.RoleType,
      "thruDate": this.datePipe.transform(this.QuoteRoleForm.value.ThroughDate, "yyyy-MM-dd hh:mm:ss")
    }
    this.myContactsService.updateQuoteRole(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.QuoteRoleForm.reset();
        this.closebutton7.nativeElement.click();
        this.getQuoteRole();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  resetRole() {
    this.QuoteRoleForm.reset();
    this.showRole = false;
  }

  createQuoteRole() {
    this.spinner.show();
    const formData = {
      "fromDate": this.datePipe.transform(this.QuoteRoleForm.value.FromDate, "yyyy-MM-dd hh:mm:ss"),
      "partyId": this.QuoteRoleForm.value.Party,
      "quoteId": this.quoteId,
      "roleTypeId": this.QuoteRoleForm.value.RoleType,
      "thruDate": this.datePipe.transform(this.QuoteRoleForm.value.ThroughDate, "yyyy-MM-dd hh:mm:ss")
    }
    this.myContactsService.createQuoteRole(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.QuoteTermForm.reset();
        this.closebutton7.nativeElement.click();
        this.getQuoteRole();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getQuoteRole() {
    this.spinner.show();
    this.myContactsService.getQuoteRole(this.quoteId).subscribe(res => {
      this.quoteRoleList = res.data;
      this.spinner.hide();
    })
  }
  resetTerm() {
    this.QuoteTermForm.reset();
    this.showTerm = false;
  }

  updateTerm(product) {
    this.showTerm = true;

    this.spinner.show();

    setTimeout(() => {
      const formValue = this.QuoteTermForm;
      formValue.patchValue({
        description: product.data.description,
        quoteItemSeqId: product.data.quoteItemSeqId,
        termDays: product.data.termDays,
        termTypeId: product.data.termTypeId,
        termValue: product.data.termValue,
        textValue: product.data.textValue,
        uomId: product.data.uomId
      })

    }, 2000);
    this.spinner.hide();
  }
  deleteQuoteTerm(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "quoteItemSeqId": product.data.quoteItemSeqId,
          "quoteId": this.quoteId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20",
          "termTypeId": product.data.termTypeId
        }
        this.myContactsService.deleteQuoteTerm(req).subscribe(res => {
          this.getQuoteTerm();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  getQuoteTerm() {

    this.spinner.show();
    this.myContactsService.getQuoteTerm(this.quoteId).subscribe(res => {
      this.quoteTermList = res.data;
      this.spinner.hide();
    })
  }
  updateQuoteTerm() {
    this.spinner.show();
    const formData = {
      "description": this.QuoteTermForm.value.description,
      "quoteId": this.quoteId,
      "quoteItemSeqId": this.QuoteTermForm.value.quoteItemSeqId,
      "tabButtonItems": "",
      "termDays": this.QuoteTermForm.value.termDays,
      "termTypeId": this.QuoteTermForm.value.termTypeId,
      "termValue": this.QuoteTermForm.value.termValue,
      "textValue": this.QuoteTermForm.value.textValue,
      "uomId": this.QuoteTermForm.value.uomId,

    }
    this.myContactsService.updateQuoteTerm(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.QuoteTermForm.reset();
        this.closebutton6.nativeElement.click();
        this.getQuoteTerm();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  createQuoteTerm() {
    this.spinner.show();
    const formData = {
      "description": this.QuoteTermForm.value.description,
      "quoteId": this.quoteId,
      "quoteItemSeqId": this.QuoteTermForm.value.quoteItemSeqId,
      "tabButtonItems": "",
      "termDays": this.QuoteTermForm.value.termDays,
      "termTypeId": this.QuoteTermForm.value.termTypeId,
      "termValue": this.QuoteTermForm.value.termValue,
      "textValue": this.QuoteTermForm.value.textValue,
      "uomId": this.QuoteTermForm.value.uomId,

    }
    this.myContactsService.createQuoteTerm(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.QuoteTermForm.reset();
        this.closebutton6.nativeElement.click();
        this.getQuoteTerm();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  getProductFeatures() {
    this.spinner.show();
    this.myContactsService.getProductFeatures().subscribe(res => {
      this.ProductFeatures = res.data[0].node;
      this.spinner.hide();
      for (const value of this.ProductFeatures) {
        this.ProductFeaturesArray.push({
          label: value.description,
          value: value.contentAssocTypeId
        })
      }
    })
  }
  sendQuoteReportMail() {
    this.spinner.show();
    const formData = {
      "emailType": "PRDS_QUO_CONFIRM",
      "note": this.quoteReportMailForm.value.Note,
      "quoteId": this.quoteId,
      "sendCc": this.quoteReportMailForm.value.SendCc,
      "sendTo": this.quoteReportMailForm.value.SendTo,

    }
    this.myContactsService.sendQuoteReportMail(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.quoteReportMailForm.reset();
        this.closebutton5.nativeElement.click();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  removeQuoteItem(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "quoteItemSeqId": product.node1.quoteItemSeqId,
          "quoteId": this.quoteId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.removeQuoteItem(req).subscribe(res => {
          this.getQuoteItemLists();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  getProductProductId() {
    this.spinner.show();
    this.myContactsService.getProductProductId().subscribe(res => {
      this.ProductId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
  }
  getQuoteItem_By_QuoteItemSeqIdAndQuoteId() {
    this.spinner.show();
    this.myContactsService.getQuoteItem_By_QuoteItemSeqIdAndQuoteId(this.quoteId, this.quoteItemSeqId).subscribe(res => {
      this.quoteItemById = res.data;
      this.spinner.hide();
    })
  }
  updateItems(product) {
    this.router.navigate(["/psa/project/create-quote-from-request-list"],
      { queryParams: { quoteId: this.quoteId, custRequestId: this.custRequestId, quoteItemSeqId: product.node1.quoteItemSeqId } })
    this.activeCategory = 12;
    this.myContactsService.getQuoteItem_By_QuoteItemSeqIdAndQuoteId(this.quoteId, this.quoteItemSeqId).subscribe(res => {
      this.quoteItemById = res.data;
      this.spinner.show();
      setTimeout(() => {
        const formValue = this.updateQuoteItemForm;
        formValue.patchValue({
          comments: this.quoteItemById.comments,
          configId: this.quoteItemById.configId,
          custRequestId: this.quoteItemById.custRequestId,
          custRequestItemSeqId: this.quoteItemById.custRequestItemSeqId,
          deliverableTypeId: this.quoteItemById.deliverableTypeId,
          estimatedDeliveryDate: this.quoteItemById.estimatedDeliveryDate,
          leadTimeDays: this.quoteItemById.leadTimeDays,
          productFeatureId: this.quoteItemById.productFeatureId,
          productId: this.quoteItemById.productId,
          quantity: this.quoteItemById.quantity,
          quoteItemSeqId: this.quoteItemSeqId,
          quoteUnitPrice: this.quoteItemById.quoteUnitPrice,
          reservLength: this.quoteItemById.reservLength,
          reservPersons: this.quoteItemById.reservPersons,
          reservStart: this.quoteItemById.reservStart,
          selectedAmount: this.quoteItemById.selectedAmount,
          skillTypeId: this.quoteItemById.skillTypeId,
          uomId: this.quoteItemById.uomId,
          workEffortId: this.quoteItemById.workEffortId,
        })

      }, 2000);
    })
    this.spinner.hide();
  }
  updateQuote(product) {
    this.router.navigate(["/psa/project/create-quote-from-request-list"],
      { queryParams: { quoteId: this.quoteId, custRequestId: this.custRequestId, quoteItemSeqId: product.node1.quoteItemSeqId } })
    this.activeCategory = 12;
    this.showQuote = true;

    this.spinner.show();

    setTimeout(() => {
      const formValue = this.QuoteItemForm;
      formValue.patchValue({
        comments: product.node1.comments,
        configId: product.node1.configId,
        custRequestId: product.node1.custRequestId,
        custRequestItemSeqId: product.node1.custRequestItemSeqId,
        deliverableTypeId: product.node1.deliverableTypeId,
        estimatedDeliveryDate: product.node1.estimatedDeliveryDate,
        leadTimeDays: product.node1.leadTimeDays,
        productFeatureId: product.node1.productFeatureId,
        productId: product.node1.productId,
        quantity: product.node1.quantity,
        quoteItemSeqId: product.node1.quoteItemSeqId,
        quoteUnitPrice: product.node1.quoteUnitPrice,
        reservLength: product.node1.reservLength,
        reservPersons: product.node1.reservPersons,
        reservStart: product.node1.reservStart,
        selectedAmount: product.node1.selectedAmount,
        skillTypeId: product.node1.skillTypeId,
        uomId: product.node1.uomId,
        workEffortId: product.node1.workEffortId,
      })

    }, 2000);
    this.spinner.hide();
  }


  updateQuoteItem() {
    this.spinner.show();
    const ReservStart = this.updateQuoteItemForm.get('reservStart').value;
    this.reservStart = this.datePipe.transform(ReservStart, "yyyy-MM-dd hh:mm:ss");

    const EstimatedDeliveryDate = this.updateQuoteItemForm.get('estimatedDeliveryDate').value;
    this.estimatedDeliveryDate = this.datePipe.transform(EstimatedDeliveryDate, "yyyy-MM-dd hh:mm:ss");
    const formData = {

      "comments": this.updateQuoteItemForm.value.comments,
      "configId": this.updateQuoteItemForm.value.configId,
      "custRequestId": this.updateQuoteItemForm.value.custRequestId,
      "custRequestItemSeqId": this.updateQuoteItemForm.value.custRequestItemSeqId,
      "deliverableTypeId": this.updateQuoteItemForm.value.deliverableTypeId,
      "estimatedDeliveryDate": this.estimatedDeliveryDate,
      "isPromo": "",
      "leadTimeDays": this.updateQuoteItemForm.value.leadTimeDays,
      "productFeatureId": this.updateQuoteItemForm.value.productFeatureId,
      "productId": this.updateQuoteItemForm.value.productId,
      "quantity": this.updateQuoteItemForm.value.quantity,
      "quoteId": this.quoteId,
      "quoteItemSeqId": this.updateQuoteItemForm.value.quoteItemSeqId,
      "quoteUnitPrice": this.updateQuoteItemForm.value.quoteUnitPrice,
      "reservLength": this.updateQuoteItemForm.value.reservLength,
      "reservPersons": this.updateQuoteItemForm.value.reservPersons,
      "reservStart": this.reservStart,
      "selectedAmount": this.updateQuoteItemForm.value.selectedAmount,
      "skillTypeId": this.updateQuoteItemForm.value.skillTypeId,
      "uomId": this.updateQuoteItemForm.value.uomId,
      "workEffortId": this.updateQuoteItemForm.value.workEffortId,

    }
    this.myContactsService.updateQuoteItem(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.updateQuoteItemForm.reset();
        this.getQuoteItemLists();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  createQuoteItem() {
    this.spinner.show();
    const ReservStart = this.QuoteItemForm.get('reservStart').value;
    this.reservStart = this.datePipe.transform(ReservStart, "yyyy-MM-dd hh:mm:ss");

    const EstimatedDeliveryDate = this.QuoteItemForm.get('estimatedDeliveryDate').value;
    this.estimatedDeliveryDate = this.datePipe.transform(EstimatedDeliveryDate, "yyyy-MM-dd hh:mm:ss");
    const formData = {

      "comments": this.QuoteItemForm.value.comments,
      "configId": this.QuoteItemForm.value.configId,
      "custRequestId": this.QuoteItemForm.value.custRequestId,
      "custRequestItemSeqId": this.QuoteItemForm.value.custRequestItemSeqId,
      "deliverableTypeId": this.QuoteItemForm.value.deliverableTypeId,
      "estimatedDeliveryDate": this.estimatedDeliveryDate,
      "isPromo": "",
      "leadTimeDays": this.QuoteItemForm.value.leadTimeDays,
      "productFeatureId": this.QuoteItemForm.value.productFeatureId,
      "productId": this.QuoteItemForm.value.productId,
      "quantity": this.QuoteItemForm.value.quantity,
      "quoteId": this.quoteId,
      "quoteItemSeqId": this.QuoteItemForm.value.quoteItemSeqId,
      "quoteUnitPrice": this.QuoteItemForm.value.quoteUnitPrice,
      "reservLength": this.QuoteItemForm.value.reservLength,
      "reservPersons": this.QuoteItemForm.value.reservPersons,
      "reservStart": this.reservStart,
      "selectedAmount": this.QuoteItemForm.value.selectedAmount,
      "skillTypeId": this.QuoteItemForm.value.skillTypeId,
      "uomId": this.QuoteItemForm.value.uomId,
      "workEffortId": this.QuoteItemForm.value.workEffortId
    }
    this.myContactsService.createQuoteItem(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.QuoteItemForm.reset();
        this.closebutton5.nativeElement.click();
        this.getQuoteItemLists();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  copyQuote() {
    this.spinner.show();

    const formData = {
      "copyQuoteAdjustments": this.DuplicateQuote.value.Adjustments,
      "copyQuoteAttributes": this.DuplicateQuote.value.QuoteAttributes,
      "copyQuoteCoefficients": this.DuplicateQuote.value.Coefficients,
      "copyQuoteItems": this.DuplicateQuote.value.QuoteItems,
      "copyQuoteRoles": this.DuplicateQuote.value.QuoteRoles,
      "copyQuoteTerms": this.DuplicateQuote.value.QuoteTerm,
      "quoteId": this.quoteId

    }
    this.myContactsService.copyQuote(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.DuplicateQuote.reset();
        this.getMainQuote();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  partyPage() {
    this.router.navigate(["/partyQuickLink/profile-party-quick-link"],
      { queryParams: { party: this.MainQuote.data.partyId } })

  }
  removeQuoteCoefficient(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "coeffName": product.data.coeffName,
          "quoteId": this.quoteId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.removeQuoteCoefficient(req).subscribe(res => {
          this.getQuoteAttribute();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  resetCoefficient() {
    this.QuoteCoefficientForm.reset();
    this.showCoefficient = false;
  }
  updateCoefficient(product) {
    this.showCoefficient = true;

    this.spinner.show();

    setTimeout(() => {
      const formValue = this.QuoteCoefficientForm;
      formValue.patchValue({
        coeffName: product.data.coeffName,
        coeffValue: product.data.coeffValue,
      })

    }, 2000);
    this.spinner.hide();
  }
  createQuoteCoefficient() {
    this.spinner.show();

    const formData = {
      "coeffName": this.QuoteCoefficientForm.value.coeffName,
      "coeffValue": this.QuoteCoefficientForm.value.coeffValue,
      "quoteId": this.quoteId

    }
    this.myContactsService.createQuoteCoefficient(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.QuoteCoefficientForm.reset();
        this.getQuoteCoefficient();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  updateQuoteCoefficient() {
    this.spinner.show();

    const formData = {
      "coeffName": this.QuoteCoefficientForm.value.coeffName,
      "coeffValue": this.QuoteCoefficientForm.value.coeffValue,
      "quoteId": this.quoteId
    }
    this.myContactsService.updateQuoteCoefficient(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.QuoteCoefficientForm.reset();
        this.getQuoteCoefficient();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getQuoteCoefficient() {

    this.spinner.show();
    this.myContactsService.getQuoteCoefficient(this.quoteId).subscribe(res => {
      this.quoteCoefficient = res.data;
      this.spinner.hide();
    })
  }

  resetAtt() {
    this.attributeForm.reset();
    this.showAttribute = false;
  }

  updateAttribute(product) {
    this.showAttribute = true;

    this.spinner.show();

    setTimeout(() => {
      const formValue = this.attributeForm;
      formValue.patchValue({
        attrDescription: product.data.attrDescription,
        attrName: product.data.attrName,
        attrValue: product.data.attrValue
      })

    }, 2000);
    this.spinner.hide();
  }
  removeQuoteAttribute(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        let req = {
          "attrName": product.data.attrName,
          "quoteId": this.quoteId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"

        }
        this.myContactsService.removeQuoteAttribute(req).subscribe(res => {
          this.getQuoteAttribute();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getChannelId() {
    this.spinner.show();
    this.myContactsService.getChannelId().subscribe(res => {
      this.ChannelId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.ChannelId) {
        this.ChannelIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  getQuoteTypeIdList() {
    this.spinner.show();
    this.myContactsService.getQuoteTypeIdList().subscribe(res => {
      this.QuoteTypeIdList = res.data[0].node;
      this.spinner.hide();
      for (const value of this.QuoteTypeIdList) {
        this.QuoteTypeIdListArray.push({
          label: value.description,
          value: value.quoteTypeId
        })
      }
    })
  }
  getQuoteUomId() {
    this.spinner.show();
    this.myContactsService.getQuoteUomId().subscribe(res => {
      this.QuoteUomId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.QuoteUomId) {
        this.QuoteUomIdArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  getProductProductStore() {
    this.spinner.show();
    this.myContactsService.getProductProductStore().subscribe(res => {
      this.ProductProductStore = res.data[0].node;
      this.spinner.hide();
      for (const value of this.ProductProductStore) {
        this.ProductProductStoreArray.push({
          label: value.storeName,
          value: value.productStoreId
        })
      }
    })
  }
  getQuoteItemLists() {
    this.spinner.show();
    this.myContactsService.getQuoteItemLists(this.quoteId).subscribe(res => {
      this.QuoteItemLists = res.data;
      this.spinner.hide();


    })
  }
  getQuoteSkillTypeId() {
    this.spinner.show();
    this.myContactsService.getQuoteSkillTypeId().subscribe(res => {
      this.QuoteSkillTypeId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.QuoteSkillTypeId) {
        this.QuoteSkillTypeIdArray.push({
          label: value.description,
          value: value.skillTypeId
        })
      }
    })
  }
  createQuoteAttribute() {
    this.spinner.show();

    const formData = {
      "attrDescription": this.attributeForm.value.attrDescription,
      "attrName": this.attributeForm.value.attrName,
      "attrValue": this.attributeForm.value.attrValue,
      "quoteId": this.quoteId,

    }
    this.myContactsService.createQuoteAttribute(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.attributeForm.reset();
        this.getQuoteAttribute();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  updateQuoteAttribute() {
    this.spinner.show();

    const formData = {
      "attrDescription": this.attributeForm.value.attrDescription,
      "attrName": this.attributeForm.value.attrName,
      "attrValue": this.attributeForm.value.attrValue,
      "quoteId": this.quoteId,
    }
    this.myContactsService.updateQuoteAttribute(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.attributeForm.reset();
        this.getQuoteAttribute();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  update() {
    setTimeout(() => {
      const formValue = this.updateQuoteForm;
      formValue.patchValue({
        quoteId: this.quoteId,
        QuoteTypeID: this.MainQuote.data.quoteTypeId,
        PartyID: this.MainQuote.data.partyId,
        QuoteIssueDate: this.MainQuote.data.issueDate,
        Status: this.MainQuote.data.statusId,
        Currency: this.MainQuote.data.currencyUomId,
        ProductProductStore: this.MainQuote.data.productStoreId,
        SalesChannel: "",
        ValidFromDate: this.MainQuote.data.validFromDate,
        QuoteName: this.MainQuote.data.quoteName,
        ValidThroughDate: this.MainQuote.data.validThruDate,
        Description: this.MainQuote.data.description,
      })

    }, 2000);

  }
  updateQuotes() {
    this.spinner.show();

    const IssueDate = this.updateQuoteForm.get('QuoteIssueDate').value;
    this.issueDate = this.datePipe.transform(IssueDate, "yyyy-MM-dd hh:mm:ss");

    const StartDate = this.updateQuoteForm.get('ValidThroughDate').value;
    this.validThruDate = this.datePipe.transform(StartDate, "yyyy-MM-dd hh:mm:ss");

    const EndDate = this.updateQuoteForm.get('ValidFromDate').value;
    this.validFromDate = this.datePipe.transform(EndDate, "yyyy-MM-dd hh:mm:ss");


    const formData = {
      "currencyId": this.updateQuoteForm.value.Currency,
      "description": this.updateQuoteForm.value.Description,
      "quoteIssueDate": this.issueDate,
      "partyId": this.updateQuoteForm.value.PartyID,
      "productStoreId": this.updateQuoteForm.value.ProductProductStore,
      "quoteId": this.quoteId,
      "quoteName": this.updateQuoteForm.value.QuoteName,
      "quoteTypeId": this.updateQuoteForm.value.QuoteTypeID,
      "salesId": this.updateQuoteForm.value.SalesChannel,
      "statusId": this.updateQuoteForm.value.Status,
      "validFrom": this.validFromDate,
      "validThrough": this.validThruDate,

    }
    this.myContactsService.updateQuotes(formData, this.quoteId).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.updateQuoteForm.reset();
        this.getMainQuote();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  createQuotes() {
    this.spinner.show();
    const IssueDate = this.quoteForm.get('QuoteIssueDate').value;
    this.issueDate = this.datePipe.transform(IssueDate, "yyyy-MM-dd hh:mm:ss");

    const StartDate = this.quoteForm.get('ValidThroughDate').value;
    this.validThruDate = this.datePipe.transform(StartDate, "yyyy-MM-dd hh:mm:ss");
    const EndDate = this.quoteForm.get('ValidFromDate').value;
    this.validFromDate = this.datePipe.transform(EndDate, "yyyy-MM-dd hh:mm:ss");

    const formData = {
      "currencyUomId": this.quoteForm.value.Currency,
      "description": this.quoteForm.value.Description,
      "issueDate": this.issueDate,
      "partyId": this.quoteForm.value.PartyID,
      "productStoreId": this.quoteForm.value.ProductProductStore,
      "quoteId": this.quoteId,
      "quoteName": this.quoteForm.value.QuoteName,
      "quoteTypeId": this.quoteForm.value.QuoteTypeID,
      "salesChannelEnumId": this.quoteForm.value.SalesChannel,
      "statusId": this.quoteForm.value.Status,
      "validFromDate": this.validFromDate,
      "validThruDate": this.validThruDate
    }
    this.myContactsService.createQuotes(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.quoteForm.reset();
        this.quoteId = res.data.quoteId;
        this.router.navigate(["/psa/project/create-quote-from-request-list"],
          { queryParams: { quoteId: this.quoteId, custRequestId: this.custRequestId, } })
        this.getMainQuote();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  getWorkEfforts() {
    this.spinner.show();
    this.myContactsService.getWorkEfforts(this.custRequestId).subscribe(res => {
      this.WorkEfforts = res.data;
      this.spinner.hide();
    })
  }
  generatePdf() {
    this.spinner.show();
    this.myContactsService.generatePdf(this.quoteId).subscribe(res => {
      const blob = new Blob([res], { type: 'document/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'ViewSimpleContent';
      this.spinner.hide();
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })

  }
  getQuoteAttribute() {
    this.spinner.show();
    this.myContactsService.getQuoteAttribute(this.quoteId).subscribe(res => {
      this.QuoteAttribute = res.data;
      this.spinner.hide();
    })
  }
  getQuoteRoles() {
    this.spinner.show();
    this.myContactsService.getQuoteRoles(this.quoteId).subscribe(res => {
      this.QuoteRoles = res.data;
      this.spinner.hide();
    })
  }
  getMainQuote() {
    this.spinner.show();
    this.myContactsService.getMainQuote(this.quoteId).subscribe(res => {
      this.MainQuoteList = res.data[0];
      this.statusID = res.data[0].data.statusId;
      this.spinner.hide();
    })
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
