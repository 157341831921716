<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
          
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Add New Classification Group</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                                   Classification Summary
                                </a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                 Parties
                                </a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="w-100">
                                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                        <div *ngIf="activeCategory==1">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling" style="margin-left:1%">
                                                    Classification Summary

                                                </h4>
                                                <span class="edit-buttons" style="margin-right:1%">
                                                  <a *ngIf="!this.Hidebtn" data-toggle="modal" (click)="getPartyClassificationGroupByIdEdit()"
                                                  data-target="#exampleModalCenter">Update</a></span>

                                            </div>
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="color-black container" *ngIf="editData">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6 col-12">Classification Group Id</p>
                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{ this.editData.partyClassificationGroupId}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-6 col-12">Party Classification Type Id</p>
                                                                <span class="color-grey pl-20 col-lg-10 col-12"> {{this.editData.partyClassificationTypeId}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-6 col-12">Parent Group Id</p>
                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{ this.editData.parentGroupId}}</span>

                                                            </div>

                                                            <div class="article">
                                                                <p class="col-lg-6 col-12">Description</p>
                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{ this.editData.description}}</span>
                                                            </div>









                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==2">

                                            <div>
                                                <header class="w3-container">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling" style="margin-left: 1%;">
                                                            Parties
                                                        </h4>
                                                        <span *ngIf="!this.Hidebtn"  class="edit-buttons" style="margin-right:1%">
                                                            <a (click)="createClassificationNew()">Create New Party</a></span>
                                                    </div>
                                                </header>
                                                <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="PartyClassificationById" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Classification Group ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Party
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    From Date
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                                                                <div style="color: white;">
                                                                                    Through Date
                                                                                    <p-sortIcon field="category">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'150px'}" width="11%" pSortableColumn="quantity">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                    <p-sortIcon field="quantity">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span class="summary account-button">{{product.partyRateData.partyClassificationGroupId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                {{product.partyRateData.partyId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                            </td>


                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <svg *ngIf="!this.Hidebtn" class="hover" (click)="updatePartyClassification(product.partyRateData.partyId,product.partyRateData.partyClassificationGroupId,product.fromDate,product.thruDate)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                <g transform="translate(0 -0.004)">
                                                                                  <g transform="translate(0 1.52)">
                                                                                    <path
                                                                                      d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                      transform="translate(0 -34.137)" />
                                                                                  </g>
                                                                                  <g transform="translate(4.548 0.004)">
                                                                                    <g transform="translate(0 0)">
                                                                                      <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                    </g>
                                                                                  </g>
                                                                                </g>
                                                                              </svg>
                                                                                <svg *ngIf="!this.Hidebtn" xmlns="http://www.w3.org/2000/svg" (click)="deletePartyClassification(product)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                <defs>
                                                                                  <style>
                                                                                    .a {
                                                                                      fill: #f44336;
                                                                                    }
                                    
                                                                                    .b {
                                                                                      fill: #fafafa;
                                                                                      margin-left: 20px;
                                                                                    }
                                                                                  </style>
                                                                                </defs>
                                                                                <path class="a fill-color"
                                                                                  d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                <path class="b"
                                                                                  d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                  transform="translate(-147.576 -147.576)" />
                                                                              </svg>

                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No
                                                                                        Record Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">  Update Classification Group</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''" *ngIf="!this.show"><a>
                        Add New Classification Group

                    </a></li>
                                <li [ngClass]="activeCategory==1?'active':''" *ngIf="this.show"><a>
                        Update New Classification Group

                    </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createClassification">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                    Party Classification Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="ClassificationGroupTypeIdArray" filter="true" formControlName="PartyClassificationTypeID" placeholder="Enter Party Classification Type ID" name="Parent Type ID">
                                                        </p-dropdown>
                                                    </div>


                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                    Parent Group ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="ClassificationGroupIdArray" filter="true" formControlName="ParentGroupID" placeholder="Enter Parent Group ID" name="Parent Type ID">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                    Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" formControlName="Description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>




                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 68%;">

                                        <button type="submit" *ngIf="!this.show" (click)="onSubmit();" class="btn btn-secondary submit-btn">Create</button>

                                        <button type="submit" *ngIf="this.show" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>




                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>