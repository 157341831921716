import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-capture',
  templateUrl: './capture.component.html',
  styleUrls: ['./capture.component.css']
})
export class CaptureComponent implements OnInit {
  activeCategory=2;
  createAuth:FormGroup;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  getPaymentMethodType: any;
  getPaymentMethodTypeArray: any[]=[];
  paymentType: { label: string; value: string; }[];
  orderId: any;
  orderPaymentPreferenceId: any;
  amount: any;
  creditCard: string;
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,
    private _location: Location,
    ) { 
      this.createAuth = this._FormBuilder.group({
        orderId: [''],
        paymentPrefrenece:[''],
        paymentMethod:[''],
        amount:[''],
        paymentType:['']
       
        
      });
      this.paymentType=[{
        "label":"Customer Payment",
        "value":"CUSTOMER_PAYMENT"
      },
      {
        "label":"Customer Deposit",
        "value":"CUSTOMER_DEPOSIT"
      },{
        "label":"Interest Receipt",
        "value":"INTEREST_RECEIPT"
      },{
        "label":"Gift Certificate Deposit",
        "value":"GC_DEPOSIT"
      }]
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getPaymentMethodTypeGlSetting();
    this._ActivatedRoute.queryParams.subscribe(params=> [
      this.orderId = params["orderId"],
      this.orderPaymentPreferenceId=params["orderPaymentPreferenceId"],
      this.amount=params["amount"],
      this.creditCard="CREDIT_CARD"
    
    ])
    if(this.orderId) {
      setTimeout(() => {
        const formValue = this.createAuth;
      formValue.patchValue({
        orderId:  this.orderId,
        paymentPrefrenece: this.orderPaymentPreferenceId,
        paymentMethod:this.creditCard,
        amount: this.amount
       
      })
      }, 1000);
     
    }
   
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/financial/main']);
  }
  Authorize(){
    this._Router.navigate(["/financial/authorize-transaction"])
  }
  Capture(){
    this._Router.navigate(["/financial/capture"])
  }
  GatewayResponses(){
    this._Router.navigate(["/financial/payment-gateway-response"])
  }
  ManualElectronicTransaction(){
    this._Router.navigate(["/financial/manual-electronic-transaction"])
  }
  getPaymentMethodTypeGlSetting() {
    this.spinner.show();
    this.accountsService.getPaymentMethodTypeGlSetting(this.finSize).subscribe((res: any) => {
      this.getPaymentMethodType = res.data;
      this.spinner.hide();
      for (const value of this.getPaymentMethodType) {
        this.getPaymentMethodTypeArray.push({
          label: value.description,
          value: value.paymentMethodTypeId
        })
      }
    })
  
  }
  
   
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "orderId": this.createAuth.value.orderId,
      "orderPaymentPreferenceId": this.createAuth.value.paymentPrefrenece,
      "captureAmount":this.createAuth.value.amount,
      "paymentMethodTypeId": this.createAuth.value.paymentMethod,
      "paymentTypeId":this.createAuth.value.paymentType,
    }
    this.accountsService.captureOrderPreference(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Capture Successfully");
        this._Router.navigate(['/financial/payment-gateway-response']);
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
