import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';   import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-revision',
  templateUrl: './revision.component.html',
  styleUrls: ['./revision.component.css']
})
export class RevisionComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 6;
  resData: any;
  total = 0;
  partyId:any;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  ProductName: any;
  ProductNameArray: any[]=[];
  advanceSearch: { ProductID: string; BacklogID: string; TaskID: string; };
  revisionList: any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,    
    private _location: Location,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
     this.advanceSearch = {

      ProductID: "",
      BacklogID: "",
      TaskID: "",
  }; 
}

  ngOnInit(): void {
    this.spinner.show;
    this.getProductName();
    this.FindTaskRevisionList();
    this.spinner.hide();
  }

  FindTaskRevision() {
    this.spinner.show();
    if(this.advanceSearch.BacklogID||this.advanceSearch.ProductID||this.advanceSearch.TaskID ){
      const req = {
        "custRequestId": this.advanceSearch.BacklogID ? this.advanceSearch.BacklogID : '',
      "productId": this.advanceSearch.ProductID ? this.advanceSearch.ProductID : '',
      "workEffortId": this.advanceSearch.TaskID ? this.advanceSearch.TaskID : '',
      }
      this.accountsService.FindTaskRevision(req.custRequestId,req.productId,req.workEffortId).subscribe((res: any) => {
        this.revisionList = res.data;
        this.spinner.hide();
      })}
      else{
        this.FindTaskRevisionList();
      }
  }

  FindTaskRevisionList() {
    this.spinner.show();
    this.accountsService.FindTaskRevisionList().subscribe(res => {
      this.revisionList = res.data;
      this.spinner.hide();
    })
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.ProductID = "";
    this.advanceSearch.BacklogID = "";
    this.advanceSearch.TaskID = "";

    this.accountsService.FindTaskRevisionList().subscribe((res: any) => {
      this.revisionList = res.data;
      this.spinner.hide();
    })
  }

  getProductName() {
    this.spinner.show();
    this.accountsService.getProductName().subscribe(res => {
      this.ProductName = res.data[0].name;
      this.spinner.hide();
      for (const value of this.ProductName) {
        this.ProductNameArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
  }
 
  Backlog(){
    this._Router.navigate(["/psa/product-backlog"])
  }
  Sprint(){
    this._Router.navigate(["/psa/sprints"])
  }
  MyWork(){
    this._Router.navigate(["/psa/my-work"])
  }
  Tasks(){
    this._Router.navigate(["/psa/tasks"])
  }
  OpenTest(){
    this._Router.navigate(["/psa/open-test"])
  }
  Revision(){
    this._Router.navigate(["/psa/find-task-revision"])
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
