<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Accounting Preferences For: Your Company Name Here [{{this.partyId}}] </span>
                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Back</button>

            </div>

         
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass pl-0" style="overflow:scroll;">

                           
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategoryFunction(6)"><a>
                                    Reports </a></li>
                           



                        </ul>
                    </div>
                    <div>
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">

                                                <div *ngIf="activeCategory==6">

                                                    <div class="w3-card-4 classCard">
                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass pl-0"
                                                                style="
                                                   
                                                    display: flex !important;
                                                   ">

                                                                <li [ngClass]="activeCategoryValue==2?'active':''"
                                                                    (click)="changeactiveCategoryFunctionSubMenu(2)"><a>
                                                                        Trial Balance</a></li>
                                                                <li [ngClass]="activeCategoryValue==3?'active':''"
                                                                    (click)="changeactiveCategoryFunctionSubMenu(3)"><a>
                                                                        Transaction Total
                                                                    </a></li>

                                                                <li [ngClass]="activeCategoryValue==4?'active':''"
                                                                    (click)="changeactiveCategoryFunctionSubMenu(4)"><a>
                                                                        Income Statement</a></li>
                                                                <li [ngClass]="activeCategoryValue==5?'active':''"
                                                                    (click)="changeactiveCategoryFunctionSubMenu(5)"><a>
                                                                        Cash Flow Statement </a></li>
                                                                <li [ngClass]="activeCategoryValue==6?'active':''"
                                                                    (click)="changeactiveCategoryFunctionSubMenu(6)"><a>
                                                                        Balance Sheet</a></li>
                                                                <li [ngClass]="activeCategoryValue==7?'active':''"
                                                                    (click)="changeactiveCategoryFunctionSubMenu(7)"><a>
                                                                        Comparative Income Statement</a></li>
                                                                <li [ngClass]="activeCategoryValue==8?'active':''"
                                                                    (click)="changeactiveCategoryFunctionSubMenu(8)"><a>
                                                                        Comparative Cash Flow Statement </a></li>
                                                                <li [ngClass]="activeCategoryValue==9?'active':''"
                                                                    (click)="changeactiveCategoryFunctionSubMenu(9)"><a>
                                                                        Comparative Balance Sheet</a></li>
                                                                <li [ngClass]="activeCategoryValue==10?'active':''"
                                                                    (click)="changeactiveCategoryFunctionSubMenu(10)">
                                                                    <a>
                                                                        GL Account Trial Balance</a>
                                                                </li>
                                                                <li [ngClass]="activeCategoryValue==11?'active':''"
                                                                    (click)="changeactiveCategoryFunctionSubMenu(11)">
                                                                    <a>
                                                                        Inventory Valuation </a>
                                                                </li>
                                                                <li [ngClass]="activeCategoryValue==12?'active':''"
                                                                    (click)="changeactiveCategoryFunctionSubMenu(12)">
                                                                    <a>
                                                                        Cost Centers
                                                                    </a>
                                                                </li>





                                                            </ul>
                                                        </div>

                                                        <div *ngIf="activeCategoryValue==2">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling HLeft">
                                                                    Find Trial Balance

                                                                </h4>
                                                                <span class="edit-buttons  HRight">
                                                                    <a (click)="generateTrialBalancePDF()">PDF
                                                                        
                                                                    </a>
                                                                    <a (click)="getGenerateCsvTrialBalance()">Export as
                                                                        CSV
                                                                    </a></span>
                                                            </div>
                                                            <br>
                                                            <div class="panel-body">
                                                                <div>
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100"
                                                                                [formGroup]="trialBalance">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputC" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Custom Time Period
                                                                                                ID</label>
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                placeholder="Enter Custom Time Period ID"
                                                                                                filter="true"
                                                                                                formControlName="customeTime"
                                                                                                [options]="timePerodArray"
                                                                                                optionlabel="label">

                                                                                            </p-dropdown>

                                                                                            </div>
                                                                                         
                                                                                        </div>
                                                                                    </div>

                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button"
                                                                                style="margin-left: -66%;">
                                                                                <button type="submit"
                                                                                    (click)="getTrialBalance();"
                                                                                    class="btn btn-secondary submit-btn">Submit</button>



                                                                            </div>
                                                                        </div>
                                                                        <div>


                                                                            <div class="all-users-infomation font-13 font-medium own-user-select"
                                                                                style="display: block!important;">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table
                                                                                            [value]="trialBalanceList"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr
                                                                                                    style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align"
                                                                                                            style="color: white;">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>




                                                                                                            Account Code
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Account Name
                                                                                                            <p-sortIcon
                                                                                                                field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>


                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Opening
                                                                                                            Balance
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Dr
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Cr
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Ending
                                                                                                            Balance
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>





                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.accountCode}}

                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.accountName}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        US${{product.openingBalance}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        US${{product.postedDebits}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        US${{product.postedCredits}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        US${{product.endingBalance}}
                                                                                                    </td>






                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="emptymessage"
                                                                                                let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span
                                                                                                            class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page

                                                                                        </p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label>Dr:
                                                                                            US${{this.postedDebitsTotal}}</label>
                                                                                        <label>Cr:
                                                                                            US${{this.postedCreditsTotal}}</label>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div *ngIf="activeCategoryValue==3">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling HLeft">
                                                                    Find Transaction Totals

                                                                </h4>
                                                                <span class="edit-buttons  HRight">

                                                                    <a (click)="getGenerateCsvtransactionTotals()">Export
                                                                        as CSV

                                                                    </a></span>

                                                            </div>
                                                            <br>

                                                            <div class="panel-body">
                                                                <div>
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100"
                                                                                [formGroup]="totalTransaction">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputB" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Month</label>
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                placeholder="Enter Month"
                                                                                                filter="true"
                                                                                                [options]="months"
                                                                                                optionlabel="label"
                                                                                                formControlName="monthTotal">

                                                                                            </p-dropdown>
                                                
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                From Date</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="date"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Account Type"
                                                                                                formControlName="fromDateTotal">
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputB" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Through Date</label>
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                                <input type="date"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Account Type"
                                                                                                formControlName="througDateTotal">
                                                
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Fiscal Gl Type</label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                placeholder="Enter Fiscal Gl Type"
                                                                                                filter="true"
                                                                                                formControlName="FiscalGlTypeTotal"
                                                                                                [options]="glFiscalTypeArray"
                                                                                                optionlabel="label">

                                                                                            </p-dropdown>
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>

                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button"
                                                                                style="margin-left: -30%;">
                                                                                <button type="submit"
                                                                                    (click)="gettransactionTotals();"
                                                                                    class="btn btn-secondary submit-btn">Submit</button>



                                                                            </div>
                                                                        </div>
                                                                        <div>

                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Posted Totals
                                                                                    </h4>

                                                                                </div>
                                                                            </div>
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select"
                                                                                style="display: block!important;">



                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table
                                                                                            [value]="postedTransactionTotals"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr
                                                                                                    style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align"
                                                                                                            style="color: white;">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>




                                                                                                            Account Code
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Account Name
                                                                                                            <p-sortIcon
                                                                                                                field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>


                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Opening D
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Opening C
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Dr
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Cr
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Closing D
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Closing C
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>





                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.accountCode}}

                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">

                                                                                                        {{product.accountName}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.openingD}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.openingC}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        US${{product.D}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        US${{product.C}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                    </td>






                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="emptymessage"
                                                                                                let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span
                                                                                                            class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page

                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        UnPosted Totals
                                                                                    </h4>

                                                                                </div>
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select"
                                                                                    style="display: block!important;">



                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="unpostedTransactionTotals"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Opening
                                                                                                                D
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Opening
                                                                                                                C
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Dr
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Cr
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Closing
                                                                                                                D
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Closing
                                                                                                                C
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>





                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">

                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.D}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.C}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                        </td>






                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>
                                                                            <div>

                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Posted And Unposted Totals
                                                                                    </h4>

                                                                                </div>
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select"
                                                                                    style="display: block!important;">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="allTransactionTotals"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Opening
                                                                                                                D
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Opening
                                                                                                                C
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Dr
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Cr
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Closing
                                                                                                                D
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Closing
                                                                                                                C
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>





                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.openingD}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.openingC}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.D}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.C}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.D}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.C}}
                                                                                                        </td>






                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page
                                                                                                <ng-container
                                                                                                    *ngIf="openNoRecordFoundRelatedAccount">
                                                                                                    <span
                                                                                                        style="margin-left: 400px;">No
                                                                                                        Record
                                                                                                        Found!....</span>
                                                                                                </ng-container>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div *ngIf="activeCategoryValue==4">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling HLeft">
                                                                    Find Income Statement

                                                                </h4>
                                                                <span class="edit-buttons  HRight">

                                                                    <a (click)="getgenerateCsvIncomeStatements()">Export
                                                                        as CSV

                                                                    </a></span>

                                                            </div>
                                                            <br>

                                                            <div class="panel-body">
                                                                <div>
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100"
                                                                                [formGroup]="incomeStatement">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputB" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Month</label>
                                                                                            
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                               
                                                                                            <p-dropdown
                                                                                            placeholder="Enter Month"
                                                                                            filter="true"
                                                                                            [options]="months"
                                                                                            optionlabel="label"
                                                                                            formControlName="monthTotalIncome">

                                                                                        </p-dropdown>
                                                
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                From Date</label>
                                                                                           
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                
                                                                                            <input type="date"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Account Type"
                                                                                            formControlName="fromDateIncome">
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputB" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Through Date</label>
                                                                                        
                                                                                          
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                                <input type="date"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Account Type"
                                                                                                formControlName="throughDateIncome">
                                                                                            
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Fiscal Gl Type</label>
                                                                                          
                                                    
                                                                                           
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                placeholder="Enter Fiscal Gl Type"
                                                                                                filter="true"
                                                                                                formControlName="FiscalGlTypeIncome"
                                                                                                [options]="glFiscalTypeArray"
                                                                                                optionlabel="label">

                                                                                            </p-dropdown>
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>


                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button"
                                                                                style="margin-left: -40%;">
                                                                                <button type="submit"
                                                                                    (click)="getIncomeStatements();"
                                                                                    class="btn btn-secondary submit-btn">Submit</button>



                                                                            </div>
                                                                        </div>
                                                                        <div>

                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Accounting Revenues
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">



                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="incomeStatementRevenues"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance}}
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>
                                                                            <div>

                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Expenses
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="incomeStatementExpenses"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>






                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance}}
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>
                                                                            <div class="header-tabs">
                                                                                <h4 class=" common-styling">
                                                                                    Income
                                                                                </h4>

                                                                            </div>
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">



                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table
                                                                                            [value]="incomeStatementIncome"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr
                                                                                                    style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align"
                                                                                                            style="color: white;">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>




                                                                                                            Account Code
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Account Name
                                                                                                            <p-sortIcon
                                                                                                                field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>


                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Balance
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>






                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.accountCode}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.accountName}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        US${{product.balance}}
                                                                                                    </td>







                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="emptymessage"
                                                                                                let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span
                                                                                                            class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page

                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Total
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">



                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="balanceTotals"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Total
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <p-sortIcon
                                                                                                                field="name">
                                                                                                            </p-sortIcon>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.totalName}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance}}
                                                                                                        </td>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div *ngIf="activeCategoryValue==5">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling HLeft">
                                                                    Find Cash Flow Statement

                                                                </h4>
                                                                <span class="edit-buttons  HRight">

                                                                    <a (click)="getgenerateCsvcashflowStatements()">Export
                                                                        as CSV

                                                                    </a></span>

                                                            </div>
                                                            <br>

                                                            <div class="panel-body">
                                                                <div>
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100"
                                                                                [formGroup]="cashFlowStatement">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputB" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Month</label>
                                                                                            
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                placeholder="Enter Month"
                                                                                                filter="true"
                                                                                                [options]="months"
                                                                                                optionlabel="label"
                                                                                                formControlName="monthTotalCash">

                                                                                            </p-dropdown>
                                                
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                From Date</label>
                                                                                            
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                             
                                                                                            <input type="date"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Account Type"
                                                                                            formControlName="fromDateCash">
                                                                                       
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputB" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Through Date</label>
                                                                                           
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                               
                                                                                            <input type="date"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Account Type"
                                                                                            formControlName="throughDateCash">
                                                
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Fiscal Gl Type</label>
                                                                                            
                                                                                            
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                            
                                                                                                <p-dropdown
                                                                                                placeholder="Enter Fiscal Gl Type"
                                                                                                filter="true"
                                                                                                formControlName="FiscalGlTypeCash"
                                                                                                [options]="glFiscalTypeArray"
                                                                                                optionlabel="label">

                                                                                            </p-dropdown>
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>


                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button"
                                                                                style="margin-left: -30%;">
                                                                                <button type="submit"
                                                                                    (click)="getcashflowStatements();"
                                                                                    class="btn btn-secondary submit-btn">Submit</button>



                                                                            </div>
                                                                        </div>
                                                                        <div>

                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Opening Cash Balance
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="openingCashBalance"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance}}
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Period Cash Balance
                                                                                    </h4>

                                                                                </div>

                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">



                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="periodCashBalance"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Total
                                                                                                                Debit
                                                                                                                (Receipts)
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Total
                                                                                                                Credit
                                                                                                                (Disbursement)
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>






                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance}}
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Closing Cash Balance
                                                                                    </h4>

                                                                                </div>

                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="closingCashBalance"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>






                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance}}
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Total
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">



                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="balanceTotalsCash"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Total
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <p-sortIcon
                                                                                                                field="name">
                                                                                                            </p-sortIcon>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.totalName}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance}}
                                                                                                        </td>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div *ngIf="activeCategoryValue==6">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling HLeft">
                                                                    Find Balance Sheet

                                                                </h4>
                                                                <span class="edit-buttons  HRight">

                                                                    <a (click)="getCSvbalanceSheet()">Export as CSV

                                                                    </a></span>

                                                            </div>
                                                            <br>

                                                            <div class="panel-body">
                                                                <div>
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100"
                                                                                [formGroup]="balanceSheetStatement">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputB" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Through Date</label>
                                                                                           
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                                <input type="date"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Account Type"
                                                                                                formControlName="throughDateBalance">
                                                                                            
                                                
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Fiscal Gl Type</label>
                                                                                           
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                placeholder="Enter Fiscal Gl Type"
                                                                                                filter="true"
                                                                                                formControlName="FiscalGlTypeBalance"
                                                                                                [options]="glFiscalTypeArray"
                                                                                                optionlabel="label">

                                                                                            </p-dropdown>
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>



                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button"
                                                                                style="margin-left: -30%;">
                                                                                <button type="submit"
                                                                                    (click)="getbalanceSheet();"
                                                                                    class="btn btn-secondary submit-btn">Submit</button>



                                                                            </div>
                                                                        </div>
                                                                        <div>

                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Assets
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="balanceSheetAssets"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance}}
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Liabilities
                                                                                    </h4>

                                                                                </div>

                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">



                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="balanceSheetLiabilities"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance}}
                                                                                                        </td>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Equities
                                                                                    </h4>

                                                                                </div>

                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="balanceSheetEquities"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>






                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance}}
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page
                                                                                                <ng-container
                                                                                                    *ngIf="openNoRecordFoundRelatedAccount">
                                                                                                    <span
                                                                                                        style="margin-left: 400px;">No
                                                                                                        Record
                                                                                                        Found!....</span>
                                                                                                </ng-container>
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Total
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">



                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="balanceTotalsBalance"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Total
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <p-sortIcon
                                                                                                                field="name">
                                                                                                            </p-sortIcon>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.totalName}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance}}
                                                                                                        </td>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div *ngIf="activeCategoryValue==7">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling HLeft">
                                                                    Find Comparative Income Statement

                                                                </h4>
                                                                <span class="edit-buttons  HRight">

                                                                    <a (click)="getCSvcomparativeIncomeStatements()">Export
                                                                        as CSV

                                                                    </a></span>

                                                            </div>
                                                            <br>

                                                            <div class="panel-body">
                                                                <div >
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100"
                                                                                [formGroup]="comparativeIncomeStatement">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputD" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period1 From
                                                                                                Date</label>
                                                                                           
                                                                                           
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                              
                                                                                            <input type="date"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Account Type"
                                                                                            formControlName="period1fromDate">
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period2 From
                                                                                                Date</label>
                                                                                            
                                                                                           
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="date"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Account Type"
                                                                                                formControlName="period2fromDate">
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputD" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period1 Through
                                                                                                Date</label>
                                                                                          
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                              
                                                                                                <input type="date"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Account Type"
                                                                                                formControlName="period1throughDate">
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period2 Through
                                                                                                Date</label>
                                                                                           
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="date"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Account Type"
                                                                                                formControlName="period2throughDate">
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputD" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period1 Gl Fiscal Type
                                                                                                ID</label>
                                                                                           
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                placeholder="Enter Period1 Gl Fiscal Type ID"
                                                                                                filter="true"
                                                                                                formControlName="period1FiscalType"
                                                                                                [options]="glFiscalTypeArray"
                                                                                                optionlabel="label">

                                                                                            </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period2 Gl Fiscal Type
                                                                                                ID</label>
                                                                                           
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                placeholder="Enter Period2 Gl Fiscal Type ID"
                                                                                                filter="true"
                                                                                                formControlName="period2FiscalType"
                                                                                                [options]="glFiscalTypeArray"
                                                                                                optionlabel="label">

                                                                                            </p-dropdown>
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>

                                                                                


                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button"
                                                                                style="margin-left:-30%;">
                                                                                <button type="submit"
                                                                                    (click)="getcomparativeIncomeStatements();"
                                                                                    class="btn btn-secondary submit-btn">Submit</button>



                                                                            </div>
                                                                        </div>
                                                                        <div>

                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        AccountingRevenues
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="comparativeIncomeStatementRevenues"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color:white">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance1
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance2
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance1}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance2}}
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Expenses
                                                                                    </h4>

                                                                                </div>

                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">



                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="comparativeIncomeStatementExpenses"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance1
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance2
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance1}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance2}}
                                                                                                        </td>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Income
                                                                                    </h4>

                                                                                </div>

                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="comparativeIncomeStatementIncome"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance1
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance2
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>






                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance1}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance2}}
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Total
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">



                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="comparativeBalanceTotals"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>





                                                                                                                Total
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Period1
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Period2
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.totalName}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance1}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance2}}
                                                                                                        </td>









                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div *ngIf="activeCategoryValue==8">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling HLeft">
                                                                    Find Comparative Cash Flow Statement

                                                                </h4>
                                                                <span class="edit-buttons  HRight">

                                                                    <a
                                                                        (click)="getgenerateCsvcomparativeCashFlowStatement()">Export
                                                                        as CSV

                                                                    </a></span>

                                                            </div>
                                                            <br>

                                                            <div class="panel-body">
                                                                <div>
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100"
                                                                                [formGroup]="comparativeCashFlowStatement">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputD" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period1 From
                                                                                                Date</label>
                                                                                         
                                                                                          
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                             
                                                                                            <input type="date"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Account Type"
                                                                                            formControlName="period1fromDateCash">
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period2 From
                                                                                                Date</label>
                                                                                            
                                                                                           
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                              
                                                                                            <input type="date"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Account Type"
                                                                                            formControlName="period2fromDateCash">
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputD" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period1 Through
                                                                                                Date</label>
                                                                                           
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                                <input type="date"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Account Type"
                                                                                                formControlName="period1ThroughDateCash">
                                                                                         
                                                                                          
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period2 Through
                                                                                                Date</label>
                                                                                         
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="date"
                                                                                                class="form-control"
                                                                                                id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Account Type"
                                                                                                formControlName="period2ThroughDateCash">
                                                                                            
                                                                                           
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputD" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period1 Gl Fiscal Type
                                                                                                ID</label>
                                                                                         
                                                                                           
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                               
                                                                                            <p-dropdown
                                                                                            placeholder="Enter Period1 Gl Fiscal Type ID"
                                                                                            filter="true"
                                                                                            formControlName="period1FiscalTypeCash"
                                                                                            [options]="glFiscalTypeArray"
                                                                                            optionlabel="label">

                                                                                        </p-dropdown>
                                                                                         
                                                                                          
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period2 Gl Fiscal Type
                                                                                                ID</label>
                                                                                           
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                placeholder="Enter Period2 Gl Fiscal Type ID"
                                                                                                filter="true"
                                                                                                formControlName="period2FiscalTypeCash"
                                                                                                [options]="glFiscalTypeArray"
                                                                                                optionlabel="label">

                                                                                            </p-dropdown>
                                                                                           
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>


                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button"
                                                                                style="margin-left: -30%;">
                                                                                <button type="submit"
                                                                                    (click)="getcomparativeCashFlowStatement();"
                                                                                    class="btn btn-secondary submit-btn">Submit</button>



                                                                            </div>
                                                                        </div>
                                                                        <div>

                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Opening Cash Balance
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="openingCashBalanceComp"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color:white">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance1
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance2
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance1}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance2}}
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Period Cash Balance
                                                                                    </h4>

                                                                                </div>

                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">



                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="periodCashBalanceComp"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Period 1
                                                                                                                Debit
                                                                                                                (Receipts)
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Period 1
                                                                                                                Credit
                                                                                                                (Disbursement)
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance1
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Period 2
                                                                                                                Debit
                                                                                                                (Receipts)
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Period 2
                                                                                                                Credit
                                                                                                                (Disbursement)
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance2
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance1}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance2}}
                                                                                                        </td>









                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Closing Cash Balance
                                                                                    </h4>

                                                                                </div>

                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="closingCashBalanceComp"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance1
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance2
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>






                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance1}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance2}}
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Total
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">



                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="balanceTotalsComp"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color:white">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>





                                                                                                                Total
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Period1
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Period2
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.totalName}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance1}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance2}}
                                                                                                        </td>









                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div *ngIf="activeCategoryValue==9">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling HLeft">
                                                                    Find Comparative Balance Sheet

                                                                </h4>
                                                                <span class="edit-buttons  HRight">

                                                                    <a
                                                                        (click)="getcomparativeGenerateCSVBalanceSheet()">Export
                                                                        as CSV

                                                                    </a></span>

                                                            </div>
                                                            <br>

                                                            <div class="panel-body">
                                                                <div >
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100"
                                                                                [formGroup]="comparativeBalanceSheetStatement">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputD" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period1 Through
                                                                                                Date</label>
                                                                                         
                                                                                           
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                               
                                                                                            <input type="date"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Account Type"
                                                                                            formControlName="period1ThrougDateBalce">
                                                                                          
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period2 Through
                                                                                                Date</label>
                                                                                        
                                                                                         
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                              
                                                                                            <input type="date"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Account Type"
                                                                                            formControlName="period2ThrougDateBalce">
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputD" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period1 Gl Fiscal Type
                                                                                                ID</label>
                                                                                         
                                                                                           
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                             
                                                                                            <p-dropdown
                                                                                            placeholder="Enter Period1 Gl Fiscal Type ID"
                                                                                            filter="true"
                                                                                            formControlName="period1FiscalTypeBalalnce"
                                                                                            [options]="glFiscalTypeArray"
                                                                                            optionlabel="label">

                                                                                        </p-dropdown>
                                                                                     
                                                                                          
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Period2 Gl Fiscal Type
                                                                                                ID</label>
                                                                                       
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                             
                                                                                            <p-dropdown
                                                                                            placeholder="Enter Period2 Gl Fiscal Type ID"
                                                                                            filter="true"
                                                                                            formControlName="period2FiscalTypeBalalnce"
                                                                                            [options]="glFiscalTypeArray"
                                                                                            optionlabel="label">

                                                                                        </p-dropdown>
                                                                                    
                                                                                     
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>




                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button"
                                                                                style="margin-left: -30%;">
                                                                                <button type="submit"
                                                                                    (click)="getcomparativeBalanceSheet();"
                                                                                    class="btn btn-secondary submit-btn">Submit</button>



                                                                            </div>
                                                                        </div>
                                                                        <div>

                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Assets
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="balanceSheetAssetsComp"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance1
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance2
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance1}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance2}}
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Liabilities
                                                                                    </h4>

                                                                                </div>

                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">



                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="balanceSheetLiabilitiesComp"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance1
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance2
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance1}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance2}}
                                                                                                        </td>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <br>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Equities
                                                                                    </h4>

                                                                                </div>

                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="balanceSheetEquitiesComp"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>




                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance1
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="category">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Balance2
                                                                                                                <p-sortIcon
                                                                                                                    field="category">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>






                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.accountCode}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance1}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance2}}
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Total
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">



                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="balanceTotalsBalComp"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>





                                                                                                                Total
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Period1
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Period2
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>








                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.totalName}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance1}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.balance2}}
                                                                                                        </td>









                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div *ngIf="activeCategoryValue==10">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling HLeft">
                                                                    GL Account Trial Balance

                                                                </h4>
                                                                <span class="edit-buttons  HRight">

                                                                    <a (click)="getgeneratePdfglAccountTrialBalance()">PDF

                                                                    </a></span>

                                                            </div>
                                                            <br>

                                                            <div class="panel-body">
                                                                <div>
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100"
                                                                                [formGroup]="trialBalanceForm">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputB" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">

                                                                                                GL Account ID</label>
                                                                                           
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                placeholder="Enter GL Account ID"
                                                                                                filter="true"
                                                                                                formControlName="glAccEntGl"
                                                                                                [options]="assignGLPartyIdArray"
                                                                                                optionlabel="label">

                                                                                            </p-dropdown>
                                                                                         
                                                                                         
                                                                                          
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Time Period</label>
                                                                                           
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                placeholder="Enter Time Period"
                                                                                                filter="true"
                                                                                                formControlName="customeTime"
                                                                                                [options]="timePerodArray"
                                                                                                optionlabel="label">

                                                                                            </p-dropdown>
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputB" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Is Posted</label>
                                                                                           
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                placeholder="Enter Is Posted"
                                                                                                filter="true"
                                                                                                formControlName="postedY"
                                                                                                [options]="postedAll"
                                                                                                optionlabel="label">

                                                                                            </p-dropdown>
                                                                                           
                                                                                         
                                                                                         
                                                                                          
                                                                                            </div>
                                                                                         
                                                                    
                                                                                        </div>
                                                                                    </div>

                                                                               


                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button"
                                                                                style="margin-left: -30%;">
                                                                                <button type="submit"
                                                                                    (click)="getglAccountTrialBalance();"
                                                                                    class="btn btn-secondary submit-btn">Submit</button>



                                                                            </div>
                                                                        </div>
                                                                        <div>

                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        GL Account Trial Balance
                                                                                    </h4>

                                                                                </div>
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select"
                                                                                    *ngIf="this.getTrialListDetails">


                                                                                    <div class="form-group">
                                                                                        <label> Subsidiary
                                                                                            Ledger</label>
                                                                                        <label> Company Name : {{
                                                                                            this.getTrialListDetails.currentOrganization}}</label>
                                                                                        <label> Time Period : {{
                                                                                            this.getTrialListDetails.fromDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}} To {{
                                                                                            this.getTrialListDetails.thruDate|date:'yyyy-MM-dd
                                                                                            hh:mm:ss'}}</label>
                                                                                        <label> Gl account code and Gl
                                                                                            account name : {{
                                                                                            this.getTrialListDetails.glAccount.accountCode}}
                                                                                            {{
                                                                                            this.getTrialListDetails.glAccount.accountName}}</label>
                                                                                        <br>


                                                                                    </div>
                                                                                    <div class="card own-account-table">
                                                                                        <p-table
                                                                                            [value]="transactionEntries"
                                                                                            [paginator]="true"
                                                                                            [rows]="rows"
                                                                                            scrollWidth="100%"
                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [totalRecords]="total"
                                                                                            [scrollable]="true"
                                                                                            (sortFunction)="customSort($event)"
                                                                                            [customSort]="true">
                                                                                            <ng-template
                                                                                                pTemplate="header">
                                                                                                <tr
                                                                                                    style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code"
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align"
                                                                                                            style="color: white;">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>



                                                                                                            Transaction
                                                                                                            Date
                                                                                                            <p-sortIcon
                                                                                                                field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input
                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox"
                                                                                                            aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="name">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Account
                                                                                                            Transaction
                                                                                                            ID
                                                                                                            <p-sortIcon
                                                                                                                field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>



                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Description
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Type of the
                                                                                                            Currency
                                                                                                            <br>
                                                                                                            The balance
                                                                                                            of last year

                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Original
                                                                                                            Currency
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Debit Amount
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Credit
                                                                                                            Amount
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>

                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Debit Or
                                                                                                            Credit Of
                                                                                                            The Balance
                                                                                                            <br>Cr
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                        pSortableColumn="category">
                                                                                                        <div
                                                                                                            style="color: white;">
                                                                                                            Balance Of
                                                                                                            The Account
                                                                                                            <p-sortIcon
                                                                                                                field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>








                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="body"
                                                                                                let-product>
                                                                                                <tr
                                                                                                    *ngFor="let data of product.acctgTransAndEntries">
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            class="checkbox-align">
                                                                                                            <p-checkbox
                                                                                                                _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox"
                                                                                                                binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div
                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                        <input
                                                                                                                            type="checkbox"
                                                                                                                            name="undefined"
                                                                                                                            value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            <span>{{data.transactionDate}}</span>


                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{data.transactionId}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{data.description}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{data.typeOfTheCurrency}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{data.originalCurrency}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{data.debitAmount}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{data.creditAmount}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{data.debitOrCreditOfBalance}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{data.balanceOfTheAccount}}
                                                                                                    </td>








                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            class="checkbox-align">

                                                                                                            <span></span>


                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        Total of the
                                                                                                        current month
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.totalOfYearToDateDebit}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.balanceOfTheAcctgForYear}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        Cr
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.balance}}
                                                                                                    </td>








                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        <div
                                                                                                            class="checkbox-align">

                                                                                                            <span></span>


                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        Total of Year To
                                                                                                        Date
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.totalOfYearToDateDebit}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.totalOfYearToDateCredit}}
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        Cr
                                                                                                    </td>
                                                                                                    <td
                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.balance}}
                                                                                                    </td>








                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template
                                                                                                pTemplate="emptymessage"
                                                                                                let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span
                                                                                                            class="text-center">
                                                                                                            <h4
                                                                                                                class="ml-2">
                                                                                                                No
                                                                                                                Record
                                                                                                                Found
                                                                                                            </h4>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page

                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div *ngIf="activeCategoryValue==11">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling HLeft">
                                                                    Find Inventory Valuation

                                                                </h4>
                                                                <span class="edit-buttons  HRight">

                                                                    <a (click)="getgenerateCsvinventoryValuation()">Export
                                                                        as CSV

                                                                    </a></span>

                                                            </div>
                                                            <br>

                                                            <div class="panel-body">
                                                                <div>
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100"
                                                                                [formGroup]="inventoryValuation">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputB" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Facility ID</label>
                                                                                       
                                                                                           
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                              
                                                                                            <p-dropdown
                                                                                            placeholder="Enter Facility ID"
                                                                                            filter="true"
                                                                                            formControlName="facilityId"
                                                                                            [options]="facilities"
                                                                                            optionlabel="label">

                                                                                        </p-dropdown>
                                                                                         
                                                                                          
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Product ID</label>
                                                                                         
                                                                                           
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                
                                                                                           
                                                                                            <input type="email"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Account Type"
                                                                                            formControlName="productId">
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputB" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Through Date</label>
                                                                                          
                                                                                           
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                              
                                                                                            <input type="date"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Account Type"
                                                                                            formControlName="throughDate">
                                                                                          
                                                                                            </div>
                                                                                       
                                                                                        </div>
                                                                                    </div>

                                                                                

                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button"
                                                                                style="margin-left: -30%;">
                                                                                <button type="submit"
                                                                                    (click)="getinventoryValuation();"
                                                                                    class="btn btn-secondary submit-btn">Submit</button>



                                                                            </div>
                                                                        </div>
                                                                        <div>

                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Inventory Valuation List
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="inventoryValuationData"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th pSortableColumn="code"
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>





                                                                                                                Product
                                                                                                                ID
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Unit
                                                                                                                Cost
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Accounting
                                                                                                                Quantity
                                                                                                                Sum
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Value
                                                                                                                <p-sortIcon
                                                                                                                    field="name">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                            <div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </div>
                                                                                                        </th>

                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </p-checkbox>
                                                                                                                {{product.productId}}

                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.unitCost}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountingQuantitySum}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            US${{product.value}}
                                                                                                        </td>







                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page

                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div *ngIf="activeCategoryValue==12">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling HLeft">
                                                                    Find Cost Centers
                                                                </h4>
                                                                <span class="edit-buttons  HRight">

                                                                    <a (click)="getgeneratePdfcostCenterAcc()">Export as
                                                                        PDF

                                                                    </a></span>

                                                            </div>
                                                            <br>

                                                            <div class="panel-body">
                                                                <div >
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100"
                                                                                [formGroup]="costCenterForm">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputB" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Form Date</label>
                                                                                         
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                             
                                                                                            <input type="date"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Account Type"
                                                                                            formControlName="costFromDate">
                                                                                   
                                                                                                 
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Through Date</label>
                                                                                          
                                                                                           
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                              
                                                                                            <input type="date"
                                                                                            class="form-control"
                                                                                            id="exampleInputEmail1"
                                                                                            aria-describedby="emailHelp"
                                                                                            placeholder="Enter Account Type"
                                                                                            formControlName="costThroughDate">
                                                                                     
                                                                                            </div>
                                                                    
                                                                                           
                                                                    
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInputB" >
                                                                                                <label
                                                                                                for="exampleInputEmail1">
                                                                                                Fiscal Gl Type</label>
                                                                         
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                           
                                                                                                <p-dropdown
                                                                                                placeholder="Enter Fiscal Gl Type"
                                                                                                filter="true"
                                                                                                formControlName="costFiscal"
                                                                                                [options]="glFiscalTypeArray"
                                                                                                optionlabel="label">

                                                                                            </p-dropdown>
                                                                                        
                                                                                            </div>
                                                                                         
                                                                                        </div>
                                                                                    </div>



                                                                                </div>


                                                                            </form>
                                                                            <div class="main-submit-button"
                                                                                style="margin-left: -30%;">
                                                                                <button type="submit"
                                                                                    (click)="getcostCenterAcc();"
                                                                                    class="btn btn-secondary submit-btn">Submit</button>



                                                                            </div>
                                                                        </div>
                                                                        <div>

                                                                            <div>
                                                                                <div class="header-tabs">
                                                                                    <h4 class=" common-styling">
                                                                                        Cost Centers
                                                                                    </h4>

                                                                                </div>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">


                                                                                    <div class="form-group">
                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="costCenterValue"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'200px'}"
                                                                                                            pSortableColumn="code">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>



                                                                                                                Gl
                                                                                                                Account
                                                                                                                ID
                                                                                                                <p-sortIcon
                                                                                                                    field="code">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                        </th>


                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="price">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Code
                                                                                                                <p-sortIcon
                                                                                                                    field="price">
                                                                                                                </p-sortIcon>
                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="price">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Account
                                                                                                                Name
                                                                                                                <p-sortIcon
                                                                                                                    field="price">
                                                                                                                </p-sortIcon>

                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="price">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Posted
                                                                                                                Balance
                                                                                                                <p-sortIcon
                                                                                                                    field="price">
                                                                                                                </p-sortIcon>

                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="price"
                                                                                                            *ngFor="let data of glAccountCategories;">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                {{data.description}}
                                                                                                                <p-sortIcon
                                                                                                                    field="price">
                                                                                                                </p-sortIcon>

                                                                                                            </div>
                                                                                                        </th>


                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'200px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                {{product.glAccountId}}
                                                                                                            </div>
                                                                                                        </td>


                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountCode}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.accountName}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.balance}}
                                                                                                        </td>
                                                                                                        <td [ngStyle]="{'width':'170px'}"
                                                                                                            *ngFor="let data of product.costCenterList;">
                                                                                                            {{data.value|
                                                                                                            number :
                                                                                                            '1.2-2'}}
                                                                                                        </td>




                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>
                                                                                            <p class="paginate_data">
                                                                                                View per page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>