import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FinancialService } from 'src/app/services/financial.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['./create-product.component.css']
})
export class CreateProductComponent implements OnInit {
  show: boolean;
  fixedAssetId: string;
  productForm: FormGroup;
  editMode: boolean;
  productTypeIds: any[];
  uomIds: any[];
  productIds: any[];
  productData: any;
  activeCategory = 2;
  constructor(
    readonly _ToastrService: ToastrService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FinancialService: FinancialService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly _AccountingApService: AccountingApService
  ) {
    this.fixedAssetId = '';
    this.productForm = this._FormBuilder.group({
      comments: [''],
      fixedAssetProductTypeId: [''],
      fromDate: [''],
      productId: [''],
      quantity: [''],
      quantityUomId: [''],
      sequenceNum: [''],
      thruDate: ['']
    });
    this.editMode = false;
    this.productTypeIds = [];
    this.uomIds = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.fixedAssetId = this._ActivatedRoute.snapshot.queryParams.fixedAssetId;
    this._ActivatedRoute.queryParams.subscribe(data => {
      if (data.fixedAssetProductTypeId && data.productId) {
        this._FinancialService.getProductById({
          fixedAssetId: data.fixedAssetId,
          fixedAssetProductTypeId: data.fixedAssetProductTypeId,
          fromDate: moment(Number(data.fromDate)).format('YYYY-MM-DD HH:mm:ss'),
          productId: data.productId
        })
          .then(data => {
            if (data.success) {
              this.productData = data.data[0];
              this.productForm.patchValue({
                ...data.data[0], ...{
                  fromDate: data.data[0].fromDate ? moment(data.data[0].fromDate).format('YYYY-MM-DD') : '',
                  thruDate: data.data[0].thruDate ? moment(data.data[0].thruDate).format('YYYY-MM-DD') : ''
                }
              });
            }
          });
        this.productForm.controls.productId.disable();
        this.productForm.controls.productId.updateValueAndValidity();
        this.productForm.controls.fromDate.disable();
        this.productForm.controls.fromDate.updateValueAndValidity();
        this.productForm.controls.quantityUomId.disable();
        this.productForm.controls.quantityUomId.updateValueAndValidity();
        this.productForm.controls.fixedAssetProductTypeId.disable();
        this.productForm.controls.fixedAssetProductTypeId.updateValueAndValidity();
        this.productForm.controls.quantity.disable();
        this.productForm.controls.quantity.updateValueAndValidity();
        this.productForm.controls.comments.disable();
        this.productForm.controls.comments.updateValueAndValidity();
        this.productForm.controls.sequenceNum.disable();
        this.productForm.controls.sequenceNum.updateValueAndValidity();
        this.editMode = true;
      }
    });
    this.getProductTypeIds();
    this.getUomIds();
    this.getProducts();
  }
  getProducts(): void {
    this.spinner.show();
    this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, {
      internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains'
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.productIds = data.data.map(value => {
            return {
              label: value.productId,
              value: value.productId
            };
          });
        }
      });
     
  }
  getProductTypeIds(): void {
    this.spinner.show();
    this._FinancialService.getProductInitialData()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.productTypeIds = data.data.fixedAssetProductTypes.map(value => {
            return {
              label: value.description,
              value: value.fixedAssetProductTypeId
            };
          });
        }
      });
     
  }
  getUomIds(): void {
    this.spinner.show();
    this._AccountingApService.getTermType()
      .then(data => {
        this.spinner.hide();
        this.uomIds = data.data.uom.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });
     
  }

  onUpdate() {
    this.spinner.show();
    this._FinancialService.updateProduct(this.fixedAssetId, {
      ...this.productData, ...this.productForm.value, ...{
        fromDate: this.productData.fromDate ? moment(this.productData.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDate: this.productForm.value.thruDate ? moment(this.productForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Updated');
          this.spinner.hide();
          this.productForm.controls.productId.enable();
          this.productForm.controls.productId.updateValueAndValidity();
          this.productForm.controls.fromDate.enable();
          this.productForm.controls.fromDate.updateValueAndValidity();
          this.productForm.controls.quantityUomId.enable();
          this.productForm.controls.quantityUomId.updateValueAndValidity();
          this.productForm.controls.fixedAssetProductTypeId.enable();
          this.productForm.controls.fixedAssetProductTypeId.updateValueAndValidity();
          this.productForm.controls.quantity.enable();
          this.productForm.controls.quantity.updateValueAndValidity();
          this.productForm.controls.comments.enable();
          this.productForm.controls.comments.updateValueAndValidity();
          this.productForm.controls.sequenceNum.enable();
          this.productForm.controls.sequenceNum.updateValueAndValidity();
          this.cancelSubmit();
        }
      });
     
  }
  onSubmit() {
    this.spinner.show();
    this._FinancialService.createProduct(this.fixedAssetId, {
      ...this.productForm.value, ...{
        fromDate: this.productForm.value.fromDate ? moment(this.productForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDate: this.productForm.value.thruDate ? moment(this.productForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this._ToastrService.success('Created');
          this.cancelSubmit();
        }
      });
   
  }
  cancelSubmit() {
    this.productForm.reset();
    this._Router.navigate(['/financial/fix-assest-summary-page'], { queryParams: { fixedAssetId: this.fixedAssetId } });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

