import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class EmployementApplicationService {

  constructor(readonly http: HttpClient) { }
 getEmployeeApplication(getList):Observable<any>{
     const getEmployeeApp = URLS.EmploymentAppList + "?pageNo=" + getList.pageNo + "&pageSize=" + getList.pageSize;
     return this.http.get(getEmployeeApp);
 }
 getEmpJobList(job,jobList) :Observable<any>{
  const getApplicationEmployeeList = URLS.getApplicationEmployeeList + "?pageSize=" + job.pageSize +"&pageNo=" + job.pageNo;
  return this.http.post(getApplicationEmployeeList,jobList);
}
  


}
