import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DetailPositionService {

  constructor(private http:HttpClient) { }

  getEmployeeDetail(id):Observable<any> {
    const employeePositionDetail = URLS.getEmplPositionById.replace(":empPositionId",id)
    return this.http.get(employeePositionDetail)
  }

  getFullFillment():Observable<any> {
    const fullFillMent = URLS.getFulfillment;
    return this.http.get(fullFillMent);
  }

getResponsibility():Observable<any> {
  const responsibility = URLS.getResponsibilityTypeId;
  return this.http.get(responsibility);
}
getPositionReportedTo(empPositionId):Observable<any> {
  const reportedTo = URLS.getPositionReportedTo + "?emplPositionId=" + empPositionId;
  return this.http.get(reportedTo);
}
getPositionReportsTo(empPositionId):Observable<any> {
 
  const reportedTo = URLS.getPositionReportsTo + "?emplPositionId=" + empPositionId;
  return this.http.get(reportedTo);
}

postEmpPositionFullfillment(id,formdata):Observable<any> {
  const employeePositionDetail = URLS.createPositionFullfillment.replace("empPositionId",id)
  return this.http.post(employeePositionDetail,formdata)
}
updateEmpPositionFullfillment(id,updatePartyID,date,formdata):Observable<any> {
  
  const employeePositionDetail = URLS.deletePositionFullfillment.replace("empPositionId",id).replace("partyId",updatePartyID) + "?fromDate=" + date;

  return this.http.put(decodeURIComponent(employeePositionDetail),formdata)
}
deleteEmpPositionFullfillment(id,updatePartyID,date):Observable<any> {
  
  const employeePositionDetail = URLS.deletePositionFullfillment.replace("empPositionId",id).replace("partyId",updatePartyID) + "?fromDate=" + date;
  return this.http.delete(decodeURIComponent(employeePositionDetail))
}

getEmployeePosition(lists,emplPosition) {
  const getEmployeePosition = URLS.getEmplPositions + "?pageNo=" + lists.pageNo + "&pageSize=" + lists.pageSize;
  return this.http.post(getEmployeePosition,emplPosition);
} 
getPartId():Observable<any> {
  const partyID = URLS.getMyPartyGroupID;
  return this.http.get(partyID);
}
getEmpPositionResponsibility():Observable<any> {
  const partyID = URLS.getEmpPositionResponsibility;
  return this.http.get(partyID);
}

postEmpPositionResponsibility(id,formdata):Observable<any> {
  const createPositionResponsibility = URLS.createPositionResponsibility.replace("emplPositionId",id)
  return this.http.post(createPositionResponsibility,formdata)
}
getEmpPositionResponsibilityList(id):Observable<any> {
  const createPositionResponsibility = URLS.createPositionResponsibility.replace("emplPositionId",id)
  return this.http.get(createPositionResponsibility)
}
deleteResponsibility(id,updatePartyID,date):Observable<any> {
  
  const deletePositionResponsibility = URLS.deletePositionResponsibility.replace("emplPositionId",id).replace("responsibilityTypeId",updatePartyID) + "?fromDate=" + date;
  return this.http.delete(decodeURIComponent(deletePositionResponsibility))
}
updateEmpPositionResponsibility(id,updatePartyID,date,formdata):Observable<any> {
  
  const deletePositionResponsibility = URLS.deletePositionResponsibility.replace("emplPositionId",id).replace("responsibilityTypeId",updatePartyID) + "?fromDate=" + date;

  return this.http.put(decodeURIComponent(deletePositionResponsibility),formdata)
}
createPositionReportStructureTo(formdata):Observable<any> {
  const createPositionReportStructureTo = URLS.createPositionReportStructureTo
  return this.http.post(createPositionReportStructureTo,formdata)
}
createPositionReportStructureBy(formdata):Observable<any> {
  const createPositionReportStructureBy = URLS.createPositionReportStructureBy
  return this.http.post(createPositionReportStructureBy,formdata)
}
}
