
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { JobInterviewModel } from './job-interview-model';

@Injectable({
  providedIn: 'root'
})


export class JobInterviewFormService {
  job_interview_form: FormGroup;
  constructor(public _formBuilder: FormBuilder,
    public jobInterviewModel: JobInterviewModel
  ) { }

  jobInterviewForm(data: any): void {
    if (!this.job_interview_form) {
      this.job_interview_form = this._formBuilder.group(this.jobInterviewModel.mapDataToModel(data));

    }

  }

}










