import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { NgxSpinnerService } from "ngx-spinner";
import {DeparmentsService} from "./departments.service";
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';    
import {BehaviourSubjectService} from "../../behaviour-subject.service";
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import {Router,ActivatedRoute} from "@angular/router";
import { AddLeaveEmpService } from '../leave/add-new-leaves/add-new-leaves.service';
import { CreateDepartmentFormService } from '../create-departments/create-departments-service';
import { CreateDeparmentsService } from '../create-departments/create-departments.service';

import { Location } from '@angular/common';



@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.css']
})
export class DepartmentsComponent implements OnInit {
  activeCategory=2;
  showError: any;
  products3: any;
  @ViewChild('closebutton') closebutton;
  total=0;
  rowNumber=0;
  pageSize= 100;
  rows = 50;
  pageNo=1;
  search="";
  public departments = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  departmentList: any;
  companyData: any;
  companyArray: any[]=[];  
  readonly employessSize={
    pageNo:this.pageNo,
    pageSize:this.pageSize,
  
  }
  companyName: any;
  array: any[]=[];
  partyId: any;
  partyIdArray: any[]=[];
  show: boolean;
 

  constructor(readonly accountsService: AccountsService,
    readonly router: Router,@Inject(DOCUMENT) readonly document: Document,
    readonly toastr:ToastrService,
    readonly deparmentsService:DeparmentsService,
    readonly spinner:NgxSpinnerService,
    readonly behaviourSubject:BehaviourSubjectService,
    readonly createDeparmentsService:CreateDeparmentsService,
    public createDepartmentFormService:CreateDepartmentFormService,
      private _location: Location,
      readonly activatedRoute:ActivatedRoute,
    readonly addLeaveEmpService: AddLeaveEmpService,
    ) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getdepartmentsList();
    this.createDepartmentFormService.job_requistion_form=null;
    this.createDepartmentFormService.jobRequistionForm(this.createDepartmentFormService.job_requistion_form)
    this.show=false;
    this.getAllAccount();
    this.getCompanyName();
    this.getPartyId();
    
    
  
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  getPartyId() {
    this.spinner.show();
    this.addLeaveEmpService.getPartyId().subscribe(res => {
      this.partyId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getCompanyName() {
    this.spinner.show();
    const req={
      "companyId": "",
      "companyIdSearchType": "Contains",
      "departmentId": "",
      "departmentIdSearchType": "Contains",
      "departmentName": "",
      "departmentNameSearchType": "Contains"
    }
    this.createDeparmentsService.getDepartment(this.employessSize,req).subscribe((res:any)=> {
      this.companyName = res.data;
      this.spinner.hide();
      this.companyName.forEach(value=> {
        for(const value of this.companyName){
        this.array.push({
          label:value.parentDepartment,
          value:value.parentDepartmentId
        })
      }
      })
    })
  
  }
  getdepartmentsList(){
    this.spinner.show();
   const dept =  {
      companyId: "",
     companyIdSearchType: "Contains",
      departmentId: "",
      departmentIdSearchType: "Contains",
      departmentName: "",
      departmentNameSearchType: "Contains"
    }
   this.deparmentsService.getDepartment(this.departments,dept).subscribe((res:any)=> {
     this.departmentList = res.data;
     this.spinner.hide();
   })
  
  }


  onUpdate(id){
    this.router.navigate(["/hr/departments/create-departments"],{queryParams:{updateId:id}})
  }
  detailPage(id){
    this.router.navigate(["/hr/departments/detail-summary"],{queryParams:{updateId:id}})
   
  }
  onDelete(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.deparmentsService.deleteDept(id).subscribe((res:any)=> {
          this.document.location.reload();
      },(err) => {
        this.toastr.error(err.error.message);
     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
}
employeeList(departmentId) {
  this.router.navigate(["./hr/departments/employee-list"],
  { queryParams: { departmentId:departmentId} })
 

}

// employeeList(EmployeeList) {
//   this.router.navigate(["./hr/departments/employee-list"])
//   this.behaviourSubject.transferData(EmployeeList)
// }

/* new ts */
onSubmit(){
  this.spinner.show();
  this.createDepartmentFormService.job_requistion_form.markAllAsTouched();
  const formData={...this.createDepartmentFormService.job_requistion_form.value};
  this.createDeparmentsService.createDepartMent(formData).subscribe(res=> {
    if(res.success) {
      this.toastr.success(res.data.successMessage);
      this.spinner.hide();
      this.getdepartmentsList();
      this.createDepartmentFormService.job_requistion_form.reset();
      this.closebutton.nativeElement.click();
      
    }
    else if(res.success == false) {
      this.spinner.hide();
      this.toastr.error("error");
    }
  },(err) => {
    this.spinner.hide();
    this.showError=(err.error.errors[0])
    this.toastr.error(this.showError.fieldName + " " +this.showError.fieldError)

 })

}

getAllAccount(){
  this.spinner.show();
  const req={
    "contactMechTypeId": "",
    "groupName": "",
    "groupNameSearchType": "Contains",
    "partyId": "",
    "partyIdSearchType": "Contains"
  }
  let all = "true"
  this.createDeparmentsService.getAllAccount(this.employessSize,req,all).subscribe((res:any)=> {
    this.companyData = res.data;
    this.spinner.hide();
    for(const value of this.companyData) {
      this.companyArray.push({
        label:value.groupName,
        value:value.partyId
      })
    }
  })
 
}

/* new ts end*/

ngOnDestroy(): void {
  this.spinner.hide();
    }
}
