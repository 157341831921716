import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from '../../header/header.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { DropdownModule } from 'primeng/dropdown';
import { ToastrService } from 'ngx-toastr';
import { OrderManagementService } from '../order-management.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-purchase-finalaize-order',
  templateUrl: './purchase-finalaize-order.component.html',
  styleUrls: ['./purchase-finalaize-order.component.css']
})
export class PurchaseFinalaizeOrderComponent implements OnInit {
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  config: NgWizardConfig = {
    selected: 3,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ],
    }
  };
  products3: any;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  supplierList: any[];
  agreementList: any[];
  addressList: any[];
  partyIds: any[];
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  activeCategory = 1;
  shippingAddress: any;
  partyIdValue: string;
  constructor(
    readonly _ToastrService: ToastrService,
    readonly _OrderManagementService: OrderManagementService,
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _GlobalResourceService: GlobalResourceService
  ) {
    this.partyIds = [];
    this.partyIdValue = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails()
   
    this.createNewShipGroup();
    this.getPartyIds();
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.spinner.hide();
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });
     
  }
  createNewShipGroup(): void {
    this.spinner.show();
    this._OrderManagementService.createNewShipGroup('N')
      .then(data => {
        this.spinner.hide();
        this.agreementList = data.data.agreementList.map(value => {
          return {
            label: value.description,
            value: value.agreementId
          };
        });
        this.supplierList = data.data.suppliersList.map(value => {
          return {
            label: value.partyName,
            value: value.partyId
          };
        });
        this.addressList = data.data.listPOShipGrp;
      });
      
  }
  setShipping(event: any, geoId: string, facilityId: string): void {
   
    if (event) {
      this.shippingAddress = `${geoId}_@_${facilityId.split('[')[1].split(']')[0].trim()}`;
    } else {
      this.shippingAddress = '';
    }
  }
  finalizeOrder(): void {
    this.spinner.show();
    this._OrderManagementService.finalizeOrderWithDynamicBody({
      finalizeMode: 'ship',
      shipToPartyId: '',
      '0_supplierPartyId': '',
      '0_supplierAgreementId': '',
      '0_shipGroupFacilityId': '',
      '0_shipping_contact_mech_id': this.shippingAddress
    })
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          switch (data.data.responseMessage) {
            case 'shipping': this.router.navigate(['/order/purchase-finalize-order']);
              break;
            case 'options': this.router.navigate(['/order/purchase-order-options']);
              break;
            case 'term': this.router.navigate(['/order/purchase-order-entry-terms']);
              break;
            case 'addparty': this.router.navigate(['/order/purchase-order-additional-party-entry']);
              break;
            case 'purchase': this.router.navigate(['/order/purchase-order-confirmation']);
              break;
          }
      
        }
      });
      this.spinner.hide();
  }

}
