<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Lead </span>
                <span class="color-black pl-1"> > Find</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    Find Leads
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Lead ID</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">First name</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Last name</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Company Name</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Phone Number</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Email Address</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Classification</label>
                                            <div class="">
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">To Name</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Attention Name</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Address Line 1</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">City</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Country</label>
                                            <div class="">
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">State/Province</label>
                                            <div class="">
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Postal Code</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>

                                </div>
                                <div class="main-submit-button">
                                    <button type="submit" class="btn btn-secondary submit-btn">Find Leads</button>
                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0 background-grey text-white mb-2"
                                    data-toggle="collapse" href="#collapseone">
                                    <span class="">
                                        Lead List
                                    </span>
                                    <span class="right-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18.541" height="9.27"
                                            viewBox="0 0 18.541 9.27">
                                            <defs>
                                                <style>
                                                    .a {
                                                        fill: #fff;
                                                    }
                                                </style>
                                            </defs>
                                            <g transform="translate(0 9.27) rotate(-90)">
                                                <g transform="translate(0 0)">
                                                    <path class="a"
                                                        d="M9.163,9.537l-8.5,8.884A.386.386,0,0,1,0,18.154V16.609a.386.386,0,0,1,.106-.266l6.7-7.073L.106,2.2A.386.386,0,0,1,0,1.931V.386A.386.386,0,0,1,.665.119L9.163,9A.387.387,0,0,1,9.163,9.537Z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </span>

                                </div>
                                <div class="collapse" id="collapseone">

                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12 col-12">
                                            <div class="form-group">
                                                <label for="exampleInputEmail1">Leads List</label>

                                                <div class="card own-account-table">
                                                    <p-table [value]="products3" [paginator]="true" [rows]="rows"
                                                        [style]="{width:'1200px'}" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                                        [scrollable]="true" (sortFunction)="customSort($event)"
                                                        [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th pSortableColumn="code">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined"></div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Lead Id <p-sortIcon field="code"></p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="name">First Name <p-sortIcon
                                                                        field="name"></p-sortIcon>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="name">Last Name <p-sortIcon
                                                                        field="name"></p-sortIcon>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>

                                                                <th pSortableColumn="name">Company Name <p-sortIcon
                                                                        field="name"></p-sortIcon>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="category">City <p-sortIcon
                                                                        field="category"></p-sortIcon>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="quantity">State/Province
                                                                    <p-sortIcon field="quantity"></p-sortIcon>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price">Phone Number <p-sortIcon
                                                                        field="price"></p-sortIcon>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price">Email Address <p-sortIcon
                                                                        field="price"></p-sortIcon>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price">Action
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        style="visibility:hidden;" value=""
                                                                        autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td>
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined"></div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        {{product.code}}
                                                                    </div>
                                                                </td>
                                                                <td>{{product.name}}</td>
                                                                <td>{{product.category}}</td>
                                                                <td>{{product.quantity}}</td>
                                                                <td>{{product.price | currency: 'USD'}}</td>
                                                                <td>{{product.category}}</td>
                                                                <td>
                                                                    <div>
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            width="16" height="16"
                                                                            viewBox="0 0 21.223 21.222">
                                                                            <g transform="translate(0 -0.004)">
                                                                                <g transform="translate(0 1.52)">
                                                                                    <path
                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                        transform="translate(0 -34.137)" />
                                                                                </g>
                                                                                <g transform="translate(4.548 0.004)">
                                                                                    <g transform="translate(0 0)">
                                                                                        <path
                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                            class="ml-20" width="16" height="16"
                                                                            viewBox="0 0 26 26">
                                                                            <defs>
                                                                                <style>
                                                                                    .a {
                                                                                        fill: #f44336;
                                                                                    }

                                                                                    .b {
                                                                                        fill: #fafafa;
                                                                                        margin-left: 20px;
                                                                                    }
                                                                                </style>
                                                                            </defs>
                                                                            <path class="a fill-color"
                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                            <path class="b"
                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                transform="translate(-147.576 -147.576)" />
                                                                        </svg>
                                                                    </div>
                                                                </td>

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                                                    
                                                    <p class="paginate_data">
                                                        View per page </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 