import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountingApService } from '../accounting-ap.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.css']
})
export class VendorsComponent implements OnInit {
  products3: any;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  statusIds: any[];
  invoiceType: any[];
  opportunityIdArray = [
    {
      label: 'Contains',
      value: 'Contains'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    },
    {
      label: 'Begins With',
      value: 'Begins With'
    },
  ];
  greaterArray = [
    {
      label: 'Equals',
      value: 'Equals'
    },
    {
      label: 'Same Day',
      value: 'Same Day'
    },
    {
      label: 'Greater Than From Day Start',
      value: 'Greater Than From Day Start'
    },
    {
      label: 'Greater Than',
      value: 'Greater Than'
    }
  ];
  smallerArray = [
    {
      label: 'Less Than',
      value: 'Less Than'
    },
    {
      label: 'Up To Day',
      value: 'Up To Day'
    },
    {
      label: 'Up Through Day',
      value: 'Up Through Day'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    }
  ];
  searchInvoiceForm: FormGroup;
  invoices: any[];
  partyIds: any[];
  constructor(
    readonly _FormBuilder: FormBuilder,
    readonly _AccountingApService: AccountingApService,
    readonly _ToastrService: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _GlobalResourceService: GlobalResourceService
  ) {
    this.searchInvoiceForm = this._FormBuilder.group({
      description: [''],
      descriptionSearchType: ['Contains'],
      fromPartyId: [''],
      invoiceDateFrom: [new Date()],
      invoiceDateFromSearchType: ['Equals'],
      invoiceDateTo: [new Date()],
      invoiceDateToSearchType: ['Less Than'],
      invoiceId: [''],
      invoiceIdSearchType: ['Contains'],
      invoiceType: ['PURCHASE_INVOICE', [Validators.required]],
      statusId: ['INVOICE_IN_PROCESS'],
      toPartyId: ['']
    });
    this.invoiceType = [];
    this.statusIds = [];
    this.invoices = [];
    this.partyIds = [];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getInvoiceTypeAndStatus();
    this.getPartyIds();
  }
  getInvoiceTypeAndStatus(): void {
    this.spinner.show();
    this._AccountingApService.getInvoiceTypeAndStatus()
      .then(data => {
        this.statusIds = data.data.invoiceStatus.map(value => {
          return {
            label: value.description,
            value: value.statusId
          };
        });
        this.invoiceType = data.data.invoiceType.map(value => {
          return {
            label: value.description,
            value: value.invoiceTypeId
          };
        });
      });
      this.spinner.hide();
  }



  getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.partyIds = data.data.PersonsGroups.map(value => {
          return {
            label: value.partyId,
            value: value.partyId
          };
        });
      });
      this.spinner.hide();
  }



  searchInvoice(): void {
    this.spinner.show();
    if (this.searchInvoiceForm.valid) {
      this._AccountingApService.searchInvoice({ pageNo: 1, pageSize: 200 }, this.searchInvoiceForm.value)
        .then(data => {
          this.spinner.hide();
          this.invoices = data.data;
        })
        .catch(err => {
          this.spinner.hide();
          if (err.errors) {
            err.errors.forEach(value => {

              this._ToastrService.success(value.fieldName);
            });
          }
        });
    }
   
  }


  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
