import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NewWarehouseShipmentService } from './new-warehouse-shipment.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { WarehouseShipmentService } from '../warehouse-shipment/warehouse-shipment.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-new-warehouse-shipment',
  templateUrl: './new-warehouse-shipment.component.html',
  styleUrls: ['./new-warehouse-shipment.component.css']
})
export class NewWarehouseShipmentComponent implements OnInit {
  total=0;
  activeCategory = 2;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  
  newWarehouseShipment: FormGroup;
  editMode: boolean;
  OrderIdsList: any = [];
  personsAndPartyGroupsList = [];
  getCurrencyList: any = [];
  StatusIdList: any = [];
  ShipmentTypeIdList: any = [];

  facilities: any[];
  facilityId: string;
  estimatedReadyDate: string;
  estimatedShipDate: string;
  estimatedArrivalDate: string;
  latestCancelDate: string;
  eventDate: string;
  url: string;

  constructor(readonly router: Router,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly WarehouseShipmentService: WarehouseShipmentService,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,) {
      this.url='facilities/warehouse-shipment'
    this.newWarehouseShipment = this._FormBuilder.group({
      statusId: [''],
      primaryOrderId: [''],
      primaryReturnId: [''],
      primaryShipGroupSeqId: [''],
      picklistBinId: [''],
      estimatedReadyDate: [''],
      estimatedShipDate: [''],
      estimatedArrivalDate: [''],
      latestCancelDate: [''],
      estimatedShipCost: [''],
      currencyUomId: [''],
      handlingInstructions: [''],
      originFacilityId: [''],
      originFacility: [''],
      originTelecomNumberId: [''],
      destinationFacilityId: [''],
      destinationTelecomNumberId: [''],
      partyIdTo: [''],
      partyIdFrom: [''],
      additionalShippingCharge: [''],
      addtlShippingChargeDesc: [''],
      eventDate: [''],
      shipmentTypeId: [''],
      adress:['']
    });
    this.editMode = false;
    this.facilities = [];
    this.facilityId = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getOrderIds();
    this.getPersonsAndPartyGroups();
    this.getCurrency();
    this.getStatusId();
    this.getFacility();
    this.getShipmentTypeId();
  }

  getOrderIds() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getOrderIds().subscribe((responce) => {
      this.OrderIdsList = responce.data;
      this.spinner.hide();
    }, (error) => {
      return;
    });
   
  }

  getPersonsAndPartyGroups() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getPersonsAndPartyGroups().subscribe((responce) => {
      this.spinner.hide();
      const partyArray = responce.data.PersonsGroups
      this.personsAndPartyGroupsList = partyArray;
    }, (error) => {
      return;
    });
  
  }

  getCurrency() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getCurrency().subscribe((responce) => {
      const currencyArray = responce.data.currencyList;
      this.getCurrencyList = currencyArray;
      this.spinner.hide();
    }, (error) => {
      return;
    });
   
  }

  getStatusId() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getStatusId().subscribe((responce) => {
      this.StatusIdList = responce.data;
      this.spinner.hide();
    }, (error) => {
      return;
    });
   
  }

  getFacility(): void {
    this.spinner.show();
    this.WarehouseShipmentService.getFacility()
      .then(data => {
        this.spinner.hide();
        this.facilities = data.data.map(value => {
          return {
            label: value.facilityName,
            value: value.facilityId
          };
        });
      });
     
  }
  getShipmentTypeId() {
    this.spinner.show();
    this.WarehouseShipmentService.getShipmentTypeIdList().subscribe((responce) => {
      this.ShipmentTypeIdList = responce.data;
      this.spinner.hide();
    }, (error) => {
      return;
    });
    
  }

  submit(): void {
    this.spinner.show();
    this.estimatedReadyDate=this.datePipe.transform(this.newWarehouseShipment.value.estimatedReadyDate,"yyyy-MM-dd hh:mm:ss"),
    this.estimatedShipDate=this.datePipe.transform(this.newWarehouseShipment.value.estimatedShipDate,"yyyy-MM-dd hh:mm:ss"),
    this.estimatedArrivalDate=this.datePipe.transform(this.newWarehouseShipment.value.estimatedArrivalDate,"yyyy-MM-dd hh:mm:ss"),
    this.latestCancelDate=this.datePipe.transform(this.newWarehouseShipment.value.latestCancelDate,"yyyy-MM-dd hh:mm:ss"),
    this.eventDate=this.datePipe.transform(this.newWarehouseShipment.value.eventDate,"yyyy-MM-dd hh:mm:ss")
    const requestData = {
      statusId: this.newWarehouseShipment.value.statusId.statusId,
      primaryOrderId: this.newWarehouseShipment.value.primaryOrderId.orderId,
      primaryReturnId: this.newWarehouseShipment.value.primaryReturnId,
      primaryShipGroupSeqId: this.newWarehouseShipment.value.primaryShipGroupSeqId,
      picklistBinId: this.newWarehouseShipment.value.picklistBinId,
      estimatedReadyDate:this.estimatedReadyDate,
      estimatedShipDate:this.estimatedShipDate,
      estimatedArrivalDate:this.estimatedArrivalDate,
      latestCancelDate: this.latestCancelDate,
      estimatedShipCost: this.newWarehouseShipment.value.estimatedShipCost,
      currencyUomId: this.newWarehouseShipment.value.currencyUomId.uomId,
      handlingInstructions: this.newWarehouseShipment.value.handlingInstructions,
      originFacilityId:this.newWarehouseShipment.value.originFacilityId.value,
     
      originContactMechId: this.newWarehouseShipment.value.adress,
      originTelecomNumberId: this.newWarehouseShipment.value.originTelecomNumberId,
      destinationContactMechId:this.newWarehouseShipment.value.destinationFacilityId,
      destinationFacilityId: this.newWarehouseShipment.value.originFacility.value,
      destinationTelecomNumberId: this.newWarehouseShipment.value.destinationTelecomNumberId,
      partyIdTo: this.newWarehouseShipment.value.partyIdTo.partyId,
      partyIdFrom: this.newWarehouseShipment.value.partyIdFrom.partyId,
      additionalShippingCharge: this.newWarehouseShipment.value.additionalShippingCharge,
      addtlShippingChargeDesc: this.newWarehouseShipment.value.addtlShippingChargeDesc,
      eventDate: this.eventDate ,
      shipmentTypeId: this.newWarehouseShipment.value.shipmentTypeId.shipmentTypeId,
    };
    this.NewWarehouseShipmentService.createFacilityShipment(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate([this.url])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }

  cancel(){
    this.newWarehouseShipment.reset();
    this.router.navigate([this.url]);
  }

  back() {
    this.router.navigate([this.url])
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
