<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Employee Leaves</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>

            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategoryMain==1?'active':''" (click)="empleave()"><a>
                        Employee Leave</a></li>
                        <li [ngClass]="activeCategoryMain==2?'active':''" (click)="leaveapproval()"><a>
                        Leave Approval</a></li>


                    </ul>
                </div>
                <div class=" bg-white color-grey">

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" style="padding: 15px 15px;">

                                    <div class="w3-card-4  w-100">
                                        <div class="accordion classCard" id="myAccordion">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                        <span class="headingAccords">Search</span>
                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCenter">+ Add New Leave</button>

                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 74%!important; padding-top: 11px !important;
                                                            padding-bottom: 11px !important;">
                                                        </button>	
                                                    </div>
                
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                    <div class="card-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Party ID </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="partyIdArray" optionlabel="label" placeholder="Enter Employee ID " [(ngModel)]="advanceSearch.partyId" name="partyId" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Leave Type ID</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="leaveId" [(ngModel)]="advanceSearch.leaveTypeId" name="leaveTypeId" [ngModelOptions]="{standlone:true}" optionlabel="label" placeholder="Enter Leave Type ID">
                
                                                                        </p-dropdown>
                                                                    </div>
                
                
                
                                                                </div>
                                                            </div>
                
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Leave Reason Type</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="leaveReasonArray" [(ngModel)]="advanceSearch.leaveReasonTypeId" name="leaveReasonTypeId" [ngModelOptions]="{standlone:true}" optionlabel="label" placeholder="Enter Leave Reason Type">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-2"></div>
                
                
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">From Date</label>
                                                                    </div>
                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown [(ngModel)]="advanceSearch.fromDateFromSearchType" name="lastName" [ngModelOptions]="{standalone:true}" filter="true" name="fromDateFromSearchType" [options]="dateIdArray" optionlabel="label">
                
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="date" [(ngModel)]="advanceSearch.fromDateFrom" name="fromDateFrom" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                    </div>
                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown [(ngModel)]="advanceSearch.fromDateToSearchType" name="lastName" [ngModelOptions]="{standalone:true}" filter="true" name="fromDateToSearchType" [options]="dateIdArray" optionlabel="label">
                                                                        </p-dropdown>
                
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromDateTo" name="fromDateTo" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Through Date</label>
                                                                    </div>
                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown [(ngModel)]="advanceSearch.thruDateFromSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="thruDateFromSearchType" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                    <div class="col-lg-2 form-group">
                                                                        <input type="date" [(ngModel)]="advanceSearch.throughDateFrom" name="throughDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                    </div>
                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown [(ngModel)]="advanceSearch.thruDateToSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="thruDateToSearchType" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                        </p-dropdown>
                
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="date" class="form-control" [(ngModel)]="advanceSearch.throughDateTo" name="throughDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Approver Party</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="partyIdArray" optionlabel="label" placeholder="Enter Approver Party" [(ngModel)]="advanceSearch.approverPartyId" name="approverPartyId" [ngModelOptions]="{standlone:true}">
                
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1">Leave Status</label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <p-dropdown filter="true" [options]="leaveStatusReoortArray" [(ngModel)]="advanceSearch.leaveStatusID" name="leaveStatusID" [ngModelOptions]="{standlone:true}" (ngModelChange)="onChange(advanceSearch.leaveStatusID)" aria-describedby="emailHelp" optionlabel="label"
                                                                            placeholder="Enter Leave Status">
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                </div>
                                                            </div>
                
                
                
                
                
                                                        </div>
    
                                                        <div class="col-lg-12 col-12 main-submit-button" style="
                                                                margin-left: -26%;"><button _ngcontent-cta-c128="" (click)="advanceSearchContacts(false)" type="submit" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>

                                                        </div>
    
    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <!-- <div class="w3-card-4 classCard">
                                        <div class="port-header-contact create-lead font-medium mb-0">
                                            <div class="suppliers-wrapper">
                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">+ Add New Leave</button>
                                               
                                            </div>
                                        </div>
                                        <br>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="partyIdArray" optionlabel="label" placeholder="Enter Employee ID " [(ngModel)]="advanceSearch.partyId" name="partyId" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Leave Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="leaveId" [(ngModel)]="advanceSearch.leaveTypeId" name="leaveTypeId" [ngModelOptions]="{standlone:true}" optionlabel="label" placeholder="Enter Leave Type ID">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Leave Reason Type</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="leaveReasonArray" [(ngModel)]="advanceSearch.leaveReasonTypeId" name="leaveReasonTypeId" [ngModelOptions]="{standlone:true}" optionlabel="label" placeholder="Enter Leave Reason Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2"></div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2" style="display: none;">
                                                        <p-dropdown [(ngModel)]="advanceSearch.fromDateFromSearchType" name="lastName" [ngModelOptions]="{standalone:true}" filter="true" name="fromDateFromSearchType" [options]="dateIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" [(ngModel)]="advanceSearch.fromDateFrom" name="fromDateFrom" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>
                                                    <div class="col-lg-2" style="display: none;">
                                                        <p-dropdown [(ngModel)]="advanceSearch.fromDateToSearchType" name="lastName" [ngModelOptions]="{standalone:true}" filter="true" name="fromDateToSearchType" [options]="dateIdArray" optionlabel="label">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" [(ngModel)]="advanceSearch.fromDateTo" name="fromDateTo" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2" style="display: none;">
                                                        <p-dropdown [(ngModel)]="advanceSearch.thruDateFromSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="thruDateFromSearchType" [options]="dateIdArray" optionlabel="label" checked>

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-2 form-group">
                                                        <input type="date" [(ngModel)]="advanceSearch.throughDateFrom" name="throughDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>
                                                    <div class="col-lg-2" style="display: none;">
                                                        <p-dropdown [(ngModel)]="advanceSearch.thruDateToSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="thruDateToSearchType" [options]="dateIdArray" optionlabel="label" checked>

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" [(ngModel)]="advanceSearch.throughDateTo" name="throughDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Approver Party</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="partyIdArray" optionlabel="label" placeholder="Enter Approver Party" [(ngModel)]="advanceSearch.approverPartyId" name="approverPartyId" [ngModelOptions]="{standlone:true}">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Leave Status</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="leaveStatusReoortArray" [(ngModel)]="advanceSearch.leaveStatusID" name="leaveStatusID" [ngModelOptions]="{standlone:true}" (ngModelChange)="onChange(advanceSearch.leaveStatusID)" aria-describedby="emailHelp" optionlabel="label"
                                                            placeholder="Enter Leave Status">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>





                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button" style="
                                                margin-left: -26%;"><button _ngcontent-cta-c128="" (click)="advanceSearchContacts(false)" type="submit" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>

                                        </div>
                                    </div>  -->
                                    
                                    <br>
                                    <div class="w3-card-4 classCard">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Leave List
                                                </h4>

                                            </div>
                                        </header>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="employeeLeave" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Party ID
                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'155px'}" pSortableColumn="name">
                                                                        <div style="color: white;">Leave Type ID
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="category" [ngStyle]="{'width':'150px'}">
                                                                        <div style="color: white;">Leave Reason Type
                                                                            <p-sortIcon field="category"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                                                        <div style="color: white;">From Date
                                                                            <p-sortIcon field="quantity"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                        <div style="color: white;">Through Date
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> Approver Party
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> Leave Status
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> Action</div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span class="account-button" (click)="detailPage(product.partyId,product.leaveTypeId,product.fromDate,product.thruDate,product.approverPartyId,product.description,product.emplLeaveReasonTypeId,product.leaveStatus)">
                                                                        {{product.partyId}}</span>

                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'150px'}">{{product.leaveType}}</td>
                                                                    <td [ngStyle]="{'width':'155px'}">
                                                                        {{product.leaveReasonType}}</td>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        {{product.fromDate | date : 'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        {{product.thruDate | date : 'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        {{product.approverPartyId}}</td>
                                                                    <td [ngStyle]="{'width':'150px'}">{{product.status}}</td>

                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        <div>
                                                                            <!-- <svg class="hover"
                                                                        (click)="onUpdate(product.partyId,product.leaveTypeId,product.fromDate,product.thruDate,product.approverPartyId,product.description,product.emplLeaveReasonTypeId,product.leaveStatus)"
                                                                        xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 21.223 21.222">
                                                                        <g transform="translate(0 -0.004)">
                                                                            <g transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g transform="translate(4.548 0.004)">
                                                                                <g transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg> -->

                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add New Leaves</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                Add New Employee Leaves</a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="addLeaveService.add_leave_form" [formGroup]="addLeaveService.add_leave_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">  Employee Party ID <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="partyIdArray" formControlName="partyId" optionlabel="label" placeholder="Enter Party ID">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!addLeaveService.add_leave_form.controls.partyId.invalid && !!addLeaveService.add_leave_form.controls.partyId.touched">   Emp Party ID    is required!</small>


                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Leave Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="leaveId" formControlName="leaveTypeId" optionlabel="label" placeholder="Enter Leave Type ID">

                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Leave Reason Type<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="leaveReasonArray" formControlName="emplLeaveReasonTypeId" optionlabel="label" placeholder="Enter Leave Reason Type">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">From Date <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="From Date">
                                                        <small class="text-danger" *ngIf="!!addLeaveService.add_leave_form.controls.fromDate.invalid && !!addLeaveService.add_leave_form.controls.fromDate.touched"> From Date  is required!</small>




                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thruDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Through Date">

                                                        <small class="text-danger" *ngIf="!!addLeaveService.add_leave_form.controls.thruDate.invalid && !!addLeaveService.add_leave_form.controls.thruDate.touched"> Through Date  is required!</small>


                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Approver Party <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="partyIdArray" optionlabel="label" placeholder="Enter Approver Party" formControlName="approverPartyId">

                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!addLeaveService.add_leave_form.controls.approverPartyId.invalid && !!addLeaveService.add_leave_form.controls.approverPartyId.touched"> Approver PartyId   is required!</small>




                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter description">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes" *ngIf="leave">
                                                        <label for="exampleInputEmail1">Leave</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="leave">
                                                        <p-dropdown filter="true" [options]="leaveStatusReoortArray" formControlName="leaveStatus" optionlabel="label" placeholder="Enter Leave">
                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="main-submit-button" style="
                                        margin-right: 12%;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button>
                                            <span *ngIf="show">
                                            <button type="submit" *ngIf="!leave" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="leave" (click)="onUpdateApproval()" class="btn btn-secondary submit-btn">Update</button>
                                        </span>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>