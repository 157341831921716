import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AccountsService } from '../../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from 'src/app/header/header.component';
import { HeaderService } from 'src/app/header/header.service';
import { CreateEventServices } from '../create-events/create-event.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, DOCUMENT } from '@angular/common';
import Swal from 'sweetalert2'
import { FormGroup } from '@angular/forms';
import { CreateEventFormService } from '../create-events/create-event-service';
import { Location } from '@angular/common';


declare var $: any;
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  getPurposeList: any;
  activeCategorys = 1;
  purposeArray: any = [];
  updateEventId: any;
  show = false;
  resData: any;
  partygroup: any;
  partygroupArray: any = [];
  priority: any;
  preSelectedPriority;
  workEffortTypeId: any;
  products3: any;
  total=0;
  createUser: FormGroup;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  eventData: any;
  assignedByMeData: any;
  opportunityIdArray: any = [];
  
  public size = {
    pagesize: this.pageSize,
    pageNo: this.pageNo
  };
  assignedToMeData: any;
  getCurrencyList: any;
  currencyArray: any = [];
  priorityArray = [
    {
      label: '1(High)',
      value: '1'
    },
    {
      label: '2',
      value: '2'
    },
    {
      label: '3',
      value: '3'
    },
    {
      label: '4',
      value: '4'
    },
    {
      label: '5',
      value: '5'
    },
    {
      label: '6',
      value: '6'
    },
    {
      label: '7',
      value: '7'
    },
    {
      label: '8',
      value: '8'
    },
    {
      label: '9(Low)',
      value: '9'
    },

  ];

  req: {
    assignedByPartyId: string,
    assignedToPartyId: string,
    description: string,
    descriptionSearchType: "Contains",
    estimatedCompletionDateFrom: string,
    estimatedCompletionDateTo: string,
    estimatedStartDateFrom: string,
    estimatedStartDateTo: string,
    priority: string,
    purposeId: string,
    workEffortId: string,
    workEffortIdSearchType: "Contains",
    workEffortName: string,
    workEffortNameSearchType: "Contains"
  };
  activeCategoies1=1;
  activeCategories=4;
  activeCategory: number;
  constructor(public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly toastr: ToastrService,
    @Inject(DOCUMENT) readonly document: Document,
    readonly router: Router,
    readonly _Router: Router,
    private _location: Location,

  ) {

    this.req = {
      assignedByPartyId: "",
      assignedToPartyId: "",
      description: null,
      descriptionSearchType: "Contains",
      estimatedCompletionDateFrom: "",
      estimatedCompletionDateTo: "",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      priority: "",
      purposeId: "",
      workEffortId: "",
      workEffortIdSearchType: "Contains",
      workEffortName: "",
      workEffortNameSearchType: "Contains"
    }
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

   const patchData = this.createEventFormService.create_event_form;
      setTimeout(() => {
        patchData.patchValue({
          partyId:"admin"
        })
      
      }, 1000);

    this.assignedByMe();
   
    this.assignedToMe();
    this.getPurpose();
    this.getCurrency();
    this.getPurpose();
    this.getEventPartyId();
    this.activatedRoute.queryParams.subscribe(params => {
      this.updateEventId = params['updateEventId'];
    });
    
    if (this.updateEventId) {
      this.show = true;
    }
    else {
      this.show = false;
    }
    
    this.createEventFormService.create_event_form = null;
    this.createEventFormService.createEventForm(this.createEventFormService.create_event_form);
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });
    this.eventDetail();

  
    // this.getEventList();
    // this.headerService.catalogLink.next(true);
    this.products3 = this.accountsService.customerDetails();
    $('.right-arrow').click(() => {
      $(this).toggleClass('rotate-up');
    });
    this.opportunityIdArray = [
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Begins With',
        text: 'Begins With'
      },
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      }
    ];

  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  toForecast(){
    
    this._Router.navigate(['/crm/sales-forecast']);

  }
  toCases()
  {
    this._Router.navigate(['/crm/Cases']);
  }
  toEmails(){
    
    this._Router.navigate(['/communication-overview/Dashboard']);

  } toCalendar(){
    
    this._Router.navigate(['crm/sales-calendar']);

  }
  toNotes(){
    this._Router.navigate(['/crm/sales-notes']);
  }
  toDocuments(){
    
    this._Router.navigate(['crm/sales-documents']);

  }
  toLead()
  {
    this._Router.navigate(['/crm/leads']);

  }
  toTasks(){
    this._Router.navigate(['/crm/Tasks']);
  }
  toQuotes()
  {
    this.router.navigate(['/order/quotations']);

  }
  toSalesOrder()
  {
    this.router.navigate(['/order/create-sales-order']);

  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
 
  onPurposeChange(event) {
    this.req.purposeId = event.value;
  }
  onPriorityChange(event) {
    this.req.priority = event.value;
  }
 
  resetFilter(){
    this.spinner.show();
    this.req = {
      assignedByPartyId: "",
      assignedToPartyId: "",
      description: null,
      descriptionSearchType: "Contains",
      estimatedCompletionDateFrom: "",
      estimatedCompletionDateTo: "",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      priority: "",
      purposeId: "",
      workEffortId: "",
      workEffortIdSearchType: "Contains",
      workEffortName: "",
      workEffortNameSearchType: "Contains"
    }
    this.assignedByMe();
   
    this.assignedToMe();
    this.spinner.hide();
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  onChange() {
    this.show = true;
  }
  onFirstChange() {
    this.show = false;
  }

  toAccount()
  {
    this.router.navigate(['/crm/accounts']);
  }
  toContact()
  {
    this.router.navigate(['/crm/my-contacts']);

  }
  toOpportunity()
  {
    this.router.navigate(['/crm/opportunities']);

  }
  toActivities()
  {
    this.router.navigate(['/crm/events']);

  }

changeactiveCategoryAction(tab: number)
{
  this.activeCategories = tab;

}

changeactiveCategoryAction1(tab: number)
{
  this.activeCategoies1 = tab;

}

 getCurrency() {
    this.spinner.show();
    this.createEventServices.getCurrency().subscribe(res => {
      this.getCurrencyList = res.data.currencyList;
      this.spinner.hide();
      this.getCurrencyList.forEach(currencyList => {
        this.currencyArray.push({
          label: currencyList.description + "-" + currencyList.uomId,
          value: currencyList.uomId


        })
      })
    })
   
  }

  getPurpose() {
    this.spinner.show();
    this.createEventServices.getEventPurpose().subscribe(res => {
      this.getPurposeList = res.data;
      this.spinner.hide();
      for (const value of this.getPurposeList.eventPurposeList) {
        this.purposeArray.push({
          label: value.description,
          value: value.workEffortPurposeTypeId
        })
      }
    })
    
  }
  assignedByMe() {
    this.spinner.show();
    this.createEventServices.assignedByMe(this.req).subscribe(res => {
    
      this.assignedByMeData = res.data
      this.spinner.hide();
    })
  
  }
  createEvents() {
    this.router.navigateByUrl('/create-events');
  }
  eventDetails(id) {
    this.router.navigate(['/crm/events/details-event'], { queryParams: { eventId: id } });
    // details-event
  }



  updateEvent(id) {
    this.router.navigate(['/crm/events/create-events'], { queryParams: { updateEventId: id } });
  }

  

  onDelete(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.createEventServices.deleteEvent(id).subscribe(res => {
      
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})}

  


  //................................assigned by......................................................

  complete(id) {
    this.spinner.show();
    this.createEventServices.completeEvent(id).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data);
        this.document.location.reload();
        this.spinner.hide();
      }
    })
   
  }
  assignedToMe() {
    this.spinner.show();
    this.createEventServices.assignedToMe(this.req).subscribe(res => {
      this.spinner.hide();
      this.assignedToMeData = res.data;
    })
  
  }

  completeEvent(id) {
    this.spinner.show();
    this.createEventServices.completeEvent(id).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data);
        this.document.location.reload();
      }
    }, (err) => {
      this.spinner.hide();
    })
    
  }

  updateEventBy(id) {
    this.router.navigate(['/crm/events/create-events'], { queryParams: { updateEventId: id } })
  }
  
  

  getEventPartyId() {
    this.spinner.show();
    this.createEventServices.getPersonAndPartyGroup().subscribe(res => {
      this.partygroup = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partygroup) {
        this.partygroupArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  eventDetail() {
    this.spinner.show();
    this.createEventServices.eventDetail(this.updateEventId).subscribe(res => {
   
     this.preSelectedPriority=res.data[0].priority;
     this.workEffortTypeId=res.data[0].workEffortTypeId
     
      const patchData = this.createEventFormService.create_event_form;
      this.spinner.show();
      setTimeout(() => {
        patchData.patchValue({
          partyId: res.data[0].toPartyId,
          workEffortName: res.data[0].workEffortName,
        
          description: res.data[0].description,
          workEffortPurposeTypeId: res.data[0].workEffortPurposeTypeId,
          estimatedStartDate: res.data[0].estimatedStartDate ? new Date(res.data[0].estimatedStartDate) : '',
          estimatedCompletionDate: res.data[0].estimatedCompletionDate ? new Date(res.data[0].estimatedCompletionDate) : ''
  
        })
      
      }, 3000);
      this.spinner.hide();
      
    })
   
  }

  detailedPage(workEffortId) {
    this._Router.navigate(["/psa/work-effort/task-list-view/summary"],{ queryParams: { workEffortId: workEffortId} })
    }  

  onSubmit() {
    this.spinner.show();
    const formData = { ...this.createEventFormService.create_event_form.value };
    const estimatedCompletionDate = this.datePipe.transform(formData.estimatedCompletionDate, "yyyy-MM-dd HH:mm:ss");
    const estimatedStartDate = this.datePipe.transform(formData.estimatedStartDate, "yyyy-MM-dd HH:mm:ss");
    const form = {
      description: formData.description,
      estimatedCompletionDate: estimatedCompletionDate,
      estimatedStartDate: estimatedStartDate,
      partyId: formData.partyId,
      priority: formData.priority,
      workEffortName: formData.workEffortName,
      workEffortPurposeTypeId: formData.workEffortPurposeTypeId
      
    }
    this.createEventServices.createEvent(form).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.assignedByMe();
        this.assignedToMe();

      }
    }, (err) => {
 
      if (err.error.success == false) {
        if (err.error.errors) {
          this.spinner.hide();
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }
        }
        else if (err.error.success == false) {
          this.spinner.hide();
          this.toastr.error(err.error.debugMessage);
        }
      }
    })
   
  }
  previousEvent() {
    this.router.navigate(['/crm/events'])
  }
  onUpdate() {
    this.spinner.show();
    const updateForm = { ...this.createEventFormService.create_event_form.value };
    const estimatedCompletionDate = this.datePipe.transform(updateForm.estimatedCompletionDate, "yyyy-MM-dd HH:mm:ss");
    const estimatedStartDate = this.datePipe.transform(updateForm.estimatedStartDate, "yyyy-MM-dd HH:mm:ss");
    const updatedform = {
      "communicationEventId": "",
      "currentStatusId": "CAL_ACCEPTED",
      "custRequestId": "",
      "description": updateForm.description,
      "estimatedCompletionDate": estimatedCompletionDate,
      "estimatedStartDate": estimatedStartDate,
      "fromDate": "",
      "partyId": updateForm.partyId,
      "priority":updateForm.priority,
      "quickAssignPartyId": "",
      "requirementId": "",
      "roleTypeId": "",
      "statusId": "",
      "thruDate": "",
      "webSiteId": "",
      "workEffortName": updateForm.workEffortName,
      "workEffortPurposeTypeId": updateForm.workEffortPurposeTypeId,
      "workEffortTypeId":this.workEffortTypeId

  
    }
    this.createEventServices.updateEvent(updatedform, this.updateEventId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data);
        this.spinner.hide();
        this.router.navigate(['/crm/events']);
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.debugMessage);
    })
    
  }

  openTasks() {
    this._Router.navigate(["/crm/Tasks"])
  }
  openEvents() {
    this._Router.navigate(["/crm/events"])
  }


  ngOnDestroy(): void {
    this.spinner.hide();
      }
 


}
