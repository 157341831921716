
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { DatePipe, Location } from '@angular/common';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { FinancialService } from 'src/app/services/financial.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-add-other-party-relationship',
  templateUrl: './add-other-party-relationship.component.html',
  styleUrls: ['./add-other-party-relationship.component.css']
})
export class AddOtherPartyRelationshipComponent implements OnInit {
  newRelationType:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  party: any;
  identification: any;
  identificationArray: any[]=[];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  ParentTypeIdParty: any;
  ParentTypeIdPartyArray: any[]=[];
  securtiyList: any;
  groupIdIdArray: any[]=[];
  statusArray=[{
    "label":"Created",
    "value":"PARTYREL_CREATED"
  },
  {
    "label":"Expired",
    "value":"PARTYREL_EXPIRED"
  }]
  personId: any;
  partyIdArray: any[]=[];
  roleList: any;
  roleListArray: any[]=[];
  actualfromdate: string;
  actualthroughdate: string;
  status: string;
  PartyInvitationStatusList: any;
  PartyInvitationStatusListArray: any[]=[];
  fromDate: any;
  thruDate: any;
  comment: any;
  partyIdFrom: any;
  isA: any;
  partyRelationshipTypeId: any;
  roleTypeIdFrom: any;
  roleTypeIdTo: any;
  securityGroupId: any;
  statusId: any;
  editgetEstimateFrom: string;
  egetEstimateTo: string;
  @ViewChild('closebutton') closebutton;
	
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService, readonly addSkillService: AddSkillService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    public bsModalRef: BsModalRef) { 
      this.contentIds = [];
      this.newRelationType = this._FormBuilder.group({
        role: [''],
        status: [''],
        fromDate:[''],
        throughDate:[''],
        securityId:[''],
        comment:[''],
        fromParty:[''],
        isA:[''],
        inRoleof:['']
       
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.show = false;
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.fromDate = params["fromDate"];
      this.thruDate = params["thruDate"];
      this.comment = params["comment"];
      this.partyIdFrom = params["partyIdFrom"];
      this.isA = params["isA"];
      this.partyRelationshipTypeId = params["partyRelationshipTypeId"];
      this.roleTypeIdFrom = params["roleTypeIdFrom"];
      this.roleTypeIdTo = params["roleTypeIdTo"];
      this.securityGroupId = params["securityGroupId"];
      this.statusId = params["statusId"];
     
    });

    this.getContentId();
    this.getRoleAllType();
    this.getParentTypeIdParty();
    this.getUserDetails();
    this.getPartyId();
    this.getPartyRole();
    this.getPartyInvitationStatusList();
  if(this.roleTypeIdFrom)
{
  this.show=true
  setTimeout(() => {
    const fromdate= this.fromDate
        this.editgetEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=this.thruDate
        this.egetEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
    const formValue = this.newRelationType;
  formValue.patchValue({
    role: this.roleTypeIdFrom,
    status: this.statusId,
    fromDate:this.editgetEstimateFrom,
    throughDate:this.egetEstimateTo,
    securityId:this.securityGroupId,
    comment:this.comment,
    fromParty:this.partyIdFrom,
    isA:this.isA,
    inRoleof: this.roleTypeIdTo
  })
  }, 3000); 
}   
else{
  this.show=false
}
   
 
  }
  getPartyInvitationStatusList(){
    this.spinner.show();
    this.status='PARTY_REL_STATUS'
      this.accountsService.getPartyInvitationStatusList(this.status).subscribe((res:any)=> {
        this.PartyInvitationStatusList = res.data;
        this.spinner.hide();
        for(const value of this.PartyInvitationStatusList){
          this.PartyInvitationStatusListArray.push({
            label:value.data.description,
            value:value.data.statusId
          })
        }
      
      })
     
     }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  getPartyRole(){
    this.accountsService.getPartyRole(this.finSize,this.party).subscribe((res:any)=> {
       this.roleList = res.data;
       this.spinner.hide();
       for(const value of this.roleList){
       this.roleListArray.push({
         label:value.description,
         value:value.roleTypeId
       })
     } 
    })
   }
  getUserDetails(){
    this.spinner.show();
    const req={
  "description": "",
  "descriptionSearchType": "Contains",
  "groupId": "",
  "groupIdSearchType": "Contains"
     
    }
    this.accountsService.getSecurityGroupList(this.finSize,req).subscribe((res:any)=> {
      this.securtiyList = res.data;
      this.spinner.hide();
      for(const value of this.securtiyList){
      this.groupIdIdArray.push({
        label:value.groupName,
        value:value.groupId
      })
    } 
  
    })   
   }
  getParentTypeIdParty(){
    this.spinner.show();
  
      this.accountsService.getParentTypeIdParty().subscribe((res:any)=> {
        this.ParentTypeIdParty = res.data;
        this.spinner.hide();
        for(const value of this.ParentTypeIdParty){
          this.ParentTypeIdPartyArray.push({
            label:value.parentTypeIdData.partyRelationshipName,
            value:value.parentTypeIdData.partyRelationshipTypeId
          })
        }
      
      })
     
     }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
  getContentId(): void {
    this.spinner.show();
    this._CategoryService.getCatalogIdList()
        .then(data => {
            this.spinner.hide();
            if (data.success) {
                this.contentIds = data.data.map(content => {
                    return {
                        label: content.contentId,
                        value: content.contentId
                    };
                });
            }
        })
       
}
  onSubmit() {
  
    this.spinner.show();
    const fromActualdate=this.newRelationType.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.newRelationType.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "comments": this.newRelationType.value.comment,
      "fromDate":  this.actualfromdate,
      "partyId": this.party,
      "partyIdFrom":  this.newRelationType.value.fromParty,
      "partyIdTo":  this.party,
      "partyRelationshipTypeId":  this.newRelationType.value.isA,
      "roleTypeIdFrom": this.newRelationType.value.role,
      "roleTypeIdTo":  this.newRelationType.value.inRoleof,
      "securityGroupId":  this.newRelationType.value.securityId,
      "statusId":  this.newRelationType.value.status,
      "thruDate":  this.actualthroughdate

     
    }
    this.accountsService.createOtherRelationship(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.closebutton.nativeElement.click();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onUpdate() {
    this.spinner.show();
 
    const throughActualdate=this.newRelationType.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "comments": this.newRelationType.value.comment,
      "fromDate":  this.fromDate,
      "partyId": this.party,
      "partyIdFrom":  this.newRelationType.value.fromParty,
      "partyIdTo":  this.party,
      "partyRelationshipTypeId":  this.newRelationType.value.isA,
      "roleTypeIdFrom": this.newRelationType.value.role,
      "roleTypeIdTo":  this.newRelationType.value.inRoleof,
      "securityGroupId":  this.newRelationType.value.securityId==null?'':this.newRelationType.value.securityId,
      "statusId":  this.newRelationType.value.status==null?'':this.newRelationType.value.status,
      "thruDate":  this.actualthroughdate==null?'':this.actualthroughdate

     
    }
    this.accountsService.updateOtherRelationship(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.closebutton.nativeElement.click();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
 
  
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }}