
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CreateTimeSheetModel } from './create-timesheet-model'

@Injectable({
    providedIn: 'root'
  })


export class CreateTimesheetFormService {
    create_timesheet_form : FormGroup;
  
  
  constructor(public _formBuilder: FormBuilder,
    public createTimeSheetModel: CreateTimeSheetModel
    ) { } 

  createTimesheetForm(data:any): void {
    if (!this.create_timesheet_form) {
        this.create_timesheet_form = this._formBuilder.group(this.createTimeSheetModel.mapDataToModel(data));
       
    }

  }

}

