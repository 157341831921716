import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from "@angular/forms";

@Component({
  selector: 'app-interbal-job-posting-app',
  templateUrl: './interbal-job-posting-app.component.html',
  styleUrls: ['./interbal-job-posting-app.component.css']
})
export class InterbalJobPostingAppComponent implements OnInit {
  jobPosting: FormGroup;

  constructor() { }

  ngOnInit(): void {
    this.jobPosting = new FormGroup({
      applicationDate	: new FormControl('', [
        
      ]),
      approverParty	: new FormControl('', [
        Validators.required
      ]),
      jobRequisitionID:	new FormControl('', [
        Validators.required
      ]),

    });
  }

}
