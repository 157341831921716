<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Shopping List
 
                </span>
                <span class="color-black pl-1" > >Add Shopping List
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="header-tabs">

                        <h4 class=" common-styling hCard" style="margin-left: 7px;">
                         Update Shopping List
                        </h4>
                        <span  (click)="order()" class="edit-buttons hCreate"
                       style="margin-left: 64%;" >New Order|</span>
                        <span class="edit-buttons hCreate"
                          (click)="createPartyCustomRequest()">Create Request|</span>
                          <span style="margin-right: 2%;" class="edit-buttons hCreate"
                          (click)="createQuoteFromShoppingList()">Create Quotes</span>
                      </div>
              
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createContact">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                  <div class="col-lg-12" >
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightForm">
                                          <label for="exampleInputEmail1">
                                            List Name</label>
                                        </div>
                                        <div class="col-lg-2">
                                          <input type="text"  formControlName="ListName"  class="form-control" id="exampleInputEmail1"
                                          aria-describedby="emailHelp" placeholder="Enter List Name" >
                                      
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                          <label for="exampleInputEmail1">
                                            Description</label>
                                        </div>
                                        <div class="col-lg-2">
                                          <input type="text"  formControlName="Description"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Description" >
                                        </div>
                                       
                                    </div>
                                  </div>
                                  <div class="col-lg-12" >
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightForm">
                                          <label for="exampleInputEmail1">
                                            List Type</label>
                                        </div>
                                        <div class="col-lg-2">
                                          <p-dropdown [options]="shoppingListTypeIdDataArray" filter="true"
                                          formControlName="ListType"
                                         placeholder="Enter List Type"
                                         name="Parent Type ID">
                                     </p-dropdown>
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                          <label for="exampleInputEmail1">
                                            Public?</label>
                                        </div>
                                        <div class="col-lg-2">
                                          <p-dropdown [options]="public" filter="true"
                                                 formControlName="Public"
                                                placeholder="Enter Public?"
                                                name="Parent Type ID">
                                            </p-dropdown>
                                        </div>
                                       
                                    </div>
                                  </div>
                                  <div class="col-lg-12" >
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightForm">
                                          <label for="exampleInputEmail1">
                                            Parent List</label>
                                        </div>
                                        <div class="col-lg-2">
                                          <p-dropdown [options]="ShoppingListArray" filter="true"
                                                 formControlName="ParentList"
                                                placeholder="Enter Parent List"
                                                name="Parent Type ID">
                                            </p-dropdown>
                                        </div>
                                       
                                       
                                    </div>
                                  </div>
                                  
                                 
                                  
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 59%;">
                               
                              
                                <button type="submit" (click)="onUpdate();"  class="btn btn-secondary submit-btn">Update</button>
                                
                                
                                
                              
                            </div>
                            <br>
                            <div class="w3-card-4 classCard">
                                <div class="header-tabs">
  
                                  <h4 class=" common-styling hCard" style="margin-left: 7px;">
                                    List Items - New Shopping List
                                  </h4>
                                  <span style="margin-right: 2%;" class="edit-buttons hCreate"
                                    (click)="quickAddShoping()">Quick Add To Shopping List</span>
                                </div>
                              <div class="form-group" style="width: 100%!important">
  
                                <div class="card own-account-table" style="padding: 15px;">
                                  <p-table id="table" [value]="ItemToShoppingList" [paginator]="true" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                    [scrollable]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                          <div class="checkbox-align" style="color: white;">
                                           
                                            <p-checkbox _ngcontent-egj-c329=""
                                            styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div
                                                class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined"
                                                        value="undefined"></div>
                                                <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span
                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                </div>
                                            </div>
                                            <!---->
                                        </p-checkbox>
                                            
                                            Product<p-sortIcon field="code">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                          <div style="color: white;">Quantity <p-sortIcon field="name">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                          <div style="color: white;">	Quantity Purchased<p-sortIcon field="category"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                          <div style="color: white;">	Price
                                            <p-sortIcon field="quantity">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                          <div style="color: white;">	Total<p-sortIcon field="quantity">
                                            </p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                     
                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                          <div style="color: white;"> Action</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                      <tr>
                                        <td [ngStyle]="{'width':'150px'}" (click)="contactsDetail()">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                            <span  class="account-button"  
                                             >
                                              {{product.shoppingListItem.productId}}  {{product.product.productName}}</span>
  
                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'150px'}">{{product.shoppingListItem.quantity}}
                                        </td>
                                        <td [ngStyle]="{'width':'150px'}">{{product.shoppingListItem.quantityPurchased}}</td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          ${{product.unitPrice}}
                                         </td>
                                        <td [ngStyle]="{'width':'150px'}">
                                          ${{product.totalPrice}}
                                         </td>
                                        
                                        <td [ngStyle]="{'width':'150px'}">
                                          <div>
  
                                            <svg class="hover"   (click)="updatequickAddShoping(product.shoppingListItem.productId,product.shoppingListItem.quantity,product.shoppingListItem.shoppingListItemSeqId,product.shoppingListItem.quantityPurchased)"
                                              xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                              viewBox="0 0 21.223 21.222">
                                              <g transform="translate(0 -0.004)">
                                                <g transform="translate(0 1.52)">
                                                  <path
                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                    transform="translate(0 -34.137)" />
                                                </g>
                                                <g transform="translate(4.548 0.004)">
                                                  <g transform="translate(0 0)">
                                                    <path
                                                      d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                      transform="translate(-102.409 -0.004)" />
                                                  </g>
                                                </g>
                                              </g>
                                            </svg>
                                            <svg  (click)="removeShoppingListItem(product.shoppingListItem.shoppingListItemSeqId)"
                                              xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16"
                                              viewBox="0 0 26 26">
                                              <defs>
                                                <style>
                                                  .a {
                                                    fill: #f44336;
                                                  }
  
                                                  .b {
                                                    fill: #fafafa;
                                                    margin-left: 20px;
                                                  }
                                                </style>
                                              </defs>
                                              <path class="a fill-color"
                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                              <path class="b"
                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                transform="translate(-147.576 -147.576)" />
                                            </svg>
                                          </div>
                                        </td>
  
                                      </tr>
                                    </ng-template>
                                  </p-table>
  
                                  <p class="paginate_data">
                                    View per page </p>
                                </div>
                              </div>
                              </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>




