import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreateQuetoModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
      currencyId:(data && data.currencyId) ? data.currencyId : null,
      description:(data && data.description) ? data.description : null,
      partyId:(data && data.partyId) ? data.partyId : null,
      productStoreId:(data && data.productStoreId) ? data.productStoreId : null,
      quoteId:(data && data.quoteId) ? data.quoteId : null,
      quoteIssueDate:(data && data.quoteIssueDate) ? data.quoteIssueDate : null,
      quoteName:(data && data.quoteName) ? data.quoteName : null,
      quoteTypeId:(data && data.quoteTypeId) ? data.quoteTypeId : null,
      salesId:(data && data.salesId) ? data.salesId : null,  
      statusId:(data && data.statusId) ? data.statusId : null,
      validFrom:(data && data.validFrom) ? data.validFrom : null,
      validThrough:(data && data.validThrough) ? data.validThrough : null,
      Item:(data && data.Item) ? data.Item : null,
      Product:(data && data.Product) ? data.Product : null,
      Price:(data && data.Price) ? data.Price : null,
       
     
    });
   
   
    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   return ({
    currencyId: new FormControl(data.currencyId,[Validators.required]),
    description: new FormControl(data.description),
    partyId: new FormControl(data.partyId),
    productStoreId: new FormControl(data.productStoreId,[Validators.required]),
    quoteId: new FormControl(data.quoteId),
    quoteIssueDate: new FormControl(data.quoteIssueDate),
    quoteName: new FormControl(data.quoteName),
    quoteTypeId: new FormControl(data.quoteTypeId),
    salesId: new FormControl(data.salesId,[Validators.required]),
    statusId: new FormControl(data.statusId,[Validators.required]),
    validFrom: new FormControl(data.validFrom),
    validThrough: new FormControl(data.validThrough),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
