import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';

@Component({
  selector: 'app-organization-gl-setting',
  templateUrl: './organization-gl-setting.component.html',
  styleUrls: ['./organization-gl-setting.component.css']
})
export class OrganizationGlSettingComponent implements OnInit {
  activeCategory=2;
  activeCategoryMain=1;
  products3: any;
  createOrganization:FormGroup;
  @ViewChild('closebutton') closebutton;
  orgId: any;
  orgIdArray: any[]=[];
  show: boolean;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  companyList:any[]=[]
  getMainOrg: any;
  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly _ToastrService: ToastrService,
    readonly accountsService: AccountsService,
  ) {
    this.createOrganization = this._FormBuilder.group({
      organizationPartyId: [''],
      
    });
    this.companyList=[{
      'company':"Your Company Name Here "
    }]
   }

  ngOnInit(): void {
    this.getGlAccountListData();
    this.getGlAccountInitialData();
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getOrganizationDropdown().subscribe((res: any) => {
      this.spinner.hide();
      this.orgId = res.data.partyRoleAndPartyDetail;
     
      for (const value of this.orgId) {
        this.orgIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
     
    })
   
  }
 
  onSubmit() {
    this.show=true;
    this.orgId=this.createOrganization.value.organizationPartyId;
    if(this.orgId == ""){
      this._ToastrService.error("Organization Party Id is blank");
      this.show=false;
    }
    else{
      this.closebutton.nativeElement.click();
      this.show=false;
      this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId: this.orgId,show:this.show } });

    }
  

  }
  AccountingCompany(){
    this._Router.navigate(['/financial/create-organization-gl-setting']);
  }
  AccountingInfo(){
    this._Router.navigate(['/financial/organization-gl-setting']);
  }
  glOrganizationSummary(id){
    this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId: id } });
  }
  glOrganizationAccountingSummary(id){
    this._Router.navigate(['/financial/detail-accounting-summary'], { queryParams: { partyId: id } });
  }
  getGlAccountListData() {
    this.spinner.show();
    this.accountsService.getOrganizationMain().subscribe((res: any) => {
      this.spinner.hide();
      this.getMainOrg = res.data;
    })
   
  }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
}
