import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CreatePaymentServices } from 'src/app/accounting/payments/create-payments/create-payment.service';
import { CreatePaymentFormService } from "src/app/accounting/payments/create-payments/create-payment-service";
import { Router, ActivatedRoute } from '@angular/router';
import { MergeContactsService } from 'src/app/crm/contacts/merge-contacts/merge-contacts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PayablePaymentService } from 'src/app/accounting/accounting-ap/payables-payment/payables-payment.service';

@Component({
  selector: 'app-my-portal-create-payments',
  templateUrl: './my-portal-create-payments.component.html',
  styleUrls: ['./my-portal-create-payments.component.css']
})
export class MyPortalCreatePaymentsComponent implements OnInit {


  organizationPartyId = 'Company';
  toCompanyId = 'Company'
  fromPartyId = 'DemoCustomer'
  statusId = 'Not Paid'
  fromPartyIdByApi = '';
  finAccountTransIdByApi = '';
  toPartyIdByApi = '';

  allPaymentTypeList: any = [];
  allFinAccountIdList: any = [];
  allpaymentMethodId: any = [];
  allPartyIdList: any = [];
  currencyArray: any = [];
  allStatusList: any = []

  public paymentId: any;
  showUpdateBtn = false;
  showCreateBtn = false;
  payablePaymentForm: FormGroup;
  incomingPaymentForm: FormGroup;
  newPaymentId: any;
  PaymentTypeOutgoingList: any[] = [];
  GIAccounts: any[];
  paymentMethodId: any;
  outGoingPaymentForm: FormGroup;
  paymentMethodIdrray: any[] = [];
  PaymentDetailsById: any;

  constructor(
    public createPaymentFormService: CreatePaymentFormService,
    readonly createPaymentService: CreatePaymentServices,
    readonly _FormBuilder: FormBuilder,
    readonly _PayablePaymentService: PayablePaymentService,
    readonly mergeContactsService: MergeContactsService,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router
  ) {
    this.payablePaymentForm = this._FormBuilder.group({
      amount: ['', [Validators.required]],
      comments: [''],
      overrideGlAccountId: [''],
      partyIdFrom: ['', [Validators.required]],
      partyIdTo: [''],
      paymentMethodId: [''],
      paymentRefNum: [''],
      paymentTypeId: [''],
      actualCurrencyAmount: [''],
      actualCurrencyUomId: [''],
      currencyUomId: [''],
      effectiveDate: [''],
      finAccountTransId: [''],
      statusId: [''],
    });

    this.incomingPaymentForm = this._FormBuilder.group({
      amount: ['', [Validators.required]],
      comments: [''],
      overrideGlAccountId: [''],
      partyIdFrom: ['', [Validators.required]],
      partyIdTo: [''],
      paymentMethodId: [''],
      paymentRefNum: [''],
      paymentTypeId: [''],
      finAccountId: [''],
    });
  }
  public OrganizationPartyIdArray: any[] = [
    {
      value: "widget_div",
      label: "Widget Business Unit"
    },
    {
      value: "Company",
      label: "Your Company Name Here"
    },
  ]
  ngAfterViewInit(): void { }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.paymentId = params["paymentId"];
    });
    this.getPaymentType();
    this.getFromPartyId();
    this.getPaymentAccountId();
    this.getCurrency();
    this.getGIAccounts();
    this.getPaymentTypes();
    this.getPaymentTypeOutgoing();
    this.getPaymentType();
    this.getPaymentMethodId();

    this.spinner.hide();
  }
  getPaymentsById() {
    this.spinner.show();
    this.mergeContactsService.getPaymentDetailsById(this.newPaymentId).subscribe(res => {
      this.PaymentDetailsById = res.data[0];
      setTimeout(() => {
        const formValue = this.payablePaymentForm;
        formValue.patchValue({
          paymentTypeId: this.PaymentDetailsById.paymentTypeId,
          statusId: this.PaymentDetailsById.statusId,
          paymentMethodId: this.PaymentDetailsById.paymentMethodId,
          partyIdFrom: this.PaymentDetailsById.partyIdFrom,
          partyIdTo: this.PaymentDetailsById.partyIdTo,
          amount: this.PaymentDetailsById.amount,
          currencyUomId: this.PaymentDetailsById.currencyUomId,
          actualCurrencyAmount: this.PaymentDetailsById.actualCurrencyAmount,
          paymentRefNum: this.PaymentDetailsById.paymentRefNum,
          comments: this.PaymentDetailsById.comments,
          finAccountTransId: this.PaymentDetailsById.financialAccountTransaction,
          overrideGlAccountId: this.PaymentDetailsById.overrideGlAccountId,

        })

      }, 1000);

      this.spinner.hide();
    })
  }
  getPaymentMethodId() {
    this.spinner.show();
    this.createPaymentService.getPaymentMethodId().subscribe(res => {
      this.paymentMethodId = res.data;
      this.spinner.hide();
      for (const value of this.paymentMethodId) {
        this.paymentMethodIdrray.push({
          label: value.paymentMethodId,
          value: value.paymentMethodId
        })
      }
    })
  }
  createPaymentIncoming(): void {
    this.spinner.show();
    const requestData = {
      "amount": this.incomingPaymentForm.value.amount,
      "comments": this.incomingPaymentForm.value.comments,
      "paymentMethodId": this.incomingPaymentForm.value.paymentMethodId,
      "finAccountId": this.incomingPaymentForm.value.finAccountId,
      "overrideGlAccountId": this.incomingPaymentForm.value.overrideGlAccountId,
      "partyIdFrom": this.incomingPaymentForm.value.partyIdFrom,
      "partyIdTo": this.incomingPaymentForm.value.partyIdTo,
      "paymentRefNum": this.incomingPaymentForm.value.paymentRefNum,
      "paymentTypeId": this.incomingPaymentForm.value.paymentTypeId,
    }
    this.createPaymentService.createPaymentIncoming(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.incomingPaymentForm.reset();
        this.spinner.hide();
        this.newPaymentId = res.data.paymentId;
        this.getPaymentsById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  createOutgoingPayment(): void {
    this.spinner.show();
    if (this.payablePaymentForm.valid) {
      const {
        amount, comments,
        overrideGlAccountId, partyIdFrom,
        partyIdTo, paymentMethodId,
        paymentRefNum, paymentTypeId
      } = this.payablePaymentForm.value;
      this._PayablePaymentService.createOutgoingPayment({
        amount, comments,
        overrideGlAccountId, partyIdFrom,
        partyIdTo, paymentMethodId,
        paymentRefNum, paymentTypeId
      })
        .then(data => {
          if (data.success) {
            this.toastr.success('Created');
            this.spinner.hide();
            this.payablePaymentForm.reset();
          }
        });

      this.spinner.hide();
    }

  }
  getPaymentTypeOutgoing() {
    this.spinner.show();
    this.createPaymentService.getPaymentTypeOutgoing().subscribe(res => {
      if (res.success) {
        const PaymentTypeOutgoing = res.data;
        for (const value of PaymentTypeOutgoing) {
          this.PaymentTypeOutgoingList.push({
            label: value.description,
            value: value.paymentTypeId
          })
        }
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  getPaymentTypes() {
    this.spinner.show();
    this.createPaymentService.getPaymentType().subscribe(res => {
      if (res.success) {
        const allPaymentType = res.data.paymentType;
        const allStatusList = res.data.status;
        const finAccountIdList = res.data.finAccountId;
        for (const value of allPaymentType) {
          this.allPaymentTypeList.push({
            label: value.description,
            value: value.paymentTypeId
          })
        }

        for (const value of finAccountIdList) {
          this.allFinAccountIdList.push({
            label: value.finAccountName,
            value: value.finAccountId
          })
        }
        for (const value of allStatusList) {
          this.allStatusList.push({
            label: value.description,
            value: value.statusId
          })
        }
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  getGIAccounts(): void {
    this.spinner.show();
    this._PayablePaymentService.getGIAccountAndPaymentMethod()
      .then(data => {
        if (data) {
          this.GIAccounts = data.data.glAccounts.map(value => {
            return {
              label: value.glAccountId,
              value: value.glAccountId
            };
          });
        }
      });
    this.spinner.hide();
  }
  getPaymentType() {
    this.spinner.show();
    this.createPaymentService.getPaymentType().subscribe(res => {
      if (res.success) {
        const allPaymentType = res.data.paymentType;
        const finAccountIdList = res.data.finAccountId
        for (const value of allPaymentType) {
          this.allPaymentTypeList.push({
            label: value.description,
            value: value.paymentTypeId
          })
        }

        for (const value of finAccountIdList) {
          this.allFinAccountIdList.push({
            label: value.finAccountName,
            value: value.finAccountId
          })
        }

      }

    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }

  getFromPartyId() {
    this.spinner.show();
    this.createPaymentService.getFromPartyId().subscribe(res => {
      if (res.success) {
        const allPartyId = res.data.PersonsGroups;
        for (const value of allPartyId) {
          this.allPartyIdList.push({
            label: value.partyId,
            value: value.partyId
          })
        }
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }

  getPaymentAccountId() {
    this.spinner.show();
    this.createPaymentService.getPaymentAccoundId(this.organizationPartyId).subscribe(res => {
      if (res.success) {
        const paymentMethodId = res.data.paymentMethodId;
        for (const value of paymentMethodId) {
          this.allpaymentMethodId.push({
            label: value.paymentMethodId,
            value: value.paymentMethodId
          })
        }
      }
    }
    )
    this.spinner.hide();
  }

  getPaymentDetailsById() {
    this.spinner.show();
    this.createPaymentService.getFromPartyId().subscribe(res => {
      if (res.success) {
        const allPartyId = res.data.PersonsGroups;
        for (const value of allPartyId) {
          this.allPartyIdList.push({
            label: value.partyId,
            value: value.partyId
          })
        }
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }


  onSubmit() {
    this.spinner.show();
    const partyIdTo = this.organizationPartyId
    const formValues = { ...this.createPaymentFormService.create_payment_form.value, partyIdTo };

    this.createPaymentService.createPayment(formValues).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
      }
    }, (err) => {
      for (const value of err.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
    this.spinner.hide();
  }

  getPaymentDetailsByIdForUpdate() {
    this.spinner.show();
    this.createPaymentService.getPaymentDetailsById(this.paymentId).subscribe(res => {
      if (res.success) {

        this.fromPartyIdByApi = res.data[0].partyIdFrom;
        this.finAccountTransIdByApi = res.data[0].financialAccountTransaction;
        this.toPartyIdByApi = res.data[0].partyIdTo;

      }
    }
    )
    this.spinner.hide();
  }


  getCurrency() {
    this.spinner.show();
    this.createPaymentService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
    this.spinner.hide();
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }
}

