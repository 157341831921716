import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { ThesaurusComponent } from 'src/app/catalog-mgmt/thesaurus/thesaurus.component';

@Component({
  selector: 'app-timesheet-project',
  templateUrl: './timesheet-project.component.html',
  styleUrls: ['./timesheet-project.component.css']
})
export class TimesheetProjectComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 7;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  projectForm: FormGroup;
  timesheetForm: FormGroup;
  newrequestForm: FormGroup;
  PartyIdArray: any[]=[];
  PartyId: any;
  TimeSheetProjectList: any;
  advanceSearch: { TimesheetID: string; comments: string; Party: string; status: string; fromDate: string; toDate: string; };
  statusArray: { label: string; value: string; }[];
  requiredDate: string;
  Party: any;
  partIdDes: any;


  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
        readonly _FormBuilder: FormBuilder,
        private _location: Location,
        readonly toastr: ToastrService,
    readonly _Router: Router
  ) { 
     this.advanceSearch = {
      TimesheetID: "",
      comments: "",
      Party: "",
    status: "",
    fromDate: "",
    toDate: "",
  };
  this.statusArray = [

    {
      label: 'Approved',
      value: 'TIMESHEET_APPROVED'
    },
    {
      label: 'Completed',
      value: 'TIMESHEET_COMPLETED'
    },
    {
      label: 'In-Process',
      value: 'TIMESHEET_IN_PROCESS'
    },
  ];
  this.timesheetForm = this._FormBuilder.group({
    Party: [''],
    SelectDate: [''],
    

  });
}

  ngOnInit(): void {
    this.getPartyList();
    this.getFindTimeSheetProjectList();
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  MyStatusToComplete(statusId,timesheetId) {
    this.spinner.show();
    const sdate = this.timesheetForm.get('SelectDate').value;
    this.requiredDate = this.datePipe.transform(sdate, "yyyy-MM-dd hh:mm:ss");
   
    const formData = {
      "partyId":this.timesheetForm.value.Party,
      "requiredDate":this.requiredDate
    }
    this.myContactsService.MyStatusToComplete(statusId,timesheetId).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
     //   this.getPaymentMethods();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }


  createWeeklyProTimesheet() {
    this.spinner.show();
    
    for (const r of this.PartyId) {
      this.partIdDes = r.description;
  }
    const sdate = this.timesheetForm.get('SelectDate').value;
    this.requiredDate = this.datePipe.transform(sdate, "yyyy-MM-dd hh:mm:ss");
   
    const formData = {
      "partyId":this.timesheetForm.value.Party,
      "requiredDate":this.requiredDate
    }
    this.myContactsService.createWeeklyProTimesheet(formData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this._Router.navigate(["/psa/project/detail-timesheet"], { queryParams:{ partyId: this.timesheetForm.value.Party , party: this.partIdDes} })
      
     //   this.getPaymentMethods();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
 

  getFindTimeSheetProjectList() {
    this.spinner.show();
    this.myContactsService.FindTimeSheetProjectList().subscribe(res => {
      this.TimeSheetProjectList = res.data;
      this.spinner.hide();
    })
  }

  FindTimeSheetProject() {
    this.spinner.show();
    if(this.advanceSearch.comments||this.advanceSearch.Party||this.advanceSearch.fromDate||
      this.advanceSearch.status||this.advanceSearch.TimesheetID
     ){
    const req = {
      "comments": this.advanceSearch.comments ? this.advanceSearch.comments : '',
      "statusId": this.advanceSearch.status ? this.advanceSearch.status : '',
      "partyId": this.advanceSearch.Party ? this.advanceSearch.Party : '',
      "fromDate": this.advanceSearch.fromDate ? this.advanceSearch.fromDate : '',
      "timesheetId": this.advanceSearch.TimesheetID ? this.advanceSearch.TimesheetID : '',
    }
    this.myContactsService.FindTimeSheetProject(req.comments, req.fromDate,
       req.partyId, req.statusId, req.timesheetId).subscribe((res: any) => {
      this.TimeSheetProjectList = res.data;
      this.spinner.hide();
    })}
    else{
      this.getFindTimeSheetProjectList();
    }
    this.spinner.hide();
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.TimesheetID = "";
    this.advanceSearch.Party = "";
    this.advanceSearch.status = "";
    this.advanceSearch.comments = "";
    this.advanceSearch.toDate = "";
    this.advanceSearch.fromDate = "";

    
    this.myContactsService.FindTimeSheetProjectList().subscribe((res: any) => {
      this.TimeSheetProjectList = res.data;
      this.spinner.hide();
    })
  }
  getPartyList() {
    this.spinner.show();
    this.myContactsService.getPartyList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }
  projectMain() {
    this._Router.navigate(["/psa/project/project"])
  }
  myTasks() {
    this._Router.navigate(["/psa/project/my-tasks"])
  }
  myTime() {
    this._Router.navigate(["/psa/project/my-time"])
  }
  projects() {
    this._Router.navigate(["/psa/project/projects"])
  }
  tasks() {
    this._Router.navigate(["/psa/project/tasks-project"])
  }
  resources() {
    this._Router.navigate(["/psa/project/resources"])
  }
  timesheet() {
    this._Router.navigate(["/psa/project/timesheet-project"])
  }
  
  skillType() {
    this._Router.navigate(["/psa/project/skill-type"])
  }
  requestList() {
    this._Router.navigate(["/psa/project/request-list"])
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}
