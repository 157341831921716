import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-mime-type-template',
  templateUrl: './mime-type-template.component.html',
  styleUrls: ['./mime-type-template.component.css']
})
export class MimeTypeTemplateComponent implements OnInit {
  activeCategory=8;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  addMimeTypeTemplateForm: FormGroup;
  show: boolean;
  MimeTypeHtmlTemplateList: any;
  CalatalogMimeTypeId: any;
  CalatalogMimeTypeIdArray: any[]=[];
  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly myContactsService: MyContactsService,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly toastr: ToastrService,
    private _location: Location,
  )
  {
    this.addMimeTypeTemplateForm=this._FormBuilder.group({
      templateLocation:"",
      mimeTypeID:"",
    })
  }

  
  ngOnInit(): void {
    this.MimeTypeHtmlTemplate();
    this.getCalatalogMimeTypeId();
  
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  
  getCalatalogMimeTypeId() {
    this.spinner.show();
    this.accountsService.getCalatalogMimeTypeId().subscribe(res => {
      this.CalatalogMimeTypeId = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.CalatalogMimeTypeId) {
        this.CalatalogMimeTypeIdArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }
  reset(){
    this.addMimeTypeTemplateForm.reset();
    this.show = false;
  }
  update(product) {
    this.show = true;
    setTimeout(() => {
      const form = this.addMimeTypeTemplateForm;
      form.patchValue({
        templateLocation:product.templateLocation,
        mimeTypeID:product.mimeTypeId,
      })
    }, 2000);
  }
  MimeTypeHtmlTemplate(){
    this.spinner.show();
    this.myContactsService.MimeTypeHtmlTemplate().subscribe(res=>{
      this.MimeTypeHtmlTemplateList = res.data[0].MimeTypeHtmlTemplate;
      this.spinner.hide();
    })
  }
  removeMimeTypeHtmlTemplate(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "mimeTypeId": product.mimeTypeId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20",
          "dataResourceId": product.dataResourceId,
          "fromDate": product.fromDate,
        }
        this.myContactsService.removeMimeTypeHtmlTemplate(req).subscribe(res => {
          this.MimeTypeHtmlTemplate();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createMimeTypeHtmlTemplate() {
    this.spinner.show();
    const requestData =
    {
      "mimeTypeId":  this.addMimeTypeTemplateForm.value.mimeTypeID ,
      "templateLocation":  this.addMimeTypeTemplateForm.value.templateLocation ,
    }
    this.myContactsService.createMimeTypeHtmlTemplate(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addMimeTypeTemplateForm.reset();
        this.MimeTypeHtmlTemplate();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  updateMimeTypeHtmlTemplate() {
    this.spinner.show();
    const requestData =
    {
      "mimeTypeId":  this.addMimeTypeTemplateForm.value.mimeTypeID ,
      "templateLocation":  this.addMimeTypeTemplateForm.value.templateLocation ,
    }
    this.myContactsService.updateMimeTypeHtmlTemplate(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addMimeTypeTemplateForm.reset();
        this.MimeTypeHtmlTemplate();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  openType()
  {
    this._Router.navigate(['/content/data-resource-setup']);
  }
  openCharcterSet()
  {
    this._Router.navigate(['/content/data-resource-setup/character-set']);

  }
  openCategory()
  {
    this._Router.navigate(['/content/data-resource-setup/category']);

  }
  openTypeAttribute()
  {
    this._Router.navigate(['/content/data-resource-setup/type-attribute']);

  }
  openFileExt()
  {
    this._Router.navigate(['/content/data-resource-setup/file-ext']);

  }
  openMetaDataPredicate()
  {
    this._Router.navigate(['/content/data-resource-setup/metadata-predicate']);

  }
  openMimeType()
  {
    this._Router.navigate(['/content/data-resource-setup/mimetype']);

  }
  openMimeTypeTemplate()
  {
    this._Router.navigate(['/content/data-resource-setup/mimetype-template']);

  }


  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;
 
        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
 
    
 }
 
 ngOnDestroy(): void {
  this.spinner.hide();
    }
 
  changeactiveCategory(tab: number)
  {
    this.activeCategory = tab;
  
  }




}
