import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-content-setup',
  templateUrl: './content-setup.component.html',
  styleUrls: ['./content-setup.component.css']
})
export class ContentSetupComponent implements OnInit {
  activeCategory = 7;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  editContentTypeForm: FormGroup;
  contentType: any;
  show: boolean;
  yesNoArray: { label: string; value: string; }[];
  constructor(
    readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,

    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
  ) {
    this.editContentTypeForm = this._FormBuilder.group({
      contentTypeID: "",
      parentTypeID: "",
      hasTable: "",
      description: "",
    })
    this.yesNoArray = [
      {
        label: "Y",
        value: "Y"
      },
      {
        label: "N",
        value: "N"
      }
    ]
  }

  openPurposeOperation() {
    this._Router.navigate(['/content/content-setup/edit-content-purpose-operation']);

  }

  openContentOperation() {
    this._Router.navigate(['/content/content-setup/edit-content-operation']);

  }

  openAssociationPredicate() {
    this._Router.navigate(['/content/content-setup/edit-content-assoication-predicate']);

  }

  openTypeAttribute() {
    this._Router.navigate(['/content/content-setup/edit-content-type-attribute']);

  }

  openPurposeType() {
    this._Router.navigate(['/content/content-setup/edit-content-purpose-type']);

  }

  openAssociationType() {
    this._Router.navigate(['/content/content-setup/edit-content-association-type']);

  }

  openPermission() {
    this._Router.navigate(['/content/content-setup/permission']);

  }

  openContentType() {
    this._Router.navigate(['/content/content-setup']);

  }


  ngOnInit(): void {
    this.getContentType();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  reset() {
    this.show = false;
    this.editContentTypeForm.reset();
  }
  update(product) {
    this.show = true;
    setTimeout(() => {
      const formValue = this.editContentTypeForm;
      formValue.patchValue({
        contentTypeID: product.contentTypeId,
        parentTypeID: product.parentTypeId,
        hasTable: product.hasTable,
        description: product.description,
      })
    }, 2000);
  }
  getContentType() {
    this.spinner.show();
    this.myContactsService.getContentType().subscribe(res => {
      this.contentType = res.data.getContentTypeAttrById;
      this.spinner.hide();
    })
  }
  removeContentType(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        const req = {
          "contentTypeId": product.contentTypeId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.removeContentType(req).subscribe(res => {
          this.getContentType();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  addContentType() {
    this.spinner.show();
    const requestData =
    {
      "contentTypeId": this.editContentTypeForm.value.contentTypeID,
      "description": this.editContentTypeForm.value.description,
      "hasTable": this.editContentTypeForm.value.hasTable,
      "parentTypeId": this.editContentTypeForm.value.parentTypeID,
    }
    this.myContactsService.addContentType(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.editContentTypeForm.reset();
        this.getContentType();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateContentType() {
    this.spinner.show();
    const requestData =
    {
      "contentTypeId": this.editContentTypeForm.value.contentTypeID,
      "description": this.editContentTypeForm.value.description,
      "hasTable": this.editContentTypeForm.value.hasTable,
      "parentTypeId": this.editContentTypeForm.value.parentTypeID,
    }
    this.myContactsService.updateContentType(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.editContentTypeForm.reset();
        this.getContentType();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });


  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

}
