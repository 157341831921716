
<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">
    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            <div class="container-fluid" style="padding: 0px 0px;">
            
                <div class="col-md-12 p-0" >
                    <div class="w3-card-4 classCard  w-100"> 
                
                        <div class="mt-3">
                            <label class="" style="font-size:14px !important; font-weight: bold; margin-left: 14px ;">Class Tracking Settings</label>

                        </div>

                        
                    
                        <div class="col-lg-12">
                            <div class="row mt-3">
                                <p>
                                    You can track and assign accounting classes here for export on time sheet and invoices 
                                    to your financial system.
                                </p>

                            </div>

                        </div>



                        <div class="row " style="width: 100%; margin-top: 2%;">
                            <div class="col-md-12">
                                <div class="w3-card-4 classCard" style="margin-left: 12px;margin-right: -18px; margin-bottom: 1%;">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                        style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                        <p class="h-text color-grey"
                                            style="margin: 0px 15px -1px -2px !important; color:black; font-weight: bold !important;">
                                            Class Settings </p>
                                        
                                    </div>
                                    <div class="">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100">
                                                    <div class="mt-2">
                                                        <div class="col-lg-12">
                                                            <div class="row me-1">
                                                                <p>Note : If you are using classes in QuickBooks and are integarting
                                                                    with QuickBooks using our integartion client. please select 'Yes'
                                                                    you will be able to select a class for your invoices and time sheet.
                                                                    When you run integration. invoices will be exported along with class
                                                                    information.
                                                                </p>
                                                            </div>

                                                            <div class="col-lg-12 mt-4">
                                                                <div class="row d-flex pb-4">
                                                                    <div class="col-lg-5">
                                                                        <p>Do you want to use class tracking?Yes</p>

                                                                    </div>

                                                                    <div class="col-lg-2">
                                                                        <a href="">Edit</a>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                


                            </div>

                            
                        </div>

                       

                        <div class="mt-3">
                            <label class="" style="font-size:14px !important; font-weight: bold; margin-left: 14px !important;">Class List</label>

                        </div>

                        <div class="col-lg-12">
                            <div class="row " style="margin-top: -7px !important; margin-bottom: -1% !important;margin-left: 0% !important; ">
                                <a class="" style="" data-target="#addNewClassPopup" data-toggle="modal">Add New Class </a>
                            </div>
    
                        </div>

                        <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                    <div class="card own-account-table" style="border: 1px solid lightcyan !important;">
                                        <label style="background-color:rgb(206, 214, 218);color:black !important; border: 1px solid lightcyan !important;
                                        margin: 0px !important ; padding: 3px !important; font-size: 15px ; font-weight: bold;
                                        margin-left: 1%;">Class List </label>
                                        <p-table [value]="classList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                            <ng-template pTemplate="header">
                                                <tr style="background:rgb(206, 214, 218);">
                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                        <div class="checkbox-align" style="color:black;">
                                                            <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                    </div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            Class Name
                                                            <p-sortIcon field="code">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>
                                                    
                                                   
    
                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                        <div style="color:black;">
                                                            Action
                                                            <p-sortIcon field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-product>
                                                <tr>
                                                    <td [ngStyle]="{'width':'150px'}">
                                                        <div class="checkbox-align">
                                                            <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div class="ui-helper-hidden-accessible">
                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                    </div>
                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            <span  class="account-button"  >
                                                                {{product.teamMemberPartyId}}
                                                            </span>
    
                                                        </div>
                                                    </td>
                                                    <td [ngStyle]="{'width':'180px'}">{{product.securityGroupId}}
                                                    </td>
                                                    <td [ngStyle]="{'width':'180px'}">
                                                        <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" (click)="update(product)" data-target="#TeamMemberPopup">Preview</button>
                                                        <button type="button" class="btn btn-secondary submit-btn ml-2" (click)="delete(product.autoId)">Change</button>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage" let-columns>
                                                <tr>
                                                    <td>
                                                        <span class="text-center">
                                                            <h4 class="ml-2">No Record Found</h4>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                        <p class="paginate_data">
                                            View per page
    
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
       
        </div>
    </div>

</div>


<div class="modal fade" id="addNewClassPopup" tabindex="-1" role="dialog" aria-labelledby="addNewClassPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add New Class</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add New Class</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addNewClassForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Class Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                        formControlName="className" placeholder="Enter Class Name">
                                                        
                                                    </div>

                                                    
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 52%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
