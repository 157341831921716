import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
    import { Location } from '@angular/common';
    import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { ProductionService } from '../production.service';

@Component({
  selector: 'app-create-actual-material',
  templateUrl: './create-actual-material.component.html',
  styleUrls: ['./create-actual-material.component.css']
})
export class CreateActualMaterialComponent implements OnInit {
  createJob: FormGroup;
  activeCategory = 2;
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[] = [];
  varianceReasonId: any;
  varianceReasonIdArray: any[] = [];
  glAccountid: any;
  varianceReasonIdData: any;
  show: boolean;
  budgetId: any;
  personId: any;
  partyIdArray: any[] = [];
  ReviewResultTypes: any;
  ReviewResultTypesArray: any[] = [];
  reviewDate: string;
  workEffortId: any;
  routingTaskList: any;
  routingTaskListArray: any[]=[];
  productListArray: any[]=[];
  FromDate: string;
  enumId: string;
  enumDataArrayList: any[]=[];
  constructor(readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,  private _location: Location,
    readonly _ToastrService: ToastrService,) {
    this.createJob = this._FormBuilder.group({
      productId: [''],
      TaskID: [''],
      FromDate: [''],
      Quantity: [''],
      FailIfItemsAreNotAvailable: [''],
      FailIfItemsAreNotOnHand: [''],
      LotID: [''],
      LocationSeqID: [''],
      SecondaryLocation: [''],
      ReasonEnumID: [''],
      Description: [''],

    });

  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
     
   
    });
    this.getProductionMaterialRoutingTaskIds();
    this.getProducts();
    this.getEenumByTypeData();
  }
  getProducts(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      const productList = res.data;
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
   
    });
  
  }
  getProductionMaterialRoutingTaskIds() {
    this.spinner.show();
    
    this.productionService.getProductionMaterialRoutingTaskIds(this.workEffortId).subscribe(res => {
      this.routingTaskList = res.data;
      this.spinner.hide();
      for (const value of this.routingTaskList) {
        this.routingTaskListArray.push({
          label: value.workEffortId,
          value: value.workEffortId
        })
      }
    })
    
  }
  getEenumByTypeData(): void {
    this.spinner.show();
    this.enumId="IID_REASON"
    this.accountsService.getEenumByType(this.enumId).subscribe((res) => {
      const enumDa = res.data;
      this.spinner.hide();
      for (const value of enumDa) {
        this.enumDataArrayList.push({
          label: value.description,
          value: value.enumId,
        });
      }
    });
   
  }
  onSubmit() {
    this.spinner.show();
    const completeDate = this.createJob.get('FromDate').value;
    this.FromDate = this.datePipe.transform(completeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "description": this.createJob.value.Description,
      "failIfItemsAreNotAvailable": this.createJob.value.FailIfItemsAreNotAvailable,
      "failIfItemsAreNotOnHand": this.createJob.value.FailIfItemsAreNotOnHand, "fromDate": this.FromDate,
      "locationSeqId": this.createJob.value.LocationSeqID,
      "lotId": this.createJob.value.LotID,
      "productId": this.createJob.value.productId,
      "quantity": this.createJob.value.Quantity,
      "reasonEnumId": this.createJob.value.ReasonEnumID,
      "secondaryLocationSeqId": this.createJob.value.SecondaryLocation,
      "workEffortId": this.createJob.value.TaskID,
    }
    this.productionService.postActualMaterial(this.workEffortId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: this.workEffortId} })
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }}  else{
          this._ToastrService.error(err.error.message);
        }});}
        
  // cancelSubmit(){
  //   this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: this.workEffortId} })
  // }
  homeButton(){
    this._Router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}


