import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
  import { Location } from '@angular/common';
  import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { NewWarehouseShipmentService } from '../new-warehouse-shipment/new-warehouse-shipment.service';
import { WarehouseShipmentService } from '../warehouse-shipment/warehouse-shipment.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import Swal from 'sweetalert2'
import { WarehouseService } from '../warehouse/warehouse.service';


@Component({
  selector: 'app-warehouse-shipment-summary',
  templateUrl: './warehouse-shipment-summary.component.html',
  styleUrls: ['./warehouse-shipment-summary.component.css']
})
export class WarehouseShipmentSummaryComponent implements OnInit {
  activeCategory=1;
  products3: any;
  total=0;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;

  rowNumber=0;
  activeCategoryShip=2;
  pageSize = 10;
  rows = 50;
  ShipmentInfo:any=[];
  shipmentItemInfo:any=[];
  itemIssuancesInfo:any=[];
 
  shipmentRouteInfo: any;
  shipmentPackageInfo: any;
  box: any;
  weight: any;
  destPostalAddress: any;
  destTelecomNumber: any;
  originPostalAddress: any;
  originTelecomNumber: any;
  ShipmentInfoAddress: any;
  detailShipmentPackge: any;
  updateWarehouseShipment:FormGroup;
  shipmentPlanForm:FormGroup;
  editMode: boolean;

  OrderIdsList: any[];
  PersonsAndPartyGroupsList: any[];
  getCurrencyList: any[];
  StatusIdList: any[];
  ShipmentTypeIdList: any[];

  facilities: any[];
  facilityId: string;
  shipmentId: any = [];
 
  estimatedReadyDate: string;
  estimatedShipDate: string;
  estimatedArrivalDate: string;
  latestCancelDate: string;
  eventDate: string;
  url: string;
  activeShipment: number = 0;
  OrderItemForm: FormGroup;
  itemsForm: FormGroup;
  addShipmentRouteForm: FormGroup;
  recieveInventoryPurchaseOrderForm: FormGroup;
  shipmentItem: any;
  receiveProductsMasterValue: any;
  products: any[]=[];
  show: boolean;
  updateShipmentItemPat: any;
  updateItemsForm: FormGroup;
  findShipmentPlan: any;
  addshipmentPlanForm: FormGroup;
  shipmentPackage: any;
  shipmentPackageValues: any;
  updateRouteSegmentForm: FormGroup;
  createShipmentPackageContentForm: FormGroup;
  updateShipmentPackageForm: FormGroup;
  shipmentPackageRoutes: any;
  shipmentPackageContent: any;
  orderIds: any[]=[];
  orderItems: any;
  receiveInventoryAgainstPurchaseOrder: any;
  billingWeightUomIDArray: any[]=[];
  billingWeightUomID: any;
  carrierPartyIDArray: any[]=[];
  carrierPartyID: any;
  carrierServiceStatusID: any;
  carrierServiceStatusIDArray: any[]=[];
  ShipmentMethodTypeIDArray: any[]=[];
  ShipmentMethodTypeID: any;
  updateShipmentPackageRouteSegPat: any;
  shipmentDropDownID: any;
  shipmentDropDownArray: any[]=[];
  shipmentboxTypeDropDownIDArray: any[]=[];
  shipmentboxTypeDropDownID: any;
  shipmentDropDownWeightArray: any[]=[];
  addShipmentRouteSegmentForm: FormGroup;
  updateShipmentRouteSegmentForm: FormGroup;
  editShipmentRouteSegments: any;
  status: any;
  hideReceiv: boolean=true;
  editRouteSegmentsSummary: any;
  shipmentReceipts: any;
  updateShipmentRouteSegmentPat: any;
  estimatedStartDatePatch: string;
  estimatedArrivalDatePatch: string;
  actualStartDatePatch: string;
  actualArrivalDatePatch: string;
  hideTabs: boolean=true;
  constructor(
    readonly router: Router,
  private _location: Location,
  readonly spinner: NgxSpinnerService,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _WarehouseService: WarehouseService,
  
    readonly WarehouseShipmentService: WarehouseShipmentService,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe
  ) {
    this.updateWarehouseShipment = this._FormBuilder.group({
      statusId: [''],
      primaryOrderId: [''],
      primaryReturnId: [''],
      primaryShipGroupSeqId: [''],
      picklistBinId: [''],
      estimatedReadyDate: [''],
      estimatedShipDate: [''],
      estimatedArrivalDate: [''],
      latestCancelDate: [''],
      estimatedShipCost: [''],
      currencyUomId: [''],
      handlingInstructions: [''],
      originFacilityId: [''],
      originFacility: [''],
      originTelecomNumberId: [''],
      destinationFacilityId: [''],
      destinationTelecomNumberId: [''],
      partyIdTo: [''],
      partyIdFrom: [''],
      additionalShippingCharge: [''],
      addtlShippingChargeDesc: [''],
      eventDate: [''],
      shipmentTypeId: [''],
      shipmentId: [''],
      adress: ['']
    });
    this.shipmentPlanForm = this._FormBuilder.group({
      ordId: [''],
      shipSeqId: ['00001'],
    })
    this.OrderItemForm = this._FormBuilder.group({
      ordItemId: [''],
      shipGrpId: ['00001'],
    })
    this.itemsForm = this._FormBuilder.group({
      pid: [''],
      qntt: [''],
      pdesc:['']
    })

    this.updateItemsForm = this._FormBuilder.group({
      pid: [''],
      qntt: [''],
      pdesc:['']
    })

    this.updateRouteSegmentForm = this._FormBuilder.group({
      track: [''],
      box: [''],
    })

    this.createShipmentPackageContentForm = this._FormBuilder.group({
      shipmentItemSeqId: [''],
      quantity: [''],
    })

    this.updateShipmentPackageForm = this._FormBuilder.group({
      weight: [''],
      weightUnit: [''],
      boxType: [''],
      insuredValue: [''],
    })

    
    

    this.addshipmentPlanForm = this._FormBuilder.group({
      ordId: [''],
      orderItem: [''],
      shipGroupSeqID:[''],
      productID:[''],
      orderedQuantity:[''],
      plannedQuantity:[''],
      issuedQuantity:[''],
      quantity:[''],
      weight:[''],
      weightUOM:[''],
      volume:[''],
      volumeUOM:[''],
    })
    
    this.addShipmentRouteSegmentForm = this._FormBuilder.group({
      carrierPartyId: [''],
      shipmentMethodTypeId: [''],
      facilityID: [''],
      destFacilityID: [''],
      originContactMechId: [''],
      destContactMechId: [''],
      originTelecomNumberId: [''],
      destTelecomNumberId: [''],
      carrierServiceStatusId: [''],
      trackNbr: [''],
      estimatedStartDate: [''],
      estimatedArrivalDate: [''],
      actualStartDate: [''],
      actualArrivalDate: [''],
      billingWeight: [''],
      billingWeightUomId: [''],
      currencyUomId: [''],
      actualTransportCost: [''],
      actualServiceCost: [''],
      actualOtherCost: [''],
      actualCost: [''],
    })

    this.updateShipmentRouteSegmentForm = this._FormBuilder.group({
      carrierPartyId: [''],
      shipmentMethodTypeId: [''],
      facilityID: [''],
      destFacilityID: [''],
      originContactMechId: [''],
      destContactMechId: [''],
      originTelecomNumberId: [''],
      destTelecomNumberId: [''],
      carrierServiceStatusId: [''],
      trackNbr: [''],
      estimatedStartDate: [''],
      estimatedArrivalDate: [''],
      actualStartDate: [''],
      actualArrivalDate: [''],
      billingWeight: [''],
      billingWeightUomId: [''],
      currencyUomId: [''],
      actualTransportCost: [''],
      actualServiceCost: [''],
      actualOtherCost: [''],
      actualCost: [''],
      shipmentRouteSegmentId:[''],
      thirdPartyAccountNumber:[''],
      thirdPartyCountryGeoCode:[''],
      thirdPartyPostalCode:[''],
    })

    

    this.recieveInventoryPurchaseOrderForm = this._FormBuilder.group({
      shipmentID: [''],
      ordItemId:[''],
      shipGrpId:[''],
    })

    

    this.editMode = false;
    this.facilities = [];
    this.StatusIdList = [];
    this.getCurrencyList = [];
    this.OrderIdsList = [];
    this.PersonsAndPartyGroupsList = [];
    this.ShipmentTypeIdList = []
    this.facilityId = '';
   }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.router.routerState.root.queryParams.subscribe(params => {
      this.shipmentId = JSON.parse(params.data); 
      this.status = params["status"];
      if(this.status=="Received")
      {
        this.hideReceiv=false;
      }

      if(this.status != "Received")
      {
        this.hideTabs=false;
      }

    });
    this.getFacilityShipmentByIdView();
    this.getOrderIds();
    this.getPersonsAndPartyGroups();
    this.getCurrency();
    this.getStatusId();
    this.getFacility();
    this.getShipmentTypeId();
    this.getAgreementById();
    this.getProducts();
    this.getShipmentItem();
    this.EditShipmentPackages();
    this.billingWeightUomId();
    this.carrierPartyId();
    this.carrierServiceStatusId();
    this.ShipmentMethodType();
    this.AddItemsFromOrder();
    this.ReceiveInventoryAgainstPurchaseOrder();
    this.shipmentDropDown();
    this.shipmentboxTypeDropDown();
    this.EditShipmentRouteSegments();
    this.getShipmentReceipts();
    
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getFacilityShipmentByIdView() {
    this.spinner.show();
    var shipmentId =this.shipmentId;
    this.NewWarehouseShipmentService.getFacilityShipmentByIdView(shipmentId).subscribe((responce) => {

      this.ShipmentInfo = responce.data.shipmentInfo.shipment;
      this.ShipmentInfoAddress = responce.data.shipmentInfo;
      if(responce.data.shipmentItemsInfo.length!=0){
        this.shipmentItemInfo = responce.data.shipmentItemsInfo
      this.itemIssuancesInfo = responce.data.shipmentItemsInfo[0].itemIssuances[0];
      }
      if(responce.data.shipmentRouteInfo.length!=0){
      this.shipmentRouteInfo=responce.data.shipmentRouteInfo[0].shipmentRouteSegment
      this.destPostalAddress=responce.data.shipmentRouteInfo[0].destPostalAddress
      this.destTelecomNumber=responce.data.shipmentRouteInfo[0].destTelecomNumber

      this.originPostalAddress=responce.data.shipmentRouteInfo[0].originPostalAddress
      this.originTelecomNumber=responce.data.shipmentRouteInfo[0].originTelecomNumber
      }
     
      if(responce.data.shipmentPackageInfo.length!=0){
        this.detailShipmentPackge=responce.data.shipmentPackageInfo
      this.shipmentPackageInfo=responce.data.shipmentPackageInfo[0].shipmentPackage
      this.box=responce.data.shipmentPackageInfo[0].shipmentPackageRouteSegs[0]
      this.weight=responce.data.shipmentPackageInfo[0].shipmentPackageContents[0]
      }
   
      this.spinner.hide();
    });
   
  }

  EditShipmentPackages(): void {
    this.spinner.show();
    this.accountsService.EditShipmentPackages(this.shipmentId).subscribe((res: any) => { 
      if (res.success) {
        this.shipmentPackageValues=res.data.shipmentPackageDatas;
        this.shipmentPackageRoutes=res.data.shipmentPackageDatas[0].shipmentPackageRouteSegs;
        this.shipmentPackageContent=res.data.shipmentPackageDatas[0].shipmentPackageContents;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  getShipmentItem(): void {
    this.spinner.show();
    this.accountsService.getShipmentItem(this.shipmentId).subscribe((res: any) => { 
      if (res.success) {
        this.shipmentItem=res.data[0].getShipmentItem;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  EditShipmentRouteSegments(): void {
    this.spinner.show();
    this.accountsService.EditShipmentRouteSegments(this.shipmentId).subscribe((res: any) => { 
      if (res.success) {
        this.editShipmentRouteSegments=res.data[0].EditShipmentRouteSegments;
        this.editRouteSegmentsSummary=res.data[0].EditShipmentRouteSegments[0];

        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  getShipmentReceipts(): void {
    this.spinner.show();
    this.accountsService.getShipmentReceipts(this.shipmentId).subscribe((res: any) => { 
      if (res.success) {
        this.shipmentReceipts=res.data[0].shipmentReceipt;

        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  
 
  

  ReceiveInventoryAgainstPurchaseOrder(): void {
    this.spinner.show();
    this.accountsService.ReceiveInventoryAgainstPurchaseOrder(this.recieveInventoryPurchaseOrderForm.value.ordItemId,this.recieveInventoryPurchaseOrderForm.value.shipGrpId,this.shipmentId).subscribe((res: any) => { 
      if (res.success) {
        this.receiveInventoryAgainstPurchaseOrder=res.data;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  AddItemsFromOrder(): void {
    this.spinner.show();
    this.accountsService.AddItemsFromOrder(this.OrderItemForm.value.ordItemId,this.OrderItemForm.value.shipGrpId,this.shipmentId).subscribe((res: any) => { 
      if (res.success) {
        this.orderItems=res.data;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  getOrderIds(): void {
    this.spinner.show();
    this._WarehouseService.getOrderIds()
        .then(data => {
            this.spinner.hide();
            this.orderIds = data.data.map(value => {
                return {
                    label: value.orderId,
                    value: value.orderId
                };
            });
        });
        
}

  getProducts(): void {
    this.spinner.show();
    this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
        .then(data => {
            this.spinner.hide();
            this.receiveProductsMasterValue = data.data;
            this.products = data.data.map(value => {
                return {
                    label: value.productId,
                    name: value.productName,
                    value: value.productId
                };
            });
        });
       
}

createShipmentRouteSegment(): void {
  this.spinner.show();
  const req ={ 
  "actualArrivalDate": this.datePipe.transform(this.addShipmentRouteSegmentForm.value.actualArrivalDate, "yyyy-MM-dd hh:mm:ss"),
  "actualArrivalDate_i18n":'',
  "actualCost": this.addShipmentRouteSegmentForm.value.actualCost,
  "actualOtherCost": this.addShipmentRouteSegmentForm.value.actualOtherCost,
  "actualServiceCost": this.addShipmentRouteSegmentForm.value.actualServiceCost,
  "actualStartDate": this.datePipe.transform(this.addShipmentRouteSegmentForm.value.actualStartDate, "yyyy-MM-dd hh:mm:ss"),
  "actualStartDate_i18n":'',
  "actualTransportCost":this.addShipmentRouteSegmentForm.value.actualTransportCost ,
  "billingWeight": this.addShipmentRouteSegmentForm.value.billingWeight,
  "billingWeightUomId":this.addShipmentRouteSegmentForm.value.billingWeightUomId ,
  "carrierPartyId":this.addShipmentRouteSegmentForm.value.carrierPartyId ,
  "carrierServiceStatusId": this.addShipmentRouteSegmentForm.value.carrierServiceStatusId,
  "currencyUomId": this.addShipmentRouteSegmentForm.value.currencyUomId,
  "destContactMechId":this.addShipmentRouteSegmentForm.value.destContactMechId ,
  "destFacilityId": this.addShipmentRouteSegmentForm.value.destFacilityID,
  "destTelecomNumberId":this.addShipmentRouteSegmentForm.value.destTelecomNumberId ,
  "estimatedArrivalDate":this.datePipe.transform(this.addShipmentRouteSegmentForm.value.estimatedArrivalDate, "yyyy-MM-dd hh:mm:ss"),
  "estimatedArrivalDate_i18n":'',
  "estimatedStartDate": this.datePipe.transform(this.addShipmentRouteSegmentForm.value.estimatedStartDate, "yyyy-MM-dd hh:mm:ss"),
  "estimatedStartDate_i18n":'',
  "originContactMechId":this.addShipmentRouteSegmentForm.value.originContactMechId ,
  "originFacilityId": this.addShipmentRouteSegmentForm.value.facilityID,
  "originTelecomNumberId":this.addShipmentRouteSegmentForm.value.originTelecomNumberId ,
  "shipmentId": this.shipmentId,
  "shipmentMethodTypeId":this.addShipmentRouteSegmentForm.value.shipmentMethodTypeId ,
  "trackingIdNumber":this.addShipmentRouteSegmentForm.value.trackNbr 

  }
   this.accountsService.createShipmentRouteSegment(req).subscribe((res: any) => { 
    if (res.success) {
      this.toastr.success("Create Successfully");
      this.spinner.hide();
      this.EditShipmentPackages();
      this.closebutton5.nativeElement.click();
      this.createShipmentPackageContentForm.reset();
    }
  }, (err) => {
    this.spinner.hide();
    if(err.error.errors){
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
    }
    else{
      this.toastr.error(err.error.message);
    }
  }); 
}



updateShipmentRouteSegment(): void {
  this.spinner.show();
  const req ={
  "actualArrivalDate": this.datePipe.transform(this.updateShipmentRouteSegmentForm.value.actualArrivalDate, "yyyy-MM-dd hh:mm:ss"),
  "actualCost": this.updateShipmentRouteSegmentForm.value.actualCost,
  "actualOtherCost": this.updateShipmentRouteSegmentForm.value.actualOtherCost,
  "actualServiceCost": this.updateShipmentRouteSegmentForm.value.actualServiceCost,
  "actualStartDate":this.datePipe.transform(this.updateShipmentRouteSegmentForm.value.actualStartDate, "yyyy-MM-dd hh:mm:ss"),
  "actualTransportCost": this.updateShipmentRouteSegmentForm.value.actualTransportCost,
  "billingWeight": this.updateShipmentRouteSegmentForm.value.billingWeight,
  "billingWeightUomId": this.updateShipmentRouteSegmentForm.value.billingWeightUomId,
  "carrierPartyId": this.updateShipmentRouteSegmentForm.value.carrierPartyId,
  "carrierServiceStatusId": this.updateShipmentRouteSegmentForm.value.carrierServiceStatusId,
  "currencyUomId": this.updateShipmentRouteSegmentForm.value.currencyUomId,
  "destContactMechId": this.updateShipmentRouteSegmentForm.value.destContactMechId,
  "destFacilityId": this.updateShipmentRouteSegmentForm.value.destFacilityID,
  "destTelecomNumberId": this.updateShipmentRouteSegmentForm.value.destTelecomNumberId,
  "estimatedArrivalDate": this.datePipe.transform(this.updateShipmentRouteSegmentForm.value.estimatedArrivalDate, "yyyy-MM-dd hh:mm:ss"),
  "estimatedStartDate":this.datePipe.transform(this.updateShipmentRouteSegmentForm.value.estimatedStartDate, "yyyy-MM-dd hh:mm:ss"),
  "originContactMechId": this.updateShipmentRouteSegmentForm.value.originContactMechId,
  "originFacilityId": this.updateShipmentRouteSegmentForm.value.facilityID,
  "originTelecomNumberId": this.updateShipmentRouteSegmentForm.value.originTelecomNumberId,
  "shipmentId": this.shipmentId,
  "shipmentMethodTypeId":this.updateShipmentRouteSegmentForm.value.shipmentMethodTypeId,
  "shipmentRouteSegmentId":this.updateShipmentRouteSegmentForm.value.shipmentRouteSegmentId,
  "thirdPartyAccountNumber":this.updateShipmentRouteSegmentForm.value.thirdPartyAccountNumber,
  "thirdPartyCountryGeoCode": this.updateShipmentRouteSegmentForm.value.thirdPartyCountryGeoCode,
  "thirdPartyPostalCode": this.updateShipmentRouteSegmentForm.value.thirdPartyPostalCode,
  "trackingIdNumber": this.updateShipmentRouteSegmentForm.value.trackNbr

}
   this.accountsService.updateShipmentRouteSegment(req).subscribe((res: any) => { 
    if (res.success) {
      this.toastr.success("Updated Successfully");
      this.spinner.hide();
      this.EditShipmentPackages();
      this.closebutton6.nativeElement.click();
      this.show=true;
    }
  }, (err) => {
    this.spinner.hide();
    if(err.error.errors){
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
    }
    else{
      this.toastr.error(err.error.debugMessage);
    }
  }); 
}

updateShipmentRouteSegmentPatch(product) {
  this.show=false;
  this.updateShipmentRouteSegmentPat  = product;
  this.spinner.show();
  const estimatedStartDatePatch=product.estimatedStartDate;
  this.estimatedStartDatePatch=this.datePipe.transform(estimatedStartDatePatch,"yyyy-MM-dd");

  const estimatedArrivalDatePatch=product.estimatedArrivalDate;
  this.estimatedArrivalDatePatch=this.datePipe.transform(estimatedArrivalDatePatch,"yyyy-MM-dd");

  const actualStartDatePatch=product.actualStartDate;
  this.actualStartDatePatch=this.datePipe.transform(actualStartDatePatch,"yyyy-MM-dd");

  const actualArrivalDatePatch=product.actualArrivalDate;
  this.actualArrivalDatePatch=this.datePipe.transform(actualArrivalDatePatch,"yyyy-MM-dd");
    setTimeout(() => {
      const formValue = this.updateShipmentRouteSegmentForm;
      formValue.patchValue({
      shipmentMethodTypeId:this.updateShipmentRouteSegmentPat.shipmentMethodTypeId ,
      facilityID: this.updateShipmentRouteSegmentPat.originFacilityId,
      destFacilityID: this.updateShipmentRouteSegmentPat.destFacilityId,
      originContactMechId:this.updateShipmentRouteSegmentPat.originContactMechId ,
      destContactMechId:this.updateShipmentRouteSegmentPat.destContactMechId ,
      originTelecomNumberId:this.updateShipmentRouteSegmentPat.originTelecomNumberId ,
      destTelecomNumberId:'',
      carrierServiceStatusId:this.updateShipmentRouteSegmentPat.carrierServiceStatusId ,
      trackNbr:this.updateShipmentRouteSegmentPat.trackingIdNumber ,
      estimatedStartDate:this.updateShipmentRouteSegmentPat.estimatedStartDatePatch ,
      estimatedArrivalDate:this.updateShipmentRouteSegmentPat.estimatedArrivalDatePatch ,
      actualStartDate: this.updateShipmentRouteSegmentPat.actualStartDatePatch,
      actualArrivalDate:this.updateShipmentRouteSegmentPat.actualArrivalDatePatch ,
      billingWeight:this.updateShipmentRouteSegmentPat.billingWeight ,
      billingWeightUomId: this.updateShipmentRouteSegmentPat.billingWeightUomId,
      currencyUomId:this.updateShipmentRouteSegmentPat.currencyUomId,
      actualTransportCost:this.updateShipmentRouteSegmentPat.actualTransportCost ,
      actualServiceCost:this.updateShipmentRouteSegmentPat.actualServiceCost ,
      actualOtherCost: this.updateShipmentRouteSegmentPat.actualOtherCost,
      actualCost:this.updateShipmentRouteSegmentPat.actualCost ,
      shipmentRouteSegmentId:this.updateShipmentRouteSegmentPat.trackingCode,
      thirdPartyAccountNumber:this.updateShipmentRouteSegmentPat.thirdPartyAccountNumber,
      thirdPartyCountryGeoCode:this.updateShipmentRouteSegmentPat.thirdPartyCountryGeoCode,
      thirdPartyPostalCode:this.updateShipmentRouteSegmentPat.thirdPartyPostalCode,
    
      })
    }, 2000);
    this.spinner.hide();
}


updateShipmentPackageRouteSeg(): void {
  this.spinner.show();
  const req ={
  "boxNumber": this.updateRouteSegmentForm.value.box,
  "shipmentId": this.updateShipmentPackageRouteSegPat.shipmentId,
  "shipmentPackageSeqId": this.updateShipmentPackageRouteSegPat.shipmentPackageSeqId,
  "shipmentRouteSegmentId":this.updateShipmentPackageRouteSegPat.shipmentRouteSegmentId,
  "trackingCode": this.updateRouteSegmentForm.value.track,
}
   this.accountsService.updateShipmentPackageRouteSeg(req).subscribe((res: any) => { 
    if (res.success) {
      this.toastr.success("Updated Successfully");
      this.spinner.hide();
      this.EditShipmentPackages();
      this.closebutton6.nativeElement.click();
      this.show=true;
    }
  }, (err) => {
    this.spinner.hide();
    if(err.error.errors){
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
    }
    else{
      this.toastr.error(err.error.debugMessage);
    }
  }); 
}

updateShipmentPackageRouteSegPatch(product) {
  this.show=false;
  this.updateShipmentPackageRouteSegPat  = product;
  this.spinner.show();
    setTimeout(() => {
      const formValue = this.updateRouteSegmentForm;
      formValue.patchValue({
        track: this.updateShipmentPackageRouteSegPat.trackingCode,
        box: this.updateShipmentPackageRouteSegPat.boxNumber,
    
      })
    }, 2000);
    this.spinner.hide();
}


issueOrderItemShipGrpInvResToShipment(product) {
  Swal.fire({
  title: 'Are you sure?',
  text: 'You will not be able to recover this data.!',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes, delete it!',
  cancelButtonText: 'No, keep it'
}).then((result) => {
  if (result.value) {
    let req = {
      "_rowCount": '',
      "_rowSubmit": 'Y',
      "_useRowSubmit": 'Y',
      "inventoryItemId": '',
      "orderId": product.orderItemDatas[0].itemIssuances[0].orderId,
      "orderItemSeqId": product.orderItemDatas[0].itemIssuances[0].shipmentItemSeqId,
      "quantity": '',
      "selectAll": 'Y',
      "shipGroupSeqId":product.orderItemDatas[0].itemIssuances[0].shipGroupSeqId,
      "shipmentId": this.shipmentId,

    }
    this.accountsService.issueOrderItemShipGrpInvResToShipment(req).subscribe((res: any) => {
      if (res.success) {
        this.EditShipmentPackages();
      }
      else {
        this.toastr.error("Error");
      }
    })
    Swal.fire(
      'Deleted!',
      'Deleted Successfully.',
      'success'
    )
  } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire(
      'Cancelled',
      'Your data is safe :)',
      'error'
    )
  }
})
}


  deleteShipmentRouteSegment(product) {
    Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {
      let req = {
        "shipmentId":product.shipmentId,
        "shipmentRouteSegmentId": product.shipmentRouteSegmentId,
      }
      this.accountsService.deleteShipmentRouteSegment(req).subscribe((res: any) => {
        if (res.success) {
          this.EditShipmentPackages();
        }
        else {
          this.toastr.error("Error");
        }
      })
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }
  })
  }

deleteShipmentPackageRouteSeg(product) {
  Swal.fire({
  title: 'Are you sure?',
  text: 'You will not be able to recover this data.!',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes, delete it!',
  cancelButtonText: 'No, keep it'
}).then((result) => {
  if (result.value) {
    let req = {
      "shipmentId":product.shipmentId ,
      "shipmentPackageSeqId":product.shipmentPackageSeqId ,
      "shipmentRouteSegmentId":product.shipmentRouteSegmentId,

  }
    this.accountsService.deleteShipmentPackageRouteSeg(req).subscribe((res: any) => {
      if (res.success) {
        this.EditShipmentPackages();
      }
      else {
        this.toastr.error("Error");
      }
    })
    Swal.fire(
      'Deleted!',
      'Deleted Successfully.',
      'success'
    )
  } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire(
      'Cancelled',
      'Your data is safe :)',
      'error'
    )
  }
})
}



deleteShipmentPackage(product) {
  Swal.fire({
  title: 'Are you sure?',
  text: 'You will not be able to recover this data.!',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes, delete it!',
  cancelButtonText: 'No, keep it'
}).then((result) => {
  if (result.value) {
    let req = {
      "shipmentId": product.shipmentPackage.shipmentId,
      "shipmentPackageSeqId": product.shipmentPackage.shipmentPackageSeqId,
  }
    this.accountsService.deleteShipmentPackage(req).subscribe((res: any) => {
      if (res.success) {
        this.EditShipmentPackages();
      }
      else {
        this.toastr.error("Error");
      }
    })
    Swal.fire(
      'Deleted!',
      'Deleted Successfully.',
      'success'
    )
  } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire(
      'Cancelled',
      'Your data is safe :)',
      'error'
    )
  }
})
}

deleteShipmentPackageContent(product) {
  Swal.fire({
  title: 'Are you sure?',
  text: 'You will not be able to recover this data.!',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes, delete it!',
  cancelButtonText: 'No, keep it'
}).then((result) => {
  if (result.value) {
    let req = {
    "shipmentId":product.shipmentId ,
    "shipmentItemSeqId": product.shipmentItemSeqId,
    "shipmentPackageSeqId": product.shipmentPackageSeqId

    }
    this.accountsService.deleteShipmentPackageContent(req).subscribe((res: any) => {
      if (res.success) {
        this.EditShipmentPackages();
      }
      else {
        this.toastr.error("Error");
      }
    })
    Swal.fire(
      'Deleted!',
      'Deleted Successfully.',
      'success'
    )
  } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire(
      'Cancelled',
      'Your data is safe :)',
      'error'
    )
  }
})
}

createShipmentPackageContent(): void {
  this.spinner.show();
  const req ={
    "quantity": this.createShipmentPackageContentForm.value.quantity,
    "shipmentId": this.shipmentId,
    "shipmentItemSeqId": this.createShipmentPackageContentForm.value.shipmentItemSeqId,
    "shipmentPackageSeqId": "00001",
  }
   this.accountsService.createShipmentPackageContent(req).subscribe((res: any) => { 
    if (res.success) {
      this.toastr.success("Create Successfully");
      this.spinner.hide();
      this.EditShipmentPackages();
      this.closebutton5.nativeElement.click();
      this.createShipmentPackageContentForm.reset();
    }
  }, (err) => {
    this.spinner.hide();
    if(err.error.errors){
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
    }
    else{
      this.toastr.error(err.error.debugMessage);
    }
  }); 
}

createShipmentItem(): void {
  this.spinner.show();
  const req ={
    "productId": this.itemsForm.value.pid,
    "quantity":  this.itemsForm.value.qntt,
    "shipmentContentDescription":  this.itemsForm.value.pdesc,
    "shipmentId":this.shipmentId,
    }
   
  this.accountsService.createShipmentItem(req).subscribe((res: any) => { 
    if (res.success) {
      this.toastr.success("Create Successfully");
      this.spinner.hide();
      this.getShipmentItem();
      this.closebutton2.nativeElement.click();
      this.itemsForm.reset();
    }
  }, (err) => {
    this.spinner.hide();
    if(err.error.errors){
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
    }
    else{
      this.toastr.error(err.error.debugMessage);
    }
  }); 
}


addToShipmentPlan(): void {
  this.spinner.show();
  const req ={
    "issuedQuantity": this.addshipmentPlanForm.value.issuedQuantity,
    "orderId": this.addshipmentPlanForm.value.ordId,
    "orderItemSeqId": this.addshipmentPlanForm.value.issuedQuantity,
    "orderedQuantity": this.addshipmentPlanForm.value.orderedQuantity,
    "plannedQuantity": this.addshipmentPlanForm.value.plannedQuantity,
    "productId": this.addshipmentPlanForm.value.productID,
    "quantity": this.addshipmentPlanForm.value.quantity,
    "shipGroupSeqId": this.addshipmentPlanForm.value.shipGroupSeqID,
    "shipmentId": this.shipmentId,
    "volume": this.addshipmentPlanForm.value.volume,
    "volumeUom": this.addshipmentPlanForm.value.volumeUOM,
    "weight": this.addshipmentPlanForm.value.weight,
    "weightUom": this.addshipmentPlanForm.value.weightUOM
  }
  this.accountsService.addToShipmentPlan(req).subscribe((res: any) => { 
    if (res.success) {
      this.toastr.success("Create Successfully");
      this.spinner.hide();
      this.EditShipmentPlan();
      this.closebutton4.nativeElement.click();
      this.addshipmentPlanForm.reset();
    }
  }, (err) => {
    this.spinner.hide();
    if(err.error.errors){
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
    }
    else{
      this.toastr.error(err.error.debugMessage);
    }
  }); 
}

billingWeightUomId() {
  this.spinner.show();
  this.accountsService.billingWeightUomId().subscribe(res => {
    this.billingWeightUomID = res.data[0].billingWeightUomId;
    this.spinner.hide();
    for (const value of this.billingWeightUomID) {
      this.billingWeightUomIDArray.push({
        label: value.description,
        value: value.abbreviation
      })
    }
  })

}

carrierServiceStatusId() {
  this.spinner.show();
  this.accountsService.carrierServiceStatusId().subscribe(res => {
    this.carrierServiceStatusID = res.data[0].carrierServiceStatusId;
    this.spinner.hide();
    for (const value of this.carrierServiceStatusID) {
      this.carrierServiceStatusIDArray.push({
        label: value.description +"["+value.statusCode+"]",
        value: value.statusId
      })
    }
  })

}

shipmentDropDown() {
  this.spinner.show();
  this.accountsService.shipmentDropDown().subscribe(res => {
    this.shipmentDropDownID = res.data;
    this.spinner.hide();
    for (const value of this.shipmentDropDownID) {
      this.shipmentDropDownWeightArray.push({
        label: value.value,
        value: value.value
      })
    }
  })

}

shipmentboxTypeDropDown() {
  this.spinner.show();
  this.accountsService.shipmentboxTypeDropDown().subscribe(res => {
    this.shipmentboxTypeDropDownID = res.data;
    this.spinner.hide();
    for (const value of this.shipmentboxTypeDropDownID) {
      this.shipmentboxTypeDropDownIDArray.push({
        label: value.value,
        value: value.value
      })
    }
  })

}

carrierPartyId() {
  this.spinner.show();
  this.accountsService.carrierPartyId().subscribe(res => {
    this.carrierPartyID = res.data;
    this.spinner.hide();
    for (const value of this.carrierPartyID) {
      this.carrierPartyIDArray.push({
        label: value.partyIdDes[0].groupName,
        value: value.partyIdDes[0].partyId
      })
      console.log(value.partyIdDes[0].groupName);
    }
  })

}

ShipmentMethodType() {
  this.spinner.show();
  this.accountsService.ShipmentMethodType().subscribe(res => {
    this.ShipmentMethodTypeID = res.data[0].ShipmentMethodType;
    this.spinner.hide();
    for (const value of this.ShipmentMethodTypeID) {
      this.ShipmentMethodTypeIDArray.push({
        label: value.description,
        value: value.shipmentMethodTypeId
      })
    }
  })

}


EditShipmentPlan(): void {
  this.spinner.show();
  let req={
    primaryOrderId: this.shipmentPlanForm.value.ordId ? this.shipmentPlanForm.value.ordId : '' ,
    primaryShipGroupSeqId: this.shipmentPlanForm.value.shipSeqId ? this.shipmentPlanForm.value.shipSeqId : '',
    shipmentId:this.shipmentId,
   }
   this.accountsService.EditShipmentPlan(req).subscribe((res: any) => { 
    if (res.success) {
      this.findShipmentPlan=res.data;
      this.spinner.hide();
    }
  }, (err) => {
    this.spinner.hide();
    if(err.error.errors){
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
    }
    else{
      this.toastr.error(err.error.message);
    }
  }); 
}
updateShipmentItem(): void {
  this.spinner.show();
  const req ={
    "productId": this.updateItemsForm.value.pid,
    "quantity": this.updateItemsForm.value.qntt,
    "shipmentContentDescription": this.updateItemsForm.value.pdesc,
    "shipmentId":this.shipmentId,
  }
   
  this.accountsService.updateShipmentItem(req).subscribe((res: any) => { 
    if (res.success) {
      this.toastr.success("Updated Successfully");
      this.spinner.hide();
      this.getShipmentItem();
      this.closebutton3.nativeElement.click();
      this.show=true;
    }
  }, (err) => {
    this.spinner.hide();
    if(err.error.errors){
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
    }
    else{
      this.toastr.error(err.error.message);
    }
  }); 
}

updateShipmentItemPatch(product) {
  this.show=false;
  this.updateShipmentItemPat  = product;
  this.spinner.show();
    setTimeout(() => {
      const formValue = this.updateItemsForm;
      formValue.patchValue({
        pid: this.updateShipmentItemPat.productId,
        qntt: this.updateShipmentItemPat.quantity,
        pdesc: this.updateShipmentItemPat.shipmentContentDescription,
    
      })
    }, 2000);
    this.spinner.hide();
}

deleteShipmentItem(product) {
  Swal.fire({
  title: 'Are you sure?',
  text: 'You will not be able to recover this data.!',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes, delete it!',
  cancelButtonText: 'No, keep it'
}).then((result) => {
  if (result.value) {
    let req = {
      "shipmentId": product.shipmentId,
      "shipmentItemSeqId":product.shipmentItemSeqId,
    }
    this.accountsService.deleteShipmentItem(req).subscribe((res: any) => {
      if (res.success) {
        this.getShipmentItem();
      }
      else {
        this.toastr.error("Error");
      }
    })
    Swal.fire(
      'Deleted!',
      'Deleted Successfully.',
      'success'
    )
  } else if (result.dismiss === Swal.DismissReason.cancel) {
    Swal.fire(
      'Cancelled',
      'Your data is safe :)',
      'error'
    )
  }
})
}

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
 
  export(id: any) {
    this.spinner.show();
    this.NewWarehouseShipmentService.downloadView(id).subscribe(res => {
      const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = id;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });
  
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  changeactiveCategory(tab:number){
    this.activeShipment = tab
  }
  // getOrderIds() {
  //   this.spinner.show();
  //   this.NewWarehouseShipmentService.getOrderIds().subscribe((responce) => {
  //     this.spinner.hide();
  //     this.OrderIdsList = responce.data.map(value => {
  //       return {
  //         label: value.orderId,
  //         value: value.orderId
  //       };
  //     });
  //   });
    
  // }

  getPersonsAndPartyGroups() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getPersonsAndPartyGroups().subscribe((responce) => {
      this.spinner.hide();
      this.PersonsAndPartyGroupsList = responce.data.PersonsGroups.map(value => {
        return {
          label: value.groupName,
          value: value.partyId
        };
      });
    });
  
  }

  getCurrency() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getCurrency().subscribe((responce) => {
      this.spinner.hide();
      this.getCurrencyList = responce.data.currencyList.map(value => {
        return {
          label: value.description,
          value: value.uomId
        };
      });
    });
   
  }

  getStatusId() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getStatusId().subscribe((responce) => {
      this.spinner.hide();
      this.StatusIdList = responce.data.map(value => {
        return {
          label: value.description,
          value: value.statusId
        };
      });

    });
   
  }

  getFacility(): void {
    this.spinner.show();
    this.WarehouseShipmentService.getFacility()
      .then(data => {
        this.spinner.hide();
        this.facilities = data.data.map(value => {
          return {
            label: value.facilityName,
            value: value.facilityId
          };
        });
      });
     
  }
  getShipmentTypeId() {
    this.spinner.show();
    this.WarehouseShipmentService.getShipmentTypeIdList().subscribe((responce) => {
      this.ShipmentTypeIdList = responce.data.map(value => {
        this.spinner.hide();
        return {
          label: value.shipmentTypeId,
          value: value.shipmentTypeId
        };
      });
    });
   
  }

  submit(): void {
    this.spinner.show();
    var shipmentId = this.shipmentId;
    this.estimatedReadyDate = this.datePipe.transform(this.updateWarehouseShipment.value.estimatedReadyDate, "yyyy-MM-dd hh:mm:ss"),
      this.estimatedShipDate = this.datePipe.transform(this.updateWarehouseShipment.value.estimatedShipDate, "yyyy-MM-dd hh:mm:ss"),
      this.estimatedArrivalDate = this.datePipe.transform(this.updateWarehouseShipment.value.estimatedArrivalDate, "yyyy-MM-dd hh:mm:ss"),
      this.latestCancelDate = this.datePipe.transform(this.updateWarehouseShipment.value.latestCancelDate, "yyyy-MM-dd hh:mm:ss"),
      this.eventDate = this.datePipe.transform(this.updateWarehouseShipment.value.eventDate, "yyyy-MM-dd hh:mm:ss")
    if (this.updateWarehouseShipment.value.destinationTelecomNumberId == null) {
      this.updateWarehouseShipment.value.destinationTelecomNumberId = ""
    }
    if (this.updateWarehouseShipment.value.handlingInstructions == null) {
      this.updateWarehouseShipment.value.handlingInstructions = "";
    }
    if (this.updateWarehouseShipment.value.originTelecomNumberId == null) {
      this.updateWarehouseShipment.value.originTelecomNumberId = "";
    }
    if (this.updateWarehouseShipment.value.picklistBinId == null) {
      this.updateWarehouseShipment.value.picklistBinId = "";
    }

    const requestData = {
      statusId: this.updateWarehouseShipment.value.statusId,
      primaryOrderId: this.updateWarehouseShipment.value.primaryOrderId,
      primaryReturnId: this.updateWarehouseShipment.value.primaryReturnId,
      primaryShipGroupSeqId: this.updateWarehouseShipment.value.primaryShipGroupSeqId,
      picklistBinId: this.updateWarehouseShipment.value.picklistBinId,
      estimatedReadyDate: this.estimatedReadyDate,
      estimatedShipDate: this.estimatedShipDate,
      estimatedArrivalDate: this.estimatedArrivalDate,
      latestCancelDate: this.latestCancelDate,
      estimatedShipCost: this.updateWarehouseShipment.value.estimatedShipCost,
      currencyUomId: this.updateWarehouseShipment.value.currencyUomId,
      handlingInstructions: this.updateWarehouseShipment.value.handlingInstructions,
      originFacilityId: this.updateWarehouseShipment.value.originFacilityId,

      originContactMechId: this.updateWarehouseShipment.value.adress,
      originTelecomNumberId: this.updateWarehouseShipment.value.originTelecomNumberId,
      destinationContactMechId: this.updateWarehouseShipment.value.destinationFacilityId,
      destinationFacilityId: this.updateWarehouseShipment.value.originFacility,
      destinationTelecomNumberId: this.updateWarehouseShipment.value.destinationTelecomNumberId,
      partyIdTo: this.updateWarehouseShipment.value.partyIdTo,
      partyIdFrom: this.updateWarehouseShipment.value.partyIdFrom,
      additionalShippingCharge: this.updateWarehouseShipment.value.additionalShippingCharge,
      addtlShippingChargeDesc: this.updateWarehouseShipment.value.addtlShippingChargeDesc,
      eventDate: this.eventDate,
      shipmentTypeId: this.updateWarehouseShipment.value.shipmentTypeId,
    };
    this.NewWarehouseShipmentService.updateFacilityShipment(shipmentId, requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Updated");
        this.spinner.hide();
        this.getFacilityShipmentByIdView();
        this.updateWarehouseShipment.reset();
        
        this.closebutton.nativeElement.click();
       
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }


  getAgreementById() {
    this.spinner.show();
    const shipmentId = this.shipmentId;
    this.NewWarehouseShipmentService.getFacilityShipmentById(shipmentId).subscribe((responce: any) => {

      this.estimatedReadyDate = this.datePipe.transform(responce.data.shipment.estimatedReadyDate, "yyyy-MM-dd"),
        this.estimatedShipDate = this.datePipe.transform(responce.data.shipment.estimatedShipDate, "yyyy-MM-dd"),
        this.estimatedArrivalDate = this.datePipe.transform(responce.data.shipment.estimatedArrivalDate, "yyyy-MM-dd"),
        this.latestCancelDate = this.datePipe.transform(responce.data.shipment.latestCancelDate, "yyyy-MM-dd"),
        this.eventDate = this.datePipe.transform(responce.data.shipment.eventDate, "yyyy-MM-dd"),
        setTimeout(() => {
          this.updateWarehouseShipment.patchValue({
            statusId: responce.data.shipment.statusId,
            primaryOrderId: responce.data.shipment.primaryOrderId,
            primaryReturnId: responce.data.shipment.primaryReturnId,
            primaryShipGroupSeqId: responce.data.shipment.primaryShipGroupSeqId,
            picklistBinId: responce.data.shipment.picklistBinId,
            estimatedReadyDate: this.estimatedReadyDate,
            estimatedShipDate: this.estimatedShipDate,
            estimatedArrivalDate: this.estimatedArrivalDate,
            latestCancelDate: this.latestCancelDate,
            estimatedShipCost: responce.data.shipment.estimatedShipCost,
            currencyUomId: responce.data.shipment.currencyUomId,
            handlingInstructions: responce.data.shipment.handlingInstructions,
            originFacilityId: responce.data.shipment.originFacilityId,
            adress: responce.data.shipment.originContactMechId,
            originTelecomNumberId: responce.data.shipment.originTelecomNumberId,
            originFacility: responce.data.shipment.destinationFacilityId,
            destinationFacilityId: responce.data.shipment.destinationContactMechId,
            destinationTelecomNumberId: responce.data.shipment.destinationTelecomNumberId,
            partyIdTo: responce.data.shipment.partyIdTo,
            partyIdFrom: responce.data.shipment.partyIdFrom,
            additionalShippingCharge: responce.data.shipment.additionalShippingCharge,
            addtlShippingChargeDesc: responce.data.shipment.addtlShippingChargeDesc,
            eventDate: this.eventDate,
            shipmentTypeId: responce.data.shipment.shipmentTypeId,
            shipmentId: responce.data.shipment.shipmentId,
          });


        }, 3000);
        this.spinner.hide();

    });
  
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
