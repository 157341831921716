
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AccountLeadSourceModel } from '../detailed-accounts-summary/detailed-account-model';

@Injectable({
  providedIn: 'root'
})


export class AccountLeadSourceService {
  lead_source_form: FormGroup;


  constructor(public _formBuilder: FormBuilder,
    public accountLeadSourceModel: AccountLeadSourceModel
  ) { }

  AccountleadSourceForm(data: any): void {
    if (!this.lead_source_form) {
      this.lead_source_form = this._formBuilder.group(this.accountLeadSourceModel.mapDataToModel(data));

    }

  }

}

