import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { HeaderService } from '../../../header/header.service';
import { Router } from '@angular/router';
import { AccountsService } from '../../accounts/accounts.service';
import { ActivatedRoute } from '@angular/router';
import { detailedContactsService } from './detailed-contacts-summary.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2'
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import * as moment from 'moment';
import { LeadPersonalLeadServices } from 'src/app/leadPersonal/lead-personal/lead-personal.service';
import { CreateContactsService } from '../create-contacts/create-contacts.service';
import { MyContactsService } from '../my-contacts/my-contacts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detailed-contacts-summary',
  templateUrl: './detailed-contacts-summary.component.html',
  styleUrls: ['./detailed-contacts-summary.component.css'],
  providers: [DatePipe]
})
export class DetailedContactsSummaryComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;
  @ViewChild('closebutton11') closebutton11;
  @ViewChild('closebutton12') closebutton12;
  @ViewChild('closebutton13') closebutton13;
  @ViewChild('closebutton14') closebutton14;
  @ViewChild('closebutton15') closebutton15;
  @ViewChild('closebutton16') closebutton16;
  @ViewChild('closebutton17') closebutton17;
  @ViewChild('closebutton18') closebutton18;
  products3: any;
  faEdit = faEdit;
  faTrash = faTrash;
  activeCategory = 1;
  total = 0;
  rowNumber = 0;
  pageSize = 10;
  rows = 50;
  partyId: any;
  assignName: any;
  partyIdDetail: any;
  contactName: string;
  partyIdFrom = '';
  updateTeamMem: any;
  accountId: any;
  accountarray: any = [];
  partyIdTo: any;
  noteData: any;
  relatedAccount: any;
  postalTable: any = [];
  emailId: any;
  phoneNumber: string;
  allCasessData: any;
  partyContentData: any;
  partyContentArray: any = [];
  partyIdLists: any;
  partyContentTypeId: any;
  fileData: any;
  Editform: FormGroup;
  image: any;
  imageDetails: any;
  previewUrl: any = null;
  contentList: any;
  fromDates: any;
  addr;
  tableArray = [{}]
  openNoRecordFoundRelatedAccount: Boolean = false;
  openNoRecordFoundContactInfo: Boolean = false;
  openNoRecordFoundPartyContent: Boolean = false;
  openNoRecordFoundNote: Boolean = false;
  updateContact: FormGroup;
  crmArray: any[] = [];
  array: any[] = [];
  stateArray: any[] = [];
  countryGeoId: any;
  countryId: any;
  stateProvinceGeoId: any;
  stateData: any;
  statusId: string;
  stateId: any;
  fileUploadForm: FormGroup;
  fileUploadFormA: FormGroup;
  contactId: any;
  contactArray: any[] = [];
  params: any;
  contactPartyId: any;
  partyIdForCreate: any;
  showUpdateBtn = false;
  showCreateBtn = false;
  noteId: any;
  noteNameForUpdate: any;
  noteForUpdate: any;
  createCases: FormGroup;
  createAccountsForm: FormGroup;
  createCasesForm: FormGroup;
  createNotesForm: FormGroup;
  teamMemberForm: FormGroup;
  AllContactRelatedAccount: any;
  getAccountsList: any;
  productAcc: any;
  showAcc: boolean;
  allAccountData: any;
  show: boolean;
  productOrder: any;
  createOrderForm: FormGroup;
  actualfromdate: string;
  shipBeforeDate: string;
  allOrderByData: any;
  productOpp: any;
  allOpportunityData: any;
  createCasesNewForm: FormGroup;
  productCasessNew: any;
  createCatalogForm: FormGroup;
  showActivityEvent: boolean;
  productActivityEvent: any;
  eventForm: FormGroup;
  taskForm: FormGroup;
  pendingActivityEvent: any;
  productActivityTask: any;
  showActivityTask: boolean;
  PendingActivityTask: any;
  OutboundArray: { label: string; value: string; }[];
  amPM: { label: string; value: string; }[];
  yesNoArray: { label: string; value: string; }[];
  AvailabilityArray: { label: string; value: string; }[];
  securityArray: { label: string; value: string; }[];
  minute: { label: string; value: string; }[];
  hours: { label: string; value: string; }[];
  PurposeWebUrlArray: { label: string; value: string; }[];
  PurposeArray: { label: string; value: string; }[];
  CasesArray: any[] = [];
  Cases: any;
  personId: any;
  partyIdArray: any[] = [];
  SalesOpportunity: any;
  SalesOpportunityArray: any[] = [];
  TeamMemberByPartyId: any;
  addTeamMember: FormGroup;
  Role: any;
  RoleArray:  any[] = [];
  teamMember: any;
  teamMemberArray:  any[] = [];
  showLogCall: boolean;
  productLogCall: any;
  logTaskForm: any;
  logTaskCallPartnerId: any;
  logEmailForm: FormGroup;
  LogTaskEmail: any;
  showLogEmail: boolean;
  productLogEmail: any;
  allCatalogRequestData: any;
  productCatalog: any;
  accountDataNote: any;
  noteName: any;
  note: any;
  ClassificationBookmark: any;
  ClassificationBookmarkArray: any[]=[];
  bookmarksUploadPartner: any;
  fileUpload: FormGroup;
  addBookmark: FormGroup;
  showBookmark: boolean;
  showUpload: boolean;
  productBook: any;
  BookUpload: any;
  fileURL: any;
  bookMarksUrlPartner: any;
  NotePartnerList: any;
  addNote: FormGroup;
  showNote: boolean;
  noteProduct: any;
  paymentMethods: any[];
  billingAccounts: any[];
  isAdditionalDetail: boolean;
  isJobDetail: boolean;
  relatedContact: any;
  accountName: any;
  accountDetails: any;
  contactInformation: any[];
  showTelecomNumber: boolean;
  productTelecomNumber: any;
  addPhone: FormGroup;
  phoneNumberPartnerList: any;
  postalAddressPartnerList: any;
  showPostalAddress: boolean;
  addAddress: any;
  productPostalAddress: any;
  postalAddressPurpose: any;
  postalAddressPurposeArray: any[]=[];
  phoneNumberPurposeArray: any[]=[];
  phoneNumberPurpose: any;
  constructor(
    readonly accountsService: AccountsService,
    public detailService: detailedContactsService,
    readonly HeaderService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    @Inject(DOCUMENT) readonly document: Document,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly activatedRoute: ActivatedRoute,
    readonly leadPersonalLeadServices: LeadPersonalLeadServices,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    public createContact: CreateContactsService,
    readonly formBuilder: FormBuilder,
    readonly myContactsService: MyContactsService,
    private _location: Location,
  ) {
    this.paymentMethods = [];
    this.billingAccounts = [];
    this.contactInformation = [];
    this.isAdditionalDetail = false;
    this.isJobDetail = false;
    this.updateContact = this._FormBuilder.group({

      partyId: [''],
      externalId: [''],

      firstName: [''],
      lastName: [''],
      comments: [''],

      statusId: [''],
      postalCode: [''],
      emailAddress: [''],
      address1: [''],
      countryGeoId: [''],
      stateProvinceGeoId: [''],
      city: ['']



    });
    this.createCasesForm = this._FormBuilder.group({
      opportunityName: "",
      stage: "",
      opportunityAmount: "",
      estimatedCloseDate: "",
    })
    this.createCasesNewForm = this._FormBuilder.group({
      priority: "",
      reason: "",
      status: "",
      subject: "",
      type: ""
    });
    this.createAccountsForm = this._FormBuilder.group({
      accountName: "",
      city: "",
      email: "",
      primaryPhone: "",
    })
    this.createNotesForm = this._FormBuilder.group({
      noteInfo: "",
      createdBy: "",
      noteDateTime: "",
    })
    this.teamMemberForm = this._FormBuilder.group({
      newTeamMember: "",
      role: "",
    })
    this.createOrderForm = this._FormBuilder.group({
      amount: "",
      customer: "",
      orderDate: "",
      orderName: "",
      po: "",
      shipBeforeDate: "",
      status: ""
    })
    this.createCatalogForm = this._FormBuilder.group({
      activity: "",
      address: "",
      date: "",
      fullFilled: "",
      takenBy: ""
    });
    this.taskForm = this._FormBuilder.group({
      Subject: [''],
      Security: [''],
      RelatedParty: [''],
      Opportunity: [''],
      Case: [''],
      Purpose: [''],
      EndDateandTime: [''],
      Duration: [''],
      StartDateandTime: [''],
      Description: [''],
      Availability: [''],
      IgnoreSchedulingConflicts: [''],
      CompletionDateandTime: [''],
      CompletionDateHour: [''],
      CompletionAmPm: [''],
      CompletionDateMinutes: [''],
      StartDateAmPm: [''],
      StartDateandHour: [''],
      StartDateMinutes: [''],
    });
    this.eventForm = this._FormBuilder.group({
      Name: [''],
      Security: [''],
      RelatedParty: [''],
      Opportunity: [''],
      Case: [''],
      ScheduledStart: [''],
      EndDateandTime: [''],
      Duration: [''],
      Location: [''],
      Description: [''],
      Availability: [''],
      IgnoreSchedulingConflicts: [''],
      CompletionDateandTime: [''],
      CompletionDateHour: [''],
      CompletionAmPm: [''],
      CompletionDateMinutes: [''],
      StartDateAmPm: [''],
      StartDateandHour: [''],
      StartDateandTime: [''],
      StartDateMinutes: [''],
    });
    this.PurposeWebUrlArray = [
      {
        label: 'Primary Website URL',
        value: 'PRIMARY_WEB_URL'
      },
      {
        label: 'iCalendar URL',
        value: 'ICAL_URL'
      },

    ];
    this.PurposeArray = [
      {
        label: 'Email',
        value: 'WEPT_TASK_EMAIL'
      },
      {
        label: 'Phone Call',
        value: 'WEPT_TASK_PHONE_CALL'
      },

    ];
    this.AvailabilityArray = [
      {
        label: 'Available',
        value: 'WEPA_AV_AVAILABLE'
      },
      {
        label: 'Busy',
        value: 'WEPA_AV_BUSY'
      },
      {
        label: 'Away',
        value: 'WEPA_AV_AWAY'
      },
    ];
    this.securityArray = [
      {
        label: 'General, public access',
        value: 'WES_PUBLIC'
      },
      {
        label: 'Very restricted, confidential access',
        value: 'WES_CONFIDENTIAL'
      },

    ];
    this.hours = [
      {
        label: '1',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9',
        value: '9'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },

    ];
    this.minute = [
      {
        label: '00',
        value: '00'
      },
      {
        label: '01',
        value: '01'
      },
      {
        label: '02',
        value: '02'
      },
      {
        label: '03',
        value: '03'
      },
      {
        label: '04',
        value: '04'
      },
      {
        label: '05',
        value: '05'
      },
      {
        label: '06',
        value: '06'
      },
      {
        label: '07',
        value: '07'
      },
      {
        label: '08',
        value: '08'
      },
      {
        label: '09',
        value: '09'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },
      {
        label: '13',
        value: '13'
      },
      {
        label: '14',
        value: '14'
      },
      {
        label: '15',
        value: '15'
      },
      {
        label: '16',
        value: '16'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '19',
        value: '19'
      },
      {
        label: '20',
        value: '20'
      },
      {
        label: '21',
        value: '21'
      },
      {
        label: '22',
        value: '22'
      },
      {
        label: '23',
        value: '23'
      },
      {
        label: '24',
        value: '24'
      },
      {
        label: '25',
        value: '25'
      },
      {
        label: '26',
        value: '26'
      },
      {
        label: '27',
        value: '27'
      },
      {
        label: '28',
        value: '28'
      },
      {
        label: '29',
        value: '29'
      },
      {
        label: '30',
        value: '30'
      },
      {
        label: '31',
        value: '31'
      },
      {
        label: '32',
        value: '32'
      },
      {
        label: '33',
        value: '33'
      }, {
        label: '34',
        value: '34'
      },
      {
        label: '35',
        value: '35'
      }, {
        label: '36',
        value: '36'
      },
      {
        label: '37',
        value: '37'
      }, {
        label: '38',
        value: '38'
      },
      {
        label: '39',
        value: '39'
      },
      {
        label: '40',
        value: '40'
      }, {
        label: '41',
        value: '41'
      },
      {
        label: '42',
        value: '42'
      },
      {
        label: '43',
        value: '43'
      }, {
        label: '44',
        value: '44'
      },
      {
        label: '45',
        value: '45'
      },
      {
        label: '46',
        value: '46'
      }, {
        label: '47',
        value: '47'
      },
      {
        label: '48',
        value: '48'
      },
      {
        label: '49',
        value: '49'
      }, {
        label: '50',
        value: '50'
      },
      {
        label: '51',
        value: '51'
      },
      {
        label: '52',
        value: '52'
      },
      {
        label: '53',
        value: '53'
      }, {
        label: '54',
        value: '54'
      }, {
        label: '55',
        value: '55'
      }, {
        label: '56',
        value: '56'
      }, {
        label: '57',
        value: '57'
      },
      {
        label: '58',
        value: '58'
      },
      {
        label: '59',
        value: '59'
      }


    ];
    this.amPM = [
      {
        label: 'AM',
        value: 'AM'
      },
      {
        label: 'PM',
        value: 'PM'
      },
    ];
    this.OutboundArray = [
      {
        label: 'Outbound',
        value: 'Y'
      },
      {
        label: 'Inbound',
        value: 'N'
      },

    ];
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];
    this.addTeamMember = this._FormBuilder.group({
      newTeamMember: [''],
      role: [''],
    });

    this.logTaskForm = this._FormBuilder.group({
      Type: [''] ,
      Outbound: [''] ,
      InternalParty: [''] ,
      ExternalParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Subject: [''] ,
      Message: [''] ,
      StartDateandTime: [''] ,
      hourFrom: [''] ,
      Duration: [''] ,
      minuteFrom: [''] ,
      amPmFrom: [''] ,
    });
    this.logEmailForm = this._FormBuilder.group({
      Type: [''] ,
      Outbound: [''] ,
      InternalParty: [''] ,
      ExternalParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Subject: [''] ,
      Message: [''] ,
      StartDateandTime: [''] ,
      Duration: [''] ,
      hourFrom: [''] ,
      minuteFrom: [''] ,
      amPmFrom: [''] 
    });
  }

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    /*newdoe2*/
    let contactPartyIdLocal;
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate = params["id"];
      if (this.partyIdForCreate) {
        contactPartyIdLocal = this.partyIdForCreate;
      }
      this.contactName = params["contactName"];
      if (this.partyIdForCreate) {
        this.showCreateBtn = true;
      }
    })
    this.activatedRoute.queryParams.subscribe(params => {
      this.noteId = params["noteId"];
      this.partyId = params["id"];
      if (this.partyId) {
        contactPartyIdLocal = this.partyId;
      }
      this.contactName = params["contactName"];
      this.noteName = params["noteName"];
      this.noteForUpdate = params["note"];
      this.note = params["note"];

      if (this.noteId && this.partyId) {
        this.showUpdateBtn = true;
      }
    });
    this.contactPartyId = contactPartyIdLocal;
    this.fileUploadFormA = this.formBuilder.group({
      noteName: [''], note: ['']
    });
    /*newdoe2*/

    /*newcode1*/
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params["id"]
    });
    this.getContactList();
    this.fileUploadForm = this.formBuilder.group({
      accountPartyId: [''],
      comments: [''],
      contactPartyId: [''],
      partyId: ['']
    });
    this.addBookmark = this._FormBuilder.group({
      classification: [''],
      url: [''],
      description: [''],
    });
    this.fileUpload = this._FormBuilder.group({
      classification: [''],
      file: [''],
      description: [''],
    });
    this.addNote = this._FormBuilder.group({
      note: ['']
    });
    this.addAddress = this._FormBuilder.group({
      purpose: [''],
      toName: [''],
      attentionName: [''],
      addressLine1: [''],
      addressLine2: [''],
      city: [''],
      state: [''],
      country: [''],
      directions: [''],
      solicitation: [''],
      postalCode: [''],
      postalCodeExt: [''],
    });
    this.addPhone = this._FormBuilder.group({
      purpose: [''],
      phone1: [''],
      phone2: [''],
      phone3: [''],
      ext: [''],
      personToAsk: [''],
      solicitation: [''],
    });
    /*newcode1*/



    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params['id'];
    });

    this.getStatusCrm();
    this.getCountryList();

    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
      partyContentTypeId: new FormControl('')
    });
    this.getDetailContact();
    this.getAccount();
    this.getNoteList();
    this.getAccordian();
    this.getPartyContent();
    this.getPartyList();
    this.getPartyContentsList();
    this.getAllContactRelatedAccount();
    this.getAccounts();
    this.getAccountByAccountId();
    this.getOrderByPartyId();
    this.getOpportunity();
    this.getPendingActivityEventPartner();
    this.getPendingActivityTaskPartner();
    this.getCases();
    this.getPartyId();
    this.getRole();
    this.getLogTaskEmailPartnerId();
    this.getLogTaskCallPartnerId();
    this.getTeamMember();
    this.getTeamMemberByPartyId();
    this.getCatalogRequestByPartyId();
    this.getBookmarksUploadPartner();
    this.getAccountNote();
    this.getClassificationBookmark();
    this.getBookMarksUrlIdPartner();
    this.getPostalAddressPartner();
    this.getNotePartnerBId();
    this.accountDetail();
    this.getSalesOpportunity();
    this.getPhoneNumberPurpose();
    this.getPhoneNumberPurpose();
    this.getPhoneNumberPurpose();
    this.getPostalAddressPurpose();




  }
  getOpportunity() {
    this.spinner.show();
    this.myContactsService.getOpportunityContact(this.partyId).subscribe(res => {
      this.allOpportunityData = res.data[0].getOpportunity;
      this.spinner.hide();
    })
  }

  deleteOrder(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "orderId": product.orderId,
          "partnerId": product.partnerId
        }
        this.accountsService.deleteOrder(formData).subscribe(res => {
          this.getOrderByPartyId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateOrder() {
    this.spinner.show();
    const fromActualdate = this.createOrderForm.get('orderDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const shipBeforeDate = this.createOrderForm.get('shipBeforeDate').value;
    this.shipBeforeDate = this.datePipe.transform(shipBeforeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "amount": this.createOrderForm.value.amount,
      "customer": this.createOrderForm.value.customer,
      "orderDate": this.actualfromdate,
      "orderId": this.productOrder.orderId,
      "orderName": this.createOrderForm.value.orderName,
      "partnerId": this.productOrder.partnerId,
      "po": this.createOrderForm.value.po,
      "shipBeforeDate": this.shipBeforeDate,
      "status": this.createOrderForm.value.status
    }
    this.accountsService.updateOrder(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton15.nativeElement.click();
        this.createOrderForm.reset();
        this.getOrderByPartyId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateOrderPatch(product) {
    this.spinner.show();
    this.show = true;
    this.productOrder = product;
    setTimeout(() => {
      const formValue = this.createOrderForm;
      formValue.patchValue({
        amount: product.amount,
        customer: product.customer,
        orderDate: this.datePipe.transform(product.orderDate, "yyyy-MM-dd"),
        orderName: product.orderName,
        po: product.po,
        shipBeforeDate: this.datePipe.transform(product.shipBeforeDate, "yyyy-MM-dd"),
        status: product.status
      })
    }, 2000);
    this.spinner.hide();
  }
  createOrder() {
    this.spinner.show();
    const fromActualdate = this.createOrderForm.get('orderDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const shipBeforeDate = this.createOrderForm.get('shipBeforeDate').value;
    this.shipBeforeDate = this.datePipe.transform(shipBeforeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "amount": this.createOrderForm.value.amount,
      "customer": this.createOrderForm.value.customer,
      "orderDate": this.actualfromdate,
      "orderId": this.partyId,
      "orderName": this.createOrderForm.value.orderName,
      "partnerId": "",
      "po": this.createOrderForm.value.po,
      "shipBeforeDate": this.shipBeforeDate,
      "status": this.createOrderForm.value.status
    }
    this.accountsService.createOrder(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton15.nativeElement.click();
        this.createOrderForm.reset();
        this.getOrderByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  } 
  getOrderByPartyId() {
    this.spinner.show();
    this.accountsService.getOrderByPartyId(this.partyId).subscribe(res => {
      this.allOrderByData = res.data[0].getOrderByPartyId;
      this.spinner.hide();
    })
  }
  deleteOpportunity(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "opportunityId": product.opportunityId,
          "partnerId": product.partnerId
        }
        this.myContactsService.deleteOpportunity(formData).subscribe(res => {
          this.getOpportunity();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateOpportunity() {
    this.spinner.show();
    const fromActualdate = this.createCasesForm.get('estimatedCloseDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "closeDate": this.actualfromdate,
      "opportunityAmount": this.createCasesForm.value.opportunityAmount,
      "opportunityId": this.productOpp.opportunityId,
      "opportunityName": this.createCasesForm.value.opportunityName,
      "partnerId": this.productOpp.partnerId,
      "stage": this.createCasesForm.value.stage
    }
    this.myContactsService.updateOpportunityContact(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.createCasesForm.reset();
        this.getOpportunity();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateOpportunityPatch(product) {
    this.spinner.show();
    this.show = true;
    this.productOpp = product;
    setTimeout(() => {
      const formValue = this.createCasesForm;
      formValue.patchValue({
        opportunityName: product.opportunityName,
        stage: product.stage,
        opportunityAmount: product.opportunityAmount,
        estimatedCloseDate: this.datePipe.transform(product.closeDate, "yyyy-MM-dd"),
      })
    }, 2000);
    this.spinner.hide();
  }
  createOpportunities() {
    this.spinner.show();
    const fromActualdate = this.createCasesForm.get('estimatedCloseDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "closeDate": this.actualfromdate,
      "opportunityAmount": this.createCasesForm.value.opportunityAmount,
      "opportunityId": this.partyId,
      "opportunityName": this.createCasesForm.value.opportunityName,
      "partnerId": "",
      "stage": this.createCasesForm.value.stage
    }
    this.myContactsService.createOpportunities(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.createCasesForm.reset();
        this.getOpportunity();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }


  getPostalAddressPurpose() {
    this.spinner.show();
    this.myContactsService.getPostalAddressPurpose().subscribe(res => {
      this.postalAddressPurpose = res.data[0].data;
      this.spinner.hide();
      for (const value of this.postalAddressPurpose) {
        this.postalAddressPurposeArray.push({
          label: value.description,
          value: value.contactMechPurposeTypeId
        })
      }
    })

  }
  getPhoneNumberPartner() {
    this.spinner.show();
    this.myContactsService.getPhoneNumberPartner(this.partyId).subscribe(res => {
      this.phoneNumberPartnerList = res.data[0].getPhoneNumberPartner;
      this.spinner.hide();

    })
  }
  UpdatePhoneNumberPartner(){
    this.spinner.show();
    const requestData = {
      "allowSolicitation": this.addPhone.value.solicitation ,
      "areaCode":this.addPhone.value.phone2 ,
      "askForName": this.addPhone.value.personToAsk ,
      "contactMechPurposeTypeId": this.addPhone.value.purpose ,
      "contactMechTypeId": "TELECOM_NUMBER" ,
      "contactNumber": this.addPhone.value.phone3 ,
      "countryCode": this.addPhone.value.phone1 ,
      "donePage": "" ,
      "errorPage": "",
      "extension": this.addPhone.value.ext ,
      "partnerId": this.partyId ,
      "preContactMechTypeId": "TELECOM_NUMBER",
      "telecomNumberId": this.productTelecomNumber.telecomNumberId,


    }
    this.myContactsService.UpdatePhoneNumberPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.addPhone.reset();
       this.getPhoneNumberPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
   
  deletePartnerTelecomNumber(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partyId,
          "telecomNumberId": product.telecomNumberId
        }
        this.myContactsService.deletePartnerTelecomNumber(formData).subscribe(res => {
        this.getPhoneNumberPartner(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deletePostalAddressPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partyId,
          "postalAddressId":product.postalAddressId
        }
        this.myContactsService.deletePostalAddressPartner(formData).subscribe(res => {
        this.getPostalAddressPartner(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updatePostalAddress(product){
    this.showPostalAddress = true;
    this.productPostalAddress = product;

    setTimeout(()=>{
      const formValue = this.addAddress;
      formValue.patchValue({
        purpose: product.contactMechPurposeTypeId,
        toName: product.toName,
        attentionName: product.attnName,
        addressLine1: product.address1,
        addressLine2: product.address2,
        city: product.city,
        state: product.stateProvinceGeoId,
        country: product.countryGeoId,
        directions: product.directions,
        solicitation: product.allowSolicitation,
        postalCode: product.postalCode,
        postalCodeExt: product.postalCodeExt,
      }
      )
    },2000
    );
  }
  resetAddress(){
    this.showPostalAddress = false;
    this.addAddress.reset();
  }
  getPostalAddressPartner() {
    this.spinner.show();
    this.myContactsService.getPostalAddressPartner(this.partyId).subscribe(res => {
      this.postalAddressPartnerList = res.data[0].getPostalAddressPartner;
      this.spinner.hide();

    })
  }
  createPhoneNumberPartner(){
    this.spinner.show();
    const requestData = {
      "allowSolicitation": this.addPhone.value.solicitation ,
      "areaCode":this.addPhone.value.phone2 ,
      "askForName": this.addPhone.value.personToAsk ,
      "contactMechPurposeTypeId": this.addPhone.value.purpose ,
      "contactMechTypeId": "TELECOM_NUMBER" ,
      "contactNumber": this.addPhone.value.phone3 ,
      "countryCode": this.addPhone.value.phone1 ,
      "donePage": "" ,
      "errorPage": "",
      "extension": this.addPhone.value.ext ,
      "partnerId": this.partyId ,
      "preContactMechTypeId": "TELECOM_NUMBER",
      "telecomNumberId": ""

    }
    this.myContactsService.createPhoneNumberPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.addPhone.reset();
        this.getPhoneNumberPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getPhoneNumberPurpose() {
    this.spinner.show();
    this.myContactsService.getPhoneNumberPurpose().subscribe(res => {
      this.phoneNumberPurpose = res.data[0].data;
      this.spinner.hide();
      for (const value of this.phoneNumberPurpose) {
        this.phoneNumberPurposeArray.push({
          label: value.description,
          value: value.contactMechPurposeTypeId
        })
      }
    })

  }
  resetTelecomNumber(){
    this.showTelecomNumber = false;
    this.addPhone.reset();
  }
  UpdatePostalAddressPartner(){
    this.spinner.show();
    const requestData = {
    
      "address1": this.addAddress.value.addressLine1 ,
  "address2":  this.addAddress.value.addressLine2 ,
  "allowSolicitation":  this.addAddress.value.solicitation ,
  "attnName":  this.addAddress.value.attentionName ,
  "city":  this.addAddress.value.city ,
  "contactMechPurposeTypeId":  this.addAddress.value.purpose ,
  "contactMechTypeId":  "POSTAL_ADDRESS",
  "countryGeoId":  this.addAddress.value.country ,
  "directions":  this.addAddress.value.directions ,
  "donePage": "" ,
  "errorPage":  "",
  "partnerId":  this.partyId,
  "postalAddressId":   this.productPostalAddress.postalAddressId,
  "postalCode":  this.addAddress.value.postalCode ,
  "postalCodeExt":  this.addAddress.value.postalCodeExt ,
  "preContactMechTypeId":"POSTAL_ADDRESS" ,
  "stateProvinceGeoId":  this.addAddress.value.state ,
  "toName":  this.addAddress.value.toName ,

    }
    this.myContactsService.UpdatePostalAddressPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addAddress.reset();
        this.getPostalAddressPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  
createPostalAddressPartner(){
    this.spinner.show();
    const requestData = {
     
  "address1": this.addAddress.value.addressLine1 ,
  "address2":  this.addAddress.value.addressLine2 ,
  "allowSolicitation":  this.addAddress.value.solicitation ,
  "attnName":  this.addAddress.value.attentionName ,
  "city":  this.addAddress.value.city ,
  "contactMechPurposeTypeId":  this.addAddress.value.purpose ,
  "contactMechTypeId":  "POSTAL_ADDRESS",
  "countryGeoId":  this.addAddress.value.country ,
  "directions":  this.addAddress.value.directions ,
  "donePage": "" ,
  "errorPage":  "",
  "partnerId":  this.partyId,
  "postalAddressId":  "",
  "postalCode":  this.addAddress.value.postalCode ,
  "postalCodeExt":  this.addAddress.value.postalCodeExt ,
  "preContactMechTypeId":"POSTAL_ADDRESS" ,
  "stateProvinceGeoId":  this.addAddress.value.state ,
  "toName":  this.addAddress.value.toName ,
}
    this.myContactsService.createPostalAddressPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addAddress.reset();
        this.getPostalAddressPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateTelecomNumber(product){
    this.showTelecomNumber = true;
    this.productTelecomNumber = product;

    setTimeout(()=>{
      const formValue = this.addPhone;
      formValue.patchValue({
        purpose: product.contactMechPurposeTypeId,
        phone1:  product.countryCode,
        phone2:  product.areaCode,
        phone3:  product.contactNumber,
        ext:  product.extension,
        personToAsk: product.askForName,
        solicitation:  product.allowSolicitation,
      }
      )

    },2000
    );

  }
  accountDetail() {
    this.spinner.show();
    this.accountsService.getAccountById(this.partyId).subscribe(res => {


      this.accountDetails = res.data;
      this.spinner.hide();
      this.isAdditionalDetail = res.data.additionalInformation.infoType ? true : false;
      this.isJobDetail = res.data.jobInformation.jobStatus ? true : false;
      res.data.contactInformation.pop();
      this.contactInformation = res.data.contactInformation;

      this.relatedContact = res.data.relatedContacts;
      this.accountName = res.data.groupName;

      this.addr = res.data.address.address1 + ' ' + res.data.address.city + ' ' + res.data.address.postalCode + ' ' + res.data.address.countryGeoId;
      this.emailId = res.data.email.infoString;
      this.phoneNumber = res.data.telenumber.countryCode + ' ' + res.data.telenumber.areaCode + ' ' + res.data.telenumber.contactNumber

    })

  }
  updateData(partyId: string, type: string) {
    switch (type) {
      case 'POSTAL_ADDRESS':
        this.router.navigate(['/crm/accounts/create-postal-address'], { queryParams: { partyId: this.partyId, contactMechId: partyId } })
        break;

      case 'TELECOM_NUMBER':
        this.router.navigate(['/crm/accounts/create-contact'], { queryParams: { partyId: this.partyId, contactMechId: partyId } })
    }


  }
  resetNote(){
    this.showNote = false ;
    this.addNote.reset();

  }
  updateNotes(product){
    this.showNote = true;
    this.noteProduct = product ;

    setTimeout(()=>{
      const formValue = this.noteProduct;
      formValue.patchValue({
        note: product.noteInformation,
      }
      )

    },2000
    );

  }
  deleteNotePartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {

          "noteId": product.noteId,
          "partnerId": product.partyId,
        }
        this.myContactsService.deleteNotePartner(formData).subscribe(res => {
        this.getNotePartnerBId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  UpdateNotePartner() {
    this.spinner.show();
    const requestData = {
      "noteId": this.noteProduct.noteId  ,
      "noteInformation": this.addNote.value.note,
      "partnerId": this.partyId
    }
    this.myContactsService.UpdateNotePartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton10.nativeElement.click();
        this.addNote.reset();
        this.getNotePartnerBId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createNotePartner() {
    this.spinner.show();
    const requestData = {
      "noteId": "",
      "noteInformation": this.addNote.value.note,
      "partnerId": this.partyId
    }
    this.myContactsService.createNotePartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton10.nativeElement.click();
        this.addNote.reset();
        this.getNotePartnerBId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getNotePartnerBId() {
    this.spinner.show();
    this.myContactsService.getNotePartnerBId(this.partyId).subscribe(res => {
      this.NotePartnerList = res.data[0].getNotePartnerAll;
      this.spinner.hide();

    })
  }
  getClassificationBookmark(){
    this.spinner.show();
    this.myContactsService.getClassificationBookmark().subscribe(res=>{
      this.ClassificationBookmark = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ClassificationBookmark) {
        this.ClassificationBookmarkArray.push({
          label: value.description,
          value: value.classificationEnumId
        })
      }
    })
  }
  deleteBookmarksUploadPartner(bookmarksUploadId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       
        this.myContactsService.deleteBookmarksUploadPartner(bookmarksUploadId).subscribe(res => {
          this.getBookmarksUploadPartner(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateBookmarksUploadPartner(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);
    this.myContactsService.updateBookmarksUploadPartner(formData,this.BookUpload.bookmarksUploadId,this.fileUpload.value.classification,
    this.fileUpload.value.description,this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton9.nativeElement.click();
        this.fileUpload.reset();
        this.getBookmarksUploadPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }  
  updateBookmarkUpload(){
    this.onSubmitFile();

    setTimeout(() => {
      this.updateBookmarksUploadPartner();
    }, 2000);
   
  }
  getBookmarksUploadPartner() {
    this.spinner.show();
    this.myContactsService.getBookmarksUploadPartner(this.partyId).subscribe(res => {
      this.bookmarksUploadPartner = res.data[0].getBookmarksUploadPartner;
      this.spinner.hide();
    })
  }
  createBookmarksUploadPartner(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.myContactsService.createBookmarksUploadPartner(formData,this.fileUpload.value.classification,
      this.fileUpload.value.description,this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton9.nativeElement.click();
        this.fileUpload.reset();
        this.getBookmarksUploadPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }   
  fileProgressCom(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }
  onSubmitFile() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.accountsService.attachFile(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.responseMessage);
        this.fileURL = res.data.URL;
      }
    }, (err) => {
      this.toastr.error(err.message);
      this.spinner.hide();
    })

  }

  createBookmarkUpload(){
    this.onSubmitFile();

    setTimeout(() => {
      this.createBookmarksUploadPartner();
    }, 2000);
   
  }
  getBookMarksUrlIdPartner() {
    this.spinner.show();
    this.myContactsService.getBookMarksUrlIdPartner(this.partyId).subscribe(res => {
      this.bookMarksUrlPartner = res.data[0].getBookMarksUrlIdPartner;
      this.spinner.hide();
    })
  }
  deleteBookMarksUrlPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "bookmarksUrlId": product.bookmarksUrlId,
          "partnerId": this.partyId
         
        }
        this.myContactsService.deleteBookMarksUrlPartner(formData).subscribe(res => {
          this.getBookMarksUrlIdPartner(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  resetUpload(){
    this.showUpload = false ;
    this.fileUpload.reset();
  }
  resetBookUrl(){
    this.showBookmark = false ;
    this.addBookmark.reset();
  }
  updateBook(product) {
    this.spinner.show();
    this.showBookmark = true ;
    this.productBook = product ;
   
      setTimeout(() => {
        const formValue = this.addBookmark;
        formValue.patchValue({
          classification: product.classification,
          url: product.urlAddress,
          description: product.description,
        })
      }, 2000);
      this.spinner.hide();
  }
  UpdateBookMarksUrlPartner(){
    this.spinner.show();
    const requestData = {
      "partnerId": this.partyId,
      "bookmarksUrlId":this.productBook.bookmarksUrlId,
      "classification": this.addBookmark.value.classification,
     
      "description":this.addBookmark.value.description,
      "urlAddress": this.addBookmark.value.url,

    }
    this.myContactsService.UpdateBookMarksUrlPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.addBookmark.reset();
        this.getBookMarksUrlIdPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createBookMarksUrlPartner(){
    this.spinner.show();
    const requestData = {
    
      "bookmarksUrlId":this.addBookmark.value.description,
      "classification": this.addBookmark.value.classification,
      "partnerId": this.partyId,
      "description":this.addBookmark.value.description,
      "urlAddress": this.addBookmark.value.url,
    }
    this.myContactsService.createBookMarksUrlPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("BookmarksUrl created successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.addBookmark.reset();
        this.getBookMarksUrlIdPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  updateBookUpload(product) {
    this.spinner.show();
    this.showUpload = true ;
    this.BookUpload = product ;
   
      setTimeout(() => {
        const formValue = this.fileUpload;
        formValue.patchValue({
          classification: product.classification,
          file: product.url,
          description: product.description,
        })
      }, 2000);
      this.spinner.hide();
  }
  onSubmitbtn() {
    this.spinner.show();
    const form = { ...this.fileUploadForm.value }
    this.myContactsService.createContactNote(form, this.partyIdForCreate).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.fileUploadForm.reset();
        this.getAccountNote();
        // this.router.navigate(['/crm/accounts/account-details'],{queryParams:{partyId:this.partyIdForCreate}})

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )

  }
  navigate() {
    this.router.navigate(["/crm/accounts/account-details"], { queryParams: { partyId: this.partyId } });
  }

  navigateFromCreate() {
    this.router.navigate(["/crm/accounts/account-details"], { queryParams: { partyId: this.partyIdForCreate } });
  }
  onUpdateNote() {
    this.spinner.show();
    const form = { ...this.fileUploadForm.value }
    this.myContactsService.updateContactNote(form, this.noteId).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.fileUploadForm.reset();
        this.getAccountNote();

        //this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }


  getAccountNote() {
    this.spinner.show();
    this.accountsService.getContactNotes(this.partyId).subscribe(res => {
      this.accountDataNote = res.data;
      this.spinner.hide();
    });


  }
  UpdateCatalogRequestPatch(product) {
    this.spinner.show();
    this.show = true ;
    this.productCatalog = product ;
      setTimeout(() => {
        const formValue = this.createCatalogForm;
        formValue.patchValue({
            activity: product.activity,
            address: product.address,
            date: this.datePipe.transform(product.date,"yyyy-MM-dd"),
            fullFilled: product.fullFilled,
            takenBy: product.takenBy
        })
      }, 2000);
      this.spinner.hide();
  }
  deleteCatalogRequest(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "catalogId": product.catalogId,
          "partnerId": product.partnerId
         
        }
        this.accountsService.deleteCatalogRequest(formData).subscribe(res => {
        this.getCatalogRequestByPartyId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getCatalogRequestByPartyId() {
    this.spinner.show();
    this.accountsService.getCatalogRequestByPartyId(this.partyId).subscribe(res => {
      this.allCatalogRequestData = res.data[0].getCatalogRequestById;
      this.spinner.hide();
    })
  }
  resetLogEmail(){
    this.showLogEmail = false ;
    this.logEmailForm.reset();
  }
  updateLogEmail(product) {
    this.spinner.show();
    this.showLogEmail = true ;
    this.productLogEmail = product ;
   
      setTimeout(() => {
        const formValue = this.logEmailForm;
        formValue.patchValue({
          Type: product.workEffortPurposeTypeId ,
          Outbound:  product.inboundAndOutBound ,
          InternalParty:  product.internalPartyId ,
          ExternalParty:  product.externalPartyId ,
          Opportunity:  product.salesOpportunityId ,
          Case:  product.partnerId ,
          Subject:  "",
          Message:  "",
          StartDateandTime:  product.actualStartDateDate ,
          Duration:  product.duration ,
          hourFrom:  product.actualStartDateHour ,
          minuteFrom:  product.actualStartDateMinutes ,
          amPmFrom:  product.actualStartDateAmPm ,
        })
      }, 2000);
      this.spinner.hide();
  }
  createLogTaskEmailPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logEmailForm.value.amPmFrom,
      "actualStartDateCompositeType":"Timestamp",
      "actualStartDateDate":this.datePipe.transform(this.logEmailForm.value.StartDateandTime, "yyyy-MM-dd"),
      "actualStartDateHour": this.logEmailForm.value.hourFrom,
      "actualStartDateMinutes": this.logEmailForm.value.minuteFrom,
      "caseId": "",
      "completionStartDateAmPm": "",
      "completionStartDateCompositeType": "",
      "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
      "completionStartDateHour": "24",
      "completionStartDateMinutes": "60",
      "content": this.logEmailForm.value.Message ,
      "currentStatusId": "EXST_DEFINED",
      "duration":this.logEmailForm.value.Duration,
      "externalPartyId": this.logEmailForm.value.ExternalParty,
      "inboundAndOutBound": this.logEmailForm.value.Outbound,
      "internalPartyId": this.logEmailForm.value.InternalParty,
      "logTaskEmailId": "",
      "salesOpportunityId":this.logEmailForm.value.Opportunity,
      "workEffortName":this.logEmailForm.value.Subject,
      "workEffortPurposeTypeId":"WEPT_TASK_EMAIL",
      "partnerId":  this.partyId
    }

    this.myContactsService.createLogTaskEmailPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton12.nativeElement.click();
        this.logEmailForm.reset();
        this.getLogTaskEmailPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  UpdateLogTaskEmailPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logEmailForm.value.amPmFrom,
      "actualStartDateCompositeType":"Timestamp",
      "actualStartDateDate":this.datePipe.transform(this.logEmailForm.value.StartDateandTime, "yyyy-MM-dd"),
      "actualStartDateHour": this.logEmailForm.value.hourFrom,
      "actualStartDateMinutes": this.logEmailForm.value.minuteFrom,
      "caseId": "",
      "completionStartDateAmPm": "",
      "completionStartDateCompositeType": "",
      "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
      "completionStartDateHour": "24",
      "completionStartDateMinutes": "60",
      "content": this.logEmailForm.value.Message ,
      "currentStatusId": "EXST_DEFINED",
      "duration":this.logEmailForm.value.Duration,
      "externalPartyId": this.logEmailForm.value.ExternalParty,
      "inboundAndOutBound": this.logEmailForm.value.Outbound,
      "internalPartyId": this.logEmailForm.value.InternalParty,
      "logTaskEmailId": this.productLogEmail.logTaskEmailId ,
      "salesOpportunityId":this.logEmailForm.value.Opportunity,
      "workEffortName":this.logEmailForm.value.Subject,
      "workEffortPurposeTypeId":"WEPT_TASK_EMAIL",
      "partnerId":  this.partyId,

    }

    this.myContactsService.UpdateLogTaskEmailPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton12.nativeElement.click();
        this.logEmailForm.reset();
        this.getLogTaskEmailPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getLogTaskEmailPartnerId() {
    this.spinner.show();
    this.myContactsService.getLogTaskEmailPartnerId(this.partyId).subscribe(res => {
      this.LogTaskEmail = res.data[0].getLogTaskEmailPartnerId;
      this.spinner.hide();
    })
  }
  deleteLogTaskEmailPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partyId,
          "logTaskEmailId": product.logTaskEmailId,
         
        }
        this.myContactsService.deleteLogTaskEmailPartner(formData).subscribe(res => {
          this.getLogTaskEmailPartnerId (); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  deleteLogTaskCallPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "logTaskCasesCallId": product.logTaskCasesCallId,
          "partnerId": this.partyId
         
        }
        this.myContactsService.deleteLogTaskCallPartner(formData).subscribe(res => {
          this.getLogTaskCallPartnerId(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getLogTaskCallPartnerId() {
    this.spinner.show();
    this.myContactsService.getLogTaskCallPartnerId(this.partyId).subscribe(res => {
      this.logTaskCallPartnerId = res.data[0].getLogTaskCallPartnerId;
      this.spinner.hide();
    })
  }
  UpdateLogTaskCallPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logTaskForm.value.amPmFrom,
  "actualStartDateCompositeType":"Timestamp",
  "actualStartDateDate": this.datePipe.transform(this.logTaskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss"),
  "actualStartDateHour": this.logTaskForm.value.hourFrom,
  "actualStartDateMinutes":  this.logTaskForm.value.minuteFrom,
  "caseId":"",
  "completionStartDateAmPm":"",
  "completionStartDateCompositeType":"",
  "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
  "completionStartDateHour":  "23",
  "completionStartDateMinutes":  "59",
  "content":this.logTaskForm.value.Message,
  "currentStatusId":"EXST_DEFINED",
  "duration":this.logTaskForm.value.Duration,
  "externalPartyId":this.logTaskForm.value.ExternalParty,
  "inboundAndOutBound":this.logTaskForm.value.Outbound,
  "internalPartyId":this.logTaskForm.value.InternalParty,
  "logTaskCasesCallId":this.productLogCall.logTaskCasesCallId ,
  "salesOpportunityId":this.logTaskForm.value.Opportunity,
  "workEffortName":this.logTaskForm.value.Subject,
  "workEffortPurposeTypeId": "WEPT_TASK_PHONE_CALL",
  "partnerId": this.partyId,
 
    }

    this.myContactsService.UpdateLogTaskCallPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.logTaskForm.reset();
        this.getLogTaskCallPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createLogTaskCallPartner(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logTaskForm.value.amPmFrom,
  "actualStartDateCompositeType":"Timestamp",
  "actualStartDateDate": this.datePipe.transform(this.logTaskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss"),
  "actualStartDateHour": this.logTaskForm.value.hourFrom,
  "actualStartDateMinutes":  this.logTaskForm.value.minuteFrom,
  "caseId":"",
  "completionStartDateAmPm":"",
  "completionStartDateCompositeType":"",
  "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
  "completionStartDateHour":  "23",
  "completionStartDateMinutes":  "59",
  "content":this.logTaskForm.value.Message,
  "currentStatusId":"EXST_DEFINED",
  "duration":this.logTaskForm.value.Duration,
  "externalPartyId":this.logTaskForm.value.ExternalParty,
  "inboundAndOutBound":this.logTaskForm.value.Outbound,
  "internalPartyId":this.logTaskForm.value.InternalParty,
  "logTaskCasesCallId":"",
  "salesOpportunityId":this.logTaskForm.value.Opportunity,
  "workEffortName":this.logTaskForm.value.Subject,
  "workEffortPurposeTypeId": "WEPT_TASK_PHONE_CALL",
  "partnerId": this.partyId,
 
    }

    this.myContactsService.createLogTaskCallPartner(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.logTaskForm.reset();
        this.getLogTaskCallPartnerId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateLogCall(product) {
    this.spinner.show();
    this.showLogCall = true ;
    this.productLogCall = product ;
   
      setTimeout(() => {
        const formValue = this.logTaskForm;
        formValue.patchValue({
          Type: product.workEffortPurposeTypeId ,
          Outbound:  product.inboundAndOutBound ,
          InternalParty:  product.internalPartyId ,
          ExternalParty:  product.externalPartyId ,
          Opportunity:  product.salesOpportunityId ,
          Case:  product.partnerId ,
          Subject:  "",
          Message:  "",
          StartDateandTime:  product.actualStartDateDate ,
          Duration:  product.duration ,
          hourFrom:  product.actualStartDateHour ,
          minuteFrom:  product.actualStartDateMinutes ,
          amPmFrom:  product.actualStartDateAmPm ,

         
        })
      }, 2000);
      this.spinner.hide();
  }
  resetLogCall(){
    this.showLogCall = false ;
    this.logTaskForm.reset();
  }
  getTeamMember() {
    this.spinner.show();
    this.myContactsService.getTeamMember().subscribe(res => {
      this.teamMember = res.data;
      this.spinner.hide();
      for (const value of this.teamMember) {
        this.teamMemberArray.push({
          label: value.data.name,
          value: value.data.partyId
        })
      }
    })
  }


  reset(){
    this.show = false;
    this.addTeamMember.reset();
  }
  update(product){
    this.show = true;
    this.updateTeamMem = product;
    setTimeout(() => {
      const formValue = this.addTeamMember;
      formValue.patchValue({
        newTeamMember:product.teamMemberPartyId,
        role: product.securityGroupId 
      })

    }, 2000);

  }
  getRole() {
    this.spinner.show();
    this.myContactsService.getRoleTeam().subscribe(res => {
      this.Role = res.data;
      this.spinner.hide();
      for (const value of this.Role) {
        this.RoleArray.push({
          label: value.roleValue,
          value: value.roleValue
        })
      }
    })
  }
  updateTeamMemberInviewTeam(){
    this.spinner.show();
    const requestData = {
      "autoId": this.updateTeamMem.autoId,
      "securityGroupId": this.updateTeamMem.securityGroupId,
    }

    this.myContactsService.updateTeamMemberInviewTeam(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addTeamMember.reset();
        this.getTeamMemberByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  addTeamMemberInviewTeam(){
    this.spinner.show();
    const requestData = {
      "accountTeamPartyId":  this.partyId,
      "autoId": "",
      "partyId":  this.partyId,
      "securityGroupId": this.addTeamMember.value.role ,
      "teamMemberPartyId": this.addTeamMember.value.newTeamMember 

    }
    this.myContactsService.addTeamMemberInviewTeam(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addTeamMember.reset();
       // this.getTeamByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getTeamMemberByPartyId() {
    this.spinner.show();
    this.myContactsService.getTeamMemberByPartyId(this.partyId).subscribe(res => {
      this.TeamMemberByPartyId = res.data.multiNode ;
      this.spinner.hide();
    })
  }

  deleteTeam(autoId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
      
        this.myContactsService.deleteTeamMemberInviewTeamByAutoId(autoId).subscribe(res => {
        this.getTeamMemberByPartyId();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  getSalesOpportunity() {
    this.spinner.show();
    this.myContactsService.getSalesOpportunity().subscribe(res => {
      this.SalesOpportunity = res.data[0].getSalesOpportunity;
      this.spinner.hide();
      for (const value of this.SalesOpportunity) {
        this.SalesOpportunityArray.push({
          label: value.opportunityName,
          value: value.salesOpportunityId
        })
      }
    })
  }
  getCases() {
    this.spinner.show();
    this.myContactsService.getCases().subscribe(res => {
      this.Cases = res.data[0].getCasesById;
      this.spinner.hide();
      for (const value of this.Cases) {
        this.CasesArray.push({
          label: value.custRequestName,
          value: value.custRequestId
        })
      }
    })
  }
  updatePendingActivityEvent(product) {
    this.spinner.show();
    this.showActivityEvent = true;
    this.productActivityEvent = product;

    setTimeout(() => {
      const formValue = this.eventForm;
      formValue.patchValue({
        Security: product.scopeEnumId,
        RelatedParty: product.relatedPartyId,
        Opportunity: product.lookupOpportunity,
        Case: "",
        Purpose: product.workEffortPurposeTypeId,
        Duration: product.duration,
        StartDateandTime: this.datePipe.transform(product.estimatedStartDateDate, "yyyy-MM-dd hh:mm:ss"),
        Description: product.description,
        Availability: product.availability,
        IgnoreSchedulingConflicts: "",
        CompletionDateandTime: this.datePipe.transform(product.estimatedCompletionDate, "yyyy-MM-dd hh:mm:ss"),
        CompletionDateHour: product.estimatedCompletionDateHour,
        CompletionAmPm: product.estimatedCompletionDateAmPm,
        CompletionDateMinutes: product.estimatedCompletionDateMinutes,
        StartDateAmPm: product.estimatedStartDateAmPm,
        StartDateandHour: product.estimatedStartDateHour,
        StartDateMinutes: product.estimatedStartDateMinutes,
        Name: product.workEffortName,
        Location: product.location,
      })
    }, 2000);
    this.spinner.hide();
  }
  deletePendingActivityTaskPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partyId,
          "pendingActivityTaskId": product.pendingActivityTaskId

        }
        this.myContactsService.deletePendingActivityTasks(formData).subscribe(res => {
          this.getPendingActivityTaskPartner();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deletePendingActivityEventPartner(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partyId,
          "pendingActivitiesId": product.pendingActivitiesId
        }
        this.myContactsService.deletePendingActivityEvent(formData).subscribe(res => {
          this.getPendingActivityEventPartner();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  UpdatePendingActivityEventPartner() {
    this.spinner.show();
    const requestData = {
      "availability": this.eventForm.value.Availability,
      "currentStatusId": "EVENT_SCHEDULED",
      "description": this.eventForm.value.Description,
      "duration": this.eventForm.value.Duration,
      "estimatedCompletionCompositeType": "Timestamp",
      "estimatedCompletionDate": this.datePipe.transform(this.eventForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss"),
      "estimatedCompletionDateAmPm": this.eventForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.eventForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.eventForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.eventForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedStartDateDate": this.datePipe.transform(this.eventForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss"),
      "estimatedStartDateHour": this.eventForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.eventForm.value.StartDateMinutes,
      "location": this.eventForm.value.Location,
      "lookupCase": "",
      "lookupOpportunity": this.eventForm.value.Opportunity,
      "pendingActivitiesId": this.productActivityEvent.pendingActivitiesId,
      "relatedPartyId": this.eventForm.value.RelatedParty,
      "scopeEnumId": this.eventForm.value.Security,
      "type": "",
      "workEffortName": this.eventForm.value.Name,
      "workEffortPurposeTypeId": "",
      "workEffortTypeId": "EVENT",
      "partnerId": this.partyId,
    }
    this.myContactsService.UpdatePendingActivityEvent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivityEvent Updated Successfully");
        this.spinner.hide();
        this.closebutton13.nativeElement.click();
        this.eventForm.reset();
        this.getPendingActivityEventPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  CreatePendingActivityEventPartner() {
    this.spinner.show();
    const requestData = {
      "availability": this.eventForm.value.Availability,
      "currentStatusId": "EVENT_SCHEDULED",
      "description": this.eventForm.value.Description,
      "duration": this.eventForm.value.Duration,
      "estimatedCompletionCompositeType": "Timestamp",
      "estimatedCompletionDate": this.datePipe.transform(this.eventForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss"),
      "estimatedCompletionDateAmPm": this.eventForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.eventForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.eventForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.eventForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedStartDateDate": this.datePipe.transform(this.eventForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss"),
      "estimatedStartDateHour": this.eventForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.eventForm.value.StartDateMinutes,
      "location": this.eventForm.value.Location,
      "lookupCase": "",
      "lookupOpportunity": this.eventForm.value.Opportunity,
      "pendingActivitiesId": "",
      "relatedPartyId": this.eventForm.value.RelatedParty,
      "scopeEnumId": this.eventForm.value.Security,
      "type": "",
      "workEffortName": this.eventForm.value.Name,
      "workEffortPurposeTypeId": "",
      "workEffortTypeId": "EVENT",
      "partnerId": this.partyId,

    }
    this.myContactsService.CreatePendingActivityEvent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivityEvent Created Successfully");
        this.spinner.hide();
        this.closebutton13.nativeElement.click();
        this.eventForm.reset();
        this.getPendingActivityEventPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updatePendingActivityTask(product) {
    this.spinner.show();
    this.showActivityTask = true;
    this.productActivityTask = product;

    setTimeout(() => {
      const formValue = this.taskForm;
      formValue.patchValue({
        Subject: product.workEffortName,
        Security: product.scopeEnumId,
        RelatedParty: product.relatedPartyId,
        Opportunity: product.lookupOpportunity,
        Case: "",
        Purpose: product.workEffortPurposeTypeId,

        Duration: product.duration,
        StartDateandTime: this.datePipe.transform(product.estimatedStartDateDate, "yyyy-MM-dd hh:mm:ss"),
        Description: product.description,
        Availability: product.availability,
        IgnoreSchedulingConflicts: "",
        CompletionDateandTime: this.datePipe.transform(product.estimatedCompletionDate, "yyyy-MM-dd hh:mm:ss"),
        CompletionDateHour: product.estimatedCompletionDateHour,
        CompletionAmPm: product.estimatedCompletionDateAmPm,
        CompletionDateMinutes: product.estimatedCompletionDateMinutes,
        StartDateAmPm: product.estimatedStartDateAmPm,
        StartDateandHour: product.estimatedStartDateHour,
        StartDateMinutes: product.estimatedStartDateMinutes,
      })
    }, 2000);
    this.spinner.hide();
  }
  UpdatePendingActivityTaskPartner() {
    this.spinner.show();
    const requestData = {
      "availability": this.taskForm.value.Availability,
      "currentStatusId": "TASK_SCHEDULED",
      "description": this.taskForm.value.Description,
      "duration": this.taskForm.value.Duration,
      "estimatedCompletionCompositeType": "Timestamp",
      "estimatedCompletionDate": this.datePipe.transform(this.taskForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss"),
      "estimatedCompletionDateAmPm": this.taskForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.taskForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.taskForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.taskForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedStartDateDate": this.datePipe.transform(this.taskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss"),
      "estimatedStartDateHour": this.taskForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.taskForm.value.StartDateMinutes,
      "ignoreSchedulingConflicts": this.taskForm.value.IgnoreSchedulingConflicts,
      "lookupCase": "",
      "lookupOpportunity": this.taskForm.value.Opportunity,
      "pendingActivityTaskId": this.productActivityTask.pendingActivityTaskId,
      "relatedPartyId": this.taskForm.value.RelatedParty,
      "scopeEnumId": this.taskForm.value.Security,
      "type": "",
      "workEffortName": this.taskForm.value.Subject,
      "workEffortPurposeTypeId": this.taskForm.value.Purpose,
      "workEffortTypeId": "TASK",
      "partnerId": this.partyId,
    }

    this.myContactsService.UpdatePendingActivityTask(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivityTask Update successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.taskForm.reset();
        this.getPendingActivityTaskPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createPendingActivityTaskPartner() {
    this.spinner.show();
    const requestData = {
      "availability": this.taskForm.value.Availability,
      "currentStatusId": "TASK_SCHEDULED",
      "description": this.taskForm.value.Description,
      "duration": this.taskForm.value.Duration,
      "estimatedCompletionCompositeType": "Timestamp",
      "estimatedCompletionDate": this.datePipe.transform(this.taskForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss"),
      "estimatedCompletionDateAmPm": this.taskForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.taskForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.taskForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.taskForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedStartDateDate": this.datePipe.transform(this.taskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss"),
      "estimatedStartDateHour": this.taskForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.taskForm.value.StartDateMinutes,
      "ignoreSchedulingConflicts": this.taskForm.value.IgnoreSchedulingConflicts,
      "lookupCase": "",
      "lookupOpportunity": this.taskForm.value.Opportunity,
      "pendingActivityTaskId": "",
      "relatedPartyId": this.taskForm.value.RelatedParty,
      "scopeEnumId": this.taskForm.value.Security,
      "type": "",
      "workEffortName": this.taskForm.value.Subject,
      "workEffortPurposeTypeId": this.taskForm.value.Purpose,
      "workEffortTypeId": "TASK",
      "partnerId": this.partyId,

    }

    this.myContactsService.createPendingActivityTasks(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivityTask Created successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.taskForm.reset();
        this.getPendingActivityTaskPartner();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getPendingActivityEventPartner() {
    this.spinner.show();
    this.myContactsService.getPendingActivityEvent(this.partyId).subscribe(res => {
      this.pendingActivityEvent = res.data[0].getPendingActivityEventPartner;
      setTimeout(() => {
        const formValue = this.eventForm;
        formValue.patchValue({
          RelatedParty: this.partyId,
          ScheduledStart: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
          Duration: "1:00"
        })

      }, 2000);
      this.spinner.hide();
    })
  }
  getPendingActivityTaskPartner() {
    this.spinner.show();
    this.myContactsService.getPendingActivityTask(this.partyId).subscribe(res => {
      this.PendingActivityTask = res.data[0].getPendingActivityTaskPartner;
      this.spinner.hide();
    })
  }
  deleteCasess(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "casesId": product.casesId,
          "partyId": product.partyId
        }
        this.accountsService.deleteCasess(formData).subscribe(res => {
          this.getCasessByPartyId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateCasess() {
    this.spinner.show();
    const requestData = {
      "casesId": this.productCasessNew.casesId,
      "partyId": this.productCasessNew.partyId,
      "priority": this.createCasesNewForm.value.priority,
      "reason": this.createCasesNewForm.value.reason,
      "status": this.createCasesNewForm.value.status,
      "subject": this.createCasesNewForm.value.subject,
      "type": this.createCasesNewForm.value.type
    }
    this.accountsService.updateCasess(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton17.nativeElement.click();
        this.createCasesNewForm.reset();
        this.getCasessByPartyId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateCasessPatch(product) {
    this.spinner.show();
    this.show = true;
    this.productCasessNew = product;
    setTimeout(() => {
      const formValue = this.createCasesNewForm;
      formValue.patchValue({
        priority: product.priority,
        reason: product.reason,
        status: product.status,
        subject: product.subject,
        type: product.type
      })
    }, 2000);
    this.spinner.hide();
  }
  resetFrom() {
    this.show = false;
    this.createAccountsForm.reset();
    this.createCasesForm.reset();
    this.createCatalogForm.reset();
    this.createCasesNewForm.reset();
    this.createOrderForm.reset();

  }
  UpdateCatalogRequest(){
    this.spinner.show();
    const fromActualdate=this.createCatalogForm.get('date').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "activity": this.createCatalogForm.value.activity,
      "address": this.createCatalogForm.value.address,
      "catalogId": this.productCatalog.catalogId,
      "date": this.actualfromdate,
      "fullFilled": this.createCatalogForm.value.fullFilled,
      "partnerId": this.productCatalog.partnerId,
      "takenBy": this.createCatalogForm.value.takenBy
    }
    this.accountsService.UpdateCatalogRequest(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton16.nativeElement.click();
        this.createCatalogForm.reset();
        this.getCatalogRequestByPartyId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createCatalogRequest(){
    this.spinner.show();
    const fromActualdate=this.createCatalogForm.get('date').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "activity": this.createCatalogForm.value.activity,
      "address": this.createCatalogForm.value.address,
      "catalogId": "",
      "date": this.actualfromdate,
      "fullFilled": this.createCatalogForm.value.fullFilled,
      "partnerId": this.partyId,
      "takenBy": this.createCatalogForm.value.takenBy
    }
    this.accountsService.createCatalogRequest(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton16.nativeElement.click();
        this.createCatalogForm.reset();
        this.getCatalogRequestByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createCasess() {
    this.spinner.show();
    const requestData = {
      "casesId": "",
      "partyId": this.partyId,
      "priority": this.createCasesNewForm.value.priority,
      "reason": this.createCasesNewForm.value.reason,
      "status": this.createCasesNewForm.value.status,
      "subject": this.createCasesNewForm.value.subject,
      "type": this.createCasesNewForm.value.type
    }
    this.accountsService.createCasess(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton17.nativeElement.click();
        this.createCasesNewForm.reset();
        this.getCasessByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCasessByPartyId() {
    this.spinner.show();
    this.accountsService.getCasessByPartyId(this.partyId).subscribe(res => {
      this.allCasessData = res.data[0].getCasessByPartyId;
      this.spinner.hide();
    });
  }

  getAccountByAccountId() {
    this.spinner.show();
    this.accountsService.getAccountByAccountId(this.partyId).subscribe(res => {
      this.allAccountData = res.data[0].getAccount;
      this.spinner.hide();
    })
  }

  getAccounts() {
    this.spinner.show();
    this.myContactsService.getAccount(this.partyId).subscribe(res => {
      this.getAccountsList = res.data[0].data;
      this.spinner.hide();

    })
  }
  resetForm() {
    this.showAcc = false;
    this.createAccountsForm.reset();

  }
  updateAcc(product) {
    this.spinner.show();
    this.showAcc = true;
    this.productAcc = product;
    setTimeout(() => {
      const formValue = this.createAccountsForm;
      formValue.patchValue({
        Security: product.Security,
        accountName: product.accountName,
        city: product.city,
        email: product.email,
        primaryPhone: product.primaryPhone,
      })
    }, 2000);
    this.spinner.hide();
  }
  updateAccounts() {
    this.spinner.show();
    const requestData = {
      "partnerId": this.partyId,
      "accountId": this.productAcc.accountId,
      "accountName": this.createAccountsForm.value.accountName,
      "city": this.createAccountsForm.value.city,
      "primaryEmail": this.createAccountsForm.value.email,
      "primaryPhone": this.createAccountsForm.value.primaryPhone,

    }
    this.myContactsService.updateAccounts(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.createAccountsForm.reset();
        this.getAccounts();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteAccount(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partnerId": this.partyIdForCreate,
          "accountId": product.accountId,

        }
        this.myContactsService.deleteAccount(formData).subscribe(res => {
          this.getAccounts();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createAccounts() {
    this.spinner.show();
    const requestData = {
      "partnerId": this.partyId,
      "accountId": this.partyId,
      "accountName": this.createAccountsForm.value.accountName,
      "city": this.createAccountsForm.value.city,
      "primaryEmail": this.createAccountsForm.value.email,
      "primaryPhone": this.createAccountsForm.value.primaryPhone,
    }
    this.myContactsService.createAccounts(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.createAccountsForm.reset();
        this.getAccounts();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  backWindow() {
    this._location.back();
  }
  toDashboard() {
    this.router.navigate(['/crm/main-overview']);
  }
  /*new code 2*/
  onSubmitNew1() {
    this.spinner.show();
    const form = { ...this.fileUploadFormA.value }
    this.myContactsService.createContactNote(form, this.partyIdForCreate).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.fileUploadFormA.reset();
        this.closebutton2.nativeElement.click();
        this.getNoteList()
        this.router.navigate(['/crm/my-contacts/details-contact'], { queryParams: { id: this.partyIdForCreate } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
  }
  /*newcode2*/
  /*new code*/
  getContactList() {
    this.spinner.show();
    this.myContactsService.getContactsLists().subscribe(res => {
      this.contactId = res.data;
      this.spinner.hide();
      for (const value of this.contactId) {
        this.contactArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }

  onSubmitNew() {
    this.spinner.show();
    this.fileUploadForm.get('accountPartyId').patchValue(this.partyId);
    this.fileUploadForm.get('partyId').patchValue(this.partyId);
    const formData = new FormData();
    const formdata = { ...this.fileUploadForm.value };
    this.myContactsService.contactRelatedAccount(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.closebutton1.nativeElement.click();
        this.getAllContactRelatedAccount();
        this.spinner.hide();
        // this.router.navigate(["/crm/accounts/account-details"],{queryParams:{partyId:this.partyId}})
      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();

  }

  // onSubmitNew(){
  //   this.spinner.show();
  //   this.fileUploadForm.get('accountPartyId').patchValue(this.partyId);
  //   this.fileUploadForm.get('partyId').patchValue(this.partyId);
  //   const formData = new FormData();
  //   const formdata = {...this.fileUploadForm.value};
  //   this.myContactsService.RelatedAccount(formdata).subscribe((res:any)=> {
  //     if(res.success) {
  //       this.toastr.success(res.data.responseMessage);
  //       this.closebutton1.nativeElement.click();
  //       this.getDetailContact();
  //       this.spinner.hide();
  //       // this.router.navigate(["/crm/accounts/account-details"],{queryParams:{partyId:this.partyId}})
  //     }      }
  //     ,(err) => {
  //       for(const value of err.error.errors){
  //       this.toastr.error(value.fieldName + " " + value.fieldError);
  //       }}
  //     )
  //     this.spinner.hide();

  // }

  /*newcode*/

  cancelSubmit() {
    this.router.navigate(['/crm/my-contacts'])
  }
  getAccount() {
    this.spinner.show();
    this.detailService.getAccountList().subscribe(res => {
      this.accountId = res.data;
      this.spinner.hide();
      for (const value of this.accountId) {
        this.accountarray.push({
          label: value.partyId,
          value: value.partyId
        })

      }
    })

  }

  getAllContactRelatedAccount() {
    this.spinner.show();
    this.accountsService.getAllContactRelatedAccount().subscribe(res => {
      this.AllContactRelatedAccount = res.data[0].data;
      this.spinner.hide();

    })
  }

  getDetailContact() {
    this.spinner.show();
    this.detailService.getContacts(this.partyId).subscribe(res => {
      this.partyIdDetail = res.data;
      this.contactName = res.data.firstName + ' ' + res.data.lastName;
      this.partyId = res.data.partyId;
      this.relatedAccount = res.data.relatedCompanies;
      this.spinner.hide();
      if (this.relatedAccount.length == 0) {
        this.openNoRecordFoundRelatedAccount = true;
      }

      this.addr = res.data.address.address1 + ' ' + res.data.address.city + ' ' + res.data.address.postalCode + ' ' + res.data.address.countryGeoId;
      if (this.addr) {
        this.emailId = res.data.email.infoString;
        this.phoneNumber = res.data.telenumber.countryCode + ' ' + res.data.telenumber.areaCode + ' ' + res.data.telenumber.contactNumber
      } else {
        this.openNoRecordFoundContactInfo = true;
      }

    })

  }

  getPartyContent() {
    this.spinner.show();
    this.detailService.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        })
      }
    })

  }
  getPartyList() {
    this.spinner.show();
    this.detailService.getPartyList(this.partyId).subscribe(res => {
      this.partyIdLists = res.data;
      this.partyContentTypeId = res.data.partyContentTypeId;
      this.spinner.hide();
    });

  }


  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];

    this.preview();
  }
  preview() {
    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }
  onpartyContent(event) {
    this.partyContentTypeId = event;

  }
  getAccordian() {
    this.postalTable.push({
      postalAddress: 'Postal Address',
      phoneNumber: this.phoneNumber
    }, {
      postalAddress: 'Email Address',
      phoneNumber: this.emailId
    });
  }
  onUpdateNotes(enoteId, enoteName, enote) {
    this.router.navigate(['/crm/my-contacts/createNote'], { queryParams: { noteId: enoteId, contactId: this.partyId, contactName: this.contactName, noteName: enoteName, note: enote } })
  }

  onDeleteNotes(noteId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.detailService.deleteNote(noteId).subscribe(res => {
          if (res.success) {

            window.location.reload();
          }
        }, (err) => {
          this.toastr.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  getNoteList() {
    this.spinner.show();
    this.detailService.getContactNotes(this.partyId).subscribe(res => {

      this.noteData = res.data;
      this.spinner.hide();
      if (this.noteData.length == 0) {
        this.openNoRecordFoundNote = true;
      }
    });

  }

  onSubmit() {
    this.spinner.show();
    const formData = new FormData();
    formData.append('file', this.fileData);
    this.partyContentTypeId = this.Editform.get('partyContentTypeId').value;

    this.detailService.attachPartyContent(formData, this.partyContentTypeId, this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.getPartyContentsList();
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message);
    });

  }
  onChange(data) {
    this.partyIdTo = data;
  }
  change() {
    this.router.navigate(['/crm/my-contacts/listRelatedAccount'], { queryParams: { partyId: this.partyId } })
  }
  contactNote() {
    this.router.navigate(['/crm/my-contacts/createNote'], { queryParams: { contactIdd: this.partyId, contactName: this.contactName } });
  }
  onAssign() {
    this.spinner.show();
    this.detailService.assignContact(this.partyIdFrom, this.partyIdTo).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
      }

    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.error.message);
    });

  }


  getPartyContentsList() {
    this.spinner.show();
    this.detailService.getPartyContentList(this.partyId).subscribe(res => {
      this.contentList = res.data;
      this.spinner.hide();
      if (this.contentList.length == 0) {
        this.openNoRecordFoundPartyContent = true;
      }
    });

  }

  export(id: any, contentName: any) {
    this.spinner.show();
    this.detailService.downloadView(id).subscribe(res => {
      const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //   window.navigator.msSaveOrOpenBlob(blob);
      //   return;
      // }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = contentName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }

  onDelete(contentId, fromDate, partyContent, partyId): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.detailService.deleteContent(contentId, fromDate, partyContent, partyId).subscribe(res => {
          if (res['data'].responseMessage == 'success') {

          }
          this.getPartyContentsList();
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;
      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  updateDetail(eupdateId) {
    this.router.navigate(['/crm/my-contacts/update-contact-info'],
      { queryParams: { updateId: eupdateId } })
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  onCountryChange(event) {

    this.stateArray = [];
    this.countryGeoId = event;
    this.countryId = event;
    if (event) {
      this.getStateList();
    }

  }
  onStateChange(event) {
    this.stateProvinceGeoId = event
  }
  getStateList() {
    this.spinner.show();
    this.createContact.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })

  }
  getCountryList() {
    this.spinner.show();
    this.createContact.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0].trim(),
          value: element.split(":").pop().trim()
        })
      })
    })

  }
  getStatusCrm() {
    this.spinner.show();
    this.leadPersonalLeadServices.getStatusCrm().subscribe(res => {
      let crmStatus = res.data;
      this.spinner.hide();
      for (const value of crmStatus) {
        this.crmArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })


  }

  
  getContactDetail() {
    this.spinner.show();
    this.createContact.getContacts(this.partyId).subscribe(res => {

      const form = this.updateContact;
      this.countryId = res.data.address.countryId;
      if (res.data.statusId == 'Enabled') {
        this.statusId = "PARTY_ENABLED"
      }
      else {
        this.statusId = "PARTY_DISABLED"
      }

      this.onCountryChange(this.countryId);
      this.spinner.hide();
      setTimeout(() => {
        form.patchValue({
          address1: res.data.address.address1 == undefined ? '' : res.data.address.address1,
          firstName: res.data.firstName, lastName: res.data.lastName, partyId: this.partyId,
          emailAddress: res.data.email == null ? '' : res.data.email.infoString,
          countryGeoId: res.data.address.countryId == undefined ? '' : res.data.address.countryId,
          comments: res.data.comments, statusId: this.statusId, externalId: res.data.externalId,
          city: res.data.address.city == undefined ? '' : res.data.address.city,
          postalCode: res.data.address.postalCode == undefined ? '' : res.data.address.postalCode,
          stateProvinceGeoId: res.data.address.stateId == undefined ? '' : res.data.address.stateId,
        });
      }, 3000);
    })
  }
  onUpdate() {
    this.spinner.show();
    const formData = this.updateContact.value;
    this.leadPersonalLeadServices.updateContact(this.partyId, formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data);
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getDetailContact();
        this.getAccount();
        this.getNoteList();
        this.getAccordian();
        this.getPartyContent();
        this.getPartyList();
        this.getPartyContentsList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.success == false) {
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }
        }
        else if (err.error.success == false) {
          this.toastr.error(err.error.message);
        }
      }
    })
    this.spinner.hide();
  }


  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
