<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/financial/payable/commission-invoice">Invoices </span>
                <span class="color-gry cursor-pointer"
                    routerLink="/financial/payable/commission-invoice/summary-commission-invoice"
                    [queryParams]="{invoiceId:invoiceId}">>Commission Invoice Summary </span>
                <span class="color-black pl-1"> > {{editMode ? 'Update':'Create'}} Commission Invoice Term </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                    data-toggle="collapse" href="#collapsetwo">
                                    <div class="w-100">
                                        <div class="panel-group" >
                                            <div class="panel panel-default">
                                                <div class="w3-card-4" style="border:1px solid;
                                                border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                                  background: #F5F7FA!important;">
                                          
                                                      <li [ngClass]="activeCategory==2?'active':''"><a>
                                                        {{editMode ? 'Update':'Create'}} Commission Invoice Term</a></li>
                                                   
                                                   
                                          
                                                    </ul>
                                                  </div>
                                              
                                                <div>
                                                    <br>
                                                    <div class="panel-body">
                                                        <form [formGroup]="invoiceTermForm">
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                         margin-left: -9%;">
                                                                            <label for="invoiceTermId">Invoice Term
                                                                                ID</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            formControlName="invoiceTermId">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       ">
                                                                            <label for="partyId">Term Type ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                [options]="invoiceTypeIds"
                                                                                formControlName="termTypeId"
                                                                                optionlabel="label"
                                                                                placeholder="Select Term Type Id">
                                                                            </p-dropdown>
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                         margin-left: -9%;">
                                                                            <label for="itemNo">Item No</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            formControlName="invoiceItemSeqId">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       ">
                                                                            <label for="termValue">Term Value</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            formControlName="termValue">
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                         margin-left: -9%;">
                                                                            <label for="termDays">Term Days</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            formControlName="termDays">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                         ">
                                                                            <label for="termValue">Text Value</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            formControlName="textValue">
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                         margin-left: -9%;">
                                                                            <label for="termValue">Description</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            formControlName="description">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        ">
                                                                            <label for="partyId">Uom</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [options]="uomIds"
                                                                                optionlabel="label"
                                                                                formControlName="uomId"
                                                                                placeholder="Select Term Type Id">
                                                                            </p-dropdown>
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                               
                                                             
                                                            </div>
                                                            <div class="main-submit-button" style="    margin-right: 27%;">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="submit()">{{editMode ?'Update':'Submit'}}</button>
                                                                <button class="btn btn-danger cursor-pointer ml-2"
                                                                    (click)="reset()">Cancel</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 