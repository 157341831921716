<div class="container-fluid main-container-wrapper">

    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Product</span>
             

               <span class="">

                   <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

                   <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
               </span>
      
            </div>
            <div class=" bg-white color-grey">
                <div class="mb-5">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="display: none !important;padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCat==2?'active':''" (click)="catalogOpen()">
                                <a>Catalogs</a>
                            </li>
                            <li [ngClass]="activeCat==3?'active':''" (click)="categoriesOpen()">
                                <a>Categories</a>
                            </li>
                            <li [ngClass]="activeCat==4?'active':''" (click)="productsOpen()">
                                <a>Products</a>
                            </li>
                            <li [ngClass]="activeCat==5?'active':''" (click)="featuresOpen()">
                                <a>Features</a>
                            </li>
                            <li [ngClass]="activeCat==6?'active':''" (click)="promosOpen()">
                                <a>Promos</a>
                            </li>
                            <li [ngClass]="activeCat==7?'active':''" (click)="priceRulesOpen()">
                                <a>Price Rules</a>
                            </li>
                            <li [ngClass]="activeCat==8?'active':''" (click)="storesOpen()">
                                <a>Stores</a>
                            </li>
                            <li [ngClass]="activeCat==9?'active':''" (click)="productStoreGroupsOpen()">
                                <a>Product Store Groups</a>
                            </li>
                            <li [ngClass]="activeCat==10?'active':''" (click)="thesaurusOpen()">
                                <a>Thesaurus</a>
                            </li>
                            <li [ngClass]="activeCat==11?'active':''" (click)="reviewsOpen()">
                                <a>Reviews</a>
                            </li>
                            <li [ngClass]="activeCat==12?'active':''" (click)="configurationsOpen()">
                                <a>Configurations</a>
                            </li>
                            <li [ngClass]="activeCat==13?'active':''" (click)="subscriptionsOpen()">
                                <a>Subscriptions</a>
                            </li>
                            <li [ngClass]="activeCat==14?'active':''" (click)="shippingOpen()">
                                <a>Shipping</a>
                            </li>
                            <li [ngClass]="activeCat==15?'active':''" (click)="imageManagementOpen()">
                                <a>Image Management</a>
                            </li>
                        </ul>
                    </div>
                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                        <div class="create-new-leade mt-2">
                            <div class="">
                                <div class="">
                                    <form class="w-100" [formGroup]="productForm">
                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                            <!-- <span class="headingAccords"> Search Option</span> -->
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-outline-secondary active marginHeader" data-toggle="modal" data-target="#exampleModalCenter">Create New Product</button>

                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 77%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>									
                
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                
                                                                        <div class="col-lg-3 form-group classInputJJJ">
                                                                            <label for="exampleInputEmail1">Product ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown filter="true" formControlName="productIdSearchType" [options]="opportunityIdArray">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control w-100" formControlName="productId" placeholder="Enter Product ID">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Internal Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown filter="true" formControlName="internalNameSearchType" [options]="opportunityIdArray">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control w-100" formControlName="internalName" placeholder="Enter Internal Name">
                                                                        </div>
                                                                    </div>
                                                                </div>
                
                
                
                                                            </div>
    
                                                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -31%;"><button type="submit" class="btn btn-secondary submit-btn" (click)=" getProductList()">Find</button>&nbsp;
                                                                <button type="button" class="btn btn-danger ml-2" (click)="resetProductForm()">Reset</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <!-- <div class="w3-card-4 classCard">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <button type="button" class="btn btn-outline-secondary active" data-toggle="modal" data-target="#exampleModalCenter">Create New Product</button>

                                            </div><br>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInputJJJ">
                                                            <label for="exampleInputEmail1">Product ID</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown filter="true" formControlName="productIdSearchType" [options]="opportunityIdArray">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control w-100" formControlName="productId" placeholder="Enter Product ID">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Internal Name</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown filter="true" formControlName="internalNameSearchType" [options]="opportunityIdArray">
                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control w-100" formControlName="internalName" placeholder="Enter Internal Name">
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -31%;"><button type="submit" class="btn btn-secondary submit-btn" (click)=" getProductList()">Find</button>&nbsp;
                                                <button type="button" class="btn btn-danger ml-2" (click)="resetProductForm()">Reset</button>
                                            </div>
                                        </div> -->
                                        <br>

                                        <div class="w3-card-4 classCard">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Product List
                                                    </h4>

                                                </div>
                                            </header>
                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                <div class="col-lg-12 col-12">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="products" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Product ID
                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                        <div style="color: white;"> Product Type ID
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                        <div style="color: white;"> Internal Name
                                                                            <p-sortIcon field="category"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                        <div style="color: white;"> Brand Name
                                                                            <p-sortIcon field="quantity"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> Product Name
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> Description
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                        <div style="color: white;"> Created Date
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span  class="account-button" 
                                                                             routerLink="/product-management/products/detail-product-summary/{{product.productId}}"  class="account-button">{{product.productId}}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">{{product.productType}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">{{product.internalName}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">{{product.brandName}}</td>
                                                                    <td [ngStyle]="{'width':'190px'}">{{product.productName}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">{{product.description}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                        {{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}
                                                                    </td>


                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                    </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Create Product</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                Product</a></li>
                                <li [ngClass]="activeCategory==3?'active':''" (click)="wordingValid(createEditProductForm.value.productTypeId)"><a>
                                Wording And Comment</a></li>

                                <li [ngClass]="activeCategory==4?'active':''" (click)="virtualValid(createEditProductForm.value.internalName)"><a>
                                Virtual Product </a></li>
                                <li [ngClass]="activeCategory==5?'active':''" (click)="primaryValid(createEditProductForm.value.virtualVariantMethodEnum)"><a>
                                Primary Category </a></li>
                                <li [ngClass]="activeCategory==6?'active':''" (click)="dateValid(createEditProductForm.value.primaryProductCategoryId)"><a>
                                Dates </a></li>
                                <li [ngClass]="activeCategory==7?'active':''" (click)="inventoryValid(createEditProductForm.value.primaryProductCategoryId)"><a>
                                Inventory </a></li>
                                <li [ngClass]="activeCategory==8?'active':''" (click)="ratingValid(createEditProductForm.value.inventoryItemTypeId)"><a>
                                Rating </a></li>
                                <li [ngClass]="activeCategory==9?'active':''" (click)="amountValid(createEditProductForm.value.ratingTypeEnum)"><a>
                                Amount </a></li>
                                <li [ngClass]="activeCategory==10?'active':''" (click)="measureValid(createEditProductForm.value.amountUomTypeId)"><a>
                                Measures </a></li>
                                <li [ngClass]="activeCategory==11?'active':''" (click)="shipingValid(createEditProductForm.value.heightUomId,createEditProductForm.value.widthUomId)"><a>
                                Shipping </a></li>
                                <li [ngClass]="activeCategory==12?'active':''" (click)="shopingValid(createEditProductForm.value.defaultShipmentBoxTypeId)"><a>
                                ShoppingCart </a></li>
                                <li [ngClass]="activeCategory==13?'active':''" (click)="shopingCartValid(createEditProductForm.value.defaultShipmentBoxTypeId)"><a>
                                Miscellaneous </a></li>



                            </ul>
                        </div>
                        <div>
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createEditProductForm">
                                        <div *ngIf="activeCategory==2">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Product Type <span
                                                            style="color:red">*</span></label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="productTypeIdArray" placeholder="Select Product Type" formControlName="productTypeId">
                                                                </p-dropdown>
                                                                <small class="text-danger" *ngIf="!!createEditProductForm.controls.productTypeId.invalid && !!createEditProductForm.controls.productTypeId.touched">Required</small>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">
                                                            Product ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product ID" formControlName="productId">
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="margin-right: 14%;">
                                                    <button type="submit" (click)="selectProduct(createEditProductForm.value.productTypeId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==3">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputB">
                                                                <label for="exampleInputEmail1">
                                                            Internal Name <span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Internal Name" formControlName="internalName">
                                                                <small class='text-danger' *ngIf="!!createEditProductForm.controls.internalName.invalid && !! createEditProductForm.controls.internalName.touched">Required</small>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Brand Name</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Brand Name" formControlName="brandName">
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputB">
                                                                <label for="exampleInputEmail1">Product Name</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Name" formControlName="productName">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Comments </label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments" formControlName="comments">
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 13%;">
                                                    <button type="submit" (click)="selectworking(createEditProductForm.value.internalName)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==4">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputC">
                                                                <label for="exampleInputEmail1">
                                                            Is VIRTUAL Product ?</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Is Virtual Product" formControlName="isVirtual">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">
                                                            Is VARIANT Product ?</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Is Variant Product" formControlName="isVariant"></p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputC">
                                                                <label for="exampleInputEmail1">Virtual Variant
                                                            Method<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="virtualVariantMethodArray" placeholder="Select Virtual Variant Method" formControlName="virtualVariantMethodEnum">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 9%;">
                                                    <button type="submit" (click)="selectvirtual(createEditProductForm.value.virtualVariantMethodEnum)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==5">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">
                                                            Primary Category<span style="color:red">*</span></label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="categories" placeholder="Select Primary Category" formControlName="primaryProductCategoryId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div class="main-submit-button" style="margin-right: 61%;">
                                                    <button type="submit" (click)="selectPrimary(createEditProductForm.value.primaryProductCategoryId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==6">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputGI">
                                                                <label for="exampleInputEmail1">
                                                            Introduction Date</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-calendar placeholder="Select Date" formControlName="introductionDate">
                                                                </p-calendar>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Release
                                                            Date</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-calendar formControlName="releaseDate" placeholder="Select Date">
                                                                </p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputGI">
                                                                <label for="exampleInputEmail1">Sales
                                                            Discontinuation Through Date</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-calendar formControlName="salesDiscontinuationDate" placeholder="Select Date">
                                                                </p-calendar>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Support
                                                            Discontinuation Date</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-calendar formControlName="supportDiscontinuationDate" placeholder="Select Date">
                                                                </p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 5%;">
                                                    <button type="submit" (click)="selectDate()" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==7">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputE">
                                                                <label for="exampleInputEmail1">Disc. When Inv. Not
                                                            Avail?</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="salesDiscWhenNotAvail">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Requirement Method
                                                            Enum ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="requirementMethodEnumIDArray" placeholder="Select Enum ID" formControlName="requirementMethodEnumId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputE">
                                                                <label for="exampleInputEmail1">Inventory
                                                            Message</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Inventory Message" formControlName="inventoryMessage">
                                                            </div>

                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">InventoryItem Type
                                                            ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="inventoryItemTypeIDArray" placeholder="Select Inventory Item Type Id" formControlName="inventoryItemTypeId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputE">
                                                                <label for="exampleInputEmail1">Require
                                                            Inventory</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="requireInventory">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Lot ID</label>


                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="commandingArray" placeholder="Select Lot Id" formControlName="lotIdFilledIn">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="main-submit-button" style="margin-right: 7%;">
                                                    <button type="submit" (click)="selecInventory(createEditProductForm.value.inventoryItemTypeId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==8">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">
                                                            Rating Type Enum<span style="color:red">*</span></label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="ratingTypeEnumArray" placeholder="Select Rating Type Enum" formControlName="ratingTypeEnum">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Rating</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Rating" formControlName="productRating">
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="margin-right: 11%;">
                                                    <button type="submit" (click)="selectRating(createEditProductForm.value.ratingTypeEnum)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==9">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputB">
                                                                <label for="exampleInputEmail1">
                                                            Require Amount</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="requireAmount">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Amount Uom Type
                                                            ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="amountUomTypeIDArray" placeholder="Select Amount Uom Type Id" formControlName="amountUomTypeId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 13%;">
                                                    <button type="submit" (click)="selectAmount(createEditProductForm.value.amountUomTypeId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==10">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Product
                                                            Height</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Height" formControlName="productHeight">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Height Uom
                                                            ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="measuresUOMIDArray" placeholder="Select Height Uom ID" formControlName="heightUomId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Shipping
                                                            Height</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Shipping Height" formControlName="shippingHeight">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Product
                                                            Width</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Width" formControlName="productWeight">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Width Uom ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="measuresUOMIDArray" placeholder="Select Width Uom ID" formControlName="widthUomId">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Shipping
                                                            Width</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Shipping Width" formControlName="shippingWidth">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Product
                                                            Depth</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Depth" formControlName="productDepth">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Depth Uom ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="measuresUOMIDArray" placeholder="Select Depth Uom ID" formControlName="depthUomId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Shipping
                                                            Depth</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Shipping Depth" formControlName="shippingDepth">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Product
                                                            Diameter</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Diameter" formControlName="productDiameter">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Diameter Uom
                                                            ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="measuresUOMIDArray" placeholder="Select Diameter Uom ID" formControlName="diameterUomId">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Product
                                                            Weight</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product Weight" formControlName="productWeight">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Weight Uom
                                                            ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="weightUomIDArray" placeholder="Select Weight Uom ID" formControlName="weightUomId">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Shipping
                                                            Weight</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Shipping Weight" formControlName="shippingWeight">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputD">
                                                                <label for="exampleInputEmail1">Quantity
                                                            Included</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Quantity Included" formControlName="quantityIncluded">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Quality Uom
                                                            ID</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="quantityUomIDArray" placeholder="Select Quality Uom ID" formControlName="quantityUomId">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div class="col-lg-6 col-12">
                                                        <label for="exampleInputEmail1"></label>
                                                        <div class="main-submit-button" style="    margin-right: -86%;">
                                                            <button type="submit" (click)="selectMeasure(createEditProductForm.value.heightUomId,createEditProductForm.value.widthUomId)" class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==11">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputG">
                                                                <label for="exampleInputEmail1">Pieces
                                                            Included</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Pieces
                                                    Included" formControlName="piecesIncluded">
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">In Shipping Box
                                                            ?</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="inShippingBox">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputG">
                                                                <label for="exampleInputEmail1">Default Shipment Box
                                                            Type ID<span style="color:red">*</span></label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="defaultShipmentBoxTypeIDArray" placeholder="Select Shipment" formControlName="defaultShipmentBoxTypeId">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Charge
                                                            Shipping</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select Charge Shipping" formControlName="chargeShipping">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="    margin-right: 3%;">
                                                    <button type="submit" (click)="selectShiping(createEditProductForm.value.defaultShipmentBoxTypeId)" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==12">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInputH">
                                                                <label for="exampleInputEmail1">
                                                            Allow order decimal quantity</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select Quantity" formControlName="orderDecimalQuantity">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="main-submit-button" style="margin-right: 55%;">
                                                    <button type="submit" (click)="selectShopingCart()" class="btn btn-secondary submit-btn">Continue</button>

                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="activeCategory==13">

                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Returnable</label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="returnable">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="exampleInputEmail1">Include In
                                                            Promotions</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="includeInPromotions">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Taxable</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="taxable">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="keyword">AutoCreate Keywords</label>

                                                            </div>

                                                            <div class="col-lg-3">
                                                                <p-dropdown filter="true" [options]="interrogativeArray" placeholder="Select " formControlName="autoCreateKeywords">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-lg-12" *ngIf="editMode">
                                                        <div class="row">

                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="createdBy">Created By
                                                        </label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="text" class="form-control" [value]="product.createdByUserLogin" readonly>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="lastModifiedBy">Last Modified By
                                                        </label>
                                                            </div>

                                                            <div class="col-lg-3">
                                                                <input type="text" class="form-control" [value]="product.lastModifiedByUser" readonly>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div class="main-submit-button " style="margin-right: 16%;">
                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="submitProduct()">{{editMode? 'Update' : 'Create'}}
                                                </button>

                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>