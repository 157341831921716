<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Detail Portal Traning Summary</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard" >
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px;">
                        <li [ngClass]="activeCategory==1?'active':''" ><a>
                            Portal Traning  Information</a></li>
                       
                    </ul>
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">
                                            <div *ngIf="activeCategory==1">
                                               
                                                <div class="panel-body">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="color-black container">
                                                            <div class="row">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Employee Party Id</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.firstName}} {{this.lastName}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Training Request Id</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.trainingRequestId}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Training Class Type</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.description}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Work Effort Id</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.workEffortId}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">From Date</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                    </div>
                                    
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Through Date</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Approver Party</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.approverId}}</span>
                                                                    </div>
                                    
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Approver Status</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.approvalStatus}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Reason</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.reason}}</span>
                                                                    </div>
                                    
                                    
                                                                  
                                                                 
                                    
                                                                    
                                                                  </div>

                                                               
                                                               
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner> 