import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { SortEvent } from 'primeng/api';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router,ActivatedRoute } from '@angular/router';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';

@Component({
    selector: 'app-commission-invoice',
    templateUrl: './commission-invoice.component.html',
    styleUrls: ['./commission-invoice.component.css']
})
export class CommissionInvoiceComponent implements OnInit {
    commissionInvoiceForm: FormGroup;
    @ViewChild('closebuttonN') closebuttonN;
    
    activeCategoryInv = 2;
    currencyUomIds: any[];
    
    invoiceId: string;
    editMode: boolean;
    invoiceCommission: any;
    
    
    
    
    isShown = false; 
    activeCategory=1;
    activeCategoryMain=4;
    opportunityIdArray = [
        {
            label: 'Contains',
            value: 'Contains'
        },
        {
            label: 'Equals',
            value: 'Equals'
        },
        {
            label: 'Is Empty',
            value: 'Is Empty'
        },
        {
            label: 'Begins With',
            value: 'Begins With'
        },
    ];
    greaterArray = [
        {
            label: 'Equals',
            value: 'Equals'
        },
        {
            label: 'Same Day',
            value: 'Same Day'
        },
        {
            label: 'Greater Than From Day Start',
            value: 'Greater Than From Day Start'
        },
        {
            label: 'Greater Than',
            value: 'Greater Than'
        }
    ];
    smallerArray = [
        {
            label: 'Less Than',
            value: 'Less Than'
        },
        {
            label: 'Up To Day',
            value: 'Up To Day'
        },
        {
            label: 'Up Through Day',
            value: 'Up Through Day'
        },
        {
            label: 'Is Empty',
            value: 'Is Empty'
        }
    ];
    faEdit = faEdit;
    faTrash = faTrash;
    total=0;
    rowNumber=0;
    pageSize = 10;
    rows = 50;
    searchCommissionInvoiceForm: FormGroup;
    statusIds: any[];
    array = [
        {
            label: 'Your Company Name',
            value: 'Company'
        }
    ];
    partyIds: any[];
    commissionInvoices: any[];
    agreementTypeValue: string;
    constructor(
        readonly router: Router,
        readonly _FormBuilder: FormBuilder,
        readonly spinner: NgxSpinnerService,
        readonly _AccountingApService: AccountingApService,
        readonly _GlobalResourceService: GlobalResourceService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _ToastrService: ToastrService,
        readonly  _Router: Router,
        readonly accountsService: AccountsService,

    ) {
        this.commissionInvoiceForm = this._FormBuilder.group({
            currencyUomId: [''],
            description: [''],
            dueDate: [''],
            invoiceDate: [''],
            invoiceMessage: [''],
            partyId: ['Company'],
            partyIdFrom: [''],
            referenceNumber: ['']
        });
        this.commissionInvoices = [];
        this.searchCommissionInvoiceForm = this._FormBuilder.group({
            description: [''],
            descriptionSearchType: ['Contains'],
            fromPartyId: [''],
            invoiceDateFrom: [''],
            invoiceDateFromSearchType: ['Equals'],
            invoiceDateTo: [''],
            invoiceDateToSearchType: ['Equals'],
            invoiceId: [''],
            invoiceIdSearchType: ['Contains'],
            statusId: [''],
            toPartyId: [''],

            currencyUomId: [''],
            dueDate: [''],
            invoiceDate: [''],
            invoiceMessage: [''],
            partyId: ['Company'],
            partyIdFrom: [''],
            referenceNumber: ['']

           
            
        });

        this.invoiceId = '';
        this.editMode = false;

        this.statusIds = [];
        this.partyIds = [];

      
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.agreementTypeValue = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this.getInvoiceTypeAndStatus();
        this.getPartyIds();
        this.searchCommissionInvoices();

        this.invoiceId = this._ActivatedRoute.snapshot.queryParams.invoiceId;
        this.getPartyIds1();
        this.getCurrency();
        if (this.invoiceId) {
            this.editMode = true;
            this._AccountingApService.getCommissionInvoiceById(this.invoiceId)
                .then(data => {
                    this.invoiceCommission = data.data;
                    this.commissionInvoiceForm.patchValue({
                        ...data.data, ...{
                            invoiceDate: data.data.invoiceDate ? new Date(data.data.invoiceDate) : '',
                            dueDate: data.data.dueDate ? new Date(data.data.dueDate) : ''
                        }
                    });
                });
        }
    }

    
    getPartyIds1(): void {
        this.spinner.show();
        this._GlobalResourceService.getSupplierList()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
           
    }
    getCurrency(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                this.spinner.hide();
                this.currencyUomIds = data.data.currencyList.map(value => {
                    return {
                        label: value.uomId,
                        value: value.uomId
                    };
                });
            });
          
    }
    update(): void {
        this.spinner.show();
        this._AccountingApService.updateInvoice(this.invoiceId, {
            ...this.invoiceCommission, ...this.commissionInvoiceForm.value, ...{
                dueDate: this.commissionInvoiceForm.value.dueDate ? moment(this.commissionInvoiceForm.value.dueDate).format('YYYY-MM-DD HH:mm:ss') : '',
                invoiceDate: this.commissionInvoiceForm.value.invoiceDate ? moment(this.commissionInvoiceForm.value.invoiceDate).format('YYYY-MM-DD HH:mm:ss') : ''
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.commissionInvoiceForm.reset();
                    this._Router.navigate(['/financial/payable/commission-invoice']);
                }
            });
         
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            this._AccountingApService.createCommissionInvoice({
                ...this.commissionInvoiceForm.value, ...{
                    dueDate: this.commissionInvoiceForm.value.dueDate ? moment(this.commissionInvoiceForm.value.dueDate).format('YYYY-MM-DD HH:mm:ss') : '',
                    invoiceDate: this.commissionInvoiceForm.value.invoiceDate ? moment(this.commissionInvoiceForm.value.invoiceDate).format('YYYY-MM-DD HH:mm:ss') : ''
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.searchCommissionInvoices();
                        this.commissionInvoiceForm.reset();
                        this.closebuttonN.nativeElement.click();
                        this._Router.navigate(['/financial/payable/commission-invoice']);
                    }
                });
        }
       
    }

    reset() {

        this.searchCommissionInvoiceForm.controls['description'].reset();
        this.searchCommissionInvoiceForm.controls['fromPartyId'].reset();
        this.searchCommissionInvoiceForm.controls['invoiceDateFrom'].reset();
        this.searchCommissionInvoiceForm.controls['invoiceDateTo'].reset();
        this.searchCommissionInvoiceForm.controls['invoiceId'].reset();
        this.searchCommissionInvoiceForm.controls['toPartyId'].reset();
        this.searchCommissionInvoiceForm.controls['statusId'].reset();

    
        this.searchCommissionInvoices();
    
      
      }
    
    invoiceType(){
        this.isShown = !this.isShown;
      }
      changeactiveCategoryFunctionPurchae(){
        this.router.navigate([`/financial/payable/invoices`]);
      }
      changeactiveCategoryFunctionCredit(){
        this.router.navigate([`/financial/payable/credit-memo`]);
      }
      changeactiveCommission(){
        this.router.navigate([`/financial/payable/commission-invoice`]);
      }
    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getSupplierList()
            .then(data => {
                this.partyIds = data.data.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
            this.spinner.hide();
    }
    getInvoiceTypeAndStatus(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceTypeAndStatus()
            .then(data => {
                this.statusIds = data.data.invoiceStatus.map(value => {
                    return {
                        label: value.description,
                        value: value.statusId
                    };
                });
            });
            this.spinner.hide();
    }
    searchCommissionInvoices(): void {
        this.spinner.show();
        this._AccountingApService.getCommissionInvoices({ pageNo: 1, pageSize: 200 }, {
            ...this.searchCommissionInvoiceForm.value, ...{
                invoiceDateFrom: this.searchCommissionInvoiceForm.value.invoiceDateFrom ? moment(this.searchCommissionInvoiceForm.value.invoiceDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
                invoiceDateTo: this.searchCommissionInvoiceForm.value.invoiceDateTo ? moment(this.searchCommissionInvoiceForm.value.invoiceDateTo).format('YYYY-MM-DD HH:mm:ss') : '',
            }
        })
            .then(data => {
                this.commissionInvoices = data.data;
                this.spinner.hide();
            });
          
    }
    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}