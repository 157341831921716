import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { PayablePaymentService } from '../../accounting-ap/payables-payment/payables-payment.service';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import * as moment from 'moment';
import { Location } from '@angular/common';

@Component({
    selector: 'app-incoming-payment',
    templateUrl: './incoming-payment.component.html',
    styleUrls: ['./incoming-payment.component.css']
})
export class IncomingPaymentComponent implements OnInit {
    @ViewChild('closebutton') closebutton;
    total = 0;
    activeCategory = 2;
    rowNumber = 0;
    isShown = false;
    pageSize = 10;
    rows = 50;
    pageNo = 1;
    faEdit = faEdit;
    opportunityIdArray: any = [
        {
            label: 'Equals',
            value: 'Equals'
        },
        {
            label: 'Begins With',
            text: 'Begins With'
        },
        {
            label: 'Contains',
            value: 'Contains'
        },
        {
            label: 'Is Empty',
            value: 'Is Empty'
        },
    ];
    paymentTypeList: any[];
    statusList: any[];
    paymentList: any[];
    partyIds: any[];
    searchPaymentForm: FormGroup;
    agreementType: string;
    array = [
        {
            label: 'Your Company Name',
            value: 'Company'
        },
        {
            label: 'Widget Business Unit',
            value: 'widget_div'
        },
    ];
    finAccountIds: any[];
    payablePaymentForm: FormGroup;
    editMode: boolean;
    paymentId: string;
    paymentMethods: any[];
    GIAccounts: any[];
    currencyUomID: any[];
    paymentValue: any;
    constructor(
        readonly _PayablePaymentService: PayablePaymentService,
        readonly _ToastrService: ToastrService,
        readonly _Router: Router, readonly spinner: NgxSpinnerService,
        readonly _GlobalResourceService: GlobalResourceService,
        readonly _FormBuilder: FormBuilder,
        readonly _ActivatedRoute: ActivatedRoute,
        private _location: Location,
    ) {
        this.paymentTypeList = [];
        this.statusList = [];
        this.paymentList = [];
        this.partyIds = [];
        this.searchPaymentForm = this._FormBuilder.group({
            amount: [''],
            comments: [''],
            commentsSearchType: ['Contains'],
            fromPartyId: [''],
            paymentId: [''],
            paymentIdSearchType: ['Contains'],
            paymentType: [''],
            status: [''],
            toPartyId: ['']
        });

        this.partyIds = [];
        this.paymentTypeList = [];
        this.payablePaymentForm = this._FormBuilder.group({
            amount: ['', [Validators.required]],
            comments: [''],
            overrideGlAccountId: [''],
            partyIdFrom: ['', [Validators.required]],
            partyIdTo: ['Company'],
            paymentMethodId: [''],
            paymentRefNum: [''],
            paymentTypeId: [''],
            actualCurrencyAmount: [''],
            actualCurrencyUomId: [''],
            currencyUomId: [''],
            effectiveDate: [''],
            finAccountTransId: [''],
            statusId: [''],
            finAccountId: ['']
        });
        this.finAccountIds = [];
        this.paymentMethods = [];
        this.GIAccounts = [];
        this.paymentId = '';
        this.editMode = false;
        this.currencyUomID = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this.getPaymentType();
        this.getStatus();
        this.getAllPaymentList();
        this.getPartyIds();

        this.paymentId = this._ActivatedRoute.snapshot.queryParams.paymentId;
        if (this.paymentId) {
            this.editMode = true;
            this._PayablePaymentService.getPaymentById(this.paymentId)
                .then(data => {
                    if (data.data.length) {
                        this.paymentValue = data.data[0];
                        this.payablePaymentForm.patchValue({
                            ...data.data[0], ...{
                                effectiveDate: new Date(data.data[0].effectiveDate),
                                finAccountTransId: data.data[0].financialAccountTransaction.finAccountTransId
                            }
                        });
                    }
                });
        }
        this.getPartyIds();
        this.getPaymentType();
        this.getGIAccountsAndPaymentMethodIds();
        this.getCurrency();
        this.getMethodIds();
    }

backWindow(){
  this._location.back();
}
  toDashboard()
{
this._Router.navigate(['/financial/main']);
}
    resetForm(){
     
    this.searchPaymentForm.controls['paymentId'].reset();
    this.searchPaymentForm.controls['comments'].reset();
    this.searchPaymentForm.controls['paymentType'].reset();
    this.searchPaymentForm.controls['fromPartyId'].reset();
    this.searchPaymentForm.controls['toPartyId'].reset();
    this.searchPaymentForm.controls['amount'].reset();
    this.searchPaymentForm.controls['status'].reset();

    this.getAllPaymentList();
    }
    mainReceive() {
        this._Router.navigate(["/financial/main-receivable"])
    }
    enterBills() {
        this._Router.navigate(["/financial/payable/invoices"])
    }
    payableMakePayment() {
        this._Router.navigate(["/financial/payable/payment-payables"])
    }
    payableMakeGroupPayment() {
        this._Router.navigate(["/financial/payable/payment-group"])
    }
    salesInvoice() {
        this._Router.navigate(["/financial/receiveable/invoices"])
    }
    receivePayment() {
        this._Router.navigate(["/financial/receiveable/payments"])
    }
    receiveGroupPayments() {
        this._Router.navigate(["/financial/receiveable/payment-group"])
    }
    invoiceType() {
        this.isShown = !this.isShown;
    }

    getStatus(): void {
        this.spinner.show();
        this._PayablePaymentService.getStatus()
            .then(data => {
                if (data) {
                    this.statusList = data.data.status.map(value => {
                        return {
                            label: value.description,
                            value: value.statusId
                        };
                    });
                }
            });
        this.spinner.hide();
    }

    getAllPaymentList(): void {
        this.spinner.show();
        this._PayablePaymentService.getIncomingPayment({ pageNo: 1, pageSize: 200 }, this.searchPaymentForm.value).then(data => {
            if (data.success) {
                this.paymentList = data.data;
                this.spinner.hide();
            }
        });

    }

    customSort(event: SortEvent) {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }

    getCurrency(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                this.spinner.hide();
                this.currencyUomID = data.data.currencyList.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
            });

    }
    getMethodIds(): void {
        this.spinner.show();
        this._PayablePaymentService.getIncomingMethodType('Company')
            .then(data => {
                this.spinner.hide();
                this.paymentMethods = data.data.paymentMethodId.map(value => {
                    return {
                        label: value.description+"["+value.paymentMethodId+"]",
                        value: value.paymentMethodId
                    };
                });
            });

    }
    getGIAccountsAndPaymentMethodIds(): void {
        this.spinner.show();
        this._PayablePaymentService.getGIAccountAndPaymentMethod()
            .then(data => {
                if (data) {
                    this.spinner.hide();
                    this.GIAccounts = data.data.glAccounts.map(value => {
                        return {
                            label: value.glAccountId,
                            value: value.glAccountId
                        };
                    });
                }
            });

    }
    getPartyIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.PersonsGroups.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });

    }
    getPaymentType(): void {
        this.spinner.show();
        this._PayablePaymentService.getIncomingPaymentType()
            .then(data => {
                if (data) {
                    this.spinner.hide();
                    this.paymentTypeList = data.data.paymentType.map(value => {
                        return {
                            label: value.description,
                            value: value.paymentTypeId
                        };
                    });
                    this.finAccountIds = data.data.finAccountId.map(value => {
                        return {
                            label: value.finAccountName,
                            value: value.finAccountId
                        };
                    });
                }
            });

    }
    reset(): void {
        this.payablePaymentForm.reset();
        this._Router.navigate(['/financial/receiveable/payments']);
    }
    update(): void {
        this.spinner.show();
        if (this.payablePaymentForm.valid) {
            const {
                actualCurrencyAmount, actualCurrencyUomId, amount, comments, currencyUomId, effectiveDate, finAccountTransId,
                overrideGlAccountId, partyIdFrom, partyIdTo, paymentMethodId, paymentRefNum, paymentTypeId,
                statusId
            } = this.paymentValue;
            this._PayablePaymentService.updatePayment(this.paymentId, {
                ...{
                    actualCurrencyAmount, actualCurrencyUomId, amount, comments, currencyUomId, effectiveDate, finAccountTransId,
                    overrideGlAccountId, partyIdFrom, partyIdTo,
                    paymentMethodId, paymentRefNum, paymentTypeId,
                    statusId
                }, ...this.payablePaymentForm.value, ...{
                    effectiveDate: moment(this.payablePaymentForm.value.effectiveDate).format('YYYY-MM-DD HH:mm:ss')
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.spinner.hide();
                        this.payablePaymentForm.reset();
                        this._Router.navigate(['/financial/receiveable/payments']);
                    }
                });
        }

    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            if (this.payablePaymentForm.valid) {
                const { amount, comments,
                    finAccountId, overrideGlAccountId,
                    partyIdFrom, partyIdTo,
                    paymentMethodId, paymentRefNum,
                    paymentTypeId
                } = this.payablePaymentForm.value;
                this._PayablePaymentService.createIncomingPayment({
                    amount, comments, finAccountId,
                    overrideGlAccountId, partyIdFrom,
                    partyIdTo, paymentMethodId,
                    paymentRefNum, paymentTypeId
                })
                    .then(data => {
                        if (data.success) {
                            this._ToastrService.success('Created');
                            this.payablePaymentForm.reset();
                            this.spinner.hide();
                            this.closebutton.nativeElement.click();
                            this.getAllPaymentList();
                            //    this._Router.navigate(['/financial/receiveable/payments']);
                        }
                    });
            }
        }

    }
    ngOnDestroy(): void {
        this.spinner.hide();
    }
}