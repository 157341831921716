import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.css']
})
export class PartsComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  activeCategory = 9;
  activeCategories = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  equipmentType: any;
  addServiceDescriptionForm: FormGroup;
  addServiceTrackForm: FormGroup;
  equipmentId: any;
  fsmEquipmentServiceList: any;
  show: boolean;
  equipmentServiceId: any;
  Priority: any;
  PriorityArray: any[] = [];
  equipmentTrack: any;
  showTrack: boolean;
  FsmEquipmentTrackList: any;
  skillId: any;
  skillIdArray: any[] = [];
  yesNoArray: { label: string; value: string; }[];
  skillID: any;
  priorityArray: { label: string; value: string; }[];
  pro: any;
  proTrack: any;
  addServiceCheckListForm: FormGroup;
  fsmEquipmentPartList: any;
  partForm: FormGroup;
  fsmEquipmentId: any;
  fsmEquipmentIdArray: any[]=[];
  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) {
    this.partForm = this._FormBuilder.group({
      trackName: "",
      priority: "",
      partName: "",
      description: "",
      equipmentId: ""
    });
  
   
    this.yesNoArray =
      [{
        label: "Y",
        value: "Y",
      },
      {
        label: "N",
        value: "N",
      },
      ]
      this.priorityArray =
      [{
        label: "Urgent",
        value: "Urgent",
      },
      {
        label: "Non-Urgent",
        value: "Non-Urgent",
      },
      ]
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.equipmentId = params.equipmentId;
    })
    this.show = false;
    this.showTrack = false;
    this.getFsmEquipmentPart();
    this.getFsmEquipmentId();
  }
  getFsmEquipmentId() {
    this.spinner.show();
    this.myContactsService.getFsmEquipmentId().subscribe(res => {
      this.fsmEquipmentId = res.data[0].getFsmEquipment;
      this.spinner.hide();
      for (const value of this.fsmEquipmentId) {
        this.fsmEquipmentIdArray.push({
          label: value.equipmentId,
          value: value.equipmentId
        })
      }
    })
  }
  reset() {
    this.showTrack = false;
    this.partForm.reset();

  }
  update(product) {
    this.showTrack = true;
    this.proTrack = product;
      setTimeout(() => {
        const formValue = this.partForm;
        formValue.patchValue({
          trackName: this.proTrack.trackName ,
          priority: this.proTrack.priority ,
          partName: this.proTrack.partName ,
          description: this.proTrack.description ,
          equipmentId: this.proTrack.equipmentId
        })
      }, 2000);
  }
 
  updateFsmEquipmentPart() {
    this.spinner.show();
    const requestData = {
      "description": this.partForm.value.description ,
  "equipmentId": this.partForm.value.equipmentId ,
  "equipmentPartsId":  this.proTrack.equipmentPartsId,
  "partName": this.partForm.value.partName ,
  "priority": this.partForm.value.priority ,
  "trackName":  this.partForm.value.trackName ,
    }
    this.myContactsService.updateFsmEquipmentPart(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.partForm.reset();
        this.getFsmEquipmentPart();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  createFsmEquipmentPart() {
    this.spinner.show();

    const requestData = {
      "description": this.partForm.value.description ,
  "equipmentId": this.partForm.value.equipmentId ,
  "equipmentPartsId":  "",
  "partName": this.partForm.value.partName ,
  "priority": this.partForm.value.priority ,
  "trackName":  this.partForm.value.trackName ,

    }
    this.myContactsService.createFsmEquipmentPart(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.partForm.reset();
        this.getFsmEquipmentPart();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFsmEquipmentPart() {
    this.spinner.show();
    this.myContactsService.getFsmEquipmentPart().subscribe(res => {
      this.fsmEquipmentPartList = res.data[0].getFsmEquipmentPart;
      this.spinner.hide();
    })
  }
  deleteFsmEquipmentPart(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "equipmentPartsId": product.equipmentPartsId,
        }
        this.myContactsService.deleteFsmEquipmentPart(req).subscribe(res => {
          this.getFsmEquipmentPart();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }



  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
