<div class="container-fluid main-container-wrapper">
    <br>


    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Internal Job Posting</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>


            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategoryMain==1?'active':''" (click)="JobRequisition()"><a>
                            Job Requisition</a></li>
                        <li [ngClass]="activeCategoryMain==2?'active':''" (click)="InternalJobPostingApplication()"><a>
                            Internal Job Posting Application</a></li>



                    </ul>
                </div>
                <div class="w3-card-4 classCard" style="margin: 11px">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li (click)="jobInterview()"><a>
                                Job Interview</a></li>
                            <li (click)="approval()"><a>
                                Approval</a></li>
                            <li (click)="Relocation()"><a>
                                    Relocation</a></li>



                        </ul>
                    </div>
                    <div class=" bg-white color-grey" style="    padding: 15px 15px;">

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">

                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                            <span class="headingAccords">Search</span>
                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalCenter">Create New Internal Job Posting</button>

                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 65%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>	
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Application ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [options]="appIdArray" [(ngModel)]="advanceSearch.applicationId" name="applicationId" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Application ID">
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Internal Job Posting Status</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [options]="status" [(ngModel)]="advanceSearch.statusId" name="statusId" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Internal Job Posting Status">
                                                                            </p-dropdown>
                                                                        </div>
                
                
                                                                    </div>
                                                                </div>
                
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Application Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown [(ngModel)]="advanceSearch.applicationDateFromSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="applicationDateFromSearchType" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="date" [(ngModel)]="advanceSearch.applicationDateFrom" name="applicationDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="date" [(ngModel)]="advanceSearch.applicationDateTo" name="applicationDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                        </div>
                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12" style="display: none;">
                                                                    <div class="row">
                                                                        <div class="col-lg-2 form-group classInput">
                                                                            <label for="exampleInputEmail1"></label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [(ngModel)]="advanceSearch.applicationDateToSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="applicationDateToSearchType" [options]="dateIdArray" optionlabel="label" checked>
                
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2"></div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="date" [(ngModel)]="advanceSearch.applicationDateTo" name="applicationDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                        </div>
                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Approver Party</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [(ngModel)]="advanceSearch.approverPartyId" name="approverPartyId" [ngModelOptions]="{standlone:true}" [options]="approveData" optionlabel="label" placeholder="Enter Approver Party">
                
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Job Requisition ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [options]="rIdArray" [(ngModel)]="advanceSearch.jobRequisitionId" name="jobRequisitionId" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Requisition ID">
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                    </div>
                                                                </div>
                
                
                
                
                                                            </div>
        
                                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -29%;">
                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                                                <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                            </div>
        
        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <!-- <div class="w3-card-4 classCard">
                                            <div class="port-header-contact create-lead font-medium mb-0">
                                                <div class="suppliers-wrapper">
                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#exampleModalCenter">Create New Internal Job Posting</button>
                                                    
                                                </div>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Application ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" [options]="appIdArray" [(ngModel)]="advanceSearch.applicationId" name="applicationId" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Application ID">
                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">Internal Job Posting Status</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" [options]="status" [(ngModel)]="advanceSearch.statusId" name="statusId" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Internal Job Posting Status">
                                                            </p-dropdown>
                                                        </div>


                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Application Date</label>
                                                        </div>
                                                        <div class="col-lg-2" style="display: none;">
                                                            <p-dropdown [(ngModel)]="advanceSearch.applicationDateFromSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="applicationDateFromSearchType" [options]="dateIdArray" optionlabel="label" checked>

                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-2 form-group">
                                                            <input type="date" [(ngModel)]="advanceSearch.applicationDateFrom" name="applicationDateFrom" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <input type="date" [(ngModel)]="advanceSearch.applicationDateTo" name="applicationDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-lg-12" style="display: none;">
                                                    <div class="row">
                                                        <div class="col-lg-2 form-group classInput">
                                                            <label for="exampleInputEmail1"></label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [(ngModel)]="advanceSearch.applicationDateToSearchType" [ngModelOptions]="{standlone:true}" filter="true" name="applicationDateToSearchType" [options]="dateIdArray" optionlabel="label" checked>

                                                            </p-dropdown>
                                                        </div>
                                                        <div class="col-lg-2"></div>
                                                        <div class="col-lg-2 form-group">
                                                            <input type="date" [(ngModel)]="advanceSearch.applicationDateTo" name="applicationDateTo" [ngModelOptions]="{standlone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Approver Party</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" [(ngModel)]="advanceSearch.approverPartyId" name="approverPartyId" [ngModelOptions]="{standlone:true}" [options]="approveData" optionlabel="label" placeholder="Enter Approver Party">

                                                            </p-dropdown>
                                                        </div>

                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                            <label for="exampleInputEmail1">Job Requisition ID</label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown filter="true" [options]="rIdArray" [(ngModel)]="advanceSearch.jobRequisitionId" name="jobRequisitionId" [ngModelOptions]="{standlone:true}" aria-describedby="emailHelp" optionlabel="label" placeholder="Enter Requisition ID">
                                                            </p-dropdown>
                                                        </div>

                                                    </div>
                                                </div>




                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -29%;">
                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                                <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                            </div>
                                        </div> -->
                                        <br>

                                        <div class="w3-card-4 classCard">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Internal Job Posting List
                                                    </h4>

                                                </div>
                                            </header>
                                            <div>

                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="internalJobPostList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>

                                                                                    Application ID
                                                                                    <p-sortIcon field="code">

                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                <div style="color: white;">IJP Status
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                                                                <div style="color: white;">Applying Party ID
                                                                                    <p-sortIcon field="category"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                                                                <div style="color: white;">Application Date
                                                                                    <p-sortIcon field="quantity"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Approver Party
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                <div style="color: white;">Job Requisition ID
                                                                                    <p-sortIcon field="price"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>


                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                                                                <div style="color: white;">Created At
                                                                                    <p-sortIcon field="quantity"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                <div style="color: white;">Action</div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span class="account-button"
                                                                                    (click)="detailPage(product.applicationId)">
                                                                            {{product.applicationId}} </span>

                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'150px'}">{{product.statusId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                {{product.applyingPartyId}}</td>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                {{product.applicationDate | date :"yyyy-MM-dd HH:mm:ss"}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                {{product.approverPartyId}}</td>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                {{product.jobRequisitionId}}</td>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                {{product.createdStamp | date :"yyyy-MM-dd HH:mm:ss"}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div>
                                                                                    <!-- <svg class="hover"
                                                                            (click)="onUpdate(product.applicationId)"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="16" height="16"
                                                                            viewBox="0 0 21.223 21.222">
                                                                            <g transform="translate(0 -0.004)">
                                                                                <g transform="translate(0 1.52)">
                                                                                    <path
                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                        transform="translate(0 -34.137)" />
                                                                                </g>
                                                                                <g transform="translate(4.548 0.004)">
                                                                                    <g transform="translate(0 0)">
                                                                                        <path
                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg> -->
                                                                                    <svg (click)="onDelete(product.applicationId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                            <defs>
                                                                                <style>
                                                                                    .a {
                                                                                        fill: #f44336;
                                                                                    }

                                                                                    .b {
                                                                                        fill: #fafafa;
                                                                                        margin-left: 20px;
                                                                                    }
                                                                                </style>
                                                                            </defs>
                                                                            <path class="a fill-color"
                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                            <path class="b"
                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                transform="translate(-147.576 -147.576)" />
                                                                        </svg>
                                                                                </div>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
               <span class="color-grey ">Job Posting </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                   <span aria-hidden="true" #closebutton>&times;</span>
               </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid" style="width: 100%;">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                            New Internal Job Posting </a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="addFormService.add_job_form" [formGroup]="addFormService.add_job_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Application Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="applicationDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Application Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Approver Party <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="approverPartyId" [options]="approveData" optionlabel="label" placeholder="Enter Approver Party">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group" *ngIf="show">
                                                        <label for="exampleInputEmail1">Application ID</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="show">
                                                        <input type="email" disabled formControlName="applicationId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled placeholder="Application ID">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Job Requisition ID	
                                                        <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="jobRequisitionId" [options]="getJobRequistionArray" filter="true" optionlabel="label" placeholder="Enter Job Requisition ID">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-4 col-12">
                                                <div class="form-group">
                                                    <!-- <label for="exampleInputEmail1">Application ID</label> -->
                                                    <input type="hidden" disabled formControlName="statusId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled placeholder="Application ID">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 11%;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button>
                                            <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>