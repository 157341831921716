<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                   <span class="color-black pl-1 titlePanels">Skills</span>
                   <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>

                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>


            </div>
            <div class=" bg-white color-grey">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">

                                <div class="w3-card-4  w-100">
                                    <div class="accordion classCard" id="myAccordion">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                    <span class="headingAccords">Find Skills</span>

                                                    <button _ngcontent-cta-c128="" type="submit"
                                                        class="btn btn-secondary submit-btn ml-2" 
                                                        data-toggle="modal" data-target="#exampleModalCenter">Create New Party
                                                        Skill
                                                    </button>


                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 70%!important; padding-top: 11px !important;
                                                        padding-bottom: 11px !important;">
                                                    </button>	
                                                </div>
            
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="all-users-infomation font-13 font-medium  own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Employee ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.partyId" name="partyId"
                                                                        [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                                        placeholder="Select Employee ID" filter="true"
                                                                        [options]="partyIdArray" optionlabel="label">
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Skill Type ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.skillTypeId"
                                                                        name="skillTypeId" [ngModelOptions]="{standlone:true}"
                                                                        id="exampleInputEmail1" filter="true" [options]="skillArray"
                                                                        optionlabel="label" placeholder="Select Skill Type Id">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Year Experience</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true"
                                                                        [(ngModel)]="advanceSearch.searchExperience"
                                                                        [ngModelOptions]="{standlone:true}" name="firstNameFilter"
                                                                        [options]="opportunityIdArray" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input style="width: 100%;" type="email" class="pl-2"
                                                                        [(ngModel)]="advanceSearch.yearsExperience"
                                                                        name="yearsExperience" [ngModelOptions]="{standlone:true}"
                                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                        placeholder="Enter Year Experience">
                                                                </div>
                
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Rating</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.searchRating" name="lastName"
                                                                        [ngModelOptions]="{standlone:true}" filter="true"
                                                                        name="lastNameFilter" [options]="opportunityIdArray"
                                                                        optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input style="width: 100%;" type="email" class="pl-2"
                                                                        [(ngModel)]="advanceSearch.rating" name="rating"
                                                                        [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Enter Rating">
                                                                </div>
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Skill Level</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.searchSkillLevel"
                                                                        name="lastName" [ngModelOptions]="{standlone:true}"
                                                                        filter="true" name="lastNameFilter"
                                                                        [options]="opportunityIdArray" optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input style="width: 100%;" type="email" class="pl-2"
                                                                        [(ngModel)]="advanceSearch.skillLevel" name="skillLevel"
                                                                        [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Enter Skill Level">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Started Using Date</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.estimatedThroughDate"
                                                                        [ngModelOptions]="{standlone:true}" filter="true"
                                                                        name="estimatedThroughDate" [options]="dateIdArray"
                                                                        optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" [(ngModel)]="advanceSearch.estimatedThruDateFrom"
                                                                        name="estimatedThruDateFrom" [ngModelOptions]="{standlone:true}"
                                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                        >
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.estimatedThroughDateStart"
                                                                        [ngModelOptions]="{standlone:true}" filter="true"
                                                                        name="estimatedThroughDateStart" [options]="dateIdArray"
                                                                        optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" [(ngModel)]="advanceSearch.estimatedThruDateTo"
                                                                        name="estimatedThruDateTo" [ngModelOptions]="{standlone:true}"
                                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                        >
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12" style="display: none;">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                                    <label for="exampleInputEmail1">Started Using Date</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown [(ngModel)]="advanceSearch.estimatedThroughDateStart"
                                                                        [ngModelOptions]="{standlone:true}" filter="true"
                                                                        name="estimatedThroughDateStart" [options]="dateIdArray"
                                                                        optionlabel="label" checked>
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="date" [(ngModel)]="advanceSearch.estimatedThruDateTo"
                                                                        name="estimatedThruDateTo" [ngModelOptions]="{standlone:true}"
                                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                                        >
                                                                </div>
                                                            </div>
                                                        </div>
                
                
                                                    </div>

                                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -28%;"><button
                                                        type="submit" (click)="advanceSearchContacts(false)"
                                                        class="btn btn-secondary submit-btn">Find Skill</button>&nbsp;
                                                        <button type="button" class="btn btn-danger ml-2"
                                                            (click)="resetFilter()">Reset</button>
                                                    
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>


                                <!-- <div class="w3-card-4 classCard">
                                    <div class="port-header-contact create-lead font-medium mb-0" style="    background: #cce0e95e;
                            margin-top: 0%;">
                                        <div class="suppliers-wrapper" style="    margin-top: 14px;">
                                            <p class="h-text">Find Skills</p>
                                            <button _ngcontent-cta-c128="" type="submit"
                                                class="btn btn-secondary submit-btn" style="margin-left:95px"
                                                data-toggle="modal" data-target="#exampleModalCenter">Create New Party
                                                Skill</button>
                                            
                                        </div>
                                    </div>
                                    <br>
                                    <div class="all-users-infomation font-13 font-medium  own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Employee ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [(ngModel)]="advanceSearch.partyId" name="partyId"
                                                        [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                        placeholder="Select Employee ID" filter="true"
                                                        [options]="partyIdArray" optionlabel="label">
                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Skill Type ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [(ngModel)]="advanceSearch.skillTypeId"
                                                        name="skillTypeId" [ngModelOptions]="{standlone:true}"
                                                        id="exampleInputEmail1" filter="true" [options]="skillArray"
                                                        optionlabel="label" placeholder="Select Skill Type Id">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Year Experience</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown filter="true"
                                                        [(ngModel)]="advanceSearch.searchExperience"
                                                        [ngModelOptions]="{standlone:true}" name="firstNameFilter"
                                                        [options]="opportunityIdArray" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input style="width: 100%;" type="email" class="pl-2"
                                                        [(ngModel)]="advanceSearch.yearsExperience"
                                                        name="yearsExperience" [ngModelOptions]="{standlone:true}"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Enter Year Experience">
                                                </div>

                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Rating</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.searchRating" name="lastName"
                                                        [ngModelOptions]="{standlone:true}" filter="true"
                                                        name="lastNameFilter" [options]="opportunityIdArray"
                                                        optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input style="width: 100%;" type="email" class="pl-2"
                                                        [(ngModel)]="advanceSearch.rating" name="rating"
                                                        [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Rating">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Skill Level</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.searchSkillLevel"
                                                        name="lastName" [ngModelOptions]="{standlone:true}"
                                                        filter="true" name="lastNameFilter"
                                                        [options]="opportunityIdArray" optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input style="width: 100%;" type="email" class="pl-2"
                                                        [(ngModel)]="advanceSearch.skillLevel" name="skillLevel"
                                                        [ngModelOptions]="{standlone:true}" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Skill Level">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Started Using Date</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.estimatedThroughDate"
                                                        [ngModelOptions]="{standlone:true}" filter="true"
                                                        name="estimatedThroughDate" [options]="dateIdArray"
                                                        optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="date" [(ngModel)]="advanceSearch.estimatedThruDateFrom"
                                                        name="estimatedThruDateFrom" [ngModelOptions]="{standlone:true}"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        >
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.estimatedThroughDateStart"
                                                        [ngModelOptions]="{standlone:true}" filter="true"
                                                        name="estimatedThroughDateStart" [options]="dateIdArray"
                                                        optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="date" [(ngModel)]="advanceSearch.estimatedThruDateTo"
                                                        name="estimatedThruDateTo" [ngModelOptions]="{standlone:true}"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12" style="display: none;">
                                            <div class="row">
                                                <div class="col-lg-3 form-group classInput__new_changes">
                                                    <label for="exampleInputEmail1">Started Using Date</label>
                                                </div>
                                                <div class="col-lg-2" style="display: none;">
                                                    <p-dropdown [(ngModel)]="advanceSearch.estimatedThroughDateStart"
                                                        [ngModelOptions]="{standlone:true}" filter="true"
                                                        name="estimatedThroughDateStart" [options]="dateIdArray"
                                                        optionlabel="label" checked>

                                                    </p-dropdown>
                                                </div>

                                                <div class="col-lg-2 form-group">
                                                    <input type="date" [(ngModel)]="advanceSearch.estimatedThruDateTo"
                                                        name="estimatedThruDateTo" [ngModelOptions]="{standlone:true}"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        >
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -28%;"><button
                                            type="submit" (click)="advanceSearchContacts(false)"
                                            class="btn btn-secondary submit-btn">Find Skill</button>&nbsp;
                                        <button type="button" class="btn btn-danger ml-2"
                                            (click)="resetFilter()">Reset</button>
                                    </div>
                                </div> -->

                                <br>


                                <div class="w3-card-4 classCard">
                                    <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                            <h4 class=" common-styling h4Margin">
                                                Skill List
                                            </h4>

                                        </div>
                                    </header>
                                    <div>

                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="skillList" [paginator]="true" [rows]="rows"
                                                            scrollWidth="100%"
                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                            [totalRecords]="total" [scrollable]="true"
                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th pSortableColumn="code">
                                                                        <div class="checkbox-align"
                                                                            style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined"></div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Skill Type Id
                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th pSortableColumn="name">
                                                                        <div style="color: white;"> Years Experience
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th pSortableColumn="category">
                                                                        <div style="color: white;"> Rating
                                                                            <p-sortIcon field="category"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th pSortableColumn="quantity">
                                                                        <div style="color: white;"> Skill Level
                                                                            <p-sortIcon field="quantity"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                    <th style="width: 16%;" pSortableColumn="price">
                                                                        <div style="color: white;"> Created At
                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th pSortableColumn="price">
                                                                        <div style="color: white;"> Action</div>
                                                                        <div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                style="visibility:hidden;" value=""
                                                                                autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td  class="account-button"  
                                                                        (click)="detailPage(product.partyId,product.skillTypeId
                                ,product.rating,product.skillLevel,product.yearsExperience)">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined"></div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span class="account-button">   {{product.description}}</span>
                                                                        
                                                                        </div>
                                                                    </td>
                                                                    <td>{{product.yearsExperience}}</td>
                                                                    <td>{{product.rating}}</td>
                                                                    <td>{{product.skillLevel}}</td>

                                                                    <td>{{product.createdStamp | date :'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                    <td>
                                                                        <button type="submit"
                                                                            class="btn btn-secondary submit-btn"
                                                                            data-toggle="modal"
                                                                            data-target="#reasonModal"
                                                                            (click)="onDelete(product.partyId,product.skillTypeId)">Delete</button>
                                                                    </td>

                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p id="view" class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Add Party Skill</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Add Party Skill </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update Party Skill </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="addSkillsService.add_skill_form"
                                        [formGroup]="addSkillsService.add_skill_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Employee ID <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="partyId"
                                                            placeholder="Select Employee ID" filter="true"
                                                            [options]="partyIdArray" optionlabel="label">
                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!addSkillsService.add_skill_form.controls.partyId.invalid && !!addSkillsService.add_skill_form.controls.partyId.touched">
                                                            Employee ID is required!</small>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Skill Type ID <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="skillTypeId" filter="true"
                                                            [options]="skillArray" optionlabel="label"
                                                            placeholder="Select Skill Type Id">

                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!addSkillsService.add_skill_form.controls.skillTypeId.invalid && !!addSkillsService.add_skill_form.controls.skillTypeId.touched">
                                                            Skill Type ID is required!</small>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Years Experience</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="yearsExperience"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter year of experience">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Rating </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="rating"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Rating">


                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Skill Level</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="skillLevel"
                                                            class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter skill Level">

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="main-submit-button" style="margin-right: 13%;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmit()"
                                                class="btn btn-secondary submit-btn">Create</button>
                                            <button type="submit" *ngIf="show"
                                                class="btn btn-secondary submit-btn">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>