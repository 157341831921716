<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class="port-header-contact bg-white mt-20 mb-20 font-medium">



        <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
          <span class="color-gry cursor-pointer"
            routerLink="/financial/{{agreementType}}/customer-agreeement">Agreements
          </span>
          <span class="color-gry cursor-pointer"
            routerLink="/financial/{{agreementType}}/customer-agreeement/summary-customer-agreeement"
            [queryParams]="{agreementID:agreement.agreementId}">>Agreements Summary </span>
          <span class="color-gry cursor-pointer">>Item
            Summary </span>

        </div>

      </div>
      <div class=" bg-white color-grey">

        <div class="create-new-leade mt-2">
          <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians" style="padding-left:9px">
              <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                  Promotions</a></li>
              <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                  Terms</a></li>
              <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                  Products</a></li>
              <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                  Geo</a></li>
              <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                  Facilities</a></li>
            </ul>
          </div>
          <div class="container-fluid">
            <div class="row">
              <form class="w-100">
                <div class="port-header-contact create-lead font-medium mb-0 text-white" data-toggle="collapse"
                  href="#collapsetwo">
                  <div class="w-100">
                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                      <div class="panel panel-default">
                        <div *ngIf="activeCategory==1">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Promotions
                            </h4>
                            <span class="edit-buttons"
                              routerLink="/financial/{{agreementType}}/customer-agreeement/create-item-promotion"
                              [queryParams]="{agreementId:agreement.agreementId,agreementItemSeqId:agreement.agreementItemSeqId}">Create</span>
                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">

                              <div class="card own-account-table">
                                <p-table [value]="agreementPromotions" [paginator]="true" [rows]="rows"
                                  scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                  [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                  <ng-template pTemplate="header">
                                    <tr style="background: #0d3769;">
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                        <div class="checkbox-align">
                                          <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                              <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                  name="undefined" value="undefined">
                                              </div>
                                              <div role="checkbox"
                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                  class="ui-chkbox-icon ui-clickable"></span></div>
                                            </div>

                                          </p-checkbox>
                                          <div style="color: white;">  Product Promo ID</div><p-sortIcon field="code"></p-sortIcon>
                                        </div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"><div style="color: white;">From Date
                                        <p-sortIcon field="name"></p-sortIcon></div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"><div style="color: white;"> Thru Date
                                        <p-sortIcon field="name"></p-sortIcon></div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'120px'}" pSortableColumn="name"><div style="color: white;">Sequence Num
                                        <p-sortIcon field="name"></p-sortIcon></div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"><div style="color: white;"> Product Promo Name
                                        <p-sortIcon field="category">
                                        </p-sortIcon></div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>

                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"><div style="color: white;"> Created Date
                                        <p-sortIcon field="category">
                                        </p-sortIcon></div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Action</div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          style="visibility:hidden;" value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                    </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-product>
                                    <tr>
                                      <td [ngStyle]="{'width':'170px'}">
                                        <div class="checkbox-align">
                                          <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                              <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                  name="undefined" value="undefined">
                                              </div>
                                              <div role="checkbox"
                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                  class="ui-chkbox-icon ui-clickable"></span></div>
                                            </div>
                                          </p-checkbox>
                                          {{product.productPromoId}}
                                        </div>



                                      </td>
                                      <td [ngStyle]="{'width':'170px'}">
                                        {{product.fromDate | date:'yyyy-MM-d hh:mm:ss'}}
                                      </td>
                                      <td [ngStyle]="{'width':'170px'}">
                                        {{product.thruDate | date:'yyyy-MM-d hh:mm:ss'}}</td>
                                      <td [ngStyle]="{'width':'120px'}">{{product.sequenceNum}}</td>
                                      <td [ngStyle]="{'width':'170px'}">{{product.promoName}}</td>
                                      <td [ngStyle]="{'width':'170px'}">
                                        {{product.createdStamp |date :'yyyy-MM-dd HH:mm:ss'}}</td>
                                      <td [ngStyle]="{'width':'170px'}">
                                        <fa-icon [icon]="faEdit"  class="account-button" 
                                          routerLink="/financial/{{agreementType}}/customer-agreeement/create-item-promotion"
                                          [queryParams]="{agreementId:agreement.agreementId,agreementItemSeqId:product.agreementItemSeqId,fromDate:product.fromDate,promoId:product.productPromoId}">
                                        </fa-icon>
                                        <fa-icon [icon]="faTrash" class="text-danger ml-2 cursor-pointer"
                                          (click)="deleteItmPromotion(product.fromDate,product.productPromoId)">
                                        </fa-icon>
                                      </td>
                                    </tr>
                                  </ng-template>
                                  <ng-template pTemplate="emptymessage" let-columns>
                                    <tr>
                                      <td>
                                        <span class="text-center">
                                          <h4 class="ml-2">No Record Found</h4>
                                        </span>
                                      </td>
                                    </tr>
                                  </ng-template>
                                </p-table>
                                <!-- <p class="paginate_data">
                                    View per page - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                <p class="paginate_data">
                                  View per page </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==2">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Terms
                            </h4>
                            <span class="edit-buttons"
                              routerLink="/financial/{{agreementType}}/customer-agreeement/create-item-term"
                              [queryParams]="{agreementId:agreement.agreementId,agreementItemSeqId:agreement.agreementItemSeqId}">Create</span>
                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">

                              <div class="card own-account-table">
                                <p-table [value]="agreementItemTerms" [paginator]="true" [rows]="rows"
                                  scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                  [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                  <ng-template pTemplate="header">
                                    <tr style="background: #0d3769;">
                                      <th [ngStyle]="{'width':'250px'}" pSortableColumn="code">
                                        <div class="checkbox-align">
                                          <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                              <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                  name="undefined" value="undefined">
                                              </div>
                                              <div role="checkbox"
                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                  class="ui-chkbox-icon ui-clickable"></span></div>
                                            </div>

                                          </p-checkbox>
                                          <div style="color: white;">  Term Type ID</div><p-sortIcon field="code"></p-sortIcon>
                                        </div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"> <div style="color: white;">
                                        Invoice Item Type
                                        <p-sortIcon field="name"></p-sortIcon></div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"><div style="color: white;"> From Date <p-sortIcon
                                          field="name"></p-sortIcon></div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"><div style="color: white;"> Through Date
                                        <p-sortIcon field="category">
                                        </p-sortIcon></div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity"><div style="color: white;">Term Value
                                        <p-sortIcon field="quantity">
                                        </p-sortIcon></div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Term Days</div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          style="visibility:hidden;" value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Text Value</div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          style="visibility:hidden;" value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Min Quantity</div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          style="visibility:hidden;" value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Max Quantity</div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          style="visibility:hidden;" value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Description</div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          style="visibility:hidden;" value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Action</div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          style="visibility:hidden;" value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                    </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-product>
                                    <tr>
                                      <td [ngStyle]="{'width':'250px'}">
                                        <div class="checkbox-align">
                                          <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                              <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                  name="undefined" value="undefined">
                                              </div>
                                              <div role="checkbox"
                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                  class="ui-chkbox-icon ui-clickable"></span></div>
                                            </div>
                                          </p-checkbox>
                                          {{product.termTypeDescription}}
                                        </div>
                                      </td>
                                      <td [ngStyle]="{'width':'170px'}">{{product.invoiceItemTypeDescription}}</td>
                                      <td [ngStyle]="{'width':'170px'}">
                                        {{product.fromDate | date:'yyyy-MM-d hh:mm:ss'}}</td>
                                      <td [ngStyle]="{'width':'170px'}">
                                        {{product.thruDate | date:'yyyy-MM-d hh:mm:ss'}}</td>
                                      <td [ngStyle]="{'width':'170px'}">{{product.termValue}}</td>
                                      <td [ngStyle]="{'width':'170px'}">{{product.termDays}}</td>
                                      <td [ngStyle]="{'width':'170px'}">{{product.textValue}}</td>
                                      <td [ngStyle]="{'width':'170px'}">{{product.minQuantity}}</td>
                                      <td [ngStyle]="{'width':'170px'}">{{product.maxQuantity}}</td>
                                      <td [ngStyle]="{'width':'170px'}">{{product.description}}</td>
                                      <td [ngStyle]="{'width':'170px'}">
                                        <fa-icon [icon]="faEdit"  class="account-button" 
                                          routerLink="/financial/{{agreementType}}/customer-agreeement/create-item-term"
                                          [queryParams]="{agreementId:agreement.agreementId,agreementItemSeqId:product.agreementItemSeqId,agreementTermId:product.agreementTermId}">
                                        </fa-icon>
                                        <fa-icon [icon]="faTrash" class="text-danger ml-2 cursor-pointer"
                                          (click)="deleteAgreementItemTerm(product.agreementTermId)"></fa-icon>
                                      </td>
                                    </tr>
                                  </ng-template>
                                  <ng-template pTemplate="emptymessage" let-columns>
                                    <tr>
                                      <td>
                                        <span class="text-center">
                                          <h4 class="ml-2">No Record Found</h4>
                                        </span>
                                      </td>
                                    </tr>
                                  </ng-template>
                                </p-table>
                                <!-- <p class="paginate_data">
                                    View per page - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                <p class="paginate_data">
                                  View per page </p>
                              </div>

                            </div>
                          </div>
                        </div>

                        <div *ngIf="activeCategory==3">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Products
                            </h4>
                            <span class="edit-buttons"
                              routerLink="/financial/{{agreementType}}/customer-agreeement/create-item-product"
                              [queryParams]="{agreementId:agreement.agreementId,agreementItemSeqId:agreement.agreementItemSeqId}">Create</span>
                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">

                              <ng-container *ngIf="isPurchasedAgreement;else sales">
                                <div class="card own-account-table">
                                  <p-table [value]="agreementProducts" [paginator]="true" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                    [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'250px'}" pSortableColumn="code">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                              binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                    name="undefined" value="undefined">
                                                </div>
                                                <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                    class="ui-chkbox-icon ui-clickable"></span></div>
                                              </div>

                                            </p-checkbox>
                                            <div style="color: white;">  Product ID<p-sortIcon field="code"></p-sortIcon>
                                        </div>  </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"><div style="color: white;"> Available From Date
                                          <p-sortIcon field="name"></p-sortIcon></div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"><div style="color: white;">Available Through
                                          Date
                                          <p-sortIcon field="name"></p-sortIcon></div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"><div style="color: white;"> Supplier Pref Order
                                          Id
                                          <p-sortIcon field="name"></p-sortIcon></div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category"> <div style="color: white;">Supplier Rating
                                          Type ID
                                          <p-sortIcon field="category">
                                          </p-sortIcon></div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity"><div style="color: white;"> Standard Lead
                                          Time
                                          Days
                                          <p-sortIcon field="quantity">
                                          </p-sortIcon></div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Min Order Qty</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Order Qty Increments</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Units Included</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Quantity Uom ID</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Last Price</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Shipping Price</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Supplier Product
                                          Name</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Supplier Product ID</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Can Drop Ship</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Comments</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Internal Name</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Action</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                      <tr>
                                        <td [ngStyle]="{'width':'250px'}">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                              binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                    name="undefined" value="undefined">
                                                </div>
                                                <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                    class="ui-chkbox-icon ui-clickable"></span></div>
                                              </div>
                                            </p-checkbox>
                                            {{product.productId}}
                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'170px'}">
                                          {{product.availableFromDate | date:'yyyy-MM-d hh:mm:ss'}}</td>
                                        <td [ngStyle]="{'width':'170px'}">
                                          {{product.availableThruDate | date:'yyyy-MM-d hh:mm:ss'}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.supplierPrefOrderId}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.supplierRatingTypeId}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.standardLeadTimeDays}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.minimumOrderQuantity}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.orderQtyIncrements}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.unitsIncluded}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.quantityUomId}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.lastPrice |currency :'USD'}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.shippingPrice |currency :'USD'}}
                                        </td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.supplierProductName}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.canDropShip}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.comments}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.productIdInternalName}}</td>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <fa-icon [icon]="faEdit"  class="account-button" 
                                            (click)="navigateToEditPage(product.productId , product.availableFromDate, product.currencyUomId, product.minimumOrderQuantity, product.partyId)">
                                          </fa-icon>
                                          <fa-icon [icon]="faTrash" class="text-danger ml-2 cursor-pointer"
                                            (click)="deletePurchasedAgreement(product.availableFromDate, product.currencyUomId, product.minimumOrderQuantity, product.partyId, product.productId)">
                                          </fa-icon>
                                        </td>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                      <tr>
                                        <td>
                                          <span class="text-center">
                                            <h4 class="ml-2">No Record Found</h4>
                                          </span>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>
                                  <!-- <p class="paginate_data">
                                      View per page - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                  <p class="paginate_data">
                                    View per page </p>
                                </div>
                              </ng-container>
                              <ng-template #sales>
                                <div class="card own-account-table">
                                  <p-table [value]="agreementProducts" [paginator]="true" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                    [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                      <tr>
                                        <th [ngStyle]="{'width':'250px'}" pSortableColumn="code">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                              binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                    name="undefined" value="undefined">
                                                </div>
                                                <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                    class="ui-chkbox-icon ui-clickable"></span></div>
                                              </div>

                                            </p-checkbox>
                                            Product ID<p-sortIcon field="code"></p-sortIcon>
                                          </div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"> Price
                                          <p-sortIcon field="name"></p-sortIcon>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"> Internal Name
                                          <p-sortIcon field="name"></p-sortIcon>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">Action
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                      <tr>
                                        <td [ngStyle]="{'width':'250px'}">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                              binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                    name="undefined" value="undefined">
                                                </div>
                                                <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                    class="ui-chkbox-icon ui-clickable"></span></div>
                                              </div>
                                            </p-checkbox>
                                            {{product.productId}}
                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.price |currency :'USD'}}</td>
                                        <td [ngStyle]="{'width':'170px'}">{{product.productIdInternalName }}</td>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <fa-icon [icon]="faEdit"  class="account-button" 
                                            routerLink="/financial/{{agreementType}}/customer-agreeement/create-item-product"
                                            [queryParams]="{agreementId:agreement.agreementId,agreementItemSeqId:product.agreementItemSeqId,agreementTermId:product.agreementTermId,productId:product.productId}">
                                          </fa-icon>
                                          <fa-icon [icon]="faTrash" class="text-danger ml-2 cursor-pointer"
                                            (click)="deleteSaleAgreement(product.productId)"></fa-icon>
                                        </td>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                      <tr>
                                        <td>
                                          <span class="text-center">
                                            <h4 class="ml-2">No Record Found</h4>
                                          </span>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>
                                  <!-- <p class="paginate_data">
                                      View per page - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                  <p class="paginate_data">
                                    View per page </p>
                                </div>
                              </ng-template>

                            </div>
                          </div>
                        </div>
                        <div *ngIf="activeCategory==4">
                          <div class="header-tabs">
                            <h4 class=" common-styling">
                              Geo
                            </h4>
                            <span class="edit-buttons"
                              routerLink="/financial/{{agreementType}}/customer-agreeement/create-item-geo"
                              [queryParams]="{agreementId:agreement.agreementId,agreementItemSeqId:agreement.agreementItemSeqId}">Create</span>
                          </div>
                          <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">

                              <div class="card own-account-table">
                                <p-table [value]="agreementItemGioList" [paginator]="true" [rows]="rows"
                                  scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                  [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                  <ng-template pTemplate="header">
                                    <tr style="background: #0d3769;">
                                      <th [ngStyle]="{'width':'250px'}" pSortableColumn="code">
                                        <div class="checkbox-align">
                                          <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                              <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                  name="undefined" value="undefined">
                                              </div>
                                              <div role="checkbox"
                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                  class="ui-chkbox-icon ui-clickable"></span></div>
                                            </div>

                                          </p-checkbox><div style="color: white;">
                                          Description<p-sortIcon field="code"></p-sortIcon></div>
                                        </div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                      <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"><div style="color: white;">Action</div>
                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                          name="j_idt727:j_idt728:j_idt731:filter"
                                          class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                          style="visibility:hidden;" value="" autocomplete="off"
                                          aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                          aria-disabled="false" aria-readonly="false">
                                      </th>
                                    </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-product>
                                    <tr>
                                      <td [ngStyle]="{'width':'250px'}">
                                        <div class="checkbox-align">
                                          <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                              <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                  name="undefined" value="undefined">
                                              </div>
                                              <div role="checkbox"
                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                  class="ui-chkbox-icon ui-clickable"></span></div>
                                            </div>
                                          </p-checkbox>
                                          {{product.description}}
                                        </div>
                                      </td>
                                      <td [ngStyle]="{'width':'170px'}">
                                        <fa-icon [icon]="faTrash" class="text-danger ml-2 cursor-pointer"
                                          (click)="deleteAgreementGioIds(product.geoId)"></fa-icon>
                                      </td>
                                    </tr>
                                  </ng-template>
                                  <ng-template pTemplate="emptymessage" let-columns>
                                    <tr>
                                      <td>
                                        <span class="text-center">
                                          <h4 class="ml-2">No Record Found</h4>
                                        </span>
                                      </td>
                                    </tr>
                                  </ng-template>
                                </p-table>
                                <!-- <p class="paginate_data">
                                    View per page - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                <p class="paginate_data">
                                  View per page </p>
                              </div>


                            </div>
                          </div>
                        </div>

                        <ng-container *ngIf="!isPurchasedAgreement">
                          <div *ngIf="activeCategory==5">
                            <div class="header-tabs">
                              <h4 class=" common-styling">
                                Facilities
                              </h4>
                              <span class="edit-buttons"
                                routerLink="/financial/{{agreementType}}/customer-agreeement/create-item-facility"
                                [queryParams]="{agreementId:agreement.agreementId,agreementItemSeqId:agreement.agreementItemSeqId}">Create</span>
                            </div>
                            <div class="panel-body">
                              <div class="all-users-infomation font-13 font-medium own-user-select">

                                <div class="card own-account-table">
                                  <p-table [value]="agreementItemFacilities" [paginator]="true" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                    [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                      <tr style="background: #0d3769;">
                                        <th [ngStyle]="{'width':'250px'}" pSortableColumn="code">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                              binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                    name="undefined" value="undefined">
                                                </div>
                                                <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                    class="ui-chkbox-icon ui-clickable"></span></div>
                                              </div>

                                            </p-checkbox>
                                            <div style="color: white;">
                                            Internal Name<p-sortIcon field="code"></p-sortIcon>
                                          </div></div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="price"> <div style="color: white;">Action</div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            style="visibility:hidden;" value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                      <tr>
                                        <td [ngStyle]="{'width':'250px'}">
                                          <div class="checkbox-align">
                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                              binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                    name="undefined" value="undefined">
                                                </div>
                                                <div role="checkbox"
                                                  class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                    class="ui-chkbox-icon ui-clickable"></span></div>
                                              </div>
                                            </p-checkbox>
                                            {{product.facilityId}}
                                          </div>
                                        </td>
                                        <td [ngStyle]="{'width':'170px'}">
                                          <fa-icon [icon]="faTrash" class="text-danger ml-2 cursor-pointer"
                                            (click)="deleteAgreementItemFacility(product.facilityId)"></fa-icon>
                                        </td>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage" let-columns>
                                      <tr>
                                        <td>
                                          <span class="text-center">
                                            <h4 class="ml-2">No Record Found</h4>
                                          </span>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>
                                  <!-- <p class="paginate_data">
                                    View per page - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                  <p class="paginate_data">
                                    View per page </p>
                                </div>


                              </div>
                            </div>
                          </div>
                        </ng-container>




                      </div>




                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>