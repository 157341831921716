import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

@Component({
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.css']
})
export class RequestListComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 9;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  projectForm: FormGroup;
  timesheetForm: FormGroup;
  newrequestForm: FormGroup;
  MainPageRequestListDetails: any;
  MainPageSecondRequestListDetails: any;
  CustRequestTypeIDArray: any[]=[];
  CustRequestTypeID: any;
  ParentCustRequestId: any;
  ParentCustRequestIdArray: any[]=[];
  ProjectAndPhaseNameArray: any[]=[];
  ProjectAndPhaseName: any;
  PriorityListArray: any[]=[];
  PriorityList: any;
  PartyId: any;
  PartyIdArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  yesNOArray: { label: string; value: string; }[];
  ResponseRequiredDate: string;
  RequestDate: string;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly myContactsService: MyContactsService,
    readonly accountsService: AccountsService,
    private _location: Location,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
        readonly _FormBuilder: FormBuilder,
        readonly toastr: ToastrService,
    readonly _Router: Router
  ) { 
 
    this.newrequestForm = this._FormBuilder.group({
      RequestingParty: [''],
      CustRequestName: [''],
      CustRequestTypeID: [''],
      Description: [''],
      ShortDescription: [''],
      Priority: [''],
      ProjectandPhaseName: [''],
      Party: [''],
      RequestDate: [''],
      ResponseRequiredDate: [''],
      CustEstimatedMilliSeconds: [''],
      CustSequenceNum: [''],
      ParentCustRequestID: [''],
      Billed: [''],
      Story: [''],
      WebSiteID: [''],
     

    });
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];

  }

  ngOnInit(): void {
    this.getMainPageRequestListDetails();
    this.getMainPageSecondRequestListDetails();
    this.getCustRequestTypeID();
    this.getParentCustRequestId();
    this.getProjectAndPhaseName();
    this.getPriorityList();
    this.getPartyIdList();
    this.getPartyId();
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  setCustRequestStatus(product) {
    this.spinner.show();
    const fromData = {

      "custRequestId": product.data.custRequestId,
      "statusId": "CRQ_REJECTED",
      "view_INDEX_1": "20",
      "view_SIZE_1": "0"
    }
    this._Router.navigate(["/psa/project/detail-new-request"], 
    { queryParams: { custRequestId:product.data.custRequestId, custRequestName:product.data.custRequestName} })
    this.myContactsService.setCustRequestStatus(fromData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success(res.debugMessage);
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  getProjectAndPhaseName() {
    this.spinner.show();
    this.myContactsService.getProjectAndPhaseName().subscribe(res => {
      this.ProjectAndPhaseName = res.data;
      this.spinner.hide();
      for (const value of this.ProjectAndPhaseName) {
        this.ProjectAndPhaseNameArray.push({
          label: value.data.phaseName + "[" + value.data.projectName + "]",
          value: value.data.projectId
        })
      }
    })
  }
  getPartyIdList() {
    this.spinner.show();
    this.myContactsService.getPartyIdList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }
  getPriorityList() {
    this.spinner.show();
    this.myContactsService.getPriorityList().subscribe(res => {
      this.PriorityList = res.data;
      this.spinner.hide();
      for (const value of this.PriorityList) {
        this.PriorityListArray.push({
          label: value.description,
          value: value.priorityId
        })
      }
    })
  }
  getParentCustRequestId() {
    this.spinner.show();
    this.myContactsService.getParentCustRequestId().subscribe(res => {
      this.ParentCustRequestId = res.data;
      this.spinner.hide();
      for (const value of this.ParentCustRequestId) {
        this.ParentCustRequestIdArray.push({
          label: value.data.custRequestName,
          value: value.data.custRequestId
        })
      }
    })
  }
  getCustRequestTypeID() {
    this.spinner.show();
    this.myContactsService.getCustRequestTypeID().subscribe(res => {
      this.CustRequestTypeID = res.data;
      this.spinner.hide();
      for (const value of this.CustRequestTypeID) {
        this.CustRequestTypeIDArray.push({
          label: value.data.description,
          value: value.data.custRequestTypeId
        })
      }
    })
  }
  createRequest() {
    this.spinner.show();
    const requestDate = this.newrequestForm.get('RequestDate').value;
    this.RequestDate = this.datePipe.transform(requestDate, "yyyy-MM-dd hh:mm:ss");
    
    const responseRequiredDate = this.newrequestForm.get('ResponseRequiredDate').value;
    this.ResponseRequiredDate = this.datePipe.transform(responseRequiredDate, "yyyy-MM-dd");
    const requestData = {
      "billed": this.newrequestForm.value.Billed ,
      "communicationEventId": "",
      "createdByUserLogin": "",
      "createdDate": "",
      "currentStatusId": "PTS_CREATED",
      "custEstimatedMilliSeconds": this.newrequestForm.value.CustEstimatedMilliSeconds ,
      "custRequestDate":this.RequestDate ,
      "custRequestId": "",
      "custRequestName": this.newrequestForm.value.CustRequestName ,
      "custRequestTypeId":this.newrequestForm.value.CustRequestTypeID ,
      "custSequenceNum": this.newrequestForm.value.CustSequenceNum ,
      "description":this.newrequestForm.value.Description ,
      "form": "list",
      "fromPartyId": this.newrequestForm.value.RequestingParty ,
      "lastModifiedByUserLogin": "",
      "lastModifiedDate": "",
      "parentCustRequestId": this.newrequestForm.value.ParentCustRequestID ,
      "partyId": this.newrequestForm.value.Party,
      "portalPageId": "",
      "priority":this.newrequestForm.value.Priority ,
      "responseRequiredDate": this.ResponseRequiredDate ,
      "roleTypeId": "PROJECT_TEAM" ,
      "statusId": "CRQ_SUBMITTED",
      "story":this.newrequestForm.value.Story ,
      "webSiteId": this.newrequestForm.value.WebSiteID ,
      "workEffortName": "",
      "workEffortParentId": this.newrequestForm.value.ProjectandPhaseName ,
      "workEffortTypeId": "TASK"
    } 

    this.myContactsService.createRequest(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.newrequestForm.reset();
        this.getMainPageRequestListDetails();
        

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  detailTask(workEffortId) {
    this._Router.navigate(["/psa/project/detail-task-project"], { queryParams: { workEffortId:workEffortId} })
  }
  detailProject(projectID) {
    this._Router.navigate(["/psa/project/detail-project-summary"], { queryParams: { projectID:projectID} })
  }
  newRequestPage(custRequestName,custRequestId,fromPartyId) {
    this._Router.navigate(["/psa/project/create-task-from-request"], { queryParams: {custRequestName:custRequestName ,custRequestId:custRequestId, fromPartyId:fromPartyId} })
  }
  summaryPage(id,custRequestName) {
    this._Router.navigate(["/psa/project/detail-new-request"], { queryParams: { custRequestId:id, custRequestName:custRequestName} })
  }
  myProfilePage(fromPartyId) {
    this._Router.navigate(["/psa/my-profile"], { queryParams: { partyId:fromPartyId} })
  }

  getMainPageSecondRequestListDetails() {
    this.spinner.show();
    this.myContactsService.getMainPageSecondRequestListDetails().subscribe(res => {
      this.MainPageSecondRequestListDetails = res.data;
      this.spinner.hide();
    })
  }

  getMainPageRequestListDetails() {
    this.spinner.show();
    this.myContactsService.getMainPageRequestListDetails().subscribe(res => {
      this.MainPageRequestListDetails = res.data;
      this.spinner.hide();
    })
  }
  projectMain() {
    this._Router.navigate(["/psa/project/project"])
  }
  myTasks() {
    this._Router.navigate(["/psa/project/my-tasks"])
  }
  myTime() {
    this._Router.navigate(["/psa/project/my-time"])
  }
  projects() {
    this._Router.navigate(["/psa/project/projects"])
  }
  tasks() {
    this._Router.navigate(["/psa/project/tasks-project"])
  }
  resources() {
    this._Router.navigate(["/psa/project/resources"])
  }
  timesheet() {
    this._Router.navigate(["/psa/project/timesheet-project"])
  }
  
  skillType() {
    this._Router.navigate(["/psa/project/skill-type"])
  }
  requestList() {
    this._Router.navigate(["/psa/project/request-list"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
