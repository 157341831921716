import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(readonly  _Router: Router) {

    }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      
        if (window.localStorage.getItem('token')) {
            this._Router.navigate(['/crm/crm-dashboard']);
        } else {

            return Promise.resolve(false);
        }
    }
}