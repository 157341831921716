import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-type-attribute',
  templateUrl: './type-attribute.component.html',
  styleUrls: ['./type-attribute.component.css']
})
export class TypeAttributeComponent implements OnInit {
  activeCategory=4;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  dataResourceTypeAttributeForm: FormGroup;
  show: boolean;
  dataResourceTypeList: any;
  DataResourceTypeAttrList: any;
  dataResourceTypeId: any;
  dataResourceTypeIdArray: any[]=[];
  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly myContactsService: MyContactsService,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly toastr: ToastrService,
    private _location: Location,
  ) 
  {
    this.dataResourceTypeAttributeForm=this._FormBuilder.group({
      description:"",
      dataResourceTypeID:"",
      attrName:"",
    })
  }
 ngOnInit(): void {
  this.DataResourceTypeAttr();
  this.dataResourceTypeIdDropDown();
}


  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  
dataResourceTypeIdDropDown() {
  this.spinner.show();
  this.myContactsService.dataResourceTypeIdDropDown().subscribe(res => {
    this.dataResourceTypeId = res.data[0].dataResourceTypeId;
    this.spinner.hide();
    for (const value of this.dataResourceTypeId) {
      this.dataResourceTypeIdArray.push({
        label: value.description,
        value: value.dataResourceTypeId
      })
    }
  })
}
reset(){
  this.dataResourceTypeAttributeForm.reset();
  this.show = false;
}
update(product) {
  this.show = true;
  setTimeout(() => {
    const form = this.dataResourceTypeAttributeForm;
    form.patchValue({
      description: product.description,
      dataResourceTypeID: product.dataResourceTypeId,
      attrName: product.attrName
    })
  }, 2000);
}
DataResourceTypeAttr(){
  this.spinner.show();
  this.myContactsService.DataResourceTypeAttr().subscribe(res=>{
    this.DataResourceTypeAttrList = res.data[0].DataResourceTypeAttrById;
    this.spinner.hide();
  })
}
removeDataResourceTypeAttr(product) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this data.!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, keep it'
  }).then((result) => {
    if (result.value) {

      const req = {
        "attrName": product.attrName ,
        "dataResourceTypeId": product.dataResourceTypeId ,
        "view_INDEX_1": "0",
        "view_SIZE_1": "20"
      }
      this.myContactsService.removeDataResourceTypeAttr(req).subscribe(res => {
        this.DataResourceTypeAttr();
      })
      Swal.fire(
        'Deleted!',
        'Deleted Successfully.',
        'success'
      )
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      Swal.fire(
        'Cancelled',
        'Your data is safe :)',
        'error'
      )
    }
  })
}
addDataResourceTypeAttr() {
  this.spinner.show();
  const requestData =
  {
    "attrName": this.dataResourceTypeAttributeForm.value.attrName ,
    "dataResourceTypeId": this.dataResourceTypeAttributeForm.value.dataResourceTypeID ,
    "description":  this.dataResourceTypeAttributeForm.value.description ,
  }
  this.myContactsService.addDataResourceTypeAttr(requestData).subscribe((res: any) => {
    if (res.success) {
      this.toastr.success("Created Successfully");
      this.spinner.hide();
      this.closebutton2.nativeElement.click();
      this.dataResourceTypeAttributeForm.reset();
      this.DataResourceTypeAttr();
    }
  }, (err) => {
    this.spinner.hide();
    if (err.error.errors) {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    }
    else {
      this.toastr.error(err.error.message);
    }
  });
}
  changeactiveCategory(tab: number)
  {
    this.activeCategory = tab;
  }
  openType()
  {
    this._Router.navigate(['/content/data-resource-setup']);
  }
  openCharcterSet()
  {
    this._Router.navigate(['/content/data-resource-setup/character-set']);
  }
  openCategory()
  {
    this._Router.navigate(['/content/data-resource-setup/category']);
  }
  openTypeAttribute()
  {
    this._Router.navigate(['/content/data-resource-setup/type-attribute']);
  }
  openFileExt()
  {
    this._Router.navigate(['/content/data-resource-setup/file-ext']);
  }
  openMetaDataPredicate()
  {
    this._Router.navigate(['/content/data-resource-setup/metadata-predicate']);
  }
  openMimeType()
  {
    this._Router.navigate(['/content/data-resource-setup/mimetype']);
  }
  openMimeTypeTemplate()
  {
    this._Router.navigate(['/content/data-resource-setup/mimetype-template']);
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;
 
        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
 
    
 }
}
