<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCardView w-100">
                                <div class="d-flex justify-content-between bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    <span class="color-black pl-1 titlePanels">Report Builder</span>
                                    <span>
                                        <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toReports()">Home</button>
                                        <button _ngcontent-jra-c762="" type="submit" class="btn btn-danger buttonclass" (click)="backSubmit()">Back</button>
                    
                                    </span>
                                    
                                    <!-- <button type="submit" class="btn btn-secondary submit-btn ml-2" style="width: 113px;" data-toggle="modal" data-target="#exampleCreateNewContactList">Create New Contact List
                                    </button> -->
                                </div>
                                <div class="create-new-leade">
                                    <div class="container-fluid" style="padding: 0;">
                                        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div class="w-100">
                                                <div class="w3-card-4 classCard" *ngIf="!showTable">
                
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        Star Schema

                                                        
                                                    </div>
                                                      <form [formGroup]="selectStarSchema" >
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                
                                                            
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="exampleInputEmail1">Select the start schema (report)	
                                                                </label>
                                                            </div>
                                                            
                                                            <div class="col-lg-2">
                                                        
                                                                <p-dropdown filter="true" optionlabel="label"
                                                                  formControlName="startschema" [options]="dropdownBI"  placeholder="Select the start schema"></p-dropdown> 
                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                    
                                                </div>
                                                </form>
                                                <div style=" margin-left: -59%;" class="col-lg-12 col-12 main-submit-button ">
                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                       (click)="submit()" >Submit</button>&nbsp;
                                                    
                                                </div>
                                                </div><br>
                                                <div class="w3-card-4 classCardView mt-2" *ngIf="showTable">
                                                    <div class="header-tabs" >
                                                        <h4 class="common-styling h4Margin">
                                                            Select Star Schema Fields
                                                               
                                                        </h4>
                                                    </div>
                                                    <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                
                                                                <div class="card own-account-table">
                                                                <p-table [value]="ReportBuilderSelectData" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [responsive]="true" [totalRecords]="total" [scrollable]="true"
                                                                styleClass="p-datatable-customers"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" 
                                                                                (click)="markCheckPrint($event)" 
                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined"
                                                                                                value="undefined"></div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                Field Name	 <p-sortIcon field="code">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                       
                                                                      
                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                            pSortableColumn="quantity">  <div style="color: white;"> Field Description
	
                                                                            <p-sortIcon field="quantity"></p-sortIcon></div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'190px'}"
                                                                        pSortableColumn="quantity">  <div style="color: white;"> Action

                                                                        <p-sortIcon field="quantity"></p-sortIcon></div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                       
                                                                        
                                                                        
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox 
                                                                                (click)="payment(product.name)" 
                                                                                _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                            </p-checkbox>
                                                                                <span 
                                                                                    >{{product.name}}</span>
                                                                            </div>
                                                                        </td>
                                                
                                                
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                            {{product.description}}
                                                                        </td>
                                                                      
                                                                        <td [ngStyle]="{'width':'190px'}">
                                                                             <button type="submit" class="btn btn-secondary submit-btn ml-2" 
                                                                             style="width: 113px;" (click)="detailPage()"
                                                                             >Render the Report</button> 
                                                                        </td>
                                                                        
                                                                      
                                                
                                                                    </tr>
                                                
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No Record Found</h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                                    
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                
                                                                </div>
                                                            </div>
                                                            <button type="submit" class="btn btn-secondary submit-btn ml-2" 
                                                                             style="width: 113px;" (click)="nextPage()"
                                                                             >Render the Report</button> 
                                                        </div>
                                                    </div>
                                                </div>

                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
