<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Config Items</span>

             
                <span class="color-grey search-icons">
                    <div class="search-option">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.361" height="20.361"
                            viewBox="0 0 20.361 20.361">
                            <defs>
                                <style>
                                    .a {
                                        fill: #65787e;
                                    }
                                </style>
                            </defs>
                            <g transform="translate(-4.02 -4.02)">
                                <g transform="translate(4.02 4.02)">
                                    <path class="a"
                                        d="M24.179,23,18.83,17.652a8.337,8.337,0,1,0-1.178,1.178L23,24.179A.836.836,0,0,0,24.179,23ZM7.631,17.092a6.645,6.645,0,1,1,4.726,1.974,6.686,6.686,0,0,1-4.726-1.974Z"
                                        transform="translate(-4.02 -4.02)" />
                                </g>
                            </g>
                        </svg>
                        <input class="form-control" type="text" placeholder="Search">
                    </div>
                    <div class="search-option filter-bar">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                        <input class="form-control" type="text" placeholder="Filters">
                    </div>
                    <div class="search-option group-by">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.4" height="16.866" viewBox="0 0 18.4 16.866">
                            <defs>
                                <style>
                                    .a {
                                        fill: #65787e;
                                    }
                                </style>
                            </defs>
                            <g transform="translate(0 -17.067)">
                                <g transform="translate(0 17.067)">
                                    <g transform="translate(0 0)">
                                        <path class="a"
                                            d="M17.633,17.067H.767a.767.767,0,1,0,0,1.533H17.633a.767.767,0,1,0,0-1.533Z"
                                            transform="translate(0 -17.067)" />
                                    </g>
                                </g>
                                <g transform="translate(0 24.734)">
                                    <g transform="translate(0 0)">
                                        <path class="a"
                                            d="M17.633,187.733H.767a.767.767,0,1,0,0,1.533H17.633a.767.767,0,1,0,0-1.533Z"
                                            transform="translate(0 -187.733)" />
                                    </g>
                                </g>
                                <g transform="translate(0 32.4)">
                                    <g transform="translate(0 0)">
                                        <path class="a"
                                            d="M17.633,358.4H.767a.767.767,0,1,0,0,1.533H17.633a.767.767,0,1,0,0-1.533Z"
                                            transform="translate(0 -358.4)" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <input class="form-control" type="text" placeholder="Group By">
                    </div>
                    <div class="search-option Favourites-bar">
                        <svg xmlns="http://www.w3.org/2000/svg" width="23.588" height="22.416"
                            viewBox="0 0 23.588 22.416">
                            <g transform="translate(-9.8 -11.3)">
                                <g transform="translate(9.8 11.3)">
                                    <path
                                        d="M25.213,18.684,21.579,11.3l-3.633,7.384L9.8,19.856,15.69,25.6l-1.377,8.117,7.267-3.839,7.3,3.839L27.5,25.6l5.89-5.743Zm2.08,12.893-5.714-3.018-5.714,3.018,1.084-6.388-4.63-4.513,6.388-.938,2.872-5.773,2.872,5.8,6.388.938-4.63,4.483Z"
                                        transform="translate(-9.8 -11.3)" />
                                </g>
                            </g>
                        </svg>
                        <input class="form-control" type="text" placeholder="Favourites">
                    </div>
                </span>
                <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>
            <div class="bg-white color-grey">
                <div class="mb-5">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="display: none !important;padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCat==2?'active':''" (click)="catalogOpen()">
                                <a>Catalogs</a>
                            </li>
                            <li [ngClass]="activeCat==3?'active':''" (click)="categoriesOpen()">
                                <a>Categories</a>
                            </li>
                            <li [ngClass]="activeCat==4?'active':''" (click)="productsOpen()">
                                <a>Products</a>
                            </li>
                            <li [ngClass]="activeCat==5?'active':''" (click)="featuresOpen()">
                                <a>Features</a>
                            </li>
                            <li [ngClass]="activeCat==6?'active':''" (click)="promosOpen()">
                                <a>Promos</a>
                            </li>
                            <li [ngClass]="activeCat==7?'active':''" (click)="priceRulesOpen()">
                                <a>Price Rules</a>
                            </li>
                            <li [ngClass]="activeCat==8?'active':''" (click)="storesOpen()">
                                <a>Stores</a>
                            </li>
                            <li [ngClass]="activeCat==9?'active':''" (click)="productStoreGroupsOpen()">
                                <a>Product Store Groups</a>
                            </li>
                            <li [ngClass]="activeCat==10?'active':''" (click)="thesaurusOpen()">
                                <a>Thesaurus</a>
                            </li>
                            <li [ngClass]="activeCat==11?'active':''" (click)="reviewsOpen()">
                                <a>Reviews</a>
                            </li>
                            <li [ngClass]="activeCat==12?'active':''" (click)="configurationsOpen()">
                                <a>Configurations</a>
                            </li>
                            <li [ngClass]="activeCat==13?'active':''" (click)="subscriptionsOpen()">
                                <a>Subscriptions</a>
                            </li>
                            <li [ngClass]="activeCat==14?'active':''" (click)="shippingOpen()">
                                <a>Shipping</a>
                            </li>
                            <li [ngClass]="activeCat==15?'active':''" (click)="imageManagementOpen()">
                                <a>Image Management</a>
                            </li>
                        </ul>
                    </div>
                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                        <div class="">
                            <div>
                                <form class="w-100">

                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" style="padding-bottom: 7px!important;
                                                        padding-top: 7px !important;" >
                                                            <!-- <span class="headingAccords"> Search Options</span> -->
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-secondary submit-btn ml-2"
                                                            routerLinkActive="active marginHeader" data-target="#newConfigItem_Popup"
                                                            data-toggle="modal">Create New Config</button>
                                                        <button *ngIf="!this.Hidebtn" type="button"
                                                            class="btn btn-secondary submit-btn ml-2 text-white bg-brown">Import</button>
                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 71%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>									
                
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Config
                                                                                Item</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control"
                                                                                id="exampleInputEmail1" name="ConfigItem"
                                                                                [(ngModel)]="advanceSearch.ConfigItem"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Config Item">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Config Item
                                                                                Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                name="ConfigItemName"
                                                                                [(ngModel)]="advanceSearch.ConfigItemName"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Config Item Name">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label
                                                                                for="exampleInputEmail1">Description</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control"
                                                                                id="exampleInputEmail1" name="Description"
                                                                                [(ngModel)]="advanceSearch.Description"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Description">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-10 col-10 main-submit-button">
                                                                    <button type="submit"
                                                                        class="btn btn-secondary submit-btn"
                                                                        (click)="advanceSearchContacts()">Find</button>&nbsp;
                                                                    <button type="submit" class="btn btn-danger ml-2"
                                                                        (click)="reset()">Reset</button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <!-- <div class="w3-card-4 classCard w-100">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <button type="button" class="btn btn-secondary submit-btn ml-2"
                                                    routerLinkActive="active" data-target="#newConfigItem_Popup"
                                                    data-toggle="modal">Create Config</button>
                                                <button type="button"
                                                    class="btn btn-secondary submit-btn ml-2 text-white bg-brown">Import</button>
                                            </div>
                                            <div class="">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <form class="w-100">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Config
                                                                                Item</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control"
                                                                                id="exampleInputEmail1" name="ConfigItem"
                                                                                [(ngModel)]="advanceSearch.ConfigItem"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Config Item">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Config Item
                                                                                Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control"
                                                                                id="exampleInputEmail1"
                                                                                name="ConfigItemName"
                                                                                [(ngModel)]="advanceSearch.ConfigItemName"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Config Item Name">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label
                                                                                for="exampleInputEmail1">Description</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control"
                                                                                id="exampleInputEmail1" name="Description"
                                                                                [(ngModel)]="advanceSearch.Description"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Description">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-10 col-10 main-submit-button">
                                                                    <button type="submit"
                                                                        class="btn btn-secondary submit-btn"
                                                                        (click)="advanceSearchContacts()">Find</button>&nbsp;
                                                                    <button type="submit" class="btn btn-danger ml-2"
                                                                        (click)="reset()">Reset</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCard w-100">
                                            <div
                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Catalog List</p>
                                            </div>
                                            <div class="create-new-leade mt-2">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <form class="w-100">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="congigList" [paginator]="true"
                                                                                [rows]="rows" scrollWidth="100%"
                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [totalRecords]="total" [scrollable]="true"
                                                                                (sortFunction)="customSort($event)"
                                                                                [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background-color:  #0d3769;color: #fff;">
                                                                                        <th pSortableColumn="code" >
                                                                                            <div class="checkbox-align"  style="color: white;">
                                                                                                <p-checkbox
                                                                                                    _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Config Item
                                                                                                <p-sortIcon field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="name">
                                                                                            <div  style="color: white;">Name</div> 
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="category">    <div  style="color: white;">Type</div> 
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="quantity">
                                                                                            <div  style="color: white;">Description</div> 
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox
                                                                                                    _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span
                                                                                                class="account-button"
                                                                                                    (click)="detailPage(product.configItemId)">
                                                                                                    {{product.configItemId}}
                                                                                                </span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>{{product.configItemName}}</td>
                                                                                        <td>{{product.configItemTypeId}}
                                                                                        </td>
                                                                                        <td>{{product.description}}</td>


                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <!-- <p class="paginate_data">
                                                                            View per page  - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="newConfigItem_Popup" tabindex="-1" role="dialog" aria-labelledby="newConfigItem_Popup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> New Config Item</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <br>


                <div class="w3-card-4 classCardPopUp" style="width: 100%;margin-top:0%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                  background: #F5F7FA!important;">

                            <li class="aSize" [ngClass]="activeCategoryInv==2?'active':''">
                                New Config Item</li>


                        </ul>
                    </div>
                    <div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form [formGroup]="configForm" class="w-100">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Config Item Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 col-3 ">
                                                        <p-dropdown placeholder="Enter Config Item Type" filter="true"
                                                            [options]="dropdownValue" optionlabel="label"
                                                            formControlName="ConfigItemType">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 col-3  ">
                                                        <label for="exampleInputEmail1">Config Item Name</label>
                                                    </div>
                                                    <div class="col-lg-3 col-3 ">
                                                        <input type="email" class="form-control"
                                                            formControlName="ConfigItemName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                                <div class="col-lg-12">
                                                    <div class="col-lg-3 col-3 form-group  classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-3 col-3 ">
                                                        <input type="email" class="form-control"
                                                        formControlName="Description" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="main-submit-button" style="margin-right: 1%;">
                                                <button type="submit"
                                                    class="btn btn-secondary bg-brown ml-3" (click)="createProductConfigItem()">Update</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>