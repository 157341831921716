<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Open Test
                </span>

                <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            
            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                        <li [ngClass]="activeCategory==1?'active':''" (click)="Backlog()"><a>
                                Product Backlog </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="Sprint()"><a>
                                Project/Sprint
                            </a></li>
                        <li [ngClass]="activeCategory==3?'active':''" (click)="MyWork()"><a>
                                My Work</a></li>
                        <li [ngClass]="activeCategory==4?'active':''" (click)="Tasks()"><a>
                                Tasks
                            </a></li>
                        <li [ngClass]="activeCategory==5?'active':''" (click)="OpenTest()"><a>
                                Open Test
                            </a></li>
                        <li [ngClass]="activeCategory==6?'active':''" (click)="Revision()"><a>
                                Revision
                            </a></li>


                    </ul>
                </div>
                <div class=" bg-white color-grey">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">

                                    <div class="w3-card-4  w-100">
                                        <div class="accordion classCard" id="myAccordion">
                                            <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                        <span class="headingAccords">Open Test

                                                        </span>
                                                       
                                                      
                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 74%!important; padding-top: 11px !important;
                                                            padding-bottom: 11px !important;">
                                                        </button>									
            
                                                    </div>
                
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                    <div class="card-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput" style="margin-left: -10%;">
                                                                        <label for="exampleInputEmail1">Product
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-2">
                
                                                                        <p-dropdown filter="true" name="Product" optionlabel="label" [ngModelOptions]="{standalone:true}" [options]="ProductNameArray" [(ngModel)]="advanceSearch.Product" placeholder="Enter Product">
                                                                        </p-dropdown>
                                                                    </div>
                
                                                                </div>
                                                            </div>
                
                
                
                
                                                        </div>

                                                        <div class="col-lg-12  main-submit-button" style="
                                                                   margin-left: -68%;">

                                                                <button _ngcontent-cta-c128="" type="submit" (click)="findBacklogOpenTest()" class="btn btn-secondary submit-btn">Find</button>&nbsp;

                                                                <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>


                                    <!-- <div class="w3-card-4 classCardView">
                                        <div class="container-fluid">
                                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                        flex-wrap: wrap !important;
                        display: flex !important;">
                                                <li><a>
                                                    Find Backlog
 

                                                    </a>
                                                </li>

                                            </ul>
                                        </div>


                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput" style="margin-left: -10%;">
                                                        <label for="exampleInputEmail1">Product
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" name="Product" optionlabel="label" [ngModelOptions]="{standalone:true}" [options]="ProductNameArray" [(ngModel)]="advanceSearch.Product" placeholder="Enter Product">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>




                                        </div>
                                        <div class="col-lg-12  main-submit-button" style="
                                                                   margin-left: -68%;">

                                            <button _ngcontent-cta-c128="" type="submit" (click)="findBacklogOpenTest()" class="btn btn-secondary submit-btn">Find</button>&nbsp;

                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>

                                        </div>

                                    </div> -->

                                    <br>

                                    <div class="w3-card-4 classCardView mt-4">
                                        <header class="w3-container w3-blue">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling h4Margin">
                                                    Backlog List
                                                </h4>

                                            </div>
                                        </header>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="findBackloglist" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Backlog Item
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Unplanned
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'350px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Story
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Status
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>




                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span  class="account-button">
                                                                                {{product.BacklogItem}}</span>

                                                                        </div>
                                                                    </td>

                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        {{product.Unplanned}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'350px'}">
                                                                        {{product.names.story}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        {{product.names.statusId}}
                                                                    </td>
                                                                    <!-- <td [ngStyle]="{'width':'180px'}">

                                                                    <svg 
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        class="ml-20" width="16"
                                                                        height="16" viewBox="0 0 26 26">
                                                                        <defs>
                                                                            <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }

                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                            </style>
                                                                        </defs>
                                                                        <path class="a fill-color"
                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                        <path class="b"
                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                            transform="translate(-147.576 -147.576)" />
                                                                    </svg>
                                                                </td> -->
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>