import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';
@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    constructor(
        private _ApiService: ApiService,
        private http: HttpClient
    ) { }
    invoiceCount(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/dashboard/invoiceCount`);
    }
    getOrders(orderType: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/dashboard/orders/${orderType}`);
    }
    catalogData(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/dashboard/catalogDashboard`);
    }
    paymentCount(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/dashboard/paymentCount`);
    }
    productInventoryDetails(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/dashboard/productInventoryDetails`);
    }
    getUsers(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/dashboard/users`);
    }
    getHrmsData(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/dashboard/hrmsDashboard`);
    }
    getCrmDashboard(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/dashboard/crmDashboard`);
    }
  
  
    getPurchaseOrderCount(id):Observable<any>{
        const getPurchaseOrderCount = URLS.getPurchaseOrderCount.replace(":orderType",id);
        return this.http.get(getPurchaseOrderCount);
      }
      getuserChart():Observable<any>{
        const getuserChart = URLS.getuserChart;
        return this.http.get(getuserChart);
      }
      gethrmsDashboardChart():Observable<any>{
        const gethrmsDashboardChart = URLS.gethrmsDashboardChart;
        return this.http.get(gethrmsDashboardChart);
      }
      getproductInventoryDetailsChart():Observable<any>{
        const getproductInventoryDetailsChart = URLS.getproductInventoryDetailsChart;
        return this.http.get(getproductInventoryDetailsChart);
      }
      getcatalogDashboardChart():Observable<any>{
        const getcatalogDashboardChart = URLS.getcatalogDashboardChart;
        return this.http.get(getcatalogDashboardChart);
      }
     
      getPendingOrderCount(id):Observable<any>{
        const getPendingOrderCount = URLS.getPendingOrderCount.replace(":orderType",id);
        return this.http.get(getPendingOrderCount);
      }
      getSalesOrderCount(id):Observable<any>{
        const getSalesOrderCount = URLS.getSalesOrderCount.replace(":orderType",id);
        return this.http.get(getSalesOrderCount);
      }
      getcrmDashboardChart():Observable<any>{
        const getcrmDashboardChart = URLS.getcrmDashboardChart;
        return this.http.get(getcrmDashboardChart);
      }
}