import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LeadNotesModel {

  constructor(
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
      noteName:(data && data.noteName) ? data.noteName : null,
      note:(data && data.note) ? data.note : null,
    });
    return this.getFormGroup(form_data);
  }

  getFormGroup(data:any): any {
   return ({
    noteName: new FormControl(data.noteName,Validators.required),
    note: new FormControl(data.note),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
