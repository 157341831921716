import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ShippingService } from '../shipping/shipping.service';

@Component({
  selector: 'app-detail-quantity-break-summary',
  templateUrl: './detail-quantity-break-summary.component.html',
  styleUrls: ['./detail-quantity-break-summary.component.css']
})
export class DetailQuantityBreakSummaryComponent implements OnInit {
  activeCategory=1;
  id: any;
  groupid: any;
  parentId: any;
  activeCategorytab=2;
  type: any;
  thruquan: any;
  fromquan: any;
  total=0;
  @ViewChild('closebutton') closebutton;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  quantityBreakForm: FormGroup;
  quantityType: any[];
  quantityBreaks: any[];
  editMode: boolean;
  quantityBreakTypeId: string;
  quantityId: string;
  isShown=false;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,

    readonly _ShippingService: ShippingService,
    readonly _FormBuilder: FormBuilder,
    readonly _ToastrService: ToastrService,
   
    readonly  _Router: Router,
  ) {
    this.quantityBreakForm = this._FormBuilder.group({
      fromQuantity: [''],
      quantityBreakTypeId: ['', [Validators.required]],
      thruQuantity: ['']
    });
    this.quantityType = [];
    this.quantityBreaks = [];
    this.editMode = false;
    this.quantityBreakTypeId = ''; }
  
  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this.getQuantityType();
    this.getQuantityBreaks();
    this.activatedRoute.queryParams.subscribe(params=> [
      this.id = params["id"],
      this.type=params["type"],
      this.thruquan=params["thruquan"],
      this.fromquan=params["fromquan"]
    ])
  
  }
  getQuantityType(): void {
    this.spinner.show();
    this._ShippingService.getQuantityBreakType()
      .then(data => {
        this.spinner.hide();
        this.quantityType = data.data.map(value => {
          return {
            label: value.description,
            value: value.quantityBreakTypeId
          };
        });
      });
     
  }
  getQuantityBreaks(): void {
    this.spinner.show();
    this._ShippingService.getQuantityBreaks()
      .then(data => {
        this.quantityBreaks = data.data;
        this.spinner.hide();
      });
     
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  edit(): void {
    this.quantityBreakTypeId =  this.type;
    this.quantityId =  this.id
    this.quantityBreakForm.patchValue({
      fromQuantity: this.fromquan,
      quantityBreakTypeId: this.type,
      thruQuantity: this.thruquan
     
    });
   /*  this.quantityBreakForm.patchValue({
      quantityBreakTypeId, fromQuantity, thruQuantity
    }); */
    this.quantityBreakForm.controls.quantityBreakTypeId.enable();
    this.quantityBreakForm.controls.quantityBreakTypeId.updateValueAndValidity();
    this.editMode = true;
  }
 
  reset(): void {
    this.quantityBreakForm.reset();
    document.getElementById('editForm').scrollIntoView({ behavior: 'smooth' });
  }
  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      if (this.quantityBreakForm.valid) {
        this._ShippingService.updateQuantityBreak({
          quantityBreakTypeId: this.quantityBreakTypeId,
          ...this.quantityBreakForm.value
        }, this.quantityId)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Updated');
              this.closebutton.nativeElement.click();
              this.router.navigate(["facilities/catalog/shipment/quantity-breaks"])
             
              this.spinner.hide();
              this.reset();
              this.getQuantityBreaks();
            
             
            }
          });  }
    } else {
      if (this.quantityBreakForm.valid) {
        this._ShippingService.createQuantityBreak(this.quantityBreakForm.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.closebutton.nativeElement.click();
              this.router.navigate(["facilities/catalog/shipment/quantity-breaks"])
             
              this.spinner.hide();
              this.reset();
              this.getQuantityBreaks();
            
            
            }
          }); }
    }
  }
  cancelSubmit(){
    this.router.navigate(["facilities/catalog/shipment/quantity-breaks"])
  }
}
