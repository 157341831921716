
<div class="container-fluid main-container-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
         <span class="color-black pl-1 titlePanels">Available Internal Accounting Organizations
        </span> <span></span>
        
        </div>
        <div class=" bg-white color-grey">
          <div class="port-header-contact create-lead font-medium mb-0">
            <div class="suppliers-wrapper">
             
            </div>
          </div>
         
          <div class="create-new-leade mt-2">
            <div class="container-fluid">
              <div class="row">
                <form class="w-100">
                  <div class="port-header-contact create-lead font-medium mb-0 text-white">
                    <div class="w-100">
                      <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
  
                        <div *ngIf="activeCategory==2">
                        
                          <div class="panel-body">
                            <div class="w3-card-4 classCard">
                              <div class="container-fluid">
                                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                    <li [ngClass]="activeCategoryMain==1?'active':''" (click)="AccountingInfo()"><a>
                                      Available Internal Accounting Organizations</a></li>
                                    <li [ngClass]="activeCategoryMain==2?'active':''" (click)="AccountingCompany()"><a>
                                      Create new accounting company</a></li>
                                     
                                   
                                </ul>
                            </div>
                             
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="form-group">
                                <div class="create-new-leade mt-2">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100">
                                               
                                                <div >
                                                    <div class="all-users-infomation font-13 font-medium own-user-select allUserPadding">
                                                        <form [formGroup]="createOrganization">
                                                            <div class="row" style="margin-left: 6%;">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-5 form-group classInput">
                                                                            <label for="exampleInputEmail1" style="color: black!important;">
                                                                                Organization Party ID
                                                                            </label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-4">
                                                                            <p-dropdown filter="true" 
                                                                                placeholder="Select Organization Party ID"
                                                                                formControlName="organizationPartyId"
                                                                                [options]="orgIdArray" optionlabel="label">
                                                                            </p-dropdown>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-12" style="margin-left: 43%;">
                                                                            <button _ngcontent-cta-c128=""
                                                                            (click)="onSubmit()"   type="submit" class="btn btn-secondary submit-btn"
                                                                              >Create</button>
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                
                                                            </div>
                                                        </form>
                                                    </div>
                                                   
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                              </div>
                            </div>
                            </div>
                          </div>
  
                        </div>
                      
                      
                      </div>
                    </div>
                  </div>
  
                </form>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  </div>
<ngx-spinner></ngx-spinner> 
