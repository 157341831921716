import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


    
    import { Location } from '@angular/common';
@Component({
  selector: 'app-detail-portal-training-summary',
  templateUrl: './detail-portal-training-summary.component.html',
  styleUrls: ['./detail-portal-training-summary.component.css']
})
export class DetailPortalTrainingSummaryComponent implements OnInit {
  firstName: any;
  lastName: any;
  trainingRequestId: any;
  workEffortId: any;
  fromDate: any;
  thruDate: any;
  approverId: any;
  approvalStatus: any;
  reason:any;
  description:any;
  activeCategory=1;

  constructor(
    readonly activatedRoute:ActivatedRoute,
      private _location: Location,
      readonly spinner: NgxSpinnerService,
    readonly router: Router) { }
   
  ngOnInit(): void {
   
    this.activatedRoute.queryParams.subscribe(params=> [
      this.firstName = params["firstName"],
      this.description=params["description"],
      this.lastName=params["lastName"],
      this.trainingRequestId=params["trainingRequestId"],
      this.workEffortId = params["workEffortId"],
      this.fromDate=params["fromDate"],
      this.thruDate=params["thruDate"],
      this.approverId=params["approverId"],
      this.approvalStatus=params["approvalStatus"],
      this.reason=params["reason"],
    ])
   
  }

  homeButton(){
    this.router.navigate(['/myPortal/my-portal-my-profile'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
}
