import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AddLeaveService } from '../leave/add-new-leaves/add-new-leaves-service';
import { AddLeaveEmpService } from '../leave/add-new-leaves/add-new-leaves.service';
import { EmployeeLeaveService } from '../leave/employee-leave/employee-leave.service';

@Component({
  selector: 'app-overview-leave',
  templateUrl: './overview-leave.component.html',
  styleUrls: ['./overview-leave.component.css']
})
export class OverviewLeaveComponent implements OnInit {
  leaveTypeId: any;
  activeCategory = 2;
  leaveId: any = [];
  leaveReason: any;
  leaveReasonArray: any = [];
  partyIdArray: any = [];
  partyId: any;
  leaveTypeIds: any;
  fromDate: any;
  show = false;
  leaves: {};
  isApproval: boolean;
  updateefromdate: string;
  updateetodate: string;
  thruDate: any;
  AppId: any;
  Description: any;
  Reasonid: any;
  partyIdEdit: any;
  leaveStatusReoort: any;
  leaveStatusReoortArray: any[]=[];
  statusId: any;
  add_leave_form:FormGroup
  leave=false;

  constructor(public addLeaveService: AddLeaveService,
    readonly addLeaveEmpService: AddLeaveEmpService,
    readonly toastr: ToastrService,
    readonly router: Router,
    public datePipe: DatePipe,
    readonly employeeLeaveService: EmployeeLeaveService,
    readonly activatedRoute: ActivatedRoute,
    readonly spinner:NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,) {
    this.isApproval = false;
    this.add_leave_form = this._FormBuilder.group({
      leaveTypeId: [''],
      emplLeaveReasonTypeId: [''],
      fromDate: [''],
      thruDate:[''],
      approverPartyId: [''],
      description:[''],
      leaveStatus:['']


    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
   
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdEdit = params["partyId"];
      this.leaveTypeId = params["leaveTypeId"];
      this.fromDate = params["fromDate"];
      this.thruDate = params["thruDate"];
      this.AppId = params["AppId"]
      this.Description = params["Description"]
      this.Reasonid = params["Reasonid"];
      this.statusId=params["leaveStatus"]
      this.isApproval = params.approval === 'true' ? true : false;
    })
    if(this.isApproval){ 
      this.leave=true
   }else{
     this.leave=false
   }
       
    if (this.partyIdEdit && this.Reasonid) {
      this.show = true;
      let fromDate = this.datePipe.transform(this.fromDate, 'yyyy-MM-dd')
      let toDate = this.datePipe.transform(this.thruDate, 'yyyy-MM-dd')
      setTimeout(() => {

        const formValue = this.add_leave_form;
        formValue.patchValue({
          partyId: this.partyIdEdit,
          leaveTypeId: this.leaveTypeId,
          emplLeaveReasonTypeId: this.Reasonid,
          approverPartyId: this.AppId,
          description: this.Description,
          thruDate: toDate,
          fromDate: fromDate,
          leaveStatus:this.statusId
           

        })
      }, 3000);

    }
    else {
      this.show = false
    }

    this.getLeaveReasonId();
    this.getPartyId();
    this.leavereason();
    this.leaveStatus();

  }
  leaveStatus() {
    this.spinner.show();
    this.employeeLeaveService.getLeaveStatus().subscribe(res => {
      this.leaveStatusReoort = res.data;
      this.spinner.hide();
      for (const value of this.leaveStatusReoort) {
        this.leaveStatusReoortArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
  
  }
  navigatePrev() {
    this.router.navigate(['/hr/hrm-main'],{ queryParams: { partyId: this.partyIdEdit } })
  }
  cancelLeave() {
    this.router.navigate(['/hr/hrm-main'],{ queryParams: { partyId: this.partyIdEdit } })
  }
  onSubmit() {
    this.spinner.show();
    const fromdate = this.add_leave_form.get('fromDate').value;
    this.updateefromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const todate = this.add_leave_form.get('thruDate').value;
    this.updateetodate = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");

    const formDate = {
      approverPartyId: this.add_leave_form.value.approverPartyId,
      description: this.add_leave_form.value.description,
      emplLeaveReasonTypeId: this.add_leave_form.value.emplLeaveReasonTypeId,
      fromDate: this.updateefromdate,
      leaveTypeId: this.add_leave_form.value.leaveTypeId,
      partyId: this.partyIdEdit,
      thruDate: this.updateetodate,
      leaveStatus: this.add_leave_form.value.leaveStatus,
      
    }
    this.addLeaveEmpService.AddLeave(formDate).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.router.navigate(['/hr/hrm-main'],{ queryParams: { partyId: this.partyIdEdit } })
        this.spinner.hide();
       
      }
      else if (res.success == false) {
        this.spinner.hide();
        this.toastr.error(res.message.debugMessage);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
      
    })
   
   
  }
  onUpdate() {
    this.spinner.show();
    const fromdate = this.add_leave_form.get('fromDate').value;
    this.updateefromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const todate = this.add_leave_form.get('thruDate').value;
    this.updateetodate = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");

    const formDate = {
      approverPartyId: this.add_leave_form.value.approverPartyId,
      description: this.add_leave_form.value.description,
      emplLeaveReasonTypeId: this.add_leave_form.value.emplLeaveReasonTypeId,
      fromDate: this.updateefromdate,
      leaveTypeId: this.add_leave_form.value.leaveTypeId,
      partyId: this.partyIdEdit,
      thruDate: this.updateetodate,
      leaveStatus: this.add_leave_form.value.leaveStatus,
    }
  
    this.addLeaveEmpService.update(formDate, this.partyIdEdit, this.leaveTypeId, this.fromDate).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.successMessage);
        this.router.navigate(['/hr/hrm-main'],{ queryParams: { partyId: this.partyIdEdit } })
      }
      else if (res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
   

  }
  onUpdateApproval() {
    this.spinner.show();
    const fromdate = this.add_leave_form.get('fromDate').value;
    this.updateefromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const todate = this.add_leave_form.get('thruDate').value;
    this.updateetodate = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");

    const formDate = {
      approverPartyId: this.add_leave_form.value.approverPartyId,
      description: this.add_leave_form.value.description,
      emplLeaveReasonTypeId: this.add_leave_form.value.emplLeaveReasonTypeId,
      fromDate: this.updateefromdate,
      leaveTypeId: this.add_leave_form.value.leaveTypeId,
      partyId: this.add_leave_form.value.partyId,
      thruDate: this.updateetodate,
      leaveStatus: this.add_leave_form.value.leaveStatus,
    }
  
    this.addLeaveEmpService.postLeaveStatus(formDate).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/hr/hrm-main'],{ queryParams: { partyId: this.partyIdEdit } })
      }
      else if (res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();

      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
   
  }
  getLeaveReasonId() {
    this.spinner.show();
    this.addLeaveEmpService.getLeaveReasonType().subscribe(res => {
      this.leaveTypeIds = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.leaveTypeIds) {
        this.leaveId.push({
          label: value.description,
          value: value.leaveTypeId
        })
      }
    })
   
  }
  leavereason() {
    this.spinner.show();
    this.addLeaveEmpService.getLeaveReason().subscribe(res => {
      this.leaveReason = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.leaveReason) {
        this.leaveReasonArray.push({
          label: value.description,
          value: value.emplLeaveReasonTypeId
        })
      }
    })
   
  }

  getPartyId() {
    this.spinner.show();
    this.addLeaveEmpService.getPartyId().subscribe(res => {
      this.partyId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

