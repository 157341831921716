import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from 'src/app/header/header.component';
import { HeaderService } from 'src/app/header/header.service';   import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DatePipe, DOCUMENT } from '@angular/common';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup } from '@angular/forms';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';

@Component({
  selector: 'app-sprints',
  templateUrl: './sprints.component.html',
  styleUrls: ['./sprints.component.css']
})
export class SprintsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 2;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  sprintForm: FormGroup;
  productId: any;
  internalName: any;
  partyId:any;
  ProjectName: any;
  ProjectGoal: any;
  Billed: any;
  ProjectSprintFrontPage: any;
  ProjectSprintProductName: any;
  ProjectSprintProductNameArray: any[] = [];
  yesNOArray: { label: string; value: string; }[];
  productIdSprint = "";
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,    
    private _location: Location,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
    this.sprintForm = this._FormBuilder.group({
      ProductName: [''],
      ProjectName: [''],
      Billed: [''],
      ProjectGoal: [''],

    });
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.productId = params.productId;
      this.internalName = params.internalName;
      this.ProjectName = params.ProjectName;
      this.ProjectGoal = params.ProjectGoal;
      this.Billed = params.Billed;
    })
    this.getProjectSprintFrontPage();
    this.getProjectSprintProductName();
  }
  myProfilePage(partyId) {


    this._Router.navigate(["/psa/my-profile"], { queryParams: { taxAuthPartyId: partyId } })
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getProjectSprintProductName() {
    this.spinner.show();
    this.accountsService.getProjectSprintProductName().subscribe(res => {
      this.ProjectSprintProductName = res.data;
      this.spinner.hide();
      for (const value of this.ProjectSprintProductName) {
        this.ProjectSprintProductNameArray.push({
          label: value.productName[0].internalName + "[" + value.productName[0].productId + "]",
          value: value.productName[0].productId
        })
      }
    })
  }

  detailedOverviewPage(ProjectName, internalName, productId) {
    // this._Router.navigate(["/psa/detail-sprint-project"])
    this._Router.navigate(["/psa/detail-sprint-project"], { queryParams: { Project: ProjectName,internalName:internalName, productId: productId } })

  }

  detailedPartyPage(product) {
    const hh = product.ownerCompany ;
    const ret = hh.substring(hh.indexOf("[") + 1);
    const str = ret.substring(0, ret.length - 1);
    this._Router.navigate(["/psa/my-profile"], { queryParams: { taxAuthPartyId: str } })
  }

  createProjectScrum() {
    this.spinner.show();
    const hh = this.sprintForm.value.ProductName ;
    const ret = hh.substring(hh.indexOf("[") + 1);
    const str = ret.substring(0, ret.length - 1);
   // var rr = ret.substring(ret.indexOf("]") + 1);
    console.log(str); 

    const formData = {

      "billed": this.sprintForm.value.Billed ,
      "currentStatusId": "SPJ_ACTIVE" ,
      "description": this.sprintForm.value.ProjectGoal ,
      "partyId": "admin",
      "productId": this.sprintForm.value.ProductName ,
      "productOwnerId": "",
      "projectId": "",
      "workEffortId": "",
      "workEffortName": this.sprintForm.value.ProjectName ,
      "workEffortTypeId": "SCRUM_PROJECT"

    }
    this.accountsService.createProjectScrum(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getProjectSprintFrontPage();
        this.closebutton.nativeElement.click();
        this._Router.navigate(["/psa/detail-sprint-project"], { queryParams: { productId :  this.sprintForm.value.ProductName  ,ProjectName : this.sprintForm.value.ProjectName , internalName:this.internalName, ProjectGoal : this.sprintForm.value.ProjectGoal , Billed : this.sprintForm.value.Billed, } })


      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  getProjectSprintFrontPage() {
    this.spinner.show();
    this.accountsService.getProjectSprintFrontPage().subscribe(res => {
      this.ProjectSprintFrontPage = res.data;
      this.spinner.hide();
    })
  }

  detailedPage() {
    this._Router.navigate(["/psa/product-overview"])
  }

  Backlog() {
    this._Router.navigate(["/psa/product-backlog"])
  }
  Sprint() {
    this._Router.navigate(["/psa/sprints"])
  }
  MyWork() {
    this._Router.navigate(["/psa/my-work"])
  }
  Tasks() {
    this._Router.navigate(["/psa/tasks"])
  }
  OpenTest() {
    this._Router.navigate(["/psa/open-test"])
  }
  Revision() {
    this._Router.navigate(["/psa/find-task-revision"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }


}
