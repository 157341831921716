import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';import { Location } from '@angular/common';


import Swal from 'sweetalert2'
import { ProductionService } from '../production.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { NewJobRequistionsService } from 'src/app/human-resource/recuitment/new-job-requisition/new-job-requisition.service';
@Component({
  selector: 'app-detail-routing-task-summary',
  templateUrl: './detail-routing-task-summary.component.html',
  styleUrls: ['./detail-routing-task-summary.component.css']
})
export class DetailRoutingTaskSummaryComponent implements OnInit {
  activeCategory=1;
  products3: any;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonB') closebuttonB;
  @ViewChild('closebuttonC') closebuttonC;
  @ViewChild('closebuttonD') closebuttonD;
  @ViewChild('closebuttonE') closebuttonE;
  @ViewChild('closebuttonF') closebuttonF;
  paymentPartyGroup: any;
  downloading: boolean;
  total=0;
  ReportTax:any[]=[];
  rowNumber=0;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= ""
  private finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  FinancialTransaction:any[]=[];
  budgetId: any;
  budgetHeader: any;
  budgetStatus: any;
  budgetRole: any;
  budgetItem: any;
  budgetReview: any;
  budgetItemList: any;
  budgetRoleList: any;
  budgetReviewList: any;
  status: string;
  routingTaskId: any;
  RoutingTaskDetails: any;
  costData: any;
  TaskDelievarbleProduct: any;
  ProdCost: any;
  ProdfixedAsset: any;
  prodParty: any;
  ProdSkill: any;
  createroute: FormGroup;

  fixedAssetId: any;
  estimateCalcMethod: any;
  routingTaskType: any;
  fixedAssetIdArray: any[]=[];
  estimateCalcMethodArray: any[]=[];
  routingTaskTypeArray: any[]=[];

  show: boolean;
  createCost:FormGroup;
  activeCategoryMain=2;
  costComponentCalcList: any;
  costComponentCalcListArray: any[]=[];
  costComponentTypeList: any;
  costComponentTypeListArray: any[]=[];
 
  startDate: string;
  fromDate: string;
  showDel: boolean;
  createDeliverable:FormGroup;
 
  productId: any;
  productIdArray: any[]=[];

  throughDate: string;
  productIdEdit: any;
  fromDateEdit: any;
  workEffortGoodStdTypeId: any;
  thruDateEdit: any;
  showFix: boolean;
  createFixed:FormGroup;

  fixedAssetsInitialDataArray: any[]=[];

  fixedAssetTypeId: any;
  estimatedQuantity: any;
  estimatedCost: any;
  estimatedDuration: any;
  showParty: boolean;
  createParty:FormGroup;
 
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];

  partyId: any;
  roleTypeId: any;
  showSkill: boolean;
  createSkill:FormGroup;

  getSkill: any;

  skillTypeArray: any[]=[];
  skillTypeIdEdit: any;
  estimatedNumPeopleEdit: any;
  estimatedDurationEdit: any;
  estimatedCostEdit: any;
  onUpdatePartyPat: any;
  activity: any;
 

  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
private _location: Location,
readonly accountsService: AccountsService,
    readonly productionService:ProductionService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly _ToastrService: ToastrService,
    readonly newJobRequistionsService: NewJobRequistionsService,
    readonly datePipe: DatePipe,
    readonly addSkillService: AddSkillService,
    readonly router: Router) { 
      this.createroute = this._FormBuilder.group({
        RoutingTaskName: [''],
        TaskType: [''],
        Description: [''],
        FixedAssetID: [''],
        EstimatedSetupTime: [''],
        EstimatedUnitRunTime: [''],
        EstimateCalcMethod: [''],
        ReservPersons: [''],
  
  
  
      });
      this.createCost = this._FormBuilder.group({
        CostComponentTypeID: [''],
        CostComponentCalcID:[''],
        FromDate:[''],
        ThroughDate: [''],
 
      });
      this.createDeliverable = this._FormBuilder.group({
        ProductID: [''],
        FromDate:[''],
        ThroughDate: [''],
      
       
       
      });
      this.createFixed = this._FormBuilder.group({
        FixedAssetTypeID: [''],
        EstimatedQuantity:[''],
        EstimatedDuration: [''],
        EstimatedCost:['']
      
       
       
      });
      this.createParty = this._FormBuilder.group({
        PartyID: [''],
        RoleTypeID:[''],
        fromDate: [''],
        throughDate:[''],
       
       
      });
      this.createSkill = this._FormBuilder.group({
        SkillTypeID: [''],
        EstimatedNumPeople:[''],
        EstimatedDuration: [''],
        Estimatedcost:[''],
       
       
      });
    
    }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.routingTaskId = params["routingTaskId"];
    
    
    });
    this.showDel=false;
    this.showFix=false;
    this.showParty=false;
    this.showSkill=false;
    this.show=true;
    this.getRoutingTaskDetails();
    this.getRoutingTaskCostData();
    this.getRoutingTaskDelievarbleProduct();
    this.getProdfixedAsset();
    this.getProdParty();
    this.getProdSkill();
    this.getRoutingTasksinitialData();
    this.getRoutingTaskCost();
    this.getProducts();
    this.fixedAssetsInitialData();
    this.getRoleAllType();
    this.getPartyId();
    this.getSkillTypeList();
    
  }
  getSkillTypeList() {
    this.spinner.show();
    this.newJobRequistionsService.getSkillTypeList().subscribe(res => {
      this.getSkill = res.data;
      this.spinner.hide();
      for (const value of this.getSkill) {
        this.skillTypeArray.push({
          label: value.description,
          value: value.skillTypeId
        })
      }
    })
   
  }
  
  onSubmitSkill() {
    this.spinner.show();
    const requestData = {
      "estimatedCost":this.createSkill.value.Estimatedcost,
      "estimatedDuration":this.createSkill.value.EstimatedDuration,
      "estimatedNumPeople": this.createSkill.value.EstimatedNumPeople,
      "skillTypeId":this.createSkill.value.SkillTypeID,
    }
    this.productionService.postProdSkill(this.routingTaskId,requestData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebuttonF.nativeElement.click();
        this.getProdSkill();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onUpdateSkill() {
    this.spinner.show();
    const requestData = {
      "estimatedCost":this.createSkill.value.Estimatedcost,
      "estimatedDuration":this.createSkill.value.EstimatedDuration,
      "estimatedNumPeople": this.createSkill.value.EstimatedNumPeople,
      "skillTypeId":this.createSkill.value.SkillTypeID,
    }
    this.productionService.updateProdSkill(this.routingTaskId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebuttonF.nativeElement.click();
        this.getProdSkill();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        } })
     
     }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    }) 
  }
  
  onSubmitParty() {
    this.spinner.show();
    const startDate = this.createParty.get('throughDate').value;
    this.throughDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const completeDate = this.createParty.get('fromDate').value;
    this.fromDate = this.datePipe.transform(completeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate": this.fromDate,
      "partyId": this.createParty.value.PartyID,
      "roleTypeId": this.createParty.value.RoleTypeID,
      "thruDate":this.throughDate
    }
    this.productionService.postProdParty(this.routingTaskId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebuttonE.nativeElement.click();
        this.getProdParty();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }

  onUpdateParty() {
    this.spinner.show();
    const startDate = this.createParty.get('throughDate').value;
    this.throughDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const completeDate = this.createParty.get('fromDate').value;
    this.fromDate = this.datePipe.transform(completeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":  this.fromDate,
      "partyId": this.createParty.value.PartyID,
      "roleTypeId": this.createParty.value.RoleTypeID,
      "thruDate":this.throughDate
    }
    this.productionService.updateProdParty(this.routingTaskId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebuttonE.nativeElement.click();
        this.getProdParty();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });
  }

  onUpdatePartyPatch(activity) {
    this.show = true;
    setTimeout(() => {
      const form = this.createParty;
      form.patchValue({
      fromDate:activity.fromDate,
      PartyID:activity.partyId,
      RoleTypeID:activity.roleTypeId,
      throughDate:activity.thruDate

      })
    }, 2000);
  }

  

  fixedAssetsInitialData() {
    this.spinner.show();
    this.accountsService.fixedAssetsInitialData().subscribe(res => {
      let fixedAssetTypes = res.data.fixedAssetTypes;
      this.spinner.hide();
      for (const value of fixedAssetTypes) {
        this.fixedAssetsInitialDataArray.push({
          label: value.description,
          value: value.fixedAssetTypeId
        })
      }
    })
  }
  
  onSubmitFix() {
    this.spinner.show();
    const requestData = {
      "estimatedCost":  this.createFixed.value.EstimatedCost,
      "estimatedDuration": this.createFixed.value.EstimatedDuration,
      "estimatedQuantity": this.createFixed.value.EstimatedQuantity,
      "fixedAssetTypeId": this.createFixed.value.FixedAssetTypeID,
    }
    this.productionService.postProdfixedAsset(this.routingTaskId,requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebuttonD.nativeElement.click();
        this.getProdfixedAsset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onUpdateFix() {
    this.spinner.show();
    const requestData = {
      "estimatedCost":  this.createFixed.value.EstimatedCost,
      "estimatedDuration": this.createFixed.value.EstimatedDuration,
      "estimatedQuantity": this.createFixed.value.EstimatedQuantity,
      "fixedAssetTypeId": this.createFixed.value.FixedAssetTypeID,
    }
    this.productionService.updateProdfixedAsset(this.routingTaskId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebuttonD.nativeElement.click();
        this.getProdfixedAsset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onSubmitDel() {
    this.spinner.show();
    const startDate = this.createDeliverable.get('ThroughDate').value;
    this.throughDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const completeDate = this.createDeliverable.get('FromDate').value;
    this.fromDate = this.datePipe.transform(completeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":  this.fromDate,
      "productId": this.createDeliverable.value.ProductID,
      "thruDate": this.throughDate
    }
    this.productionService.postRoutingTaskDelievarbleProduct(this.routingTaskId,requestData).subscribe((res: any) => { 
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebuttonC.nativeElement.click();
        this.getRoutingTaskDelievarbleProduct();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onUpdateDel() {
    this.spinner.show();
    const startDate = this.createDeliverable.get('ThroughDate').value;
    this.throughDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate": this.fromDateEdit,
      "productId": this.createDeliverable.value.ProductID,
      "thruDate": this.throughDate
    }
    this.productionService.updateRoutingTaskDelievarbleProduct(this.routingTaskId,requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebuttonC.nativeElement.click();
        this.getRoutingTaskDelievarbleProduct();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getProducts(): void {
    this.spinner.show();
  
    this.productionService.getJobShopInitialData().subscribe((res) => {
      
      this.productId = res.data.productId;
      
      this.spinner.hide();
     
      for (const value of this.productId) {
        this.productIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    
    })
  
  }
  onSubmitCost() {
    this.spinner.show();
    const startDate = this.createCost.get('ThroughDate').value;
    this.startDate = this.datePipe.transform(startDate, "yyyy-MM-dd hh:mm:ss");
    const completeDate = this.createCost.get('FromDate').value;
    this.fromDate = this.datePipe.transform(completeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "costComponentCalcId": this.createCost.value.CostComponentCalcID,
      "costComponentTypeId":this.createCost.value.CostComponentTypeID,
      "fromDate": this.fromDate,
      "thruDate":this.startDate ,
    }

    this.productionService.postRoutingTaskCost(this.routingTaskId,requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebuttonB.nativeElement.click();
        this.getRoutingTaskCostData();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getRoutingTaskCost() {
    this.spinner.show();
   
    this.productionService.getRoutingTaskCost().subscribe(res => {
      this.costComponentCalcList = res.data.costComponentCalcList;
      this.costComponentTypeList=res.data.costComponentTypeList;
      this.spinner.hide();
      for (const value of this.costComponentCalcList) {
        this.costComponentCalcListArray.push({
          label: value.description,
          value: value.costComponentCalcId
        })
      }
      for (const value of this.costComponentTypeList) {
        this.costComponentTypeListArray.push({
          label: value.description,
          value: value.costComponentTypeId
        })
      }
    })
    
  }
  getRoutingTasks() {
    this.spinner.show();
    const req={
      "fixedAssetId": "",
      "workEffortId": this.routingTaskId,
      "workEffortIdSearchType": "Contains",
      "workEffortName": "",
      "workEffortNameSearchType": "Contains"
    }
    this.productionService.getRoutingTasks(this.finSize,req).subscribe((res: any) => {
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.createroute;
        formValue.patchValue({
          RoutingTaskName: res.data[0].workEffortName,
          TaskType:  res.data[0].workEffortPurposeTypeId,
          Description:  res.data[0].description,
          FixedAssetID:  res.data[0].fixedAssetId,
          EstimatedSetupTime: res.data[0].estimatedSetupMillis,
          EstimatedUnitRunTime:  res.data[0].estimatedMilliSeconds,
          EstimateCalcMethod:  res.data[0].estimateCalcMethod,
          ReservPersons:  res.data[0].reservPersons,
        })
      }, 2000);
    })
  }
  getRoutingTasksinitialData() {
    this.spinner.show();
    this.productionService.getRoutingTasksinitialData().subscribe(res => {
      this.fixedAssetId = res.data.fixedAssetId;
      this.estimateCalcMethod = res.data.estimateCalcMethod;
      this.routingTaskType = res.data.routingTaskType;
      this.spinner.hide();
      for (const value of this.fixedAssetId) {
        this.fixedAssetIdArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
      for (const value of this.estimateCalcMethod) {
        this.estimateCalcMethodArray.push({
          label: value.description,
          value: value.customMethodId
        })
      }
      for (const value of this.routingTaskType) {
        this.routingTaskTypeArray.push({
          label: value.description,
          value: value.workEffortPurposeTypeId
        })
      }
    })
    
  }
  

  onUpdate() {
    this.spinner.show();
    const requestData = {
      "currentStatusId": "",
      "description": this.createroute.value.Description,
      "estimateCalcMethod": this.createroute.value.EstimateCalcMethod,
      "estimatedMilliSeconds": this.createroute.value.EstimatedUnitRunTime,
      "estimatedSetupMillis": this.createroute.value.EstimatedSetupTime,
      "fixedAssetId": this.createroute.value.FixedAssetID,
      "reservPersons": this.createroute.value.ReservPersons,
      "workEffortId":this.routingTaskId,
      "workEffortName": this.createroute.value.RoutingTaskName,
      "workEffortPurposeTypeId": this.createroute.value.TaskType,
      "workEffortTypeId": "",
    }
    this.productionService.updateRoutingTasks(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Updated Successfully");
        this.getRoutingTaskDetails();
        this.closebutton.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }  }
        else{
          this.toastr.error(err.error.message);  }
    });}
    homeButton(){
      this.router.navigate(['/production/main-production'])
    
    }
    cancelSubmit(){
      this._location.back();
    }
  getRoutingTaskCostData() {
    this.spinner.show();
  
    this.productionService.getRoutingTaskCostData(this.routingTaskId,this.finSize).subscribe((res: any) => {
      this.costData = res.data;
      this.spinner.hide();
    })
  
  }
  getRoutingTaskDelievarbleProduct() {
    this.spinner.show();
  
    this.productionService.getRoutingTaskDelievarbleProduct(this.routingTaskId,this.finSize).subscribe((res: any) => {
      this.TaskDelievarbleProduct = res.data;
      this.spinner.hide();
    })
  
  }
  getProdfixedAsset() {
    this.spinner.show();
  
    this.productionService.getProdfixedAsset(this.routingTaskId,this.finSize).subscribe((res: any) => {
      this.ProdfixedAsset = res.data;
      this.spinner.hide();
    })
  
  }
  
  
  getRoutingTaskDetails() {
    this.spinner.show();
  
    this.productionService.getRoutingTaskDetails(this.routingTaskId).subscribe((res: any) => {
      this.RoutingTaskDetails = res.data;
     
      this.spinner.hide();
    })
  
  }
  getProdParty() {
    this.spinner.show();
  
    this.productionService.getProdParty(this.routingTaskId,this.finSize).subscribe((res: any) => {
      this.prodParty = res.data;
     
      this.spinner.hide();
    })
  
  }
  getProdSkill() {
    this.spinner.show();
  
    this.productionService.getProdSkill(this.routingTaskId,this.finSize).subscribe((res: any) => {
      this.ProdSkill = res.data;
     
      this.spinner.hide();
    })
  
  }
  
  
 
  
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  
  deleteProdSkill(skillTypeId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) { 
        this.productionService.deleteProdSkill(this.routingTaskId,skillTypeId).subscribe((res: any) => {  
          this.getProdSkill();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
       } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}}) }
  deleteProdfixedAsset(fixedAssetTypeId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.productionService.deleteProdfixedAsset(this.routingTaskId,fixedAssetTypeId).subscribe((res: any) => {
        this.getProdfixedAsset();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  deleteRoutingTaskDelievarbleProduct(fromDate,productId,workEffortGoodStdTypeId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.productionService.deleteRoutingTaskDelievarbleProduct(this.routingTaskId,fromDate,productId,workEffortGoodStdTypeId).subscribe((res: any) => {
          this.getRoutingTaskDelievarbleProduct();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deletePostRoutingTaskCost(costComponentCalcId,costComponentTypeId,fromDate) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.productionService.deletePostRoutingTaskCost(this.routingTaskId,costComponentCalcId,costComponentTypeId,fromDate).subscribe((res: any) => { 
          this.getRoutingTaskCostData();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
    )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
 /*  createCost() {
    this.router.navigate(["production/create-route-task-cost"],{ queryParams: { routingTaskId : this.routingTaskId}})

  } */
 /*  createDeliverable() {
    this.router.navigate(["production/create-task-deliverable-product"],{ queryParams: { routingTaskId : this.routingTaskId}})

  } */
  updateDeliverable(efromDate,eproductId,ethruDate){
    this.router.navigate(["production/create-task-deliverable-product"],{ queryParams: { routingTaskId : this.routingTaskId,fromDate:efromDate,productId:eproductId,thruDate:ethruDate}})

  }
  createFixedAsset() {
    this.router.navigate(["production/create-routing-task-fixed-asset"],{ queryParams: { routingTaskId : this.routingTaskId}})

  }
  updateFixedAssest(efixedAssetTypeId,eestimatedQuantity,eestimatedDuration,eestimatedCost) {
    this.router.navigate(["production/create-routing-task-fixed-asset"],{ queryParams: { routingTaskId : this.routingTaskId,fixedAssetTypeId:efixedAssetTypeId,estimatedQuantity:eestimatedQuantity,estimatedDuration:eestimatedDuration,estimatedCost:eestimatedCost}})

  }
 
  createParties() {
    this.router.navigate(["production/create-routing-task-parties"],{ queryParams: { routingTaskId : this.routingTaskId}})

  }
  updateParties(epartyId,eroleTypeId,efromDate,ethruDate) {
    this.router.navigate(["production/create-routing-task-parties"],{ queryParams: { routingTaskId : this.routingTaskId,partyId:epartyId,roleTypeId:eroleTypeId,fromDate:efromDate,thruDate:ethruDate}})

  }
 
  createSkills() {
    this.router.navigate(["production/create-routing-task-skills"],{ queryParams: { routingTaskId : this.routingTaskId}})

  }
  updateSkill(eskillTypeId,eestimatedNumPeople,eestimatedDuration,eestimatedCost){
    this.router.navigate(["production/create-routing-task-skills"],{ queryParams: { routingTaskId : this.routingTaskId,skillTypeId:eskillTypeId,estimatedNumPeople:eestimatedNumPeople,estimatedDuration:eestimatedDuration,estimatedCost:eestimatedCost}})

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}

