import { Component, OnInit } from '@angular/core';
import { MergeLeadService } from '../merge-lead.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-merge-lead',
  templateUrl: './merge-lead.component.html',
  styleUrls: ['./merge-lead.component.css']
})
export class MergeLeadComponent implements OnInit {
  public partyIdTo: any;
  activeCategory=2;
  public partyIdFrom: any;
  contactMechId = '';
  firstNameSearchType = 'Contains';
  firstName: String = '';
  public pageNo = 1;
  public pageSize= 100;
  lastName = '';
  lastNameSearchType = 'Contains';
  partyIds = '';
  partyIdSearchType = 'Contains';
  public contacts = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    isMyLead: 'false'
  }
  leadsLists: any;
  leadListarray: any = [];
  public useAddress2: any;
  public useContactNum2: any;
  public useEmail2: any;
  public mergeLead: any;
  addrContactMechId: any;
  addrContactMechIdTo: any;
  emailContactMechId: any;
  emailContactMechIdTo: any;
  partyId: any;
  partyIdToTs = 'Y';
  phoneContactMechId = 'Y';
  phoneContactMechIdTo = 'Y';
  leadPartyId: any;

  constructor(
    private mergeLeadService: MergeLeadService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    private _location: Location,
  ) {
    this.emailContactMechId = '';
    this.emailContactMechIdTo = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.leadPartyId = params[''];
    });
    this.getLeadList();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/crm/main-overview']);
  }
  partyIdFromData(event) {
    this.partyIdTo = event;
  }
  partyIdToData(event) {
    this.partyIdFrom = event;
  }
  public checkAddress(event) {
    if (event.target.checked) {
      this.useAddress2 = 'Y';
    } else {
      this.useAddress2 = 'N';
    }
  }
  public checkEmail(event) {
    if (event.target.checked) {
      this.useEmail2 = 'Y';
    } else {
      this.useEmail2 = 'N';
    }
  }
  public checkContact(event) {
    if (event.target.checked) {
      this.useContactNum2 = 'Y';
    } else {
      this.useContactNum2 = 'N';
    }
  }
  public getLeadList() {
    this.spinner.show();
    const leadAdvancedSearch = {
      contactMechId: this.contactMechId,
      firstName: this.firstName,
      firstNameSearchType: this.firstNameSearchType,
      lastName: this.lastName,
      lastNameSearchType: this.lastNameSearchType,
      partyId: '',
      partyIdSearchType: this.partyIdSearchType
    };
    this.mergeLeadService.getLeadsList(this.contacts, leadAdvancedSearch).subscribe(res => {
      this.leadsLists = res.data;
      this.spinner.hide();
      for (const value of this.leadsLists) {
        this.leadListarray.push({
          label: value.partyId,
          value: value.partyId
        });
      }
    });
   
  }
  onSubmit(): void {
    this.spinner.show();
    const mergeContactFrom = {
      addrContactMechId: this.mergeLead[1].addrContactMechId ? this.mergeLead[1].addrContactMechId : '',
      addrContactMechIdTo: this.mergeLead[0].addrContactMechId ? this.mergeLead[0].addrContactMechId : '',
      emailContactMechId: this.mergeLead[1].emailContactMechId ? this.mergeLead[1].emailContactMechId : '',
      emailContactMechIdTo: this.mergeLead[0].emailContactMechId ? this.mergeLead[0].emailContactMechId : '',
      partyId: this.partyIdFrom,
      partyIdTo: this.partyIdTo,
      phoneContactMechId: this.mergeLead[1].phoneContactMechId ? this.mergeLead[1].phoneContactMechId : '',
      phoneContactMechIdTo: this.mergeLead[0].phoneContactMechIdTo ? this.mergeLead[0].phoneContactMechIdTo : '',
      useAddress2: this.useAddress2,
      useContactNum2: this.useContactNum2,
      useEmail2: this.useEmail2 ? this.useEmail2 : 'N',
    };
    this.mergeLeadService.submitMergeLead(mergeContactFrom).subscribe((res: any) => {
      (res.success) ?
        this.toastr.success(res.data.responseMessage) :
        this.spinner.hide();
        this.toastr.error(res.data.message);
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
    });
    this.spinner.hide();
  }
  mergeContact(): void {
    this.spinner.show();
    this.mergeLeadService.getMergeContacts(this.partyIdFrom, this.partyIdTo).subscribe(res => {
      this.mergeLead = res.data.results;
      this.spinner.hide();
    });
   
  }
}
