import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { CreateAccountFormService } from '../create-account/create-account-service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderService } from '../../header/header.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { EditAccountServices } from 'src/app/editContactGroup/edit-contact-group/edit-contact-group.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-inactive-customer',
  templateUrl: './inactive-customer.component.html',
  styleUrls: ['./inactive-customer.component.css']
})
export class InactiveCustomerComponent implements OnInit {

  products3: any;
  partyIdData='';
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  search = '';
  rows = 50;
  accountsList: any;
  public groupName = '';
  public groupNameSearchType: any = 'Contains';
  public partyId: string = '';
  public partyIdSearchType: any = 'Contains';
  contactMechTypeId = '';
  inactiveUserList: any;
  inactiveUserId: string;

  constructor(
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly HeaderService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly AccountsService: AccountsService,
    readonly _GlobalResourceService: GlobalResourceService,
    public createAccountFormService: CreateAccountFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly _CRMAccountService: CRMAccountService,
    readonly _EditAccountServices: EditAccountServices,
    private _location: Location,
  ) { }

  ngOnInit(): void {
    this.findInactiveUser();
  }
  findInactiveUser() {
    this.inactiveUserId="PARTY_DISABLED"
    this.accountsService.findInactiveUser(this.inactiveUserId).subscribe(res => {
      this.inactiveUserList = res.data;
    })
  }
  backWindow(){
    this._location.back();
  }
  toDashboard(){
    this.router.navigate(['/crm/main-overview']);
  }
  refertToDetail(id){
    this.router.navigate(['/crm/customer-service/inactive-customer-summary-detail'],{queryParams:{id:id}});

  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
