<div class="container-fluid main-container-wrapper">
    <div class="row">


        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span>
                    <span class="color-gry cursor-pointer" routerLink="/financial/payable/commission-invoice">Commission Invoices
                    </span>
                    <span class="color-black pl-1"> > Commission Invoice Summary</span>
                </span>
                <span style="margin-left: 30%;">
                    <p-dropdown filter="true" [options]="invoiceStatus" optionlabel="label" [(ngModel)]="statusId"
                        (ngModelChange)="setStatus($event)" placeholder="Select Status">
                    </p-dropdown>

                    <button type="button" class="btn btn-outline-secondary ml-2"
                        routerLink="/financial/payable/invoices/send-email"
                        [queryParams]="{invoiceId:invoiceId,isCommission:true}">Send
                        Email</button>
                    <button class="btn btn-outline-secondary ml-2" (click)="downloadPDF()" [disabled]="downloading">
                        <ng-container *ngIf="downloading;else noDownload">
                            PDF <fa-icon [icon]="faSpinner" [pulse]="true"></fa-icon>
                        </ng-container>
                        <ng-template #noDownload>
                            PDF
                        </ng-template>
                    </button>
                </span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                    <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span> 
            </div>
          
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">
                                            <div class="divA">
                                                <div class="w3-card-4 classCardView">
                                                  <div class="header-tabs">
                                                    
                                                        <h4 class=" common-styling hCard">
                                                           Commission Invoice Information
                                                        </h4>
                                                      </div>
                                                           <div class="panel-body">
                                                               <div
                                                                   class="all-users-infomation font-13 font-medium own-user-select">
                                                                   <div *ngIf="invoiceValue" class="color-black container">
                                                                       <div class="article-container">
                                                                           <div class="article">
                                                                               <p class="col-lg-6 col-12">Invoice Type</p>
                                                                               <span
                                                                                   class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.invoiceType ? invoiceValue.invoiceType :'N/A'}}</span>
                                                                           </div>
                                                                           <div class="article">
                                                                               <p class="col-lg-6 col-12">Status</p>
                                                                           <span
                                                                               class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.statusId ? invoiceStatusValue :'N/A'}}</span>
                                                                           </div>
                                           
                                                                           <div class="article">
                                                                               <p class="col-lg-6 col-12">Description</p>
                                                                               <span
                                                                                   class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.description ? invoiceValue.description:'N/A'}}</span>
                                                                           </div>
                                           
                                                                           <div class="article">
                                                                               <p class="col-lg-6 col-12">Invoice Message</p>
                                                                           <span
                                                                               class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.invoiceMessage ? invoiceValue.invoiceMessage :'N/A'}}</span>
                                                                           </div>
                                           
                                                                           <div class="article">
                                                                               <p class="col-lg-6 col-12">Customer ID</p>
                                                                           <span
                                                                               class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.partyIdFrom ? invoiceValue.partyIdFrom:'N/A'}}</span>
                                                                           </div>
                                           
                                                                           <div class="article">
                                                                               <p class="col-lg-6 col-12">Supplier ID
                                                                               </p>
                                                                               <span
                                                                                   class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.partyId ? invoiceValue.partyId:'N/A'}}</span>
                                                                           </div>
                                           
                                                                           <div class="article">
                                                                               <p class="col-lg-6 col-12">Role Type ID</p>
                                                                           <span
                                                                               class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.roleTypeId ? invoiceValue.roleTypeId : 'N/A'}}
                                                                           </span>
                                                                           </div>
                                         
                                                                           <div class="article">
                                                                               <p class="col-lg-6 col-12">Billing Account ID</p>
                                                                           <span
                                                                               class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.billingAccountId ? invoiceValue.billingAccountId:'N/A'}}
                                                                           </span>
                                                                           </div>
       
                                                                           <div class="article">
                                                                               <p class="col-lg-6 col-12">Invoice Date</p>
                                                                           <span
                                                                               class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.invoiceDate  ? (invoiceValue.invoiceDate|date:'yyyy-MM-d hh:mm:ss'):'N/A'}}
                                                                           </span>
                                                                           </div>
       
                                                                           <div class="article">
                                                                               <p class="col-lg-6 col-12">Due Date</p>
                                                                               <span
                                                                                   class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.dueDate  ? (invoiceValue.dueDate |date:'yyyy-MM-d hh:mm:ss') :'N/A'}}
                                                                               </span>
                                                                           </div>
       
                                                                           <div class="article">
                                                                               <p class="col-lg-6 col-12">Total
                                                                               </p>
                                                                               <span
                                                                                   class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.total ? invoiceValue.total:'N/A'}}
                                                                               </span>
                                                                           </div>
                                                                           <div class="article">
                                                                               <p class="col-lg-6 col-12">Reference Num
                                                                               </p>
                                                                               <span
                                                                                   class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.referenceNumber}}
                                                                               </span>
                                                                           </div>
                                                                           <div class="article">
                                                                               <p class="col-lg-6 col-12">Date Paid</p>
                                                                               <span
                                                                                   class="color-grey pl-20 col-lg-10 col-12">{{invoiceValue.paidDate  ? (invoiceValue.paidDate|date:'yyyy-MM-d hh:mm:ss') :'N/A'}}
                                                                               </span>
                                                                           </div>
                                           
                                                                      
                                                                           
                                                                         </div>
       
                                                                      
                                                                 
                                                                   </div>
                                                               </div>
                                                           </div>
                                               
                                              </div>
                                              <br>
                                              <div class="w3-card-4 classCardView">
                                                <div class="header-tabs">
                                                   
                                                        <h4 class=" common-styling hCard">
                                                           Items
                                                        </h4>
                                                        <span class="edit-buttons  hCreate" *ngIf="this.invoiceStatusValue=='In-Process'" data-target="#role_Popup" data-toggle="modal"
                                                        [queryParams]="{invoiceId:invoiceId}">Create</span>
                                                      </div>

                                                           <div class="panel-body allUserCardDetail">
                                                               <div class="card own-account-table borderTable">
                                                                   <p-table [value]="invoiceItems"   scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%"
                                                                       [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                       [totalRecords]="total" [scrollable]="true"
                                                                       (sortFunction)="customSort($event)" [customSort]="true">
                                                                       <ng-template pTemplate="header">
                                                                           <tr style="background: #0d3769;">
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="code">
                                                                                   <div class="checkbox-align" style="color: white;">
                                                                                       Item No <p-sortIcon field="code">
                                                                                       </p-sortIcon>
                                                                                   </div>
                                                                                   <input
                                                                                       id="j_idt727:j_idt728:j_idt731:filter"
                                                                                       name="j_idt727:j_idt728:j_idt731:filter"
                                                                                       class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                       value="" autocomplete="off"
                                                                                       aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                       role="textbox" aria-disabled="false"
                                                                                       aria-readonly="false">
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="name" style="width: 1rem;">
                                                                                   <div style="color: white;"> 
                                                                                   Invoice Item Type <p-sortIcon field="name">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Override Gl
                                                                                   Account ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Override Org
                                                                                   Party ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Inventory Item ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Product ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Product Feature
                                                                                   ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Parent Invoice ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Parent Invoice
                                                                                   Item Seq ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> UOM
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Taxable Flag
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;">  Unit Price
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Description
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category">  <div style="color: white;"> Tax Authority
                                                                                   Party
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Tax Auth Geo ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Tax Authority
                                                                                   Rate Seq ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Sales Opportunity
                                                                                   ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Order ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Order Item Seq ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Total
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Action<p-sortIcon
                                                                                       field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
       
                                                                               </th>
                                                                           </tr>
                                                                       </ng-template>
                                                                       <ng-template pTemplate="body" let-product>
                                                                           <ng-container *ngIf="invoiceItems.length">
                                                                               <tr>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       <div class="checkbox-align  account-button">
                                                                                           {{product.invoiceItemSeqId}}
                                                                                       </div>
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.invoiceItemType}}</td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.overrideGlAccountId}}</td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.overrideOrgPartyId}}</td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.inventoryItemId}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.productId}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.productFeatureId}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.parentInvoiceItemSeqId}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.inventoryItemId}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.uomId}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.taxableFlag=='0'?'No':'Yes'}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.quantity}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.amount}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.description}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.taxAuthPartyId}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.taxAuthGeoId}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.taxAuthorityRateSeqId}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
       
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.salesOpportunityId}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
       
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       {{product.total}}
                                                                                   </td>
                                                                                   <td [ngStyle]="{'width':'190px'}">
                                                                                       <fa-icon *ngIf="isEditable"
                                                                                       (click)="viewPageLoc(invoiceId,product.invoiceItemSeqId);"
                                                                                          
                                                                                         
                                                                                            class="account-button" 
                                                                                           [icon]="faEdit"></fa-icon>
                                                                                       <fa-icon *ngIf="isEditable"
                                                                                           (click)="deleteInvoiceItem(product.invoiceItemSeqId)"
                                                                                           class="text-danger cursor-pointer"
                                                                                           [icon]="faTrash"></fa-icon>
                                                                                   </td>
                                                                               </tr>
                                                                           </ng-container>
                                                                       </ng-template>
                                                                       <ng-template pTemplate="emptymessage" let-columns>
                                                                           <tr>
                                                                               <td>
                                                                                   <span class="text-center">
                                                                                       <h4 class="ml-2">No Record Found</h4>
                                                                                   </span>
                                                                               </td>
                                                                           </tr>
                                                                       </ng-template>
                                                                   </p-table>
                                                                   <!-- <p class="paginate_data">
                                                                                                                                                                                                                                                                        - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                   <p class="paginate_data">
                                                                       </p>
                                                               </div>
                                                           </div>
                                             
                                            </div>
                                            <br>
                                            <div class="w3-card-4 classCardView">
                                              <div class="header-tabs">
                                          
                                                    <h4 class=" common-styling hCard">
                                                       Terms
                                                    </h4>
                                                    
                                                    <span class="edit-buttons  hCreate"  *ngIf="isEditable"
                                                        
                                                        [queryParams]="{invoiceId:invoiceId}"data-target="#modalPopup" data-toggle="modal">Create</span>
                                                  </div>
                                                       <div class="panel-body allUserCardDetail">
                                                           <div class="card own-account-table borderTable">
                                                               <p-table [value]="invoiceTerms"   scrollHeight="150px"  [paginator]="false" [rows]="rows"
                                                                scrollWidth="100%"
                                                                   [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                   [totalRecords]="total" [scrollable]="true"
                                                                   (sortFunction)="customSort($event)" [customSort]="true">
                                                                   <ng-template pTemplate="header">
                                                                       <tr style="background: #0d3769;">
                                                                           <th [ngStyle]="{'width':'190px'}"
                                                                               pSortableColumn="code">
                                                                               <div class="checkbox-align" style="color: white;">
                                                                                   Invoice Term ID <p-sortIcon
                                                                                       field="code">
                                                                                   </p-sortIcon>
                                                                               </div>
                                                                               <input
                                                                                   id="j_idt727:j_idt728:j_idt731:filter"
                                                                                   name="j_idt727:j_idt728:j_idt731:filter"
                                                                                   class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                   value="" autocomplete="off"
                                                                                   aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                   role="textbox" aria-disabled="false"
                                                                                   aria-readonly="false">
                                                                           </th>
                                                                           <th [ngStyle]="{'width':'190px'}"
                                                                               pSortableColumn="name" style="width: 1rem;">
                                                                               <div style="color: white;"> 
                                                                               Term Type ID <p-sortIcon field="name">
                                                                               </p-sortIcon></div>
                                                                               <div>
                                                                                   <input
                                                                                       id="j_idt727:j_idt728:j_idt731:filter"
                                                                                       name="j_idt727:j_idt728:j_idt731:filter"
                                                                                       class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                       value="" autocomplete="off"
                                                                                       aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                       role="textbox" aria-disabled="false"
                                                                                       aria-readonly="false">
                                                                               </div>
                                                                           </th>
                                                                           <th [ngStyle]="{'width':'190px'}"
                                                                               pSortableColumn="category"> <div style="color: white;"> Item No
                                                                               <p-sortIcon field="category">
                                                                               </p-sortIcon></div>
                                                                               <div>
                                                                                   <input
                                                                                       id="j_idt727:j_idt728:j_idt731:filter"
                                                                                       name="j_idt727:j_idt728:j_idt731:filter"
                                                                                       class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                       value="" autocomplete="off"
                                                                                       aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                       role="textbox" aria-disabled="false"
                                                                                       aria-readonly="false">
                                                                               </div>
                                                                           </th>
                                                                           <th [ngStyle]="{'width':'190px'}"
                                                                               pSortableColumn="category"> <div style="color: white;"> Item Value
                                                                               <p-sortIcon field="category">
                                                                               </p-sortIcon></div>
                                                                               <div>
                                                                                   <input
                                                                                       id="j_idt727:j_idt728:j_idt731:filter"
                                                                                       name="j_idt727:j_idt728:j_idt731:filter"
                                                                                       class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                       value="" autocomplete="off"
                                                                                       aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                       role="textbox" aria-disabled="false"
                                                                                       aria-readonly="false">
                                                                               </div>
                                                                           </th>
                                                                           <th [ngStyle]="{'width':'190px'}"
                                                                               pSortableColumn="category"> <div style="color: white;"> Term Days
                                                                               <p-sortIcon field="category">
                                                                               </p-sortIcon></div>
                                                                               <div>
                                                                                   <input
                                                                                       id="j_idt727:j_idt728:j_idt731:filter"
                                                                                       name="j_idt727:j_idt728:j_idt731:filter"
                                                                                       class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                       value="" autocomplete="off"
                                                                                       aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                       role="textbox" aria-disabled="false"
                                                                                       aria-readonly="false">
                                                                               </div>
                                                                           </th>
                                                                           <th [ngStyle]="{'width':'190px'}"
                                                                               pSortableColumn="category"> <div style="color: white;"> Text Value
                                                                               <p-sortIcon field="category">
                                                                               </p-sortIcon></div>
                                                                               <div>
                                                                                   <input
                                                                                       id="j_idt727:j_idt728:j_idt731:filter"
                                                                                       name="j_idt727:j_idt728:j_idt731:filter"
                                                                                       class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                       value="" autocomplete="off"
                                                                                       aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                       role="textbox" aria-disabled="false"
                                                                                       aria-readonly="false">
                                                                               </div>
                                                                           </th>
                                                                           <th [ngStyle]="{'width':'190px'}"
                                                                               pSortableColumn="category"> <div style="color: white;"> Description
                                                                               <p-sortIcon field="category">
                                                                               </p-sortIcon></div>
                                                                               <div>
                                                                                   <input
                                                                                       id="j_idt727:j_idt728:j_idt731:filter"
                                                                                       name="j_idt727:j_idt728:j_idt731:filter"
                                                                                       class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                       value="" autocomplete="off"
                                                                                       aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                       role="textbox" aria-disabled="false"
                                                                                       aria-readonly="false">
                                                                               </div>
                                                                           </th>
                                                                           <th [ngStyle]="{'width':'190px'}"
                                                                               pSortableColumn="category"> <div style="color: white;"> Uom
                                                                               <p-sortIcon field="category">
                                                                               </p-sortIcon></div>
                                                                               <div>
                                                                                   <input
                                                                                       id="j_idt727:j_idt728:j_idt731:filter"
                                                                                       name="j_idt727:j_idt728:j_idt731:filter"
                                                                                       class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                       value="" autocomplete="off"
                                                                                       aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                       role="textbox" aria-disabled="false"
                                                                                       aria-readonly="false">
                                                                               </div>
                                                                           </th>
                                                                           <th [ngStyle]="{'width':'190px'}"
                                                                               pSortableColumn="category"> <div style="color: white;"> Action<p-sortIcon
                                                                                   field="category">
                                                                               </p-sortIcon></div>
                                                                               <div>
                                                                                   <input
                                                                                       id="j_idt727:j_idt728:j_idt731:filter"
                                                                                       name="j_idt727:j_idt728:j_idt731:filter"
                                                                                       class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                       value="" autocomplete="off"
                                                                                       aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                       role="textbox" aria-disabled="false"
                                                                                       aria-readonly="false">
                                                                               </div>
   
                                                                           </th>
                                                                       </tr>
                                                                   </ng-template>
                                                                   <ng-template pTemplate="body" let-product>
                                                                       <ng-container *ngIf="invoiceTerms.length">
                                                                           <tr>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   <div class="checkbox-align  account-button">
                                                                                       {{product.invoiceTermId}}
                                                                                   </div>
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.termTypeId}}</td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.itemNo}}</td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.termValue}}</td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.termDays}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.textValue}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.description}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.uom}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   <fa-icon *ngIf="isEditable"  (click)="viewPage(invoiceId,product.invoiceTermId);"
                                                                                       
                                                                                      
                                                                                        class="account-button" 
                                                                                       [icon]="faEdit"></fa-icon>
                                                                                   <fa-icon *ngIf="isEditable"
                                                                                       (click)="deleteInvoiceTerm(product.invoiceTermId)"
                                                                                       class="text-danger cursor-pointer"
                                                                                       [icon]="faTrash"></fa-icon>
                                                                               </td>
                                                                           </tr>
                                                                       </ng-container>
   
                                                                   </ng-template>
                                                                   <ng-template pTemplate="emptymessage" let-columns>
                                                                       <tr>
                                                                           <td>
                                                                               <span class="text-center">
                                                                                   <h4 class="ml-2">No Record Found</h4>
                                                                               </span>
                                                                           </td>
                                                                       </tr>
                                                                   </ng-template>
                                                               </p-table>
                                                               <!-- <p class="paginate_data">
                                                                                                                                                                                                                        - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                               <p class="paginate_data">
                                                                   </p>
                                                           </div>
                                                       </div>
                                           
                                          </div>
                                            <br>
                                             
                                             </div>
                                            
                                             <div class="divB">
                                                <div class="w3-card-4 classCardView">
                                                  <div class="header-tabs">
                                                
                                                   
                                                        <h4 class=" common-styling hCard">
                                                           Applied Payments
                                                                   {{appliedAmount.appliedAmount |currency:'USD'}} Open
                                                                   {{appliedAmount.notAppliedAmount|currency:'USD'}}
                                                        </h4>
                                                       </div>
                                                           <div class="panel-body allUserCardDetail">
                                                               <div class="card own-account-table borderTable">
                                                                   <p-table [value]="appliedPayments"   scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%"
                                                                       [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                       [totalRecords]="total" [scrollable]="true"
                                                                       (sortFunction)="customSort($event)" [customSort]="true">
                                                                       <ng-template pTemplate="header">
                                                                           <tr style="background: #0d3769;">
                                                                               
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category">
                                                                                   <div class="checkbox-align" style="color: white;">
                                                                                       Total <p-sortIcon field="code">
                                                                                       </p-sortIcon>
                                                                                   </div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Payment ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Amount Applied
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
       
       
                                                                           </tr>
                                                                       </ng-template>
                                                                       <ng-template pTemplate="body" let-product>
                                                                           <tr>
                                                                              
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   <div class="checkbox-align  account-button">
                                                                                       {{product.total |currency :'USD'}}
                                                                                   </div>
                                                                               </td>
       
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.paymentId}}</td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.amountApplied |currency :'USD'}}
                                                                               </td>
                                                                           </tr>
       
       
                                                                       </ng-template>
                                                                       <ng-template pTemplate="emptymessage" let-columns>
                                                                           <tr>
                                                                               <td>
                                                                                   <span class="text-center">
                                                                                       <h4 class="ml-2">No Record Found</h4>
                                                                                   </span>
                                                                               </td>
                                                                           </tr>
                                                                       </ng-template>
                                                                   </p-table>
                                                                   <!-- <p class="paginate_data">
                                                                                                                                                                                                                            - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                   <p class="paginate_data">
                                                                       </p>
                                                               </div>
                                                           </div>
                                                
                                                </div>
                                              <br>
                                              <div class="w3-card-4 classCardView">
                                                <div class="header-tabs">
                                               
                                                        <h4 class=" common-styling hCard">
                                                           Transactions
                                                        </h4>
                                                       </div>
                                                           <div class="panel-body allUserCardDetail">
                                                               <div class="card own-account-table borderTable">
                                                                   <p-table [value]="transactions"   scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%"
                                                                       [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                       [totalRecords]="total" [scrollable]="true"
                                                                       (sortFunction)="customSort($event)" [customSort]="true">
                                                                       <ng-template pTemplate="header">
                                                                           <tr style="background: #0d3769;">
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="code">
                                                                                   <div class="checkbox-align" style="color: white;">
       
                                                                                       Acctg Trans ID <p-sortIcon field="code">
                                                                                       </p-sortIcon>
                                                                                   </div>
                                                                                   <input
                                                                                       id="j_idt727:j_idt728:j_idt731:filter"
                                                                                       name="j_idt727:j_idt728:j_idt731:filter"
                                                                                       class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                       value="" autocomplete="off"
                                                                                       aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                       role="textbox" aria-disabled="false"
                                                                                       aria-readonly="false">
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="name" style="width: 1rem;">
                                                                                   <div style="color: white;">  Acctg Trans Entry Seq ID<p-sortIcon
                                                                                       field="name">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Is Posted
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Fiscal Gl Type ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Acctg Trans Type
                                                                                   ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Transaction Date
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Posted Date
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
       
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Gl Journal ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Trans Type
                                                                                   Description
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Invoice ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Fixed Asset ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Description
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Gl Account ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Product ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Debit Credit Flag
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Amount
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Orig Amount
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Organization
                                                                                   Party ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Gl Account Type
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'220px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Account Code
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Account Name
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> GL Account Class
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Party
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Reconcile Status
                                                                                   ID
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Acctg Trans Entry
                                                                                   Type ID<p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                               <th [ngStyle]="{'width':'190px'}"
                                                                                   pSortableColumn="category"> <div style="color: white;"> Created Date
                                                                                   <p-sortIcon field="category">
                                                                                   </p-sortIcon></div>
                                                                                   <div>
                                                                                       <input
                                                                                           id="j_idt727:j_idt728:j_idt731:filter"
                                                                                           name="j_idt727:j_idt728:j_idt731:filter"
                                                                                           class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                           value="" autocomplete="off"
                                                                                           aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                           role="textbox" aria-disabled="false"
                                                                                           aria-readonly="false">
                                                                                   </div>
                                                                               </th>
                                                                           </tr>
                                                                       </ng-template>
                                                                       <ng-template pTemplate="body" let-product>
                                                                           <tr>
       
       
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   <div class="checkbox-align  account-button">
                                                                                       {{product.acctgTransId}}
                                                                                   </div>
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.acctgTransEntrySeqId}}</td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.isPosted}}</td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.glFiscalTypeId}}</td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.acctgTransTypeId}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.transactionDate |date:'yyyy-MM-d hh:mm:ss'}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.postedDate | date:'yyyy-MM-d hh:mm:ss'}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.glJournalId}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.transTypeDescription}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.invoiceId}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.fixedAssetId}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.transDescription}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.glAccountId}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.productId}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.debitCreditFlag}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.amount}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.origAmount}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.organizationPartyId}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.glAccountTypeId}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'220px'}">
                                                                                   {{product.accountCode}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.accountName}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.glAccountClassDescription}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.partyId}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.reconcileStatusId}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.acctgTransEntryTypeId}}
                                                                               </td>
                                                                               <td [ngStyle]="{'width':'190px'}">
                                                                                   {{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}
                                                                               </td>
                                                                           </tr>
                                                                       </ng-template>
                                                                       <ng-template pTemplate="emptymessage" let-columns>
                                                                           <tr>
                                                                               <td>
                                                                                   <span class="text-center">
                                                                                       <h4 class="ml-2">No Record Found</h4>
                                                                                   </span>
                                                                               </td>
                                                                           </tr>
                                                                       </ng-template>
                                                                   </p-table>
                                                                   <!-- <p class="paginate_data">
                                                                                                                                                                                                                            - {{(pageSize > total) ? total : pageSize}} of {{total}} </p> -->
                                                                   <p class="paginate_data">
                                                                       </p>
                                                               </div>
                                                           </div>
                                             
                                            </div>
                                           <br>
                                           
                                             
                                            
                                             
                                            
                                            
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modalPopup" tabindex="-1" role="dialog" aria-labelledby="modalPopup" aria-hidden="true" style="padding-right: 33%;">
        <div class="modal-dialog" role="document">
            <div class="modal-content cssModelLength" style="width: 197%;">
                <div class="modal-header">
    
                    <span class="color-black pl-1"> Create Commission Invoice Term</span>
    
                    <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" #closebuttonA>&times;</span>
                    </button>
                </div>
                <div class="modal-body d-flex">
                    <div class=" bg-white color-grey">
                        <div class="w3-card-4 classCardPopUp">
                            <div class="container-fluid">
                                <ul class="tabbing-section tabbing-accordians tabClass">
    
                                    <li [ngClass]="activeCategoryMain==2?'active':''"><a>
                             Create Commission Invoice Term</a></li>
    
    
                                </ul>
                            </div>
    
                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        

                                        <form [formGroup]="invoiceTermForm">
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                         margin-left: -9%;">
                                                                            <label for="invoiceTermId">Invoice Term
                                                                                ID</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            formControlName="invoiceTermId">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       ">
                                                                            <label for="partyId">Term Type ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                [options]="invoiceTypeIds"
                                                                                formControlName="termTypeId"
                                                                                optionlabel="label"
                                                                                placeholder="Select Term Type Id">
                                                                            </p-dropdown>
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                         margin-left: -9%;">
                                                                            <label for="itemNo">Item No</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            formControlName="invoiceItemSeqId">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       ">
                                                                            <label for="termValue">Term Value</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            formControlName="termValue">
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                         margin-left: -9%;">
                                                                            <label for="termDays">Term Days</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            formControlName="termDays">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                         ">
                                                                            <label for="termValue">Text Value</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            formControlName="textValue">
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                         margin-left: -9%;">
                                                                            <label for="termValue">Description</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            formControlName="description">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="    font-size: 10px;
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        ">
                                                                            <label for="partyId">Uom</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" [options]="uomIds"
                                                                                optionlabel="label"
                                                                                formControlName="uomId"
                                                                                placeholder="Select Term Type Id">
                                                                            </p-dropdown>
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                               
                                                             
                                                            </div>
                                                            <div class="main-submit-button" style="    margin-right: 27%;">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="submit()">{{editMode ?'Update':'Submit'}}</button>
                                                                <button class="btn btn-danger cursor-pointer ml-2"
                                                                    (click)="reset()">Cancel</button>
                                                            </div>
                                                        </form>


                                    </div>
                                </div>
                            </div>
    
                        </div>
                        
                    </div>
    
    
                </div>
    
            </div>
        </div>
    </div>



    
<ngx-spinner></ngx-spinner> 