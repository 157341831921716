import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { URLS } from 'src/app/app.constant';
import { ApiService } from '../api.service';
import { timeStamp } from 'console';

@Injectable({
  providedIn: 'root'
})
export class quoteNotesService {

  constructor(private http: HttpClient, private _ApiService: ApiService) { }



  createQuoteNote(quoteId: string, formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/quoteNote/${quoteId}`, formData);
  }
  getQuoteNoteById(noteId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/crm/quoteNote/note/${noteId}`);
  }
  updateQuoteNote(noteId: string, quoteId: string, formData: any): Promise<any> {
    return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/crm/quoteNote/${quoteId}/${noteId}`, formData);
  }
  deleteQuoteNote(noteId: string): Promise<any> {
    return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/crm/quoteNote/${noteId}`);
  }
}
