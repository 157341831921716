
<div class="w3-card-4 classCard">
    <!-- <div class="container-fluid">
        <ul class="tabbing-section tabbing-accordians tabClass">

            <li [ngClass]="activeCategory==2?'active':''"><a>
               Warehouse Dashboard</a></li>
           

        </ul>
    </div> -->

    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="heads"> Warehouse Dashboard</div>


                      <!-- NEW CODE -->


                <div id="chart">
              
                    <apx-chart [series]="ChartOptionsWarehouse.series"
                    [chart]="ChartOptionsWarehouse.chartWarehouse"
                    [dataLabels]="ChartOptionsWarehouse.dataLabels"
                    [plotOptions]="ChartOptionsWarehouse.plotOptions"
                    [yaxis]="ChartOptionsWarehouse.yaxis"
                    [xaxis]="ChartOptionsWarehouse.xaxis"
                    [fill]="ChartOptionsWarehouse.fill"
                    [title]="ChartOptionsWarehouse.titles"></apx-chart>

                   
                  </div>


    <!-- PREVIOUS CODE -->


                <!-- <script src="https://cdn.amcharts.com/lib/4/core.js"></script>
                <script src="https://cdn.amcharts.com/lib/4/charts.js"></script>
                <script src="https://cdn.amcharts.com/lib/4/plugins/forceDirected.js"></script>
                <script src="https://cdn.amcharts.com/lib/4/themes/animated.js"></script>
                <div id="chartdiv"></div> -->
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 