<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Events</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="w3-card-4 classCard">

                <div class="w-100">
                    <div>
                        <div *ngIf="activeCategory==2">
                            <div class="header-tabs">
                                <h4 class=" common-styling GRight">
                                    Work Effort Related Summary
                                </h4>
                                <span class="edit-buttons hCreate">
                               
                                    <a  data-toggle="modal"
                                    data-target="#exampleModalCenter" (click)="eventDetailEditData()">Update</a>
                                  </span>

                            </div>

                            <div class="panel-body">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="color-black container">
                                        <div *ngIf="eventDetail" class="row">
                                            <div class="article-container">
                                                <div class="article">
                                                    <p class="col-lg-6 col-12">Name</p>
                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{eventDetail.workEffortName
                                                        ?
                                                        eventDetail.workEffortName : 'NA'}}</span>
                                                </div>
                                                <div class="article">
                                                    <p class="col-lg-6 col-12">Type</p>
                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{eventDetail.workEffortType
                                                        ?
                                                        eventDetail.workEffortType : 'NA'}}</span>
                                                </div>

                                                <div class="article">
                                                    <p class="col-lg-6 col-12 ">Purpose </p>
                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{eventDetail.percentComplete
                                                        ?
                                                        eventDetail.percentComplete : 'NA'}}</span>
                                                </div>

                                                <div class="article">
                                                    <p class="col-lg-6 col-12">Status</p>
                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{eventDetail.status
                                                        ? eventDetail.status :
                                                        'NA'}}</span>
                                                </div>

                                                <div class="article">
                                                    <p class="col-lg-6 col-12">Percent Complete </p>
                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{eventDetail.percentComplete
                                                        ?
                                                        eventDetail.percentComplete : 'NA'}}</span>
                                                </div>

                                                <div class="article">
                                                    <p class="col-lg-6 col-12">Priority</p>
                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{eventDetail.priority
                                                        ? eventDetail.priority
                                                        : 'NA' }}</span>
                                                </div>

                                                <div class="article">
                                                    <p class="col-lg-6 col-12">Estimated Start Date </p>
                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{eventDetail.estimatedStartDate
                                                        |
                                                        date:"yyyy-MM-dd"}}</span>
                                                </div>

                                                <div class="article">
                                                    <p class="col-lg-6 col-12">Estimated Completion Date</p>
                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{eventDetail.estimatedCompletionDate
                                                        |
                                                        date:"yyyy-MM-dd"}}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header"> 

                <span *ngIf="!this.show" class="color-black pl-1"> Create-Event</span>
                <span *ngIf="this.show" class="color-black pl-1"> Update-Event</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!this.show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Create Event</a></li>
                                <li *ngIf="this.show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update Event</a></li>

                            </ul>
                        </div>

                        <div class="container-fluid main-container-dialog">
                            <div class="row">
                                <div class="col-12">
                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form *ngIf="createEventFormService.create_event_form" [formGroup]="createEventFormService.create_event_form" class="w-100">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormC">
                                                                    <label for="exampleInputEmail1">Event
                                                                        Name<span style="color:red">*</span></label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control small-input" formControlName="workEffortName" id="exampleInputEmail1" estimatedStartDate id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Event name">
                                                                    <ng-container *ngIf="this.createEventFormService.create_event_form.controls['workEffortName'].invalid && (this.createEventFormService.create_event_form.controls['workEffortName'].dirty || this.createEventFormService.create_event_form.controls['workEffortName'].touched)">
                                                                        <p class="validate-field" *ngIf="this.createEventFormService.create_event_form.controls['workEffortName'].errors.required">
                                                                            Event Name required!</p>
                                                                    </ng-container>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Description</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="email" class="form-control small-input" formControlName="description" id="exampleInputEmail1" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormC">
                                                                    <label for="exampleInputEmail1">Priority</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown" [(ngModel)]="preSelectedPriority" [options]="priorityArray" optionlabel="label" formControlName="priority">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">To Party
                                                                        ID</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown" filter="true" formControlName="partyId" [options]="partygroupArray" optionlabel="label" placeholder="Select To Party ID">
                                                                    </p-dropdown>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormC">
                                                                    <label for="exampleInputEmail1">Purpose</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-dropdown styleClass="small-dropdown" filter="true" [options]="purposeArray" formControlName="workEffortPurposeTypeId" optionlabel="label" placeholder="Select Purpose">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Estimated
                                                                        Start Date</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-calendar formControlName="estimatedStartDate" id="exampleInputEmail1" placeholder="Select Estimated Start Date">
                                                                    </p-calendar>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightFormC">
                                                                    <label for="exampleInputEmail1">Estimated
                                                                        Completion Date</label>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <p-calendar formControlName="estimatedCompletionDate" id="exampleInputEmail1" placeholder="Select Completion Date">
                                                                    </p-calendar>
                                                                </div>


                                                            </div>
                                                        </div>






                                                    </div>

                                                    <div class="main-submit-button" style="margin-right: 9%;">
                                                        <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update
                                                        </button>
                                                        <button style="width:130px" type="submit" (click)="onSubmit()" *ngIf="!show" class="btn btn-secondary submit-btn">Create
                                                        </button>

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>