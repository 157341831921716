import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PostalEmployeeModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        address1:(data && data.address1) ? data.address1 : null,
        address2:(data && data.address2) ? data.address2 : null,

        countryGeoId:(data && data.countryGeoId) ? data.countryGeoId : null,
    
        postalCode:(data && data.postalCode) ? data.postalCode : null,
        stateProvinceGeoId:(data && data.stateProvinceGeoId) ? data.stateProvinceGeoId : null,
        partyId:(data && data.partyId) ? data.partyId:null,
        allowSolicitation:(data && data.allowSolicitation) ? data.allowSolicitation :null,
        toName:(data && data.toName) ? data.toName :null,
        attnName:(data && data.attnName) ? data.attnName :null,
        contactMechId:(data && data.contactMechId) ? data.contactMechId :null,

   
       
     
    });
   
   
    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   return ({
    address1: new FormControl(data.address1),
    address2: new FormControl(data.address2),
  
    city: new FormControl(data.city),
    toName:new FormControl(data.toName),
    countryGeoId: new FormControl(data.countryGeoId),
   
    partyId:new FormControl(data.partyId),
    postalCode: new FormControl(data.postalCode),
    stateProvinceGeoId: new FormControl(data.stateProvinceGeoId),
    allowSolicitation:new FormControl(data.allowSolicitation),
   
    attnName: new FormControl(data.attnName),
    contactMechId:new FormControl(data.contactMechId)
   

    
    
   
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
