import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {ActivatedRoute} from "@angular/router";
import {Router} from "@angular/router";
import { EmpPhoneFormService } from './update-phone-service';
import { EmployeeService } from '../employees/employess.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-update-phone-number',
  templateUrl: './update-phone-number.component.html',
  styleUrls: ['./update-phone-number.component.css']
})
export class UpdatePhoneNumberComponent implements OnInit {
  partyId: any;
  partyIds:any;
  employeeDetail: any;
  crmStatus: any;
  crmArray: any[]=[];
  solicitation: any[]=[];
  cid: any;
  constructor(
    readonly employeeService:EmployeeService,
    public empPersonalFormService:EmpPhoneFormService,
    readonly toastr:ToastrService,
    readonly activatedRoute:ActivatedRoute,
    readonly spinner:NgxSpinnerService,
    readonly router:Router
  ) {
    this.solicitation = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      }
     
    ];
   }
   ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    
    this.activatedRoute.queryParams.subscribe(params=> {
      this.partyId = params['personId'];
      this.cid = params['cid'];
     
    })
    this.partyIds=this.partyId;
    this.empPersonalFormService.emp_phone_form=null;
    this.getEmployeeDetail();
    this.empPersonalFormService.createEventForm(this.empPersonalFormService.emp_phone_form);
}
onUpdate(){
  this.spinner.show();
  const formData = {... this.empPersonalFormService.emp_phone_form.value};
  this.employeeService.updateTelephone(formData).subscribe((res:any)=> {
    if(res.success) {
      this.toastr.success(res.data.successMessage);
      this.spinner.hide();
      this.router.navigate(["/hr/employees/employee-details"],{queryParams:{employeeDetailId: this.partyId}});
    } else if(res.success == false) {
      this.toastr.error(res.message);
    }
  },(err) => {
    this.spinner.hide();
          for(const value of err.error.errors){
          this.toastr.error(value.fieldName + " " + value.fieldError);
          }
        })
       
}
cancelSubmit(){
  this.router.navigate(["/hr/employees/employee-details"],{queryParams:{employeeDetailId: this.partyId}});
} 

getEmployeeDetail() {
  this.spinner.show();
  this.employeeService.getTelephoneEmployeeId(this.cid).subscribe(res=> {
    this.spinner.hide();
    const formValue = this.empPersonalFormService.emp_phone_form;
    formValue.patchValue({
      contactNumber:res.data.contactNumber,
      allowSolicitation:res.allowSolicitation,
      contactMechId:this.cid,
      areaCode:res.data.areaCode,
      partyId: this.partyId,
      countryCode:res.data.countryCode
    
    })
  })
  
}
ngOnDestroy(): void {
  this.spinner.hide();
    }
}
