<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Project/Sprint</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        

            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                        <li [ngClass]="activeCategory==1?'active':''" (click)="Backlog()"><a>
                                Product Backlog </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="Sprint()"><a>
                                Project/Sprint
                            </a></li>
                        <li [ngClass]="activeCategory==3?'active':''" (click)="MyWork()"><a>
                                My Work</a></li>
                        <li [ngClass]="activeCategory==4?'active':''" (click)="Tasks()"><a>
                                Tasks
                            </a></li>
                        <li [ngClass]="activeCategory==5?'active':''" (click)="OpenTest()"><a>
                                Open Test
                            </a></li>
                        <li [ngClass]="activeCategory==6?'active':''" (click)="Revision()"><a>
                                Revision
                            </a></li>
                    </ul>
                </div>
                <div class=" bg-white color-grey">
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">

                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">

                                                <form class="w-100">

                                                    <div>
                                                        <div class="w3-card-4 classCardView">

                                                            <div class="container-fluid">
                                                                <ul class="tabbing-section tabbing-accordians tabClass p-0" style="width: 102% !important;
                                            flex-wrap: wrap !important;
                                            display: flex !important;">
                                                                    <li><a>
                                                                        Sprints List
                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-3" data-toggle="modal"
                                                                        data-target="#exampleModalCenter">Create New Project</button>
                                                                        </a>
                                                                    </li>

                                                                </ul>
                                                            </div>

                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="ProjectSprintFrontPage" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">


                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'270px'}" pSortableColumn="name">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span style="color: white;">
                                                                                                Owner Company
                                                                                            </span>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Project
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Sprint
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'240px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Product
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Status
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'300px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Product Owner
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Sprint Length Weeks
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>




                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                            <div style="color: white;">
                                                                                                Planned Hours
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                            <div style="color: white;">
                                                                                                Actual Hours
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                            <div style="color: white;">
                                                                                                Act Hrs Not Bill
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                            <div style="color: white;">
                                                                                                Start Date
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">
                                                                                            <div style="color: white;">
                                                                                                End date
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>


                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'270px'}"  (click)="detailedPartyPage(product.ownerCompany)"  >
                                                                                            <div class="checkbox-align" >
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span  (click)="detailedPartyPage(product.ownerCompany)" >
                                                                                                    {{product.ownerCompany}}
                                                                                                </span>
                                                                                               
                                                                                            </div>
                                                                                        </td>
                                                                                        <td (click)="detailedOverviewPage(product.Project ,product.productName[0].internalName , product.productName[0].productId)" [ngStyle]="{'width':'170px'}">
                                                                                            <span   class="account-button">{{product.Project}}</span>

                                                                                        </td>

                                                                                        <td [ngStyle]="{'width':'200px'}">
                                                                                            {{product.SprintID}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'240px'}">
                                                                                            {{product.productName[0].internalName + "[" + 
                                                                                            product.productName[0].productId + "]"}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.Status}}</td>
                                                                                        <td [ngStyle]="{'width':'300px'}">
                                                                                            {{product.productOwner}}
                                                                                        </td>

                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.SprintLengthWeeks}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">

                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.ActualHours}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.ActHrsNotBill}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.StartDate | date:'yyyy-MM-dd HH:mm:ss'}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.Enddate | date:'yyyy-MM-dd HH:mm:ss'}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            {{product.assignedTo}}
                                                                                        </td>

                                                                                        <!-- <td [ngStyle]="{'width':'170px'}">
                                                                                        <svg class="hover"
                                                                                            (click)="updateEvent(product.workEffortId)"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16" height="16"
                                                                                            viewBox="0 0 21.223 21.222">
                                                                                            <g
                                                                                                transform="translate(0 -0.004)">
                                                                                                <g
                                                                                                    transform="translate(0 1.52)">
                                                                                                    <path
                                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                        transform="translate(0 -34.137)" />
                                                                                                </g>
                                                                                                <g
                                                                                                    transform="translate(4.548 0.004)">
                                                                                                    <g
                                                                                                        transform="translate(0 0)">
                                                                                                        <path
                                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                    </td> -->
                                                                                        <!-- <svg (click)="onDelete(product.workEffortId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26"><defs><style>.a{fill:#f44336;}.b{fill:#fafafa; margin-left: 20px;}</style></defs><path class="a fill-color" d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0"/><path class="b" d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0" transform="translate(-147.576 -147.576)"/></svg> -->


                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"></span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Edit Project</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="sprintForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">
                                                            Product Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="ProjectSprintProductNameArray" formControlName="ProductName">

                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="productId">Project Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="ProjectName" >

                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Billed</label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="yesNOArray" formControlName="Billed">

                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Project Goal</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="ProjectGoal" aria-describedby="emailHelp" >
                                                    </div>


                                                </div>
                                            </div>
                                            



                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -4%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="createProjectScrum()">Save</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>