<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Job Posting of {{this.updateId}} </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>
            </div>

            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <header class="w3-container w3-blue">
                            <div class="header-tabs">
                                <h4 class=" common-styling h4Margin">
                                    Job Posting Information
                                </h4>
                                <span class="edit-buttons hCreate">

                              <a click="getdepartmentsListById()"  data-toggle="modal" 
                              data-target="#exampleModalCenter">Update</a>
                            </span>
                            </div>
                        </header>
                        <!-- <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px;">
                        <li [ngClass]="activeCategory==1?'active':''" ><a>
                                Job Posting Information</a></li>
                       
                    </ul> -->
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group">
                                                <div *ngIf="activeCategory==1">

                                                    <div class="panel-body" *ngIf="posting">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Job Posting ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{this.updateId}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Approver Party</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{posting.approverPartyId}}</span>

                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Applying Party</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{posting.applyingPartyId}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Application Date</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{posting.applicationDate| date :"yyyy-MM-dd HH:mm:ss"}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Job Requisition ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{posting.jobRequisitionId}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Status</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{posting.statusId}}</span>
                                                                        </div>






                                                                    </div>




                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
               <span class="color-grey ">Job Posting </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                   <span aria-hidden="true" #closebutton>&times;</span>
               </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==1?'active':''"><a>
                            Update Internal Job Posting </a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="addFormService.add_job_form" [formGroup]="addFormService.add_job_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Application Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="applicationDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Application Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Approver Party <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="approverPartyId" [options]="approveData" optionlabel="label" placeholder="Enter Approver Party">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput" *ngIf="show">
                                                        <label for="exampleInputEmail1">Application ID</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="show">
                                                        <input type="email" disabled formControlName="applicationId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled placeholder="Application ID">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Job Requisition ID	
                                                        <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown formControlName="jobRequisitionId" [options]="getJobRequistionArray" filter="true" optionlabel="label" placeholder="Enter Job Requisition ID">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>




                                            <div class="col-lg-4 col-12">
                                                <div class="form-group">
                                                    <!-- <label for="exampleInputEmail1">Application ID</label> -->
                                                    <input type="hidden" disabled formControlName="statusId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled placeholder="Application ID">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 13%;">
                                            <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button>
                                            <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->

<ngx-spinner></ngx-spinner>