import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {Router} from "@angular/router";
import {AccountsService} from "../../crm/accounts/accounts.service";
import {MyContactsService} from "../../crm/contacts/my-contacts/my-contacts.service";
import {ActivatedRoute} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-list-related-account',
  templateUrl: './list-related-account.component.html',
  styleUrls: ['./list-related-account.component.css']
})
export class ListRelatedAccountComponent implements OnInit {
  fileUploadForm: FormGroup;
  accountData: any;
  accountArray: any=[];
  partyId: any;
  activeCategory=1;
  groupNameSearchType='Contains'
  contactMechTypeId='';
  groupName='';
  partyIds='';
  partyIdSearchType='Contains';
  constructor(readonly formBuilder:FormBuilder,
    readonly router:Router,
    readonly activatedRoute:ActivatedRoute,
    readonly MyContactsService:MyContactsService,
    readonly accountsService:AccountsService,
    readonly spinner: NgxSpinnerService,
    readonly toastr:ToastrService
    ) { }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.partyId = params["partyId"];
    });
  
    this.fileUploadForm = this.formBuilder.group({
      accountPartyId: [''],
      comments:[''],
      contactPartyId:[''],
      partyId:['']
    });
    this.getAccountList();
  }
  navigate(){
    this.router.navigate(["/crm/my-contacts/details-contact"],{queryParams:{id:this.partyId}});
    }
    getAccountList(){
      this.spinner.show();
      let searchAccountForm ={
        contactMechTypeId: this.contactMechTypeId,
        groupName: this.groupName,
        groupNameSearchType: this.groupNameSearchType,
        partyId: this.partyIds,
        partyIdSearchType: this.partyIdSearchType
      }
      let all = "true"
      this.accountsService.getAccountList(searchAccountForm,all).subscribe(res=> {
        this.accountData = res.data;
        this.spinner.hide();
        for(const value of this.accountData){
          this.accountArray.push({
            label:value.partyId,
            value:value.partyId
          })
        }
        
      })
      
    }
    onSubmit(){
      this.spinner.show();
      this.fileUploadForm.get('contactPartyId').patchValue(this.partyId);
      this.fileUploadForm.get('partyId').patchValue(this.partyId);
      const formData = new FormData();
      const formdata = {...this.fileUploadForm.value};
      this.MyContactsService.RelatedAccount(formdata).subscribe((res:any)=> {
        if(res.success) {
          this.toastr.success(res.data.responseMessage);
          this.spinner.hide();
          this.router.navigate(["/crm/my-contacts/details-contact"],{queryParams:{id:this.partyId}});
        }      }
        ,(err) => {
          for(const value of err.error.errors){
          this.toastr.error(value.fieldName + " " + value.fieldError);
          }}
        )
        this.spinner.hide();
    }
    changeactiveCategoryFunction(tab: number){
      this.activeCategory =tab;
    }
}
