import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-update-payment-method-type',
  templateUrl: './update-payment-method-type.component.html',
  styleUrls: ['./update-payment-method-type.component.css']
})
export class UpdatePaymentMethodTypeComponent implements OnInit {
  show: boolean;
  paymentMethodTypeId: any;
  description: any;
  defaultGlAccountId: any;
  updatePayment: FormGroup;
  parentGlAccount: any;
  activeCategory = 2;
  parentGlAccountArray: any[]=[];
  constructor(readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
   ) {
    this.updatePayment = this._FormBuilder.group({
      defaultGlAccountId: [''],
      description: [''],
      paymentMethodTypeId: [''],



    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.paymentMethodTypeId = params["paymentMethodTypeId"];
      this.description = params["description"];
      this.defaultGlAccountId = params["defaultGlAccountId"];

    });
    this.getGlAccountInitialData();
    setTimeout(() => {


      const formValue = this.updatePayment;
      formValue.patchValue({
        defaultGlAccountId: this.defaultGlAccountId,
        description: this.description,
        paymentMethodTypeId: this.paymentMethodTypeId



      })

    }, 3000);

  }
  cancelSubmit() {
    this.router.navigate(["financial/gl-payment-method-type"])
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {
 
      this.parentGlAccount = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.parentGlAccount) {
        this.parentGlAccountArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }


    })
   
  }
  onUpdate() {
    this.spinner.show();
    const requestData = {
      "defaultGlAccountId":this.updatePayment.value.defaultGlAccountId,
      "description":this.updatePayment.value.description,
      "paymentMethodTypeId": this.updatePayment.value.paymentMethodTypeId
     
    }

    this.accountsService.updatePaymentMethodTypeGlSetting(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/gl-payment-method-type"]);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}