<div class="container-fluid main-container-wrapper">

    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Feature Category</span>
               <span class="d-flex">
     
                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                        <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                            <a>Main</a>
                        </li>
                        <li [ngClass]="activeCat==2?'active':''" (click)="catalogOpen()">
                            <a>Catalogs</a>
                        </li>
                        <li [ngClass]="activeCat==3?'active':''" (click)="categoriesOpen()">
                            <a>Categories</a>
                        </li>
                        <li [ngClass]="activeCat==4?'active':''" (click)="productsOpen()">
                            <a>Products</a>
                        </li>
                        <li [ngClass]="activeCat==5?'active':''" (click)="featuresOpen()">
                            <a>Features</a>
                        </li>
                        <li [ngClass]="activeCat==6?'active':''" (click)="promosOpen()">
                            <a>Promos</a>
                        </li>
                        <li [ngClass]="activeCat==7?'active':''" (click)="priceRulesOpen()">
                            <a>Price Rules</a>
                        </li>
                        <li [ngClass]="activeCat==8?'active':''" (click)="storesOpen()">
                            <a>Stores</a>
                        </li>
                        <li [ngClass]="activeCat==9?'active':''" (click)="productStoreGroupsOpen()">
                            <a>Product Store Groups</a>
                        </li>
                        <li [ngClass]="activeCat==10?'active':''" (click)="thesaurusOpen()">
                            <a>Thesaurus</a>
                        </li>
                        <li [ngClass]="activeCat==11?'active':''" (click)="reviewsOpen()">
                            <a>Reviews</a>
                        </li>
                        <li [ngClass]="activeCat==12?'active':''" (click)="configurationsOpen()">
                            <a>Configurations</a>
                        </li>
                        <li [ngClass]="activeCat==13?'active':''" (click)="subscriptionsOpen()">
                            <a>Subscriptions</a>
                        </li>
                        <li [ngClass]="activeCat==14?'active':''" (click)="shippingOpen()">
                            <a>Shipping</a>
                        </li>
                        <li [ngClass]="activeCat==15?'active':''" (click)="imageManagementOpen()">
                            <a>Image Management</a>
                        </li>
                    </ul>
                </div>
                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                    <div class="w3-card-4 classCard">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li [ngClass]="activeCategoryMain==1?'active':''" (click)="featureType()"><a>
                                    Feature Type</a></li>
                                <li [ngClass]="activeCategoryMain==2?'active':''" (click)="featureCategoryGroup()"><a>
                                    Feature Category</a></li>
                                <li [ngClass]="activeCategoryMain==3?'active':''" (click)="featureGroup()"><a>
                                        Feature Group</a></li>
                                        
                                <li [ngClass]="activeCategoryMain==4?'active':''" (click)="featureInteraction()"><a>
                                            Feature Interaction</a></li>


                            </ul>
                        </div>
                        <div class=" bg-white color-grey" style="padding: 15px 15px;">

                            <div class="create-new-leade mt-2">
                                <div class="">
                                    <div class="">
                                        <form class="w-100" [formGroup]="findFeatureInteractionForm">

                                            <div class="w3-card-4  w-100">
                                                <div class="accordion classCard" id="myAccordion">
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header" id="headingOne">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                <!-- <span class="headingAccords"> Search Option</span> -->
                                                                <button *ngIf="!this.Hidebtn" type="button" class="btn btn-outline-secondary active marginHeader" data-toggle="modal" data-target="#exampleModalCenter">Create New Feature Interaction</button>

                                                                <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 71%!important; padding-top: 11px !important;
                                                                    padding-bottom: 11px !important;">
                                                                </button>									
                    
                                                            </div>
                        
                                                        </h2>
                                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                            <div class="card-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select allUser">
                                                                    <div class="row">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Product Feature ID</label>
                                                                                </div>
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true"  optionlabel="label" formControlName="productFeatureId">
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-3">
                                                                                    <input type="text" class="form-control w-100" formControlName="productFeatureId" placeholder="Enter Product Feature ID">
                                                                                </div>
                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                    <label for="exampleInputEmail1">Product Feature Id To</label>
                                                                                </div>
                                                                                <div class="col-lg-3" >
                                                                                    <input type="text" class="form-control w-100" formControlName="productFeatureIdTo" placeholder="Enter Product Feature ID To">
                
                                                                                </div>
                
                                                                               
                                                                                
                                                                            </div>
                                                                        </div>
                
                                                                      
                
                
                                                                    </div>
                
                                                                </div>
        
                                                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -39%;">
                                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="findProductFeatureIactnByIds()">Find</button>&nbsp;
                                                                    <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>

                                            <!-- <div class="w3-card-4 classCard">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <button type="button" class="btn btn-outline-secondary active" data-toggle="modal" data-target="#exampleModalCenter">Create New Feature Interaction</button>
                                                    <button type="button" class="btn btn-outline-secondary active" (click)="toSummaryPage()" >Summary Page</button>

                                                </div><br>
                                                <div class="all-users-infomation font-13 font-medium own-user-select allUser">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Product Feature ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true"  optionlabel="label" formControlName="productFeatureId">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="text" class="form-control w-100" formControlName="productFeatureId" placeholder="Enter Product Feature ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Product Feature Id To</label>
                                                                </div>
                                                                <div class="col-lg-3" >
                                                                    <input type="text" class="form-control w-100" formControlName="productFeatureIdTo" placeholder="Enter Product Feature ID To">

                                                                </div>

                                                               
                                                                
                                                            </div>
                                                        </div>

                                                      


                                                    </div>

                                                </div>
                                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -39%;">
                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="findProductFeatureIactnByIds()">Find</button>&nbsp;
                                                    <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                </div>
                                            </div> -->
                                            
                                            <br>
                                            
                                            
                                            <div class="w3-card-4 classCard">
                                                <header class="w3-container w3-blue">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling h4Margin">
                                                            Feature Interaction List
                                                        </h4>

                                                    </div>
                                                </header>
                                                <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="productFeatureIactnAll" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th width="50%" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    Feature Category ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th width="50%" pSortableColumn="name">
                                                                                <div style="color: white;"> Feature Category ID To
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th width="50%" pSortableColumn="name">
                                                                                <div style="color: white;"> Interaction Type
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th width="50%" pSortableColumn="name">
                                                                                <div style="color: white;"> Action
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>
                                                                               
                                                                            </th>

                                                                      
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td width="50%">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button"  (click)="toSummaryPage(product.productFeatureId)" >
                                                                                        {{product.productFeatureId}}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td width="50%">
                                                                                {{product.productFeatureIdTo}}
                                                                            </td>
                                                                            <td width="50%">
                                                                                {{product.productFeatureIactnTypeId}}
                                                                            </td>
                                                                            <td width="50%">
                                                                                <small *ngIf="!this.Hidebtn" style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                                                pointer; " (click)="removeProductFeatureIactn(product)">Delete</small>
                                                                            </td>
                                                                    

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<!-- <div class="container-fluid main-container-wrapper">

    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Feature Category</span>

            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                        <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                            <a>Main</a>
                        </li>
                        <li [ngClass]="activeCat==2?'active':''" (click)="catalogOpen()">
                            <a>Catalogs</a>
                        </li>
                        <li [ngClass]="activeCat==3?'active':''" (click)="categoriesOpen()">
                            <a>Categories</a>
                        </li>
                        <li [ngClass]="activeCat==4?'active':''" (click)="productsOpen()">
                            <a>Products</a>
                        </li>
                        <li [ngClass]="activeCat==5?'active':''" (click)="featuresOpen()">
                            <a>Features</a>
                        </li>
                        <li [ngClass]="activeCat==6?'active':''" (click)="promosOpen()">
                            <a>Promos</a>
                        </li>
                        <li [ngClass]="activeCat==7?'active':''" (click)="priceRulesOpen()">
                            <a>Price Rules</a>
                        </li>
                        <li [ngClass]="activeCat==8?'active':''" (click)="storesOpen()">
                            <a>Stores</a>
                        </li>
                        <li [ngClass]="activeCat==9?'active':''" (click)="productStoreGroupsOpen()">
                            <a>Product Store Groups</a>
                        </li>
                        <li [ngClass]="activeCat==10?'active':''" (click)="thesaurusOpen()">
                            <a>Thesaurus</a>
                        </li>
                        <li [ngClass]="activeCat==11?'active':''" (click)="reviewsOpen()">
                            <a>Reviews</a>
                        </li>
                        <li [ngClass]="activeCat==12?'active':''" (click)="configurationsOpen()">
                            <a>Configurations</a>
                        </li>
                        <li [ngClass]="activeCat==13?'active':''" (click)="subscriptionsOpen()">
                            <a>Subscriptions</a>
                        </li>
                        <li [ngClass]="activeCat==14?'active':''" (click)="shippingOpen()">
                            <a>Shipping</a>
                        </li>
                        <li [ngClass]="activeCat==15?'active':''" (click)="imageManagementOpen()">
                            <a>Image Management</a>
                        </li>
                    </ul>
                </div>
                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                    <div class="w3-card-4 classCard">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li [ngClass]="activeCategoryMain==1?'active':''" (click)="featureType()"><a>
                                    Feature Type</a></li>
                                <li [ngClass]="activeCategoryMain==2?'active':''" (click)="featureCategoryGroup()"><a>
                                    Feature Category</a></li>
                                <li [ngClass]="activeCategoryMain==3?'active':''" (click)="featureGroup()"><a>
                                        Feature Group</a></li>

                                <li [ngClass]="activeCategoryMain==4?'active':''" (click)="featureInteraction()"><a>
                                    Feature Interaction </a></li>


                            </ul>
                        </div>
                        <div class=" bg-white color-grey" style="padding: 15px 15px;">

                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="searchFilter">
                                            <div class="w3-card-4 classCard">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    <button type="button" class="btn btn-outline-secondary active" data-toggle="modal" data-target="#exampleModalCenter">Create New Feature Interaction</button>
                                                    <button type="button" class="btn btn-outline-secondary active" (click)="toSummaryPage()" >Summary Page</button>

                                                </div><br>
                                                <div class="all-users-infomation font-13 font-medium own-user-select allUser">
                                                    <div class="row">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Product Feature ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="productFeatureId">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="text" class="form-control w-100" formControlName="productFeatureId" placeholder="Enter Feature ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Product Feature Id To</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <input type="text" class="form-control w-100" formControlName="productFeatureIdTo" placeholder="Enter Feature ID To">

                                                                </div>

                                                                <div class="col-lg-3">
                                                                    <input type="text" class="form-control w-100" formControlName="description" placeholder="Enter Description">
                                                                </div>
                                                                
                                                            </div>
                                                        </div>

                                                         <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Feature Category ID</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="productFeatureCategoryIdSearchType">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3">
                                                                    <input type="text" class="form-control w-100" formControlName="productFeatureCategoryId" placeholder="Enter Feature Category ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Description</label>
                                                                </div>
                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="descriptionSearchType">
                                                                    </p-dropdown>
                                                                </div>
                                                                
                                                            </div>
                                                        </div> 


                                                    </div>

                                                </div>
                                                <div class="col-lg-12 col-12 main-submit-button " style="
                                        margin-left: -30%;
                                    "><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" >Find</button>&nbsp;
                                                    <button type="button" class="btn btn-danger ml-2">Reset</button>
                                                </div>
                                            </div><br>
                                            <div class="w3-card-4 classCard">
                                                <header class="w3-container w3-blue">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling h4Margin">
                                                            Feature Interaction List
                                                        </h4>

                                                    </div>
                                                </header>
                                                <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">

                                                            <div class="card own-account-table">
                                                                <p-table [value]="featureCategories" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th width="50%" pSortableColumn="code">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    Feature Category ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th width="50%" pSortableColumn="name">
                                                                                <div style="color: white;"> Feature Category ID To
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th width="50%" pSortableColumn="name">
                                                                                <div style="color: white;"> Interaction Type
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th width="50%" pSortableColumn="name">
                                                                                <div style="color: white;"> Delete
                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                </div>
                                                                               
                                                                            </th>

                                                                      
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td width="50%">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        
                                                                                    </p-checkbox>
                                                                                    <span  class="account-button" ></span>
                                                                                </div>
                                                                            </td>
                                                                            <td width="50%">{{product.description}}</td>
                                                                    

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div> -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Add Feature Interaction</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a>Add Feature Interaction</a></li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addFeatureInteractionForm" >
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Product Feature ID <span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="productFeatureId"
                                                        optionlabel="label" placeholder="Select Product Feature ID" [options]="ProductFeatureIdListArray">
                                                        </p-dropdown>
                                                       
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA" >
                                                        <label for="exampleInputEmail1">Product Feature Id To
                                                    </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="productFeatureIdTo"
                                                        optionlabel="label" placeholder="Select Product Feature ID To" [options]="ProductFeatureIdListArray">
                                                        </p-dropdown>
                                                        <!-- <p-dropdown  formControlName="productFeatureIdTo" placeholder="Product Feature Id To">
                                                        </p-dropdown> -->
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Interaction Type
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" formControlName="interactionType"
                                                        optionlabel="label" placeholder="Select Interaction Type" [options]="interactionTypeArray">
                                                        </p-dropdown>
                                                    </div>
                                                    
                                                </div>
                                            </div>



                                        </div>
                                        <div class="main-submit-button" style="margin-right: 15%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="AddProductFeatureIactn()">Create</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
  
  
  