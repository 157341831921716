import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';

import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { CreateContactsService } from 'src/app/crm/contacts/create-contacts/create-contacts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
@Component({
  selector: 'app-create-party-group-assoc',
  templateUrl: './create-party-group-assoc.component.html',
  styleUrls: ['./create-party-group-assoc.component.css']
})
export class CreatePartyGroupAssocComponent implements OnInit {
  createGroup:FormGroup;
  activeCategory=2;
  partyId: any;
  show: boolean;
  inviteId: any;
  personId: any;
  partyIdArray: any[]=[];
 
 
 
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    public createCont: CreateContactsService,
    readonly addSkillService: AddSkillService,
    readonly accountsService: AccountsService,) { 
      
      this.createGroup = this._FormBuilder.group({
        ToPartyID: [''],
       
       
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
       this.show=false;
       this.getPartyId();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.inviteId = params["inviteId"];
  
      
    });
   
  
 
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
 
     
  onSubmit() {
 
    this.accountsService.createPartyInvitationGroupAssoci(this.inviteId,this.createGroup.value.ToPartyID).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
 
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }}

