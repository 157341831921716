<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
         
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Add New CompDoc</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="openViewTree()"><a>
                                View Tree</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="openEdit()" ><a>
                                Edit</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="openApprovals()" ><a>
                                Approvals</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="openRevisions()"><a>
                                Revisions</a></li>
            
                            <li [ngClass]="activeCategory==5?'active':''" (click)="openCreateNewRoot()"><a>
                                Create New Root CompDoc Template</a></li>
            
                            <li [ngClass]="activeCategory==6?'active':''"  (click)="openApprovals()"><a>
                                View Waiting Approvals</a></li>
                           

                        </ul>
                        
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div *ngIf="activeCategory==5">

                                        <div class="w3-card-4 classCard"  style="width:98%!important; margin-left:1% ;">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                               CompDoc Template
                                                <!-- <button type="button" class="btn btn-outline-secondary active" data-toggle="modal" 
                                                data-target="#createNewCompDocPopup">Create New CompDoc Template</button> -->
                                            </div><br>
                                        <form class="w-100" [formGroup]="createNewCompDocForm">

                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                
                                                        <div class="col-lg-3 form-group classInput" >
                                                            <label for="exampleInputEmail1">
                                                                Comp Doc Template Name</label>
                                                        </div>
                                                        
                                                        <div class="col-lg-2" >
                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Comp Doc Template Name"
                                                            formControlName="compDocTemplateName">
                                                        </div>
                                                    
                                                        
                                                    </div>
                                                </div>
                                                
                                            
                                                
                                                
                                                
                                            </div>
                                        </form>
                                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -67%;">
                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="createRootCompDocTemplate()">Add</button>&nbsp;
                                            </div>
                                        </div><br>

                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                    
                                        

                </div>
            </div>
        </div>
    </div> 
</div>


<ngx-spinner></ngx-spinner> 
