import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-requirements',
  templateUrl: './detail-requirements.component.html',
  styleUrls: ['./detail-requirements.component.css']
})
export class DetailRequirementsComponent implements OnInit {
  @ViewChild('closebutton')closebutton;
  @ViewChild('closebutton2')closebutton2;
  activeCategory = 1;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  advanceRequirement: { RequirementID: string; RequirementTypeID: string; StatusID: string; FacilityID: string; ProductID: string; Description: string; StartDate: string; ByDate: string; };
  advanceSearch: { RequirementID: string; RequirementTypeID: string; FacilityID: string; ProductID: string; Description: string; StartDate: string; ByDate: string; };
  advanceProSearch: { RequirementID: string; CustomerPartyId: string; Supplier: string; CurrencyUomID: string; unassignedRequirements: string; ByDate: string; FacilityID: string; ProductID: string; };
  RequirementForm: any;
  RequirementRoleForm: FormGroup;
  showRole:boolean;
  requirementById: any;
  requirementId: any;
  RequirementType: any;
  fixedAssetIdRequirement: any;
  fixedAssetIdRequirementArray: any[]=[];
  facilityRequirement: any;
  facilityRequirementArray: any[]=[];
  FacilityID: any;
  FacilityIDArray: any[]=[];
  fixedAssetIdArray: any[]=[];
  fixedAssetId: any;
  ProductIdArray: any[]=[];
  ProductId:any;
  RequirementTypeArray: any[]=[];
  statusIdArray: { label: string; value: string; }[];
  requirementRoleListById: any;
  requirementRoleByPartyId: any;
  personId: any;
  partyIdArray: any[]=[];
  RoleTypeId: any;
  RoleTypeIdArray: any[]=[];
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService, 
    readonly headerService: HeaderService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.advanceRequirement = {
      RequirementID: "",
      RequirementTypeID: "",
      StatusID: "",
      FacilityID: "",
      ProductID: "",
      Description: "",
      StartDate: "",
      ByDate: "",
     
    };
    this.advanceSearch = {
      RequirementID: "",
      RequirementTypeID: "",
      FacilityID: "",
      ProductID: "",
      Description: "",
      StartDate: "",
      ByDate: "",
     
    };
    this.advanceProSearch = {
      RequirementID: "",
      CustomerPartyId: "",
      Supplier: "",
      CurrencyUomID: "",
      unassignedRequirements: "",
      ByDate: "",
      FacilityID: "",
      ProductID: "",
     
    };
    this.RequirementForm = this._FormBuilder.group({
      RequirementTypeID: [''] ,
      FacilityID: [''] ,
      DeliverableID: [''] ,
      FixedAssetID: [''] ,
      ProductID: [''] ,
      StatusID: [''] ,
      Description: [''] ,
      StartDate: [''] ,
      ByDate: [''] ,
      EstimatedBudget: [''] ,
      Quantity: [''] ,
      UseCase: [''] ,
      Reason: [''] ,
      CreatedDate: [''] ,
      CreatedByUser: [''] ,
      LastModifiedDate: [''] ,
      FacilityIDTo: [''] ,
      LastModifiedUser: [''] ,
      CustRequestID: [''] ,
      CustRequestItemSeqID: [''] ,
    });
    this.RequirementRoleForm = this._FormBuilder.group({
      PartyID: [''] ,
      RoleTypeID: [''] ,
      FromDate: [''] ,
      ThroughDate: [''] ,
      updateFromDate:['']
     
    });
    this.statusIdArray = [

      {
        label: 'Proposed',
        value: 'REQ_PROPOSED'
      },
      {
        label: 'Created',
        value: 'REQ_CREATED'
      },
      {
        label: 'Approved',
        value: 'REQ_APPROVED'
      },
      {
        label: 'Ordered',
        value: 'REQ_ORDERED'
      },
      {
        label: 'Rejected',
        value: 'REQ_REJECTED'
      },
    ];
   }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=>{
      this.requirementId = params.id;
    })
    this.getRequirementById();
    this.getFixedAssetIdRequirement();
    this.getFacilityRequirement();
    this.getfixedAssetID();
    this.getProductId();
    this.getRequirementType();
    this.getRequirementRoleListById();
   // this.getRequirementRoleByPartyId();
    this.getPartyId();
    this.getRoleTypeIdOrder();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/order/main-order']);
  }
  autoAssignRequirementToSupplier() {
    this.spinner.show();
   
    this.myContactsService.autoAssignRequirementToSupplier(this.requirementId).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success(" Success");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  deleteRequirementRole(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "fromDate":this.datePipe.transform( product.fromDate, "yyyy-MM-dd hh:mm:ss") ,
          "partyId":product.partyId,
          "requirementId": this.requirementId,
          "roleTypeId": product.roleTypeId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"
        }
        this.myContactsService.deleteRequirementRole(req).subscribe(res => {
          this.getRequirementRoleListById(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  getRoleTypeIdOrder() {
    this.spinner.show();
    this.myContactsService.getRoleTypeIdOrder().subscribe(res => {
      this.RoleTypeId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.RoleTypeId) {
        this.RoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  updateRequirementRole() {
    this.spinner.show();
    const requestData = {
  "fromDate":this.datePipe.transform( this.RequirementRoleForm.value.FromDate,"yyyy-MM-dd HH:mm:ss") ,
  "partyId":  this.RequirementRoleForm.value.PartyID,
  "requirementId": this.requirementId,
  "roleTypeId":this.RequirementRoleForm.value.RoleTypeID,
  "thruDate":this.datePipe.transform( this.RequirementRoleForm.value.ThroughDate,"yyyy-MM-dd HH:mm:ss") 
    }
    this.myContactsService.updateRequirementRole(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.RequirementRoleForm.reset();
        this.getRequirementRoleListById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  createRequirementRole() {
    this.spinner.show();
    const requestData = {
  "fromDate":this.datePipe.transform( this.RequirementRoleForm.value.FromDate,"yyyy-MM-dd HH:mm:ss") ,
  "partyId":  this.RequirementRoleForm.value.PartyID,
  "requirementId": this.requirementId,
  "roleTypeId":this.RequirementRoleForm.value.RoleTypeID,
  "thruDate":this.datePipe.transform( this.RequirementRoleForm.value.ThroughDate,"yyyy-MM-dd HH:mm:ss") 
    }
    this.myContactsService.createRequirementRole(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.RequirementRoleForm.reset();
        this.getRequirementRoleListById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  reset(){
    this.showRole = false;
    this.RequirementRoleForm.reset();
  }
  getRequirementRoleByPartyId(product) {
    this.showRole = true;
    this.spinner.show();
    this.myContactsService.getRequirementRoleByPartyId(product.partyId).subscribe((res: any) => {
      this.requirementRoleByPartyId = res.data[0].data[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.RequirementRoleForm;
        formValue.patchValue ({
          PartyID: product.partyId ,
      RoleTypeID: product.roleTypeId ,
      FromDate: this.datePipe.transform(product.fromDate ,"yyyy-MM-dd HH:mm:ss") ,
      ThroughDate:this.datePipe.transform(product.thruDate ,"yyyy-MM-dd HH:mm:ss") 
         
        })
            }, 2000);

    })
  }
  getRequirementRoleListById() {
    this.spinner.show();
    this.myContactsService.getRequirementRoleListById(this.requirementId).subscribe((res: any) => {
      this.requirementRoleListById = res.data[0].data;
      this.spinner.hide();

    })
  }
  getRequirementType() {
    this.spinner.show();
    this.myContactsService.getRequirementType().subscribe(res => {
      this.RequirementType = res.data[0].requirementTypeData;
      this.spinner.hide();
      for (const value of this.RequirementType) {
        this.RequirementTypeArray.push({
          label: value.description,
          value: value.requirementTypeId
        })
      }
    })
   
  }
  getProductId() {
    this.spinner.show();
    this.accountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
   
  }
  getfixedAssetID() {
    this.spinner.show();
    this.myContactsService.getFixedAssetIdRequirement().subscribe(res => {
      this.fixedAssetId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.fixedAssetId) {
        this.fixedAssetIdArray.push({
          label: value.fixedAssetId,
          value: value.fixedAssetId
        })
      }
    })
  }
  getFacilityRequirement() {
    this.spinner.show();
    this.myContactsService.getFacilityRequirement().subscribe(res => {
      this.facilityRequirement = res.data ;
      this.spinner.hide();
      for (const value of this.facilityRequirement) {
        this.facilityRequirementArray.push({
          label: value.description,
          value: value.facilityId
        })
      }
    })
  }

  getFixedAssetIdRequirement() {
    this.spinner.show();
    this.myContactsService.getFixedAssetIdRequirement().subscribe(res => {
      this.fixedAssetIdRequirement = res.data[0].data ;
      this.spinner.hide();
      for (const value of this.fixedAssetIdRequirement) {
        this.fixedAssetIdRequirementArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
    })
    
  }
  updateRequirement() {
    this.spinner.show();

    const requestData = {
      "createdByUserLogin": this.RequirementForm.value.CreatedByUser,
      "createdDate": this.datePipe.transform(this.RequirementForm.value.CreatedDate, "yyyy-MM-dd HH:mm:ss"),
      "custRequestId": this.RequirementForm.value.CustRequestID,
      "custRequestItemSeqId": this.RequirementForm.value.CustRequestItemSeqID,
      "deliverableId": this.RequirementForm.value.DeliverableID,
      "description": this.RequirementForm.value.Description,
      "estimatedBudget": this.RequirementForm.value.EstimatedBudget,
      "facilityId": this.RequirementForm.value.FacilityID,
      "facilityIdTo": this.RequirementForm.value.FacilityIDTo,
      "fixedAssetId": this.RequirementForm.value.FixedAssetID,
      "lastModifiedByUserLogin": this.RequirementForm.value.LastModifiedUser,
      "lastModifiedDate": this.datePipe.transform(this.RequirementForm.value.LastModifiedDate, "yyyy-MM-dd HH:mm:ss"),
      "productId": this.RequirementForm.value.ProductID,
      "quantity": this.RequirementForm.value.Quantity,
      "reason": this.RequirementForm.value.Reason,
      "requiredByDate": this.datePipe.transform(this.RequirementForm.value.ByDate, "yyyy-MM-dd HH:mm:ss"),
      "requirementId": this.requirementId ,
      "requirementStartDate": this.datePipe.transform(this.RequirementForm.value.StartDate, "yyyy-MM-dd HH:mm:ss"),
      "requirementTypeId": this.RequirementForm.value.RequirementTypeID,
      "statusId": this.RequirementForm.value.StatusID,
      "useCase": this.RequirementForm.value.UseCase,


     
    }

    this.myContactsService.updateRequirement(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.RequirementForm.reset();
        this.getRequirementById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  getRequirementById() {
    this.spinner.show();
    this.myContactsService.getRequirementById(this.requirementId).subscribe((res: any) => {
      this.requirementById = res.data[0].data[0];
      this.spinner.hide();

       setTimeout(() => {
   const formValue = this.RequirementForm;
   formValue.patchValue ({
     RequirementTypeID: this.requirementById.requirementTypeId ,
     FacilityID: this.requirementById.facilityId ,
     DeliverableID: this.requirementById.deliverableId ,
     FixedAssetID: this.requirementById.fixedAssetId ,
     ProductID: this.requirementById.productId ,
     StatusID: this.requirementById.statusId ,
     Description: this.requirementById.description ,
     StartDate:this.datePipe.transform(this.requirementById.requirementStartDate, "yyyy-MM-dd")  ,
     ByDate: this.datePipe.transform(this.requirementById.requiredByDate, "yyyy-MM-dd")  ,
     EstimatedBudget: this.requirementById.estimatedBudget ,
     Quantity: this.requirementById.quantity ,
     UseCase: this.requirementById.useCase ,
     Reason: this.requirementById.reason ,
     CreatedDate: this.datePipe.transform(this.requirementById.createdDate, "yyyy-MM-dd")  ,
     CreatedByUser: this.requirementById.createdByUserLogin ,
     LastModifiedDate: this.datePipe.transform(this.requirementById.requirementStartDate, "yyyy-MM-dd")  ,
     FacilityIDTo: this.requirementById.facilityIdTo ,
     LastModifiedUser: this.requirementById.lastModifiedByUserLogin ,
     CustRequestID: this.requirementById.gdgd ,
     CustRequestItemSeqID: this.requirementById.gdgd ,
   })

       
        
       }, 2000);

    })
  }
  updateRole(){
    this.showRole= true;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
