<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Production Run Declaration


 
                </span>
                <span class="color-black pl-1" > >Add Production Run Declaration



 
                </span>

                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''"  ><a>
                        Add Production Run Declaration

                    </a></li>
                 
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createProduction">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                           
                                            <div class="col-lg-3 form-group formRight">
                                                <label for="exampleInputEmail1">
                                                    Sequence Num</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Sequence Num"
                                                formControlName="SequenceNum" readonly>
                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Routing Task Name</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Routing Task Name"
                                                formControlName="RoutingTaskName"readonly >
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12">
                                        <div class="row">
                                           
                                            <div class="col-lg-3 form-group formRight">
                                                <label for="exampleInputEmail1">
                                                    Description</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Description"
                                            formControlName="Description" readonly>
                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Calculated Completion Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Calculated Completion Date"
                                                formControlName="CalculatedCompletionDate"readonly >
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                           
                                            <div class="col-lg-3 form-group formRight">
                                                <label for="exampleInputEmail1">
                                                    From Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Routing Task Name"
                                            formControlName="FromDate" >
                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    To Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Routing Task Name"
                                            formControlName="ToDate" >
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                           
                                            <div class="col-lg-3 form-group formRight">
                                                <label for="exampleInputEmail1">
                                                    Estimated Setup Time</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Estimated Setup Time"
                                                formControlName="EstimatedSetupTime" readonly>
                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Actual Setup Time</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Actual Setup Time"
                                            formControlName="ActualSetupTime" readonly>
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                           
                                            <div class="col-lg-3 form-group formRight">
                                                <label for="exampleInputEmail1">
                                                    Add Actual Setup Time</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Add Actual Setup Time"
                                            formControlName="AddActualSetupTime" >
                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Estimated Unit Run Time</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Estimated Unit Run Time"
                                                formControlName="EstimatedUnitRunTime" readonly>
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                           
                                            <div class="col-lg-3 form-group formRight">
                                                <label for="exampleInputEmail1">
                                                    Actual Time</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Actual Time"
                                                formControlName="ActualTime" readonly>
                                              
                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Add Actual Task Time</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Add Actual Task Time"
                                            formControlName="AddActualTaskTime" >
                                              
                                            </div>
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12">
                                        <div class="row">
                                           
                                            <div class="col-lg-3 form-group formRight">
                                                <label for="exampleInputEmail1">
                                                    Qty To Produce</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Qty To Produce"
                                            formControlName="QtyToProduce" readonly>
                                              
                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Produced</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Produced"
                                            formControlName="Produced" readonly>
                                              
                                            </div>
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                           
                                            <div class="col-lg-3 form-group formRight">
                                                <label for="exampleInputEmail1">
                                                    Add Qty Produced</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Add Qty Produced"
                                            formControlName="AddQtyProduced" >
                                              
                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Rejected</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Rejected"
                                                formControlName="Rejected" >
                                              
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                           
                                            <div class="col-lg-3 form-group formRight">
                                                <label for="exampleInputEmail1">
                                                    Add Qty Rejected</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Add Qty Rejected"
                                            formControlName="AddQtyRejected" >
                                          
                                              
                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    Comments</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Comments"
                                            formControlName="Comments" >
                                          
                                              
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                           
                                            <div class="col-lg-3 form-group formRight">
                                                <label for="exampleInputEmail1">
                                                    Worker</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Worker"
                                            formControlName="Worker" >
                                          
                                              
                                            </div>
                                        </div>
                                    </div>
                                  
                                
                                   
                                 
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 66%;">
                               
                                <button type="submit"  (click)="onSubmit()"   class="btn btn-secondary submit-btn">Save</button>
                             
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
