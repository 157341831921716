import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';   import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-open-test',
  templateUrl: './open-test.component.html',
  styleUrls: ['./open-test.component.css']
})
export class OpenTestComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 5;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  partyId:any;
  pageNo = 1;
  sprintTable: any;
  ProductName: any;
  ProductNameArray: any[] = [];
  productId: any;
  findBackloglist: any;
  advanceSearch: { Product: string; };
 
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,    
    private _location: Location,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
    this.advanceSearch = {
      Product: "",
    };
  }

  ngOnInit(): void {
    this.spinner.hide();
    this.activatedRoute.queryParams.subscribe(params => {
      this.productId = params.productId;

    })
    this.getProductName();
    this.findBacklog();
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.Product = "";


    this.accountsService.findBacklog().subscribe((res: any) => {
      this.findBackloglist = res.data;
      this.spinner.hide();


    })
  }
  findBacklogOpenTest() {

    const hh = this.advanceSearch.Product ;
    const ret = hh.substring(hh.indexOf("[") + 1);
    const str = ret.substring(0, ret.length - 1);
   // var rr = ret.substring(ret.indexOf("]") + 1);
    console.log(str); 

    this.spinner.show();
    this.findBacklog();

    const req = {
      "productId": str ? str : '',
    }
    this.accountsService.findOpenTestBacklog(req.productId).subscribe((res: any) => {
      this.findBackloglist = res.data;
      this.spinner.hide();

    })
    this.spinner.hide();
  }

  findBacklog() {
    this.spinner.show();
    this.accountsService.findBacklog().subscribe(res => {
      this.findBackloglist = res.data;
      this.spinner.hide();
    })
  }

  getProductName() {
    this.spinner.show();
    this.accountsService.getProductName().subscribe(res => {
      this.ProductName = res.data;
      this.spinner.hide();
      for (const value of this.ProductName) {
        this.ProductNameArray.push({
          label: value.productName,
          value: value.productName
        })
      }
    })
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  Backlog() {
    this._Router.navigate(["/psa/product-backlog"])
  }
  Sprint() {
    this._Router.navigate(["/psa/sprints"])
  }
  MyWork() {
    this._Router.navigate(["/psa/my-work"])
  }
  Tasks() {
    this._Router.navigate(["/psa/tasks"])
  }
  OpenTest() {
    this._Router.navigate(["/psa/open-test"])
  }
  Revision() {
    this._Router.navigate(["/psa/find-task-revision"])
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
