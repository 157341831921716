import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MergeContactsService } from '../contacts/merge-contacts/merge-contacts.service';
import { MyLeadsService } from '../my-leads/my-leads.service';

@Component({
  selector: 'app-merge-leads',
  templateUrl: './merge-leads.component.html',
  styleUrls: ['./merge-leads.component.css']
})
export class MergeLeadsComponent implements OnInit {

  public contacts = {
    pageNo: 1,
    pageSize: 200,
    isMyLead: 'false'
  };
  leadAdvancedSearch: {
    contactMechId: string,
    firstName: string,
    firstNameSearchType: string,
    lastName: string,
    lastNameSearchType: string,
    partyId: string,
    partyIdSearchType: string
  };
  partyIdFrom: string;
  partyIdTo: string;
  partyIdArray: any[];
  mergeContacts: any;
  useAddressTo: string;
  useEmailTo: string;
  useContactNumTo: string;
  mergearray: any[];
  mergedata: any[];
  mergeLeadForm: any;
  constructor(
    readonly _MyLeadsService: MyLeadsService,
    readonly _MergeContactsService: MergeContactsService,
    readonly spinner:NgxSpinnerService,
    readonly _ToastrService: ToastrService
  ) {
    this.leadAdvancedSearch = {
      contactMechId: '',
      firstName: '',
      firstNameSearchType: 'Contains',
      lastName: '',
      lastNameSearchType: 'Contains',
      partyId: '',
      partyIdSearchType: 'Contains'
    };
    this.useAddressTo = '';
    this.useEmailTo = '';
    this.useContactNumTo = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getPartyId();
  }

  getPartyId(): void {
    this.spinner.show();
    this._MyLeadsService.getLeadsList(this.contacts, this.leadAdvancedSearch)
      .subscribe(data => {
        this.spinner.hide();
        for (const value of data.data) {
          this.partyIdArray.push({
            label: value.partyId,
            value: value.partyId
          });
        }
      });
     
  }
  onSubmit(): void {
    this.spinner.show();
    this.mergearray = [];
    this._MergeContactsService.getMergeContacts(this.partyIdFrom, this.partyIdTo).subscribe(res => {
   
      this.mergeContacts = res.data.results;
      this.spinner.hide();
      this.mergearray.push({
        data: this.mergeContacts
      });
      this.mergedata = this.mergearray[0].data;
    });
   
  }
  submitMergeLeads(): void {
    this.spinner.show();
    const mergeContactFrom = {
      addrContactMechId: this.partyIdFrom,
      addrContactMechIdTo: this.partyIdTo,
      emailContactMechId: this.mergeContacts[0].primaryEmail,
      emailContactMechIdTo: this.mergeContacts[1].primaryEmail,
      partyId: this.partyIdFrom,
      partyIdTo: this.partyIdTo,
      phoneContactMechId: this.mergeContacts[0].contactNumber,
      phoneContactMechIdTo: this.mergeContacts[1].contactNumber,
      useAddress2: this.useAddressTo,
      useContactNum2: this.useContactNumTo,
      useEmail2: this.useEmailTo,
    };
    this._MergeContactsService.submitMergeLead(mergeContactFrom).subscribe((res: any) => {
      (res.success) ?
        this._ToastrService.success(res.data.responseMessage) :
        this._ToastrService.error(res.data.message);
        this.spinner.hide();
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
   
  }
  public checkAddress(event: any): void {
    this.useAddressTo = event.target.checked ? 'Y' : 'N';
  }
  public checkEmail(event: any): void {
    this.useEmailTo = event.target.checked ? 'Y' : 'N';
  }
  public checkContact(event: any): void {
    this.useContactNumTo = event.target.checked ? 'Y' : 'N';
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
