<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Routing Task Product


 
                </span>
                <span class="color-black pl-1" > >Add New Routing Task Product



 
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show" ><a>
                        Add Routing Task Product

                    </a></li>
                    <li [ngClass]="activeCategory==2?'active':''"  *ngIf="this.show"><a>
                        Update Routing Task Product

                    </a></li>
                 
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createDeliverable">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRight"  >
                                                <label for="exampleInputEmail1">
                                                    Product ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2"  *ngIf="!this.show">
                                                <p-dropdown  placeholder="Enter Product ID" filter="true"
                                            formControlName="ProductID"
                                            [options]="productIdArray" optionlabel="label" >
                                                            
                                            </p-dropdown>

                                            </div>
                                            <div class="col-lg-2"  *ngIf="this.show">
                                                <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Sequence Num"
                                            formControlName="ProductID" readonly>

                                            </div>
                                            <div class="col-lg-3 form-group formRightA">
                                                <label for="exampleInputEmail1">
                                                    From Date</label>
                                            </div>
                                            <div class="col-lg-2" *ngIf="!this.show">
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Sequence Num"
                                            formControlName="FromDate" >
                                            </div>
                                            <div class="col-lg-2" *ngIf="this.show">
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Sequence Num"
                                            formControlName="FromDate"  readonly>
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group formRight"  >
                                                <label for="exampleInputEmail1">
                                                    Through Date</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Routing Task Name"
                                                formControlName="ThroughDate" >

                                            </div>
                                            <div class="col-lg-2"></div>
                                          
                                        </div>
                                    </div>
                                   
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: -31% !important;">
                               
                                <button type="submit" *ngIf="!this.show" (click)="onSubmit()"      class="btn btn-secondary submit-btn">Save</button>
                                <button type="submit" *ngIf="this.show" (click)="onUpdate()"      class="btn btn-secondary submit-btn">Update</button>
                             
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
