import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-respose-summary',
  templateUrl: './respose-summary.component.html',
  styleUrls: ['./respose-summary.component.css']
})
export class ResposeSummaryComponent implements OnInit {
  constructor(
  ) {
  }
  ngOnInit(): void {
  }
}