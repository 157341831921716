import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ProductionService } from '../production.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';import { Location } from '@angular/common';
@Component({
  selector: 'app-routing',
  templateUrl: './routing.component.html',
  styleUrls: ['./routing.component.css']
})
export class RoutingComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  advanceSearch: {

    RoutingID: string,
    routingSearchId:string,
    RoutingName:string,
    routingNameType: string,
    CurrentStatusID: string,



  };
  createroute:FormGroup;
  activeCategory=2;
  id: string;
  statusId: any;
  statusIdArray: any[]=[];
  routingId: any;
  show: boolean;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  financialList=[{
    "product":"1"
  }];
  FinAccountTypeList: any;
  FinAccountTypeListArray: any[] = [];
  smallerArray: { label: string; value: string; }[];
  greaterArray: { label: string; value: string; }[];
  RoutingList: any[]=[];

  statusSelect: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
    readonly router: Router, private _location: Location,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
  
   
   
    readonly _ActivatedRoute: ActivatedRoute,
    ) {
      this.createroute = this._FormBuilder.group({
        RoutingName: [''],
        Description:[''],
        QtyToProduce:[''],
        CurrentStatusID: [''],
      
       
      });
    this.advanceSearch = {

      RoutingID: '',
      routingSearchId: 'Contains',
      RoutingName: '',
      routingNameType: 'Contains',
      CurrentStatusID: '',
 
    };
  
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getRoutingList();
    this.getstatusByType();
  }

  onSubmit() {
  
    const requestData = {
      "currentStatusId": this.createroute.value.CurrentStatusID,
      "description": this.createroute.value.Description,
      "quantityToProduce": this.createroute.value.QtyToProduce,
      "routingId": "",
      "routingName": this.createroute.value.RoutingName,

    }

    this.productionService.postRouting(requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebutton.nativeElement.click();
        this.getRoutingList();
        
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getstatusByType() {
    this.spinner.show();
    this.id="ROUTING_STATUS"
    this.productionService.getstatusByType(this.id).subscribe(res => {
      this.statusId = res.data;
      this.spinner.hide();
      for (const value of this.statusId) {
        this.statusIdArray.push({
          label: value.statusCode,
          value: value.statusId
        })
      }
    })
    
  }
  onStatusChange(event) {
  /*   this.statusSelect=[]
   this.statusSelect.push(event.value)
    */
   this.statusSelect=event.value
  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.financialList = [];
    const req = {
      "currentStatusIds": this.statusSelect==undefined?[""]:this.statusSelect,
      "routingId": this.advanceSearch.RoutingID ? this.advanceSearch.RoutingID : '',
      "routingIdSearchType": this.advanceSearch.routingSearchId ? this.advanceSearch.routingSearchId : '',
      "routingName": this.advanceSearch.RoutingName ? this.advanceSearch.RoutingName : '',
      "routingNameSearchType": this.advanceSearch.routingNameType ? this.advanceSearch.routingNameType : '',
 

    }
    this.productionService.getRoutingList(this.finSize, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.RoutingList = resp.data;
        } 
      });
     
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.RoutingName = "";
    this.advanceSearch.RoutingID = "";
    this.advanceSearch.CurrentStatusID="";
    this.statusSelect="";
    this.RoutingList = []
    const req = {
      "currentStatusIds": [""],
      "routingId": "",
      "routingIdSearchType": "Contains",
      "routingName": "",
      "routingNameSearchType": "Contains"

    }
    this.productionService.getRoutingList(this.finSize,req).subscribe((res: any) => {
      this.RoutingList = res.data;
      this.spinner.hide();

    })
   
  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getRoutingList() {
    this.spinner.show();
    const req={
      "currentStatusIds": [""],
      "routingId": "",
      "routingIdSearchType": "Contains",
      "routingName": "",
      "routingNameSearchType": "Contains"
    }
    this.productionService.getRoutingList(this.finSize,req).subscribe((res: any) => {
      this.RoutingList = res.data;
      this.spinner.hide();
    })
  
  }

  routingDetails(id) {
    this.router.navigate(["production/detail-routing-summary"],{ queryParams: { routingId : id}})
  }
 
  createRoute() {
    this.router.navigate(["production/create-routing"])

  }
   
  updateRouting(id) {
    this.router.navigate(["production/create-routing"],{ queryParams: { routingId : id}})

  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
 
  ngOnDestroy(): void {
    this.spinner.hide();
      }

}

