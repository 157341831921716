import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class PayablePaymentService {
  constructor(
    readonly _ApiService: ApiService
  ) { }



  getStatus(): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/payments/paymentType`);
  }
  getPaymentList(pagination: { pageNo: number, pageSize: number }, formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accountingPayable/outgoingPayment/search?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, formData);
  }
  getPaymentById(paymentId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/payments/${paymentId}`);
  }
  updatePayment(paymentId: string, formData: any): Promise<any> {
    return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/accounting/payments/${paymentId}`, formData);
  }
  getPaymentType(): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accountingPayable/outgoingPayment/paymentType`);
  }
  createOutgoingPayment(formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accountingPayable/outgoingPayment`, formData);
  }
  createPaymentIncoming(formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/myPortal/createPaymentIncoming`, formData);
  }
  getGIAccountAndPaymentMethod(): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accountingPayable/outgoingPayment/glAccountsAndPaymentMethod`);
  }
  setPaymentStatus(paymentId: string, statusId: string): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accountingPayable/outgoingPayment/setPaymentStatus?paymentId=${paymentId}&statusId=${statusId}`, {});
  }
  setPaymentStatusToVoid(paymentId: string): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accountingPayable/outgoingPayment/statusToVoid?paymentId=${paymentId}`, {});
  }
  getIncomingPayment(pagination: { pageNo: number, pageSize: number }, formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/payments/search?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, formData);
  }
  getIncomingPaymentType(): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/payments/paymentType`);
  }
  createIncomingPayment(formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/accounting/payments`, formData);
  }
  getIncomingMethodType(organizationPartyId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/accounting/payments/paymentMethodType?organizationPartyId=${organizationPartyId}`);
  }
}
