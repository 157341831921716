import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-message-detail-page',
  templateUrl: './message-detail-page.component.html',
  styleUrls: ['./message-detail-page.component.css']
})
export class MessageDetailPageComponent implements OnInit {
  activeCategory=3;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebuttonNew2') closebuttonNew2;
  @ViewChild('closebutton3New') closebutton3New;


  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  addThreadForm: FormGroup;
  forumGroupId: any;
  threadForm: FormGroup;
  fromDateCate: string;
  throughDateCate: string;
  messageListForm: FormGroup;
  updatemessageListForm: FormGroup;
  show: boolean;
  updateForumMessagePat: any;
  updateContentRolesPat: any;
  findForumMessages: any;
  updateForumThreadMessagePat: any;
  forumId: any;
  ownerContentId: any;
  caContentId: any;
  caContentAssocTypeId: any;
  throughDateCatePatch: string;
  fromDateCatePatch: string;
  findForumMessagess: any;
  forumIdNew: any;
  forumGroupIdNew: any;
  contentName: any;
  drDataResourceTypeId: any;
  findForumMessagesAll: any;
  updateMessageThreadForm: FormGroup;

  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly toastr: ToastrService,
    private _location: Location,

  )
  {
    this.messageListForm=this._FormBuilder.group({
      messageTitle:"",
      text:"",
    })

    this.updateMessageThreadForm=this._FormBuilder.group({
      msessageTitle:"",
      messageText:""
    })
    this.updatemessageListForm=this._FormBuilder.group({
      msessageTitle:"",
      messageText:""
    })

    this.addThreadForm=this._FormBuilder.group({
      threaddescription:"",
      text:"",
    })
   }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.forumGroupId = params["dataResourceId"];
      this.forumId = params["contentId"];
      this.forumIdNew = params["forumId"];
      this.forumGroupIdNew = params["forumGroupId"];
      this.ownerContentId = params["ownerContentId"];
      this.caContentId = params["caContentId"];
      this.contentName = params["contentName"];
      this.drDataResourceTypeId = params["drDataResourceTypeId"];

      this.caContentAssocTypeId = params["caContentAssocTypeId"];
    
    });

    this.FindForumMessagesAll();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }

  FindForumMessages() {
    this.spinner.show();
    this.accountsService.FindForumMessages(this.forumGroupIdNew,this.forumIdNew).subscribe(res => {
      this.findForumMessagesAll = res.data;
      this.spinner.hide();
    })
  }

  FindForumMessagesAll() {
    this.spinner.show();
    this.accountsService.FindForumMessagesAll().subscribe(res => {
      this.findForumMessages = res.data.FindForumMessages;
      this.spinner.hide();
      
    })
  }
 
  createForumMessage(): void {
    this.spinner.show();
    const requestData ={
      "addButton": "Y",
      "contentAssocTypeId": this.caContentAssocTypeId,
      "contentIdFrom": this.forumIdNew,
      "contentName":  this.contentName,
      "contentTypeId": "DOCUMENT",
      "dataResourceTypeId": this.drDataResourceTypeId?this.drDataResourceTypeId:'',
      "description": this.messageListForm.value.messageTitle,
      "forumId": this.forumIdNew,
      "pubPtContentId": this.forumIdNew,
      "textData": this.messageListForm.value.text,
      "threadContentId": this.forumIdNew,
      "threadView": "",
      "view_INDEX": ""
     }
     console.log("value",this.messageListForm.value);
     console.log("value1",this.messageListForm);

     this.accountsService.createForumMessage(requestData).subscribe((res: any) => { 
      console.log("res",res);
      if (res.success) {
        this.toastr.success("Create Successfully");
        console.log("res",res);
        this.spinner.hide();
        this.FindForumMessages();
        this.messageListForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  updateForumMessage(): void {
    this.spinner.show();
    const requestData = {
      "addButton": "Y",
      "contentAssocTypeId": this.caContentAssocTypeId,
      "contentIdFrom": this.forumIdNew,
      "contentName":  this.contentName,
      "contentTypeId": "DOCUMENT",
      "dataResourceTypeId": this.drDataResourceTypeId?this.drDataResourceTypeId:'',
      "description": this.messageListForm.value.messageTitle,
      "forumId": this.forumIdNew,
      "pubPtContentId": this.forumIdNew,
      "textData": this.messageListForm.value.text,
      "threadContentId": this.forumIdNew,
      "threadView": "",
      "view_INDEX": ""
     
}
    this.accountsService.updateForumMessage(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.FindForumMessages();
        this.closebuttonNew2.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  
  updateForumMessagePatch(product) {
    this.updateForumMessagePat=product;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.updatemessageListForm;
        formValue.patchValue({
            msessageTitle:this.updateForumMessagePat.dataNodeContent.description,
            messageText:this.updateForumMessagePat.textData.textData
          })
      }, 2000);
      this.spinner.hide();
  }

  
  updateForumThreadMessage(): void {
    this.spinner.show();
    const requestData = {
      "caContentAssocTypeId": "RESPONSE",
      "caContentIdTo": this.updateForumThreadMessagePat.dataNodeContent.contentId,
      "contentId":  this.updateForumThreadMessagePat.dataNodeContent.contentId,
      "contentName": this.contentName,
      "contentTypeId": "DOCUMENT",
      "dataResourceTypeId": "",
      "description": this.updateMessageThreadForm.value.msessageTitle,
      "forumGroupId": this.forumGroupIdNew,
      "forumId": this.forumIdNew,
      "fromDate":this.throughDateCatePatch,
      "ownerContentId": this.forumIdNew,
      "textData": this.updateMessageThreadForm.value.messageText,
      "thruDate":'',
      
}
    this.accountsService.updateForumThreadMessage(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.FindForumMessages();
        this.closebutton3New.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  updateForumThreadMessagePatch(product) {
    this.show=false;
    const throughDateCatePatch=product.dataContentSecond.fromDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
    
    this.updateForumThreadMessagePat=product;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.updateMessageThreadForm;
        formValue.patchValue({
            msessageTitle:this.updateForumThreadMessagePat.dataNodeContent.description,
            messageText:this.updateForumThreadMessagePat.textData.textData
          })
      }, 2000);
      this.spinner.hide();
  }

openForumGroup()
  {
    this._Router.navigate(['/content/forum']);

  }
  openForums()
  {
    this._Router.navigate(['/content/forum/forums'],{ queryParams: { forumId: this.forumGroupId } });

  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

changeactiveCategory(tab: number)
{
  this.activeCategory = tab;

}

}
