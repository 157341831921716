import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LeadGroupModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        annualRevenue:(data && data.annualRevenue) ? data.annualRevenue : null,
        comments:(data && data.comments) ? data.comments : null,
        currencyId:(data && data.currencyId) ? data.currencyId : null,
        description:(data && data.description) ? data.description : null,
        externalId:(data && data.externalId) ? data.externalId : null,
      groupLocalName:(data && data.groupLocalName) ? data.groupLocalName : null,
      groupName:(data && data.groupName) ? data.groupName : null,
      logoImageUrl:(data && data.logoImageUrl) ? data.logoImageUrl : null,  
      noOfEmployees:(data && data.noOfEmployees) ? data.noOfEmployees : null,
      officeSiteName:(data && data.officeSiteName) ? data.officeSiteName : null,
      statusId:(data && data.statusId) ? data.statusId : null,
      tickerSymbol:(data && data.tickerSymbol) ? data.tickerSymbol : null,

       
     
    });
   
   
    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   return ({
    annualRevenue: new FormControl(data.annualRevenue,[Validators.required]),
    comments: new FormControl(data.comments),
    currencyId: new FormControl(data.currencyId),
    description: new FormControl(data.description,[Validators.required]),
    externalId: new FormControl(data.externalId),
    groupLocalName: new FormControl(data.groupLocalName),
    groupName: new FormControl(data.groupName),
    logoImageUrl: new FormControl(data.logoImageUrl),
    noOfEmployees: new FormControl(data.noOfEmployees,[Validators.required]),
    officeSiteName: new FormControl(data.officeSiteName),
    statusId: new FormControl(data.statusId),
    tickerSymbol: new FormControl(data.validThrough),
    
    
   
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
