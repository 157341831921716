<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div style="margin: 4%;font-size: 11px;">
          <ng-wizard [config]="config">

            <ng-wizard-step [title]="'Step 1'" [description]="'Sales Order'"> 
            </ng-wizard-step>

            <ng-wizard-step [title]="'Step 2'" [description]="' Enter Order Currency, Agreements, and Ship Dates '">  
            </ng-wizard-step>
           
          <ng-wizard-step [title]="'Step 3'" [description]="'Create Sales Order'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 4'" [description]="'Shipping'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 5'" [description]="'Option'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 6'" [description]="'Order Term'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 7'" [description]="'Payment'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 8'" [description]="'Parties'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 9'" [description]="'Review Order'">     
            </ng-wizard-step>
          
         
          </ng-wizard>
        </div>
        <div>
          <div class="container-fluid">
            <div class="container-fluid">
              <ul class="tabbing-section  tabbing-accordians" style="padding-left: 9px;">
                <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                   Create Sales Order </a></li>
                <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                  Order Items </a></li>
                <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                    Promotion/Coupon codes</a></li>
                <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                    Special Offers </a></li>
              
              </ul>
            </div>
            <div class="row">
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div *ngIf="activeCategory==1">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Create Sales Order
                          </h4>
                          <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                                href="#seo-collapse">
                              </span>
                              <span style="margin-left: 29%!important" class="edit-buttons" routerLinkActive="active"
                                (click)="clearOrder()">Clear Order</span>
                              <span class="edit-buttons">Remove Selected</span>
                              <span class="edit-buttons" (click)="recalculateOrder()">Recalculate
                                Order</span>
                              <span class="edit-buttons" routerLinkActive="active"
                                (click)="finalizeOrder()">Finalize Order</span>
                              <span class="edit-buttons" (click)="finalizeOrderWithDefault()">Finalize Order With
                                Default Options</span>
                        </div>
                        <div class="panel-body">
                          <form class="w-100">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                              <div class="col-lg-4 col-12">
                                <div class="form-group">
                                  <label class="common-labels">Product ID
                                  </label>
                                  <p-dropdown filter="true" [options]="productListArray" appendTo="body"
                                    [(ngModel)]="soFormData.productId" [ngModelOptions]="{standalone: true}"
                                    optionlabel="label" placeholder="Select Product">
                                  </p-dropdown>
                                </div>
                              </div>
                              <div class="col-lg-4 col-12">
                                <div class="form-group">
                                  <label class="common-labels">Quantity </label>
                                  <input type="text" class="form-control" placeholder="Enter Quantity"
                                    [(ngModel)]="soFormData.quantity" [ngModelOptions]="{standalone: true}">
                                </div>
                              </div>
                              <div class="col-lg-4 col-12">
                                <div class="form-group">
                                  <label for="exampleInputEmail1"> Desired Delivery Date </label>
                                  <div class="">
                                    <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                      hourFormat="24" dateFormat="dd.mm.yy"
                                      [(ngModel)]="soFormData.itemDesiredDeliveryDate"
                                      [ngModelOptions]="{standalone: true}"></p-calendar>
                                    <div class="d-flex align-items-center" style="margin-top:10px;">
                                      <p-checkbox name="selectContacts" styleClass="custom-checkbox"
                                        binary="true"></p-checkbox>
                                      <p class="mb-0 d-inline-block" style="color: #65787e;font-size: 13px;">
                                        Use as default desired delivery date for next entry
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-12">
                                <div class="form-group">
                                  <label for="exampleInputEmail1"> Ship After Date </label>
                                  <div class="">
                                    <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                      hourFormat="24" dateFormat="dd.mm.yy" [(ngModel)]="soFormData.shipAfterDate"
                                      [ngModelOptions]="{standalone: true}"></p-calendar>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-12">
                                <div class="form-group">
                                  <label for="exampleInputEmail1"> Ship Before Date </label>
                                  <div class="">
                                    <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                      hourFormat="24" dateFormat="dd.mm.yy"
                                      [(ngModel)]="soFormData.shipBeforeDate"
                                      [ngModelOptions]="{standalone: true}"></p-calendar>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-12">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">Reserve After Date</label>
                                  <div class="">
                                    <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                      hourFormat="24" dateFormat="dd.mm.yy"
                                      [(ngModel)]="soFormData.reserveAfterDate"
                                      [ngModelOptions]="{standalone: true}"></p-calendar>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-12">
                                <div class="form-group">
                                  <label for="exampleInputEmail1">Comments</label>
                                  <div class="">
                                    <textarea maxlength="1500" [rows]="2" [cols]="30" autoResize="autoResize"
                                      [(ngModel)]="soFormData.itemComment"
                                      [ngModelOptions]="{standalone: true}"></textarea>
                                    <div class="d-flex align-items-center" style="margin-top:10px;">
                                      <p-checkbox name="selectContacts" styleClass="custom-checkbox"
                                        binary="true"></p-checkbox>
                                      <p class="mb-0 d-inline-block" style="color: #65787e;font-size: 13px;">
                                        Use as default comment for next entry
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-12 col-12 main-submit-button"><button type="submit"
                                (click)="addToOrder()" class="btn btn-secondary submit-btn">Add To Order</button>
                            </div>
                          </form>
                        </div>
                      </div>

                      <div *ngIf="activeCategory==2">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Order Items
                          </h4>
                        
                        </div>
                        <div class="panel-body">
                         
                              <div class="all-users-infomation font-13 font-medium own-user-select">
                                <p class="col-lg-12 mb-0 col-12" style="color:#65787e;font-size:14px;">No order
                                  items to display : <span
                                    class="text-parimary">{{orderItems.length ? orderItems.length :0}}</span></p>
                              </div>
                              <ng-template ngFor let-order [ngForOf]="orderItems" let-index="index">
                                <div class="row">
                                  <div class="col-md-9 d-flex align-items-center">
                                    <div class=" justify-content-start submit-btn-sales main-submit-button"
                                      style="padding-left:15px;margin-top:-8px">
                                      <button type="submit"
                                        class="btn btn-secondary submit-btn">{{order.productId}}</button>
                                    </div>
                                    <div class="col-lg-3 col-12">
                                      <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Name"
                                          [value]="order.description">
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Quantity"
                                          [value]="order.quantity">
                                      </div>
                                    </div>
                                    <div class="col-lg-3 col-12">
                                      <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Enter Price"
                                          [value]="order.unitPrice">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-3 d-flex justify-content-end">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                      <p class="col-lg-12 mb-0 col-12" style="color:#65787e;font-size:12px;">
                                        US${{order.orderAmount}} US${{order.itemTotal}}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                <div class="row">
                                  <div class="col-md-12 d-flex align-items-center">
                                    <div class=" justify-content-start submit-btn-sales main-submit-button"
                                      style="padding-left:15px;margin-top:-8px">
                                      <button type="submit" class="btn btn-secondary  submit-btn">Inventory</button>
                                      <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <p class="col-lg-12 mb-0 col-12" style="color:#65787e;font-size:14px;">ATP =
                                          {{order.inventorySummary.availableToPromiseMap[order.productId]}}, QOH =
                                          {{order.inventorySummary.quantityOnHandMap[order.productId]}}</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-3 d-flex">
                                    <div class="form-group">
                                      <div class="flex-column">
                                        <label for="afterDate">Ship After Date</label>
                                        <p-calendar></p-calendar>
                                      </div>
                                    </div>
                                    <div class="form-group">
                                      <div class="flex-column">
                                        <label for="beforeDate">Ship Before Date</label>
                                        <p-calendar></p-calendar>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-9 d-flex align-items-center">
                                    <div class=" justify-content-start submit-btn-sales main-submit-button"
                                      style="padding-left:15px;margin-top:-8px">
                                      <button type="submit" class="btn btn-secondary  submit-btn">Quick
                                        Loop</button>
                                      <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <p class="col-lg-12 mb-0 col-12" style="color:#65787e;font-size:14px;">
                                          Associated Products</p>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-3 d-flex">

                                  </div>
                                </div>
                                <ng-template [ngIf]="order.orderAssociatedProducts" ngFor let-productAssociate
                                  [ngForOf]="order.orderAssociatedProducts" let-j="index">
                                  <div class="row">
                                    <div class="col-md-9 d-flex align-items-center">
                                      <div class=" justify-content-start submit-btn-sales main-submit-button"
                                        style="padding-left:15px;margin-top:-8px">
                                        <button type="submit"
                                          class="btn btn-secondary submit-btn">{{productAssociate.productId}}</button>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                          <p class="col-lg-12 mb-0 col-12" style="color:#65787e;font-size:14px;">
                                            {{productAssociate.description}}(Also Bought)</p>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-3 d-flex">

                                    </div>
                                  </div>
                                </ng-template>

                           
                              </ng-template>


                              <div class="row">
                                <div class="col-md-9 d-flex align-items-center">
                                </div>
                                <div class="col-md-3 d-flex justify-content-end">
                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <p class="col-lg-12  col-12"
                                      style="text-align:right;color:#65787e;margin-bottom:5px;font-size:12px;">Sub
                                      Total:
                                      US${{totalAmount?.orderSubTotal}}</p>
                                    <p class="col-lg-12 col-12"
                                      style="text-align:right;color:#65787e;margin-bottom:5px;font-size:12px;">
                                      Adjustment - Promotion:
                                      -US${{totalAmount?.orderAdjustment.adjustmentAmount}}</p>
                                    <p class="col-lg-12 col-12"
                                      style="text-align:right;color:#65787e;margin-bottom:5px;font-size:12px;">Cart
                                      Total:
                                      US${{totalAmount?.cartTotal}}</p>
                                  </div>
                                </div>
                              </div>
                          
                        </div>
                      </div>

                      <div *ngIf="activeCategory==3">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Promotion/Coupan Codes 
                          </h4>
                        
                        </div>
                        <div class="panel-body">
                        
                              <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-4 col-12">
                                  <div class="form-group">
                                    <p-dropdown filter="true" [options]="promotionValue" [(ngModel)]="promoId"
                                      [ngModelOptions]="{standAlone:true}" placeholder="Enter Promo"></p-dropdown>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-12 col-12 main-submit-button">
                                <button type="submit" class="btn btn-secondary submit-btn"
                                  (click)="addPromotion()">Add Code</button>
                              </div>
                          
                        </div>
                      </div>

                      <div *ngIf="activeCategory==4">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Special Offers
                          </h4>
                        
                        </div>
                        <div class="panel-body">
                         
                              <div class="all-users-infomation font-13 font-medium own-user-select">
                                <ng-template ngFor let-promo [ngForOf]="promotions" let-index="index">
                                  <div class="col-lg-12 col-12 details-offer">
                                    <a class="detail-btn cursor-pointer" routerLink="/order/sales-product"
                                      [queryParams]="{promoId:promo.productPromoId}">Details</a>
                                    <p>{{promo.promoText}}</p>
                                  </div>
                                </ng-template>

                              </div>

                          
                        </div>
                      </div>
                    
                    </div>

                  </div>

                  <!-- order-items -->
                
                  <!--  order-items -->

                  <!--Promotion/Coupon Codes-->
               
                  <!--  Promotion/Coupon Codes -->

                  <!--Special Offers-->
                 
                  <!--  Special Offers -->
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner> 