import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class TimeSheetService {

  constructor(readonly http: HttpClient) { }
  getApplicationTimesheetList(job,jobList) :Observable<any>{
    const getApplicationTimesheetList = URLS.getApplicationTimesheetList + "?pageSize=" + job.pageSize +"&pageNo=" + job.pageNo;
    return this.http.post(getApplicationTimesheetList,jobList);
  }
  getUserLogin(): Observable<any> {
    const getUserLoginList = URLS.getUserLogin;
    return this.http.get(getUserLoginList);
  }
  getTimeSheetList(getList,data): Observable<any> {
    const getTimesheet = URLS.getTimeSheetList + "?pageNo=" + getList.pageNo + "&pageSize=" + getList.pageSize;
    return this.http.post(getTimesheet,data);
  }
  createTimeSheet(formData): Observable<any> {
    const createTimeSheet = URLS.createTimeSheet;
    return this.http.post(createTimeSheet, formData)
  }
  postRole(formData): Observable<any> {
    const postRole = URLS.postRole;
    return this.http.post(postRole, formData)
  }
  getStatusList(): Observable<any> {
    const getStatusList = URLS.getTimeSheetStatusList;
    return this.http.get(getStatusList);
  }
  deleteTimeSheetNew(timesheetId, roleTypeId, partyId): Observable<any> {
    const deleteTimeSheet = URLS.deleteTimesheetRole.replace(":timesheetId", timesheetId).replace(":roleTypeId", roleTypeId).replace(":partyId", partyId)
    return this.http.post(deleteTimeSheet,"");
  }
  deleteTimeSheet(timesheetId, roleTypeId, partyId): Observable<any> {
    const deleteTimeSheet = URLS.deleteTimesheetRole.replace(":timesheetId", timesheetId).replace(":roleTypeId", roleTypeId).replace(":partyId", partyId)
    return this.http.delete(deleteTimeSheet);
  }
  deleteRoleType(timesheetId, roleTypeId, partyId): Observable<any> {
    const deleteRole = URLS.deleteRole.replace("timesheetId", timesheetId).replace("roleTypeId", roleTypeId).replace("partyId", partyId)
    return this.http.delete(deleteRole);
  }
  getPartyId():Observable<any> {
    const partyId = URLS.personsAndPartyGroups;
    return this.http.get(partyId);
  }
  getRoleList():Observable<any> {
    const getRoleList = URLS.getRoleList;
    return this.http.get(getRoleList);
  }
  updateTimesheet(formData,timeSheetId) {
    const updateTimesheet = URLS.updateTimeSheet.replace(":timesheetId",timeSheetId)
  return this.http.put(updateTimesheet,formData)
  }
  getRole(timeSheetId):Observable<any> {
    const getRole = URLS.getRole.replace("timesheetId",timeSheetId)
    return this.http.get(getRole)
    }
   
    



}
