import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { EmployeeService } from '../../employees/employess.service';
import { DetailedEmployeeSummary } from '../detailed-employee-summary.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-employee-fax-information',
    templateUrl: './employee-fax-information.component.html'
})
export class EmployeeFaxInformationComponent implements OnInit {

    contactForm: FormGroup;
    editMode: boolean;
    partyId: string;
    contact: any;
    array = [
        {
            label: 'Y',
            value: 'Y'
        },
        {
            label: 'N',
            value: 'N'
        }
    ];
    contactPurpose: any[];
    contactMechId: string;
    constructor(
        readonly _DetailedEmployeeSummary: DetailedEmployeeSummary,
        readonly _CRMAccountService: CRMAccountService,
        readonly _FormBuilder: FormBuilder,
        readonly  _Router: Router,
        private _location: Location,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _ToastrService: ToastrService,
        readonly _EmployeeService: EmployeeService,
        readonly spinner:NgxSpinnerService,
    ) {
        this.partyId = '';
        this.editMode = false;
        this.contactForm = this._FormBuilder.group({
            allowSolicitation: [''],
            areaCode: [''],
            askForName: [''],
            contactMechId: [''],
            contactMechPurposeTypeId: ['', [Validators.required]],
            contactMechTypeId: [''],
            contactNumber: [''],
            countryCode: [''],
            extension: [''],
            partyId: [''],
            preContactMechTypeId: ['TELECOM_NUMBER']
        });
        this.contactPurpose = [];
        this.contactMechId = '';
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.partyId = this._ActivatedRoute.snapshot.queryParams.partyId;
        this.contactMechId = this._ActivatedRoute.snapshot.queryParams.contactMechId;
        if (this.contactMechId) {
            this.editMode = true;
            this.spinner.show();


            this._EmployeeService.getEmployeeId(this.partyId)
                .subscribe(data => {
                    
                    if (data.success) {
                        this.spinner.hide();
                        data.data.contactInformation.filter(value => {
                            if (value.contactMech.contactMechId.trim() === this.contactMechId.trim()) {
                                this.contact = {
                                    allowSolicitation: value.partyContactMech.allowSolicitation ? value.partyContactMech.allowSolicitation : 'N',
                                    areaCode: value.telecomNumber.areaCode,
                                    askForName: [''],
                                    contactMechId: value.contactMech.contactMechId.trim(),
                                    contactMechPurposeTypeId: value.partyContactMechPurposes[0].contactMechPurposeTypeId,
                                    contactMechTypeId: value.contactMech.contactMechTypeId,
                                    contactNumber: value.telecomNumber.contactNumber,
                                    countryCode: value.telecomNumber.countryCode,
                                    extension: value.partyContactMech.extension,
                                    partyId: this.partyId,
                                    preContactMechTypeId: 'TELECOM_NUMBER'
                                };
                                this.contactForm.patchValue(this.contact);
                             
                                this.contactForm.controls.contactMechPurposeTypeId.disable();
                                this.contactForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
                            }
                        });
                    }
                });
            this.getContactDetails();
           
        }
        this.getContactPurpose();
    }
    getContactDetails(): void {
        this.spinner.show();
        this._DetailedEmployeeSummary.getTelecomById(this.contactMechId, this.partyId)
            .then(data => {
                this.spinner.hide();
                this.contact = {
                    areaCode: data.data.areaCode,
                    askForName: data.data.askForName,
                    countryCode: data.data.countryCode,
                    contactNumber: data.data.contactNumber,
                    contactMechId: data.data.contactMechId,
                    allowSolicitation: data.data.allowSolicitation,
                    contactMechPurposeTypeId: data.data.contactMechPurposeTypeId,
                    contactMechTypeId: data.data.contactMechTypeId,
                    extension: data.data.extension,
                    partyId: data.data.partyId,
                    preContactMechTypeId: 'TELECOM_NUMBER'
                };
                this.contactForm.patchValue(this.contact);
                this.contactForm.controls.contactMechPurposeTypeId.disable();
                this.contactForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
            });
           
    }
    getContactPurpose(): void {
        this.spinner.show();
        this._CRMAccountService.getContactPurposes(this.partyId, 'TELECOM_NUMBER')
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.contactPurpose = data.data.purposeTypes.map(value => {
                        return {
                            label: value.description,
                            value: value.contactMechPurposeTypeId
                        };
                    });
                }
            });
           
    }
    update(): void {
        this.spinner.show();
        if (this.contactForm.valid) {
            this._DetailedEmployeeSummary.updateEmployeeTelecomNumber({
                ...this.contact, ...this.contactForm.value
            })
                .then(data => {
                    if (data.success) {
                        this.spinner.hide();
                        this._ToastrService.success('Updated');
        this._location.back();

                      //  this.cancelSubmit();
                    }
                });
        }
    }
    submit(): void {
        this.spinner.show();
        if (this.contactForm.valid) {
            this._DetailedEmployeeSummary.createEmployeeTelecomNumber({
                ...this.contactForm.value, ...{
                    partyId: this.partyId
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                     //   this.cancelSubmit();
                        this.spinner.hide();
        this._location.back();

                    }
                });
        }
        this.spinner.hide();
    }
    cancelSubmit(): void {
        this.contactForm.reset();
        this._Router.navigate(['/hr/employees/employee-details'], { queryParams: { employeeDetailId: this.partyId } });
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
} 