import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
@Component({
  selector: 'app-gl-payment-method-summary',
  templateUrl: './gl-payment-method-summary.component.html',
  styleUrls: ['./gl-payment-method-summary.component.css']
})
export class GlPaymentMethodSummaryComponent implements OnInit {
  show: boolean;
  @ViewChild('closebutton') closebutton;
  paymentMethodTypeId: any;
  description: any;
  defaultGlAccountId: any;
  updatePayment: FormGroup;
  parentGlAccount: any;
  activeCategory = 2;
  parentGlAccountArray: any[]=[];
  constructor(readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
   ) {
    this.updatePayment = this._FormBuilder.group({
      defaultGlAccountId: [''],
      description: [''],
      paymentMethodTypeId: [''],



    }); }

  ngOnInit(): void { this._ActivatedRoute.queryParams.subscribe(params => {
    this.paymentMethodTypeId = params["paymentMethodTypeId"];
    this.description = params["description"];
    this.defaultGlAccountId = params["defaultGlAccountId"];

  });
  this.getGlAccountInitialData();

}
edit(){
  const formValue = this.updatePayment;
  formValue.patchValue({
    defaultGlAccountId: this.defaultGlAccountId,
    description: this.description,
    paymentMethodTypeId: this.paymentMethodTypeId



  })
}
cancelSubmit() {
  this.router.navigate(["financial/gl-payment-method-type"])
}
getGlAccountInitialData() {
  this.spinner.show();
  this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

    this.parentGlAccount = res.data.parentGlAccount;
    this.spinner.hide();
    for (const value of this.parentGlAccount) {
      this.parentGlAccountArray.push({
        label: value.accountName,
        value: value.glAccountId
      })
    }


  })
 
}
onUpdate() {
  this.spinner.show();
  const requestData = {
    "defaultGlAccountId":this.updatePayment.value.defaultGlAccountId,
    "description":this.updatePayment.value.description,
    "paymentMethodTypeId": this.updatePayment.value.paymentMethodTypeId
   
  }

  this.accountsService.updatePaymentMethodTypeGlSetting(requestData).subscribe((res: any) => {
  
    if (res.success) {
      this.toastr.success("Updated Successfully");
      this.spinner.hide();
      this.closebutton.nativeElement.click();
      this.router.navigate(["financial/gl-payment-method-type"]);
    }
  }, (err) => {
    this.spinner.hide();
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + ' ' + value.fieldError);
    }
  });

}

}
