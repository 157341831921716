import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router ,RouterModule } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AccountLeadSourceService } from 'src/app/crm/accounts/detailed-accounts-summary/detailed-account-service';
import { detailedContactsService } from 'src/app/crm/contacts/detailed-contacts-summary/detailed-contacts-summary.service';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2'
import { TrainingApprovalService } from '../training/training-approvals/training-approval.service';
import { EmployeeLeaveService } from '../leave/employee-leave/employee-leave.service';
import { AddSkillService } from '../add-skills/add-skill.service';
import { SkillService } from '../skills/skill.service';
import { EmployeePositionService } from '../employee-position/employee-position.service';
import { EditAccountServices } from 'src/app/editContactGroup/edit-contact-group/edit-contact-group.service';
import { CreateAccountFormService } from 'src/app/crm/create-account/create-account-service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { CreateAccountService } from 'src/app/crm/create-account/create-account.service';
import { AddSkillsService } from '../add-skills/add-skill-service';
import { AddLeaveService } from '../leave/add-new-leaves/add-new-leaves-service';
import { EmployeeService } from '../employees/employess.service';
import { AddLeaveEmpService } from '../leave/add-new-leaves/add-new-leaves.service';
import { DetailedEmployeeSummary } from '../detailed-employee-summary/detailed-employee-summary.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
declare var $: any;

import { Location } from '@angular/common';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;
  @ViewChild('closebutton11') closebutton11;
  @ViewChild('closebutton12') closebutton12;
  @ViewChild('closebutton14') closebutton14;
  @ViewChild('closebutton15') closebutton15;
  activeCategory = 2;
  faEdit = faEdit;
  faTrash = faTrash;
  activeCategoryValue = 2;
  fileData: File;
  accountDetails: any;
  accountName: any;
  Editform: FormGroup;
  leaveForm: FormGroup
  qualification: FormGroup;
  add_skill_form: FormGroup;
  total = 0;
  rowNumber = 0;
  pageSize = 10;
  rows = 50;
  relatedContact: any = [];
  contentList: any[] = []
  contactInformation: any[] = []
  partyId: any;
  accountData: any;
  partyContentTypeId: any;
  partyContentData: any = [];
  partyContentArray: any[] = [];
  isAdditionalDetail: boolean;
  isJobDetail: boolean;
  addr: string;
  ResidenceStatusEnumID: any;
  emailId: any;
  phoneNumber: string;
  ResidenceStatusEnumIDArray: any[]=[];
  paymentMethods: any[];
  employeePosition: any;
  billingAccounts: any[];

  pageNo = 1;

  public leaveSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize
  }
  trainingApprovalList: any;
  employeeLeave: any;
  skillList: any;
  getEmployement: any;
  readonly employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  address1: any;
  address2: any;
  areaCode: any;
  city: any;
  contactNumber: any;
  countryCode: any;
  countryGeoId: any;
  emailAddress: any;
  extension: any;
  groupName: any;
  numEmployees: any;
  officeSiteName: any;
  postalCode: any;
  stateProvinceGeoId: any;
  EditEmployeePositionList:any;
  show = false;
  array: any = [];
  stateData: any;
  createContact: any;
  countryId: any;
  stateArray: any = [];
  // currencyArray: any = [];
  crmArray: any[]= [];
  crmStatus:any;
  accountForm: FormGroup;
  additionalDetailForm: FormGroup;
  jobInfoForm: FormGroup;
  customerType = [{

    label: 'Individual Customer',
    value: 'IndividualCustomer'
  },
  {
    label: 'Business Customer',
    value: 'BusinessCustomer'
  },
  ]
  salutationArray = [
    {
      label: 'Mr.',
      value: 'Mr.'
    },
    {
      label: 'Mrs.',
      value: 'Mrs.'
    },
    {
      label: 'Ms.',
      value: 'Ms.'
    }
  ];
  currencyList: any[];
  statusList: any[];
  id: any;
  editMode = false;
  jobInfo: any;
  additionalInfo: any;
  getSkillLists: any;
  skillArray: any = [];
  personId: any;
  partyIdArray: any = [];
  updateId: any;
  Skillid: any;
  rate: any;
  skill: any;
  year: any;
  leaveTypeId: any;
  leaveId: any = [];
  leaveReason: any;
  leaveReasonArray: any = [];
  leaveTypeIds: any;
  fromDate: any;
  leaves: {};
  isApproval: boolean;
  updateefromdate: string;
  updateetodate: string;
  thruDate: any;
  AppId: any;
  Description: any;
  Reasonid: any;
  partyIdEdit: any;
  leaveStatusReoort: any;
  leaveStatusReoortArray: any[] = [];
  statusId: any;
  add_leave_form: FormGroup
  leave = false;
  eftAccountFormEFT : FormGroup;

  contactForm: FormGroup;
  contact: any;
  arrayA = [
    {
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'N',
      value: 'N'
    }
  ];
  contactPurpose: any[];
  contactMechId: string;

  editModeA: boolean;
  addressForm: FormGroup;
  solicitationArray = [
    {
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'N',
      value: 'N'
    }
  ];
  contactMechTypeId: any[];
  countryArray: any[];
  accountInfo: any;
  fileUploadForm: FormGroup;
  activeCategoryA = 1;
  contactId: any;
  contactArray: any = [];
  params: any;
  activeCategoryB = 1;
  partyIdForCreate: any;
  noteId: any;
  showUpdateBtn = false;
  showCreateBtn = false;
  noteName = '';
  note = '';
  fileUploadFormNote: FormGroup;
  partyIdLeave: any;
  employeeDetail:any;
  giftForm: FormGroup;
  expirationMonth = [
    {
      label: '1 (Jan)',
      value: '1'
    },
    {
      label: '2 (Feb)',
      value: '2'
    },
    {
      label: '3 (Mar)',
      value: '3'
    },
    {
      label: '4 (Apr)',
      value: '4'
    },
    {
      label: '5 (May)',
      value: '5'
    },
    {
      label: '6 (Jun)',
      value: '6'
    },
    {
      label: '7 (Jul)',
      value: '7'
    },
    {
      label: '8 (Aug)',
      value: '8'
    },
    {
      label: '9 (Sep)',
      value: '9'
    },
    {
      label: '10 (Oct)',
      value: '10'
    },
    {
      label: '11 (Nov)',
      value: '11'
    },
    {
      label: '12 (Dec)',
      value: '12'
    }];
  expirationYear = [
    {
      label: '2020',
      value: '2020'
    },
    {
      label: '2021',
      value: '2021'
    },
    {
      label: '2022',
      value: '2022'
    },
    {
      label: '2023',
      value: '2023'
    },

    {
      label: '2024',
      value: '2024'
    },
    {
      label: '2025',
      value: '2025'
    },
    {
      label: '2026',
      value: '2026'
    },
    {
      label: '2027',
      value: '2027'
    },
    {
      label: '2028',
      value: '2028'
    },
    {
      label: '2029',
      value: '2029'
    },
    {
      label: '2030',
      value: '2030'
    }];
  giftCard: any;
  paymentMethodId: string;

  eftAccountForm: FormGroup;
  isEftAccount: boolean;
  eftAccount: any;
  accountTypes = [
    {
      label: 'Checking',
      value: 'Checking'
    },
    {
      label: 'Savings',
      value: 'Savings'
    }];

  creditCardForm: FormGroup;
  cardTypes: any[];
  prefixArray = [
    {
      label: 'Mr.',
      value: 'Mr.'
    },
    {
      label: 'Mrs.',
      value: 'Mrs.'
    },
    {
      label: 'Ms.',
      value: 'Ms.'
    },
    {
      label: 'Dr.',
      value: 'Dr.'
    }];
  suffixArray = [
    {
      label: 'Jr.',
      value: 'Jr.'
    },
    {
      label: 'Sr.',
      value: 'Sr.'
    },
    {
      label: 'I',
      value: 'I'
    },
    {
      label: 'II',
      value: 'II'
    },
    {
      label: 'III',
      value: 'III'
    },
    {
      label: 'IV',
      value: 'IV'
    },
    {
      label: 'V',
      value: 'V'
    },
  ];


  paymentMethodTypes: any[];
  paymentMethodTypeId: string;
  paymentMethodValue: any;
  creditCardValue: any;
  accountDataCredit: any;
  accountDataEFT: any;
  accountDataCheck: any;

  currencyUomIds: any[];
  billingAccountForm: FormGroup;
  partyIds: any[];
  billingAccount: any;
  availableBalance: string;
  actualfromdate: string;
  createQualification:FormGroup;
  PaymentID: any;
  PaymentIDArray: any[] = [];
  VerifStatusIDArray: any[] = [];
  VerifStatusID: any;
  PartyQualTypeIdArray: any[]=[];
  PartyQualTypeId: any;
  getEmployementList: any;
  fromActualdate: any;
  employeeQualList: any;
  summaryPage:any;
  showSkill: boolean;
  updateProfile: FormGroup;
  showLeave: boolean; EmploymentStatusEnumId: any;
  EmploymentStatusEnumIdArray: any[]=[];
  yesNOArray: { label: string; value: string; }[];
  martialStatusArray: { label: string; value: string; }[];
  EditEmployeeLeavesList:any;
  genderArray = [
    {
      label: 'Male.',
      value: 'M.'
    },
    {
      label: 'Female.',
      value: 'F.'
    },
  ];
  updateAccountForm:FormGroup;
  editPartyGroupList:any;
  notePro:any;
  contactNotesList:any;
  showUpdateNoteBtn:boolean;
  EditListRelatedAccountList:any;
  EditEmployeeSkillList:any;
  EditPartyQualList:any;
  employmentsList:any;
  constructor(readonly _FormBuilder: FormBuilder,
    readonly accountsService: AccountsService,
    readonly skillService: SkillService,
    public addSkillService: AddSkillService,
    readonly spinner: NgxSpinnerService,
    public accountLeadSourceService: AccountLeadSourceService,
    readonly toastr: ToastrService,
    readonly employeeLeaveService: EmployeeLeaveService,
    readonly activatedRoute: ActivatedRoute,
    readonly _detailedContactsService: detailedContactsService,
    readonly datePipe: DatePipe,
    readonly trainingApprovalService: TrainingApprovalService,
    readonly _CRMAccountService: CRMAccountService,
    readonly employeePositionService: EmployeePositionService,
    readonly router: Router,
    readonly createAccountService: CreateAccountService,
    readonly _GlobalResourceService: GlobalResourceService,
    public createAccountFormService: CreateAccountFormService,
    readonly _EditAccountServices: EditAccountServices,
    public addSkillsService: AddSkillsService, 
     readonly employeeService:EmployeeService,
    public addLeaveService: AddLeaveService,
    readonly addLeaveEmpService: AddLeaveEmpService,
    readonly _DetailedEmployeeSummary: DetailedEmployeeSummary,
    readonly _Router: Router,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,
    readonly formBuilder: FormBuilder,
    
        private _location: Location,
    readonly myContactsService: MyContactsService,
  ) {
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.genderArray = [
      {
        label: 'Male',
        value: 'M'
      },
      {
        label: 'Female',
        value: 'F'
      },
    ];
    this.martialStatusArray = [

      {
        label: 'Single []',
        value: 'SINGLE'
      },
      {
        label: 'Married []',
        value: 'MARRIED'
      },
    ];
    this.createQualification = this._FormBuilder.group({
      QualificationDesc: [''],
      Title: [''],
      StatusID: [''],
      VerifStatusID: [''],
      ThroughDate: [''],
      PartyID: [''],
      fromDate: [''],
      PartyQualTypeID: [''],
      Comments: ['']

    });
    this.updateProfile = this._FormBuilder.group({
      partyID: [''],
      salutation: [''],
      firstName: [''],
      middleName: [''],
      lastName: [''],
      personalTitle: [''],
      suffix: [''],
      nickname: [''],
      firstNameLocal: [''],
      middleNameLocal: [''],
      lastNameLocal: [''],
      otherLocal: [''],
      memberID: [''],
      gender: [''],
      birthDate: [''],
      decreasedDate: [''],
      height: [''],
      weight: [''],
      mothersMaidenName: [''],
      oldMaritialStatus: [''],
      maritialStatusEnumID: [''],
      socialSecurityNumber: [''],
      passportNumber: [''],
      passportExpiryDate: [''],
      totalExperience: [''],
      comments: [''],
      employeeStatusEnumID: [''],
      residenceStatusEnumID: [''],
      occupation: [''],
      yearswithEmployer: [''],
      monthsWithEmployer: [''],
      existingCustomer: [''],
      cardID: [''],
      usCitizen: [''],
      ethnicity: [''],
      accountNo: [''],
      billingRateLevel: [''],
      preferredCurrencyUomID: [''],
      description: [''],
      externalID: [''],
      statusID: [''],
    });
    this.editModeA = false;
        this.partyId = '';

        this.billingAccountForm = this._FormBuilder.group({
            accountCurrencyUomId: [''],
            accountLimit: [''],
            contactMechId: [''],
            description: [''],
            externalAccountId: [''],
            fromDate: [''],
            partyId: ['', [Validators.required]],
            thruDate: ['']
        });
        this.currencyUomIds = [];
        this.partyIds = [];
        this.paymentMethodId = '';
        this.availableBalance = '0';

    this.partyId = '';
    this.eftAccountForm = this._FormBuilder.group({
      accountNumber: ['', [Validators.required]],
      accountType: ['', [Validators.required]],
      bankName: ['', [Validators.required]],
      companyNameOnAccount: [''],
      contactMechId: [''],
      description: [''],
      paymentMethodTypeId: ['', [Validators.required]],
      nameOnAccount: ['', [Validators.required]],
      routingNumber: ['', [Validators.required]]
    });
    this.editMode = false;
    this.contactMechId = '';
    this.paymentMethodTypes = [];
    this.paymentMethodId = '';
    this.paymentMethodTypeId = '';


    this.partyId = '';
    this.eftAccountFormEFT = this._FormBuilder.group({
      accountNumber: ['', [Validators.required]],
      accountType: ['', [Validators.required]],
      bankName: ['', [Validators.required]],
      companyNameOnAccount: [''],
      contactMechId: [''],
      description: [''],
      nameOnAccount: ['', [Validators.required]],
      routingNumber: ['', [Validators.required]]
    });
    this.editModeA = false;
    this.contactMechId = '';
    this.isEftAccount = false;


    this.editModeA = false;
    this.giftForm = this._FormBuilder.group({
      cardNumber: ['', [Validators.required]],
      description: [''],
      expMonth: ['', Validators.required],
      expYear: ['', Validators.required],
      pinNumber: ['', Validators.required]
    });
    this.partyId = '';
    this.paymentMethodId = '';

    this.paymentMethods = [];
    this.billingAccounts = [];
    this.add_skill_form = this._FormBuilder.group({
      skillTypeId: [''],
      yearsExperience: [''],
      skillLevel: [''],

      rating: [''],


    });
    this.qualification = this._FormBuilder.group({
      desc: [''],
      title: [''],
      statusId: [''],

      verif: [''],
      tdate: [''],
      partyQual: [''],
      fdate: [''],

    })
    this.leaveForm = this._FormBuilder.group({
      leavetypeId: [''],
      fDate: [''],
      reasonId: [''],

      appParty: [''],
      tdate: [''],
      desc: [''],


    })
    this.jobInfoForm = this._FormBuilder.group({
      description: [''],
      endDate: [''],
      jobStatus: [''],
      jobType: [''],
      partyId: [''],
      productType: [''],
      projectedEndDate: [''],
      serviceType: [''],
      startDate: ['']
    });
    this.additionalDetailForm = this._FormBuilder.group({
      infoType: [''],
      partyId: [''],
      prefeferredSendMethod: [''],
      priceLevel: [''],
      rep: [''],
      resaleNo: [''],
      terms: ['']
    });
    this.accountForm = this._FormBuilder.group({
      address1: [''],
      address2: [''],
      areaCode: [''],
      city: [''],
      contactNumber: [''],
      countryCode: [''],
      countryGeoId: [''],
      emailAddress: [''],
      extension: [''],
      groupName: ['', [Validators.required]],
      numEmployees: [''],
      officeSiteName: [''],
      postalCode: [''],
      stateProvinceGeoId: [''],
      firstName: [''],
      lastName: [''],
      title: [''],
      customerType: [''],
      annualRevenue: [''],
      description: [''],
      externalId: [''],
      logoImageUrl: [''],
      preferredCurrencyUomId: [''],
      statusId: [''],
    });

    this.add_skill_form = this._FormBuilder.group({
      skillTypeId: [''],
      yearsExperience: [''],
      skillLevel: [''],
      PartyID: [''],

      rating: [''],


    });
    this.isApproval = false;
    this.add_leave_form = this._FormBuilder.group({
      leaveTypeId: [''],
      emplLeaveReasonTypeId: [''],
      fromDate: [''],
      thruDate: [''],
      approverPartyId: [''],
      description: [''],
      leaveStatus: [''],
      partyId: ['']


    });

    this.partyId = '';
    this.editMode = false;
    this.contactForm = this._FormBuilder.group({
      allowSolicitation: [''],
      areaCode: [''],
      askForName: [''],
      contactMechId: [''],
      contactMechPurposeTypeId: ['', [Validators.required]],
      contactMechTypeId: [''],
      contactNumber: [''],
      countryCode: [''],
      extension: [''],
      partyId: [''],
      preContactMechTypeId: ['TELECOM_NUMBER']
    });
    this.contactPurpose = [];
    this.contactMechId = '';


    this.addressForm = this._FormBuilder.group({
      address1: [''],
      address2: [''],
      allowSolicitation: [''],
      attnName: [''],
      city: [''],
      contactMechId: [''],
      contactMechPurposeTypeId: ['', [Validators.required]],
      contactMechTypeId: [''],
      countryGeoId: [''],
      directions: [''],
      partyId: [''],
      postalCode: [''],
      preContactMechTypeId: ['POSTAL_ADDRESS'],
      stateProvinceGeoId: [''],
      toName: ['']
    });
    this.updateAccountForm = this._FormBuilder.group({
      numEmployees: [''],
      groupName: [''],
      GroupNameLocal: [''],
      firstName: [''],
      lastName: [''],
      Tickersymbol: [''],
      Comments: [''],
      Title: [''],
      officeSiteName: [''],
      annualRevenue: [''],
      description: [''],
      externalId: [''],
      logoImageUrl: [''],
      preferredCurrencyUomId: [''],
      statusId: ['']
    });
    this.editMode = false;
    this.partyId = '';
    this.countryArray = [];
    this.stateArray = [];
    this.contactMechTypeId = [];
    this.contactMechId = '';

    this.partyId = '';
    this.editMode = false;
    this.creditCardForm = this._FormBuilder.group({
      cardNumber: ['', [Validators.required]],
      cardType: ['', [Validators.required]],
      companyNameOnCard: [''],
      contactMechId: [''],
      description: [''],
      expMonth: ['', [Validators.required]],
      expYear: ['', [Validators.required]],
      firstNameOnCard: ['', [Validators.required]],
      lastNameOnCard: ['', [Validators.required]],
      middleNameOnCard: ['', [Validators.required]],
      suffixOnCard: [''],
      titleOnCard: ['']
    });
    this.cardTypes = [];
    this.contactMechId = '';
    this.paymentMethodId = '';

  }
  
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=>{
      this.partyId= params.partyId;
    })

    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
      partyContentTypeId: new FormControl('')
    });
    this.getAccountNote();
    this.getPartyContentsList();
    this.getLeaveList();
    this.getPartyContent();
    this.accountDetail();
    this.getPaymentMethods();
    this.getTrainingApprovalList();
    this.getSkillList();
    this.getEmployementById();
    this.getEmployeePosition();

    this.getCountryList();

    this.getPartyId();
    this.getPaymentID();
    this.getStatusVerifId();

    this.accountForm.controls.countryGeoId.valueChanges.subscribe(data => {
      if (data) {
        this.countryId = data;
        this.getStateList();
      }
    });

    this.createAccountFormService.create_account_form = null;
    this.createAccountFormService.prepareAccountForm(this.createAccountFormService.create_account_form);

    if (this.partyId) {
      this.show = true;
      this.editMode = true;
      this.getData();
      this._CRMAccountService.getAccountById(this.partyId)
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this.additionalInfo = data.data.additionalInformation;
            this.additionalDetailForm.patchValue({
              ...data.data.additionalInformation
            });
          }
        });
      this._CRMAccountService.getAccountById(this.partyId)
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this.jobInfo = data.data.jobInformation;
            this.jobInfoForm.patchValue({
              ...data.data.jobInformation,
              startDate: data.data.jobInformation.startDate ? moment(data.data.jobInformation.startDate).format('YYYY-MM-DD') : '',
              endDate: data.data.jobInformation.endDate ? moment(data.data.jobInformation.endDate).format('YYYY-MM-DD') : '',
              projectedEndDate: data.data.jobInformation.projectedEndDate ? moment(data.data.jobInformation.projectedEndDate).format('YYYY-MM-DD') : ''
            });

          }

        });
    }
    else {
      this.show = false;
    }
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });

    this.getCrmStatus();
    this.getCurrency();
    this.getPartyId();
    this.getListSkill();

    this.getLeaveReasonId();
    this.getPartyIdLeave();
    this.leavereason();
    this.leaveStatus();

    this.contactMechId = this._ActivatedRoute.snapshot.queryParams.contactMechId;
    if (this.contactMechId) {
      this.editMode = true;
      this.getContactDetails();

    }
    this.getContactPurpose();
    this.contactMechId = this._ActivatedRoute.snapshot.queryParams.contactMechId;
    if (this.contactMechId) {
      this.getAccountDetails();
    }
    this.addressForm.controls.countryGeoId.valueChanges.subscribe(data => {
      if (data) {
        this.getStates(data);
      }
    });
    this.getCountries();
    this.getPreContactMechIds();


    this.getContactList();
    this.fileUploadForm = this.formBuilder.group({
      accountPartyId: [''],
      comments: [''],
      contactPartyId: [''],
      partyId: ['']
    });

    this.fileUploadFormNote = this.formBuilder.group({
      noteName: [''],
      note: ['']
    });

      if (this.partyId) {
        this.showCreateBtn = true;
      }


    this.activatedRoute.queryParams.subscribe(params => {
      this.noteId = params["noteId"];
      this.noteName = params["noteName"];
      this.note = params["note"];
      if (this.noteId && this.partyId) {
        this.showUpdateBtn = true;
      }
    });

    this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.paymentMethodId;
    if (this.paymentMethodId) {
      this.getPaymentMethod();
    }

    this.isEftAccount = this._ActivatedRoute.snapshot.queryParams.isEft === 'false' ? false : true;
    this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.paymentMethodId;
    this.getAccountDetailsEFT();
    if (this.paymentMethodId) {
      this.getPaymentMethodEFT();
    }

     this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.paymentMethodId;
     if (this.paymentMethodId) {
       this.spinner.show();
       this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'CREDIT_CARD')
         .then(data => {
           if (data.success) {
             this.spinner.hide();
             this.creditCardValue = data.data;
             this.contactMechId = data.data.creditCardData.currentPostalAddress.contactMechId;
            this.creditCardForm.patchValue({
               ...data.data.creditCardData, ...{
                 description: data.data.paymentMethodData.description
               }
            });
            this.editModeA = true;

           }
        });

   }
     this.getCardTypes();
     this.getAccountDetailsCredit();


    this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.paymentMethodId;
    this.paymentMethodTypeId = this._ActivatedRoute.snapshot.queryParams.paymentMethodTypeId;
    this.getAccountDetailsCheck();
    this.getPaymentMethodTypes();
    if (this.paymentMethodId) {
      this.getPaymentMethodById();
    }

    this.paymentMethodId = this._ActivatedRoute.snapshot.queryParams.billingAccountId;
    this.getCurrencyList();
    this.getPartyIds();
    if (this.paymentMethodId) {
        this.getBillingAccountById();
    }


    this.getFindEmploymentList();
    this.getPartyQualList();
    this.getsummaryPage();
    this.getEmployeeDetail();
    this.getProfileStatus();
    this.getResidenceStatusEnumID();
    this.getEmploymentStatusEnumId();
    this.EditListRelatedAccounts();
    this.editPartyGroup();
    this.getContactNotes();
    this.getProfileStatus();
    this.EditEmployeeLeaves();
    this.EditPartyQual();
    this.getPartyQualType();
    this.EditEmployeePosition();
    this.getEmployments();
    this.EditEmployeeSkills();
    this.EditListRelatedAccounts();
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  EditListRelatedAccounts() {
    this.spinner.show();
    this.myContactsService.EditListRelatedAccounts(this.partyId).subscribe(res => {
      this.EditListRelatedAccountList = res.data;
      this.spinner.hide();
    })
  
  }

  getEmployments() {
    this.spinner.show();
    this.myContactsService.getEmployments(this.partyId).subscribe(res => {
      this.employmentsList = res.data[0].EditEmployeeSkills;
      this.spinner.hide();
    })
  
  }
  EditEmployeeSkills() {
    this.spinner.show();
    this.myContactsService.EditEmployeeSkills(this.partyId).subscribe(res => {
      this.EditEmployeeSkillList = res.data[0].EditEmployeeSkills;
      this.spinner.hide();
    })
  
  }
  onDeleteSkills(partyId, skillTypeId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.skillService.deleteSkills(partyId, skillTypeId).subscribe(res => {
         
          this.EditEmployeeSkills();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )  } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    }) }
  detailSkillPages(id, skid, rating, skill, year) {
    this.router.navigate(["/hr/skills/skill-summary"], { queryParams: { updateId: id, skid: skid, rate: rating, skill: skill, year: year } })
  }
  onUpdateEmp(id) {
    this.router.navigate(["/hr/employee-position/employee-position-details"], { queryParams: { positionId: id} })
  }
 
  EditEmployeeLeaves() {
    this.spinner.show();
    this.myContactsService.EditEmployeeLeaves(this.partyId).subscribe(res => {
      this.EditEmployeeLeavesList = res.data;
      this.spinner.hide();
    })
  
  }
  detailPageLeave(epartyId, eleaveTypeId, efromDate, ethruDate, eAppId, eDescription, eReasonid) {
    this.router.navigate(["/hr/employee-leave/leave-summary"], { queryParams: { partyId: epartyId , leaveTypeId: eleaveTypeId, fromDate: efromDate, thruDate: ethruDate, AppId: eAppId, Description: eDescription, Reasonid: eReasonid, approval: false } })
  }
  ////////////////++++++++++++/////////////////////////////////////////++++////////////////////////////
 
  
  resetnote(){
    this.showUpdateNoteBtn = false;
    this.fileUploadFormNote.reset();

  }
  updateNote(product){
    this.showUpdateNoteBtn = true;
    this.notePro =product;
    setTimeout(() => {
    const form =this.fileUploadFormNote;
    form.patchValue({
      "note": product.note  ,
      "noteName": product.noteName  ,
    })
    }, 2000);
  }
  getContactNotes(){
    this.accountsService.getContactNotes(this.partyId).subscribe(res =>{
      this.contactNotesList = res.data;
     
    })
  }
  onSubmitNote() {
    this.spinner.show();
    const form = { ...this.fileUploadFormNote.value }
    this.myContactsService.createContactNote(form, this.partyId).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.getAccountNote();
        this.fileUploadFormNote.reset();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )

  }
  updateContactNote() {
    this.spinner.show();
    const formData={
      "note": this.fileUploadFormNote.value.note  ,
  "noteName": this.fileUploadFormNote.value.noteName  ,
}

    this.accountsService.updateContactNoteHrms(formData, this.notePro.noteId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Succesfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.getContactNotes();

      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message);

    });

  }
  editPartyGroup() {
    this.spinner.show();
    this.myContactsService.editPartyGroup(this.partyId).subscribe(res => {
      this.editPartyGroupList = res.data[0];
      this.spinner.hide();
      setTimeout(() => {
        const form = this.updateAccountForm;
        form.patchValue({
        
          
          numEmployees:  this.editPartyGroupList.dataNode.numEmployees, 
      groupName: this.editPartyGroupList.dataNode.groupName, 
      GroupNameLocal: this.editPartyGroupList.dataNode.groupNameLocal, 
      firstName: this.editPartyGroupList.dataNode.firstName, 
      lastName: this.editPartyGroupList.dataNode.lastName, 
      Tickersymbol: this.editPartyGroupList.dataNode.tickerSymbol, 
      Comments: this.editPartyGroupList.dataNode.comments, 
      Title: this.editPartyGroupList.dataNode.title, 
      officeSiteName: this.editPartyGroupList.dataNode.officeSiteName, 
      annualRevenue: this.editPartyGroupList.dataNode.annualRevenue, 
      description: this.editPartyGroupList.editPartyGroupDetails[0].description, 
      externalId: this.editPartyGroupList.editPartyGroupDetails[0].externalId, 
      logoImageUrl: this.editPartyGroupList.dataNode.logoImageUrl, 
      preferredCurrencyUomId: this.editPartyGroupList.editPartyGroupDetails[0].preferredCurrencyUomId, 
      statusId:this.editPartyGroupList.editPartyGroupDetails[0].statusId, 
        
        })
      }, 2000);
    
    });
  }


  updatePartyGroup() {
    this.spinner.show();
    const requestData = {
      "firstName": this.updateAccountForm.value.firstName,
      "lastName": this.updateAccountForm.value.lastName,
      "annualRevenue":this.updateAccountForm.value.annualRevenue,
      "comments": this.updateAccountForm.value.Comments,
      "currencyId": this.updateAccountForm.value.preferredCurrencyUomId,
      "description": this.updateAccountForm.value.description,
      "externalId": this.updateAccountForm.value.externalId,
      "groupNameLocal": this.updateAccountForm.value.GroupNameLocal,
      "groupLocalName":"",
      "groupName": this.updateAccountForm.value.groupName,
      "logoImageUrl": this.updateAccountForm.value.logoImageUrl,
      "noOfEmployees": this.updateAccountForm.value.numEmployees,
      "officeSiteName": this.updateAccountForm.value.officeSiteName,
      "statusId": this.updateAccountForm.value.statusId,
      "tickerSymbol": this.updateAccountForm.value.Tickersymbol,
      "title": this.updateAccountForm.value.Title,

    
    }
    this.myContactsService.updatePartyGroup(requestData,this.partyId ).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
      //  this.updateProfile.reset();


      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
 
  getEmploymentStatusEnumId() {
    this.spinner.show();
    this.myContactsService.getEmploymentStatusEnumId().subscribe(res => {
      this.EmploymentStatusEnumId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.EmploymentStatusEnumId) {
        this.EmploymentStatusEnumIdArray.push({
          label: value.description,
          value: value.employmentStatusEnumId
        });
      }
    });
  }
  
  getProfileStatus() {
    this.spinner.show();
    this.myContactsService.getProfileStatus().subscribe(res => {
      this.crmStatus = res.data;
      this.spinner.hide();
      for (const value of this.crmStatus) {
        this.crmArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })

  }

  getResidenceStatusEnumID() {
    this.spinner.show();
    this.myContactsService.getResidenceStatusEnumID().subscribe(res => {
      this.ResidenceStatusEnumID = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ResidenceStatusEnumID) {
        this.ResidenceStatusEnumIDArray.push({
          label: value.description,
          value: value.residenceStatusEnumID
        });
      }
    });
  }

  getsummaryPage() {
    this.spinner.show();
    this.employeeService.getEmployeeId(this.partyId).subscribe(res => {
      this.summaryPage = res.data;
      setTimeout(() => {
        const form = this.updateProfile;
        form.patchValue({
          partyID: this.partyId,
          salutation: "",
          firstName: this.summaryPage.firstName ,
          middleName: "",
          lastName: this.summaryPage.lastName ,
          personalTitle: "",
          suffix: "",
          nickname: "",
          firstNameLocal: "",
          middleNameLocal: "",
          lastNameLocal: "",
          otherLocal: "",
          memberID: "",
          gender: this.summaryPage.gender ,
          birthDate: this.summaryPage.birthDate ,
          decreasedDate: "",
          height: "",
          weight: "",
          mothersMaidenName: "",
          oldMaritialStatus: "",
          maritialStatusEnumID: "",
          socialSecurityNumber: this.summaryPage.socialSecurityNumber ,
          passportNumber: "",
          passportExpiryDate: "",
          totalExperience: "",
          comments: this.summaryPage.comments ,
          employeeStatusEnumID: "",
          residenceStatusEnumID: "",
          occupation: "",
          yearswithEmployer: "",
          monthsWithEmployer: "",
          existingCustomer: "",
          cardID: "",
          usCitizen: this.summaryPage.usCitizen ,
          ethnicity: this.summaryPage.ethnicity ,
          accountNo: this.summaryPage.accountNo ,
          billingRateLevel: "",
          preferredCurrencyUomID: this.summaryPage.preferredCurrencyUomId ,
          description: this.summaryPage.description ,
          externalID: this.summaryPage.externalId ,
          statusID: this.summaryPage.statusId ,
        })
      }, 2000);
      this.spinner.hide();

    })

  }
  
  updateProfilePerson() {
    this.spinner.show();
    const requestData = {
      "accountNo": this.updateProfile.value.accountNo,
      "billingRateLevel": this.updateProfile.value.billingRateLevel,
      "birthDate": this.updateProfile.value.birthDate,
      "comments": this.updateProfile.value.comments,
      "description": this.updateProfile.value.description,
      "ethnicity": this.updateProfile.value.ethnicity,
      "externalId": this.updateProfile.value.externalID,
      "firstName": this.updateProfile.value.firstName,
      "gender": this.updateProfile.value.gender,
      "lastName": this.updateProfile.value.lastName,
      "maritalStatusEnumId": this.updateProfile.value.maritialStatusEnumID,
      "partyId": this.partyId,
      "preferredCurrencyUomId": this.updateProfile.value.preferredCurrencyUomID,
      "socialSecurityNumber": this.updateProfile.value.socialSecurityNumber,
      "statusId": this.updateProfile.value.statusID,
      "usCitizen": this.updateProfile.value.usCitizen,
    }
    this.accountsService.updateProfilePerson(this.partyId, requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
      //  this.updateProfile.reset();


      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  onUpdateLeaves(product){
    this.showLeave = true;
    let fromDate = this.datePipe.transform(product.fromDate, 'yyyy-MM-dd')
    let toDate = this.datePipe.transform(product.thruDate, 'yyyy-MM-dd')
    setTimeout(() => {

      const formValue = this.add_leave_form;
      formValue.patchValue({
        partyId: product.partyIdEdit,
        leaveTypeId: product.leaveTypeId,
        emplLeaveReasonTypeId: product.Reasonid,
        approverPartyId: product.AppId,
        description: product.Description,
        thruDate: toDate,
        fromDate: fromDate,
        leaveStatus:product.statusId
         

      })
    }, 3000);
  }
  resetLeave()
  {
    this.showLeave = false;
    this.add_leave_form.reset();
  }
  resetSkill()
  {
    this.showSkill = false;
    this.add_skill_form.reset();
  }
  onUpdateSkill(product){
    this.showSkill = true;
    setTimeout(() => {
      const formValue = this.add_skill_form;
    formValue.patchValue({
      partyId:product.updateId,
      skillTypeId: product.Skillid,
      yearsExperience: product.year,
      rating: product.rate,
      skillLevel: product.skill,
     
    })
    }, 3000);
  }


  onUpdateEmpPosition(id) {
    this.router.navigate(["hr/employee-position/add-employee-position"], { queryParams: { updateId: id } })
  }

  detail(id) {
    this.router.navigate(["/hr/employee-position/employee-position-details"], { queryParams: { positionId: id} })
  }
  onDeleteLeave(partyId, leaveTypeId, fromDate) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.employeeLeaveService.deleteLeave(partyId, leaveTypeId, fromDate).subscribe(res => {
          this.getLeaveList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  qualificationDetail(partyId, partyQualTypeId, fromDate, qualificationDesc, title, statusId, verifStatusId, thruDate) {
    this.router.navigate(['/hr/detail-qualification'], {
      queryParams: {
        partyId: partyId, partyQualTypeId: partyQualTypeId,
        fromDate: fromDate, qualificationDesc: qualificationDesc, title: title, statusId: statusId,
        verifStatusId: verifStatusId, thruDate: thruDate
      }
    })
  }
  EditEmployeePosition() {
    this.spinner.show();
    this.myContactsService.EditEmployeePosition(this.partyId).subscribe(res => {
      this.EditEmployeePositionList = res.data[0].EditEmployeePosition;
      this.spinner.hide();
    })
  
  }
  detailEmployeePosition(id) {
    this.router.navigate(["/hr/employee-position/employee-position-details"], { queryParams: { positionId: id} })
  }
  EditPartyQual() {
    this.spinner.show();
    this.myContactsService.EditPartyQual(this.partyId).subscribe(res => {
      this.EditPartyQualList = res.data
      ;
      this.spinner.hide();
    })
  
  }
  deletePartyQual(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "fromDate":product.fromDate,
          "partyId": product.EditPerfReview.partyId,
          "partyQualTypeId": product.EditPerfReview.partyQualTypeId,
          "qualificationDesc": product.EditPerfReview.qualificationDesc,
          "statusId": product.EditPerfReview.statusId,
          "thruDate":product.thruDate,
          "title": product.EditPerfReview.title,
          "verifStatusId": product.EditPerfReview.verifStatusId,
        }
        this.accountsService.deletePartyQual(req).subscribe((res: any) => {
          this.getPartyQualList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  detailPageSkill(id, skid, rating, skill, year) {
    this.router.navigate(["/hr/skills/skill-summary"], { queryParams: { updateId: id, skid: skid, rate: rating, skill: skill, year: year } })
  }
  detailPageTraining(id) {
    this.router.navigate(["/hr/training-approvals/training-summary"], { queryParams: { trainingId: id } })
  }
  getPartyQualList() {
    this.spinner.show();
    this.accountsService.getPartyQualList().subscribe(res => {
      this.employeeQualList = res.data;
      this.spinner.hide();

    })
  }
  employementDetailQual(partyId, partyQualTypeId, fromDate, qualificationDesc, title, statusId, verifStatusId, thruDate) {
    this.router.navigate(['/hr/detail-qualification'], {
      queryParams: {
        partyId: partyId, partyQualTypeId: partyQualTypeId,
        fromDate: fromDate, qualificationDesc: qualificationDesc, title: title, statusId: statusId,
        verifStatusId: verifStatusId, thruDate: thruDate
      }
    })
  }

  getPartyQualType() {
    this.spinner.show();
    this.accountsService.getPartyQualType().subscribe(res => {
      this.PartyQualTypeId = res.data;
      this.spinner.hide();
      for (const value of this.PartyQualTypeId) {
        this.PartyQualTypeIdArray.push({
          label: value.partyQualTypeId,
          value: value.partyQualTypeId
        })
      }
    })
  }
  getStatusVerifId() {
    this.spinner.show();
    this.accountsService.getStatusVerifId().subscribe(res => {
      this.VerifStatusID = res.data;
      this.spinner.hide();
      for (const value of this.VerifStatusID) {
        this.VerifStatusIDArray.push({
          label: value.statusId,
          value: value.statusId
        })
      }
    })
  }
  getPaymentID() {
    this.spinner.show();
    this.accountsService.getPaymentID().subscribe(res => {
      this.PaymentID = res.data;
      this.spinner.hide();
      for (const value of this.PaymentID) {
        this.PaymentIDArray.push({
          label: value.data.paymentId,
          value: value.data.paymentId
        })
      }
    })
  }

  ////////////////////////////Functions//////////////////////////
  getBillingAccountById(): void {
    this.spinner.show();
    this._CRMAccountService.getBillingAccountById(this.paymentMethodId, this.partyId)
        .then(data => {
            if (data.success) {
                this.spinner.hide();
                this.billingAccount = data.data;
                this.availableBalance = data.data.availableBalance;
                this.billingAccountForm.patchValue({
                    ...data.data
                });
                this.editModeA = true;
            }
           
        });
       
}
getPartyIds(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
        .then(data => {
            this.spinner.hide();
            this.partyIds = data.data.PersonsGroups.map(value => {
                return {
                    label: value.partyId,
                    value: value.partyId
                };
            });
        });
       
}
getCurrencyList(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
        .then(data => {
            if (data.success) {
                this.spinner.hide();
                this.currencyUomIds = data.data.currencyList.map(value => {
                    return {
                        label: value.uomId,
                        value: value.uomId
                    };
                });
            }
        });
       
}
submitBill(): void {
    this.spinner.show();
    if (this.billingAccountForm.valid) {
        this._CRMAccountService.createBillingAccount(this.partyId, {
            ...this.billingAccountForm.value, ...{
                fromDate: this.billingAccountForm.value.fromDate ? moment(this.billingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
                thruDate: this.billingAccountForm.value.thruDate ? moment(this.billingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Created');
                    this.spinner.hide();
                    this.closebutton12.nativeElement.click();
                    this.getPaymentMethods();
                    this.billingAccountForm.reset();
                }
            });
    }
 
}
updateBill(): void {
    this.spinner.show();
    if (this.billingAccountForm.valid) {
        this._CRMAccountService.updateBillingAccount(this.paymentMethodId, this.partyId, {
            ...this.billingAccount, ...this.billingAccountForm.value, ...{
                fromDate: this.billingAccountForm.value.fromDate ? moment(this.billingAccountForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
                thruDate: this.billingAccountForm.value.thruDate ? moment(this.billingAccountForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.closebutton12.nativeElement.click();
                    this.getPaymentMethods();
                    this.billingAccountForm.reset();

                }
            });
    }
   
}

  getPaymentMethodById(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'CHECK_ACCOUNT')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.paymentMethodValue = data.data.checkAccountData;
          if (data.data.checkAccountData.postalAddressList[0]) {
            this.contactMechId = data.data.checkAccountData.postalAddressList[0].contactMech.contactMechId;
          } else {
            this.contactMechId = data.data.checkAccountData.currentPostalAddress.contactMechId;
          }

          this.eftAccountForm.patchValue({
            ...data.data.checkAccountData, ...{
              paymentMethodTypeId: this.paymentMethodTypeId, ...{
                description: data.data.paymentMethodData.description
              }
            }
          });

          this.editModeA = true;
        }
      });

  }
  getPaymentMethodTypes(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodTypes()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.paymentMethodTypes = data.data.map(value => {
            return {
              label: value.description,
              value: value.paymentMethodTypeId
            };
          });
        }
      });

  }
  getAccountDetailsCheck(): void {
    this.spinner.show();
    this._CRMAccountService.getAccountById(this.partyId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.accountDataCheck = data.data;
        }
      });

  }


  submitCheck(): void {
    this.spinner.show();
    if (this.eftAccountForm.valid) {
      this._CRMAccountService.createCheckAccount(this.partyId, {
        ...this.eftAccountForm.value, ...{
          contactMechId: this.contactMechId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton11.nativeElement.click();
            this.getPaymentMethods();
            this.eftAccountForm.reset();

          }
        });
    }

  }
  updateCheck(): void {
    this.spinner.show();
    if (this.eftAccountForm.valid) {
      this._CRMAccountService.updateCheckAccount(this.partyId, this.paymentMethodId, {
        ...this.paymentMethodValue, ...this.eftAccountForm.value, ...{
          contactMechId: this.contactMechId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.editModeA = false;
            this.closebutton11.nativeElement.click();
            this.getPaymentMethods();
            this.eftAccountForm.reset();
          }
        });
    }

  }


  getAccountDetailsCredit(): void {
    this.spinner.show();
    this._CRMAccountService.getAccountById(this.partyId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.accountDataCredit = data.data;
        }
      });

  }
  getCardTypes(): void {
    this.spinner.show();
    this._CRMAccountService.getCreditCardTypes()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.cardTypes = data.data.map(value => {
            return {
              label: value.enumCode,
              value: value.enumId
            };
          });
        }
      });

  }

  getAddress(event: any, contactMechId: string): void {
    this.contactMechId = (event.target.checked) ? contactMechId : '';
  }
  submitCredit(): void {
    this.spinner.show();
    if (this.creditCardForm.valid) {
      this._CRMAccountService.createCreditCard(this.partyId, {
        ...this.creditCardForm.value, ...{
          contactMechId: this.contactMechId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.creditCardForm.reset();
          }
        });
    }

  }
  updateCredit(): void {
    this.spinner.show();
    if (this.creditCardForm.valid) {
      this._CRMAccountService.updateCreditCard(this.partyId, this.paymentMethodId, {
        ...this.creditCardValue.creditCardData, ...this.creditCardForm.value, ...{
          contactMechId: this.contactMechId,
          suffixOnCard: 'I'
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.creditCardForm.reset();

          }
        });
    }

  }

  getPaymentMethodEFT(): void {
    this.spinner.show();
    this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'EFT_ACCOUNT')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.eftAccount = data.data.eftAccountData;
          this.contactMechId = data.data.eftAccountData.postalAddressList[0].contactMech.contactMechId;
          this.eftAccountFormEFT.patchValue({
            ...data.data.eftAccountData, ...{
              description: data.data.paymentMethodData.description
            }
          });
          this.editModeA = true;

        }
      });

  }
  getAccountDetailsEFT(): void {
    this.spinner.show();
    this._CRMAccountService.getAccountById(this.partyId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.accountDataEFT = data.data;
        }
      });

  }

  submitEFT(): void {
    this.spinner.show();
    if (this.isEftAccount) {
      if (this.eftAccountFormEFT.valid) {
        this._CRMAccountService.createEftAccount(this.partyId, this.eftAccountFormEFT.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.spinner.hide();
              this.closebutton10.nativeElement.click();
              this.getPaymentMethods();
              this.eftAccountFormEFT.reset();
            }
          });
      }
    } else {
      if (this.eftAccountFormEFT.valid) {
        this._CRMAccountService.createCheckAccount(this.partyId, this.eftAccountFormEFT.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.spinner.hide();
              this.closebutton10.nativeElement.click();
              this.getPaymentMethods();
              this.eftAccountFormEFT.reset();

            }
          });
      }
    }

  }
  updateEFT(): void {
    this.spinner.show();
    if (this.eftAccountFormEFT.valid) {
      this._CRMAccountService.updateEftAccount(this.partyId, this.paymentMethodId, {
        ...this.eftAccount, ...this.eftAccountFormEFT.value, contactMechId: this.contactMechId
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.editModeA = false;
            this.closebutton10.nativeElement.click();
            this.getPaymentMethods();
            this.eftAccountFormEFT.reset();

          }
        })
    }

  }


  getPaymentMethod(): void {

    this.spinner.show();
    this._CRMAccountService.getPaymentMethodById(this.partyId, this.paymentMethodId, 'GIFT_CARD')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.giftCard = data.data.giftCardData;
          this.giftForm.patchValue({
            ...data.data.giftCardData, ...{
              description: data.data.paymentMethodData.description
            }
          });
          this.editModeA = true;

        }
      });

  }
  submitGift(): void {

    this.spinner.show();
    if (this.giftForm.valid) {
      this._CRMAccountService.createGiftCard(this.partyId, this.giftForm.value)
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton9.nativeElement.click();
            this.getAccountNote();
            this.giftForm.reset();
          }
        });
    }

  }
  updateGift(): void {
    this.spinner.show();
    if (this.giftForm.valid) {

      this._CRMAccountService.updateGiftCard(this.partyId, this.paymentMethodId, {
        ...this.giftCard, ...this.giftForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.closebutton9.nativeElement.click();
            this.getAccountNote();
            this.giftForm.reset();
          }
        });
    }

  }


  navigateFromCreate() {
    this.router.navigate(["/crm/accounts/account-details"], { queryParams: { partyId: this.partyId } });
  }


  onUpdateNote() {

    this.spinner.show();
    const form = { ...this.fileUploadFormNote.value }
    this.myContactsService.updateContactNote(form, this.noteId).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.getAccountNote();
        this.fileUploadFormNote.reset();


      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  changeactiveCategoryFunctionValue(tab: number) {
    this.activeCategoryB = tab;
  }

  navigate() {
    this.router.navigate(["/crm/accounts/account-details"], { queryParams: { partyId: this.partyId } })
  }
  getContactList() {
    this.spinner.show();
    this.myContactsService.getContactsLists().subscribe(res => {
      this.contactId = res.data;
      this.spinner.hide();
      for (const value of this.contactId) {
        this.contactArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  onSubmitBTN() {
    this.spinner.show();
    this.fileUploadForm.get('accountPartyId').patchValue(this.partyId);
    this.fileUploadForm.get('partyId').patchValue(this.partyId);
    const formData = new FormData();
    const formdata = { ...this.fileUploadForm.value };
    this.myContactsService.RelatedAccount(formdata).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.EditListRelatedAccounts();
        this.fileUploadForm.reset();
      }
    }
      , (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }

  changeactiveCategoryFunctionA(tab: number) {
    this.activeCategory = tab;
  }

  getAccountDetails(): void {
    this.spinner.show();
    this._CRMAccountService.getAccountById(this.partyId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.accountInfo = data.data.contactInformation.filter(value => {
            if (value.contactMech && (value.contactMech.contactMechId === this.contactMechId)) {
              this.addressForm.patchValue({
                ...value.postalAddress, ...{
                  contactMechPurposeTypeId: value.partyContactMechPurposes[0].contactMechPurposeTypeId
                }
              })
              return value.postalAddress;
            }
          });
          this.addressForm.controls.contactMechPurposeTypeId.disable();
          this.addressForm.controls.contactMechPurposeTypeId.updateValueAndValidity();

        }
      });

  }
  getPreContactMechIds(): void {
    this.spinner.show();
    this._CRMAccountService.getContactPurposes(this.partyId, 'POSTAL_ADDRESS')
      .then(data => {
        if (data.success) {
          this.contactMechTypeId = data.data.purposeTypes.map(value => {
            return {
              label: value.description,
              value: value.contactMechPurposeTypeId
            };
          });
        }
      });
    this.spinner.hide();

  }
  getCountries(): void {
    this.spinner.show();
    this._GlobalResourceService.getCountryList()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.countryArray = data.data.countryList.map(value => {
            return {
              label: value.split(':')[0].trim(),
              value: value.split(':')[1].trim()
            };
          });
        }
      });

  }
  getStates(countryId: string): void {
    this.spinner.show();
    this._GlobalResourceService.getStateList(countryId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.stateArray = data.data.stateList.map(value => {
            return {
              label: value.split(':')[0].trim(),
              value: value.split(':')[1].trim()
            };
          });
        }
      });

  }



  submitAddress(): void {
    this.spinner.show();
    if (this.addressForm.valid) {
      this._CRMAccountService.createPostalAddress({
        ...this.addressForm.value, ...{
          partyId: this.partyId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton5.nativeElement.click();
            this.accountDetail();
            this.addressForm.reset();
          }
        });
    }

  }
  updateAddress(): void {
    this.spinner.show();
    if (this.addressForm.valid) {
      this._CRMAccountService.updatePostalAddress({
        ...this.accountInfo[0], ...this.addressForm.value, ...{
          partyId: this.partyId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.addressForm.controls.contactMechPurposeTypeId.enable();
            this.addressForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
            this.closebutton5.nativeElement.click();
            this.accountDetail();
            this.addressForm.reset();
          }
        });
    }

  }


  getContactDetails(): void {
    this.spinner.show();
    this._DetailedEmployeeSummary.getTelecomById(this.contactMechId, this.partyId)
      .then(data => {
        this.contact = {
          areaCode: data.areaCode,
          askForName: data.askForName,
          countryCode: data.countryCode,
          contactNumber: data.contactNumber,
          contactMechId: data.contactMechId,
          allowSolicitation: data.allowSolicitation,
          contactMechPurposeTypeId: data.contactMechPurposeTypeId,
          contactMechTypeId: data.contactMechTypeId,
          extension: data.extension,
          partyId: data.partyId,
          preContactMechTypeId: 'TELECOM_NUMBER'
        };
        this.contactForm.patchValue(this.contact);
        this.spinner.hide();
        this.contactForm.controls.contactMechPurposeTypeId.disable();
        this.contactForm.controls.contactMechPurposeTypeId.updateValueAndValidity();
      });

  }
  getContactPurpose(): void {
    this.spinner.show();
    this._CRMAccountService.getContactPurposes(this.partyId, 'TELECOM_NUMBER')
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.contactPurpose = data.data.purposeTypes.map(value => {
            return {
              label: value.description,
              value: value.contactMechPurposeTypeId
            };
          });
        }
      });

  }
  updateContact(): void {
    this.spinner.show();
    if (this.contactForm.valid) {
      this._DetailedEmployeeSummary.updateEmployeeTelecomNumber({
        ...this.contact, ...this.contactForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.spinner.hide();
            this.closebutton4.nativeElement.click();
            this.accountDetail();
            this.contactForm.reset();

          }
        });
    }

  }
  submitContact(): void {
    this.spinner.show();
    if (this.contactForm.valid) {
      this._DetailedEmployeeSummary.createEmployeeTelecomNumber({
        ...this.contactForm.value, ...{
          partyId: this.partyId
        }
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.closebutton4.nativeElement.click();
            this.accountDetail();
            this.contactForm.reset();

          }
        });
    }

  }

  leaveStatus() {
    this.spinner.show();
    this.employeeLeaveService.getLeaveStatus().subscribe(res => {
      this.leaveStatusReoort = res.data;
      this.spinner.hide();
      for (const value of this.leaveStatusReoort) {
        this.leaveStatusReoortArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })

  }
  navigatePrev() {
    this.router.navigate(['/hr/hrm-main'], { queryParams: { partyId: this.partyIdEdit } })
  }
  cancelLeave() {
    this.router.navigate(['/hr/hrm-main'], { queryParams: { partyId: this.partyIdEdit } })
  }
  onSubmitLeave() {
    this.spinner.show();
    const fromdate = this.add_leave_form.get('fromDate').value;
    this.updateefromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const todate = this.add_leave_form.get('thruDate').value;
    this.updateetodate = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");

    const formDate = {
      approverPartyId: this.add_leave_form.value.approverPartyId,
      description: this.add_leave_form.value.description,
      emplLeaveReasonTypeId: this.add_leave_form.value.emplLeaveReasonTypeId,
      fromDate: this.updateefromdate,
      leaveTypeId: this.add_leave_form.value.leaveTypeId,
      partyId: this.partyId,
      thruDate: this.updateetodate,
      leaveStatus: this.add_leave_form.value.leaveStatus,

    }
    this.addLeaveEmpService.AddLeave(formDate).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.EditEmployeeLeaves();
        this.add_leave_form.reset();

      }
      else if (res.success == false) {
        this.spinner.hide();
        this.toastr.error(res.message.debugMessage);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }

    })


  }
  UpdateLeave() {
    this.spinner.show();
    const fromdate = this.add_leave_form.get('fromDate').value;
    this.updateefromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const todate = this.add_leave_form.get('thruDate').value;
    this.updateetodate = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");

    const formDate = {
      approverPartyId: this.add_leave_form.value.approverPartyId,
      description: this.add_leave_form.value.description,
      emplLeaveReasonTypeId: this.add_leave_form.value.emplLeaveReasonTypeId,
      fromDate: this.updateefromdate,
      leaveTypeId: this.add_leave_form.value.leaveTypeId,
      partyId: this.add_leave_form.value.partyId,
      thruDate: this.updateetodate,
      leaveStatus: this.add_leave_form.value.leaveStatus,
    }

    this.addLeaveEmpService.update(formDate, this.partyIdEdit, this.leaveTypeId, this.fromDate).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success(res.data.successMessage);
        this.closebutton3.nativeElement.click();
        this.getLeaveList();
        this.add_leave_form.reset();
      }
      else if (res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })


  }
  onUpdateApproval() {
    this.spinner.show();
    const fromdate = this.add_leave_form.get('fromDate').value;
    this.updateefromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");

    const todate = this.add_leave_form.get('thruDate').value;
    this.updateetodate = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");

    const formDate = {
      approverPartyId: this.add_leave_form.value.approverPartyId,
      description: this.add_leave_form.value.description,
      emplLeaveReasonTypeId: this.add_leave_form.value.emplLeaveReasonTypeId,
      fromDate: this.updateefromdate,
      leaveTypeId: this.add_leave_form.value.leaveTypeId,
      partyId: this.add_leave_form.value.partyId,
      thruDate: this.updateetodate,
      leaveStatus: this.add_leave_form.value.leaveStatus,
    }

    this.addLeaveEmpService.postLeaveStatus(formDate).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/hr/hrm-main'], { queryParams: { partyId: this.partyIdEdit } })
      }
      else if (res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();

      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })

  }
  getLeaveReasonId() {
    this.spinner.show();
    this.addLeaveEmpService.getLeaveReasonType().subscribe(res => {
      this.leaveTypeIds = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.leaveTypeIds) {
        this.leaveId.push({
          label: value.description,
          value: value.leaveTypeId
        })
      }
    })

  }
  leavereason() {
    this.spinner.show();
    this.addLeaveEmpService.getLeaveReason().subscribe(res => {
      this.leaveReason = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.leaveReason) {
        this.leaveReasonArray.push({
          label: value.description,
          value: value.emplLeaveReasonTypeId
        })
      }
    })

  }

  getPartyIdLeave() {
    this.spinner.show();
    this.addLeaveEmpService.getPartyIdLeave().subscribe(res => {
      this.partyIdLeave = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partyIdLeave) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }


  getListSkill() {
    this.spinner.show();
    this.addSkillService.getSkillTypeList().subscribe(res => {
      this.getSkillLists = res.data;
      this.spinner.hide();
      for (const value of this.getSkillLists) {
        this.skillArray.push({
          label: value.description,
          value: value.skillTypeId
        })
      }

    })

  }

  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }

  onSubmitSkill() {
    this.spinner.show();
    const req = {
      rating: this.add_skill_form.value.rating,
      skillLevel: this.add_skill_form.value.skillLevel,
      skillTypeId: this.add_skill_form.value.skillTypeId,
      yearsExperience: this.add_skill_form.value.yearsExperience,
      partyId: this.partyId
    }

    this.addSkillService.createSkills(req).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Skill Subbmitted Successfully");
        this.closebutton2.nativeElement.click();
        this.EditEmployeeSkills();
        this.add_skill_form.reset();
      }

      else if (res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
      this.spinner.hide();
    })

  }

  updateSkills() {
    this.spinner.show();
    const req = {
      rating: this.add_skill_form.value.rating,
      skillLevel: this.add_skill_form.value.skillLevel,
      skillTypeId: this.add_skill_form.value.skillTypeId,
      yearsExperience: this.add_skill_form.value.yearsExperience,
      partyId:  this.add_skill_form.value.PartyID,
    }

    this.addSkillService.updateSkills(req, this.updateId).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Skill Updated Successfully");
        this.closebutton2.nativeElement.click();
        this.getSkillList();
        this.add_skill_form.reset();
      }

      else if (res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
      this.spinner.hide();
    })


  }


  getCrmStatus() {
    this.spinner.show();
    this._EditAccountServices.getCrmStatus().subscribe(res => {
      this.spinner.hide();
      this.statusList = res.data.map(value => {
        return {
          label: value.description,
          value: value.statusId
        };
      });
    })

  }

  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.currencyList = data.data.currencyList.map(value => {
            return {
              label: value.description,
              value: value.uomId
            };
          });
        }
      });

  }

  getData() {
    this.spinner.show();
    this.createAccountService.getAccountById(this.partyId).subscribe(res => {
      this.spinner.hide();
      const form = this.createAccountFormService.create_account_form;
      this.accountForm.patchValue({
        groupName: res.data.groupName !== null ? res.data.groupName : '',
        numEmployees: res.data.numEmployees !== null ? res.data.numEmployees : '',
        officeSiteName: res.data.officeSiteName !== null ? res.data.officeSiteName : '',
        emailAddress: res.data.email.infoString !== null ? res.data.email.infoString : '',
        address1: res.data.address.address1 !== null ? res.data.address.address1 : '',
        address2: res.data.address.address2 !== null ? res.data.address.address2 : '',
        city: res.data.address.city !== null ? res.data.address.city : '',
        postalCode: res.data.address.postalCode !== null ? res.data.address.postalCode : '',
        contactNumber: res.data.telenumber.contactNumber !== null ? res.data.telenumber.contactNumber : '',
        countryCode: res.data.telenumber.countryCode !== null ? res.data.telenumber.countryCode : '',
        areaCode: res.data.telenumber.areaCode !== null ? res.data.telenumber.areaCode : '',
        stateProvinceGeoId: res.data.address.stateId !== null ? res.data.address.stateId : '',
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        title: res.data.title,
        annualRevenue: res.data.annualRevenue,
        description: res.data.description,
        externalId: res.data.externalId,
        logoImageUrl: res.data.logoImageUrl,
        preferredCurrencyUomId: res.data.preferredCurrencyUomId,
        statusId: res.data.statusId,
        countryGeoId: res.data.address.countryId
      });

    })

  }


  createAccount() {
    this.spinner.show();
    const formValues = { ...this.createAccountFormService.create_account_form.value };


    const {
      address1,
      address2,
      areaCode,
      city,
      contactNumber,
      countryCode,
      countryGeoId,
      emailAddress,
      extension,
      groupName,
      numEmployees,
      officeSiteName,
      postalCode,
      stateProvinceGeoId,
      firstName,
      lastName,
      title } = this.accountForm.value;
    this._CRMAccountService.createAccount({
      address1,
      address2,
      areaCode,
      city,
      contactNumber,
      countryCode,
      countryGeoId,
      emailAddress,
      extension,
      groupName,
      numEmployees,
      officeSiteName,
      postalCode,
      stateProvinceGeoId,
      firstName,
      lastName,
      title
    })
      .then(data => {
        if (data.success) {
          this.id = data.data.partyId
          this.toastr.success('Customer created successfully');
          this.spinner.hide();
          this.activeCategory = 3;
          //  this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: this.id } });
          // this.router.navigate(['/crm/accounts'])
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    this.spinner.hide();


  }

  updateAccount() {

    this.spinner.show();

    this._CRMAccountService.updateAccount(this.partyId, this.accountForm.value)
      .then(data => {
        if (data.success) {
          this.toastr.success("Customer Updated Successfully");
          this.spinner.hide();
          this.activeCategory = 3;
          // this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId:  this.partyId } });
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    this.spinner.hide();



  }
  back() {
    this.router.navigate(['/crm/accounts']);
  }

  getCountryList() {
    this.spinner.show();
    this.createAccountService.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0],
          value: element.split(":").pop().trim()
        })

      })

    })

  }

  onCountryChange(event) {
    this.stateArray = [];
    this.countryId = event;
    this.getStateList();

  }

  onStateChange(event) {
    this.stateProvinceGeoId = event;
  }

  getStateList() {
    this.spinner.show();
    this.createAccountService.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })

    })


  }

  updateAdd(): void {
    this.spinner.show();
    this._CRMAccountService.updateAdditionalDetail({
      ...this.additionalInfo, ...this.additionalDetailForm.value, ...{
        partyId: this.partyId
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Updated');

          this.spinner.hide();
        }
      });

  }
  updateJob(): void {
    this.spinner.show();
    this._CRMAccountService.updateJobInformation({
      ...this.jobInfo, ...this.jobInfoForm.value, ...{
        partyId: this.partyId,
        startDate: this.jobInfoForm.value.startDate ? moment(this.jobInfoForm.value.startDate).format('YYYY-MM-DD HH:mm:ss') : '',
        endDate: this.jobInfoForm.value.endDate ? moment(this.jobInfoForm.value.endDate).format('YYYY-MM-DD HH:mm:ss') : '',
        projectedEndDate: this.jobInfoForm.value.projectedEndDate ? moment(this.jobInfoForm.value.projectedEndDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Updated');
          this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: this.partyId } });
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.accountDetail();
          this.jobInfoForm.reset();


        }
      });

  }
  submit(): void {
    this.spinner.show();
    this._CRMAccountService.createJobInformation({
      ...this.jobInfoForm.value, ...{
        partyId: this.id,
        startDate: this.jobInfoForm.value.startDate ? moment(this.jobInfoForm.value.startDate).format('YYYY-MM-DD HH:mm:ss') : '',
        endDate: this.jobInfoForm.value.endDate ? moment(this.jobInfoForm.value.endDate).format('YYYY-MM-DD HH:mm:ss') : '',
        projectedEndDate: this.jobInfoForm.value.projectedEndDate ? moment(this.jobInfoForm.value.projectedEndDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Created');
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.accountDetail();
          this.jobInfoForm.reset();

        }
      });

  }
  submitAdd(): void {
    this.spinner.show();
    this._CRMAccountService.createAdditionalDetail({
      ...this.additionalDetailForm.value, ...{
        partyId: this.id
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Created');
          this.spinner.hide();
          this.activeCategory = 4;
        }
      });


  }

 
  getEmployeePosition() {
    this.spinner.show();
    const emplPosition = {
      actualFromDateFrom: "",
      actualFromDateTo: "",
      actualThruDateFrom: "",
      actualThruDateTo: "",
      budgetId: "",
      budgetIdSearchType: "Contains",
      budgetItemSeqId: "",
      budgetItemSeqIdSearchType: "Contains",
      emplPositionId: "",
      emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.employeePositionService.getEmployeePosition(this.employessSize, emplPosition).subscribe((res: any) => {
      this.employeePosition = res.data;
      this.spinner.hide();
    })

  }
  getSkillList() {
    this.spinner.show();
    let req = {
      "pageNo": this.pageNo,
      "pageSize": this.pageSize
    }
    let skillList = {
      partyId: "",
      rating: "",
      skillLevel: "",
      skillTypeId: "",
      yearsExperience: ""
    }


    this.skillService.getSkillList(req, skillList).subscribe(res => {
      this.skillList = res.data;
      this.spinner.hide();
    })

  }

 
  updateData(partyId: string, type: string) {
    switch (type) {
      case 'POSTAL_ADDRESS':
        this.router.navigate(['/hr/employees/update-address'], { queryParams: { employeeDetailId: this.partyId, contactMechId: partyId } })
        break;
      case 'EMAIL_ADDRESS':
        this.router.navigate(['/hr/employees/update-email'], { queryParams: { personId: this.partyId, contactMechId: partyId } })
        break;
      case 'TELECOM_NUMBER':
        this.router.navigate(['/hr/employees/contact'], { queryParams: { partyId: this.partyId, contactMechId: partyId } })
    }


  }
  change() {
    this.router.navigate(['/crm/accounts/listRelatedAccount'], { queryParams: { partyId: this.partyId } })
  }
  onPartyGroup() {

    this.router.navigate(['/crm/accounts/create-accounts'], { queryParams: { partyId: this.partyId } });
  }
  getEmployeeDetail() {
    this.spinner.show();
    this.employeeService.getEmployeeId(this.partyId).subscribe(res => {
      this.employeeDetail = res.data;
      this.spinner.hide();
      res.data.contactInformation.pop();
      this.contactInformation = res.data.contactInformation;
      
     

    })

  }
  accountDetail() {
    this.spinner.show();
    this.accountsService.getAccountById(this.partyId).subscribe(res => {


      this.accountDetails = res.data;
      this.isAdditionalDetail = res.data.additionalInformation.infoType ? true : false;
      this.isJobDetail = res.data.jobInformation.jobStatus ? true : false;
    //  res.data.contactInformation.pop();
   //   this.contactInformation = res.data.contactInformation;

      this.relatedContact = res.data.relatedContacts;
      this.accountName = res.data.groupName;

      this.addr = res.data.address.address1 + ' ' + res.data.address.city + ' ' + res.data.address.postalCode + ' ' + res.data.address.countryGeoId;
      this.emailId = res.data.email.infoString;
      this.phoneNumber = res.data.telenumber.countryCode + ' ' + res.data.telenumber.areaCode + ' ' + res.data.telenumber.contactNumber

    })
    this.spinner.hide();
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];

  }
  onpartyContent(event) {
    this.partyContentTypeId = event;
  }
  getPartyContent() {
    this.spinner.show();
    this.accountsService.getParty().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        });
      }
    });

  }
  employementDetail(partyIdFrom,partyID,fromDate,roleTypeIdFrom,roleTypeIdTo) {
    this.router.navigate(['/hr/detail-employement-summary'],{ queryParams: { partyIdFrom:partyIdFrom ,
      partyID:partyID, fromdate: fromDate,roleTypeIdFrom:roleTypeIdFrom,roleTypeIdTo:roleTypeIdTo } })
  }
  employeeProfile(partyId){
    this.router.navigate(['/hr/hrm-main'],{queryParams:{partyId:partyId}})
  }
  employementDetails(partyIdFrom,partyID,fromDate,roleTypeIdFrom,roleTypeIdTo) {
    this.router.navigate(['/hr/detail-employement-summary'],{ queryParams: { partyIdFrom:partyIdFrom ,
      partyID:partyID, fromdate: fromDate,roleTypeIdFrom:roleTypeIdFrom,roleTypeIdTo:roleTypeIdTo } })
  }
  getFindEmploymentList() {
    this.spinner.show();
    this.accountsService.getFindEmploymentList().subscribe(res => {
      this.getEmployementList = res.data;
      this.spinner.hide();
    })

  }
  getEmployementById() {
    this.spinner.show();
    this.accountsService.getEmployementById(this.partyId).subscribe(data => {
      if (data.success) {
        this.spinner.hide();
        this.getEmployement = data.data;
      }
    })

  }
  getPartyContentsList() {
    this.spinner.show();
    this.accountsService.getPartyContentList(this.partyId).subscribe(res => {
      this.contentList = res.data;
      this.spinner.hide();
    })

  }
  onSubmit() {
    this.spinner.show();
    let formData = new FormData();
    formData.append('file', this.fileData);
    this.partyContentTypeId = this.Editform.get('partyContentTypeId').value;

    this.accountsService.attachPartyContent(formData, this.partyContentTypeId, this.partyId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.getPartyContentsList();

      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message);

    });

  }
  
  onDelete(contentId, fromDate, partyContent, partyId): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._detailedContactsService.deleteContent(contentId, moment(fromDate).format('yyyy-MM-DD HH:mm:ss.SSS'), partyContent, partyId).subscribe(res => {
          if (res['data'].responseMessage == 'success') {
          }
          this.getPartyContentsList();
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  export(id: any, contentName: any) {
    this.spinner.show();
    this.accountsService.downloadView(id).subscribe(res => {
      const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = contentName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }
  getAccountNote() {
    this.spinner.show();
    this.accountsService.getContactNotes(this.partyId).subscribe(res => {
      this.accountData = res.data;
      this.spinner.hide();
    });

  }
  createSkill() {
    this.router.navigate(['/hr/overview-skill'], { queryParams: { partyId: this.partyId } })
  }
  createLeave() {
    this.router.navigate(['/hr/overview-leave'], { queryParams: { partyId: this.partyId } })
  }
  onUpdateLeave(epartyId, eleaveTypeId, efromDate, ethruDate, eAppId, eDescription, eReasonid) {


    this.router.navigate(['/hr/overview-leave'], { queryParams: { partyId: epartyId, leaveTypeId: eleaveTypeId, fromDate: efromDate, thruDate: ethruDate, AppId: eAppId, Description: eDescription, Reasonid: eReasonid, approval: false } })
  }
  onUpdate(eid, eskid, erating, eskill, eyear) {
    this.router.navigate(["/hr/overview-skill"], { queryParams: { partyId: eid, skid: eskid, rate: erating, skill: eskill, year: eyear } })
  }
  account() {
    this.router.navigate(['/crm/accounts/accountNotes'], { queryParams: { partyId: this.partyId } })
  }
  onUpdateNotes(enoteId, enoteName, enote) {
    this.router.navigate(['/crm/accounts/accountNotes'], { queryParams: { noteId: enoteId, leadId: this.partyId, noteName: enoteName, note: enote } })
  }
  onDeleteNotes(noteId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteNote(noteId).subscribe(res => {
          if (res.success) {
            window.location.reload();
          }
        }, (err) => {
          this.toastr.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  paymentMethodNavigation(type: string, paymentMethodId: string): void {
    switch (type) {
      case 'GIFT_CARD':
        this.router.navigate(['/crm/accounts/create-gift-card'], { queryParams: { partyId: this.partyId, paymentMethodId } });
        break;
      case 'EFT_ACCOUNT':
        this.router.navigate(['/crm/accounts/create-eft-account'], { queryParams: { partyId: this.partyId, paymentMethodId, isEftAccount: true } });
        break;
      case 'CERTIFIED_CHECK':
        this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.partyId, paymentMethodId, paymentMethodTypeId: 'CERTIFIED_CHECK' } });
        break;
      case 'CREDIT_CARD':
        this.router.navigate(['/crm/accounts/create-credit-card'], { queryParams: { partyId: this.partyId, paymentMethodId } });
        break;
      case 'COMPANY_CHECK':
        this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.partyId, paymentMethodId, paymentMethodTypeId: 'COMPANY_CHECK' } });
        break;
      case 'BILLING':
        this.router.navigate([`/crm/accounts/create-billing-account`], { queryParams: { partyId: this.partyId, billingAccountId: paymentMethodId } });
        break;
      case 'PERSONAL_CHECK':
        this.router.navigate([`/crm/accounts/create-check-account`], { queryParams: { partyId: this.partyId, paymentMethodId, paymentMethodTypeId: 'PERSONAL_CHECK' } });
        break;
    }
  }
  removePaymentMethod(type: string, paymentMethodId: string): void {
    if (type === 'BILLING') {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this._CRMAccountService.removeBillingAccount(this.partyId, paymentMethodId)
            .then(data => {
              if (data.success) {
                this.getPaymentMethods();
              }
            });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })


    } else {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this._CRMAccountService.removeGiftCard(paymentMethodId)
            .then(data => {
              if (data.success) {
                this.getPaymentMethods();
              }
            });
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )

        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })


    }
  }
  getPaymentMethods(): void {
    this.spinner.show();
    this._CRMAccountService.getAllPayment(this.partyId, false)
      .then(data => {
        if (data.success) {
          this.paymentMethods = data.data.map(value => {
            switch (value.paymentMethod.paymentMethodTypeId) {
              case 'GIFT_CARD':
                return {
                  ...value.giftCard, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Gift Card'
                  }
                };
                break;
              case 'EFT_ACCOUNT':
                return {
                  ...value.eftAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'EFT Account'
                  }
                };
                break;
              case 'CERTIFIED_CHECK':
                return {
                  ...value.certifiedCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Certified Check'
                  }
                };
                break;
              case 'CREDIT_CARD':
                return {
                  ...value.creditCard, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Credit Card'
                  }
                };
                break;
              case 'COMPANY_CHECK':
                return {
                  ...value.companyCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Company Check'
                  }
                }
                break;
              case 'PERSONAL_CHECK':
                return {
                  ...value.personalCheckAccount, ...{
                    paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                    type: 'Personal Check'
                  }
                };
            }
          });
          this.getBillingAccounts();
          this.spinner.hide();
        }
      });

  }
  getBillingAccounts(): void {
    this.spinner.show();
    this._CRMAccountService.getBillingAccounts(this.partyId)
      .then(data => {
        if (data.success) {
          this.billingAccounts = data.data.map(value => {
            return {
              ...value, ...{ type: 'BILLING', paymentMethodTypeId: 'BILLING' }
            };
          });
          this.paymentMethods = [...this.paymentMethods, ...this.billingAccounts];
        }
        this.spinner.hide();
      });

  }
  onDeleteSkill(partyId, skillTypeId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.skillService.deleteSkills(partyId, skillTypeId).subscribe(res => {


          this.getSkillList();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getLeaveList() {
    this.spinner.show();
    const searchEmployrrLeaveForm =
    {
      approverPartyId: "",
      fromDateFrom: "",
      fromDateFromSearchType: "Contains",
      fromDateTo: "",
      fromDateToSearchType: "Contains",
      leaveReasonTypeId: "",
      leaveStatusID: [
        ""
      ],
      leaveTypeId: "",
      partyId: "",
      throughDateFrom: "",
      throughDateTo: "",
      thruDateFromSearchType: "Contains",
      thruDateToSearchType: "Contains"
    }

    this.employeeLeaveService.employeeLeaveList(this.leaveSize, searchEmployrrLeaveForm).subscribe(res => {
      this.employeeLeave = res.data;
      this.spinner.hide();
    })

  }
  getTrainingApprovalList(): void {
    this.spinner.show();
    const req = {
      approvalStatus: "",
      approvalStatusSearchType: "Contains",
      approverId: "",
      approverIdSearchType: "Contains",
      fromDateFrom: "",
      fromDateFromSearchType: "Equals",
      fromDateTo: "",
      fromDateToSearchType: "Equals",
      partyId: this.partyId,
      reason: "",
      reasonSearchType: "Contains",
      thruDateFrom: "",
      thruDateFromSearchType: "Equals",
      thruDateTo: "",
      thruDateToSearchType: "Equals",
      trainingClassTypeId: "",
      trainingRequestId: "",
      trainingRequestIdSearchType: "Contains",
      workEffortId: "",
      workEffortIdSearchType: "Contains"
    }
    this.trainingApprovalService.getOverviewList(this.leaveSize, req).subscribe(data => {
      if (data.success) {
        this.spinner.hide();
        this.trainingApprovalList = data.data;
      }
    })

  }
  createQualifications(){
    this.spinner.show();

    let fromActualdate = this.createQualification.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");

    let formData = {

      "fromDate": this.createQualification.value.fromDate,
      "partyId": this.partyId,
      "partyQualTypeId": this.createQualification.value.PartyQualTypeID,
      "qualificationDesc": this.createQualification.value.QualificationDesc,
      "statusId": "CTNT_PUBLISHED",
      "thruDate": this.createQualification.value.ThroughDate,
      "title": this.createQualification.value.Title,
      "verifStatusId": "IXF_COMPLETE",
    }


    this.accountsService.createPartyQual(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Created Successfully");
        this.closebutton15.nativeElement.click();
        // this.router.navigate(["/hr/detail-qualification"], { queryParams: { partyID:this.createQualification.value.EmployeePartyID,fromdate:this.actualfromdate} })


      }

      else if (res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
      this.spinner.hide();
    })
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategoryValue = tab;
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  newIdent() {
    this.router.navigate(["/hr/employees/new-identification"]);
  }
  userName() {
    this.router.navigate(["/hr/employees/new-username"]);
  }
  newPartyAttribute() {
    this.router.navigate(["/hr/employees/new-party-attribute"]);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}

