import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Location } from '@angular/common';
@Component({
  selector: 'app-detail-new-sprint',
  templateUrl: './detail-new-sprint.component.html',
  styleUrls: ['./detail-new-sprint.component.css']
})
export class DetailNewSprintComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  activeCategory = 1;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  UpdateProductBacklogForm: FormGroup;
  ContentList: any;
  fileData: File;
  productId: any;
  contentForm: FormGroup;
  contentTypeId: any;
  contentTypeIdArray: any[] = [];
  MimeTypeId: any;
  mimeTypeIdArray: any[] = [];
  ContentDataList: any;
  CommunicationList: any;
  communicationEventId: any;
  NewBacklogItemNoteForm: FormGroup;
  NewEmailForm: FormGroup;
  UploadedFileForm: FormGroup;
  communicationEventIds: any;
  datetimeStarted: any;
  statusId: any;
  ProductUpdateEmail: any;
  tooString: any;
  fromString: any;
  EmailsProductBacklogs: any;
  ProductContentList: any;
  custRequestId: any;
  SprintOverview: any;
  ProductBacklogItemStatusList: any;
  description: any;
  yesNOArray: { label: string; value: string; }[];
  RequesterName: any;
  RequesterNameArray: any[] = [];
  desc: any;
  BacklogItemNoteList: any;
  editDetailForm: FormGroup;
  constructor(public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,    
    private _location: Location,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router) {



    this.UpdateProductBacklogForm = this._FormBuilder.group({
      ProductBacklogItem: [''],
      Company: [''],
      ProductOwner: [''],
      BacklogItemCategory: [''],
      PlanTime: [''],
      SequenceNum: [''],
      Billed: [''],
      Status: [''],
      Unplanned: [''],
      Requestname: [''],
      Story: [''],
      Howtotest: [''],
    });

    this.contentForm = this._FormBuilder.group({
      ContentTypeID: [''],
      MimeTypeID: [''],
      Upload: [''],
    });

    this.NewBacklogItemNoteForm = this._FormBuilder.group({
      NoteName: [''],
      NoteInfo: [''],
    });
    this.UploadedFileForm = this._FormBuilder.group({
      UploadedFile: [''],
    });
    this.NewEmailForm = this._FormBuilder.group({
      From: [''],
      To: [''],
      Subject: [''],
      Content: [''],
    });
    this.editDetailForm = this._FormBuilder.group({
      Reason: [''],
    });
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
  }

  ngOnInit(): void {
    this.spinner.hide();
    this.activatedRoute.queryParams.subscribe(params => {
      this.productId = params.productId;
      this.custRequestId = params.custRequestId;
      this.description = params.description;
    })

    this.getMimeTypeId();
    this.getContentTypeId();
    this.getScrumProductContentListData();
    this.getProductUpdateEmail();
    this.getCommunicationList();
    this.getEmailContentData();
    this.getEmailContentData();
    this.getEmailsProductBacklogs();
    this.getProductOverviewProductContent();
    this.getSprintOverview();
    this.getProductBacklogItemStatusList();
    this.getFromPartyIdRequesterName();
    this.getBacklogItemNoteList();

  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  billed() {
    this.spinner.show();
    const requestData = {
      "billed": this.SprintOverview.productOverviewDetails[0].billed,
      "custRequestId": this.custRequestId,
      "description": this.description,
      "productId": this.productId
    }
    this.accountsService.updateProductBacklog(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
      this.activeCategory = 1;
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  newProductBacklogItem() {
    this._Router.navigate(["/psa/product-overview"], { queryParams: { productId: this.productId } })
    this.activeCategory = 5;
  }
  deleteBacklogItem() {
    this.activeCategory = 7;
  }
  deleteBacklogItems() {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {

          "custRequestId": this.custRequestId,
          "productId": this.productId,
          "reason": this.editDetailForm.value.Reason


        }
        this.accountsService.deleteBacklogItem(formData).subscribe((res: any) => {
          this.activeCategory = 1;

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  ////////////////----NOTE ------/////////////////////


  deleteProdBacklogNote(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "custRequestId": this.custRequestId,
          "noteId": product.noteId,
          "productId": this.productId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.deleteProdBacklogNote(formData).subscribe((res: any) => {
          this.spinner.hide();
          this.getBacklogItemNoteList();

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }


  getBacklogItemNoteList() {
    this.spinner.show();
    this.accountsService.getBacklogItemNoteList(this.custRequestId).subscribe(res => {
      this.BacklogItemNoteList = res.data[0].noteList;
      this.spinner.hide();
    })
  }

  createProdBacklogNote() {
    this.spinner.show();
    const requestData = {
      "noteInfo": this.NewBacklogItemNoteForm.value.NoteInfo,
      "noteName": this.NewBacklogItemNoteForm.value.NoteName,
    }
    this.accountsService.createProdBacklogNote(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getBacklogItemNoteList();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  ////////////////----UPDATE Product Backlogs------/////////////////////

  updateProductBacklogs() {
    this.spinner.show();
    const requestData = {
      "billed": this.UpdateProductBacklogForm.value.Billed,
      "custEstimatedMilliSeconds": this.UpdateProductBacklogForm.value.PlanTime,
      "custRequestId": this.custRequestId,
      "custRequestTypeId": "RF_PROD_BACKLOG",
      "custSequenceNum": this.UpdateProductBacklogForm.value.SequenceNum,
      "description": this.UpdateProductBacklogForm.value.ProductBacklogItem,
      "noteId": "11664",
      "noteInfo": this.UpdateProductBacklogForm.value.Howtotest,
      "parentCustRequestId": this.UpdateProductBacklogForm.value.Requestname,
      "partyId": "userLogin.partyId",
      "partyIdFrom": "",
      "productId": this.productId,
      "productOwerId": "",
      "sequence": "",
      "sprint": "",
      "statusId": this.UpdateProductBacklogForm.value.Status,
      "story": this.UpdateProductBacklogForm.value.Story,
    }
    this.accountsService.updateProductBacklogs(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    this.getSprintOverview();
  }
  getFromPartyIdRequesterName() {
    this.spinner.show();
    this.accountsService.getFromPartyIdRequesterName().subscribe(res => {
      this.RequesterName = res.data[0].requesterName;
      this.spinner.hide();
      for (const value of this.RequesterName) {
        this.RequesterNameArray.push({
          label: value.fromPartyId,
          value: value.fromPartyId
        })
      }
    })
  }

  getProductBacklogItemStatusList() {

    this.spinner.show();
    this.accountsService.getProductBacklogItemStatusList(this.productId, this.custRequestId).subscribe(res => {
      this.ProductBacklogItemStatusList = res.data;
      this.spinner.hide();
    })
  }

  getSprintOverview() {

    this.spinner.show();
    this.accountsService.getSprintOverview(this.productId, this.custRequestId).subscribe(res => {
      this.SprintOverview = res.data[0];
      this.spinner.hide();
    })
  }


  getProductOverviewProductContent() {

    this.spinner.show();
    this.accountsService.getProductOverviewProductContent(this.productId).subscribe(res => {
      this.ProductContentList = res.data;
      this.spinner.hide();
    })
  }

  getEmailsProductBacklogs() {

    this.spinner.show();
    this.accountsService.getEmailsProductBacklogs().subscribe(res => {
      this.EmailsProductBacklogs = res.data.data;
      this.spinner.hide();
    })
  }
  ////////------------COMMUNICATION-----------//////////


  updateEmailForProduct() {
    this.spinner.show();
    let dateTimeStart = this.datePipe.transform(this.datetimeStarted, "yyyy-MM-dd hh:mm:ss");

    const requestData = {

      "communicationEventId": this.communicationEventIds,
      "contactMechPurposeTypeIdFrom": "SUPPORT_EMAIL",
      "content": this.NewEmailForm.value.Content,
      "contentMimeTypeId": "text/plain",
      "datetimeStarted": dateTimeStart,
      "dummy": "",
      "form": "list",
      "partyIdFrom": this.fromString,
      "partyIdTo": this.tooString,
      "productId": this.productId,
      "productIdIn": this.productId,
      "statusId": this.statusId,
      "subject": this.NewEmailForm.value.Subject,

    }
    this.accountsService.updateEmailForProduct(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.activeCategory = 5;
        this.getCommunicationList();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  getProductUpdateEmail() {
    this.spinner.show();
    this.accountsService.getProductUpdateEmail(this.productId).subscribe(res => {
      this.ProductUpdateEmail = res.data[0];
      this.communicationEventIds = res.data[0].ItemList[0].communicationEventId;
      this.datetimeStarted = res.data[0].ItemList[0].datetimeStarted;
      this.statusId = res.data[0].ItemList[0].statusId;
      this.tooString = res.data[0].ItemList[0].toString;
      this.fromString = res.data[0].ItemList[0].fromString;
      this.spinner.hide();
    })
  }
  detailedCommunicationPage(productId, communicationEventId) {
    this.activeCategory = 7;
    this._Router.navigate(["/psa/product-overview"],
      { queryParams: { productId: productId, communicationEventId: communicationEventId } })

  }



  getEmailContentData() {
    this.spinner.show();
    this.accountsService.getEmailContentData(this.productId).subscribe(res => {
      this.ContentDataList = res.data.data;
      this.spinner.hide();
    })
  }

  EmailContentDownload(imageUrl) {
    this.spinner.show();
    this.accountsService.getEmailContentDownload(imageUrl).subscribe(res => {
      const blob = new Blob([res], { type: 'document/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'DEMO-CONTENT.xml';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })

  }

  fileProgressCom(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  uploadEmailContent() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.accountsService.uploadEmailContent(formData, this.productId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getEmailContentData();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  newEmailPage() {
    this.activeCategory = 6;
  }

  getCommunicationList() {
    this.spinner.show();
    this.accountsService.getCommunicationList(this.productId).subscribe(res => {
      this.CommunicationList = res.data.filteringData;
      this.spinner.hide();
    })
  }


  /////////////=======CONTENT======/////////////////////
  getMimeTypeId() {
    this.spinner.show();
    this.accountsService.getMimeTypeId().subscribe(res => {
      this.MimeTypeId = res.data.mimeTypeId;
      this.spinner.hide();
      for (const value of this.MimeTypeId) {
        this.mimeTypeIdArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      }
    })
  }

  getContentTypeId() {
    this.spinner.show();
    this.accountsService.getContentTypeId().subscribe(res => {
      this.contentTypeId = res.data.contentTypeId;
      this.spinner.hide();
      for (const value of this.contentTypeId) {
        this.contentTypeIdArray.push({
          label: value.description,
          value: value.contentTypeId
        })
      }
    })
  }

  deleteScrumProductContent(contentID) {


    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.accountsService.deleteScrumProductContent(contentID).subscribe((res: any) => {
          this.getScrumProductContentListData();

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  DownloadImageProductContent(imageURL) {
    this.spinner.show();
    this.accountsService.getDownloadImageProductContent(imageURL).subscribe(res => {
      const blob = new Blob([res], { type: 'document/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'DEMO-CONTENT.xml';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })

  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  oncreateScrumProductContent() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.accountsService.createScrumProductContent(formData, this.contentForm.value.ContentTypeID, this.contentForm.value.MimeTypeID, this.productId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.getScrumProductContentListData();
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }
  getScrumProductContentListData() {
    this.spinner.show();
    this.accountsService.getScrumProductContentListData(this.productId).subscribe(res => {
      this.ContentList = res.data.data;
      this.spinner.hide();
    })
  }



  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
