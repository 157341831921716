<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Product Id: {{this.productId }}</span>
               <span class="d-flex">
     
                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>

            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="w3-card-4 classCardA ">

                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">

                                                <div class="create-new-leade ">
                                                    <div class="container-fluid">
                                                        <div class="panel-group">
                                                            <div class="w3-card-4 classCardView">
                                                                <div class="header-tabs"
                                                                    style="background:#F5F7FA!important;">
                                                                    <h4 class=" common-styling hleft">
                                                                      {{productId}}
                                                                    </h4>
                                                                </div>
                                                                <div class="panel-body allStyleUser">
                                                                    <div
                                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="form-group">
                                                                            <div
                                                                                class="card own-account-table borderTable">


                                                                                <p-table [value]=""
                                                                                    [rows]="rows">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr
                                                                                            style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'120px'}"
                                                                                                pSortableColumn="code">
                                                                                                <div class="checkbox-align"
                                                                                                    style="color: white;">
                                                                                                    <p-checkbox
                                                                                                        _ngcontent-egj-c329=""
                                                                                                        styleclass="custom-checkbox"
                                                                                                        binary="true"
                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div
                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div
                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="undefined"
                                                                                                                    value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox"
                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span
                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Image	
                                                                                                    <p-sortIcon
                                                                                                        field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'120px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Image Name
                                                                                                    <p-sortIcon
                                                                                                        field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'120px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    Content ID	
                                                                                                    <p-sortIcon
                                                                                                        field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'110px'}"
                                                                                                pSortableColumn="name">
                                                                                                <div
                                                                                                    style="color: white;">
                                                                                                    ContentDataResourceId
                                                                                                    <p-sortIcon
                                                                                                        field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>

                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'110px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div
                                                                                                style="color: white;">
                                                                                                Status
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>

                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'110px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div
                                                                                            style="color: white;">
                                                                                            Approved Date
                                                                                            <p-sortIcon
                                                                                                field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>

                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'110px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div
                                                                                        style="color: white;">
                                                                                        View

                                                                                        <p-sortIcon
                                                                                            field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body"
                                                                                        let-product>
                                                                                        <tr>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'120px'}">

                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'120px'}">
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'120px'}">
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'120px'}">
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'120px'}">
                                                                                            </td>
                                                                                            <td
                                                                                                [ngStyle]="{'width':'120px'}">
                                                                                            </td>

                                                                                        </tr>


                                                                                    </ng-template>
                                                                                    <ng-template
                                                                                        pTemplate="emptymessage"
                                                                                        let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span
                                                                                                    class="text-center">
                                                                                                    <h4 class="ml-2">
                                                                                                        No
                                                                                                        Record
                                                                                                        Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>

                                                                                <p class="paginate_data">

                                                                                </p>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner>