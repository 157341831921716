import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ToastrService } from 'ngx-toastr';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { FinancialService } from 'src/app/services/financial.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-global-gl-setting-list',
  templateUrl: './global-gl-setting-list.component.html',
  styleUrls: ['./global-gl-setting-list.component.css']
})
export class GlobalGlSettingListComponent implements OnInit {
  createGlobalSettingForm: FormGroup;
  @ViewChild('closebutton') closebutton;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search = '';
  globalGLSettingAccounts: any[];
  activeCategory=1;
  show: boolean;
  glAccountClass: any;
  glAccountType: any;
  glXbrlClass: any;
  glResourceType: any;
  Products: any;
  parentGlAccount: any;
  glAccountClassArray: any[]=[];
  glAccountTypeArray: any[]=[];
  glResourceTypeArray: any[]=[];
  glXbrlClassArray: any[]=[];
  parentGlAccountArray: any[]=[];
  ProductsArray: any[]=[];
  accountCode: any;
  accountName: any;
  description: any;
  productId: any;
  externalId: any;
  glAccountClassId: any;
  glAccountTypeId: any;
  glAccountId: any;
  glXbrlClassId: any;
  parentGlAccountId: any;
  glResourceTypeId: any;
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
 
  constructor(
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly  _Router: Router,
    readonly accountsService: AccountsService,
    readonly spinner: NgxSpinnerService,
    readonly _FinancialService: FinancialService
  ) {
    this.createGlobalSettingForm = this._FormBuilder.group({
      accountCode: [''],
      accountName: [''],
      description: [''],
      externalId: [''],
      glAccountClassId: [''],
      glAccountId: [''],
      glAccountTypeId: [''],
      glResourceTypeId: [''],
      glXbrlClassId: [''],
      parentGlAccountId: [''],
      productId: ['']
    });
   }
  financialList = [{
    'value': '1'
  }]
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getProducts();
    this.activeCategory=1;
    this.getGlSettingAccounts();

  
    this.getGlAccountInitialData();
  }
  referToAccSummary(id){
    this._Router.navigate(["/financial/gl-account-summary"], { queryParams:{ glAccountId : id }});

  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {
      this.spinner.hide();
      this.glAccountClass = res.data.glAccountClass;
      this.glAccountType = res.data.glAccountType;this.glResourceType = res.data.glResourceType;
      this.glXbrlClass = res.data.glXbrlClass; this.parentGlAccount = res.data.parentGlAccount;
      for (const value of this.glAccountClass) {
        this.glAccountClassArray.push({
          label: value.description,
          value: value.glAccountClassId
        })}
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })  }
      for (const value of this.glResourceType) {
        this.glResourceTypeArray.push({
          label: value.description,
          value: value.glResourceTypeId
        })}
      for (const value of this.glXbrlClass) {
        this.glXbrlClassArray.push({
          label: value.description,
          value: value.glXbrlClassId
        }) }
      for (const value of this.parentGlAccount) {
        this.parentGlAccountArray.push({
          label: value.accountName,
          value: value.glAccountId
        })}
    })}
  
  getProducts() {
    this.spinner.show();
    const req = {
      "internalName": "",
      "internalNameSearchType": "Contains",
      "productId": "",
      "productIdSearchType": "Contains"
    }
    this.accountsService.getProducts(this.finSize, req).subscribe((res: any) => {

      this.Products = res.data;
      this.spinner.hide();
      for (const value of this.Products) {
        this.ProductsArray.push({
          label: value.productName,
          value: value.productId
        })
      }


    })
   

  }
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "accountCode": this.createGlobalSettingForm.value.accountCode,
      "accountName": this.createGlobalSettingForm.value.accountName,
      "description": this.createGlobalSettingForm.value.description,
      "externalId": this.createGlobalSettingForm.value.externalId,
      "glAccountClassId": this.createGlobalSettingForm.value.glAccountClassId,
      "glAccountId": this.createGlobalSettingForm.value.glAccountId,
      "glAccountTypeId": this.createGlobalSettingForm.value.glAccountTypeId,
      "glResourceTypeId": this.createGlobalSettingForm.value.glResourceTypeId,
      "glXbrlClassId": this.createGlobalSettingForm.value.glXbrlClassId,
      "parentGlAccountId": this.createGlobalSettingForm.value.parentGlAccountId,
      "productId": this.createGlobalSettingForm.value.productId,
    }
    this.accountsService.postChartAccountData(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.getGlSettingAccounts();
        this.createGlobalSettingForm.reset();
        this.closebutton.nativeElement.click();
        // this._Router.navigate(['/financial/global-gl-setting-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  chartAccount(){
    this._Router.navigate(["/financial/global-gl-setting-list"])
  }
  timePeriod(){
    this._Router.navigate(["/financial/gl-custom-time-period-list"])
  }
  cost(){
    this._Router.navigate(["/financial/global-gl-cost"])
  }
  paymentMethod(){
    this._Router.navigate(["/financial/gl-payment-method-type"])
  }
  invoiceMethod(){
    this._Router.navigate(["/financial/gl-invoice-type-list"])
  }
  rate(){
    this._Router.navigate(["/financial/global-gl-rate-list"])
  }
  foreignExc(){
    this._Router.navigate(["/financial/gl-foreign-exchange-rate"])
  }
  accountCat(){
    this._Router.navigate(["/financial/gl-account-category-list"])
  }
  costCenter(){
    this._Router.navigate(["/financial/gl-cost-center"])
  }
  getGlSettingAccounts(): void {
    this.spinner.show();
    this._FinancialService.getGlAccounts({ pageNo: 1, pageSize: 200 })
      .then(data => {
        if (data.success) {
          this.globalGLSettingAccounts = data.data;
          this.spinner.hide();
        }
      });
    
  }
  createGlSetting() {
    this._Router.navigate(['financial/create-global-gl-setting']);
  }
  assignGlAccount() {
    
    this._Router.navigate(['financial/gl-assign-list']);
 
  }
  onUpdate(eaccountCode,eaccountName,edescription,eproductId,eexternalId,eglAccountClassId,eglAccountTypeId,eglAccountId,eglResourceTypeId,eglXbrlClassId,eparentGlAccountId) {
    this._Router.navigate(['financial/create-global-gl-setting'],{ queryParams: { accountCode: eaccountCode,accountName:eaccountName,description:edescription,productId:eproductId,externalId:eexternalId,glAccountClassId:eglAccountClassId,glAccountTypeId:eglAccountTypeId,glAccountId:eglAccountId,glResourceTypeId:eglResourceTypeId,glXbrlClassId:eglXbrlClassId,parentGlAccountId:eparentGlAccountId} });
  }
  accountDetails() {
    this._Router.navigate(['financial/gl-account-summary']);
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  
  export() {
    this.spinner.show();
    this.accountsService.downloadPDFChartAccountData().subscribe(res => {
      const blob = new Blob([res], {type: 'application/application/pdf' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = 'chartAccount.pdf';
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    })
 

  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}