<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
       <span class="color-black pl-1 titlePanels">Training</span>
       <span class="d-flex">

        <button type="submit" (click)="homeButton();"
            class=" btn btn-outline-secondary mr-2">Home</button>

        <button type="submit" (click)="cancelSubmit();"
            class="btn btn-danger buttonclass ">Back</button>
    </span>

      </div>
      <div class=" bg-white color-grey">
      
        <div class="w3-card-4 classCard" style="margin-bottom:7%;">
          <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px;align-items: center;">
                <li [ngClass]="activeTabbing==1?'active':''" (click)="tabbingFunction(1)"><a>
                  Training Calendar</a></li>
                <li [ngClass]="activeTabbing==2?'active':''" (click)="tabbingFunction(2)"><a>
                  Training Approvals</a></li>

                  <!-- <button class="btn btn-primary float-right" (click)="addEvent()"
                  style="margin-left:69%;">
                    Add new
                  </button> -->
                  <button class="btn btn-primary float-right"   
                  data-toggle="modal" data-target="#CalendarpopUp"
                  style="margin-left:69%;">
                    Add new
                  </button>
                 
                 
               
            </ul>
        </div>
        <div class="calendar-full"  *ngIf="activeTabbing==1" style="padding-bottom:60px;padding: 15px 15px;">
          <div class="w3-card-4 classCard" >
          <div class="row text-center d-flex align-items-center" style="padding-top:20px">
            <div class="col-md-4 d-flex" style="padding-left:30px">
              <div class="btn-group">
                <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                  (viewDateChange)="closeOpenMonthViewDay()">
                  Previous
                </div>
                <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                  Today
                </div>
                <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                  (viewDateChange)="closeOpenMonthViewDay()">
                  Next
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <h3 class="mb-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
            </div>
            <div class="col-md-4 d-flex justify-content-end" style=" padding-right:40px">
              <div class="btn-group">
                <div class="btn btn-primary" (click)="setView(CalendarView.Month)"
                  [class.active]="view === CalendarView.Month">
                  Month
                </div>
                <div class="btn btn-primary" (click)="setView(CalendarView.Week)"
                  [class.active]="view === CalendarView.Week">
                  Week
                </div>
                <div class="btn btn-primary" (click)="setView(CalendarView.Day)"
                  [class.active]="view === CalendarView.Day">
                  Day
                </div>
              </div>
            </div>
          </div>
          <br />
          <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
              [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
              (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
              [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
              [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-day-view>
          </div>

          <!-- Everything you see below is just for the demo, you don't need to include it in your app -->

          <br /><br /><br />

          <h3>
            <span class="text-black" *ngIf="isForm">Add/modify a calendar event</span>
            <!-- <button class="btn btn-primary float-right" (click)="addEvent()">
              Add new
            </button> -->
            <div class="clearfix"></div>
          </h3>
          <hr />
          <ng-container *ngIf="isForm">
            <div class="mt-5 mb-5" id="eventForm" #eventForm>
              <form [formGroup]="calendarEventForm">
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <div class="d-flex flex-column">
                        <label for="training">Training Name</label>
                        <p-dropdown [options]="trainingClassTypes" filter="true" formControlName="workEffortName"
                          placeholder="Training Name"> </p-dropdown>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="description">Description <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" placeholder="Description" formControlName="description">
                      <small class="text-danger"
                        *ngIf="!!calendarEventForm.controls.description.invalid && !!calendarEventForm.controls.touched">Description
                        is required.</small>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="fromDate">From Date</label>
                      <input type="datetime-local" class="form-control" id="meeting-time" name="meeting-time"
                        formControlName="estimatedStartDate" placeholder="From Date">
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="fromDate">Through Date</label>
                      <input type="datetime-local" class="form-control" id="meeting-time" name="meeting-time"
                        formControlName="estimatedCompletionDate" placeholder="Through Date">
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="d-flex flex-row-reverse bd-highlight">
                      <button class="btn btn-danger cursor-pointer ml-2 p-2 bd-highlight"
                        (click)="cancel()">Cancel</button>
                      <button class="btn btn-primary cursor-pointer ml-2 p-2 bd-highlight"
                        (click)="submit()">{{isUpdate ? 'Update' : 'Add'}}</button>
                    </div>
                  </div>


                </div>
              </form>
              <ng-container *ngIf="isUpdate">
                <div class="mt-5 mb-5">
                  <h4 class="text-black">Participants</h4>
                  <hr />
                  <form>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <div class="d-flex flex-column">
                            <label for="partyId">Party Id</label>
                            <div class="d-flex justify-content-between">
                              <p-dropdown [options]="partyIds" filter="true" [(ngModel)]="partyId"
                                [ngModelOptions]="{standalone:true}" placeholder="Party Id"></p-dropdown>
                              <button class="btn btn-primary cursor-pointer" (click)="assignEvent()">Assign</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </ng-container>
            </div>
          </ng-container>


          <ng-container *ngIf="isUpdate">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Employee Party ID</th>
                    <th> Training Request ID</th>
                    <th>Training Class Type ID</th>
                    <th> Approver Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let event of assignList">
                    <td>
                      {{event.partyId}}
                    </td>
                    <td>
                      {{event.trainingRequestId}}
                    </td>
                    <td>
                      {{event.trainingClassTypeId}}
                    </td>
                    <td>{{event.approvalStatus}}
                    </td>
                  </tr>
                  <tr *ngIf="!assignList.length">
                    <td>No Data</td>
                    <td>No Data</td>
                    <td>No Data</td>
                    <td>No Data</td>

                  </tr>
                 
                </tbody>
              </table>
            </div>
          </ng-container>

          <ng-template #modalContent let-close="close">
            <div class="modal-header">
              <h5 class="modal-title">Event action occurred</h5>
              <button type="button" class="close" (click)="close()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div>
                Action:
                <pre>{{ modalData?.action }}</pre>
              </div>
              <div>
                Event:
                <pre>{{ modalData?.event | json }}</pre>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary" (click)="close()">
                OK
              </button>
            </div>
          </ng-template>
</div>
        </div>


        <div class="create-new-leade mt-2" *ngIf="activeTabbing==2">
          <div class="container-fluid">
            <div class="row">
              <form class="w-100" [formGroup]="trainingApprovalForm" style="    padding: 15px 15px;">
                <div class="w3-card-4 classCard" >
                <div class="all-users-infomation font-13 font-medium own-user-select">
                  <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-3 form-group classInput">
                          <label for="exampleInputEmail1">Employee Party ID</label>
                        </div>
                        <div class="col-lg-2">
                          <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                          formControlName="partyId" placeholder="Employee Party ID">
                        </div>

                        <div class="col-lg-3 form-group classInput__new_changes">
                          <label for="exampleInputEmail1">Training Request ID</label>
                        </div>
                        <div class="col-lg-2" style="display: none;">
                          <p-dropdown [options]="opportunityIdArray" formControlName="trainingRequestIdSearchType"
                            filter="true"></p-dropdown>
                        </div>
                        <div class="col-lg-2">
                          <input type="text" class="form-control" placeholder="Training Request ID"
                            formControlName="trainingRequestId">
                        </div>
                       
                    </div>
                </div>
            
              <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-3 form-group classInput">
                      <label for="exampleInputEmail1">Training Class Type</label>
                    </div>
                    <div class="col-lg-2">
                      <p-dropdown filter="true" [options]="trainingClassTypes" placeholder="Training Class Type"
                      formControlName="trainingClassTypeId" optionLabel="label">
                    </p-dropdown>
                    </div>

                    <div class="col-lg-3 form-group classInput__new_changes">
                      <label for="exampleInputEmail1">Work Effort ID</label>
                    </div>
                    <div class="col-lg-2" style="display: none;">
                      <p-dropdown [options]="opportunityIdArray" formControlName="workEffortIdSearchType"
                      filter="true"></p-dropdown>
                    </div>
                    <div class="col-lg-2">
                      <input type="text" class="form-control" formControlName="workEffortId" placeholder="Work Effort ID">
                    </div>
                   
                </div>
            </div>
           
          <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-3 form-group classInput">
                  <label for="exampleInputEmail1">From Date</label>
                </div>
                <div class="col-lg-2" style="display: none;">
                  <p-dropdown [options]="dateIdArray" formControlName="fromDateFromSearchType" filter="true">
                </p-dropdown>
                </div>
                <div class="col-lg-2">
                  <input type="date" class="form-control" formControlName="fromDateFrom">
                </div>
                <div class="col-lg-2" style="display: none;">
                  <p-dropdown [options]="dateIdArray" filter="true" formControlName="fromDateToSearchType">
                  </p-dropdown>
                </div>
                <div class="col-lg-2">
                  <input type="date" class="form-control" formControlName="fromDateTo">
                </div>
               
               
            </div>
        </div>

        <div class="col-lg-12">
          <div class="row">
              <div class="col-lg-3 form-group classInput">
                <label for="exampleInputEmail1">Through Date</label>
              </div>
              <div class="col-lg-2" style="display: none;">
                <p-dropdown [options]="dateIdArray" formControlName="thruDateFromSearchType" filter="true">
                </p-dropdown>
              </div>
              <div class="col-lg-2">
                <input type="date" class="form-control" formControlName="thruDateFrom">
              </div>
              <div class="col-lg-2" style="display: none;">
                <p-dropdown [options]="dateIdArray" filter="true" formControlName="thruDateToSearchType">
                </p-dropdown>
              </div>
              <div class="col-lg-2">
                <input type="date" class="form-control" formControlName="thruDateTo">
              </div>
             
             
          </div>
      </div>
      <div class="col-lg-12">
        <div class="row">
            <div class="col-lg-3 form-group classInput">
              <label for="exampleInputEmail1">Approver Party</label>
            </div>
            <div class="col-lg-2" style="display: none;" >
              <p-dropdown [options]="opportunityIdArray" formControlName="approverIdSearchType" filter="true">
              </p-dropdown>
            </div>
            <div class="col-lg-2">
              <input type="text" class="form-control" formControlName="approverId"
              placeholder="Approver Party">
            </div>

            <div class="col-lg-3 form-group classInput__new_changes">
              <label for="exampleInputEmail1">Approver Status</label>
            </div>
            <div class="col-lg-2" style="display: none;">
              <p-dropdown [options]="opportunityIdArray" formControlName="approvalStatusSearchType"
              filter="true"></p-dropdown>
            </div>
            <div class="col-lg-2">
              <input type="text" class="form-control" formControlName="approvalStatus" placeholder="Status">
            </div>
           
           
        </div>
    </div>
 
  <div class="col-lg-12">
    <div class="row">
        <div class="col-lg-3 form-group classInput">
          <label for="exampleInputEmail1">Reason</label>
        </div>
        <div class="col-lg-2" style="display: none;">
          <p-dropdown [options]="opportunityIdArray" formControlName="reasonSearchType" filter="true">
          </p-dropdown>
        </div>
        <div class="col-lg-2">
          <input type="text" class="form-control" formControlName="reason" placeholder="Reasons">
        </div>
       
       
    </div>
</div>
                 
                 
                </div>
                <div class="col-lg-12 col-12 main-submit-button" style="    margin-left: -27%;"><button _ngcontent-cta-c128="" type="submit"
                    (click)="getTrainingApprovalList()" class="btn btn-secondary submit-btn">Find</button></div>
                </div><br>
                <div class="w3-card-4 classCard">
                  <header class="w3-container w3-blue">
                    <div class="header-tabs">
                      <h4 class=" common-styling h4Margin">
                        Training List
                      </h4>
                   
                    </div>
                  </header>
                <div class="all-users-infomation font-13 font-medium own-user-select">
                  <div class="col-lg-12 col-12">
                    <div class="form-group">
                      
                      <div class="card own-account-table">
                        <p-table [value]="trainingApprovalList" [paginator]="true" [rows]="rows" scrollWidth="100%"
                          [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                          (sortFunction)="customSort($event)" [customSort]="true">
                          <ng-template pTemplate="header">
                            <tr style="background: #0d3769;">
                              <th [ngStyle]="{'width':'155px'}" pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                <div class="checkbox-align" style="color: white;">
                                  <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                      <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined"
                                          value="undefined"></div>
                                      <div role="checkbox"
                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                          class="ui-chkbox-icon ui-clickable"></span></div>
                                    </div>
                                    <!---->
                                  </p-checkbox>
                                  Training Id
                                </div>
                              </th>
                                  <th [ngStyle]="{'width':'150px'}" pSortableColumn="name"> <div style="color: white;">   Employee Party ID <p-sortIcon field="code"></p-sortIcon>
                                </div>
                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                  value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                  role="textbox" aria-disabled="false" aria-readonly="false">
                              </th>
                              <th [ngStyle]="{'width':'150px'}" pSortableColumn="name"> <div style="color: white;">  Training Request ID <p-sortIcon
                                  field="name"></p-sortIcon></div>
                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                  value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                  role="textbox" aria-disabled="false" aria-readonly="false">
                              </th>
                              <th [ngStyle]="{'width':'150px'}" pSortableColumn="category"
                                [ngStyle]="{'width':'150px'}"> <div style="color: white;"> Training Class Type<p-sortIcon field="category">
                                </p-sortIcon></div>
                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                  value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                  role="textbox" aria-disabled="false" aria-readonly="false">
                              </th>
                              <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity"> <div style="color: white;"> Work Effort ID <p-sortIcon
                                  field="quantity"></p-sortIcon></div>
                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                  value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                  role="textbox" aria-disabled="false" aria-readonly="false">
                              </th>
                              <th [ngStyle]="{'width':'150px'}" pSortableColumn="price"> <div style="color: white;"> From Date <p-sortIcon
                                  field="price"></p-sortIcon></div>
                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                  value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                  role="textbox" aria-disabled="false" aria-readonly="false">
                              </th>
                              <th [ngStyle]="{'width':'150px'}" pSortableColumn="price"> <div style="color: white;"> Through Date <p-sortIcon
                                  field="price"></p-sortIcon></div>
                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                  value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                  role="textbox" aria-disabled="false" aria-readonly="false">
                              </th>
                              <th [ngStyle]="{'width':'150px'}" pSortableColumn="price"> <div style="color: white;"> Approver Party<p-sortIcon
                                  field="price"></p-sortIcon></div>
                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                  value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                  role="textbox" aria-disabled="false" aria-readonly="false">
                              </th>
                              <th [ngStyle]="{'width':'150px'}" pSortableColumn="price"> <div style="color: white;"> Approver Status<p-sortIcon
                                  field="price"></p-sortIcon></div>
                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter"
                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                  value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                  role="textbox" aria-disabled="false" aria-readonly="false">
                              </th>

                            
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-product>
                            <tr>
                              
                              <td [ngStyle]="{'width':'155px'}" 
                               class="account-button"   (click)="detailPage(product.trainingRequestId)">
                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted"><div class="custom-checkbox ui-chkbox ui-widget"><div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div><div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div></div></p-checkbox> 
                                {{product.partyId}}

                              </td>
                              <td [ngStyle]="{'width':'150px'}">{{product.trainingRequestId}}</td>
                              <td [ngStyle]="{'width':'150px'}">{{product.trainingClassTypeId}}</td>
                              <td [ngStyle]="{'width':'150px'}">{{product.workEffortId}}</td>
                              <td [ngStyle]="{'width':'150px'}">{{product.fromDate | date: "yyyy-MM-dd HH:mm:ss"}}</td>
                              <td [ngStyle]="{'width':'150px'}">{{product.thruDate | date: "yyyy-MM-dd HH:mm:ss"}}</td>
                              <td [ngStyle]="{'width':'150px'}">{{product.approverPartyName}}</td>
                              <td [ngStyle]="{'width':'150px'}">{{product.approvalStatus}}</td>
                              

                            </tr>
                          </ng-template>
                        </p-table>
                       
                        <p class="paginate_data">
                          View per page </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </form>

            </div>
          </div>
        </div>
        </div>
      </div>
   

    </div>
  </div>
</div>

<div class="modal fade" id="CalendarpopUp" tabindex="-1" role="dialog" aria-labelledby="CalendarpopUp" aria-hidden="true">
  <div class="modal-dialog" role="document">
      <div class="modal-content cssModelLength">
          <div class="modal-header">
              <span class="color-black pl-1">Add a Calendar Event
              </span>
              <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" #closebuttonD>&times;</span>
              </button>
          </div>
           <div class="modal-body d-flex">
              <div class="bg-white color-grey w-100">
                  <div class="w3-card-4 classCardPopUp">
                      <div class="container-fluid">
                          <ul class="tabbing-section tabbing-accordians tabClass">
                              <li>
                                  <a>Add a Calendar Event
                                  </a>
                              </li>
                          </ul>
                      </div>

                      <div class="create-new-leade mt-2">
                          <div class="container-fluid">
                              <div class="row">
                                <form [formGroup]="calendarEventForm">
                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputD">
                                              <label for="training">Training Name</label>
                                            </div>
                                            <div class="col-lg-3">
                                              <p-dropdown [options]="trainingClassTypes" filter="true"
                                               formControlName="workEffortName"
                                              placeholder="Training Name"> </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                              <label for="exampleInputEmail1">Through Date</label>
                                            </div>
                                            <div class="col-lg-3" >
                                              <p-calendar id="exampleInputEmail1" formControlName="thruDate" 
                                              placeholder="Select Date">
                                              </p-calendar>

                                            </div>



                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInputD">
                                              <label for="fromDate">From Date</label>
                                            </div>
                                            <div class="col-lg-3">
                                              <input type="datetime-local" class="form-control" id="meeting-time"
                                               name="meeting-time"
                                              formControlName="estimatedStartDate" placeholder="From Date">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Through Date</label>
                                            </div>
                                            <div class="col-lg-3">
                                              <input type="datetime-local" class="form-control" id="meeting-time" name="meeting-time"
                                              formControlName="estimatedCompletionDate" placeholder="Through Date">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -4%;">
                                      <button class="btn btn-danger cursor-pointer ml-2 p-2 bd-highlight"
                                      (click)="cancel()">Cancel</button>
                                    <button class="btn btn-primary cursor-pointer ml-2 p-2 bd-highlight"
                                      (click)="submit()">{{isUpdate ? 'Update' : 'Add'}}</button>

                                    </div>
                                 
                              

                                </div>

                                  <!-- <div class="row">
                                    <div class="col-lg-12">
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <div class="d-flex flex-column">
                                          <label for="training">Training Name</label>
                                          <p-dropdown [options]="trainingClassTypes" filter="true" formControlName="workEffortName"
                                            placeholder="Training Name"> </p-dropdown>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <label for="description">Description <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" placeholder="Description" formControlName="description">
                                        <small class="text-danger"
                                          *ngIf="!!calendarEventForm.controls.description.invalid && !!calendarEventForm.controls.touched">Description
                                          is required.</small>
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <label for="fromDate">From Date</label>
                                        <input type="datetime-local" class="form-control" id="meeting-time" name="meeting-time"
                                          formControlName="estimatedStartDate" placeholder="From Date">
                                      </div>
                                    </div>
                                    <div class="col-md-6">
                                      <div class="form-group">
                                        <label for="fromDate">Through Date</label>
                                        <input type="datetime-local" class="form-control" id="meeting-time" name="meeting-time"
                                          formControlName="estimatedCompletionDate" placeholder="Through Date">
                                      </div>
                                    </div>
                                    <div class="col-md-12">
                                      <div class="d-flex flex-row-reverse bd-highlight">
                                        <button class="btn btn-danger cursor-pointer ml-2 p-2 bd-highlight"
                                          (click)="cancel()">Cancel</button>
                                        <button class="btn btn-primary cursor-pointer ml-2 p-2 bd-highlight"
                                          (click)="submit()">{{isUpdate ? 'Update' : 'Add'}}</button>
                                      </div>
                                    </div>
                  
                  
                                  </div> -->
                                </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<ngx-spinner></ngx-spinner> 