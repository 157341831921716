<div class="container-fluid main-container-wrapper">
    
  
    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Payments</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>

    <div class=" bg-white color-grey">
     
        <div class="w3-card-4 classCard classCardType">
            <div class="container-fluid" *ngIf="this.agreementType=='payable'">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                    <li [ngClass]="activeCategory==4?'active':''" (click)="main()"><a>
                        Payables Summary </a></li>
                    <li [ngClass]="activeCategory==1?'active':''" (click)="enterBills()"><a>
                    Enter Bills </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" (click)="payableMakePayment()"><a>
                    Make Payments</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="payableMakeGroupPayment()"><a>
                        Make Group Payments</a></li>
                </ul>
            </div>
            <div class="container-fluid" *ngIf="this.agreementType=='receiveable'">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                    <li [ngClass]="activeCategory==1?'active':''" (click)="salesInvoice()"><a>
                    Sales Invoice </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" (click)="receivePayment()"><a>
                    Receive Payments</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="receiveGroupPayments()"><a>
                        Receive Group Payments</a></li>
                </ul>
            </div>

            <div class="create-new-leade mt-2">
                <div class="container-fluid">


                    <div class="panel-group">
                        <div *ngIf="activeCategory==2">


                            <div class="panel-collapse">


                                <div class="">

                                    <div>
                                        <div class="container-fluid">
                                            <form class="w-100" [formGroup]="searchPaymentForm">
                                                <div class="">

                                                    <div class="w3-card-4  w-100">
                                                        <div class="accordion classCard" id="myAccordion">
                                                            <div class="accordion-item">
                                                                <h2 class="accordion-header" id="headingOne">
                                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                        <button data-toggle="modal" data-target="#exampleModalCenter" type="button" class="btn btn-outline-secondary">Create New Make Payment
                                                                        </button>
                                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 73%!important; padding-top: 11px !important;
                                                                            padding-bottom: 11px !important;">
                                                                        </button>									
                        
                                                                    </div>
                                
                                                                </h2>
                                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                    <div class="card-body">
                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Payment Id</label>
                                                                                    </div>
                
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="paymentIdSearchType">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Payment Id" aria-describedby="emailHelp" formControlName="paymentId">
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Comments</label>
                                                                                    </div>
                
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="commentsSearchType">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="comments" placeholder="Comments">
                                                                                    </div>
                
                
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Payment Type</label>
                                                                                    </div>
                
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown [options]="paymentTypeList" placeholder="Select Payment Type" optionlabel="label" formControlName="paymentType">
                                                                                        </p-dropdown>
                                                                                    </div>
                
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">From Party Id</label>
                                                                                    </div>
                
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown [options]="partyIds" placeholder="Select Party ID" optionlabel="label" formControlName="fromPartyId">
                                                                                        </p-dropdown>
                                                                                    </div>
                
                
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">To Party Id</label>
                                                                                    </div>
                
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown [options]="partyIds" placeholder="Select Party ID" optionlabel="label" formControlName="toPartyId">
                                                                                        </p-dropdown>
                                                                                    </div>
                
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Amount</label>
                                                                                    </div>
                
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="amount" aria-describedby="emailHelp" placeholder="Amount">
                                                                                    </div>
                
                
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                
                                                                                    <div class="col-lg-3 form-group classInput">
                                                                                        <label for="exampleInputEmail1">Status</label>
                                                                                    </div>
                
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown [options]="statusList" placeholder="Select Status" formControlName="status" optionlabel="label">
                                                                                        </p-dropdown>
                                                                                    </div>
                
                
                
                                                                                </div>
                                                                            </div>
                
                
                                                                        </div>

                                                                        <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -29%;">
                                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getAllPaymentList()">Find</button>&nbsp;
                                                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                        </div>
                                                                        
                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>

                                                    <!-- <div class="w3-card-4 classCard">

                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">

                                                            <button data-toggle="modal" data-target="#exampleModalCenter" type="button" class="btn btn-outline-secondary">Create New Make Payment
                                                            </button>

                                                        </div><br>
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Payment Id</label>
                                                                    </div>

                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="paymentIdSearchType">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Payment Id" aria-describedby="emailHelp" formControlName="paymentId">
                                                                    </div>
                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Comments</label>
                                                                    </div>

                                                                    <div class="col-lg-2" style="display: none;">
                                                                        <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label" formControlName="commentsSearchType">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="comments" placeholder="Comments">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Payment Type</label>
                                                                    </div>

                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="paymentTypeList" placeholder="Select Payment Type" optionlabel="label" formControlName="paymentType">
                                                                        </p-dropdown>
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">From Party Id</label>
                                                                    </div>

                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="partyIds" placeholder="Select Party ID" optionlabel="label" formControlName="fromPartyId">
                                                                        </p-dropdown>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">To Party Id</label>
                                                                    </div>

                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="partyIds" placeholder="Select Party ID" optionlabel="label" formControlName="toPartyId">
                                                                        </p-dropdown>
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInputA">
                                                                        <label for="exampleInputEmail1">Amount</label>
                                                                    </div>

                                                                    <div class="col-lg-2">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="amount" aria-describedby="emailHelp" placeholder="Amount">
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Status</label>
                                                                    </div>

                                                                    <div class="col-lg-2">
                                                                        <p-dropdown [options]="statusList" placeholder="Select Status" formControlName="status" optionlabel="label">
                                                                        </p-dropdown>
                                                                    </div>



                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -29%;">
                                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="getAllPaymentList()">Find</button>&nbsp;
                                                            <button type="button" class="btn btn-danger ml-2" (click)="resetForm()">Reset</button>
                                                        </div>
                                                    </div> -->
                                                    <br>
                                                    
                                                    <div class="w3-card-4 classCard" style="width: 100%;">
                                                        <header class="w3-container w3-blue">
                                                            <div class="header-tabs">
                                                                <h4 class=" common-styling h4Margin">
                                                                    Payment List
                                                                </h4>

                                                            </div>
                                                        </header>
                                                        <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">

                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="paymentList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Payment Id
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                        <div style="color: white;"> Payment Type
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="category">
                                                                                        <div style="color: white;"> Status
                                                                                            <p-sortIcon field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="quantity">
                                                                                        <div style="color: white;"> Comments
                                                                                            <p-sortIcon field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> From Party
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> To Party
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Effective Date
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Amount
                                                                                            <p-sortIcon field="price">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Outstanding amount
                                                                                            <p-sortIcon field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Created Date</div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <!--   <th [ngStyle]="{'width':'190px'}" pSortableColumn="price"><div  style="color: white;"> Action</div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </th> -->
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            <span class="account-button" routerLink="/financial/payable/payment-payables/summary-payable-payment" [queryParams]="{paymentId:product.paymentId}">{{product.paymentId}}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">{{product.paymentType}}</td>
                                                                                    <td [ngStyle]="{'width':'190px'}">{{product.status}}</td>
                                                                                    <td [ngStyle]="{'width':'190px'}">{{product.comments}}</td>
                                                                                    <td [ngStyle]="{'width':'190px'}">{{product.fromParty}}</td>
                                                                                    <td [ngStyle]="{'width':'190px'}">{{product.toParty}}</td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.effectiveDate | date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.amount |currency :'USD'}}</td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.outstandingAmount |currency :'USD'}}</td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                                    <!--   <td [ngStyle]="{'width':'190px'}">
                                                                                                    <fa-icon [icon]="faEdit"  class="account-button" 
                                                                                                        *ngIf="product.statusId==='PMNT_NOT_PAID'"
                                                                                                        routerLink="/financial/payable/payment-payables/create-payable-payment"
                                                                                                        [queryParams]="{paymentId:product.paymentId}"></fa-icon>
                                                                                                </td> -->
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                                    </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Outgoing Payment</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                            Create Outgoing Payment</a></li>



                            </ul>
                        </div>

                        <div class="">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="payablePaymentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Organization Party ID
                                                            <span
                                                            class="text-danger">*</span>
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party" [options]="array" formControlName="partyIdFrom" optionlabel="label" placeholder="Select Party ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">To Party ID
                                                        <span class="text-danger">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party" [options]="partyIds" optionlabel="label" placeholder="Select PartyID" formControlName="partyIdTo">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!payablePaymentForm.controls.partyIdTo.invalid && !!payablePaymentForm.controls.partyIdTo.touched">To
                                                    Party ID is required</small>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Payment Type</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party" [options]="paymentTypeList" optionlabel="label" placeholder="Select Payment Type" formControlName="paymentTypeId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Payment Method ID
                                                            <span
                                                            class="text-danger">*</span>
                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party" [options]="paymentMethods" optionlabel="label" placeholder="Select Payment Method ID" formControlName="paymentMethodId">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1"> Override Gl Account ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party" [options]="GIAccounts" optionlabel="label" formControlName="overrideGlAccountId" placeholder="Select Override Gl Account ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Amount <span
                                                        class="text-danger">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="amount" aria-describedby="emailHelp" placeholder="Amount">

                                                        <small class="text-danger" *ngIf="!!payablePaymentForm.controls.amount.invalid && !!payablePaymentForm.controls.amount.touched">Amount
                                                is required</small>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="comments" aria-describedby="emailHelp" placeholder="Comments">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Reference No</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="paymentRefNum" aria-describedby="emailHelp" placeholder="Reference No">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12" *ngIf="editMode">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1"> Currency</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party" [options]="currencyUomID" optionlabel="label" formControlName="currencyUomId" placeholder="Select Currency">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Actual Currency Amount</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="actualCurrencyAmount" aria-describedby="emailHelp" placeholder="Actual Currency Amount">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12" *ngIf="editMode">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1"> Actual Currency Uom ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" name="party" [options]="currencyUomID" optionlabel="label" formControlName="actualCurrencyUomId" placeholder="Select Currency">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="effectiveDate">Effective Date</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-calendar placeholder="Select Date" formControlName="effectiveDate"></p-calendar>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12" *ngIf="editMode">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">File Account Trans ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="finAccountTransId" aria-describedby="emailHelp" placeholder="File Account Trans Id">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Status Id</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p>{{payablePaymentForm.get('statusId').value}}</p>
                                                    </div>


                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="
                                    margin-left: -4%;">
                                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn " (click)="submit()">{{editMode ? 'Update':'Create'}}</button>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>