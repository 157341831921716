import { Component, OnInit } from '@angular/core';
import {CreateQuetoFormService } from './create-quotes-service';
import { QuotesService } from '../quotes.service';
import {ActivatedRoute} from '@angular/router';
import {Router} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyContactsService } from '../../contacts/my-contacts/my-contacts.service';
import { quoteNotesService } from "src/app/quotesNotes/quote-notes.service";
import Swal from 'sweetalert2'
declare var $: any;
@Component({
  selector: 'app-create-quotes',
  templateUrl: './create-quotes.component.html',
  styleUrls: ['./create-quotes.component.css'],
  providers:[DatePipe]
})
export class CreateQuotesComponent implements OnInit {
  currencyArray:any=[];
  faEdit = faEdit;
  faTrash = faTrash;
  setCurrency: any;
  activeCategory = 2;
  quotesId: any;
  resultData: any;
  show= false;
  productStores: any;
  productStore: any;
  productArray: any=[];
  salesChannelArray: any=[];
  quoteTypesArray: any=[];
  statusList: any;
  statusArray: any=[];
  partydata: any;
  partyData: any;
  partyarray: any=[];
  statusIds: any;
  statusArrays: any=[];
  resQuotesId: any;
  quoteNotes: any;
  editMode: boolean;
  fileUploadForm: FormGroup;
  partyId: any;

  noteId: string;
  showNotes: boolean= false;
  constructor(public createQuetoFormService: CreateQuetoFormService,
    readonly quotesService:QuotesService,
    readonly activatedRoute:ActivatedRoute,
    readonly router:Router,
    readonly toastr:ToastrService,
    readonly spinner:NgxSpinnerService,
    readonly formBuilder: FormBuilder,
    readonly quoteNotesService: quoteNotesService,
     readonly myContactsService: MyContactsService,
    readonly datePipe:DatePipe
    ) { this.editMode = false;
      this.noteId = '';
      this.fileUploadForm = this.formBuilder.group({
        noteName: ['', [Validators.required]],
        noteInfo: ['', [Validators.required]],
      }); }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getQuotoId();
    this.getStatusList();
    this.getstatusId();
    this.activatedRoute.queryParams.subscribe(params=> {
      this.resQuotesId = params['quotoId'];
      
    })
    this.getCurrency();
    this.getPartyId();
    this.editMode=false;
    this.createQuetoFormService.create_quetos_form = null;
    this.createQuetoFormService.prepareQuetosForm(this.createQuetoFormService.create_quetos_form);
    $('.right-arrow').click(function(){
      $(this).toggleClass('rotate-up');
    });

    if(this.quotesId) {
      this.show = true;
      return this.getQuetoDetail();
     } else {
      this.show = false;
    //  throw "invalid Id"
     }

   //  this.getQuoteNotes();

  }
  changeactiveCategory(tab: number)
{
  this.activeCategory = tab;

}
  onSubmitNote(): void {
    this.spinner.show();
    if (this.editMode) {
      this.update();
    } else {
      if (this.fileUploadForm.valid) {
        const form = { ...this.fileUploadForm.value }
        this.quoteNotesService.createQuoteNote(this.resQuotesId, form).then(res => { 
          if (res.success) {
            this.toastr.success(res.data.successMessage);
            this.spinner.hide();
            this.getQuoteNotes();
            this.fileUploadForm.reset();
          }
        });
      }
    }
    this.spinner.hide();
  }
  edit(noteId){
    this.noteId=noteId;
      this.quoteNotesService.getQuoteNoteById(this.noteId)
        .then(data => {
          if (data.success) {
            this.fileUploadForm.patchValue(data.data);
            this.editMode = true;
          }
        });
    
  }
  update(): void {
    this.spinner.show();
    if (this.fileUploadForm.valid) {
      this.quoteNotesService.updateQuoteNote(this.noteId, this.resQuotesId, this.fileUploadForm.value)
        .then(data => {
          if (data.success) {
            this.toastr.success('Updated');
            this.fileUploadForm.reset();
            this.spinner.hide();
            this.getQuoteNotes();
            this.editMode = false;
          }
        });
    }
    this.spinner.hide();
  }
  getCurrency() {
    this.spinner.show();
    this.quotesService.getCurrency().subscribe(res=> {
      this.setCurrency = res.data.currencyList;
      this.spinner.hide();
     this.setCurrency.forEach(currencyList=> {
       this.currencyArray.push({
         label:currencyList.description + "-" +  currencyList.uomId,
         value:currencyList.uomId

       })
     })
      })
     
   }
   
   getStatusList(){
    this.spinner.show();
     this.quotesService.getstatusList().subscribe(res=> {
       this.statusList = res.data;
       this.spinner.hide();
       for(const value of this.statusList){
         this.statusArray.push({
           label:value.statusId,
           value:value.description
         })
       }

     })
    
   }
   getQuotoId(){
    this.spinner.show();
    this.quotesService.getQuotoId().subscribe(res=> {
      this.productStore = res.data;
      this.spinner.hide();
      for(const data of this.productStore.productStores){
        this.productArray.push({
          label:data.storeName,
          value:data.productStoreId,
        })
      }
      for(const data of this.productStore.salesChannels){
       this.salesChannelArray.push({
         label:data.description,
         value:data.enumId,
       })
     }
     for(const data of this.productStore.quoteTypes){
       this.quoteTypesArray.push({
         label:data.description,
         value:data.quoteTypeId,
       })
     }
    })
   
  }
    getQuetoDetail() {
      this.spinner.show();
      this.quotesService.getQuetoDetail(this.quotesId).subscribe(res=> {
       
        this.resultData = res.data;
        this.spinner.hide();
        const form = this.createQuetoFormService.create_quetos_form;
        form.patchValue({
          description: res.data.description,
          productStoreId:res.data.productStoreId,
          quoteName:res.data.quoteName,
          partyId:res.data.partyId,
          validFrom:this.datePipe.transform(res.data.validFromDate, "yyyy-MM-dd"),
          validThrough:this.datePipe.transform(res.data.validThruDate, "yyyy-MM-dd"),
          quoteIssueDate:this.datePipe.transform(res.data.issueDate, "yyyy-MM-dd"),
          currencyId:res.data.currencyUomId,
          salesId:res.data.salesChannelEnumId, 
          statusId:res.data.statusId,
          quoteId:res.data.quoteId,
          quoteTypeId:res.data.quoteTypeId
        })
       
      })
     
   }


  onSubmit() {
    this.spinner.show();
    this.createQuetoFormService.create_quetos_form.markAllAsTouched();
    const formValues = {...this.createQuetoFormService.create_quetos_form.value };
    this.quotesService.postQuotes(formValues).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success("Submitted Successfully");
        this.resQuotesId = res.data.quoteId;
        this.router.navigate(['/order/create-quotes'], { queryParams: { quotoId: this.resQuotesId} })
        this.showNotes = true;
        this.getQuoteNotes();
        this.spinner.hide();
      }
      else if(res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();
      }
    },(err)=> {
        if(err.error.success == false) {
          this.spinner.hide();
          if(err.error.errors)
          {
            for(const value of err.error.errors){
              this.toastr.error(value.fieldName + " " + value.fieldError);
              }
          }else if(err.error.success == false){
            this.toastr.error(err.error.message);
          }
        }
      })
      
  }
  navigate(){
    this.router.navigate(["/order/quotations"]);
  }
  getPartyId(){
    this.spinner.show();
    this.quotesService.getPartygroup().subscribe(res=> {
      this.partyData = res.data.PersonsGroups;
      this.spinner.hide();
      for(const value of this.partyData){
        this.partyarray.push({
          label:value.partyId,
          value:value.partyId
        })
      }
    })
 
  }
  getstatusId(){
    this.spinner.show();
    this.quotesService.status().subscribe(res=> {
       this.statusIds = (res.data.status);
       this.spinner.hide();
       for(const value of this.statusIds){
         this.statusArrays.push({
           label:value.description,
           value:value.statusId
         })
       }
    })
   
  }
  cancelQuote(){
    this.router.navigate(["/order/quotations"]);
  }
  onUpdate() {
    this.spinner.show();
    const formValues = {...this.createQuetoFormService.create_quetos_form.value };
    this.quotesService.updateQuotes(formValues,this.quotesId).subscribe((res:any)=> {
      if(res.success) {
        this.toastr.success("Form Updated Successfully");
        this.spinner.hide();
        this.router.navigate(["/order/quotations"]);
      }
       },(err)=> {
        if(err.error.success == false) {
          if(err.error.errors)
          {
            this.spinner.hide();
            for(const value of err.error.errors){
              this.toastr.error(value.fieldName + " " + value.fieldError);
              }
          }else if(err.error.success == false){
            this.spinner.hide();
            this.toastr.error(err.error.message);
          }
        }
      })
     
  }
  getQuoteNotes(): void {
    this.spinner.show();
    this.quotesService.quoteNoteList(this.resQuotesId)
      .then(data => {
        this.spinner.hide();
        if (data.success) {
          this.quoteNotes = data.data;
        }
      });
     
  }
  deleteNote(noteId: string): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.quoteNotesService.deleteQuoteNote(noteId)
      .then(data => {
        if (data.success) {
          this.toastr.success('Deleted');
          this.getQuoteNotes();
        }
      });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    }) }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
