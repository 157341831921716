import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ThrowStmt } from '@angular/compiler';
@Component({
  selector: 'app-work-effort-products',
  templateUrl: './work-effort-products.component.html',
  styleUrls: ['./work-effort-products.component.css']
})
export class WorkEffortProductsComponent implements OnInit {
  activeCategory = 15;
  addProduct: any;
  updateProduct: any;
  total = 0;
  @ViewChild('closebutton') closebutton;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  workEffortId: any;
  WorkEffortGoodStandardId: any;
  show: boolean;
  ProductId: any;
  ProductIdArray: any[]=[];
  WorkEffortGoodStdTypeID: any;
  WorkEffortGoodStdTypeIDArray: any[]=[];
  available: { label: string; value: string; }[];
  actualfromdate: string;
  thrufromdate: string;
  getEstimateFrom: string;
  thruEstimateFrom: string;
  updateFromDate: any;
  userPermissions: any;
    Hidebtn: boolean=false;
    HideCrudbtn: boolean=false;
  constructor( public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly addSkillService: AddSkillService,
    readonly accountsService: AccountsService,
    private _location: Location,
    
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router){
      this.available=[{
        'label':'Cancelled',
        'value':'WEGS_CANCELLED'
      },
      {
        'label':'Completed',
        'value':'WEGS_COMPLETED'
      },
      {
        'label':'Created',
        'value':'WEGS_CREATED'
      }]
    this.addProduct = this._FormBuilder.group({
      productID: [''],
      goodStdTypeID: [''],
      fromDate: [''],
      throughDate: [''],
      status: [''],
      estimatedQuantity: [''],
      estimatedCost: [''],
    })

    this.updateProduct = this._FormBuilder.group({
      productID: [''],
      goodStdTypeID: [''],
      fromDate: [''],
      throughDate: [''],
      status: [''],
      estimatedQuantity: [''],
      estimatedCost: [''],
    })


  }

  ngOnInit(): void {
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params['workEffortId'];

    });
    this.getWorkEffortGoodStandardId();
    this.getWorkEffortGoodStdTypeID();
    this.getProductId();
  }
  
  getProductId() {
    this.spinner.show();
    this.accountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
   
  }
  getWorkEffortGoodStdTypeID() {
    this.spinner.show();
    this.accountsService.getWorkEffortGoodStdTypeID().subscribe(res => {
      this.WorkEffortGoodStdTypeID = res.data;
      this.spinner.hide();
      for (const value of this.WorkEffortGoodStdTypeID) {
        this.WorkEffortGoodStdTypeIDArray.push({
          label: value.workEffortGoodStdTypeDes[0].description,
          value: value.workEffortGoodStdTypeDes[0].workEffortGoodStdTypeId
        })
      }
    })
   
  }
  
  removeWorkEffortGoodStandard(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "fromDate":product.fromDate,
          "productId": product.workEffortGoodStandardId.productId,
          "view_INDEX_1": "",
          "view_SIZE_1": "",
          "workEffortGoodStdTypeId": product.workEffortGoodStandardId.workEffortGoodStdTypeId,
          "workEffortId":this.workEffortId
        }
        this.accountsService.removeWorkEffortGoodStandard(req).subscribe(res => {
          this.getWorkEffortGoodStandardId(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  getWorkEffortGoodStandardId(){
    this.spinner.show();
   
    this.accountsService.getWorkEffortGoodStandardId(this.workEffortId).subscribe((res:any)=> {
      this.WorkEffortGoodStandardId = res.data;
      this.spinner.hide();
    
    })   
   }
   
   createWorkEffortGoodStandard(): void {
    this.spinner.show();
    const fromActualdate=this.addProduct.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const thruActualdate=this.addProduct.get('throughDate').value;
    this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "estimatedCost": this.addProduct.value.estimatedCost,
      "estimatedQuantity": this.addProduct.value.estimatedQuantity,
      "fromDate":  this.actualfromdate,
      "productId": this.addProduct.value.productID,
      "statusId": this.addProduct.value.status,
      "thruDate":  this.thrufromdate,
      "workEffortGoodStdTypeId": this.addProduct.value.goodStdTypeID,
      "workEffortId": this.workEffortId  
   
      }
     
    this.accountsService.createWorkEffortGoodStandard(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getWorkEffortGoodStandardId(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    
    updateWorkEffortGoodStandard(): void {
      this.spinner.show();
      const fromActualdate=this.addProduct.get('fromDate').value;
      this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
      const thruActualdate=this.addProduct.get('throughDate').value;
      this.thrufromdate=this.datePipe.transform(thruActualdate,"yyyy-MM-dd hh:mm:ss");
      const requestData ={
        "estimatedCost": this.addProduct.value.estimatedCost,
        "estimatedQuantity": this.addProduct.value.estimatedQuantity,
        "fromDate":   this.updateFromDate,
        "productId": this.addProduct.value.productID,
        "statusId": this.addProduct.value.status,
        "thruDate":  this.thrufromdate,
        "workEffortGoodStdTypeId": this.addProduct.value.goodStdTypeID,
        "workEffortId": this.workEffortId  
     
        }
       
      this.accountsService.updateWorkEffortGoodStandard(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.getWorkEffortGoodStandardId(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
    updateEvent(product){
      this.show=true;
      this.addProduct.reset();
      this.spinner.show();
     
        this.updateFromDate=product.fromDate;
        setTimeout(() => {
          const fromdate=product.workEffortGoodStandardId.fromDate;
          this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
          const thrudate=product.workEffortGoodStandardId.thruDate;
          this.thruEstimateFrom=this.datePipe.transform(thrudate,"yyyy-MM-dd");
          const formValue = this.addProduct;
          formValue.patchValue({
            productID:product.workEffortGoodStandardId.productId,
            goodStdTypeID:product.workEffortGoodStandardId.workEffortGoodStdTypeId,
            fromDate: this.getEstimateFrom,
            throughDate: this.thruEstimateFrom,
            status: product.workEffortGoodStandardId.statusId,
            estimatedQuantity:product.workEffortGoodStandardId.estimatedQuantity,
            estimatedCost:product.workEffortGoodStandardId.estimatedCost,
          })
        
        }, 2000);
    
    }
  addWorkEffort(){
    this._Router.navigate(["/psa/work-effort/add-work-effort"])
  }
  editWorkEffort1(){
    this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  summary(){
    this._Router.navigate(["/psa/work-effort/task-list-view/summary"],{ queryParams: { workEffortId: this.workEffortId} })
  }

  childWorkEfforts(){
    this._Router.navigate(["/psa/work-effort/task-list-view/childWorkEfforts"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  partyAssigns(){
    this._Router.navigate(["/psa/work-effort/task-list-view/PartyAssigns"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  rates(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Rates"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  commEvents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/CommEvents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  shopLists(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ShopLists"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requests(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requests"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  requirements(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Requirements"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  quotes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Quotes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  orderHeaders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/OrderHeaders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  timeEntries(){
    this._Router.navigate(["/psa/work-effort/task-list-view/TimeEntries"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  notes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Notes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contents(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Contents"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  products(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Products"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  reviews(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Reviews"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  keywords(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Keywords"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  contactMechs(){
    this._Router.navigate(["/psa/work-effort/task-list-view/ContactMechs"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  agreementApplics(){
    this._Router.navigate(["/psa/work-effort/task-list-view/AgreementApplics"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  fixedAssets(){
    this._Router.navigate(["/psa/work-effort/task-list-view/FixedAssets"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  attributes(){
    this._Router.navigate(["/psa/work-effort/task-list-view/Attributes"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  eventReminders(){
    this._Router.navigate(["/psa/work-effort/task-list-view/EventReminders"],{ queryParams: { workEffortId: this.workEffortId} })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  reset(){
    this.show=false;
    this.addProduct.reset();
  }
}
