import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-supply-dashboard',
  templateUrl: './supply-dashboard.component.html',
  styleUrls: ['./supply-dashboard.component.css']
})
export class SupplyDashboardComponent implements OnInit {
  activeCategory=2;
  activeCategoryValue=2;
  checkPOSToken: string;
  productStoreIdNew: string;
  posTerminalIdNew: string;
  constructor(
    readonly router: Router,
    private _location: Location,

  ) { }

  ngOnInit(): void {
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  logoutPOS() {
    this.checkPOSToken = localStorage.getItem('posToken');
    if(this.checkPOSToken){
      this.productStoreIdNew = localStorage.getItem('productStoreId');
      this.posTerminalIdNew = localStorage.getItem('posTerminalId');
      this.router.navigate(["/webpos/control/main"],{queryParams:{ posTerminalId :  this.posTerminalIdNew, productStoreId:this.productStoreIdNew}});
    }
    else{
      localStorage.removeItem("token");
      localStorage.removeItem('posToken');
      localStorage.removeItem('productStoreId');
      localStorage.removeItem('posTerminalId');
      localStorage.removeItem('userLoginId');
      this.router.navigate(["/login"],{queryParams:{ webPos :  "webPos"}});
    }
   

  }

}
