<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels">Accounting Manager Main Page</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn mr-2"
                        (click)="toDashboard()">Home</button>
                    <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">

                <div class="w3-card-4 classCard m-0">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass mt-0"
                            style="padding-left:9px; width: 102%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategoriess==1?'active':''" (click)="mainPage()">
                                <a>
                                    Main </a>
                            </li>

                            <li [ngClass]="activeCategoriess==2?'active':''" (click)="accountingPage()">
                                <a>
                                    Accounting </a>
                            </li>
                            <li [ngClass]="activeCategoriess==3?'active':''" (click)="invoicePage()">
                                <a>
                                    Invoices</a>
                            </li>
                            <li [ngClass]="activeCategoriess==4?'active':''" (click)="paymentPage()">
                                <a>
                                    Payments </a>
                            </li>
                            <li [ngClass]="activeCategoriess==5?'active':''" (click)="paymentGrpPage()">
                                <a>
                                    Payment group</a>
                            </li>
                            <li [ngClass]="activeCategoriess==6?'active':''" (click)="billingAccountPage()">
                                <a>
                                    Billing Account
                                </a>
                            </li>
                            <li [ngClass]="activeCategoriess==7?'active':''" (click)="agreementPage()">
                                <a>
                                    Agreements</a>
                            </li>
                            <li [ngClass]="activeCategoriess==8?'active':''" (click)="commisionReportPage()">
                                <a>
                                    Commission Report</a>
                            </li>
                        </ul>
                    </div>

                    <div class="w3-card-4 classCard m-2">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass mt-0"
                                style="padding-left:9px; width: 102%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                                <li [ngClass]="activeCategory==1?'active':''" (click)="mainPage()">
                                    <a>
                                        Agreements </a>
                                </li>

                                <li [ngClass]="activeCategory==2?'active':''" (click)="mainBillingAccountPage()">
                                    <a>
                                        Billing Accounts </a>
                                </li>
                                <li [ngClass]="activeCategory==3?'active':''" (click)="mainFixedAssetPage()">
                                    <a> Fixed Assets
                                    </a>
                                </li>
                                <li [ngClass]="activeCategory==4?'active':''" (click)="mainInvoicePage()">
                                    <a>
                                        Invoices </a>
                                </li>
                                <li [ngClass]="activeCategory==5?'active':''" (click)="mainPaymentPage()">
                                    <a>
                                        Payment </a>
                                </li>

                            </ul>
                        </div>

                        <div class="w3-card-4 classCard m-3">
                            <div class="container-fluid">

                                <ul class="tabbing-section tabbing-accordians tabClass mt-0"
                                    style="padding-left:9px; width: 102%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                                    <li [ngClass]="activeCategoryInvoice==1?'active':''"
                                        (click)="changeActiveCategoryInvoice(1)">
                                        <a>
                                            Show All Invoices </a>
                                    </li>

                                    <li [ngClass]="activeCategoryInvoice==2?'active':''"
                                        (click)="changeActiveCategoryInvoice(2)">
                                        <a>
                                            Show Invoices Commission </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==3?'active':''"
                                        (click)="changeActiveCategoryInvoice(3)">
                                        <a> Show Invoices Customer Return
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==4?'active':''"
                                        (click)="changeActiveCategoryInvoice(4)">
                                        <a> Show Invoices Interest
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==5?'active':''"
                                        (click)="changeActiveCategoryInvoice(5)">
                                        <a> Show Invoices Invoice
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==6?'active':''"
                                        (click)="changeActiveCategoryInvoice(6)">
                                        <a> Show Invoices Payroll
                                        </a>
                                    </li>

                                    <li [ngClass]="activeCategoryInvoice==7?'active':''"
                                        (click)="changeActiveCategoryInvoice(7)">
                                        <a> Show Invoices Purchase Invoice
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==8?'active':''"
                                        (click)="changeActiveCategoryInvoice(8)">
                                        <a> Show Invoices Purchase Return
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==9?'active':''"
                                        (click)="changeActiveCategoryInvoice(9)">
                                        <a> Show Invoices Purchase Invoice Template
                                        </a>
                                    </li>

                                    <li [ngClass]="activeCategoryInvoice==10?'active':''"
                                        (click)="changeActiveCategoryInvoice(10)">
                                        <a> Show Invoices Sales Invoice
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==11?'active':''"
                                        (click)="changeActiveCategoryInvoice(11)">
                                        <a> Show Invoices Sales Invoice Template
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==12?'active':''"
                                        (click)="changeActiveCategoryInvoice(12)">
                                        <a> Show Invoices Invoice Template
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==13?'active':''"
                                        (click)="changeActiveCategoryInvoice(13)">
                                        <a> Show Invoices Approved
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==14?'active':''"
                                        (click)="changeActiveCategoryInvoice(14)">
                                        <a> Show Invoices Cancelled
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==15?'active':''"
                                        (click)="changeActiveCategoryInvoice(15)">
                                        <a> Show Invoices In-Process
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==16?'active':''"
                                        (click)="changeActiveCategoryInvoice(16)">
                                        <a> Show Invoices Paid
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==17?'active':''"
                                        (click)="changeActiveCategoryInvoice(17)">
                                        <a> Show Invoices Ready for Posting
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==18?'active':''"
                                        (click)="changeActiveCategoryInvoice(18)">
                                        <a> Show Invoices Received
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==19?'active':''"
                                        (click)="changeActiveCategoryInvoice(19)">
                                        <a> Show Invoices Sent
                                        </a>
                                    </li>
                                    <li [ngClass]="activeCategoryInvoice==20?'active':''"
                                        (click)="changeActiveCategoryInvoice(20)">
                                        <a> Show Invoices Write Off
                                        </a>
                                    </li>


                                </ul>
                            </div>
                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div>

                                            <div class=" bg-white color-grey">
                                                <div *ngIf="activeCategoryInvoice==1">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show All Invoices
                                                            </h4>
                                                            <button type="submit"
                                                            class="btn btn-secondary submit-btn ml-2 fontMenu"
                                                              (click)="createPayment()">Create</button>

                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">                               
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategoryInvoice==2">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Commission
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategoryInvoice==3">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Customer Return
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategoryInvoice==4">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Interest
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>
 
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategoryInvoice==5">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Invoice
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategoryInvoice==6">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Payroll
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategoryInvoice==7">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Purchase Invoice
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategoryInvoice==8">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Purchase Return
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategoryInvoice==9">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Purchase Invoice Template
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategoryInvoice==10">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Sales Invoice
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategoryInvoice==11">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Sales Invoice Template
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategoryInvoice==12">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Invoice Template
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                 <div *ngIf="activeCategoryInvoice==13">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Approved
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                 <div *ngIf="activeCategoryInvoice==14">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Cancelled
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategoryInvoice==15">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices In-Process
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                 <div *ngIf="activeCategoryInvoice==16">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Paid
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                 <div *ngIf="activeCategoryInvoice==17">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Ready for Posting
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                 <div *ngIf="activeCategoryInvoice==18">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Received
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategoryInvoice==19">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Sent
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategoryInvoice==20">
                                                    <div class="w3-card-4 classCardView">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                                Show Invoices Write Off
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="row">
                                                                        <form class="w-100"
                                                                            [formGroup]="searchInvoiceForm">
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                ID</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Invoice Id"
                                                                                                formControlName="invoiceId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Description</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="description"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Description">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Type</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="invoiceTypes"
                                                                                                placeholder="Choose Invoice Type"
                                                                                                formControlName="invoiceTypeId"
                                                                                                optionlabel="label">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Status
                                                                                                ID</label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <p-dropdown filter="true"
                                                                                                name="party"
                                                                                                [options]="statusIds"
                                                                                                formControlName="statusId"
                                                                                                optionlabel="label"
                                                                                                placeholder="Select Status">
                                                                                            </p-dropdown>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">From
                                                                                                Party ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter From Party ID	"
                                                                                                formControlName="fromPartyId">
                                                                                        </div>
                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">To
                                                                                                Party ID </label>
                                                                                        </div>
                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="toPartyId"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="To Party ID	">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Billing
                                                                                                Account ID </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Billing Account ID	"
                                                                                                formControlName="billingAccountId">


                                                                                        </div>

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInputA">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Invoice
                                                                                                Date </label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="date"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                formControlName="invoiceDate"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Invoice Date">
                                                                                        </div>



                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-lg-12">
                                                                                    <div class="row">

                                                                                        <div
                                                                                            class="col-lg-3 form-group classInput">
                                                                                            <label
                                                                                                for="exampleInputEmail1">Reference
                                                                                                Number</label>
                                                                                        </div>

                                                                                        <div class="col-lg-2">
                                                                                            <input type="email"
                                                                                                id="exampleInputEmail1"
                                                                                                class="form-control"
                                                                                                aria-describedby="emailHelp"
                                                                                                placeholder="Enter Reference Number"
                                                                                                formControlName="referenceNumber">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                            </div>

                                                                            <div style=" margin-left: -29%;"
                                                                                class="col-lg-12 col-12 main-submit-button ">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                <button type="button"
                                                                                    class="btn btn-danger ml-2"
                                                                                    (click)="resetPerInvoice()">Reset</button>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="w3-card-4 classCard w-100 mt-3">
                                                        <div
                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text">Search Results
                                                            </p>
                                                        </div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="getPurchaseInvoicesData"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                            [responsive]="true" [totalRecords]="total"
                                                                            [scrollable]="true"
                                                                            styleClass="p-datatable-customers"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                            Invoice ID <p-sortIcon
                                                                                                field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Invoice Type

                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="category">
                                                                                        <div style="color: white;">
                                                                                            Invoice Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                        pSortableColumn="quantity">
                                                                                        <div style="color: white;">
                                                                                            Status
                                                                                            <p-sortIcon
                                                                                                field="quantity">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Party Id
                                                                                            <p-sortIcon
                                                                                                field="price"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="price"
                                                                                        [ngStyle]="{'width':'190px'}">
                                                                                        <div style="color: white;"> To
                                                                                            Party Id
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            style="visibility:hidden;"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>




                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>

                                                                                            <span class="account-button"
                                                                                                (click)="invoicePageDetail(product.invoice.invoiceId)">{{product.invoice.invoiceId}}</span>
                                                                                        </div>
                                                                                    </td>


                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.invoiceTypeId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.hfh}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.statusId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.description}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyIdFrom}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                        {{product.invoice.partyId}}
                                                                                    </td>
                                                                                </tr>

                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found</h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

