import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { HeaderService } from 'src/app/header/header.service';
import { AccountsService } from '../accounts/accounts.service';
import { MyContactsService } from '../contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from '../events/create-events/create-event-service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.css']
})
export class PreferencesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton3') closebutton3;
  activeCategory = 1;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  createPortal: any;
  createPortalPageList: any;
  delete: boolean;
  revertOriginal: boolean;
  showPortal: any;
  columnwidthForm: any;
  widthProduct: any;
  addCommAdmin: any;
  addCommRoles: any;
  addCommContent: any;
  managePortalPagesEmailsList: any;
  newList: any;
  eventList: any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    private _location: Location,
  ) {
    this.createPortal = this._FormBuilder.group({
      portalPageName: [''],
      description: [''],
    });
    this.columnwidthForm = this._FormBuilder.group({
      ColumnWidthPixels: [''],
      ColumnWidthPercentage: [''],
    });
    this.addCommAdmin = this._FormBuilder.group({
      partyTo: [''],
      subject: [''],
      content: [''],
    });
    this.addCommRoles = this._FormBuilder.group({
      partyID: [''],
      roleTypeID: [''],
    });
    this.addCommContent = this._FormBuilder.group({
      contentID: [''],
      uploadedFile: [''],
      parentContentID: [''],
    });
   }

  ngOnInit(): void {
    this.getCreatePortalPage();
    this.getManagePortalPagesEmailsUnKnown();
    this.getNewList();
    this.getAllEvent();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  getNewList() {
    this.spinner.show();
    this.myContactsService.getNewList().subscribe(res => {
      this.newList = res.data[0].getNewList;
      this.spinner.hide();

    })
  }

  getAllEvent() {
    this.spinner.show();
    this.accountsService.getAllEvent().subscribe(res => {
      this.eventList = res.data.communicationEvents;
      this.spinner.hide();

    })
  }

  

  removeCommunicationEvent(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.myContactsService.removeCommunicationEvent(product.communicationEventId).subscribe(res => {
        this.getNewList();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteCommunicationEvents(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "_rowSubmit": "Y",
          "_useRowSubmit": "Y",
          "communicationEventId": product.communicationEventId,
          "delContentDataResource": "Y" ,
          "entryDate": this.datePipe.transform(product.entryDate,'yyyy-MM-dd hh:mm:ss'),
          "note":product.note,
          "subject": product.subject,
          "viewIndex_2": "20",
          "viewSize_2": "0"   
        }
        this.myContactsService.deleteCommunicationEventsBy(formData).subscribe(res => {
          this.getManagePortalPagesEmailsUnKnown(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getManagePortalPagesEmailsUnKnown() {
    this.spinner.show();
    this.myContactsService.getManagePortalPagesEmailsUnKnown().subscribe(res => {
      this.managePortalPagesEmailsList = res.data[0].getManagePortalPages;
      this.spinner.hide();

    })
  }
detail(communicationEventId){
  this._Router.navigate(["/crm/Preferences/preferencesall"],
  {queryParams : {communicationEventId : communicationEventId}})
}

  PortletPage(parentPortalPageId){
    this._Router.navigate(["/crm/Preferences/viewPreferences"],
    {queryParams : {parentPortalPageId : parentPortalPageId}})
  }

  updatePortal(product) {
    this.showPortal = true;
    this.widthProduct = product ;
    this.spinner.show();

    setTimeout(() => {
      const formValue = this.createPortal;
      formValue.patchValue({
        portalPageName: product.portalPageName ,
      description: product.description ,
      })

    }, 2000);
    this.spinner.hide();
  }
  
  updatePortalPageColumnWidth() {
    this.spinner.show();
    const requestData = {
      "columnSeqId": this.widthProduct.portalPageId,
  "columnWidthPercentage":this.columnwidthForm.value.ColumnWidthPercentage,
  "columnWidthPixels":this.columnwidthForm.value.ColumnWidthPixels,
  "portalPageId":  this.widthProduct.portalPageId,
    }
    this.myContactsService.updatePortalPageColumnWidth(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.getCreatePortalPage();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deletePortalPage(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

       const req ={
          "parentPortalPageId": product.parentPortalPageId,
          "portalPageId": product.portalPageId,
          "view_INDEX_1": "20",
          "view_SIZE_1": "0"
        }
        this.myContactsService.deletePortalPage(req).subscribe(res => {
          this.getCreatePortalPage();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  getCreatePortalPage() {
    this.spinner.show();
    this.myContactsService.getCreatePortalPage().subscribe(res => {
      this.createPortalPageList = res.data[0].getCreatePortalPage;
      if(this.createPortalPageList.originalPortalPageId==null){
        this.delete = true;
      }
      else{
        this.delete = false;
      }
      this.spinner.hide();

    })
  }
  revertToOriginal(product): void {
    this.spinner.show();
    const requestData = {
      "parentPortalPageId": product.parentPortalPageId  ,
      "portalPageId":  product.portalPageId  ,
      "view_INDEX_1": "20",
      "view_SIZE_1": "0"
    }

    this.myContactsService.revertToOriginal(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.getCreatePortalPage();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  reset(){
    this.showPortal = false;
    this.createPortal.reset();
  }
 
  createPortalPage(): void {
    this.spinner.show();

    const requestData = {
      "description": this.createPortal.value.description,
      "parentPortalPageId": "SFA_MAIN",
      "portalPageName":this.createPortal.value.portalPageName,
      "sequenceNum": "1"
    }

    this.myContactsService.createPortalPage(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getCreatePortalPage();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updatePortalPage(): void {
    this.spinner.show();

    const requestData = {
      "description": this.createPortal.value.description,
      "parentPortalPageId": "SFA_MAIN",
      "portalPageName":this.createPortal.value.portalPageName,
      "sequenceNum": "1"
    }

    this.myContactsService.createPortalPage(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getCreatePortalPage();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
