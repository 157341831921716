import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-basic-invoice-setting',
  templateUrl: './basic-invoice-setting.component.html',
  styleUrls: ['./basic-invoice-setting.component.css']
})
export class BasicInvoiceSettingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
