<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12 pad_0 ">
            
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Manage Portal Pages</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>

            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCard w-100 border_wdth_1">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    <p class="h-text">Portal Pages for Applications</p>
                                    <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#createPreferencePopup">New Portal Page</button>
                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                    <div class="w3-card-4 classCard w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">
                                                    <div class="card own-account-table">
                                                        <p-table [value]="createPortalPageList" [paginator]="true"
                                                        [rows]="rows" scrollWidth="100%"
                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr style="background: #0d3769;">
                                                                <th [ngStyle]="{'width':'150px'}"
                                                                    pSortableColumn="code">
                                                                    <div class="checkbox-align"
                                                                        style="color: white;">
                                                                        <p-checkbox styleclass="custom-checkbox"
                                                                            binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        Edit
                                                                        <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'180px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Name
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'180px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Description
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'180px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Original Page
                                                                        <p-sortIcon field="name">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                             
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td [ngStyle]="{'width':'150px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox styleclass="custom-checkbox"
                                                                            binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined">
                                                                                </div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        <span (click)="updatePortal(product)"
                                                                             class="account-button"  >
                                                                            Edit
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td [ngStyle]="{'width':'180px'}">
                                                                    {{product.portalPageName}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'180px'}">
                                                                    {{product.description}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'180px'}">
                                                                    {{product.originalPortalPageId}}
                                                                </td>
                                            

                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                            <tr>
                                                                <td>
                                                                    <span class="text-center">
                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </ng-template>
                                                    </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w3-card-4 classCard w-100 mt-2">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    <p class="h-text">Edit portal page: {{this.parentPortalPageId}}</p>
                                  

                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                    <div class="w3-card-4 classCard w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <button type="submit" class="btn btn-secondary submit-btn">Add Column</button>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1"> PortalPageId:  {{this.parentPortalPageId}}</label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <button type="submit" class="btn btn-danger ">Delete Column</button>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <button type="submit" class="btn btn-secondary submit-btn"
                                                            (click)="PortletPage(this.parentPortalPageId)">Add
                                                            A Portlet...</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <button type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#columnwidth">Width: auto</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                    <div class="w3-card-4 classCardView w-100 mt-2">
                                        <div class="header-tabs" >
                                            <h4 class="common-styling hleft">
                                                Portlet(My Communication)
                                            </h4>
                                        </div>
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <p class="h-text">Communications of Party
                                                <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addCommAdminpopup" (click)="allPage()">All Communications</button>
                                                <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addCommRolespopup" (click)="notePage()">New Message</button>
                                                <button type="button" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addCommContentpopup" (click)="emailPage()">New Email</button>
                                            </p>
                                        </div>
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100" style="align-items: initial;">
                                            <div style="width: 49%; float: left;">
                                                <p class="h-text">Communication Event</p>
                                                <div class="w3-card-4 classCardView w-100 mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class=" common-styling hleft">
                                                            Communication Event
                                                        </h4>
                                                    </div>
                                                    <p style="color:#0d3769 ;font-size: 14px;font-weight: 600; margin: 10px 0 5px 10px !important;">Origin Email Address not known!</p>
                                                    <p class="h-text" style="margin: 0 0 5px 10px !important">Allocate this message to a existing or new party and add an email address</p>
                                                    <form class="w-100" [formGroup]="newCommEvent">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1">Party ID
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-5">
                                                                        <p-dropdown filter="true" optionlabel="label"
                                                                        [options]="partyIdArray" formControlName="partyID" placeholder="Enter Party ID">
                                                                        </p-dropdown>
                                                                    </div>
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1">Email Address
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-5">
                                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="email" placeholder="Enter Email Address">
                                                                    </div>
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1">First Name
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-5">
                                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="fName" placeholder="Enter First Name">
                                                                    </div>
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1">Middle Name
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-5">
                                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="mName" placeholder="Enter Middle Name">
                                                                    </div>
                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1">Last Name
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-5">
                                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="lName" placeholder="Enter Last Name">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-10 main-submit-button">
                                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="allocateMsgToPartyBy()">Add</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div class="w3-card-4 classCardView w-100 mt-2">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="color-black container">
                                                            <div class="row">
                                                                <div class="article-container" *ngIf="this.communicationEventsBYCe">
                                                                    <div class="article">
                                                                        <p class="col-lg-8 col-12">Communication Event ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                       {{this.communicationEventsBYCe.communicationEventId}}
                                                                                    </span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-8 col-12">Comm.Type ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                             {{this.communicationEventsBYCe.communicationEventTypeId}}
                                                                                    </span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-8 col-12">Parent Comm Event ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                             {{this.communicationEventsBYCe.parentCommEventId}}
                                                                                    </span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-8 col-12">From Party ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                             {{this.communicationEventsBYCe.partyIdFrom}}
                                                                                    </span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-8 col-12">To Party ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                             {{this.communicationEventsBYCe.partyIdTo}}
                                                                                    </span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-8 col-12">Status ID</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                             {{this.communicationEventsBYCe.statusId}}
                                                                                    </span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-8 col-12">Last Modified Date</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                             {{this.communicationEventsBYCe.communicationEventId}}
                                                                                    </span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-8 col-12">Send Date</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                             {{this.communicationEventsBYCe.entryDate | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                    </span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-8 col-12">End date</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                             {{this.communicationEventsBYCe.datetimeEnded | date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                    </span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-8 col-12">Subject</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                             {{this.communicationEventsBYCe.subject}}
                                                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p class="h-text" style="margin: 10px 0 5px 25px !important">Content</p>
                                                        <div class="color-black container">
                                                            <div class="row">
                                                                <div class="article-container" *ngIf="this.communicationEventsBYCe">
                                                                    <div class="article">
                                                                        <p class="col-lg-3 col-12">Content</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                             {{this.communicationEventsBYCe.content}}
                                                                                    </span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-3 col-12">Note</p>
                                                                        <span class="color-grey pl-20 col-lg-10 col-12 font-weight-bold">
                                                                                             {{this.communicationEventsBYCe.note}}
                                                                                    </span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="width: 49.8%; float: right;">
                                                <p class="h-text">Related Information</p>
                                                <div class="w3-card-4 classCardView w-100 mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class=" common-styling hleft">
                                                            Communication Event Roles
                                                        </h4>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="eventRoles" [paginator]="true"
                                                                    [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox
                                                                                        _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>Party ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Contact Mech ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Role Type ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Status ID
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox"
                                                                                    aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox
                                                                                        _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span>{{product.partyId}}</span>
                                                                                </div>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.contactMechId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.roleTypeId}}
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.statusId}}

                                                                           
                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage"
                                                                        let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                    <p class="paginate_data">View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCardView w-100 mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class=" common-styling hleft">
                                                            Communication Content
                                                        </h4>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="eventContentList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>



                                                                                        Content Name
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>



                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;">Delete
                                                                                        <p-sortIcon field="price"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td >
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        {{product.contentName}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteContent(product.communicationEventId,product.contentId,product.fromDate);" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                          <defs>
                                            <style>
                                              .a {
                                                fill: #f44336;
                                              }
          
                                              .b {
                                                fill: #fafafa;
                                                margin-left: 20px;
                                              }
                                            </style>
                                          </defs>
                                          <path class="a fill-color"
                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                          <path class="b"
                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                            transform="translate(-147.576 -147.576)" />
                                        </svg>
                                                                                </td>





                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCardView w-100 mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class=" common-styling hleft">
                                                            Child Communication Events
                                                        </h4>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="ChildCommunicationOverviewEvent" [rows]="rows" scrollWidth="100%" [scrollable]="true" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        Subject

                                                                                    </div>

                                                                                </th>
                                                                                <th [ngStyle]="{'width':'100px'}" pSortableColumn="price">
                                                                                    <div style="color: white;">
                                                                                        Type

                                                                                    </div>

                                                                                </th>
                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;">
                                                                                        Status Id

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;">
                                                                                        Party Id

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;">
                                                                                        Role Status Id

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;">
                                                                                        Created

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;">
                                                                                        Sent

                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td *ngFor="let r of product.eventRoles" [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align ml-3">
                                                                                        <span>{{r.subject}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'100px'}">

                                                                                </td>
                                                                                <td *ngFor="let r of product.eventRoles" [ngStyle]="{'width':'150px'}">
                                                                                    <span>
                                                                                        {{r.statusId}}
                                                                                    </span>


                                                                                </td>
                                                                                <td *ngFor="let r of product.eventRoles" [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <span>{{r.partyIdTo}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td *ngFor="let r of product.eventRoles" [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <span>{{r.roleTypeIdTo}}</span>
                                                                                    </div>
                                                                                </td>

                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div *ngFor="let r of product.eventRoles" class="checkbox-align">
                                                                                        <span>{{r.createdStamp |  date:'yyyy-MM-d hh:mm:ss' }}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <span></span>
                                                                                    </div>
                                                                                </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4
                                                                                            class="ml-2">
                                                                                            No Record
                                                                                            Found</h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="w3-card-4 classCardView w-100 mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class=" common-styling hleft">
                                                            Customer Request List
                                                        </h4>
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="communicationEventsReq" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
        
                                                                                        Cust Request ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Cust Request Type ID
                                                                                    </div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Product Store ID</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Cust Request Name</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Priority
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Cust Request Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Response Required Date
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        From Party ID
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Status ID</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Last Modified Date</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'100px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Reject</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'300px'}" pSortableColumn="category">
                                                                                    <div style="color: white;">
                                                                                        Create Task From Request</div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
        
        
        
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span  class="account-button"   
                                                                                       >
                                                                                            {{product.custRequestId}}</span>
        
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.custRequestTypeId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.productStoreId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.custRequestName}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.priority}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.custRequestDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.responseRequiredDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}"  class="account-button"   (click)="myProfilePage(product.data.fromPartyId)">
                                                                                    {{product.fromPartyId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.statusId}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.lastModifiedDate | date:'yyyy-MM-d hh:mm:ss' }}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'100px'}">
                                                                                    <button type="submit" (click)="rejectCustomerReq(product)" class="btn btn-secondary submit-btn ml-2"> Reject
                                                                                        </button>
        
                                                                                </td>
        
                        
        
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No
                                                                                            Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               

                                            </div>
                                           
                                        </div>
                                    </div>
                                   
                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                    <div class="w3-card-4 classCardView w-100 mt-2">
                                        <div class="">
                                            <div class="row text-center d-flex align-items-center" style="padding-top:20px">
                                                <div class="col-md-4 d-flex" style="padding-left:30px">
                                                    <div class="btn-group">
                                                        <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                                            Previous
                                                        </div>
                                                        <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                                                            Today
                                                        </div>
                                                        <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                                                            Next
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <h3 class="mb-0">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
                                                </div>
                                                <div class="col-md-4 d-flex justify-content-end" style=" padding-right:40px">
                                                    <div class="btn-group">
                                                        <div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
                                                            Month
                                                        </div>
                                                        <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
                                                            Week
                                                        </div>
                                                        <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
                                                            Day
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div [ngSwitch]="view">
                                                <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                                                </mwl-calendar-month-view>
                                                <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                                                </mwl-calendar-week-view>
                                                <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
                                                </mwl-calendar-day-view>
                                            </div>
    
                                            <!-- Everything you see below is just for the demo, you don't need to include it in your app -->
    
                                            <br /><br /><br />
    
                                            <h3>
                                                <!-- <span class="text-black" *ngIf="isForm">Add/modify a calendar event</span> -->
                                                <button class="btn btn-primary float-right" *ngIf="isButton" (click)="addEvent()">
                                                Add new
                                              </button>
                                                <div class="clearfix"></div>
                                            </h3>
                                            <ng-container *ngIf="isForm">
                                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
    
                                                    <div class="w3-card-4 classCard w-100">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                                                            <p class="h-text">Add/modify a calendar event</p>
                                                            <span>
                                                                <button type="submit" style="margin: 0 !important;"  *ngIf="isUpdate" (click)="cancelCalenderEvent()" class="btn btn-secondary submit-btn mr-2">Cancel a calendar event</button>
                                                                <button type="submit" style="margin: 0 !important;" *ngIf="isForm" (click)="addNew()" class="btn btn-secondary submit-btn">Add New</button>
                                                    </span>
                                                        </div>
                                                        <!-- <form [formGroup]="calendarEventForm1">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Event Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" formControlName="eventName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Event Name">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Desription</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" formControlName="desc" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Desription">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Event Type
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown formControlName="eventType" 
                                                                            [options]="EventTypeArray"
                                                                            placeholder="Enter Event Type" filter="true">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Status
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown formControlName="status" [options]="CalStatusListArray" placeholder="Enter Status" filter="true">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Scope
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown formControlName="scope" 
                                                                           [options]="ScopeListArray" placeholder="Enter Scope" filter="true">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Estimated Start Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" formControlName="startDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Start Date">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Estimated Completion date</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" formControlName="compDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Completion date">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-10 main-submit-button">
                                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                                (click)="submit()">{{isUpdate ? 'Update' : 'Add'}}</button>
                      
                                                            </div>
                                                        </form> -->
                                                    </div>
                                                </div>
                                                <div class="port-header-contact create-lead font-medium mb-0 w-100" *ngIf="isUpdate">
                                                    <div class="w3-card-4 classCard mt-2 w-100">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                                                            <p class="h-text">Participants</p>
                                                            <span>
                                                       </span>
                                                      <!-- <form [formGroup]="ParticipantsForm">
                                                            <div class="row">
                                                              
                                                                <div class="col-md-5 ml-4">
                                                                    <div class="form-group">
                                                                        <div class="d-flex flex-column">
                                                                            <label for="training">
                                                                                Party ID</label>
                                                                            <p-dropdown [options]="PartyIdArray" filter="true"
                                                                             formControlName="PartyId" placeholder=" Party ID"> </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-5 ml-4">
                                                                    <div class="form-group">
                                                                        <label for="description">Role Type ID <span class="text-danger">*</span></label>
                                                                        <p-dropdown [options]="CalenderRoleArray" filter="true" formControlName="RoleTypeID" 
                                                                        placeholder="Role Type ID"> </p-dropdown>
                                                                    </div>
                                                                </div>
                                                             
                                                                <div class="col-md-10">
                                                                    <div class="d-flex flex-row-reverse bd-highlight">
                                                                        <button class="btn btn-secondary cursor-pointer ml-2 p-2 bd-highlight"
                                                                         (click)="createMyPortalParticipants()">Add</button>
                                                                    </div>
                                                                </div>
    
    
                                                            </div>
                                                        </form>  -->
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <!-- <p-table [value]="ParticipantsList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th [ngStyle]="{'width': '180px'}" pSortableColumn="code">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                              
                                                                                            </p-checkbox>
                                                                                            
    Party ID
                                                                                            <p-sortIcon field="code">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                    ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
        
                                                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Role Type ID
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                    ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Status ID
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                    ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
        
                                                                                    <th [ngStyle]="{ 'width': '180px'}" pSortableColumn="name">
                                                                                        <div style="color: white;">
                                                                                            Remove
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                    ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
        
                                                                                 
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <span>{{product.partyId}}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                                                        <span>{{product.roleTypeId}}</span>
                                                                                    </td>
                                                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                                                        <span>{{product.statusId}}</span>
                                                                                    </td>
                                                                                  
                                                                                    <td [ngStyle]="{ 'width': '180px'}">
                                                                                        <span>
                                                                                            <a style="color: #0d3769!important;" (click)="deleteMyPortalAParticipants(product)" 
                                                                                              class="account-button"  >Remove</a>
                                                                                    </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                                <h4 class="ml-2">No Record Found</h4>
                                                                                            </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table> -->
        
                                                                        <p class="paginate_data">
                                                                            View per page
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                           
                                                   
                                                    <ng-container *ngIf="isUpdate">
                                                        <div class="mt-5 mb-5">
                                                            <h4 class="text-black">Participants</h4>
                                                            <hr />
                                                            <form>
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <div class="form-group">
                                                                            <div class="d-flex flex-column">
                                                                                <label for="partyId">Party Id</label>
                                                                                <div class="d-flex justify-content-between">
                                                                                    <p-dropdown [options]="partyIds" filter="true" [(ngModel)]="partyId" [ngModelOptions]="{standalone:true}" placeholder="Party Id"></p-dropdown>
                                                                                    <!-- <button class="btn btn-primary cursor-pointer" (click)="assignEvent()">Assign</button> -->
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
    
    
                                            <!-- <ng-container *ngIf="isUpdate">
                                                <div class="table-responsive">
                                                    <table class="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th>Employee Party ID</th>
                                                                <th> Training Request ID</th>
                                                                <th>Training Class Type ID</th>
                                                                <th> Approver Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let event of assignList">
                                                                <td>
                                                                    {{event.partyId}}
                                                                </td>
                                                                <td>
                                                                    {{event.trainingRequestId}}
                                                                </td>
                                                                <td>
                                                                    {{event.trainingClassTypeId}}
                                                                </td>
                                                                <td>{{event.approvalStatus}}
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="!assignList.length">
                                                                <td>No Data</td>
                                                                <td>No Data</td>
                                                                <td>No Data</td>
                                                                <td>No Data</td>
    
                                                            </tr>
    
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </ng-container> -->
    
                                            <ng-template #modalContent let-close="close">
                                                <div class="modal-header">
                                                    <h5 class="modal-title">Event action occurred</h5>
                                                    <button type="button" class="close" (click)="close()">
                                                  <span aria-hidden="true">&times;</span>
                                                </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div>
                                                        Action:
                                                        <pre>{{ modalData?.action }}</pre>
                                                    </div>
                                                    <div>
                                                        Event:
                                                        <pre>{{ modalData?.event | json }}</pre>
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-outline-secondary" (click)="close()">
                                                  OK
                                                </button>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                    </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createPreferencePopup" tabindex="-1" role="dialog" aria-labelledby="createPreferencePopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add a New Portal Page</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add a New Portal Page</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createPortal">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Portal Page Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="portalPageName"
                                                            placeholder="Enter Portal Page Name">
                                                    </div>

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="description"
                                                            placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4  main-submit-button" style="margin-left: 33%;">
                                                <button type="submit" (click)="createPortalPage()"
                                                    class="btn btn-secondary submit-btn">Create</button>
                                               
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>