import { DatePipe } from '@angular/common';
import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { AccountsService } from '../../accounts/accounts.service';
import { MyContactsService } from '../../contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from '../../events/create-events/create-event-service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import {
  ChangeDetectionStrategy,
  TemplateRef,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';

import { FullCalendarComponent, CalendarOptions } from '@fullcalendar/angular';

import dayGridPlugin from '@fullcalendar/daygrid';
import { TrainingApprovalService } from 'src/app/human-resource/training/training-approvals/training-approval.service';


declare const $: any;

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
@Component({
  selector: 'app-preferences-comm',
  templateUrl: './preferences-comm.component.html',
  styleUrls: ['./preferences-comm.component.css']
})
export class PreferencesCommComponent implements OnInit {
  activeCategory = 1;  
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  createPortalPageList: any;
  delete: boolean;
  ParticipantsList: any;
  createPortal: any;
  parentPortalPageId: any;
  communicationEventId: any;
  estCompletion: any;
  partyId: string;
  estStart: any;
  calendarEventForm1: FormGroup;
  EventType: any;
  EventTypeArray: any[]=[];
  ScopeList: any;
  ScopeListArray: any[]=[];
  CalStatusList: any;
  workEffortId: any;
  CalStatusListArray: any[]=[];
  isForm: boolean;
  isButton: boolean;
  isUpdate: boolean;

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };
  calendarValue: any;
  partyIds: any[];
  workEffId: any;
  createCommEvent: FormGroup;
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  contactListId: any;
  contactListIdArray: any[]=[];
  datetimeEnded: string;
  datetimeStarted: string;
  parentCommEventArray: any[]=[];
  reasonenumArray: any[]=[];
  contactMech: any;
  contactMechArray: any[]=[];
  getEstimateFrom: string;
  getEstimateTo: string;
  contactMechTypeArray: any[]=[];
  eventProductList: any;
  contactMechTo: any;
  contactMechToArray: any[]=[];
  stausIdArray: any[]=[];
  comtypeIdArray: any[]=[];
  contentMMArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  date: {
    startDate: string,
    endDate: string
  };
  ParticipantsForm: FormGroup;
  CalenderRole: any;
  CalenderRoleArray: any[]=[];
  PartyId: any;
  PartyIdArray: any[]=[];
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
     readonly addSkillService: AddSkillService,
    readonly trainingApprovalService: TrainingApprovalService,
    readonly _Router: Router,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
  ) {
    this.createPortal = this._FormBuilder.group({
      portalPageName: [''],
      description: [''],
    });
    this.calendarEventForm1 = this._FormBuilder.group({
      eventName: [''],
      desc: [''],
      eventType: [''],
      status: [''],
      scope: [''],
      startDate: [''],
      compDate: [''],
      actualStartDate: [''],
      actualCompletionDate: [''],
    });
    this.createCommEvent = this._FormBuilder.group({
      commEventId: [''],
      parCommEventID: [''],
      commTypeId: [''],
      status:[''],
      partyFrom:[''],
      partyTo: [''],
      conMechId: [''],
      fromContactMechId: [''],
      toContactMechId:[''],
      roleTypeFrom:[''],
      roleTypeTo: [''],
      contactListId: [''],
      startdate: [''],
      finishDate:[''],
      subject:[''],
      reasonEnumId: [''],
      contentMimeTypeId: [''],
      content: [''],
      note:[''],
 
    });
    this.ParticipantsForm = this._FormBuilder.group({
      PartyId: [''],
      RoleTypeID: [''],
    });
   }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.parentPortalPageId = params.parentPortalPageId;
      this.communicationEventId = params.communicationEventId;
      this.workEffortId = params.workEffortId;
    })
    this.getEvents();
    this.getCreatePortalPage();
    this.getEventType();
    this.getScopeList();
    this.getParticipants();
    this.getCalStatusLists();
    this.getPartyId();     
    this.getDropdownList();
    this.getRoleId();
    this.getContactList();
    this.getCalenderRole();
    this.getPartyIdList();

    setTimeout(() => {
      const formValue = this.createCommEvent;
      formValue.patchValue({
        commEventId : this.communicationEventId
      })
    }, 1000);
  }
  
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt" (click)="update()"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    }

  ];

  getEvents(): void {
    this.spinner.show();

    switch (this.view) {
      case 'month': this.date = {
        startDate: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
      case 'day': this.date = {
        startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
      case 'week': this.date = {
        startDate: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
    }
    this.trainingApprovalService.getCommunicationCalender()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.events = data.data.map(value => {
            return {
              ...value, ...{
                start: new Date(value.fromDate),
                end: "",
                title: value.getWorkEffortName[0].workEffortName,
                color: colors.red,
                actions: this.actions,
                allDay: false,
                resizable: {
                  beforeStart: true,
                  afterEnd: true,
                },
                draggable: true,
              }
            }
          });
        }
      });

  }
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[];

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: any): void {

    this.workEffortId = event.workEffortId
    // this.workEffortId = event.event.getWorkEffortName[0].workEffortId,
    this.calendarValue = {
      eventName: event.workEffortName,
      desc:event.description,
      eventType:event.workEffortTypeId,
      status:  event.currentStatusId,
      scope: event.scopeEnumId,
      startDate: event.estimatedStartDate ? moment(event.estimatedStartDate).format('yyyy-MM-DDTHH:mm') : '',
      compDate: event.estimatedCompletionDate ? moment(event.estimatedCompletionDate).format('yyyy-MM-DDTHH:mm') : '',

    };
    this.isForm = true;
    this.isUpdate = true;

    this.calendarEventForm1.patchValue({
      
      eventName: event.workEffortName,
      desc:event.description,
      eventType:event.workEffortTypeId,
      status:  event.currentStatusId,
      scope: event.scopeEnumId,
      startDate: event.estimatedStartDate ? moment(event.estimatedStartDate).format('yyyy-MM-DDTHH:mm') : '',
      compDate: event.estimatedCompletionDate ? moment(event.estimatedCompletionDate).format('yyyy-MM-DDTHH:mm') : '',


    });
    this.getParticipants();

  }
  cancelCalenderEvent(): void {
    this.spinner.show();

    const requestData = {

      "currentStatusId": "CAL_CANCELLED",
      "workEffortId": this.workEffortId

    }

    this.myContactsService.cancelCalenderEvent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  addEvent(): void {
   this._Router.navigate(["/crm/Preferences/mailPreferences"],
      { queryParams: { communicationEventId: this.communicationEventId } })
  }
  addNew(): void {
    this.isForm = true;
    this.isButton = false;
    this.isUpdate = false;
  }
  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }
  setView(view: CalendarView) {
    this.view = view;
  }
  activeDayIsOpen: boolean = true;
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  getDropdownList() {
    this.spinner.show();
   
    this.myContactsService.getCrmInitialCommunicationData().subscribe((res: any) => {
      const stausId = res.data.commEventstatus;
      const comtypeId=res.data.commEventTypes;
      const contentMM=res.data.mimeType;
    this.spinner.hide();
    const parentCommEvent=res.data.parentCommEvent;
    const reasonenum=res.data.reasonEnum;
    const contactMechType=res.data.contactMechType;
    for (const value of contactMechType) {
      this.contactMechTypeArray.push({
        label: value.description,
        value: value.contactMechTypeId
      })
    } 
    for (const value of reasonenum) {
      this.reasonenumArray.push({
        label: value.description,
        value: value.enumId
      })
    } 
    for (const value of parentCommEvent) {
      this.parentCommEventArray.push({
        label: value.communicationEventId,
        value: value.communicationEventId
      })
    } 
      for (const value of stausId) {
        this.stausIdArray.push({
          label: value.description,
          value: value.statusId
        })
      } 
      for (const value of comtypeId) {
        this.comtypeIdArray.push({
          label: value.description,
          value: value.communicationEventTypeId
        })
      } 
      for (const value of contentMM) {
        this.contentMMArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      } 
    })
      

    
   
  }
    
  getContactList() {
    this.spinner.show();
    this.accountsService.getContactList().subscribe(res => {
      this.contactListId = res.data;
      this.spinner.hide();
      for (const value of this.contactListId) {
        this.contactListIdArray.push({
          label: value.description,
          value: value.contactListId
        })
      }
    })
   
  }
  getRoleId() {
    this.spinner.show();
    this.accountsService.getParty().subscribe(res => {
      this.roleTypeId = res.data.roleType;
      this.spinner.hide();
      for (const value of this.roleTypeId) {
        this.roleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
    
  }
  onUpdateEvent() {
    this.spinner.show();
    const fromActualdate=this.createCommEvent.get('startdate').value;
    this.datetimeStarted=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createCommEvent.get('finishDate').value;
    this.datetimeEnded=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "communicationEventId":  this.communicationEventId,
      "communicationEventTypeId": this.createCommEvent.value.commTypeId,
      "contactListId": this.createCommEvent.value.contactListId,
      "contactMechIdFrom": this.createCommEvent.value.fromContactMechId,
      "contactMechIdTo": this.createCommEvent.value.toContactMechId,
      "contactMechTypeId": this.createCommEvent.value.conMechId,
      "content": this.createCommEvent.value.content,
      "contentMimeTypeId": this.createCommEvent.value.contentMimeTypeId,
      "datetimeEnded": this.datetimeEnded,
      "datetimeStarted": this.datetimeStarted,
      "messageId": '',
      "note": this.createCommEvent.value.note,
      "parentCommEventId": this.createCommEvent.value.parCommEventID,
      "partyIdFrom": this.createCommEvent.value.partyFrom,
      "partyIdTo": this.createCommEvent.value.partyTo,
      "reasonEnumId": this.createCommEvent.value.reasonEnumId,
      "roleTypeIdFrom": this.createCommEvent.value.roleTypeFrom,
      "roleTypeIdTo":this.createCommEvent.value.roleTypeTo,
      "statusId":this.createCommEvent.value.status,
      "subject": this.createCommEvent.value.subject,
    }

    this.myContactsService.updatePostCommunicationNew(requestData).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
    //    this._Router.navigate(['/crm/main-crm']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }

  getCalenderRole() {
    this.spinner.show();
    this.myContactsService.getCalenderRole().subscribe(res => {
      this.CalenderRole = res.data[0].data;
      this.spinner.hide();
      for (const value of this.CalenderRole) {
        this.CalenderRoleArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  createMyPortalParticipants() {
    this.spinner.show();
    const formData = {
      "partyId": this.ParticipantsForm.value.PartyId,
      "roleTypeId": this.ParticipantsForm.value.RoleTypeID,
      "statusId": "PRTYASGN_ASSIGNED",
      "workEffortId": this.workEffortId
   
    }
    this.myContactsService.createMyPortalParticipants(formData).subscribe((res: any) => {
  
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.ParticipantsForm.reset();
        this.getParticipants();
        this.isForm = false;
        this.isUpdate = false;
        this.activeCategory=10;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  
  }
  contactEventMechTo(id) {
    this.spinner.show();
    this.accountsService.contactEventMech(id).subscribe(res => {
      this.contactMechTo = res.data;
      this.spinner.hide();
      for (const value of this.contactMechTo) {
        this.contactMechToArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
  
  }

  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  
  }
  contactEventMech(id) {
    this.spinner.show();
    this.accountsService.contactEventMech(id).subscribe(res => {
      this.contactMech = res.data;
      this.spinner.hide();
      for (const value of this.contactMech) {
        this.contactMechArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
   
  }
  getPartyIdList() {
    this.spinner.show();
    this.myContactsService.getPartyIdList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }
  deleteMyPortalAParticipants(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {


          "fromDate": this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss'),
          "partyId": product.partyId,
          "roleTypeId": product.roleTypeId,
          "statusId": product.statusId,
          "workEffortId": this.workEffortId

        }
        this.myContactsService.deleteMyPortalAParticipants(req).subscribe(res => {

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateMyPortalCalendarEvent(): void {
    this.spinner.show();
    const fromActualdate = this.calendarEventForm1.get('startDate').value;
    this.estStart = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const thruActualdate = this.calendarEventForm1.get('compDate').value;
    this.estCompletion = this.datePipe.transform(thruActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "currentStatusId": this.calendarEventForm1.value.status,
      "description": this.calendarEventForm1.value.desc,
      "estimatedCompletionDate": this.datePipe.transform(this.calendarEventForm1.value.compDate, "yyyy-MM-dd"),
      "estimatedCompletionDate_c_compositeType": "Timestamp",
      "estimatedCompletionDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.compDate, "hh"),
      "estimatedCompletionDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.compDate, "mm"),
      "estimatedStartDate": this.datePipe.transform(this.calendarEventForm1.value.startDate, "yyyy-MM-dd"),
      "estimatedStartDate_c_compositeType": "Timestamp",
      "estimatedStartDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.startDate, "hh"),
      "estimatedStartDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.startDate, "mm"),
      "fixedAssetId": "this.fixedAssetId",
      "partyId": "admin",
      "roleTypeId": "CAL_OWNER",
      "scopeEnumId": this.calendarEventForm1.value.scope,
      "start": "",
      "statusId": "PRTYASGN_ASSIGNED",
      "workEffortName": this.calendarEventForm1.value.eventName,
      "workEffortTypeId": this.calendarEventForm1.value.eventType,

      "actualCompletionDate": this.datePipe.transform(this.calendarEventForm1.value.actualCompletionDate, "yyyy-MM-dd"),
      "actualCompletionDate_c_compositeType": "Timestamp",
      "actualCompletionDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.actualCompletionDate, "hh"),
      "actualCompletionDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.actualCompletionDate, "mm"),
      "actualStartDate": this.datePipe.transform(this.calendarEventForm1.value.actualStartDate, "yyyy-MM-dd"),
      "actualStartDate_c_compositeType": "Timestamp",
      "actualStartDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.actualStartDate, "hh"),
      "actualStartDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.actualStartDate, "mm"),
      "workEffortId": this.workEffortId,

    }

    this.myContactsService.updateCalendarEvent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.calendarEventForm1.reset();
        //this.getEvents();
        this.isUpdate = false;
        this.isForm = false;
        this.activeCategory = 10;
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  submit(): void {
    this.spinner.show();
    if (this.isUpdate) {
      this.updateMyPortalCalendarEvent();

    } else {
      this.createMyPortalCalendarEvent();
    }
  }
  getCalStatusLists() {
    this.spinner.show();
    this.myContactsService.getCalenStatusList().subscribe(res => {
      this.CalStatusList = res.data[0].StatusItem;
      this.spinner.hide();
      for (const value of this.CalStatusList) {
        this.CalStatusListArray.push({
          label: value.transitionName,
          value: value.statusIdTo
        })
      }
    })

  }
  getScopeList() {
    this.spinner.show();
    this.myContactsService.getScopesList().subscribe(res => {
      this.ScopeList = res.data;
      this.spinner.hide();
      for (const value of this.ScopeList) {
        this.ScopeListArray.push({
          label: value.scopeData.description,
          value: value.scopeData.enumId
        })
      }
    })
  }
  getEventType() {
    this.spinner.show();
    this.myContactsService.getEventType().subscribe(res => {
      this.EventType = res.data[0].data;
      this.spinner.hide();
      for (const value of this.EventType) {
        this.EventTypeArray.push({
          label: value.description,
          value: value.workEffortTypeId
        })
      }
    })
  }
 
  createMyPortalCalendarEvent(): void {
    this.spinner.show();
    const fromActualdate = this.calendarEventForm1.get('startDate').value;
    this.estStart = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const thruActualdate = this.calendarEventForm1.get('compDate').value;
    this.estCompletion = this.datePipe.transform(thruActualdate, "yyyy-MM-dd hh:mm:ss");

    const requestData = {

      "currentStatusId": this.calendarEventForm1.value.status,
      "description": this.calendarEventForm1.value.desc,
      "estimatedCompletionDate": this.datePipe.transform(this.calendarEventForm1.value.compDate, "yyyy-MM-dd"),
      "estimatedCompletionDate_c_compositeType": "Timestamp",
      "estimatedCompletionDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.compDate, "hh"),
      "estimatedCompletionDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.compDate, "mm"),
      "estimatedStartDate": this.datePipe.transform(this.calendarEventForm1.value.startDate, "yyyy-MM-dd"),
      "estimatedStartDate_c_compositeType": "Timestamp",
      "estimatedStartDate_c_hour": this.datePipe.transform(this.calendarEventForm1.value.startDate, "hh"),
      "estimatedStartDate_c_minutes": this.datePipe.transform(this.calendarEventForm1.value.startDate, "mm"),
      "fixedAssetId": "",
      "partyId": "admin",
      "roleTypeId": "CAL_OWNER",
      "scopeEnumId": this.calendarEventForm1.value.scope,
      "start": "",
      "statusId": "PRTYASGN_ASSIGNED",
      "workEffortName": this.calendarEventForm1.value.eventName,
      "workEffortTypeId": this.calendarEventForm1.value.eventType,

    }

    this.myContactsService.createCalendarEvent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.calendarEventForm1.reset();
        this.workEffId = res.data.workEffortId;
        this.getParticipants();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
   getParticipants() {
     this.spinner.show();
    this.myContactsService.getParticipants(this.workEffortId).subscribe(res => {
      this.ParticipantsList = res.data[0].data;
      this.spinner.hide();

    })
   }
  allPage() {
    this._Router.navigate(["/crm/Preferences/preferencesall"],
      { queryParams: { communicationEventId: this.communicationEventId } })
  }
  emailPage() {
    this._Router.navigate(["/crm/Preferences/emailPreferences"],
      { queryParams: { communicationEventId: this.communicationEventId } })
  }
  notePage() {
    this._Router.navigate(["/crm/Preferences/notePreferences"],
      { queryParams: { communicationEventId: this.communicationEventId } })
  }
  createPortalPage(): void {
    this.spinner.show();

    const requestData = {
      "description": this.createPortal.value.description,
      "parentPortalPageId": "SFA_MAIN",
      "portalPageName": this.createPortal.value.portalPageName,
      "sequenceNum": "1"
    }

    this.myContactsService.createPortalPage(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getCreatePortalPage();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCreatePortalPage() {
    this.spinner.show();
    this.myContactsService.getCreatePortalPage().subscribe(res => {
      this.createPortalPageList = res.data[0].getCreatePortalPage;
    
      this.spinner.hide();

    })
  }
 
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}
