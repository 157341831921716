import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from '../../app.constant';

@Injectable({
  providedIn: 'root'
})
export class FacilityGroupService {

  constructor(private http: HttpClient) { }
  getparentGroupList(): Observable<any> {
    const parentGroupList = URLS.getFacilityGroupParentGroupId;
    return this.http.get(parentGroupList);
  }

  getFacilityGroupList(): Observable<any> {
    const FacilityGroupList = URLS.FacilityGroupList;
    return this.http.get(FacilityGroupList);
  }


  getFacilityGroupTypeList(): Observable<any> {
    const FacilityGroupTypeList = URLS.getFacilityGroupTypeId;
    return this.http.get(FacilityGroupTypeList);
  }

  createFacilityGroup(formData): Observable<any> {
    const createFacilityGroup = URLS.createFacilityGroup;
    return this.http.post(createFacilityGroup, formData);
  }createInventoryItemLabel

  updateFacilityGroup(id,formData): Observable<any> {
    const updateFacilityGroup = URLS.createFacilityGroup + `/${id}`;
    return this.http.put(updateFacilityGroup, formData);
  }

  deleteCatalogStore(id) {
    const deleteFacilityGroup = URLS.createFacilityGroup + `/${id}`;
    return this.http.delete(deleteFacilityGroup);
  }

}
