import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-create-user-login',
  templateUrl: './create-user-login.component.html',
  styleUrls: ['./create-user-login.component.css']
})
export class CreateUserLoginComponent implements OnInit {

  createUser:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  user=[{
    "label":"Yes",
    "value":"Y"
  },
  {
    "label":"No",
    "value":"N"
  }]
  @Output() closeUserModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closebutton') closebutton;
  constructor( readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    public bsModalRef: BsModalRef
    
    ) { 
      
      this.createUser = this._FormBuilder.group({
        loginId: [''],
        currentpass: [''],
        verifypass: [''],
        passHint: [''],
        passrequire: [''],
        extAuthId: [''],
       
        
      });
    
  }
 
  ngOnInit(): void {
    
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];

      
    });
  }
  onSubmit() {
    this.modalClose();
    this.spinner.show();
    const requestData = {
      "currentPassword": this.createUser.value.currentpass,
      "currentPasswordVerify":this.createUser.value.verifypass,
      "externalAuthId": this.createUser.value.extAuthId,
      "partyId": this.party,
      "passwordHint": this.createUser.value.passHint,
      "requirePasswordChange": this.createUser.value.passrequire,
      "userLoginId":this.createUser.value.loginId,
    
    }
    this.accountsService.createProfileUserLogin(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.bsModalRef.hide();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.debugMessage);
        }
    });

  }
/*   onUpdate() {
    this.spinner.show();
    const requestData = {
      "glJournalId":  this.jid,
      "glJournalName": this.createJournal.value.journalName,
      "organizationPartyId":  this.partyId,
    }
    this.accountsService.updateJournal(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  } */
  
  cancelSubmit(){
    this._location.back();
  }
  modalClose(): void {
    this.bsModalRef.hide();
    this.closebutton.nativeElement.click();
    
    //this.closeUserModal.emit();
  }

}
