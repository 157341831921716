import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductionService } from '../production.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ToastrService } from 'ngx-toastr';import { Location } from '@angular/common';
@Component({
  selector: 'app-mrp-log-list',
  templateUrl: './mrp-log-list.component.html',
  styleUrls: ['./mrp-log-list.component.css']
})
export class MrpLogListComponent implements OnInit {
  createRunMrp:FormGroup;
  @ViewChild('closebutton') closebutton;
  products3: any;
  total=0;
  activeCategoryMain=2;
  rowNumber=0;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  financialList:any[]=[];
  FinAccountTypeList: any;
  FinAccountTypeListArray: any[] = [];
  smallerArray: { label: string; value: string; }[];
  greaterArray: { label: string; value: string; }[];
  ProdMrp: any;
  mrpsearchList: any;
  productListArray: any[]=[];
  fromDate: any;
  createRunMrpForm:FormGroup;
  activeCategory=2;
  activeCategoryA=1;
  getFacilityGroupArray: any[]=[];
  getFacilityGroup: any;
  MrpFacilities: any;
  MrpFacilitiesArray: any[]=[];
  constructor(readonly spinner: NgxSpinnerService,
    private productionService:ProductionService,
     readonly _FormBuilder: FormBuilder,
     private accountsService: AccountsService,
     private datePipe: DatePipe, private _location: Location,
     readonly _ToastrService: ToastrService,
    readonly router: Router) {
      this.createRunMrp = this._FormBuilder.group({
        ProductID: [''],
        FromDate:[''],
     
      });
      this.createRunMrpForm = this._FormBuilder.group({
        MrpName: [''],
        FacilityGroup:[''],
        Facility: [''],
        DefaultYearsOffset:['1']
      
       
       
      });
  
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getProdMrp();
    this.mrpSearch();
    this.getProducts();
    this.getparentGroupList();
    this.getMrpFacilities();
  }
  changeactiveCategoryMainA(tab: number) {
    this.activeCategoryA = tab;

  }
  changeactiveCategoryMain(tab: number) {
    this.activeCategoryMain = tab;

  }
  getMrpFacilities() {
    this.spinner.show();
    this.productionService.getMrpFacilities().subscribe(res => {
      this.MrpFacilities = res.data;
      this.spinner.hide();
      for (const value of this.MrpFacilities) {
        this.MrpFacilitiesArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
    })
   
  }
  getparentGroupList() {
    this.spinner.show();
    this.productionService.getparentGroupList().subscribe(res => {
      this.getFacilityGroup = res.data;
      this.spinner.hide();
      for (const value of this.getFacilityGroup) {
        this.getFacilityGroupArray.push({
          label: value.facilityGroupName,
          value: value.facilityGroupId
        })
      }
    })
   
  }
  onSubmit() {
    this.spinner.show();
      const requestData = {
      "defaultYearsOffset":this.createRunMrpForm.value.DefaultYearsOffset,
      "facilityGroupId":this.createRunMrpForm.value.FacilityGroup,
      "facilityId": this.createRunMrpForm.value.Facility,
      "mrpName": this.createRunMrpForm.value.MrpName,
    }
    this.productionService.postProdMrp(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully"); 
        this.closebutton.nativeElement.click();
      
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }

  getProducts(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      const productList = res.data;
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });

    });

  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  mrpSearch() {
    this.spinner.show();
    const req={
      "eventDate": "",
      "eventDate_i18n": "",
      "hideFields": "Y",
      "lookupFlag": "Y",
      "productId": ""
    }
    this.productionService.mrpSearch(req).subscribe((res: any) => {
      this.mrpsearchList = res.data;
      this.spinner.hide();
    })
  
  }
  RunMRP() {
    this.router.navigate(["/production/create-run-mrp"])

  }

  MRPLog() {
    this.router.navigate(["/production/mrp-log-list"])

  }

  reset() {
    this.spinner.show();
    this.createRunMrp.reset();
    const req={
      "eventDate": "",
      "eventDate_i18n": "",
      "hideFields": "Y",
      "lookupFlag": "Y",
      "productId": ""
    }
    this.productionService.mrpSearch(req).subscribe((res: any) => {
      this.mrpsearchList = res.data;
      this.spinner.hide();
    })
  
  }
  mrpFind() {
    this.spinner.show();
    const FromDate = this.createRunMrp.get('FromDate').value;
    this.fromDate = this.datePipe.transform(FromDate, "yyyy-MM-dd hh:mm:ss");
  

    const req={
      "eventDate":this.fromDate,
      "eventDate_i18n": "",
      "hideFields": "Y",
      "lookupFlag": "Y",
      "productId": this.createRunMrp.value.ProductID
    }
    this.productionService.mrpSearch(req).subscribe((res: any) => {
      this.mrpsearchList = res.data;
      this.spinner.hide();
    })
  
  }
  getProdMrp() {
    this.spinner.show();
  
    this.productionService.getProdMrp().subscribe((res: any) => {
      this.ProdMrp = res.data.lastFinishedJobs;
     
      this.spinner.hide();
    })
  
  }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
