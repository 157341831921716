<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Allocation Plan</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div
                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Overview ID: {{planId}}

                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal"
                                        data-target="#allocationPlanPopUp">New Allocation Plan
                                    </button>
                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal"
                                    *ngIf="this.showApprove"
                                  (click)="changeAllocationPlanStatusApprove()"  > Approve Plan
                                </button>
                                <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal"
                                *ngIf="this.showApprove" (click)="changeAllocationPlanStatusCancel()"   > Cancel Plan
                            </button>

                                </div>
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="color-black container" *ngIf="this.viewAllocationPlanList">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="article-container">
                                                    <div class="article">
                                                        <p class="col-lg-6">Name</p>
                                                        <span class="color-grey pl-20 font-weight-bold">
                                                            {{this.viewAllocationPlanList.planName}}
                                                          
                                                            
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="article-container">
                                                    <div class="article">
                                                        <p class="col-lg-6">Product </p>
                                                        <span class="color-grey pl-20 font-weight-bold">
                                                           
                                                            {{this.viewAllocationPlanList.productName}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="article-container">
                                                    <div class="article">
                                                        <p class="col-lg-6">Created By</p>
                                                        <span class="color-grey pl-20 font-weight-bold">
                                                            {{this.viewAllocationPlanList.createdBy}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="article-container">
                                                    <div class="article">
                                                        <p class="col-lg-6">Status</p>
                                                        <span class="color-grey pl-20 font-weight-bold">
                                                            {{this.viewAllocationPlanList.statusId}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="article-container">
                                                    <div class="article">
                                                        <p class="col-lg-6">ATP/QOH	</p>
                                                        <span class="color-grey pl-20 font-weight-bold">
                                                            {{this.viewAllocationPlanList.totalATP}} / {{this.viewAllocationPlanList.totalQOH}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="article-container">
                                                    <div class="article">
                                                        <p class="col-lg-6">Created Date	</p>
                                                        <span class="color-grey pl-20 font-weight-bold">
                                                            {{this.viewAllocationPlanList.createdDate | date : "yyyy-MM-dd hh:mm:ss"}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div
                                    class="d-flex bd-highlight port-header-contact-import  create-lead font-medium mb-0 suppliers-wrapper">
                                    Summary


                                </div>


                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100 pl-0">
                                        <div class="w3-card-4  w-100">

                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="summaryList" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                   
                                                                                </p-checkbox>

                                                                              
Ordering Channel	
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Ordered Units	
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Ordered Value	
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Allocated Units	</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Allocated Value	
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Allocation %

                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  
                                                                              >
                                                                                    {{product.WEB_SALES_CHANNEL.salesChannel}}</span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <span>
                                                                                {{product.WEB_SALES_CHANNEL.orderedQuantity}}
                                                                            </span>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            ${{product.WEB_SALES_CHANNEL.orderedValue}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.WEB_SALES_CHANNEL.allocatedQuantity}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                           ${{product.WEB_SALES_CHANNEL.allocatedValue}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.WEB_SALES_CHANNEL.allocationPercentage}}%
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div
                                    class="d-flex bd-highlight port-header-contact-import  create-lead font-medium mb-0 suppliers-wrapper">
                                    Items
                                </div>


                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100 pl-0">
                                        <div class="w3-card-4  w-100">

                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="itemList" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>

                                                                                Sales Channel
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                Customer
                                                                            </div>
                                                                            <div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Status
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Order ID	</div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Order Item Seq ID
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                            pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Est. Ship Date

                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="category">
                                                                        <div style="color: white;">
                                                                            Ordered

                                                                        </div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                    pSortableColumn="category">
                                                                    <div style="color: white;">
                                                                        Reserved

                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;">
                                                                    Ext. Value	

                                                                </div>
                                                                <input
                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'170px'}"
                                                            pSortableColumn="category">
                                                            <div style="color: white;">
                                                                Allocated

                                                            </div>
                                                            <input
                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                        <th [ngStyle]="{'width':'170px'}"
                                                        pSortableColumn="category">
                                                        <div style="color: white;">
                                                            Allocation %

                                                        </div>
                                                        <input
                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                            value="" autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false"
                                                            aria-readonly="false">
                                                    </th>
                                                                        


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <div
                                                                                     class="account-button"  
                                                                             >
                                                                                    {{product.salesChannel}}  </div>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div   >
                                                                                {{product.partyName}}
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div    >  {{product.statusId}}</div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div    > {{product.orderId}}</div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div  > {{product.orderItemSeqId}}</div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div   >  
                                                                                {{product.estimatedShipDate | date :'yyyy-MM-dd'}}
                                                                                </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div   >  {{product.orderedQuantity}}</div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div    >  {{product.reservedQuantity}}</div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div  >  {{product.orderedValue}}</div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                         
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div  > {{product.allocationPercentage}}</div>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="allocationPlanPopUp" tabindex="-1" role="dialog" aria-labelledby="allocationPlanPopUpTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Allocation Plan
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>
                                        Create Allocation Plan
                                    </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="allocationForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm2">
                                                        <label for="exampleInputEmail1">
                                                            Product ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="ProductIdArray" placeholder="Choose  Product ID"
                                                            formControlName="ProductID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Plan Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            placeholder="Enter Plan Name" aria-describedby="emailHelp"
                                                            formControlName="PlanName">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12 col-12 main-submit-button" style="margin-left: -27%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Next</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>