<div class="container-fluid main-container-wrapper" style="margin-top: 53px !important;">

    <!-- <div class="row " style="background-color: #0492C2; padding-top: 6px !important;">
        

        <div class="col-lg-1">
           <span class="color-black pl-1 titlePanels" style="cursor: pointer; color: white; font-weight: bold;">Find </span>
        </div>
            <div class="col-lg-2" style="margin-left: -4% !important;">
                <p-dropdown filter="true"
                [options]="" optionlabel="label"
                placeholder="Work Order Number"
                >
                </p-dropdown>

            </div>

            <div class="col-lg-2" style="margin-left: -2% !important;">
                <input type="text" class="form-control" id=""  >
            </div>

            <div class="col-lg-1" style="margin-left: -2% !important;">
                <button type="submit" class="btn btn-secondary submit-btn">Search</button>

            </div>
        

        <div class="col-lg-4">
        </div>
            <div class="col-lg-2" style="margin-left: 7% !important;">
                <p-dropdown filter="true"
                [options]="" optionlabel="label"
                placeholder="Recent Items"
                >
                </p-dropdown>

            </div>

        

    

</div> -->

<div class="row" style="background-color:  #0492C2; padding-bottom: 6px !important; padding-top: 4px !important;">

<!-- <div class="col-lg-3   ">
    <button type="submit" class="btn btn-secondary submit-btn" style="margin-left: -2%;">
        Dispatch Board
     </button>
</div> -->

<div class="col-lg-2 " style="margin-left: 60% !important;">
    <button (click)="toWorkOrder()" style="background-color: #1338BE; 
    color: white !important; font-weight: bold;"> Work Orders</button>
</div>

<div class="col-lg-2 " style="margin-left: -8% !important;">
    <button (click)="toCustomer()" style="background-color: #48AAAD; 
    color: white !important; font-weight: bold;">Customer</button>

</div>               
   
<div class="col-lg-1 " style="margin-left: -9% !important;">
    <button (click)="toInvoices()" style="background-color:#016064;
     color: white; font-weight: bold;">Invoices</button>

</div>                
    
<!-- <div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toReports()" style="background-color: #1520A6; 
    color: white; font-weight: bold;">Reports</button>

</div>               

<div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toPeople()" style="background-color:#59788E;
     color: white !important; font-weight: bold; padding-left: 12px; padding-right: 12px;">Staff</button>

</div> -->
<div class="col-lg-2 " style="margin-left: -3% !important;">
    <button (click)="toTimeSheet()" style="background-color: #1F456E;
     color: white !important; font-weight: bold;">Time Sheet</button>

</div>                 

<div class="col-lg-1 " style="margin-left: -9% !important;">
    <button (click)="toSetting()" style="background-color:#757c88 ; 
    color: white; font-weight: bold; ">Settings</button>

</div>



</div>
    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            <div class="col-md-12 p-0" >
                <div class="w3-card-4 classCard  w-100" style="padding-bottom: 2%;"> 
            
                            <div>
                                <a class="btn btn-link" style="font-size:19px !important; text-decoration: none;">Time-Sheet Settings</a>

                            </div>
                        <a class="btn btn-link" (click)="toWorkFlowSettings()">Work Flow Settings</a>
                        <p>You can select a Time Sheet model, pick the times to include as Work Order times 
                            and set how to handle times crossing midnight.
                        </p>

                        <a class="btn btn-link" (click)="toTimeSheetPeriod()">Time Sheet Period</a>
                        <p>You can select your Time Sheet period either as Weekly or Semi-monthly here.
                        </p>

                        <a class="btn btn-link" (click)="toOverTimeRules()">Over Time Rules</a>
                        <p>You can set the over times rules for your company here.
                        </p>
                        
                        <a class="btn btn-link" (click)="toRoundingRules()">Rounding Rules</a>
                        <p>You can set the rounding rules for times here.
                        </p>

                        <a class="btn btn-link" (click)="toListViewConfiguration()">List View Configuration</a>
                        <p>You can set the times (regular,OT,DT) you want to see in your list here.
                        </p>

                        <a class="btn btn-link" (click)="toReconcileFlagRules()">Reconcile Flag Rules</a>
                        <p>You can set exception rules here for Time Sheet Based on those rules the 
                            Time Sheet list view wll show flags next to the exceptions. This is an easy
                            way for you to quickly audit the Time Sheet.
                        </p>
                </div>
            </div>
               

            
            
    
       
        
    </div>

</div>


