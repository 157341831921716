<div class="modal-header">
    <span class="color-black pl-1">Credit Card</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>

<div class="row p-15">
    <div class="col-12">
        <div class=" bg-white color-grey">
            <div class="w3-card-4 classCard border-card">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
            
                        <li class="active"><a>
                            {{editMode? 'Update':'Create'}} Credit Card
                    </a></li>
                    
                        
                    
                    </ul>
                </div>

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="creditCardForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm" 
                                            style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                <label for="exampleInputEmail1">Company Name Card</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Company Name Card"
                                                formControlName="companyNameOnCard">
                                            </div>
                                            
                                            <div class="col-lg-2 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                <label for="exampleInputEmail1">Prefix Card</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <p-dropdown placeholder="Prefix Card" filter="true" [options]="prefixArray"
                                                formControlName="titleOnCard">
                                            </p-dropdown>
                                            </div>  
                                            
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                <label for="exampleInputEmail1">First Name</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="First Name"
                                                formControlName="firstNameOnCard">
                                            <small class="text-danger"
                                                *ngIf="!!creditCardForm.controls.firstNameOnCard.invalid && !!creditCardForm.controls.firstNameOnCard.touched">First
                                                Name is Required</small>
                                            </div>
                                            <div class="col-lg-2 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                <label for="exampleInputEmail1">Middle Name Card</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Middle Name Card"
                                                formControlName="middleNameOnCard">
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                <label for="exampleInputEmail1">Last Name Card <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="text" class="form-control" placeholder="Last Name Card"
                                                formControlName="lastNameOnCard">
                                            <small class="text-danger"
                                                *ngIf="!!creditCardForm.controls.lastNameOnCard.invalid && !!creditCardForm.controls.lastNameOnCard.touched">Last
                                                Name is Required</small>
                                            </div>
                                            
                                            <div class="col-lg-2 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                <label for="exampleInputEmail1">Suffix On Card
                                                </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <p-dropdown placeholder="Suffix On Card" filter="true"
                                                    [options]="suffixArray" formControlName="suffixOnCard">
                                                </p-dropdown>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                <label for="exampleInputEmail1">Card Type
                                                </label>
                                            </div>
                                            <div class="col-lg-3">
                                                <p-dropdown placeholder="Card Type" filter="true" [options]="cardTypes"
                                                    formControlName="cardType">
                                                </p-dropdown>
                                            </div>
                                            
                                            <div class="col-lg-2 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                <label for="exampleInputEmail1">Card Number</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="cardNumber" aria-describedby="emailHelp"
                                                placeholder="Card Number">
                                            </div> 
                                            
                                        </div>
                                    </div>
                                    
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                <label for="exampleInputEmail1">Expiration Date <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                            <div class="col-lg-3">
                                                <p-dropdown placeholder="Month" filter="true"
                                                [options]="expirationMonth" formControlName="expMonth">
                                            </p-dropdown>
                                            <small class="text-danger"
                                            *ngIf="!!creditCardForm.controls.expMonth.invalid && !!creditCardForm.controls.expMonth.touched">Expiration
                                            Month is Required</small></div>
                                            <div class="col-lg-3">
                                            <p-dropdown class="ml-2" placeholder="Year" filter="true"
                                                [options]="expirationYear" formControlName="expYear">
                                            </p-dropdown>
                                    
                                        
                                        <small class="text-danger"
                                            *ngIf="!!creditCardForm.controls.expYear.invalid && !!creditCardForm.controls.expYear.touched">Expiration
                                            Year is Required</small>
                                            </div>
                                            
                                            
                                            
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 0%;">
                                                <label for="exampleInputEmail1">Description</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                formControlName="description" aria-describedby="emailHelp"
                                                placeholder="Description">
                                            </div>
                                            <div class="col-lg-2 form-group rightForm"
                                            style="    text-align: right!important;
                                            padding-right: 17px!important;
                                            margin-left: 3%;">
                                                <label for="">Billing Address</label>
                                            </div>
                                            <div class="col-lg-3">
                                                <ng-container *ngIf="accountData?.address;else noData">
                                                    <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                                        (change)="getAddress($event,accountData?.address.contactMechId)">
                                                    <label class="form-check-label" for="exampleCheck1">
                                                        To:<span>{{accountData?.address.address1}}</span>,
                                                        <span>{{accountData?.address.address2}}</span>,
                                                        <span>{{accountData?.address.city}}</span>,
                                                        <span>{{accountData?.address.stateProvinceGeoId}}</span>,
                                                        <span>{{accountData?.address.countryId}}</span>,
                                                        <span>{{accountData?.address.postalCode}}</span>
                                                    </label>
                                                </ng-container>
                                                <ng-template #noData>
                                                    No contact information on file.
                                                </ng-template>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                    
                                    
                                    
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-left: -10%;">
                                <button type="submit" *ngIf="editMode" (click)="update()"
                                    class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" *ngIf="!editMode" (click)="submit()"
                                    class="btn btn-secondary submit-btn">Submit</button>
                                
                            </div>
                        </div>
                    </div>
                </div>
    
           
            </div>

        </div>
        
    </div>
</div>
