<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;"> Communication Event
                    Order</span>

                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass"
                    >Cancel</button>
            </div>
            <div class="w3-card-4 classCard" >
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">

                        <li [ngClass]="activeCategory==2?'active':''"><a>
                                Add Communication Event Order</a></li>



                    </ul>
                </div>

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="form-group">
                            <div class="row">
                                <form class="w-100" [formGroup]="createOrder">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-2 form-group rightForm">
                                                    <label for="exampleInputEmail1">



                                                        Order ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="orderArray" optionlabel="label"
                                                  placeholder="Order Id" filter="true"
                                                    formControlName="orderId">

                                                </p-dropdown>

                                                </div>
                                                
                                               
                                            </div>
                                        </div>

                                    




                                    </div>


                                </form>
                                <div class="main-submit-button" style="margin-left: 14%;">
                                    <button (click)="onSubmit();" type="submit" class="btn btn-secondary submit-btn">Add Order</button>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>