<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/financial/payable/invoices">Invoices </span>
                <span class="color-gry cursor-pointer" routerLink="/financial/payable/invoices/detailed-summary"
                    [queryParams]="{invoiceId:invoiceId}">>Invoice Summary </span>
                <span class="color-black pl-1"> > Create Invoice Role </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4" style="border:1px solid;
                border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                  background: #F5F7FA!important;">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Add New Party Role</a></li>
                       
                   
          
                    </ul>
                  </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                    data-toggle="collapse" href="#collapsetwo">
                                    <div class="w-100">



                                       
                                            <div class="panel panel-default">
                                               
                                                <div>
                                                    <div class="panel-body">
                                                        <form [formGroup]="createRoleForm">
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" style="
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -8%;
                                                                        font-size: 10px;
                                                                    ">
                                                                            <label for="partyId">Party ID</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                            [options]="partyIds" optionlabel="label"
                                                                            placeholder="select PartyId"
                                                                            formControlName="partyId">
                                                                        </p-dropdown>
                            
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       
                                                                        font-size: 10px;
                                                                    ">
                                                                            <label for="partyId">Role Type ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                [options]="roleTypeIds"
                                                                                optionlabel="label"
                                                                                placeholder="select Role Type Id"
                                                                                formControlName="roleTypeId">
                                                                            </p-dropdown>
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" style="
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -8%;
                                                                        font-size: 10px;
                                                                    ">
                                                                            <label for="date">Date Time
                                                                                Performed</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-calendar
                                                                                formControlName="datetimePerformed">
                                                                            </p-calendar>
                            
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="
                                                                        text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 10px;
                                                                    ">
                                                                            <label for="percentage">Percentage</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            formControlName="percentage">
                                                                        </div>
                                                
                                                                       
                                                
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="main-submit-button" style="
                                                            margin-right: 26%;
                                                        ">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="submitInvoiceRole()">Submit</button>
                                                                <button class="btn btn-danger cursor-pointer ml-2"
                                                                    (click)="reset()">Cancel</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                     
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 