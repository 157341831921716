import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {FormGroup, FormBuilder} from '@angular/forms';
import { CRMAccountService } from 'src/app/services/crm-account.service'
import { GlobalResourceService } from 'src/app/services/global-resource.service'
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import Swal from 'sweetalert2'
import { SortEvent } from 'primeng/api';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
 
      
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



import { FullCalendarComponent, CalendarOptions } from '@fullcalendar/angular';

import dayGridPlugin from '@fullcalendar/daygrid';
import {Validators } from '@angular/forms';
import { TrainingApprovalService } from 'src/app/human-resource/training/training-approvals/training-approval.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { detailedContactsService } from 'src/app/crm/contacts/detailed-contacts-summary/detailed-contacts-summary.service';
import { EmployementApplicationService } from 'src/app/employeeApplication/employee-application.service';
import { EmployeeApplicationService } from 'src/app/employeeApplication/employee-application/employee-application.service';
import { SkillService } from 'src/app/human-resource/skills/skill.service';
import { EmployeeService } from 'src/app/human-resource/employees/employess.service';


declare const $: any;

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
@Component({
  selector: 'app-my-portal-preferences',
  templateUrl: './my-portal-preferences.component.html',
  styleUrls: ['./my-portal-preferences.component.css']
})
export class MyPortalPreferencesComponent implements OnInit {
  pageSize= 100;
  rows = 50;
  pageNo=1;
  search="";
  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  @ViewChild('closebutton') closebutton;
  faEdit = faEdit;
  faTrash = faTrash;
  activeCategory=1;
  products3: any;
  total=0;
  rowNumber=0;

  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  userLoginIdArray:any[]=[];
  partyId: string;
  payment: any;
  TrainingData: any;
  id: string;
  PortalPages: any;

  activeTabbing = 1;
 
  options: any;
  public trainingSize = {
    "pageNo": this.pageNo,
    "pageSize": this.pageSize
  };
 

  trainingApprovalForm: FormGroup;
  dateIdArray = [
    {
      label: 'Less Than',
      value: 'LessThan'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },

    {
      label: 'Greater Than',
      value: 'GreaterThan'
    },
    {
      label: 'Greater Than Equal To',
      value: 'GreaterThanEqualTo'
    },
    {
      label: 'Less Than Equal To',
      value: 'LessThanEqualTo'
    },
    {
      label: 'Is Empty',
      value: 'IsEmpty'
    }

  ];
  opportunityIdArray = [
    {
      label: 'Contains',
      value: 'Contains'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    },
    {
      label: 'Begins With',
      value: 'Begins With'
    },
  ];


  FinancialTransaction:any[]=[];
  accountData:any;
  budgetId: any;
  budgetHeader: any;
  budgetStatus: any;
  budgetRole: any;
  budgetItem: any;
  budgetReview: any;
  budgetItemList: any;
  budgetRoleList: any;
  budgetReviewList: any;
  status: string;
  taxAuthPartyId: any;
  roleList: any;

  isAdditionalDetail: boolean;
  isJobDetail: boolean;
  contactInformation: any[]=[];
  accountName: any;
  addr: string;
  emailId: any;
  phoneNumber: string;
  contentList: any;
  billingAccounts: any[];
  paymentMethods: any[];
  skillList: any;
  public employeeDetails: any;
  public employeeDetail: any;
  employeeDetailArray: any = [];
  departmentList: any;
  departmantArray: any = [];
  departmentId: any;
  department: any;
  demoName: any;
  demoId: any;
  employeeDetailArrayEmail: any = [];
  employeeDetailArrayPhone: any = [];
  employeeDetailRelatedAccount: any;
  emergencyContact: any;
  personId: any;
  createLinkParty:FormGroup;
  partyIdArray: any[]=[];
  IdentificationList: any;
  PartyResume: any;
  SegmentRole: any;
  PartyClassification: any;
  PartyRate: any;
  UserLoginData: any;
  PartyAttribute: any;
  PartyContactList: any;
  employmentApplication: any;
  productStoresListArray: any[]=[];
 
  allrole: any;
  allroleArray: any[]=[];
  AllProductStoreRole: any;
  Visitors: any;
  PartyLoyaltyPoints: any;
  Person: any;
  ProductStore: any;
  Preferences: any;
  RelationshipPartyIdTo: any;
  AllFinanceHistory: any;
  ShoppingListArray: any[]=[];
  TaxInfo: any;
  PartyIcsAvsOverRide: any[]=[];
  partyIdLogin: string;



  calendarValue: any;
  trainingApproval: any;
  trainingApprovalList: any[];
  trainingClassTypes: any[];
  isForm: boolean;
  isUpdate: boolean;

  calendarEventForm: FormGroup;
  partyIds: any[];
  workEffortId: string;
  
  date: {
    startDate: string,
    endDate: string
  };
  assignList: any[];
  partyIdData: string;
  paymentScreen=false;
  MyLeaveScreen=false;
  MyLeave: any;
  Mytraining=false;
  otherPartyComm=false;
  communicationEvents: any;
  Main=false;
  SystemInfoNote: any;
  SystemInfoStatus: any;
  myComm=false;
  getOverviewList: any;
  commEventIdPartyId: string;
  myTask=false;
  MyTask: any;
  portalPage:FormGroup;
  RoleById: any;
  RoleByIdArray: any[]=[];
  
  myProfile=false;
  relatedContact: any;
  securtiyList: any;
  groupIdIdArray: any[]=[];
  descriptionIdArray: any[]=[];
  constructor(
    readonly _ToastrService: ToastrService,
    
    readonly modal: NgbModal,
    readonly accountsService: AccountsService,
    readonly router: Router,
    readonly spinner:NgxSpinnerService,
    readonly trainingApprovalService: TrainingApprovalService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _CategoryService: CategoryService, readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly addSkillService: AddSkillService,
    readonly _AccountingApService: AccountingApService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _detailedContactsService: detailedContactsService,
    readonly skillService: SkillService,
    readonly employeeService: EmployeeService,
    private _location: Location,
    readonly employementApplicationService: EmployementApplicationService,
    readonly _CRMAccountService: CRMAccountService,
    readonly employeeApplicationService: EmployeeApplicationService,

   
  ) {
    this.partyId=localStorage.getItem("partyId")
    this.portalPage = this._FormBuilder.group({
      Description: [''],
      SecurityGroupID:[''],
      SequenceNum:[''],
      Portalpagename:[''],
      OwnerUserLoginID:[''],
      OriginalPage:[''],
      Parentpage:[''],
      PortalPageID:['']  
    });
    this.partyIdData=localStorage.getItem("partyId")
    this.date = {
      startDate: '',
      endDate: ''
    };
    this.assignList = [];
    this.trainingApprovalList = [];
    this.trainingClassTypes = [];
    this.isForm = false;
    this.isUpdate = false;
    this.partyIds = [];
    this.calendarEventForm = this._FormBuilder.group({
      description: ['', [Validators.required]],
      estimatedCompletionDate: [''],
      estimatedStartDate: [''],
      workEffortName: ['']
    });}
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    const partyId: string = localStorage.getItem('partyId');
    this.commEventIdPartyId=partyId
    this.ManagePortalPages();
 this.getPayment();
 this.getMyLeave();
 this.getTrainingData();
 this.communicationEventsPortal();
 this.getSystemInfoNote();
 this.getSystemInfoStatus();
 this.getCrmListDetail();
 this.getRoleById();
 this.getPartyId();
 this.getMyTask();

 this.getPartyRole();
 this.getAccountNote();
 //this.accountDetail();
 this.getPartyContentsList();
 this.getPaymentMethods();

 this.getEmployeeDetail();
 this.getPartyId();
 
 this.getUserLoginData();
 this.getPartyAttribute();

 this.getOrderListItems();
 this.getRoleAllType();
 
 this.getVisitors();
 this.getPartyLoyaltyPoints();
 this.getPerson();
 
 this.getPreferences();

 this.getPartyIcsAvsOverRide();
this.getIdentificationList();
this.getUserDetails();
  }
  homeButton(){
    this.router.navigate(['/myPortal/my-portal-my-profile'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
 

  getUserDetails(){
    this.spinner.show();
    const req={
  "description": "",
  "descriptionSearchType": "Contains",
  "groupId": "",
  "groupIdSearchType": "Contains"
     
    }
    this.accountsService.getSecurityGroupList(this.user,req).subscribe((res:any)=> {
      this.securtiyList = res.data;
      this.spinner.hide();
      for(const value of this.securtiyList){
      this.groupIdIdArray.push({
        label:value.groupName,
        value:value.groupId
      })
    } 
    for(const value of this.securtiyList){
      this.descriptionIdArray.push({
        label:value.description,
        value:value.description
      })
    } 
    })   
   }
  onSubmit() {
    this.spinner.show();
   
    const requestData = {
      "description": this.portalPage.value.Description,
      "originalPortalPageId": this.portalPage.value.OriginalPage,
      "ownerUserLoginId": this.portalPage.value.OwnerUserLoginID,
      "parentPortalPageId": this.portalPage.value.Parentpage,
      "portalPageId": this.portalPage.value.PortalPageID,
      "portalPageName": this.portalPage.value.Portalpagename,
      "securityGroupId":this.portalPage.value.SecurityGroupID,
      "sequenceNum": this.portalPage.value.SequenceNum,
     
  
     
    }
    this.accountsService.createPortalPageAdm(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.portalPage.reset();
        this.closebutton.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  getIdentificationList() {
    this.spinner.show();
    this.accountsService.getIdentificationList(this.partyIdData).subscribe(res => {
      this.IdentificationList = res.data;
      this.spinner.hide();
    })
  
  }
  getPartyIcsAvsOverRide() {
    this.spinner.show();
    this.accountsService.getPartyIcsAvsOverRide(this.partyIdData).subscribe(res => {
      this.PartyIcsAvsOverRide = res.data;
      this.spinner.hide();
    })
  
  }
  detailPageSummary(portalPageName,description,portalPageId){
    this.router.navigate(['myPortal/detail-preference-summary'], { queryParams: { portalPageName:portalPageName,description:description,portalPageId:portalPageId } });
  }
  updateAVS() {
    this.router.navigate(['partyQuickLink/create-avs-string'], { queryParams: { party: this.partyIdData } });
  }
  resetAvsOverride() {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       
        this.accountsService.resetAvsOverride(this.partyIdData).subscribe(res => {
          this.getPartyIcsAvsOverRide(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
 
 
  
  
  getPreferences() {
    this.spinner.show();
    this.accountsService.getPreferences(this.partyIdData).subscribe(res => {
      this.Preferences = res.data;
      this.spinner.hide();
    })
  
  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
  getOrderListItems(): void {
    this.spinner.show();
    this.accountsService.getOrderList().subscribe((res) => {
      let productStores = res.data.productStores;
      this.spinner.hide();
      for (const value of productStores) {
        this.productStoresListArray.push({
          label: value.storeName,
          value: value.productStoreId,
        });
      }
    });
   
  }
 
  getVisitors() {
    this.spinner.show();
   
    this.accountsService.getVisitors(this.finSize).subscribe(res => {
      this.Visitors = res.data;
      this.spinner.hide();
    })
  }
  
 
     
      
      getPartyLoyaltyPoints() {
        this.spinner.show();
        this.accountsService.getPartyLoyaltyPoints(this.partyIdData).subscribe(res => {
          this.PartyLoyaltyPoints = res.data;
          this.spinner.hide();
        })
      
      }
      getPerson() {
        this.spinner.show();
        this.accountsService.getPerson(this.partyIdData).subscribe(res => {
          this.Person = res.data.personInformation;
          this.spinner.hide();
        })
      
      }
      
      
 
  getPartyAttribute() {
    this.spinner.show();
    this.accountsService.getPartyAttribute(this.partyIdData).subscribe(res => {
      this.PartyAttribute = res.data;
      this.spinner.hide();
    })
  
  }
  getUserLoginData() {
    this.spinner.show();
    this.accountsService.getUserLoginData(this.partyIdData).subscribe(res => {
      this.UserLoginData = res.data;
      this.spinner.hide();
    })
  
  }
 

  
  
  updatePerson() {
    this.router.navigate(['/hr/employees/update-personal-info'], { queryParams: { personId: this.partyIdData } })
  }
  getEmployeeDetail() {
    this.spinner.show();
    this.employeeService.getEmployeeId(this.partyIdData).subscribe(res => {
      this.employeeDetail = res.data;
      res.data.contactInformation.pop();
      this.contactInformation = res.data.contactInformation;
      this.relatedContact = res.data.relatedContacts;
     
      this.emergencyContact = res.data.emergencyContacts;
      this.demoName = res.data.firstName + " " + res.data.lastName;
      this.demoId = res.data.partyId;
      this.employeeDetailArray.push({
        data: this.employeeDetail
      })
      this.employeeDetailArrayEmail.push({
        data: this.employeeDetail
      })
      this.employeeDetailArrayPhone.push({
        data: this.employeeDetail
      })
      
    })
    this.spinner.hide();
  }
  
  
  deletePartyAttribute(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req={
          "attrDescription":product.attrDescription,
          "attrName":product.attrName,
          "attrValue":product.attrValue,
          "partyId":product.partyId,
          
        }
        this.accountsService.deletePartyAttribute(req).subscribe(res => {
          this.getPartyAttribute(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
 
 
 
  accountDetail() {
    this.spinner.show();
    this.accountsService.getAccountById(this.partyIdData).subscribe(res => {
      this.accountDetails = res.data;
     // this.isAdditionalDetail = res.data.additionalInformation.infoType ? true : false;
      this.isJobDetail = res.data.jobInformation.jobStatus ? true : false;
      res.data.contactInformation.pop();
      this.contactInformation = res.data.contactInformation;
      this.relatedContact = res.data.relatedContacts;
      this.accountName = res.data.groupName;
      this.addr = res.data.address.address1 + ' ' + res.data.address.city + ' ' + res.data.address.postalCode + ' ' + res.data.address.countryGeoId;
      this.emailId = res.data.email.infoString;
      this.phoneNumber = res.data.telenumber.countryCode + ' ' + res.data.telenumber.areaCode + ' ' + res.data.telenumber.contactNumber
    })
    this.spinner.hide();
  }

  getPartyRole(){
    this.accountsService.getPartyRole(this.finSize,this.partyIdData).subscribe((res:any)=> {
       this.roleList = res.data;
    })
   }

   getAccountNote() {
    this.spinner.show();
    this.accountsService.getContactNotes(this.partyIdData).subscribe(res => {
      this.accountData = res.data;
      this.spinner.hide();
    });
  }

   deleteRoleTypeId(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
      this.accountsService.deleteRoleTypeId(this.partyIdData,id).subscribe((res:any)=> {
        this._ToastrService.success("Delete Successfully");
        this.getPartyRole();
    },(err) => {
      this._ToastrService.error(err.error.message);
   })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
}

getPartyContentsList() {
  this.spinner.show();
  this.accountsService.getPartyContentList(this.partyIdData).subscribe(res => {
    this.contentList = res.data;
    this.spinner.hide();
  })

}



getBillingAccounts(): void {
  this.spinner.show();
  this._CRMAccountService.getBillingAccounts(this.partyIdData)
    .then(data => {
      if (data.success) {
        this.billingAccounts = data.data.map(value => {
          return {
            ...value, ...{ type: 'BILLING', paymentMethodTypeId: 'BILLING' }
          };
        });
        this.paymentMethods = [...this.paymentMethods, ...this.billingAccounts];
      }
      this.spinner.hide();
    });

}

getPaymentMethods(): void {
  this.spinner.show();
  this._CRMAccountService.getAllPayment(this.partyIdData, false)
    .then(data => {
      if (data.success) {
        this.paymentMethods = data.data.map(value => {
          switch (value.paymentMethod.paymentMethodTypeId) {
            case 'GIFT_CARD':
              return {
                ...value.giftCard, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Gift Card'
                }
              };
              break;
            case 'EFT_ACCOUNT':
              return {
                ...value.eftAccount, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'EFT Account'
                }
              };
              break;
            case 'CERTIFIED_CHECK':
              return {
                ...value.certifiedCheckAccount, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Certified Check'
                }
              };
              break;
            case 'CREDIT_CARD':
              return {
                ...value.creditCard, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Credit Card'
                }
              };
              break;
            case 'COMPANY_CHECK':
              return {
                ...value.companyCheckAccount, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Company Check'
                }
              }
              break;
            case 'PERSONAL_CHECK':
              return {
                ...value.personalCheckAccount, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Personal Check'
                }
              };
          }
        });
        this.getBillingAccounts();
        this.spinner.hide();
      }
    });

}

removePaymentMethod(type: string, paymentMethodId: string): void {
  if (type === 'BILLING') {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._CRMAccountService.removeBillingAccount(this.partyIdData, paymentMethodId)
          .then(data => {
            if (data.success) {
              this.getPaymentMethods();
            }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  } else {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._CRMAccountService.removeGiftCard(paymentMethodId)
          .then(data => {
            if (data.success) {
              this.getPaymentMethods();
            }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
}

paymentMethodNavigation(type: string, paymentMethodId: string): void {
  switch (type) {
    case 'GIFT_CARD':
      this.router.navigate(['/crm/accounts/create-gift-card'], { queryParams: { partyId: this.partyIdData, paymentMethodId } });
      break;
    case 'EFT_ACCOUNT':
      this.router.navigate(['/crm/accounts/create-eft-account'], { queryParams: { partyId: this.partyIdData, paymentMethodId, isEftAccount: true } });
      break;
    case 'CERTIFIED_CHECK':
      this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.partyIdData, paymentMethodId, paymentMethodTypeId: 'CERTIFIED_CHECK' } });
      break;
    case 'CREDIT_CARD':
      this.router.navigate(['/crm/accounts/create-credit-card'], { queryParams: { partyId: this.partyIdData, paymentMethodId } });
      break;
    case 'COMPANY_CHECK':
      this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.partyIdData, paymentMethodId, paymentMethodTypeId: 'COMPANY_CHECK' } });
      break;
    case 'BILLING':
      this.router.navigate([`/crm/accounts/create-billing-account`], { queryParams: { partyId: this.partyIdData, billingAccountId: paymentMethodId } });
      break;
    case 'PERSONAL_CHECK':
      this.router.navigate([`/crm/accounts/create-check-account`], { queryParams: { partyId: this.partyIdData, paymentMethodId, paymentMethodTypeId: 'PERSONAL_CHECK' } });
      break;
  }
}

 

  export(id: any, contentName: any) {
    this.spinner.show();
    this.accountsService.downloadView(id).subscribe(res => {
      const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = contentName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }

  onDelete(contentId, fromDate, partyContent, partyId): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._detailedContactsService.deleteContent(contentId, moment(fromDate).format('yyyy-MM-DD HH:mm:ss.SSS'), partyContent, partyId).subscribe(res => {
          if (res['data'].responseMessage === 'success') {
          }
          this.getPartyContentsList();
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deletePartyIdentification(partyTypeId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deletePartyIdentification(this.partyIdData, partyTypeId).subscribe(res => {
          this.getIdentificationList(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }  
 
  createRoleType(){
    this.router.navigate(["financial/party-role-type"],{ queryParams: { party:  this.partyIdData} });

  }

  createAddRole(){
    this.router.navigate(["financial/party-add-to-role"],{ queryParams: { party:  this.partyIdData} });

  }
  createIdentificationNumber(){
    this.router.navigate(["financial/party-create-identification-number"],{ queryParams: { party:  this.partyIdData}});
  }
  updatePartyIdentification(epartyTypeId,epartyValue){
    this.router.navigate(["financial/party-create-identification-number"],{ queryParams: { party:  this.partyIdData,partyTypeId:epartyTypeId,partyValue:epartyValue}});
  }
  createNewRelationshipType(){
    this.router.navigate(["financial/new-relationship-type"],{ queryParams: { party:  this.partyIdData}});
  }
  UserLoginDatacreateAddOtherpartyRelationship(){
    this.router.navigate(["financial/add-other-party-relationship"],{ queryParams: { party:  this.partyIdData}});
  }
  updateAddOtherpartyRelationship(activity){
    this.router.navigate(["financial/add-other-party-relationship"],{ queryParams: { party:  this.partyIdData,fromDate:activity.fromDate,thruDate:activity.thruDate,comment:activity.data.comments,partyIdFrom:activity.partyIdFrom,isA:activity.isA,partyRelationshipTypeId:activity.data.partyRelationshipTypeId,roleTypeIdFrom:activity.data.roleTypeIdFrom,roleTypeIdTo:activity.data.roleTypeIdTo,securityGroupId:activity.data.securityGroupId,statusId:activity.data.statusId}});
  }
  createEditPartyRates(){
    this.router.navigate(["financial/edit-party-rates"],{ queryParams: { party:  this.partyIdData}});
  }
  createAddPartySkill(){
    this.router.navigate(["financial/add-party-skill"],{ queryParams: { party:  this.partyIdData} });
  }
  onUpdateSkill(eskid, erating, eskill, eyear) {
    this.router.navigate(["financial/add-party-skill"], { queryParams: { party:this.partyIdData, skid: eskid, rate: erating, skill: eskill, year: eyear } })
  }
  createAddPartyContent(){
    this.router.navigate(["financial/add-party-content"], { queryParams: { party:this.partyIdData} });
  }
  createAddPartySegmentRole(){
    this.router.navigate(["financial/add-party-segment-role"], { queryParams: { party:this.partyIdData} });
  }
  createAddPartyResumes(){
    this.router.navigate(["financial/add-party-resumes"], { queryParams: { party:this.partyIdData} });
  }
  createEmployeeAppilcation(){
    this.router.navigate(["partyQuickLink/create-party-employee-application"], { queryParams: { party:this.partyIdData} });
  }
 
  updateResume(eresumeId,econtentId,eresumeDate,eresumeText){
    this.router.navigate(["financial/add-party-resumes"], { queryParams: { party:this.partyIdData,resumeId:eresumeId,contentId:econtentId,resumeDate:eresumeDate,resumeText:eresumeText} });
  }
  updatePassword(loginId){
    this.router.navigate(["partyQuickLink/update-password"], { queryParams: { party:this.partyIdData,loginid:loginId} });
  }
  securityList(loginId){
    this.router.navigate(["partyQuickLink/party-security-group-list"], { queryParams: { party:this.partyIdData,loginid:loginId} });
  }
  
  createEditPersonalInformation(){
    this.router.navigate(["financial/edit-personal-information"]);
  }
  createAddRelatedAccount(){
    this.router.navigate(["financial/add-related-account"],{queryParams:{party:this.partyIdData}});
  }

  createAddPartyClassification(){
    this.router.navigate(["partyQuickLink/create-party-classification"],{queryParams:{party:this.partyIdData}});
  }
  
  createProductStore(){
    this.router.navigate(["partyQuickLink/create-product-store"],{queryParams:{party:this.partyIdData}});
  }
  onUpdateProductStore(product){
    this.router.navigate(["partyQuickLink/create-product-store"],{queryParams:{party:this.partyIdData,fromDate:product.fromDate,thruDate:product.thruDate,roleTypeId:product.data.roleTypeId,sequenceNumber:product.data.sequenceNum,productStoreId:product.data.productStoreId}});
  }
  
  createPartyContact(){
    this.router.navigate(["partyQuickLink/create-party-contact"],{queryParams:{party:this.partyIdData}});
  }
  updateContactList(activity){
    this.router.navigate(["partyQuickLink/create-party-contact"],{queryParams:{party:this.partyIdData,contactId:activity.data.contactListId,preferredContactMechId:activity.data.preferredContactMechId,statusId:activity.data.statusId,fromDate:activity.fromDate,thruDate:activity.thruDate}});
  }
  updatePartyClassification(epartyClassId,fromdate,thrudate){
    this.router.navigate(["partyQuickLink/create-party-classification"],{queryParams:{party:this.partyIdData,partyClassId:epartyClassId,fromDate:fromdate,thruDate:thrudate}});
  }
  
  createAddNewContactInformation(){
    this.router.navigate(["financial/add-new-contact-information"],{queryParams:{party:this.partyIdData}});
  }
  updateData(partyId: string, type: string) {
    switch (type) {
      case 'POSTAL_ADDRESS':
        this.router.navigate(['/crm/accounts/create-postal-address'], { queryParams: { partyId: this.partyIdData, contactMechId: partyId } })
        break;

      case 'TELECOM_NUMBER':
        this.router.navigate(['/crm/accounts/create-contact'], { queryParams: { partyId: this.partyIdData, contactMechId: partyId } })
    }


  }
  createUserLogin(){
    this.router.navigate(["financial/create-user-login"],{ queryParams: { party: this.partyIdData } });
  }
  createPartyAttribute(){
    this.router.navigate(["financial/create-party-attribute"], { queryParams: { party: this.partyIdData } });
  }
  
  updateAttribute(product){
    this.router.navigate(["financial/create-party-attribute"], { queryParams: { party: this.partyIdData,attName:product.attrName,attValue:product.attrValue,attDes:product.attrDescription } });
  }
  createNewPartyNote(){
    this.router.navigate(["financial/new-party-note"], { queryParams: { party: this.partyIdData } });
  }
  onUpdateNotes(enoteId, enoteName, enote) {
    this.router.navigate(['financial/new-party-note'], { queryParams: { noteId: enoteId, party: this.partyIdData, noteName: enoteName, note: enote } })
  }
  onDeleteNotes(noteId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteNote(noteId).subscribe(res => {
          this._ToastrService.success("Delete Successfully");
          this.getAccountNote();
        }, (err) => {
          this._ToastrService.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  createAttachContent(){
    this.router.navigate(["financial/attach-content"],{ queryParams: { party: this.partyIdData } });
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  getRoleById(){
    this.spinner.show();
   
      this.accountsService.getRoleById().subscribe((res:any)=> {
        this.RoleById = res.data;
        this.spinner.hide();
        for(const value of this.RoleById){
          this.RoleByIdArray.push({
            label:value.roleTypeData.description,
            value:value.roleTypeData.roleTypeId
          })
        }
      
      })
     
     }
  getMyTask() {
    this.spinner.show();
    this.accountsService.getMyTask(this.partyIdData).subscribe(res => {
      this.MyTask = res.data;
      this.spinner.hide();
    })

  }
  
 
  createTask(product) {
    this.spinner.show();
    let req = {
      "currentStatusId": "",
      "description": product.myTaskData.description,
      "estimatedCompletionDate": product.estimatedCompletionDate,
      "estimatedHours":"",
      "estimatedStartDate":product.estimatedStartDate,
      "partyId": product.myTaskData.partyId,
      "priority": product.myTaskData.priority,
      "projectId":"",
      "remove": "",
      "roleTypeId": product.myTaskData.roleTypeId,
      "scopeEnumId":product.myTaskData.scopeEnumId,
      "sequenceNum": product.myTaskData.sequenceNum,
      "skillTypeId":"",
      "statusId": product.myTaskData.statusId,
      "toComplete":"",
      "workEffortName": product.myTaskData.workEffortName,
      "workEffortParentId": product.myTaskData.workEffortParentId,
      "workEffortTypeId": product.myTaskData.workEffortTypeId
    }
    this.accountsService.updateTaskAssignment(req).subscribe(res => {
      if (res.success) {
      this.getMyTask();
      this.spinner.hide();
      }
      
    })
    
  }
  assign(product){
    this._Router.navigate(["myPortal/create-task"], { queryParams: {workName:product.myTaskData.workEffortName,workId:product.myTaskData.workEffortId,priority:product.myTaskData.priority,startDate:product.myTaskData.estimatedStartDate,completeDate:product.myTaskData.estimatedCompletionDate} })

  }
  createTaskPortal(){
    this._Router.navigate(["myPortal/portal-create-task"])
  }
  removeMyTasks(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "fromDate": product.fromDate,
          "partyId": product.myTaskData.partyId,
          "roleTypeId": product.myTaskData.roleTypeId,
          "statusId": product.myTaskData.statusId==null?'':product.myTaskData.statusId,
          "workEffortId": product.myTaskData.workEffortId,


        }
        this.accountsService.removeMyTasks(req).subscribe(res => {
          this.getMyTask();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
  updateTask(product) {
    this.spinner.show();
    let req = {
      "fromDate": product.fromDate,
      "partyId": product.myTaskData.partyId,
      "roleTypeId": product.myTaskData.roleTypeId,
      "statusId":"PAS_COMPLETED",
      "workEffortId": product.myTaskData.workEffortId
    }
    this.accountsService.removeMyTasks(req).subscribe(res => {
      if (res.success) {
      this.getMyTask();
      this.spinner.hide();
      }
      
    })

  }
 
  getCrmListDetail() {
    this.spinner.show();
    const data={
      "communicationEventId": "",
      "communicationEventIdSearchType": "Contains",
      "communicationEventTypeId": "",
      "parentCommEventId": "",
      "parentCommEventIdSearchType": "Contains",
      "partyId": "",
      "partyIdFrom": "",
      "partyIdTo":  this.commEventIdPartyId,
      "roleTypeId": "",
      "statusId": "",
      "subject": "",
      "subjectSearchType": ""
    }
   
    this.accountsService.crmMainPageData(data, this.finSize).subscribe((res: any) => {
      this.getOverviewList = res.data;
      this.spinner.hide();

    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
 
      
      accountDetails(id){
        this.router.navigate(['/crm/main-detail-summary'], { queryParams: { commEventId: id} });

      }
   
          replyMail(){
            this.router.navigate(['/crm/new-communication-crm']);
          }
  getSystemInfoNote() {
    this.spinner.show();
    this.accountsService.getSystemInfoNote(this.partyId).subscribe(res => {
      this.SystemInfoNote = res.data.systemInfoNotes;
      this.spinner.hide();
    })
  
  }
  getSystemInfoStatus() {
    this.spinner.show();
    this.accountsService.getSystemInfoStatus(this.partyId).subscribe(res => {
      this.SystemInfoStatus = res.data.systemInfoStatus;
      this.spinner.hide();
    })
  
  }
   
  deleteSystemInfoNote(nodeId){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.id="MYPORTAL_EMPLOYEE5"
        this.accountsService.deleteSystemInfoNote(nodeId,this.id).subscribe((res:any)=> {
          this.getSystemInfoNote();
          this.getSystemInfoStatus();
      },(err) => {
        this._ToastrService.error(err.error.message);
     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )} else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  deleteALL(){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteAllSystemNotes().subscribe((res:any)=> {
          this.getSystemInfoNote();
          this.getSystemInfoStatus();
      },(err) => {
        this._ToastrService.error(err.error.message);
     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )} else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
 
  goToCreatePublicMessage(){
    this._Router.navigate(['/myPortal/create-public-message']);
  }
  communicationEventsPortal() {
    this.spinner.show();
    let req=
      {
        "communicationEventId": "",
        "communicationEventIdSearchType": "Contains",
        "communicationEventTypeId": "",
        "parentCommEventId": "",
        "parentCommEventIdSearchType": "Contains",
        "partyId": "Company",
        "partyIdFrom": "",
        "partyIdTo": "",
        "roleTypeId": "",
        "statusId": "",
        "subject": "",
        "subjectSearchType": "Contains"
      }
    
    this.accountsService.communicationEventsPortal(this.finSize,req).subscribe(res => {
      this.communicationEvents = res.data;
      this.spinner.hide();
    })
  
  }
  portalEditPage(value){
    if(value=='My Payments'){
      this.router.navigate(['/myPortal/my-portal-my-payments']);
    }
    else if(value=='My Leave'){
      this.router.navigate(['/myPortal/my-portal-my-leave']);
    }
    else if(value=='My Trainings'){
      this.router.navigate(['/myPortal/my-portal-my-training']);
    }
    else if(value=='Other Party Comms'){
      this.router.navigate(['/myPortal/my-portal-other-party-comms']);
    }
    else if(value=='Main'){
      this.router.navigate(['/myPortal/main-my-portal']);
    }
    else if(value=='My Comms'){
      this.router.navigate(['/myPortal/portal-communication']);
    }
    else if(value=='My Tasks'){
      this.router.navigate(['/myPortal/my-portal-my-tasks']);
    }
    else if(value=='My Profile'){
      this.router.navigate(['/myPortal/my-portal-my-profile']);
    }
    else if(value=='My Time Sheet'){
      this.router.navigate(['/myPortal/my-portal-my-time-sheet']);
    }
  }
  deleteCommunicationEventId(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       
        this.accountsService.communicationEventsDelete(id).subscribe((res:any)=> {
          this.communicationEventsPortal();
        
      },(err) => {
        this._ToastrService.error(err.error.message);
     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )} else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
 
  GoToQuickLinkCrm(){
    this._Router.navigate(['/crm/main-crm']);
  } 
  getTrainingData() {
    this.spinner.show();
    this.accountsService.getTrainingData(this.finSize).subscribe(res => {
      this.TrainingData = res.data;
      this.spinner.hide();
    })
  
  } 
  getPayment() {
    this.spinner.show();
    this.accountsService.getPayment(this.finSize).subscribe(res => {
      this.payment = res.data;
      this.spinner.hide();
    })
  
  }
  ManagePortalPages() {
    this.spinner.show();
    this.id="MYPORTAL_EMPLOYEE"
    this.accountsService.ManagePortalPages(this.id).subscribe(res => {
      this.PortalPages = res.data;
      this.spinner.hide();
    })
  
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  goToCreatePortalPage(){
    this._Router.navigate(['/myPortal/create-new-portal-page'])
  }
  detailPage(id) {
    this.router.navigate(["/hr/training-approvals/training-summary"], { queryParams: { trainingId: id } })
  }
  portalEdit(value){
    if(value=='My Payments'){
      this.paymentScreen=true;
      this.MyLeaveScreen=false;
      this.Mytraining=false;
      this.otherPartyComm=false;
      this.Main=false;
      this.myComm=false;
      this.myTask=false;
      this.myProfile=false;

    }
    else if(value=='My Leave'){
      this.MyLeaveScreen=true;
      this.paymentScreen=false;
      this.Mytraining=false;
      this.otherPartyComm=false;
      this.Main=false;
      this.myComm=false;
      this.myTask=false;
      this.myProfile=false;
    }
    else if(value=='My Trainings'){
      this.Mytraining=true;
      this.MyLeaveScreen=false;
      this.paymentScreen=false;
      this.otherPartyComm=false;
      this.Main=false;
      this.myComm=false;
      this.myTask=false;
      this.myProfile=false;
    }
    else if(value=='Other Party Comms'){
      this.Mytraining=false;
      this.MyLeaveScreen=false;
      this.paymentScreen=false;
      this.otherPartyComm=true;
      this.Main=false;
      this.myComm=false;
      this.myTask=false;
      this.myProfile=false;

    }
    else if(value=='Main'){
      this.Mytraining=false;
      this.MyLeaveScreen=false;
      this.paymentScreen=false;
      this.otherPartyComm=false;
      this.Main=true;
      this.myComm=false;
      this.myTask=false;
      this.myProfile=false;
    }
    else if(value=='My Comms'){
      this.Mytraining=false;
      this.MyLeaveScreen=false;
      this.paymentScreen=false;
      this.otherPartyComm=false;
      this.Main=false;
      this.myComm=true;
      this.myTask=false;
      this.myProfile=false;
    }
    else if(value=='My Tasks'){
      this.Mytraining=false;
      this.MyLeaveScreen=false;
      this.paymentScreen=false;
      this.otherPartyComm=false;
      this.Main=false;
      this.myComm=false;
      this.myTask=true;
      this.myProfile=false;
    }
    else if(value=='My Profile'){
      this.Mytraining=false;
      this.MyLeaveScreen=false;
      this.paymentScreen=false;
      this.otherPartyComm=false;
      this.Main=false;
      this.myComm=false;
      this.myTask=false;
      this.myProfile=true;
    }
  }
  onUpdate(epartyId, eleaveTypeId, efromDate, ethruDate, eAppId, eDescription, eReasonid) {


    this._Router.navigate(['/myPortal/portal-leave-summary'], { queryParams: { partyId: epartyId, leaveTypeId: eleaveTypeId, fromDate: efromDate, thruDate: ethruDate, AppId: eAppId, Description: eDescription, Reasonid: eReasonid, approval: false } })
  }

  GoToQuickLink(){
    this._Router.navigate(['/myPortal/create-leave']);
  } 
  getMyLeave() {
    this.spinner.show();
    this.accountsService.getMyLeave(this.finSize).subscribe(res => {
      this.MyLeave = res.data;
      this.spinner.hide();
    })
  
  }
  
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

