import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from 'src/app/app.constant';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/api.service';
@Injectable({
  providedIn: 'root'
})
export class ApprovalService {

  constructor(private http: HttpClient, private _ApiService: ApiService) { }

  getInternalJobList(job, data) {
    const getInternalJobList = URLS.getInternalJobPosting + "?pageSize=" + job.pageSize + "&pageNo=" + job.pageNo + "&search=" + job.search;
    return this.http.post(getInternalJobList, data);
  }


  updateApproval(applicationId: string, formData: any): Promise<any> {
    return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/hr/internalJobPosting/jobApproval/${applicationId}`, formData);
  }
  getApprovalStatus(): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/hr/internalJobPosting/jobApprovalStatus`);
  }



}
