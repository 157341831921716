<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;" (click)="cancelSubmit()">Employment Application</span>
                <span class="color-black pl-1"> > New</span>

                <button type="submit" (click)="cancelSubmit()" class="btn btn-danger buttonclass">Cancel</button>

            </div>
            <div class="w3-card-4 classCard">
                <div class=" bg-white color-grey">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)"><a>
                                    New Employment Application </a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)"><a>
                                    Personal </a></li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)"><a>
                                    Desired Position </a></li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)"><a>
                                    Education </a></li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)"><a>
                                    General </a></li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)"><a>
                                    References</a></li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategory(8)"><a>
                                    Employment History </a></li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategory(9)"><a>
                                    To Be Completed By The Company</a></li>

                        </ul>
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div *ngIf="activeCategory==2">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            NEW EMPLOYMENT APPLICATION
                                                        </h4>


                                                    </div>

                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <form class="w-100" *ngIf="createEventFormService.employee_application_form" [formGroup]="createEventFormService.employee_application_form">

                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Application
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="applicationId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Application Id">


                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Empl
                                                                                PositionId</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" placeholder="Empl Position name" formControlName="emplPositionId" [options]="positionArray" optionlabel="label">
                                                                            </p-dropdown>


                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Status
                                                                                Id<span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" placeholder="Status Id" formControlName="statusId" [options]="statusArray" optionlabel="label">
                                                                            </p-dropdown>

                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Employment
                                                                                App Source Type
                                                                                Id<span
                                                                                    style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown class="lists" placeholder="Employment App Source Type Id" filter="true" formControlName="employmentAppSourceTypeId" [options]="appSourceArray" optionlabel="label">
                                                                            </p-dropdown>


                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Applying
                                                                                Party Id<span
                                                                                    style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown placeholder="Applying Party Id" class="lists" filter="true" formControlName="applyingPartyId" [options]="partyIdArray" optionlabel="label">
                                                                            </p-dropdown>


                                                                        </div>


                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Application
                                                                                Date<span
                                                                                    style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" formControlName="applicationDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >



                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Referred By
                                                                                Party ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown placeholder="Refered By Party Id" class="lists" filter="true" formControlName="referredByPartyId" [options]="partyIdArray" optionlabel="label">
                                                                            </p-dropdown>

                                                                        </div>
                                                                        <div class="col-lg-2"></div>

                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 27%;">
                                                                <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Continue</button>
                                                                <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            PERSONAL INFORMATION

                                                        </h4>


                                                    </div>


                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <form class="w-100" [formGroup]="createEventFormService.employee_application_form">

                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Name
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="name" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name">


                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Social
                                                                                Security Number
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="socailSecurityNumber" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Social Security Number">

                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Address
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="address" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address">

                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Telephone
                                                                                Number

                                                                                <span style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="telephoneNo" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Telephone Number">


                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Other Phone
                                                                                number
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="OtherPhonenumber" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Other Phone Number">


                                                                        </div>


                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Have you
                                                                                ever been convicted of a felony/crime
                                                                                <span style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" placeholder="Choose Your Answer" formControlName="crime" name="emplPositionId">
                                                                            </p-dropdown>




                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">If Yes,
                                                                                Please Explain
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="reason" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reason">

                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Date

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" formControlName="empDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Date ">

                                                                        </div>

                                                                    </div>
                                                                </div>



                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 27%;">
                                                                <button type="submit" class="btn btn-secondary submit-btn">Continue</button>
                                                                <!-- <button type="submit" *ngIf="show"
                                                                    class="btn btn-secondary submit-btn">Update</button> -->

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==4">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            DESIRED POSITION
                                                        </h4>


                                                    </div>

                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <form class="w-100" [formGroup]="createEventFormService.employee_application_form">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Position
                                                                                Applied For
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown placeholder="Enter Position Applied For" filter="true" formControlName="PositionAppliedFor">
                                                                            </p-dropdown>


                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Date
                                                                                available for work
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" formControlName="DateAvailable" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Date available for work">

                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">How Were You
                                                                                Referred To Us
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown placeholder="Choose Your Answer" filter="true" formControlName="referred">
                                                                            </p-dropdown>

                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Desire Pay
                                                                                Rate


                                                                                <span style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="payRate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Pay Rate">


                                                                        </div>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 24%;">
                                                                <button type="submit" class="btn btn-secondary submit-btn">Continue</button>
                                                                <!-- <button type="submit" *ngIf="show"
                                                                    class="btn btn-secondary submit-btn">Update</button> -->

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==5">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            EDUCATION
                                                        </h4>


                                                    </div>

                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <form class="w-100" [formGroup]="createEventFormService.employee_application_form">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class='' style="color: black;">List educational qualifications you believe will help you perform the job for which you are applying.</div>
                                                                    <div class="row mt-4">
                                                                        <div class="col-lg-3 form-group classInput ">
                                                                            <label for="exampleInputEmail1">School
                                                                                Address-1

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">

                                                                            <input type="text" formControlName="SchoolAddress" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter  School Address">

                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Degree/Diploma-1

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" formControlName="degree" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Degree/Dimloma">

                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">School
                                                                                Address-2

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">

                                                                            <input type="text" formControlName="SchoolAddressTwo" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter School Address">

                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Degree/Diploma-2

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" formControlName="degreeTwo" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Degree/Dimloma">

                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">School
                                                                                Address-3

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">

                                                                            <input type="text" formControlName="SchoolAddressThree" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter School Address">

                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Degree/Diploma-3

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" formControlName="degreeThree" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Degree/Dimloma">

                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">School
                                                                                Address-4

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">

                                                                            <input type="text" formControlName="SchoolAddressFour" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter School Address">

                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Degree/Diploma-4

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" formControlName="degreeFour" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Degree/Dimloma">

                                                                        </div>


                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 28%;">
                                                                <button type="submit" class="btn btn-secondary submit-btn">Continue</button>
                                                                <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn">Update</button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==6">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            GENERAL INFORMATION
                                                        </h4>


                                                    </div>

                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <form class="w-100" [formGroup]="createEventFormService.employee_application_form">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputB">
                                                                            <label for="exampleInputEmail1">Professional
                                                                                License Held

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">

                                                                            <input type="text" formControlName="ProfessionalLicense" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Professional License Held">


                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Expiration
                                                                                Date

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" formControlName="Expiration" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Date available for work">

                                                                        </div>


                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputB">
                                                                            <label for="exampleInputEmail1">Have you
                                                                                ever worked or filed and application
                                                                                here before?
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown placeholder="Choose Your Answer" filter="true" formControlName="applicationFilled">
                                                                            </p-dropdown>

                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">If Yes,
                                                                                Please list dates and explain


                                                                                <span style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="explain" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Pay Rate">


                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputB">
                                                                            <label for="exampleInputEmail1">What is your
                                                                                means of transportation to work?
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown placeholder="Choose Your Answer" filter="true" formControlName="transportation">
                                                                            </p-dropdown>

                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Do you have
                                                                                a driver’s license?


                                                                                <span style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown placeholder="Choose Your Answer" filter="true" formControlName="license">
                                                                            </p-dropdown>

                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputB">
                                                                            <label for="exampleInputEmail1">Driver’s
                                                                                License Number
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="LicenseNumber" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Driver’s License Number ">

                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">State of
                                                                                Issue


                                                                                <span style="color:red">*</span></label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="Issue" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter State of Issue">


                                                                        </div>

                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputB">
                                                                            <label for="exampleInputEmail1">Expiration
                                                                                Date
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" formControlName="ExpirationDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Expiration Date ">

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="main-submit-button" style="margin-right: 18%;">
                                                                <button type="submit" class="btn btn-secondary submit-btn">Continue</button>
                                                                <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn">Update</button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==7">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            REFERENCES
                                                        </h4>


                                                    </div>

                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <form class="w-100" [formGroup]="createEventFormService.employee_application_form">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">

                                                                    <div style="color: black;">Please list two references other than relatives or previous employers</div>

                                                                    <div class="row mt-4">
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Name-1

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" formControlName="nameOne" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name ">

                                                                        </div>


                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Name-2

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">

                                                                            <input type="text" formControlName="nameTwo" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name ">

                                                                        </div>




                                                                    </div>
                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">

                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Address-1

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" formControlName="addressOne" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address ">

                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Address-2

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" formControlName="secondAddress" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address ">

                                                                        </div>

                                                                    </div>

                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">

                                                                        <div class="col-lg-3 form-group classInput">

                                                                            <label for="exampleInputEmail1">Telephone
                                                                                Number-1
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="TelephoneNumber" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Telephone Number">


                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput">

                                                                            <label for="exampleInputEmail1">Telephone
                                                                                Number-2
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="TelephoneNumberTwo" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Telephone Number">


                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="main-submit-button" style="margin-right:36%;">
                                                                <button type="submit" class="btn btn-secondary submit-btn">Continue</button>
                                                                <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn">Update</button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==8">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            EMPLOYMENT HISTORY
                                                        </h4>
                                                    </div>

                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <form class="w-100" [formGroup]="createEventFormService.employee_application_form">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputD">
                                                                            <label for="exampleInputEmail1">Employer

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown placeholder="Enter Employer" filter="true" formControlName="Employer">
                                                                            </p-dropdown>


                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Type of
                                                                                Business


                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" formControlName="TypeofBusiness" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Type of Business ">

                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputD">
                                                                            <label for="exampleInputEmail1">Address
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="EmpAddress" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Address ">


                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Phone
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="Phone" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Phone ">


                                                                        </div>


                                                                    </div>

                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputD">
                                                                            <label for="exampleInputEmail1">Titles and
                                                                                Duties
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="TitlesDuties" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Titles and Duties ">


                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Rate Pay
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="RatePay" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Rate Pay ">


                                                                        </div>


                                                                    </div>

                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputD">
                                                                            <label for="exampleInputEmail1">Period
                                                                                Employed
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="PeriodEmployed" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Period Employed  ">


                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Reason for
                                                                                Leaving
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="ReasonforLeaving" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reason for Leaving  ">


                                                                        </div>


                                                                    </div>

                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputD">
                                                                            <label for="exampleInputEmail1">Supervisor’s
                                                                                Name and Title
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="SupervisorName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Supervisor’s Name and Title  ">


                                                                        </div>




                                                                    </div>

                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInput" style="margin-left: -3%;">
                                                                            <p style="color: #000!important;"> Please Read Carefully</p>

                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput__new_changes" style="text-align: left!important;">

                                                                            <button class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModal">Click Here To Read
                                                                               
                                                                          </button>
                                                                        </div>





                                                                    </div>

                                                                </div>


                                                            </div>

                                                            <div class="main-submit-button" style="margin-right: 22%;">
                                                                <button type="submit" class="btn btn-secondary submit-btn">Continue</button>
                                                                <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn">Update</button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==9">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            TO BE COMPLETED BY THE COMPANY
                                                        </h4>
                                                    </div>

                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <form class="w-100" [formGroup]="createEventFormService.employee_application_form">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputC">
                                                                            <label for="exampleInputEmail1">Position

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown class="lists" placeholder="Employment App Source Type Id" filter="true" formControlName="position" optionlabel="label">
                                                                            </p-dropdown>


                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Department


                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown placeholder="Enter Department" filter="true" formControlName="Department">
                                                                            </p-dropdown>


                                                                        </div>
                                                                    </div>


                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputC">
                                                                            <label for="exampleInputEmail1">Date of Hire
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" formControlName="dateHire" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Date of Hire ">


                                                                        </div>

                                                                        <div class="col-lg-3 form-group classInput__new_changes">
                                                                            <label for="exampleInputEmail1">Salary
                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="Salary" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Salary ">


                                                                        </div>


                                                                    </div>

                                                                </div>

                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputC">
                                                                            <label for="exampleInputEmail1">Comments

                                                                                <span style="color:red">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" formControlName="Comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments ">

                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            </div>

                                                            <div class="main-submit-button" style="margin-right: 32%;">
                                                                <button type="submit" class="btn btn-secondary submit-btn">Submit</button>
                                                                <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn">Update</button>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Document</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                         Document  </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''">
                                    <a>
                                    </a>
                                </li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <h4 class="document mt-2">Please Read this Document Carefully</h4>
                                        <p>
                                            I hereby certify that the facts set forth in this employment application (and accompanying resume, if any) are true and complete to the best of my knowledge, and I agree and understand that any misrepresentation of information or failure to disclose information
                                            on this employment application may disqualify me from further consideration for employment and, if employed, will subject me to dismissal. I am aware that a more detailed investigation concerning background
                                            and credit may also be conducted, if applicable to the job for which I am applying, and I hereby authorize such an investigation. I understand that employment is contingent upon satisfactory completion of reference
                                            checks and that, upon my written request, information on the nature and scope of an inquiry, if one is made, will be provided to me. Should a job offer be made, I consent to taking a pre-placement physical examination
                                            and such future examination as may be required by Phyrst. I understand that any job offer or my continuing employment, if hired is contingent upon being physically, mentally, and medical able, with or without
                                            reasonable accommodation, to successfully perform the essential functions of my job. I agree that the results of my medical/health screen may be released to appropriate agencies in the event of a worker’s compensation
                                            injury and/or dispute on payment of a medical claim. I understand that as part of my pre-placement physical examination, upon which any offer of employment is contingent, I may be required to successfully pass
                                            a drug screening test. The test will be administered at Phyrst expense, and will require me to provide a urine specimen for analysis. The urine specimen will be analyzed for the presence of marijuana, cocaine,
                                            phencyclidine (PCP), opiates and amphetamines. Results of the drug test are confidential, and will not be disclosed to others without specific written consent. My signature below specifically signifies my consent
                                            to this pre-placement drug screening test. I agree to wear or use all protective clothing or devices required by the facility and to comply with all safety policies and procedures. I understand that nothing
                                            contained in this employment application is intended to lead to or to create an employment contract between Phyrst or any subsidiary or affiliate and myself which would in any way restrict the right of the company
                                            to terminate my employment at will. I further understand and agree that the employment relationship that may result from my application will be employment-at-will, and either I or Phyrst or any subsidiary or
                                            affiliate and I may terminate the relationship at any time. I understand that any omission, misrepresentation of falsification can be grounds for refusal of employment. I further understand that, if employed,
                                            any false statements or misrepresentations herein or in conjunction with the application may be a cause for dismissal. I understand that any and all disputes regarding my employment with Phyrst, including and
                                            dispute relating to the termination of my employment are subject to Phyrst fair treatment process by utilizing the grievance procedure, and I also understand and agree as a condition of employment and continued
                                            employment, to submit any such disputes for resolution under that process, and I further agree to abide by and accept the decision of the grievance panel as the final and binding decision and resolution of any
                                            such disputes I may have.
                                        </p>
                                    </div>
                                    <form class="w-100 mt-3" [formGroup]="createEventFormService.employee_application_form">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Applicant’s Signature

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="file" formControlName="Signature" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Signature">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Date

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="signDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Date">

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="main-submit-button" style="margin-right: 10%;">
                                            <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn">Cancel</button>
                                            <button type="submit" *ngIf="!show" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                    <div class="col-lg-12 mb-5 mt-3">
                                        <p>Phyrst, offers equal employment opportunity to all applicants and to all employees regardless of sex, age, race, color, religion, creed, national origin, ancestry, marital status, sexual orientation, or disability.</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>





<ngx-spinner></ngx-spinner>