
<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;" (click)="navigate()">Timesheet Summary </span>
                <span class="color-black pl-1"> > Add Role</span>
                <button type="submit" (click)="navigate()" class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class="w3-card-4 classCard" >
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Add New Role </a></li>
                       
                     
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form [formGroup]="fileUploadForm" class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Role</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown formControlName="roleTypeId" [options]="roleArray" optionlabel="label" id="exampleInputEmail1" filter="true" placeholder="Select Role"></p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Party</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" placeholder="Enter Party" formControlName="partyId" [options]="partyIdArray"
                                                optionlabel="label"></p-dropdown>
                                                    

                                            </div>
                                           
                                        </div>
                                    </div>
                                   
                                  
                                </div>
                                <div class="main-submit-button" style="margin-right: 37%;">
                                    <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>
                                                  
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
            </div>
          
        </div>
    </div>

</div>



<ngx-spinner></ngx-spinner> 



        