import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerAgreementsService } from '../customer-agreements/customer-agreements.service';
import { NewWarehouseShipmentService } from 'src/app/warehouse/new-warehouse-shipment/new-warehouse-shipment.service';
import * as moment from 'moment';
import { AccountingApService } from '../../accounting-ap/accounting-ap.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-update-customer-agreements',
  templateUrl: './update-customer-agreements.component.html',
  styleUrls: ['./update-customer-agreements.component.css']
})
export class UpdateCustomerAgreementsComponent implements OnInit {

  updateCustomerAgreements: FormGroup;
  PersonsAndPartyGroupsList: any = [];
  productList: any = [];
  AgreementTypeList: any = [];
  RoleTypesList: any = [];
  editMode: boolean;
  agreementId: any;
  agreementType: string;
  agreementValue: any;
  activeCategory = 2;
  constructor(
    readonly _AccountingApService: AccountingApService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly CustomerAgreementsService: CustomerAgreementsService,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder) {
    this.updateCustomerAgreements = this._FormBuilder.group({
      agreementTypeId: [''],
      agreementDate: [''],
      description: [''],
      fromDate: [''],
      partyIdFrom: [''],
      partyIdTo: [''],
      productId: [''],
      roleTypeIdFrom: [''],
      roleTypeIdTo: [''],
      textData: [''],
      thruDate: [''],
      agreementId: ['']
    });
    this.editMode = false;
    this.agreementType = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.agreementId = this._ActivatedRoute.snapshot.queryParams.agreementID;
    this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    this.getPersonsAndPartyGroups();
    this.getProducts();
    this.getRoleTypes();
    this.getAgreementTypeList();

    this.getAgreementById();

  }


  submit(): void {
    this.spinner.show();
    this.CustomerAgreementsService.updateAgreement(this.agreementId, {
      ...this.agreementValue, ...this.updateCustomerAgreements.value, ...{
        fromDate: this.updateCustomerAgreements.value.fromDate ? moment(this.updateCustomerAgreements.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDate: this.updateCustomerAgreements.value.thruDate ? moment(this.updateCustomerAgreements.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : '',
        agreementDate: this.updateCustomerAgreements.value.agreementDate ? moment(this.updateCustomerAgreements.value.agreementDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    }).then((res: any) => {
      
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate([`/financial/${this.agreementType}/customer-agreeement`]);
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
    });
   
  }

  getPersonsAndPartyGroups() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getPersonsAndPartyGroups().subscribe((responce) => {

      this.PersonsAndPartyGroupsList = responce.data.PersonsGroups.map(value => {
        return {
          label: value.partyId,
          value: value.partyId
        };
      });
    });
    this.spinner.hide();
  }

  getProducts() {
    this.spinner.show();
    const requestData = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains"
    };
    this.CustomerAgreementsService.getProducts(requestData).subscribe((responce) => {

      this.productList = responce.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
    });
    this.spinner.hide();
  }

  getRoleTypes() { this.spinner.show();
    this.CustomerAgreementsService.getRoleTypes().subscribe((responce) => {

      this.RoleTypesList = responce.data.map(value => {
        return {
          label: value.roleTypeId,
          value: value.roleTypeId
        };
      });
    });
    this.spinner.hide();
  }

  getAgreementTypeList() {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementTypeList().subscribe((responce) => {

      this.AgreementTypeList = responce.data.map(value => {
        return {
          label: value.agreementTypeId,
          value: value.agreementTypeId
        };
      });
    });
    this.spinner.hide();
  }

  getAgreementById(): void {
    this.spinner.show();
    this._AccountingApService.getAgreementById(this.agreementId).then(data => {
      this.agreementValue = data.data;
     
      this.updateCustomerAgreements.patchValue({
        ...data.data, ...{
          fromDate: data.data.fromDate ? new Date(data.data.fromDate) : '',
          thruDate: data.data.thruDate ? new Date(data.data.thruDate) : '',
          agreementDate: data.data.agreementDate ? new Date(data.data.agreementDate) : ''
        }
      });
      this.spinner.hide();
    });
  
  }

  getdata(data) {
    this.updateCustomerAgreements.patchValue({
      facilityGroupTypeId: data.facilityGroupTypeId,
      facilityGroupName: data.facilityGroupName,
      description: data.description,
      facilityGroupId: data.facilityGroupId,
    });
  }
  reset(): void {
    this.updateCustomerAgreements.reset();
    this.router.navigate([`/financial/${this.agreementType}/customer-agreeement`]);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }

}

