import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';

import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.css']
})
export class CreateTaskComponent implements OnInit {
  EnabledArray=[{
    "label":"Y",
    "value":"Y"
  },
  {
    "label":"N",
    "value":"N"
  }]
  assignTask:FormGroup;
  addTask:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  identification: any;
  identificationArray: any[]=[];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  ClassificationGroupId: any;
  ClassificationGroupIdArray: any[]=[];
  fromDate: string;
  thruDate: string;
  partyClassId: any;
  efromDate: any;
  ethruDate: any;
  getthruDate: string;
  DateTime: string;
  loginid: any;
  priority=[{
    "label":"1 (High)",
    "value":"1"
  },{
    "label":"2",
    "value":"2"
  },{
    "label":"3",
    "value":"3"
  },{
    "label":"4",
    "value":"4"
  },{
    "label":"5",
    "value":"5"
  },{
    "label":"6",
    "value":"6"
  },{
    "label":"7",
    "value":"7"
  },{
    "label":"8",
    "value":"8"
  },{
    "label":"9 (Low)",
    "value":"9"
  }]
  taskArray=[{
    "label":"Task",
    "value":"TASK"
  },
  {
    "label":"Milestone",
    "value":"MILESTONE"
  }]
  Scope: any;
  ScopeArray: any[]=[];
  SkillType: any;
  SkillTypeArray: any[]=[];
  TaskName: any;
  TaskNameArray: any[]=[];
  RoleById: any;
  RoleByIdArray: any[]=[];
  actualfromdate: string;
  actualthroughdate: string;
  workName: any;
  workId: any;
  priorityId: any;
  getEstimate: string;
  getEstimateDate: string;
  startDate: any;
  completeDate: any;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,) { 
      this.partyId=localStorage.getItem("partyId")
      this.contentIds = [];
      this.assignTask = this._FormBuilder.group({
        TaskName: [''],
        RoleTypeID: [''],
      
       
        
      });
     
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
  
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workName = params["workName"];
      this.workId=params["workId"];
      this.priorityId=params["priority"];
      this.startDate=params["startDate"]
      this.completeDate=params["completeDate"]

    });
    
  /*   setTimeout(() => {
      const fromdate= this.startDate
    this.getEstimate=this.datePipe.transform(fromdate,"yyyy-MM-dd");
    const toEstdate=this.completeDate
    this.getEstimateDate=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
      const formValue = this.addTask;
    formValue.patchValue({
      Priority:this.priorityId, 
        EstimatedStartDate: this.startDate,
        EstimatedCompletionDate:this.completeDate
    })
    }, 3000);  */
    setTimeout(() => {
      const formValue = this.assignTask;
    formValue.patchValue({
      TaskName: this.workName,
    })
    }, 3000); 
  this.getScope();
  this.getSkillType();
  this.getTaskName();
  this.getRoleById();
 
  }
  getRoleById(){
    this.spinner.show();
   
      this.accountsService.getRoleById().subscribe((res:any)=> {
        this.RoleById = res.data;
        this.spinner.hide();
        for(const value of this.RoleById){
          this.RoleByIdArray.push({
            label:value.roleTypeData.description,
            value:value.roleTypeData.roleTypeId
          })
        }
      
      })
     
     }
  getTaskName(){
    this.spinner.show();
   
      this.accountsService.getParentPhase().subscribe((res:any)=> {
        this.TaskName = res.data;
        this.spinner.hide();
        for(const value of this.TaskName){
          this.TaskNameArray.push({
            label:value.getParentPhaseList.projectName + '-' +value.getParentPhaseList.phaseName,
            value:value.getParentPhaseList.phaseId
          })
        }
      
      })
     
     }
  getSkillType(){
    this.spinner.show();
   
      this.accountsService.getSkillType().subscribe((res:any)=> {
        this.SkillType = res.data;
        this.spinner.hide();
        for(const value of this.SkillType){
          this.SkillTypeArray.push({
            label:value.skillTypeData.description,
            value:value.skillTypeData.skillTypeId
          })
        }
      
      })
     
     }
  getScope(){
    this.spinner.show();
   
      this.accountsService.getScope().subscribe((res:any)=> {
        this.Scope = res.data;
        this.spinner.hide();
        for(const value of this.Scope){
          this.ScopeArray.push({
            label:value.scopeData.description,
            value:value.scopeData.enumId
          })
        }
      
      })
     
     }
     
     onSubmitTask() {
      this.spinner.show();
     
      const requestData = {
        "partyId":  this.partyId,
        "roleTypeId":this.assignTask.value.RoleTypeID,
        "statusId": "PAS_ASSIGNED",
        "workEffortId":  this.workId
       
   
      }
      this.accountsService.addTaskAssignment(requestData).subscribe((res: any) => {
        if (res.success) {
          this._ToastrService.success("Created Successfully");
          this._Router.navigate(['/myPortal/my-portal-my-tasks']);
          this.spinner.hide();
         
      }}, (err) => {
        this.spinner.hide();
        if(err.error.errors){
          for (const value of err.error.errors) {
            this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
          }
         
    
          }
          else{
            this._ToastrService.error(err.error.debugMessage);
          }
      });}

  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
 }


