import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
    import { Location } from '@angular/common';
    import { ToastrService } from 'ngx-toastr';

import { AccountsService } from 'src/app/crm/accounts/accounts.service';

import { ProductionService } from '../production.service';
@Component({
  selector: 'app-create-cost-cacl',
  templateUrl: './create-cost-cacl.component.html',
  styleUrls: ['./create-cost-cacl.component.css']
})
export class CreateCostCaclComponent implements OnInit {
  createCost: FormGroup;
  activeCategory = 2;
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[] = [];
  varianceReasonId: any;
  varianceReasonIdArray: any[] = [];
  glAccountid: any;
  varianceReasonIdData: any;
  show: boolean;
  budgetId: any;
  personId: any;
  partyIdArray: any[] = [];
  ReviewResultTypes: any;
  ReviewResultTypesArray: any[] = [];
  reviewDate: string;
  currencyArray: any[]=[];
  costCustomMethodID: any;
  costCustomMethodIDArray: any[]=[];
  CalcId: any;
  constructor(readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
   
    readonly spinner: NgxSpinnerService,
    readonly productionService:ProductionService,
    readonly accountsService: AccountsService,  private _location: Location,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) {
    this.createCost = this._FormBuilder.group({
      Description: [''],
      FixedCost: [''],
      VariableCost: [''],
      PerMilliSecond: [''],
      Currency: [''],
      CostCustomMethodID: [''],


    });

  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.CalcId = params["CalcId"];
    
    
    });
  this.getCurrency();
  this.getInitialDataCost();
  
  if(this.CalcId){
    this.show=true;
    this.getProdCostById();
  }else{
    this.show=false;
  }
  
  }
  getProdCostById() {
    this.spinner.show();
   
    this.productionService.getProdCostById(this.CalcId).subscribe((res: any) => {
      this.spinner.hide();
      setTimeout(() => {
      
        const formValue = this.createCost;

        formValue.patchValue({
         
        Description: res.data.description,
        FixedCost: res.data.fixedCost,
        VariableCost: res.data.variableCost,
        PerMilliSecond: res.data.perMilliSecond,
        Currency: res.data.currencyUomId,
        CostCustomMethodID: res.data.costCustomMethodId,
        })
      
      }, 3000);
    })
  
  }
  
  getInitialDataCost() {
    this.spinner.show();
    this.accountsService.getInitialDataCost().subscribe(res => {
      
      this.costCustomMethodID = res.data.costCustomMethodID;
    
      this.spinner.hide();
     
      for (const value of this.costCustomMethodID) {
        this.costCustomMethodIDArray.push({
          label: value.description,
          value: value.customMethodId
        })
      }
     
    })
   
  }
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "costComponentCalcId":"",
      "costCustomMethodId": this.createCost.value.CostCustomMethodID,
      "costGlAccountTypeId":"",
      "currencyUomId": this.createCost.value.Currency,
      "description":this.createCost.value.Description,
      "fixedCost":this.createCost.value.FixedCost,
      "offsettingGlAccountTypeId": "",
      "perMilliSecond": this.createCost.value.PerMilliSecond,
      "variableCost": this.createCost.value.VariableCost,
    }

    this.productionService.postProdCost(requestData).subscribe((res: any) => {
     
      if (res.success) {
      
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(['/production/cost-cacls-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onUpdate() {
    this.spinner.show();
    
    const requestData = {
      "costComponentCalcId":this.CalcId,
      "costCustomMethodId": this.createCost.value.CostCustomMethodID,
      "costGlAccountTypeId":"",
      "currencyUomId": this.createCost.value.Currency,
      "description":this.createCost.value.Description,
      "fixedCost":this.createCost.value.FixedCost,
      "offsettingGlAccountTypeId": "",
      "perMilliSecond": this.createCost.value.PerMilliSecond,
      "variableCost": this.createCost.value.VariableCost,
    }

    this.productionService.updateProdCost(requestData).subscribe((res: any) => {
     
      if (res.success) {
      
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(['/production/cost-cacls-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  homeButton(){
    this._Router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
