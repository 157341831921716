import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { FinancialService } from 'src/app/services/financial.service';

import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-asset-facility',
  templateUrl: './asset-facility.component.html',
  styleUrls: ['./asset-facility.component.css']
})
export class AssetFacilityComponent implements OnInit {
  products3: any;
  @ViewChild('closebutton') closebutton;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= "";
  advanceSearch: {

    FacilityTypeID: string,
    ParentFacilityID: string,
    FacilityName: string,
    FacilityID: string,



  };
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  fixedAssetsInitialDataArray: any[] = [];
  getFixedAssets: any;
  show: boolean;
  createFixAssetForm: FormGroup;
  fixedAssetTypes: any[];
  classEnumId: any[];
  calendar: any[];
  partyIds: any[];
  activeCategory = 2;
  productIds: any[];
  fixAssetIds: any[];
  uomIds: any[];
  roleTypeIds: any[];
  facilityId: string;
  facilityLocation: any[];
  fixAssetId: string;
  fixAsset: any;
  editMode: boolean;
  createTaskList:FormGroup;
  facilities: any[];

 
  facilityForm: FormGroup;
  inventoryData: any[];
  facilityType: any[];
  weightData: any[];
  areaData: any[];
  facilityIds: any[];
 
  facilityData: any;
  
  url: string;
  dataFacilities: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FinancialService: FinancialService,

    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
   
    readonly _AccountingApService: AccountingApService,
    readonly _WarehouseService: WarehouseService,
    readonly addSkillService: AddSkillService,
    private _location: Location,
    ) {
      this.createTaskList = this._FormBuilder.group({
        EstimatedStartDate: [''],
        EstimatedCompletionDate:[''],
        Status:[''],
        MaintenanceType:[''],
        ProductMaintenance:[''],
        IntervalMeterType: [''],
        MaintenanceTemplate:[''],
        PurchaseOrderID:[''],
        IntervalQuantity:[''],
        PartyID:[''],
        IntervalUom:[''],
        ActualCompletionDate:[''],
        ActualStartDate:['']
       
        
      });
      this.facilities = [];
      this.facilityId = '';
  
      this.url='/facilities/warehouse/'
      this.inventoryData = [];
      this.facilityType = [];
      this.weightData = [];
      this.areaData = [];
      this.facilityForm = this._FormBuilder.group({
        defaultDaysToShip: [''],
        defaultInventoryItemTypeId: [null],
        defaultWeightUomId: [null],
        description: [''],
        facilityName: ['', [Validators.required]],
        facilitySize: [''],
        facilitySizeUomId: [null],
        facilityTypeId: [null, [Validators.required]],
        ownerPartyId: [null, [Validators.required]],
        parentFacilityId: [null]
      });
      this.facilityIds = [];
      this.partyIds = [];
      this.facilityId = '';
      this.editMode = false;
      this.advanceSearch = {
 
        FacilityTypeID: "",
        FacilityName: "",
        ParentFacilityID: "",
        FacilityID: "",
      };
    }

  ngOnInit(): void {
    this.getFacility();
    this.getFacilityType();
    this.getInventory();
    this.getWeight();
    this.getArea();
    this.getFacilityId();
    this.getPartyGroup();
    this.findFacilities();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  
  
  findFacilities() {
    this.spinner.show();
    let req = {
      "facilityId": "",
      "facilityName": "",
      "facilityTypeId": "",
      "parentFacilityId": "",

    }
    this.accountsService.findFacilities(req).subscribe((res: any) => {
      this.dataFacilities = res.data;
      this.spinner.hide();
    })
  
  }
  advanceSearchContacts(): void {
    this.spinner.show();
    this.dataFacilities = [];
    const req = {
      "parentFacilityId": this.advanceSearch.ParentFacilityID ? this.advanceSearch.ParentFacilityID : '',
      "facilityTypeId": this.advanceSearch.FacilityTypeID ? this.advanceSearch.FacilityTypeID : '',
      "facilityName": this.advanceSearch.FacilityName ? this.advanceSearch.FacilityName : '',
      "facilityId": this.advanceSearch.FacilityID ? this.advanceSearch.FacilityID : '',
     
    }
    this.accountsService.findFacilities(req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.dataFacilities = resp.data;
        } 
      });
     
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.FacilityID='';
    this.advanceSearch.FacilityName='';
    this.advanceSearch.FacilityTypeID='';
    this.advanceSearch.ParentFacilityID='';
    let req = {
      "facilityId": "",
      "facilityName": "",
      "facilityTypeId": "",
      "parentFacilityId": "",

    }
    this.accountsService.findFacilities(req).subscribe((res: any) => {
      this.dataFacilities = res.data;
      this.spinner.hide();
    })
  
  }
  getFacilityId(): void {
    this.spinner.show();
    this._WarehouseService.getFacility()
      .then(data => {
        this.facilityIds = data.data;
        this.spinner.hide();
      });
     
  }
  getPartyGroup(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
      .then(data => {
        this.partyIds = data.data.PersonsGroups;
        this.spinner.hide();
      });
     
  }
  getFacilityType(): void {
    this.spinner.show();
    this._WarehouseService.getFacilityType()
      .then(data => {
        this.facilityType = data.data;
        this.spinner.hide();
      });
     
  }
  getInventory(): void {
    this.spinner.show();
    this._WarehouseService.defaultInventory()
      .then(data => {
        this.inventoryData = data.data;
        this.spinner.hide();
      });
     
  }
  getWeight(): void {
    this.spinner.show();
    this._WarehouseService.defaultWeight()
      .then(data => {
        this.weightData = data.data;
        this.spinner.hide();
      });
      
  }
  getArea(): void {
    this.spinner.show();
    this._WarehouseService.areaUnit()
      .then(data => {
        this.areaData = data.data;
        this.spinner.hide();
      });
     
  }
  update(): void {
    this.spinner.show();

    if (this.facilityForm.valid) {
      this._WarehouseService.updateFacility(this.facilityId, {
        ...this.facilityData, ...this.facilityForm.value
      })
        .then(data => {
          if (data.success) {
            this._ToastrService.success('Updated');
            this.closebutton.nativeElement.click();
            this.spinner.hide();
            this.getFacility();
            this.editMode = false;
            this.facilityForm.reset();
          }
        });
    }
  }
getData(){
 /// this.facilityId = this.facilityId;
  if (this.facilityId) {
    this.editMode = true;
    this._WarehouseService.getFacilityById(this.facilityId).then(data =>{
      this.facilityData = data.data;
      this.facilityForm.patchValue(data.data);
      
  
    });
  }
}
  submit(): void {
    if (this.editMode) {
      this.update();
    } else {
  
        this.spinner.show();
        this._WarehouseService.createFacility(this.facilityForm.value)
          .then(data => {
            if (data.success) {
              this._ToastrService.success('Created');
              this.spinner.hide();
              this.findFacilities();
              this.facilityForm.reset();
              this.closebutton.nativeElement.click();
             // this.router.navigate([this.url]);
            }
          });
      
    }
 
  }
  Main(){
    this.router.navigate(["/facilities/warehouse"])
  }
  Facility(){
    this.router.navigate(["/facilities/facility-group"])
  }
  Inventory(){
    this.router.navigate(["/facilities/inventory-labels"])
  }
  Shipments(){
    this.router.navigate(["/facilities/warehouse-shipment"])
  }
  getFacility(): void {
    this.spinner.show();
    this._WarehouseService.getFacility()
      .then(data => {

        this.spinner.hide()
        this.facilities = data.data.map(value => {
          return {
            label: value.facilityName,
            value: value.facilityId
          };
        });
      });
     
  }

  createFacilities(): void {
    this.router.navigate([`/facilities/warehouse/create-new-facilities`]);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
}
