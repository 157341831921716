import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { CreateContactsService } from 'src/app/crm/contacts/create-contacts/create-contacts.service';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.css']
})
export class CompanyProfileComponent implements OnInit {
  createUser: FormGroup;
  selectedFileName: any;
  activeCategory=2;
  selectedFileNameDocument: any;
  sellOnlineArray: { label: string; value: string; }[];
  assignUser: { label: string; value: string; }[];
  array: any[]=[];
  arrayShip: any[]=[];
  stateData: any;
  stateArray: any[]=[];
  countryGeoId: any;
  countryId: any;
  stateDataShip: any;
  stateArrayShip: any[]=[];
  countryIdShip: any;
  countryGeoIdShip: any;
  subsId: string;
  subDetail: any;
  addDetail: any;
  BaddressLine1: any;
  BaddressLine2: any;
  BaddressLine3: any;
  Bcity: any;
  BcountryGeoId: any;
  BstateGeoId: any;
  Bzipcode: any;
  SaddressLine1: any;
  SaddressLine2: any;
  SaddressLine3: any;
  Scity: any;
  ScountryGeoId: any;
  SstateGeoId: any;
  Szipcode: any;
  url: string;
  constructor(
    readonly accountsService: AccountsService,public createContact: CreateContactsService,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,readonly http: HttpClient,
    readonly activatedRoute:ActivatedRoute,
    readonly router: Router ) {
      this.sellOnlineArray=[{
        "label": 'Yes',
        "value": 'Y'
      },
      {
        "label": 'No',
        "value": 'N'
      }]
      this.assignUser = [
        {
        "label": '5',
        "value": '5'
      },
      {
        "label": '10',
        "value": '10'
      },
      {
        "label": '15',
        "value": '15'
      },
      {
        "label": '20',
        "value": '20'
      },
      {
        "label": '25',
        "value": '25'
      },
      {
        "label": '50',
        "value": '50'
      },
      {
        "label": '75',
        "value": '75'
      },
      {
        "label": '100',
        "value": '100'
      },
      {
        "label": '250',
        "value": '250'
      },
      
      {
        "label": '500',
        "value": '500'
      }]
    this.createUser = this._FormBuilder.group({
      noOfUsers: [''],additionalUsers: [''],ownerName: ['',[Validators.required]],tenantName: ['',[Validators.required]],
      addressLine1: [''],addressLine2: [''],addressLine3: [''],countryGeoId: [''],stateGeoId: [''],
      city: [''],zipcode: [''],irsTaxIdNo: ['',[Validators.required]],taxIdProofUrl: [''],
      typeOfBusiness: [''],sellOnline: [''],professionalWebsite: [''],noOfEmployees: [''],
      addressLine1Bill: ['',[Validators.required]],addressLine2Bill: [''],
      addressLine3Bill: [''],countryGeoIdBill: ['',[Validators.required]],
      stateGeoIdBill: ['',[Validators.required]],cityBill: ['',[Validators.required]],
      zipcodeBill: ['',[Validators.required]],title: ['',[Validators.required]],
      firstname: ['',[Validators.required]],lastname: ['',[Validators.required]], middlename: [''],
      phone: ['',[Validators.required]],mail: ['',[Validators.required]],
      titleAlt: [''],firstnameAlt: [''],lastnameAlt: [''],middlenameAlt: [''],
      phoneAlt: [''],mailAlt: [''], paymentMethod:[''], creditCardNo:[''], expDate: [''], expMonth:[''], nameOnCard: [''], 

    });
   }
   ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getCountryList();
    this.getCountryListShip();
   this.getSubscriptionById();
  }
  onFileSelect(event) {

    this.selectedFileName = event.target.files[0];
  }
  onFileSelectDocument(event) {

    this.selectedFileNameDocument = event.target.files[0];
  }
  onCountryChange(event) {
    this.stateArray = [];
    this.countryGeoId = event;
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  }
  getCountryList() {
    this.spinner.show();
    this.createContact.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0].trim(),
          value: element.split(":").pop().trim()
        })
      })
    })
   
  }
  getCountryListShip() {
    this.spinner.show();
    this.createContact.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.arrayShip.push({
          label: element.split(":")[0].trim(),
          value: element.split(":").pop().trim()
        })
      })
    })
   
  }
  getStateList() {
   
    this.spinner.show();
    this.createContact.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
   
  }
  onCountryChangeBill(event) {
    this.stateArrayShip = [];
   
    this.countryGeoIdShip = event;
    this.countryIdShip = event;
    if (event) {
      this.getStateListShip();
    }
  }
  getStateListShip() {
  
    this.spinner.show();
    this.createContact.getState(this.countryIdShip).subscribe(res => {
      this.stateDataShip = res.data.stateList;
      this.spinner.hide();
      this.stateDataShip.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArrayShip.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
  
  }
  
  getSubscriptionById(){ 
  
    const tenantId: string = localStorage.getItem('tenantId');
 // this.subsId="vc"
  let url='http://34.196.47.126:81/api/v1/saas/subscriptionDetail'
  this.http.get(url + "?tenantName=" + tenantId).subscribe((res:any) => {
    /* this.accountsService.getUserDataAdminSubs(this.subsId).subscribe((res:any)=> { */
      this.subDetail=res.data;
          this.addDetail=res.data.tenantAddress;
       for(let val of this.addDetail){
         if(val.addressType=='BILLING_ADDRESS'){
           this.BaddressLine1=val.addressLine1,
           this.BaddressLine2=val.addressLine2,
           this.BaddressLine3=val.addressLine3,
           this.Bcity=val.city,
           this.BcountryGeoId=val.countryGeoId,
           this.BstateGeoId=val.stateGeoId,
           this.Bzipcode=val.zipcode
         }
         else{
          this.SaddressLine1=val.addressLine1,
           this.SaddressLine2=val.addressLine2
           this.SaddressLine3=val.addressLine3
           this.Scity=val.city
           this.ScountryGeoId=val.countryGeoId
           this.SstateGeoId=val.stateGeoId
           this.Szipcode=val.zipcode
         }
       }
       this.countryId=this.BcountryGeoId
       this.countryIdShip= this.ScountryGeoId
       this.getStateListShip();
       this.getStateList();
      setTimeout(() => {
    
   
   // this.getAdditonal(nUser);
        const formValue = this.createUser;
        formValue.patchValue({
          noOfUsers:res.data.tenantPaymentDetails.tenantDetails.subscription.noOfUseres,
      additionalUsers: res.data.tenantPaymentDetails.tenantDetails.subscription.additionalUsers,
      ownerName:res.data.tenantPaymentDetails.tenantDetails.ownerName,
      tenantName: res.data.tenantPaymentDetails.tenantDetails.tenantName,
      addressLine1:  this.SaddressLine1,
      addressLine2:  this.SaddressLine2,
      addressLine3: this.SaddressLine3,
      countryGeoId:this.ScountryGeoId,
      stateGeoId:  this.SstateGeoId,
      city: this.Scity,
      zipcode:  this.Szipcode,
      irsTaxIdNo: res.data.tenantPaymentDetails.tenantDetails.irsTaxIdNo,
      typeOfBusiness: res.data.tenantPaymentDetails.tenantDetails.typeOfBusiness,
      sellOnline:res.data.tenantPaymentDetails.tenantDetails.sellOnline,
      professionalWebsite:res.data.tenantPaymentDetails.tenantDetails.professionalWebsite,
      noOfEmployees: res.data.tenantPaymentDetails.tenantDetails.noOfEmployees,
      addressLine1Bill: this.BaddressLine1,
      addressLine2Bill:this.BaddressLine2,
      addressLine3Bill:this.BaddressLine3,
      countryGeoIdBill:this.BcountryGeoId,
      stateGeoIdBill: this.BstateGeoId,
      cityBill:  this.Bcity,
      zipcodeBill:  this.Bzipcode,
      title:res.data.tenantPaymentDetails.tenantDetails.title,
      firstname: res.data.tenantPaymentDetails.tenantDetails.firstName,
      lastname: res.data.tenantPaymentDetails.tenantDetails.lastName,
      middlename:res.data.tenantPaymentDetails.tenantDetails.middleName,
      phone: res.data.tenantPaymentDetails.tenantDetails.phone,
      mail: res.data.tenantPaymentDetails.tenantDetails.email,
      creditCardNo:res.data.tenantPaymentDetails.creditCardNo,
      expDate: res.data.tenantPaymentDetails.expDate,
      expMonth:res.data.tenantPaymentDetails.expMonth,
      nameOnCard: res.data.tenantPaymentDetails.nameOnCard,
      titleAlt: res.data.alternateContact.title,
      firstnameAlt: res.data.alternateContact.firstName,
      lastnameAlt: res.data.alternateContact.lastName,
      middlenameAlt:res.data.alternateContact.middleName,
      phoneAlt: res.data.alternateContact.phone,
      mailAlt:res.data.alternateContact.email, 

        })
      
      }, 2000);
   
   
    })
 
   
   }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
