<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-grey " (click)="cancelSubmit();" style="cursor: pointer;"> Party



                </span>
                <span class="color-black pl-1"> >Add New Party




                </span>

                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Add Party




                                </a></li>



                        </ul>
                    </div>


                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="createJob">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightFormXS"  >
                                                    <label for="exampleInputEmail1">
                                                        Party ID<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Party ID" filter="true"
                                                    formControlName="PartyID"
                                                    [options]="partyIdArray" optionlabel="label">

                                                </p-dropdown>

                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Role Type ID<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Role Type ID" filter="true"
                                                    formControlName="RoleTypeID"
                                                    [options]="allroleArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightFormXS"  >
                                                    <label for="exampleInputEmail1">
                                                        Work Effort ID</label>
                                                </div>
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Work Effort ID" filter="true"
                                                    formControlName="WorkEffortID"
                                                    [options]="routingTaskListArray" optionlabel="label">

                                                </p-dropdown>

                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        From Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Description"
                                                    formControlName="fromDate">
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightFormXS"  >
                                                    <label for="exampleInputEmail1">
                                                        Through Date</label>
                                                </div>
                                                <div class="col-lg-2" >
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Reserv Persons"
                                                    formControlName="throughDate">

                                                </div>
                                               
                                               
                                            </div>
                                        </div>


                                      





                                    </div>


                                </form>
                                <div class="main-submit-button" style="margin-left: 61%;">

                                    <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>