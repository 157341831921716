import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

@Component({
  selector: 'app-work-effort-effort',
  templateUrl: './work-effort-effort.component.html',
  styleUrls: ['./work-effort-effort.component.css']
})
export class WorkEffortEffortComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  activeCategory = 6;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  advanceSearch: { workEffortID: string; workEffortParentID: string; type: string; purpose: string; status: string; priority: string; workEffortName: string; description: string; facilityID: string; fixedAssetID: string; scope: string; currency: string; PartyID: string; };
  advancedSearchpopup: FormGroup;
  Type: any;
  TypeArray: any[]=[];
  Purpose: any;
  PurposeArray: any[]=[];
  CurrencyList: any;
  CurrencyListArray: any[]=[];
  WorkEffortAssocType: any;
  WorkEffortAssocTypeArray: any[]=[];
  RoleTypeId: any;
  RoleTypeIdArray: any[]=[];
  sortedbyArray: { label: string; value: string; }[];
  scopeArray: { label: string; value: string; }[];
  PriorityArray: { label: string; value: string; }[];
  ProductIdsList: any;
  ProductIdsListArray: any[]=[];
  WorkEffortId: any;
  WorkEffortIdArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  WorkEffortdata: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;


  constructor( public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly addSkillService: AddSkillService,
    readonly spinner: NgxSpinnerService,
    private _location: Location,
    
    readonly myContactsService: MyContactsService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router) {
      this.advanceSearch = {
        workEffortID: "",
        workEffortParentID: "",
        type: "",
        purpose: "",
        status: "",
        priority: "",
        workEffortName: "",
        description: "",
        facilityID: "",
        fixedAssetID: "",
        scope: "",
        currency: "",
        PartyID: "",
      };
      
      this.sortedbyArray = [

        {
          label: 'Keyword Relevancy',
          value: 'SortKeywordRelevancy'
        },
        {
          label: 'Name',
          value: 'SortWorkEffortField:workEffortName'
        },
        ];
        this.scopeArray = [

          {
            label: 'General, public access',
            value: 'WES_PUBLIC'
          },
          {
            label: 'Restricted, private access',
            value: 'WES_PRIVATE'
          },
          {
            label: 'Very restricted, confidential access',
            value: 'WES_CONFIDENTIAL'
          },
        ]; 
        this.PriorityArray = [
          {
            label: '9',
            value: '9'
          },
          {
            label: '8',
            value: '8'
          },
          {
            label: '7',
            value: '7'
          },
          {
            label: '6',
            value: '6'
          },
          {
            label: '5',
            value: '5'
          },
          {
            label: '4',
            value: '4'
          },
          {
            label: '3',
            value: '3'
          },
          {
            label: '2',
            value: '2'
          },
          {
            label: '1',
            value: '1'
          },
          ];
    
    this.advancedSearchpopup = this._FormBuilder.group({
      keywords: [''],
      reviews: [''],
      workEffortID: [''],
      workEffortAssocTypeID: [''],
      partyID: [''],
      roleTypeID: [''],
      productID1: [''],
      productID2: [''],
      updatedFrom: [''],
      updatedThrough: [''],
      sortedBy: [''],
    })
    }

  ngOnInit(): void {
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this.getType();
    this.getPurpose();
    this.getCurrencyList();
    this.getWorkEffortAssocType();
    this.getRoleTypeId();
    this.getProductIdsList();
    this.getWorkEffortId();
    this.getPartyId();
    this.findWorkEffort();
    
  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  detailedPage(workEffortId) {
    this._Router.navigate(["/psa/work-effort/task-list-view/editWorkEffort"],{ queryParams: { workEffortId: workEffortId} })
  }
  getfindWorkEffort() {
    
    this.spinner.show();
   if(this.advanceSearch.workEffortID||this.advanceSearch.workEffortParentID||
      this.advanceSearch.type ||this.advanceSearch.purpose
       ||this.advanceSearch.status ||this.advanceSearch.priority ||this.advanceSearch.workEffortName
       ||this.advanceSearch.description ||this.advanceSearch.facilityID ||this.advanceSearch.scope
       ||this.advanceSearch.currency ||this.advanceSearch.PartyID){
    const req = {
      "workEffortId": this.advanceSearch.workEffortID ? this.advanceSearch.workEffortID : '',
      "workEffortParentId": this.advanceSearch.workEffortParentID ? this.advanceSearch.workEffortParentID : '',
      "workEffortTypeId": this.advanceSearch.type ? this.advanceSearch.type : '',
      "workEffortPurposeTypeId": this.advanceSearch.purpose ? this.advanceSearch.purpose : '',
      "currentStatusId": this.advanceSearch.status ? this.advanceSearch.status : '',
      "priority": this.advanceSearch.priority ? this.advanceSearch.priority : '',
      "workEffortName": this.advanceSearch.workEffortName ? this.advanceSearch.workEffortName : '',
      "description": this.advanceSearch.description ? this.advanceSearch.description : '',
      "facilityId": this.advanceSearch.facilityID ? this.advanceSearch.facilityID : '',
      "fixedAssetId": this.advanceSearch.fixedAssetID ? this.advanceSearch.fixedAssetID : '',
      "scopeEnumId": this.advanceSearch.scope ? this.advanceSearch.scope : '',
      "recurrenceInfoId": this.advanceSearch.currency ? this.advanceSearch.currency : '',
      "partyId": this.advanceSearch.PartyID ? this.advanceSearch.PartyID : '',
    }
    this.myContactsService.findWorkEffort(req).subscribe((res: any) => {
      this.WorkEffortdata = res.data;
      this.spinner.hide();
    })}
    else{
      this.findWorkEffort();
    }
    this.spinner.hide();
  }
  findWorkEffort() {
    this.spinner.show();
    const req = {
  "currentStatusId": "",
  "description": "",
  "facilityId": "",
  "fixedAssetId": "",
  "partyId": "",
  "priority": "",
  "recurrenceInfoId": "",
  "scopeEnumId": "",
  "workEffortId": "",
  "workEffortName": "",
  "workEffortParentId": "",
  "workEffortPurposeTypeId": "",
  "workEffortTypeId": ""
    }
    this.myContactsService.findWorkEffort(req).subscribe(res => {
      this.WorkEffortdata = res.data;
      this.spinner.hide();
    })
  }
  reset() {
    
    this.advanceSearch.workEffortID = "";
    this.advanceSearch.workEffortParentID = "";
    this.advanceSearch.type = "";
    this.advanceSearch.purpose = "";
    this.advanceSearch.status = "";
    this.advanceSearch.priority = "";
    this.advanceSearch.workEffortName = "";
    this.advanceSearch.description = "";
    this.advanceSearch.facilityID = "";
    this.advanceSearch.fixedAssetID = "";
    this.advanceSearch.scope = "";
    this.advanceSearch.currency = "";
    this.advanceSearch.PartyID = "";

    const req = {
      "currentStatusId": "",
      "description": "",
      "facilityId": "",
      "fixedAssetId": "",
      "partyId": "",
      "priority": "",
      "recurrenceInfoId": "",
      "scopeEnumId": "",
      "workEffortId": "",
      "workEffortName": "",
      "workEffortParentId": "",
      "workEffortPurposeTypeId": "",
      "workEffortTypeId": ""
    }

    this.myContactsService.findWorkEffort(req).subscribe((res: any) => {
      this.WorkEffortdata = res.data;
      this.spinner.hide();
    })
  }
  addWorkEffort(){
    this._Router.navigate(["/psa/work-effort/add-work-effort"])
  }
  TaskList(){
    this._Router.navigate(["/psa/work-effort/task-list"])
  }
  Calendar(){
    this._Router.navigate(["/psa/work-effort/calendar"])
  }
  MyTime(){
    this._Router.navigate(["/psa/work-effort/my-time-effort"])
  }
  RequestList(){
    this._Router.navigate(["/psa/work-effort/request-list-effort"])
  }
  WorkEffort(){
    this._Router.navigate(["/psa/work-effort/work-effort-effort"])
  }
  Timesheet(){
    this._Router.navigate(["/psa/work-effort/timesheet-effort"])
  }
  SubmittedJobs(){
    this._Router.navigate(["/psa/work-effort/submitted-jobs"])
  }
  iCalendar(){
    this._Router.navigate(["/psa/work-effort/icalendar"])
  }
 

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  getType() {
    this.spinner.show();
    this.addSkillService.getType().subscribe(res => {
      this.Type = res.data[0].type;
      this.spinner.hide();
      for (const value of this.Type) {
        this.TypeArray.push({
          label: value.description,
          value: value.workEffortTypeId
        })
      }
    })
   }
   getPurpose() {
    this.spinner.show();
    this.addSkillService.getPurpose().subscribe(res => {
      this.Purpose = res.data[0].Purpose;
      this.spinner.hide();
      for (const value of this.Purpose) {
        this.PurposeArray.push({
          label: value.description,
          value: value.workEffortPurposeTypeId
        })
      }
    })
   }
   getCurrencyList() {
    this.spinner.show();
    this.addSkillService.getCurrencyList().subscribe(res => {
      this.CurrencyList = res.data[0].node;
      this.spinner.hide();
      for (const value of this.CurrencyList) {
        this.CurrencyListArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  getWorkEffortAssocType() {
    this.spinner.show();
    this.addSkillService.getWorkEffortAssocType().subscribe(res => {
      this.WorkEffortAssocType = res.data[0].data;
      this.spinner.hide();
      for (const value of this.WorkEffortAssocType) {
        this.WorkEffortAssocTypeArray.push({
          label: value.description,
          value: value.workEffortAssocTypeId
        })
      }
    })
  }
  getRoleTypeId() {
    this.spinner.show();
    this.addSkillService.getRoleTypeId().subscribe(res => {
      this.RoleTypeId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.RoleTypeId) {
        this.RoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  getProductIdsList() {
    this.spinner.show();
    this.addSkillService.getProductIdsList().subscribe(res => {
      this.ProductIdsList = res.data;
      this.spinner.hide();
      for (const value of this.ProductIdsList) {
        this.ProductIdsListArray.push({
          label: value.singleObj.productId,
          value: value.singleObj.productName
        })
      }
    })
  }
  getWorkEffortId() {
    this.spinner.show();
    this.addSkillService.getWorkEffortId().subscribe(res => {
      this.WorkEffortId = res.data[0].getWorkEffortId;
      this.spinner.hide();
      for (const value of this.WorkEffortId) {
        this.WorkEffortIdArray.push({
          label: value.workEffortId,
          value: value.workEffortName
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   }
  

}
