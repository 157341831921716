import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-commission-invoices',
  templateUrl: './create-commission-invoices.component.html',
  styleUrls: ['./create-commission-invoices.component.css']
})
export class CreateCommissionInvoicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
