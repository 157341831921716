import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CatalogModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        catalogName:(data && data.catalogName) ? data.catalogName : null,
        contentPathPrefix:(data && data.contentPathPrefix) ? data.contentPathPrefix : null,
        headerLogo:(data && data.headerLogo) ? data.headerLogo : null,
        prodCatalogId:(data && data.prodCatalogId) ? data.prodCatalogId : null,
        purchaseAllowPermReqd:(data && data.purchaseAllowPermReqd) ? data.purchaseAllowPermReqd : null,
        styleSheet:(data && data.styleSheet) ? data.styleSheet : null,
        templatePathPrefix:(data && data.templatePathPrefix) ? data.templatePathPrefix : null,
        useQuickAdd:(data && data.useQuickAdd) ? data.useQuickAdd : null,
        viewAllowPermReqd:(data && data.viewAllowPermReqd) ? data.viewAllowPermReqd : null,
    });
    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   return ({
    catalogName: new FormControl(data.catalogName,[Validators.required]),
    contentPathPrefix: new FormControl(data.contentPathPrefix),
    headerLogo: new FormControl(data.headerLogo),
    prodCatalogId: new FormControl(data.prodCatalogId),
    purchaseAllowPermReqd: new FormControl(data.purchaseAllowPermReqd),
    styleSheet: new FormControl(data.styleSheet),
    templatePathPrefix: new FormControl(data.templatePathPrefix),
    useQuickAdd: new FormControl(data.useQuickAdd),
    viewAllowPermReqd: new FormControl(data.viewAllowPermReqd),

   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
