<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact bg-white mt-20 mb-20 font-medium">
                <span class="color-grey float-left">Shipment ID: {{shipmentId}} </span>

                <div class="float-right">
                    <button type="button" (click)="export(this.shipmentId)" class="btn btn-outline-secondary mr-2" style="font-size: 13px;">
            Generate Shipment Report
          </button>

          <button  *ngIf="this.hideTabs" type="button"  class="btn btn-outline-secondary mr-2" style="font-size: 13px;">
            Receive Inventory Into
          </button>

          <button  *ngIf="this.hideTabs" type="button"  class="btn btn-outline-secondary mr-2" style="font-size: 13px;">
            Receive Against PO
          </button>
                  
                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>

                </div>

            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">
                    </div>
                </div>
                <div class="w3-card-4 classCard" style="margin-left:-2%!important ;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
                flex-wrap: wrap !important;
                display: flex !important;">
                            <li [ngClass]="activeShipment==0?'active':''" (click)="changeactiveCategory(0)"><a>
               View </a></li>
                            <li *ngIf="this.hideReceiv" [ngClass]="activeShipment==1?'active':''" (click)="changeactiveCategory(1)"><a>
                  Shipment Plan</a></li>
                            <li [ngClass]="activeShipment==2?'active':''" (click)="changeactiveCategory(2)"><a>
                Order Items</a></li>

                <li *ngIf="this.hideTabs" [ngClass]="activeShipment==3?'active':''" (click)="changeactiveCategory(3)"><a>
                    Shipment Receipt</a></li>
                            <li *ngIf="this.hideReceiv" [ngClass]="activeShipment==4?'active':''" (click)="changeactiveCategory(4)"><a>
                Items</a></li>
                            <li *ngIf="this.hideReceiv" [ngClass]="activeShipment==5?'active':''" (click)="changeactiveCategory(5)"><a>
                Packages</a></li>

                <li  *ngIf="this.hideReceiv" [ngClass]="activeShipment==6?'active':''" (click)="changeactiveCategory(6)"><a>
                    Route Segments</a></li>
                        </ul>
                    </div>

                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                        <div *ngIf="activeShipment==0">
                                            <div class="divA">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling h4Class">
                                                            View Shipment
                                                        </h4>
                                                        <span class="edit-buttons hCreate">
                            
                                                            <a  data-toggle="modal"
                                                            data-target="#exampleModalCenter" (click)="getAgreementById()">Update</a>
                                                        </span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="row" *ngIf="ShipmentInfo">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Shipment ID</p>
                                                                            <span *ngIf="ShipmentInfo?.shipmentId" class="color-grey pl-20 col-lg-10 col-12">{{ShipmentInfo?.shipmentId}}</span>
                                                                            <span *ngIf="!ShipmentInfo?.shipmentId" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Shipment Type</p>
                                                                            <span *ngIf="ShipmentInfo?.shipmentTypeId" class="color-grey pl-20 col-lg-10 col-12">{{ShipmentInfo?.shipmentTypeId}} </span>
                                                                            <span *ngIf="!ShipmentInfo?.shipmentTypeId" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Status</p>
                                                                            <span *ngIf="ShipmentInfo?.statusId" class="color-grey pl-20 col-lg-10 col-12">{{ShipmentInfo?.statusId}}</span>
                                                                            <span *ngIf="!ShipmentInfo?.statusId" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>

                                                                        <div class="article">

                                                                            <p class="col-lg-6 col-12">Primary Order ID</p>
                                                                            <span *ngIf="ShipmentInfo?.primaryOrderId" class="color-grey pl-20 col-lg-10 col-12">{{ShipmentInfo?.primaryOrderId}}</span>
                                                                            <span *ngIf="!ShipmentInfo?.primaryOrderId" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>

                                                                        <div class="article">

                                                                            <p class="col-lg-6 col-12">Primary Return ID</p>
                                                                            <span *ngIf="ShipmentInfo?.primaryReturnId" class="color-grey pl-20 col-lg-10 col-12">{{ShipmentInfo?.primaryReturnId}}</span>
                                                                            <span *ngIf="!ShipmentInfo?.primaryReturnId" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Primary Ship Group Seq ID</p>
                                                                            <span *ngIf="ShipmentInfo?.primaryShipGroupSeqId" class="color-grey pl-20 col-lg-10 col-12">{{ShipmentInfo?.primaryShipGroupSeqId}}</span>
                                                                            <span *ngIf="!ShipmentInfo?.primaryShipGroupSeqId" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Estimated Dates</p>
                                                                            <span *ngIf="ShipmentInfo?.estimatedReadyDate" class="color-grey pl-20 col-lg-10 col-12">
                                                                                {{ShipmentInfo?.estimatedReadyDate |date :'yyyy-MM-dd HH:mm:ss'}}
                                                                            </span>
                                                                            <span *ngIf="!ShipmentInfo?.estimatedReadyDate" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Latest Cancel Date</p>
                                                                            <span *ngIf="ShipmentInfo?.latestCancelDate" class="color-grey pl-20 col-lg-10 col-12">{{ShipmentInfo?.latestCancelDate |date :'yyyy-MM-dd HH:mm:ss'}}</span>
                                                                            <span *ngIf="!ShipmentInfo?.latestCancelDate" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Estimated Ship Cost</p>
                                                                            <span *ngIf="ShipmentInfo?.estimatedShipCost" class="color-grey pl-20 col-lg-10 col-12">{{ShipmentInfo?.estimatedShipCost}}</span>
                                                                            <span *ngIf="!ShipmentInfo?.estimatedShipCost" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Additional Shipping Charge</p>
                                                                            <span *ngIf="ShipmentInfo?.additionalShippingCharge" class="color-grey pl-20 col-lg-10 col-12">{{ShipmentInfo?.additionalShippingCharge}}</span>
                                                                            <span *ngIf="!ShipmentInfo?.additionalShippingCharge" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Handling Instructions</p>
                                                                            <span *ngIf="ShipmentInfo?.handlingInstructions" class="color-grey pl-20 col-lg-10 col-12">{{ShipmentInfo?.handlingInstructions}}</span>
                                                                            <span *ngIf="!ShipmentInfo?.handlingInstructions" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Facilities</p>
                                                                            <span *ngIf="ShipmentInfo?.originFacilityId" class="color-grey pl-20 col-lg-10 col-12">{{ShipmentInfo?.originFacilityId}}</span>
                                                                            <span *ngIf="!ShipmentInfo?.originFacilityId" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Parties</p>
                                                                            <span *ngIf="ShipmentInfo?.partyIdFrom" class="color-grey pl-20 col-lg-10 col-12">From:
                                                                                {{ShipmentInfo?.partyIdFrom}}
                                                                                To: {{ShipmentInfo?.partyIdTo}}</span>
                                                                            <span *ngIf="!ShipmentInfo?.partyIdFrom" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Origin Address ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">To {{originPostalAddress?.toName}},Attn:{{originPostalAddress?.attnName}}{{originPostalAddress?.address1}}{{originPostalAddress?.stateProvinceGeoId}},{{originPostalAddress?.city}},{{originPostalAddress?.countryGeoId}}</span>
                                                                        </div>


                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Origin Phone ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{originTelecomNumber?.contactNumber}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Destination Address ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">To {{destPostalAddress?.toName}},Attn:{{destPostalAddress?.attnName}}{{destPostalAddress?.address1}}{{destPostalAddress?.stateProvinceGeoId}},{{destPostalAddress?.city}},{{destPostalAddress?.countryGeoId}}</span>
                                                                        </div>
                                                                        <div class="article">


                                                                            <p class="col-lg-6 col-12">Destination Phone ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{destTelecomNumber?.contactNumber}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Created</p>
                                                                            <span *ngIf="ShipmentInfo?.createdDate" class="color-grey pl-20 col-lg-10 col-12">
                                        {{ShipmentInfo?.createdDate | date:'yyyy-MM-dd HH:mm:ss'}}</span>
                                                                            <span *ngIf="!ShipmentInfo?.createdDate" class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Last Updated</p>
                                                                            <span *ngIf="ShipmentInfo?.lastModifiedDate" class="color-grey pl-20 col-lg-10 col-12">{{ShipmentInfo?.lastModifiedDate | date:'yyyy-MM-dd HH:mm:ss'}}</span>
                                                                            <span *ngIf="!ShipmentInfo?.lastModifiedDate" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Estimated Ready Dates</p>
                                                                            <span *ngIf="ShipmentInfo?.estimatedReadyDate" class="color-grey pl-20 col-lg-10 col-12">
                                        {{ShipmentInfo?.estimatedReadyDate |date :'yyyy-MM-dd HH:mm:ss'}}
                                    </span>
                                                                            <span *ngIf="!ShipmentInfo?.estimatedReadyDate" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Estimated Ship Date</p>
                                                                            <span *ngIf="ShipmentInfo?.estimatedShipDate" class="color-grey pl-20 col-lg-10 col-12">
                                        {{ShipmentInfo?.estimatedShipDate |date :'yyyy-MM-dd HH:mm:ss'}}
                                    </span>
                                                                            <span *ngIf="!ShipmentInfo?.estimatedShipDate" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>
                                                                        <div class="article">

                                                                            <p class="col-lg-6 col-12">Estimated Arrival Dates</p>
                                                                            <span *ngIf="ShipmentInfo?.estimatedArrivalDate" class="color-grey pl-20 col-lg-10 col-12">
                                    {{ShipmentInfo?.estimatedArrivalDate |date :'yyyy-MM-dd HH:mm:ss'}}
                                </span>
                                                                            <span *ngIf="!ShipmentInfo?.estimatedArrivalDate" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Created By</p>
                                                                            <span *ngIf="ShipmentInfo?.createdByUserLogin" class="color-grey pl-20 col-lg-10 col-12">
                                        {{ShipmentInfo?.createdByUserLogin}}
                                    </span>

                                                                            <span *ngIf="!ShipmentInfo?.createdByUserLogin" class="color-grey pl-20 col-lg-10 col-12">N/A</span>
                                                                        </div>

                                                                    </div>


                                                                </div>
                                                                <div class="row" *ngIf="!ShipmentInfo">
                                                                    <p>No Record Found.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling h4Class">
                                                            View Shipment Item Info
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">

                                                                <div class="card own-account-table shipment-table">
                                                                    <p-table [value]="shipmentItemInfo"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code" style="    width: 1px !important;">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        Item
                                                                                    </div>
                                                                                </th>
                                                                                <th style="
                                                                                            width: 86px !important;
                                                                                        ">
                                                                                </th>
                                                                                <th>
                                                                                </th>
                                                                                <th pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Quantity</div>

                                                                                </th>
                                                                                <th>
                                                                                </th>
                                                                                <th>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td>
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span class="account-button"> {{product.shipmentItem.shipmentItemSeqId}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <span>{{product.shipmentItem.internalName}}{{product.shipmentItem.productId}}</span>
                                                                                    <br>
                                                                                    <span *ngFor="let item of product.orderShipments;">Order Item {{item.orderId}} {{item.orderItemSeqId}}</span>
                                                                                    <br>

                                                                                    <span *ngFor="let items of product.shipmentPackageContents;">Package {{items.shipmentPackageSeqId}} </span>

                                                                                </td>
                                                                                <td *ngFor="let data of product.itemIssuances;">Inventory {{data.inventoryItemId}}</td>
                                                                                <td>
                                                                                    <span>{{product.shipmentItem.quantity}}</span>
                                                                                    <br>
                                                                                    <span *ngFor="let item of product.orderShipments;"> {{item.quantity}} </span>
                                                                                    <br>

                                                                                    <span *ngFor="let items of product.shipmentPackageContents;">{{items.quantity}} </span>

                                                                                </td>

                                                                                <td *ngFor="let data of product.itemIssuances;">{{data.issuedDateTime|date:'yyyy-MM-dd hh:mm:ss'}}</td>

                                                                                <td> Future Party / RoleList
                                                                                </td>



                                                                            </tr>
                                                                        </ng-template>

                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                    <h4 class="ml-2">No Record Found</h4>
                                                </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>

                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling h4Class">
                                                            View Shipment Package Info
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="form-group allUserCardDetail" style="width: 100%;">
                                                                <div class="color-black container">
                                                                    <div class="row" *ngIf="detailShipmentPackge">
                                                                        <div class="card own-account-table shipment-table">
                                                                            <p-table [value]="detailShipmentPackge"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Package
                                                                                            </div>
                                                                                        </th>
                                                                                        <th>
                                                                                            <div style="color: white;"> Created</div>

                                                                                        </th>
                                                                                        <th>
                                                                                        </th>

                                                                                        <th>
                                                                                        </th>


                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                <span class="account-button"> {{product.shipmentPackage.shipmentPackageSeqId}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <span>{{product.shipmentPackage.dateCreated}}</span>
                                                                                            <br>
                                                                                            <span *ngFor="let item of product.shipmentPackageContents;"> Item {{item.shipmentItemSeqId}}</span>
                                                                                            <br>

                                                                                            <span *ngFor="let items of product.shipmentPackageRouteSegs;">	Route Segment {{items.shipmentRouteSegmentId}} </span>

                                                                                        </td>

                                                                                        <td>
                                                                                            <span>Weight {{product.shipmentPackage.weightUomId}}</span>
                                                                                            <br>
                                                                                            <span *ngFor="let item of product.shipmentPackageContents;"> Quantity {{item.quantity}} </span>
                                                                                            <br>

                                                                                            <span *ngFor="let track of product.shipmentPackageRouteSegs;">	Tracking {{track.trackingCode}} </span>

                                                                                        </td>

                                                                                        <td>
                                                                                            <span>Weight Unit {{product.shipmentPackage.weightUomId}}</span>
                                                                                            <br>
                                                                                            <span></span>
                                                                                            <br>

                                                                                            <span *ngFor="let track of product.shipmentPackageRouteSegs;">		Box {{track.boxNumber}} </span>

                                                                                        </td>


                                                                                    </tr>
                                                                                </ng-template>

                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                    <h4 class="ml-2">No Record Found</h4>
                                                </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>




                                                                    </div>
                                                                    <div class="row" *ngIf="!shipmentPackageInfo">
                                                                        <p>No Record Found.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <br>

                                            </div>

                                            <div class="divB">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling h4Class">
                                                            View Shipment Route Info
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="form-group">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row" *ngIf="shipmentRouteInfo">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">
                                                                                    Segment</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{shipmentRouteInfo?.shipmentRouteSegmentId}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">
                                                                                    Carrier Shipment Method</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{shipmentRouteInfo?.carrierPartyId}}</span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Origin Facility</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{shipmentRouteInfo?.originFacilityId}}</span>
                                                                            </div>

                                                                            <div class="article">

                                                                                <p class="col-lg-6 col-12">Destination Facility</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{shipmentRouteInfo?.destFacilityId}}</span>
                                                                            </div>





                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Origin Phone ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{originTelecomNumber?.contactNumber}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Destination Address ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">To {{destPostalAddress?.toName}},Attn:{{destPostalAddress?.attnName}}{{destPostalAddress?.address1}}{{destPostalAddress?.stateProvinceGeoId}},{{destPostalAddress?.city}},{{destPostalAddress?.countryGeoId}}</span>
                                                                            </div>
                                                                            <div class="article">


                                                                                <p class="col-lg-6 col-12">Destination Phone ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{destTelecomNumber?.contactNumber}}</span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Origin Address ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">To {{originPostalAddress?.toName}},Attn:{{originPostalAddress?.attnName}}{{originPostalAddress?.address1}}{{originPostalAddress?.stateProvinceGeoId}},{{originPostalAddress?.city}},{{originPostalAddress?.countryGeoId}}</span>
                                                                            </div>




                                                                        </div>







                                                                    </div>
                                                                    <div class="row" *ngIf="!shipmentRouteInfo">
                                                                        <p>No Record Found.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling h4Class">
                                                            View Shipment Receipts
                                                        </h4>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="form-group">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="color-black container">
                                                                    <div class="row" *ngIf="shipmentRouteInfo">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">
                                                                                    Segment</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{shipmentRouteInfo?.shipmentRouteSegmentId}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">
                                                                                    Carrier Shipment Method</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{shipmentRouteInfo?.carrierPartyId}}</span>

                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Origin Facility</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{shipmentRouteInfo?.originFacilityId}}</span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Destination Facility</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{shipmentRouteInfo?.destFacilityId}}</span>
                                                                            </div>





                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Origin Phone ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{originTelecomNumber?.contactNumber}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Destination Address ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">To {{destPostalAddress?.toName}},Attn:{{destPostalAddress?.attnName}}{{destPostalAddress?.address1}}{{destPostalAddress?.stateProvinceGeoId}},{{destPostalAddress?.city}},{{destPostalAddress?.countryGeoId}}</span>
                                                                            </div>
                                                                            <div class="article">


                                                                                <p class="col-lg-6 col-12">Destination Phone ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{destTelecomNumber?.contactNumber}}</span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Origin Address ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">To {{originPostalAddress?.toName}},Attn:{{originPostalAddress?.attnName}}{{originPostalAddress?.address1}}{{originPostalAddress?.stateProvinceGeoId}},{{originPostalAddress?.city}},{{originPostalAddress?.countryGeoId}}</span>
                                                                            </div>




                                                                        </div>

                                                                    </div>
                                                                    <div class="row" *ngIf="!shipmentRouteInfo">
                                                                        <p>No Record Found.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>


                                            </div>
                                        </div>

                                        <div *ngIf="activeShipment==1">
                                            <div class="w3-card-4 classCard" style="height:200px !important ;">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass">

                                                        <li class="active">
                                                            <a>
                                                                Shipment Plan</a>
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                                <div class="create-new-leade mt-2">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form class="w-100" [formGroup]="shipmentPlanForm">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                        
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Order ID</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <p-dropdown  filter="true" placeholder="Select Order ID" formControlName="ordId" [options]="orderIds">
                                                                                </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Ship Group Seq ID</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="text" class="form-control" id="exampleInputEmail1" 
                                                                                    aria-describedby="emailHelp" placeholder="Enter Ship Group Seq ID" formControlName="shipSeqId">
                                                    
                                                                            </div>
                                                                        </div>
                                                                    </div>
                        
                        
                        
                                                                </div>
                                                            </form>
                                                            <div class="main-submit-button" style="margin-left:-8% !important">
                                                                <button type="submit" class="btn btn-secondary submit-btn update-button" (click)="EditShipmentPlan()">Submit</button>
                        
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="w3-card-4 classCard  mt-3">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass">
                                                        <li class="active">
                                                            <a>
                                                                List for Shipment Plan</a>
                                                        </li>
                                                    </ul>

                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="findShipmentPlan"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Shipment Item Seq ID
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                        <div style="color: white;"> Order ID
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Order Item</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Ship Group Seq ID</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Product ID</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Quantity</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Issued Quantity</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Tot Ordered Quantity</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Not Available</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Tot Planned Quantity</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Tot Issued Quantity</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Weight</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> UOM</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Volume</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>


                                                                                    <th  [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> UOM</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'180px'}"  class="account-button"   >
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <span >
                                                                                                {{product.dataItem[0].orderItemSeqId}}
                                                                                            </span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.dataItem[0].orderId}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.dataItem[0].orderItemSeqId}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.dataItem[0].orderItemSeqId}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.dataItem[0].productId}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.dataItem[0].quantity}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.dataItem[0].quantity}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.dataItem[0].quantity}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{0}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{0}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.dataItem[0].quantity}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        <span></span>
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.data.currencyUomId}}
                                                                                    </td>
                                                                                    <td>
                                                                                        <button class="btn btn-danger">
                                                                                            Delete
                                                                                        </button>
                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> 

                                                    
                                                </div>
                                            </div>

                                            <div class="w3-card-4 classCard mt-3">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass">

                                                        <li class="active">
                                                            <a>
                                                                Add To Shipment Plan</a>
                                                        </li>
                                                        <span class="edit-buttons create_skill" style="margin-left: 79% !important; margin-top: 6px;">
                                                            <a  data-toggle="modal" data-target="#shipPlanModal">Create New Shipment Plan</a>
                                                        </span>


                                                    </ul>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="findShipmentPlan"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Order ID
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                        <div style="color: white;"> Order Item
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Ship Group Seq ID</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Product ID</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">Ordered Quantity</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">Planned Quantity</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Issued Quantity</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Quantity</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Weight</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> UOM</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Volume</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>


                                                                                    <th  [ngStyle]="{'width':'190px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> UOM</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'180px'}"  class="account-button"   >
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <td [ngStyle]="{'width':'180px'}"> 
                                                                                                {{product.dataItem[0].orderId}}
                                                                                            </td>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.dataItem[0].orderItemSeqId}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.dataItem[0].orderItemSeqId}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.dataItem[0].productId}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.dataItem[0].quantity}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.dataItem[0].quantity}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.dataItem[0].quantity}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{0}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                        {{product.data.currencyUomId}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}"> 
                                                                                    </td>
                                                                                    

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="activeShipment==2" >
                                            <div class="w3-card-4 classCard" style="height: 200px!important;" >
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass">

                                                        <li class="active">
                                                            <a>
                                                                Add Items From Order</a>
                                                        </li>
                                                        <span class="edit-buttons create_skill" style="margin-left: 79% !important;
                                                        margin-top: 6px;">
                                                            <a  data-toggle="modal" data-target="#orderModal">Create New Items From Order</a>
                                                        </span>
                                                    </ul>
                                                </div>
                                                <div class="create-new-leade mt-2">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form class="w-100" [formGroup]="OrderItemForm">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">

                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Order ID</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <p-dropdown  placeholder="Select Order ID" 
                                                                                [options]="orderIds" filter="true" formControlName="ordItemId"></p-dropdown>

                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Ship Group ID</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                                                placeholder="Enter Ship Group ID" formControlName="shipGrpId">
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </form>
                                                            <div class="main-submit-button" style="margin-left:-11% !important;">
                                                                <button type="submit" class="btn btn-secondary submit-btn update-button" (click)="AddItemsFromOrder()">Select</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="w3-card-4 classCard mt-3">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass">

                                                        <li class="active">
                                                            <a>
                                                                Add Items to Shipment</a>
                                                        </li>
                                                        
                                                    </ul>
                                                    
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">NOTE:</p>
                                                                            <span  class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Shipment Type:</p>
                                                                            <span  class="color-grey pl-20 col-lg-10 col-12"> {{orderItems.shipment.shipmentTypeId}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Status:</p>
                                                                            <span  class="color-grey pl-20 col-lg-10 col-12">{{orderItems.shipment.statusId}}</span>
                                                                        </div>

                                                                        <div class="article">

                                                                            <p class="col-lg-6 col-12">Origin Facility is:</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{orderItems.shipment.originFacilityId}}[{{orderItems.shipment.originFacilityId}}]</span>
                                                                        </div>

                                                                        <div class="article">

                                                                            <p class="col-lg-6 col-12">NOTE:</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">Order Status is {{orderItems.shipment.statusId}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                        
                                                            </div>
                                                        </div>
                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="w3-card-4 classCardView mt-2 w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    Ordered List
                                                </div>
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="orderItems"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    Order ID
                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;"> Ship Group ID
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Order Item</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Product</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Items Issued or Reserved	</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'220px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> [Issued + Reserved=Total]=Ordered	</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Reserved	</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;">Not Available	</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Issue	
                                                                                </div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;">Submit</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                        
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'180px'}"  class="account-button"  >
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span >
                                                                                        {{product.orderItemDatas[0].itemIssuances[0].orderId}}
                                                                                        
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}"> {{product.orderItemDatas[0].itemIssuances[0].shipGroupSeqId}}</td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.orderItemDatas[0].itemIssuances[0].shipmentItemSeqId}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.orderItemDatas[0].product.internalName}} [{{product.orderItemDatas[0].product.productId}}]
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.orderItemDatas[0].itemIssuances[0].quantity}} {{product.orderItemDatas[0].itemIssuances[0].shipmentId}} [{{product.orderItemDatas[0].itemIssuances[0].issuedDateTime}}]By {{productorderItemDatas[0].itemIssuances[0].issuedByUserLoginId}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                [{{product.orderItemDatas[0].totalQuantityIssued}}+{{product.orderItemDatas[0].totalQuantityReserved}}={{product.orderItemDatas[0].totalQuantityIssuedAndReserved}}]
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.orderItemDatas[0].totalQuantityIssuedAndReserved}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">

                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <button class="btn btn-secondary submit-btn" (click)="issueOrderItemShipGrpInvResToShipment(product)">Issue All</button>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div *ngIf="activeShipment==3">
                                            <div class="w3-card-4 classCard" style="height:200px !important ;">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass">

                                                        <li class="active">
                                                            <a>
                                                                Receive Inventory Against Purchase Order</a>
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                                <div class="create-new-leade mt-2">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form class="w-100" [formGroup]="recieveInventoryPurchaseOrderForm">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">

                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Shipment ID</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="text" class="form-control" id="exampleInputEmail1"
                                                                                 aria-describedby="emailHelp" placeholder="Enter Shipment ID" formControlName="shipmentID">


                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Order ID</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <p-dropdown  placeholder="Select Order ID" 
                                                                                filter="true" formControlName="ordItemId" [options]="orderIds"></p-dropdown>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Ship Group ID</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="text" class="form-control" id="exampleInputEmail1" 
                                                                                aria-describedby="emailHelp" placeholder="Enter Ship Group ID" formControlName="shipGrpId">
                                                                            </div>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </form>
                                                            <div class="main-submit-button" style="margin-left:-11% !important; margin-top: -3% !important;">
                                                                <button type="submit" class="btn btn-secondary submit-btn update-button" (click)="ReceiveInventoryAgainstPurchaseOrder()">Select</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="w3-card-4 classCard mt-3">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass">

                                                        <li class="active">
                                                            <a>
                                                                Shipment Receipt List</a>
                                                        </li>
                                                        
                                                    </ul>

                                                    <div class="panel-body" style="margin-top: 0% !important;">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12 col-12">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="shipmentReceipts"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Receipt ID
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                        <div style="color: white;"> Inventory Item ID
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Product ID</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Shipment Item Seq ID</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Shipment Package Seq ID	</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Order ID	</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Return ID	</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">Return Item Seq ID
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">Rejection ID
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">Received By User Login ID
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">Date Time Received
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">Item Description
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">Quantity Accepted
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                        <div style="color: white;">Quantity Rejected
                                                                                        </div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    
                                                                                
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'180px'}"  class="account-button"  >
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <span>{{product.receiptId}}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{product.inventoryItemId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{product.productId}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{product.datetimeReceived |date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{product.itemDescription}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{product.quantityAccepted}}
                                                                                    </td>

                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        {{product.quantityRejected}}
                                                                                    </td>


                                                                                    

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                            </div>

                                            <div class="panel-body" style="margin-top: 0% !important;">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="receiveInventoryAgainstPurchaseOrder"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    Product
                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;"> UPCA
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Order</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Cancelled</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Backordered	</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Received	</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Open	</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;">Back Orders
                                                                                </div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            
                                                                        
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'180px'}"  class="account-button"  >
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span>{{product.orderItemDatas[0].orderItem.itemDescription}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}"> </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                               {{product.orderItem.quantity}}
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.orderItem.cancelQuantity}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}">
                                                                                {{product.backOrderedQuantity}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}">
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}">
                                                                            </td>

                                                                             <td [ngStyle]="{'width':'180px'}">
                                                                                
                                                                             </td>

                                                                             
                                                                             

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div *ngIf="activeShipment==4">
                                            <div class="w3-card-4 classCard">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass">

                                                        <li class="active">
                                                            <a>
                                                            Items</a>
                                                        </li>
                                                        <span class="edit-buttons create_skill" style="margin-left: 88% !important; margin-top: 6px !important;">
                                                            <a  data-toggle="modal" data-target="#itemsModal">Create New Items</a>
                                                        </span>


                                                    </ul>
                                                </div>
                                                <div class="panel-body">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12 col-12">

                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="shipmentItem"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        Items
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> 
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> Quantity
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                    <div style="color: white;"> 
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Action</div>
                                                                                    <div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'180px'}"  class="account-button"   >
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span>{{product.shipmentItemSeqId}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                

                                                                                <td [ngStyle]="{'width':'180px'}">{{product.productId}} </td>

                                                                                <td [ngStyle]="{'width':'180px'}">{{product.quantity}} </td>


                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    {{product.createdTxStamp | date:'yyyy-MM-d hh:mm:ss'}}
                                                                                </td>

                                                                                
                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                    <!-- <button class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#updateItemsModal" (click)="updateShipmentItemPatch(product)">
                                                                                        Update
                                                                                    </button> -->

                                                                                    <button class="btn btn-danger ml-2" (click)="deleteShipmentItem(product)">
                                                                                        Delete
                                                                                    </button>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div *ngIf="activeShipment==5">
                                            <div class="w3-card-4 classCard">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass">
                                                        <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)">
                                                            <a>
                                                                Shipment Package </a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)">
                                                            <a>
                                                                Shipment Package Content</a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)">
                                                            <a>
                                                                Shipment Package Route Segment
                                                            </a>
                                                        </li>
                            
                                                    </ul>
                                                </div>
                                                    <div *ngIf="activeCategory==1">

                                                        <div class="w3-card-4 classCardView mt-3 mb-3 w-100" style="width:98%!important;margin-left:1%!important ;">
                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                    Shipment Package
                                                                    
                                                                </div>
                                                                
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12 col-12">

                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="shipmentPackageValues"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    Package
                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                                <div style="color: white;"> Created
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                                <div style="color: white;"> Action</div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'180px'}"  class="account-button"   >
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    <span>{{product.shipmentPackage.shipmentPackageSeqId}}</span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">{{product.shipmentPackage.createdStamp |date:'yyyy-MM-d hh:mm:ss'}} </td>
                                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                                <button class="btn btn-secondary submit-btn ml-2 " data-toggle="modal" data-target="#updateShipmentPackagePopup">
                                                                                                    Update
                                                                                                </button>

                                                                                                <button class="btn btn-danger ml-2 " (click)="deleteShipmentPackage(product)">
                                                                                                    Delete
                                                                                                </button>
                                                                                                
                                                                                            </td>

                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page

                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> 
                                                        </div>
                                                        <!-- <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="form-group">
                                                                    <div class="card own-account-table">
                                                                        <p-table [value]="shipmentPackageValues"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            Package
                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                        <div style="color: white;"> Created
                                                                                            <p-sortIcon field="name">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                    </th>
                                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                        <div style="color: white;"> Action</div>
                                                                                        <div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-product>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'180px'}"  class="account-button"   (click)="detailPage(product.partyId,product.description
                                                                                        ,product.rating,product.skillLevel,product.yearsExperience)">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                </div>
                                                                                            </p-checkbox>
                                                                                            <span>{{product.shipmentPackageDatas[0].shipmentPackage.shipmentPackageSeqId}}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">{{product.shipmentPackageDatas[0].shipmentPackage.createdStamp |date:'yyyy-MM-d hh:mm:ss'}} </td>
                                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                                        <button class="btn btn-secondary submit-btn ">
                                                                                            Update
                                                                                        </button>

                                                                                        <button class="btn btn-danger ml-2 ">
                                                                                            Delete
                                                                                        </button>
                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                    </div>

                                                    <div *ngIf="activeCategory==2">

                                                        <div class="w3-card-4 classCardView mt-3 mb-3 w-100" style="width:98%!important;margin-left:1%!important ;">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                Shipment Package Content
                                                                
                                                                <button type="submit" class="btn btn-secondary submit-btn ml-2" 
                                                                data-toggle="modal" data-target="#createShipmentPackageContentPopup" >Create Shipment Package Content
                                                                </button>
                                                                
                                                            </div>
                                                            
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">

                                                                    <div class="form-group">
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="shipmentPackageContent"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                Item ID
                                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                      
                                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                            <div style="color: white;"> Quantity
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                            <div style="color: white;"> Action</div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'180px'}"  class="account-button"   >
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                <span>{{product.shipmentItemSeqId}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">{{product.quantity}} </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            

                                                                                            <button class="btn btn-danger ml-2 " (click)="deleteShipmentPackageContent(product)">
                                                                                                Delete
                                                                                            </button>
                                                                                        </td>

                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                View per page

                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                    <div *ngIf="activeCategory==3">

                                                        <div class="w3-card-4 classCardView mt-3 mb-3 w-100" style="width:98%!important;margin-left:1%!important ;">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                Route Segment
                                                            </div>
                                                            
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">

                                                                    <div class="form-group">
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="shipmentPackageRoutes"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                Route Segment ID
                                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                            <div style="color: white;"> Track
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                            <div style="color: white;"> Box
                                                                                                <p-sortIcon field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                            <div style="color: white;"> Action</div>
                                                                                            <div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </div>
                                                                                        </th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'180px'}"  class="account-button"   >
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                    </div>
                                                                                                </p-checkbox>
                                                                                                <span>{{product.shipmentRouteSegmentId}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">{{product.trackingCode}} </td>
                                                                                        <td [ngStyle]="{'width':'180px'}">{{product.boxNumber}} </td>

                                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                                            <button class="btn btn-secondary submit-btn" data-toggle="modal" 
                                                                                            data-target="#updateRouteSegmentPopup" (click)="updateShipmentPackageRouteSegPatch(product)">
                                                                                                Update
                                                                                            </button>

                                                                                            <button class="btn btn-danger ml-2 " (click)="deleteShipmentPackageRouteSeg(product)">
                                                                                                Delete
                                                                                            </button>
                                                                                        </td>

                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                View per page

                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                            </div>
                                        </div>

                                        <div *ngIf="activeShipment==6">
                                            <div class="panel-body">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling">
                                                        Edit Shipment Route Segments


                                                    </h4>
                                                </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="color-black container">
                                                            <div class="row">
                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Carrier Shipment Method
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.shipmentMethodTypeId}}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Origin-Destination Facility
                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.destFacilityId}}</span>

                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-lg-6">
                                                                            <div class="article-container">
                                                                                <div class="article">
                                                                                    <p class="col-lg-6">Origin-Destination Address ID

                                                                                    </p>
                                                                                    <span class="color-grey pl-20 font-weight-bold"></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                
                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p class="col-lg-6 ">Origin-Destination Phone ID

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold"></span>
                                                                            </div>

                                                                        </div>
                                                                    </div>


                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6">Third Party Account Number

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.thirdPartyAccountNumber}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6">Third Party Postal Code

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.thirdPartyAccountNumber}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6">Third Party Country Code

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.thirdPartyCountryGeoCode}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6">Home Delivery Type/Date

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.homeDeliveryType}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6">Carrier Status

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.carrierServiceStatusId}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6">Tracking Number

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.trackingIdNumber}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6">Estimated (Start-Arrive)

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.estimatedArrivalDate}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6">Actual (Start-Arrive)

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.actualArrivalDate |date:'yyyy-MM-d hh:mm:ss'}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6">Billing Weight & UOM

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.billingWeightUomId}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6">Currency UOM

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.currencyUomId}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6">Actual Transport

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.actualTransportCost}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6">Actual Services

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.actualServiceCost}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6">Actual Other

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.actualOtherCost}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-6">
                                                                        <div class="article-container">

                                                                            <div class="article">
                                                                                <p class="col-lg-6">Actual Total

                                                                                </p>
                                                                                <span class="color-grey pl-20 font-weight-bold">{{editRouteSegmentsSummary.actualCost}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                        </div>
                                                    </div>


                                            </div>

                                            <div class="w3-card-4 classCardView mt-3 mb-3 w-100" style="width:98%!important;margin-left:1%!important ;">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                    Shipment Route Segment List

                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" 
                                                        data-toggle="modal" data-target="#addRouteSegmentPopup" >Add Shipment Route Segment

                                                    </button>
                                                </div>

                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12 col-12">

                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="editShipmentRouteSegments"  scrollHeight="150px"  [paginator]="false" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'180px'}">
                                                                                <div class="checkbox-align" style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    Shipment Route Segment Id
                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;"> Carrier Party Id
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                                <div style="color: white;"> Shipment Method Type Id
                                                                                    <p-sortIcon field="name">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Origin Facility Id</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Dest Facility Id</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Origin Contact Mech Id</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Dest Contact Mech Id</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Origin Telecom Number Id</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Dest Telecom Number Id</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Third Party Account Number</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Third Party Postal Code</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Third Party Country Geo Code</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Confirm Shipment</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Home Delivery Type</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Carrier Service Status Id</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                           
                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Tracking Id Number</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Estimated Start Date</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Estimated Arrival Date</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Actual Start Date</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Actual Arrival Date</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Billing Weight</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Billing Weight Uom Id</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Currency Uom Id</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Actual Transport Cost</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Actual Service Cost</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Actual Other Cost</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Actual Cost</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Action</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="price">
                                                                                <div style="color: white;"> Duplicate</div>
                                                                                <div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'180px'}"  class="account-button"   >
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                        </div>
                                                                                    </p-checkbox>
                                                                                    <span>{{product.shipmentRouteSegmentId}}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">{{product.carrierPartyId}} </td>
                                                                            <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.shipmentMethodTypeId}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.originFacilityId}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.destFacilityId}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.originContactMechId}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.destContactMechId}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.originTelecomNumberId}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.thirdPartyAccountNumber}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.thirdPartyPostalCode}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.thirdPartyCountryGeoCode}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                            </td>
                                                                            
                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.carrierServiceStatusId}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.trackingIdNumber}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.estimatedStartDate |date:'yyyy-MM-d hh:mm:ss'}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.estimatedArrivalDate |date:'yyyy-MM-d hh:mm:ss'}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.actualStartDate |date:'yyyy-MM-d hh:mm:ss'}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.actualArrivalDate |date:'yyyy-MM-d hh:mm:ss'}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.billingWeight}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.billingWeightUomId}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.currencyUomId}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.actualTransportCost}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.actualServiceCost}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.actualOtherCost}}
                                                                             </td>

                                                                             <td [ngStyle]="{'width':'180px'}"> 
                                                                                {{product.actualCost}}
                                                                             </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <button class="btn btn-secondary submit-btn" data-toggle="modal" 
                                                                                data-target="#updateRouteSegmentLstPopup" (click)="updateShipmentRouteSegmentPatch(product)">
                                                                                    Update
                                                                                </button>

                                                                                <button class="btn btn-danger ml-2 " (click)="deleteShipmentRouteSegment(product)" >
                                                                                    Delete
                                                                                </button>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <button class="btn btn-secondary submit-btn" data-toggle="modal" >
                                                                                    Duplicate
                                                                                </button>
                                                                            </td>

                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update New Shipment</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryShip==2?'active':''"><a>
                                Update Shipment</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateWarehouseShipment">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Shipment ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                         formControlName="shipmentId" readonly>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Status ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="StatusIdList" placeholder="Select Status ID" filter="true" 
                                                        formControlName="statusId"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Primary Order ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="OrderIdsList" placeholder="Select Primary Order ID" filter="true" formControlName="primaryOrderId"></p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Primary Return ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="primaryReturnId">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Primary Ship Group Seq ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="primaryShipGroupSeqId">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Picklist Bin</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="picklistBinId">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Ready Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="estimatedReadyDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Estimated Ship Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="estimatedShipDate">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Arrival Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="estimatedArrivalDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Latest Cancel Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="latestCancelDate">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Ship Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="estimatedShipCost">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Currency Uom ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="getCurrencyList" placeholder="Select Currency" filter="true" formControlName="currencyUomId"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Handling Instructions</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="handlingInstructions">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Origin Facility</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="facilities" placeholder="Select Origin Facility" filter="true" formControlName="originFacilityId"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Destination Facility</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="facilities" placeholder="Select Destination Facility" filter="true" formControlName="originFacility"></p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Origin Postal Address ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="adress">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Origin Phone Number ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="originTelecomNumberId">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Destination Postal Address ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="destinationFacilityId">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Destination Phone Number ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="destinationTelecomNumberId">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">To Party</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="PersonsAndPartyGroupsList" filter="true" placeholder="Select" formControlName="partyIdTo">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Party</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="PersonsAndPartyGroupsList" filter="true" placeholder="Select" formControlName="partyIdFrom">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Additional Shipping Charge</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="additionalShippingCharge">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Addtl Shipping Charge Desc</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="addtlShippingChargeDesc">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Event Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  formControlName="eventDate">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Shipment type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="ShipmentTypeIdList" filter="true" placeholder="Select" formControlName="shipmentTypeId">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left:83%">
                                        <button type="submit" (click)="submit()" class="btn btn-secondary submit-btn update-button">Update</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="shipPlanModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Shipment Plan</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp ">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active"><a>
                                 Shipment Plan</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addshipmentPlanForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Order ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  filter="true" placeholder="Select Order ID"
                                                        [options]="orderIds" formControlName="ordId">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Order Item</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Order Item" formControlName="orderItem">
                                                    
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Ship Group Seq ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Ship Group Seq ID" formControlName="shipGroupSeqID">
                                                    
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Product ID</label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown  filter="true" placeholder="Select Product ID" 
                                                        [options]="products" formControlName="productID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Ordered Quantity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Ordered Quantity" formControlName="orderedQuantity">
                                                    
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Planned Quantity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Planned Quantity" formControlName="plannedQuantity">
                                                    
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Issued Quantity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Issued Quantity" formControlName="issuedQuantity">
                                                    
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Quantity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Quantity" formControlName="quantity">
                                                    
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Weight</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Weight" formControlName="weight">
                                                    
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Weight UOM</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Weight UOM" formControlName="weightUOM">
                                                    
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Volume</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Volume" formControlName="volume">
                                                    
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Volume UOM</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Volume UOM" formControlName="volumeUOM">
                                                    
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left:-8% !important">
                                        <button type="submit" class="btn btn-secondary submit-btn update-button" (click)="addToShipmentPlan()">Create</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="itemsModal" tabindex="-1" role="dialog" aria-labelledby="itemsModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Item</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex mx-ht300">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCard ht100">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active"><a>
                                 New Item</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="itemsForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  filter="true" placeholder="Select Product ID" formControlName="pid" [options]="products">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Quantity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        size="5" name="quantity" formControlName="qntt" placeholder="Quantity">
                                                    </div>
                                                </div>
                                            
                                            </div>

                                            <div class="col-lg-12">

                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Product Description" formControlName="pdesc">
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left:-10%">
                                        <button type="submit" class="btn btn-secondary submit-btn update-button" (click)="createShipmentItem()">Create</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="updateItemsModal" tabindex="-1" role="dialog" aria-labelledby="itemsModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Item</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex mx-ht300">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCard ht100">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active"><a>
                                 New Item</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateItemsForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  filter="true" placeholder="Select Product ID" formControlName="pid" [options]="products">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Quantity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        size="5" name="quantity" formControlName="qntt" placeholder="Quantity">
                                                    </div>
                                                </div>
                                            
                                            </div>

                                            <div class="col-lg-12">

                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Product Description" formControlName="pdesc">
                                                    
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left:-10%">
                                        <button type="submit" class="btn btn-secondary submit-btn update-button" (click)="updateShipmentItem()">Update</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="updateRouteSegmentPopup" tabindex="-1" role="dialog" aria-labelledby="updateRouteSegmentPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Route Segment</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton6>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex mx-ht300">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCard ht100">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active"><a>
                                    Update Route Segment</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateRouteSegmentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Track</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Track" formControlName="track">
                                                    
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Box</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Box" formControlName="box">
                                                    
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left:-10%">
                                        <button type="submit" class="btn btn-secondary submit-btn update-button" (click)="updateShipmentPackageRouteSeg()" >Update</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="createShipmentPackageContentPopup" tabindex="-1" role="dialog" aria-labelledby="createShipmentPackageContentPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create Shipment Package Content</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex mx-ht300">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCard ht100">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active"><a>
                                    Create Shipment Package Content</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createShipmentPackageContentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Shipment Item Seq Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Shipment Item Seq Id" formControlName="shipmentItemSeqId">
                                                    
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Quantity</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Quantity" formControlName="quantity">
                                                    
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left:-10%">
                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="createShipmentPackageContent()" >Create</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="updateShipmentPackagePopup" tabindex="-1" role="dialog" aria-labelledby="updateShipmentPackagePopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Shipment Package</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex mx-ht300">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCard ht100">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active"><a>
                                    Update Shipment Package</a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateShipmentPackageForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Weight</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Weight" formControlName="weight">
                                                    
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Weight Unit</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  placeholder="Select Weight Unit" filter="true" 
                                                        formControlName="weightUnit" [options]="shipmentDropDownWeightArray"></p-dropdown>

                                                        
                                                    </div>
                                                </div>
                                            
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Box Type</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  placeholder="Select Weight Unit" filter="true" 
                                                        formControlName="boxType" [options]="shipmentboxTypeDropDownIDArray"></p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Insured Value</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Enter Insured Value" formControlName="insuredValue">
                                                    
                                                        
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left:-10%">
                                        <button type="submit" class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="addRouteSegmentPopup" tabindex="-1" role="dialog" aria-labelledby="addRouteSegmentPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Shipment Route Segment</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton7>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex ">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCard">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active"><a>
                                    Add Shipment Route Segment
                                </a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addShipmentRouteSegmentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Carrier Party Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  placeholder="Select Carrier Party Id" 
                                                        filter="true" formControlName="carrierPartyId" [options]="carrierPartyIDArray"></p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Shipment Method Type Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        
                                                        <p-dropdown  placeholder="Select Method Type Id" 
                                                        filter="true" formControlName="shipmentMethodTypeId" [options]="ShipmentMethodTypeIDArray"></p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Facility ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  placeholder="Select Facility ID" 
                                                        filter="true" formControlName="facilityID" [options]="facilities"></p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Dest Facility ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  placeholder="Select Dest Facility ID" 
                                                        filter="true" formControlName="destFacilityID" [options]="facilities"></p-dropdown>

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Origin Contact Mech Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Origin Contact Mech Id " formControlName="originContactMechId" >

                                                    </div>


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Dest Contact Mech Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                       <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Dest Contact Mech Id" formControlName="destContactMechId" >


                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Origin Telecom Number Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                       <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Origin Telecom Number Id" formControlName="originTelecomNumberId" >


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Dest Telecom Number Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                       <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Dest Telecom Number Id" formControlName="destTelecomNumberId" >


                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Carrier Service Status Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  placeholder="Select Carrier Service Status Id" 
                                                        filter="true" formControlName="carrierServiceStatusId" [options]="carrierServiceStatusIDArray"></p-dropdown>
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Track Nbr</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                       <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Track Nbr" formControlName="trackNbr" >


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Start Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                         formControlName="estimatedStartDate" >

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Estimated Arrival Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                       <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                         formControlName="estimatedArrivalDate" >


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Start Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                         formControlName="actualStartDate" >

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Actual Arrival Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                       <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                         formControlName="actualArrivalDate" >


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Billing Weight</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Billing Weight" formControlName="billingWeight" >

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Billing Weight Uom Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  placeholder="Select Billing Weight Uom Id" 
                                                        filter="true" formControlName="billingWeightUomId" [options]="shipmentDropDownWeightArray"></p-dropdown>


                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Currency Uom Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  placeholder="Select Currency Uom Id" 
                                                        filter="true" formControlName="currencyUomId" [options]="billingWeightUomIDArray" ></p-dropdown>


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Actual Transport Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Actual Transport Cost" formControlName="actualTransportCost" >

                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Service Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Actual Service Cost" formControlName="actualServiceCost" >


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Actual Other Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Actual Other Cost" formControlName="actualOtherCost" >

                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Actual  Cost" formControlName="actualCost" >


                                                    </div>


                                                    
                                                </div>
                                            </div>



                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left:-11% !important;">
                                        <button type="submit" class="btn btn-secondary submit-btn update-button" (click)="createShipmentRouteSegment()">Create</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="updateRouteSegmentLstPopup" tabindex="-1" role="dialog" aria-labelledby="updateRouteSegmentLstPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Shipment Route Segment</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton8>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex ">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCard">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li class="active"><a>
                                    Update Shipment Route Segment
                                </a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="updateShipmentRouteSegmentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Carrier Party Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  placeholder="Select Carrier Party Id" 
                                                        filter="true" formControlName="carrierPartyId" [options]="carrierPartyIDArray"></p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Shipment Method Type Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        
                                                        <p-dropdown  placeholder="Select Method Type Id" 
                                                        filter="true" formControlName="shipmentMethodTypeId" [options]="ShipmentMethodTypeIDArray"></p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Facility ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  placeholder="Select Facility ID" 
                                                        filter="true" formControlName="facilityID" [options]="facilities"></p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Dest Facility ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  placeholder="Select Dest Facility ID" 
                                                        filter="true" formControlName="destFacilityID" [options]="facilities"></p-dropdown>

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Origin Contact Mech Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Origin Contact Mech Id " formControlName="originContactMechId" >

                                                    </div>


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Dest Contact Mech Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                       <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Dest Contact Mech Id" formControlName="destContactMechId" >


                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Origin Telecom Number Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                       <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Origin Telecom Number Id" formControlName="originTelecomNumberId" >


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Dest Telecom Number Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                       <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Dest Telecom Number Id" formControlName="destTelecomNumberId" >


                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Carrier Service Status Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  placeholder="Select Carrier Service Status Id" 
                                                        filter="true" formControlName="carrierServiceStatusId" [options]="carrierServiceStatusIDArray"></p-dropdown>
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Track Nbr</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                       <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Track Nbr" formControlName="trackNbr" >


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated Start Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                         formControlName="estimatedStartDate" >

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Estimated Arrival Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                       <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                         formControlName="estimatedArrivalDate" >


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Start Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                         formControlName="actualStartDate" >

                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Actual Arrival Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                       <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                         formControlName="actualArrivalDate" >


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Billing Weight</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Billing Weight" formControlName="billingWeight" >

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Billing Weight Uom Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  placeholder="Select Billing Weight Uom Id" 
                                                        filter="true" formControlName="billingWeightUomId" [options]="shipmentDropDownWeightArray"></p-dropdown>


                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Currency Uom Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown  placeholder="Select Currency Uom Id" 
                                                        filter="true" formControlName="currencyUomId" [options]="billingWeightUomIDArray"></p-dropdown>


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Actual Transport Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Actual Transport Cost" formControlName="actualTransportCost" >

                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Service Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Actual Service Cost" formControlName="actualServiceCost" >


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Actual Other Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Actual Other Cost" formControlName="actualOtherCost" >

                                                    </div>

                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Actual  Cost" formControlName="actualCost" >


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Shipment Route Segment Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Shipment Route Segment Id" formControlName="shipmentRouteSegmentId" >


                                                    </div>


                                                    
                                                </div>
                                            </div>

                                           

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual Cost</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Actual  Cost" formControlName="actualCost" >


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Shipment Route Segment Id</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Shipment Route Segment Id" formControlName="shipmentRouteSegmentId" >


                                                    </div>


                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Third Party Account Number</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Third Party Account Number" formControlName="thirdPartyAccountNumber" >


                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Third Party Country Geo Code</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Third Party Country Geo Code" formControlName="thirdPartyCountryGeoCode" >


                                                    </div>


                                                    
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Third Party Postal Code</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                        placeholder="Enter Third Party Postal Code" formControlName="thirdPartyPostalCode" >


                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left:-11% !important;">
                                        <button type="submit" class="btn btn-secondary submit-btn update-button" (click)="updateShipmentRouteSegment()">Update</button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>