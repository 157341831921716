<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Chart of Accounts</span>
                <span class="color-black pl-1" *ngIf="!show"> >Add Chart of Accounts </span>
                <span class="color-black pl-1" *ngIf="show"> >Update Chart of Accounts</span>
                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                        Add Chart of Accounts</a></li>
                        <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                            Update Chart of Accounts</a></li>
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createGlobalSettingForm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                           
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Gl Account Type Id</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter GL Account Type Id" filter="true"
                                                formControlName="glAccountTypeId" 
                                                [options]="glAccountTypeArray" optionlabel="label">
                                                                
                                                </p-dropdown>
                                               
                                            </div>
                                            <div class="col-lg-3 form-group classInputA" *ngIf="show">
                                                <label for="exampleInputEmail1">Gl Account ID	
                                               
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2" *ngIf="show">
                                                <input type="email" [ngModelOptions]="{standalone: true}"  [(ngModel)]="this.glAccountId" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Gl Account ID"
                                              readonly >
                                             
                                            </div>
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Gl Account Class Id</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter GL Account Class Id" filter="true"
                                            formControlName="glAccountClassId"  
                                            [options]="glAccountClassArray" optionlabel="label">
                                                            
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Gl Resource Type Id</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Gl Resource Type Id" filter="true"
                                            formControlName="glResourceTypeId" 
                                            [options]="glResourceTypeArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Gl Xbrl Class Id</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Gl Xbrl Class Id" filter="true"
                                                formControlName="glXbrlClassId"  
                                                [options]="glXbrlClassArray" optionlabel="label">
                                                                
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Parent Gl Account Id </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Parent Gl Account Id"  filter="true" 
                                                formControlName="parentGlAccountId"  
                                                [options]="parentGlAccountArray" optionlabel="label">
                                                    
                                                </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Account Code	
                                               
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="accountCode"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Account Code"
                                               >
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Account Name</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="accountName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Account Name"
                                              >
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Description</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="description"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Description"
                                              >
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Product Id</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Product Id" filter="true"
                                                formControlName="productId" 
                                                [options]="ProductsArray" optionlabel="label">
                                                                
                                                </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">External Id</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"   formControlName="externalId"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter External Id"
                                              >
                                            </div>
                                           
                    
                                        </div>
                                    </div>
                                  
                                  
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 64%;">
                                <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit"  *ngIf="!show"  (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
