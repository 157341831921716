<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/financial/{{agreementType}}/customer-agreeement">Agreements
                </span>
                <span class="color-gry cursor-pointer"
                    routerLink="/{{agreementType}}/customer-agreeement/summary-customer-agreeement"
                    [queryParams]="{agreementID:agreementId}">>Agreements Summary </span>
                <span class="color-black pl-1"> > Agreement Content </span>
                <button class="btn btn-danger" style="margin-left: 52%;"
                (click)="reset()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4" style="border:1px solid;
                border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians" style="margin-top: 0px!important;background: #cce0e95e!important;">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Agreement Content</a></li>
                
                    </ul>
                  </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                    data-toggle="collapse" >
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist"
                                            aria-multiselectable="true">
                                            <div class="panel panel-default">
                                               
                                                <div 
                                                    aria-labelledby="headingOne">
                                                    <div class="panel-body">
                                                        <form [formGroup]="createAgreementContentForm">
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                    
                                                                        <div class="col-lg-3 form-group" style="  text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 13px;">
                                                                            <label for="agreementInvoiceType">Agreement
                                                                                Item Seq ID</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                [options]="itemSeqIds"
                                                                                optionlabel="label"
                                                                                formControlName="agreementItemSeqId"
                                                                                placeholder="Select Item">
                                                                            </p-dropdown>
                                                                        </div>
                                    
                                                                        <div class="col-lg-3 form-group" style="  text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 13px;">
                                                                            <label for="currency">Agreement Content Type
                                                                                ID</label>
                                                                        </div>
                                                                       
                                                                        <div class="col-lg-2" >
                                                                            <p-dropdown filter="true"
                                                                                [options]="contentTypeIds"
                                                                                optionlabel="label"
                                                                                formControlName="agreementContentTypeId"
                                                                                placeholder="Select Content Type">
                                                                            </p-dropdown>
                                                                        </div>
                                    
                                    
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                    
                                                                        <div class="col-lg-3 form-group" style="  text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 13px;">
                                                                            <label for="agreementText">Content Type
                                                                                ID</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                [options]="contentIds"
                                                                                formControlName="contentTypeId"
                                                                                optionlabel="label"
                                                                                placeholder="Select Content Id">
                                                                            </p-dropdown>
                                                                        </div>
                                    
                                                                        <div class="col-lg-3 form-group" style="  text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 13px;">
                                                                            <label for="agreementImage">Status
                                                                                ID</label>
                                                                        </div>
                                                                       
                                                                        <div class="col-lg-2" >
                                                                            <p-dropdown filter="true"
                                                                                [options]="statusIds"
                                                                                formControlName="statusId"
                                                                                optionlabel="label"
                                                                                placeholder="Select Status">
                                                                            </p-dropdown>
                                                                        </div>
                                    
                                    
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                    
                                                                        <div class="col-lg-3 form-group" style="  text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 13px;">
                                                                            <label for="fromDate">From Date</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-calendar formControlName="fromDate">
                                                                            </p-calendar>
                                                                        </div>
                                    
                                                                        <div class="col-lg-3 form-group" style="  text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 13px;">
                                                                            <label for="fromDate">Through Date</label>
                                                                        </div>
                                                                       
                                                                        <div class="col-lg-2" >
                                                                            <p-calendar formControlName="thruDate">
                                                                            </p-calendar>
                                                                        </div>
                                    
                                    
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                    
                                                                        <div class="col-lg-3 form-group" style="  text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -7%;
                                                                        font-size: 13px;">
                                                                            <label for="file">File</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="file" class="form-control"
                                                                            formControlName="file"
                                                                            (change)="setFile($event)">
                                                                        </div>
                                    
                                                                        
                                                                    </div>
                                                                </div>

                                                             
                                                            </div>
                                                            <div class="main-submit-button" style="
                                                            margin-right: 24%;
                                                        ">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="submit()">Upload</button>
                                                               
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 