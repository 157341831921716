<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="bg-white color-grey w-100 mt-20">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCardView mt-2 w-100">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    Flexible Report
                                    <button  type="submit" class="btn btn-danger "(click)="toBack()" style="margin-left: 83%;">Back</button>
                                    
                                </div>
                                <div class="create-new-leade">
                                    <div class="container-fluid" style="padding: 0;">
                                        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                            <div class="w-100 row">
                                                <div class=" color-grey" style="width: 49%; margin-left: 13px !important; ">
                                                    <div class="w3-card-4 classCard w-100">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                            >
                                                            <p class="h-text "
                                                                style="margin: 0px 15px -1px -2px !important;">
                                                                Edit Report
                                                            </p>
                                                        </div>
                                                            <form class="w-100" [formGroup]="editReportForm">
                                                                <div class="heading">
                                                                    <h3>Report information
                                                                    </h3>
                                                                </div>

                                                                <div class="col-lg-12 mt-3">
                                                                    <div class="row d-flex">
                                                                        <div class="col-lg-5 classInput">
                                                                            <label>Report name	</label>
                                                                        </div>

                                                                        <div class="col-lg-5">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="reportname" readonly placeholder="Enter Report name">

                                                                        </div>

                                                                    </div>

                                                                </div>

                                                                <div class="col-lg-12 mt-1">
                                                                    <div class="row d-flex">
                                                                        <div class="col-lg-5 classInput">
                                                                            <label>Description	</label>
                                                                        </div>

                                                                        <div class="col-lg-5">
                                                                            <input
                                                                            type="text" class="form-control"
                                                                                formControlName="description" placeholder="Enter Description">

                                                                        </div>

                                                                    </div>

                                                                </div>

                                                                <div class="col-lg-12 mt-1">
                                                                    <div class="row d-flex">
                                                                        <div class="col-lg-5 classInput">
                                                                            <label>Status ID	
                                                                            </label>
                                                                        </div>

                                                                        <div class="col-lg-5">
                                                                            <p-dropdown filter="true" optionlabel="label"
                                                                            formControlName="statusID"  placeholder="Select Status ID" [options]="statusArray"></p-dropdown> 
                                                            
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div class="col-lg-2 main-submit-button" style="margin-left: 62%;">
                                                                    <button  type="submit" class="btn btn-secondary submit-btn" (click)="UpdateFlexibleReportS()">Submit</button>
                                        
                                                                </div>

                                                                <div class="heading">
                                                                    <h3>Upload rptdesign file
                                                                    </h3>
                                                                </div>

                                                               <div class="col-lg-5 mt-1">
                                                                    <input 
                                                                    (change)="fileProgress($event)"

                                                                        type="file"
                                                                        class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        formControlName="uploadFileRptDesign"
                                                                        aria-describedby="emailHelp"
                                                                        >
                                                                </div>

                                                                <div class="col-lg-2 main-submit-button" style="margin-left: 62%;">
                                                                    <button  type="submit" class="btn btn-secondary submit-btn" (click)="UploadRptDesign()" >
                                                                        Upload</button>
                                        
                                                                </div>
                                                                
                                                                <div class="heading">
                                                                    <h3>Override filters
                                                                    </h3>
                                                                </div>         
                                                                
                                                                <div class="col-lg-12 mt-1">
                                                                    <textarea cols="70" rows="40" formControlName="textareadata" >

                                                                    </textarea>
                                                                </div>

                                                                <div class="col-lg-2 main-submit-button" style="margin-left: 62%;">
                                                                    <button  type="submit" class="btn btn-secondary submit-btn" >Save</button>
                                        
                                                                </div>

                                                            </form>
                                                            
                                                        </div>
                                                </div>

                                                <div *ngIf="this.showNew" class=" color-grey" style="width: 49%; margin-left: 7px;">
                                                    <div class="w3-card-4 classCard w-100" style="height:347px ! important">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text "
                                                                style="margin: 0px 15px -1px -2px !important;">
                                                                Preview

                                                            </p>
                                                        </div>
                                                        <form  class="w-100"  [formGroup]="previewForm">

                                                                <div class="col-lg-12 mt-3">
                                                                    <div class="row d-flex">
                                                                        <div class="col-lg-5 classInput">
                                                                            <label>From Date</label>
                                                                        </div>

                                                                        <div class="col-lg-5">
                                                                            <input type="date" class="form-control"
                                                                                formControlName="fromDate" >

                                                                        </div>

                                                                    </div>

                                                                </div>

                                                                <div class="col-lg-12 mt-1">
                                                                    <div class="row d-flex">
                                                                        <div class="col-lg-5 classInput">
                                                                            <label>Through Date	</label>
                                                                        </div>

                                                                        <div class="col-lg-5">
                                                                            <input
                                                                            type="date"class="form-control"
                                                                                formControlName="throughDate" >

                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div class="col-lg-12 mt-1">
                                                                    <div class="row d-flex">
                                                                        <div class="col-lg-5 classInput">
                                                                            <label>Product Category ID	
                                                                            </label>
                                                                        </div>

                                                                        <div class="col-lg-5">
                                                                            <input
                                                                            type="text"  class="form-control"
                                                                                formControlName="productCategoryID" placeholder="Enter Product Category ID">

                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div class="col-lg-12 mt-1">
                                                                    <div class="row d-flex">
                                                                        <div class="col-lg-5 classInput">
                                                                            <label>Product Store ID	</label>
                                                                        </div>

                                                                        <div class="col-lg-5">
                                                                            <input type="text"  class="form-control"
                                                                                formControlName="productStoreID"  placeholder="Enter Product Store ID	">

                                                                        </div>

                                                                    </div>

                                                                </div>

                                                                <div>
                                                                    <hr style="width:98% !important; margin-left:1% !important ;" >
                                                                </div>

                                                                <div class="col-lg-12 mt-1">
                                                                    <div class="row d-flex">
                                                                        <div class="col-lg-5 classInput">
                                                                            <label>Birt export format</label>
                                                                        </div>

                                                                        <div class="col-lg-5">
                                                                            <p-dropdown filter="true" optionlabel="label"
                                                                            formControlName="birtexportformat"  placeholder="Select Birt export format" [options]="birtexportformatArray"></p-dropdown> 
                                                            
                                                                        </div>

                                                                    </div>

                                                                </div>

                                                                <div class="col-lg-10 main-submit-button" style="margin-left: 9% !important;">
                                                                    <button type="submit"  class="btn btn-secondary submit-btn" 
                                                                        (click)="generatePdfShort()">Send</button>&nbsp;
                                                                    
                                                                </div>

                                                        </form>

                                                    </div>
                                                </div>

                                                <div *ngIf="!this.showNew" class=" color-grey" style="width: 49%; margin-left: 7px;">
                                                    <div class="w3-card-4 classCard w-100" style="height:740px ! important">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                            <p class="h-text "
                                                                style="margin: 0px 15px -1px -2px !important;">
                                                                Preview

                                                            </p>
                                                        </div>
                                                        <form *ngIf="!this.showNew" class="w-100" [formGroup]="displayFlexibleReportSearchForm">

                                                            <div class="col-lg-12 mt-3">
                                                                <div class="row">
                                            
                                                                    <div class="col-lg-5 form-group classInput" >
                                                                        <label for="exampleInputEmail1">
                                                                            Example ID	</label>
                                                                    </div>
                                                                    
                                                                    <div class="col-lg-5" >
                                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Enter Example ID"
                                                                        formControlName="exampleID">
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 mt-1">
                                                                <div class="row">

                                                                    <div class="col-lg-5 form-group classInput">
                                                                        <label for="exampleInputEmail1">Example Type ID		
                                                                        </label>
                                                                    </div>
                                                                    
                                                                    <div class="col-lg-5" >
                                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Enter Example Type ID	"
                                                                        formControlName="exampleTypeID">
                                                                    
                                                                    </div>
                                                                </div>
                                                            </div>
                                            
                                                            
                                                            <div class="col-lg-12 mt-1">
                                                                <div class="row">
                                            
                                                                    <div class="col-lg-5 form-group classInput" >
                                                                        <label for="accountLimit">Status ID	</label>
                                                                    </div>
                                                                    
                                                                    <div class="col-lg-5">
                
                                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Enter Status ID"
                                                                        formControlName="statusID">
                
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 mt-1">
                                                                <div class="row">
                
                                                                    <div class="col-lg-5 form-group classInput" >
                                                                        <label for="accountLimit">Example Name</label>
                                                                    </div>
                
                                                                    <div class="col-lg-5">
                                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Enter Example Name"
                                                                            formControlName="exampleName">
                
                                                                    </div>
                                                                </div>
                                                            </div>
                
                                                            
                
                                                            <div class="col-lg-12 mt-1">
                                                                <div class="row">
                                            
                                                                    <div class="col-lg-5 form-group classInput" >
                                                                        <label for="accountLimit">Description</label>
                                                                    </div>
                                                                    
                                                                    <div class="col-lg-5">
                
                                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Enter Description"
                                                                        formControlName="description">
                
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 mt-1">
                                                                <div class="row">
                
                                                                    <div class="col-lg-5 form-group classInput" >
                                                                        <label for="accountLimit">Long Description	</label>
                                                                    </div>
                
                                                                    <div class="col-lg-5">
                                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Enter Long Description"
                                                                            formControlName="longDescription">
                
                                                                    </div>
                                                                </div>
                                                            </div>
                
                                                            
                
                                                            <div class="col-lg-12 mt-1">
                                                                <div class="row">
                                            
                                                                    <div class="col-lg-5 form-group classInput" >
                                                                        <label for="accountLimit">Comments	</label>
                                                                    </div>
                                                                    
                                                                    <div class="col-lg-5">
                
                                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Enter Comments"
                                                                        formControlName="comments">
                
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            
                                                            <div class="col-lg-12 mt-1">
                                                                <div class="row">
                
                                                                    <div class="col-lg-5 form-group classInput" >
                                                                        <label for="accountLimit">Example Date</label>
                                                                    </div>
                
                                                                    <div class="col-lg-5">
                                                                            <input type="date"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" 
                                                                            formControlName="exampleDate">
                
                                                                    </div>
                                                                </div>
                                                            </div>
                
                                                            
                
                                                            <div class="col-lg-12 mt-1">
                                                                <div class="row">
                                            
                                                                    <div class="col-lg-5 form-group classInput" >
                                                                        <label for="accountLimit">Example Size</label>
                                                                    </div>
                                                                    
                                                                    <div class="col-lg-5">
                
                                                                        <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Enter Example Size"
                                                                        formControlName="exampleSize">
                
                                                                    </div>
                                                                
                                                                </div>
                                                            </div>

                                                             <div class="col-lg-12 mt-1">
                                                                <div class="row">
                                            
                                                                    <div class="col-lg-5 form-group classInput" >
                                                                        <label for="accountLimit">Another Date</label>
                                                                    </div>
                                                                    
                                                                    <div class="col-lg-5">
                
                                                                        <input type="date"  class="form-control" id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Enter Another Date"
                                                                        formControlName="anotherDate">
                
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12 mt-1">
                                                                <div class="row">
                
                                                                    <div class="col-lg-5 form-group classInput" >
                                                                        <label for="accountLimit">Another Text	</label>
                                                                    </div>
                
                                                                    <div class="col-lg-5">
                                                                            <input type="email"  class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Enter Another Text"
                                                                            formControlName="anotherText">
                
                                                                    </div>
                                                                </div>
                                                            </div>
                
                                                            
                
                                                            <div>
                                                                <hr style="width:98% !important; margin-left: 1%;">
                                                            </div>
                
                
                                                            <div class="col-lg-12 mt-1">
                                                                <div class="row">
                                            
                                                                <div class="col-lg-5 form-group classInput" >
                                                                        <label for="accountLimit">Birt Export Format</label>
                                                                    </div>
                
                                                                    <div class="col-lg-5">
                                                                            <p-dropdown filter="true" optionlabel="label" 
                                                                            formControlName="birtExportFormat"
                                                                            placeholder="Select Birt Export Format" [options]="birtexportformatArray">
                
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                    </div>
                                                            </div>
                
                                                            <div class="col-lg-10 main-submit-button" style="margin-left: 9% !important;">
                                                                <button type="submit"  class="btn btn-secondary submit-btn" 
                                                                    (click)="generatePdf()">Send</button>&nbsp;
                                                                
                                                            </div>
                                                    </form>  
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



 
<ngx-spinner></ngx-spinner>