<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">

            <div class="col-md-12 p-0">
                <div class="w3-card-4 classCard  w-100">
                    <div class="col-lg-12 mt-3" style="margin-bottom: 10px !important; ">
                        <div class="row d-flex justify-content-between">
                            <div class="font-12" style="font-weight:bold;font-size: 14px !important; margin-left: 18px !important;">
                                <label>Equipment Service</label>
                            </div>
                            <div>
                                <button style="margin-right:12px !important"
                                    class="btn btn-secondary submit-btn" (click)="toBack()">Back</button>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-left: 1%; margin-bottom: 2%;">
                        <p>Define the list of Services you perform on this Equipment Type
                        </p>
                    </div>
                    <div class="col-lg-12">
                        <div class="row " style="margin-top: -19px !important;margin-left: -1% !important; ">
                            <a class="btn btn-link" style="" data-toggle="modal"
                            (click)="reset()"      data-target="#addNewEquipmentTypesPopup">Add a New Service/Checklist</a>
                        </div>
                    </div>
                    <!-- <div class="col-lg-12">
                        <div class="row "
                            style="margin-top: 0px !important; margin-bottom: -1% !important;margin-left: -1% !important; ">
                            <a class="btn btn-link" style="" data-toggle="modal"
                             data-target="#addServiceDescriptionPopup">Add Service & Description</a>
                        </div>

                    </div> -->
                    <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: -1%;">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="border: 1px solid lightcyan !important;">

                                    <p-table [value]="fsmEquipmentServiceList" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                        [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background:rgb(206, 214, 218);">
                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: black;">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        Name
                                                        <p-sortIcon field="code">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default 
                                                        ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>



                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Duration
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Priority
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>


                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Skill
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Description
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Action
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'150px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>

                                                        </p-checkbox>
                                                        <span  class="account-button"  >
                                                            {{product.serviceName}}
                                                        </span>

                                                    </div>
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.serviceDuration}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.priority}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.serviceSkill}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.description}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">
                                                    <button type="button" class="btn btn-secondary submit-btn ml-2"
                                                   (click)="getFsmEquipmentServiceById(product)"    
                                                    data-toggle="modal" data-target="#addNewEquipmentTypesPopup">Edit</button>
                                                    <button type="button"
                                                    (click)="deleteFsmEquipmentService(product)"         
                                                    class="btn btn-danger  ml-2">Delete</button>

                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">No Record Found</h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <p class="paginate_data">
                                        View per page

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div class="col-lg-12">
                        <div class="row font-12" style="margin-left: 0%; margin-bottom: 2%;">
                            <p>Select which attributes you wish to track for this type
                                Equipment
                            </p>
                        </div>

                    </div>

                    <div class="col-lg-12">
                        <div class="row "
                            style="margin-top: 0px !important; margin-bottom: -1% !important;margin-left: -1% !important; ">
                            <a class="btn btn-link" style="" data-toggle="modal"
                           (click)="resetTrack()"   data-target="#addServiceTrackPopup">Add Service & Track</a>
                        </div>

                    </div>




                    <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="border: 1px solid lightcyan !important;">

                                    <p-table [value]="FsmEquipmentTrackList" [paginator]="true" [rows]="rows" scrollWidth="100%"
                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                        [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background:rgb(206, 214, 218);">
                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: black;">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>

                                                        </p-checkbox>
                                                        Name
                                                        <p-sortIcon field="code">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>



                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Type
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Keep History
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>


                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Required
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>



                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Track
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'150px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        <span  class="account-button"  >
                                                            {{product.trackName}}
                                                        </span>

                                                    </div>
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.trackType}}
                                                </td>

                                                <td [ngStyle]="{'width':'180px'}">{{product.trackKeepHistory}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.trackRequired}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.track}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">

                                                    <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer; " 
                                                    (click)="getFsmEquipmentTrackById(product)" data-toggle="modal"
                                                    data-target="#addServiceTrackPopup">Update</small>
                                                    <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                     pointer; "        
                                                       (click)="deleteFsmEquipmentTrack(product)" >Delete</small>

                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">No Record Found</h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <p class="paginate_data">
                                        View per page

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- 
                <div class="col-lg-12">
                    <div class="row" style="margin-left: 1%; margin-bottom: 2%;">
                        <p style="font-weight: bold;"> Description for 110-volt Service :Exposed Wiring
                        </p>
                    </div>   

                </div>  

                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-6" style="margin-left:2%!important">
                            <textarea style="border-radius: 0px !important; height: 200px !important; width: 300px;" ></textarea>

                        </div>
                    </div>
                </div>


                <div class="col-lg-5 main-submit-button" style="margin-left: 40%;">
                    <button type="submit" class="btn btn-secondary submit-btn">Cancel</button>
                    <button type="submit" class="btn btn-secondary submit-btn">OK</button>
                </div> -->

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addNewEquipmentTypesPopup" tabindex="-1" role="dialog"
    aria-labelledby="addNewEquipmentTypesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add a Service/Checklist</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add a Service/Checklist</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addServiceCheckListForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby=""
                                                            placeholder="Enter Equipment Type Name"
                                                            formControlName="typeName">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Priority</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                        [options]="priorityArray"    placeholder="Choose Priority" 
                                                        formControlName="priority">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" 
                                                            formControlName="duration">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Skill</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                         [options]="skillDropdownArray"   placeholder="Choose Skill" formControlName="skill">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Description"
                                                            formControlName="Description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 47%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                               *ngIf="!this.show" (click)="createFsmEquipmentService()">Add</button>
                                                <button type="submit"
                                                *ngIf="this.show"   (click)="updateFsmEquipmentService()" 
                                                class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addServiceDescriptionPopup" tabindex="-1" role="dialog"
    aria-labelledby="addServiceDescriptionPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add a Equipment Service</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add a Equipment Service</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addServiceDescriptionForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" 
                                                            formControlName="name">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="time" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" 
                                                            formControlName="duration">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Priority</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                         [options]="priorityArray"   placeholder="Choose Priority" 
                                                         formControlName="priority">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Skill</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                         [options]="skillDropdownArray"   placeholder="Choose Skill" formControlName="skill">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Description"
                                                            formControlName="description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 47%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Add</button>
                                                <button type="submit"
                                                    class="btn btn-secondary submit-btn">Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addServiceTrackPopup" tabindex="-1" role="dialog" aria-labelledby="addServiceTrackPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add a Equipment Service</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add a Equipment Service</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addServiceTrackForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            name="" aria-describedby="" placeholder="Enter Name"
                                                            formControlName="name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes ">
                                                        <label for="exampleInputEmail1">Type</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                        name="" aria-describedby="" placeholder="Enter type"
                                                        formControlName="type">
                                                      
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Keep History</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" placeholder="Select Keep History"
                                                          [options]="yesNoArray"  formControlName="keepHistory">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Required</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" placeholder="Select Required"
                                                        [options]="yesNoArray"    formControlName="required">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormAB">
                                                        <label for="exampleInputEmail1">Track</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" placeholder="Select Track"
                                                        [options]="yesNoArray" formControlName="track">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 47%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                             *ngIf="!this.showTrack"   (click)="createFsmEquipmentTrack()">Create</button>
                                                <button type="submit" *ngIf="this.showTrack"   
                                                 (click)="updateFsmEquipmentTrack()"   class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>