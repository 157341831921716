import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';


@Component({
  selector: 'app-requirements',
  templateUrl: './requirements.component.html',
  styleUrls: ['./requirements.component.css']
})
export class RequirementsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  activeCategory = 1;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  advanceRequirement: { RequirementID: string; RequirementTypeID: string; StatusID: string; FacilityID: string; ProductID: string; Description: string; StartDate: string; ByDate: string; };
  advanceSearch: { RequirementID: string; RequirementTypeID: string; FacilityID: string; ProductID: string; Description: string; StartDate: string; ByDate: string; };
  advanceProSearch: { RequirementID: string; CustomerPartyId: string; Supplier: string; CurrencyUomID: string; unassignedRequirements: string; ByDate: string; FacilityID: string; ProductID: string; };
  RequirementForm: any;
  findRequirementsList: any;
  fixedAssetIdRequirement: any;
  fixedAssetIdRequirementArray: any[]=[];
  facilityRequirement: any;
  facilityRequirementArray: any[]=[];
  FacilityID: any;
  FacilityIDArray: any[]=[];
  fixedAssetIdArray: any[]=[];
  fixedAssetId: any;
  statusIdArray: { label: string; value: string; }[];
  ProductId: any;
  ProductIdArray: any[]=[];
  RequirementType: any;
  RequirementTypeArray: any[]=[];
  supplierList: any;
  supplierListArray: any[]=[];
  billToCustomerParty: any;
  billToCustomerPartyArray: any[]=[];
  currencyArray: any[]=[];
  ApprovedRequirementList: any;
  approvedRequirementsBySupplier: any;
  approvedProductRequirements: any;
  facilityIdListOrder: any;
  facilityIdListOrderArray: any[]=[];
  prepareOrderForm: any;
  approvedProductRequirementList: any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly headerService: HeaderService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.advanceRequirement = {
      RequirementID: "",
      RequirementTypeID: "",
      StatusID: "",
      FacilityID: "",
      ProductID: "",
      Description: "",
      StartDate: "",
      ByDate: "",

    };
    this.advanceSearch = {
      RequirementID: "",
      RequirementTypeID: "",
      FacilityID: "",
      ProductID: "",
      Description: "",
      StartDate: "",
      ByDate: "",

    };
    this.advanceProSearch = {
      RequirementID: "",
      CustomerPartyId: "",
      Supplier: "",
      CurrencyUomID: "",
      unassignedRequirements: "",
      ByDate: "",
      FacilityID: "",
      ProductID: "",

    };
    this.RequirementForm = this._FormBuilder.group({
      RequirementTypeID: [''],
      FacilityID: [''],
      DeliverableID: [''],
      FixedAssetID: [''],
      ProductID: [''],
      StatusID: [''],
      Description: [''],
      StartDate: [''],
      ByDate: [''],
      EstimatedBudget: [''],
      Quantity: [''],
      UseCase: [''],
      Reason: [''],
      CreatedDate: [''],
      CreatedByUser: [''],
      LastModifiedDate: [''],
      FacilityIDTo: [''],
      LastModifiedUser: [''],
      CustRequestID: [''],
      CustRequestItemSeqID: [''],
    });
    this.prepareOrderForm = this._FormBuilder.group({
      BillToCustomerPartyID: [''],
      FacilityID: [''],
      PartyID: [''],
    });
    this.statusIdArray = [

      {
        label: 'Proposed',
        value: 'REQ_PROPOSED'
      },
      {
        label: 'Created',
        value: 'REQ_CREATED'
      },
      {
        label: 'Approved',
        value: 'REQ_APPROVED'
      },
      {
        label: 'Ordered',
        value: 'REQ_ORDERED'
      },
      {
        label: 'Rejected',
        value: 'REQ_REJECTED'
      },
    ];
  }

  ngOnInit(): void {
    this.getFindRequirements();
    this.getFixedAssetIdRequirement();
    this.getFacilityRequirement();
    this.getfixedAssetID();
    this.getProductId();
    this.getRequirementType();
    this.getSupplierList();
    this.billToCustomerPartyId();
    this.getCurrency();
    this.billToCustomerPartyId();
    this.getfindNotApprovedRequirement();
    this.getFacilityIdListOrder();
    this.findApprovedRequirementsBySupplier();
    this.getFindApprovedProductRequirements();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/order/main-order']);
  }
  resetApproveProRequirement() {
    this.spinner.show();
    this.advanceProSearch.RequirementID = "";
    this.advanceProSearch.CustomerPartyId = "";
    this.advanceProSearch.FacilityID = "";
    this.advanceProSearch.ProductID = "";
    this.advanceProSearch.CurrencyUomID = "";
    this.advanceProSearch.Supplier = "";
    this.advanceProSearch.unassignedRequirements = "";
    this.advanceProSearch.ByDate = "";
   
   
    this.myContactsService.getFindApprovedProductRequirements().subscribe((res: any) => {
      this.approvedProductRequirementList = res.data.requirementsForSupplier;
    })
    this.spinner.hide();
  }
  FindApprovedProductRequirements() {
    this.spinner.show();
    if(this.advanceProSearch.FacilityID||this.advanceProSearch.CustomerPartyId||this.advanceProSearch.ProductID||this.advanceProSearch.RequirementID||this.advanceProSearch.Supplier){
    const req = { 
      "facilityId":  this.advanceProSearch.FacilityID ? this.advanceProSearch.FacilityID : ''  ,
  "partyId":  this.advanceProSearch.CustomerPartyId ? this.advanceProSearch.CustomerPartyId : ''  ,
  "productId": this.advanceProSearch.ProductID ? this.advanceProSearch.ProductID : ''  ,
  "requirementId": this.advanceProSearch.RequirementID ? this.advanceProSearch.RequirementID : ''  ,
  "roleTypeId":  this.advanceProSearch.Supplier ? this.advanceProSearch.Supplier : ''  ,
    }
    this.myContactsService.FindApprovedProductRequirements(req.facilityId,req.partyId,
      req.productId,req.requirementId,req.roleTypeId).subscribe((res: any) => {
      this.approvedProductRequirementList = res.data.requirementsForSupplier;
    })
  }
  else{
    this.getFindApprovedProductRequirements;
  }
    this.spinner.hide();
  }
  getFindApprovedProductRequirements() {
    this.spinner.show();
    this.myContactsService.getFindApprovedProductRequirements().subscribe((res: any) => {
      this.approvedProductRequirementList = res.data.requirementsForSupplier;
      this.spinner.hide();

    })
  }
  onSubmitPdf() {
    
    this.spinner.show();
    this.myContactsService.approvedProductRequirementsReport().subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "ApprovedProductRequirementsReport";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }
  getFacilityIdListOrder() {
    this.spinner.show();
    this.myContactsService.getFacilityIdListOrder().subscribe(res => {
      this.facilityIdListOrder = res.data[0].data ;
      this.spinner.hide();
      for (const value of this.facilityIdListOrder) {
        this.facilityIdListOrderArray.push({
          label: value.description,
          value: value.facilityId
        })
      }
    })
  }
 
  findApprovedRequirementsBySupplier() {
    this.spinner.show();
    this.myContactsService.findApprovedRequirementsBySupplier().subscribe((res: any) => {
      this.approvedRequirementsBySupplier = res.data.requirements.completeList;
      this.spinner.hide();
    })
  }
  
  approveRequirement(product) {
    this.spinner.show();
    this.myContactsService.approveRequirement(product.requirement.requirementId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  resetApproveRequirement() {
    this.spinner.show();
  
    this.advanceSearch.RequirementID = "";
    this.advanceSearch.RequirementTypeID = "";
    this.advanceSearch.FacilityID = "";
    this.advanceSearch.ProductID = "";
    this.advanceSearch.Description = "";
    this.advanceSearch.StartDate = "";
    this.advanceSearch.ByDate = "";
    this.ApprovedRequirementList = []
   
    const req = {
      "description": "",
      "facilityId": "",
      "productId": "",
      "requiredByDate": "",
      "requirementId": "",
      "requirementStartDate": "",
      "requirementTypeId": ""
    }
    this.myContactsService.findNotApprovedRequirement(req).subscribe((res: any) => {
      this.ApprovedRequirementList = res.data;
    })
    this.spinner.hide();
  }
  findNotApprovedRequirement() {
    this.spinner.show();
    this.ApprovedRequirementList = [];
    const req = { 
  
      "description":  this.advanceSearch.Description ? this.advanceSearch.Description : ''  ,
  "facilityId":  this.advanceSearch.FacilityID ? this.advanceSearch.FacilityID : ''  ,
  "productId": this.advanceSearch.ProductID ? this.advanceSearch.ProductID : ''  ,
  "requiredByDate": this.advanceSearch.ByDate ? this.advanceSearch.ByDate : ''  ,
  "requirementId":  this.advanceSearch.RequirementID ? this.advanceSearch.RequirementID : ''  ,
  "requirementStartDate":  this.advanceSearch.StartDate ? this.advanceSearch.StartDate : ''  ,
  "requirementTypeId":  this.advanceSearch.RequirementTypeID ? this.advanceSearch.RequirementTypeID : ''  ,

    }
    this.myContactsService.findNotApprovedRequirement(req).subscribe((res: any) => {
      this.ApprovedRequirementList = res.data;
    })
    this.spinner.hide();
  }
  getfindNotApprovedRequirement() {
    this.spinner.show();
    const req = {
      "description": "",
      "facilityId": "",
      "productId": "",
      "requiredByDate": "",
      "requirementId": "",
      "requirementStartDate": "",
      "requirementTypeId": ""
    }
    this.myContactsService.getfindNotApprovedRequirement(req).subscribe((res: any) => {
      this.ApprovedRequirementList = res.data;
      this.spinner.hide();

    })
  }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })

  }
  billToCustomerPartyId() {
    this.spinner.show();
    this.myContactsService.billToCustomerPartyId().subscribe(res => {
      this.billToCustomerParty = res.data.data ;
      this.spinner.hide();
      for (const value of this.billToCustomerParty) {
        this.billToCustomerPartyArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getSupplierList() {
    this.spinner.show();
    this.myContactsService.getSupplierList().subscribe(res => {
      this.supplierList = res.data.getCostComponentCalcId ;
      this.spinner.hide();
      for (const value of this.supplierList) {
        this.supplierListArray.push({
          label: value.groupName,
          value: value.partyId
        })
      }
    })
  }
  deleteRequirement(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "requirementId": product.requirement.requirementId,
  "view_INDEX_1": "0",
  "view_SIZE_1": "20"
        }
        this.myContactsService.deleteRequirement(req).subscribe(res => {
          this.getFindRequirements(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  detailPage(requirementId) {
    this._Router.navigate(["/order/detail-requirements"], { queryParams: { id:requirementId} })
  }
  detailRequestsPage(requirementId) {
    this._Router.navigate(["/order/detail-requirements"], { queryParams: { id:requirementId,activeCategory:"2"} })
  }
  detailOrderPage(requirementId) {
    this._Router.navigate(["/order/detail-requirements"], { queryParams: { id:requirementId,activeCategory:"3"} })
  }
  getRequirementType() {
    this.spinner.show();
    this.myContactsService.getRequirementType().subscribe(res => {
      this.RequirementType = res.data[0].requirementTypeData;
      this.spinner.hide();
      for (const value of this.RequirementType) {
        this.RequirementTypeArray.push({
          label: value.description,
          value: value.requirementTypeId
        })
      }
    })
   
  }
  getProductId() {
    this.spinner.show();
    this.accountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
   
  }
  getfixedAssetID() {
    this.spinner.show();
    this.myContactsService.getFixedAssetIdRequirement().subscribe(res => {
      this.fixedAssetId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.fixedAssetId) {
        this.fixedAssetIdArray.push({
          label: value.fixedAssetId,
          value: value.fixedAssetId
        })
      }
    })
  }
  getFacilityRequirement() {
    this.spinner.show();
    this.myContactsService.getFacilityRequirement().subscribe(res => {
      this.facilityRequirement = res.data ;
      this.spinner.hide();
      for (const value of this.facilityRequirement) {
        this.facilityRequirementArray.push({
          label: value.description,
          value: value.facilityId
        })
      }
    })
  }

  getFixedAssetIdRequirement() {
    this.spinner.show();
    this.myContactsService.getFixedAssetIdRequirement().subscribe(res => {
      this.fixedAssetIdRequirement = res.data[0].data ;
      this.spinner.hide();
      for (const value of this.fixedAssetIdRequirement) {
        this.fixedAssetIdRequirementArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
    })
    
  }

  resetRequirement() {
    this.spinner.show();
  
    this.advanceRequirement.RequirementID = "";
    this.advanceRequirement.StatusID = "";
    this.advanceRequirement.RequirementTypeID = "";
    this.advanceRequirement.FacilityID = "";
    this.advanceRequirement.ProductID = "";
    this.advanceRequirement.Description = "";
    this.advanceRequirement.StartDate = "";
    this.advanceRequirement.ByDate = "";
    this.findRequirementsList = []

    const req = {
      "facilityId": "",
      "manufacturingRequirementByDate": "",
      "manufacturingRequirementStartDate": "",
      "productId": "",
      "requirementId": "",
      "requirementTypeId": "",
      "statusId": ""
    }
    this.myContactsService.getFindRequirements(req).subscribe((res: any) => {
      this.findRequirementsList = res.data;
    })
    this.spinner.hide();
  }
  findRequirements() {
    this.spinner.show();
    this.findRequirementsList = [];
    const req = {
      "facilityId": this.advanceRequirement.FacilityID ? this.advanceRequirement.FacilityID : ''  ,
      "manufacturingRequirementByDate": this.advanceRequirement.ByDate ? this.advanceRequirement.ByDate : ''  ,
      "manufacturingRequirementStartDate": this.advanceRequirement.StartDate ? this.advanceRequirement.StartDate : ''  ,
      "productId": this.advanceRequirement.ProductID ? this.advanceRequirement.ProductID : ''  ,
      "requirementId": this.advanceRequirement.RequirementID ? this.advanceRequirement.RequirementID : ''  ,
      "requirementTypeId": this.advanceRequirement.RequirementTypeID ? this.advanceRequirement.RequirementTypeID : ''  ,
      "statusId":this.advanceRequirement.StatusID ? this.advanceRequirement.StatusID:'' 

    }
    this.myContactsService.findRequirements(req).subscribe((res: any) => {
      this.findRequirementsList = res.data;
    })
    this.spinner.hide();
  }
  getFindRequirements() {
    this.spinner.show();
    const req = {
      "facilityId": "",
      "manufacturingRequirementByDate": "",
      "manufacturingRequirementStartDate": "",
      "productId": "",
      "requirementId": "",
      "requirementTypeId": "",
      "statusId": ""
    }
    this.myContactsService.getFindRequirements(req).subscribe((res: any) => {
      this.findRequirementsList = res.data;
      this.spinner.hide();

    })
  }
  createRequirement() {
    this.spinner.show();

    const requestData = {
      "createdByUserLogin": this.RequirementForm.value.CreatedByUser,
      "createdDate": this.datePipe.transform(this.RequirementForm.value.CreatedDate, "yyyy-MM-dd HH:mm:ss"),
      "custRequestId": this.RequirementForm.value.CustRequestID,
      "custRequestItemSeqId": this.RequirementForm.value.CustRequestItemSeqID,
      "deliverableId": this.RequirementForm.value.DeliverableID,
      "description": this.RequirementForm.value.Description,
      "estimatedBudget": this.RequirementForm.value.EstimatedBudget,
      "facilityId": this.RequirementForm.value.FacilityID,
      "facilityIdTo": this.RequirementForm.value.FacilityIDTo,
      "fixedAssetId": this.RequirementForm.value.FixedAssetID,
      "lastModifiedByUserLogin": this.RequirementForm.value.LastModifiedUser,
      "lastModifiedDate": this.datePipe.transform(this.RequirementForm.value.LastModifiedDate, "yyyy-MM-dd HH:mm:ss"),
      "productId": this.RequirementForm.value.ProductID,
      "quantity": this.RequirementForm.value.Quantity,
      "reason": this.RequirementForm.value.Reason,
      "requiredByDate": this.datePipe.transform(this.RequirementForm.value.ByDate, "yyyy-MM-dd HH:mm:ss"),
      "requirementId": "",
      "requirementStartDate": this.datePipe.transform(this.RequirementForm.value.StartDate, "yyyy-MM-dd HH:mm:ss"),
      "requirementTypeId": this.RequirementForm.value.RequirementTypeID,
      "statusId": this.RequirementForm.value.StatusID,
      "useCase": this.RequirementForm.value.UseCase
    }

    this.myContactsService.createRequirement(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.RequirementForm.reset();
        this.getFindRequirements();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
