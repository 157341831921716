import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from './../../header/header.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { DropdownModule } from 'primeng/dropdown';
import { OrderManagementService } from '../order-management.service';
import { ToastrService } from 'ngx-toastr';
import { NgWizardConfig, NgWizardService, StepChangedArgs, StepValidationArgs, STEP_STATE, THEME } from 'ng-wizard';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-sales-order-confirmation',
  templateUrl: './sales-order-confirmation.component.html',
  styleUrls: ['./sales-order-confirmation.component.css']
})
export class SalesOrderConfirmationComponent implements OnInit {
  activeCategory = 1;
  stepStates = {
    normal: STEP_STATE.normal,
    disabled: STEP_STATE.disabled,
    error: STEP_STATE.error,
    hidden: STEP_STATE.hidden
  };
  config: NgWizardConfig = {
    selected: 8,
    theme: THEME.arrows,
    toolbarSettings: {
      toolbarExtraButtons: [
        { text: 'Finish', class: 'btn btn-info', event: () => { alert("Finished!!!"); } }
      ],
    }
  };
  products3: any;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  constructor(
    readonly _OrderManagementService: OrderManagementService,
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly _ToastrService: ToastrService
  ) {

  }
  offerShowingLineColoumns = [
    { field: 'Destination', header: 'Destination' },
    { field: 'Supplier', header: 'Supplier' },
    { field: 'ShipmentMethod', header: 'Shipment Method' },
    { field: 'Items', header: 'Items' },
    { field: 'Quantity', header: 'Quantity' },
  ];

  offerShowingLineData = [{
    status: 'Mr. THE PRIVILEGED ADMINISTRATOR',
    name: 'Sector A Officers  Hill Colony   Kohima , AA  797001',
    email: 'Test',
    phone: 'USPS Express	',
    type: '0',
    amount: 'GZ-1000 -	',
    edit: '1',
  }];
  orderItemsColumns = [
    { field: 'status', header: 'Product' },
    { field: 'name', header: 'Quantity' },
    { field: 'email', header: 'Unit Price' },
    { field: 'phone', header: 'Adjustments' },
    { field: 'type', header: 'Sub Total' },
  ];

  orderItemsData = [{
    status: 'GZ-1000 - Tiny Gizmo',
    name: '1',
    email: 'US$15.99',
    phone: 'US$0.00',
    type: 'US$15.99  ',
  }];
  reviewOrderValue: any;
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails()
   
    this.reviewOrder();
  }
  reviewOrder(): void {
    this.spinner.show();
    this._OrderManagementService.reviewOrder()
      .then(data => {
        this.spinner.hide();
        this.reviewOrderValue = data.data;
      });
     
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  processOrder(): void {
    this.spinner.show();
    this._OrderManagementService.processOrder()
      .then(data => {
        if (data.success) {
          if (data.data.errorMessage) {
            this._ToastrService.error(data.data.errorMessage);
          } else {
            this._ToastrService.success('Created');
            this.spinner.hide();
            this.router.navigate(['/order/sales-order-view-page'], { queryParams: { orderId: data.data.orderId } });
          }
        }
      });
      this.spinner.hide();
  }
}
