import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { ProductionService } from '../production.service';import { Location } from '@angular/common';
@Component({
  selector: 'app-production-run-declaration',
  templateUrl: './production-run-declaration.component.html',
  styleUrls: ['./production-run-declaration.component.css']
})
export class ProductionRunDeclarationComponent implements OnInit {
  createProduction:FormGroup;
  activeCategory=2;
  workEffortId: any;
  workId: any;
  estimatedCompletionDate: string;
  estimatedStartDate: string;
  actualCompletionDate: string;
  startDateEdit: any;
  finishDate: any;
  ThroughDate: string;

  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    readonly router: Router,
    private _location: Location,
    readonly productionService:ProductionService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly _ToastrService: ToastrService,) { 
      this.createProduction = this._FormBuilder.group({
        SequenceNum: [''],
        RoutingTaskName:[''],
        Description: [''],
        CalculatedCompletionDate: [''],
        FromDate:[''],
        ToDate: [''],
        EstimatedSetupTime: [''],
        ActualSetupTime:[''],
        AddActualSetupTime: [''],
        EstimatedUnitRunTime: [''],
        ActualTime:[''],
        AddActualTaskTime: [''],
        QtyToProduce: [''],
        Produced:[''],
        AddQtyProduced: [''],
        Rejected: [''],
        AddQtyRejected:[''],
        Comments: [''],
        Worker: [''],
      
       
       
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
      this.workId = params["workId"];
    
    });
    this.declareProductProduceQty();
  }
  declareProductProduceQty(){
    this.spinner.show(); 
    this.productionService.declareProductProduceQty(this.workEffortId,this.workId).subscribe((res: any) => {
      setTimeout(() => {
        this.startDateEdit=res.data.estimatedStartDate;
        this.finishDate=res.data.estimatedCompletionDate;
         const completeDate=res.data.estimatedCompletionDate
         this.estimatedCompletionDate=this.datePipe.transform(completeDate,"yyyy-MM-dd");
         const startDate=res.data.estimatedStartDate
         this.estimatedStartDate=this.datePipe.transform(startDate,"yyyy-MM-dd");
         const throughDate=res.data.actualCompletionDate
         this.actualCompletionDate=this.datePipe.transform(throughDate,"yyyy-MM-dd");
         const formValue = this.createProduction;
         formValue.patchValue({
          SequenceNum:res.data.priority,
          RoutingTaskName:res.data.workEffortName,Description: res.data.description,
          CalculatedCompletionDate:this.estimatedCompletionDate,FromDate:this.estimatedStartDate,
          ToDate:this.actualCompletionDate,EstimatedSetupTime:res.data.estimatedSetupMillis,
          ActualSetupTime:res.data.actualSetupMillis,
          EstimatedUnitRunTime: res.data.estimatedMilliSeconds,
          ActualTime:res.data.actualMilliSeconds,
          QtyToProduce:res.data.quantityToProduce,
          Produced:res.data.quantityProduced,
          Rejected: res.data.quantityRejected,  Worker: res.data.createdByUserLogin,
         })
       }, 2000);
      this.spinner.hide();
    }) }
    homeButton(){
      this.router.navigate(['/production/main-production'])
    
    }
    cancelSubmit(){
      this._location.back();
    }

  onSubmit() {
    this.spinner.show();
    const ThroughDate = this.createProduction.get('ToDate').value;
    this.ThroughDate = this.datePipe.transform(ThroughDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "actualMilliSeconds": this.createProduction.value.ActualTime,
      "actualSetupMillis": this.createProduction.value.ActualSetupTime,
      "addQuantityProduced": this.createProduction.value.AddQtyProduced,
      "addQuantityRejected": this.createProduction.value.AddQtyRejected,
      "addSetupTime":this.createProduction.value.AddActualSetupTime,
      "addTaskTime": this.createProduction.value.AddActualTaskTime,
      "comments":this.createProduction.value.Comments,
      "description":this.createProduction.value.Description,
      "estimatedCompletionDate": this.finishDate,
      "estimatedMilliSeconds": this.createProduction.value.EstimatedUnitRunTime,
      "estimatedSetupMillis": this.createProduction.value.EstimatedSetupTime,
      "fromDate": this.startDateEdit,
      "partyId": this.createProduction.value.Worker,
      "priority":  this.createProduction.value.SequenceNum,
      "productionRunId": this.workEffortId,
      "productionRunTaskId": this.workId,
      "quantityProduced": this.createProduction.value.Produced,
      "quantityRejected": this.createProduction.value.Rejected,
      "quantityToProduce": this.createProduction.value.QtyToProduce,
      "toDate": this.ThroughDate,
      "workEffortName": this.createProduction.value.RoutingTaskName,
    }
    this.productionService.postDeclareProductProduceQty(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: this.workEffortId} })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  // cancelSubmit(){
  //   this._Router.navigate(["production/detail-jobshop-summary"], { queryParams: { workEffortId: this.workEffortId} })
  // }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

