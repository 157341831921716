import { Component, OnInit } from '@angular/core';
import {BehaviourSubjectService} from "../../behaviour-subject.service";
import { SortEvent } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import {MyContactsService} from "../../crm/contacts/my-contacts/my-contacts.service";
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {
 public employeeList: any;
 public total=0;
 public rows = 50;
 departmentId:any;


  constructor(private data:BehaviourSubjectService,
    readonly spinner:NgxSpinnerService,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly myContactsService:MyContactsService,
    ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.departmentId = params.departmentId;
    })
   
    // this.data.currentMessage.subscribe((res:any)=> {
    //   this.employeeList = res.employees;
    // })
    this.getDepartmentById();
  }

  getDepartmentById(){
    this.spinner.show();
    this.myContactsService.getDepartmentById(this.departmentId).subscribe((res)=> {
      this.employeeList = res.data.employees;
      this.spinner.hide();
    })
   
  }

  cancelSubmit(){
    this.router.navigate(["/hr/departments"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
 
}
