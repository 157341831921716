import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr'; 
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-create-new-party-group',
  templateUrl: './create-new-party-group.component.html',
  styleUrls: ['./create-new-party-group.component.css']
})
export class CreateNewPartyGroupComponent implements OnInit {

  show=false;
  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];

  createPartyGroup : FormGroup;
  currencyArray: any[]=[];
  
  constructor(readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService, 
    readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    private _location: Location,
    ) {
    this.createPartyGroup = this._FormBuilder.group({

      annualRevenue: [''],
      comments: [''],
      description: [''],
      externalId: [''],
      firstName: [''],
      groupName: [''],
      groupNameLocal: [''],
      lastName: [''],
      logoImage: [''],
      numberOfEmployees: [''],
      officeSiteName: [''],
      partyId : [''],
      preferredCurrency: [''],
      tickerSymbol: [''],
      title: [''],
      
    });
  }


  ngOnInit(): void {
    this.getCurrency();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/partyQuickLink/party-dashboard']);

  }

  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  GoToQuickLink(){
    this.router.navigate(['/partyQuickLink']);
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  onSubmit(): void {
this.spinner.show();
    const requestData = {

        "annualRevenue": this.createPartyGroup.value.annualRevenue ,
        "comments": this.createPartyGroup.value.comments ,
        "description": this.createPartyGroup.value.description ,
        "externalId": this.createPartyGroup.value.externalId ,
        "firstName": this.createPartyGroup.value.firstName ,
        "groupName": this.createPartyGroup.value.groupName ,
        "groupNameLocal": this.createPartyGroup.value.groupNameLocal ,
        "lastName": this.createPartyGroup.value.lastName ,
        "logoImageUrl": this.createPartyGroup.value.logoImage ,
        "numEmployees": this.createPartyGroup.value.numberOfEmployees ,
        "officeSiteName": this.createPartyGroup.value.officeSiteName ,
        "partyId": this.createPartyGroup.value.partyId ,
        "preferredCurrencyUomId": this.createPartyGroup.value.preferredCurrency ,
        "statusId": "KW_APPROVED" ,
        "tickerSymbol": this.createPartyGroup.value.tickerSymbol ,
        "title": this.createPartyGroup.value.title
    
    }

    this.accountsService.postCreatePartyGroup(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/partyQuickLink/find-party']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }

  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
    this.spinner.hide();
  }
 

}
