<div class="all-users-infomation font-13 font-medium own-user-select">
    <div class="col-lg-12 col-12">
        <div class="form-group">
          <label for="exampleInputEmail1">Open Tasks Assigned to me</label>

          <div class="card own-account-table">
              <!-- This is assigned to me data and component -->
            <p-table [value]="assignedToMeData" [paginator]="true" [rows]="rows"
                                                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                        [totalRecords]="total" [scrollable]="true"
                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                        <ng-template pTemplate="header">
                                                            <tr>
                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox styleclass="custom-checkbox"
                                                                            binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined"></div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                            <!---->
                                                                        </p-checkbox>
                                                                        WorkEffort ID <p-sortIcon field="code">
                                                                        </p-sortIcon>
                                                                    </div>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">Purpose <p-sortIcon
                                                                        field="name"></p-sortIcon>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="category" [ngStyle]="{'width':'170px'}"> Description <p-sortIcon
                                                                        field="category"></p-sortIcon>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="quantity"[ngStyle]="{'width':'170px'}"> Priority <p-sortIcon
                                                                        field="quantity"></p-sortIcon>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price"[ngStyle]="{'width':'170px'}">Estimated Start Date <p-sortIcon
                                                                        field="price"></p-sortIcon>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price"[ngStyle]="{'width':'170px'}">Estimated Completion Date <p-sortIcon
                                                                        field="price"></p-sortIcon>
                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price"[ngStyle]="{'width':'170px'}">Actual Start Date <p-sortIcon
                                                                    field="price"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price"[ngStyle]="{'width':'170px'}">Actual Completion Date   <p-sortIcon
                                                                    field="price"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">Assigned by<p-sortIcon
                                                                    field="price"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">Created At<p-sortIcon
                                                                    field="price"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">Action<p-sortIcon
                                                                    field="price"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                                </th>
                                                                <th pSortableColumn="price" [ngStyle]="{'width':'170px'}">Complete<p-sortIcon
                                                                    field="price"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                                </th>
                                                            </tr>
                                                        </ng-template>
                                                        <ng-template pTemplate="body" let-product>
                                                            <tr>
                                                                <td (click)="eventDetail(product.workEffortId)"[ngStyle]="{'width':'170px'}">
                                                                    <div class="checkbox-align">
                                                                        <p-checkbox 
                                                                            styleclass="custom-checkbox" binary="true"
                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                            <div
                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                <div
                                                                                    class="ui-helper-hidden-accessible">
                                                                                    <input type="checkbox"
                                                                                        name="undefined"
                                                                                        value="undefined"></div>
                                                                                <div role="checkbox"
                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                    <span
                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                </div>
                                                                            </div>
                                                                        </p-checkbox>
                                                                        {{product.workEffortId}}
                                                                    </div>
                                                                </td>
                                                              
                                                                <td [ngStyle]="{'width':'170px'}">{{product.workEffortPurposeType}}</td>
                                                                <td [ngStyle]="{'width':'170px'}">{{product.description}}</td>
                                                                <td [ngStyle]="{'width':'170px'}">{{product.priority}}</td>
                                                                <td [ngStyle]="{'width':'170px'}">{{product.estimatedStartDate | date:'medium'}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">{{product.estimatedCompletionDate | date:'medium'}}
                                                                </td>
                                                               
                                                                <td [ngStyle]="{'width':'170px'}">{{product.estimatedCompletionDate | date:"yyyy-MM-dd"}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">{{product.estimatedCompletionDate | date:"yyyy-MM-dd"}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">{{product.assignedBy}}
                                                                </td>
                                                                <td [ngStyle]="{'width':'170px'}">{{product.createdStamp | date:"yyyy-MM-dd HH:mm:ss"}}
                                                                </td>


                                                                <td [ngStyle]="{'width':'170px'}">
                                                                    
                                                                        <svg class="hover"
                                                                            (click)="updateEventBy(product.workEffortId)"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="16" height="16"
                                                                            viewBox="0 0 21.223 21.222">
                                                                            <g transform="translate(0 -0.004)">
                                                                                <g transform="translate(0 1.52)">
                                                                                    <path
                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                        transform="translate(0 -34.137)" />
                                                                                </g>
                                                                                <g transform="translate(4.548 0.004)">
                                                                                    <g transform="translate(0 0)">
                                                                                        <path
                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    
                                                                </td>
                                                                <td class="color"
                                                                    (click)="completeEvent(product.workEffortId)" [ngStyle]="{'width':'170px'}">
                                                                    Complete</td>

                                                            </tr>
                                                        </ng-template>
                                                    </p-table>
                <p class="paginate_data">
                    View per page   </p>
        </div>
        </div>
      </div>
</div>
<ngx-spinner></ngx-spinner>