import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'PHYRSTECH';
  public token: string;
  public=false;
  userPermissions: any;
  sidebarExpanded = true;

  constructor(

    readonly _NgxSpinnerService: NgxSpinnerService,
    readonly  _Router: Router
  ) {
    this._Router.events.subscribe((event: RouterEvent) => {
   
      if (event instanceof NavigationStart) {
        this.token = localStorage.getItem("token");
        if (event['url'].includes('/reset-password')|| event['url'].includes('/forget-email-password')){
          this.public = true;
       }
        else if(event['url'].includes('/login')||event['url'].includes('/')) { 
          this.public = false;
        
        } 
      }

      //// sidebar hidden ///

      // if (event instanceof NavigationStart) {
      //   this.token = localStorage.getItem("token");
      //   this.webpose = false;
      //   debugger
      //   if (event['url'].includes('/reset-password')|| event['url'].includes('/forget-email-password')){
      //     this.public = true;
      //  }
      //  else if(event['url'].includes('/webpos')) { 
      //   this.webpose = true;
      
      // } 
      
      //   else if(event['url'].includes('/login')||event['url'].includes('/')) { 
      //     this.public = false;
        
      //   } 
       
      //   else{
      //     this.webpose = false;
      //   }
      // }
    
    });
   
     
        }

  ngOnInit(): void {
    
    this.token = localStorage.getItem("token");
  }
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
   
      event.url.includes('login') ? this._NgxSpinnerService.hide() : this._NgxSpinnerService.show();
    }
    if (event instanceof NavigationEnd) {
      setTimeout(() => {
        this._NgxSpinnerService.hide();
      }, 3000);
    }
    if (event instanceof NavigationCancel) {
      this._NgxSpinnerService.hide();
    }
    if (event instanceof NavigationError) {
      this._NgxSpinnerService.hide();
    }
  }
  ngOnDestroy(): void {

  }
}




