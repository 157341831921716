import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreatePaymentModel {

  constructor(
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
     
      partyIdFrom:(data && data.partyIdFrom) ? data.partyIdFrom : null,
      paymentTypeId:(data && data.paymentTypeId) ? data.paymentTypeId : null,
      paymentMethodId:(data && data.paymentMethodId) ? data.paymentMethodId : null,
      paymentRefNum:(data && data.paymentRefNum) ? data.paymentRefNum : null,
      overrideGlAccountId:(data && data.overrideGlAccountId) ? data.overrideGlAccountId : null,
      amount:(data && data.amount) ? data.amount : null,
      comments:(data && data.comments) ? data.comments : null,
      finAccountTransId:(data && data.finAccountTransId) ? data.finAccountTransId : null,
      effectiveDate:(data && data.finAccountTransId) ? data.finAccountTransId : null,
      statusId:(data && data.finAccountTransId) ? data.finAccountTransId : null,
      partyIdTo:(data && data.finAccountTransId) ? data.finAccountTransId : null,
      actualCurrencyAmount:(data && data.finAccountTransId) ? data.finAccountTransId : null,
      actualCurrencyUomId:(data && data.finAccountTransId) ? data.finAccountTransId : null,
    });
    return this.getFormGroup(form_data);
  }

  getFormGroup(data:any): any {
   return ({
 
    partyIdFrom: new FormControl(data.partyIdFrom,Validators.required),
    paymentTypeId: new FormControl(data.paymentTypeId),
    paymentMethodId: new FormControl(data.paymentMethodId),
    paymentRefNum: new FormControl(data.paymentRefNum),
    overrideGlAccountId: new FormControl(data.overrideGlAccountId),
    amount: new FormControl(data.amount,Validators.required),
    comments: new FormControl(data.comments),
    finAccountTransId: new FormControl(data.finAccountTransId),
    effectiveDate: new FormControl(data.effectiveDate),
    statusId: new FormControl(data.statusId),
    partyIdTo: new FormControl(data.partyIdTo),
    actualCurrencyAmount: new FormControl(data.actualCurrencyAmount),
    actualCurrencyUomId: new FormControl(data.actualCurrencyUomId),

   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
