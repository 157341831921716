import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-commission-invoices',
  templateUrl: './commission-invoices.component.html',
  styleUrls: ['./commission-invoices.component.css']
})
export class CommissionInvoicesComponent implements OnInit {
  agreementType: string;

  constructor(readonly router: Router) { }

  ngOnInit(): void {
    this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
  }

  createInvoices(){
    this.router.navigateByUrl('/create-commision-invoice');
  }
}
