<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Find Party</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
           
            <div class=" bg-white color-grey">
              
                <div class="w3-card-4 classCard" style="margin-top: 1%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==0?'active':''" (click)="changeactiveCategoryFunction(0)"><a>
                                Find Party</a></li>
                            <!-- <li [ngClass]="activeCategory==1?'active':''" (click)="goToCreateEmployee(1)"><a> 
                                Create Employee</a></li>
                                <li [ngClass]="activeCategory==2?'active':''" (click)="goToCreateProspect(2)"><a>
                                    Create Prospect</a></li>
                                    <li [ngClass]="activeCategory==3?'active':''" (click)="goToCreateCustomer(3)"><a>
                                        Create Customer</a></li>
                                        <li [ngClass]="activeCategory==4?'active':''" (click)="goToCreateNewPerson(4)"><a>
                                            Create New Person</a></li>
                                            <li [ngClass]="activeCategory==5?'active':''" (click)="goToCreateNewPartyGroup(5)"><a>
                                                Create New Party Group</a></li> -->

                                                <!-- <button type="submit" class="btn btn-secondary submit-btn" (click)="toHub()" style="margin-left: 16%!important;
                                                margin-top: 7px!important;">Home</button> -->
                        </ul>
                    
                    </div>
                    <br>
                <div class="w3-card-4 classCard" style="    width: 98%;
                margin-left: 1%;" *ngIf="activeCategory==0">
                    <!-- <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategoryFunction(1)"><a>
                                    Find Party</a></li>
                        </ul>
                    </div> -->
                    <div class="create-new-leade mt-2 " >
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div *ngIf="activeCategory==0">
                                                    <div class="panel-body">
                                                        <form [formGroup]="findPartyForm">
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12" >
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Party
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="partyId"
                                                                            class="form-control"
                                                                            formControlName="partyId"
                                                                            id="partyId"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Party ID">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">User
                                                                                Login
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="userLoginIdArray"
                                                                            filter="true" placeholder="User Login Id">
                                                                        </p-dropdown>
                                                                        </div>
                                                                       
                                                                    </div>
                                                                  </div>
                                                                  <div class="col-lg-12" >
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">External
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="externalId"
                                                                            class="form-control"
                                                                            formControlName="externalId"
                                                                            id="externalId"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter External ID">
                                                                        </div>
                                                                        <!-- <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">First
                                                                                Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="roleTypeIdArray" formControlName="roleTypeId"
                                                                            filter="true" placeholder="Enter Role Type ID">
                                                                        </p-dropdown>
                                                                        </div> -->
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Soft
                                                                                Identifier</label>
                                                                           
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="softIdentifier"
                                                                                class="form-control" formControlName="softIdentifier"
                                                                                id="softIdentifier"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Soft Identifier">
                                                                        </div>
                                                                       
                                                                    </div>
                                                                  </div>
                                                                  <div class="col-lg-12" >
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">First
                                                                                Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="firstName"
                                                                            class="form-control"
                                                                            formControlName="firstName"
                                                                            id="firstName"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter First Name">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Last
                                                                                Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="lastName"
                                                                            class="form-control"
                                                                            formControlName="lastName"
                                                                            id="lastName"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Last Name">
                                                                        </div>
                                                                       
                                                                    </div>
                                                                  </div>
                                                                  <div class="col-lg-12" >
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Group
                                                                                Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="groupName"
                                                                                    class="form-control"
                                                                                    formControlName="groupName"
                                                                                    id="groupName"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Group Name">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Role
                                                                                Type
                                                                                Id</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="roleTypeIdArray" formControlName="roleTypeId"
                                                                                    filter="true" placeholder="Enter Role Type ID">
                                                                                </p-dropdown>
                                                                        </div>
                                                                       
                                                                    </div>
                                                                  </div>
                                                                  <div class="col-lg-12" >
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Type
                                                                                Id</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="userLoginIdArray" formControlName="partyTypeId"
                                                                                    filter="true" placeholder="Enter Type ID">
                                                                                </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label
                                                                                    for="exampleInputEmail1">Classification
                                                                                    Group</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="classificationGroup"
                                                                            class="form-control"
                                                                            id="classificationGroup"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Classification Group">
                                                                        </div>
                                                                       
                                                                    </div>
                                                                  </div>
                                                                  <div class="col-lg-12" >
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Id
                                                                                Value</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="idValue"
                                                                                    class="form-control"
                                                                                    formControlName="idValue"
                                                                                    id="idValue"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter ID Value">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label
                                                                                    for="exampleInputEmail1">Identification
                                                                                    Type</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="identificationArray" formControlName="partyIdentificationTypeId"
                                                                            filter="true" placeholder="Enter Identification Type">
                                                                        </p-dropdown>
                                                                        </div>
                                                                       
                                                                    </div>
                                                                  </div>
                                                                  <div class="col-lg-12" >
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Address
                                                                                1</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="address1"
                                                                                    class="form-control"
                                                                                    formControlName="address1"
                                                                                    id="address1"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Address 1">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Address
                                                                                2</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="address2"
                                                                                    class="form-control"
                                                                                    formControlName="address2"
                                                                                    id="address2"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Address 2">
                                                                        </div>
                                                                       
                                                                    </div>
                                                                  </div>
                                                                  <div class="col-lg-12" >
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label
                                                                                    for="exampleInputEmail1">City</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="city"
                                                                            class="form-control"
                                                                            formControlName="city"
                                                                            id="city"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter City">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">State
                                                                                </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="stateArray" name="state" formControlName="stateProvinceGeoId"
                                                                                    filter="true" placeholder="Enter State Province">
                                                                                </p-dropdown>
                                                                        </div>
                                                                       
                                                                    </div>
                                                                  </div>
                                                                  <div class="col-lg-12" >
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Postal
                                                                                Code</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="postalCode"
                                                                            class="form-control" formControlName="postalCode"
                                                                            id="postalCode"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Postal Code">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Country Code
                                                                                </label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="countryCode"
                                                                            class="form-control"
                                                                            formControlName="countryCode"
                                                                            id="countryCode"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Country Code">
                                                                        </div>
                                                                       
                                                                    </div>
                                                                  </div>
                                                                  <div class="col-lg-12" >
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Area
                                                                                Code</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="areaCode"
                                                                            class="form-control"
                                                                            formControlName="areaCode"
                                                                            id="areaCode"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Area Code">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Contact
                                                                                Number</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="contactNumber"
                                                                            class="form-control"
                                                                            formControlName="contactNumber"
                                                                            id="contactNumber"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Contact Number">
                                                                        </div>
                                                                       
                                                                    </div>
                                                                  </div>
                                                                  <div class="col-lg-12" >
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Other
                                                                                Contact
                                                                                Information</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="otherContactInformation"
                                                                                    class="form-control"
                                                                                    id="otherContactInformation"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Other Contact">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label
                                                                                    for="exampleInputEmail1">Email</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="email"
                                                                                    class="form-control"
                                                                                    formControlName="infoString"
                                                                                    id="email"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Email">
                                                                        </div>
                                                                       
                                                                    </div>
                                                                  </div>
                                                                  <div class="col-lg-12" >
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label
                                                                                    for="exampleInputEmail1">Inventory
                                                                                    Item ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="inventoryItemId"
                                                                            class="form-control"
                                                                            formControlName="inventoryItemId"
                                                                            id="inventoryItemId"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Inventory Item ID">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Serial
                                                                                Number</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="serialNumber"
                                                                                    class="form-control"
                                                                                    id="serialNumber"
                                                                                    aria-describedby="emailHelp"
                                                                                    placeholder="Enter Serial Number">
                                                                        </div>
                                                                       
                                                                    </div>
                                                                  </div>
                                                                 

                                                            </div>
                                                            <div style="margin-left: -22%;" class="col-lg-12 col-12 main-submit-button ">
                                                                <button _ngcontent-cta-c128="" type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="onSubmit()">Find</button>&nbsp;&nbsp;
                                                                <button type="button" (click)="reset();"
                                                                    class="btn btn-danger ml-2">Reset</button>&nbsp;
                                                            </div>
                                                        </form>
                                                        
                                                        <div>
                                                            <header class="w3-container">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Search Results
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div
                                                                class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="findPartyArray" [paginator]="true" [rows]="rows"
                                                                                scrollWidth="100%"
                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [totalRecords]="total"
                                                                                [scrollable]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="code">
                                                                                            <div class="checkbox-align"
                                                                                                style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox" binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                        <input type="checkbox" name="undefined"
                                                                                                            value="undefined"></div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <!---->
                                                                                            </p-checkbox>
                                                                                                Party ID <p-sortIcon
                                                                                                    field="code">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Classification Group
                                                                                                <p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                User Login ID<p-sortIcon
                                                                                                    field="name">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="category">
                                                                                            <div style="color: white;">
                                                                                                External ID<p-sortIcon
                                                                                                    field="category">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'200px'}"
                                                                                            width="11%"
                                                                                            pSortableColumn="quantity">
                                                                                            <div style="color: white;">
                                                                                                Name
                                                                                                <p-sortIcon
                                                                                                    field="quantity">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'200px'}"
                                                                                            width="11%"
                                                                                            pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                Type
                                                                                                <p-sortIcon
                                                                                                    field="price">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                Main Role
                                                                                                <p-sortIcon
                                                                                                    field="price">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                Created Date <p-sortIcon
                                                                                                    field="price">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                Last Modified Date <p-sortIcon
                                                                                                    field="price">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="price">
                                                                                            <div style="color: white;">
                                                                                                <p-sortIcon
                                                                                                    field="price">
                                                                                                </p-sortIcon>
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body"
                                                                                    let-product>
                                                                                    <tr>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                                                                <span style="cursor: pointer;color: #0d3769;" (click)="goToprofilePartyQuickLink(product)"
                                                                                                    class="account-button">{{product.partyId}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.partyClassificationGroupId}}
                                                                                        </td>
                                                                                        <td 
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            <label *ngIf="product.userLoginId!='(none)'&&product?.userLoginId?.length > 0">
                                                                                          <span *ngFor="let login of product.userLoginId">{{login.userLoginId}}<br></span> </label> 
                                                                                          <label *ngIf="product.userLoginId.userLoginId!='(none)'">
                                                                                            <span >{{product.userLoginId.userLoginId}}<br></span> </label> 
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.externalId}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'200px'}"
                                                                                            width="11%">
                                                                                            {{product.partyName}}
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'200px'}"
                                                                                            width="11%">
                                                                                            {{product.partyTypeDesc}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.mainRole }}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.createdDate|
                                                                                                date:'yyyy-MM-d hh:mm:ss'}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.lastModifiedDate|date:'yyyy-MM-d hh:mm:ss'}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            <p><a  routerLink="/order/order-management" class="account-second-button">Orders</a></p>
                                                                                            <p><a  routerLink="/crm/quotations" class="account-second-button">Quotes</a></p>
                                                                                            <p><a  routerLink="/crm/quotations/create-quotes" class="account-second-button">New quote</a></p>
                                                                                            <p><a  routerLink="/order/create-sales-order" class="account-second-button">New order</a></p>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage"
                                                                                    let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">No
                                                                                                    Record Found</h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <app-create-employee *ngIf="activeCategory==1"></app-create-employee>
                <app-create-prospect *ngIf="activeCategory==2"></app-create-prospect>
                <app-create-customer *ngIf="activeCategory==3"></app-create-customer>
                <app-create-new-person *ngIf="activeCategory==4"></app-create-new-person>
                <app-create-new-party-group *ngIf="activeCategory==5"></app-create-new-party-group>
                <!-- <router-outlet></router-outlet> -->
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>