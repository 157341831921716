import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProductService } from 'src/app/catalog-mgmt/new-products/product.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import * as moment from 'moment';
import { SortEvent } from 'primeng/api';
import { faTrash, faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';

import Swal from 'sweetalert2'
import { Location } from '@angular/common';
import { PayablePaymentService } from '../accounting-ap/payables-payment/payables-payment.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
declare var $: any;
@Component({
  selector: 'app-create-invoices',
  templateUrl: './create-invoices.component.html',
  styleUrls: ['./create-invoices.component.css']
})
export class CreateInvoicesComponent implements OnInit {
  products3: any;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  total=0;
  activeCategoryView = 0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  invoiceId: string;
  invoiceValue: any;
  appliedPayments: any[];
  transactions: any[];
  invoiceRoles: any[];
  downloading: boolean;
  faSpinner = faSpinner;
  faTrash = faTrash;
  faEdit = faEdit;
  invoiceTerms: any[];
  invoiceItems: any[];
  invoiceStatus: any[];
 
  statusId: string;
  invoiceStatusValue: string;
  isEditable: boolean;
  appliedAmount: {
      appliedAmount: number,
      invoiceAmount: number,
      notAppliedAmount: number
  };
  activeCategoryMain = 2;
  decisionArray = [
      {
          label: 'Yes',
          value: 1
      },
      {
          label: 'No',
          value: 0
      }
  ];

  invoiceItemForm: FormGroup;
  editMode: boolean;
  invoiceItemTypes: any[];
  productIds: any[];
  uomIds: any[];

 
  invoiceTypeIds: any[];
  invoiceTermForm: FormGroup;

  invoiceTermValue: any;

  invoiceTermId: string;

  invoiceItemSeqId: string;
  featureIds: any[];
  GIAccounts: any[];
  inventoryItems: any[];
  itemValue: any;

  array = [
    {
      label: 'Your Company Name',
      value: 'Company'
    }
  ];
 
  partyIds: any[];
  activeCategory = 2;
  invoiceType: any[];
  createInvoiceForm: FormGroup;
  invoiceUrl: string;
  isCredit: boolean;
  roleTypeId: string;
  supplierArray: any[]=[];
  updateSalesInvoiceForm: FormGroup;
  roleTypeIds: any[];

  currencies: any[];
 

  invoiceTypeData: string;
  billingAccounts: any[];
  searchInvoiceForm: FormGroup;
  invoices: any[];

  agreementTypeValue: string;
  invoiceTypeEdit: string;
  activeCategoryRole = 2;

  createRoleForm: FormGroup;
  showValue=false;
  customerName: any;
  uomIdsNewArray: any;
  
  constructor(
   
    private _location: Location,
 
    readonly _MainCatalogService: MainCatalogService,
  
    readonly _PayablePaymentService: PayablePaymentService,
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ProductService: ProductService,
    readonly _ActivatedRoute: ActivatedRoute,
  ) {
    this.partyIds = [];
    this.invoiceId = '';
    this.createRoleForm = this._FormBuilder.group({
        datetimePerformed: [new Date()],
        partyId: [''],
        percentage: [''],
        roleTypeId: ['']
    });
    this.roleTypeIds = [];
  
    this.createInvoiceForm = this._FormBuilder.group({
      invoiceTypeId: ['', [Validators.required]],
      partyId: ['Company'],
      partyIdFrom: ['', [Validators.required]]
    });
    this.partyIds = [];
    this.invoiceType = [];
    this.invoiceUrl = 'payable';
    this.isCredit = false;
    this.partyIds = [];
    this.currencies = [];
    this.roleTypeIds = [];
    this.updateSalesInvoiceForm = this._FormBuilder.group({
      billingAccountId: [''],
        currencyUomId: [''],
        description: [''],
        dueDate: [new Date()],
        invoiceDate: [new Date()],
        invoiceMessage: [''],
        invoiceTypeId: [''],
        partyId: [''],
        partyIdFrom: [''],
        recurrenceInfoId: [''],
        referenceNumber: [''],
        roleTypeId: [''],
        statusId: ['']
    });
    this.roleTypeIds = [];
    this.invoiceId = '';
    this.invoiceTypeData = '';
    this.billingAccounts = [];
    this.searchInvoiceForm = this._FormBuilder.group({
      description: [''],
      descriptionSearchType: ['Contains'],
      fromPartyId: [''],
      invoiceDateFrom: [''],
      invoiceDateFromSearchType: ['equal'],
      invoiceDateTo: [''],
      invoiceDateToSearchType: ['oplessthen'],
      invoiceId: [''],
      invoiceIdSearchType: ['Contains'],
      invoiceType: ['PURCHASE_INVOICE', [Validators.required]],
      statusId: [''],
      toPartyId: ['']
    });
    this.invoiceItemTypes = [];
    this.editMode = false;
    this.invoiceId = '';
    this.invoiceItemForm = this._FormBuilder.group({
        amount: [''],
        description: [''],
        inventoryItemId: [''],
        invoiceItemTypeId: [''],
        overrideGlAccountId: [''],
        productFeatureId: [''],
        productId: [''],
        quantity: [''],
        taxableFlag: [1],
        uomId: ['']
    });
    this.uomIds = [];
   
    this.invoiceItemSeqId = '';
    this.featureIds = [];
    this.GIAccounts = [];
    this.inventoryItems = [];
    this.invoiceId = '';
    this.invoiceRoles = [];
    this.invoiceTerms = [];
    this.invoiceItems = [];
    this.invoiceStatus = [];
   
    this.statusId = '';
    this.invoiceStatusValue = '';
    this.isEditable = true;
    this.transactions = [];
    this.appliedPayments = [];
    this.downloading = false;
    this.appliedAmount = {
        appliedAmount: 0,
        invoiceAmount: 0,
        notAppliedAmount: 0
    };
    this.invoiceTermForm = this._FormBuilder.group({
        description: [''],
        invoiceItemSeqId: [''],
        invoiceTermId: [''],
        termDays: [''],
        termTypeId: [''],
        termValue: [''],
        textValue: [''],
        uomId: ['']
    });
  
    this.uomIds = [];
  
    this.editMode = false;
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.invoiceUrl = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    this.isCredit = window.location.pathname.includes('isCredit') ? true : false;
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });
    this.getInvoiceTypeAndStatus();
 
    this.getCustomerList();
    if (window.location.pathname.includes('receiveable')) {
      this.createInvoiceForm.patchValue({
        invoiceTypeId: 'SALES_INVOICE'
      });
    } else {
      this.createInvoiceForm.patchValue({
        invoiceTypeId: 'PURCHASE_INVOICE'
      });
    }
    this.invoiceId = this._ActivatedRoute.snapshot.queryParams.invoiceId;
    this.invoiceTypeData = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
   if(this.invoiceId){
     this.tabfunction();
    this._AccountingApService.getInvoiceById(this.invoiceId)
        .then(data => {
            this.invoiceValue = data.data;
            this.updateSalesInvoiceForm.patchValue({
                ...data.data, ...{
                    dueDate: data.data.dueDate ? new Date(data.data.dueDate) : data.data.dueDate,
                    invoiceDate: data.data.invoiceDate ? new Date(data.data.invoiceDate) : data.data.invoiceDate
                }
            });
        });}
    this.getPartyIdDatas();
    this.getCurrency();
    this.getRoleType();
    this.getBillingAccount();
    this.agreementTypeValue = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    
    this.getInvoiceTypeAndStatus();
    this.getPartyIds();
    if (window.location.pathname.includes('receiveable')) {
      this.searchInvoiceForm.patchValue({
        invoiceType: 'SALES_INVOICE'
      });
    }
    if(this.invoiceTypeData=='payable'){
     this.searchInvoicePay();
    }
    else{
    this.searchInvoice();
    }
    this.getPartyId();
    this.getUomIdsNew();
  }
  editItemTerm(invoiceTermId){
 
      this.editMode = true;
      this._AccountingApService.getInvoiceTermById(invoiceTermId)
          .then(data => {
              this.invoiceTermValue = data.data;
              this.invoiceTermForm.patchValue({
                  ...data.data, ...{
                      invoiceItemSeqId: data.data.itemNo
                  }
              });
          });
      this.invoiceTermForm.controls.invoiceTermId.disable();
      this.invoiceTermForm.controls.invoiceTermId.updateValueAndValidity();
  
  }
  editItem(invoiceItemSeqId){
    this.invoiceItemSeqId=invoiceItemSeqId;
    this.editMode = true;
    this._AccountingApService.getInvoiceItemById(this.invoiceId,invoiceItemSeqId)
        .then(data => {
            this.itemValue = data.data;
            this.invoiceItemForm.patchValue(data.data);
        });
}
  getPartyId(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
        .then(data => {
            this.spinner.hide();
            this.partyIds = data.data.PersonsGroups.map(value => {
                return {
                    label: value.partyId,
                    value: value.partyId
                };
            });
        });
      
}

submitInvoiceRole(): void {
    this.spinner.show();
    this._AccountingApService.createInvoiceRole(this.invoiceId, {
        ...this.createRoleForm.value, ...{
            datetimePerformed: moment(this.createRoleForm.value.datetimePerformed).format('YYYY-MM-DD HH:mm:ss')
        }
    })
        .then(data => {
            if (data.success) {
                this._ToastrService.success('created');
                this.createRoleForm.reset();
                this.spinner.hide();
                this.closebutton2.nativeElement.click();
                this.getInvoiceRoles();
            }
        });
       
}
  getInvoiceTypeAndStatus(): void {
    this.spinner.show();
    if (window.location.pathname.includes('receiveable')) {
      this._AccountingApService.getInvoiceTypeAndStatus()
        .then(data => {
          this.spinner.hide();
          this.invoiceType = data.data.salesInvoiceType.map(value => {
            return {
              label: value.description,
              value: value.invoiceTypeId
            };
          });
        });
    } else {
      this._AccountingApService.getPurchasedInvoiceTypes()
        .then(data => {
          this.invoiceType = data.data.map(value => {
            return {
              label: value.description,
              value: value.invoiceTypeId
            };
          });
        });
    }
   
  }
  getPartyIds(): void {
    this.spinner.show();
    if (window.location.pathname.includes('receiveable')) {
      this._GlobalResourceService.getPartyGroups()
        .then(data => {
          this.spinner.hide();
          this.partyIds = data.data.PersonsGroups.map(value => {
            return {
              label: value.partyId,
              value: value.partyId
            };
          });
        });
    } else {
      this._GlobalResourceService.getSupplierList()
        .then(data => {
          this.partyIds = data.data.map(value => {
            return {
              label: value.partyId,
              value: value.partyId
            };
          });
        });
    }
   
  }
  getCustomerList() {
    this.spinner.show();
    this.roleTypeId = "CUSTOMER"
    this._ProductService.getCustomerList(this.roleTypeId).subscribe(res => {
      const supplier = res.data;
      this.spinner.hide();
      for (const value of supplier) {
        this.supplierArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }

    })
   
  }
  editInvoice(id){
    if(this.invoiceUrl=='receiveable'){
    this._Router.navigate(["/financial/receiveable/invoices/create-invoice"], { queryParams: { invoiceId:id } })
    }
    else{
      this._Router.navigate(["/financial/payable/invoices/create-invoice"], { queryParams: { invoiceId:id } })
    }
    this._AccountingApService.getInvoiceById(id)
    .then(data => {
        this.invoiceValue = data.data;
        this.updateSalesInvoiceForm.patchValue({
            ...data.data, ...{
                dueDate: data.data.dueDate ? new Date(data.data.dueDate) : data.data.dueDate,
                invoiceDate: data.data.invoiceDate ? new Date(data.data.invoiceDate) : data.data.invoiceDate
            }
        });
    });

  }
  submitInvoice(): void {

    this.spinner.show();
    this.customerName=this.createInvoiceForm.value.partyIdFrom
    this._AccountingApService.createInvoice({
      invoiceTypeId: this.createInvoiceForm.value.invoiceTypeId,
      partyId: window.location.pathname.includes('receiveable') ? this.createInvoiceForm.value.partyIdFrom : this.createInvoiceForm.value.partyId,
      partyIdFrom: window.location.pathname.includes('receiveable') ? this.createInvoiceForm.value.partyId : this.createInvoiceForm.value.partyIdFrom,
      statusId: 'INVOICE_IN_PROCESS',
      currencyUomId: 'USD'
    })
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Created Successfully');
          this.createInvoiceForm.reset();
          this.spinner.hide();
          this.invoiceId=data.data.invoiceId;
          this.tabfunction();
          this.searchInvoicePay();
        this.searchInvoice();
          if(this.invoiceUrl=='receiveable'){
            this._Router.navigate(["/financial/receiveable/invoices/create-invoice"], { queryParams: { invoiceId:this.invoiceId } })
            }
            else{
              this._Router.navigate(["/financial/payable/invoices/create-invoice"], { queryParams: { invoiceId:this.invoiceId } })
            }
         
         
          this._AccountingApService.getInvoiceById(this.invoiceId)
          .then(data => {
              this.invoiceValue = data.data;
              this.updateSalesInvoiceForm.patchValue({
                  ...data.data, ...{
                      dueDate: data.data.dueDate ? new Date(data.data.dueDate) : data.data.dueDate,
                      invoiceDate: data.data.invoiceDate ? new Date(data.data.invoiceDate) : data.data.invoiceDate
                  }
              });
          });
         // this._Router.navigate(["/financial/${this.invoiceUrl}/invoices/create-invoice"], { queryParams: { invoiceId: this.invoiceId } })

         // this.isCredit ? this._Router.navigate(['/financial/payable/invoices/credit-memo']) : this._Router.navigate([`/financial/${this.invoiceUrl}/invoices`]);
        }
      });
     
  }
  tabfunction(){
    this.showValue=true;
    this.invoiceTypeEdit = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    this.getInvoiceById();
    this.getInvoiceRoles();
    this.getInvoiceTerms();
    this.getInvoiceItems();
    this.getInvoiceStatus();
    this.getInvoiceItemType();
    this.getProducts();
    this.getFeatureIds();
    this.getGIAccounts();
    this.getUomIds();
    this.getInventoryItem();
    this.getInvoiceTypeIds();
  }
  editsaleInvoice() {
    this._Router.navigateByUrl('/edit-sale-invoice');
  }

  editpurInvoice() {
    this._Router.navigateByUrl('/edit-purchase');
  }
  reset(): void {
    this.createInvoiceForm.reset();
    this._Router.navigate([`/financial/${this.invoiceUrl}/invoices`]);
  }
  getPartyIdDatas(): void {
    this.spinner.show();
    this._GlobalResourceService.getPartyGroups()
        .then(data => {
            this.spinner.hide();
            this.partyIds = data.data.PersonsGroups.map(value => {
                return {
                    label: value.partyId,
                    value: value.partyId
                };
            });
        });
      
}
getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
        .then(data => {
            this.spinner.hide();
            this.currencies = data.data.currencyList.map(value => {
                return {
                    label: value.description,
                    value: value.uomId
                };
            });
        });
       
}
getRoleType(): void {
    this.spinner.show();
    this._GlobalResourceService.getRoleType()
        .then(data => {
            this.spinner.hide();
            this.roleTypeIds = data.data.roleType.map(value => {
                return {
                    label: value.description,
                    value: value.roleTypeId
                };
            });
        });
       
}
getBillingAccount(): void {
    this.spinner.show();
    this._AccountingApService.getBillingAccount({ pageNo: 1, pageSize: 200 }, {
        accountLimit: '', billingAccountId: '', billingAccountIdSearchType: 'Contains', description: '', descriptionSearchType: 'Contains', fromDate: '', thruDate: ''
    })
        .then(data => {
            this.spinner.hide();
            this.billingAccounts = data.data.map(value => {
                return {
                    label: value.billingAccountId,
                    value: value.billingAccountId
                };
            });
        });
       
}
submit(): void {
    this.spinner.show();
    this._AccountingApService.updateInvoice(this.invoiceId, {
        ...this.updateSalesInvoiceForm.value, ...{
            dueDate: moment(this.updateSalesInvoiceForm.value.dueDate).format('YYY-MM-DD HH:mm:ss'),
            invoiceDate: moment(this.updateSalesInvoiceForm.value.invoiceDate).format('YYYY-MM-DD HH:mm:ss')
        }
    })
        .then(data => {
            if (data.success) {
                this._ToastrService.success('Updated');
                this.spinner.hide();
                this.searchInvoicePay();
                this.searchInvoice();
                this.updateSalesInvoiceForm.reset();
               // this._Router.navigate([`/financial/${this.invoiceType}/invoices`]);
            }
        });
       
}

searchInvoicePay(): void {
  this.spinner.show();
  if (this.searchInvoiceForm.valid) {
    this._AccountingApService.searchInvoice({ pageNo: 1, pageSize: 100 }, {
      ... this.searchInvoiceForm.value, ...{
        invoiceDateFrom: this.searchInvoiceForm.value.invoiceDateFrom ? moment(this.searchInvoiceForm.value.invoiceDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
        invoiceDateTo: this.searchInvoiceForm.value.invoiceDateTo ? moment(this.searchInvoiceForm.value.invoiceDateTo).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        this.spinner.hide();
        this.invoices = data.data;
      })
      .catch(err => {
        this.spinner.hide();
        if (err.errors) {
          err.errors.forEach(value => {
            this._ToastrService.success(value.fieldName);
          });
        }
      });

  }
 
}

searchInvoice(): void {
  this.spinner.show();
  if (this.searchInvoiceForm.valid) {
    this._AccountingApService.searchInvoice({ pageNo: 2, pageSize: 100 }, {
      ... this.searchInvoiceForm.value, ...{
        invoiceDateFrom: this.searchInvoiceForm.value.invoiceDateFrom ? moment(this.searchInvoiceForm.value.invoiceDateFrom).format('YYYY-MM-DD HH:mm:ss') : '',
        invoiceDateTo: this.searchInvoiceForm.value.invoiceDateTo ? moment(this.searchInvoiceForm.value.invoiceDateTo).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        this.spinner.hide();
        this.invoices = data.data;
      })
      .catch(err => {
        this.spinner.hide();
        if (err.errors) {
          err.errors.forEach(value => {
            this._ToastrService.success(value.fieldName);
          });
        }
      });

  }
 
}
customSort(event: SortEvent): void {
  event.data.sort((data1, data2) => {
    const value1 = data1[event.field];
    const value2 = data2[event.field];
    let result = null;

    if (value1 == null && value2 != null) {
      result = -1;
    }
    else if (value1 != null && value2 == null) {
      result = 1;
    }
    else if (value1 == null && value2 == null) {
      result = 0;
    }
    else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    }
    else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }
    return (event.order * result);
  });
}
getInvoiceTypeIds(): void {
  this.spinner.show();
  this._AccountingApService.getTermType()
      .then(data => {
          this.spinner.hide();
          this.uomIds = data.data.uom.map(value => {
              return {
                  label: value.description,
                  value: value.uomId
              };
          });
          this.invoiceTypeIds = data.data.termTypes.map(value => {
              return {
                  label: value.description,
                  value: value.termTypeId
              };
          });
      });
    
}
updateTerm(): void {
  this.spinner.show();
  this._AccountingApService.updateInvoiceTerm(this.invoiceId, {
      ...this.invoiceTermValue, ...this.invoiceTermForm.value
  })
      .then(data => {
          if (data.success) {
              this._ToastrService.success('Updated');
              this.invoiceTermForm.reset();
              this.spinner.hide();
              this.invoiceTermForm.reset();
                  this.getInvoiceTerms();
                  this.getInvoiceItems();
                  this.getInvoiceStatus();
                  this.getInvoiceItemType();
                  this.closebutton1.nativeElement.click();
                  this.editMode=false;
          }
      });
     
}
submitTerm(): void {
  this.spinner.show();
  if (this.editMode) {
      this.updateTerm();
  } else {
      this._AccountingApService.createInvoiceTerm(this.invoiceId, this.invoiceTermForm.value)
          .then(data => {
              if (data.success) {
                  this._ToastrService.success('Created');
                  this.spinner.hide();
                  this.invoiceTermForm.reset();
                  this.getInvoiceTerms();
                  this.getInvoiceItems();
                  this.getInvoiceStatus();
                  this.getInvoiceItemType();
                  this.closebutton1.nativeElement.click();
              }
          });
         
  }
}
getInventoryItem(): void {
  this.spinner.show();
  this._AccountingApService.getInventoryItem()
      .then(data => {
          this.spinner.hide();
          this.inventoryItems = data.data.map(value => {
              return {
                  label: value.inventoryItemId,
                  value: value.inventoryItemId
              };
          });
      });
    
}
getInvoiceItemType(): void {
  this.spinner.show();
  this._AccountingApService.getInvoiceItemTypes()
      .then(data => {
          this.spinner.hide();
          this.invoiceItemTypes = data.data.invoiceItemType.map(value => {
              return {
                  label: value.description,
                  value: value.invoiceItemTypeId
              };
          });
      });
     
}
getFeatureIds(): void {
  this.spinner.show();
  this._GlobalResourceService.getFeatureIds()
      .then(data => {
          this.spinner.hide();
          this.featureIds = data.data.map(value => {
              return {
                  label: value.productFeatureId,
                  value: value.productFeatureId
              };
          });
      });
     
}
getGIAccounts(): void {
  this.spinner.show();
  this._PayablePaymentService.getGIAccountAndPaymentMethod()
      .then(data => {
          if (data) {
              this.spinner.hide();
              this.GIAccounts = data.data.glAccounts.map(value => {
                  return {
                      label: value.glAccountId,
                      value: value.glAccountId
                  };
              });
          }
      });
     
}
getUomIds(): void {
  this.spinner.show();
  this._AccountingApService.getTermType()
      .then(data => {
          this.spinner.hide();
          this.uomIds = data.data.uom.map(value => {
              return {
                  label: value.description,
                  value: value.uomId
              };
          });
      });
     
}
getUomIdsNew(): void {
  this.spinner.show();
  this._AccountingApService.getTermType()
      .then(data => {
          this.spinner.hide();
          this.uomIdsNewArray = data.data.uom.map(value => {
              return {
                  label: value.description,
                  value: value.uomId
              };
          });
      });
     
}
getProducts(): void {
  this.spinner.show();
  this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
      .then(data => {
          this.spinner.hide();
          this.productIds = data.data.map(value => {
              return {
                  label: value.productId,
                  value: value.productId
              };
          });
      });
     
}
updateItem(): void {
  this.spinner.show();
  this._AccountingApService.updateInvoiceItem(this.invoiceId, this.invoiceItemSeqId, {
      ...this.itemValue, ...this.invoiceItemForm.value
  })
      .then(data => {
          if (data.success) {
              this._ToastrService.success('Updated');
              this.spinner.hide();
              this.invoiceItemForm.reset();
              this.getInvoiceTerms();
              this.getInvoiceItems(); 
              this.editMode=false;
          }
      });
     
}

submitItem(): void {
  this.spinner.show();
  if (this.editMode) {
      this.updateItem();
  } else {
      this._AccountingApService.createInvoiceItem(this.invoiceId, this.invoiceItemForm.value)
          .then(data => {
              if (data.success) {
                  this._ToastrService.success('Created');
                  this.spinner.hide();
                  this.invoiceItemForm.reset();
                  this.closebutton.nativeElement.click();
                  this.getInvoiceTerms();
  this.getInvoiceItems();
              }
          });
  }
 
}
getInvoiceTerms(): void {
  this.spinner.show();
  this._AccountingApService.getInvoiceTerms(this.invoiceId, { pageNo: 1, pageSize: 200 })
      .then(data => {
          this.invoiceTerms = data.data;
      });
      this.spinner.hide();
}
cancelSubmit(){
  this._location.back();
}
getInvoiceStatus(): void {
  this.spinner.show();
  this._AccountingApService.getInvoiceTypeAndStatus()
      .then(data => {
          this.invoiceStatus = data.data.invoiceStatus.map(value => {
              return {
                  label: value.description,
                  value: value.statusId
              };
          });
      });
      this.spinner.hide();
}
getInvoiceById(): void {
  this.spinner.show();
  this._AccountingApService.getInvoiceById(this.invoiceId)
      .then(data => {
          this.invoiceValue = data.data;
          this.transactions = data.data.transactions;
          this.appliedPayments = data.data.appliedPayments.invoiceApplications ? data.data.appliedPayments.invoiceApplications : [];
          this.appliedAmount = {
              appliedAmount: data.data.appliedPayments.appliedAmount,
              invoiceAmount: data.data.appliedPayments.invoiceAmount,
              notAppliedAmount: data.data.appliedPayments.notAppliedAmount
          };
          data.data.status.filter(value => {
              if (value.statusId === data.data.statusId) {
                  this.invoiceStatusValue = value.status;
              }
          });
          this.isEditable = data.data.statusId === 'INVOICE_IN_PROCESS' ? true : false;
      });
      this.spinner.hide();
}
getInvoiceRoles(): void {
  this.spinner.show();
  this._AccountingApService.getInvoiceRole(this.invoiceId)
      .then(data => {
          this.invoiceRoles = data.data;
          this.spinner.hide();
      });
     
}
deleteInvoiceRole(partyId: string, roleTypeId: string): void {
  Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
          this._AccountingApService.deleteInvoiceRole(this.invoiceId, partyId, roleTypeId)
          .then(data => {
              if (data.success) {
                  
                  this.getInvoiceRoles();
              } });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })        }
deleteInvoiceTerm(invoiceTermId: string): void {
  Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
          this._AccountingApService.deleteInvoiceTerm(invoiceTermId)
          .then(data => {
              if (data.success) {
                 
                  this.getInvoiceTerms();
              }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}
    })    }
getInvoiceItems(): void {
  this.spinner.show();
  this._AccountingApService.getInvoiceItems(this.invoiceId)
      .then(data => {
          this.invoiceItems = data.data;
          this.spinner.hide();
      });
     
}
deleteInvoiceItem(invoiceItemSeqId: string): void {
  Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
          this._AccountingApService.deleteInvoiceItem(this.invoiceId, invoiceItemSeqId)
          .then(data => {
              if (data.success) {
                
                  this.getInvoiceItems();
              }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }  })  }


setStatus(event: any): void {
  this.spinner.show();
  this._AccountingApService.setInvoiceStatus(this.invoiceId, this.statusId)
      .then(data => {
          if (data.success) {
              this.spinner.hide();
              this._ToastrService.success('Status Updated');
              this.getInvoiceById();
          }
      });
   
}
editData(id){
  
    this._AccountingApService.getInvoiceById(id)
        .then(data => {
            this.invoiceValue = data.data;
            this.updateSalesInvoiceForm.patchValue({
                ...data.data, ...{
                    dueDate: data.data.dueDate ? new Date(data.data.dueDate) : data.data.dueDate,
                    invoiceDate: data.data.invoiceDate ? new Date(data.data.invoiceDate) : data.data.invoiceDate
                }
            });
        });
}
changeactiveCategoryFunction(tab: number) {
  this.activeCategory = tab;
}
changeactiveCategoryFunctionView(tab: number) {
  this.activeCategoryView = tab;
}

  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
