import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';



import { ToastrService } from 'ngx-toastr';
import {Router,ActivatedRoute} from "@angular/router";

import { EmpNotesFormService } from './emp-notes-service';
import { EmployeeService } from '../employees/employess.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-emp-lead-notes',
  templateUrl: './emp-lead-notes.component.html',
  styleUrls: ['./emp-lead-notes.component.css']
})
export class EmpLeadNotesComponent implements OnInit {
  noteName: any;
  activeCategory = 2;
  textArea: any;
  partyId: any;
  params: string;
  noteId:any;
  partyIdForCreate:any;
  contactName:any;
  contactPartyId:any;
  noteNameForUpdate:any; 
  noteForUpdate:any;
  
  showUpdateBtn=false;
  showCreateBtn=false;

  constructor( readonly employeeService:EmployeeService,
    readonly toastr: ToastrService,
    readonly router: Router,
    readonly spinner:NgxSpinnerService,
    readonly formBuilder: FormBuilder, readonly activatedRoute: ActivatedRoute
    ) { }
  fileUploadForm: FormGroup;

  ngOnInit(): void {
    let contactPartyIdLocal;
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate= params["contactIdd"];
      if(this.partyIdForCreate) 
      {
        contactPartyIdLocal=this.partyIdForCreate;
      }
      this.contactName= params["contactName"];
      if(this.partyIdForCreate)
      {
        this.showCreateBtn=true;   
      }
      })

    this.activatedRoute.queryParams.subscribe(params => {
      this.noteId = params["noteId"]; 
      this.partyId = params["contactId"];
      if(this.partyId) 
      {
        contactPartyIdLocal=this.partyId;
      }
      
      this.noteNameForUpdate=params["noteName"];
      this.noteForUpdate=params["note"];
      
      if(this.noteId && this.partyId){
        this.showUpdateBtn=true;
      }
    });
    this.contactPartyId=contactPartyIdLocal;

    this.fileUploadForm = this.formBuilder.group({
      noteName: [''],
      note: ['']
    });
  }
  navigate() {
    this.router.navigate(["/hr/employees/employee-details"],{queryParams:{employeeDetailId:this.partyId}});
  }

  navigateFromCreate()
  {
    this.router.navigate(["/hr/employees/employee-details"],{queryParams:{employeeDetailId: this.partyIdForCreate}});
  }
  
  onSubmit() {
    this.spinner.show();
    const form = { ...this.fileUploadForm.value }
    this.employeeService.createContactNote(form,this.partyIdForCreate).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(["/hr/employees/employee-details"],{queryParams:{employeeDetailId:this.partyIdForCreate}});
      }
    }, (err) => {
     
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }

  onUpdate()
  {
    this.spinner.show();
    const form = { ...this.fileUploadForm.value}
    this.employeeService.updateContactNote(form,this.noteId).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(["/hr/employees/employee-details"],{queryParams:{employeeDetailId:this.partyId}});
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();

  }
}