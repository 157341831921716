import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-account-main-page',
  templateUrl: './account-main-page.component.html',
  styleUrls: ['./account-main-page.component.css']
})
export class AccountMainPageComponent implements OnInit {
  activeCategory=2;
  activeCategoryValue=2;
  constructor(
    private _location: Location,
  ) { }

  ngOnInit(): void {
  }

  backWindow(){
      this._location.back();
    }

}
