import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';

import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';

@Component({
  selector: 'app-update-profile-password',
  templateUrl: './update-profile-password.component.html',
  styleUrls: ['./update-profile-password.component.css']
})
export class UpdateProfilePasswordComponent implements OnInit {
  EnabledArray=[{
    "label":"Y",
    "value":"Y"
  },
  {
    "label":"N",
    "value":"N"
  }]
  updatePassword:FormGroup;
  changePassword:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  identification: any;
  identificationArray: any[]=[];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  ClassificationGroupId: any;
  ClassificationGroupIdArray: any[]=[];
  fromDate: string;
  thruDate: string;
  partyClassId: any;
  efromDate: any;
  ethruDate: any;
  getthruDate: string;
  DateTime: string;
  loginid: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,
    private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    ) { 
      this.contentIds = [];
      this.updatePassword = this._FormBuilder.group({
        Enabled: [''],
        DisabledDateTime: [''],
        SuccessiveFailedLogins:[''],
        ExternalAuthID:[''],
        RequirePasswordChange:[''],
        DisabledBy:['']
       
        
      });
      this.changePassword = this._FormBuilder.group({
        CurrentPassword: [''],
        NewPassword: [''],
        VerifyNewPassword:[''],
        PasswordHint:[''],
        RequirePasswordChange:['']       
      });
    
  }

  ngOnInit(): void {
   
     this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
     if(this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
          this.Hidebtn=false;
        }
        else if(this.userPermissions.PARTYMGR_VIEW=="PARTYMGR_VIEW"){
          this.Hidebtn=true;
        }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.loginid=params["loginid"]
     
      
    });
  
 
  }


backWindow(){
  this._location.back();
}
  toDashboard()
{
this._Router.navigate(['/hub/Dashboard']);
}
  
  onSubmit() {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.spinner.show();
   
      const requestData = {
        "currentPassword":  this.changePassword.value.CurrentPassword,
        "newPassword": this.changePassword.value.NewPassword,
        "newPasswordVerify":  this.changePassword.value.VerifyNewPassword,
        "partyId":  this.party,
        "passwordHint": this.changePassword.value.PasswordHint,
        "requirePasswordChange": this.changePassword.value.RequirePasswordChange,
        "userLoginId":  this.loginid
  
      }
      this.accountsService.updatePassword(requestData).subscribe((res: any) => {
        
        if (res.success) {
          this._ToastrService.success("Updated Successfully");
          this.spinner.hide();
          this._location.back();
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
          for (const value of err.error.errors) {
            this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
          }
         
    
          }
          else{
            this._ToastrService.error(err.error.message);
          }
      });
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this._ToastrService.error("Permission Denied"); 
    }
  

  }

   onUpdate() {
    if(this.userPermissions.PHYCORP_ADMIN || this.userPermissions.PARTYMGR_ADMIN){
      this.spinner.show();
      const fromDate=this.updatePassword.get('DisabledDateTime').value;
      this.DateTime=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    
      const requestData = {
        "disabledBy": this.updatePassword.value.DisabledBy,
        "disabledDateTime": this.DateTime,
        "enabled": this.updatePassword.value.Enabled,
        "externalAuthId": this.updatePassword.value.ExternalAuthID,
        "partyId":  this.party,
        "requirePasswordChange": this.updatePassword.value.RequirePasswordChange,
        "successiveFailedLogins": this.updatePassword.value.SuccessiveFailedLogins,
        "userLoginId":  this.loginid
    
      }
      this.accountsService.updateUserLoginSecurity(requestData).subscribe((res: any) => {
        
        if (res.success) {
          this._ToastrService.success("Updated Successfully");
          this.spinner.hide();
          this._location.back();
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    }
    else if(this.userPermissions.PARTYMGR_VIEW){
      this._ToastrService.error("Permission Denied"); 
    }
  

  }  
   
  cancelSubmit(){
    this._location.back();
  }
 }

