import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountingApService } from '../../accounting-ap/accounting-ap.service';
import { faEdit, faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
    selector: 'app-summary-commission-invoice',
    templateUrl: './summary-commission-invoice.component.html',
    styleUrls: ['./summary-commission-invoice.component.css']
})
export class SummaryCommissionInvoiceComponent implements OnInit {
    @ViewChild('closebuttonA') closebuttonA;

    uomIds: any[];
    invoiceTypeIds: any[];
    invoiceTermForm:FormGroup;
    invoiceTermValue: any;
    editMode: boolean;
    invoiceTermId: string;
    activeCategoryMain = 2;
    
    
    
    faEdit = faEdit;
    faTrash = faTrash;
    invoiceId: string;
    invoiceValue: any;
    invoiceItems: any[];
    total=0;
    rowNumber=0;
    pageSize = 10;
    rows = 50;
    faSpinner = faSpinner;
    activeCategory = 1;
    downloading: boolean;
    appliedAmount: {
        appliedAmount: number,
        invoiceAmount: number,
        notAppliedAmount: number
    };
    invoiceTerms: any[];
    invoiceStatus: any[];
    statusId: string;
    invoiceStatusValue: string;
    isEditable: boolean;
    appliedPayments: any[];
    invoiceType: string;
    transactions: any[];
    constructor(
        private _location: Location,
        readonly _ToastrService: ToastrService,
        readonly _FormBuilder: FormBuilder,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _AccountingApService: AccountingApService,
        readonly spinner: NgxSpinnerService,
        readonly  _Router: Router,
    ) {
        this.statusId = '';
        this.invoiceStatus = [];
        this.invoiceItems = [];
        this.invoiceTerms = [];
        this.invoiceStatusValue = '';
        this.isEditable = true;
        this.transactions = [];
        this.appliedPayments = [];
        this.downloading = false;
        this.appliedAmount = {
            appliedAmount: 0,
            invoiceAmount: 0,
            notAppliedAmount: 0
        };

        this.invoiceTermForm = this._FormBuilder.group({
            description: [''],
            invoiceItemSeqId: [''],
            invoiceTermId: [''],
            termDays: [''],
            termTypeId: [''],
            termValue: [''],
            textValue: [''],
            uomId: ['']
        });
        this.invoiceTypeIds = [];
        this.uomIds = [];
        this.invoiceTermId = '';
        this.editMode = false;
        this.invoiceType = 'payable';
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.invoiceId = this._ActivatedRoute.snapshot.queryParams.invoiceId;
        this.invoiceType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this.getInvoiceById();
        this.getInvoiceItems();
        this.getInvoiceTerms();
        this.getInvoiceStatus();

        this.invoiceTermId = this._ActivatedRoute.snapshot.queryParams.invoiceTermId;
        if (this.invoiceTermId) {
            this.editMode = true;
            this._AccountingApService.getInvoiceTermById(this.invoiceTermId)
                .then(data => {
                    this.invoiceTermValue = data.data;
                    this.invoiceTermForm.patchValue({
                        ...data.data, ...{
                            invoiceItemSeqId: data.data.itemNo
                        }
                    });
                });
            this.invoiceTermForm.controls.invoiceTermId.disable();
            this.invoiceTermForm.controls.invoiceTermId.updateValueAndValidity();
        }
        this.getInvoiceTypeIds();
    }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/financial/main']);
  }
    getInvoiceTypeIds(): void {
        this.spinner.show();
        this._AccountingApService.getTermType()
            .then(data => {
                this.spinner.hide();
                this.uomIds = data.data.uom.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
                this.invoiceTypeIds = data.data.termTypes.map(value => {
                    return {
                        label: value.description,
                        value: value.termTypeId
                    };
                });
            });
           
    }
    update(): void {
        this.spinner.show();
        this._AccountingApService.updateInvoiceTerm(this.invoiceId, {
            ...this.invoiceTermValue, ...this.invoiceTermForm.value
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.invoiceTermForm.reset();
                    this._Router.navigate([`/financial/payable/commission-invoice/summary-commission-invoice`], { queryParams: { invoiceId: this.invoiceId } });
                }
            });
           
    }

    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            this._AccountingApService.createInvoiceTerm(this.invoiceId, this.invoiceTermForm.value)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.getInvoiceTerms();
                        this.invoiceTermForm.reset();
                        this.closebuttonA.nativeElement.click();
                        this.spinner.hide();
                        this._Router.navigate([`/financial/payable/commission-invoice/summary-commission-invoice`], { queryParams: { invoiceId: this.invoiceId } });
                    }
                });
        }
     
    }
    reset(): void {
        this.invoiceTermForm.reset();
        this._Router.navigate([`/financial/payable/commission-invoice/summary-commission-invoice`], { queryParams: { invoiceId: this.invoiceId } });
    }
    getInvoiceById(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceById(this.invoiceId)
            .then(data => {
                this.invoiceValue = data.data;
                this.transactions = data.data.transactions;
                this.spinner.hide();
                this.appliedPayments = data.data.appliedPayments.invoiceApplications ? data.data.appliedPayments.invoiceApplications : [];
                this.appliedAmount = {
                    appliedAmount: data.data.appliedPayments.appliedAmount,
                    invoiceAmount: data.data.appliedPayments.invoiceAmount,
                    notAppliedAmount: data.data.appliedPayments.notAppliedAmount
                };
                data.data.status.filter(value => {
                    if (value.statusId === data.data.statusId) {
                        this.invoiceStatusValue = value.status;
                    }
                });
                this.isEditable = data.data.statusId === 'INVOICE_IN_PROCESS' ? true : false;
            });
           
    }
    cancelSubmit(){
        this._location.back();
      }
    getInvoiceStatus(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceTypeAndStatus()
            .then(data => {
                this.invoiceStatus = data.data.invoiceStatus.map(value => {
                    return {
                        label: value.description,
                        value: value.statusId
                    };
                });
            });
            this.spinner.hide();
    }
    getInvoiceItems(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceItems(this.invoiceId)
            .then(data => {
                this.invoiceItems = data.data;
                this.spinner.hide();
            });
           
    }
    deleteInvoiceItem(invoiceItemSeqId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {    
        this._AccountingApService.deleteInvoiceItem(this.invoiceId, invoiceItemSeqId)
        .then(data => {
            if (data.success) { 
                this.getInvoiceItems();
            }
        });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          })   
    }
    getInvoiceTerms(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceTerms(this.invoiceId, { pageNo: 1, pageSize: 200 })
            .then(data => {
                this.invoiceTerms = data.data;
                this.spinner.hide();
            });
           
    }
    deleteInvoiceTerm(invoiceTermId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._AccountingApService.deleteInvoiceTerm(invoiceTermId)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Deleted');
                        this.getInvoiceTerms();
                    }
                });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              ) } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )   }
          }) 
    }
    downloadPDF(): void {
        this.spinner.show();
        this.downloading = true;
        this._AccountingApService.generateInvoicePDF(this.invoiceId)
            .subscribe(value => {
                const blob = new Blob([value], { type: 'application/application/pdf' });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }
                const data = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = data;
                this.spinner.hide();
                link.download = `${this.invoiceId}.pdf`;
                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                setTimeout(() => {
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
                this.downloading = false;
            });
           
    }
    setStatus(event: any): void {
        this.spinner.show();
        this._AccountingApService.setInvoiceStatus(this.invoiceId, this.statusId)
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Status Updated');
                    this.spinner.hide();
                    this.getInvoiceById();
                }
            });
          
    }
    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    
    viewPage(invoiceIdEdit,invoiceTermIdEdit){
        this._Router.navigate(['financial/payable/commission-invoice/create-invoice-term'],{ queryParams: { invoiceId: invoiceIdEdit,invoiceTermId:invoiceTermIdEdit} });      
    }
    viewPageLoc(invoiceIdEdit,invoiceTermIdEdit){
        this._Router.navigate(['financial/payable/commission-invoice/create-invoice-item'],{ queryParams: { invoiceId: invoiceIdEdit,invoiceItemSeqId:invoiceTermIdEdit} });      
    }
    changeactiveCategoryFunction(tab: number) {
        this.activeCategory = tab;
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
         
}