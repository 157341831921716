<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
     


      <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
          <span class="color-black pl-1 titlePanels">My Profile
          </span>
          <span class="">

              <button type="submit" (click)="homeButton();"
                  class=" btn btn-outline-secondary mr-2">Home</button>
      
              <button type="submit" (click)="cancelSubmit();"
                  class="btn btn-danger buttonclass ">Back</button>
          </span>
        </div>
        <div class="w3-card-4 classCard">
          <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass">
              <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                  Profile</a></li>
             

            </ul>
          </div>
          <div class="create-new-leade">
            <div class="container-fluid">
              <div class="row">
                <form class="w-100">
                  <div class="create-lead font-medium mb-0 text-white">
                    <div class="w-100">
                      <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        
                        <div *ngIf="activeCategory==3">
                          <div style="width: 49.4%; float:left">
                            <div class="w3-card-4 classCardView mt-2">
                              <div class="header-tabs">

                                <h4 class=" common-styling hCard" style="margin-left: 7px;">
                                  Personal Information
                                </h4>
                                <span style="margin-right: 2%;" class="edit-buttons"
                                  (click)="updatePerson()">Update</span>
                              </div>
                              <div class="panel-body">
                                <div class="all-users-infomation font-13 font-medium own-user-select pb-">
                                  <div class="color-black container">
                                    <div *ngIf="Person" class="row">
                                     
                                      <div class="article-container">
                                        <div class="article">
                                          <p class="col-lg-6 col-12">Personal Image</p>
                                          <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                        </div>
                                        <div class="article">
                                          <p class="col-lg-6 col-12">Party ID</p>
                                          <span class="color-grey pl-20 col-lg-10 col-12">{{Person.partyId}}
                                           </span>

                                        </div>

                                        <div class="article">
                                          <p class="col-lg-6 col-12">Name</p>
                                          <span class="color-grey pl-20 col-lg-12 col-12">{{Person.firstName}} {{Person.middleName}} {{Person.lastName}}</span>
                                        </div>
                                        <br>

                                        <div class="article">
                                          <p class="col-lg-6 col-12">External ID</p>
                                          <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                        </div>

                                        <div class="article">
                                          <p class="col-lg-6 col-12">Status ID</p>
                                          <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                        </div>

                                        <div class="article">
                                          <p class="col-lg-6 col-12">Comments</p>
                                          <span
                                            class="color-grey pl-20 col-lg-10 col-12">{{Person.comments
                                            ? Person.comments:'N/A'}}</span>
                                        </div>

                                      





                                      </div>


                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                          
                            
                            <div class="w3-card-4 classCssShadow mt-2" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Contact Information
                                  </h4>
                                  <div class="d-flex flex-row" style="margin-right: 2%;">
                                    <span class="edit-buttons" routerLink="/crm/accounts/create-contact"
                                      [queryParams]="{partyId:partyId}">Create Telecom</span>
                                    <span class="edit-buttons ml-2"
                                      routerLink="/crm/accounts/create-postal-address"
                                      [queryParams]="{partyId:partyId}">Create
                                      Address</span>
                                  </div>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="background: white;">
                                  <div style="padding: 14px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="form-group" style="width: 100%!important;">
                                      <div class="card own-account-table">
                                        <p-table [value]="contactInformation"  
                                         scrollHeight="100px"
                                        [paginator]="false" [rows]="rows"
                                        scrollWidth="100%"
                                      
                                        [totalRecords]="total"
                                        [scrollable]="true"
                                        (sortFunction)="customSort($event)"
                                        [customSort]="true">
          
                                          <ng-template pTemplate="header">
                                              <tr style="background: #0d3769;">
                                                  <th pSortableColumn="code" style="width: 200px;">
                                                      <div class="checkbox-align" style="color: white;">
                                                          <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                              <div class="custom-checkbox ui-chkbox ui-widget">
                                                                  <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                  <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                              </div>
                                                              <!---->
                                                          </p-checkbox>
                                                          Contact Type
                                                          <p-sortIcon field="code"></p-sortIcon>
                                                      </div>
                                                  </th>
                                                  <th pSortableColumn="name" style="width: 200px;">
                                                      <div style="color: white;">
                                                          Contact Information
                                                          <p-sortIcon field="name">
                                                          </p-sortIcon>
                                                      </div>
                                                  </th>
                                                  <th pSortableColumn="category" style="width: 200px;">
                                                      <div style="color: white;"> Soliciting OK?
                                                          <p-sortIcon field="category">
                                                          </p-sortIcon>
                                                      </div>
                                                  </th>
                                                  <th pSortableColumn="price" style="width: 150px;">
                                                      <div style="color: white;"> Action</div>
                                                      <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                          role="textbox" aria-disabled="false" aria-readonly="false">
                                                  </th>
                                              </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-product>
                                              <tr>
                                                  <td style="width: 200px;padding-left: 7% !important;"  >
                                                      <ng-container *ngIf="product.contactMech.contactMechTypeId==='POSTAL_ADDRESS'">
                                                          {{product.contactMechType.description}}
                                                      </ng-container>
                                                      <ng-container *ngIf="product.contactMech.contactMechTypeId==='EMAIL_ADDRESS'">
                                                          {{product.contactMechType.description}}
                                                      </ng-container>
                                                      <ng-container *ngIf="product.contactMech.contactMechTypeId==='TELECOM_NUMBER'">
                                                             {{product.contactMechType.description}}
                                                      </ng-container>
                                                  </td>
                                                  <td  style="width: 200px;">
                                                      <div class="checkbox-align">
                                                          <ng-container *ngIf="product.contactMech.contactMechTypeId==='POSTAL_ADDRESS'">
                                                              {{product.postalAddress.address1}} , {{product.postalAddress.address2}},
                                                              <br>{{product.postalAddress.stateProvinceGeoId}} , {{product.postalAddress.city}},
                                                              <br>{{product.postalAddress.postalCode}},{{product.postalAddress.countryGeoId}}
                                                          </ng-container>
                                                          <ng-container *ngIf="product.contactMech.contactMechTypeId==='EMAIL_ADDRESS'">
                                                              {{product.contactMech.infoString}}
                                                          </ng-container>
                                                          <ng-container *ngIf="product.contactMech.contactMechTypeId==='TELECOM_NUMBER'">
                                                              <!--   Area Code: {{product.telecomNumber.areaCode}}, Contact Number:
                {{product.telecomNumber.contactNumber}}, Country Code:
                {{product.telecomNumber.countryCode}} -->
                                                              Contact Number: {{product.telecomNumber.contactNumber}}
                                                          </ng-container>
                                                      </div>
                                                  </td>
                                                  <td  style="width: 200px;">
                                                      {{product.partyContactMech.allowSolicitation ?product.partyContactMech.allowSolicitation :'N' }}
                                                  </td>
                                                  <td  style="width: 150px;">
                                                      <button type="submit"  (click)="updateData(product.contactMech.contactMechId ,product.contactMech.contactMechTypeId)"
                                                       class="btn btn-secondary submit-btn">Update</button>


                                                  </td>

                                              </tr>
                                          </ng-template>

                                      </p-table>
                                       
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            

                            <div class="w3-card-4 classCssShadow mt-2" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    List Related Accounts 
                                  </h4>
                                  <span style="margin-right: 2%;" class="edit-buttons"
                                    (click)="createAddRelatedAccount();">Create</span>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 9px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="form-group" style="width: 100%!important;">
                                      <div class="card own-account-table">
                                          <p-table [value]="EditListRelatedAccountList"
                                                                                    scrollHeight="100px"  [paginator]="false" [rows]="rows"
                                                                                  scrollWidth="100%"
                                                                                  [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                  [totalRecords]="total"
                                                                                  [scrollable]="true"
                                                                                  (sortFunction)="customSort($event)"
                                                                                  [customSort]="true">
                                                                                  <ng-template pTemplate="header">
                                                                                      <tr
                                                                                          style="background: #0d3769;">
                                                                                          <th pSortableColumn="code">
                                                                                              <div class="checkbox-align"
                                                                                                  style="color: white;">
                                                                                                  <p-checkbox
                                                                                                      _ngcontent-egj-c329=""
                                                                                                      styleclass="custom-checkbox"
                                                                                                      binary="true"
                                                                                                      class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                      <div
                                                                                                          class="custom-checkbox ui-chkbox ui-widget">
                                                                                                          <div
                                                                                                              class="ui-helper-hidden-accessible">
                                                                                                              <input
                                                                                                                  type="checkbox"
                                                                                                                  name="undefined"
                                                                                                                  value="undefined">
                                                                                                          </div>
                                                                                                          <div role="checkbox"
                                                                                                              class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                              <span
                                                                                                                  class="ui-chkbox-icon ui-clickable"></span>
                                                                                                          </div>
                                                                                                      </div>
                                                                                                      <!---->
                                                                                                  </p-checkbox>
                                                                                                  To Party ID
                                                                                                  <p-sortIcon
                                                                                                      field="code">
                                                                                                  </p-sortIcon>
                                                                                              </div>
                                                                                              <input
                                                                                                  id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                  name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                  value=""
                                                                                                  autocomplete="off"
                                                                                                  aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                  role="textbox"
                                                                                                  aria-disabled="false"
                                                                                                  aria-readonly="false">
                                                                                          </th>

                                                                                          <th
                                                                                              pSortableColumn="quantity">
                                                                                              <div
                                                                                                  style="color: white;">
                                                                                                  Type
                                                                                                  <p-sortIcon
                                                                                                      field="quantity">
                                                                                                  </p-sortIcon>
                                                                                              </div>
                                                                                              <div>
                                                                                                  <input
                                                                                                      id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                      name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                      class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                      value=""
                                                                                                      autocomplete="off"
                                                                                                      aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                      role="textbox"
                                                                                                      aria-disabled="false"
                                                                                                      aria-readonly="false">
                                                                                              </div>
                                                                                          </th>

                                                                                          <th pSortableColumn="price">
                                                                                              <div
                                                                                                  style="color: white;">
                                                                                                  Comment
                                                                                                  <p-sortIcon
                                                                                                      field="price">
                                                                                                  </p-sortIcon>
                                                                                              </div>
                                                                                              <div>
                                                                                                  <input
                                                                                                      id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                      name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                      class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                      value=""
                                                                                                      autocomplete="off"
                                                                                                      aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                      role="textbox"
                                                                                                      aria-disabled="false"
                                                                                                      aria-readonly="false">
                                                                                              </div>
                                                                                          </th>

                                                                                      </tr>
                                                                                  </ng-template>
                                                                                  <ng-template pTemplate="body"
                                                                                      let-product>
                                                                                      <tr>
                                                                                          <td style="padding-left:8% !important" 
                                                                                          [ngStyle]="{'height':'32px'}"  >
                                                                                              <div
                                                                                                  class="checkbox-align">

                                                                                                  {{product.dataNode.partyIdTo}}
                                                                                              </div>
                                                                                          </td>
                                                                                          <td  [ngStyle]="{'height':'32px'}">{{product.dataNode.partyRelationshipTypeId}}
                                                                                          </td>
                                                                                          <td  [ngStyle]="{'height':'32px'}">{{product.dataNode.comments}}
                                                                                          </td>


                                                                                      </tr>
                                                                                  </ng-template>
                                                                              </p-table>

                                       
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                           
                            <div class="w3-card-4 classCssShadow mt-2" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Visits
                                  </h4>
                                  <span style="margin-right: 2%;" class="edit-buttons"
                                    (click)="listAll()">List All</span>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 6px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="form-group" style="width: 100%!important;">
                                      <div class="card own-account-table">
                                        <p-table [value]="Visitors"   scrollHeight="100px"  [paginator]="false" [rows]="rows"
                                          scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                          [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                          [customSort]="true">
                                          <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                              <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                  <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                    binary="true"
                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                        <input type="checkbox" name="undefined" value="undefined">
                                                      </div>
                                                      <div role="checkbox"
                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                    </div>
                                                    <!---->
                                                  </p-checkbox>
                                                  Visit ID <p-sortIcon field="code"></p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                <div style="color: white;"> User Login
                                                  <p-sortIcon field="price"></p-sortIcon>

                                                </div>
                                              </th>
                                              <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                                <div style="color: white;"> user Impersonated<p-sortIcon
                                                    field="quantity"></p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                <div style="color: white;"> New User <p-sortIcon field="name">
                                                  </p-sortIcon>
                                                </div>

                                              </th>

                                              <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                <div style="color: white;"> Web App


                                                </div>
                                              </th>
                                              <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                <div style="color: white;"> Client IP


                                                </div>
                                              </th>
                                              <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                <div style="color: white;"> From Date


                                                </div>
                                              </th>
                                              <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                <div style="color: white;"> Through Date


                                                </div>
                                              </th>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-product>
                                            <tr>
                                              <td [ngStyle]="{'width':'200px'}" style="color:#0d3769 !important;cursor:pointer;">
                                                <div class="checkbox-align pl-5"  (click)="detailSecurity(product.visitorData.visitId)">
                                                  {{product.visitorData.visitId}}
                                                </div>
                                              </td>
                                              <td [ngStyle]="{'width':'200px'}"> {{product.visitorData.userLoginId}}</td>
                                              <td [ngStyle]="{'width':'200px'}"> {{product.userImpersonated}}</td>
                                              <td [ngStyle]="{'width':'200px'}"> {{product.visitorData.userCreated}}
                                              </td>
                                              <td [ngStyle]="{'width':'200px'}"> {{product.visitorData.webappName}}</td>
                                              <td [ngStyle]="{'width':'200px'}"> {{product.clientIpAddress}}</td>
                                              <td [ngStyle]="{'width':'200px'}"> {{product.visitorData.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                              </td>
                                              <td [ngStyle]="{'width':'200px'}"> {{product.visitorData.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                              </td>



                                            </tr>
                                          </ng-template>
                                        </p-table>
                                       
                                      </div>
                                    </div>
                                  </div>



                                </div>
                              </div>
                            </div>
                           
                           
                            <div class="w3-card-4 classCssShadow mt-2" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Identification Numbers
                                  </h4>
                                  <div class="d-flex flex-row" style="margin-right: 2%;">
                                    <span class="edit-buttons"
                                      (click)="createIdentificationNumber();">Create</span>
                                  </div>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="background: white;">
                                  <div style="padding: 15px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select classUser">
                                    <div class="form-group" style="width: 100%!important;">
                                      <div class="card own-account-table">
                                        <p-table [value]="IdentificationList"   scrollHeight="100px"  [paginator]="false" [rows]="rows"
                                          scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                          [totalRecords]="total" [scrollable]="true">
                                          <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                              <th [ngStyle]="{'width':'200px'}" [ngStyle]="{'width':'200px'}"
                                                pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                  <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                    binary="true"
                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                        <input type="checkbox" name="undefined" value="undefined">
                                                      </div>
                                                      <div role="checkbox"
                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                    </div>
                                                    <!---->
                                                  </p-checkbox>
                                                  Identification Type <p-sortIcon field="code"></p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'200px'}" [ngStyle]="{'width':'200px'}"
                                                pSortableColumn="name">
                                                <div style="color: white;">ID Value<p-sortIcon field="name">
                                                  </p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'200px'}" [ngStyle]="{'width':'200px'}"
                                                pSortableColumn="name">
                                                <div style="color: white;">Action<p-sortIcon field="name">
                                                  </p-sortIcon>
                                                </div>
                                              </th>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-product>
                                            <tr>
                                              <td [ngStyle]="{'width':'200px'}">
                                                <div class="checkbox-align pl-5"  >
                                                  {{product.description}}
                                                </div>
                                              </td>
                                              <td [ngStyle]="{'width':'200px'}">
                                                <div class="checkbox-align pl-5"  >
                                                  {{product.identificationData.idValue}}
                                                </div>
                                              </td>

                                              <td [ngStyle]="{'width':'200px'}">
                                                <fa-icon [icon]="faEdit" class="text-primary"
                                                  (click)="updatePartyIdentification(product.identificationData.partyIdentificationTypeId,product.identificationData.idValue)">
                                                </fa-icon>
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                  (click)="deletePartyIdentification(product.identificationData.partyIdentificationTypeId)"
                                                  class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                  <defs>
                                                    <style>
                                                      .a {
                                                        fill: #f44336;
                                                      }

                                                      .b {
                                                        fill: #fafafa;
                                                        margin-left: 20px;
                                                      }
                                                    </style>
                                                  </defs>
                                                  <path class="a fill-color"
                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                  <path class="b"
                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                    transform="translate(-147.576 -147.576)" />
                                                </svg>

                                              </td>

                                            </tr>
                                          </ng-template>
                                        </p-table>
                                       
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                           
                          
                          <div class="w3-card-4 classCssShadow mt-2" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Loyalty Points
                                  </h4>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 15px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="form-group">
                                      <div class="card own-account-table"
                                        style="width: -3px!important;    margin-left: 5%!important;">
                                    <span *ngIf="this.PartyLoyaltyPoints" style="color: #383139 !important;">
                                      You have {{this.PartyLoyaltyPoints.totalSubRemainingAmount}} points from {{this.PartyLoyaltyPoints.totalOrders}} order(s) in the last {{this.PartyLoyaltyPoints.monthsToInclude}} Months.
                                    </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                            
                          
                          </div>
                          <div style="width: 49.8%; float:right">
                            <div class="w3-card-4 classCssShadow mt-2" style="border:1px solid;
                                 border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    User name(s)
                                  </h4>
                                  <div class="d-flex flex-row" style="margin-right: 2%;">
                                    <span class="edit-buttons" (click)="createUserLogin();">Create New User name</span>

                                  </div>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 7px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select classUser">
                                    <div class="form-group">
                                      <div class="card own-account-table">
                                        <p-table [value]="UserLoginData"   scrollHeight="100px"  [paginator]="false" [rows]="rows"
                                          scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                          [totalRecords]="total" [scrollable]="true">
                                          <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                              <th [ngStyle]="{'width':'200px'}" [ngStyle]="{'width':'100px'}"
                                                pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                  <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                    binary="true"
                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                        <input type="checkbox" name="undefined" value="undefined">
                                                      </div>
                                                      <div role="checkbox"
                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                    </div>
                                                    <!---->
                                                  </p-checkbox>
                                                  User Login <p-sortIcon field="code"></p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'200px'}" [ngStyle]="{'width':'139px'}"
                                                style="text-align: center!important;" pSortableColumn="name">
                                                <div style="color: white;">Status<p-sortIcon field="name">
                                                  </p-sortIcon>
                                                </div>

                                              </th>

                                              <th [ngStyle]="{'width':'200px'}" [ngStyle]="{'width':'115px'}"
                                                pSortableColumn="name">
                                                <div style="color: white;">Action<p-sortIcon field="name"></p-sortIcon>
                                                </div>
                                              </th>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-product>
                                            <tr>

                                              <td [ngStyle]="{'width':'139px','height':'32px'}">
                                                <div class="checkbox-align">

                                                  {{product.data.userLoginId}}
                                                </div>
                                              </td>
                                              <td [ngStyle]="{'width':'120px','height':'32px'}" style="text-align: center!important;">
                                                {{product.partyStatusId.statusId}}

                                              </td>
                                              <td [ngStyle]="{'width':'115px','height':'32px'}">
                                                <fa-icon [icon]="faEdit" class="text-primary"
                                                  (click)="updatePassword(product.data.userLoginId)">
                                                </fa-icon>
                                                <span style="cursor: pointer;"
                                                  (click)="securityList(product.data.userLoginId)">Security Group</span>
                                               

                                              </td>

                                            </tr>
                                          </ng-template>
                                        </p-table>
                                       
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          
                            <div class="w3-card-4 classCssShadow mt-2" style="border:1px solid;
                             border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Party Attributes
                                  </h4>
                                  <span style="margin-right: 2%;" class="edit-buttons"
                                  data-toggle="modal" data-target="#profilePartyAttribute" >Create</span>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 15px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">



                                    <div class="form-group" style="width: 100%!important;">
                                      <div class="card own-account-table">
                                        <p-table [value]="PartyAttribute"  [paginator]="false" [rows]="rows"
                                         scrollHeight="100px" scrollWidth="100%" 
                                          [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                          [customSort]="true">
                                          <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                              <th [ngStyle]="{'width':'200px'}" [ngStyle]="{'width':'100px'}"
                                                pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                  <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                    binary="true"
                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                        <input type="checkbox" name="undefined" value="undefined">
                                                      </div>
                                                      <div role="checkbox"
                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                    </div>
                                                    <!---->
                                                  </p-checkbox>
                                                  Name <p-sortIcon field="code"></p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'200px'}" [ngStyle]="{'width':'100px'}"
                                                pSortableColumn="price">
                                                <div style="color: white;"> Value
                                                  <p-sortIcon field="price"></p-sortIcon>

                                                </div>
                                              </th>
                                              <th [ngStyle]="{'width':'200px'}" [ngStyle]="{'width':'120px'}"
                                                pSortableColumn="quantity">
                                                <div style="color: white;"> Action<p-sortIcon field="quantity">
                                                  </p-sortIcon>
                                                </div>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-product>
                                            <tr>
                                              <td [ngStyle]="{'width':'100px'}">
                                                <div class="checkbox-align">
                                                  {{product.attrName}}
                                                </div>
                                              </td>
                                              <td [ngStyle]="{'width':'100px'}"> {{product.attrValue}}</td>

                                              <td [ngStyle]="{'width':'100px'}">
                                                <svg class="hover"  data-toggle="modal" data-target="#profilePartyAttribute" 
                                                (click)="updateAttribute(product)" 
                                                  xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                  viewBox="0 0 21.223 21.222">
                                                  <g transform="translate(0 -0.004)">
                                                    <g transform="translate(0 1.52)">
                                                      <path
                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                        transform="translate(0 -34.137)" />
                                                    </g>
                                                    <g transform="translate(4.548 0.004)">
                                                      <g transform="translate(0 0)">
                                                        <path
                                                          d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                          transform="translate(-102.409 -0.004)" />
                                                      </g>
                                                    </g>
                                                  </g>
                                                </svg>
                                           <!--      <svg xmlns="http://www.w3.org/2000/svg"
                                                  (click)="deletePartyAttribute(product)" class="ml-20" width="16"
                                                  height="16" viewBox="0 0 26 26">
                                                  <defs>
                                                    <style>
                                                      .a {
                                                        fill: #f44336;
                                                      }

                                                      .b {
                                                        fill: #fafafa;
                                                        margin-left: 20px;
                                                      }
                                                    </style>
                                                  </defs>
                                                  <path class="a fill-color"
                                                    d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                  <path class="b"
                                                    d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                    transform="translate(-147.576 -147.576)" />
                                                </svg> -->
                                              </td>

                                            </tr>
                                          </ng-template>
                                        </p-table>
                                       
                                      </div>
                                    </div>
                                  </div>



                                </div>
                              </div>
                            </div>
                           
                         
                            <div class="w3-card-4 classCssShadow mt-2" style="border:1px solid;
                                  border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Notes
                                  </h4>
                                  <span style="margin-right: 2%;"
                                  class="edit-buttons"
                               (click)="resetnote()"   data-toggle="modal"
                                  data-target="#exampleModalFive">Create New Notes</span>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 8px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">



                                    <div class="form-group" style="width: 100%!important;">
                                      <div class="card own-account-table">
                                        <p-table [value]="accountData" [paginator]="false" [rows]="rows"
                                        scrollHeight="100px"
                                          scrollWidth="100%" 
                                          [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                          [customSort]="true">
                                          <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                              <th [ngStyle]="{'width':'200px'}" pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                  <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                    binary="true"
                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                        <input type="checkbox" name="undefined" value="undefined">
                                                      </div>
                                                      <div role="checkbox"
                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                    </div>
                                                    <!---->
                                                  </p-checkbox>
                                                  Note Name <p-sortIcon field="code"></p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                <div style="color: white;"> Note
                                                  <p-sortIcon field="price"></p-sortIcon>

                                                </div>
                                              </th>
                                              <th [ngStyle]="{'width':'250px'}" pSortableColumn="quantity">
                                                <div style="color: white;"> By<p-sortIcon field="quantity"></p-sortIcon>
                                                </div>

                                              </th>
                                              <th [ngStyle]="{'width':'200px'}" pSortableColumn="name">
                                                <div style="color: white;"> Created At <p-sortIcon field="name">
                                                  </p-sortIcon>
                                                </div>

                                              </th>

                                              <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                                <div style="color: white;"> Action


                                                </div>
                                              </th>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-product>
                                            <tr>
                                              <td [ngStyle]="{'width':'200px'}">
                                                <div class="checkbox-align pl-5">
                                                  {{product.noteName}}
                                                </div>
                                              </td>
                                              <td [ngStyle]="{'width':'150px'}">{{product.noteInfo}}</td>
                                              <td [ngStyle]="{'width':'250px'}">{{product.firstName}}
                                                {{product.middleName}}
                                                {{product.lastName}}</td>
                                              <td [ngStyle]="{'width':'200px'}">{{product.createdStamp |
                                                date:'yyyy-MM-dd'}}
                                              </td>


                                              <td [ngStyle]="{'width':'200px'}">
                                               
                                                <td
                                                [ngStyle]="{'width':'200px'}">
                                                <button type="submit"   data-toggle="modal"
                                                data-target="#exampleModalFive" (click)="updateNote(product)" 
                                                class="btn btn-secondary submit-btn"> Update</button>

                                                <button type="submit"  (click)="onDeleteNotes(product.noteId)" class="btn btn-danger"> Delete</button>

                                             
                                            </td>

                                            </tr>
                                          </ng-template>
                                        </p-table>
                                       
                                      </div>
                                    </div>



                                  </div>
                                </div>
                              </div>


                            </div>
                          
                         
                            <div class="w3-card-4 classCssShadow mt-2" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Party Content
                                  </h4>
                                  <span style="margin-right: 2%;" class="edit-buttons"
                                    (click)="createAttachContent();">Create</span>
                                </div>
                              </header>
                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 15px 5px!important;" class="card own-account-table">
                                    <p-table [value]="contentList"   scrollHeight="100px"  [paginator]="false" [rows]="rows" scrollWidth="100%"
                                      [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true"
                                      (sortFunction)="customSort($event)" [customSort]="true">
                                      <ng-template pTemplate="header">
                                        <tr style="background: #0d3769;">
                                          <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                            <div class="checkbox-align" style="color: white;">
                                              <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox"
                                                binary="true"
                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                  <div class="ui-helper-hidden-accessible">
                                                    <input type="checkbox" name="undefined" value="undefined">
                                                  </div>
                                                  <div role="checkbox"
                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                  </div>
                                                </div>
                                                <!---->
                                              </p-checkbox>
                                              Content Id <p-sortIcon field="code"></p-sortIcon>
                                            </div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>
                                          <th [ngStyle]="{'width':'250px'}" pSortableColumn="name">
                                            <div style="color: white;"> Content Name <p-sortIcon field="name">
                                              </p-sortIcon>
                                            </div>
                                            <div>
                                              <input id="j_idt727:j_idt728:j_idt731:filter"
                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                value="" autocomplete="off"
                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                aria-disabled="false" aria-readonly="false">
                                            </div>
                                          </th>
                                          <th [ngStyle]="{'width':'200px'}" pSortableColumn="category">
                                            <div style="color: white;"> Content Type <p-sortIcon field="category">
                                              </p-sortIcon>
                                            </div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>

                                          <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                            <div style="color: white;"> Party Content Type
                                              <p-sortIcon field="quantity"></p-sortIcon>
                                            </div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>

                                          <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                            <div style="color: white;"> Status <p-sortIcon field="price">
                                              </p-sortIcon>
                                            </div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>
                                          <th [ngStyle]="{'width':'200px'}" pSortableColumn="price">
                                            <div style="color: white;"> From Date <p-sortIcon field="price">
                                              </p-sortIcon>
                                            </div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>
                                          <th [ngStyle]="{'width':'200px'}" pSortableColumn="quantity">
                                            <div style="color: white;"> Action <p-sortIcon field="quantity">
                                              </p-sortIcon>
                                            </div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </th>

                                        </tr>
                                      </ng-template>
                                      <ng-template pTemplate="body" let-product>
                                        <tr>
                                          <td [ngStyle]="{'width':'150px'}">
                                            <div class="checkbox-align">

                                              {{product.contentId}}
                                            </div>
                                          </td>
                                          <td [ngStyle]="{'width':'250px'}">{{product.contentName}}</td>
                                          <td [ngStyle]="{'width':'200px'}">{{product.contentType}}</td>

                                          <td [ngStyle]="{'width':'200px'}">{{product.partyContentTypeId}}</td>
                                          <td [ngStyle]="{'width':'200px'}">{{product.status}}</td>
                                          <td [ngStyle]="{'width':'200px'}">{{product.fromDate | date:'medium'}}</td>

                                          <td [ngStyle]="{'width':'200px'}">

                                            <svg (click)="export(product.contentId,product.contentName)" width="2em"
                                              height="2em" viewBox="0 0 16 16" class="bi bi-arrow-down-circle-fill"
                                              fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                              <path fill-rule="evenodd"
                                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                            </svg>
                                            <svg
                                              (click)="onDelete(product.contentId,product.fromDate,product.partyContentTypeId,product.partyId)"
                                              xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16"
                                              viewBox="0 0 26 26">
                                              <defs>
                                                <style>
                                                  .a {
                                                    fill: #f44336;
                                                  }

                                                  .b {
                                                    fill: #fafafa;
                                                    margin-left: 20px;
                                                  }
                                                </style>
                                              </defs>
                                              <path class="a fill-color"
                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                              <path class="b"
                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                transform="translate(-147.576 -147.576)" />
                                            </svg>

                                          </td>

                                        </tr>
                                      </ng-template>
                                    </p-table>

                                  </div>


                                </div>
                              </div>


                            </div>
                          
                            <div class="w3-card-4 classCssShadow mt-2" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    Stores
                                  </h4>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 2px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="form-group">
                                      <div class="card own-account-table">
                                        <p-table [value]="ProductStore"   scrollHeight="100px"  [paginator]="false" [rows]="rows" scrollWidth="100%"
      [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                            <ng-template pTemplate="header">
                                                <tr style="background: #0d3769;">
                                                    <th [ngStyle]="{'width':'250px'}"
                                                        pSortableColumn="code">
                                                        <div class="checkbox-align"
                                                            style="color: white;">
                                                            <p-checkbox
                                                                _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox"
                                                                binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div
                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div
                                                                        class="ui-helper-hidden-accessible">
                                                                        <input
                                                                            type="checkbox"
                                                                            name="undefined"
                                                                            value="undefined">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            
                                                            Store Name [ID]<p-sortIcon
                                                                field="code">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input
                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                            value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox"
                                                            aria-disabled="false"
                                                            aria-readonly="false">
                                                    </th>
                                                    <th [ngStyle]="{'width':'150px'}"
                                                        pSortableColumn="name">
                                                        <div style="color: white;">
                                                          Role Type
                                                            <p-sortIcon
                                                                field="name">
                                                            </p-sortIcon>
                                                        </div>
                                                        <input
                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                            value=""
                                                            autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox"
                                                            aria-disabled="false"
                                                            aria-readonly="false">
                                                    </th>
                                                  
                                                  
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body"
                                                let-product>
                                                <tr>
                                                    <td
                                                        [ngStyle]="{'width':'250px'}">
                                                        <div class="checkbox-align">
                                                            <p-checkbox
                                                                _ngcontent-egj-c329=""
                                                                styleclass="custom-checkbox"
                                                                binary="true"
                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                <div
                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                    <div
                                                                        class="ui-helper-hidden-accessible">
                                                                        <input
                                                                            type="checkbox"
                                                                            name="undefined"
                                                                            value="undefined">
                                                                    </div>
                                                                    <div role="checkbox"
                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                        <span
                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                    </div>
                                                                </div>
                                                                <!---->
                                                            </p-checkbox>
                                                            <span
                                                                class="account-button">{{product.productStore.storeName}} {{product.productStore.productStoreId}}</span>
                                                        </div>
                                                    </td>
                                                    <td
                                                        [ngStyle]="{'width':'150px'}">
                                                        {{product.roleType.roleTypeId}}
                                                    </td>
                                                  
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="emptymessage"
                                                let-columns>
                                                <tr>
                                                    <td>
                                                        <span class="text-center">
                                                            <h4 class="ml-2">No
                                                                Record Found
                                                            </h4>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>

                                    </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                           
                           
                            <div class="w3-card-4 classCssShadow mt-2" style="border:1px solid;
                            border-color: #0d3769 !important;">
                              <header class="w3-container w3-blue">
                                <div class="header-tabs">
                                  <h4 class=" common-styling" style="margin-left: 7px;">
                                    AVS Override (CC Fraud Screening)
                                  </h4>
                                </div>
                              </header>

                              <div class="w3-container">
                                <div class="panel-body" style="    background: white;">
                                  <div style="padding: 15px 5px!important;"
                                    class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="form-group">
                                      <div class="card own-account-table" *ngIf="PartyIcsAvsOverRide.length"
                                        style="width: -3px!important;    margin-left: 5%!important;">
                                        <div  *ngFor = "let title of PartyIcsAvsOverRide" style="width: 153%;"
                                        class="all-users-infomation font-13 font-medium own-user-select">
                                        
                                          <div class="col-lg-4 col-12">
                                              <div class="form-group">
                                                  <label for="exampleInputEmail1">
                                                    AVS String</label>
                                                      
                                                   
                                              </div>
                                            
                                           
                                          </div>
                                          <div class="col-lg-4 col-12" >
                                            <div class="form-group" >
                                              <label for="exampleInputEmail1">
                                                  {{title.avsDeclineStringList.avsDeclineString}}</label>
                                                  
                                                
                                          </div>
                                          
                                        </div>
                                      
                                        
                                          <div class="col-lg-4 col-12" >
                                              <div class="form-group" >
                                                <button type="submit"  (click)="updateAVS();"  class="btn btn-secondary submit-btn">Edit</button>
                                 
                                                <button type="submit"  (click)="resetAvsOverride();"  class="btn btn-secondary submit-btn">Reset</button>
                                                
                                              </div>
                                            
                                          </div>
                                       
                                        
                                      </div>
                                      </div>
                                      <div class="card own-account-table" *ngIf="!PartyIcsAvsOverRide.length"
                                        style="width: -3px!important;    margin-left: 5%!important;">
                                        <div   style="width: 153%;"
                                        class="all-users-infomation font-13 font-medium own-user-select">
                                        
                                          <div class="col-lg-4 col-12">
                                              <div class="form-group">
                                                  <label for="exampleInputEmail1">
                                                    AVS String</label>
                                                      
                                                   
                                              </div>
                                            
                                           
                                          </div>
                                          <div class="col-lg-4 col-12" >
                                            <div class="form-group" >
                                              <label for="exampleInputEmail1">
                                                 Global</label>
                                                  
                                                
                                          </div>
                                          
                                        </div>
                                      
                                        
                                          <div class="col-lg-4 col-12" >
                                              <div class="form-group" >
                                                <button type="submit"  (click)="updateAVS();"  class="btn btn-secondary submit-btn">Edit</button>
                                 
                                               
                                              </div>
                                            
                                          </div>
                                       
                                        
                                      </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>


                            </div>
                           
                            <div class="w3-card-4 classCssShadow mt-2" style="border:1px solid;
                            border-color: #0d3769 !important; width: 200% !important;
      margin-left: -100% !important;">
                            <header class="w3-container w3-blue">
                              <div class="header-tabs">
                                <h4 class=" common-styling" style="margin-left: 7px;">
                                  Payment Method
                                </h4>
                                <div class="d-flex flex-row">
                                  <span class="edit-buttons ml-2"
                                    routerLink="/crm/accounts/create-credit-card"
                                    [queryParams]="{partyId:taxAuthPartyId}">Create Credit Card</span>
                                  <span class="edit-buttons ml-2"
                                    routerLink="/crm/accounts/create-gift-card"
                                    [queryParams]="{partyId:taxAuthPartyId}">Create Gift Card</span>
                                  <span class="edit-buttons ml-2"
                                    routerLink="/crm/accounts/create-eft-account"
                                    [queryParams]="{partyId:taxAuthPartyId,isEftAccount:true}">Create EFT Account</span>
                                  <span class="edit-buttons ml-2"
                                    routerLink="/crm/accounts/create-check-account"
                                    [queryParams]="{partyId:taxAuthPartyId}">Add
                                    Check Account</span>
                                  <span class="edit-buttons ml-2"
                                    routerLink="/crm/accounts/create-billing-account"
                                    [queryParams]="{partyId:taxAuthPartyId}">Create
                                    Billing Account</span>
                                </div>
                              </div>
                            </header>

                            <div class="w3-container">
                              <div class="panel-body" style="    background: white;">
                                <div class="card own-account-table"
                                  style="max-width: 100%!important; margin-left: 0%;">
                                  <p-table [value]="paymentMethods"   scrollHeight="150px"  [paginator]="false" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                    [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="body" let-product>
                                      <tr>
                                        <td>
                                            <div class="checkbox-align pl-2">
                                                {{product.type}}
                                             </div>
                                        </td>
                                        <td>
                                          <ng-container
                                            *ngIf="product.paymentMethodTypeId.includes('ACCOUNT') || product.paymentMethodTypeId.includes('CHECK')">
                                            <span>{{product.nameOnAccount}},{{product.bankName}}({{product.branchCode}}),A/C
                                              {{product.accountNumber}}, (Type:
                                              {{product.accountType ? product.accountType :''}}), (Updated At:
                                              {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                                          </ng-container>
                                          <ng-container *ngIf="product.paymentMethodTypeId==='BILLING'">
                                            <span>({{product.billingAccountId}}),(Account
                                              Limit:${{product.accountLimit}}),(Available Balance:
                                              ${{product.accountBalance}}), (Updated At:
                                              {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                                          </ng-container>
                                          <ng-container *ngIf="product.paymentMethodTypeId.includes('CARD')">
                                            <span>{{product.cardNumber ? product.cardNumber
                                              :''}}</span>, Expire :
                                            <span>{{product.expireDate? product.expireDate:''}}</span>,
                                            <span> {{product.companyNameOnCard? product.companyNameOnCard:''}}</span>,
                                            <span> ,( {{product.firstNameOnCard ? product.firstNameOnCard:''}}
                                              {{product.middleNameOnCard ? product.middleNameOnCard:''}}
                                              {{product.lastNameOnCard ? product.lastNameOnCard : ''}}),(
                                              {{product.cardType}}), (Updated At:
                                              {{product.lastUpdatedStamp |date :'yyyy-MM-dd HH:mm:ss'}})</span>
                                          </ng-container>
                                        </td>
                                        <td>
                                          <fa-icon  class="account-button" 
                                            (click)="paymentMethodNavigation(product.paymentMethodTypeId,product.paymentMethodTypeId==='BILLING' ? product.billingAccountId : product.paymentMethodId)"
                                            [icon]="faEdit"></fa-icon>
                                          <fa-icon class="text-danger cursor-pointer"
                                            (click)="removePaymentMethod(product.paymentMethodTypeId,product.paymentMethodTypeId==='BILLING' ? product.billingAccountId : product.paymentMethodId)"
                                            [icon]="faTrash"></fa-icon>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>
                                
                                </div>
                              </div>
                            </div>


                          </div>
                          <br>
                          </div>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="exampleModalFive" tabindex="-1" role="dialog" aria-labelledby="exampleModalFiveTitle"
aria-hidden="true">
<div class="modal-dialog" role="document">
    <div class="modal-content cssModelLength">
        <div class="modal-header">
            <span *ngIf="!showUpdateNoteBtn" class="color-black pl-1"> Create Note</span>
            <span *ngIf="showUpdateNoteBtn" class="color-black pl-1"> Update Note</span>

            <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton7>&times;</span>
            </button>

        </div>
        <div class="modal-body d-flex">
            <div class=" bg-white color-grey" style="width: 100%;">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategoryB==1?'active':''"
                                (click)="changeactiveCategoryFunctionValue(1)"><a>
                                    New Party Note</a></li>
                        </ul>
                    </div>
                    <div class="panel-group create-new-leade ">

                        <div *ngIf="activeCategoryB==1">

                            <div class="panel-body">
                                <form [formGroup]="fileUploadFormNote">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">

                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm"
                                                    >
                                                    <label for="exampleInputEmail1">Note Name</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"
                                                        formControlName="noteName" class="form-control"
                                                        id="exampleInputEmail1" aria-describedby="emailHelp"
                                                        placeholder="Enter Note Name">
                                                </div>

                                                <div class="col-lg-3 form-group rightForm"
                                                   >
                                                    <label for="exampleInputEmail1">Note</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email"
                                                    formControlName="note" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Note">
                                                   
                                                </div>


                                            </div>
                                        </div>


                                    </div>

                                    <div class="proper" style="margin-right: 18%;">
                                        <div class="col-lg-12 col-12 main-submit-button">
                                            <button type="submit" (click)="onSubmitNote()" *ngIf="!showUpdateNoteBtn"
                                                class="btn btn-secondary submit-btn">Create</button>
                                          
                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button" *ngIf="showUpdateNoteBtn">
                                            <button type="submit" (click)="updateContactNote()"
                                                class="btn btn-secondary submit-btn">Update</button>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>


<div class="modal fade" id="profilePartyAttribute" tabindex="-1" role="dialog" aria-labelledby="profilePartyAttributeTitle" aria-hidden="true">
<div class="modal-dialog" role="document">
    <div class="modal-content cssModelLength">
        <div class="modal-header">

            <span class="color-black pl-1">  Party Attribute</span>

            <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton2>&times;</span>
            </button>
        </div>
        <div class="modal-body d-flex">
            <div class=" bg-white color-grey w-100">
                <div class="w3-card-4 classCardPopUp">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                     Party Attribute</a></li>
                        </ul>
                    </div>
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="partyAttributes">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">
                                                        Attribute name<span style="color:red">*</span></label>
                                                </div>

                                                <div class="col-lg-3">
                                                    <input type="text" formControlName="attrName" *ngIf="!showAttribute" 
                                                    class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr name">

                                                    <input type="text" *ngIf="showAttribute" disabled
                                                     formControlName="attrName" class="form-control" 
                                                     id="exampleInputEmail1" readonly aria-describedby="emailHelp"
                                                      placeholder="Enter Attr name">
                                                </div>

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">
                                                        Attribute Value<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" formControlName="attrValue" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Value">
                                                </div>

                                            </div>
                                        </div>

                                        
                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">
                                                        Attribute Description<span
                                                            style="color:red">*</span></label>
                                                </div>

                                                <div class="col-lg-3">
                                                    <input type="text" formControlName="attrDescription" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Attr Description">
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 27%;">
                                        <button type="submit"  *ngIf="!showAttribute"(click)="onSubmitPartyAttribute();" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="showAttribute" (click)="onUpdatePartyAttribute();" class="btn btn-secondary submit-btn">Update</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<ngx-spinner></ngx-spinner>