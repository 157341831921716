import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-global-gl-cost',
  templateUrl: './global-gl-cost.component.html',
  styleUrls: ['./global-gl-cost.component.css']
})
export class GlobalGlCostComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  createCost: FormGroup;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  glCost: any;
  activeCategory=3;
  currencyArray: any[]=[];
  glAccountType: any;
  costCustomMethodID: any;
  uom: any;
  glAccountTypeArray: any[]=[];
  costCustomMethodIDArray: any[]=[];
  uomArray: any[]=[];
  show:boolean;
  constructor(  
    readonly _ActivatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _FormBuilder: FormBuilder,
   ) { 
    this.createCost = this._FormBuilder.group({
      costComponentCalcId: [''],
      costCustomMethodId: [''],
      costGlAccountTypeId: ['',[Validators.required]],
      currencyUomId: ['',[Validators.required]],
      description: [''],
      fixedCost: [''],
      offsettingGlAccountTypeId: [''],
      perMilliSecond: [''],
      variableCost: ['']
    });
   }
    getCost=[{
      value:'GEN_COST_CALC'
    }]
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activeCategory=3;
    this.getGlCost();

    this.getInitialDataCost();
    this.getCurrency();
  }

  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  getInitialDataCost() {
    this.spinner.show();
    this.accountsService.getInitialDataCost().subscribe(res => {
      this.glAccountType = res.data.glAccountType;
      this.costCustomMethodID = res.data.costCustomMethodID;
      this.uom = res.data.uom;
      this.spinner.hide();
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }
      for (const value of this.costCustomMethodID) {
        this.costCustomMethodIDArray.push({
          label: value.description,
          value: value.customMethodId
        })
      }
      for (const value of this.uom) {
        this.uomArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "costComponentCalcId":"",
      "costCustomMethodId": this.createCost.value.costCustomMethodId,
      "costGlAccountTypeId": this.createCost.value.costGlAccountTypeId,
      "currencyUomId":this.createCost.value.currencyUomId,
      "description": this.createCost.value.description,
      "fixedCost": this.createCost.value.fixedCost,
      "offsettingGlAccountTypeId": this.createCost.value.offsettingGlAccountTypeId,
      "perMilliSecond": this.createCost.value.perMilliSecond,
      "variableCost": this.createCost.value.variableCost,
    }
    this.accountsService.postGlCost(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.createCost.reset();
        this.getGlCost();
        this.closebutton.nativeElement.click();
        // this.router.navigate(["financial/global-gl-cost"]);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  summarypage(id){
    this.router.navigate(["financial/gl-cost-summary"],{ queryParams: { costComponentCalcId : id}})

  }
  chartAccount(){
    this.router.navigate(["/financial/global-gl-setting-list"])
  }
  timePeriod(){
    this.router.navigate(["/financial/gl-custom-time-period-list"])
  }
  cost(){
    this.router.navigate(["/financial/global-gl-cost"])
  }
  paymentMethod(){
    this.router.navigate(["/financial/gl-payment-method-type"])
  }
  invoiceMethod(){
    this.router.navigate(["/financial/gl-invoice-type-list"])
  }
  rate(){
    this.router.navigate(["/financial/global-gl-rate-list"])
  }
  foreignExc(){
    this.router.navigate(["/financial/gl-foreign-exchange-rate"])
  }
  accountCat(){
    this.router.navigate(["/financial/gl-account-category-list"])
  }
  costCenter(){
    this.router.navigate(["/financial/gl-cost-center"])
  }
  getGlCost() {
    this.spinner.show();
    this.accountsService.getGlCost(this.finSize).subscribe((res: any) => {
      this.glCost = res.data;
      this.spinner.hide();
    })
  
  }
  deleteGlCost(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteGlCost(id).subscribe((res:any)=> {
          this.toastr.success("Delete Successfully");
          this.getGlCost();
      },(err) => {
        this.toastr.error(err.error.message);
     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})  
}
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  // createCost(){
  //   this.router.navigate(["financial/create-global-cost"])
   
  // }
  updateCost(id){
    this.router.navigate(["financial/create-global-cost"],{ queryParams: { costComponentCalcId : id}})
   

  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
