<div class="container-fluid main-container-wrapper">
    <div class="row">
      <div class="col-12">
        <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
         <span class="color-black pl-1 titlePanels"> View Gateway Response Summary</span>
          <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
      </div>
       
        <div class=" bg-white color-grey">
           
            <br>
        
          <div class="create-new-leade mt-2">
            <div class="container-fluid">
              <div class="row">
                <form class="w-100">

                  <div class="port-header-contact create-lead font-medium mb-0 text-white">
                    <div class="w-100">
                      <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        <div class="w3-card-4 classCardView">
                          <div class="header-tabs">
                            
                          
                              <h4 class=" common-styling hCard">
                                View Gateway Response
                              </h4>
                           
                             
    
                            </div>
                            <div class="panel-body" *ngIf="this.gateway" >
                              <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="color-black container">
                                  <div class="article-container">
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Order ID</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12">{{ this.gateway.orderId}}</span>
                                    </div>
                                    <div class="article">
                                      <p class="col-lg-6 col-12">Order Payment Preference ID</p>
                                      <span class="color-grey pl-20 col-lg-10 col-12">{{ this.gateway.orderPaymentPreferenceId}}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                      </div>
                      <br>
                        <div class="w3-card-4 classCardView">
                          <div class="header-tabs">
                              <h4 class=" common-styling hCard">
                                Payments
                                </h4>
                            </div>
                            <div class="w3-container">
                              <div class="panel-body" >
                                <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails" style="    padding: 15px 15px!important">
                                    <div class="card own-account-table">
                                        <p-table [value]="payments" [paginator]="true" [rows]="rows"
                                          scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total"
                                          [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                          <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                              <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                <div class="checkbox-align" style="color: white;">
                                                  <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                          name="undefined" value="undefined"></div>
                                                      <div role="checkbox"
                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                          class="ui-chkbox-icon ui-clickable"></span></div>
                                                    </div>
                                                    <!---->
                                                  </p-checkbox>
                                                  Payment Id<p-sortIcon field="code"></p-sortIcon>
                                                </div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                  name="j_idt727:j_idt728:j_idt731:filter"
                                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                  value="" autocomplete="off"
                                                  aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                  aria-disabled="false" aria-readonly="false">
                                              </th>
                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"><div style="color: white;"> Payment Type
                                                <p-sortIcon field="name"></p-sortIcon></div>
                                                <div>
                                                  <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                    value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                    aria-disabled="false" aria-readonly="false">
                                                </div>
                                              </th>
                                           
                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"><div style="color: white;"> Status<p-sortIcon
                                                  field="name"></p-sortIcon></div>
                                                <div>
                                                  <input id="j_idt727:j_idt728:j_idt731:filter"
                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                    value="" autocomplete="off"
                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                    aria-disabled="false" aria-readonly="false">
                                                </div>
                                              </th>
                                              <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"><div style="color: white;"> Comments<p-sortIcon
                                                field="name"></p-sortIcon></div>
                                              <div>
                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                  name="j_idt727:j_idt728:j_idt731:filter"
                                                  class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                  value="" autocomplete="off"
                                                  aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                  aria-disabled="false" aria-readonly="false">
                                              </div>
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"><div style="color: white;"> To Party<p-sortIcon
                                              field="name"></p-sortIcon></div>
                                            <div>
                                              <input id="j_idt727:j_idt728:j_idt731:filter"
                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                value="" autocomplete="off"
                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                                aria-disabled="false" aria-readonly="false">
                                            </div>
                                          </th>
                                          <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"><div style="color: white;"> Effective Date<p-sortIcon
                                            field="name"></p-sortIcon></div>
                                          <div>
                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                              name="j_idt727:j_idt728:j_idt731:filter"
                                              class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                              value="" autocomplete="off"
                                              aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                              aria-disabled="false" aria-readonly="false">
                                          </div>
                                        </th>
                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name"><div style="color: white;"> Amount<p-sortIcon
                                          field="name"></p-sortIcon></div>
                                        <div>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </div>
                                      </th>
                                   
                                     
                                       
                                         
                                           
                                             
                                             
        
        
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-product>
                                            <tr>
                                              <td [ngStyle]="{'width':'170px'}">
                                                <div class="checkbox-align">
                                                  <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true"
                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible"><input type="checkbox"
                                                          name="undefined" value="undefined"></div>
                                                      <div role="checkbox"
                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span
                                                          class="ui-chkbox-icon ui-clickable"></span></div>
                                                    </div>
                                                  </p-checkbox>
                                                  {{product.paymentId}}
                                                <!--  {{product.statusDate| date:'yyyy-MM-dd hh:mm:ss'}} -->
                                                </div>
                                              </td>
                                              <td [ngStyle]="{'width':'170px'}">  {{product.paymentMethodTypeId}}</td>
                                             
                                              <td [ngStyle]="{'width':'170px'}">  {{product.statusId}}</td>
                                              <td [ngStyle]="{'width':'170px'}">  {{product.comments}}</td>
                                             
                                              <td [ngStyle]="{'width':'170px'}">  {{product.partyIdTo}}</td>
                                              <td [ngStyle]="{'width':'170px'}"> {{product.effectiveDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                             
                                              <td [ngStyle]="{'width':'170px'}">  {{product.amount}}</td>
                                             
                                           
                                            
        
        
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">No Record Found
                                                        </h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        </p-table>
                                        <p class="paginate_data">
                                          View per page
                                        
                                        </p>
                                      </div>
                                </div>
                              </div>
                            </div>
                            
                        
                        </div>
                        <br>
                        <div class="w3-card-4 classCardView">
                            <div class="header-tabs">
                              
                            
                                <h4 class=" common-styling hCard">
                                    View Gateway Response
                                </h4>
                             
                               
      
                              </div>
                              <div class="panel-body" *ngIf="this.paymentGatewayResponse" >
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                  <div class="color-black container">
                                    <div class="article-container">
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Payment Gateway Response ID</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.paymentGatewayResponseId}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Payment Service Type Enum ID</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.paymentServiceTypeEnum}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Order Payment Preference ID</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.orderPaymentPreferenceId}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Payment Method ID</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.paymentMethodTypeId}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Trans Code Enum ID</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.transCodeEnum}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Amount</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.amount}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Currency</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.currencyUomId}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Reference Num</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.referenceNum}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Alt Reference</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.altReference}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Sub Reference</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.subReference}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Gateway Code</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.gatewayCode}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Gateway Flag</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.gatewayFlag}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Gateway Avs Result</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.gatewayAvsResult}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">	 
                                            Gateway Cv Result</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.gatewayCvResult}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Gateway Score Result</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.gatewayScoreResult}}</span>
                                      </div>
                                    
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Transaction Date</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.transactionDate}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Result Declined</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.resultDeclined}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Result Nsf</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.resultNsf}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Result Bad Expire</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.resultBadExpire}}</span>
                                      </div>
                                     
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Result Bad Card Number</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.resultBadCardNumber}}</span>
                                      </div>
                                      <div class="article">
                                        <p class="col-lg-6 col-12">Gateway Message</p>
                                        <span class="color-grey pl-20 col-lg-10 col-12">{{this.paymentGatewayResponse.gatewayMessage}}</span>
                                      </div>
                                  
                                   
                                   
                                 
      
                                   
      
                                     
      
      
      
                                    </div>
      
                                  </div>
                                </div>
                              </div>
                         
                        </div>
                        
                       
               
                      
                 
                       
                    
                       
                     
                     
                       
                      
                       
                      
                      
                      </div>
                       
                     
                        
                      </div>
                    </div>
                
  
                </form>
              </div>
            </div>
          </div>
        </div>
      
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>

  
  