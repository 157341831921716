import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

@Component({
  selector: 'app-tasks-project',
  templateUrl: './tasks-project.component.html',
  styleUrls: ['./tasks-project.component.css']
})
export class TasksProjectComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 5;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  projectForm: FormGroup;
  timesheetForm: FormGroup;
  newrequestForm: FormGroup;
  advanceSearch: { TaskID: string; CurrentStatusID: string; TaskName: string; partyID: string; fromDate: string; toDate: string; ProjectName: string; };
  FindTaskList: any;
  PartyId: any;
  PartyIdArray: any[]=[];

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
        readonly _FormBuilder: FormBuilder,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
    readonly _Router: Router
  ) {
    this.advanceSearch = {
      TaskID: "",
      CurrentStatusID: "",
      TaskName: "",
      partyID: "",
      fromDate: "",
      toDate: "",
      ProjectName: "",
    };
   }

  ngOnInit(): void {
    this.getFindTaskList();
    this.getPartyList();
  }
   
  getPartyList() {
    this.spinner.show();
    this.myContactsService.getPartyList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }


  getFindTask() {
 
    this.spinner.show();
    if(this.advanceSearch.TaskID||this.advanceSearch.CurrentStatusID||this.advanceSearch.TaskName||
      this.advanceSearch.partyID||this.advanceSearch.fromDate||this.advanceSearch.toDate
      ||this.advanceSearch.ProjectName){
    const req = {
      "taskId": this.advanceSearch.TaskID ? this.advanceSearch.TaskID : '',
      "currentStatusId": this.advanceSearch.CurrentStatusID ? this.advanceSearch.CurrentStatusID : '',
      "taskName": this.advanceSearch.TaskName ? this.advanceSearch.TaskName : '',
      "partyId": this.advanceSearch.partyID ? this.advanceSearch.partyID : '',
      "formDate": this.advanceSearch.fromDate ? this.advanceSearch.fromDate : '',
      "projectName": this.advanceSearch.ProjectName ? this.advanceSearch.ProjectName : '',
    }
    this.myContactsService.getTaskFind(req).subscribe((res: any) => {
      this.FindTaskList = res.data;
      this.spinner.hide();
    })}
    else{
      this.getFindTaskList();
    }
    this.spinner.hide();
  }

  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  
  reset() {
    this.spinner.show();
    this.advanceSearch.TaskID = "";
    this.advanceSearch.CurrentStatusID = "";
    this.advanceSearch.partyID = "";
    this.advanceSearch.TaskName = "";
    this.advanceSearch.toDate = "";
    this.advanceSearch.fromDate = "";
    this.advanceSearch.ProjectName = "";

    const req = {
      "currentStatusId": "",
      "formDate": "",
      "partyId": "",
      "projectName": "",
      "taskId": "",
      "taskName": ""
    }
    this.myContactsService.getTaskFind(req).subscribe((res: any) => {
      this.FindTaskList = res.data;
      this.spinner.hide();
    })
  }
 

  projectSummary(projectId) {
    this._Router.navigate(["/psa/project/detail-project-summary"], { queryParams: { projectID: projectId } })
  }


  getFindTaskList() {
    this.spinner.show();
    const req = {
      "currentStatusId": "",
      "formDate": "",
      "partyId": "",
      "projectName": "",
      "taskId": "",
      "taskName": ""
    }
    this.myContactsService.getTaskFind(req).subscribe(res => {
      this.FindTaskList = res.data;
      this.spinner.hide();
    })
  }

  detailPage(workEffortId,projectId,statusId) {
    this._Router.navigate(["/psa/project/detail-task-project"],
 { queryParams: { workEffortId: workEffortId,projectId:projectId ,statusId:statusId} })
  }

  projectMain() {
    this._Router.navigate(["/psa/project/project"])
  }
  myTasks() {
    this._Router.navigate(["/psa/project/my-tasks"])
  }
  myTime() {
    this._Router.navigate(["/psa/project/my-time"])
  }
  projects() {
    this._Router.navigate(["/psa/project/projects"])
  }
  tasks() {
    this._Router.navigate(["/psa/project/tasks-project"])
  }
  resources() {
    this._Router.navigate(["/psa/project/resources"])
  }
  timesheet() {
    this._Router.navigate(["/psa/project/timesheet-project"])
  }
  
  skillType() {
    this._Router.navigate(["/psa/project/skill-type"])
  }
  requestList() {
    this._Router.navigate(["/psa/project/request-list"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  
}
