<div class="all-users-infomation font-13 font-medium own-user-select"  style="margin-top: 5%;">
    <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
        <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Back</button>
    </div>
    <div class="col-lg-12 col-12">
        <div class="w3-card-4 classCard" >
        <div class="form-group">
            
                <header class="w3-container w3-blue">
                  <div class="header-tabs">
                    <h4 class=" common-styling h4Margin">
                        Employees List
                        
                    </h4>
                 
                  </div>
                </header>
          <div class="card own-account-table" style="    padding: 10px!important;">
            <p-table [value]="employeeList"  [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"  [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                <ng-template pTemplate="header">
                    <tr style="background: #0d3769;">
                        <th pSortableColumn="code" [ngStyle]="{'width':'150px'}">
                            <div class="checkbox-align" style="color: white;">
                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted"><div class="custom-checkbox ui-chkbox ui-widget"><div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div><div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div></div><!----></p-checkbox>
                                Employee ID <p-sortIcon field="code"></p-sortIcon>
                            </div>
                          </th>
                        <th pSortableColumn="name" [ngStyle]="{'width':'150px'}"><div style="color: white;">First Name<p-sortIcon field="name"></p-sortIcon></div>
                            <div>
                       </div>
                        </th>
                        <th pSortableColumn="category" [ngStyle]="{'width':'150px'}"><div style="color: white;">Last Name <p-sortIcon field="category"></p-sortIcon></div>
                            <div>
                      </div>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td [ngStyle]="{'width':'150px'}"><div class="checkbox-align">
                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted"><div class="custom-checkbox ui-chkbox ui-widget"><div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div><div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div></div><!----></p-checkbox>
                            {{product.partyId}}
                        </div>
                           </td>
                        <td [ngStyle]="{'width':'150px'}">{{product.firstName}}</td>    
                        <td [ngStyle]="{'width':'150px'}">{{product.lastName}}</td>
                    </tr>
                </ng-template>
            </p-table>
          
                <p class="paginate_data">
                    View per page</p>
        </div>
        </div>
        </div>
      </div>
</div>
<ngx-spinner></ngx-spinner>