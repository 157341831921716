<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
           
            <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                <span class="color-black pl-1 titlePanels">Notes Summary</span>
                <span>
                     <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                     <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                 </span> 
             </div>
            
            <div class="create-new-leade ">
            <div class="container-fluid">
                <div class="row">
                    <form class="w-100">
                        <div class="port-header-contact create-lead font-medium mb-0 w-100">

                                <div class="w3-card-4 classCardView w-100">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                        style="justify-content: space-between;">
                                        <p class="h-text">Summary</p>
                                        <span>
                                            <!-- <button type="submit" class="btn btn-secondary submit-btn ml-2"
                                                style="margin: 0;" data-toggle="modal"
                                                data-target="#softwarePopup">Update</button> -->
                                        </span>
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        
                                        <div class="color-black container">
                                            <div class="row" *ngIf="taskNoteByIdList">
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Note Id	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.taskNoteByIdList.list.noteId}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Note Name
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.taskNoteByIdList.list.noteName}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Note Info	
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.taskNoteByIdList.list.noteInfo}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Note Party</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.taskNoteByIdList.list.noteParty}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">More Info Url	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.taskNoteByIdList.list.moreInfoUrl}}
                                                             </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">More Info Item Name	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.taskNoteByIdList.list.moreInfoItemName}} </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Note Date Time	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{this.taskNoteByIdList.list.noteDateTime| date:'yyyy-MM-dd hh:mm:ss'}}  </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </form>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>



 <ngx-spinner></ngx-spinner> 

