<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Detail Financial Account</span>
                <span class="color-black pl-1" *ngIf="!show"> >Add New Financial Role </span>
                <span class="color-black pl-1" *ngIf="show"> >Update New Financial Role </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass"  >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                        Add New Financial Role</a></li>
                        <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                            Update New Financial Role</a></li>
                     
                     
          
          
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createRole">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Customer ID<span
                                                    style="color:red">*</span> </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Customer ID" filter="true"
                                            formControlName="partyId" 
                                            [options]="partyIdArray" optionlabel="label" *ngIf="!show">
                                                            
                                            </p-dropdown>
                                            <input type="email"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter From Date"
                                            formControlName="partyId" *ngIf="show" readonly >
                                            </div>
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Role Type ID <span
                                                    style="color:red">*</span> </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter Role Type ID"  filter="true" 
                                                        formControlName="roleTypeId"
                                                        [options]="allroleArray" optionlabel="label" *ngIf="!show">
                                                            
                                                        </p-dropdown>
                                                        <input type="email" *ngIf="show"   class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter From Date"
                                                        formControlName="roleTypeId" readonly >
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">From Date	<span
                                                    style="color:red">*</span> 
                                                   
                                                </label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date"  *ngIf="!show"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter From Date"
                                                formControlName="fromDate" >
                                                <input type="date"  *ngIf="show"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter From Date"
                                                formControlName="fromDate" readonly >
                                            </div>
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Through Date </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date"   class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Through Date"
                                                formControlName="thruDate" >
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                  
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 80%;">
                                <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit"  *ngIf="!show"  (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
