import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';

@Component({
  selector: 'app-create-budget',
  templateUrl: './create-budget.component.html',
  styleUrls: ['./create-budget.component.css']
})
export class CreateBudgetComponent implements OnInit {
  createbudget:FormGroup;
  activeCategory=2;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  partyId: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  varianceReasonId: any;
  varianceReasonIdArray: any[]=[];
  glAccountid: any;
  varianceReasonIdData: any;
  show: boolean;
  id: string;
  BudgetType: any;
  BudgetTypeArray: any[]=[];
  timePerod: any;
  timePerodArray: any[]=[];
  budgetId: any;
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
  
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createbudget = this._FormBuilder.group({
        budgetId: [''],
        customTime:[''],
        comment:['']
       
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.budgetId = params["budgetId"];
    });
  this.getBudgetType();
  this.getCustomTimePeriodListDetail();
  if(this.budgetId){
    this.show=true;
    this.getBudgetList();
   
        }else{
          this.show=false;
        }
  }
  getBudgetList() {
    this.spinner.show();
    const req={
      "budgetId":this.budgetId,
      "budgetIdSearchType": "Contains",
      "budgetTypeId": "",
      "comments": "",
      "commentsSearchType": "Contains",
      "customTimePeriodId": "",
      "customTimePeriodIdSearchType": "Contains"
    
    }
    this.accountsService.getBudgetList(this.finSize,req).subscribe((res: any) => {
     
      this.spinner.hide();
      setTimeout(() => {
       
      
        const formValue = this.createbudget;

        formValue.patchValue({
          budgetId: res.data[0].budgetTypeId,
          customTime:res.data[0].customTimePeriodId,
          comment:res.data[0].comments,
        })
      
      }, 3000);
    })
  
  }
  getBudgetType() {
    this.spinner.show();
    this.accountsService.getBudgetType().subscribe((res: any) => {
      this.BudgetType = res.data;
      this.spinner.hide();
      for (const value of this.BudgetType) {
        this.BudgetTypeArray.push({
          label: value.description,
          value: value.budgetTypeId
        })
      }
    })
  
  }
  getCustomTimePeriodListDetail() {
    this.spinner.show();
   this.id=""
    this.accountsService.getCustomTimePeriodListDetail(this.id).subscribe((res: any) => {

      this.timePerod = res.data;
      this.spinner.hide();
      for (const value of this.timePerod) {
        this.timePerodArray.push({
          label:  value.customTimePeriodId,
          value: value.customTimePeriodId
        })
      }


    })
   

  }
  onUpdate(): void {

    const requestData = {
      "budgetId":this.budgetId,
      "budgetTypeId": this.createbudget.value.budgetId,
      "comments": this.createbudget.value.comment,
      "customTimePeriodId": this.createbudget.value.customTime,
  
           
    }

    this.accountsService.updatetBudget(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/budget-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  onSubmit(): void {

    const requestData = {
      "budgetId": "",
      "budgetTypeId": this.createbudget.value.budgetId,
      "comments": this.createbudget.value.comment,
      "customTimePeriodId": this.createbudget.value.customTime,
  
           
    }

    this.accountsService.postBudget(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/budget-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  
  cancelSubmit(){
    this._Router.navigate(['/financial/budget-list']);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
