import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from '../accounts/accounts.service';

@Component({
  selector: 'app-create-content',
  templateUrl: './create-content.component.html',
  styleUrls: ['./create-content.component.css']
})
export class CreateContentComponent implements OnInit {
  activeCategory=2;
  createEventContent:FormGroup
  eventPurposeArray: any[]=[];
  commEventId: any;
  constructor(readonly router: Router,
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    
    readonly spinner: NgxSpinnerService,) { 
    this.createEventContent = this._FormBuilder.group({
      extContentId: [''],
      parentContentId:['']
     
 
    });
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.commEventId = params['commEventId'];
     
    });
    this.existingContentIds();
  }
  
  existingContentIds() {
    this.spinner.show();
    this.accountsService.existingContentIds().subscribe(res => {
      const contentid = res.data;
      this.spinner.hide();
       for (const value of contentid) {
        this.eventPurposeArray.push({
          label: value.contentId,
          value: value.contentId
        })
      } 
    })
   
  }
  cancelSubmit(){
    this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:this.commEventId} });
  }
}

