<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Relationships
                </span>
                <span class="color-black pl-1" > >New Relationship Type
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''"><a>
                        New Relationship Type
                    </a></li>
                    
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="newRelationType">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">
                                                    Party Relationship Type ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="partyRelationType"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Party Relationship Type ID"> 
                                          
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Parent Type ID</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true"
                                                [options]="ParentTypeIdPartyArray" optionlabel="label"
                                                formControlName="parentTypeId"
                                                placeholder="Enter Parent Type ID"
                                                name="Parent Type ID">
                                            </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">
                                                    Party Relationship Name<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="relationName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Party Relationship Name"> 
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Description</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="Description"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Description"> 
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">
                                                    Valid From Role Type</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true"
                                                formControlName="fromRole"
                                                [options]="allroleArray" optionlabel="label"
                                                placeholder="Enter Valid From Role Type"
                                                name="Parent Type ID">
                                            </p-dropdown>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Valid To Role Type</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true"
                                                formControlName="toRole"
                                                [options]="allroleArray" optionlabel="label"
                                                placeholder="Enter Valid To Role Type"
                                                name="Parent Type ID">
                                            </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                   
                                 
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 68%;">
                               
                                <button type="submit" (click)="onSubmit();"  class="btn btn-secondary submit-btn">Create</button>
                                
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div> -->


<div class="modal-header">
    <span class="color-black pl-1">New Relationship Type</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex">
      <div class=" bg-white color-grey" style="width: 100%;">
          <div class="w3-card-4 classCardPopUp" style="width: 100%;">
              <div class="container-fluid">
                  <ul class="tabbing-section tabbing-accordians tabClass">

                      <li class="active"><a>
                        New Relationship Type</a></li>
                  </ul>
              </div>
              
            
              <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form  class="w-100" [formGroup]="newRelationType">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                
                                        <div class="col-lg-4 form-group classInput" >
                                            <label for="exampleInputEmail1">
                                                Party Relationship Type ID<span
                                                style="color:red">*</span></label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <input type="email"  formControlName="partyRelationType"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Party Relationship Type ID"> 
                                      
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Parent Type ID</label>
                                        </div>
                                       
                                        <div class="col-lg-3 custom-dropdown">
                                            <p-dropdown filter="true"
                                            [options]="ParentTypeIdPartyArray" optionlabel="label"
                                            formControlName="parentTypeId"
                                            placeholder="Enter Parent Type ID"
                                            name="Parent Type ID">
                                        </p-dropdown>
                                        </div>
                
                                       
                
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                
                                        <div class="col-lg-4 form-group classInput" >
                                            <label for="exampleInputEmail1">
                                                Party Relationship Name<span
                                                style="color:red">*</span></label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <input type="email"  formControlName="relationName"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Party Relationship Name"> 
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Description</label>
                                        </div>
                                       
                                        <div class="col-lg-3">
                                            <input type="email"  formControlName="Description"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Description"> 
                                        </div>
                
                                       
                
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                
                                        <div class="col-lg-4 form-group classInput" >
                                            <label for="exampleInputEmail1">
                                                Valid From Role Type</label>
                                        </div>
                                     
                                        <div class="col-lg-3 custom-dropdown">
                                            <p-dropdown filter="true"
                                            formControlName="fromRole"
                                            [options]="allroleArray" optionlabel="label"
                                            placeholder="Enter Valid From Role Type"
                                            name="Parent Type ID">
                                        </p-dropdown>
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Valid To Role Type</label>
                                        </div>
                                       
                                        <div class="col-lg-3 custom-dropdown">
                                            <p-dropdown filter="true"
                                            formControlName="toRole"
                                            [options]="allroleArray" optionlabel="label"
                                            placeholder="Enter Valid To Role Type"
                                            name="Parent Type ID">
                                        </p-dropdown>
                                        </div>
                
                                       
                
                                    </div>
                                </div>
                               
                             
                            </div>
                            

                        </form>
                        <div class="main-submit-button" style="margin-left: 78%;">
                           
                            <button type="submit" (click)="onSubmit();"  class="btn btn-secondary submit-btn">Create</button>
                            
                            
                            
                          
                        </div>
                    </div>
                </div>

            </div>
              
             </div>
      </div>
  </div>

