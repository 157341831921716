<div class="container-fluid main-container-wrapper pad_0 ">
    
    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels" *ngIf="this.agreementTypeValue=='payable'">Payables

        </span>
       <span class="color-black pl-1 titlePanels" *ngIf="this.agreementTypeValue=='receiveable'">Receivables

        </span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>
    <div class=" bg-white color-grey">
        
        <div class="w3-card-4 classCard classCardType border_wdth_1" >
        <div class="container-fluid" *ngIf="this.agreementTypeValue=='payable'">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                <li  [ngClass]="activeCategory==4?'active':''" (click)="main()">
                    <a>Payables Summary </a>

                </li>
                <li [ngClass]="activeCategory==1?'active':''" (click)="enterBills()"><a>
                    Enter Bills </a></li>
                <li [ngClass]="activeCategory==2?'active':''" (click)="payableMakePayment()"><a>
                    Make Payments</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="payableMakeGroupPayment()"><a>
                        Make Group Payments</a></li>
            </ul>
        </div>
        <div class="container-fluid" *ngIf="this.agreementTypeValue=='receiveable'">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                <li [ngClass]="activeCategory==4?'active':''" (click)="mainReceive()"><a>
                    Receivables Summary</a></li>
                <li [ngClass]="activeCategory==1?'active':''" (click)="salesInvoice()"><a>
                    Sales Invoice </a></li>
                <li [ngClass]="activeCategory==2?'active':''" (click)="receivePayment()"><a>
                    Receive Payments</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="receiveGroupPayments()"><a>
                        Receive Group Payments</a></li>
            </ul>
        </div>

        <div class="create-new-leade mt-2">
            <div class="container-fluid">
              
                   
                                <div class="panel-group">
                                    <div *ngIf="activeCategory==1">
                                      
                                       
                                        <div class="panel-collapse">
                                            <br>
                                            <div class="w3-card-4 classCard classCardType" *ngIf="this.agreementTypeValue=='payable'">
                                                <div class="container-fluid" >
                                                    <ul class="tabbing-section tabbing-accordians tabClass" >
                                          
                                                      <li [ngClass]="activeCategoryMain==2?'active':''" (click)="changeactiveCategoryFunctionPurchae()"><a>
                                                        Purchases Invoices</a></li>
                                                        <li [ngClass]="activeCategoryMain==4?'active':''" (click)="changeactiveCommission()"><a>
                                                            Commission Invoices</a></li>
                                                        
                                                        <li [ngClass]="activeCategoryMain==3?'active':''" (click)="changeactiveCategoryFunctionCredit()"><a>
                                                            Credit Memo</a></li>
                                                   
                                          
                                                    </ul>
                                                </div>
                                                    <div class="create-new-leade mt-2">
                                                        <div class="container-fluid">
                                                            <div class="row" style=" padding: 7px;">
                                                                <form class="w-100" [formGroup]="searchInvoiceForm">

                                                                    <div class="w3-card-4 mt-2 w-100">
                                                                        <div class="accordion classCard" id="myAccordion">
                                                                            <div class="accordion-item">
                                                                                <h2 class="accordion-header" id="headingOne">
                                                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                                        <button type="button" class="btn btn-outline-secondary active marginHeader" routerLinkActive="active"
                                                                                        routerLink="/financial/{{invoiceType}}/invoices/create-invoice">Create New Invoice</button>
                                                                            
                                                                                        <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 78%!important; padding-top: 11px !important;
                                                                                            padding-bottom: 11px !important;">
                                                                                        </button>									
                                        
                                                                                    </div>
                                                
                                                                                </h2>
                                                                                <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                                    <div class="card-body">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                            
                                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                                        <label for="exampleInputEmail1">Invoice ID</label>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                        <p-dropdown filter="true" name="party"
                                                                                                        [options]="opportunityIdArray" optionlabel="label"
                                                                                                        formControlName="invoiceIdSearchType">
                                                                                                    </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                        
                                                                                                            aria-describedby="emailHelp" placeholder="Invoice Id"
                                                                                                                formControlName="invoiceId">
                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">Description</label>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                        <p-dropdown filter="true" name="party"
                                                                                                        formControlName="descriptionSearchType"
                                                                                                        [options]="opportunityIdArray" optionlabel="label">
                                                                                                    </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                        
                                                                                                            formControlName="description" aria-describedby="emailHelp"
                                                                                                        placeholder="Description">
                                                                                                    </div>
                                                                            
                                                                                                    
                                                                            
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                            
                                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                                        <label for="exampleInputEmail1">Invoice Type</label>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true" name="party" [options]="invoiceTypes"
                                                                                                        
                                                                                                        formControlName="invoiceType" optionlabel="label">
                                                                                                    </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">Status ID</label>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true" name="party" [options]="statusIds"
                                                                                                        
                                                                                                        formControlName="statusId" optionlabel="label"
                                                                                                            placeholder="Select Status">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                            
                                                                                                    
                                                                            
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                            
                                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                                        <label for="exampleInputEmail1">Customer ID</label>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                                                        
                                                                                                            formControlName="fromPartyId" optionlabel="label"
                                                                                                            placeholder="Select PartyID">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                                        <label for="exampleInputEmail1">Supplier ID</label>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                                                        
                                                                                                            formControlName="toPartyId" optionlabel="label"
                                                                                                            placeholder="Select PartyID">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                            
                                                                                                    
                                                                            
                                                                                                </div>
                                                                                            </div>
                                                                                            
                                                                                            <div class="col-lg-12">
                                                                                                <div class="row">
                                                                            
                                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                                        <label for="exampleInputEmail1">Invoice Date</label>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                        <p-dropdown filter="true" name="party" [options]="greaterArray"
                                                                                                        
                                                                                                            formControlName="invoiceDateFromSearchType" optionlabel="label">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2" style="    margin-left: -2%;">
                                                                                                        <p-calendar class="ml-2 col-lg-2"
                                                                                                        
                                                                                                            formControlName="invoiceDateFrom"></p-calendar>
                                                                                                    </div>
                                                                                                    
                                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                                        <p-dropdown filter="true" class="ml-2" name="party"
                                                                                                            formControlName="invoiceDateToSearchType" [options]="smallerArray"
                                                                                                            optionlabel="label">
                                                                                                        </p-dropdown>
                                                                                                    </div>
                                                                                                    <div class="col-lg-2">
                                                                                                        <p-calendar class="ml-2 col-lg-2" 
                                                                                                        
                                                                                                        formControlName="invoiceDateTo"></p-calendar>
                                                                                                    </div>
                                                                            
                                                                                                    
                                                                            
                                                                                                </div>
                                                                                            </div>
                                                                    
                                                        
                                                                                        </div>

                                                                                        <div style=" margin-left: -29%;" class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128=""
                                                                                            type="submit" class="btn btn-secondary submit-btn"
                                                                                            (click)="findPurchaseInvoices()">Find</button>&nbsp;
                                                                                            <button type="button" class="btn btn-danger ml-2" (click)="resetPerInvoice()">Reset</button>
                                                                                        </div>
                                                                                        
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>


                                                                    <!-- <div class="w3-card-4 classCard">
                                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                           
                                                                                    <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active"
                                                                                        routerLink="/financial/{{invoiceType}}/invoices/create-invoice">Create New Invoice</button>
                                                                            
                                                                        </div>
                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                            
                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                        <label for="exampleInputEmail1">Invoice ID</label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" name="party"
                                                                                        [options]="opportunityIdArray" optionlabel="label"
                                                                                        formControlName="invoiceIdSearchType">
                                                                                    </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                        
                                                                                            aria-describedby="emailHelp" placeholder="Invoice Id"
                                                                                                formControlName="invoiceId">
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Description</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" name="party"
                                                                                        formControlName="descriptionSearchType"
                                                                                        [options]="opportunityIdArray" optionlabel="label">
                                                                                    </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                        
                                                                                            formControlName="description" aria-describedby="emailHelp"
                                                                                        placeholder="Description">
                                                                                    </div>
                                                            
                                                                                    
                                                            
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                            
                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                        <label for="exampleInputEmail1">Invoice Type</label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" name="party" [options]="invoiceTypes"
                                                                                        
                                                                                        formControlName="invoiceType" optionlabel="label">
                                                                                    </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Status ID</label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" name="party" [options]="statusIds"
                                                                                        
                                                                                        formControlName="statusId" optionlabel="label"
                                                                                            placeholder="Select Status">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                            
                                                                                    
                                                            
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                            
                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                        <label for="exampleInputEmail1">Customer ID</label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                                        
                                                                                            formControlName="fromPartyId" optionlabel="label"
                                                                                            placeholder="Select PartyID">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Supplier ID</label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                                        
                                                                                            formControlName="toPartyId" optionlabel="label"
                                                                                            placeholder="Select PartyID">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                            
                                                                                    
                                                            
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                            
                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                        <label for="exampleInputEmail1">Invoice Date</label>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" name="party" [options]="greaterArray"
                                                                                        
                                                                                            formControlName="invoiceDateFromSearchType" optionlabel="label">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="    margin-left: -2%;">
                                                                                        <p-calendar class="ml-2 col-lg-2"
                                                                                        
                                                                                            formControlName="invoiceDateFrom"></p-calendar>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" class="ml-2" name="party"
                                                                                            formControlName="invoiceDateToSearchType" [options]="smallerArray"
                                                                                            optionlabel="label">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-calendar class="ml-2 col-lg-2" 
                                                                                        
                                                                                        formControlName="invoiceDateTo"></p-calendar>
                                                                                    </div>
                                                            
                                                                                    
                                                            
                                                                                </div>
                                                                            </div>
                                                    
                                        
                                                                        </div>
                                                                        <div style=" margin-left: -29%;" class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128=""
                                                                                type="submit" class="btn btn-secondary submit-btn"
                                                                                (click)="searchInvoice()">Find</button>&nbsp;
                                                                            <button type="button" class="btn btn-danger ml-2" (click)="resetPerInvoice()">Reset</button>
                                                                        </div>
                                                                    </div> -->
                                                                <br>
                                                                    <div class="w3-card-4 classCard">
                                                                        <header class="w3-container w3-blue">
                                                                          <div class="header-tabs">
                                                                            <h4 class=" common-styling h4Margin">
                                                                                Invoice List
                                                                            </h4>
                                                                         
                                                                          </div>
                                                                        </header>
                                                                    <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                        <div class="col-lg-12 col-12">
                                                                            <div class="form-group">
                                                                                
                                                                                <div class="card own-account-table">
                                                                                    <p-table [value]="getPurchaseInvoicesData" [paginator]="true" [rows]="rows"
                                                                                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                        [responsive]="true" [totalRecords]="total" [scrollable]="true"
                                                                                        styleClass="p-datatable-customers"
                                                                                        (sortFunction)="customSort($event)" [customSort]="true">
                                                                                        <ng-template pTemplate="header">
                                                                                            <tr style="background: #0d3769;">
                                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined"
                                                                                                                        value="undefined"></div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        Invoice ID <p-sortIcon field="code">
                                                                                                        </p-sortIcon>
                                                                                                    </div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                                 <div style="color: white;">   Invoice Type<p-sortIcon field="name">
                                                                                                    </p-sortIcon></div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                                    pSortableColumn="category">  <div style="color: white;"> Invoice Date
                                                                                                    <p-sortIcon field="category"></p-sortIcon></div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th [ngStyle]="{'width':'190px'}"
                                                                                                    pSortableColumn="quantity">  <div style="color: white;"> Status
                                                                                                    <p-sortIcon field="quantity"></p-sortIcon></div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">  Description <p-sortIcon field="price"></p-sortIcon></div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}"  >
                                                                                                    <div style="color: white;"> Customer Id<p-sortIcon field="price"></p-sortIcon></div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        value="" autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}" >
                                                                                                    <div style="color: white;"> Supplier Id</div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                              
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">   Total</div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">  Outstanding amount</div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">  Created Date</div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                                <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                    <div style="color: white;">  Action</div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                        style="visibility:hidden;" value=""
                                                                                                        autocomplete="off"
                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false"
                                                                                                        aria-readonly="false">
                                                                                                </th>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="body" let-product>
                                                                                            <tr>
                                                                                                <td [ngStyle]="{'width':'200px'}">
                                                                                                    <div class="checkbox-align">
                                                                                                        <p-checkbox _ngcontent-egj-c329=""
                                                                                                            styleclass="custom-checkbox" binary="true"
                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                            <div
                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                    <input type="checkbox" name="undefined"
                                                                                                                        value="undefined"></div>
                                                                                                                <div role="checkbox"
                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                    <span
                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <!---->
                                                                                                        </p-checkbox>
                                                                                                        <span
                                                                                                            routerLink="/financial/{{invoiceType}}/invoices/detailed-summary"
                                                                                                            [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                            class="account-button">{{product.invoice.invoiceId}}</span>
                                                                                                    </div>
                                                                                                </td>
                                    
                                    
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.invoice.invoiceTypeId}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.invoice.invoiceDate |date :'yyyy-MM-dd'}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.invoice.statusId }}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.invoice.description}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.invoice.partyIdFrom}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.invoice.partyId}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.invoiceItem[0].amount |currency :'USD'}}</td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    <!-- {{product.outstanding |currency :'USD'}} -->
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    {{product.invoice.createdStamp |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                </td>
                                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                                    <div>
                                                                                                        <svg class="hover"
                                                                                                            xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                                            *ngIf="product.statusId==='INVOICE_IN_PROCESS'"
                                                                                                            routerLink="/financial/{{invoiceType}}/invoices/create-invoice"
                                                                                                            [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                            height="16" viewBox="0 0 21.223 21.222">
                                                                                                            <g transform="translate(0 -0.004)">
                                                                                                                <g transform="translate(0 1.52)">
                                                                                                                    <path
                                                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                        transform="translate(0 -34.137)" />
                                                                                                                </g>
                                                                                                                <g transform="translate(4.548 0.004)">
                                                                                                                    <g transform="translate(0 0)">
                                                                                                                        <path
                                                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </svg>
                                    
                                                                                                    </div>
                                                                                                </td>
                                    
                                                                                            </tr>
                                    
                                                                                        </ng-template>
                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                            <tr>
                                                                                                <td>
                                                                                                    <span class="text-center">
                                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </ng-template>
                                                                                    </p-table>
                                                                                   
                                                                                    <p class="paginate_data">
                                                                                        View per page </p>
                                    
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </form>
                                    
                                                            </div>
                                                        </div>
                                                    </div>
                                                 
                                                </div>
                                               
                                                    <div  *ngIf="this.agreementTypeValue=='receiveable'">
                                                     
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100" [formGroup]="searchInvoiceForm">
                                                                        <div class="w3-card-4 classCard">
                                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                               
                                                                                        <button type="button" class="btn btn-outline-secondary active" routerLinkActive="active"
                                                                                            routerLink="/financial/{{invoiceType}}/invoices/create-invoice">Create Invoice</button>
                                                                                
                                                                                </div>
                                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                            
                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                        <label for="exampleInputEmail1">Invoice ID</label>
                                                                                    </div>
                                                                                 
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" name="party"
                                                                                        [options]="opportunityIdArray" optionlabel="label"
                                                                                        formControlName="invoiceIdSearchType">
                                                                                    </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                                aria-describedby="emailHelp" placeholder="Invoice Id"
                                                                                                formControlName="invoiceId">
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Description</label>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" name="party"
                                                                                        formControlName="descriptionSearchType"
                                                                                        [options]="opportunityIdArray" optionlabel="label">
                                                                                    </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <input type="email" id="exampleInputEmail1" class="form-control"
                                                                                        formControlName="description" aria-describedby="emailHelp"
                                                                                        placeholder="Description">
                                                                                    </div>
                                                            
                                                                                   
                                                            
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                            
                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                        <label for="exampleInputEmail1">Invoice Type</label>
                                                                                    </div>
                                                                                 
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" name="party" [options]="invoiceTypes"
                                                                                        formControlName="invoiceType" optionlabel="label">
                                                                                    </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Status ID</label>
                                                                                    </div>
                                                                                   
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" name="party" [options]="statusIds"
                                                                                            formControlName="statusId" optionlabel="label"
                                                                                            placeholder="Select Status">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                            
                                                                                   
                                                            
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                            
                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                        <label for="exampleInputEmail1">Supplier ID</label>
                                                                                    </div>
                                                                                 
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                                            formControlName="fromPartyId" optionlabel="label"
                                                                                            placeholder="Select PartyID">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-3 form-group classInputA">
                                                                                        <label for="exampleInputEmail1">Customer ID</label>
                                                                                    </div>
                                                                                   
                                                                                    <div class="col-lg-2">
                                                                                        <p-dropdown filter="true" name="party" [options]="partyIds"
                                                                                            formControlName="toPartyId" optionlabel="label"
                                                                                            placeholder="Select PartyID">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                            
                                                                                   
                                                            
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-12">
                                                                                <div class="row">
                                                            
                                                                                    <div class="col-lg-3 form-group classInput" >
                                                                                        <label for="exampleInputEmail1">Invoice Date</label>
                                                                                    </div>
                                                                                 
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" name="party" [options]="greaterArray"
                                                                                            formControlName="invoiceDateFromSearchType" optionlabel="label">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2" style="    margin-left: -2%;">
                                                                                        <p-calendar class="ml-2 col-lg-2" formControlName="invoiceDateFrom"></p-calendar>
                                                                                    </div>
                                                                                  
                                                                                    <div class="col-lg-2" style="display: none;">
                                                                                        <p-dropdown filter="true" class="ml-2" name="party"
                                                                                            formControlName="invoiceDateToSearchType" [options]="smallerArray"
                                                                                            optionlabel="label">
                                                                                        </p-dropdown>
                                                                                    </div>
                                                                                    <div class="col-lg-2">
                                                                                        <p-calendar class="ml-2 col-lg-2" formControlName="invoiceDateTo"></p-calendar>
                                                                                    </div>
                                                            
                                                                                   
                                                            
                                                                                </div>
                                                                            </div>
                                                  
                                        
                                                                        </div>
                                                                        <div style=" margin-left: -29%;" class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128=""
                                                                                type="submit" class="btn btn-secondary submit-btn"
                                                                                (click)="searchInvoice()">Find</button>&nbsp;
                                                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                                        </div></div><br>
                                                                        <div class="w3-card-4 classCard">
                                                                            <header class="w3-container w3-blue">
                                                                              <div class="header-tabs">
                                                                                <h4 class=" common-styling h4Margin">
                                                                                    Invoice List
                                                                                </h4>
                                                                             
                                                                              </div>
                                                                            </header>
                                                                        <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                            <div class="col-lg-12 col-12">
                                                                                <div class="form-group">
                                                                                    
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="invoices" [paginator]="true" [rows]="rows"
                                                                                            scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                            [responsive]="true" [totalRecords]="total" [scrollable]="true"
                                                                                            styleClass="p-datatable-customers"
                                                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox" binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined"
                                                                                                                            value="undefined"></div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            Invoice ID <p-sortIcon field="code">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                                     <div style="color: white;">   Invoice Type<p-sortIcon field="name">
                                                                                                        </p-sortIcon></div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                        pSortableColumn="category">  <div style="color: white;"> Invoice Date
                                                                                                        <p-sortIcon field="category"></p-sortIcon></div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                        pSortableColumn="quantity">  <div style="color: white;"> Status
                                                                                                        <p-sortIcon field="quantity"></p-sortIcon></div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">  Description <p-sortIcon field="price"></p-sortIcon></div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;"> Supplier Id<p-sortIcon field="price"></p-sortIcon></div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            value="" autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;"> Customer Id</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">   Total</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">  Outstanding amount</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">  Created Date</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;">  Action</div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                            style="visibility:hidden;" value=""
                                                                                                            autocomplete="off"
                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false"
                                                                                                            aria-readonly="false">
                                                                                                    </th>
                                                                                                </tr>
                                                                                               
                                                                                                <tr style="background: #FFFFFF;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'190px'}">
                                                                                                        <div class="checkbox-align" style="color: white;height: 25px;">
                                                                                                           
                                                                                                           <span>
                                                                                                            
                                                                                                           </span>
                                                                                                        </div>
                                                                                                       
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'190px'}" pSortableColumn="name">
                                                                                                     <div style="color: white;"></div>
                                                                                                   
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                        pSortableColumn="category">  
                                                                                                        <div style="color: white;"> 
                                                                                                           
                                                                                                          
                                                                                                        </div>
                                                                                                       
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                                                        pSortableColumn="quantity">  
                                                                                                        <form [formGroup]="filterInvoiceStatusForm">
                                                                                                        <div class="row" style="color: white;"> 
                                                                                                            <div class="col-md-12" style="position: absolute;">
                                                                                                                <p-dropdown style="position: absolute;" filter="true" name="party" [options]="invoiceStatusArray" 
                                                                                                                formControlName="statusId" optionlabel="label" (onChange)="invoiceFilter($event)">
                                                                                                                </p-dropdown>
                                                                                                            </div>
        
                                                                                                           
                                                                                                        </div>
                                                                                                        </form>
                                                                                                      
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;"> 
                                                                                                            </div>
                                                                                                       
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}"  >
                                                                                                        <div style="color: white;">
                                                                                                          </div>
                                                                                                      
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}" >
                                                                                                        <div style="color: white;"> </div>
                                                                                                       
                                                                                                    </th>
                                                                                                  
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;"> </div>
                                                                                                     
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;"></div>
                                                                                                      
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;"></div>
                                                                                                        
                                                                                                    </th>
                                                                                                    <th pSortableColumn="price" [ngStyle]="{'width':'190px'}">
                                                                                                        <div style="color: white;"></div>
                                                                                                    
                                                                                                    </th>
                                                                                                </tr>
                                                                                            </ng-template>                                                                             
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                                                styleclass="custom-checkbox" binary="true"
                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div
                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined"
                                                                                                                            value="undefined"></div>
                                                                                                                    <div role="checkbox"
                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span
                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
                                                                                                            <span
                                                                                                                routerLink="/financial/{{invoiceType}}/invoices/detailed-summary"
                                                                                                                [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                                class="account-button">{{product.invoiceId}}</span>
                                                                                                        </div>
                                                                                                    </td>
                                        
                                        
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.invoiceTypeId}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.invoiceDate |date :'yyyy-MM-d hh:mm:ss'}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">{{product.status }}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.description}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">{{product.partyFrom}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">{{product.partyTo}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.total |currency :'USD'}}</td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.outstanding |currency :'USD'}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        {{product.createdStamp |date:'yyyy-MM-d hh:mm:ss'}}
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'190px'}">
                                                                                                        <div>
                                                                                                            <svg class="hover"
                                                                                                                xmlns="http://www.w3.org/2000/svg" width="16"
                                                                                                                *ngIf="product.statusId==='INVOICE_IN_PROCESS'"
                                                                                                                routerLink="/financial/{{invoiceType}}/invoices/create-invoice"
                                                                                                                [queryParams]="{invoiceId:product.invoiceId}"
                                                                                                                height="16" viewBox="0 0 21.223 21.222">
                                                                                                                <g transform="translate(0 -0.004)">
                                                                                                                    <g transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g transform="translate(4.548 0.004)">
                                                                                                                        <g transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                        
                                                                                                        </div>
                                                                                                    </td>
                                        
                                                                                                </tr>
                                        
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                                            <h4 class="ml-2">No Record Found</h4>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                       
                                                                                        <p class="paginate_data">
                                                                                            View per page </p>
                                        
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </form>
                                        
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                        </div>
                                    </div>
                                 


                                </div>
                          
            
            </div>
        </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 