<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Resources
                   </span>
                   <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span> 
            
            </div>

            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="projectMain()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="myTasks()">
                                <a>My Tasks</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="myTime()">
                                <a>My Time</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="projects()">
                                <a>Projects</a>
                            </li>
                            <li [ngClass]="activeCategory==5?'active':''" (click)="tasks()">
                                <a>Tasks</a>
                            </li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="resources()">
                                <a>Resources</a>
                            </li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="timesheet()">
                                <a>Timesheet</a>
                            </li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="skillType()">
                                <a>Skill Type</a>
                            </li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="requestList()">
                                <a>Request List</a>
                            </li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div class="w3-card-4 classCard w-100">
                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                <p class="h-text">Add a Resource</p>
                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                 data-toggle="modal" data-target="#addResource"> Create New Resource</button>
                                            </div>
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table borderTable">
                                                            <p-table [value]="ResourcePage" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>

                                                                                Resource Name
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                E-mail Address
                                                                            </div>
                                                                            <div>
                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                            </div>
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Phone Number
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Purpose
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                            <div style="color: white;">
                                                                                Action
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                </p-checkbox>
                                                                                <span  class="account-button" (click)="myProfilePage(product.partyid.partyId)">
                                                                                {{product.partyid.partyId}}
                                                                            </span>
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                           
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.resourcepage.contactNumber}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                            {{product.purposede[0].description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'170px'}" >
                                                                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-danger ml-2"
                                                                            (click)="removeResource(product)">Remove</button>
                                                                   
                                                                        </td>


                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">No
                                                                                    Record Found
                                                                                </h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>
                                                            <p class="paginate_data">
                                                                View per page

                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="addResource" tabindex="-1" role="dialog" aria-labelledby="addResource" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add a Resource </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li><a> Add a Resource </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addResourceForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormG">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="partyID" 
                                                        [options]="partyIdArray"
                                                        optionlabel="label" placeholder="Enter Party ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormH">
                                                        <label for="exampleInputEmail1">Role Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3 form-group">
                                                        <p-dropdown filter="true" formControlName="roleTypeID" 
                                                        [options]="RoleResourceListArray"
                                                        optionlabel="label" placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            

                                            <div class="col-lg-6 col-6 main-submit-button" style="margin-left: 45%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createAddResource()">Add</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>







