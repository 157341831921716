import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';   import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import Swal from 'sweetalert2'
import { MyContactsService } from "../../crm/contacts/my-contacts/my-contacts.service";

@Component({
  selector: 'app-security-my-profile',
  templateUrl: './security-my-profile.component.html',
  styleUrls: ['./security-my-profile.component.css']
})
export class SecurityMyProfileComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  products3: any;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  rows = 50;
  pageNo = 1;
  activeCategory = 1;
  search = "";
  public user = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search
  }

  userList: any;
  loginIdArray: any[] = [];
  partIdArray: any[] = [];
  status: string;
  adminSecurity: string;
  userEmail: string;
  personId: any;
  partyIdArray: any[] = [];
  catalogId: string;
  party: any;
  AddUserLoginToSecurityGroup: any;
  loginid: any;
  userLoginId: string;
  createSecurity: FormGroup;
  activeCategoryA = 2;
  partyId: any;
  jid: any;

  jname: any;
  show = false;
  identification: any;
  identificationArray: any[] = [];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  ClassificationGroupId: any;
  ClassificationGroupIdArray: any[] = [];
  fromDate: string;
  thruDate: string;
  partyClassId: any;
  efromDate: any;
  ethruDate: any;
  getthruDate: string;
  securtiyList: any;
  groupIdIdArray: any[] = [];
  login: any;
  securityGroupId: any;
  fromDateS: string;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,    
    private _location: Location,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly MyContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly router: Router
  ) {
    this.userLoginId = localStorage.getItem("userLoginId")

    this.contentIds = [];
    this.createSecurity = this._FormBuilder.group({

      Group: [''],
      FromDate: [''],
      ThroughDate: ['']


    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.loginid = params["loginid"];
      this.loginid = params["taxAuthPartyId"];
      this.securityGroupId = this.activatedRoute.snapshot.queryParams.securityGroupId;


    })


    this.getListAddUserLoginToSecurityGroup();
    this.getUserDetails();
  }
  homeButton(){
    this.router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  updateUserLoginToSecurityGroup(product) {
    
      this.show = true;
      const fromdate = this.efromDate
      this.getEstimateFrom = this.datePipe.transform(product.fromDate, "yyyy-MM-dd");
      const ethruDate = this.ethruDate
      this.getthruDate = this.datePipe.transform(product.thruDate, "yyyy-MM-dd");
      setTimeout(() => {
        const formValue = this.createSecurity;
        formValue.patchValue({

          Group: product.securityGroupId,
          FromDate: this.getEstimateFrom,
          ThroughDate: this.getthruDate
        })
      }, 3000);
     
  }

  getUserDetails() {
    this.spinner.show();
    const req = {
      "description": "",
      "descriptionSearchType": "Contains",
      "groupId": "",
      "groupIdSearchType": "Contains"

    }
    this.MyContactsService.getSecurityList(this.user, req).subscribe((res: any) => {
      this.securtiyList = res.data;
      this.spinner.hide();
      for (const value of this.securtiyList) {
        this.groupIdIdArray.push({
          label: value.groupName,
          value: value.groupId
        })
      }

    })
  }

  onSubmit() {
    this.spinner.show();
    const fromDate = this.createSecurity.get('FromDate').value;
    this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");
    const thruDate = this.createSecurity.get('ThroughDate').value;
    this.thruDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {

      "fromDate": this.fromDate,
      "groupId": this.createSecurity.value.Group,
      "partyId": this.party,
      "thruDate": this.thruDate


    }
    this.MyContactsService.profileAddUserLoginSecurityGroup(requestData, this.loginid).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  onUpdate() {
    this.spinner.show();
    const fromDate = this.createSecurity.get('FromDate').value;
    this.fromDateS = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");
    const thruDate = this.createSecurity.get('ThroughDate').value;
    this.thruDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate": this.fromDateS,
      "groupId": this.createSecurity.value.Group,
      "partyId": this.party,
      "thruDate": this.thruDate


    }
    this.MyContactsService.updateAddUserLoginSecurityGroup(requestData, this.loginid).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }

  getListAddUserLoginToSecurityGroup() {
    this.spinner.show();

    this.MyContactsService.getListAddUserLoginToSecurityGroup(this.party, this.loginid).subscribe((res: any) => {
      this.AddUserLoginToSecurityGroup = res.data;
      this.spinner.hide();

    })

  }

  deleteAddUserLoginToSecurityGroup(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "fromDate": product.fromDate,
          "groupId": product.securityGroupId,
          "partyId": product.partyId,
          "thruDate": product.thruDate,
        }
        this.MyContactsService.deleteAddUserLoginToSecurityGroup(req, this.loginid).subscribe(res => {
          this.getListAddUserLoginToSecurityGroup();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
