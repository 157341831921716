
<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Facilities </span>
                <span class="color-black pl-1"> > Inventory Transfer</span>
                <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger" style="margin-left: 75%;"
                (click)="onBack()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard card-border" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
            
                                <li Class="active"><a>
                                    Create Inventory Transfer</a></li>
                            </ul>
                        </div>
            
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                            data-toggle="collapse" href="#collapsetwo">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist"
                                                    aria-multiselectable="true">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <form>
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                      
                                                                        <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -5%;">
                                                                            <label for="inventoryItemId">Inventory Item
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="text" class="form-control"
                                                                                placeholder="Inventory Item ID"
                                                                                [(ngModel)]="preTransfer.inventoryItemId"
                                                                                [ngModelOptions]="{standalone:true}">
                                                                           
                                                                        </div>
                                                                        <div class="col-lg-3" style="    margin-top: -3%;">
                                                                           
                                                                            <button class="btn btn-primary cursor-pointer mt-4"
                                                                                (click)="readyWithInventoryId()">Submit</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                      
                                                                        <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -5%;">
                                                                            <label for="productId">Product ID</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <ng-select class="ng-select-width"
                                                                                [items]="products" bindLabel="internalName"
                                                                                [(ngModel)]="preTransfer.productId"
                                                                                [ngModelOptions]="{standalone:true}"
                                                                                placeholder="Product ID" bindValue="productId">
                                                                            </ng-select>
                                                                           
                                                                        </div>
                                                                        <div class="col-lg-3" style="    margin-top: -3%;">
                                                                           
                                                                            <button class="btn btn-primary cursor-pointer mt-4"
                                                                                (click)="readyWithProductId()">Submit</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                              
                                                              
                                                            </div>
                                                        </form>
                                                        <ng-container *ngIf="true">
                                                            <form [formGroup]="inventoryTransferForm" class="mt-5">
                                                                <div class="row">
                                                                    <div class="col-lg-12" *ngIf="inventoryTransferValue?.statusId">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -8%;">
                                                                                <label for="inventoryId">Inventory ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <span class="text-color-black">
                                                                                    {{inventoryTransferValue?.statusId}}</span>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput"  style="text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: 0%;">
                                                                                <label for="inventoryId">Inventory Item Type
                                                                                    ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <span
                                                                                    class="text-color-black">{{inventoryTransferValue?.inventoryItemTypeId}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                  
                                                                    <div class="col-lg-12" *ngIf="inventoryTransferValue?.statusId">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -8%;">
                                                                                <label for="inventoryId">Product ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <span
                                                                                    class="text-color-black">{{inventoryTransferValue?.productId}}</span>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: 0%;">
                                                                                <label for="inventoryId">Inventory Status
                                                                                    ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <span
                                                                                    class="text-color-black">{{inventoryTransferValue?.statusId}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                  
                                                                    <div class="col-lg-12" *ngIf="inventoryTransferValue?.statusId">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -8%;">
                                                                                <label for="inventoryId">Comments</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <span
                                                                                class="text-color-black">{{inventoryTransferValue?.comments}}</span>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: 0%;">
                                                                                <label for="inventoryId">Serial# or
                                                                                    ATP/QOH</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <span
                                                                                    class="text-color-black">{{inventoryTransferValue?.availableToPromiseTotal}}/{{inventoryTransferValue?.quantityOnHandTotal}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                 
                                                                    <div class="col-lg-12" *ngIf="preTransfer.productId">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -8%;">
                                                                                <label for="inventoryId">Product ID</label>
                                                                            </div>
                                                                            <div class="col-lg-2">
                                                                                <span
                                                                                class="text-color-black">{{preTransfer?.productId}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -8%;">
                                                                                <label for="transferStatus">Transfer Status</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <ng-select [items]="transferStatus"
                                                                                bindLabel="description" bindValue="statusId"
                                                                                formControlName="statusId"
                                                                                placeholder="Transfer Status"
                                                                                class="ng-select-width">
                                                                            </ng-select>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: 0%;">
                                                                                <label for="transferSentDate">Transfer Sent
                                                                                    Date</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <p-calendar formControlName="sendDate">
                                                                                </p-calendar>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                  
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -8%;">
                                                                                <label for="facilityId">Facility ID To</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <ng-select [items]="facilities"
                                                                                bindLabel="facilityName" class="ng-select-width"
                                                                                bindValue="facilityId"
                                                                                placeholder="Facility ID To"
                                                                                formControlName="facilityIdTo"></ng-select>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: 0%;">
                                                                                <label for="containerId">Container ID</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="text" class="form-control"
                                                                                placeholder="Container ID"
                                                                                formControlName="containerIdTo">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                  
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: -8%;">
                                                                                <label for="toLocations">To Locations</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <ng-select class="ng-select-width"
                                                                                [items]="location"
                                                                                formControlName="locationSeqIdTo"
                                                                                placeholder="To Locations"
                                                                                bindLabel="locationSeqId"
                                                                                bindValue="locationSeqId"></ng-select>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                            padding-right: 17px!important;
                                                                            margin-left: 0%;">
                                                                                <label for="comments">Comments</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="text" class="form-control"
                                                                                placeholder="Comments"
                                                                                formControlName="comments">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                   
                                                                    <div class="col-lg-12" *ngIf="!inventoryTransferId">
                                                                        <div class="row">
                                                                          
                                                                            <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                            padding-right: 17px!important;">
                                                                                <label for="quantityTo">Quantity To Transfer
                                                                                    <span class="text-danger">*</span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <input type="text" class="form-control"
                                                                                placeholder="Quantity To Transfer"
                                                                                formControlName="xferQty">
                                                                            <small class="validate-field"
                                                                                *ngIf="!!inventoryTransferForm.controls.xferQty.invalid && !!inventoryTransferForm.controls.xferQty.touched">
                                                                                Quantity To Transfer is required!</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
        
                                                             
                                                                </div>
                                                            </form>
                                                        </ng-container>
                                                        <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -24%;">
                                                            <button type="submit" class="btn btn-primary submit-btn"
                                                                (click)="transfer()">Transfer</button>
                                                            <button class="btn btn-danger cursor-pointer ml-2"
                                                                (click)="cancelSubmit()">Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
            
                    </div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="modal fade" id="inventoryModalCenter" tabindex="-1" role="dialog" aria-labelledby="inventoryModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1"> Create Inventory Transfer</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCard card-border" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li Class="active"><a>
                                    Create Inventory Transfer</a></li>
                            </ul>
                        </div>
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                        data-toggle="collapse" href="#collapsetwo">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <form>
                                                        <div class="row">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -5%;">
                                                                        <label for="inventoryItemId">Inventory Item
                                                                            ID</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="text" class="form-control"
                                                                            placeholder="Inventory Item ID"
                                                                            [(ngModel)]="preTransfer.inventoryItemId"
                                                                            [ngModelOptions]="{standalone:true}">
                                                                       
                                                                    </div>
                                                                    <div class="col-lg-3" style="    margin-top: -3%;">
                                                                       
                                                                        <button class="btn btn-primary cursor-pointer mt-4"
                                                                            (click)="readyWithInventoryId()">Submit</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                    padding-right: 17px!important;
                                                                    margin-left: -5%;">
                                                                        <label for="productId">Product ID</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <ng-select class="ng-select-width"
                                                                            [items]="products" bindLabel="internalName"
                                                                            [(ngModel)]="preTransfer.productId"
                                                                            [ngModelOptions]="{standalone:true}"
                                                                            placeholder="Product ID" bindValue="productId">
                                                                        </ng-select>
                                                                       
                                                                    </div>
                                                                    <div class="col-lg-3" style="    margin-top: -3%;">
                                                                       
                                                                        <button class="btn btn-primary cursor-pointer mt-4"
                                                                            (click)="readyWithProductId()">Submit</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                          
                                                          
                                                        </div>
                                                    </form>
                                                    <ng-container *ngIf="true">
                                                        <form [formGroup]="inventoryTransferForm" class="mt-5">
                                                            <div class="row">
                                                                <div class="col-lg-12" *ngIf="inventoryTransferValue?.statusId">
                                                                    <div class="row">
                                                                      
                                                                        <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -8%;">
                                                                            <label for="inventoryId">Inventory ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <span class="text-color-black">
                                                                                {{inventoryTransferValue?.statusId}}</span>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput"  style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: 0%;">
                                                                            <label for="inventoryId">Inventory Item Type
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <span
                                                                                class="text-color-black">{{inventoryTransferValue?.inventoryItemTypeId}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                              
                                                                <div class="col-lg-12" *ngIf="inventoryTransferValue?.statusId">
                                                                    <div class="row">
                                                                      
                                                                        <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -8%;">
                                                                            <label for="inventoryId">Product ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <span
                                                                                class="text-color-black">{{inventoryTransferValue?.productId}}</span>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: 0%;">
                                                                            <label for="inventoryId">Inventory Status
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <span
                                                                                class="text-color-black">{{inventoryTransferValue?.statusId}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                              
                                                                <div class="col-lg-12" *ngIf="inventoryTransferValue?.statusId">
                                                                    <div class="row">
                                                                      
                                                                        <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -8%;">
                                                                            <label for="inventoryId">Comments</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <span
                                                                            class="text-color-black">{{inventoryTransferValue?.comments}}</span>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: 0%;">
                                                                            <label for="inventoryId">Serial# or
                                                                                ATP/QOH</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <span
                                                                                class="text-color-black">{{inventoryTransferValue?.availableToPromiseTotal}}/{{inventoryTransferValue?.quantityOnHandTotal}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                             
                                                                <div class="col-lg-12" *ngIf="preTransfer.productId">
                                                                    <div class="row">
                                                                      
                                                                        <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -8%;">
                                                                            <label for="inventoryId">Product ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <span
                                                                            class="text-color-black">{{preTransfer?.productId}}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                      
                                                                        <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -8%;">
                                                                            <label for="transferStatus">Transfer Status</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <ng-select [items]="transferStatus"
                                                                            bindLabel="description" bindValue="statusId"
                                                                            formControlName="statusId"
                                                                            placeholder="Transfer Status"
                                                                            class="ng-select-width">
                                                                        </ng-select>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: 0%;">
                                                                            <label for="transferSentDate">Transfer Sent
                                                                                Date</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <p-calendar formControlName="sendDate">
                                                                            </p-calendar>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                              
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                      
                                                                        <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -8%;">
                                                                            <label for="facilityId">Facility ID To</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <ng-select [items]="facilities"
                                                                            bindLabel="facilityName" class="ng-select-width"
                                                                            bindValue="facilityId"
                                                                            placeholder="Facility ID To"
                                                                            formControlName="facilityIdTo"></ng-select>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: 0%;">
                                                                            <label for="containerId">Container ID</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="text" class="form-control"
                                                                            placeholder="Container ID"
                                                                            formControlName="containerIdTo">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                              
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                      
                                                                        <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -8%;">
                                                                            <label for="toLocations">To Locations</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <ng-select class="ng-select-width"
                                                                            [items]="location"
                                                                            formControlName="locationSeqIdTo"
                                                                            placeholder="To Locations"
                                                                            bindLabel="locationSeqId"
                                                                            bindValue="locationSeqId"></ng-select>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: 0%;">
                                                                            <label for="comments">Comments</label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="text" class="form-control"
                                                                            placeholder="Comments"
                                                                            formControlName="comments">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                               
                                                                <div class="col-lg-12" *ngIf="!inventoryTransferId">
                                                                    <div class="row">
                                                                      
                                                                        <div class="col-lg-2 form-group classInput" style="text-align: right!important;
                                                                        padding-right: 17px!important;">
                                                                            <label for="quantityTo">Quantity To Transfer
                                                                                <span class="text-danger">*</span>
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-3">
                                                                            <input type="text" class="form-control"
                                                                            placeholder="Quantity To Transfer"
                                                                            formControlName="xferQty">
                                                                        <small class="validate-field"
                                                                            *ngIf="!!inventoryTransferForm.controls.xferQty.invalid && !!inventoryTransferForm.controls.xferQty.touched">
                                                                            Quantity To Transfer is required!</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                         
                                                            </div>
                                                        </form>
                                                    </ng-container>
                                                    <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -24%;">
                                                        <button type="submit" class="btn btn-primary submit-btn"
                                                            (click)="transfer()">Transfer</button>
                                                        <button class="btn btn-danger cursor-pointer ml-2"
                                                            (click)="cancelSubmit()">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
		            </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<ngx-spinner></ngx-spinner>