import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoryModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        fromDate:(data && data.fromDate) ? data.fromDate : null,
        productStoreId:(data && data.productStoreId) ? data.productStoreId : null,
        sequenceNum:(data && data.sequenceNum) ? data.sequenceNum : null,
        thruDate:(data && data.thruDate) ? data.thruDate : null,
    });
    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   return ({
    fromDate: new FormControl(data.fromDate,[Validators.required]),
    productStoreId: new FormControl(data.productStoreId),
    sequenceNum: new FormControl(data.sequenceNum),
    thruDate: new FormControl(data.thruDate),
   

   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
