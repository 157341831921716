import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class MergeContactsService {

  constructor(readonly http: HttpClient) { }

  getContacts(contactDetails): Observable<any> {
    return this.http.get(URLS.contactList + "pageNo=" + contactDetails.pageNo + "&pageSize=" + contactDetails.pageSize + "&search=" + contactDetails.search);
  }
  findRequirement(formData):Observable<any>{
    const findRequirement = URLS.findRequirement;
    return this.http.post(findRequirement,formData);
  }
  submitMergeLead(formData):Observable<any>{
    const merge = URLS.mergeContacts;
    return this.http.post(merge,formData);
  }
  getRequirementPlanningById(id):Observable<any>{
    const getRequirementPlanningById = URLS.getRequirementPlanningById + "?requirementId=" + id;
    return this.http.get(getRequirementPlanningById); 
  }
   getMergeContacts(partyIdFrom,partyIdTo):Observable<any>{
     const getMergeContacts = URLS.getMergeContacts + "?partyIdFrom=" + partyIdFrom + "&partyIdTo=" + partyIdTo;
     return this.http.get(getMergeContacts); 
   }
   deletePartyFromCategory(formData): Observable<any> {
    const deletePartyFromCategory = URLS.deletePartyFromCategory  ;
    return this.http.post(deletePartyFromCategory,formData);
  }
  updateProdCatalogToParty(formData): Observable<any> {
    const updatePartyToCategory = URLS.updatePartyToCategory ;
    return this.http.post(updatePartyToCategory,formData);
  }
  createPartyToCategory(formData): Observable<any> {
    const addPartyToCategory = URLS.addPartyToCategory ;
    return this.http.post(addPartyToCategory,formData);
  }
  category_removeProductCategoryFromProdCatalog(formData): Observable<any> {
    const category_removeProductCategoryFromProdCatalog = URLS.category_removeProductCategoryFromProdCatalog ;
    return this.http.post(category_removeProductCategoryFromProdCatalog,formData);
  }
  category_updateProductCategoryToProdCatalog(formData): Observable<any> {
    const category_updateProductCategoryToProdCatalog = URLS.category_updateProductCategoryToProdCatalog ;
    return this.http.post(category_updateProductCategoryToProdCatalog,formData);
  }
  category_addProductCategoryToProdCatalog(formData): Observable<any> {
    const category_addProductCategoryToProdCatalog = URLS.category_addProductCategoryToProdCatalog ;
    return this.http.post(category_addProductCategoryToProdCatalog,formData);
  }
  getOpenRequirement(formData): Observable<any> {
    const getOpenRequirement = URLS.getOpenRequirement ;
    return this.http.post(getOpenRequirement,formData);
  }
  prodCatalogCategoryTypeId(): Observable<any> {
    const prodCatalogCategoryTypeId = URLS.prodCatalogCategoryTypeId ;
    return this.http.get(prodCatalogCategoryTypeId);
  }
  getApprovedTransferRequirements(): Observable<any> {
    const getApprovedTransferRequirements = URLS.getApprovedTransferRequirements ;
    return this.http.get(getApprovedTransferRequirements);
  }
  getAllProductCategory(): Observable<any> {
    const getAllProductCategory = URLS.getAllProductCategory ;
    return this.http.get(getAllProductCategory);
  }
  getApprovedProductRequirementsByVendor(): Observable<any> {
    const getApprovedProductRequirementsByVendor = URLS.getApprovedProductRequirementsByVendor ;
    return this.http.get(getApprovedProductRequirementsByVendor);
  }
  getApprovedInternalRequirements(): Observable<any> {
    const getApprovedInternalRequirements = URLS.getApprovedInternalRequirements ;
    return this.http.get(getApprovedInternalRequirements);
  }
  prodCatalogId(): Observable<any> {
    const prodCatalogId = URLS.prodCatalogId ;
    return this.http.get(prodCatalogId);
  }
  setPaymentStatus(id,statusId): Observable<any> {
    const setPaymentStatus = URLS.setPaymentStatus +"?paymentId=" + id +"&statusId="+statusId ;
    return this.http.post(setPaymentStatus,"");
  }
  EditCategoryProdCatalogs(id): Observable<any> {
    const EditCategoryProdCatalogs = URLS.EditCategoryProdCatalogs +"?productCategoryId=" + id  ;
    return this.http.get(EditCategoryProdCatalogs);
  }
  getInitialManager():Observable<any>{
    const getInitialManager = URLS.getInitialManager;
    return this.http.get(getInitialManager);
  }
  MrpEventType():Observable<any>{
    const MrpEventType = URLS.MrpEventType;
    return this.http.get(MrpEventType);
  }
  getPaymentDetailsById(paymentId): Observable<any> {
    const getPaymentsById = URLS.getPaymentsById.replace(":paymentId",paymentId);
    return this.http.get(getPaymentsById);
  }
  getPrimaryWarehouse():Observable<any>{
    const getPrimaryWarehouse = URLS.getPrimaryWarehouse;
    return this.http.get(getPrimaryWarehouse);
  }
  getPlanningWareHousesById(id):Observable<any>{
    const getPlanningWareHousesById = URLS.getPlanningWareHousesById +"?planningWareHouseId=" + id  ;
    return this.http.get(getPlanningWareHousesById);
  }
  getPrimaryWarehouseById(id):Observable<any>{
    const getPrimaryWarehouseById = URLS.getPrimaryWarehouseById +"?primaryWarehouse=" + id  ;
    return this.http.get(getPrimaryWarehouseById);
  }
  getProductCategoryId(id):Observable<any>{
    const getProductCategoryId = URLS.getProductCategoryId.replace(":productCategoryId",id);
    return this.http.get(getProductCategoryId);
  }
  createPlanningWareHousesExisting(formData):Observable<any>{
    const createPlanningWareHousesExisting = URLS.createPlanningWareHousesExisting;
    return this.http.post(createPlanningWareHousesExisting,formData);
  }
  UpdatePlanningWareHousesExisting(formData):Observable<any>{
    const UpdatePlanningWareHousesExisting = URLS.UpdatePlanningWareHousesExisting;
    return this.http.post(UpdatePlanningWareHousesExisting,formData);
  }
  deletePlanningWareHousesExisting(formData):Observable<any>{
    const deletePlanningWareHousesExisting = URLS.deletePlanningWareHousesExisting;
    return this.http.post(deletePlanningWareHousesExisting,formData);
  }
    createPlanningWareHouse(formData):Observable<any>{
    const createPlanningWareHouse = URLS.createPlanningWareHouse;
    return this.http.post(createPlanningWareHouse,formData);
  }
  createRunMrp(formData):Observable<any>{
    const createRunMrp = URLS.createRunMrp;
    return this.http.post(createRunMrp,formData);
  }
  runMrp(formData):Observable<any>{
    const runMrp = URLS.runMrp;
    return this.http.post(runMrp,formData);
  }
  deletePlanningWareHouse(formData):Observable<any>{
    const deletePlanningWareHouse = URLS.deletePlanningWareHouse;
    return this.http.post(deletePlanningWareHouse,formData);
  }
  UpdatePlanningWareHouse(formData):Observable<any>{
    const UpdatePlanningWareHouse = URLS.UpdatePlanningWareHouse;
    return this.http.post(UpdatePlanningWareHouse,formData);
  }
  getviewMRPList():Observable<any>{
    const getviewMRPList = URLS.getviewMRPList ;
    return this.http.get(getviewMRPList);
  }
  viewMRP(id,mrpEventTypeId,productId,productStore,runMrpId):Observable<any>{
    const viewMRP = URLS.viewMRP +"?facilityId=" + id +"&mrpEventTypeId=" + mrpEventTypeId +"&productId=" + productId +"&productStore=" + productStore 
    +"&runMrpId=" + runMrpId  ;
    return this.http.get(viewMRP);
  }
  getPlanningWareHousesExistingAll():Observable<any>{
    const getPlanningWareHousesExistingAll = URLS.getPlanningWareHousesExistingAll;
    return this.http.get(getPlanningWareHousesExistingAll);
  }
  getPlanningWareHousesExistingById(id):Observable<any>{
    const getPlanningWareHousesExistingById = URLS.getPlanningWareHousesExistingById 
    +"?planningWareHouseExistingId=" + id  ;
    return this.http.get(getPlanningWareHousesExistingById);
  }
  getPlanningWareHousesExistingSummaryPage(id):Observable<any>{
    const getPlanningWareHousesExistingSummaryPage = URLS.getPlanningWareHousesExistingSummaryPage +"?primaryWarehouse=" + id  ;
    return this.http.get(getPlanningWareHousesExistingSummaryPage);
  }
  getFacilityIdTo(id):Observable<any>{
    const getFacilityIdTo = URLS.getFacilityIdTo +"?facilityId=" + id  ;
    return this.http.get(getFacilityIdTo);
  }
}
