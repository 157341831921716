import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerAgreementsService } from '../customer-agreements/customer-agreements.service';
import { NewWarehouseShipmentService } from 'src/app/warehouse/new-warehouse-shipment/new-warehouse-shipment.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-create-agrrement-terms',
  templateUrl: './create-agrrement-terms.component.html',
  styleUrls: ['./create-agrrement-terms.component.css']
})
export class CreateAgrrementTermsComponent implements OnInit {

  TermTypeIdList: any = [];
  activeCategory = 2;
  InvoiceItemTypeList: any = [];
  editMode: boolean;
  AgreementsTerms: FormGroup;
  agreementId: any;
  agreementType: string;
  agreementTermId: string;
  agreementTerm: any;
  constructor(
    readonly _ActivatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly CustomerAgreementsService: CustomerAgreementsService,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder) {
    this.AgreementsTerms = this._FormBuilder.group({
      agreementDate: [''],
      agreementId: [''],
      agreementItemSeqId: [''],
      description: [''],
      fromDate: [''],
      invoiceItemTypeId: [''],
      maxQuantity: [''],
      minQuantity: [''],
      termDays: [''],
      termTypeId: ['', [Validators.required]],
      termValue: [''],
      textValue: [''],
      thruDate: ['']
    });
    this.editMode = false;
    this.agreementType = '';
    this.agreementTermId = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.agreementId = this._ActivatedRoute.snapshot.queryParams.agreementID;
    this.agreementTermId = this._ActivatedRoute.snapshot.queryParams.agreementTermId;
    this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    if (this.agreementTermId) {
      this.editMode = true;
      this.CustomerAgreementsService.getAgreementTermById(this.agreementId, this.agreementTermId)
        .then(data => {
          this.agreementTerm = data.data;
          this.AgreementsTerms.patchValue({
            ...data.data, ...{
              fromDate: data.data.fromDate ? new Date(data.data.fromDate) : '',
              thruDate: data.data.thruDate ? new Date(data.data.thruDate) : ''
            }
          });
        });
    }
    this.getTermTypeIdList();
    this.getInvoiceItemTypeList();
  }

  getTermTypeIdList() {
    this.spinner.show();
    this.CustomerAgreementsService.getTermTypeIdList().subscribe((responce) => {
      this.spinner.hide();
      this.TermTypeIdList = responce.data.map(value => {
        return {
          label: value.description,
          value: value.termTypeId
        };
      });
    });
   
  }

  getInvoiceItemTypeList() {
    this.spinner.show();
    this.CustomerAgreementsService.getInvoiceItemTypeList().subscribe((responce) => {
      this.spinner.hide();
      this.InvoiceItemTypeList = responce.data.map(value => {
        return {
          label: value.description,
          value: value.invoiceItemTypeId
        };
      });
    });
   
  }
  reset(): void {
    this.AgreementsTerms.reset();
    this.router.navigate([`/financial/${this.agreementType}/customer-agreeement/summary-customer-agreeement`], { queryParams: { agreementID: this.agreementId } });

  }
  update(): void {
    this.spinner.show();
    this.CustomerAgreementsService.updateAgreementTerm(this.agreementTermId, {
      ...this.agreementTerm, ...this.AgreementsTerms.value, ...{

        fromDate: this.AgreementsTerms.value.formDate ? moment(this.AgreementsTerms.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
        thruDate: this.AgreementsTerms.value.thruDate ? moment(this.AgreementsTerms.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Updated');
          this.spinner.hide();
          this.AgreementsTerms.reset();
          this.router.navigate([`/financial/${this.agreementType}/customer-agreeement/summary-customer-agreeement`], { queryParams: { agreementID: this.agreementId } });

        }
      });
     
  }
  submit(): void {
    this.spinner.show();
    if (this.editMode) {
      this.update();
    } else {
      this.CustomerAgreementsService.createAgreementTerm({
        ...this.AgreementsTerms.value, ...{
          fromDate: this.AgreementsTerms.value.formDate ? moment(this.AgreementsTerms.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
          thruDate: this.AgreementsTerms.value.thruDate ? moment(this.AgreementsTerms.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : '',
          agreementId: this.agreementId
        }
      }).subscribe((res: any) => {
     
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.AgreementsTerms.reset();
          this.router.navigate([`/financial/${this.agreementType}/customer-agreeement/summary-customer-agreeement`], { queryParams: { agreementID: this.agreementId } });

        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
  
    }
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }

}
