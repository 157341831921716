<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" routerLink="/facilities/catalog/products">Product </span>
                <span class="color-black pl-1"> > {{editMode ? 'Update': 'Create'}} New</span>
               
                <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">

                        <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                Product</a></li>
                        <li [ngClass]="activeCategory==3?'active':''" (click)="wordingValid(createEditProductForm.value.productTypeId)"><a>
                                Wording And Comment</a></li>

                        <li [ngClass]="activeCategory==4?'active':''" (click)="virtualValid(createEditProductForm.value.internalName)"><a>
                                Virtual Product </a></li>
                        <li [ngClass]="activeCategory==5?'active':''"  (click)="primaryValid(createEditProductForm.value.virtualVariantMethodEnum)"><a>
                                Primary Category </a></li>
                        <li [ngClass]="activeCategory==6?'active':''" (click)="dateValid(createEditProductForm.value.primaryProductCategoryId)"><a>
                                Dates </a></li>
                        <li [ngClass]="activeCategory==7?'active':''" (click)="inventoryValid(createEditProductForm.value.primaryProductCategoryId)"><a>
                                Inventory </a></li>
                        <li [ngClass]="activeCategory==8?'active':''" (click)="ratingValid(createEditProductForm.value.inventoryItemTypeId)"><a>
                                Rating </a></li>
                        <li [ngClass]="activeCategory==9?'active':''" (click)="amountValid(createEditProductForm.value.ratingTypeEnum)"><a>
                                Amount </a></li>
                        <li [ngClass]="activeCategory==10?'active':''" (click)="measureValid(createEditProductForm.value.amountUomTypeId)"><a>
                                Measures </a></li>
                        <li [ngClass]="activeCategory==11?'active':''" (click)="shipingValid(createEditProductForm.value.heightUomId,createEditProductForm.value.widthUomId)"><a>
                                Shipping </a></li>
                        <li [ngClass]="activeCategory==12?'active':''" (click)="shopingValid(createEditProductForm.value.defaultShipmentBoxTypeId)"><a>
                                ShoppingCart </a></li>
                        <li [ngClass]="activeCategory==13?'active':''" (click)="shopingCartValid(createEditProductForm.value.defaultShipmentBoxTypeId)"><a>
                                Miscellaneous </a></li>



                    </ul>
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="createEditProductForm">
                                <div *ngIf="activeCategory==2">
                                    <div class="header-tabs tabPaa">
                                        <h4 class=" common-styling">
                                            Product
                                        </h4>


                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">
                                                            Product ID</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Product ID"
                                                        formControlName="productId">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Product Type <span
                                                            style="color:red">*</span></label>

                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="productMenu.productType"
                                                            appendTo="body" optionlabel="description"
                                                            placeholder="Select Product Type"
                                                            formControlName="productTypeId">
                                                        </p-dropdown>
                                                        <small class="text-danger"
                                                            *ngIf="!!createEditProductForm.controls.productTypeId.invalid && !!createEditProductForm.controls.productTypeId.touched">Required</small>
                                                    </div>
                                                </div>
                                            </div>
                                          

                                        </div>
                                        <div class="main-submit-button" style="margin-right: 32%;">
                                            <button type="submit"
                                                (click)="selectProduct(createEditProductForm.value.productTypeId)"
                                                class="btn btn-secondary submit-btn">Continue</button>

                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="activeCategory==3">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling headRight">
                                            Wording And Comment
                                        </h4>


                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">
                                                            Internal Name <span style="color:red">*</span></label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Internal Name"
                                                        formControlName="internalName">
                                                    <small class='text-danger'
                                                        *ngIf="!!createEditProductForm.controls.internalName.invalid && !! createEditProductForm.controls.internalName.touched">Required</small>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Brand Name</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Brand Name"
                                                        formControlName="brandName">
                                                    </div>

                                                </div>
                                            </div>
                                        
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">Product Name</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Product Name"
                                                        formControlName="productName">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Comments </label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Comments"
                                                        formControlName="comments">
                                                    </div>
                                                </div>
                                            </div>
                                           
                                         
                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 29%;">
                                            <button type="submit"
                                                (click)="selectworking(createEditProductForm.value.internalName)"
                                                class="btn btn-secondary submit-btn">Continue</button>

                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="activeCategory==4">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling headRight">
                                            Virtual Product
                                        </h4>


                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">
                                                            Is VIRTUAL Product ?</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="interrogativeArray"
                                                            appendTo="body" optionlabel="label"
                                                            placeholder="Is Virtual Product"
                                                            formControlName="isVirtual">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">
                                                            Is VARIANT Product ?</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="interrogativeArray"
                                                            appendTo="body" optionlabel="label"
                                                            placeholder="Is Variant Product"
                                                            formControlName="isVariant"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Virtual Variant
                                                            Method<span style="color:red">*</span></label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            [options]="productMenu.virtualVariantMethod" appendTo="body"
                                                            optionlabel="description"
                                                            placeholder="Select Virtual Variant Method"
                                                            formControlName="virtualVariantMethodEnum">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                      
                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 25%;">
                                            <button type="submit"
                                                (click)="selectvirtual(createEditProductForm.value.virtualVariantMethodEnum)"
                                                class="btn btn-secondary submit-btn">Continue</button>

                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="activeCategory==5">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling headRight">
                                            Primary Category
                                        </h4>


                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">
                                                            Primary Category<span style="color:red">*</span></label>
                                                       
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="categories" appendTo="body"
                                                            optionlabel="categoryName"
                                                            placeholder="Select Primary Category"
                                                            formControlName="primaryProductCategoryId">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                       
                                        </div>
                                        <div class="main-submit-button" style="margin-right: 69%;">
                                            <button type="submit"
                                                (click)="selectPrimary(createEditProductForm.value.primaryProductCategoryId)"
                                                class="btn btn-secondary submit-btn">Continue</button>

                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="activeCategory==6">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling headRight">
                                            Dates
                                        </h4>


                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputGI">
                                                        <label for="exampleInputEmail1">
                                                            Introduction Date</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-calendar placeholder="Select Date"
                                                            formControlName="introductionDate">
                                                        </p-calendar>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Release
                                                            Date</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-calendar formControlName="releaseDate"
                                                            placeholder="Select Date">
                                                        </p-calendar>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputGI">
                                                        <label for="exampleInputEmail1">Sales
                                                            Discontinuation Through Date</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-calendar formControlName="salesDiscontinuationDate"
                                                            placeholder="Select Date">
                                                        </p-calendar>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Support
                                                            Discontinuation Date</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-calendar formControlName="supportDiscontinuationDate"
                                                            placeholder="Select Date">
                                                        </p-calendar>
                                                    </div>
                                                </div>
                                            </div>
                                       
                                      
                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 17%;">
                                            <button type="submit" (click)="selectDate()"
                                                class="btn btn-secondary submit-btn">Continue</button>

                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="activeCategory==7">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling headRight">
                                            Inventory
                                        </h4>


                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Disc. When Inv. Not
                                                            Avail?</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="interrogativeArray"
                                                            appendTo="body" optionlabel="label" placeholder="Select "
                                                            formControlName="salesDiscWhenNotAvail">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Requirement Method
                                                            Enum ID</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            [options]="productMenu.requirementMethodEnumID"
                                                            appendTo="body" optionlabel="description"
                                                            placeholder="Select Enum ID"
                                                            formControlName="requirementMethodEnumId">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Inventory
                                                            Message</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Inventory Message" formControlName="inventoryMessage">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">InventoryItem Type
                                                            ID<span style="color:red">*</span></label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            [options]="productMenu.inventoryItemTypeID" appendTo="body"
                                                            optionlabel="description"
                                                            placeholder="Select Inventory Item Type Id"
                                                            formControlName="inventoryItemTypeId">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Require
                                                            Inventory</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="interrogativeArray"
                                                            appendTo="body" optionlabel="label" placeholder="Select "
                                                            formControlName="requireInventory">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Lot ID</label>
                                                   
                                                       
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="commandingArray"
                                                        appendTo="body" optionlabel="label"
                                                        placeholder="Select Lot Id" formControlName="lotIdFilledIn">
                                                    </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                          
                                       
                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 22%;">
                                            <button type="submit" (click)="selecInventory(createEditProductForm.value.inventoryItemTypeId)"
                                                class="btn btn-secondary submit-btn">Continue</button>

                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="activeCategory==8">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling headRight">
                                            Rating
                                        </h4>


                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">
                                                            Rating Type Enum<span style="color:red">*</span></label>
    
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="productMenu.ratingTypeEnum"
                                                            appendTo="body" optionlabel="description"
                                                            placeholder="Select Rating Type Enum"
                                                            formControlName="ratingTypeEnum">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Rating</label>
    
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Rating"
                                                        formControlName="productRating">
                                                    </div>
                                                </div>
                                            </div>
                                          
                                         
                                        </div>
                                        <div class="main-submit-button" style="margin-right: 27%;">
                                            <button type="submit" (click)="selectRating(createEditProductForm.value.ratingTypeEnum)"
                                                class="btn btn-secondary submit-btn">Continue</button>

                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="activeCategory==9">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling headRight">
                                            Amount
                                        </h4>


                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">
                                                            Require Amount</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="interrogativeArray"
                                                            appendTo="body" optionlabel="label" placeholder="Select "
                                                            formControlName="requireAmount">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Amount Uom Type
                                                            ID<span style="color:red">*</span></label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            [options]="productMenu.amountUomTypeID" appendTo="body"
                                                            optionlabel="description"
                                                            placeholder="Select Amount Uom Type Id"
                                                            formControlName="amountUomTypeId">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                         

                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 29%;">
                                            <button type="submit" (click)="selectAmount(createEditProductForm.value.amountUomTypeId)"
                                                class="btn btn-secondary submit-btn">Continue</button>

                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="activeCategory==10">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling headRight">
                                            Measures
                                        </h4>


                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Product
                                                            Height</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Product Height"
                                                        formControlName="productHeight">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Height Uom
                                                            ID<span style="color:red">*</span></label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="productMenu.measuresUOMID"
                                                        appendTo="body" optionlabel="description"
                                                        placeholder="Select Height Uom ID"
                                                        formControlName="heightUomId">
                                                    </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Shipping
                                                            Height</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Shipping Height"
                                                        formControlName="shippingHeight">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Product
                                                            Width</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Product Width"
                                                        formControlName="productWeight">
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Width Uom ID<span style="color:red">*</span></label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="productMenu.measuresUOMID"
                                                            appendTo="body" optionlabel="description"
                                                            placeholder="Select Width Uom ID"
                                                            formControlName="widthUomId">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Shipping
                                                            Width</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Shipping Width"
                                                        formControlName="shippingWidth">
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Product
                                                            Depth</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Product Depth"
                                                        formControlName="productDepth">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Depth Uom ID</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="productMenu.measuresUOMID"
                                                            appendTo="body" optionlabel="description"
                                                            placeholder="Select Depth Uom ID"
                                                            formControlName="depthUomId">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Shipping
                                                            Depth</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Shipping Depth"
                                                        formControlName="shippingDepth">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Product
                                                            Diameter</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Product Diameter"
                                                        formControlName="productDiameter">
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Diameter Uom
                                                            ID</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="productMenu.measuresUOMID"
                                                        appendTo="body" optionlabel="description"
                                                        placeholder="Select Diameter Uom ID"
                                                        formControlName="diameterUomId">
                                                    </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Product
                                                            Weight</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Product Weight"
                                                        formControlName="productWeight">
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Weight Uom
                                                            ID</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="productMenu.weightUomID"
                                                            appendTo="body" optionlabel="description"
                                                            placeholder="Select Weight Uom ID"
                                                            formControlName="weightUomId">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Shipping
                                                            Weight</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Shipping Weight"
                                                        formControlName="shippingWeight">
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Quantity
                                                            Included</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp"
                                                        placeholder="Enter Quantity Included"
                                                        formControlName="quantityIncluded">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Quality Uom
                                                            ID</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="productMenu.quantityUomID"
                                                            appendTo="body" optionlabel="description"
                                                            placeholder="Select Quality Uom ID"
                                                            formControlName="quantityUomId">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                         
                                            <div class="col-lg-6 col-12">
                                                <label for="exampleInputEmail1"></label>
                                                <div class="main-submit-button" style="    margin-right: -54%;">
                                                    <button type="submit" (click)="selectMeasure(createEditProductForm.value.heightUomId,createEditProductForm.value.widthUomId)"
                                                        class="btn btn-secondary submit-btn">Continue</button>
        
                                                </div>
                                            </div>
                                          
                                        </div>
                                      
                                    </div>

                                </div>
                                <div *ngIf="activeCategory==11">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling headRight">
                                            Shipping
                                        </h4>


                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Pieces
                                                            Included</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" placeholder="Enter Pieces
                                                    Included" formControlName="piecesIncluded">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">In Shipping Box
                                                            ?</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="interrogativeArray"
                                                        appendTo="body" optionlabel="label" placeholder="Select "
                                                        formControlName="inShippingBox">
                                                    </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputG">
                                                        <label for="exampleInputEmail1">Default Shipment Box
                                                            Type ID<span style="color:red">*</span></label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                        [options]="productMenu.defaultShipmentBoxTypeID"
                                                        appendTo="body" optionlabel="description"
                                                        optionValue="shipmentBoxTypeId"
                                                        placeholder="Select Shipment"
                                                        formControlName="defaultShipmentBoxTypeId">
                                                    </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Charge
                                                            Shipping</label>
                                                       
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="interrogativeArray"
                                                            appendTo="body" optionlabel="label"
                                                            placeholder="Select Charge Shipping"
                                                            formControlName="chargeShipping">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                      
                                        </div>
                                        <div class="main-submit-button" style="    margin-right: 19%;">
                                            <button type="submit" (click)="selectShiping(createEditProductForm.value.defaultShipmentBoxTypeId)"
                                                class="btn btn-secondary submit-btn">Continue</button>

                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="activeCategory==12">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling headRight">
                                            Shopping Cart
                                        </h4>


                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInputH">
                                                        <label for="exampleInputEmail1">
                                                            Allow order decimal quantity</label>
                                                       
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="interrogativeArray"
                                                        appendTo="body" optionlabel="label"
                                                        placeholder="Select Quantity"
                                                        formControlName="orderDecimalQuantity">
                                                    </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                         
                                        </div>
                                        <div class="main-submit-button" style="margin-right: 63%;">
                                            <button type="submit" (click)="selectShopingCart()"
                                                class="btn btn-secondary submit-btn">Continue</button>

                                        </div>
                                    </div>

                                </div>
                                <div *ngIf="activeCategory==13">
                                    <div class="header-tabs">
                                        <h4 class=" common-styling headRight">
                                            Miscellaneous
                                        </h4>


                                    </div>
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Returnable</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="interrogativeArray"
                                                        appendTo="body" optionlabel="label" placeholder="Select "
                                                        formControlName="returnable">
                                                    </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Include In
                                                            Promotions</label>
                                                       
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="interrogativeArray"
                                                        appendTo="body" optionlabel="label" placeholder="Select "
                                                        formControlName="includeInPromotions">
                                                    </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Taxable</label>
                                                       
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="interrogativeArray"
                                                        appendTo="body" optionlabel="label" placeholder="Select "
                                                        formControlName="taxable">
                                                    </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="keyword">AutoCreate Keywords</label>
                                                       
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="interrogativeArray"
                                                        appendTo="body" optionlabel="label" placeholder="Select "
                                                        formControlName="autoCreateKeywords">
                                                    </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12" *ngIf="editMode">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="createdBy">Created By
                                                        </label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                            [value]="product.createdByUserLogin" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="lastModifiedBy">Last Modified By
                                                        </label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                            [value]="product.lastModifiedByUser" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                           
                                        
                                        </div>
                                        <div class="main-submit-button " style="margin-right: 32%;">
                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="submitProduct()">{{editMode? 'Update' : 'Create'}}
                                                </button>

                                        </div>
                                    </div>

                                </div>


                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>