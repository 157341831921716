<div class="container-fluid main-container-wrapper">

    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels">Subscriptions</span>
                <span class="">
 
                    <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>
 
                    <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Back</button>
                </span>
       
            </div>
            <div class=" bg-white color-grey">
                <div class="mb-5">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="display: none !important;padding-left: 9px; width: calc(103% - 5px); flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCat==2?'active':''" (click)="catalogOpen()">
                                <a>Catalogs</a>
                            </li>
                            <li [ngClass]="activeCat==3?'active':''" (click)="categoriesOpen()">
                                <a>Categories</a>
                            </li>
                            <li [ngClass]="activeCat==4?'active':''" (click)="productsOpen()">
                                <a>Products</a>
                            </li>
                            <li [ngClass]="activeCat==5?'active':''" (click)="featuresOpen()">
                                <a>Features</a>
                            </li>
                            <li [ngClass]="activeCat==6?'active':''" (click)="promosOpen()">
                                <a>Promos</a>
                            </li>
                            <li [ngClass]="activeCat==7?'active':''" (click)="priceRulesOpen()">
                                <a>Price Rules</a>
                            </li>
                            <li [ngClass]="activeCat==8?'active':''" (click)="storesOpen()">
                                <a>Stores</a>
                            </li>
                            <li [ngClass]="activeCat==9?'active':''" (click)="productStoreGroupsOpen()">
                                <a>Product Store Groups</a>
                            </li>
                            <li [ngClass]="activeCat==10?'active':''" (click)="thesaurusOpen()">
                                <a>Thesaurus</a>
                            </li>
                            <li [ngClass]="activeCat==11?'active':''" (click)="reviewsOpen()">
                                <a>Reviews</a>
                            </li>
                            <li [ngClass]="activeCat==12?'active':''" (click)="configurationsOpen()">
                                <a>Configurations</a>
                            </li>
                            <li [ngClass]="activeCat==13?'active':''" (click)="subscriptionsOpen()">
                                <a>Subscriptions</a>
                            </li>
                            <li [ngClass]="activeCat==14?'active':''" (click)="shippingOpen()">
                                <a>Shipping</a>
                            </li>
                            <li [ngClass]="activeCat==15?'active':''" (click)="imageManagementOpen()">
                                <a>Image Management</a>
                            </li>
                        </ul>
                    </div>
                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                        <div class="create-new-leade mt-2">
                            <div class="">
                                <div class="">
                                    <form class="w-100" >

                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                            <!-- <span class="headingAccords"> Search Option</span> -->
                                                            <button *ngIf="!this.Hidebtn" type="button" class="btn btn-outline-secondary active marginHeader" data-toggle="modal" data-target="#newpopup">Create New Catalog</button>

                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 77%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>									
                
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Subscription ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="text" class="form-control" name="subscriptionID" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.subscriptionID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Subscription ID">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Description
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="text" class="form-control" name="description" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Subscription Resource ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <p-dropdown filter="true" name="subscriptionResourceID" [options]="subscriptionResourceIdArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.subscriptionResourceID" optionlabel="label" placeholder="Enter Subscription Resource ID">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Party ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <p-dropdown filter="true" name="partyID" [options]="partyIdArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.partyID" optionlabel="label" placeholder="Enter Party ID">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Order ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <p-dropdown filter="true" name="orderID" [options]="orderByIdArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.orderID" optionlabel="label" placeholder="Enter Order ID">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Order Item Seq. ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="text" class="form-control" name="orderItemSeqID" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.orderItemSeqID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Order Item Seq. ID">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Product ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <p-dropdown filter="true" name="productID" [options]="ProductIdArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.productID" optionlabel="label" placeholder="Enter Product ID">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Subscription Type
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <p-dropdown filter="true" name="subscriptionType" [options]="subscriptionTypeArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.subscriptionType" optionlabel="label" placeholder="Enter Subscription Type">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">External Subscription ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="text" class="form-control" name="externalSubscriptionID" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.externalSubscriptionID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External Subscription ID">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">From Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" name="fromDate" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Through Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" name="throughDate" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Automatic Extend
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <p-dropdown filter="true" name="automaticExtend" [options]="yesNoArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.automaticExtend" optionlabel="label" placeholder="Enter Automatic Extend">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Grace Period on Expiry
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="text" class="form-control" name="gracePeriodExpiry" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.gracePeriodExpiry" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Grace Period on Expiry">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Grace Period on Expiry UOM ID
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-lg-2 form-group">
                                                                            <input type="text" class="form-control" name="gracePeriodExpiryUomID" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.gracePeriodExpiryUomID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Grace Period on Expiry UOM ID">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Expiration Completion  Date</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" name="expirationCompletionDate" [ngModelOptions]="{standalone:true}" [(ngModel)]="findSubscription.expirationCompletionDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Expiration Completion Date">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
    
                                                            <div class="col-lg-10 main-submit-button">
                                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="find()">Find</button>&nbsp;
                                                                <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                                            </div>
    
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                                    <!-- <div class="w3-card-4 classCardView mt-2 w-100">
                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                            Search Options
                                        </div>
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" name="productID" [ngModelOptions]="{standalone:true}" [(ngModel)]="findReviews.productID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Product ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Status
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" name="status" [options]="reviewStatusArray" [ngModelOptions]="{standalone:true}" [(ngModel)]="findReviews.status" optionlabel="label" placeholder="Enter Status">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Reviews
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" name="reviews" [ngModelOptions]="{standalone:true}" [(ngModel)]="findReviews.reviews" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Reviews">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-8 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)=" getFIndReview()">Find</button>&nbsp;
                                            <button type="button" class="btn btn-danger ml-2" (click)="reset()">Reset</button>
                                        </div>

                                    </div> -->
                                    <br>
                                        
                                    <div class="w3-card-4 classCardView mt-2">
                                        <div class="header-tabs" >
                                            <h4 class="common-styling h4Margin">
                                                Search Results
                                            </h4>
                                        </div>

                                        <div class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">

                                                    <div class="card own-account-table">
                                                        <p-table [value]="findSubscriptionList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                        <div class="checkbox-align" style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>

                                                                            Subscription ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Description
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Subscription Resource ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Party ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Order ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Order Item Seq. ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Product ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Subscription Type
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            External Subscription ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            From Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Through Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'500px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            The Subscription will be extended at the end of the subscription period with a new order
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Grace Period on Expiry
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Grace Period on Expiry UOM ID
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}" pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Expiration Completion Date
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                    </th>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span  class="account-button"   (click)="detailSubsPage(product.node1.subscriptionId)">
                                                                            {{product.node1.subscriptionId}} </span>

                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{product.node1.description}}

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}" (click)="detailPage(product.node1.subscriptionResourceId)"> {{product.node1.subscriptionResourceId}}

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{product.node1.partyId}}

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{product.node1.orderId}}

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{product.node1.orderItemSeqId}}

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{product.node1.productId}}

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{product.node1.subscriptionTypeId}}

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{product.node1.externalSubscriptionId}}

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{product.node1.fromDate| date:"yyyy-MM-dd hh:mm:ss"}}

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{product.node1.thruDate | date:"yyyy-MM-dd hh:mm:ss"}}

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{product.node1.gracePeriodOnExpiry}}

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{product.node1.gracePeriodOnExpiryUomId}}

                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}"> {{product.node1.expirationCompletedDate| date:"yyyy-MM-dd hh:mm:ss"}}

                                                                    </td>
                                                                </tr>
                                                            </ng-template>

                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                    <h4
                                                                        class="ml-2">
                                                                        No Record
                                                                        Found</h4>
                                                                </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>



<div class="modal fade" id="newpopup" tabindex="-1" role="dialog" aria-labelledby="newpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> New Subscription</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>New Subscription</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newSubscription">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Subscription Resource ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="subsResourceID" [options]="subscriptionResourceIdArray" optionlabel="label" placeholder="Enter Subscription Resource ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Communication Event ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="commEventID" [options]="CommunicationEventIdArray" optionlabel="label" placeholder="Enter Communication Event ID">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Contact Mech ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="contactMechID" [options]="ContactMechIdArray" optionlabel="label" placeholder="Enter Contact Mech ID">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Originated From Party ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="originPartyID" [options]="partyIdArray" optionlabel="label" placeholder="Enter Originated From Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Originated From Role Type ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="originRoleTypeID" [options]="OriginatedFromRoleTypeIdArray" optionlabel="label" placeholder="Enter Originated From Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="partyID" [options]="partyIdArray" optionlabel="label" placeholder="Enter Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Role Type ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="roleTypeID" [options]="RoleTypeIdArray" optionlabel="label" placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party Need ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="partyNeedID" [options]="partyIdArray" optionlabel="label" placeholder="Enter Party Need ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Need Type ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="needTypeID" optionlabel="label" placeholder="Enter Need Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Order ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="orderID" [options]="orderByIdArray" optionlabel="label" placeholder="Enter Order ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Order Item Seq ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="orderItemSeqID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Order Item Seq ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="productID" [options]="ProductIdArray" optionlabel="label" placeholder="Enter Product ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Category ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="productCategoryID" [options]="productCategoryArray" optionlabel="label" placeholder="Enter Product Category ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Inventory Item ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="inventoryItemID" [options]="inventoryItemIdArray" optionlabel="label" placeholder="Enter Inventory Item ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Subscription Type
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="subsType" [options]="subscriptionTypeArray" optionlabel="label" placeholder="Enter Subscription Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">External Subscription ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="extSubsID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External Subscription ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Purchase From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="purchaseFromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Purchase Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="purchaseThroughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Max Life Time
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="maxLifeTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Max Life Time">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Max Life Time UOM ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="maxUomID" [options]="currencyArray" optionlabel="label" placeholder="Enter Max Life Time UOM ID">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Available Time
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="availTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Available Time">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">

                                                        <label for="exampleInputEmail1">Available Time UOM ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="availUomID" [options]="currencyArray" optionlabel="label" placeholder="Enter Available Time UOM ID">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Count Limit
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="countLimit" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Count Limit">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Time
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="useTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Time">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Time UOM
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="useTimeUom" [options]="useTimeUomArray" optionlabel="label" placeholder="Enter Use Time UOM">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Automatic Extend
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="autoExtend" [options]="yesNoArray" optionlabel="label" placeholder="Enter Automatic Extend">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Cancel Time
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="cancelTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Cancel Time">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Cancel UOM Time
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="cancelUomTime" [options]="useTimeUomArray" optionlabel="label" placeholder="Enter Cancel UOM Time">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Grace Period on Expiry
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="gracePeriodExpiry" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Grace Period on Expiry">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Grace Period UOM ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="gracePeriodUomID" [options]="useTimeUomArray" optionlabel="label" placeholder="Enter Grace Period UOM ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Expiration Completed Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="expCompletedDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Expiration Completed Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="createSubscription()">Create</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner>