<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div>
          <div class="container-fluid">
            <div class="row">
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title">
                          <a role="button" class="port-header tabs-header collapsed" data-toggle="collapse"
                            href="#collapseOne">
                            Promotion Details
                            <span class="detail-created"></span>
                          </a>
                        </h4>
                      </div>
                      <div id="collapseOne" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="mt-2">
                            <div class="">
                              <div class="row">

                                <div class="col-lg-12 col-12 details-offer">
                                  <p>{{promotionsDetail?.productPromo.promoText}}
                                  </p>
                                </div>
                                <div class="col-lg-12 col-12 details-offer">
                                  <p>{{promotionsDetail?.productPromo.promoText}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title">
                          <a role="button" class="port-header tabs-header collapsed" data-toggle="collapse"
                            href="#collapsecategories">
                            Promotion Categories
                            <span class="detail-created"></span>
                          </a>
                        </h4>
                      </div>
                      <div id="collapsecategories" class="panel-collapse collapse">
                        <div class="panel-body">
                          <div class="mt-2">
                            <div class="">
                              <div class="row">
                                <div class="col-lg-12 col-12 details-offer">
                                  <p>Promotion Products In Categories:
                                  </p>
                                  <ng-template ngFor let-promo
                                    [ngForOf]="promotionsDetail?.productPromoCategoryIncludeList" let-i="index">
                                    <a  class="account-button" >{{promo.productCategoryId}}</a>
                                  </ng-template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 2 -->
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div class="panel-heading" role="tab" id="headingOne">
                        <h4 class="panel-title">
                          <a role="button" class="port-header tabs-header collapsed" data-toggle="collapse"
                            href="#collapsetwo">
                            Products For Promotion
                            <span class="detail-created"></span>
                          </a>
                        </h4>
                      </div>
                      <div id="collapsetwo" class="panel-collapse collapse">
                        <div class="panel-body a">
                          <div class="mt-2">
                            <div class="">
                              <div class="row m-0">
                                <div class="card own-account-tables">
                                  <p-table [resizableColumns]="true" columnResizeMode="expand" scrollWidth="100%"
                                    [paginator]="true" [rows]="rows" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                    [lazy]="true" [totalRecords]="total"
                                    [value]="promotionsDetail?.promotionAppliedToproductIds" [scrollable]="true"
                                    styleClass="tags-table  lm-table offerShowing-table offerShowingLine-table custom-dataTable table-striped">
                                    <ng-template pTemplate="colgroup" let-columns>
                                      <colgroup>
                                        <col *ngFor="let col of offerShowingLineColoumns">
                                      </colgroup>
                                    </ng-template>
                                    <ng-template pTemplate="header">
                                      <tr class="activity-rows">
                                        <th id="tableHead" *ngFor="let col of offerShowingLineColoumns">
                                          <ng-container *ngIf="col.field === 'status'">
                                            <p-checkbox name="selectContacts" styleClass="custom-checkbox"
                                              binary="true"></p-checkbox>
                                          </ng-container>
                                          <ng-container>
                                            {{col.header}}
                                          </ng-container>
                                          <p-sortIcon class="sort-icon" field="quantity">
                                          </p-sortIcon>
                                          <input id="j_idt727:j_idt728:j_idt731:filter"
                                            name="j_idt727:j_idt728:j_idt731:filter"
                                            class="ui-column-filter custom-search-input ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                            value="" autocomplete="off"
                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label" role="textbox"
                                            aria-disabled="false" aria-readonly="false">
                                        </th>
                                      </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-i="rowIndex" let-activity>
                                      <tr class="activity-data">
                                        <td *ngFor="let col of offerShowingLineColoumns">
                                          <ng-container *ngIf="col.field === 'status'">
                                            <div class="d-flex align-items-center">
                                              <figure class="circular-image mb-0" style="margin-right:15px;">
                                                <img [src]="activity.smallImageUrl"></figure>
                                              <div class="`">
                                                <span class="d-block">{{activity.internalName}}</span>
                                                <span class="d-block">{{activity.longDescription}}</span>
                                                <span class="d-block">{{activity.productId}}</span>
                                                <span class="d-block">Your Price US${{activity.requireAmount}}</span>
                                                <div
                                                  class="col-lg-12 pl-0 justify-content-start col-12 table-button main-submit-button">
                                                  <button type="submit" class="btn btn-secondary submit-btn">
                                                    Add to Compare
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </ng-container>

                                          <ng-container *ngIf="col.field === 'edit'"><span class="add-note">
                                              <input class="table-search" type="text" [value]="1">
                                              <div
                                                class="col-lg-12 pl-0 justify-content-start col-12 table-button main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn">
                                                  Add To Basket
                                                </button>
                                              </div>

                                            </span>
                                          </ng-container>
                                        </td>
                                      </tr>
                                    </ng-template>
                                  </p-table>

                                  <p class="paginate_data fid-pagination">
                                    View per page </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <!-- 2 -->

            </div>

          </div>
        </div>
      </div>
    </div>
  
  </div>
  <ngx-spinner></ngx-spinner> 