
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {CreateAccountModel} from './create-account-model'

@Injectable({
    providedIn: 'root'
  })


export class CreateAccountFormService {
    create_account_form : FormGroup;
  
  
  constructor(public _formBuilder: FormBuilder,
    public createAccountModel: CreateAccountModel
    ) { } 

  prepareAccountForm(data:any): void {
    if (!this.create_account_form) {
        this.create_account_form = this._formBuilder.group(this.createAccountModel.mapDataToModel(data));
       
    }

  }

}

 