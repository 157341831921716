<div class="container-fluid main-container-wrapper" style="margin-top: 53px !important;">
    <div class="row " style="background-color: #0492C2; padding-top: 6px !important;">
        

        <div class="col-lg-1">
           <span class="color-black pl-1 titlePanels" style="cursor: pointer; color: white; font-weight: bold;">Find </span>
        </div>
            <div class="col-lg-2" style="margin-left: -4% !important;">
                <p-dropdown filter="true"
                [options]="" optionlabel="label"
                placeholder="Work Order Number"
                >
                </p-dropdown>

            </div>

            <div class="col-lg-2" style="margin-left: -2% !important;">
                <input type="text" class="form-control" id=""  >
            </div>

            <div class="col-lg-1" style="margin-left: -2% !important;">
                <button type="submit" class="btn btn-secondary submit-btn">Search</button>

            </div>
        

        <div class="col-lg-4">
        </div>
            <div class="col-lg-2" style="margin-left: 7% !important;">
                <p-dropdown filter="true"
                [options]="" optionlabel="label"
                placeholder="Recent Items"
                >
                </p-dropdown>

            </div>

        

    

</div>

<div class="row" style="background-color:  #0492C2; padding-bottom: 6px !important; padding-top: 4px !important;">

<div class="col-lg-3   ">
    <button type="submit" class="btn btn-secondary submit-btn" style="margin-left: -2%;">
        Dispatch Board
     </button>
</div>

<div class="col-lg-2 " style="margin-left: 23% !important;">
    <button (click)="toWorkOrder()" style="background-color: #1338BE; 
    color: white !important; font-weight: bold;"> Work Orders</button>
</div>

<div class="col-lg-2 " style="margin-left: -8% !important;">
    <button (click)="toCustomer()" style="background-color: #48AAAD; 
    color: white !important; font-weight: bold;">Customer</button>

</div>               
   
<div class="col-lg-1 " style="margin-left: -9% !important;">
    <button (click)="toInvoices()" style="background-color:#016064;
     color: white; font-weight: bold;">Invoices</button>

</div>                
    
<div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toReports()" style="background-color: #1520A6; 
    color: white; font-weight: bold;">Reports</button>

</div>               

<div class="col-lg-1 " style="margin-left: -2% !important;">
    <button (click)="toPeople()" style="background-color:#59788E;
     color: white !important; font-weight: bold; padding-left: 12px; padding-right: 12px;">Staff</button>

</div>
<div class="col-lg-2 " style="margin-left: -3% !important;">
    <button (click)="toTimeSheet()" style="background-color: #1F456E;
     color: white !important; font-weight: bold;">Time Sheet</button>

</div>                 

<div class="col-lg-1 " style="margin-left: -9% !important;">
    <button (click)="toSetting()" style="background-color:#757c88 ; 
    color: white; font-weight: bold; ">Settings</button>

</div>



</div>
    
    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;" >
            <div class="col-md-12 p-0" >
                <div class="w3-card-4 classCard  w-100" style="padding-bottom: 2%;">


                
                       
            
                        <div>
                            <a class="btn btn-link" style="font-size:19px !important; font-weight: bold;">Company</a>

                        </div>
                        <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                        " (click)="toCustomFileds()">Custom Fields</a>
                        <p>You can create new fields and add them to Work Order, invoice and Site pages if 
                            you need to called more data.
                        </p>

                        <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                        "(click)="toTerminology()" >Terminology</a>
                        <p>You can change the default terms we use in the application to something that you
                            actually use in the business
                        </p>

                        <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                        " (click)="toSubscriptionDetails()">Subscription Details</a>
                        <p>You can view your company profile, licensing and module subscription information
                            here. </p>

                    <a class="btn btn-link" style="font-size:16px; font-weight: bold;
                    " (click)="toUploadLogo()" >Upload Logo</a>
                    <p> You can upload and add your company logo to screens and invoices</p>
                    
                    <a class="btn btn-link" style="font-size:16px; font-weight: bold;
                    "(click)="toDocumentType()">Document Type</a>
                    <p>
                        You can modify how document attachments are described and labeled.
                    </p>

                    <a class="btn btn-link" style="font-size:16px; font-weight: bold;
                    " (click)="toAppearance()">Appearance</a>
                    <p>
                        You can change the color scheme of screens throughout the application
                    </p>

                    <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                    " (click)="toLandmark()">Landmark</a>
                    <p> You can add landmark that will appear on the Map Details page for a person.

                    </p>

                    <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                    " (click)="toIntegration()">Integration</a>
                    <p>
                        You can choose to integate with and export invoices of QuickBooks.

                    </p>

                    <a class="btn btn-link" style="font-size:16px; font-weight: bold; 
                    " (click)="toClassSettings()">Class Settings</a>
                    <p>
                        You can track and assign accounting classes here for export on time cards 
                        and invoices to your financial system.
                    </p>
                </div>
            </div>
        </div>

    </div>
</div>