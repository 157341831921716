import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-main-production',
  templateUrl: './main-production.component.html',
  styleUrls: ['./main-production.component.css']
})
export class MainProductionComponent implements OnInit {
  activeCategory=2;
  activeCategoryValue=2;
  constructor(  private _location: Location,
    readonly router: Router,
    ) { }

  ngOnInit(): void {
  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
}
