import { Component, OnInit, OnDestroy,ViewChild } from '@angular/core';
import{HeaderService} from '../../header/header.service';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { SortEvent } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CreatePaymentServices } from 'src/app/accounting/payments/create-payments/create-payment.service';
import { AddLeaveEmpService } from 'src/app/human-resource/leave/add-new-leaves/add-new-leaves.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { ToastrService } from 'ngx-toastr'; 
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { CustomerAgreementsService } from 'src/app/accounting/accounting-ar/customer-agreements/customer-agreements.service';
import { NewWarehouseShipmentService } from 'src/app/warehouse/new-warehouse-shipment/new-warehouse-shipment.service';

@Component({
  selector: 'app-add-new-contact-information',
  templateUrl: './add-new-contact-information.component.html',
  styleUrls: ['./add-new-contact-information.component.css']
})
export class AddNewContactInformationComponent implements OnInit {

  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  products3: any;
  total=0;
  rowNumber=0;
  activeCategoryPay=1;
  partyId: any;
  activeCategoriess=4;
  activeCategoryInvoice=1;
  activeCategory=1;
  allStatusList: any = []
  pageSize = 10;
  getMainOrg: any;
  show: boolean;
  rows = 50;
  createOrganization:FormGroup;
  orgId: any;
  orgIdArray: any[]=[];
  faInfoCircle = faInfoCircle;
  isShown = false; 
  activeCategoryMain=2;
  statusIds: any[];
  agreementType: string;
  invoiceTypes: any[];
  invoiceStatusArray = [
    {
      label: 'Un-Paid',
      value: 'INVOICE_IN_PROCESS'
    },
    {
      label: 'Paid',
      value: 'INVOICE_PAID'
    },

  ];
  invoiceDateArray = [
    {
      label: 'Past due date',
      value: 'past due date'
    },
    {
      label: 'Within Due date',
      value: 'within due date'
    },
  ];
  opportunityIdArray = [
    {
      label: 'Contains',
      value: 'Contains'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    },
    {
      label: 'Begins With',
      value: 'Begins With'
    },
  ];
  greaterArray = [
    {
      label: 'Equals',
      value: 'equal'
    },
    {
      label: 'Same Day',
      value: 'Same Day'
    },
    {
      label: 'Greater Than From Day Start',
      value: 'Greater Than From Day Start'
    },
    {
      label: 'Greater Than',
      value: 'Greater Than'
    }
  ];
  smallerArray = [
    {
      label: 'Less Than',
      value: 'Less Than'
    },
    {
      label: 'Up To Day',
      value: 'Up To Day'
    },
    {
      label: 'Up Through Day',
      value: 'Up Through Day'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    }
  ];
  allpaymentMethodId: any = [];
  paymentTypeArray: any[] = [];
  PaymentTypeOutgoingList: any[] = [];
  invoiceIDArray: any[] = [];
  pageNo = 1;

  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }

  searchInvoiceForm: FormGroup;
  invoices: any[];
  partyIds: any[];
  invoiceType: string;
  agreementTypeValue: string;
  advanceSearch: { description: string; fromPartyId: string; invoiceDateFrom: string; invoiceDateTo: string; invoiceId: string; invoiceType: string; statusId: string; toPartyId: string; };
  getPurchaseInvoicesData: any;
  filterInvoiceDateForm: FormGroup;
  allFinAccountIdList: any = [];
  filterInvoiceStatusForm: FormGroup;
  CustomerAgreements: FormGroup;
  agreementList: any;
  PersonsAndPartyGroupsListArray: any[]=[];
  productListArray: any[]=[];
  AgreementTypeListArray: any[]=[];
  fromDateType: { label: string; value: string; }[];
  toDateType: { label: string; value: string; }[];
  findForm: FormGroup;
  createSalesInvoices: FormGroup;
  allPaymentTypeList: any = [];
  PaymentsList: any;
  organizationPartyId: any;
  partyIdArray: any[]=[];
  organizationPartyIdArray: any[]=[];
  createPurchaseInvoices: FormGroup;
   constructor(
    readonly accountsService: AccountsService,
    readonly  HeaderService: HeaderService,  
    readonly CustomerAgreementsService: CustomerAgreementsService,
    readonly addLeaveEmpService: AddLeaveEmpService,
    readonly _FormBuilder: FormBuilder,
    readonly _AccountingApService: AccountingApService,
    readonly _ToastrService: ToastrService,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly  _Router: Router,
    readonly createPaymentService: CreatePaymentServices,
    readonly spinner: NgxSpinnerService,
    readonly _GlobalResourceService: GlobalResourceService,
    private _location: Location,
    readonly router: Router,
    ) {
      this.findForm = this._FormBuilder.group({
        fromPartyId: [''],
        toPartyId: [''],
        amount: [''],
        status: [''],
        comments: [''],
        paymentId: [''],
        paymentType: [''],
  
      });
      this.filterInvoiceDateForm = this._FormBuilder.group({
        invoiceDate: [''],
      })
      this.filterInvoiceStatusForm = this._FormBuilder.group({
        invoiceStatus: [''],
      })
      this.filterInvoiceStatusForm = this._FormBuilder.group({
        description: [''],
        descriptionSearchType: ['Contains'],
        fromPartyId: [''],
        invoiceDateFrom: [''],
        invoiceDateFromSearchType: ['equal'],
        invoiceDateTo: [''],
        invoiceDateToSearchType: ['oplessthen'],
        invoiceId: [''],
        invoiceIdSearchType: ['Contains'],
        invoiceType: ['PURCHASE_INVOICE', [Validators.required]],
        statusId: [''],
        toPartyId: ['']
      });
      this.searchInvoiceForm = this._FormBuilder.group({
        description: [''],
        descriptionSearchType: ['Contains'],
        fromPartyId: [''],
        invoiceDateFrom: [''],
        invoiceDateFromSearchType: ['equal'],
        invoiceDateTo: [''],
        invoiceDateToSearchType: ['oplessthen'],
        invoiceId: [''],
        invoiceIdSearchType: ['Contains'],
        invoiceType: ['PURCHASE_INVOICE', [Validators.required]],
        statusId: [''],
        toPartyId: ['']
      });
      this.advanceSearch = {
  
        description: "",
        fromPartyId: "",
        invoiceDateFrom: "",
        invoiceDateTo: "",
        invoiceId: "",
        invoiceType:"",
        statusId: "",
        toPartyId:""
  
      };
      this.invoiceTypes = [];
      this.statusIds = [];
      this.invoices = [];
      this.partyIds = [];
      this.invoiceType = 'payable';

      this.CustomerAgreements = this._FormBuilder.group({
        agreementId: [''],
        agreementIdSearchType: ['Contains'],
        agreementTypeId: [''],
        fromDateFrom: [''],
        fromDateFromSearchType: ['Greater Than'],
        fromDateTo: [''],
        fromDateToSearchType: ['Less Than'],
        partyIdFrom: [''],
        partyIdTo: [''],
        productId: [''],
      });

     
      this.fromDateType = [
        {
          label: 'Equals',
          value: 'Equals'
        },
        {
          label: 'Greater Than',
          value: 'Greater Than'
        },
        {
          label: 'Greater Than Equals',
          value: ' Greater Than Equal To'
        },
  
      ];
      this.toDateType = [
        {
          label: 'Less Than',
          value: 'LessThan'
        },
        {
          label: 'Is Empty',
          value: 'Is Empty'
        },
        {
          label: 'Less Than Equals',
          value: ' Less Than Equal To'
        },
  
      ];
      this.createSalesInvoices = this._FormBuilder.group({
        organizationPartyId: [''],
        PartyID: [''],
        DaysOffset: [''],
      })
      this.createPurchaseInvoices = this._FormBuilder.group({
        organizationPartyId: [''],
        PartyID: [''],
        DaysOffset: [''],
      })
     }
 

  ngOnInit(): void {

    this.getPaymentTypeOutgoing();
    this.getPaymentTypes();
    this.getPaymentsList();
    this.getPartyId();
    this.getOrganizationPartyId();
  }
  FindSalesInvoicesByDueDate() {
    this.spinner.show();
    const formData = {
      "daysOffset": this.createSalesInvoices.value.DaysOffset ,
      "invoiceTypeId":  this.createSalesInvoices.value.amount ,
      "organizationPartyId":  this.createSalesInvoices.value.organizationPartyId ,
      "partyId":  this.createSalesInvoices.value.PartyID ,
    }
    this.accountsService.FindSalesInvoicesByDueDate(formData).subscribe((res:any)=> {
      if(res.success) {
        this.spinner.hide();
        this._ToastrService.success("Subbmitted Successfully");
        this.closebutton1.nativeElement.click();
        this.createSalesInvoices.reset();
      }

      else if(res.success === false) {
        this._ToastrService.error(res.message);
        this.spinner.hide();
      }
    },(err) => {
      for(const value of err.error.errors){
        this._ToastrService.error(value.fieldName + " " + value.fieldError);
        }
        this.spinner.hide();
   })

  }
 
  FindPurchaseInvoicesByDueDate() {
    this.spinner.show();
    const formData = {
      "daysOffset": this.createPurchaseInvoices.value.DaysOffset ,
      "invoiceTypeId":  this.createPurchaseInvoices.value.amount ,
      "organizationPartyId":  this.createPurchaseInvoices.value.organizationPartyId ,
      "partyId":  this.createPurchaseInvoices.value.PartyID ,
    }
    this.accountsService.FindPurchaseInvoicesByDueDate(formData).subscribe((res:any)=> {
      if(res.success) {
        this.spinner.hide();
        this._ToastrService.success("Subbmitted Successfully");
        this.closebutton2.nativeElement.click();
        this.createPurchaseInvoices.reset();

      }

      else if(res.success === false) {
        this._ToastrService.error(res.message);
        this.spinner.hide();
      }
    },(err) => {
      for(const value of err.error.errors){
        this._ToastrService.error(value.fieldName + " " + value.fieldError);
        }
        this.spinner.hide();
   })

  }
  getOrganizationPartyId() {
    this.spinner.show();
    this.addLeaveEmpService.getOrganizationPartyId().subscribe(res => {
      this.organizationPartyId = res.data;
      this.spinner.hide();
      for (const value of this.partyId) {
        this.organizationPartyIdArray.push({
          label: value.organizationPartyId,
          value: value.organizationPartyId
        })
      }
    })

  }
  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this._Router.navigate(['/financial/main']);
}
  detailMainPage(paymentId,description,statusIdWithDescription,comments,partyIdFrom,partyIdTo,effectiveDate,amount){
    this._Router.navigate(['/financial/detail-payment-portal-summary'],{ queryParams: { paymentId:paymentId,description:description,statusIdWithDescription:statusIdWithDescription,comments:comments,partyIdFrom:partyIdFrom,partyIdTo:partyIdTo,effectiveDate:effectiveDate,amount:amount}});
  }
  createPayment() {
    this.router.navigate(["/financial/my-portal-create-payments"])
  }
  getPartyId() {
    this.spinner.show();
    this.addLeaveEmpService.getPartyId().subscribe(res => {
      this.partyId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }

  getPaymentTypeOutgoing() {
    this.spinner.show();
    this.createPaymentService.getPaymentTypeOutgoing().subscribe(res => {
      if (res.success) {
        const PaymentTypeOutgoing = res.data;
        for (const value of PaymentTypeOutgoing) {
          this.PaymentTypeOutgoingList.push({
            label: value.description,
            value: value.paymentTypeId
          })
        }
      }

    }, (err) => {
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  getPaymentTypes() {
    this.spinner.show();
    this.createPaymentService.getPaymentType().subscribe(res => {
      if (res.success) {
        const allPaymentType = res.data.paymentType;
        const allStatusList = res.data.status;
        const finAccountIdList = res.data.finAccountId;
        for (const value of allPaymentType) {
          this.allPaymentTypeList.push({
            label: value.description,
            value: value.paymentTypeId
          })
        }

        for (const value of finAccountIdList) {
          this.allFinAccountIdList.push({
            label: value.finAccountName,
            value: value.finAccountId
          })
        }
        for (const value of allStatusList) {
          this.allStatusList.push({
            label: value.description,
            value: value.statusId
          })
        }

      }

    }, (err) => {
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  resetPayment() {
    this.findForm.reset();
    this.getPaymentsList();
  }
  findPaymentsList() {
    this.spinner.show();

    const req = { 
      "amount": this.findForm.value.amount ? this.findForm.value.amount : "",
      "comments": this.findForm.value.comments ? this.findForm.value.comments : "",
      "commentsSearchType": "Contains",
      "fromPartyId": this.findForm.value.fromPartyId ? this.findForm.value.fromPartyId : "",
      "paymentId": this.findForm.value.paymentId ? this.findForm.value.paymentId : "",
      "paymentIdSearchType": "Contains",
      "paymentType": this.findForm.value.paymentType ? this.findForm.value.paymentType : "",
      "status": this.findForm.value.status ? this.findForm.value.status : "",
      "toPartyId": this.findForm.value.toPartyId ? this.findForm.value.toPartyId : ""
    }
    this.accountsService.getPaymentsList(this.finSize, req).subscribe((res: any) => {
      this.PaymentsList = res.data;
      this.spinner.hide();

    })
  }
  getPaymentsList() {
    this.spinner.show();
    const req = {
      "amount": "",
      "comments": "",
      "commentsSearchType": "Contains",
      "fromPartyId": "",
      "paymentId": "",
      "paymentIdSearchType": "Contains",
      "paymentType": "",
      "status": "",
      "toPartyId": ""
    }
    this.accountsService.getPaymentsList(this.finSize, req).subscribe((res: any) => {
      this.PaymentsList = res.data;
      this.spinner.hide();

    })
  }
  mainPage()
  {
    this._Router.navigate(['/financial/main-accounting']);
  }
  accountingPage()
  {
    this._Router.navigate(['/financial/Accounting']);
  }
  invoicePage()
  {
    this._Router.navigate(['/financial/Invoices']);
  }
  paymentPage()
  {
    this._Router.navigate(['/financial/Payments']);
  }
  paymentGrpPage()
  {
    this._Router.navigate(['/financial/Payment-group']);
  }
  billingAccountPage()
  {
    this._Router.navigate(['/financial/Billing-Account']);
  }
  agreementPage()
  {
    this._Router.navigate(['/financial/Agreements']);
  }
  commisionReportPage()
  {
    this._Router.navigate(['/financial/Commission-Report']);
  }
  mainAgreementPage()
  {
    this._Router.navigate(['/financial/main-agreements']);
  } 
  mainBillingAccountPage()
  {
    this._Router.navigate(['/financial/main-Billing-Accounts']);
  }
  mainFixedAssetPage()
  {
    this._Router.navigate(['/financial/main-Fixed-Assets']);
  } 
  
  mainInvoicePage()
  {
    this._Router.navigate(['/financial/main-Invoices']);
  }
   mainPaymentPage()
  {
    this._Router.navigate(['/financial/main-Payment']);
  }


  changeActiveCategoryInvoice(tab)
  {
    this.activeCategoryInvoice =tab;
  }
  changeActiveCategoryFunctions(tab)
  {
    this.activeCategoriess=tab;
  }
  changeActiveCategory(tab)
  {
    this.activeCategory=tab;
  }
  changeActiveCategoryPayment(tab)
  {
    this.activeCategoryPay =tab;
  }
  
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }

}