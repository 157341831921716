import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmpPhoneModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        partyId:(data && data.partyId) ? data.partyId : null,
        areaCode:(data && data.areaCode) ? data.areaCode : null,
        askForName:(data && data.askForName) ? data.askForName : null,
        contactMechId:(data && data.contactMechId) ? data.contactMechId : null,
        countryCode:(data && data.countryCode) ? data.countryCode : null,
        extension:(data && data.extension) ? data.extension : null,
        allowSolicitation:(data && data.allowSolicitation) ? data.allowSolicitation : null,
        contactNumber:(data && data.contactNumber) ? data.contactNumber : null,
    });
    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   return ({
    extension: new FormControl(data.extension),
    partyId: new FormControl(data.partyId),
    countryCode: new FormControl(data.countryCode),
    contactNumber: new FormControl(data.contactNumber),
    contactMechId: new FormControl(data.contactMechId),
    askForName: new FormControl(data.askForName),
    areaCode: new FormControl(data.areaCode),
    allowSolicitation: new FormControl(data.allowSolicitation),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}

