import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class LeadServices {

  constructor(readonly http: HttpClient) { }
  getStatusCrm(): Observable<any> {
    const statusCrm = URLS.getCRMStatus;
    return this.http.get(statusCrm);
  }
  getLeadDetail(id): Observable<any> {
    const leadDetails = URLS.getLeadDetails.replace(':personId', id);
    return this.http.get(leadDetails)
  }
  createLead(formData): Observable<any> {
   
    return this.http.post(URLS.createLead, formData);
  }
  updateLead(formData): Observable<any> {
    return this.http.post(URLS.createLead, formData);
  }
  getLeadSource(): Observable<any> {
    const getLeadSource = URLS.getLeadSource;
    return this.http.get(getLeadSource);
  }
  getCountry(): Observable<any> {
    return this.http.get(URLS.getCountry);
  }
  getContactList(): Observable<any> {
    const getContactList = URLS.getContactList;
    return this.http.get(getContactList);
  }
  getState(data): Observable<any> {
    const getStateList = URLS.getState;
    const getStateLists = getStateList.replace('accState', data.trim())
    return this.http.get(getStateLists)
  }

}
