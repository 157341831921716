<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class=" bg-white color-grey">
                <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
                    <span class="color-black pl-1 titlePanels"> Invitation</span>
                    <span>
                         <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                         <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
                     </span> 
                 </div>
                <div class="w3-card-4 classCard">
                    <div class="w3-container">
                        <div class="header-tabs px-2" style="background-color: #cce0e95e !important;">
                            <h4 class=" common-styling">
                                Find Party Invitations
                            </h4>
                            <span *ngIf="!this.Hidebtn" class="px-2 edit-buttons" style="float: left !important;cursor: pointer;" data-toggle="modal" data-target="#exampleModalCenter">Create New Party Invitations</span>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100">
                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                            <div class="w-100">
                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                    <div *ngIf="activeCategory==1">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling">
                                                                Search Options
                                                            </h4>
                                                        </div>
                                                        <div class="panel-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Party
                                                                                Invitation ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="opportunityIdArray" filter="true" style="display: none;" placeholder="Contains" [(ngModel)]="advanceSearch.searchPartyInvitation" [ngModelOptions]="{standalone:true}" name="searchPartyInvitation">
                                                                            </p-dropdown>
                                                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Party Invitation ID" [(ngModel)]="advanceSearch.partyInvitationId" [ngModelOptions]="{standalone:true}" name="partyInvitationId">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Party
                                                                                From</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="partyIdArray" filter="true" placeholder="Enter Party ID" [(ngModel)]="advanceSearch.partyIdFrom" [ngModelOptions]="{standalone:true}" name="partyIdFrom">
                                                                            </p-dropdown>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Party
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="partyIdArray" filter="true" placeholder="Enter Party ID" [(ngModel)]="advanceSearch.partyIdTo" [ngModelOptions]="{standalone:true}" name="partyIdTo">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">To
                                                                                Name</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="opportunityIdArray" filter="true" style="display: none;" placeholder="Contains" [(ngModel)]="advanceSearch.searchToName" [ngModelOptions]="{standalone:true}" name="searchToName">
                                                                            </p-dropdown>
                                                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter To Name" [(ngModel)]="advanceSearch.toName" [ngModelOptions]="{standalone:true}" name="toName">
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Email
                                                                                Address</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="opportunityIdArray" filter="true" style="display: none;" placeholder="Contains" [(ngModel)]="advanceSearch.searchEmail" [ngModelOptions]="{standalone:true}" name="searchEmail">
                                                                            </p-dropdown>
                                                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email Address" [(ngModel)]="advanceSearch.email" [ngModelOptions]="{standalone:true}" name="email">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">From
                                                                                Date</label>

                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" id="date" class="form-control" [(ngModel)]="advanceSearch.fromDate" [ngModelOptions]="{standalone:true}" name="fromDate">
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                                        <div class="col-lg-3 form-group rightForm">
                                                                            <label for="exampleInputEmail1">Status
                                                                                ID
                                                                                (Any Role)</label>

                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="PartyInvitationStatusListArray" filter="true" placeholder="Enter Status ID" [(ngModel)]="advanceSearch.statusId" [ngModelOptions]="{standalone:true}" name="statusId">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Through
                                                                                Date</label>


                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="date" id="date" class="form-control" [(ngModel)]="advanceSearch.throughDate" [ngModelOptions]="{standalone:true}" name="throughDate">
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -26%;">
                                                                <button _ngcontent-cta-c128="" type="submit" (click)="advanceSearchContacts(false)" class="btn btn-secondary submit-btn">Find</button>&nbsp;&nbsp;
                                                                <button _ngcontent-cta-c128="" type="submit" (click)="reset()" class="btn btn-danger ml-2">Reset</button>&nbsp;&nbsp;
                                                            </div>
                                                            <div>
                                                                <header class="w3-container">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling">
                                                                            Search Results
                                                                        </h4>

                                                                    </div>
                                                                </header>
                                                                <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                                    <div class="col-lg-12 col-12">
                                                                        <div class="form-group">

                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="invitationParty" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    Party Invitation ID
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Party From
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                <div style="color: white;">
                                                                                                    Party Id
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                                                                                <div style="color: white;">
                                                                                                    To Name
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'200px'}" width="11%" pSortableColumn="quantity">
                                                                                                <div style="color: white;">
                                                                                                    E-Mail Address
                                                                                                    <p-sortIcon field="quantity">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'200px'}" width="11%" pSortableColumn="price">
                                                                                                <div style="color: white;">
                                                                                                    Status ID
                                                                                                    <p-sortIcon field="price">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                                <div style="color: white;">
                                                                                                    Last Invite Date
                                                                                                    <p-sortIcon field="price">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                                                <div style="color: white;">
                                                                                                    Action
                                                                                                    <p-sortIcon field="price">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
                                                                                                    <span (click)="summary(product.partyInvitationId)" style="color: #0d3769;cursor: pointer;"  class="account-button">{{product.partyInvitationId}}</span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                {{product.partyIdFrom}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                {{product.partyId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                                {{product.toName}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'200px'}" width="11%">
                                                                                                {{product.emailAddress}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'200px'}" width="11%">
                                                                                                {{product.statusId}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'150px'}" width="11%">
                                                                                                {{product.lastInviteDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'150px'}" width="11%">
                                                                                                <!--  <svg class="hover" (click)="update(product.partyInvitationId)"
                                              xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                              viewBox="0 0 21.223 21.222">
                                              <g transform="translate(0 -0.004)">
                                                <g transform="translate(0 1.52)">
                                                  <path
                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                    transform="translate(0 -34.137)" />
                                                </g>
                                                <g transform="translate(4.548 0.004)">
                                                  <g transform="translate(0 0)">
                                                    <path
                                                      d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                      transform="translate(-102.409 -0.004)" />
                                                  </g>
                                                </g>
                                              </g>
                                            </svg> -->
                                                                                                <svg *ngIf="!this.Hidebtn" (click)="deleteStoreRole(product.partyInvitationId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                                    <defs>
                                                                                                        <style>
                                                                                                            .a {
                                                                                                                fill: #f44336;
                                                                                                            }

                                                                                                            .b {
                                                                                                                fill: #fafafa;
                                                                                                                margin-left: 20px;
                                                                                                            }
                                                                                                        </style>
                                                                                                    </defs>
                                                                                                    <path
                                                                                                        class="a fill-color"
                                                                                                        d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                    <path class="b"
                                                                                                        d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                        transform="translate(-147.576 -147.576)" />
                                                                                                </svg>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No
                                                                                                        Record Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>

                                                                                <p class="paginate_data">
                                                                                    View per page </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create-Invitation</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                        Add New Invitation
                                    </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                        Update New Invitation
                                    </a></li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="invitataionForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Party From</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Party From" filter="true" [options]="partyIdArray" optionlabel="label" formControlName="partyFrom">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA" *ngIf="this.show">
                                                        <label for="exampleInputEmail1">
                                                            Invitation ID</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="this.show">
                                                        <label>{{this.inviteId}}</label>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Party ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Party ID" [options]="partyIdArray" filter="true" optionlabel="label" formControlName="partyTo">
                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">To Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter To Name" formControlName="toName">
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Email Address</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email Address" formControlName="emailAddress">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Status ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Status ID" [options]="PartyInvitationStatusListArray" filter="true" optionlabel="label" formControlName="statusId">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Last Invite Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Last Invite Date" formControlName="inviteDate">

                                                    </div>


                                                </div>
                                            </div>


                                        </div>
                                    </form>
                                    <div class="main-submit-button" style="margin-left: 78%;">
                                        <button type="submit" *ngIf="!this.show" (click)="onSubmit();" class="btn btn-secondary submit-btn">Create</button>&nbsp;
                                        <button type="submit" *ngIf="this.show" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>&nbsp;
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>