import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class ReceivalesPaymentServices {

  constructor(readonly http: HttpClient) { }
 
  getPaymentType(): Observable<any> {
    const paymentMode = URLS.getPaymentType;
    return this.http.get(paymentMode);
  }

  getPaymentList(data, formData): Observable<any> {
    
    const getPaymentsList = URLS.getPayments + "?pageSize=" + data.pageSize + "&pageNo=" + data.pageNo;
    return this.http.post(getPaymentsList, formData);
  }

}
