import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CreateDepartmentModel } from './create-departments-model'

@Injectable({
    providedIn: 'root'
  })


export class CreateDepartmentFormService {
    job_requistion_form : FormGroup;
  
  
  constructor(public _formBuilder: FormBuilder,
    private createDepartmentModel: CreateDepartmentModel
    ) { } 

  jobRequistionForm(data:any): void {
    if (!this.job_requistion_form) {
        this.job_requistion_form = this._formBuilder.group(this.createDepartmentModel.mapDataToModel(data));
    }
  }
}

 
