import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';import { Location } from '@angular/common';


import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ProductionService } from '../production.service';
@Component({
  selector: 'app-detail-manufacturing-rule',
  templateUrl: './detail-manufacturing-rule.component.html',
  styleUrls: ['./detail-manufacturing-rule.component.css']
})
export class DetailManufacturingRuleComponent implements OnInit {
  ruleId: any;
  @ViewChild('closebutton') closebutton;
  manufactureRule: any;
  createManRule: FormGroup;
  activeCategory = 2;
  productListArray: any[] = [];
  ProductFeatureIds: any;
  ProductFeatureIdsArray: any[] = [];
  Rule = [{
    "label": "OR",
    "value": "OR"
  },
  {
    "label": "AND",
    "value": "AND"
  }]
  fromDate: string;
  ThroughDate: string;
 
  show: boolean;
  getEstimateFrom: string;
  estimatedCompletionDate: string;
  productInIds: any;
  productInIdsArray: any[]=[];
  date: Date;
  constructor(readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,

    readonly spinner: NgxSpinnerService,
    readonly productionService: ProductionService,
private _location: Location,
readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createManRule = this._FormBuilder.group({
        ProductID: [''],
        ProductIDFor: [''],
        ProductIDIn: [''],
        FromDate: [''],
        ProductIDInSubst: [''],
        ProductFeature: [''],
        RuleOperator: [''],
        Quantity: [''],
        ThroughDate: [''],
  
      });
    }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.ruleId = params["ruleId"];
    });
    this.date=new Date();
    let latest_date =this.datePipe.transform(this.date, 'yyyy-MM-dd');
    const formValue = this.createManRule;
    formValue.patchValue({
      FromDate:latest_date
    })
    this.show=true;
    this.getManufacturingIdByData();
    this.getProducts();
    this.getProductFeatureIds();
    this.getproductInIds();
  }
  getManufacturingIdByDataEdit(){
    this.spinner.show();
  
    this.productionService.getManufacturingIdByData(this.ruleId).subscribe((res: any) => {
      setTimeout(() => {
       
         const fromdate=res.data.fromDate
         this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
         const estimatedCompletionDate=res.data.thruDate
         this.estimatedCompletionDate=this.datePipe.transform(estimatedCompletionDate,"yyyy-MM-dd");
         const formValue = this.createManRule;
         formValue.patchValue({
          ProductID: res.data.productId,
          ProductIDFor:res.data.productIdFor,
          ProductIDIn: res.data.productIdIn,
          FromDate: this.getEstimateFrom,
          ProductIDInSubst: res.data.productIdInSubst,
          ProductFeature: res.data.productFeature,
          RuleOperator: res.data.ruleOperator,
          Quantity: res.data.quantity,
          ThroughDate: this.estimatedCompletionDate
         })
       
       }, 2000);
     
      this.spinner.hide();
    })
  }
  getProductFeatureIds() {
    this.spinner.show();

    this.productionService.getProductFeatureIds().subscribe(res => {
      this.ProductFeatureIds = res.data;
      this.spinner.hide();
      for (const value of this.ProductFeatureIds) {
        this.ProductFeatureIdsArray.push({
          label: value.description,
          value: value.productFeatureId
        })
      }
    })

  }
  getproductInIds() {
    this.spinner.show();

    this.productionService.getproductInIds().subscribe(res => {
      this.productInIds = res.data;
      this.spinner.hide();
      for (const value of this.productInIds) {
        this.productInIdsArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })

  }
  
  getProducts(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      const productList = res.data;
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });

    });

  }

  onSubmit() {
    this.spinner.show();
    const FromDate = this.createManRule.get('FromDate').value;
    this.fromDate = this.datePipe.transform(FromDate, "yyyy-MM-dd hh:mm:ss");
    const ThroughDate = this.createManRule.get('ThroughDate').value;
    this.ThroughDate = this.datePipe.transform(ThroughDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = { 
      "fromDate":this.fromDate,
      "productFeature": this.createManRule.value.ProductFeature,
      "productId": this.createManRule.value.ProductID,
      "productIdFor":this.createManRule.value.ProductIDFor,
      "productIdIn": this.createManRule.value.ProductIDIn,
      "productIdInSubst":this.createManRule.value.ProductIDInSubst,
      "quantity":this.createManRule.value.Quantity,"ruleId":"",
      "ruleOperator":this.createManRule.value.RuleOperator,
      "thruDate":  this.ThroughDate
    }
    this.productionService.postManufacturingRule(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this._Router.navigate(['/production/manufacturing-rules']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    }); }
  onUpdate() {
    this.spinner.show();
    const FromDate = this.createManRule.get('FromDate').value;
    this.fromDate = this.datePipe.transform(FromDate, "yyyy-MM-dd hh:mm:ss");
    const ThroughDate = this.createManRule.get('ThroughDate').value;
    this.ThroughDate = this.datePipe.transform(ThroughDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":this.fromDate,
      "productFeature": this.createManRule.value.ProductFeature,
      "productId": this.createManRule.value.ProductID,
      "productIdFor":this.createManRule.value.ProductIDFor,
      "productIdIn": this.createManRule.value.ProductIDIn,
      "productIdInSubst":this.createManRule.value.ProductIDInSubst,
      "quantity":this.createManRule.value.Quantity,
      "ruleId":this.ruleId,
      "ruleOperator":this.createManRule.value.RuleOperator,
      "thruDate":  this.ThroughDate
    }
    this.productionService.updateManufacturingRule(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebutton.nativeElement.click();
        this._Router.navigate(['/production/manufacturing-rules']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  getManufacturingIdByData(){
    this.spinner.show();
  
    this.productionService.getManufacturingIdByData(this.ruleId).subscribe((res: any) => {
    this.manufactureRule=res.data
     
      this.spinner.hide();
    })
  }
  homeButton(){
    this._Router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
}
