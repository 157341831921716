import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import { PaymentGroupService } from '../accounting-ap/payable-payment-group/payment-group.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-financial-check-detail-summary',
  templateUrl: './financial-check-detail-summary.component.html',
  styleUrls: ['./financial-check-detail-summary.component.css']
})
export class FinancialCheckDetailSummaryComponent implements OnInit {
  createAccountRecon:FormGroup;
  trialBalance:FormGroup;
  costCenterForm:FormGroup;
  inventoryValuation:FormGroup;
  trialBalanceForm:FormGroup;
  comparativeBalanceSheetStatement:FormGroup;
  comparativeIncomeStatement:FormGroup;
  comparativeCashFlowStatement:FormGroup;
  balanceSheetStatement:FormGroup;
  cashFlowStatement:FormGroup;
  totalTransaction:FormGroup;
  incomeStatement:FormGroup;
  paymentPartyGroup: any;
  downloading: boolean;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  private finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  activeCategory=7;
  activeCategoryValue=2;
  chartAccount:any[]=[]
  createReconAccountForm:FormGroup;
  createTransForm:FormGroup;
  createTransEntForm:FormGroup;
  partyId: any;
  activeCategoryCheck=2;
  ReconciliationsAccount: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  postfindReconciliation: any;
  actualfromdate: any;
  actualthroughdate: any;
  glFiscalType: any;
  glJournal: any;
  fixedAsset: any;
  groupStatus: any;
  accTransactionType: any;
  glFiscalTypeArray: any[]=[];
  glJournalArray: any[]=[];
  fixedAssetArray: any[]=[];
  groupStatusArray: any[]=[];
  accTransactionTypeArray: any[]=[];
  Products: any;
  ProductsArray: any[]=[];
  workEffortArray: any[]=[];
  FacilityShipments: any;
  FacilityShipmentsArray: any[]=[];
  PaymentsList: any;
  getPaymentsListArray: any[]=[];
  invoiceList: any;
  invoiceListArray: any[]=[];
  posted: { label: string; value: string; }[];
  transDeatils: any;
  actualfrom: string;
  actualthrough: string;
  pdf: any;
  id: string;
  reportType: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[]=[];
  accountTransactionDetail: any;
  actualfromEnt: string;
  actualthroughEnt: string;
  timePerod: any;
  timePerodArray: any[]=[];
  trialBalanceList: any;
  postedCreditsTotal: any;
  postedDebitsTotal: any;
  months: { label: string; value: string; }[];
  transactionTotals: any;
  fromDateTotal: string;
  througDateTotal: string;
  postedTransactionTotals: any;
  unpostedTransactionTotals: any;
  allTransactionTotals: any;
  throughDateIncome: string;
  fromDateIncome: string;
  incomeStatementRevenues: any;
  incomeStatementExpenses: any;
  incomeStatementIncome: any;
  balanceTotals: any;
  fromDateCash: string;
  throughDateCash: string;
  openingCashBalance: any;
  periodCashBalance: any;
  closingCashBalance: any;
  balanceTotalsCash: any;
  throughDateBalance: string;
  balanceSheetAssets: any;
  balanceSheetLiabilities: any;
  balanceSheetEquities: any;
  balanceTotalsBalance: any;
  period1fromDate: string;
  period2fromDate: string;
  period1throughDate: string;
  period2throughDate: string;
  comparativeIncomeStatementRevenues: any;
  comparativeIncomeStatementExpenses: any;
  comparativeIncomeStatementIncome: any;
  comparativeBalanceTotals: any;
  period1fromDateCash: string;
  period2fromDateCash: string;
  period1ThroughDateCash: string;
  period2ThroughDateCash: string;
  openingCashBalanceComp: any;
  periodCashBalanceComp: any;
  closingCashBalanceComp: any;
  balanceTotalsComp: any;
  period1ThrougDateBalce: string;
  period2ThrougDateBalce: string;
  balanceSheetAssetsComp: any;
  balanceSheetLiabilitiesComp: any;
  balanceSheetEquitiesComp: any;
  balanceTotalsBalComp: any;
  getTrialListDetails: any;
  facilities: any[];
  inventoryValuationData: any;
  throughDateInvent: string;
  costCenterValue: any;
  costFromDate: string;
  costThroughDate: string;
  getCheckDetailsValue: any;
  payIdArrayWith: any[]=[];
  accontArrayRecon: any[]=[];
  requestDataRecon: { acctgTransEntrySeqId: string; acctgTransId: string; amount: string; glAccountId: string; partyId: string; productId: string; }[];
  glReconciliationId: any;
  payIdArrayWithData: any[]=[];
  requestDataReconPDF: any[];
  pdfReqData: any[];
  glAccountCategories: any;
  postedAll: { label: string; value: string; }[];
  transactionEntries: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
   
    readonly addSkillService: AddSkillService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly datePipe: DatePipe,
    readonly _WarehouseService: WarehouseService,
    readonly router: Router,
    private _location: Location,
    ){
      this.facilities=[];
      this.months=[{
        "label":"January",
        "value":"0"
      },
      {
        "label":"February",
        "value":"1"
      },
      {
        "label":"March",
        "value":"2"
      },
      {
        "label":"April",
        "value":"3"
      },
      {
        "label":"May",
        "value":"4"
      },
      {
        "label":"June",
        "value":"5"
      },
      {
        "label":"July",
        "value":"6"
      },
      {
        "label":"August",
        "value":"7"
      },
      {
        "label":"September",
        "value":"8"
      },
      {
        "label":"October",
        "value":"9"
      },
      {
        "label":"November",
        "value":"10"
      },
      {
        "label":"December",
        "value":"11"
      },]
this.reportType=[{
  "label":"By Account",
  "value":"byAccount"
},{
  "label":"By Date",
  "value":"byDate"
}]

      this.chartAccount=[{
    "product":'1'
      }]
      this.createAccountRecon = this._FormBuilder.group({
        glAccountId: [''],
    
        
      });

      this.inventoryValuation=this._FormBuilder.group({
       
        facilityId: [''],
        productId: [''],
        throughDate: [''],
    
        
      });
      this.trialBalanceForm=this._FormBuilder.group({
       
        glAccEntGl: [''],
        customeTime: [''],
        postedY: [''],
    
        
      });
      this.balanceSheetStatement=this._FormBuilder.group({
       
        throughDateBalance: [''],
        FiscalGlTypeBalance: [''],
    
        
      });
      this.comparativeBalanceSheetStatement=this._FormBuilder.group({
     
        period1ThrougDateBalce: [''],
        period2ThrougDateBalce: [''],
        period1FiscalTypeBalalnce: [''],
        period2FiscalTypeBalalnce: [''],
        
      });
      this.comparativeCashFlowStatement=this._FormBuilder.group({
        period1fromDateCash: [''],
        period2fromDateCash: [''],
        period1ThroughDateCash: [''],
        period2ThroughDateCash: [''],
        period1FiscalTypeCash: [''],
        period2FiscalTypeCash: [''],
        
      });
      this.comparativeIncomeStatement=this._FormBuilder.group({
        period1fromDate: [''],
        period2fromDate: [''],
        period1throughDate: [''],
        period2throughDate: [''],
        period1FiscalType: [''],
        period2FiscalType: [''],
        
      });
      this.cashFlowStatement=this._FormBuilder.group({
        monthTotalCash: [''],
        fromDateCash: [''],
        throughDateCash: [''],
        FiscalGlTypeCash: [''],
    
        
      });
      this.incomeStatement=this._FormBuilder.group({
        monthTotalIncome: [''],
        fromDateIncome: [''],
        throughDateIncome: [''],
        FiscalGlTypeIncome: [''],
    
        
      });
      this.totalTransaction=this._FormBuilder.group({
        monthTotal: [''],
        fromDateTotal: [''],
        througDateTotal: [''],
        FiscalGlTypeTotal: [''],
    
        
      });
      this.createReconAccountForm = this._FormBuilder.group({
        glAccountId: [''],
        fromDate: [''],
        throughDate: [''],
    
        
      });
      this.costCenterForm= this._FormBuilder.group({
        costFromDate: [''],
        costThroughDate: [''],
        costFiscal: [''],
    
        
      });
      this.trialBalance = this._FormBuilder.group({
        customeTime: ['']
    
        
      });
      
      this.createTransForm = this._FormBuilder.group({
        acctTrans: [''],
        transType: [''],
        fiscalType: [''],
        glJournal: [''],
        posted: [''],
        invoiceId: [''],
        paymentId: [''],
        productId: [''],
        workEffortId: [''],
        shipId: [''],
        fromDate: [''],
        throughDate: [''],
    
        
      });
      this.createTransEntForm = this._FormBuilder.group({
        acctEnt: [''],
        glAccEnt: [''],
        transTypeEnt: [''],
        fiscalTypeEnt: [''],
        glJournalEnt: [''],
        postedEnt: [''],
        partyEnt: [''],
        invoiceIdEnt: [''],
        paymentIdEnt: [''],
        productIdEnt: [''],
        workEffortIdEnt: [''],
        shipIdEnt: [''],
        fromDateEnt: [''],
        throughDateEnt: [''],
        report: [''],
    
        
      });
      this.posted=[{
        'label':'Yes',
        'value':'Y'
      },
      {
        'label':'No',
        'value':'N'
      }]
      this.postedAll=[{
        'label':'Yes',
        'value':'Y'
      },
      {
        'label':'No',
        'value':'N'
      },
      {
        'label':'All',
        'value':'ALL'
      }]
     }
     ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
    });

    this.getGlAccountInitialData();
    this.initialTransData();
    this.getProducts();
    this.getWorkEffortList();
    this.getFacilityShipments();
    this.getPaymentsList();
    this.getInvoices();
    this.getPartyId();
    this.getCustomTimePeriodListDetail();
    this.getFacility();
    this.getCheckDetails();
  }


backWindow(){
  this._location.back();
}
  toDashboard()
{
this.router.navigate(['/financial/main']);
}
  resetReconciliationsAccount(){
    this.createAccountRecon.reset();

  }
  resetRconlForm(){
this.createReconAccountForm.reset();
  }
  resetTransForm(){
    this.createTransForm.reset();
  }
  resetTransFormEntry(){
    this.createTransEntForm.reset();
  }
  cancelSubmit(){
    this.router.navigate(["/financial/financial-check"])
  }  
  getFacility(): void {
    this.spinner.show();
    this._WarehouseService.getFacility()
      .then(data => {

        this.spinner.hide()
        this.facilities = data.data.map(value => {
          return {
            label: value.facilityName,
            value: value.facilityId
          };
        });
      });
     
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    this.spinner.hide();
  }
 
  downloadPDF(id): void {
    this.spinner.show();
    this.downloading = true;
    
    this.accountsService.generateTransactionDetailsPdf(this.partyId,id)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'application/application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
           link.download = `${id}.pdf`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });
      
}
downloadFullPDF(): void {
  this.spinner.show();
  this.id=''
  this.downloading = true;
  
  this.accountsService.generateTransactionsPdf(this.partyId,this.id)
      .subscribe(value => {
          const blob = new Blob([value], { type: 'application/application/pdf' });
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob);
              return;
          }
          const data = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = data;
          this.spinner.hide();
         link.download = `trans.pdf`;
          link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
          setTimeout(() => {
              window.URL.revokeObjectURL(data);
              link.remove();
          }, 100);
          this.downloading = false;
      });
    
}
  getWorkEffortList(): void {
    this.spinner.show();
    this.accountsService.getWorkEfforts().subscribe((res) => {
      const workEfforts = res.data;
      this.spinner.hide();
      for (const value of workEfforts) {
        this.workEffortArray.push({
          label: value.workEffortName + " [" + value.workEffortId + "]",
          value: value.workEffortId,
        });
      }
    });
   
  }
  getGenerateCsvTrialBalance(): void {
    this.spinner.show();
    this.id=''
    this.downloading = true;
    
    this.accountsService.getGenerateCsvTrialBalance(this.partyId,this.trialBalance.value.customeTime)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'text/csv;charset=utf-8;' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
           link.download = `trailBalance.csv`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });
      
  }
  
  getGenerateCsvtransactionTotals(): void {
    this.spinner.show();
    this.id=''
    this.downloading = true;
    const fromDateTotal=this.totalTransaction.get('fromDateTotal').value;
    this.fromDateTotal=this.datePipe.transform(fromDateTotal,"yyyy-MM-dd hh:mm:ss");
    const througDateTotal=this.totalTransaction.get('througDateTotal').value;
    this.througDateTotal=this.datePipe.transform(througDateTotal,"yyyy-MM-dd hh:mm:ss");
    const req= {
      "fromDate": this.fromDateTotal, "glFiscalTypeId": this.totalTransaction.value.FiscalGlTypeTotal,
      "selectedMonth": this.totalTransaction.value.monthTotal, "thruDate": this.througDateTotal
    }
    this.accountsService.getGenerateCsvtransactionTotals(this.partyId,req)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'text/csv;charset=utf-8;' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;this.spinner.hide();
           link.download = `toatlTrans.csv`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });  }
  getgenerateCsvIncomeStatements(): void {
    this.spinner.show();
    this.id=''
    this.downloading = true;
    const fromDateIncome=this.incomeStatement.get('fromDateIncome').value;
    this.fromDateIncome=this.datePipe.transform(fromDateIncome,"yyyy-MM-dd hh:mm:ss");
    const throughDateIncome=this.incomeStatement.get('throughDateIncome').value;
    this.throughDateIncome=this.datePipe.transform(throughDateIncome,"yyyy-MM-dd hh:mm:ss");
    const req= {
      "fromDate": this.fromDateIncome,
      "glFiscalTypeId": this.incomeStatement.value.FiscalGlTypeIncome,
      "selectedMonth": this.incomeStatement.value.monthTotalIncome,
      "thruDate": this.throughDateIncome
    }
    this.accountsService.getgenerateCsvIncomeStatements(this.partyId,req)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'text/csv;charset=utf-8;' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return; }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;this.spinner.hide();
           link.download = `incomeStatement.csv`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100); this.downloading = false;
        });   }
  
  getgenerateCsvcashflowStatements(): void {
    this.spinner.show();
    this.id=''
    this.downloading = true;
    const fromDateCash=this.cashFlowStatement.get('fromDateCash').value;
    this.fromDateCash=this.datePipe.transform(fromDateCash,"yyyy-MM-dd hh:mm:ss");
    const throughDateCash=this.cashFlowStatement.get('throughDateCash').value;
    this.throughDateCash=this.datePipe.transform(throughDateCash,"yyyy-MM-dd hh:mm:ss");
    const req={
      "fromDate": this.fromDateCash,
      "glFiscalTypeId": this.cashFlowStatement.value.FiscalGlTypeCash,
      "selectedMonth": this.incomeStatement.value.monthTotalCash,
      "thruDate": this.throughDateCash
    }
    this.accountsService.getgenerateCsvcashflowStatements(this.partyId,req)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'text/csv;charset=utf-8;' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return; }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;this.spinner.hide();
           link.download = `cahFlowStatement.csv`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false; });}
   
  getCSvbalanceSheet(): void {
    this.spinner.show();
    this.id=''
    this.downloading = true;
    const throughDateBalance=this.balanceSheetStatement.get('throughDateBalance').value;
    this.throughDateBalance=this.datePipe.transform(throughDateBalance,"yyyy-MM-dd hh:mm:ss");
    const req= {
      "glFiscalTypeId": this.balanceSheetStatement.value.FiscalGlTypeBalance,
      "thruDate": this.throughDateBalance
    }
    this.accountsService.getCSvbalanceSheet(this.partyId,req)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'text/csv;charset=utf-8;' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
           link.download = `balanceSheet.csv`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        }); 
  }
  
     
  getCSvcomparativeIncomeStatements(): void {
    this.spinner.show();
    this.id=''
    this.downloading = true;
    const period1fromDate=this.comparativeIncomeStatement.get('period1fromDate').value;
    this.period1fromDate=this.datePipe.transform(period1fromDate,"yyyy-MM-dd hh:mm:ss");
    const period2fromDate=this.comparativeIncomeStatement.get('period2fromDate').value;
    this.period2fromDate=this.datePipe.transform(period2fromDate,"yyyy-MM-dd hh:mm:ss");
    const period1throughDate=this.comparativeIncomeStatement.get('period1throughDate').value;
    this.period1throughDate=this.datePipe.transform(period1throughDate,"yyyy-MM-dd hh:mm:ss");
    const period2throughDate=this.comparativeIncomeStatement.get('period2throughDate').value;
    this.period2throughDate=this.datePipe.transform(period2throughDate,"yyyy-MM-dd hh:mm:ss");
    const req= {
      "period1FromDate":  this.period1fromDate,"period1GlFiscalTypeId": this.comparativeIncomeStatement.value.period1FiscalType,
      "period1ThruDate": this.period1throughDate, "period2FromDate": this.period2fromDate,
      "period2GlFiscalTypeId": this.comparativeIncomeStatement.value.period2FiscalType,"period2ThruDate": this.period2throughDate,  
    }
    this.accountsService.getCSvcomparativeIncomeStatements(this.partyId,req)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'text/csv;charset=utf-8;' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
           link.download = `comparativeIncomeSheet.csv`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });    
  }
  getgenerateCsvcomparativeCashFlowStatement(): void {
    this.spinner.show();
    this.id=''
    this.downloading = true;
    const period1fromDateCash=this.comparativeCashFlowStatement.get('period1fromDateCash').value;
    this.period1fromDateCash=this.datePipe.transform(period1fromDateCash,"yyyy-MM-dd hh:mm:ss");
    const period2fromDateCash=this.comparativeCashFlowStatement.get('period2fromDateCash').value;
    this.period2fromDateCash=this.datePipe.transform(period2fromDateCash,"yyyy-MM-dd hh:mm:ss");
    const period1ThroughDateCash=this.comparativeCashFlowStatement.get('period1ThroughDateCash').value;
    this.period1ThroughDateCash=this.datePipe.transform(period1ThroughDateCash,"yyyy-MM-dd hh:mm:ss");
    const period2ThroughDateCash=this.comparativeCashFlowStatement.get('period2ThroughDateCash').value;
    this.period2ThroughDateCash=this.datePipe.transform(period2ThroughDateCash,"yyyy-MM-dd hh:mm:ss");
    const req={
      "period1FromDate":  this.period1fromDateCash,
      "period1GlFiscalTypeId": this.comparativeCashFlowStatement.value.period1FiscalTypeCash,
      "period1ThruDate": this.period1ThroughDateCash,
      "period2FromDate": this.period2fromDateCash,
      "period2GlFiscalTypeId": this.comparativeCashFlowStatement.value.period2FiscalTypeCash,
      "period2ThruDate": this.period2ThroughDateCash,
    }
    this.accountsService.getgenerateCsvcomparativeCashFlowStatement(this.partyId,req)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'text/csv;charset=utf-8;' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
           link.download = `comparativeCashFlow.csv`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });  
  }
  
  getgenerateCsvinventoryValuation(): void {
    this.spinner.show();
    this.id=''
    this.downloading = true;
    const throughDate=this.inventoryValuation.get('throughDate').value;
    this.throughDateInvent=this.datePipe.transform(throughDate,"yyyy-MM-dd hh:mm:ss");
    const req={
      "facilityId": this.inventoryValuation.value.facilityId,
      "organizationPartyId":this.partyId,
      "productId":  this.inventoryValuation.value.productId,
      "thruDate": this.throughDateInvent
    }
    this.accountsService.getgenerateCsvinventoryValuation(req)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'text/csv;charset=utf-8;' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;this.spinner.hide();
           link.download = `inventory.csv`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });  }
  getcomparativeGenerateCSVBalanceSheet(): void {
    this.spinner.show();
    this.id=''
    this.downloading = true;
    const period1ThrougDateBalce=this.comparativeBalanceSheetStatement.get('period1ThrougDateBalce').value;
    this.period1ThrougDateBalce=this.datePipe.transform(period1ThrougDateBalce,"yyyy-MM-dd hh:mm:ss");
    const period2ThrougDateBalce=this.comparativeBalanceSheetStatement.get('period2ThrougDateBalce').value;
    this.period2ThrougDateBalce=this.datePipe.transform(period2ThrougDateBalce,"yyyy-MM-dd hh:mm:ss");
    const req={
      "period1GlFiscalTypeId": this.comparativeBalanceSheetStatement.value.period1FiscalTypeBalalnce,
      "period1ThruDate": this.period1ThrougDateBalce,
      "period2GlFiscalTypeId": this.comparativeBalanceSheetStatement.value.period2FiscalTypeBalalnce,
      "period2ThruDate": this.period2ThrougDateBalce,
    }
    this.accountsService.getcomparativeGenerateCSVBalanceSheet(this.partyId,req)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'text/csv;charset=utf-8;' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;this.spinner.hide();
           link.download = `comparativeBaanceSheet.csv`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false; });  }
  getgeneratePdfglAccountTrialBalance(): void {
    this.spinner.show();
    this.id=''
    this.downloading = true;
    
    const req={
      "glAccountId":  this.trialBalanceForm.value.glAccEntGl,
  "timePeriod":   this.trialBalanceForm.value.customeTime,
  "isPosted":  this.trialBalanceForm.value.postedY,
    }
    this.accountsService.getgeneratePdfglAccountTrialBalance(this.partyId,req)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'application/application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
           link.download = `trialBalance.pdf`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });
      
  }
  generatePdfmarkChecksSent(): void {
    this.spinner.show();
    this.id=''
    this.downloading = true;
    
   this.pdfReqData=this.payIdArrayWithData
    this.accountsService.generatePdfmarkChecksSent(this.partyId,this.pdfReqData)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'application/application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
           link.download = `check.pdf`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });
      
  }
  
  getgeneratePdfcostCenterAcc(): void {
    this.spinner.show();
    this.id=''
    this.downloading = true;
    const costFromDate=this.costCenterForm.get('costFromDate').value;
    this.costFromDate=this.datePipe.transform(costFromDate,"yyyy-MM-dd hh:mm:ss");
    const costThroughDate=this.costCenterForm.get('costThroughDate').value;
    this.costThroughDate=this.datePipe.transform(costThroughDate,"yyyy-MM-dd hh:mm:ss");
    const req={
      "glFiscalTypeId": this.costCenterForm.value.costFiscal,
      "thruDate":this.costThroughDate,
      "fromDate": this.costFromDate,
    }
    this.accountsService.getgeneratePdfcostCenterAcc(this.partyId,req)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'application/application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;}
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;this.spinner.hide();
           link.download = `costCenter.pdf`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });  }
  
  
  
  
  
  getCustomTimePeriodListDetail() {
    this.spinner.show();
   
    this.accountsService.getCustomTimePeriodListDetail(this.partyId).subscribe((res: any) => {

      this.timePerod = res.data;
      this.spinner.hide();
      for (const value of this.timePerod) {
        this.timePerodArray.push({
          label: value.fromDate +''+ value.thruDate,
          value: value.customTimePeriodId
        })
      }


    })
   

  }
  getInvoices() {
    this.spinner.show();
    const req = {
      "description": "",
      "descriptionSearchType": "Contains",
      "fromPartyId": "",
      "invoiceDateFrom": "",
      "invoiceDateFromSearchType": "Contains",
      "invoiceDateTo": "",
      "invoiceDateToSearchType": "Contains",
      "invoiceId": "",
      "invoiceIdSearchType": "Contains",
      "invoiceType": "PURCHASE_INVOICE",
      "statusId": "",
      "toPartyId": ""
    }
    this.accountsService.getInvoices(this.finSize, req).subscribe((res: any) => {
      this.invoiceList = res.data;
      this.spinner.hide();
      for (const value of this.invoiceList) {
        this.invoiceListArray.push({
          label: value.invoiceId,
          value: value.invoiceId
        })
      }
    })
  }
  getPaymentsList() {
    this.spinner.show();
    const req = {
      "amount": "",
  "comments": "",
  "commentsSearchType": "Contains",
  "fromPartyId": "",
  "paymentId": "",
  "paymentIdSearchType": "Contains",
  "paymentType": "",
  "status": "",
  "toPartyId": ""
     

    }
    this.accountsService.getPaymentsList(this.finSize, req).subscribe((res: any) => {

      this.PaymentsList = res.data;
      this.spinner.hide();
      for (const value of this.PaymentsList) {
        this.getPaymentsListArray.push({
          label: value.paymentId,
          value: value.paymentId
        })
      }


    })
   

  }
  getFacilityShipments() {
    this.spinner.show();
    const req = {
      "destinationFacility": "","entryDateFrom": "","entryDateFromSearchType": "Contains","entryDateTo": "",
  "entryDateToSearchType": "Contains",
  "estimatedShipDateFrom": "",
  "estimatedShipDateFromSearchType": "Contains",
  "estimatedShipDateTo": "",
  "estimatedShipDateToSearchType": "Contains",
  "originFacility": "",
  "shipmentId": "",
  "shipmentIdSearchType": "Contains",
  "shipmentType": "",
  "statusId": [
    ""
  ]
    }
    this.accountsService.getFacilityShipments(this.finSize, req).subscribe((res: any) => {
      this.FacilityShipments = res.data;
      this.spinner.hide();
      for (const value of this.FacilityShipments) {
        this.FacilityShipmentsArray.push({
          label: value.shipmentId,
          value: value.shipmentId
        })
      }
    })
  }
  setup(){
    this.router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:this.partyId } });
  }
  accounting(){
    this.router.navigate(['/financial/detail-accounting-summary'], { queryParams: { partyId:this.partyId } });
  }
  createSalesAccount(){
    this.router.navigate(['/financial/create-accounting-quick-transaction'], { queryParams: { partyId:this.partyId } });
  }

  trialBalanceCheck(){
    this.activeCategory=6;
   
  }
  balanceSheetList(){
    this.activeCategory=6;
    this.activeCategoryValue=6;
  }
  incomeStatemetList(){
    this.activeCategory=6;
    this.activeCategoryValue=4;

  }
  sendmarkChecksSent() {
    this.spinner.show();
  
      this.requestDataRecon= this.payIdArrayWith

    
   

    this.accountsService.sendmarkChecksSent(this.partyId,this.requestDataRecon).subscribe((res: any) => {
      
      if (res.success) {
       this.getCheckDetails();
        this.toastr.success("Send Successfully");
        this.spinner.hide();
       
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this.toastr.error(err.error.message);
        }
    });

  }
 
  
  markCheckPrintData(e){
  
    if (e.target.ariaChecked ==="true") {
       for (const r of this.getCheckDetailsValue) {
        
         this.payIdArrayWithData.push(r.paymentId);
       }
     }
     else {
       this.payIdArrayWithData = []
     } 
   }
   paymentData(product) {
  
    const index = this.payIdArrayWithData.findIndex(x => x == product)
    if (index === -1) {
     
      this.payIdArrayWithData.push(product);

    }
    else if (index === 0) {

      this.payIdArrayWithData.splice(index, 1);
    }
    else {
      this.payIdArrayWithData.splice(index, 1);

    }
  }
  markCheckPrint(e){
  
   if (e.target.ariaChecked === "true") {
      for (const r of this.getCheckDetailsValue) {
       
        this.payIdArrayWith.push(r.paymentId);
      }
    }
    else {
      this.payIdArrayWith = []
    } 
  }
  
  payment(product) {
  
    const index = this.payIdArrayWith.findIndex(x => x == product)
    if (index === -1) {
     
      this.payIdArrayWith.push(product);

    }
    else if (index === 0) {

      this.payIdArrayWith.splice(index, 1);
    }
    else {
      this.payIdArrayWith.splice(index, 1);

    }
  }
  
  getCheckDetails() {
    this.spinner.show();
   
    this.accountsService.getCheckDetails(this.partyId).subscribe((res: any) => {

      this.getCheckDetailsValue = res.data;
      this.spinner.hide();
    


    })
   

  }
 
  getcostCenterAcc() {
    this.spinner.show();
    const costFromDate=this.costCenterForm.get('costFromDate').value;
    this.costFromDate=this.datePipe.transform(costFromDate,"yyyy-MM-dd hh:mm:ss");
    const costThroughDate=this.costCenterForm.get('costThroughDate').value;
    this.costThroughDate=this.datePipe.transform(costThroughDate,"yyyy-MM-dd hh:mm:ss");
    const req={
      "glFiscalTypeId": this.costCenterForm.value.costFiscal,
      "thruDate":this.costThroughDate,
      "fromDate": this.costFromDate,
     
   
    }
    this.accountsService.getcostCenterAcc(this.partyId,req).subscribe((res: any) => {

      this.costCenterValue = res.data.costCenters;
      this.glAccountCategories=res.data.glAccountCategories
      this.spinner.hide();
    


    })
   

  }
  getinventoryValuation() {
    this.spinner.show();
    const throughDate=this.inventoryValuation.get('throughDate').value;
    this.throughDateInvent=this.datePipe.transform(throughDate,"yyyy-MM-dd hh:mm:ss");
    const req={
      "facilityId": this.inventoryValuation.value.facilityId,
      "organizationPartyId":this.partyId,
      "productId":  this.inventoryValuation.value.productId,
      "thruDate": this.throughDateInvent
   
    }
    this.accountsService.getinventoryValuation(this.finSize,req).subscribe((res: any) => {

      this.inventoryValuationData = res.data;
      this.spinner.hide();
    


    })
   

  }
  getProducts() {
    this.spinner.show();
    const req = {
      "internalName": "",
      "internalNameSearchType": "Contains",
      "productId": "",
      "productIdSearchType": "Contains"
     

    }
    this.accountsService.getProducts(this.finSize, req).subscribe((res: any) => {

      this.Products = res.data;
      this.spinner.hide();
      for (const value of this.Products) {
        this.ProductsArray.push({
          label: value.productName,
          value: value.productId
        })
      }


    })
   

  }
  initialTransData() {
    this.spinner.show();
    this.accountsService.initialTransData().subscribe((res: any) => {
      this.glFiscalType = res.data.glFiscalType;
      this.glJournal = res.data.glJournal;
      this.fixedAsset = res.data.fixedAsset;
      this.groupStatus = res.data.groupStatus;
      this.accTransactionType = res.data.accTransactionType;
      this.spinner.hide();
      for (const value of this.glFiscalType) {
        this.glFiscalTypeArray.push({
          label: value.description,
          value: value.glFiscalTypeId
        })
      }
      for (const value of this.glJournal) {
        this.glJournalArray.push({
          label: value.glJournalName,
          value: value.glJournalId
        })
      }
      for (const value of this.fixedAsset) {
        this.fixedAssetArray.push({
          label: value.fixedAssetId,
          value: value.fixedAssetId
        })
      }
      for (const value of this.groupStatus) {
        this.groupStatusArray.push({
          label: value.description,
          value: value.statusId
        })
      }
      for (const value of this.accTransactionType) {
        this.accTransactionTypeArray.push({
          label: value.description,
          value: value.acctgTransTypeId
        })
      }
    })
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }





    })
   
  }
  findReconciliationsAccount() {
    this.spinner.show();
    this.accountsService.findReconciliationsAccount(this.finSize,this.createAccountRecon.value.glAccountId,this.partyId).subscribe((res: any) => {
      this.ReconciliationsAccount = res.data;
      this.spinner.hide();
    })
  
  }
  
  
  getTrialBalance() {
    this.spinner.show();
  
  
    this.accountsService.getTrialBalance(this.partyId,this.trialBalance.value.customeTime).subscribe((res: any) => {

      this.trialBalanceList = res.data.accountBalances;
      this.postedCreditsTotal = res.data.postedCreditsTotal;
      this.postedDebitsTotal = res.data.postedDebitsTotal;
      this.spinner.hide();
    })
  
  }
 
  getbalanceSheet() {
    this.spinner.show();
   
    const throughDateBalance=this.balanceSheetStatement.get('throughDateBalance').value;
    this.throughDateBalance=this.datePipe.transform(throughDateBalance,"yyyy-MM-dd hh:mm:ss");
    const req={ 
      "glFiscalTypeId": this.balanceSheetStatement.value.FiscalGlTypeBalance, 
      "thruDate": this.throughDateBalance
    }
    this.accountsService.getbalanceSheet(this.partyId,req).subscribe((res: any) => {
      this.balanceSheetAssets = res.data.balanceSheetAssets;
      this.balanceSheetLiabilities=res.data.balanceSheetLiabilities;
      this.balanceSheetEquities=res.data.balanceSheetEquities;
      this.balanceTotalsBalance=res.data.balanceTotals;
      this.spinner.hide();
    })
  
  }
  
  getcomparativeBalanceSheet() {
    this.spinner.show();
   
    const period1ThrougDateBalce=this.comparativeBalanceSheetStatement.get('period1ThrougDateBalce').value;
    this.period1ThrougDateBalce=this.datePipe.transform(period1ThrougDateBalce,"yyyy-MM-dd hh:mm:ss");
    const period2ThrougDateBalce=this.comparativeBalanceSheetStatement.get('period2ThrougDateBalce').value;
    this.period2ThrougDateBalce=this.datePipe.transform(period2ThrougDateBalce,"yyyy-MM-dd hh:mm:ss");
    const req= { 
      "period1GlFiscalTypeId": this.comparativeBalanceSheetStatement.value.period1FiscalTypeBalalnce,
      "period1ThruDate": this.period1ThrougDateBalce,
      "period2GlFiscalTypeId": this.comparativeBalanceSheetStatement.value.period2FiscalTypeBalalnce,
      "period2ThruDate": this.period2ThrougDateBalce,
    }
    
    this.accountsService.getcomparativeBalanceSheet(this.partyId,req).subscribe((res: any) => {

      this.balanceSheetAssetsComp = res.data.balanceSheetAssets;
      this.balanceSheetLiabilitiesComp=res.data.balanceSheetLiabilities;
      this.balanceSheetEquitiesComp=res.data.balanceSheetEquities;
      this.balanceTotalsBalComp=res.data.balanceTotals;
      this.spinner.hide();
    })
  
  }
  getcomparativeCashFlowStatement() {
    this.spinner.show();
    const period1fromDateCash=this.comparativeCashFlowStatement.get('period1fromDateCash').value;
    this.period1fromDateCash=this.datePipe.transform(period1fromDateCash,"yyyy-MM-dd hh:mm:ss");
    const period2fromDateCash=this.comparativeCashFlowStatement.get('period2fromDateCash').value;
    this.period2fromDateCash=this.datePipe.transform(period2fromDateCash,"yyyy-MM-dd hh:mm:ss");
    const period1ThroughDateCash=this.comparativeCashFlowStatement.get('period1ThroughDateCash').value;
    this.period1ThroughDateCash=this.datePipe.transform(period1ThroughDateCash,"yyyy-MM-dd hh:mm:ss");
    const period2ThroughDateCash=this.comparativeCashFlowStatement.get('period2ThroughDateCash').value;
    this.period2ThroughDateCash=this.datePipe.transform(period2ThroughDateCash,"yyyy-MM-dd hh:mm:ss");
    const req={
      "period1FromDate":  this.period1fromDateCash,
      "period1GlFiscalTypeId": this.comparativeCashFlowStatement.value.period1FiscalTypeCash,
      "period1ThruDate": this.period1ThroughDateCash,
      "period2FromDate": this.period2fromDateCash,
      "period2GlFiscalTypeId": this.comparativeCashFlowStatement.value.period2FiscalTypeCash,
      "period2ThruDate": this.period2ThroughDateCash,   
    }
    this.accountsService.getcomparativeCashFlowStatement(this.partyId,req).subscribe((res: any) => {

      this.openingCashBalanceComp = res.data.openingCashBalance;
      this.periodCashBalanceComp=res.data.periodCashBalance;
      this.closingCashBalanceComp=res.data.closingCashBalance;
      this.balanceTotalsComp=res.data.balanceTotals;
      this.spinner.hide();
    })
  
  }
  getcomparativeIncomeStatements() {
    this.spinner.show();
    const period1fromDate=this.comparativeIncomeStatement.get('period1fromDate').value;
    this.period1fromDate=this.datePipe.transform(period1fromDate,"yyyy-MM-dd hh:mm:ss");
    const period2fromDate=this.comparativeIncomeStatement.get('period2fromDate').value;
    this.period2fromDate=this.datePipe.transform(period2fromDate,"yyyy-MM-dd hh:mm:ss");
    const period1throughDate=this.comparativeIncomeStatement.get('period1throughDate').value;
    this.period1throughDate=this.datePipe.transform(period1throughDate,"yyyy-MM-dd hh:mm:ss");
    const period2throughDate=this.comparativeIncomeStatement.get('period2throughDate').value;
    this.period2throughDate=this.datePipe.transform(period2throughDate,"yyyy-MM-dd hh:mm:ss");
    const req={
      "period1FromDate":  this.period1fromDate,
      "period1GlFiscalTypeId": this.comparativeIncomeStatement.value.period1FiscalType,
      "period1ThruDate": this.period1throughDate,
      "period2FromDate": this.period2fromDate,
      "period2GlFiscalTypeId": this.comparativeIncomeStatement.value.period2FiscalType,
      "period2ThruDate": this.period2throughDate,
      "fromDate": this.fromDateCash
   
    }
    this.accountsService.getcomparativeIncomeStatements(this.partyId,req).subscribe((res: any) => {
      this.comparativeIncomeStatementRevenues = res.data.comparativeIncomeStatementRevenues;
      this.comparativeIncomeStatementExpenses=res.data.comparativeIncomeStatementExpenses;
      this.comparativeIncomeStatementIncome=res.data.comparativeIncomeStatementIncome;
      this.comparativeBalanceTotals=res.data.comparativeBalanceTotals;
      this.spinner.hide();
    })
  
  }
  getcashflowStatements() {
    this.spinner.show();
    const fromDateCash=this.cashFlowStatement.get('fromDateCash').value;
    this.fromDateCash=this.datePipe.transform(fromDateCash,"yyyy-MM-dd hh:mm:ss");
    const throughDateCash=this.cashFlowStatement.get('throughDateCash').value;
    this.throughDateCash=this.datePipe.transform(throughDateCash,"yyyy-MM-dd hh:mm:ss");
    const req={
      "fromDate": this.fromDateCash,
      "glFiscalTypeId": this.cashFlowStatement.value.FiscalGlTypeCash,
      "selectedMonth": this.cashFlowStatement.value.monthTotalCash,
      "thruDate": this.throughDateCash
    }
    this.accountsService.getcashflowStatements(this.partyId,req).subscribe((res: any) => {

      this.openingCashBalance = res.data.openingCashBalance;
      this.periodCashBalance=res.data.periodCashBalance;
      this.closingCashBalance=res.data.closingCashBalance;
      this.balanceTotalsCash=res.data.balanceTotals;
      this.spinner.hide();
    })
  
  }
  
  getIncomeStatements() {
    this.spinner.show();
    const fromDateIncome=this.incomeStatement.get('fromDateIncome').value;
    this.fromDateIncome=this.datePipe.transform(fromDateIncome,"yyyy-MM-dd hh:mm:ss");
    const throughDateIncome=this.incomeStatement.get('throughDateIncome').value;
    this.throughDateIncome=this.datePipe.transform(throughDateIncome,"yyyy-MM-dd hh:mm:ss");
    const req= {
      "fromDate": this.fromDateIncome,
      "glFiscalTypeId": this.incomeStatement.value.FiscalGlTypeIncome,
      "selectedMonth": this.incomeStatement.value.monthTotalIncome,
      "thruDate": this.throughDateIncome
    }
    this.accountsService.getIncomeStatements(this.partyId,req).subscribe((res: any) => {

      this.incomeStatementRevenues = res.data.incomeStatementRevenues;
      this.incomeStatementExpenses=res.data.incomeStatementExpenses;
      this.incomeStatementIncome=res.data.incomeStatementIncome;
      this.balanceTotals=res.data.balanceTotals;
      this.spinner.hide();
    })
  
  }

  gettransactionTotals() {
    this.spinner.show();
    const fromDateTotal=this.totalTransaction.get('fromDateTotal').value;
    this.fromDateTotal=this.datePipe.transform(fromDateTotal,"yyyy-MM-dd hh:mm:ss");
    const througDateTotal=this.totalTransaction.get('througDateTotal').value;
    this.througDateTotal=this.datePipe.transform(througDateTotal,"yyyy-MM-dd hh:mm:ss");
    const req={
      "fromDate": this.fromDateTotal,
      "glFiscalTypeId": this.totalTransaction.value.FiscalGlTypeTotal,
      "selectedMonth": this.totalTransaction.value.monthTotal,
      "thruDate": this.througDateTotal
    }
    this.accountsService.gettransactionTotals(this.partyId,req).subscribe((res: any) => {

      this.postedTransactionTotals = res.data.postedTransactionTotals;
      this.unpostedTransactionTotals=res.data.unpostedTransactionTotals;
      this.allTransactionTotals=res.data.allTransactionTotals;
      this.spinner.hide();
    })
  
  }
  findAccountTransactionsEntries() {
    this.spinner.show();
    const fromActualEnt=this.createTransEntForm.get('fromDateEnt').value;
    this.actualfromEnt=this.datePipe.transform(fromActualEnt,"yyyy-MM-dd hh:mm:ss");
    const throughActualEnt=this.createTransEntForm.get('throughDateEnt').value;
    this.actualthroughEnt=this.datePipe.transform(throughActualEnt,"yyyy-MM-dd hh:mm:ss");
  
    const req={
      "acctgTransId": this.createTransEntForm.value.acctEnt,
      "acctgTransTypeId": this.createTransEntForm.value.transTypeEnt,
      "fromDate":  this.actualfromEnt,
      "glFiscalTypeId": this.createTransEntForm.value.fiscalTypeEnt,
      "glJournalId": this.createTransEntForm.value.glJournalEnt,
      "invoiceId": this.createTransEntForm.value.invoiceIdEnt,
      "isPosted": this.createTransEntForm.value.postedEnt,
      "organizationPartyId": this.partyId,
      "paymentId":this.createTransEntForm.value.paymentIdEnt,
      "productId": this.createTransEntForm.value.productIdEnt,
      "shipmentId":this.createTransEntForm.value.shipIdEnt,
      "thruDate":this.actualthroughEnt,
      "workEffortId": this.createTransEntForm.value.workEffortIdEnt,
    }
    this.accountsService.findAccountTransactionsEntries(this.finSize,req).subscribe((res: any) => {
     this.accountTransactionDetail=res.data
      this.spinner.hide();
    })
  
  }
  findTransactionDetails() {
    this.spinner.show();
    const fromActual=this.createReconAccountForm.get('fromDate').value;
    this.actualfrom=this.datePipe.transform(fromActual,"yyyy-MM-dd hh:mm:ss");
    const throughActual=this.createReconAccountForm.get('throughDate').value;
    this.actualthrough=this.datePipe.transform(throughActual,"yyyy-MM-dd hh:mm:ss");
    const req={
      "acctgTransId":  this.createTransForm.value.acctTrans,
      "acctgTransTypeId":  this.createTransForm.value.transType,
      "fromDate": this.actualfrom,
      "glFiscalTypeId":  this.createTransForm.value.fiscalType,
      "glJournalId": this.createTransForm.value.glJournal,
      "invoiceId":  this.createTransForm.value.invoiceId,
      "isPosted":  this.createTransForm.value.posted,
      "organizationPartyId":this.partyId,
      "paymentId":  this.createTransForm.value.paymentId,
      "productId":  this.createTransForm.value.productId,
      "shipmentId":  this.createTransForm.value.shipId,
      "thruDate":  this.actualthrough,
      "workEffortId":  this.createTransForm.value.workEffortId,
 
   
    }
    this.accountsService.findTransactionDetails(this.finSize,req).subscribe((res: any) => {
      this.transDeatils = res.data;
     
      this.spinner.hide();
    })
  
  }

  
  getglAccountTrialBalance() {
    this.spinner.show();
  
    const req={
      "glAccountId":  this.trialBalanceForm.value.glAccEntGl,
  "timePeriod":   this.trialBalanceForm.value.customeTime,
  "isPosted":  this.trialBalanceForm.value.postedY,
    }
    this.accountsService.getglAccountTrialBalance(this.partyId,req).subscribe((res: any) => {

      this.getTrialListDetails = res.data;
      this.transactionEntries=res.data.transactionEntries
      this.spinner.hide();
    })
  
  }
  postfindReconciliationsAccount() {
    this.spinner.show();
    const fromActualdate=this.createReconAccountForm.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createReconAccountForm.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const req={
      "glAccountId":  this.createReconAccountForm.value.glAccountId,
  "reconciledDateFrom":   this.actualfromdate,
  "reconciledDateTo":  this.actualthroughdate
    }
    this.accountsService.PostfindReconciliationsAccount(this.finSize,this.createAccountRecon.value.glAccountId,this.partyId,req).subscribe((res: any) => {

      this.postfindReconciliation = res.data;
      this.spinner.hide();
    })
  
  }
  
  
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  changeactiveCategoryFunctionSubMenuCheck(tab: number) {
    this.activeCategoryCheck = tab;
  }
  changeactiveCategoryFunctionSubMenu(tab: number) {
    this.activeCategoryValue = tab;
  }
  depositCheckboxClickWith(product) {
   
    const index = this.accontArrayRecon.findIndex(x => x.acctgTransEntrySeqId == product.acctgTransEntrySeqId)
    if (index === -1) {
      const req={
        "acctgTransEntrySeqId": product.acctgTransEntrySeqId,
  "acctgTransId":product.acctgTransId,
  "amount": product.amount,
  "glAccountId": product.glAccountId==null?"":product.glAccountId,
  "partyId": product.partyId==null?"":product.partyId,
  "productId": product.productId==null?"": product.productId,
      }
      this.accontArrayRecon.push(req);
    }
    else if (index === 0) {

      this.payIdArrayWith.splice(index, 1);
    }
    else {
      this.payIdArrayWith.splice(index, 1);

    }
  }
  selectAllDepositWith(e) {
   
    if (e.target.ariaChecked === "true") {
      for (const r of this.ReconciliationsAccount) {
        const req={
          "acctgTransEntrySeqId": r.acctgTransEntrySeqId,
    "acctgTransId":r.acctgTransId,
    "amount": r.amount,
    "glAccountId": r.glAccountId==null?"":r.glAccountId,
    "partyId": r.partyId==null?"":r.partyId,
    "productId": r.productId==null?"":r.productId,
        }
        this.accontArrayRecon.push(req);
      }
    }
    else {
      this.accontArrayRecon = []
    }
  }
  createAccount() {
    this.spinner.show();
    if(this.accontArrayRecon.length===0){
          this.requestDataRecon=[
          {
            "acctgTransEntrySeqId": "",
            "acctgTransId": "",
            "amount": "",
            "glAccountId": "",
            "partyId": "",
            "productId": ""
          }]
    }else{
      this.requestDataRecon= this.accontArrayRecon
    }
    this.accountsService.postreconcialation(this.partyId, this.requestDataRecon).subscribe((res: any) => {  
      if (res.success) {
        this.glReconciliationId=res.data.glReconciliationId
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/financial/edit-account-reconcilation'],{ queryParams: { partyId:  this.partyId, glReconciliationId:this.glReconciliationId } });
      } }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        } }
        else{
          this.toastr.error(err.error.message);
        } }); }
  
  createAccTrans(){
    this.router.navigate(['/financial/create-accounting-transaction'],{ queryParams: { partyId:  this.partyId } });
  }
  updateEditTransactin(id){
    this.router.navigate(['/financial/create-accounting-transaction'],{ queryParams: { partyId:  this.partyId,acctran:id } });
  }

  createQuickAccount(){
    this.router.navigate(['/financial/create-accounting-quick-transaction'],{ queryParams: { partyId:  this.partyId } });

  }
  unpostedList(){
    this.router.navigate(['/financial/unposted-account-transaction'],{ queryParams: { partyId:this.partyId } });

  }
  detailTransaction(id){
    this.router.navigate(['/financial/detail-accounting-transaction'],{ queryParams: { partyId:this.partyId ,acctrans:id} });

  }
 
 
  ngOnDestroy(): void {
    this.spinner.hide();
      }
 
}

