import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
        import { Location } from '@angular/common';
        import { FeaturesService } from '../../features.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
@Component({
    selector: 'app-summary-page',
    templateUrl: './summary-page.component.html'
})
export class SummaryPageComponent implements OnInit {
    faEdit = faEdit;
    faTrash = faTrash;
    total: number;
    rowNumber: number;
    activeCategory = 1;
    pageNo: number;
    pageSize: number;
    rows: number;
    priceForm: FormGroup;
    priceType: any[];
    currency: any[];
    featureMaintenanceId: string;
    priceValue: any[];
    editMode: boolean;
    updateDateDate: string;
    updatetoDate: string;
    updateDateTo: string;
    constructor(
        readonly _FormBuilder: FormBuilder,
        readonly _ToastrService: ToastrService,
        readonly _FeaturesService: FeaturesService, 
          private _location: Location,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly spinner: NgxSpinnerService,
        readonly datePipe: DatePipe,
        readonly  _Router: Router,

    ) {
        this.priceForm = this._FormBuilder.group({
            currencyUomId: [''],
            fromDate: [''],
            price: [0, [Validators.required]],
            productPriceTypeId: ['', [Validators.required]],
            thruDate: ['']
        });
        this.featureMaintenanceId = '';
        this.priceType = [];
        this.currency = [];
        this.total = 0;
        this.rowNumber = 0;
        this.pageNo = 1;
        this.pageSize = 100;
        this.rows = 50;
        this.priceValue = [];
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.editMode = false;
        this.featureMaintenanceId = this._ActivatedRoute.snapshot.queryParams.featureMaintenanceId;
        this.getPriceValue();
        this.getCurrency();
        this.getPriceType();
    }  
    homeButton(){
        this._Router.navigate(['/facilities/Dashboard'])
      
      }
      cancelSubmit(){
        this._location.back();
      }
     
    
    getPriceValue(): void {
        this.spinner.show();
        this._FeaturesService.getPrice(this.featureMaintenanceId)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this.priceValue = data.data;
                }
            });
          
    }
    getPriceType(): void {
        this.spinner.show();
        this._FeaturesService.getPriceType()
            .then(data => {
                this.spinner.hide();
                this.priceType = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.productPriceTypeId
                    };
                });
            });
          
    }
    getCurrency(): void {
        this.spinner.show();
        this._FeaturesService.getCurrency()
            .then(data => {
                this.spinner.hide();
                this.currency = data.data.currencyList.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
            });
           
    }
    submit(): void {
        this.spinner.show();
        if (this.priceForm.valid) {
            this._FeaturesService.createPrice({
                ...this.priceForm.value, ...{
                    fromDate: moment(this.priceForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss'),
                    thruDate: moment(this.priceForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss')
                }
            }, this.featureMaintenanceId)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.priceForm.reset();
                        this.getPriceValue();
                        this.activeCategory = 2;
                    }
                });
        } else {
            this.spinner.hide();
            this._ToastrService.error('Please fill required fields');
        }
       
    }
    update(): void {
        this.spinner.show();
        if (this.priceForm.valid) {
            this._FeaturesService.updatePrice({
                ...this.priceForm.value, ...{
                    fromDate: moment(this.priceForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss'),
                    thruDate: moment(this.priceForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss')
                }
            }, this.featureMaintenanceId)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Updated');
                        this.spinner.hide();
                        this.priceForm.reset();
                        this.editMode = false;
                        this.getPriceValue();
                        this.activeCategory = 2;
                    }
                });
        } else {
            this.spinner.hide();
            this._ToastrService.error('Please fill required fields');
        }
      
    }
    reset(): void {
        this.priceForm.reset();
        this.activeCategory = 2;
    }
    delete(fromDate: string, currencyId: string, priceTypeId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._FeaturesService.deletePrice(this.featureMaintenanceId, fromDate, currencyId, priceTypeId)
            .then(data => {
                if (data.success) {
                    this.getPriceValue();
                }
            });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
           
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          })  }
    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    editForm(value: any) {
        this.editMode = true;
        this.updateDateDate = this.datePipe.transform(value.fromDate, "yyyy-MM-dd");
        this.updateDateTo = this.datePipe.transform(value.thruDate, "yyyy-MM-dd");
      
        const formValue = this.priceForm;
        formValue.patchValue({
            productPriceTypeId: value.productPriceTypeId,

            price: value.price,
            currencyUomId: value.currencyUomId,
            fromDate: this.updateDateDate,
            thruDate: this.updateDateTo,

        });
        this.activeCategory = 1;
    }
    changeactiveCategoryFunction(tab: number) {
        this.activeCategory = tab;
    }

    toBack()
    {
        this._Router.navigate(['/facilities/catalog/feature-category/detailed-summary-feature-category'])
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}