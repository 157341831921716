<div class="container-fluid main-container-wrapper">


    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               
                   <span class="color-black pl-1 titlePanels" style="cursor: pointer;">
                    Thesaurus
                    </span>
                   


                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
                

            </div>
            <div class=" bg-white color-grey">
                <div class="mb-5">
                    <div class="container-fluid" style="padding: 0;">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="display: none !important;padding-left: 9px; width: 100%; flex-wrap: wrap; display: flex; margin-bottom: 8px !important;">
                            <li [ngClass]="activeCat==1?'active':''" (click)="mainOpen()">
                                <a>Main</a>
                            </li>
                            <li [ngClass]="activeCat==2?'active':''" (click)="catalogOpen()">
                                <a>Catalogs</a>
                            </li>
                            <li [ngClass]="activeCat==3?'active':''" (click)="categoriesOpen()">
                                <a>Categories</a>
                            </li>
                            <li [ngClass]="activeCat==4?'active':''" (click)="productsOpen()">
                                <a>Products</a>
                            </li>
                            <li [ngClass]="activeCat==5?'active':''" (click)="featuresOpen()">
                                <a>Features</a>
                            </li>
                            <li [ngClass]="activeCat==6?'active':''" (click)="promosOpen()">
                                <a>Promos</a>
                            </li>
                            <li [ngClass]="activeCat==7?'active':''" (click)="priceRulesOpen()">
                                <a>Price Rules</a>
                            </li>
                            <li [ngClass]="activeCat==8?'active':''" (click)="storesOpen()">
                                <a>Stores</a>
                            </li>
                            <li [ngClass]="activeCat==9?'active':''" (click)="productStoreGroupsOpen()">
                                <a>Product Store Groups</a>
                            </li>
                            <li [ngClass]="activeCat==10?'active':''" (click)="thesaurusOpen()">
                                <a>Thesaurus</a>
                            </li>
                            <li [ngClass]="activeCat==11?'active':''" (click)="reviewsOpen()">
                                <a>Reviews</a>
                            </li>
                            <li [ngClass]="activeCat==12?'active':''" (click)="configurationsOpen()">
                                <a>Configurations</a>
                            </li>
                            <li [ngClass]="activeCat==13?'active':''" (click)="subscriptionsOpen()">
                                <a>Subscriptions</a>
                            </li>
                            <li [ngClass]="activeCat==14?'active':''" (click)="shippingOpen()">
                                <a>Shipping</a>
                            </li>
                            <li [ngClass]="activeCat==15?'active':''" (click)="imageManagementOpen()">
                                <a>Image Management</a>
                            </li>
                        </ul>
                    </div>
                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                        <div class="w3-card-4 classCard">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Alternate KeyWord Thesaurus</a></li>

                            </ul>


                            <div class="create-new-leade mt-2">
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="thesaurusForm">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Keywords
                                                        </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" formControlName="alternateKeyword" placeholder="Enter Keywords">
                                                        </div>
                                                        <div class="col-lg-3 form-group classInputA">
                                                            <label for="exampleInputEmail1">Alternate
                                                    </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <input type="text" class="form-control" formControlName="enteredKeyword" placeholder="Enter Alternate">
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12">
                                                    <div class="row">

                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Relationship
                                                        </label>
                                                        </div>
                                                        <div class="col-lg-2">
                                                            <p-dropdown [options]="relationshipList" formControlName="relationshipEnumId" placeholder="Relationship">
                                                            </p-dropdown>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="suppliers-wrapper own-merge-supplier" style="margin-left: 54%;">
                                                    <div class="d-flex flex-row">
                                                        <button type="button" (click)="submitThesaurusForm()" class="btn btn-outline-secondary active">Add</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-danger ml-2" (click)="reset()">Cancel</button>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-12">
                                                    <div class="alphabetic-counts mt-20 mb-20">
                                                        <div class="albha" (click)="editKeywordThesaurus('a')">A</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('b')">B</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('c')">C</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('d')">D</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('e')">E</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('f')">F</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('g')">G</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('h')">H</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('i')">I</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('j')">J</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('k')">K</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('l')">L</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('m')">M</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('n')">N</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('o')">O</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('p')">P</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('q')">Q</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('r')">R</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('s')">S</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('t')">T</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('u')">U</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('v')">V</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('w')">W</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('x')">X</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('y')">Y</div>
                                                        <div class="albha" (click)="editKeywordThesaurus('z')">Z</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="container">
                                        <ng-template ngFor let-product [ngForOf]="editKeywords" let-i="index">
                                            <div class="d-flex flex-row card">
                                                <div class="col-md-8 mt-2">
                                                    <span>{{product.enteredKeyword}} <a *ngIf="!this.Hidebtn" class="text-danger ml-2"
                                                        (click)="deleteAll(product.enteredKeyword)">
                                                        <fa-icon [icon]="faTrash"></fa-icon>
                                                    </a>
                                                </span>
                                                    <div class="d-flex bd-highlight mb-3">
                                                        <div class="form-group p-2 bd-highlight">
                                                            <label for="alternate">Alternate <span
                                                                class="text-danger">*</span></label>
                                                            <input type="text" class="form-control" [id]="'alternate'+i" [name]="'alternate'+i" required [(ngModel)]="array[i].alternate" [ngModelOptions]="{standalone:true}" #alternate="ngModel">
                                                            <small class="text-danger" [hidden]="array[i].alternate">Alternate
                                                            is
                                                            required.</small>
                                                        </div>
                                                        <div class="form-group ml-2 p-2 bd-highlight">
                                                            <div class="d-flex flex-column">
                                                                <label for="relation">Relationship</label>
                                                                <p-dropdown [options]="relationshipList" placeholder="Relationship" filter="true" [(ngModel)]="array[i].relation" [ngModelOptions]="{standalone:true}"></p-dropdown>
                                                            </div>

                                                        </div>
                                                        <div class="form-group ml-auto p-2 bd-highlight">
                                                            <label for=""></label>
                                                            <button class="btn btn-primary mt-4" (click)="addFromList(product.enteredKeyword,i)">Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 mt-2">
                                                    <ng-template ngFor let-file [ngForOf]="product.keyword">
                                                        <a *ngIf="!this.Hidebtn" class="text-danger" (click)="deleteAlternate(file.alternateKeyword,file.enteredKeyword)">
                                                            <fa-icon [icon]="faTrash"></fa-icon>
                                                        </a>
                                                        {{file.alternateKeyword}}
                                                    </ng-template>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>