<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Manage Portal Pages</span>
            </div>

            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100">
                            <div class="w3-card-4 classCardView">
                                <div class="header-tabs">
                                    <h4 class=" common-styling" style="margin-left: 7px;">
                                        Edit Communication Event
                                    </h4>


                                </div>
                                <div class="panel-body">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="form-group">
                                            <div class="row">
                                                <form class="w-100" [formGroup]="createCommEvent">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">
                                                                        Communication Event ID

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="text" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Communication Event ID"
                                                                        formControlName="commEventId" readonly>

                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">

                                                                        Parent Comm Event ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="parentCommEventArray"
                                                                        optionlabel="label"
                                                                        placeholder="Parent Comm Event ID" filter="true"
                                                                        formControlName="parCommEventID">

                                                                    </p-dropdown>

                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">

                                                                        Comm.Type ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown placeholder="Comm.Type ID"
                                                                        [options]="comtypeIdArray" optionlabel="label"
                                                                        filter="true" formControlName="commTypeId">

                                                                    </p-dropdown>

                                                                </div>

                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">

                                                                        Status</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="stausIdArray"
                                                                        optionlabel="label" placeholder="Status"
                                                                        filter="true" formControlName="status">

                                                                    </p-dropdown>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">

                                                                        Party From</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown
                                                                        (onChange)="contactEventMech($event.value)"
                                                                        [options]="partyIdArray" optionlabel="label"
                                                                        placeholder="Party From" filter="true"
                                                                        formControlName="partyFrom">

                                                                    </p-dropdown>

                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">

                                                                        Party To</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown
                                                                        (onChange)="contactEventMechTo($event.value)"
                                                                        [options]="partyIdArray" optionlabel="label"
                                                                        placeholder="Party To" filter="true"
                                                                        formControlName="partyTo">

                                                                    </p-dropdown>

                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">

                                                                        Contact Mech Type ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="contactMechTypeArray"
                                                                        optionlabel="label"
                                                                        placeholder="Contact Mech Type ID" filter="true"
                                                                        formControlName="conMechId">

                                                                    </p-dropdown>


                                                                </div>

                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">

                                                                        From Contact Mech</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="contactMechArray"
                                                                        optionlabel="label"
                                                                        placeholder="From Contact Mech" filter="true"
                                                                        formControlName="fromContactMechId">

                                                                    </p-dropdown>


                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">

                                                                        To Contact Mech</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="contactMechToArray"
                                                                        optionlabel="label"
                                                                        placeholder="To Contact Mech" filter="true"
                                                                        formControlName="toContactMechId">

                                                                    </p-dropdown>


                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">

                                                                        Role Type Id From</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="roleTypeIdArray"
                                                                        optionlabel="label"
                                                                        placeholder="Role Type Id From" filter="true"
                                                                        formControlName="roleTypeFrom">

                                                                    </p-dropdown>


                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">

                                                                        Role Type Id To</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="roleTypeIdArray"
                                                                        optionlabel="label"
                                                                        placeholder="Role Type Id To" filter="true"
                                                                        formControlName="roleTypeTo">

                                                                    </p-dropdown>


                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">

                                                                        Contact List ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="contactListIdArray"
                                                                        optionlabel="label"
                                                                        placeholder="Contact List ID" filter="true"
                                                                        formControlName="contactListId">

                                                                    </p-dropdown>


                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Start Date

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Start Date"
                                                                        formControlName="startdate">


                                                                </div>

                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1"> Finish Date/Time

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Finish Date/Time"
                                                                        formControlName="finishDate">

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Subject

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Subject" formControlName="subject">

                                                                </div>
                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">

                                                                        Reason Enum ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown [options]="reasonenumArray"
                                                                        optionlabel="label" placeholder="Reason Enum ID"
                                                                        filter="true" formControlName="reasonEnumId">

                                                                    </p-dropdown>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">

                                                                        Content Mime Type ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown placeholder="Content Mime Type ID"
                                                                        filter="true" [options]="contentMMArray"
                                                                        optionlabel="label"
                                                                        formControlName="contentMimeTypeId">

                                                                    </p-dropdown>
                                                                </div>

                                                                <div class="col-lg-3 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">Content

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        placeholder="Content" formControlName="content">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group rightForm">
                                                                    <label for="exampleInputEmail1">Note

                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp" placeholder="Note"
                                                                        formControlName="note">
                                                                </div>


                                                            </div>
                                                        </div>

                                                    </div>


                                                </form>
                                                <div class="main-submit-button" style="margin-left: -25% !important;">
                                                    <button (click)="onUpdateEvent()" type="submit"
                                                        class="btn btn-secondary submit-btn">Save</button>


                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="w3-card-4 classCard w-100 mt-3">
                                <div
                                    class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                    <p class="h-text">Others and General Communication Events</p>

                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                    <div class="w3-card-4 classCard w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12 col-12">
                                                <div class="form-group">
                                                    <div class="card own-account-table">
                                                        <p-table [value]="" [paginator]="true" [rows]="rows"
                                                            scrollWidth="100%"
                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                            [totalRecords]="total" [scrollable]="true"
                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'150px'}"
                                                                        pSortableColumn="code">
                                                                        <div class="checkbox-align"
                                                                            style="color: white;">
                                                                            <p-checkbox styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Edit
                                                                            <p-sortIcon field="code">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Name
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Description
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Original Page
                                                                            <p-sortIcon field="name">
                                                                            </p-sortIcon>
                                                                        </div>
                                                                        <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'150px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span
                                                                                 class="account-button"  >
                                                                                Edit
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.portalPageName}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.description}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        {{product.originalPortalPageId}}
                                                                    </td>


                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                            <h4 class="ml-2">No Record Found</h4>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <p class="paginate_data">
                                                            View per page </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w3-card-4 classCard w-100 mt-2">

                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                    <div class="w3-card-4 classCardView w-100 mt-2">
                                        <div class="header-tabs" >
                                            <h4 class="common-styling hleft">
                                                Portlet(My Communication)
                                            </h4>
                                        </div>
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                            <p class="h-text">Portlet : [Calendar]
                                                <button type="button" class="btn btn-secondary submit-btn ml-2"
                                                    data-toggle="modal" data-target="#addCommAdminpopup"
                                                    (click)="allPage()">All Communications</button>
                                                <button type="button" class="btn btn-secondary submit-btn ml-2"
                                                    data-toggle="modal" data-target="#addCommRolespopup"
                                                    (click)="notePage()">New Message</button>
                                                <button type="button" class="btn btn-secondary submit-btn ml-2"
                                                    data-toggle="modal" data-target="#addCommContentpopup"
                                                    (click)="emailPage()">New Email</button>
                                            </p>
                                        </div>
                                        <div class="port-header-contact create-lead font-medium mb-0 w-100"
                                            style="align-items: initial;">
                                            <div style="width: 49%; float: left;">

                                                <div class="w3-card-4 classCardView w-100 mt-2">
                                                    <div class="header-tabs" >
                                                        <h4 class=" common-styling hleft">
                                                            Add/modify a calendar event
                                                        </h4>
                                                    </div>

                                                    <form [formGroup]="calendarEventForm1">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Event
                                                                            Name</label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <input type="text" formControlName="eventName"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Event Name">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label
                                                                            for="exampleInputEmail1">Desription</label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <input type="text" formControlName="desc"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Desription">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Event Type
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <p-dropdown formControlName="eventType"
                                                                            [options]="EventTypeArray"
                                                                            placeholder="Enter Event Type"
                                                                            filter="true">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Status
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <p-dropdown formControlName="status"
                                                                            [options]="CalStatusListArray"
                                                                            placeholder="Enter Status" filter="true">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Scope
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <p-dropdown formControlName="scope"
                                                                            [options]="ScopeListArray"
                                                                            placeholder="Enter Scope" filter="true">
                                                                        </p-dropdown>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Estimated Start
                                                                            Date</label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <input type="date" formControlName="startDate"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Estimated Start Date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Estimated
                                                                            Completion date</label>
                                                                    </div>
                                                                    <div class="col-lg-4">
                                                                        <input type="date" formControlName="compDate"
                                                                            class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp"
                                                                            placeholder="Enter Estimated Completion date">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-10 main-submit-button">
                                                            <button type="submit" class="btn btn-secondary submit-btn"
                                                                (click)="submit()">{{isUpdate ? 'Update' :
                                                                'Add'}}</button>

                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                            <div style="width: 49.8%; float: right;">
                                                <p class="h-text">Related Information</p>
                                                <div class="w3-card-4 classCardView w-100 mt-2">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper" style="justify-content: space-between;">
                                                                <p class="h-text">Participants</p>
                                                                <span>
                                                           </span>
                                                            </div>
                                                            <form [formGroup]="ParticipantsForm">
                                                                <div class="row">
                                                                  
                                                                    <div class="col-md-5 ml-4">
                                                                        <div class="form-group">
                                                                            <div class="d-flex flex-column">
                                                                                <label for="training">
                                                                                    Party ID</label>
                                                                                <p-dropdown [options]="PartyIdArray" filter="true"
                                                                                 formControlName="PartyId" placeholder=" Party ID"> </p-dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-5 ml-4">
                                                                        <div class="form-group">
                                                                            <label for="description">Role Type ID <span class="text-danger">*</span></label>
                                                                            <p-dropdown [options]="CalenderRoleArray" filter="true" formControlName="RoleTypeID" 
                                                                            placeholder="Role Type ID"> </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                 
                                                                    <div class="col-md-10">
                                                                        <div class="d-flex flex-row-reverse bd-highlight">
                                                                            <button class="btn btn-secondary cursor-pointer ml-2 p-2 bd-highlight"
                                                                             (click)="createMyPortalParticipants()">Add</button>
                                                                            <!-- <button class="btn btn-primary cursor-pointer ml-2 p-2 bd-highlight" (click)="submit()">{{isUpdate ? 'Update' : 'Add'}}</button> -->
                                                                        </div>
                                                                    </div>
    
    
                                                                </div>
                                                            </form>
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="ParticipantsList"
                                                                        [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width': '180px'}"
                                                                                    pSortableColumn="code">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Party ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{ 'width': '180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Role Type ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th [ngStyle]="{ 'width': '180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Status ID
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>

                                                                                <th [ngStyle]="{ 'width': '180px'}"
                                                                                    pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Remove
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default
                                                                                ui-corner-all" value=""
                                                                                        autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{ 'width': '180px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </p-checkbox>
                                                                                        <span>{{product.partyId}}</span>
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{ 'width': '180px'}">
                                                                                    <span>{{product.roleTypeId}}</span>
                                                                                </td>
                                                                                <td [ngStyle]="{ 'width': '180px'}">
                                                                                    <span>{{product.statusId}}</span>
                                                                                </td>

                                                                                <td [ngStyle]="{ 'width': '180px'}">
                                                                                    <span>
                                                                                        <a style="color: #0d3769!important;"
                                                                                            (click)="deleteMyPortalAParticipants(product)"
                                                                                             class="account-button" >Remove</a>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                    <div class="w3-card-4 classCardView w-100 mt-2">
                                        <div class="">
                                            <div class="row text-center d-flex align-items-center"
                                                style="padding-top:20px">
                                                <div class="col-md-4 d-flex" style="padding-left:30px">
                                                    <div class="btn-group">
                                                        <div class="btn btn-primary" mwlCalendarPreviousView
                                                            [view]="view" [(viewDate)]="viewDate"
                                                            (viewDateChange)="closeOpenMonthViewDay()">
                                                            Previous
                                                        </div>
                                                        <div class="btn btn-outline-secondary" mwlCalendarToday
                                                            [(viewDate)]="viewDate">
                                                            Today
                                                        </div>
                                                        <div class="btn btn-primary" mwlCalendarNextView [view]="view"
                                                            [(viewDate)]="viewDate"
                                                            (viewDateChange)="closeOpenMonthViewDay()">
                                                            Next
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <h3 class="mb-0">{{ viewDate | calendarDate:(view +
                                                        'ViewTitle'):'en' }}</h3>
                                                </div>
                                                <div class="col-md-4 d-flex justify-content-end"
                                                    style=" padding-right:40px">
                                                    <div class="btn-group">
                                                        <div class="btn btn-primary"
                                                            (click)="setView(CalendarView.Month)"
                                                            [class.active]="view === CalendarView.Month">
                                                            Month
                                                        </div>
                                                        <div class="btn btn-primary"
                                                            (click)="setView(CalendarView.Week)"
                                                            [class.active]="view === CalendarView.Week">
                                                            Week
                                                        </div>
                                                        <div class="btn btn-primary" (click)="setView(CalendarView.Day)"
                                                            [class.active]="view === CalendarView.Day">
                                                            Day
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div [ngSwitch]="view">
                                                <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month"
                                                    [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                    [activeDayIsOpen]="activeDayIsOpen"
                                                    (dayClicked)="dayClicked($event.day)"
                                                    (eventClicked)="handleEvent('Clicked', $event.event)"
                                                    (eventTimesChanged)="eventTimesChanged($event)">
                                                </mwl-calendar-month-view>
                                                <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week"
                                                    [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                    (eventClicked)="handleEvent('Clicked', $event.event)"
                                                    (eventTimesChanged)="eventTimesChanged($event)">
                                                </mwl-calendar-week-view>
                                                <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day"
                                                    [viewDate]="viewDate" [events]="events" [refresh]="refresh"
                                                    (eventClicked)="handleEvent('Clicked', $event.event)"
                                                    (eventTimesChanged)="eventTimesChanged($event)">
                                                </mwl-calendar-day-view>
                                            </div>

                                            <!-- Everything you see below is just for the demo, you don't need to include it in your app -->

                                            <br /><br /><br />

                                            <h3>
                                                <!-- <span class="text-black" *ngIf="isForm">Add/modify a calendar event</span> -->
                                                <button class="btn btn-primary float-right" (click)="addEvent()">
                                                    Add new
                                                </button>
                                                <div class="clearfix"></div>
                                            </h3>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="createPreferencePopup" tabindex="-1" role="dialog" aria-labelledby="createPreferencePopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Add a New Portal Page</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add a New Portal Page</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createPortal">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Portal Page Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="portalPageName"
                                                            placeholder="Enter Portal Page Name">
                                                    </div>

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="description"
                                                            placeholder="Enter Description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-4  main-submit-button" style="margin-left: 33%;">
                                                <button type="submit" (click)="createPortalPage()"
                                                    class="btn btn-secondary submit-btn">Create</button>

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>