
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EmpEmailPhoneModel } from './emp.upadate-email.model';



@Injectable({
  providedIn: 'root'
})

export class EmpEmailFormService {
    emp_email_form: FormGroup;

  constructor(public _formBuilder: FormBuilder,
    public empPhoneModel: EmpEmailPhoneModel
  ) { }

  createEventForm(data: any): void {
    if (!this.emp_email_form) {
      this.emp_email_form = this._formBuilder.group(this.empPhoneModel.mapDataToModel(data));

    }

  }

}

