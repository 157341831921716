<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Payroll</span>

               <span>
                <button type="submit" class="btn btn-secondary submit-btn mr-2"
                    (click)="toDashboard()">Home</button>
                <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
            </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="findPaycheck()"><a>
                                Find Paycheck</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="createPaychecks()" ><a>
                                Create Paycheck</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="paycheckConfiguration()" ><a>
                                Paycheck Configuration</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="employeess()"><a>
                                Employees</a></li>

                            <li [ngClass]="activeCategory==5?'active':''"  (click)="contractReferencees()"><a>
                                Contract Reference</a></li>
            
                            <li [ngClass]="activeCategory==6?'active':''" (click)="reports()"><a>
                                Reports</a></li>

                        </ul>
                    </div>
                    <div class="create-new-leade">
                        <div class="container-fluid" style="padding: 0;">
                            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                                <div class="w-100">
                                    <div *ngIf="activeCategory==2">
                                        <div class="row">
                                            <form class="w-100" [formGroup]="createPaycheck">
                                                <div class="w3-card-4 classCard mb-2">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                
                                                        Create Paycheck
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Paycheck Type</label>
                                                                </div>
                
                
                
                                                                <div class="col-lg-2 dropdowns" *ngIf="!show">
                                                                    <p-dropdown filter="true" formControlName="PaycheckType" name="PaycheckType" optionlabel="label" [options]="PaycheckTypeArray">
                
                                                                    </p-dropdown>
                                                                </div>
                
                
                
                                                                <div class="col-lg-2 dropdowns" *ngIf="show">
                                                                    <input type="email" disabled class="form-control small-input" [(ngModel)]="this.paycheckType" id="exampleInputEmail1" name="Amount" formControlName="PaycheckType" aria-describedby="emailHelp">
                
                
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputC">
                                                                    <label for="exampleInputEmail1">Pay to Party
                                                                        <span style="color: red;">*</span>
                                                                    </label>
                                                                </div>
                
                                                                <div class="col-lg-2 dropdowns">
                
                                                                    <p-dropdown filter="true" formControlName="PaytoParty" [options]="PaytoPartyArray" name="PaytoParty" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                
                
                                                      
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Payment Method</label>
                                                                </div>
                
                                                                <div class="col-lg-2 dropdowns">
                                                                    <p-dropdown filter="true" formControlName="PaymentMethod" [options]="PaymentMethodIDArray" name="PaymentMethod" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                
                                                            </div>
                                                        </div>
                
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Gross Amount
                                                                        <span style="color: red;">*</span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control small-input" id="exampleInputEmail1" name="Amount" formControlName="Amount" aria-describedby="emailHelp">
                
                
                                                                </div>
                
                                                                <div class="col-lg-2 dropdowns">
                                                                    <p-dropdown filter="true" name="GrossAmount" optionlabel="label" formControlName="GrossAmount" [options]="currencyArray">
                
                                                                    </p-dropdown>
                                                                </div>
                
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Effective Date
                                                                        <span style="color: red;">*</span>
                                                                    </label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" class="form-control small-input" id="exampleInputEmail1" name="EffectiveDate" formControlName="EffectiveDate" aria-describedby="emailHelp">
                
                
                                                                </div>
                                                                <div class="col-lg-1 time">
                                                                    <p-dropdown filter="true" [options]="hours" name="hour" optionlabel="label" formControlName="hour"> :
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-1 time">
                                                                    <p-dropdown filter="true" [options]="minute" name="minute" optionlabel="label" formControlName="minute">
                
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-1 time">
                                                                    <p-dropdown filter="true" formControlName="amPm" [options]="amPM" name="amPm" optionlabel="label">
                
                                                                    </p-dropdown>
                                                                </div>
                
                
                
                
                
                
                                                            </div>
                                                        </div>
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Comments</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control small-input" id="exampleInputEmail1" name="Comments" formControlName="Comments" aria-describedby="emailHelp">
                
                
                                                                </div>
                                                                <div class="col-lg-3 form-group classInputC">
                                                                    <label for="exampleInputEmail1">Check/Ref Num</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control small-input" id="exampleInputEmail1" name="RefNum" formControlName="RefNum" aria-describedby="emailHelp">
                
                
                                                                </div>
                
                
                
                                                            </div>
                                                        </div>
                
                                                     ]
                
                                                        <div class="col-lg-12">
                                                            <div class="row">
                
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Number of Days</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control small-input" id="exampleInputEmail1" name="RefNum" formControlName="numberDays" aria-describedby="emailHelp">
                
                
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputC">
                                                                    <label for="exampleInputEmail1">Number of Hours</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control small-input" id="exampleInputEmail1" name="RefNum" formControlName="numberHours" aria-describedby="emailHelp">
                
                
                                                                </div>
                
                
                
                                                            </div>
                                                        </div>
                
                                                    </div>
                                                    <div class="col-lg-12 col-12 main-submit-button mb-4" style="    margin-left: 56%;height: 21px;font-size: 12px; width: 172px;">
                                                        <button type="submit" class="btn btn-secondary submit-btn" *ngIf="!show" (click)="onSubmitPaycheck()">Create
                                                            PayCheck</button>&nbsp;
                                                        <button type="submit" class="btn btn-secondary submit-btn" *ngIf="show" (click)="onSubmit()">Create 
                                                            PayCheck</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="container-fluid main-container-wrapper">
    <div class="row ">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels"></span>

            </div>
            <div class=" bg-white color-grey ">

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="createPaycheck">
                                <div class="w3-card-4 classCard mb-2">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">

                                        Create Paycheck
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">


                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Paycheck Type</label>
                                                </div>



                                                <div class="col-lg-2 dropdowns" *ngIf="!show">
                                                    <p-dropdown filter="true" formControlName="PaycheckType" name="PaycheckType" optionlabel="label" [options]="PaycheckTypeArray">

                                                    </p-dropdown>
                                                </div>



                                                <div class="col-lg-2 dropdowns" *ngIf="show">
                                                    <input type="email" disabled class="form-control small-input" [(ngModel)]="this.paycheckType" id="exampleInputEmail1" name="Amount" formControlName="PaycheckType" aria-describedby="emailHelp">


                                                </div>
                                            </div>
                                        </div>


                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Pay to Party
                                                        <span style="color: red;">*</span>
                                                    </label>
                                                </div>

                                                <div class="col-lg-2 dropdowns">

                                                    <p-dropdown filter="true" formControlName="PaytoParty" [options]="PaytoPartyArray" name="PaytoParty" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Payment Method</label>
                                                </div>

                                                <div class="col-lg-2 dropdowns">
                                                    <p-dropdown filter="true" formControlName="PaymentMethod" [options]="PaymentMethodIDArray" name="PaymentMethod" optionlabel="label">

                                                    </p-dropdown>
                                                </div>

                                            </div>
                                        </div>


                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Gross Amount
                                                        <span style="color: red;">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control small-input" id="exampleInputEmail1" name="Amount" formControlName="Amount" aria-describedby="emailHelp">


                                                </div>

                                                <div class="col-lg-2 dropdowns">
                                                    <p-dropdown filter="true" name="GrossAmount" optionlabel="label" formControlName="GrossAmount" [options]="currencyArray">

                                                    </p-dropdown>
                                                </div>


                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Effective Date
                                                        <span style="color: red;">*</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control small-input" id="exampleInputEmail1" name="EffectiveDate" formControlName="EffectiveDate" aria-describedby="emailHelp">


                                                </div>
                                                <div class="col-lg-1 time">
                                                    <p-dropdown filter="true" [options]="hours" name="hour" optionlabel="label" formControlName="hour"> :

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-1 time">
                                                    <p-dropdown filter="true" [options]="minute" name="minute" optionlabel="label" formControlName="minute">

                                                    </p-dropdown>
                                                </div>
                                                <div class="col-lg-1 time">
                                                    <p-dropdown filter="true" formControlName="amPm" [options]="amPM" name="amPm" optionlabel="label">

                                                    </p-dropdown>
                                                </div>






                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Comments</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control small-input" id="exampleInputEmail1" name="Comments" formControlName="Comments" aria-describedby="emailHelp">


                                                </div>



                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Check/Ref Num</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control small-input" id="exampleInputEmail1" name="RefNum" formControlName="RefNum" aria-describedby="emailHelp">


                                                </div>



                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Number of Days</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control small-input" id="exampleInputEmail1" name="RefNum" formControlName="numberDays" aria-describedby="emailHelp">


                                                </div>



                                            </div>
                                        </div>

                                        <div class="col-lg-12">
                                            <div class="row">

                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Number of Hours</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control small-input" id="exampleInputEmail1" name="RefNum" formControlName="numberHours" aria-describedby="emailHelp">


                                                </div>



                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-12 col-12 main-submit-button mb-4" style="    margin-left: 13%;height: 21px;font-size: 12px; width: 172px;">
                                        <button type="submit" class="btn btn-secondary submit-btn" *ngIf="!show" (click)="onSubmitPaycheck()">Create
                                            PayCheck</button>&nbsp;
                                        <button type="submit" class="btn btn-secondary submit-btn" *ngIf="show" (click)="onSubmit()">Create 
                                            PayCheck</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br>
</div> -->