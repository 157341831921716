<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="bg-white color-grey w-100 mt-20">
                <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                   <button class="btn btn-danger" (click)="back()">Back</button>
                </div>
                                
                <div class="create-new-leade">
                    <div class="container-fluid" style="padding: 0;">
                        <div class="">
                            <div class="w-100">
                                <div class="w3-card-4 classCard w-100">
                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                        style="justify-content: space-between;">
                                        <p class="h-text">Problem Summary </p>
                                        
                                    </div>
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        
                                        <div class="color-black container">
                                            <div class="row" *ngIf="problemIdData">
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Problem ID
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold">
                                                                {{problemIdData.problemId}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Customer ID	</p>
                                                            <span class="color-grey pl-20 font-weight-bold">  {{problemIdData.customerId}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Service
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold">  {{problemIdData.service}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Created Date
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold">  {{problemIdData.createdStamp | date:'yyyy-MM-dd'}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Problem type</p>
                                                            <span class="color-grey pl-20 font-weight-bold">  {{problemIdData.category}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Problem Description
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold">  {{problemIdData.description}}
        
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Priority</p>
                                                            <span class="color-grey pl-20 font-weight-bold">  {{problemIdData.priority}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Payment Mode	
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold" *ngIf="problemIdData.paymentMode">  {{problemIdData.paymentMode}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="article-container">
                                                        <div class="article">
                                                            <p class="col-lg-6">Problem Status
            
                                                            </p>
                                                            <span class="color-grey pl-20 font-weight-bold">  {{problemIdData.problemStatus}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





