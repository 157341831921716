import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import { CreateEventServices } from '../create-events/create-event.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-all-events',
  templateUrl: './all-events.component.html',
  styleUrls: ['./all-events.component.css']
})
export class AllEventsComponent implements OnInit {
  total=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  public size = {
    pagesize: this.pageSize,
    pageNo: this.pageNo
  };
  allEventsData;
  show: boolean;
  constructor(
    readonly createEventServices: CreateEventServices,
    readonly spinner:NgxSpinnerService,
    private _location: Location,
    readonly router:Router
  ) {
    this.show = false;
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.findData();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this.router.navigate(['/crm/main-overview']);
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  findData() {
    this.spinner.show();
    this.createEventServices.getEvents(this.size).subscribe(res => {
     
      this.allEventsData = res.data;
      this.spinner.hide();
    });
   
  }
  onFirstChange() {
    this.show = false;
  }
  onChange() {
    this.show = true;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
