
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { StoryModel } from '../stories/stories-model';

@Injectable({
  providedIn: 'root'
})


export class StoryService {
    story_form: FormGroup;


  constructor(public _formBuilder: FormBuilder,
    public storyModel: StoryModel
  ) { }

  StoryForm(data: any): void {
    if (!this.story_form) {
      this.story_form = this._formBuilder.group(this.storyModel.mapDataToModel(data));

    }

  }

}




















