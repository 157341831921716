<div class="container-fluid main-container-wrapper">


    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/financial/{{agreementType}}/customer-agreeement">Agreements
                </span>
                <span class="color-gry cursor-pointer"
                    routerLink="/financial/{{agreementType}}/customer-agreeement/summary-customer-agreeement"
                    [queryParams]="{agreementID:agreementId}">>Agreements Summary </span>
                <span class="color-black pl-1"> > {{editMode ? 'Update':'Create'}} Customer Agreement Term</span>
                <button class="btn btn-danger" style="margin-left: 43%;" (click)="reset()">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard" >
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        {{editMode ? 'Update':'Create'}} Customer Agreement Term</a></li>
                
                    </ul>
                  </div>

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="AgreementsTerms">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Term Type ID <span
                                                    class="text-danger">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="TermTypeIdList"
                                                formControlName="termTypeId" optionlabel="label"
                                                placeholder="Term Type ID">
                                            </p-dropdown>
                                
                                        <small class="text-danger"
                                            *ngIf="!!AgreementsTerms.controls.termTypeId.invalid && !!AgreementsTerms.controls.termTypeId.touched">Term
                                            Type is required</small>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Invoice Item Type</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" name="party" [options]="InvoiceItemTypeList"
                                                optionlabel="label" formControlName="invoiceItemTypeId"
                                                placeholder="Invoice Item Type">
                                            </p-dropdown>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">From Date</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-calendar formControlName="fromDate" placeholder="Select Date">
                                                </p-calendar>
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Through Date</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <p-calendar formControlName="thruDate" placeholder="Select Date">
                                                </p-calendar>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Term Value</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" formControlName="termValue"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Term Value">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Term Days</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" formControlName="termDays"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Term Days">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Text Value</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" formControlName="textValue"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Text Value">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Min Quantity</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" formControlName="minQuantity"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Min Quantity">
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">

                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Max Quantity</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" formControlName="maxQuantity"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Max Quantity">
                                            </div>

                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="examCreate Commission Agreement
                                                pleInputEmail1">Description</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" formControlName="description"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Description">
                                            </div>


                                        </div>
                                    </div>

                                  
                                </div>
                                <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -29%;">
                                    <button _ngcontent-cta-c128="" type="submit" (click)="submit()"
                                        class="btn btn-secondary submit-btn">{{editMode ? 'Update':'Create'}} </button>
                                   
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
          
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 