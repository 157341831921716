import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { HeaderComponent } from 'src/app/header/header.component';
import { HeaderService } from 'src/app/header/header.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-permision-detail-page',
  templateUrl: './permision-detail-page.component.html',
  styleUrls: ['./permision-detail-page.component.css']
})
export class PermisionDetailPageComponent implements OnInit {
  partyId: any;
  activeCategory = 1;
  @ViewChild('closebutton') closebutton;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 500;
  rows = 50;
  createGroup:FormGroup;
  pageNo=1;
  search="";
  public user = {
    pageNo  :this.pageNo,
    pageSize:this.pageSize,
    search  :this.search
  }
  roleList: any;
  getAllPermisionData: any;
  groupId: any;
  getSecurityInfo: any;
  show: boolean;
  showListModal: boolean = false;
  showManualModal: boolean = false;

  constructor(readonly accountsService: AccountsService,
    readonly headerService : HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly router: Router,  readonly toastr:ToastrService,
    readonly activatedRoute: ActivatedRoute) { 
      this.createGroup = this._FormBuilder.group({
        description: [''],
        groupId: ['',[Validators.required]],
        groupName: [''],
        
        
      });
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.groupId = params['groupId'];
    });
    this.show=true;
    this.getAllPermision();
    this.getUserDetails();
    this.getUserDetailsEdit();
  }
  getUserDetailsEdit(){
    const req={
  "description": "",
  "descriptionSearchType": "Contains",
  "groupId": this.groupId,
  "groupIdSearchType": "Contains"
     
    }
    this.accountsService.getSecurityGroupList(this.user,req).subscribe((res:any)=> {
     setTimeout(() => {
      const formValue = this.createGroup;
    formValue.patchValue({
      description:res.data[0].description,
      groupId: res.data[0].groupId,
      groupName:res.data[0].groupName,
     
     
    })
    }, 1000); 
     
    })
   
   }
 
   update(): void {
    this.spinner.show();
      const requestData ={
      
          "description": this.createGroup.value.description,
          "groupId": this.createGroup.value.groupId,
          "groupName":this.createGroup.value.groupName    
          
      }
      this.accountsService.putSecurityGroup(requestData).subscribe((res: any) => {
       
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.createGroup.reset();
          this.getUserDetails();
          this.getUserDetailsEdit(); 
          this.closebutton.nativeElement.click();
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
     
    }
  getUserDetails(){
    const req={
  "description": "",
  "descriptionSearchType": "Contains",
  "groupId": this.groupId,
  "groupIdSearchType": "Contains"
     
    }
    this.accountsService.getSecurityGroupList(this.user,req).subscribe((res:any)=> {
      this.getSecurityInfo = res.data[0];
     
    })
   
   }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  cancelSubmit(){
    this.router.navigate(["users/security-group"])
  }
  getAllPermision(){
    this.spinner.show();
      this.accountsService.getSecurityGroupPermissionApi(this.user,this.groupId).subscribe((res:any)=> {
        this.getAllPermisionData = res.data;
        this.spinner.hide();
      
      })
     
     }
     deletePermision(fromdate,permsionid){
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          this.accountsService.deleteSecurityGroupPermissionApi(fromdate,permsionid,this.groupId).subscribe((res:any)=> {
            this.getAllPermision();
        },(err) => {
          this.toastr.error(err.error.message);
       })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )} else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }}) }
    changeManaully(){
      // this.router.navigate(['/users/add-permision-manaully'], { queryParams: { groupId:  this.groupId } });
      this.showManualModal = true;
     
    }
    changeList(){
      // this.router.navigate(['/users/add-permision-list'], { queryParams: { groupId:  this.groupId } });
      this.showListModal = true;
    }
   
     customSort(event: SortEvent) {
      event.data.sort((data1, data2) => {
          const value1 = data1[event.field];
          const value2 = data2[event.field];
          let result = null;
  
          if (value1 == null && value2 != null){
            result = -1;
          }
          else if (value1 != null && value2 == null){
            result = 1;
          }
          else if (value1 == null && value2 == null) {
              result = 0;
           }
          else if (typeof value1 === 'string' && typeof value2 === 'string') {
              result = value1.localeCompare(value2);
          }
          else {
              result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
          }
          return (event.order * result);
      });
    }
    plistModalClosed(){
      this.showListModal = false;
     }
    pManualModalClosed(){
      this.showManualModal = false; 
     }
    ngOnDestroy(): void {
      this.spinner.hide();
        }
}
