import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {FormGroup, FormBuilder} from '@angular/forms';
import { CRMAccountService } from 'src/app/services/crm-account.service'
import { GlobalResourceService } from 'src/app/services/global-resource.service'
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import Swal from 'sweetalert2';

    import { Location } from '@angular/common';

@Component({
  selector: 'app-my-portal-my-payments',
  templateUrl: './my-portal-my-payments.component.html',
  styleUrls: ['./my-portal-my-payments.component.css']
})
export class MyPortalMyPaymentsComponent implements OnInit {

  activeCategory=1;
  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  userLoginIdArray:any[]=[];
  partyId: string;
  payment: any;
  constructor( readonly _CategoryService: CategoryService, readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
      private _location: Location,
      readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService){
      this.partyId=localStorage.getItem("partyId")
    }
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getPayment();
  }
  homeButton(){
    this._Router.navigate(['/myPortal/my-portal-my-profile'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
      
  getPayment() {
    this.spinner.show();
    this.accountsService.getPayment(this.finSize).subscribe(res => {
      this.payment = res.data;
      this.spinner.hide();
    })
  
  }
  detailMainPage(paymentId,description,statusIdWithDescription,comments,partyIdFrom,partyIdTo,effectiveDate,amount){
    this._Router.navigate(['/myPortal/detail-payment-portal-summary'],{ queryParams: { paymentId:paymentId,description:description,statusIdWithDescription:statusIdWithDescription,comments:comments,partyIdFrom:partyIdFrom,partyIdTo:partyIdTo,effectiveDate:effectiveDate,amount:amount}});
  }
 
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
