<div class="container-fluid main-container-wrapper">
    <br><br>
    <div class="row">
        <div class="col-12">
            <div class="panel panel-default">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategory==1?'active':''"><a>
                        Add Note   </a></li>
                        </ul>
                    </div>
                    <form [formGroup]="leadNotesFormService.lead_notes_form">
                        <div class="panel-body">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group rightForm">
                                            <label for="exampleInputEmail1">Note Name</label>
                                        </div>
                                        <div class="col-lg-2">
                                            <input type="email" [(ngModel)]="noteName" class="form-control" formControlName="noteName" aria-describedby="emailHelp" placeholder="Enter Note Name">
                                        </div>
                                        <div class="col-lg-3 form-group rightFormA">
                                            <label for="exampleInputEmail1">Note</label>
                                        </div>
                                        <div class="col-lg-4">
                                            <textarea style="height: 100%!important;width: 148;" id="w3review" [(ngModel)]="note" parentTypeId="description" formControlName="note" rows="3" cols="60" placeholder="Note">
                                    </textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-left: -9%;" class="col-lg-12 col-12 main-submit-button" *ngIf="showCreateBtn">
                                <button type="submit" (click)="onCreate()" class="btn btn-secondary submit-btn">Create</button>
                                <button type="submit" (click)="onCancel()" class="btn btn-danger ">Cancel</button>
                            </div>
                            <div style="    margin-left: -73%;" class="col-lg-12 col-12 main-submit-button" *ngIf="showUpdateBtn">
                                <button type="submit" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                <button type="submit" (click)="onCancel()" class="btn btn-danger ">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>