
<div class="container" style="margin-top: 8%;">
  <div class="row">
    <div class="col-12">
      
      <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Contacts > Merge Contacts</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>
      <div class="w3-card-4 classCard" >
      <div class=" bg-white color-grey">
        <div class="port-header-contact create-lead font-medium mb-0">
          <div class="suppliers-wrapper">
            <button type="button" class="btn btn-outline-secondary active">Merge Contacts</button>
          </div>
        </div>
        <div class="create-new-leade mt-2" >
          <div class="container-fluid">
            <div class="row">
              <form class="w-100">
                <div class="all-users-infomation font-13 font-medium own-user-select">
                  <div class="col-lg-12">
                    <div class="row">
                        <div class="col-lg-2 form-group rightForm">
                          <label for="exampleInputEmail1">From Party</label>
                        </div>
                        <div class="col-lg-2">
                          <p-dropdown filter="true" placeholder="From Party" [(ngModel)]="partyIdFrom" [ngModelOptions]="{standalone: true}"
                          (ngModelChange)="partyIdFromData(partyIdFrom)" [options]="partyIdArray" optionlabel="label">

                        </p-dropdown>
                        </div>
                        <div class="col-lg-2 form-group rightFormA">
                          <label for="exampleInputEmail1">To Party</label>
                        </div>
                        <div class="col-lg-2">
                          <p-dropdown [(ngModel)]="partyIdTo" placeholder="From Party" [ngModelOptions]="{standalone: true}"
                          (ngModelChange)="partyIdToData(partyIdTo)" [options]="partyIdArray" filter="true"
                          optionlabel="label">
  
                        </p-dropdown>
                        </div>
                        
                       
                    </div>
                </div>
              
                 
                
                  <div class="col-lg-4 col-12">
                    <br><br>
                    <div style="margin-left: 171%;
                    margin-top: -10%;
                ">
                      <label for="exampleInputEmail1"></label>
                     
                      <button type="button" (click)="onSubmit()"
                            class="btn btn-outline-secondary active">Merge</button>

                       
                      

                    </div>
                  </div>
                </div>

              </form>
            </div>
          </div>
          </div>

        </div>
      </div>
        <br>
        <div class="w3-card-4 classCard">
          <header class="w3-container w3-blue">
            <div class="header-tabs">
              <h4 class=" common-styling h4Margin">
                Merge Contact List
              </h4>
           
            </div>
          </header>
         
          <div class="panel-group">
            <div *ngIf="activeCategory==1">
            
                <div class="panel-body" *ngIf="mergeContacts && mergeContacts.length > 0">
                  <div class="all-users-infomation font-13 font-medium own-user-select">
                    <div class="color-black container">
                      <div class="row color" >
                        <table  class="table">
                          <thead>
                            <tr>
                              <th></th>
                              <th>First Contact
                              </th>
                              <th>Second Contact
                              </th>
                              <th>Select
                              </th>
                            </tr>
                          </thead>
                        
                          <tbody>
                            <tr>
                              <td>First name</td>
                              <td>{{mergeContacts[0].firstName}}</td>
                              <td>{{mergeContacts[1].firstName}}</td>
                              <td rowspan="2"><label class="address">
                                  <input type="checkbox" [(ngModel)]="useContactNum2" [ngModelOptions]="{standalone: true}"
                                    (change)="checkContact($event)" class="form-check-input" value="">
                                </label></td>
                            </tr>
                            <tr>
                              <td>Last Name</td>
                              <td>{{mergeContacts[0].lastName}}</td>
                              <td>{{mergeContacts[1].lastName}}</td>
                              <td></td>
        
                            </tr>
                            <tr>
                              <td>General Correspondence Address </td>
                              <td></td>
                              <td></td>
        
                            </tr>
                            <tr>
                              <td>Address Line 1 </td>
                              <td>{{mergeContacts[0].address1}}</td>
                              <td>{{mergeContacts[1].address1}}</td>
                              <td rowspan="5"> <label class="form-check-label">
                                  <input type="checkbox" [(ngModel)]="useAddress2" [ngModelOptions]="{standalone: true}"
                                    id="hash" (change)="checkAddress($event)" class="form-check-input" value="">
                                </label>
                              </td>
        
                            </tr>
                           
                            <tr>
                              <td>Address Line 2 </td>
                              <td>{{mergeContacts[0].address2}}</td>
                              <td>{{mergeContacts[1].address2}}</td>
                              <td></td>
        
        
                            </tr>
                            <tr>
                              <td>City </td>
                              <td>{{mergeContacts[0].city}}</td>
                              <td>{{mergeContacts[1].city}}</td>
                              <td></td>
        
                            </tr>
                            <tr>
                              <td>State/Province </td>
                              <td>{{mergeContacts[0].state}}</td>
                              <td>{{mergeContacts[1].state}}</td>
                              <td></td>
        
        
                            </tr>
                            <tr>
                              <td>Country </td>
                              <td>{{mergeContacts[0].country}}</td>
                              <td>{{mergeContacts[1].country}}</td>
                              <td></td>
        
                            </tr>
                            <tr>
                              <td>Primary Phone Number
                              </td>
                              <td></td>
                              <td></td>
        
                            </tr>
                            <tr>
                              <td>Area Code </td>
                              <td>{{mergeContacts[0].areaCode}}</td>
                              <td>{{mergeContacts[1].areaCode}}</td>
                              <td></td>
        
        
                            </tr>
                            <tr>
                              <td>Phone Number </td>
                              <td>{{mergeContacts[0].contactNumber}}</td>
                              <td>{{mergeContacts[1].contactNumber}}</td>
                              <td></td>
        
                            </tr>
                            <tr>
                              <td>E-Mail Address </td>
                              <td>{{mergeContacts[0].primaryEmail}}</td>
                              <td>{{mergeContacts[1].primaryEmail}}</td>
                              <td rowspan="5"> <label class="forms">
                                  <input type="checkbox" [(ngModel)]="useEmail2" id="class"
                                    [ngModelOptions]="{standalone: true}" (change)="checkEmail($event)" class="form-check-input"
                                    value="">
                                </label></td>
        
                            </tr>
        
                          </tbody>
                        
                          <tr>
                            <td> </td>
                            <td></td>
                            <td></td>
        
                          </tr>
                        
                        </table>
                        <button type="button" (click)="submitMergeContact()" style="margin-left: 85%;height: 12%!important"
                          class="btn btn-outline-secondary">Submit</button>
                          <br><br><br><br>
                      </div>
                    </div>
                  </div>
                </div>
                <br><br><br><br>
              </div>
          </div>
          </div>
          <br> <br> <br>
      </div>
    </div>
  </div>
 

<ngx-spinner></ngx-spinner> 