import { DatePipe,Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-add-party-segment-role',
  templateUrl: './add-party-segment-role.component.html',
  styleUrls: ['./add-party-segment-role.component.css']
})
export class AddPartySegmentRoleComponent implements OnInit {

  createSegment:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  SegmentGroup: any;
  SegmentGroupArray: any[]=[];
  @ViewChild('closebutton') closebutton;
  constructor(  readonly _CategoryService: CategoryService, readonly  _Router: Router,private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    public bsModalRef: BsModalRef) { 
      this.createSegment = this._FormBuilder.group({
        segmentId: [''],
        roleId:['']
       
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.show=false;
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
     
      
    });
   this.getRoleAllType();
   this.getSegmentGroup();
  }
  
  getSegmentGroup(){
    this.spinner.show();
   
      this.accountsService.getSegmentGroup().subscribe((res:any)=> {
        this.SegmentGroup = res.data;
        this.spinner.hide();
        for(const value of this.SegmentGroup){
          this.SegmentGroupArray.push({
            label:value.segmentGroupId.description,
            value:value.segmentGroupId.segmentGroupId
          })
        }
      
      })
     
     }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
  onSubmit() {
    this.spinner.show();
    let requestData = {
      "partyId": this.party,
      "roleTypeId": this.createSegment.value.roleId,
      "segmentGroupId":this.createSegment.value.segmentId
    }

    this.accountsService.createPartySegmentRole(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.closebutton.nativeElement.click();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.debugMessage);
        }
    });

  }
 
  
  cancelSubmit(){
    this._location.back();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
