import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-edit-icalendar',
  templateUrl: './edit-icalendar.component.html',
  styleUrls: ['./edit-icalendar.component.css']
})
export class EditIcalendarComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  addiCalender: any;
  editiCalendar: any;
  workEffortId: any;
  FromDate: string;
  ToDate: string;
  personId: any;
  partyIdArray: any[]=[];
  CalenderVisibilityArray: any[]=[];
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly createEventServices: CreateEventServices,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    private _location: Location,
    
    readonly addSkillService: AddSkillService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
        readonly _FormBuilder: FormBuilder, private _Router : Router
  ) {
    this.addiCalender = this._FormBuilder.group({
      calendarOwner: "",
      calendarName: "",
      calendarVisibility: "",
      fromDate: "",
      toDate: "",
    });
    this.editiCalendar = this._FormBuilder.group({
      icalendarURL: "",
      calendarName: "",
      calendarVisibility: "",
      fromDate: "",
      toDate: "",
    });
  }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.workEffortId = params["workEffortId"];
     
    })
    this.getPartyId();
    this.getCalenderVisibility();
  }
  createICalendar(): void {
    this.spinner.show();
    const fromDate=this.addiCalender.get('fromDate').value;
    this.FromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const toDate=this.addiCalender.get('toDate').value;
    this.ToDate=this.datePipe.transform(toDate,"yyyy-MM-dd hh:mm:ss");
    
     const formData ={
   
      "actualCompletionDate": this.ToDate,
      "actualStartDate": this.FromDate,
      "currentStatusId": "CAL_CANCELLED",
      "quickAssignPartyId": this.addiCalender.value.calendarOwner,
      "scopeEnumId": this.addiCalender.value.calendarVisibility,
      "timesheetId": "",
      "workEffortName": this.addiCalender.value.calendarName,
      "workEffortTypeId": "PUBLISH_PROPS" 
  
       
         
     }
     this.myContactsService.createICalendar(formData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.closebutton.nativeElement.click();
         this.addiCalender.reset();
         
         
        
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
   homeButton(){
      this._Router.navigate(['/psa/Dashboard'])
    
    }
    cancelSubmit(){
      this._location.back();
    }
    
   updateICalendar(): void {
    this.spinner.show();
    const fromDate=this.editiCalendar.get('fromDate').value;
    this.FromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const toDate=this.editiCalendar.get('toDate').value;
    this.ToDate=this.datePipe.transform(toDate,"yyyy-MM-dd hh:mm:ss");
    const requestData ={
      "actualCompletionDate": this.ToDate,
  "actualStartDate": this.FromDate,
  "currentStatusId": "CAL_CANCELLED",
  "scopeEnumId": this.editiCalendar.value.calendarVisibility,
  "workEffortId":  this.workEffortId,
  "workEffortName": this.editiCalendar.value.calendarName,
  "workEffortTypeId": "PUBLISH_PROPS",
   
      }
     
    this.myContactsService.updateICalendar(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  
    openiCalendar(){
      this._Router.navigate(["/psa/work-effort/iCalendar-view/EditiCalendar"],{ queryParams: { workEffortId: this.workEffortId} })
    }
    openChildren(){
      this._Router.navigate(["/psa/work-effort/iCalendar-view/iCalendarChildren"],{ queryParams: { workEffortId: this.workEffortId} })
    }
    openParties(){
      this._Router.navigate(["/psa/work-effort/iCalendar-view/iCalendarParties"],{ queryParams: { workEffortId: this.workEffortId} })
    }
    openFixedAssets(){
      this._Router.navigate(["/psa/work-effort/iCalendar-view/iCalendarFixedAssets"],{ queryParams: { workEffortId: this.workEffortId} })
    }
    openData(){
      this._Router.navigate(["/psa/work-effort/iCalendar-view/editiCalendarData"],{ queryParams: { workEffortId: this.workEffortId} })
    }
    openHelp(){
      this._Router.navigate(["/psa/work-effort/iCalendar-view/iCalendarHelp"],{ queryParams: { workEffortId: this.workEffortId} })
    }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   }
   getCalenderVisibility() {
    this.spinner.show();
    this.myContactsService.getCalenderVisibility().subscribe(res => {
      this.personId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.personId) {
        this.CalenderVisibilityArray.push({
          label: value.description,
          value: value.scopeEnumId
        })
      }
    })
   }
}



