import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { DatePipe, Location } from '@angular/common';
import { CategoryService } from 'src/app/catalog-mgmt/new-catagory/category.service';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { ColorModifier } from '@amcharts/amcharts4/core';
@Component({
  selector: 'app-customer-reset',
  templateUrl: './customer-reset.component.html',
  styleUrls: ['./customer-reset.component.css']
})
export class CustomerResetComponent implements OnInit {

  public token: string;
  resetEmail: string;
  tenantId:string;
  EnabledArray=[{
    "label":"Y",
    "value":"Y"
  },
  {
    "label":"N",
    "value":"N"
  }]
  updatePassword:FormGroup;
  changePassword:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  party: any;
  identification: any;
  identificationArray: any[]=[];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  ClassificationGroupId: any;
  ClassificationGroupIdArray: any[]=[];
  fromDate: string;
  thruDate: string;
  partyClassId: any;
  loginid: any;
  showLoginBtn: boolean;
  userLoginId: string;
  constructor( public router: Router,
    readonly _CategoryService: CategoryService, 
    private _location: Location,
    readonly _FormBuilder: FormBuilder, readonly datePipe: DatePipe,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,) {
      this.changePassword = this._FormBuilder.group({
        CurrentPassword: "",
        NewPassword: "",
        VerifyNewPassword: "",
        PasswordHint: "",
        RequirePasswordChange: "",
        UserLoginId:""
      });
     }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["partyId"];
      this.showLoginBtn = false;  
    });
    this.userLoginId = localStorage.getItem('userLoginId');

    setTimeout(() => {
      const form = this.changePassword;
      form.patchValue ({
        UserLoginId: this.userLoginId
      })
      
    }, 1000);
  }
login(){
  this.router.navigate(["/login"]);
}
onSubmit() {
  this.spinner.show();
 
  const requestData = {
    "currentPassword":  this.changePassword.value.CurrentPassword,
    "newPassword": this.changePassword.value.NewPassword,
    "newPasswordVerify":  this.changePassword.value.VerifyNewPassword,
    "partyId":  this.party,
    "passwordHint": this.changePassword.value.PasswordHint,
    "requirePasswordChange": this.changePassword.value.RequirePasswordChange,
    "userLoginId":  this.userLoginId ,

  }
  this.myContactsService.updateProfileUserLoginPassword(requestData).subscribe((res: any) => {
    
    if(res.success){
      
      this.toastr.success("Password Updated Successfully");
this.spinner.hide();
      this.showLoginBtn = true;  
     }
   else if(res.success == false) {
       this.toastr.error("Reset Password failed");
    }
   },(err) => {
     this.toastr.error("Invalid User Data");
  });

}

 back(){
  window.location.href = '#';
 }
  
}

