import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';   import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-party-content-my-profile',
  templateUrl: './party-content-my-profile.component.html',
  styleUrls: ['./party-content-my-profile.component.css']
})
export class PartyContentMyProfileComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total = 0;
  fileData: File;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  newForm: FormGroup;
  newrequestForm: FormGroup;
  Attachform: FormGroup;
  actualfromdate: string;
  actualthroughdate: string;
  partyId: any;
  partyContentTypeId: any;
  PartyContentData: any;
  contentId: any;
  dataResourceId: any;
  partyContentData: any;
  partyContentArray: any[] = [];
  PartyContentTypeIdList: any;
  PartyContentTypeIdListArray: any[] = [];
  show = false;
  PartyStatusIdList: any;
  PartyStatusIdListArray: any[] = [];
  DataCategoryIdList: any;
  DataCategoryIdListArray: any[] = [];
  isPublic = [{
    "label": "Yes",
    "value": "Y"
  },
  {
    "label": "No",
    "value": "N"
  }]
  editgetEstimateFrom: string;
  egetEstimateTo: string;
  fromDate: any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,    
    private _location: Location,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly myContactsService: MyContactsService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly router: Router
  ) {

    this.Attachform = this._FormBuilder.group({
      file: [''],
      partyContentTypeId: [''],
      fromDate: [''],
      throughDate: [''],
      ContentTypeID: [''],
      StatusID: [''],
      DataCategoryID: [''],
      IsPublic: ['']
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
      this.contentId = params['contentId'];
      this.partyContentTypeId = params['partyContentTypeId'];
      this.fromDate = params['fromDate'];
    });
    this.getPartyContent();
    this.getPartyContentDropdown();
    this.getPartyContentTypeIdList();
    this.getPartyStatusIdList();
    this.getPartyContentTypeIdList();
    this.getDataCategoryIdList();
  }
  homeButton(){
    this.router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  edit(product) {
    if (product.data.contentId) {
      this.show = true;
      setTimeout(() => {
        const fromdate = product.fromDate;
        this.editgetEstimateFrom = this.datePipe.transform(fromdate, "yyyy-MM-dd");

        const thruDate = product.thruDate;
        this.egetEstimateTo = this.datePipe.transform(thruDate, "yyyy-MM-dd");
        const formValue = this.Attachform;
        formValue.patchValue({
          partyContentTypeId: product.data.partyContentTypeId,
          fromDate: this.editgetEstimateFrom,
          throughDate: this.egetEstimateTo,
          ContentTypeID: product.data.contentTypeId,
          StatusID: product.data.statusId
        })
      }, 3000);
    }
    else {
      this.show = false;
    }
  }


  getDataCategoryIdList() {
    this.spinner.show();
    this.myContactsService.getDataCategoryIdList().subscribe(res => {
      this.DataCategoryIdList = res.data[0].dataCategoryIdList;
      this.spinner.hide();
      for (const value of this.DataCategoryIdList) {
        this.DataCategoryIdListArray.push({
          label: value.categoryName,
          value: value.dataCategoryId
        });
      }
    });

  }
  getPartyStatusIdList() {
    this.spinner.show();
    this.myContactsService.getPartyStatusIdList().subscribe(res => {
      this.PartyStatusIdList = res.data[0];
      this.spinner.hide();
      for (const value of this.PartyStatusIdList) {
        this.PartyStatusIdListArray.push({
          label: value.statusIdData1.description,
          value: value.statusIdData1.statusId
        });
      }
    });

  }
  onDelete(product): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.myContactsService.deleteProfileContentData(product.data.contentId, product.fromDate, product.data.partyContentTypeId, product.data.partyId).subscribe(res => {
          if (res['data'].responseMessage === 'success') {
          }
          this.getPartyContent();
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getPartyContentTypeIdList() {
    this.spinner.show();
    this.myContactsService.getPartyContentTypeIdList().subscribe(res => {
      this.PartyContentTypeIdList = res.data[0].contentTypeIdList;
      this.spinner.hide();
      for (const value of this.PartyContentTypeIdList) {
        this.PartyContentTypeIdListArray.push({
          label: value.description,
          value: value.contentTypeId
        });
      }
    });
  }

  onpartyContent(event) {
    this.partyContentTypeId = event;
  }

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  onSubmitAttach() {
    this.spinner.show();
    let formData = new FormData();
    formData.append('file', this.fileData);
    const fromActualdate = this.Attachform.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.Attachform.get('throughDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "contentTypeId": this.Attachform.value.ContentTypeID,
      "dataCategoryId": this.Attachform.value.DataCategoryID,
      "fromDate": this.actualfromdate,
      "isPublic": this.Attachform.value.IsPublic,
      "partyContentTypeId": this.Attachform.value.partyContentTypeId,
      "partyId": this.partyId,
      "statusId": this.Attachform.value.StatusID,
      "thruDate": this.actualthroughdate
    }
    this.myContactsService.createProfileContent(formData, encodeURIComponent(JSON.stringify(requestData))).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getPartyContent();
        this.Attachform.reset();
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message);

    });
  }
  onUpdateAttach(product) {
    this.spinner.show();
    let formData = new FormData();
    formData.append('file', this.fileData);
    const fromActualdate = this.Attachform.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.Attachform.get('throughDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "contentTypeId": this.Attachform.value.ContentTypeID,
      "dataCategoryId": this.Attachform.value.DataCategoryID,
      "fromDate": this.actualfromdate,
      "isPublic": this.Attachform.value.IsPublic,
      "partyContentTypeId": this.Attachform.value.partyContentTypeId,
      "partyId": this.partyId,
      "statusId": this.Attachform.value.StatusID,
      "thruDate": this.actualthroughdate
    }


    this.myContactsService.updateProfileContent(formData, encodeURIComponent(JSON.stringify(requestData)), product.data.contentId, product.data.dataResourceId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getPartyContent();
        this.Attachform.reset();
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message);

    });
  }

  getPartyContentDropdown() {
    this.spinner.show();
    this.myContactsService.getpartyContentDropdown().subscribe(res => {
      this.partyContentData = res.data.contentPurposeType;
      this.spinner.hide();
      for (const value of this.partyContentData) {
        this.partyContentArray.push({
          label: value.description,
          value: value.partyContentTypeId
        });
      }
    });
  }

  getPartyContent() {
    this.spinner.show();
    this.myContactsService.getProfileContentList(this.partyId).subscribe(res => {
      this.PartyContentData = res.data;
      this.spinner.hide();
    })
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;
      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
