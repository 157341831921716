<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;">Leave
                </span>

                <span class="d-flex">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==1?'active':''"><a>
                                   Leave Summary
                                </a></li>

                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="w-100">
                                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                        <div *ngIf="activeCategory==1">
                                            <div class="header-tabs">
                                                <h4 class=" common-styling" style="margin-left:1%">
                                                    Leave Summary

                                                </h4>
                                                <span class="edit-buttons" style="margin-right:1%">
                                                  <a   data-toggle="modal"
                                                  data-target="#exampleModalCenter" >Update</a></span>

                                            </div>
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="color-black container">
                                                        <div class="article-container">
                                                            <div class="article">
                                                                <p class="col-lg-6 col-12">Party Id</p>
                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.partyIdData}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-6 col-12">Leave Type Id</p>
                                                                <span class="color-grey pl-20 col-lg-10 col-12"> {{ this.leaveTypeId}}</span>
                                                            </div>
                                                            <div class="article">
                                                                <p class="col-lg-6 col-12">From Date</p>
                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>

                                                            </div>

                                                            <div class="article">
                                                                <p class="col-lg-6 col-12">Through Date</p>
                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                            </div>

                                                            <div class="article">
                                                                <p class="col-lg-6 col-12">Description</p>
                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{ this.Description}}</span>
                                                            </div>


                                                            <div class="article">
                                                                <p class="col-lg-6 col-12">Reason Type</p>
                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.Reasonid}}</span>
                                                            </div>

                                                            <div class="article">
                                                                <p class="col-lg-6 col-12">Status </p>
                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.statusId}}</span>
                                                            </div>










                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update New Leaves</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp">
                    <div class=" bg-white color-grey">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==1?'active':''"><a>
                                Add New Employee Leaves</a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==1?'active':''"><a>
                                    Update Employee Leaves</a></li>


                            </ul>
                        </div>


                        <div>
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="addLeaveService.add_leave_form" [formGroup]="addLeaveService.add_leave_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Leave Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="leaveId" formControlName="leaveTypeId" optionlabel="label" placeholder="Enter Leave Type ID">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Leave Reason Type<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="leaveReasonArray" formControlName="emplLeaveReasonTypeId" optionlabel="label" placeholder="Enter Leave Reason Type">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">From Date <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Through Date <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thruDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Through Date">


                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Approver Party <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="partyIdArray" optionlabel="label" placeholder="Enter Approver Party" formControlName="approverPartyId">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter description">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12" *ngIf="leave">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Leave</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown filter="true" [options]="leaveStatusReoortArray" formControlName="leaveStatus" optionlabel="label" placeholder="Enter Leave">
                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="main-submit-button" style="margin-right: 10%;">
                                            <!-- <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button> -->
                                            <!-- <span *ngIf="show"> -->
                                            <button type="submit"  (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                           
                                        <!-- </span> -->
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>