<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">BIRT</span>
                <span>
                    <button type="submit" class="btn btn-secondary submit-btn" (click)="toReports()">Home</button>

                </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="chartViewHandler()"><a>
                                PDF</a></li>
            
                            <li [ngClass]="activeCategory==2?'active':''"  (click)="openSendFormatThroughtMail()"><a>
                                Send any format through Mail</a></li>
                            
                            <li [ngClass]="activeCategory==3?'active':''"  (click)="generatePieChart()"><a>
                                PDF chart report</a></li>
                            
                        </ul>
                        
                    </div>

                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="w3-card-4 classCard"  style="width:98%!important; margin-left:1% ;">
                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                            BIRT
                                        </div><br>
                                    <form class="w-100">

                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12" style="margin-top: -3% !important;">
                                                <div class="">
                                                    <p>BIRT is an open source Eclipse-based (EPL v1 license) reporting system.</p>
                                                    <p>It integrates with any OFBiz application to produce compelling reports, including graphical presentations 
                                                        like pie and bar charts.</p>
                                                    <p>
                                                        This application provides you with sample example reports.
                                                    </p>

                                                    <p>
                                                        See the help option to know how to create your own OFBiz integrated reports.
                                                    </p>

                                                    <p>
                                                      <a class="btn btn-link btnlink ah"> See the Birt home page for more information.</a>
                                                    </p>

                                                    <hr style="width:98%!important; margin-left: 1% !important;">

                                                    <p>
                                                        You might also be interested by the OFBiz Flexible Reports which allows dynamic 
                                                        reports creation (see sub-menu).
                                                    </p>

                                                    <p>
                                                        An OFBiz Flexible Reports is based on entity, view or service.
                                                    </p>

                                                    <p>
                                                       <a class="btn btn-link btnlink ah"> Here is the link to the official OFBiz documentation</a>
                                                    </p>
                                                    
                                                    
                            
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </form>
                                       
                                    </div><br>

                                   
                                </form>

                            </div>
                        </div>
                    </div>
                    
                                        

                </div>
            </div>
        </div>
    </div> 
</div>
            
 <ngx-spinner></ngx-spinner> 

