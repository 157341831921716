<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Qualification Id [10000]</span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">
                            <li [ngClass]="activeCategory==0?'active':''" (click)="changeactiveCategoryFunction(0)"><a>
                            Qualification Information</a></li>



                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div *ngIf="activeCategory==0">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Qualification

                                                        </h4>
                                                        <!-- <span class="edit-buttons">
                                                        <a  data-toggle="modal" (click)="edit()"
                                                        data-target="#exampleModalCenter">Update</a></span> -->

                                                    </div>
                                                    <br>
                                                    <div>
                                                        <!--  <button type="button" style="width: 132px;"
                                                        class="btn btn-outline-secondary bg-brown ">Export as CSV</button>
                                                    &nbsp; <button type="button" style="width: 132px;"
                                                        class="btn btn-danger" routerLinkActive="active">Export as PDF</button> -->
                                                    </div>
                                                    <div class="w3-container">
                                                        <div class="panel-body">
                                                            <div class="panel-body">
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="article-container">
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">
                                                                                    Qualification Id</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Qualification Desc</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.qualificationDesc}}</span>

                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Title
                                                                                </p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.title}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Status ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.statusId}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Verif Status ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.verifStatusId}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Through Date</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.thruDate}}</span>
                                                                            </div>
                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Party ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.partyId}}</span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Party Qual Type ID</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.partyQualTypeId}}</span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">From Date</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12">{{this.fromDate}}</span>
                                                                            </div>

                                                                            <div class="article">
                                                                                <p class="col-lg-6 col-12">Comments</p>
                                                                                <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                            </div>



                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>




                                        </div>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">{{show?'Update':'Create'}} New Party Qualification</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                            Create New Party Qualification </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                Update New Party Qualification </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="createQualification" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">
                                                        Qualification Desc</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="QualificationDesc" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Qualification Desc">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Title</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Title" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Title">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Status ID
                                                       </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Status ID" [options]="PartyIdArray" optionlabel="label" formControlName="VerifStatusID" filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Verif Status ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Verif Status ID" [options]="VerifStatusIDArray" optionlabel="label" formControlName="VerifStatusID" filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="ThroughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Title">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Party ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Party ID" [options]="PartyIdArray" optionlabel="label" formControlName="PartyId" filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Party Qual Type ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Party Qual Type ID" [options]="PartyQualTypeIdArray" optionlabel="label" formControlName="PartyQualTypeId" filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">From Date
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budget ID">

                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputAD">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="Comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comments">
                                                    </div>


                                                </div>
                                            </div>




                                        </div>

                                        <div class="main-submit-button" style="margin-right: 10%;">
                                            <button type="submit" *ngIf="show" class="btn btn-secondary submit-btn" (click)="updatePartyQual()">Update</button>
                                            <button type="submit" *ngIf="!show" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>