<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
                style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Current Project: </span>

               <span class="">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass"
                            style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)">
                                <a>Summary</a>
                            </li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)">
                                <a>Subscription</a>
                            </li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                <a>Subscripiton Attributes</a>
                            </li>
                            <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                <a>K Communication Event</a>
                            </li>
                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        <div *ngIf="activeCategory==1" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Summary</p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                         (click)="getSubscriptionById()"   data-target="#newpopup">Update</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                         (click)="reset()"   data-target="#newpopup">New Subscription</button>
                                                    </span>
                                                </div>
                                                <form>
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Subscription Id</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.SubscriptionByIdList">
                                                                    <span>{{this.SubscriptionByIdList.subscriptionId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Description</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.SubscriptionByIdList">
                                                                    <span>{{this.SubscriptionByIdList.description}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Subscription Resource ID</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.SubscriptionByIdList">
                                                                    <span>{{this.SubscriptionByIdList.subscriptionResourceId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Communication Event ID</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.SubscriptionByIdList">
                                                                    <span>{{this.SubscriptionByIdList.communicationEventId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Contact Mech ID</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.SubscriptionByIdList">
                                                                    <span>{{this.SubscriptionByIdList.contactMechId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Originated From Party ID</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.SubscriptionByIdList">
                                                                    <span>{{this.SubscriptionByIdList.originatedFromPartyId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Originated From Role Type ID</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.SubscriptionByIdList">
                                                                    <span>{{this.SubscriptionByIdList.originatedFromRoleTypeId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Party ID</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.SubscriptionByIdList">
                                                                    <span>{{this.SubscriptionByIdList.partyId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Role Type ID</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.SubscriptionByIdList">
                                                                    <span>{{this.SubscriptionByIdList.roleTypeId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Order ID</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.SubscriptionByIdList">
                                                                    <span>{{this.SubscriptionByIdList.orderId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Order Item Seq ID</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.SubscriptionByIdList">
                                                                    <span>{{this.SubscriptionByIdList.orderItemSeqId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Product ID</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.SubscriptionByIdList">
                                                                    <span>{{this.SubscriptionByIdList.productId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Product Category ID</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.SubscriptionByIdList">
                                                                    <span>{{this.SubscriptionByIdList.productCategoryId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2">
                                                                    <p>Use Time Uom</p>
                                                                </div>
                                                                <div class="col-lg-2" *ngIf="this.SubscriptionByIdList">
                                                                    <span>{{this.SubscriptionByIdList.useTimeUomId}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==2" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Edit Subscription For:
                                                        {{subscriptionId}} </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal" (click)="reset()"
                                                            data-target="#newpopup">New Subscription</button>
                                                    </span>
                                                </div>
                                                <form class="w-100" [formGroup]="newSubscription">
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-3 form-group classInput" *ngIf="this.showSubscription">
                                                                    <label for="exampleInputEmail1">Subscription ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group" *ngIf="this.showSubscription">
                                                                    <input type="text" class="form-control" formControlName="SubscriptionID" disabled
                                                                    id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Description
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Subscription Resource ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="subsResourceID"
                                                                    [options]="subscriptionResourceIdArray" optionlabel="label" placeholder="Enter Subscription Resource ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Communication Event ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="commEventID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Communication Event ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Contact Mech ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="contactMechID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact Mech ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Originated From Party ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="originPartyID"
                                                                    [options]="OriginatedFromRoleTypeIdArray" optionlabel="label" placeholder="Enter Originated From Party ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Originated From Role Type ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="originRoleTypeID" 
                                                                    [options]="OriginatedFromRoleTypeIdArray" optionlabel="label" placeholder="Enter Originated From Role Type ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Party ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="partyID"
                                                                    [options]="partyIdArray" optionlabel="label" placeholder="Enter Party ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Role Type ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="roleTypeID"
                                                                    [options]="RoleTypeIdArray" optionlabel="label" placeholder="Enter Role Type ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Party Need ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="partyNeedID"
                                                                   [options]="partyIdArray"  optionlabel="label" placeholder="Enter Party Need ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Need Type ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="needTypeID" optionlabel="label" placeholder="Enter Need Type ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Order ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="orderID"
                                                                    [options]="orderByIdArray" optionlabel="label" placeholder="Enter Order ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Order Item Seq ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="orderItemSeqID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Order Item Seq ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Product ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="productID" 
                                                                    [options]="ProductIdArray"
                                                                    optionlabel="label" placeholder="Enter Product ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Product Category ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="productCategoryID"
                                                                    [options]="productCategoryArray" optionlabel="label" placeholder="Enter Product Category ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Inventory Item ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="inventoryItemID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Inventory Item ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Subscription Type
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="subsType"
                                                                    [options]="subscriptionTypeArray" optionlabel="label" placeholder="Enter Subscription Type">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">External Subscription ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="extSubsID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External Subscription ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">From Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Through Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Purchase From Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="purchaseFromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase From Date">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Purchase Through Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="purchaseThroughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase Through Date">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Max Life Time
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="maxLifeTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Max Life Time">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Max Life Time UOM ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="maxUomID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Max Life Time UOM ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Available Time
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="availTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Available Time">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Available Time UOM ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="availUomID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Available Time UOM ID">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Use Count Limit
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="countLimit" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Count Limit">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Use Time
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="useTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Time">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Use Time UOM
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="useTimeUom"
                                                                    [options]="useTimeUomArray" optionlabel="label" placeholder="Enter Use Time UOM">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Automatic Extend
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="autoExtend"
                                                                   [options]="yesNoArray"  optionlabel="label" placeholder="Enter Automatic Extend">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Cancel Time
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="cancelTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Cancel Time">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Cancel UOM Time
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="cancelUomTime"
                                                                    [options]="useTimeUomArray" optionlabel="label" placeholder="Enter Cancel UOM Time">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Grace Period on Expiry
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <input type="text" class="form-control" formControlName="gracePeriodExpiry" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Grace Period on Expiry">
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Grace Period UOM ID
                                                                </label>
                                                                </div>
                                                                <div class="col-lg-2 form-group">
                                                                    <p-dropdown filter="true" formControlName="gracePeriodUomID"
                                                                    [options]="useTimeUomArray"  optionlabel="label" placeholder="Enter Grace Period UOM ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Expiration Completed Date</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="date" formControlName="expCompletedDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Expiration Completed Date">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-10 col-10 main-submit-button">
                                                        <button *ngIf="!showSubscription" type="submit" class="btn btn-secondary submit-btn" (click)="createSubscription()">Create</button>
                                                        <button *ngIf="showSubscription"  type="submit" class="btn btn-secondary submit-btn" (click)="updateSubscription()">Update</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==3" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Edit Subscription Attributes : {{this.subscriptionId}} </p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#newAttr">Add Attributes</button>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal"
                                                            data-target="#newAttr">Update</button>
                                                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal" (click)="reset()"
                                                            data-target="#newpopup">New Subscription</button>
                                                    </span>
                                                </div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                    <div class="col-lg-12 col-12">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="subscriptionAttributeList" [paginator]="true" [rows]="rows"
                                                                    scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                pSortableColumn="code">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    Attribute Name
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>

                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Attribute Value
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'180px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">
                                                                                    Action
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'150px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>
                                                                                        <!---->
                                                                                    </p-checkbox>
                                                                                    <span
                                                                                         class="account-button"  >
                                                                                        {{product.attrName}}
                                                                                    </span>

                                                                                </div>
                                                                            </td>

                                                                            <td [ngStyle]="{'width':'180px'}">    {{product.attrValue}}

                                                                            </td>
                                                                            <td [ngStyle]="{'width':'180px'}">
                                                                                <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                                style="margin: 0;" data-toggle="modal" (click)="updateAtt(product)"
                                                                                data-target="#newAttr">Update</button>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>

                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">
                                                                                        No Record
                                                                                        Found</h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>

                                                                <p class="paginate_data">
                                                                    View per page </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="activeCategory==4" class="w-100">
                                            <div class="w3-card-4 classCard w-100">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="justify-content: space-between;">
                                                    <p class="h-text">Edit Subscription Communication Event For : {{this.subscriptionId}}</p>
                                                    <span>
                                                        <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                            style="margin: 0;" data-toggle="modal" 
                                                            data-target="#commPopUp"> Add Subscription Comm Event</button>
                                                    </span>
                                                </div>

                                              
                                                <div
                                                class="all-users-infomation font-13 font-medium mb-3 own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="subscriptionCommEventList" [paginator]="true" [rows]="rows"
                                                                scrollWidth="100%"
                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                [totalRecords]="total" [scrollable]="true"
                                                                (sortFunction)="customSort($event)"
                                                                [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                            pSortableColumn="code">
                                                                            <div class="checkbox-align"
                                                                                style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                Communication Event ID	
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>

                                                                        <th [ngStyle]="{'width':'180px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                From Party ID	                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'180px'}"
                                                                            pSortableColumn="name">
                                                                            <div style="color: white;">
                                                                                To Party ID	
                                                                            </div>
                                                                            <input
                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                value="" autocomplete="off"
                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false"
                                                                                aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'180px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Comm.Type ID	
                                                                        </div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Status
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'180px'}"
                                                                pSortableColumn="name">
                                                                <div style="color: white;">
                                                                    Contact Mech Type ID	
                                                                </div>
                                                                <input
                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                                        <th [ngStyle]="{'width':'180px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">
                                                                            Role Type Id From	
                                                                        </div>
                                                                        <input
                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                            value="" autocomplete="off"
                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                            role="textbox" aria-disabled="false"
                                                                            aria-readonly="false">
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'180px'}"
                                                                    pSortableColumn="name">
                                                                    <div style="color: white;">
                                                                        Role Type Id To	
                                                                    </div>
                                                                    <input
                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                        value="" autocomplete="off"
                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                        role="textbox" aria-disabled="false"
                                                                        aria-readonly="false">
                                                                </th>
                                                                <th [ngStyle]="{'width':'180px'}"
                                                                pSortableColumn="name">
                                                                <div style="color: white;">
                                                                    Start Date	
                                                                </div>
                                                                <input
                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th [ngStyle]="{'width':'180px'}"
                                                            pSortableColumn="name">
                                                            <div style="color: white;">
                                                                Finish Date/Time	
                                                            </div>
                                                            <input
                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                value="" autocomplete="off"
                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                role="textbox" aria-disabled="false"
                                                                aria-readonly="false">
                                                        </th>
                                                        <th [ngStyle]="{'width':'180px'}"
                                                        pSortableColumn="name">
                                                        <div style="color: white;">
                                                            Delete
                                                        </div>
                                                        <input
                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                            value="" autocomplete="off"
                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                            role="textbox" aria-disabled="false"
                                                            aria-readonly="false">
                                                    </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!---->
                                                                                </p-checkbox>
                                                                                <span
                                                                                     class="account-button"  >
                                                                                    {{product.communicationEventId}}
                                                                                </span>

                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">    </td>
                                                                        <td [ngStyle]="{'width':'180px'}">   </td>
                                                                        <td [ngStyle]="{'width':'180px'}">    </td>
                                                                        <td [ngStyle]="{'width':'180px'}">    </td>
                                                                        <td [ngStyle]="{'width':'180px'}">    </td>
                                                                        <td [ngStyle]="{'width':'180px'}">    </td>
                                                                        <td [ngStyle]="{'width':'180px'}">    </td>

                                                                        <td [ngStyle]="{'width':'180px'}">    {{product.createdStamp | date:"yyyy-MM-dd hh:mm:ss"}}

                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">    {{product.lastUpdatedStamp | date:"yyyy-MM-dd hh:mm:ss"}}

                                                                        </td>
                                                                        <td [ngStyle]="{'width':'180px'}">
                                                                            <button *ngIf="!this.Hidebtn" type="submit" class="btn btn-secondary submit-btn ml-2"
                                                                            style="margin: 0;" 
                                                                            (click)="removeSubscriptionCommEvent(product)"
                                                                           >Delete</button>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>

                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                <h4 class="ml-2">
                                                                                    No Record
                                                                                    Found</h4>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="commPopUp" tabindex="-1" role="dialog" aria-labelledby="commPopUp" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Subscription Comm Event</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <br>


                <div class="w3-card-4 classCardPopUp" style="width: 100%;margin-top:0%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians" style="  margin-top: 0px!important;
                                  background: #F5F7FA!important;">

                            <li class="aSize" >
                                Add Subscription Comm Event</li>


                        </ul>
                    </div>
                    <div>
                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100" [formGroup]="newCommunication">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                <div class="col-lg-12">
                                                    <div class="row">
                                                       
                                                        <div class="col-lg-3 form-group classInput">
                                                            <label for="exampleInputEmail1">Communication Event ID
                                                        </label>
                                                        </div>
                                                        <div class="col-lg-2 form-group">
                                                            <p-dropdown filter="true" formControlName="CommunicationEvent" 
                                                            [options]="CommunicationEventIdArray" optionlabel="label" placeholder="Enter Communication Event ID">
                                                            </p-dropdown>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-10 col-10 main-submit-button" style="margin-left: -42%!important;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createSubscriptionCommEvent()" >Create</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>

<!-- <div class="modal fade" id="commPopUp" tabindex="-1" role="dialog" aria-labelledby="commPopUpTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Subscription Comm Event
                </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Add Subscription Comm Event
                                </a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newCommunication">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                   
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Communication Event ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="CommunicationEvent" 
                                                        [options]="CommunicationEventIdArray" optionlabel="label" placeholder="Enter Communication Event ID">
                                                        </p-dropdown>
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="createSubscriptionCommEvent()" >Create</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div> -->

<div class="modal fade" id="newpopup" tabindex="-1" role="dialog" aria-labelledby="newpopupTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> New Subscription</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>New Subscription</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="newSubscription">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput" *ngIf="this.showSubscription">
                                                        <label for="exampleInputEmail1">Subscription ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group" *ngIf="this.showSubscription">
                                                        <input type="text" class="form-control" formControlName="SubscriptionID" disabled
                                                        id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Description
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Subscription Resource ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="subsResourceID"
                                                        [options]="subscriptionResourceIdArray" optionlabel="label" placeholder="Enter Subscription Resource ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Communication Event ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="commEventID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Communication Event ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Contact Mech ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="contactMechID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Contact Mech ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Originated From Party ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="originPartyID"
                                                        [options]="OriginatedFromRoleTypeIdArray" optionlabel="label" placeholder="Enter Originated From Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Originated From Role Type ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="originRoleTypeID" 
                                                        [options]="OriginatedFromRoleTypeIdArray" optionlabel="label" placeholder="Enter Originated From Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="partyID"
                                                        [options]="partyIdArray" optionlabel="label" placeholder="Enter Party ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Role Type ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="roleTypeID"
                                                        [options]="RoleTypeIdArray" optionlabel="label" placeholder="Enter Role Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Party Need ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="partyNeedID"
                                                       [options]="partyIdArray"  optionlabel="label" placeholder="Enter Party Need ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Need Type ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="needTypeID" optionlabel="label" placeholder="Enter Need Type ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Order ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="orderID"
                                                        [options]="orderByIdArray" optionlabel="label" placeholder="Enter Order ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Order Item Seq ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="orderItemSeqID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Order Item Seq ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="productID" 
                                                        [options]="ProductIdArray"
                                                        optionlabel="label" placeholder="Enter Product ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Product Category ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="productCategoryID"
                                                        [options]="productCategoryArray" optionlabel="label" placeholder="Enter Product Category ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Inventory Item ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="inventoryItemID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Inventory Item ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Subscription Type
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="subsType"
                                                        [options]="subscriptionTypeArray" optionlabel="label" placeholder="Enter Subscription Type">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">External Subscription ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="extSubsID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External Subscription ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="throughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Purchase From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="purchaseFromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase From Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Purchase Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="purchaseThroughDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Purchase Through Date">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Max Life Time
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="maxLifeTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Max Life Time">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Max Life Time UOM ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="maxUomID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Max Life Time UOM ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Available Time
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="availTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Available Time">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Available Time UOM ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="availUomID" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Available Time UOM ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Count Limit
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="countLimit" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Count Limit">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Time
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="useTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Use Time">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Use Time UOM
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="useTimeUom"
                                                        [options]="useTimeUomArray" optionlabel="label" placeholder="Enter Use Time UOM">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Automatic Extend
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="autoExtend"
                                                       [options]="yesNoArray"  optionlabel="label" placeholder="Enter Automatic Extend">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Cancel Time
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="cancelTime" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Cancel Time">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Cancel UOM Time
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="cancelUomTime"
                                                        [options]="useTimeUomArray" optionlabel="label" placeholder="Enter Cancel UOM Time">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Grace Period on Expiry
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control" formControlName="gracePeriodExpiry" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Grace Period on Expiry">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Grace Period UOM ID
                                                    </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <p-dropdown filter="true" formControlName="gracePeriodUomID"
                                                        [options]="useTimeUomArray"  optionlabel="label" placeholder="Enter Grace Period UOM ID">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Expiration Completed Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="expCompletedDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Expiration Completed Date">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button *ngIf="!showSubscription" type="submit" class="btn btn-secondary submit-btn" (click)="createSubscription()">Create</button>
                                            <button *ngIf="showSubscription"  type="submit" class="btn btn-secondary submit-btn" (click)="updateSubscription()">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="newAttr" tabindex="-1" role="dialog" aria-labelledby="newAttrTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Subscription Attributes</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>
            </div>
             <div class="modal-body d-flex">
                <div class="bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li><a>Update Subscription Attributes</a></li>

                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editAttributes">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Attribute Name<span
                                                                style="color: red;">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="attrName" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Attribute Name">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Attribute Value
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 form-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="attrValue" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Attribute Value">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-10 col-10 main-submit-button">
                                            <button type="submit" class="btn btn-secondary submit-btn" *ngIf="!this.showAtt" (click)="createSubscriptionAttribute()" >Add</button>
                                            <button type="submit" class="btn btn-secondary submit-btn" *ngIf="this.showAtt" (click)="updateSubscriptionAttribute()" >Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>