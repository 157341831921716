import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { DatePipe,Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-party-add-to-role',
  templateUrl: './party-add-to-role.component.html',
  styleUrls: ['./party-add-to-role.component.css']
})
export class PartyAddToRoleComponent implements OnInit {

  roleList: any;
  id: string;
  mainrole: any;
  MainArrayValue: any[]=[];
  allroleArray: any[]=[];
  allrole: any;
  allRoleRole:string;
  secondRoleRole:string;
  mainRoleDrop:string;
  parent: any;
  parentArray: any[]=[];
  createJournal:FormGroup;
  activeCategory=2;
  partyId: any;
  jid: any;
  jname: any;
  show=false;
  taxAuthPartyId: any;
  @ViewChild('closebutton') closebutton;
  constructor( readonly  _Router: Router,
    private _location: Location,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    public bsModalRef: BsModalRef) { 
      this.createJournal = this._FormBuilder.group({
        journalName: [''],
       
        
      });
    
  }
 
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.taxAuthPartyId = params["party"];
    });
    this.getRoleMainType();
    this.getRoleAllType();
  }
  
  getRoleMainType(){
    this.spinner.show();
   this.id="MAIN"
     this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
       this.mainrole = res.data;
       this.spinner.hide();
        for(const value of this.mainrole){
         this.MainArrayValue.push({
           label:value.description,
           value:value.roleTypeId
         })
       } 

     })
    
    }
    getRoleAllType(){
      this.spinner.show();
      this.id="ALL"
        this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
          this.allrole = res.data;
          this.spinner.hide();
          for(const value of this.allrole){
            this.allroleArray.push({
              label:value.description,
              value:value.roleTypeId
            })
          }
        
        })
       
       }
 /*  getRoleMainType */
 onChange(event){
  this.spinner.show();
  this.parentArray=[]
  this.accountsService.getRoleParentTypeID(event.value).subscribe((res:any)=> {
    this.parent = res.data;
    this.spinner.hide();
    for(const value of this.parent){
      this.parentArray.push({
        label:value.description,
        value:value.roleTypeId
      })
    }
  })

}
 
 addMainRole(): void {
  this.spinner.show();
      this.accountsService.postRoleTypeSave(this.taxAuthPartyId,this.mainRoleDrop).subscribe((res: any) => { 
        if (res.success) {
          this._ToastrService.success("Created Successfully");
          this.closebutton.nativeElement.click();
          this.spinner.hide();
          this._location.back();
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      });
     
    }
    addPraentRole(): void {
      this.spinner.show();
      this.accountsService.postRoleTypeSave(this.taxAuthPartyId,this.secondRoleRole).subscribe((res: any) => {
        if (res.success) {
          this._ToastrService.success("Created Successfully");
          this.closebutton.nativeElement.click();
          this.spinner.hide();
          this._location.back();
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    
    }
    addAllRole(): void {
      this.spinner.show();
         this.accountsService.postRoleTypeSave(this.partyId,this.allRoleRole).subscribe((res: any) => {         
           if (res.success) {
             this._ToastrService.success("Created Successfully");
             this.closebutton.nativeElement.click();
             this.spinner.hide();
             this._location.back();
           }
         }, (err) => {
          this.spinner.hide();
           for (const value of err.error.errors) {
             this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
           }
         });
        
       }

  cancelSubmit(){
    this._location.back();
  }

}
