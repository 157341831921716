import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import {MyContactsService} from "../../crm/contacts/my-contacts/my-contacts.service";
import { DatePipe,Location } from '@angular/common';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-new-party-note',
  templateUrl: './new-party-note.component.html',
  styleUrls: ['./new-party-note.component.css']
})
export class NewPartyNoteComponent implements OnInit {
  fileUploadForm: FormGroup;
  createJournal:FormGroup;
  activeCategory=1;
  party: any;
  show=false;
  noteName:string;
  noteText:string;
  partyIdForCreate: any;
  showCreateBtn: boolean;
  noteId: any;
  note: any;
  showUpdateBtn: boolean;
  @Output() closeNoteModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closebutton') closebutton;
	
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    private _location: Location,
    readonly myContactsService:MyContactsService,
    public bsModalRef: BsModalRef ) { 
    
    
  }
 
 
 

  ngOnInit(): void {
    this.fileUploadForm = this._FormBuilder.group({
      noteName: [''],
      note:['']
    });

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate = params["party"];
      const party=this.partyIdForCreate
      if(party){
        this.showCreateBtn=true;   
      }
    });
  

    this._ActivatedRoute.queryParams.subscribe(params => {
      this.noteId = params["noteId"]; 
      this.party = params["party"];
      this.noteName = params["noteName"];
      this.note= params["note"];
      if(this.noteId && this.party){
        this.showUpdateBtn=true;
        this.showCreateBtn=false; 
      }
     
    });
  }

  navigate() {
    this._location.back();
  }

  navigateFromCreate(){
    this.closebutton.nativeElement.click();
    this._location.back();
  }
  onSubmit(){
    this.spinner.show();
    const form = {...this.fileUploadForm.value}
    this.myContactsService.createContactNote(form,this.partyIdForCreate).subscribe(res=> {
      if(res.success) {
        this.toastr.success(res.data.successMessage);
        this.modalClose();
        this.spinner.hide();
        this._location.back();

      }   
     },(err) => {
      this.spinner.hide();
       for(const value of err.error.errors){
        this.toastr.error(value.fieldName + " " + value.fieldError);
          }     }
      )
    
  }

  onUpdate() {
    this.spinner.show();
    const form = { ...this.fileUploadForm.value}
    this.myContactsService.updateContactNote(form,this.noteId).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.modalClose();
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  changeactiveCategoryFunction(tab: number){
    this.activeCategory =tab;
  }

  modalClose(): void {
    this.closebutton.nativeElement.click();
    this.bsModalRef.hide();
    this.closeNoteModal.emit();
  }

}
