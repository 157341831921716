<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-grey " (click)="cancelSubmit();" style="cursor: pointer;">Production Run Link


                </span>
                <span class="color-black pl-1"> >Add New Production Run Link



                </span>

                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Add Production Run Link



                                </a></li>



                        </ul>
                    </div>


                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="createJob">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm"  >
                                                    <label for="exampleInputEmail1">

                                                        Production Run ID<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2" >
                                                    <p-dropdown placeholder="Enter Production Run ID" filter="true"
                                                    formControlName="ProductionRunID"
                                                    [options]="ProductionproductionRunIdArray" optionlabel="label">

                                                </p-dropdown>

                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Type<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Type" filter="true"
                                                    formControlName="Type"
                                                    [options]="enumDataArrayList" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                     
                                    </div>


                                </form>
                                <div class="main-submit-button" style="margin-left: 64%;">

                                    <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>