<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;"> Communication Event Return
                    </span>

                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass"
                    >Cancel</button>
            </div>
            <div class="w3-card-4 classCard" >
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">

                        <li [ngClass]="activeCategory==2?'active':''"><a>
                            Add Communication Event Return</a></li>



                    </ul>
                </div>

                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="form-group">
                            <div class="row">
                                <form class="w-100" [formGroup]="createReturn">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12" >
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">



                                                        Return ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown [options]="returnArray" optionlabel="label"
                                                    placeholder="Return Id" filter="true"
                                                        formControlName="returnId">
    
                                                    </p-dropdown>

                                                </div>
                                                
                                               
                                            </div>
                                        </div>

                                     




                                    </div>


                                </form>
                                <div class="main-submit-button" style="margin-left: 15%;">
                                    <button (click)="onSubmit()" type="submit" class="btn btn-secondary submit-btn">Add Return</button>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>