<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Relationships
                </span>
                <span class="color-black pl-1" > >Add Other Party Relationship
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                        Add Other Party Relationship
                    </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                        Update Other Party Relationship
                    </a></li>
                   
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="newRelationType">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    To Party ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                {{this.party}}
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    In The Role Of</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true"   *ngIf="!this.show"
                                                placeholder="Enter In The Role Of"
                                                name="Parent Type ID"
                                                formControlName="inRoleof"
                                                [options]="roleListArray" optionlabel="label">
                                            </p-dropdown>
                                            <input  *ngIf="this.show" type="text"    class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp"   placeholder="Enter In The Role Of"
                                            formControlName="inRoleof" readonly>
                                            </div>
        
                                           
        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    Is A</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" *ngIf="!this.show"
                                              placeholder="Enter Is A"
                                              name="Parent Type ID"
                                              [options]="ParentTypeIdPartyArray" optionlabel="label"
                                              formControlName="isA">
                                          </p-dropdown>
                                          <input *ngIf="this.show" type="text"    class="form-control" id="exampleInputEmail1"
                                          aria-describedby="emailHelp"    placeholder="Enter Is A"
                                          formControlName="isA" readonly>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    From Party ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true"  *ngIf="!this.show"
                                                placeholder="Enter From Party ID"
                                                name="Parent Type ID"
                                                formControlName="fromParty"
                                                [options]="partyIdArray" optionlabel="label">
                                            </p-dropdown>
                                            <input type="text"  *ngIf="this.show"    class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp"    placeholder="Enter From Party ID"
                                            formControlName="fromParty" readonly>
                                            </div>
        
                                           
        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    In The Role Of</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" *ngIf="!this.show"
                                                placeholder="Enter In The Role Of"
                                                name="Parent Type ID"
                                                formControlName="role"
                                                [options]="allroleArray" optionlabel="label">
                                            </p-dropdown>
                                            <input *ngIf="this.show" type="text"    class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp"   placeholder="Enter In The Role Of"
                                            formControlName="role" readonly>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Status</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" *ngIf="!this.show"
                                              placeholder="Enter Status"
                                              name="Parent Type ID"
                                              [options]="PartyInvitationStatusListArray" optionlabel="label"
                                              formControlName="status">
                                          </p-dropdown>
                                          <input *ngIf="this.show" type="text"    class="form-control" id="exampleInputEmail1"
                                          aria-describedby="emailHelp" placeholder="Enter Status"
                                          formControlName="status" readonly> 
                                            </div>
        
                                           
        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    From Date</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date"  *ngIf="!this.show"    class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter From Date"
                                                formControlName="fromDate"> 
                                                <input type="date"  *ngIf="this.show"    class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter From Date"
                                                formControlName="fromDate" readonly> 
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Through Date</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="date"    class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Through Date"
                                                formControlName="throughDate">
                                            </div>
        
                                           
        
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
        
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                                                    Security Group ID</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" *ngIf="!this.show"
                                                placeholder="Enter Security Group ID"
                                                name="Parent Type ID"
                                                [options]="groupIdIdArray" optionlabel="label"
                                                formControlName="securityId">
                                            </p-dropdown>
                                            <input type="text" *ngIf="this.show"   class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp"   placeholder="Enter Security Group ID"
                                            formControlName="securityId" readonly>
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                                    Comments</label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="email"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Comments"
                                                formControlName="comment">
                                            </div>
        
                                           
        
                                        </div>
                                    </div>

                                   
                                 
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 63%;">
                               
                                <button type="submit" (click)="onSubmit();" *ngIf="!show" class="btn btn-secondary submit-btn">Submit</button>
                                 
                                <button type="submit" (click)="onUpdate();" *ngIf="show" class="btn btn-secondary submit-btn">Update</button>
                                
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div> -->


<div class="modal-header">
    <span class="color-black pl-1">Add Other Party Relationship</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex">
      <div class=" bg-white color-grey" style="width: 100%;">
          <div class="w3-card-4 classCardPopUp" style="width: 100%;">
              <div class="container-fluid">
                  <ul class="tabbing-section tabbing-accordians tabClass">

                    <li class="active"><a>
                        Add Other Party Relationship
                    </a></li>
                    <!-- <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                        Update Other Party Relationship
                    </a></li> -->
                  </ul>
              </div>
              
            
              <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form  class="w-100" [formGroup]="newRelationType">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
    
                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">
                                                To Party ID</label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            {{this.party}}
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                In The Role Of</label>
                                        </div>
                                     
                                        <div class="col-lg-3 custom-dropdown">
                                            <p-dropdown filter="true"   *ngIf="!this.show"
                                            placeholder="Enter In The Role Of"
                                            name="Parent Type ID"
                                            formControlName="inRoleof"
                                            [options]="roleListArray" optionlabel="label">
                                        </p-dropdown>
                                        <input  *ngIf="this.show" type="text"    class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"   placeholder="Enter In The Role Of"
                                        formControlName="inRoleof" readonly>
                                        </div>
    
                                       
    
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
    
                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">
                                                Is A</label>
                                        </div>
                                     
                                        <div class="col-lg-3 custom-dropdown">
                                            <p-dropdown filter="true" *ngIf="!this.show"
                                          placeholder="Enter Is A"
                                          name="Parent Type ID"
                                          [options]="ParentTypeIdPartyArray" optionlabel="label"
                                          formControlName="isA">
                                      </p-dropdown>
                                      <input *ngIf="this.show" type="text"    class="form-control" id="exampleInputEmail1"
                                      aria-describedby="emailHelp"    placeholder="Enter Is A"
                                      formControlName="isA" readonly>
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                From Party ID</label>
                                        </div>
                                     
                                        <div class="col-lg-3 custom-dropdown">
                                            <p-dropdown filter="true"  *ngIf="!this.show"
                                            placeholder="Enter From Party ID"
                                            name="Parent Type ID"
                                            formControlName="fromParty"
                                            [options]="partyIdArray" optionlabel="label">
                                        </p-dropdown>
                                        <input type="text"  *ngIf="this.show"    class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"    placeholder="Enter From Party ID"
                                        formControlName="fromParty" readonly>
                                        </div>
    
                                       
    
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
    
                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">
                                                In The Role Of</label>
                                        </div>
                                     
                                        <div class="col-lg-3 custom-dropdown">
                                            <p-dropdown filter="true" *ngIf="!this.show"
                                            placeholder="Enter In The Role Of"
                                            name="Parent Type ID"
                                            formControlName="role"
                                            [options]="allroleArray" optionlabel="label">
                                        </p-dropdown>
                                        <input *ngIf="this.show" type="text"    class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"   placeholder="Enter In The Role Of"
                                        formControlName="role" readonly>
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Status</label>
                                        </div>
                                     
                                        <div class="col-lg-3 custom-dropdown">
                                            <p-dropdown filter="true" *ngIf="!this.show"
                                          placeholder="Enter Status"
                                          name="Parent Type ID"
                                          [options]="PartyInvitationStatusListArray" optionlabel="label"
                                          formControlName="status">
                                      </p-dropdown>
                                      <input *ngIf="this.show" type="text"    class="form-control" id="exampleInputEmail1"
                                      aria-describedby="emailHelp" placeholder="Enter Status"
                                      formControlName="status" readonly> 
                                        </div>
    
                                       
    
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
    
                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">
                                                From Date</label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <input type="date"  *ngIf="!this.show"    class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter From Date"
                                            formControlName="fromDate"> 
                                            <input type="date"  *ngIf="this.show"    class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter From Date"
                                            formControlName="fromDate" readonly> 
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Through Date</label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <input type="date"    class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Through Date"
                                            formControlName="throughDate">
                                        </div>
    
                                       
    
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
    
                                        <div class="col-lg-4 form-group classInput">
                                            <label for="exampleInputEmail1">
                                                Security Group ID</label>
                                        </div>
                                     
                                        <div class="col-lg-3 custom-dropdown">
                                            <p-dropdown filter="true" *ngIf="!this.show"
                                            placeholder="Enter Security Group ID"
                                            name="Parent Type ID"
                                            [options]="groupIdIdArray" optionlabel="label"
                                            formControlName="securityId">
                                        </p-dropdown>
                                        <input type="text" *ngIf="this.show"   class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp"   placeholder="Enter Security Group ID"
                                        formControlName="securityId" readonly>
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Comments</label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <input type="email"  formControlName="journalName"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Comments"
                                            formControlName="comment">
                                        </div>
    
                                       
    
                                    </div>
                                </div>

                               
                             
                               
                            </div>
                            

                        </form>
                        <div class="main-submit-button" style="margin-left: 73%;">
                           
                            <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">Submit</button>
                             
                            <!-- <button type="submit" (click)="onUpdate();" *ngIf="show" class="btn btn-secondary submit-btn">Update</button> -->
                            
                            
                            
                          
                        </div>
                    </div>
                </div>

            </div>
              
             </div>
      </div>
  </div>


