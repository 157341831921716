import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { DatePipe } from '@angular/common';

    import { Location } from '@angular/common';

@Component({
  selector: 'app-product-backlog',
  templateUrl: './product-backlog.component.html',
  styleUrls: ['./product-backlog.component.css']
})
export class ProductBacklogComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  productBacklogForm: FormGroup;
  productForm: FormGroup;
  DetailedPage = "";
  partyId = "";
  productId = "";
  productList: any;
  advanceSearch: { ProductID: string; ProductName: string; StatusId: string; };

  CompanyandProductOwner: any;
  CompanyandProductOwnerArray: any[] = [];
  ProductMembersList: any;
  ProductContentList: any;
  ProductInformation: any;
  statusArray: { label: string; value: string; }[];
  StatusId: any;
  StatusIdArray: any[] = [];
  PartyId: any;
  partyIdArray: any[] = [];
  partIdValue: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
      private _location: Location,
      readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) {

    this.productForm = this._FormBuilder.group({
      CompanyandProductOwner: [''],
      ProductName: [''],
      Description: [''],
      partyId: ['']

    });
    // this.productBacklogForm = this._FormBuilder.group({
    //   ProductID: [''],
    //   ProductName: [''],
    //   StatusId: [''],

    // });
    this.advanceSearch = {

      ProductID: "",
      ProductName: "",
      StatusId: "",


    };
    this.statusArray = [

      {
        label: 'Any',
        value: 'Any'
      },
      {
        label: 'Active',
        value: 'PRODUCT_ACTIVE'
      },
      {
        label: 'Closed',
        value: 'PRODUCT_CLOSED'
      },


    ];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.SCRUM_ADMIN=="SCRUM_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.SCRUM_BILLING_ADMIN=="SCRUM_BILLING_ADMIN"){
      this.Hidebtn=true;
    }
    this.getProductList();
    this.getCompanyAndProductOwnerList();
    this.getProductMemberParty();

  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
      
  getCompanyAndProductOwnerList() {
    this.spinner.show();
    this.accountsService.getCompanyAndProductOwnerList().subscribe(res => {
      this.CompanyandProductOwner = res.data;
      this.spinner.hide();
      for (const value of this.CompanyandProductOwner) {
        this.CompanyandProductOwnerArray.push({
          label: value.partyIdDemo,
          value: value.demoId[0].partyId
        })
      }
    })
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.ProductID = "";
    this.advanceSearch.ProductName = "";
    this.advanceSearch.StatusId = "";
    this.accountsService.getProductList().subscribe((res: any) => {
      this.productList = res.data;
      this.spinner.hide();
    })
  }

  getProductMemberParty() {
    this.spinner.show();
    this.accountsService.getProductMemberParty().subscribe(res => {
      this.PartyId = res.data[0].partyId;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getFindProduct() {

    this.spinner.show();
    if(this.advanceSearch.ProductID||this.advanceSearch.ProductName||this.advanceSearch.StatusId ){
    const req = {
      "productId": this.advanceSearch.ProductID ? this.advanceSearch.ProductID : '',
      "internalName": this.advanceSearch.ProductName ? this.advanceSearch.ProductName : '',
      "statusID": this.advanceSearch.StatusId ? this.advanceSearch.StatusId : '',
    }
    this.accountsService.getFindProduct(req.productId, req.internalName, req.statusID).subscribe((res: any) => {
      this.productList = res.data;
      this.spinner.hide();
    })}
    else{
      this.getProductList();
    }
    this.spinner.hide();
  }

  getProductList() {
    this.spinner.show();
    this.accountsService.getProductList().subscribe(res => {
      this.productList = res.data;
      this.spinner.hide();
    })
  }

  editorAddProductBacklogs() {
    this.spinner.show();
   
    for (const r of this.CompanyandProductOwner) {
     
        this.partIdValue = r.partyIdDemo;
      
    }
    const formData = {
      "internalName": this.productForm.value.ProductName,
      "longDescription": this.productForm.value.Description,
      "partyCompany":this.partIdValue, 
      "partyId": this.productForm.value.CompanyandProductOwner,
      "productId":"",
      "productTypeId": "SCRUM_ITEM",

    }
    this.accountsService.editorAddProductBacklogs(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getProductList();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }
  detailedPage(productId, internalName, billFromParty,partyId, Status, longDescription) {
    this._Router.navigate(["/psa/product-overview"], { queryParams: { productId: productId, internalName: internalName, billFromParty: billFromParty, partyId:partyId,Status: Status, longDescription: longDescription } })
  }

  myProfilePage(partyId) {
    this._Router.navigate(["/psa/my-profile"], { queryParams: { taxAuthPartyId: partyId } })
  }

  Backlog() {
    this._Router.navigate(["/psa/product-backlog"])
  }
  Sprint() {
    this._Router.navigate(["/psa/sprints"])
  }
  MyWork() {
    this._Router.navigate(["/psa/my-work"])
  }
  Tasks() {
    this._Router.navigate(["/psa/tasks"])
  }
  OpenTest() {
    this._Router.navigate(["/psa/open-test"])
  }
  Revision() {
    this._Router.navigate(["/psa/find-task-revision"])
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
