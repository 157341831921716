import { DatePipe } from '@angular/common';
import { Component, OnInit , ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import Swal from 'sweetalert2'
import { detailedContactsService } from 'src/app/crm/contacts/detailed-contacts-summary/detailed-contacts-summary.service';
import * as moment from 'moment';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SkillService } from 'src/app/human-resource/skills/skill.service';
import { EmployeeService } from 'src/app/human-resource/employees/employess.service';
import { EmployementApplicationService } from 'src/app/employeeApplication/employee-application.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { EmployeeApplicationService } from 'src/app/employeeApplication/employee-application/employee-application.service';

    
    import { Location } from '@angular/common';

@Component({
  selector: 'app-my-portal-my-profile',
  templateUrl: './my-portal-my-profile.component.html',
  styleUrls: ['./my-portal-my-profile.component.css']
})
export class MyPortalMyProfileComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton2') closebutton2;
  faEdit = faEdit;
  faTrash = faTrash;
  Editform:FormGroup;
  shoppingList:FormGroup;
  relatedContact:any[]=[];
  createJournal:FormGroup;
  createVendor:FormGroup;
  findPartyForm:FormGroup;
  activeCategory=3;
  products3: any;
  paymentPartyGroup: any;
  downloading: boolean;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 3;
  search = ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  FinancialTransaction:any[]=[];
  accountData:any;
  showAttribute:boolean;
  budgetId: any;
  budgetHeader: any;
  budgetStatus: any;
  budgetRole: any;
  budgetItem: any;
  budgetReview: any;
  budgetItemList: any;
  budgetRoleList: any;
  budgetReviewList: any;
  status: string;
  taxAuthPartyId: any;
  partyId:any
  roleList: any;
  accountDetails: any;
  isAdditionalDetail: boolean;
  isJobDetail: boolean;
  contactInformation: any[]=[];
  accountName: any;
  addr: string;
  emailId: any;
  phoneNumber: string;
  EditListRelatedAccountList:any;
  contentList: any;
  showAttrModal: boolean = false;
  billingAccounts: any[];
  paymentMethods: any[];
  skillList: any;
  public employeeDetails: any;
  public employeeDetail: any;
  employeeDetailArray: any = [];
  departmentList: any;
  departmantArray: any = [];
  departmentId: any;
  department: any;
  demoName: any;
  demoId: any;
  employeeDetailArrayEmail: any = [];
  employeeDetailArrayPhone: any = [];
  employeeDetailRelatedAccount: any;
  emergencyContact: any;
  personId: any;
  createLinkParty:FormGroup;
  partyIdArray: any[]=[];
  activeCategoryB = 1;
  IdentificationList: any;
  PartyResume: any;
  SegmentRole: any;
  PartyClassification: any;
  PartyRate: any;
  UserLoginData: any;
  PartyAttribute: any;
  PartyContactList: any;
  employmentApplication: any;
  productStoresListArray: any[]=[];
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  AllProductStoreRole: any;
  Visitors: any;
  PartyLoyaltyPoints: any;
  Person: any;
  ProductStore: any;
  Preferences: any;
  RelationshipPartyIdTo: any;
  AllFinanceHistory: any;
  partyAttributes: FormGroup;
  ShoppingListArray: any[]=[];
  fileUploadFormNote: FormGroup;
  TaxInfo: any;
  PartyIcsAvsOverRide: any[]=[];
  showUpdateNoteBtn:boolean;
  partyIdLogin: string;
  fileUploadForm: FormGroup;
  notePro: any;
  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,
    readonly _detailedContactsService: detailedContactsService,
    readonly toastr: ToastrService,
    readonly myContactsService: MyContactsService,
    readonly skillService: SkillService,
    readonly employeeService: EmployeeService,
    readonly router: Router,
    readonly employementApplicationService: EmployementApplicationService,
    private _location: Location,
    readonly addSkillService: AddSkillService,
    readonly _CRMAccountService: CRMAccountService,
    readonly employeeApplicationService: EmployeeApplicationService,
    readonly _FormBuilder: FormBuilder,) {
      this.partyIdLogin=localStorage.getItem("partyId")
      this.shoppingList=this._FormBuilder.group({
        shoppingList: [''],
      
       
        
      });
      this.partyAttributes = this._FormBuilder.group({
        attrName: [''],
        attrValue: [''],
        attrDescription: [''],
      });
      this.createVendor=this._FormBuilder.group({
        policies: [''],
        logo: [''],
        CompanyTitle: [''],
        ManifestCompany: [''],
       
        
      });
      this.createJournal = this._FormBuilder.group({
        journalName: [''],
       
        
      });
      this.createLinkParty=this._FormBuilder.group({
        partyIdTo: [''],
        partyIdFrom:['']
       
        
      });

    this.fileUploadFormNote = this._FormBuilder.group({
      noteName: [''],
      note: ['']
    });
      this.findPartyForm=this._FormBuilder.group({
        StoreID: [''],
        RoleTypeID:[''],
        FromDate: [''],
        ThroughDate:[''],
        SequenceNum: ['']
       
        
      });
      this.fileUploadForm = this._FormBuilder.group({
        noteName: [''],
        note:['']
      });
      this.Editform = new FormGroup({
        file: new FormControl('', [
          Validators.required
        ]),
        partyContentTypeId: new FormControl('')
      });
     }
     ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.taxAuthPartyId = this.partyIdLogin;
      this.partyId = this.partyIdLogin;
    });
    this.getPartyRole();
    this.getAccountNote();
    //this.accountDetail();
    this.getPartyContentsList();
    this.getPaymentMethods();
    this.editVendorsData();
    this.getSkillList();
    this.getEmployeeDetail();
    this.getPartyId();
    this.getIdentificationList();
    this.getPartyResume();
    this.getSegmentRole();
    this.getPartyClassification();
    this.getPartyRate();
    this.getUserLoginData();
    this.getPartyAttribute();
    this.getPartyContactList();
    this.getEmployeeApplication();
    this.getOrderListItems();
    this.getRoleAllType();
    this.getAllProductStoreRole();
    this.getVisitors();
    this.getPartyLoyaltyPoints();
    this.getPerson();
    this.getProductStore();
    this.getPreferences();
    this.getRelationshipPartyIdTo();
    this.getAllFinanceHistory();
    this.getShoppingList();
    this. getTaxInfo();
    this.getPartyIcsAvsOverRide();
    this.EditListRelatedAccounts();
  }
  changeactiveCategoryFunctionValue(tab: number) {
    this.activeCategoryB = tab;
  }
  resetnote(){
    this.showUpdateNoteBtn = false;
    this.fileUploadFormNote.reset();

  }

homeButton(){
  this.router.navigate(['/myPortal/my-portal-my-profile'])

}
cancelSubmit(){
  this._location.back();
}

  updateNote(product){
    this.showUpdateNoteBtn = true;
    this.notePro =product;
    setTimeout(() => {
    const form =this.fileUploadFormNote;
    form.patchValue({
      "note": product.noteInfo  ,
      "noteName": product.noteName  ,
    })
    }, 2000);
  }
  updateContactNote() {
    this.spinner.show();
    const formData={
      "note": this.fileUploadFormNote.value.note  ,
      "noteName": this.fileUploadFormNote.value.noteName  ,
}
    this.accountsService.updateContactNoteHrms(formData, this.notePro.noteId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Succesfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.getAccountNote();

      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error(err.message);

    });

  }
  onSubmitNote() {
    this.spinner.show();
    const form = { ...this.fileUploadFormNote.value }
    this.myContactsService.createContactNote(form, this.partyId).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.getAccountNote();
        this.fileUploadFormNote.reset();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )

  }
  updateAttribute(product) {
    if (product.attrName) {
      this.showAttribute = true;
      setTimeout(() => {
        const formValue = this.partyAttributes;
        formValue.patchValue({
          attrName: product.attrName,
          attrDescription: product.attrDescription,
          attrValue: product.attrValue,
        })
      }, 2000);
    }
    else {
      this.showAttribute = false;
    }
  }

  onSubmitPartyAttribute() {
    
    this.spinner.show();

    const requestData = {
      "attrDescription": this.partyAttributes.value.attrDescription,
      "attrName": this.partyAttributes.value.attrName,
      "attrValue": this.partyAttributes.value.attrValue,
      "partyId": this.taxAuthPartyId


    }
    this.accountsService.createPartyAttribute(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        //this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }

  onUpdatePartyAttribute() {
    this.spinner.show();

    const requestData = {
      "attrDescription": this.partyAttributes.value.attrDescription,
      "attrName": this.partyAttributes.value.attrName,
      "attrValue": this.partyAttributes.value.attrValue,
      "partyId": this.taxAuthPartyId
    }
    this.accountsService.updatePartyProfileAttribute(requestData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  detailSecurity(id) {
    this.router.navigate(["/partyQuickLink/visitor-summary"], { queryParams: { visitId: id } })
  }
  listAll(){
    this.router.navigate(['partyQuickLink/visits']); 
  }
  EditListRelatedAccounts() {
    this.spinner.show();
    this.myContactsService.EditListRelatedAccounts(this.taxAuthPartyId).subscribe(res => {
      this.EditListRelatedAccountList = res.data;
      this.spinner.hide();
    })
  
  }
  getPartyIcsAvsOverRide() {
    this.spinner.show();
    this.accountsService.getPartyIcsAvsOverRide(this.taxAuthPartyId).subscribe(res => {
      this.PartyIcsAvsOverRide = res.data;
      this.spinner.hide();
    })
  
  }
  updateAVS() {
    this.router.navigate(['partyQuickLink/create-avs-string'], { queryParams: { party: this.taxAuthPartyId } });
  }
  resetAvsOverride() {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       
        this.accountsService.resetAvsOverride(this.taxAuthPartyId).subscribe(res => {
          this.getPartyIcsAvsOverRide(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  getTaxInfo() {
    this.spinner.show();
    this.accountsService.getTaxInfo(this.finSize,this.taxAuthPartyId).subscribe(res => {
      this.TaxInfo = res.data;
      this.spinner.hide();
    })
  
  }
  createTaxInfo() {
    this.router.navigate(['partyQuickLink/create-tax-info'], { queryParams: { party: this.taxAuthPartyId } });
  }
  updateTaxInfo(product) {
    this.router.navigate(['partyQuickLink/create-tax-info'], { queryParams: { party: this.taxAuthPartyId,taxAuthGeoId:product.getTaxInfo.taxAuthGeoId,taxAuthPartyId:product.getTaxInfo.taxAuthPartyId,fromDate:product.fromDate,thruDate:product.thruDate,partyTaxId:product.getTaxInfo.partyTaxId,isExempt:product.getTaxInfo.isExempt,isNexus:product.getTaxInfo.isNexus } });
  }
  deleteTaxInfo(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={ 
          "fromDate": product.fromDate,
          "partyId": this.taxAuthPartyId,
          "taxAuthGeoId": product.getTaxInfo.taxAuthGeoId,
          "taxAuthPartyId": product.getTaxInfo.taxAuthPartyId,
         

        }
        this.accountsService.deleteTaxInfo(req).subscribe(res => {
          this.getTaxInfo(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  getShoppingList(): void {
    this.spinner.show();
    this.ShoppingListArray=[]
    this.accountsService.getShoppingList(this.finSize,this.taxAuthPartyId).subscribe((res) => {
      let ShoppingList = res.data;
      this.spinner.hide();
      for (const value of ShoppingList) {
        this.ShoppingListArray.push({
          label: value.shoppingListWithListName.listName,
          value: value.shoppingListWithListName.shoppingListId,
        });
      }
    });
   
  }
  createPartyEmptyShopping() {
    this.spinner.show();
  
    this.accountsService.createPartyEmptyShopping(this.taxAuthPartyId).subscribe((res: any) => {
    
      if (res.success) {
        this.getShoppingList();
        this.toastr.success("Created Successfully");
        this.spinner.hide();
       
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getAllFinanceHistory() {
    this.spinner.show();
    this.accountsService.getAllFinanceHistory(this.finSize,this.taxAuthPartyId).subscribe(res => {
      this.AllFinanceHistory = res.data;
      this.spinner.hide();
    })
  
  }
  getRelationshipPartyIdTo() {
    this.spinner.show();
    this.accountsService.getRelationshipPartyIdTo(this.taxAuthPartyId).subscribe(res => {
      this.RelationshipPartyIdTo = res.data;
      this.spinner.hide();
    })
  
  }
  
  deleteOtherRelationship(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={ 
          "comments":product.data.comments,
        "fromDate": product.fromDate,
        "partyId": this.taxAuthPartyId,
        "partyIdFrom": product.data.partyIdFrom,
        "partyIdTo": product.data.partyIdTo,
        "partyRelationshipTypeId": product.data.partyRelationshipTypeId,
        "roleTypeIdFrom": product.data.roleTypeIdFrom,
        "roleTypeIdTo": product.data.roleTypeIdTo,
        "securityGroupId": product.data.securityGroupId,
        "statusId":product.data.statusId,
        "thruDate":product.thruDate,
        }
        this.accountsService.deleteOtherRelationship(req).subscribe(res => {
          this.getRelationshipPartyIdTo(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  getPreferences() {
    this.spinner.show();
    this.accountsService.getPreferences(this.taxAuthPartyId).subscribe(res => {
      this.Preferences = res.data;
      this.spinner.hide();
    })
  
  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
  getOrderListItems(): void {
    this.spinner.show();
    this.accountsService.getOrderList().subscribe((res) => {
      let productStores = res.data.productStores;
      this.spinner.hide();
      for (const value of productStores) {
        this.productStoresListArray.push({
          label: value.storeName,
          value: value.productStoreId,
        });
      }
    });
   
  }
  getEmployeeApplication() {
    this.spinner.show();
    const data = {
      "applicationDateFrom": "",
      "applicationDateFromSearchType": "Contains",
      "applicationDateTo": "",
      "applicationDateToSearchType": "Contains",
      "applicationId": "",
      "applyingPartyId":this.taxAuthPartyId,
      "emplPositionId": "",
      "employmentAppSourceTypeId": "",
      "referredByPartyId": "",
      "statusID": [
        ""
      ]
    }
    this.employementApplicationService.getEmpJobList(this.finSize, data).subscribe(res => {
      this.employmentApplication = res.data;
      this.spinner.hide();
    })
  }
  getVisitors() {
    this.spinner.show();
   
    this.accountsService.getVisitors(this.finSize).subscribe(res => {
      this.Visitors = res.data;
      this.spinner.hide();
    })
  }
  
  onDeleteEmp(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.employeeApplicationService.deleteEmployeeApp(id).subscribe((res: any) => {
          if (res.success) {  
            this.getEmployeeApplication();
          }
          else if (res.success == false) {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
      
      getAllProductStoreRole() {
        this.spinner.show();
        this.accountsService.getAllProductStoreRole(this.taxAuthPartyId).subscribe(res => {
          this.AllProductStoreRole = res.data;
          this.spinner.hide();
        })
      
      }
      getProductStore() {
        this.spinner.show();
        this.accountsService.getProductStore(this.taxAuthPartyId).subscribe(res => {
          this.ProductStore = res.data;
          this.spinner.hide();
        })
      
      }
      
      getPartyLoyaltyPoints() {
        this.spinner.show();
        this.accountsService.getPartyLoyaltyPoints(this.taxAuthPartyId).subscribe(res => {
          this.PartyLoyaltyPoints = res.data;
          this.spinner.hide();
        })
      
      }
      getPerson() {
        this.spinner.show();
        this.accountsService.getPerson(this.taxAuthPartyId).subscribe(res => {
          this.Person = res.data.personInformation;
          this.spinner.hide();
        })
      
      }
      
      
  getPartyContactList() {
    this.spinner.show();
    this.accountsService.getPartyContactList(this.taxAuthPartyId).subscribe(res => {
      this.PartyContactList = res.data;
      this.spinner.hide();
    })
  
  }
  getPartyAttribute() {
    this.spinner.show();
    this.accountsService.getPartyAttribute(this.taxAuthPartyId).subscribe(res => {
      this.PartyAttribute = res.data;
      this.spinner.hide();
    })
  
  }
  getUserLoginData() {
    this.spinner.show();
    this.accountsService.getUserLoginData(this.taxAuthPartyId).subscribe(res => {
      this.UserLoginData = res.data;
      this.spinner.hide();
    })
  
  }
  getPartyRate() {
    this.spinner.show();
    this.accountsService.getPartyRate(this.taxAuthPartyId).subscribe(res => {
      this.PartyRate = res.data;
      this.spinner.hide();
    })
  
  }
  getPartyClassification() {
    this.spinner.show();
    this.accountsService.getPartyClassificationByPartyId(this.taxAuthPartyId).subscribe(res => {
      this.PartyClassification = res.data;
      this.spinner.hide();
    })
  
  }
  
  editAddShoping() {
    this.router.navigate(['partyQuickLink/edit-shopping-list'],{ queryParams: {party: this.taxAuthPartyId, shoppingListId:this.shoppingList.value.shoppingList } })
  }
  getIdentificationList() {
    this.spinner.show();
    this.accountsService.getIdentificationList(this.taxAuthPartyId).subscribe(res => {
      this.IdentificationList = res.data;
      this.spinner.hide();
    })
  
  }
  
  getSegmentRole() {
    this.spinner.show();
    this.accountsService.getSegmentRole(this.taxAuthPartyId).subscribe(res => {
      this.SegmentRole = res.data;
      this.spinner.hide();
    })
  
  }
  getPartyResume() {
    this.spinner.show();
    this.accountsService.getPartyResume(this.taxAuthPartyId).subscribe(res => {
      this.PartyResume = res.data;
      this.spinner.hide();
    })
  
  }
  
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  updatePerson() {
    this.router.navigate(['/myPortal/update-personal-info'], 
    { queryParams: { personId: this.taxAuthPartyId } })
  }
  getEmployeeDetail() {
    this.spinner.show();
    this.employeeService.getEmployeeId(this.taxAuthPartyId).subscribe(res => {
      this.employeeDetail = res.data;
      res.data.contactInformation.pop();
      this.contactInformation = res.data.contactInformation;
      this.relatedContact = res.data.relatedContacts;
     
      this.emergencyContact = res.data.emergencyContacts;
      this.demoName = res.data.firstName + " " + res.data.lastName;
      this.demoId = res.data.partyId;
      this.employeeDetailArray.push({
        data: this.employeeDetail
      })
      this.employeeDetailArrayEmail.push({
        data: this.employeeDetail
      })
      this.employeeDetailArrayPhone.push({
        data: this.employeeDetail
      })
      
    })
    this.spinner.hide();
  }
  
  deletePartyIdentification(partyTypeId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deletePartyIdentification(this.taxAuthPartyId, partyTypeId).subscribe(res => {
          this.getIdentificationList(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  
  deleteStoreRole(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req={
          "fromDate": product.fromDate,
          "partyId":product.data.partyId,
          "productStoreId": product.data.productStoreId,
          "roleTypeId": product.data.roleTypeId,
          "sequenceNum": product.data.sequenceNum,
          "thruDate": product.thruDate,

          
        }
        this.accountsService.deleteStoreRole(req).subscribe(res => {
          this.getAllProductStoreRole(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  deleteContactList(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req={
          "contactListId":product.data.contactListId,
          "fromDate":product.fromDate,
          "partyId": product.data.partyId,
          "preferredContactMechId": product.data.preferredContactMechId,
          "statusId":product.data.statusId,
          "thruDate": product.thruDate,

          
        }
        this.accountsService.deleteContactList(req).subscribe(res => {
          this.getPartyContactList(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  deletePartyAttribute(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req={
          "attrDescription":product.attrDescription,
          "attrName":product.attrName,
          "attrValue":product.attrValue,
          "partyId":product.partyId,
          
        }
        this.accountsService.deletePartyAttribute(req).subscribe(res => {
          this.getPartyAttribute(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  deletePartyClassification(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req={
          "fromDate": product.fromDate,
          "partyClassificationGroupId":product.partyRateData.partyClassificationGroupId,
          "partyId": product.partyRateData.partyId,
          "thruDate": product.thruDate,
        }
        this.accountsService.deletePartyClassification(req).subscribe(res => {
          this.getPartyClassification(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  deletePartySegment(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req={
          "partyId":product.segmentGroupId.partyId,
           "roleTypeId": product.segmentGroupId.roleTypeId,
           "segmentGroupId": product.segmentGroupId.segmentGroupId,
        }
        this.accountsService.deletePartySegment(req).subscribe(res => {
          this.getSegmentRole(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  
  deletePartRate(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req={
          "defaultRate":product.partyRateData.defaultRate,
          "fromDate": product.fromDate,
          "partyId": product.partyRateData.partyId,
          "percentageUsed": product.partyRateData.percentageUsed,
          "periodTypeId":"RATE_HOUR",
          "rateAmountFromDate":product.fromDate,
          "rateCurrencyUomId":"USD",
          "rateTypeId": product.partyRateData.rateTypeId,
        }
        this.accountsService.deletePartRate(req).subscribe(res => {
          this.getPartyRate(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  deletePartyResume(product) {
    
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req={
          "contentId":product.data.contentId,
          "partyId": product.data.partyId,
          "resumeDate": product.data.resumeDate,
          "resumeId": product.data.resumeId,
          "resumeText": product.data.resumeText,
        }
        this.accountsService.deletePartyResume(req).subscribe(res => {
          this.getPartyResume(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  onDeleteSkill(partyId, skillTypeId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.skillService.deleteSkills(partyId, skillTypeId).subscribe(res => {
          this.getSkillList(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  

  }
  getSkillList() {
    this.spinner.show();
    const req = {
      "pageNo": this.pageNo,
      "pageSize": this.pageSize
    }
    const skillList = {
      partyId: this.taxAuthPartyId,
      rating: "",
      skillLevel: "",
      skillTypeId: "",
      yearsExperience: ""
    }


    this.skillService.getSkillList(req, skillList).subscribe(res => {
      this.skillList = res.data;
      this.spinner.hide();
    })
   
  }
  
  setPartyLink() {
    this.spinner.show();
  
    this.accountsService.setPartyLink(this.createLinkParty.value.partyIdTo,this.createLinkParty.value.partyIdFrom).subscribe((res: any) => {
    
      if (res.success) {
       
        this.toastr.success("Created Successfully");
        this.spinner.hide();
       
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onUpdateVendor() {
    this.spinner.show();
    const requestData = {
      "manifestCompanyName":  this.createVendor.value.ManifestCompany,
      "manifestCompanyTitle": this.createVendor.value.CompanyTitle,
      "manifestLogoUrl":  this.createVendor.value.logo,
      "manifestPolicies": this.createVendor.value.policies,
      "partyId": this.taxAuthPartyId,
     
    }

    this.accountsService.updateVendors(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.editVendorsData();
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
       
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
 
  editVendorsData() {
    this.spinner.show();
    this.accountsService.getVendorListById(this.taxAuthPartyId).subscribe((res: any) => {
     
      setTimeout(() => {
       
      
        const formValue = this.createVendor;
        formValue.patchValue({
          ManifestCompany: res.data.manifestCompanyName,
          CompanyTitle: res.data.manifestCompanyTitle,
          logo: res.data.manifestLogoUrl,
          policies: res.data.manifestPolicies,
       
        
    
   
        })
      
      }, 3000);
   
      this.spinner.hide();
    })
  }
  accountDetail() {
    this.spinner.show();
    this.accountsService.getAccountById(this.taxAuthPartyId).subscribe(res => {
      this.accountDetails = res.data;
     // this.isAdditionalDetail = res.data.additionalInformation.infoType ? true : false;
      this.isJobDetail = res.data.jobInformation.jobStatus ? true : false;
      res.data.contactInformation.pop();
      this.contactInformation = res.data.contactInformation;
      this.relatedContact = res.data.relatedContacts;
      this.accountName = res.data.groupName;
      this.addr = res.data.address.address1 + ' ' + res.data.address.city + ' ' + res.data.address.postalCode + ' ' + res.data.address.countryGeoId;
      this.emailId = res.data.email.infoString;
      this.phoneNumber = res.data.telenumber.countryCode + ' ' + res.data.telenumber.areaCode + ' ' + res.data.telenumber.contactNumber
    })
    this.spinner.hide();
  }

  getPartyRole(){
    this.accountsService.getPartyRole(this.finSize,this.taxAuthPartyId).subscribe((res:any)=> {
       this.roleList = res.data;
    })
   }

   getAccountNote() {
    this.spinner.show();
    this.accountsService.getContactNotes(this.taxAuthPartyId).subscribe(res => {
      this.accountData = res.data;
      this.spinner.hide();
    });
  }

   deleteRoleTypeId(id){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
      this.accountsService.deleteRoleTypeId(this.taxAuthPartyId,id).subscribe((res:any)=> {
        this.toastr.success("Delete Successfully");
        this.getPartyRole();
    },(err) => {
      this.toastr.error(err.error.message);
   })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
}

getPartyContentsList() {
  this.spinner.show();
  this.accountsService.getPartyContentList(this.taxAuthPartyId).subscribe(res => {
    this.contentList = res.data;
    this.spinner.hide();
  })

}



getBillingAccounts(): void {
  this.spinner.show();
  this._CRMAccountService.getBillingAccounts(this.taxAuthPartyId)
    .then(data => {
      if (data.success) {
        this.billingAccounts = data.data.map(value => {
          return {
            ...value, ...{ type: 'BILLING', paymentMethodTypeId: 'BILLING' }
          };
        });
        this.paymentMethods = [...this.paymentMethods, ...this.billingAccounts];
      }
      this.spinner.hide();
    });

}

getPaymentMethods(): void {
  this.spinner.show();
  this._CRMAccountService.getAllPayment(this.taxAuthPartyId, false)
    .then(data => {
      if (data.success) {
        this.paymentMethods = data.data.map(value => {
          switch (value.paymentMethod.paymentMethodTypeId) {
            case 'GIFT_CARD':
              return {
                ...value.giftCard, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Gift Card'
                }
              };
              break;
            case 'EFT_ACCOUNT':
              return {
                ...value.eftAccount, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'EFT Account'
                }
              };
              break;
            case 'CERTIFIED_CHECK':
              return {
                ...value.certifiedCheckAccount, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Certified Check'
                }
              };
              break;
            case 'CREDIT_CARD':
              return {
                ...value.creditCard, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Credit Card'
                }
              };
              break;
            case 'COMPANY_CHECK':
              return {
                ...value.companyCheckAccount, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Company Check'
                }
              }
              break;
            case 'PERSONAL_CHECK':
              return {
                ...value.personalCheckAccount, ...{
                  paymentMethodTypeId: value.paymentMethod.paymentMethodTypeId,
                  type: 'Personal Check'
                }
              };
          }
        });
        this.getBillingAccounts();
        this.spinner.hide();
      }
    });

}

removePaymentMethod(type: string, paymentMethodId: string): void {
  if (type === 'BILLING') {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._CRMAccountService.removeBillingAccount(this.taxAuthPartyId, paymentMethodId)
          .then(data => {
            if (data.success) {
              this.getPaymentMethods();
            }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  } else {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._CRMAccountService.removeGiftCard(paymentMethodId)
          .then(data => {
            if (data.success) {
              this.getPaymentMethods();
            }
          });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })


  }
}

paymentMethodNavigation(type: string, paymentMethodId: string): void {
  switch (type) {
    case 'GIFT_CARD':
      this.router.navigate(['/crm/accounts/create-gift-card'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId } });
      break;
    case 'EFT_ACCOUNT':
      this.router.navigate(['/crm/accounts/create-eft-account'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, isEftAccount: true } });
      break;
    case 'CERTIFIED_CHECK':
      this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, paymentMethodTypeId: 'CERTIFIED_CHECK' } });
      break;
    case 'CREDIT_CARD':
      this.router.navigate(['/crm/accounts/create-credit-card'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId } });
      break;
    case 'COMPANY_CHECK':
      this.router.navigate(['/crm/accounts/create-check-account'], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, paymentMethodTypeId: 'COMPANY_CHECK' } });
      break;
    case 'BILLING':
      this.router.navigate([`/crm/accounts/create-billing-account`], { queryParams: { partyId: this.taxAuthPartyId, billingAccountId: paymentMethodId } });
      break;
    case 'PERSONAL_CHECK':
      this.router.navigate([`/crm/accounts/create-check-account`], { queryParams: { partyId: this.taxAuthPartyId, paymentMethodId, paymentMethodTypeId: 'PERSONAL_CHECK' } });
      break;
  }
}

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  export(id: any, contentName: any) {
    this.spinner.show();
    this.accountsService.downloadView(id).subscribe(res => {
      const blob = new Blob([res], { type: 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = contentName;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }

  onDelete(contentId, fromDate, partyContent, partyId): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this._detailedContactsService.deleteContent(contentId, moment(fromDate).format('yyyy-MM-DD HH:mm:ss.SSS'), partyContent, partyId).subscribe(res => {
          if (res['data'].responseMessage === 'success') {
          }
          this.getPartyContentsList();
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  createRoleType(){
    this.router.navigate(["financial/party-role-type"],{ queryParams: { party:  this.taxAuthPartyId} });

  }

  createAddRole(){
    this.router.navigate(["financial/party-add-to-role"],{ queryParams: { party:  this.taxAuthPartyId} });

  }
  createIdentificationNumber(){
    this.router.navigate(["financial/party-create-identification-number"],{ queryParams: { party:  this.taxAuthPartyId}});
  }
  updatePartyIdentification(epartyTypeId,epartyValue){
    this.router.navigate(["financial/party-create-identification-number"],{ queryParams: { party:  this.taxAuthPartyId,partyTypeId:epartyTypeId,partyValue:epartyValue}});
  }
  createNewRelationshipType(){
    this.router.navigate(["financial/new-relationship-type"],{ queryParams: { party:  this.taxAuthPartyId}});
  }
  createAddOtherpartyRelationship(){
    this.router.navigate(["financial/add-other-party-relationship"],{ queryParams: { party:  this.taxAuthPartyId}});
  }
  updateAddOtherpartyRelationship(activity){
    this.router.navigate(["financial/add-other-party-relationship"],{ queryParams: { party:  this.taxAuthPartyId,fromDate:activity.fromDate,thruDate:activity.thruDate,comment:activity.data.comments,partyIdFrom:activity.partyIdFrom,isA:activity.isA,partyRelationshipTypeId:activity.data.partyRelationshipTypeId,roleTypeIdFrom:activity.data.roleTypeIdFrom,roleTypeIdTo:activity.data.roleTypeIdTo,securityGroupId:activity.data.securityGroupId,statusId:activity.data.statusId}});
  }
  createEditPartyRates(){
    this.router.navigate(["financial/edit-party-rates"],{ queryParams: { party:  this.taxAuthPartyId}});
  }
  createAddPartySkill(){
    this.router.navigate(["financial/add-party-skill"],{ queryParams: { party:  this.taxAuthPartyId} });
  }
  onUpdateSkill(eskid, erating, eskill, eyear) {
    this.router.navigate(["financial/add-party-skill"], { queryParams: { party:this.taxAuthPartyId, skid: eskid, rate: erating, skill: eskill, year: eyear } })
  }
  createAddPartyContent(){
    this.router.navigate(["financial/add-party-content"], { queryParams: { party:this.taxAuthPartyId} });
  }
  createAddPartySegmentRole(){
    this.router.navigate(["financial/add-party-segment-role"], { queryParams: { party:this.taxAuthPartyId} });
  }
  createAddPartyResumes(){
    this.router.navigate(["financial/add-party-resumes"], { queryParams: { party:this.taxAuthPartyId} });
  }
  createEmployeeAppilcation(){
    this.router.navigate(["partyQuickLink/create-party-employee-application"], { queryParams: { party:this.taxAuthPartyId} });
  }
  onUpdate(id){
    this.router.navigate(["partyQuickLink/create-party-employee-application"], { queryParams: { party:this.taxAuthPartyId,updateId:id} });
  }
  
  updateResume(eresumeId,econtentId,eresumeDate,eresumeText){
    this.router.navigate(["financial/add-party-resumes"], { queryParams: { party:this.taxAuthPartyId,resumeId:eresumeId,contentId:econtentId,resumeDate:eresumeDate,resumeText:eresumeText} });
  }
  updatePassword(loginId){
    this.router.navigate(["partyQuickLink/update-password"], { queryParams: { party:this.taxAuthPartyId,loginid:loginId} });
  }
  securityList(loginId){
    this.router.navigate(["partyQuickLink/party-security-group-list"], { queryParams: { party:this.taxAuthPartyId,loginid:loginId} });
  }
  
  createEditPersonalInformation(){
    this.router.navigate(["financial/edit-personal-information"]);
  }
  createAddRelatedAccount(){
    this.router.navigate(["financial/add-related-account"],{queryParams:{party:this.taxAuthPartyId}});
  }

  createAddPartyClassification(){
    this.router.navigate(["partyQuickLink/create-party-classification"],{queryParams:{party:this.taxAuthPartyId}});
  }
  
  createProductStore(){
    this.router.navigate(["partyQuickLink/create-product-store"],{queryParams:{party:this.taxAuthPartyId}});
  }
  onUpdateProductStore(product){
    this.router.navigate(["partyQuickLink/create-product-store"],{queryParams:{party:this.taxAuthPartyId,fromDate:product.fromDate,thruDate:product.thruDate,roleTypeId:product.data.roleTypeId,sequenceNumber:product.data.sequenceNum,productStoreId:product.data.productStoreId}});
  }
  
  createPartyContact(){
    this.router.navigate(["partyQuickLink/create-party-contact"],{queryParams:{party:this.taxAuthPartyId}});
  }
  updateContactList(activity){
    this.router.navigate(["partyQuickLink/create-party-contact"],{queryParams:{party:this.taxAuthPartyId,contactId:activity.data.contactListId,preferredContactMechId:activity.data.preferredContactMechId,statusId:activity.data.statusId,fromDate:activity.fromDate,thruDate:activity.thruDate}});
  }
  updatePartyClassification(epartyClassId,fromdate,thrudate){
    this.router.navigate(["partyQuickLink/create-party-classification"],{queryParams:{party:this.taxAuthPartyId,partyClassId:epartyClassId,fromDate:fromdate,thruDate:thrudate}});
  }
  
  createAddNewContactInformation(){
    this.router.navigate(["financial/add-new-contact-information"],{queryParams:{party:this.taxAuthPartyId}});
  }
  updateData(partyId: string, type: string) {
    switch (type) {
      case 'POSTAL_ADDRESS':
        this.router.navigate(['/hr/employees/update-address'], { queryParams: { employeeDetailId: this.partyId, contactMechId: partyId } })
        break;
      case 'EMAIL_ADDRESS':
        this.router.navigate(['/hr/employees/update-email'], { queryParams: { personId: this.partyId, contactMechId: partyId } })
        break;
      case 'TELECOM_NUMBER':
        this.router.navigate(['/hr/employees/contact'], { queryParams: { partyId: this.partyId, contactMechId: partyId } })
    }


  }
  createUserLogin(){
    this.router.navigate(["financial/create-user-login"],{ queryParams: { party: this.taxAuthPartyId } });
  }
  closeAttrModal(): void{
    this.showAttrModal = false;
  }
  createPartyAttribute(){
    //this.router.navigate(["financial/create-party-attribute"], { queryParams: { party: this.taxAuthPartyId } });
    this.router.navigate([], { queryParams: { party: this.taxAuthPartyId } });
    this.showAttrModal = true;
  }
  
 
  createNewPartyNote(){
    this.router.navigate(["financial/new-party-note"], { queryParams: { party: this.taxAuthPartyId } });
  }
  onUpdateNotes(enoteId, enoteName, enote) {
    this.router.navigate(['financial/new-party-note'], { queryParams: { noteId: enoteId, party: this.taxAuthPartyId, noteName: enoteName, note: enote } })
  }
  onDeleteNotes(noteId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteNote(noteId).subscribe(res => {
          this.toastr.success("Delete Successfully");
          this.getAccountNote();
        }, (err) => {
          this.toastr.error(err.message);
        });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })

  }
  createAttachContent(){
    this.router.navigate(["financial/attach-content"],{ queryParams: { party: this.taxAuthPartyId } });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}