import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from '../../accounts/accounts.service';
import { MyContactsService } from '../../contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from '../../events/create-events/create-event-service';
import { Location } from '@angular/common';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-cases-detail',
  templateUrl: './cases-detail.component.html',
  styleUrls: ['./cases-detail.component.css']
})
export class CasesDetailComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton13') closebutton13;
  @ViewChild('closebutton14') closebutton14;
  @ViewChild('closebutton15') closebutton15;
  @ViewChild('closebutton16') closebutton16;
  @ViewChild('closebutton20') closebutton20;

  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  addBookmark: any;
  fileUpload: any;
  addNote: any;
  editCases: any;
  custRequestId: any;
  PartyId: any;
  PartyIdArray: any[] = [];
  show=false;
  pendingActivityTaskCrm: any;
  pendingActivityEventCrm: any;
  casesById: any;
  casesByIdList: any;
  Reason: any;
  ReasonArray:any[] = [];
  TypeCrm: any;
  TypeCrmArray: any[] = [];
  PriorityArray: any[] = [];
  Priority: any;
  StatusCrm: any;
  StatusCrmArray: any[]=[];
  noteCRMBId: any;
  showNote: boolean;
  productNote: any;
  bookMarksUrlId: any;
  bookmarksUpload: any;
  ClassificationBookmarkArray: any[]=[];
  ClassificationBookmark: any;
  fileData: File;
  fileURL: any;
  showBookmark: boolean;
  productBook: any;
  showUpload: boolean;
  BookUpload: any;
  eventForm: any;
  taskForm: any;
  activity: any;
  BookMark: any;
  pendingActivity: any;
  SalesOpportunityArray: any[]=[];
  SalesOpportunity: any;
  CasesArray: any[]=[];
  Cases: any;
  
  AvailabilityArray: { label: string; value: string; }[];
  yesNoArray: { label: string; value: string; }[];
  securityArray: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[]=[];
  PurposeArray: { label: string; value: string; }[];
  OutboundArray: { label: string; value: string; }[];
  logTaskForm: any;
  logEmailForm: any;
  amPM: { label: string; value: string; }[];
  hours: { label: string; value: string; }[];
  minute: { label: string; value: string; }[];
  showBookMark: boolean;
  productBookMark: any;
  productUpload: any;
  LogTaskEmailCasesId: any;
  LogTaskCallCasesId: any;
  teamMemberForm: any;
  createNotesForm: any;
  createCasesForm: any;
  createAccountsForm: any;
  allCatalogRequestData: any;
  createCatalogForm: any;
  actualfromdate: string;
  productCatalog: any;
  createOrderForm: any;
  allOrderByData: any;
  shipBeforeDate: string;
  productOrder: any;
  createDocumentFilesForm: any;
  allBookMarkUrlByData: any;
  productDocumentFiles: any;
  allOpportunityData: any;
  productOpp: any;
  allAccountData: any;
  productAcc: any;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly _ToastrService: ToastrService,
    readonly toster: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.hours = [
      {
        label: '1',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9',
        value: '9'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },

    ];
    this.minute = [
      {
        label: '00',
        value: '00'
      },
      {
        label: '01',
        value: '01'
      },
      {
        label: '02',
        value: '02'
      },
      {
        label: '03',
        value: '03'
      },
      {
        label: '04',
        value: '04'
      },
      {
        label: '05',
        value: '05'
      },
      {
        label: '06',
        value: '06'
      },
      {
        label: '07',
        value: '07'
      },
      {
        label: '08',
        value: '08'
      },
      {
        label: '09',
        value: '09'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },
      {
        label: '13',
        value: '13'
      },
      {
        label: '14',
        value: '14'
      },
      {
        label: '15',
        value: '15'
      },
      {
        label: '16',
        value: '16'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '19',
        value: '19'
      },
      {
        label: '20',
        value: '20'
      },
      {
        label: '21',
        value: '21'
      },
      {
        label: '22',
        value: '22'
      },
      {
        label: '23',
        value: '23'
      },
      {
        label: '24',
        value: '24'
      },
      {
        label: '25',
        value: '25'
      },
      {
        label: '26',
        value: '26'
      },
      {
        label: '27',
        value: '27'
      },
      {
        label: '28',
        value: '28'
      },
      {
        label: '29',
        value: '29'
      },
      {
        label: '30',
        value: '30'
      },
      {
        label: '31',
        value: '31'
      },
      {
        label: '32',
        value: '32'
      },
      {
        label: '33',
        value: '33'
      }, {
        label: '34',
        value: '34'
      },
      {
        label: '35',
        value: '35'
      }, {
        label: '36',
        value: '36'
      },
      {
        label: '37',
        value: '37'
      }, {
        label: '38',
        value: '38'
      },
      {
        label: '39',
        value: '39'
      },
      {
        label: '40',
        value: '40'
      }, {
        label: '41',
        value: '41'
      },
      {
        label: '42',
        value: '42'
      },
      {
        label: '43',
        value: '43'
      }, {
        label: '44',
        value: '44'
      },
      {
        label: '45',
        value: '45'
      },
      {
        label: '46',
        value: '46'
      }, {
        label: '47',
        value: '47'
      },
      {
        label: '48',
        value: '48'
      },
      {
        label: '49',
        value: '49'
      }, {
        label: '50',
        value: '50'
      },
      {
        label: '51',
        value: '51'
      },
      {
        label: '52',
        value: '52'
      },
      {
        label: '53',
        value: '53'
      },{
        label: '54',
        value: '54'
      },{
        label: '55',
        value: '55'
      },{
        label: '56',
        value: '56'
      },{
        label: '57',
        value: '57'
      },
      {
        label: '58',
        value: '58'
      },
      {
        label: '59',
        value: '59'
      }


    ];
    this.amPM = [
      {
        label: 'AM',
        value: 'AM'
      },
      {
        label: 'PM',
        value: 'PM'
      },
    ];
    this.createCasesForm=this._FormBuilder.group({
      opportunityName:"",
      stage:"",
      opportunityAmount:"",
      estimatedCloseDate:"",
    });
    this.createAccountsForm=this._FormBuilder.group({
      accountName:"",
      city:"",
      email:"",
      primaryPhone:"",
    })
    this.editCases = this._FormBuilder.group({
      status: [''],
      priority: [''],
      type: [''],
      Reason: [''],
      subject: [''],
      description: [''],
      changeNote: [''],
    });
    this.createCatalogForm =this._FormBuilder.group({
      activity: "",
      address: "",
      date: "",
      fullFilled: "",
      takenBy: ""
    });
    this.createOrderForm=this._FormBuilder.group({
      amount: "",
      customer: "",
      orderDate: "",
      orderName: "",
      po: "",
      shipBeforeDate: "",
      status: ""
    });
    this.createDocumentFilesForm=this._FormBuilder.group({
      classification: "",
      createdDate: "",
      description: "",
      name: "",
    });
    this.addBookmark = this._FormBuilder.group({
      classification: [''],
      url: [''],
      description: [''],
    });
    this.fileUpload = this._FormBuilder.group({
      classification: [''],
      file: [''],
      description: [''],
    });
    this.addNote = this._FormBuilder.group({
      note: ['']
    });
    this.taskForm = this._FormBuilder.group({
      Subject: [''] ,
      Security: [''] ,
      RelatedParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Purpose: [''] ,
      EndDateandTime: [''] ,
      Duration: [''] ,
      StartDateandTime: [''] ,
      Description: [''] ,
      Availability: [''] ,
      IgnoreSchedulingConflicts: [''] ,
      CompletionDateandTime: [''] ,
      CompletionDateHour: [''] ,
      CompletionAmPm: [''] ,
      CompletionDateMinutes: [''] ,
      StartDateAmPm: [''] ,
      StartDateandHour: [''] ,
   
      StartDateMinutes: [''] ,
    });
    this.eventForm = this._FormBuilder.group({
      Name: [''] ,
      Security: [''] ,
      RelatedParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      ScheduledStart: [''] ,
      EndDateandTime: [''] ,
      Duration: [''] ,
      Location: [''] ,
      Description: [''] ,
      Availability: [''] ,
      IgnoreSchedulingConflicts: [''] ,
      CompletionDateandTime: [''] ,
      CompletionDateHour: [''] ,
      CompletionAmPm: [''] ,
      CompletionDateMinutes: [''] ,
      StartDateAmPm: [''] ,
      StartDateandHour: [''] ,
      StartDateandTime: [''] ,
      StartDateMinutes: [''] ,
   
      
    });
    this.logEmailForm = this._FormBuilder.group({
      Type: [''] ,
      Outbound: [''] ,
      InternalParty: [''] ,
      ExternalParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Subject: [''] ,
      Message: [''] ,
      StartDateandTime: [''] ,
      Duration: [''] ,
      hourFrom: [''] ,
      minuteFrom: [''] ,
      amPmFrom: [''] 
    });
    this.logTaskForm = this._FormBuilder.group({
      Type: [''] ,
      Outbound: [''] ,
      InternalParty: [''] ,
      ExternalParty: [''] ,
      Opportunity: [''] ,
      Case: [''] ,
      Subject: [''] ,
      Message: [''] ,
      StartDateandTime: [''] ,
      hourFrom: [''] ,
      Duration: [''] ,
      minuteFrom: [''] ,
      amPmFrom: [''] ,
    });
    // Added by RA start code

    this.createNotesForm=this._FormBuilder.group({
      noteInfo:"",
      createdBy:"",
      noteDateTime:"",
    })

    this.teamMemberForm=this._FormBuilder.group({
      newTeamMember:"",
      role:"",
    })

    // end

    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];
    this.OutboundArray = [
      {
        label: 'Outbound',
        value: 'Y'
      },
      {
        label: 'Inbound',
        value: 'N'
      },

    ];
    this.PurposeArray = [
      {
        label: 'Email',
        value: 'WEPT_TASK_EMAIL'
      },
      {
        label: 'Phone Call',
        value: 'WEPT_TASK_PHONE_CALL'
      },

    ];
    this.AvailabilityArray =[
    {
      label: 'Available',
      value: 'WEPA_AV_AVAILABLE'
    },
    {
      label: 'Busy',
      value: 'WEPA_AV_BUSY'
    },
    {
      label: 'Away',
      value: 'WEPA_AV_AWAY'
    },
  ];
  this.securityArray =[
    {
      label: 'General, public access',
      value: 'WES_PUBLIC'
    },
    {
      label: 'Very restricted, confidential access',
      value: 'WES_CONFIDENTIAL'
    },
  
  ];
   
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.custRequestId = params.custRequestId;
    })
    this.getPartyIdList();
    this.getPendingActivityTaskCrm();
    this.getPendingActivityEventCrm();
    this.getCasesById();
    this.getPartyIdList();
    this.getCasesByIdTable();
    this.getPriority();
    this.getTypeCrm();
    this.getReason();
    this.getStatusCrm();
    this.getNoteCRMBId();
    this.getBookmarksUpload();
    this.getBookMarksUrlId();
    this.getActivity();
    this.getClassificationBookmark();
    this.getBookMark();
    this.getPendingActivity();
    this.getPartyId();
    this.getSalesOpportunity();
    this.getCases();
    this.getLogTaskCallCasesId();
    this.getLogTaskEmailCasesId();
    this.getCatalogRequestByPartyId();
    this.getBookMarkUrlByPartyId();
    this.getOrderByPartyId();
    this.getAccountByAccountId();
    this.getOpportunity();
  }
  resetFrom(){
    this.show=false;
    this.createAccountsForm.reset();
    this.createCasesForm.reset();
    this.createCatalogForm.reset();
    this.createOrderForm.reset();
    this.createDocumentFilesForm.reset();
    this.createCasesForm.reset();
    this.createAccountsForm.reset();
  }
  deleteAccount(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "custRequestId": product.custRequestId,
          "accountId":product.accountId,
        }
        this.accountsService.deleteCasesAccount(formData).subscribe(res => {
        this.getAccountByAccountId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateAccounts(){
    this.spinner.show();
    const requestData = {
      "accountId": this.productAcc.accountId,
      "custRequestId": this.custRequestId,
      "accountName": this.createAccountsForm.value.accountName,
      "city": this.createAccountsForm.value.city,
      "primaryEmail": this.createAccountsForm.value.email,
      "primaryPhone": this.createAccountsForm.value.primaryPhone,

    }
    this.accountsService.updateCasesAccount(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton13.nativeElement.click();
        this.createAccountsForm.reset();
        this.getAccountByAccountId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateAcc(product) {
    this.spinner.show();
    this.show = true ;
    this.productAcc = product ;
      setTimeout(() => {
        const formValue = this.createAccountsForm;
        formValue.patchValue({
          accountName: product.accountName ,
          city: product.city ,
          email: product.email ,
          primaryPhone: product.primaryPhone ,
        })
      }, 2000);
      this.spinner.hide();
  }
  createAccounts(){
    this.spinner.show();
    const requestData = {
      "accountId": "",
      "custRequestId": this.custRequestId,
      "accountName": this.createAccountsForm.value.accountName,
      "city": this.createAccountsForm.value.city,
      "primaryEmail": this.createAccountsForm.value.email,
      "primaryPhone": this.createAccountsForm.value.primaryPhone,
    }
    this.accountsService.createCasesAccount(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton13.nativeElement.click();
        this.createAccountsForm.reset();
        this.getAccountByAccountId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getAccountByAccountId() {
    this.spinner.show();
    this.accountsService.getCasesAccountByCustReqId(this.custRequestId).subscribe(res => {
      this.allAccountData = res.data[0].getCasesAccountCustReqId;
      this.spinner.hide();
    })
  }


  deleteOpportunity(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "opportunityId": product.opportunityId,
          "custRequestId": product.custRequestId
        }
        this.accountsService.deleteOpportunityCases(formData).subscribe(res => {
        this.getOpportunity();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateOpportunity(){
    this.spinner.show();
    const fromActualdate=this.createCasesForm.get('estimatedCloseDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "closeDate": this.actualfromdate,
      "opportunityAmount": this.createCasesForm.value.opportunityAmount,
      "custRequestId":this.productOpp.custRequestId,
      "opportunityName": this.createCasesForm.value.opportunityName,
      "opportunityId": this.productOpp.opportunityId,
      "stage": this.createCasesForm.value.stage
    }
    this.accountsService.updateOpportunityCases(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.createCasesForm.reset();
        this.getOpportunity();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateOpportunityPatch(product) {
    this.spinner.show();
    this.show = true ;
    this.productOpp = product ;
      setTimeout(() => {
        const formValue = this.createCasesForm;
        formValue.patchValue({
          opportunityName:product.opportunityName,
          stage:product.stage,
          opportunityAmount:product.opportunityAmount,
          estimatedCloseDate:this.datePipe.transform(product.closeDate,"yyyy-MM-dd"),
        })
      }, 2000);
      this.spinner.hide();
  }
  createOpportunities(){
    this.spinner.show();
    const fromActualdate=this.createCasesForm.get('estimatedCloseDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "closeDate": this.actualfromdate,
      "opportunityAmount": this.createCasesForm.value.opportunityAmount,
      "custRequestId":this.custRequestId,
      "opportunityName": this.createCasesForm.value.opportunityName,
      "opportunityId": "",
      "stage": this.createCasesForm.value.stage
    }
    this.accountsService.createOpportunityCases(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.createCasesForm.reset();
        this.getOpportunity();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getOpportunity() {
    this.spinner.show();
    this.accountsService.getOpportunityCasesByCustReqId(this.custRequestId).subscribe(res => {
      this.allOpportunityData = res.data[0].getOpportunityCasesByCustReqId;
      this.spinner.hide();
    })
  }
  deleteBookMarkUrl(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "bookMarkURLId": product.bookMarkURLId,
          "custRequestId": product.custRequestId
        }
        this.accountsService.deleteCasesBookMarkUrl(formData).subscribe(res => {
        this.getBookMarkUrlByPartyId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateBookMarkUrl(){
    this.spinner.show();
    const fromActualdate=this.createDocumentFilesForm.get('createdDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    
    const requestData = {
      "bookMarkURLId": "",
      "classification":  this.createDocumentFilesForm.value.classification,
      "createdDate": this.actualfromdate,
      "description": this.createDocumentFilesForm.value.description,
      "name": this.createDocumentFilesForm.value.name,
      "custRequestId": this.custRequestId
    }
    this.accountsService.updateCasesBookMarkUrl(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton20.nativeElement.click();
        this.createDocumentFilesForm.reset();
        this.getBookMarkUrlByPartyId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateBookMarkUrlPatch(product) {
    this.spinner.show();
    this.show = true ;
    this.productDocumentFiles = product ;
      setTimeout(() => {
        const formValue = this.createDocumentFilesForm;
        formValue.patchValue({
            classification: product.classification,
            createdDate: this.datePipe.transform(product.createdDate,"yyyy-MM-dd"),
            description: product.description,
            name: product.name,
        
        })
      }, 2000);
      this.spinner.hide();
  }
  createBookMarkUrl(){
    this.spinner.show();
    const fromActualdate=this.createDocumentFilesForm.get('createdDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    
    const requestData = {
      "bookMarkURLId": "",
      "classification":  this.createDocumentFilesForm.value.classification,
      "createdDate": this.actualfromdate,
      "description": this.createDocumentFilesForm.value.description,
      "name": this.createDocumentFilesForm.value.name,
      "custRequestId": this.custRequestId
    }
    this.accountsService.createCasesBookMarkUrl(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton20.nativeElement.click();
        this.createDocumentFilesForm.reset();
        this.getBookMarkUrlByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  getBookMarkUrlByPartyId() {
    this.spinner.show();
    this.accountsService.getCasesBookMarkUrlByCustRequestId(this.custRequestId).subscribe(res => {
      this.allBookMarkUrlByData = res.data[0].getCasesBookMarkUrlByCustRequestId;
      this.spinner.hide();
    });
  }
  deleteOrder(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "orderId": product.orderId,
          "custRequestId": product.custRequestId
        }
        this.accountsService.deleteCasesOrder(formData).subscribe(res => {
        this.getOrderByPartyId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateOrder(){
    this.spinner.show();
    const fromActualdate=this.createOrderForm.get('orderDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const shipBeforeDate=this.createOrderForm.get('shipBeforeDate').value;
    this.shipBeforeDate=this.datePipe.transform(shipBeforeDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "amount": this.createOrderForm.value.amount,
      "customer": this.createOrderForm.value.customer,
      "orderDate": this.actualfromdate,
      "orderId": this.productOrder.orderId,
      "orderName": this.createOrderForm.value.orderName,
      "custRequestId": this.productOrder.custRequestId,
      "po": this.createOrderForm.value.po,
      "shipBeforeDate": this.shipBeforeDate,
      "status": this.createOrderForm.value.status
    }
    this.accountsService.updateCasesOrder(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton15.nativeElement.click();
        this.createOrderForm.reset();
        this.getOrderByPartyId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateOrderPatch(product) {
    this.spinner.show();
    this.show = true ;
    this.productOrder = product ;
      setTimeout(() => {
        const formValue = this.createOrderForm;
        formValue.patchValue({
            amount: product.amount,
            customer: product.customer,
            orderDate: this.datePipe.transform(product.orderDate,"yyyy-MM-dd"),
            orderName: product.orderName,
            po: product.po,
            shipBeforeDate: this.datePipe.transform(product.shipBeforeDate,"yyyy-MM-dd"),
            status: product.status
        })
      }, 2000);
      this.spinner.hide();
  }
  createOrder(){
    this.spinner.show();
    const fromActualdate=this.createOrderForm.get('orderDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const shipBeforeDate=this.createOrderForm.get('shipBeforeDate').value;
    this.shipBeforeDate=this.datePipe.transform(shipBeforeDate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "amount": this.createOrderForm.value.amount,
      "customer": this.createOrderForm.value.customer,
      "orderDate": this.actualfromdate,
      "custRequestId": this.custRequestId,
      "orderName": this.createOrderForm.value.orderName,
      "orderId": "",
      "po": this.createOrderForm.value.po,
      "shipBeforeDate": this.shipBeforeDate,
      "status": this.createOrderForm.value.status
    }
    this.accountsService.createCasesOrder(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton15.nativeElement.click();
        this.createOrderForm.reset();
        this.getOrderByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getOrderByPartyId() {
    this.spinner.show();
    this.accountsService.getCasesOrderByCustRequestId(this.custRequestId).subscribe(res => {
      this.allOrderByData = res.data[0].getCasesOrderByCustRequestId;
      this.spinner.hide();
    })
  }

  deleteCatalogRequest(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "catalogId": product.catalogId,
          "custRequestId": product.custRequestId
         
        }
        this.accountsService.deleteCasesCatalogRequest(formData).subscribe(res => {
        this.getCatalogRequestByPartyId();

        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  UpdateCatalogRequest(){
    this.spinner.show();
    const fromActualdate=this.createCatalogForm.get('date').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "activity": this.createCatalogForm.value.activity,
      "address": this.createCatalogForm.value.address,
      "catalogId": this.productCatalog.catalogId,
      "date": this.actualfromdate,
      "fullFilled": this.createCatalogForm.value.fullFilled,
      "custRequestId": this.productCatalog.custRequestId,
      "takenBy": this.createCatalogForm.value.takenBy
    }
    this.accountsService.updateCasesCatalogRequest(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Updated Successfully");
        this.spinner.hide();
        this.closebutton16.nativeElement.click();
        this.createCatalogForm.reset();
        this.getCatalogRequestByPartyId();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  UpdateCatalogRequestPatch(product) {
    this.spinner.show();
    this.show = true ;
    this.productCatalog = product ;
      setTimeout(() => {
        const formValue = this.createCatalogForm;
        formValue.patchValue({
            activity: product.activity,
            address: product.address,
            date: this.datePipe.transform(product.date,"yyyy-MM-dd"),
            fullFilled: product.fullFilled,
            takenBy: product.takenBy
        })
      }, 2000);
      this.spinner.hide();
  }
  createCatalogRequest(){
    this.spinner.show();
    const fromActualdate=this.createCatalogForm.get('date').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "activity": this.createCatalogForm.value.activity,
      "address": this.createCatalogForm.value.address,
      "catalogId": "",
      "date": this.actualfromdate,
      "fullFilled": this.createCatalogForm.value.fullFilled,
      "custRequestId": this.custRequestId,
      "takenBy": this.createCatalogForm.value.takenBy
    }
    this.accountsService.createCasesCatalogRequest(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(" Created Successfully");
        this.spinner.hide();
        this.closebutton16.nativeElement.click();
        this.createCatalogForm.reset();
        this.getCatalogRequestByPartyId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCatalogRequestByPartyId() {
    this.spinner.show();
    this.accountsService.getCasesCatalogRequestByCustRequestId(this.custRequestId).subscribe(res => {
      this.allCatalogRequestData = res.data[0].getCasesCatalogRequestByCustRequestId;
      this.spinner.hide();
    })
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  updateCloseCase(){
    this.spinner.show();
    const requestData = {
     
  "custRequestId": this.custRequestId,
  "statusId":  this.casesById.statusId,
    }
    this.myContactsService.updateCloseCase(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
     
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getLogTaskEmailCasesId() {
    this.spinner.show();
    this.myContactsService.getLogTaskEmailCasesId(this.custRequestId).subscribe(res => {
      this.LogTaskEmailCasesId = res.data[0].getLogTaskEmailCasesId;
      setTimeout(()=>{
        const formValue = this.logEmailForm;
        formValue.patchValue({
          InternalParty : "admin",
          Case : this.custRequestId,
          StartDateandTime : this.datePipe.transform(new Date(), "yyyy-MM-dd"),
          Duration : "1:00",
        })
      })
      this.spinner.hide();
    })
  }
  getLogTaskCallCasesId() {
    this.spinner.show();
    this.myContactsService.getLogTaskCallCasesId(this.custRequestId).subscribe(res => {
      this.LogTaskCallCasesId = res.data[0].getLogTaskCallCasesId;
      setTimeout(() =>{
        const formValue = this.logTaskForm;
        formValue.patchValue({
          InternalParty : "admin",
          Case : this.custRequestId,
          StartDateandTime : this.datePipe.transform(new Date(), "yyyy-MM-dd"),
          Duration : "1:00",
        })

      },2000

      )
      this.spinner.hide();
    })
  }


  createLogTaskCallCases(){
    this.spinner.show();
   
    const requestData = {
      "actualStartDateAmPm":this.logTaskForm.value.amPmFrom,
  "actualStartDateCompositeType":"Timestamp",
  "actualStartDateDate": this.datePipe.transform(this.logTaskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss"),
  "actualStartDateHour": this.logTaskForm.value.hourFrom,
  "actualStartDateMinutes":  this.logTaskForm.value.minuteFrom,
  "caseId":"",
  "completionStartDateAmPm":"AM",
  "completionStartDateCompositeType":"Timestamp",
  "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
  "completionStartDateHour":  "23",
  "completionStartDateMinutes":  "59",
  "content":this.logTaskForm.value.Message,
  "currentStatusId":"EXST_DEFINED",
  "custRequestId":this.logTaskForm.value.Case,
  "duration":this.logTaskForm.value.Duration,
  "externalPartyId":this.logTaskForm.value.ExternalParty,
  "inboundAndOutBound":this.logTaskForm.value.Outbound,
  "internalPartyId":this.logTaskForm.value.InternalParty,
  "logTaskCasesCallId":"",
  "salesOpportunityId":this.logTaskForm.value.Opportunity,
  "workEffortName":this.logTaskForm.value.Subject,
  "workEffortPurposeTypeId": "WEPT_TASK_PHONE_CALL"
    }

    this.myContactsService.createLogTaskCallCases(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.logTaskForm.reset();
        this.getLogTaskCallCasesId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createLogTaskEmailCases(){
    this.spinner.show();

    const requestData = {
      "actualStartDateAmPm":this.logEmailForm.value.amPmFrom,
      "actualStartDateCompositeType":"Timestamp",
      "actualStartDateDate":this.datePipe.transform(this.logEmailForm.value.StartDateandTime, "yyyy-MM-dd"),
      "actualStartDateHour": this.logEmailForm.value.hourFrom,
      "actualStartDateMinutes": this.logEmailForm.value.minuteFrom,
      "caseId": "",
      "completionStartDateAmPm": "AM",
      "completionStartDateCompositeType": "",
      "completionStartDateDate":this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss') ,
      "completionStartDateHour": "24",
      "completionStartDateMinutes": "60",
      "content": this.logEmailForm.value.Message ,
      "currentStatusId": "EXST_DEFINED",
      "custRequestId": this.logEmailForm.value.Case,
      "duration":this.logEmailForm.value.Duration,
      "externalPartyId": this.logEmailForm.value.ExternalParty,
      "inboundAndOutBound": this.logEmailForm.value.Outbound,
      "internalPartyId": this.logEmailForm.value.InternalParty,
      "logTaskEmailId": "",
      "salesOpportunityId":this.logEmailForm.value.Opportunity,
      "workEffortName":this.logEmailForm.value.Subject,
      "workEffortPurposeTypeId":"WEPT_TASK_EMAIL",
    }

    this.myContactsService.createLogTaskEmailCases(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.logEmailForm.reset();
        this.getLogTaskEmailCasesId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
 
  deleteBookMark(product){
    if(product.data.bookmarksUrlId){
      this.deleteBookMarksUrl(product)
    }
    else
    {
      this.deleteBookmarksUpload(product);
    }
  }
  deleteLogTask(product){
    if(product.data.bookmarksUrlId){
      this.deleteLogTaskEmailCases(product)
    }
    else
    {
      this.deleteLogTaskCallCases(product);
    }
  }
  deleteLogTaskEmailCases(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "custRequestId": this.custRequestId ,
          "logTaskEmailId": product.logTaskEmailId,
         
        }
        this.myContactsService.deleteLogTaskEmailCases(formData).subscribe(res => {
          this.getLogTaskEmailCasesId(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  deleteLogTaskCallCases(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "custRequestId": this.custRequestId ,
          "logTaskCasesCallId": product.logTaskCasesCallId
         
        }
        this.myContactsService.deleteLogTaskCallCases(formData).subscribe(res => {
          this.getLogTaskCallCasesId(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
 
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  CreatePendingActivityEventCrm(){
    this.spinner.show();
    const requestData = {
      "availability": this.eventForm.value.Availability,
      "currentStatusId": "EVENT_SCHEDULED",
      "custRequestId": this.eventForm.value.Case,
      "description": this.eventForm.value.Description,
      "duration": this.eventForm.value.Duration,
      "estimatedCompletionDate": this.datePipe.transform(this.eventForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedCompletionDateAmPm": this.eventForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.eventForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.eventForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.eventForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedCompletionCompositeType": "Timestamp",
      "estimatedStartDateDate":this.datePipe.transform(this.eventForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedStartDateHour": this.eventForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.eventForm.value.StartDateMinutes,
      "location": this.eventForm.value.Location,
      "lookupCase": "",
      "lookupOpportunity": this.eventForm.value.Opportunity,
      "pendingActivitiesId":"",
      "relatedPartyId": this.eventForm.value.RelatedParty,
      "scopeEnumId": this.eventForm.value.Security,
      "type": "",
      "workEffortName": this.eventForm.value.Name,
      "workEffortPurposeTypeId": "",
      "workEffortTypeId": "EVENT",
    }
    this.myContactsService.CreatePendingActivityEventCrm(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivitieCrm created successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.eventForm.reset();
        this.getPendingActivityEventCrm();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createPendingActivityTask(){
    this.spinner.show();
    const requestData = {
      "availability":this.taskForm.value.Availability,
      "currentStatusId": "TASK_SCHEDULED"  ,
      "custRequestId": this.taskForm.value.Case,
      "description": this.taskForm.value.Description,
      "duration": this.taskForm.value.Duration,
      "estimatedCompletionCompositeType":"Timestamp",
      "estimatedCompletionDate": this.datePipe.transform(this.taskForm.value.CompletionDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedCompletionDateAmPm": this.taskForm.value.CompletionAmPm,
      "estimatedCompletionDateHour": this.taskForm.value.CompletionDateHour,
      "estimatedCompletionDateMinutes": this.taskForm.value.CompletionDateMinutes,
      "estimatedStartDateAmPm": this.taskForm.value.StartDateAmPm,
      "estimatedStartDateCompositeType": "Timestamp",
      "estimatedStartDateDate":this.datePipe.transform(this.taskForm.value.StartDateandTime, "yyyy-MM-dd hh:mm:ss") ,
      "estimatedStartDateHour": this.taskForm.value.StartDateandHour,
      "estimatedStartDateMinutes": this.taskForm.value.StartDateMinutes,
      "ignoreSchedulingConflicts": this.taskForm.value.IgnoreSchedulingConflicts,
      "lookupCase": "",
      "lookupOpportunity": this.taskForm.value.Opportunity,
      "pendingActivityTaskId": "",
      "relatedPartyId": this.taskForm.value.RelatedParty,
      "scopeEnumId": this.taskForm.value.Security,
      "type": "",
      "workEffortName": this.taskForm.value.Subject,
      "workEffortPurposeTypeId": this.taskForm.value.Purpose,
      "workEffortTypeId": "TASK"
    }

    this.myContactsService.createPendingActivityTask(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("PendingActivityTask created successfully");
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.taskForm.reset();
        this.getPendingActivityTaskCrm();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getSalesOpportunity(){
    this.spinner.show();
    this.myContactsService.getSalesOpportunity().subscribe(res=>{
      this.SalesOpportunity = res.data[0].getSalesOpportunity;
      this.spinner.hide();
      for (const value of this.SalesOpportunity) {
        this.SalesOpportunityArray.push({
          label: value.opportunityName,
          value: value.salesOpportunityId
        })
      }
    })
  }
  getCases(){
    this.spinner.show();
    this.myContactsService.getCases().subscribe(res=>{
      this.Cases = res.data[0].getCasesById;
      this.spinner.hide();
      for (const value of this.Cases) {
        this.CasesArray.push({
          label: value.custRequestName,
          value: value.custRequestId
        })
      }
    })
  }
  getActivity() {
    this.spinner.show();
    this.myContactsService.getActivity(this.custRequestId).subscribe(res => {
      this.activity = res.data;
      this.spinner.hide();
    })
  }
  getBookMark() {
    this.spinner.show();
    this.myContactsService.getBookMark(this.custRequestId).subscribe(res => {
      this.BookMark = res.data[0];
      this.spinner.hide();
    })
  }
  getPendingActivity() {
    this.spinner.show();
    this.myContactsService.getPendingActivity(this.custRequestId).subscribe(res => {
      this.pendingActivity = res.data;
      this.spinner.hide();
    })
  }
  


  createBookmarkUpload(){
  this.onSubmitFile();

   setTimeout(() => {
      this.createBookmarksUpload();
    }, 2000);
   
  }

  cancelSubmit(){
    this._Router.navigate(["/crm/Cases"])
  }
  // updateBookmarkUpload(){
  //   this.onSubmitFile();

  //   setTimeout(() => {
  //     this.updateBookmarksUpload();
  //   }, 2000);
   
  // }
   onSubmitFile() {
    this.spinner.show();
     const formData = new FormData();
     formData.append("file", this.fileData);

     this.accountsService.attachFile(formData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
       this.toastr.success("");
         this.fileURL = res.data.URL;
       }
     }, (err) => {
       this.toastr.error(err.message);
       this.spinner.hide();
     })

   }

  getClassificationBookmark(){
    this.spinner.show();
    this.myContactsService.getClassificationBookmark().subscribe(res=>{
      this.ClassificationBookmark = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ClassificationBookmark) {
        this.ClassificationBookmarkArray.push({
          label: value.description,
          value: value.classificationEnumId
        })
      }
    })
  }
  updateBookMark(product) {
    this.spinner.show();
    this.showBookMark = true ;
    this.productBookMark = product ;
   
      setTimeout(() => {
        const formValue = this.addBookmark;
        formValue.patchValue({
          classification: product.classification,
      url: product.urlAddress ,
      description: product.description,
        })
      }, 2000);
      this.spinner.hide();
  }
  resetUpload(){
    this.showUpload = false ;
    this.fileUpload.reset();

  }
  resetBookmark(){
    this.showBookMark = false ;
    this.addBookmark.reset();

  }
  updateBookUpload(product) {
    this.spinner.show();
    this.showUpload = true ;
    this.BookUpload = product ;
   
      setTimeout(() => {
        const formValue = this.fileUpload;
        formValue.patchValue({
          classification: product.classification,
          file: product.url,
          description: product.description,
        })
      }, 2000);
      this.spinner.hide();
  }
  
  fileProgressCom(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }

  updateBookmarksUpload(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);
    
    this.myContactsService.updateBookmarksUpload(formData,this.BookUpload.bookmarksUploadId,
      this.fileUpload.value.classification,
    this.custRequestId,this.fileUpload.value.description).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.fileUpload.reset();
        this.getBookmarksUpload();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }  
  createBookmarksUpload(){
    debugger
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);
    
    this.myContactsService.createBookmarksUpload(this.fileUpload.value.classification,
    this.custRequestId,this.fileUpload.value.description,formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.fileUpload.reset();
        this.getBookmarksUpload();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }  
 

  updateBook(product) {
    this.spinner.show();
    this.showBookmark = true ;
    this.productBook = product ;
   
      setTimeout(() => {
        const formValue = this.addBookmark;
        formValue.patchValue({
          classification: product.classification,
          url: product.urlAddress,
          description: product.description
        })
      }, 2000);
      this.spinner.hide();
  }
  deleteBookmarksUpload(bookmarksUploadId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       
        this.myContactsService.deleteBookmarksUpload(bookmarksUploadId).subscribe(res => {
          this.getBookmarksUpload(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  
deleteBookMarksUrl(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "custRequestId": this.custRequestId ,
          "bookmarksUrlId": product.bookmarksUrlId,
         
        }
        this.myContactsService.deleteBookMarksUrl(formData).subscribe(res => {
          this.getBookMarksUrlId(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  UpdateBookMarksUrl(){
    this.spinner.show();
    const requestData = {
      "bookmarksUrlId":this.productBook.bookmarksUrlId,
      "classification": this.addBookmark.value.classification,
      "custRequestId": this.custRequestId,
      "description":this.addBookmark.value.description,
      "urlAddress": this.addBookmark.value.url,

    }
    this.myContactsService.UpdateBookMarksUrl(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.addBookmark.reset();
        this.getBookMarksUrlId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createBookMarksUrl(){
    this.spinner.show();
    const requestData = {
      "bookmarksUrlId":this.addBookmark.value.description,
      "classification": this.addBookmark.value.classification,
      "custRequestId": this.custRequestId,
      "description":this.addBookmark.value.description,
      "urlAddress": this.addBookmark.value.url,
    }
    this.myContactsService.createBookMarksUrl(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("BookmarksUrl Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addBookmark.reset();
        this.getBookMarksUrlId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getBookMarksUrlId() {
    this.spinner.show();
    this.myContactsService.getBookMarksUrlId(this.custRequestId).subscribe(res => {
      this.bookMarksUrlId = res.data[0].getBookMarksUrl;
      this.spinner.hide();
    })
  }
  getBookmarksUpload() {
    this.spinner.show();
    this.myContactsService.getBookmarksUpload(this.custRequestId).subscribe(res => {
      this.bookmarksUpload = res.data[0].getBookmarksUpload;
      this.spinner.hide();
    })
  }
  resetNote(){
    this.showNote = false ;
    this.addNote.reset();
  }
  deletePendingActivityEventCrm(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "custRequestId": this.custRequestId ,
          "pendingActivitiesId": product.pendingActivitiesId
        }
        this.myContactsService.deletePendingActivityEventCrm(formData).subscribe(res => {
          this.getPendingActivityEventCrm(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deletePendingActivityTask(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "custRequestId": this.custRequestId ,
          "pendingActivityTaskId":product.pendingActivityTaskId
        }
        this.myContactsService.deletePendingActivityTask(formData).subscribe(res => {
          this.getPendingActivityTaskCrm(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteNoteCRM(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "custRequestId": this.custRequestId ,
          "noteId": product.noteId
        }
        this.myContactsService.deleteNoteCRM(formData).subscribe(res => {
          this.getNoteCRMBId(); 
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateNote(product) {
    this.spinner.show();
    this.showNote = true ;
    this.productNote = product ;
   
      setTimeout(() => {
        const formValue = this.addNote;
        formValue.patchValue({
          note: product.data.amount,
        })
      }, 2000);
      this.spinner.hide();
  }

  UpdateNoteCRM(){
    this.spinner.show();
    const requestData = {
  "custRequestId": this.custRequestId,
  "noteId":this.productNote.noteId,
  "noteInformation": this.addNote.value.note,
    }
    this.myContactsService.UpdateNoteCRM(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.getNoteCRMBId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createNoteCRM(){
    this.spinner.show();
    const requestData = {

  "custRequestId": this.custRequestId,
  "noteId": "",
  "noteInformation": this.addNote.value.note,
    }
    this.myContactsService.createNoteCRM(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.getNoteCRMBId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getNoteCRMBId() {
    this.spinner.show();
    this.myContactsService.getNoteCRMBId(this.custRequestId).subscribe(res => {
      this.noteCRMBId = res.data[0].getNoteCRMAll;
      this.spinner.hide();
    })
  }
  getStatusCrm(){
    this.spinner.show();
    this.myContactsService.getStatusCrm().subscribe(res=>{
      this.StatusCrm = res.data[0].data;
      this.spinner.hide();

      for (const value of this.StatusCrm) {
        this.StatusCrmArray.push({
          label: value.description,
          value: value.statusValue
        })
      }
    })
  }
  getPendingActivityTaskCrm() {
    this.spinner.show();
    this.myContactsService.getPendingActivityTaskCrm(this.custRequestId).subscribe(res => {
      this.pendingActivityTaskCrm = res.data[0].getPendingActivityTaskCrm;
      setTimeout(() => {
        const formValue = this.taskForm;
        formValue.patchValue({
          Case: this.custRequestId,
          StartDateandTime : this.datePipe.transform(new Date(), 'yyyy-MM-dd') ,
          Duration : "1:00"
        })
  
      }, 2000);
      this.spinner.hide();
      this.spinner.hide();
    })
  }
  getPendingActivityEventCrm() {
    this.spinner.show();
    this.myContactsService.getPendingActivityEventCrm(this.custRequestId).subscribe(res => {
      this.pendingActivityEventCrm = res.data[0].getCasesById;
      setTimeout(() => {
        const formValue = this.eventForm;
        formValue.patchValue({
          Case: this.custRequestId,
          ScheduledStart : this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
          Duration : "1:00"
        })
  
      }, 2000);
      this.spinner.hide();
    })
  }
  getCasesById() {
    this.spinner.show();
    this.myContactsService.getCasesById(this.custRequestId).subscribe(res => {
      this.casesById = res.data[0].getCasesById[0];
      setTimeout(() => {
        const formValue = this.editCases;
        formValue.patchValue({
          status: this.casesById.statusId,
          priority:this.casesById.priority,
          type: this.casesById.custRequestTypeId,
          reason: this.casesById.custRequestCategoryId,
          subject: this.casesById.custRequestName,
          description: this.casesById.description,
          changeNote:this.casesById.note
        })
  
      }, 2000);
      this.spinner.hide();
    })
  }
  getCasesByIdTable() {
    this.spinner.show();
    this.myContactsService.getCasesById(this.custRequestId).subscribe(res => {
      this.casesByIdList= res.data;
      this.spinner.hide();
    })
  }

  updateCase(){
    this.spinner.show();
    const requestData = {
      "custRequestCategoryId": this.editCases.value.Reason,
      "accountPartyId": this.casesById.accountPartyId,
      "contactPartyId":  this.casesById.contactPartyId,
      "custDate": this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss'),
   
      "custRequestId": this.custRequestId,
      "custRequestName": this.editCases.value.subject,
      "custRequestTypeId": this.editCases.value.type,
      "description": this.editCases.value.description,
      "note": this.editCases.value.changeNote,
      "priority": this.editCases.value.priority,
      "statusId": this.editCases.value.status,
      "workEffortId": this.casesById.workEffortId,
    }
    this.myContactsService.updateCase(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getCasesById();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getPriority(){
    this.spinner.show();
    this.myContactsService.getPriority().subscribe(res=>{
      this.Priority = res.data[0].data;
      this.spinner.hide();
      for (const value of this.Priority) {
        this.PriorityArray.push({
          label: value.description,
          value: value.priority
        })
      }
    })
  }
  getReason(){
    this.spinner.show();
    this.myContactsService.getReason().subscribe(res=>{
      this.Reason = res.data[0].data;
      this.spinner.hide();

      for (const value of this.Reason) {
        this.ReasonArray.push({
          label: value.description,
          value: value.statusValue
        })
      }
    })
  }
  getTypeCrm() {
    this.spinner.show();
    this.myContactsService.getTypeCrm().subscribe(res => {
      this.TypeCrm = res.data[0].data;
      this.spinner.hide();
      for (const value of this.TypeCrm) {
        this.TypeCrmArray.push({
          label: value.description,
          value: value.custRequestTypeId
        })
      }
    })
  }
  getPartyIdList() {
    this.spinner.show();
    this.myContactsService.getPartyIdList().subscribe(res => {
      this.PartyId = res.data;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.PartyIdArray.push({
          label: value.description,
          value: value.partyId
        })
      }
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
