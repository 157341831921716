import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { Location } from '@angular/common';


@Component({
  selector: 'app-contact-list-marketing',
  templateUrl: './contact-list-marketing.component.html',
  styleUrls: ['./contact-list-marketing.component.css']
})
export class ContactListMarketingComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  updateProfile: FormGroup;
  createUserLogIn: FormGroup;
  addRelatedAccount: FormGroup;
  createNewContactInformation: FormGroup;
  partyAttributes: FormGroup;
  addCheckAccount: FormGroup;
  createBillingAccount: FormGroup;
  addNewCreditCard: FormGroup;
  createGiftCard: FormGroup;
  createEFTAcc: FormGroup;
  CreateNewContactListForm: FormGroup;
  ContactListTypeId: any;
  ContactListTypeIdArray: any[]=[];
  ContactMechanismTypeID: any;
  ContactMechanismTypeIDArray: any[]=[];
  MarketingCampaign: any;
  MarketingCampaignArray: any[]=[];
  VerifyEmailWebsiteId: any;
  VerifyEmailWebsiteIdArray: any[]=[];
  yesNOArray: { label: string; value: string; }[];
  findContactList: any;
  advanceSearch: { ContactListID: string; ContactListName: string; ContactListTypeID: string; ContactMechanismTypeID: string; MarketingCampaignID: string; };
  activeCategoriess=5;
  

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly myContactsService: MyContactsService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.yesNOArray = [

      {
        label: 'Y',
        value: 'Y'
      },
      {
        label: 'N',
        value: 'N'
      },
    ];
    this.CreateNewContactListForm = this._FormBuilder.group({
      ContactListName: [''],
      ContactListTypeID: [''],
      IsPublic: [''],
      IsSingleUse: [''],
      ContactMechanismTypeID: [''],
      MarketingCampaignID: [''],
      OwnerPartyID: [''],
      VerifyEmailFrom: [''],
      VerifyEmailScreen: [''],
      VerifyEmailSubject: [''],
      VerifyEmailWebSiteID: [''],
      OptOutScreen: [''],
      
    });
    this.advanceSearch = {
      ContactListID: "",
      ContactListName: "",
      ContactListTypeID: "",
      ContactMechanismTypeID: "",
      MarketingCampaignID: "",
    };
   }

  ngOnInit(): void {
    this.getContactListTypeId();
    this.getContactMechanismTypeID();
    this.getMarketingCampaign();
    this.getVerifyEmailWebsiteId();
    this.getfindContactList();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  
  creatingContactList(): void {
    this.spinner.show();
    const requestData ={
      "contactListName": this.CreateNewContactListForm.value.ContactListName,
      "contactListTypeId":this.CreateNewContactListForm.value.ContactListTypeID,
      "contactMechTypeId": this.CreateNewContactListForm.value.ContactMechanismTypeID,
      "isPublic": this.CreateNewContactListForm.value.IsPublic,
      "marketingCampaignId":this.CreateNewContactListForm.value.MarketingCampaignID,
      "optOutScreen":this.CreateNewContactListForm.value.OptOutScreen,
      "ownerPartyId": this.CreateNewContactListForm.value.OwnerPartyID,
      "singleUse": this.CreateNewContactListForm.value.IsSingleUse,
      "verifyEmailFrom": this.CreateNewContactListForm.value.VerifyEmailFrom,
      "verifyEmailScreen": this.CreateNewContactListForm.value.VerifyEmailScreen,
      "verifyEmailSubject":this.CreateNewContactListForm.value.VerifyEmailSubject,
      "verifyEmailWebSiteId": this.CreateNewContactListForm.value.VerifyEmailWebSiteID,
   
      }
     
    this.accountsService.creatingContactList(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.CreateNewContactListForm.reset();
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getfindContactList(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  FindContactList() {
 
    this.spinner.show();

    if(this.advanceSearch.ContactListID||this.advanceSearch.ContactListName||
      this.advanceSearch.ContactListTypeID ||this.advanceSearch.ContactMechanismTypeID
       ||this.advanceSearch.MarketingCampaignID ){
    const req = {
      "contactListId": this.advanceSearch.ContactListID ? this.advanceSearch.ContactListID : '',
      "contactListName": this.advanceSearch.ContactListName ? this.advanceSearch.ContactListName : '',
      "contactListTypeId": this.advanceSearch.ContactListTypeID ? this.advanceSearch.ContactListTypeID : '',
      "contactMechTypeId": this.advanceSearch.ContactMechanismTypeID ? this.advanceSearch.ContactMechanismTypeID : '',
      "marketingCampaignId": this.advanceSearch.MarketingCampaignID ? this.advanceSearch.MarketingCampaignID : '',
    }
    this.myContactsService.getfindContactList(req).subscribe((res: any) => {
      this.findContactList = res.data[0].node1;
      this.spinner.hide();
    })}
    else{
      this.getfindContactList();
    }
    this.spinner.hide();
  }

  getfindContactList() {
    this.spinner.show();
    const req = {
      "contactListId": "",
      "contactListName": "",
      "contactListTypeId": "",
      "contactMechTypeId": "",
      "marketingCampaignId": ""
    }
    this.myContactsService.getfindContactList(req).subscribe(res => {
      this.findContactList = res.data[0].node1;
      this.spinner.hide();
    })
  }
  createcontent(){
    this._Router.navigate(['/marketing/create-content']);
    
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.ContactListID = "";
    this.advanceSearch.ContactListName = "";
    this.advanceSearch.ContactListTypeID = "";
    this.advanceSearch.ContactMechanismTypeID = "";
    this.advanceSearch.MarketingCampaignID = "";

    const req = {
      "contactListId": "",
      "contactListName": "",
      "contactListTypeId": "",
      "contactMechTypeId": "",
      "marketingCampaignId": ""
    }

    this.myContactsService.getfindContactList(req).subscribe((res: any) => {
      this.findContactList = res.data[0].node1;
      this.spinner.hide();
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  getContactListTypeId() {
    this.spinner.show();
    this.myContactsService.getContactListTypeId().subscribe(res => {
      this.ContactListTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContactListTypeId) {
        this.ContactListTypeIdArray.push({
          label: value.description,
          value: value.contactListTypeId
        })
      }
    })
  }
  getContactMechanismTypeID() {
    this.spinner.show();
    this.myContactsService.getContactMechanismTypeID().subscribe(res => {
      this.ContactMechanismTypeID = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ContactMechanismTypeID) {
        this.ContactMechanismTypeIDArray.push({
          label: value.description,
          value: value.contactMechTypeId
        })
      }
    })
  }
  getMarketingCampaign() {
    
    this.spinner.show();
    this.myContactsService.getMarketingCampaign().subscribe(res => {
      this.MarketingCampaign = res.data[0].node;
      this.spinner.hide();
      for (const value of this.MarketingCampaign) {
        this.MarketingCampaignArray.push({
          label: value.campaignName,
          value: value.marketingCampaignId,
        })
      }
    })
  }
  getVerifyEmailWebsiteId() {
    this.spinner.show();
    this.myContactsService.getVerifyEmailWebsiteId().subscribe(res => {
      this.VerifyEmailWebsiteId = res.data;
      this.spinner.hide();
      for (const value of this.VerifyEmailWebsiteId) {
        this.VerifyEmailWebsiteIdArray.push({
          label: value.siteName,
          value: value.webSiteId
        })
      }
    })
  }
  detailedPage(contactID) {
    this._Router.navigate(["/marketing/contact-list-marketing/view-contact"], { queryParams:{ contactID: contactID} })
  }
  MobileMessaging()
  {
    this._Router.navigate(['/#']);

  } 
  toCases()
  {
    this._Router.navigate(['/marketing/cases-marketing']);

  }
  SocialMedia()
  {
    this._Router.navigate(['/#']);

  }
  toAccount()
  {
    this._Router.navigate(['/crm/new-accounts']);
  }
  toOpportunity()
  {
    this._Router.navigate(['/crm/opportunities']);

  }
  toContact()
  {
    this._Router.navigate(['/crm/my-contacts']);

  }
  toEmail(){
    this._Router.navigate(['/communication-overview/Dashboard']);
  }
  toForecast(){
    
    this._Router.navigate(['/crm/sales-forecast']);

  }
  toCalendar(){
    
    this._Router.navigate(['crm/sales-calendar']);

  }
  toNotes(){
    this._Router.navigate(['/crm/sales-notes']);
  }
  toEmails(){
    
    this._Router.navigate(['/communication-overview/Dashboard']);

  }  
  toTasks(){
    this._Router.navigate(['/crm/Tasks']);
  }
  toDocuments(){
    
    this._Router.navigate(['crm/sales-documents']);

  }
  toLead()
  {
    this._Router.navigate(['/crm/leads']);

  }
  openDataResource()
  {
    this._Router.navigate(['/marketing/data-source-marketing']);
  }
  openMarketingCampaign()
  {
    this._Router.navigate(['/marketing/marketing-campaign']);
  }
  openTracking()
  {
    this._Router.navigate(['/marketing/tracking-marketing/tracking-marketing']);
  }

  openSegment()
  {
    this._Router.navigate(['/marketing/segment-marketing']);
  }

  openContactList()
  {
    this._Router.navigate(['/marketing/contact-list-marketing']);
  
  }
  openReports()
  {
    this._Router.navigate(['/marketing/reports-marketing']);
  }

  openCustomer()
  {
    this._Router.navigate(['/crm/accounts']);

  }
  openActivities()
  {
    this._Router.navigate(['/crm/events']);

  }

  changeActiveCategoryFunctions(tab)
  {
    this.activeCategoriess=tab;
  }
  
}
