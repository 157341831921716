import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-equipment-types',
  templateUrl: './equipment-types.component.html',
  styleUrls: ['./equipment-types.component.css']
})
export class EquipmentTypesComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;

  activeCategory = 9;
  activeCategories=2;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;

  equipmentTypes:any;

  equipmentTypeNameForm:FormGroup;
  fsmEquipmentList: any;
  fsmEquipmentById: any;
  editEquipmentTypeNameForm: FormGroup;

  

  constructor(
    readonly activatedRoute: ActivatedRoute,

    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) 
  {
    this.equipmentTypeNameForm = this._FormBuilder.group({
      typeName:"",

    });
    this.editEquipmentTypeNameForm = this._FormBuilder.group({
      typeName:"",

    });
   
  }

  ngOnInit(): void {
    this.getFsmEquipment();
 
  }
  detailPage(equipmentId){
    this._Router.navigate(['/fsm/fsmSetting/equipment-types/equipment-service'],
    { queryParams: { equipmentId:equipmentId} })
  }
  getFsmEquipmentById(product) {
    this.spinner.show();
    this.myContactsService.getFsmEquipmentById(product.equipmentId).subscribe(res => {
      this.fsmEquipmentById = res.data[0].getFsmEquipmentById[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.editEquipmentTypeNameForm;
        formValue.patchValue({
          typeName:  this.fsmEquipmentById.typeName
        })
  
      }, 2000);
    })
  }
  UpdateFsmEquipment() {
    this.spinner.show();

    const requestData = {
      "equipmentId": this.fsmEquipmentById.typeName,
      "typeName": this.editEquipmentTypeNameForm.value.typeName
    }

    this.myContactsService.UpdateFsmEquipment(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.editEquipmentTypeNameForm.reset();
        this.getFsmEquipment();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  createFsmEquipment() {
    this.spinner.show();

    const requestData = {
      "equipmentId": "",
      "typeName": this.equipmentTypeNameForm.value.typeName
    }
    this.myContactsService.createFsmEquipment(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.equipmentTypeNameForm.reset();
        this.getFsmEquipment();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFsmEquipment() {
    this.spinner.show();
    this.myContactsService.getFsmEquipment().subscribe(res => {
      this.fsmEquipmentList = res.data[0].getFsmEquipment;
      this.spinner.hide();
    })
  }
  DeleteFsmEquipment(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "equipmentId": product.equipmentId
        }
        this.myContactsService.DeleteFsmEquipment(req).subscribe(res => {
          this.getFsmEquipment();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  

  ngOnDestroy(): void {
    this.spinner.hide();
  }



}
