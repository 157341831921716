import { Component, OnInit, OnDestroy } from '@angular/core';
import { HeaderService } from '../../header/header.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { DropdownModule } from 'primeng/dropdown';
import { OrderManagementService } from '../order-management.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-create-sales-new-address',
  templateUrl: './create-sales-new-address.component.html',
  styleUrls: ['./create-sales-new-address.component.css']
})
export class CreateSalesNewAddressComponent implements OnInit {
  products3: any;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  countryList: any[];
  stateList: any[];
  array = [
    {
      label: 'Y',
      value: 'Y'
    },
    {
      label: 'N',
      value: 'N'
    }
  ];
  public cols: any[];
  getContacts: any = [];
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ''
  };
  createShippingAddressForm: FormGroup;
  orderType: boolean;
  activeCategory = 1;
  constructor(
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly _FormBuilder: FormBuilder,
    readonly _OrderManagementService: OrderManagementService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute
  ) {
    this.createShippingAddressForm = this._FormBuilder.group({
      address1: [''],
      address2: [''],
      allowSolicitation: [''],
      attnName: [''],
      city: [''],
      contactMechPurposeTypeId: [''],
      contactMechTypeId: [''],
      countryGeoId: [''],
      partyId: [''],
      postalCode: [''],
      preContactMechTypeId: [''],
      stateProvinceGeoId: [''],
      toName: ['']
    });
    this.countryList = [];
    this.stateList = [];
    this.orderType = false;
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails();
   
    this.orderType = this._ActivatedRoute.snapshot.queryParams.isSales === 'true' ? true : false;
    this.getCountryList();
    this.createShippingAddressForm.controls.countryGeoId.valueChanges.subscribe(data => {
      this.getStateList(data);
    });
  }
  getCountryList(): void {
    this.spinner.show();
    this._GlobalResourceService.getCountryList()
      .then(data => {
        this.countryList = data.data.countryList.map(value => {
          return {
            label: value.split(':')[0],
            value: value.split(':')[1]
          };
        });
      });
      this.spinner.hide();
  }
  getStateList(countryId: string): void {
    this.spinner.show();
    this._GlobalResourceService.getStateList(countryId)
      .then(data => {
        this.stateList = data.data.stateList.map(value => {
          return {
            label: value,
            value
          };
        });
      });
      this.spinner.hide();
  }
  submit(): void {
    this.spinner.show();
    this._OrderManagementService.createShippingAddress(this.createShippingAddressForm.value)
      .then(data => {
        if (data.success) {
          this._ToastrService.success('Created');
          this.spinner.hide();
          this.orderType ?
            this.router.navigate(['/order/sales-finalize-order']) : this.router.navigate(['/order/purchase-finalize-order']);
        }
      });
      this.spinner.hide();
  }
}
