import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 6;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  projectForm: FormGroup;
  timesheetForm: FormGroup;
  newrequestForm: FormGroup;
  addResourceForm: FormGroup;
  RoleResourceListArray: any[]=[];
  RoleResourceList: any;
  PartyId: any;
  partyIdArray: any[]=[];
  ResourcePage: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  HidebtnRoleView: boolean=false;
  HidebtnTaskCrud: boolean=false;
  HidebtnTimeCurd: boolean=false;
  constructor(public createEventFormService: CreateEventFormService,
    readonly myContactsService: MyContactsService,
    readonly activatedRoute: ActivatedRoute,
    private _location: Location,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
        readonly _FormBuilder: FormBuilder,
        readonly toastr: ToastrService,
    readonly _Router: Router) {
      this.addResourceForm = this._FormBuilder.group({
        partyID: "",
        roleTypeID: "",
      })
    }

  ngOnInit(): void {
 
    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.PROJECTMGR_ADMIN=="PROJECTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.PROJECTMGR_VIEW=="PROJECTMGR_VIEW"){
      this.Hidebtn=true;
    }
    this.getRoleResourceList();
    this.getProductMemberParty();
    this.getResourcePage();
  }
  myProfilePage(partyId) {
    this._Router.navigate(["/psa/my-profile"], { queryParams: { id: partyId } })
  }
  getResourcePage() {
    this.spinner.show();
    this.myContactsService.getResourcePage().subscribe(res => {
      this.ResourcePage = res.data;
      this.spinner.hide();
    })
  }
  homeButton(){
    this._Router.navigate(['/psa/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  removeResource(product) {

    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const formData = {
          "partyId": product.partyid.partyId,
          "roleTypeId": product.roleTypeIds.roleTypeId,
          "view_INDEX_1": "20",
          "view_SIZE_1": "0",
          
        }
        this.myContactsService.removeResource(formData).subscribe((res: any) => {
           this.getResourcePage();
        

        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createAddResource() {
    this.spinner.show();
    const formData = {
      "partyId": this.addResourceForm.value.partyID,
      "roleTypeId": this.addResourceForm.value.roleTypeID,
    }
    this.myContactsService.addResource(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getResourcePage();
        this.closebutton.nativeElement.click();


      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
      this.spinner.hide();

    });

  }
  getProductMemberParty() {
    this.spinner.show();
    this.myContactsService.getResourceParty().subscribe(res => {
      this.PartyId = res.data[0].partyId;
      this.spinner.hide();
      for (const value of this.PartyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getRoleResourceList() {
    this.spinner.show();
    this.myContactsService.getRoleResourceList().subscribe(res => {
      this.RoleResourceList = res.data;
      this.spinner.hide();
      for (const value of this.RoleResourceList) {
        this.RoleResourceListArray.push({
          label: value.roleTypeData.description ,
          value: value.roleTypeData.roleTypeId
        })
      }
    })
  }
  projectMain() {
    this._Router.navigate(["/psa/project/project"])
  }
  myTasks() {
    this._Router.navigate(["/psa/project/my-tasks"])
  }
  myTime() {
    this._Router.navigate(["/psa/project/my-time"])
  }
  projects() {
    this._Router.navigate(["/psa/project/projects"])
  }
  tasks() {
    this._Router.navigate(["/psa/project/tasks-project"])
  }
  resources() {
    this._Router.navigate(["/psa/project/resources"])
  }
  timesheet() {
    this._Router.navigate(["/psa/project/timesheet-project"])
  }
  
  skillType() {
    this._Router.navigate(["/psa/project/skill-type"])
  }
  requestList() {
    this._Router.navigate(["/psa/project/request-list"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
