import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import Swal from 'sweetalert2'
import { R3Identifiers } from '@angular/compiler';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';

import { Location } from '@angular/common';
@Component({
  selector: 'app-find-communication',
  templateUrl: './find-communication.component.html',
  styleUrls: ['./find-communication.component.css']
})
export class FindCommunicationComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;


  products3: any;
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  search= '';
  accountsList:any[]=[]
  relatedContact:any[]=[]
  rows = 3;
  activeCategory=2;
  readonly req = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  activeCategoryValue=2;
  createCommEvent:FormGroup;
  opportunityIdArray: { label: string; value: string; }[];
  commEventId: any;
  overviewDetail: any;
  communicationEventRole: any;
  communicationEventContent: any;
  communicationEventChilds: any;
  customerRequestList: any;
  eventPurpose: any;
  eventRole: any;
  stausIdArray: any[]=[];
  comtypeIdArray: any[]=[];
  contentMMArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  advanceSearchOrderByReturn :{
    communicationEventId: string,
    communicationEventIdSearchType: string,
    communicationEventTypeId:string,
    partyId:string,
    partyIdFrom: string,
    partyIdTo: string,
    returnId: string,
    returnIdSearchType: string,
    statusId: string
  }
  advanceSearchOrderBy: {
    communicationEventId:string,
    communicationEventIdSearchType:string,
    communicationEventTypeId: string,
    partyId: string,
    partyIdFrom: string,
    partyIdTo: string,
    orderId: string,
    orderIdSearchType:string,
    statusId: string
   


  };
  evenTByOrder: any;
  commByOrder: any;
  getInitailData: any;
  
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  contactListId: any;
  contactListIdArray: any[]=[];
  datetimeEnded: string;
  datetimeStarted: string;
  parentCommEventArray: any[]=[];
  reasonenumArray: any[]=[];
  contactMech: any;
  contactMechArray: any[]=[];
  getEstimateFrom: string;
  getEstimateTo: string;
  contactMechTypeArray: any[]=[];
  eventProductList: any;
  contactMechTo: any;
  contactMechToArray: any[]=[];
  eventReturnList: any;
  eventOrderList: any;
  eventContentList: any;
  UnknownPartyComms: any;
  commEventIdPartyId: string;
  eventPurposeArray: any[]=[];
  createEventPurpose1:FormGroup
  roleId: any;
  roleIdArray: any[]=[];
  createEventPurposeNew:FormGroup;
  createEventContent1:FormGroup;
  createProduct1:FormGroup;
  newCommunicationEventID:any;
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  Products: any;
  ProductsArray: any[]=[];
  createReturn1:FormGroup;
  return: any;
  returnArray: any[]=[];
  communctionEventOrderForm: FormGroup;
  ReasonEnumIds: any;
  getOrderIDArray: any[]=[];
  OrderID: any;
  communicationEventId: any;
  CommunicationEventOrders: any;
  reasonenumArrays: any[]=[];
  purposeTypeArrays: any[]=[];
  purposeTypes:any;
  listCommContent: any;
  parentContentIdListsArray: any[]=[];
  parentContentIdList:any;
  eventPurposeArrays: any[]=[];
  findCommunicationReturn: any;
  fileData: File;
  uploadFile:FormData



  constructor(
    readonly router: Router,
    readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly addSkillService: AddSkillService,
    readonly _FormBuilder: FormBuilder,
private _location: Location,
readonly spinner: NgxSpinnerService,
  ) {
    this.createReturn1 = this._FormBuilder.group({
      returnId: ['']
     
    });
    this.createProduct1 = this._FormBuilder.group({
      productId: ['']
    
    });
    this.createEventContent1 = this._FormBuilder.group({
      extContentId: [''],
      parentContentId:[''],
      uploadFile:['']
     
 
    });
    this.createEventPurposeNew = this._FormBuilder.group({
      partyId: [''],
      roleTypeId:['']
     
 
    });
    this.createEventPurpose1 = this._FormBuilder.group({
      eventPurposeId: [''],
     
 
    });

    this.communctionEventOrderForm = this._FormBuilder.group({
      orderId: [''],
     });

    

    this.advanceSearchOrderByReturn = {
      communicationEventId: "",
      communicationEventIdSearchType: "Contains",
      communicationEventTypeId: "",
      partyId: "",
      partyIdFrom: "",
      partyIdTo: "",
      returnId: "",
      returnIdSearchType: "Contains",
      statusId: ""
     


    };
    this.advanceSearchOrderBy = {
      communicationEventId: "",
      communicationEventIdSearchType: "Contains",
      communicationEventTypeId: "",
      partyId: "",
      partyIdFrom: "",
      partyIdTo: "",
      orderId: "",
      orderIdSearchType: "Contains",
      statusId: ""
     


    };
  this.createCommEvent = this._FormBuilder.group({
    commEventId: [''],
    parCommEventID: [''],
    commTypeId: [''],
    status:[''],
    partyFrom:[''],
    partyTo: [''],
    conMechId: [''],
    fromContactMechId: [''],
    toContactMechId:[''],
    roleTypeFrom:[''],
    roleTypeTo: [''],
    contactListId: [''],
    startdate: [''],
    finishDate:[''],
    subject:[''],
    reasonEnumId: [''],
    contentMimeTypeId: [''],
    content: [''],
    note:[''],

  });

  this.relatedContact=[{
    'product':'main'
  }]
  this.opportunityIdArray = [
    {
      label: 'Contains',
      value: 'Contains'
    },
    {
      label: 'Equals',
      value: 'Equals'
    },
    {
      label: 'Is Empty',
      value: 'Is Empty'
    },
    {
      label: 'Begins With',
      value: 'Begins With'
    },
  ];
   }

  ngOnInit(): void {
    this.getReturnOrderValue();
    this.getProducts();
    this.existingContentIds();

    this.getPartyId1();
    this.getCrmEventRoleTypeList();
    this.getListCommContent();
    this.getCrmEventPurpose();
    this.getCommunicationEventOrders();
    this.parentContentIdLists();
    this.FindCommunicationByReturn();

    const partyId: string = localStorage.getItem('partyId');
    this.commEventIdPartyId=partyId;
    this.getCrmListDetail();
    this.getCrmEventRole();
    this.getOrderID()
    this.getreasonEnumIds();
    this.getCrmEventProductList();
    this.getCrmEventReturnLists();
    this.getCrmEventOrderList();
    this.getCrmEventContentList();
    this.purposeType();
    this.getCrmEventPurposeListData();
  }

  homeButton(){
    this.router.navigate(['/communication-overview/Dashboard'])

  }
  cancelSubmit(){
    this._location.back();
  }
  
  
  



  getReturnOrderValue() {
    this.spinner.show();
    const req = {
    "pageNo":this.pageNo,
    "pageSize":this.pageSize
    }
    const form ={
      billingAccountId: "",
      entryDateFrom: "",
      entryDateFromSearchType: "Contains",
      entryDateTo: "",
      entryDateToSearchType: "Contains",
      billingAccountIdSearchType: "Contains",
      fromPartyId: "",
      returnHeaderTypeId: "",
      returnId: "",
      returnIdSearchType: "Contains",
      statusIds: [""],
    }
  
    
    this.accountsService.getOrderReturn(req,form).subscribe((res: any) => {

      this.return = res.data;
      this.spinner.hide();
      for (const value of this.return) {
        this.returnArray.push({
          label: value.returnId,
          value: value.returnId
        })
      }
    })
   
  }
  onSubmitNew4() {
    this.spinner.show();
    const requestData = {
      "returnId": this.createReturn1.value.returnId,
     
    }

    this.accountsService.postCrmEventReturnList(this.commEventId,requestData.returnId).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.getCrmEventReturnLists();

        // this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:this.commEventId} });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  getProducts() {
    this.spinner.show();
    const req = {
      "internalName": "",
      "internalNameSearchType": "Contains",
      "productId": "",
      "productIdSearchType": "Contains"
     

    }
    this.accountsService.getProducts(this.finSize, req).subscribe((res: any) => {

      this.Products = res.data;
      this.spinner.hide();
      for (const value of this.Products) {
        this.ProductsArray.push({
          label: value.productId,
          value: value.productId
        })
      }


    })
  

  }
  onSubmitNew2() {
    this.spinner.show();
    const requestData = {
      "productId": this.createProduct1.value.productId,
     
    }

    this.accountsService.postCrmEventProductList(this.commEventId,requestData.productId).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.getCrmEventProductList();
        // this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:this.commEventId} });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }

  existingContentIds() {
    this.spinner.show();
    this.accountsService.existingContentIds().subscribe(res => {
      const contentid = res.data;
      this.spinner.hide();
       for (const value of contentid) {
        this.eventPurposeArrays.push({
          label: value.contentId,
          value: value.contentId
          
        })
      } 
    })
   
  }

  parentContentIdLists() {
    this.spinner.show();
    this.accountsService.parentContentIdLists().subscribe(res => {
      const parentContentIdList = res.data;
      this.spinner.hide();
       for (const value of parentContentIdList) {
        this.parentContentIdListsArray.push({
          label: value.contentIdTo,
          value: value.contentId
          
        })
      } 
    })
   
  }

  

  getPartyId1() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getCrmEventRoleTypeList() {
    this.spinner.show();
    this.accountsService.getCrmEventRoleTypeList().subscribe(res => {
      this.roleId = res.data;
      this.spinner.hide();
      for (const value of this.roleId) {
        this.roleIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
   
  }
  
  
  onSubmitNew() {
    this.spinner.show();
    const requestData = {
      "partyId": this.createEventPurposeNew.value.partyId,
      "roleTypeId":this.createEventPurposeNew.value.roleTypeId,
    }

    this.accountsService.postCrmEventRole(this.commEventId,requestData).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        // this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:this.commEventId} });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }


  getCrmEventPurpose() {
    this.spinner.show();
    this.accountsService.getCrmEventPurpose().subscribe(res => {
      const eventPurpose = res.data;
      this.spinner.hide();
       for (const value of eventPurpose) {
        this.eventPurposeArray.push({
          label: value.description,
          value: value.communicationEventPrpTypId
        })
      } 
    })
   
  }

  onSubmit() {
    this.spinner.show();
    const requestData = {
      "communicationEventPrpTypId": this.createEventPurpose1.value.eventPurposeId,
      "description":''
    }

    this.accountsService.postCrmEventPurposeListData( this.commEventId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getCrmEventPurposeListData();

        // this.router.navigate(['/crm/crm-overview'], { queryParams: { commEventId:  this.commEventId} });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
 
  getCrmListDetail() {
    this.spinner.show();
    const data={
      "communicationEventId": "",
      "communicationEventIdSearchType": "Contains",
      "communicationEventTypeId": "",
      "parentCommEventId": "",
      "parentCommEventIdSearchType": "Contains",
      "partyId": "",
      "partyIdFrom": "",
      "partyIdTo":  this.commEventIdPartyId,
      "roleTypeId": "",
      "statusId": "",
      "subject": "",
      "subjectSearchType": ""
    }
   
    this.accountsService.crmMainPageData(data, this.req).subscribe((res: any) => {
      this.commEventId = res.data[0].communicationEventId;
      this.getCrmOverViewDetail();
      this.getCrmEventPurposeListData();
      this.getCrmEventRole();
      this.getDropdownList();
      this.getPartyId();
      this.getCommunicationEventByOrder();
      this.getCommunicationByOrder();
      this.getCrmOverViewDetailEdit();
      this.getCrmEventProductList();
      this.getRoleId();
      this.getContactList();
      this.getCrmEventReturnLists();
      this.getCrmEventOrderList();
      this.getCrmEventContentList();
      this.getUnknownPartyComms();
      this.spinner.hide();

    })
   
  }
  getUnknownPartyComms() {
    this.spinner.show();
  
   
    this.accountsService.getUnknownPartyComms().subscribe((res: any) => {
      this.UnknownPartyComms = res.data;
      this.spinner.hide();

    })
   
  }
  getCrmOverViewDetailEdit() {
    this.spinner.show();
   
    this.accountsService.getCrmOverViewDetail(this.commEventId).subscribe((res: any) => {
      this.contactEventMech(res.data.partyIdFrom);
      this.contactEventMechTo(res.data.partyIdTo);
      this.spinner.hide();
      setTimeout(() => {
        const fromdate=res.data.datetimeStarted
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=res.data.datetimeEnded
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
      
        const formValue = this.createCommEvent;
        formValue.patchValue({
          commEventId: res.data.communicationEventId,
          parCommEventID:res.data.parentCommEventId,
          commTypeId: res.data.communicationEventTypeId,
          status:res.data.statusId,
          partyFrom:res.data.partyIdFrom,
          partyTo: res.data.partyIdTo,
          conMechId: res.data.contactMechTypeId,
          fromContactMechId: res.data.contactMechIdFrom,
          toContactMechId:res.data.contactMechIdTo,
          roleTypeFrom:res.data.roleTypeIdFrom,
          roleTypeTo: res.data.roleTypeIdTo,
          contactListId: res.data.contactListId,
          startdate:  this.getEstimateFrom,
          finishDate:this.getEstimateTo,
          subject:res.data.subject,
          reasonEnumId: res.data.reasonEnumId,
          contentMimeTypeId: res.data.contentMimeTypeId,
          content: res.data.content,
          note:res.data.note,

        
  
  
        })
      
      }, 3000);
     
    })

   
  }
  onUpdateEvent() {
    this.spinner.show();
    const fromActualdate=this.createCommEvent.get('startdate').value;
    this.datetimeStarted=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createCommEvent.get('finishDate').value;
    this.datetimeEnded=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "communicationEventId":  this.commEventId,
      "communicationEventTypeId": this.createCommEvent.value.commTypeId,
      "contactListId": this.createCommEvent.value.contactListId,
      "contactMechIdFrom": this.createCommEvent.value.fromContactMechId,
      "contactMechIdTo": this.createCommEvent.value.toContactMechId,
      "contactMechTypeId": this.createCommEvent.value.conMechId,
      "content": this.createCommEvent.value.content,
      "contentMimeTypeId": this.createCommEvent.value.contentMimeTypeId,
      "datetimeEnded": this.datetimeEnded,
      "datetimeStarted": this.datetimeStarted,
      "messageId": '',
      "note": this.createCommEvent.value.note,
      "parentCommEventId": this.createCommEvent.value.parCommEventID,
      "partyIdFrom": this.createCommEvent.value.partyFrom,
      "partyIdTo": this.createCommEvent.value.partyTo,
      "reasonEnumId": this.createCommEvent.value.reasonEnumId,
      "roleTypeIdFrom": this.createCommEvent.value.roleTypeFrom,
      "roleTypeIdTo":this.createCommEvent.value.roleTypeTo,
      "statusId":this.createCommEvent.value.status,
      "subject": this.createCommEvent.value.subject,
    }

    this.accountsService.updateCommunicationNew(requestData).subscribe((res: any) => {
   
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate(['/crm/main-crm']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    
  }
  
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  
  }
  getDropdownList() {
    this.spinner.show();
   
    this.accountsService.getCrmInitialCommData().subscribe((res: any) => {
      const stausId = res.data.commEventstatus;
      const comtypeId=res.data.commEventTypes;
      const contentMM=res.data.mimeType;
    this.spinner.hide();
    const parentCommEvent=res.data.parentCommEvent;
    const reasonenum=res.data.Reasonenum;
    const contactMechType=res.data.contactMechType;
    for (const value of contactMechType) {
      this.contactMechTypeArray.push({
        label: value.description,
        value: value.contactMechTypeId
      })
    } 
    for (const value of reasonenum) {
      this.reasonenumArray.push({
        label: value.description,
        value: value.enumId
      })
    } 
    for (const value of parentCommEvent) {
      this.parentCommEventArray.push({
        label: value.communicationEventId,
        value: value.communicationEventId
      })
    } 
      for (const value of stausId) {
        this.stausIdArray.push({
          label: value.description,
          value: value.statusId
        })
      } 
      for (const value of comtypeId) {
        this.comtypeIdArray.push({
          label: value.description,
          value: value.communicationEventTypeId
        })
      } 
      for (const value of contentMM) {
        this.contentMMArray.push({
          label: value.description,
          value: value.mimeTypeId
        })
      } 
    })
      
}

getListCommContent() {
  this.spinner.show();    
 
  this.accountsService.getListCommContent(this.commEventId).subscribe((res: any) => {
    this.listCommContent = res.data[0].commReturn;
   
    this.spinner.hide();

  })
 
}


  getreasonEnumIds() {
    this.spinner.show();
    this.accountsService.getreasonEnumIds().subscribe(res => {
      this.ReasonEnumIds = res.data;
      this.spinner.hide();
      for (const value of this.ReasonEnumIds) {
        this.reasonenumArrays.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }

  purposeType() {
    this.spinner.show();
    this.accountsService.purposeType().subscribe(res => {
      this.purposeTypes = res.data;
      this.spinner.hide();
      for (const value of this.purposeTypes) {
        this.purposeTypeArrays.push({
          label: value.description,
          value: value.communicationEventPrpTypId
        })
      }
    })
  }


  getOrderID() {
    this.spinner.show();
    this.accountsService.getOrderID().subscribe(res => {
      this.OrderID = res.data;
      this.spinner.hide();
      for (const value of this.OrderID) {
        this.getOrderIDArray.push({
          label: value.dataNode.orderId,
          value: value.dataNode.orderId
        })
      }
    })
  }

  getCommunicationEventOrders() {
    this.spinner.show();
    this.accountsService.getCommunicationEventOrders(this.commEventId).subscribe(res => {
      this.CommunicationEventOrders = res.data;
      this.spinner.hide();
    })
  }

  

  deleteUploadFile(UploadAttachFileId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       this.accountsService.deleteUploadFile(UploadAttachFileId).subscribe((res: any) => {
          if (res.success) {
            this.getListCommContent();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

deleteCrmEventOrderLists(communicationEventId,orderId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
       this.accountsService.deleteCrmEventOrderLists(communicationEventId,orderId).subscribe((res: any) => {
          if (res.success) {
            this.getCommunicationEventOrders();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  // uploadSocialSecurityNumberUpdate(): void {
  //   this.spinner.show();
  //     if(this.fileData.name){
  //       const uploadFile = new FormData();
  //       uploadFile.append("file", this.fileData);
  //       this.accountsService.uploadSocialSecurityNumber(uploadFile,this.newTechID).subscribe((res: any) => {
  //         if (res.success) {
  //           this.toastr.success("Security-No Updated Successfully");
  //           this.spinner.hide();
  //         }
  //       }, (err) => {
  //         this.spinner.hide();
  //         if (err.error.errors) {
  //           for (const value of err.error.errors) {
  //             this.toastr.error(value.fieldName + ' ' + value.fieldError);
  //           }
  //         }
  //         else {
  //           this.toastr.error(err.error.message);
  //         }
  //       });
  //     }
  // }


  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    console.log("file-data_recoed",this.fileData);
    console.log("file-data_recoed",this.fileData.name);
}


  uploadAttachFile(): void {
    this.spinner.show();
    const uploadFile = new FormData();
    uploadFile.append("file", this.fileData);
     this.accountsService.uploadAttachFile(uploadFile,this.commEventId,this.createEventContent1.value.extContentId,this.createEventContent1.value.parentContentId).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.getListCommContent();
        this.createEventContent1.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  createCommunicationEventOrder(): void {
    this.spinner.show();
    
     this.accountsService.createCommunicationEventOrder(this.commEventId,this.communctionEventOrderForm.value.orderId,).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getCommunicationEventOrders();
        
        this.closebutton5.nativeElement.click();

        this.communctionEventOrderForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  FindCommunicationByReturn(): void {
    this.spinner.show();
    let req={
      "communicationEventId": this.advanceSearchOrderByReturn.communicationEventId ? this.advanceSearchOrderByReturn.communicationEventId : '',
      "communicationEventTypeId":this.advanceSearchOrderByReturn.communicationEventTypeId ? this.advanceSearchOrderByReturn.communicationEventTypeId : '',
      "partyId": this.advanceSearchOrderByReturn.partyId ? this.advanceSearchOrderByReturn.partyId : '',
      "partyIdFrom":  this.advanceSearchOrderByReturn.partyIdFrom ? this.advanceSearchOrderByReturn.partyIdFrom : '',
      "partyIdTo": this.advanceSearchOrderByReturn.partyIdTo ? this.advanceSearchOrderByReturn.partyIdTo : '',
      "returnId": this.advanceSearchOrderByReturn.returnId ? this.advanceSearchOrderByReturn.returnId : '',
      "statusId": this.advanceSearchOrderByReturn.statusId ? this.advanceSearchOrderByReturn.statusId : '',

      }
     this.accountsService.FindCommunicationByReturn(req).subscribe((res: any) => { 
      if (res.success) {
        this.findCommunicationReturn=res.data;
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  advanceSearchReturnOrder(isPopUp: boolean): void {
    this.spinner.show();
    this.evenTByOrder = [];
    const result = {
        
        communicationEventId: this.advanceSearchOrderByReturn.communicationEventId ? this.advanceSearchOrderByReturn.communicationEventId : '',
        communicationEventIdSearchType: this.advanceSearchOrderByReturn.communicationEventIdSearchType ? this.advanceSearchOrderByReturn.communicationEventIdSearchType : '',
        communicationEventTypeId:this.advanceSearchOrderByReturn.communicationEventTypeId ? this.advanceSearchOrderByReturn.communicationEventTypeId : '',
        partyId: this.advanceSearchOrderByReturn.partyId ? this.advanceSearchOrderByReturn.partyId : '',
        partyIdFrom: this.advanceSearchOrderByReturn.partyIdFrom ? this.advanceSearchOrderByReturn.partyIdFrom : '',
        partyIdTo: this.advanceSearchOrderByReturn.partyIdTo ? this.advanceSearchOrderByReturn.partyIdTo : '',
        returnId: this.advanceSearchOrderByReturn.returnId ? this.advanceSearchOrderByReturn.returnId : '',
        returnIdSearchType: this.advanceSearchOrderByReturn.returnIdSearchType ? this.advanceSearchOrderByReturn.returnIdSearchType : '',
        statusId: this.advanceSearchOrderByReturn.statusId ? this.advanceSearchOrderByReturn.statusId : '',
      

    }
    this.accountsService.getCommunicationEventByOrder(this.req,result).subscribe((resp: any) => {
        if (resp.success) {
          this.spinner.hide();
          this.evenTByOrder = resp.data;
        } 
      });
     
  }
  

  getRoleId() {
    this.spinner.show();
    this.accountsService.getParty().subscribe(res => {
      this.roleTypeId = res.data.roleType;
      this.spinner.hide();
      for (const value of this.roleTypeId) {
        this.roleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
    
  }
  contactEventMechTo(id) {
    this.spinner.show();
    this.accountsService.contactEventMech(id).subscribe(res => {
      this.contactMechTo = res.data;
      this.spinner.hide();
      for (const value of this.contactMechTo) {
        this.contactMechToArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
  
  }
  contactEventMech(id) {
    this.spinner.show();
    this.accountsService.contactEventMech(id).subscribe(res => {
      this.contactMech = res.data;
      this.spinner.hide();
      for (const value of this.contactMech) {
        this.contactMechArray.push({
          label: value.contactMechId,
          value: value.contactMechId
        })
      }
    })
   
  }
  
  getContactList() {
    this.spinner.show();
    this.accountsService.getContactList().subscribe(res => {
      this.contactListId = res.data;
      this.spinner.hide();
      for (const value of this.contactListId) {
        this.contactListIdArray.push({
          label: value.description,
          value: value.contactListId
        })
      }
    })
   
  }


  
  reset(){
    this.spinner.show();
    this.advanceSearchOrderByReturn.communicationEventId="";
    this.advanceSearchOrderByReturn.communicationEventTypeId="";
    this.advanceSearchOrderByReturn.partyId="";
    this.advanceSearchOrderByReturn.partyIdFrom="";
    this.advanceSearchOrderByReturn.partyIdTo="";
    this.advanceSearchOrderByReturn.returnId="";
    this.advanceSearchOrderByReturn.statusId="";
    const result = {
      communicationEventId: "",
        communicationEventIdSearchType: "Contains",
        communicationEventTypeId: "",
        partyId: "",
        partyIdFrom: "",
        partyIdTo: "",
        returnId: "",
        returnIdSearchType: "Contains",
        statusId: ""

    }
    this.accountsService.getCommunicationEventByOrder(this.req,result).subscribe((res: any) => {
      this.evenTByOrder = res.data;
      this.spinner.hide();

    })

  }
  advanceSearchOrder(isPopUp: boolean): void {
    this.spinner.show();
    this.commByOrder = [];
    const result = {
      communicationEventId: this.advanceSearchOrderBy.communicationEventId ? this.advanceSearchOrderBy.communicationEventId : '',
        communicationEventIdSearchType: this.advanceSearchOrderBy.communicationEventIdSearchType ? this.advanceSearchOrderBy.communicationEventIdSearchType : '',
        communicationEventTypeId:this.advanceSearchOrderBy.communicationEventTypeId ? this.advanceSearchOrderBy.communicationEventTypeId : '',
        partyId: this.advanceSearchOrderBy.partyId ? this.advanceSearchOrderBy.partyId : '',
        partyIdFrom: this.advanceSearchOrderBy.partyIdFrom ? this.advanceSearchOrderBy.partyIdFrom : '',
        partyIdTo: this.advanceSearchOrderBy.partyIdTo ? this.advanceSearchOrderBy.partyIdTo : '',
        orderId: this.advanceSearchOrderBy.orderId ? this.advanceSearchOrderBy.orderId : '',
        orderIdSearchType: this.advanceSearchOrderBy.orderIdSearchType ? this.advanceSearchOrderBy.orderIdSearchType : '',
        statusId: this.advanceSearchOrderBy.statusId ? this.advanceSearchOrderBy.statusId : '',
      

    }
    this.accountsService.getCommunicationByOrder(this.req,result).subscribe((resp: any) => {
        if (resp.success) {
          this.spinner.hide();
          this.commByOrder = resp.data;
        } 
      });
     
  }
  resetAdvanceReset(){
    this.spinner.show();
    this.advanceSearchOrderBy.communicationEventId="";
    this.advanceSearchOrderBy.communicationEventTypeId="";
    this.advanceSearchOrderBy.partyId="";
    this.advanceSearchOrderBy.partyIdFrom="";
    this.advanceSearchOrderBy.partyIdTo="";
    this.advanceSearchOrderBy.orderId="";
    this.advanceSearchOrderBy.statusId="";
    this.spinner.show();
    const result = {
      communicationEventId: "",
      communicationEventIdSearchType: "Contains",
      communicationEventTypeId: "",
      partyId: "",
      partyIdFrom: "",
      partyIdTo: "",
      orderId: "",
      orderIdSearchType: "Contains",
      statusId: ""

    }
    this.accountsService.getCommunicationByOrder(this.req,result).subscribe((res: any) => {
      this.commByOrder = res.data;
      this.spinner.hide();

    })

  }
  getCommunicationByOrder() {
    this.spinner.show();
    const result = {
      communicationEventId: "",
      communicationEventIdSearchType: "Contains",
      communicationEventTypeId: "",
      partyId: "",
      partyIdFrom: "",
      partyIdTo: "",
      orderId: "",
      orderIdSearchType: "Contains",
      statusId: ""

    }
    this.accountsService.getCommunicationByOrder(this.req,result).subscribe((res: any) => {
      this.commByOrder = res.data;
      this.spinner.hide();

    })
   
  }
  getCommunicationEventByOrder() {
    this.spinner.show();
    const result = {
      communicationEventId: "",
      communicationEventIdSearchType: "Contains",
      communicationEventTypeId: "",
      partyId: "",
      partyIdFrom: "",
      partyIdTo: "",
      returnId: "",
      returnIdSearchType: "Contains",
      statusId: ""

    }
    this.accountsService.getCommunicationEventByOrder(this.req,result).subscribe((res: any) => {
      this.evenTByOrder = res.data;
      this.spinner.hide();

    })
   
  }
  


  
  getCrmEventPurposeListData() {
    this.spinner.show();
   
    this.accountsService.getCrmEventPurposeListData(this.commEventId,this.req).subscribe((res: any) => {
      this.eventPurpose = res.data;
     
      this.spinner.hide();

    })
   
  }
  
  getCrmEventProductList() {
    this.spinner.show();
   
    this.accountsService.getCrmEventProductList(this.commEventId,this.req).subscribe((res: any) => {
      this.eventProductList = res.data;
     
      this.spinner.hide();

    })
   
  }
  getCrmEventReturnLists() {
    this.spinner.show();
   
    this.accountsService.getCrmEventReturnLists(this.commEventId).subscribe((res: any) => {
      this.eventReturnList = res.data[0].getCommunicationEventReturns;
     
      this.spinner.hide();

    })
   
  }
  
  getCrmEventRole() {
    this.spinner.show();
   
    this.accountsService.getCrmEventRole(this.commEventId,this.req).subscribe((res: any) => {
      this.eventRole = res.data[0].getCommunicationEventOrders;
     
      this.spinner.hide();

    })
   
  }
  getCrmEventOrderList() {
    this.spinner.show();
   
    this.accountsService.getCrmEventOrderList(this.commEventId,this.req).subscribe((res: any) => {
      this.eventOrderList = res.data;
     
      this.spinner.hide();

    })
   
  }
  getCrmEventContentList() {
    this.spinner.show();
   
    this.accountsService.getCrmEventContentList(this.commEventId,this.req).subscribe((res: any) => {
      this.eventContentList = res.data;
     
      this.spinner.hide();

    })
   
  }
  deleteContent(id,cid,fromdate){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteCrmEventContentList(id,cid,fromdate).subscribe((res: any) => {
       
          this.getCrmEventContentList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}}) }

  deleteCrmEventOrderList(id,rid){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteCrmEventOrderList(id,rid).subscribe((res: any) => {
       
          this.getCrmEventOrderList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        ) }}) }
  
  deleteCrmEventReturnList(id,rid){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteCrmEventReturnList(id,rid).subscribe((res: any) => {
       
          this.getCrmEventReturnLists();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})  }
// new delete product api created
      deleteCommunicationEventProduct(communicationEventId,productId) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this data.!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
           this.accountsService.deleteCommunicationEventProduct(communicationEventId,productId).subscribe((res: any) => {
            console.log(communicationEventId,productId)
              if (res.success) {
                this.getCrmEventProductList();

              }
              else {
                this.toastr.error("Error");
              }
            })
            Swal.fire(
              'Deleted!',
              'Deleted Successfully.',
              'success'
            )
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your data is safe :)',
              'error'
            )
          }
        })
      }
// old delete api of product commented
  // deleteCrmEventProductList(id,pid){
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: 'You will not be able to recover this data.!',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonText: 'Yes, delete it!',
  //     cancelButtonText: 'No, keep it'
  //   }).then((result) => {
  //     if (result.value) {
  //       this.accountsService.deleteCrmEventProductList(id,pid).subscribe((res: any) => {
       
          
  //       }, (err) => {
  //         this.toastr.error(err.error.message);
  //       })
  //       Swal.fire(
  //         'Deleted!',
  //         'Deleted Successfully.',
  //         'success'
  //       )
  //       this.getCrmEventProductList();
  //     } else if (result.dismiss === Swal.DismissReason.cancel) {
  //       Swal.fire(
  //         'Cancelled',
  //         'Your data is safe :)',
  //         'error'
  //       )
  //     }}) }

  deleteCommunicationEventRoles(product) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this data.!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it'
        }).then((result) => {
          if (result.value) {
            let req = {
              "communicationEventId":product.communicationEventId,
              "contactMechId":product.contactMechId,
              "partyId":product.partyId,
              "roleTypeId":product.roleTypeId ,
              "statusId": product.statusId,
              
            }
            this.accountsService.deleteCommunicationEventRoles(req).subscribe((res: any) => {
              if (res.success) {
                this.getCrmEventRole();
              }
              else {
                this.toastr.error("Error");
              }
            })
            Swal.fire(
              'Deleted!',
              'Deleted Successfully.',
              'success'
            )
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              'Cancelled',
              'Your data is safe :)',
              'error'
            )
          }
        })
      }

 
 
    //   deleteEventRole(id,pid,rid){
    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: 'You will not be able to recover this data.!',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonText: 'Yes, delete it!',
    //   cancelButtonText: 'No, keep it'
    // }).then((result) => {
    //   if (result.value) {
    //     this.accountsService.deleteEventRoleList(id,pid,rid).subscribe((res: any) => {
       
    //       this.getCrmEventRole();
    //     }, (err) => {
    //       this.toastr.error(err.error.message);
    //     })
    //     Swal.fire(
    //       'Deleted!',
    //       'Deleted Successfully.',
    //       'success'
    //     )
     
    //   } else if (result.dismiss === Swal.DismissReason.cancel) {
    //     Swal.fire(
    //       'Cancelled',
    //       'Your data is safe :)',
    //       'error'
    //     )
    //   }}) }
  deleteEventPurpose(id,cid){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteCrmEventPurposeListData(id,cid).subscribe((res: any) => {
       
          this.getCrmEventPurposeListData();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  
  getCrmOverViewDetail() {
    this.spinner.show();
   
    this.accountsService.getCrmOverViewDetail(this.commEventId).subscribe((res: any) => {
      this.overviewDetail = res.data;
      this.newCommunicationEventID = res.data.communicationEventId;
      this.spinner.hide();
      this.communicationEventRole=res.data.communicationEventRole;
      this.communicationEventContent=res.data.communicationEventContent;
      this.communicationEventChilds=res.data.communicationEventChilds;
      this.customerRequestList=res.data.customerRequestList;
     

    })
   
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategoryValue = tab;
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  createEventPurpose(id){
    this.router.navigate(['/crm/create-event-purpose'], { queryParams: { commEventId: id} });
  }
  createEventRole(id){
    this.router.navigate(['/crm/create-event-role'],{ queryParams: { commEventId: id} });
  }
  createEventWorkEffort(){
    this.router.navigate(['/crm/create-work-effort']);
  }
  createEventContent(id){
    this.router.navigate(['/crm/create-content'],{ queryParams: { commEventId: id} });
  }
  createOrder(id){
    this.router.navigate(['/crm/create-communication-order'], { queryParams: { commEventId: id} });
  }
  createProduct(id){
    this.router.navigate(['/crm/create-crm-product'], { queryParams: { commEventId: id} });
  }
  createReturn(id){
    this.router.navigate(['/crm/create-crm-return'], { queryParams: { commEventId: id} });
  }
 
  replyMail(){
    this.router.navigate(['/crm/new-communication-crm']);
  }
  ngOnDestroy(): void {

    this.spinner.hide();
      } 

}
