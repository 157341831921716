<div class="container-fluid main-container-wrapper" style="margin-top: 60px !important;">
    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
        style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Work Order</span>
        <span>
            <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
            <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
        </span>
    </div>
    <div class="bg-white color-grey" style="padding-bottom: 70px;">
        <div class="w3-card-4 classCard">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass">
                    <li [ngClass]="activeCategory==4?'active':''">
                        <a>Work Order Summary</a>
                    </li>


                </ul>
            </div>
            <div class="create-new-leade mt-2">
                <div class="container-fluid " style="padding: 0px 0px;">
                    <div class="row">
                        <form class="w-100">
                            <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                <div class="w-100">

                                    <div class="row mb-2" style="width: 100%; ">
                                        <div class="col-md-8">
                                            <div class="w3-card-4 classCard pb-5"
                                                style="margin-left:2% !important;margin-right: -18px;">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper p-2"
                                                    style="background: #cce0e95e; padding: 0px 15px;">
                                                    <p class="h-text color-grey"
                                                        style="margin: 0px 15px -1px -2px !important;">
                                                        Customer</p>
                                                    <span style="margin-left: 1%;"> | </span>
                                                    <a class="btn btn-link"
                                                        style="font-weight: bold; font-size: 14px !important;"
                                                        data-toggle="modal" data-target="#updateAssignmentPopup"
                                                       >Edit</a>
                                                </div>
                                                <div class="">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form class="w-100">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Customer
                                                                                    Id:</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.customerId ?
                                                                                this.assignmentByIdNew.customerId :
                                                                                'N/A'}}
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Customer
                                                                                    Name:</label>
                                                                            </div>

                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.customerName ?
                                                                                this.assignmentByIdNew.customerName :
                                                                                'N/A'}}
                                                                            </div>


                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Customer
                                                                                    Address: </label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.address1 ?
                                                                                this.assignmentByIdNew.address1 :
                                                                                'N/A'}}
                                                                                {{this.assignmentByIdNew.address2 ?
                                                                                this.assignmentByIdNew.address2 :
                                                                                'N/A'}}

                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Customer
                                                                                    State:</label>
                                                                            </div>

                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.stateProvinceGeoId
                                                                                ?
                                                                                this.assignmentByIdNew.stateProvinceGeoId
                                                                                : 'N/A'}}



                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Customer
                                                                                    City: </label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.city ?
                                                                                this.assignmentByIdNew.city : 'N/A'}}

                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Customer
                                                                                    Country Id:</label>
                                                                            </div>

                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.countryGeoId ?
                                                                                this.assignmentByIdNew.countryGeoId :
                                                                                'N/A'}}



                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Customer
                                                                                    Postal Code: </label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.postalCode ?
                                                                                this.assignmentByIdNew.postalCode :
                                                                                'N/A'}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="w3-card-4 classCard mt-3"
                                                style="margin-left:2% !important;margin-right: -18px;">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper p-2"
                                                    style="background: #cce0e95e; padding: 0px 15px;">
                                                    <p class="h-text color-grey"
                                                        style="margin: 0px 15px -1px -2px !important;">Problem
                                                    </p>
                                                    <span style="margin-left: 1%;"> | </span>
                                                                    <a class="btn btn-link"
                                                                        style="font-weight: bold; font-size: 14px !important;"    data-toggle="modal" data-target="#updateAssignmentPopup"
                                                       >Edit</a> 
                                                </div>
                                                <div class="">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form class="w-100">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Customer
                                                                                    Id:</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.customerId ?
                                                                                this.assignmentByIdNew.customerId:"N/A"}}
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Problem
                                                                                    Id:</label>
                                                                            </div>

                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.problemId ?
                                                                                this.assignmentByIdNew.problemId:"N/A"}}
                                                                            </div>


                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">


                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Problem
                                                                                    Status:</label>
                                                                            </div>

                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.problemStatus ?
                                                                                this.assignmentByIdNew.problemStatus:"N/A"}}

                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="w3-card-4 classCard mt-3"
                                                style="margin-left: 14px;margin-right: -18px;">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper p-2"
                                                    style="background-color:#cce0e95e!important; padding: 0px 15px;">
                                                    <p class="h-text color-grey"
                                                        style="margin: 0px 15px -1px -2px !important; color: #65787e;">
                                                        Work
                                                        Done</p>
                                                     <span style="margin-left: 1%; color :white;"> | </span>
                                                                    <a class="btn btn-link"   data-toggle="modal" data-target="#updateAssignmentPopup"
                                                                   
                                                                        
                                                                    style="color: white; font-weight: bold; font-size: 14px !important;">Edit</a> 
                                                </div>
                                                <div class="">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form class="w-100">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label
                                                                                    for="exampleInputEmail1">WorkOrder
                                                                                    Id:</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.workOrderId ?
                                                                                this.assignmentByIdNew.workOrderId:"N/A"}}
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Problem
                                                                                    Id:</label>
                                                                            </div>

                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.problemId ?
                                                                                this.assignmentByIdNew.problemId:"N/A"}}
                                                                            </div>


                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label
                                                                                    for="exampleInputEmail1">Service:</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.service ?
                                                                                this.assignmentByIdNew.service:"N/A"}}

                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Problem
                                                                                    Status:</label>
                                                                            </div>

                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.problemStatus ?
                                                                                this.assignmentByIdNew.problemStatus:"N/A"}}

                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label
                                                                                    for="exampleInputEmail1">Estimated
                                                                                    Time:</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.eta ?
                                                                                this.assignmentByIdNew.eta:"N/A"}}

                                                                            </div>



                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="w3-card-4 classCard mt-3"
                                                style="margin-left: 14px;margin-right: -18px;">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead p-2 font-medium mb-0 suppliers-wrapper"
                                                    style="background-color:#cce0e95e!important; padding: 0px 15px;">
                                                    <p class="h-text color-grey"
                                                        style="margin: 0px 15px -1px -2px !important; color: #65787e;">
                                                        Invoice</p>
                                                     <span style="margin-left: 1%; color :white;"> | </span>
                                                                    <a class="btn btn-link"   data-toggle="modal" data-target="#updateAssignmentPopup"
                                                                   
                                                                        style="color: white; font-weight: bold; font-size: 14px !important;">Edit</a> 
                                                </div>
                                                <div class="">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form class="w-100">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Status
                                                                                    :</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.problemStatus ?
                                                                                this.assignmentByIdNew.problemStatus:"N/A"}}
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Amount
                                                                                    :</label>
                                                                            </div>

                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.amount ?
                                                                                this.assignmentByIdNew.amount:"$0.00"}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="w3-card-4 classCard"
                                                style="margin-left: 6px;margin-right: -18px; height: 345px !important;">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper p-2"
                                                    style="background: #cce0e95e; padding: 0px 15px;">
                                                    <p class="h-text color-grey"
                                                        style="margin: 0px 15px -1px -2px !important; color: grey;">
                                                        Assignment</p>
                                                    <span style="color:grey; font-weight: bold;"> | </span>
                                                                    <a class="btn btn-link"
                                                                        style="color: grey; font-weight: bold; font-size: 14px !important;"    data-toggle="modal" data-target="#updateAssignmentPopup"
                                                       >Edit</a>
                                                </div>
                                                <div class="">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form class="w-100">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-6 form-group classInput">
                                                                                <label for="exampleInputEmail1"
                                                                                    style="margin-bottom: 0px !important;">Skill:</label>
                                                                            </div>
                                                                            <div class="col-lg-6">
                                                                                {{this.assignmentByIdNew.skill ?
                                                                                this.assignmentByIdNew.skill:"N/A"}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-6 form-group classInput">
                                                                                <label for="exampleInputEmail1"
                                                                                    style="margin-bottom: 0px !important;">Technician
                                                                                    Id
                                                                                    :</label>
                                                                            </div>
                                                                            <div class="col-lg-6">
                                                                                {{this.assignmentByIdNew.technicianId ?
                                                                                this.assignmentByIdNew.technicianId:"N/A"}}

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-6 form-group classInput">
                                                                                <label for="exampleInputEmail1"
                                                                                    style="margin-bottom: 0px !important;">Work
                                                                                    Order Id
                                                                                    :</label>
                                                                            </div>
                                                                            <div class="col-lg-6">
                                                                                {{this.assignmentByIdNew.workOrderId ?
                                                                                this.assignmentByIdNew.workOrderId:"N/A"}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-6 form-group classInput">
                                                                                <label for="exampleInputEmail1"
                                                                                    style="margin-bottom: 0px !important;">Priority:</label>
                                                                            </div>
                                                                            <div class="col-lg-6">
                                                                                {{this.assignmentByIdNew.priority ?
                                                                                this.assignmentByIdNew.priority:"N/A"}}
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-6 form-group classInput">
                                                                                <label for="exampleInputEmail1"
                                                                                    style="margin-bottom: 0px !important;">Work
                                                                                    Duration:</label>
                                                                            </div>
                                                                            <div class="col-lg-6">
                                                                                {{this.assignmentByIdNew.estimatedDuration
                                                                                ?
                                                                                this.assignmentByIdNew.estimatedDuration
                                                                                :"N/A"}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-6 form-group classInput">
                                                                                <label for="exampleInputEmail1"
                                                                                    style="margin-bottom: 0px !important;">Appointment
                                                                                    Type:</label>
                                                                            </div>
                                                                            <div class="col-lg-6">
                                                                                {{this.assignmentByIdNew.appointmentType
                                                                                ?
                                                                                this.assignmentByIdNew.appointmentType:"N/A"}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-6 form-group classInput">
                                                                                <label for="exampleInputEmail1"
                                                                                    style="margin-bottom: 0px !important;">Promised
                                                                                    Arrival Time:</label>
                                                                            </div>
                                                                            <div class="col-lg-6">
                                                                                {{this.assignmentByIdNew.promisedArrivalFrom
                                                                                ? this.promisedArrivalFrom :"N/A"}}
                                                                                To
                                                                                {{this.assignmentByIdNew.promisedArrivalTo
                                                                                ? this.promisedArrivalTo :"N/A"}}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="w3-card-4 classCard mt-3"
                                                style="margin-left: 6px;margin-right: -18px;">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 p-2 create-lead font-medium mb-0 suppliers-wrapper"
                                                    style="background-color:#cce0e95e!important;padding: 0px 15px;">
                                                    <p class="h-text color-grey"
                                                        style="margin: 0px 15px -1px -2px !important; color: #65787e;">
                                                        Notes
                                                    </p>
                                                    <span style="color: white; font-weight: bold;"> | </span>
                                                                    <a class="btn btn-link"   data-toggle="modal" data-target="#updateAssignmentPopup"
                                                                   
                                                                        style="color: white; font-weight: bold; font-size: 14px !important;">Edit</a>
                                                </div>
                                                <div class="">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form class="w-100">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-6 form-group classInput">
                                                                                <label for="exampleInputEmail1"
                                                                                    style="margin-bottom: 0px !important;">No
                                                                                    Notes.</label>
                                                                            </div>
                                                                            <!-- <div class="col-lg-6">
                                                                                                    {{this.assignmentByIdNew.skill ? this.assignmentByIdNew.skill:"N/A"}}
                                                                                            </div> -->
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="w3-card-4 classCard mt-3"
                                                style="margin-left: 6px;margin-right: -18px;">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper p-2"
                                                    style="background-color:#cce0e95e!important;padding: 0px 15px;">
                                                    <p class="h-text color-grey"
                                                        style="margin: 0px 15px -1px -2px !important; color: #65787e;">
                                                        Attachments</p>
                                                     <span style="color: white; font-weight: bold;"> | </span>
                                                                    <a class="btn btn-link"
                                                                        style="color: white; font-weight: bold; font-size: 14px !important;"   data-toggle="modal" data-target="#updateAssignmentPopup"
                                                       >Edit</a> 
                                                </div>
                                                <div class="">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form class="w-100">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-6 form-group classInput">
                                                                                <label for="exampleInputEmail1"
                                                                                    style="margin-bottom: 0px !important;">No
                                                                                    Attachments.</label>
                                                                            </div>
                                                                            <!-- <div class="col-lg-6">
                                                                                                    {{this.assignmentByIdNew.skill ? this.assignmentByIdNew.skill:"N/A"}}
                                                                                            </div> -->
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="w3-card-4 classCard mt-3"
                                                style="margin-left: 6px;margin-right: -18px;">
                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper p-2"
                                                    style="background-color:#cce0e95e!important; padding: 0px 15px;">
                                                    <p class="h-text color-grey"
                                                        style="margin: 0px 15px -1px -2px !important; color: #65787e;">
                                                        Time
                                                        Posted</p>
                                                   <span style="color: white; font-weight: bold;"> | </span>
                                                                    <a class="btn btn-link"   data-toggle="modal" data-target="#updateAssignmentPopup"
                                                                   
                                                                        style="color: white; font-weight: bold; font-size: 14px !important;">Edit</a>
                                                </div>
                                                <div class="">
                                                    <div class="container-fluid">
                                                        <div class="row">
                                                            <form class="w-100">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">Primary
                                                                                    Tech :</label>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.technicianName
                                                                                ?
                                                                                this.assignmentByIdNew.technicianName:"N/A"}}
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInput">
                                                                                <label for="exampleInputEmail1">En Route
                                                                                    Work :</label>
                                                                            </div>

                                                                            <div class="col-lg-3">
                                                                                {{this.assignmentByIdNew.eta ?
                                                                                this.assignmentByIdNew.eta :"$0.00"}}
                                                                            </div>


                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <!-- <div class="mt-1 mb-3" style="margin-left: 86%;">
                                                        <button type="button" class="btn btn-secondary submit-btn ml-2"
                                                            (click)="sendToInvoive()">Go To Invoice
                                                        </button>
                        
                                                    </div> -->

                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- </div>
    </div> -->
</div>

<div class="modal fade" id="updateAssignmentPopup" tabindex="-1" role="dialog" aria-labelledby="updateAssignmentPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Update WorkOrder</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li>
                                    <a>Update WorkOrder</a>
                                </li>
                            </ul>

                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">

                                     <form class="w-100" [formGroup]="editAssignmentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Appointment Type</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="AppointmentTypeArray"
                                                            optionlabel="label" placeholder="Scheduled"
                                                            formControlName="appointmentType">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Arrival From</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="promisedArrivalFrom"
                                                            class="form-control">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Arrival To</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="promisedArrivalTo"
                                                            class="form-control">


                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Priority</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" [options]="FsmPrioritiesIdArray"
                                                            optionlabel="label" placeholder="Non-Urgent"
                                                            formControlName="priority">
                                                        </p-dropdown>

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Skill</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="FsmSkillIdArray" placeholder="Add skill"
                                                            formControlName="skill">
                                                        </p-dropdown>

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Technician Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <!-- <p-dropdown filter="true" optionlabel="label"
                                                            [options]="ExpertiseAreaByTechnicianIdArray"
                                                            placeholder="technicianName"
                                                            formControlName="technicianName">
                                                        </p-dropdown> -->
                                                        <input type="date" class="form-control" formControlName="technicianName">

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">ETA</label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="date" class="form-control" formControlName="eta">

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Due By</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="dueBy" class="form-control">


                                                    </div>




                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Estimated Duration</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control"
                                                            formControlName="estimatedDuration">


                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Address 1</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="address1" class="form-control">


                                                    </div>





                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Address 2</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="address2"
                                                        class="form-control">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">City</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="city"
                                                            class="form-control">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Country Geo Id</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="countryGeoId"
                                                        class="form-control">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Customer Id</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="customerId"
                                                            class="form-control">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Customer Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="customerName"
                                                        class="form-control">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Expertise Area</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="expertiseArea"
                                                            class="form-control">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Postal Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="postalCode"
                                                        class="form-control">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">Problem Id</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="problemId"
                                                            class="form-control">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Problem Status</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="problemStatus"
                                                        class="form-control">
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormC">
                                                        <label for="exampleInputEmail1">State ProvinceGeo Id</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="stateProvinceGeoId"
                                                            class="form-control">

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormB">
                                                        <label for="exampleInputEmail1">Technician Availability</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="technicianAvailability"
                                                        class="form-control">
                                                    </div>

                                                   
                                                </div>
                                            </div>

                                          


                                            <div class="col-lg-5 main-submit-button" style="margin-left: -5%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="updateFsmAssignment()">Update</button>

                                            </div>
                                        </div>
                                    </form>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>