import { AfterViewInit,ViewChild, Component, OnInit } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { Router } from '@angular/router';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as am4plugins_forceDirected from "@amcharts/amcharts4/plugins/forceDirected";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import { DashboardService } from 'src/app/services/dashboard.service';



import {
  ApexNonAxisChartSeries,


} from "ng-apexcharts";

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexPlotOptions,
  ApexFill,
  ApexYAxis,
  ApexTitleSubtitle,
  ApexLegend
} from "ng-apexcharts";



export type ChartOptionsWarehouse = {
  series: ApexAxisChartSeries;
  labels: string[];
  chartWarehouse: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  titles: ApexTitleSubtitle;
};




@Component({
  selector: 'app-warehouse-dashboard',
  templateUrl: './warehouse-dashboard.component.html',
  styleUrls: ['./warehouse-dashboard.component.css']
})

export class WarehouseDashboardComponent implements OnInit, AfterViewInit {
  chartValue: any[];
  activeCategory=2;

  /////////------NEW CODE------////////////
  @ViewChild("chart") chartWarehouse: ChartComponent;
  public ChartOptionsWarehouse: Partial<ChartOptionsWarehouse>;
  ChartOptionsWarehouseData: any;
  chartWarehouseLabel: any[]=[];
  constructor(
    private _DashboardService: DashboardService
  ) {
  //  this.chartValue = [];


  this.ChartOptionsWarehouse = {
    series: [
      {
        name: "Warehouse",
        data: []
      }
    ],
    chartWarehouse: {
      height: 350,
      type: "bar"
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top" // top, center, bottom
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + "%";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"]
      }
    },
   
    xaxis: {
      categories:  [],
      position: "top",
      labels: {    },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5
          }
        }
      },
      tooltip: {
        enabled: true,
        offsetY: -35
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100]
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false,
        formatter: function (val) {
          return val + "%";
        }
      }
    },
    titles: {
      text: "Total Warehouse Today",
      floating: true,
      offsetY: 320,
      align: "center",
      style: {
        color: "#444"
      }
    }
  };


}



  ngOnInit(): void { 

    this.getproductInventoryDetailsChart();
  }
  getproductInventoryDetailsChart() {
 
    this._DashboardService.getproductInventoryDetailsChart().subscribe((res: any) => {

      this.ChartOptionsWarehouseData = res.data.value;
      this.chartWarehouseLabel =  res.data.label;
      console.log(this.chartWarehouseLabel,"  this.ChartOptionsWarehouse.xaxis.categories")
      this.ChartOptionsWarehouse = {
        series: [
          {
            name: res.data.label,
            data:  res.data.value
          }
        ],
        chartWarehouse: {
          height: 350,
          type: "bar"
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top" // top, center, bottom
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"]
          }
        },
       
        xaxis: {
          categories: '',
          position: "top",
          labels: {    },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#D8E3F0",
                colorTo: "#BED1E6",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          },
          tooltip: {
            enabled: true,
            offsetY: -35
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100]
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "%";
            }
          }
        },
        titles: {
          text: "Total Warehouse Today",
          floating: true,
          offsetY: 320,
          align: "center",
          style: {
            color: "#444"
          }
        }
      };

    })
  }
  ngAfterViewInit(): void {


  }
 
  // ngAfterViewInit(): void {
  //   this._DashboardService.productInventoryDetails()
  //     .then(data => {
      
  //       if (data.data) {
  //         // Themes begin
  //         am4core.useTheme(am4themes_animated);
  //         // Themes end
  //         let chart = am4core.create("chartdiv", am4plugins_forceDirected.ForceDirectedTree);
  //         let networkSeries = chart.series.push(new am4plugins_forceDirected.ForceDirectedSeries())

  //         Object.keys(data.data.inventoryByFacility).forEach(value => {
  //           this.chartValue.push({
  //             name: value,
  //             children: [
  //               ...data.data.inventoryByFacility[value].map(inventory => {
  //                 return {
  //                   name: inventory.productId,
  //                   value: `ATP :${inventory.availableToPromiseTotal ? inventory.availableToPromiseTotal : 0}/QOH:${inventory.quantityOnHandTotal ? inventory.quantityOnHandTotal : 0}`
  //                 }
  //               }), ...data.data.facilityItemCount[value].map(facility => {
  //                 return {
  //                   name: 'Total Products',
  //                   value: facility.totalProducts
  //                 };
  //               })
  //             ]
  //           })
  //         });
  //         chart.data = [...this.chartValue, ...[{
  //           name: data.data.topSellingProduct.productId,
  //           value: `Total Product: ${data.data.topSellingProduct.quantity}`
  //         }]];

  //         networkSeries.dataFields.value = "value";
  //         networkSeries.dataFields.name = "name";
  //         networkSeries.dataFields.children = "children";
  //         networkSeries.nodes.template.tooltipText = "{name}:{value}";
  //         networkSeries.nodes.template.fillOpacity = 1;

  //         networkSeries.nodes.template.label.text = "{name}"
  //         networkSeries.fontSize = 10;

  //         networkSeries.links.template.strokeWidth = 1;

  //         let hoverState = networkSeries.links.template.states.create("hover");
  //         hoverState.properties.strokeWidth = 3;
  //         hoverState.properties.strokeOpacity = 1;

  //         networkSeries.nodes.template.events.on("over", function (event) {
  //           event.target.dataItem.childLinks.each(function (link) {
  //             link.isHover = true;
  //           })
  //           if (event.target.dataItem.parentLink) {
  //             event.target.dataItem.parentLink.isHover = true;
  //           }

  //         })

  //         networkSeries.nodes.template.events.on("out", function (event) {
  //           event.target.dataItem.childLinks.each(function (link) {
  //             link.isHover = false;
  //           })
  //           if (event.target.dataItem.parentLink) {
  //             event.target.dataItem.parentLink.isHover = false;
  //           }
  //         })
  //       }
  //     });
  // }


}