import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService} from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';    
import {BehaviourSubjectService} from "../../behaviour-subject.service";
import Swal from 'sweetalert2'
import { DeparmentsService } from '../departments/departments.service';
import { CreateDeparmentsService } from '../create-departments/create-departments.service';
import { CreateDepartmentFormService } from '../create-departments/create-departments-service';
import { AddLeaveEmpService } from '../leave/add-new-leaves/add-new-leaves.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-department-summary',
  templateUrl: './detail-department-summary.component.html',
  styleUrls: ['./detail-department-summary.component.css']
})
export class DetailDepartmentSummaryComponent implements OnInit {
  deptId: any;
  pageNo = 1;
  pageSize = 10;
  activeCategory=1;
  @ViewChild('closebutton') closebutton;
  readonly employessSize={
    pageNo:this.pageNo,
    pageSize:this.pageSize,
  
  }
  detailData: any;
  partyId: any;
  partyIdArray: any[]=[];
  companyName: any;
  array: any[]=[];
  departmentList: any;
  departmentArray: any[]=[];
  show: boolean;
  showError: any;
  companyData: any;
  companyArray: any[]=[];
  constructor(
    readonly accountsService: AccountsService,
    readonly router: Router,@Inject(DOCUMENT) readonly document: Document,
      private _location: Location,
      readonly toastr:ToastrService,
    readonly deparmentsService:DeparmentsService,
    readonly spinner:NgxSpinnerService,
    readonly activatedRoute:ActivatedRoute,
    readonly createDeparmentsService:CreateDeparmentsService,
    public createDepartmentFormService:CreateDepartmentFormService,
    readonly behaviourSubject:BehaviourSubjectService,
    readonly addLeaveEmpService: AddLeaveEmpService,
  ) { }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
   
    this.getAllAccount();
    this.getdepartmentsList();
   this.activatedRoute.queryParams.subscribe(params=> {
     this.deptId=params["updateId"];
     if( this.deptId) {
       this.show=true;
       this.getdepartmentsListById();
     }
     else {
       this.show=false;
     }
   })
  
   this.createDepartmentFormService.job_requistion_form=null;
   this.createDepartmentFormService.jobRequistionForm(this.createDepartmentFormService.job_requistion_form)
 this.getCompanyName();
 this.getPartyId();
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
 
  getPartyId() {
    this.spinner.show();
    this.addLeaveEmpService.getPartyId().subscribe(res => {
      this.partyId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.partyId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getCompanyName() {
    this.spinner.show();
    const req={
      "companyId": "",
      "companyIdSearchType": "Contains",
      "departmentId": "",
      "departmentIdSearchType": "Contains",
      "departmentName": "",
      "departmentNameSearchType": "Contains"
    }
    this.createDeparmentsService.getDepartment(this.employessSize,req).subscribe((res:any)=> {
      this.companyName = res.data;
      this.spinner.hide();
      this.companyName.forEach(value=> {
        for(const value of this.companyName){
        this.array.push({
          label:value.parentDepartment,
          value:value.parentDepartmentId
        })
      }
      })
    })
  
  }
 
  getdepartmentsList() {
    this.spinner.show();
    const req={
      "companyId": "",
      "companyIdSearchType": "Contains",
      "departmentId": "",
      "departmentIdSearchType": "Contains",
      "departmentName": "",
      "departmentNameSearchType": "Contains"
    }
     this.createDeparmentsService.getDepartment(this.employessSize,req).subscribe((res:any)=> {
       this.departmentList = res.data;
       this.spinner.hide();
       for(const value of this.departmentList) {
         this.departmentArray.push({
           label:value.departmentName,
           value:value.departmentId
         })
       }
     })
    
      }
      getdepartmentsListById() {
       
        const req={
          "companyId": "",
          "companyIdSearchType": "Contains",
          "departmentId": this.deptId,
          "departmentIdSearchType": "Contains",
          "departmentName": "",
          "departmentNameSearchType": "Contains"
        }
         this.createDeparmentsService.getDepartment(this.employessSize,req).subscribe((res:any)=> {
          this.spinner.show();
      this.detailData=res.data[0];
          setTimeout(() => {
            const formValue = this.createDepartmentFormService.job_requistion_form;
            formValue.patchValue({
              departmentName:res.data[0].departmentName,
              active:res.data[0].active,
              parentDepartmentId:res.data[0].parentDepartmentId,
              companyNameId:res.data[0].partyId,
            
            })
            this.spinner.hide();
          }, 3000);
         
            })
           
          }
  // getdepartmentsListById() {
  //   this.spinner.show();
  //   const req={
  //     "companyId": "",
  //     "companyIdSearchType": "Contains",
  //     "departmentId": this.deptId,
  //     "departmentIdSearchType": "Contains",
  //     "departmentName": "",
  //     "departmentNameSearchType": "Contains"
  //   }
  //    this.createDeparmentsService.getDepartment(this.employessSize,req).subscribe((res:any)=> {
    
  //    this.detailData=res.data[0];
  //    this.spinner.hide();
     
  //       })
       
  //     }

  onUpdate() {
    this.spinner.show();
    const formData = {...this.createDepartmentFormService.job_requistion_form.value};
    this.createDeparmentsService.updateDept(formData,this.deptId).subscribe(res=> {
      if(res.success) {
        this.toastr.success("Form Updated Successfully");
        this.spinner.hide();
        this.router.navigate(['/hr/departments'])
        this.closebutton.nativeElement.click();
      }
      else if(res.success == false) {
        this.spinner.hide();
        this.toastr.error("error");
      }
    },(err) => {
      this.spinner.hide();
      this.showError=(err.error.errors[0])
      this.toastr.error(this.showError.fieldName + " " +this.showError.fieldError)

   })
 
  }
  getAllAccount(){
    this.spinner.show();
    const req={
      "contactMechTypeId": "",
      "groupName": "",
      "groupNameSearchType": "Contains",
      "partyId": "",
      "partyIdSearchType": "Contains"
    }
    let all = "true"
    this.createDeparmentsService.getAllAccount(this.employessSize,req,all).subscribe((res:any)=> {
      this.companyData = res.data;
      this.spinner.hide();
      for(const value of this.companyData) {
        this.companyArray.push({
          label:value.groupName,
          value:value.partyId
        })
      }
    })
   
  }
      ngOnDestroy(): void {
        this.spinner.hide();
          }
}
