import { Component, OnInit } from '@angular/core';
import { MyContactsService } from "../../crm/contacts/my-contacts/my-contacts.service";
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {
  noteName: any;
  textArea: any;
  partyId: any;
  params: string;
  activeCategory=1;
  noteId:any;
  partyIdForCreate:any;
  contactName:any;
  contactPartyId:any;
  noteNameForUpdate:any; 
  noteForUpdate:any;
  
  showUpdateBtn=false;
  showCreateBtn=false;

  constructor(readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly formBuilder: FormBuilder, readonly activatedRoute: ActivatedRoute) { }
  fileUploadForm: FormGroup;

  ngOnInit(): void {
    let contactPartyIdLocal;
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate= params["contactIdd"];
      if(this.partyIdForCreate)  {
        contactPartyIdLocal=this.partyIdForCreate;
      }
      this.contactName= params["contactName"];
      if(this.partyIdForCreate){
        this.showCreateBtn=true;   
      }
      })
    this.activatedRoute.queryParams.subscribe(params => {
      this.noteId = params["noteId"]; 
      this.partyId = params["contactId"];
      if(this.partyId) {
        contactPartyIdLocal=this.partyId;
      }
      this.contactName= params["contactName"];
      this.noteNameForUpdate=params["noteName"];
      this.noteForUpdate=params["note"];
      if(this.noteId && this.partyId){
        this.showUpdateBtn=true;
      }  });
    this.contactPartyId=contactPartyIdLocal;
    this.fileUploadForm = this.formBuilder.group({
      noteName: [''],note: ['']
    }); }
  navigate() {
    this.router.navigate(["/crm/my-contacts/details-contact"], { queryParams: { id: this.partyId  } });
  }

  navigateFromCreate(){
    this.router.navigate(["/crm/my-contacts/details-contact"], { queryParams: { id: this.partyIdForCreate  } });
  }
  
  onSubmit() {
    this.spinner.show();
    const form = { ...this.fileUploadForm.value }
    this.myContactsService.createContactNote(form,this.partyIdForCreate).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/crm/my-contacts/details-contact'], { queryParams: { id:this.partyIdForCreate } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
  }

  onUpdate() {
    this.spinner.show();
    const form = { ...this.fileUploadForm.value}
    this.myContactsService.updateContactNote(form,this.noteId).subscribe(res => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.router.navigate(['/crm/my-contacts/details-contact'], { queryParams: { id: this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    }
    )
    this.spinner.hide();
  }
  changeactiveCategoryFunction(tab: number){
    this.activeCategory =tab;
  }
}
