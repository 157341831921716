import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';

@Component({
  selector: 'app-edit-compdoc-page',
  templateUrl: './edit-compdoc-page.component.html',
  styleUrls: ['./edit-compdoc-page.component.css']
})
export class EditCompdocPageComponent implements OnInit {
  activeCategory=2;

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;

  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  updateCompocTemlateForm: FormGroup;
  createNewInstancesTemlateForm: FormGroup;
  surveyIdValue=true;
  show: boolean;
  contentId: any;
  contentRevisionSeqId: any;
  contentIdDocIns: any;
  contentRevisionSeqIdIns: any;
  ViewCompDocTemplateTreeAll: any;
  updateRootCompDocTemplatePat: any;
  compDoc: any;
  instanceBtn=false;
  contentIdUp: any;
  contentRevisionSeqIdUp: any;



  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    readonly _ActivatedRoute:ActivatedRoute,
    private _location: Location,
  )
  {
    this.updateCompocTemlateForm=this._FormBuilder.group({
      contentID:"",
      contentName:"",
    })

    this.createNewInstancesTemlateForm=this._FormBuilder.group({
      contentName:"",
    })

  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.contentId = params["contentId"];
      this.contentRevisionSeqId = params["contentRevisionSeqId"];
      if(params["compDoc"]=="created"){
        this.compDoc = params["compDoc"];
        this.instanceBtn=true;
      }

    });
   
    this.show=true;
    this.ViewCompDocTemplateTreeNew();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  ViewCompDocTemplateTreeNew() {
    this.spinner.show();
    this.accountsService.ViewCompDocTemplateTree(this.contentId).subscribe(res => {
      this.ViewCompDocTemplateTreeAll = res.data[0];
      this.spinner.hide();
    })
  }
  restForm(){
    this.show=true;
    this.createNewInstancesTemlateForm.reset();
  }
  genCompDocInstance(): void {
    this.spinner.show();
    const requestData ={
      "contentName": this.createNewInstancesTemlateForm.value.contentName,
      "contentTypeId": "COMPDOC_INSTANCE",
      "instanceOfContentId": this.contentId,
      }
     
    this.accountsService.genCompDocInstance(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.contentIdDocIns=res.data.contentId;
        this.contentRevisionSeqIdIns=res.data.contentRevisionSeqId;
        this.instanceBtn=false;
        this.router.navigate(['/content/compDoc/edit'], { queryParams: { contentId: this.contentIdDocIns , contentRevisionSeqId: this.contentRevisionSeqIdIns } });
        this.spinner.hide();
        setTimeout(() => {
        this.ViewCompDocTemplateTreeNew();
        }, 2000);
        this.createNewInstancesTemlateForm.reset();
        this.closebutton3.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateRootCompDocTemplate(): void {
    this.spinner.show();
    const requestData ={
      "contentId": this.contentId,
      "contentName": this.updateCompocTemlateForm.value.contentName,
      "contentTypeId": "COMPDOC_TEMPLATE"
      }
     
    this.accountsService.updateRootCompDocTemplate(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.contentIdUp=res.data.contentId;
        this.contentRevisionSeqIdUp=res.data.contentRevisionSeqId;
        this.router.navigate(['/content/compDoc/edit'], { queryParams: { contentId: this.contentIdUp , contentRevisionSeqId: this.contentRevisionSeqIdUp , compDoc: "created"  } });
        this.closebutton2.nativeElement.click();
        this.ViewCompDocTemplateTreeNew();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }
  updateRootCompDocTemplatePatch() {
    this.show=false;
    this.spinner.show();
      setTimeout(() => {
        const formValue = this.updateCompocTemlateForm;
        formValue.patchValue({
            contentID:this.ViewCompDocTemplateTreeAll.ListContentRevisions.contentId,
            contentName:this.ViewCompDocTemplateTreeAll.ListContentRevisions.contentName
        })
      }, 2000);
      this.spinner.hide();
  }
 

  openViewTree()
  {
    this._Router.navigate(['/content/compDoc/view-tree'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openEdit()
  {
    this._Router.navigate(['/content/compDoc/edit'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openApprovals()
  {
    this.router.navigate(['/content/compDoc/approvals'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openRevisions()
  {
    this.router.navigate(['/content/compDoc/revisions'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openCreateNewRoot()
  {
    this._Router.navigate(['/content/compDoc/new-root-compdoc-template'], { queryParams: { contentId: this.contentId , contentRevisionSeqId: this.contentRevisionSeqId } });
  }
  openViewWaitingApprovals()
  {
    this._Router.navigate(['/content/compDoc/view-waiting-approvals']);
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

  changeactiveCategory(tab: number)
  {
    this.activeCategory = tab;
  
  }


}
