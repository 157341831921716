import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { PaymentGroupService } from '../accounting-ap/payable-payment-group/payment-group.service';
import Swal from 'sweetalert2'
import { DatePipe } from '@angular/common';
import { SortEvent } from 'primeng/api';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
@Component({
  selector: 'app-detail-organization-gl-summary',
  templateUrl: './detail-organization-gl-summary.component.html',
  styleUrls: ['./detail-organization-gl-summary.component.css']
})
export class DetailOrganizationGlSummaryComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;
  @ViewChild('closebutton11') closebutton11;
  @ViewChild('closebutton12') closebutton12;
  @ViewChild('closebutton13') closebutton13;
  @ViewChild('closebutton14') closebutton14;
  @ViewChild('closebutton15') closebutton15;



  activeCategory=2;
  activeMain=1;
  activeCategoryMain=1;
  products3: any;
  paymentPartyGroup: any;
  downloading: boolean;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  createAccounting:FormGroup;
  chartAccount:any[]=[]
  activeCategoryValue=2;
  refundable: { label: string; value: string; }[];
  partyId: any;
  glChartOfAccount: any;
  getJournalDeatils: any;
  getGlAccount: any;
  refundPaymentMethod: any;
  refundPaymentMethodArray: any[]=[];
  taxFormOrgId: any;
  taxFormOrgIdArray: any[]=[];
  costOfGoodsSold: any;
  costOfGoodsSoldArray: any[]=[];
  errorGlJournal: any;
  errorGlJournalArray: any[]=[];
  oldInvoiceSequenceEnumId: any;
  oldInvoiceSequenceEnumIdArray: any[]=[];
  fiscalYearStartMonth: any;
  fiscalYearStartMonthArray: any[]=[];
  oldQuoteSequenceEnumId: any;
  oldQuoteSequenceEnumIdArray: any[]=[];
  oldOrderSequenceEnumId: any;
  oldOrderSequenceEnumIdArray: any[]=[];
  fiscalYearStartDay: any;
  currencyArray: any[]=[];
  show: any;
  form: boolean;
  formValue: boolean;
  actualfromdate: any;
  fiscalDay: {}[];
  getEstimateFrom: string;
  docTypeTemplate: any;
  actualdate: string;
  productGlAccount: any;
  productGlAccountCategory: any;
  financialAccountType: any;
  saleInvoice: any;
  paymentType: any;
  paymentMethodType: any;
  VarianceReasonGlAccounts: any;
  CreditCardTypeGlAccounts: any;
  TaxAuthorityGlAccount: any;
  PartyGlAccount: any;
  AssetTypeGlAccount: any;
  OpenCustomTimePeriodId: any;
  CloseCustomTimePeriodId: any;
  invoiceItemTypeIdPurchase: any;
  depGlAccountId: any;
  depGlAccountIdArray: any;
  fixedAssetTypeId: any;
  fixedAssetTypeIdArray: any;
  profitGlAccountId: any;
  profitGlAccountIdArray: any;
  fixedAssetId: any;
  fixedAssetIdArray: any;
  lossGlAccountId: any;
  lossGlAccountIdArray: any;
  accDepGlAccountId: any;
  accDepGlAccountIdArray: any;
  assetGlAccountId: any;
  assetGlAccountIdArray: any;
  id: string;
  createGlobalSettingForm:FormGroup;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  glAccountType: any;
  glAccountTypeArray: any[]=[];
  InvoiceDropdown=false;
  QuoteDropdowm=false;
  OrderDropdown=false;
  createDoc:FormGroup;
  customerScreen: {}[];
  dropdownValue: { label: string; value: string; }[];
  labelValue: string;
  doctype: any;
  typeId: any;
  typeIdArray: any[]=[];
  quoteTypeId: any;
  quoteTypeIdArray: any[]=[];
  typeIdOrder: any;
  typeIdArrayOrder: any[]=[];
  showNew: boolean;
  createJournal1:FormGroup;
  showNew1=false;
  createAccountDefault:FormGroup;
  glChartOfAccountArray: any[]=[];
  createProduct:FormGroup;
  showNew2=false;
  showNew3:boolean;
  createProduct1:FormGroup;
  search1: {
    categoryId: string,
    categoryIdSearchType: string,
    categoryName: string,
    categoryNameSearchType: string
  };
  categorySize: { pageNo: number; pageSize: number; };
  categoryList: any;
  categoryListArray: any[]=[];
  productListArray: any[]=[];
  createFin:FormGroup;
  finAccountTypeId: any;
  finAccountTypeIdArray: any[]=[];
  showNew6:boolean;
  createSale:FormGroup;
  idPur: string;
  glAccountId: any;
  glAccountIdArray: any[]=[];
  invoiceItemTypeId: any;
  invoiceItemTypeIdArray: any[]=[];
  createPurchase:FormGroup;
  createPayment:FormGroup;
  paymentTypeId: any;
  glAccountTypeId: any;
  paymentTypeIdArray: any[]=[];
  glAccountTypeIdArray: any[]=[];
  createPaymentNew1:FormGroup;
  getPaymentMethodType: any;
  getPaymentMethodTypeArray: any[]=[];
  createVaraince:FormGroup;
  showNew4:boolean;
  varianceReasonId: any;
  varianceReasonIdArray: any[]=[];
  createTax:FormGroup;
  showNew5: boolean;
  taxAuthorityGlAccountormData: any;
  taxAuthorityGlAccountorm: any;
  taxAuthorityGlAccountormArray: any[]=[];
  taxAuthorityGlAccountormDataArray: any[]=[];
  createParty:FormGroup;
  personId: any;
  partyIdArray: any[]=[];
  allrole: any;
  allroleArray: any[]=[];
  roleId: any;
  partyIdData: any;
  roleIdArray: any[]=[];
  partyIdDataArray: any[]=[];
  showNew7:boolean;
  createFixedAssest21:FormGroup;
  assetGlAccountIdArrayNew: any[]=[];
  accDepGlAccountIdArrayNew: any[]=[];
  lossGlAccountIdArrayNew: any[]=[];
  fixedAssetIdArrayNew: any[]=[];
  profitGlAccountIdArrayNew: any[]=[];
  fixedAssetTypeIdArrayNew: any[]=[];
  depGlAccountIdArrayNew: any[]=[];
  createTimePeriodNew12: FormGroup;
  closed = [{
    label: 'Y',
    value: 'Y',
  },
  {
    label: 'N',
    value: 'N',
  }]
  DataTimePeriod: any;
  parentPeriodId: any;
  DataTimePeriodArray: any[]=[];
  parentPeriodIdArray: any[]=[];
  actualthroughdate: string;
  creditPath: any;


  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly router: Router,
    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
    readonly _MainCatalogService: MainCatalogService,
    readonly addSkillService: AddSkillService,

    ) { 
      this.createTimePeriodNew12 = this._FormBuilder.group({
        fromDate: [''],
        isClosed: [''],
        parentPeriodId: [''],
        periodName: [''],
        periodNum: [''],
        periodTypeId: [''],
        thruDate: [''],
  
      });

      this.createFixedAssest21 = this._FormBuilder.group({
        assestGlAccount: [''],
        accumulated:[''],
        depreciation: [''],
        profit:[''],
        loss:[''],
        fixedAssestTypeId: [''],
        asssestId:['']
       
        
      });
      this.createParty = this._FormBuilder.group({
        partyId: [''],
        roleType:[''],
        glAccountType: [''],
        glAccountId:['']
       
        
      });
    
      this.createTax = this._FormBuilder.group({
        taxAuthParty: [''],
        taxAuth: [''],
        glAccountType:['']
       
        
      });

      this.createVaraince = this._FormBuilder.group({
        varainceType: [''],
        glAccountType:['']
       
        
      });
      this.createPaymentNew1 = this._FormBuilder.group({
        paymentType: [''],
        glAccountType:['']
       
        
      });

      this.createPayment = this._FormBuilder.group({
        paymentType: [''],
        glAccountType:['']
       
        
      });

      this.createPurchase = this._FormBuilder.group({
        invoiceItemType: [''],
        overrideRevenue:[''],
      
       
        
      });
      this.createSale = this._FormBuilder.group({
        invoiceItemType: [''],
        overrideRevenue:[''],
      
       
        
      });

      this.createFin = this._FormBuilder.group({
        finType: [''],
        accountId:[''],
       
       
        
      });
      this.categorySize = {
        pageNo: this.pageNo,
        pageSize: this.pageSize
      };
      this.search1 = {
        categoryId: '',
        categoryIdSearchType: 'Contains',
        categoryName: '',
        categoryNameSearchType: 'Contains'
      };
      this.createProduct1 = this._FormBuilder.group({
        accountType: [''],
        accountId:[''],
        productId:['']
       
        
      });

      this.createProduct = this._FormBuilder.group({
        accountType: [''],
        accountId:[''],
        productId:['']
       
        
      });

      this.createAccountDefault = this._FormBuilder.group({
        glAccountType: [''],
        glAccountId:['']
       
        
      });

      this.createJournal1 = this._FormBuilder.group({
        journalName: [''],
       
        
      });

      this.customerScreen=[{
        label: "Default Screen",
        value: ""
      }]
      this.dropdownValue = [{
        label: "Template for invoice",
        value: "InvoiceType"
      },
      {
        label: "Template for quote",
        value: "QuoteType"
      },
      {
        label: "Template for order",
        value: "OrderType"
      }]

      this.createDoc = this._FormBuilder.group({
        documentType: [''],
        customScreenId:[''],
        fromDate:[''],
        quoteTypeId:[''],
        orderTypeId:[''],
        invoiceTypeId:['']
       
        
      });

      this.createGlobalSettingForm = this._FormBuilder.group({
        glAccountId: [''],
        date: [''],
        
      });

      this.fiscalDay=[{
        label: "1",
        value: "1"
      },{
        label: "2",
        value: "2"
      },{
        label: "3",
        value: "3"
      },{
        label: "4",
        value: "4"
      },{
        label: "5",
        value: "5"
      },{
        label: "6",
        value: "6"
      },{
        label: "7",
        value: "7"
      },{
        label: "8",
        value: ""
      },{
        label: "9",
        value: "9"
      },{
        label: "10",
        value: "10"
      },{
        label: "11",
        value: "11"
      },{
        label: "12",
        value: "12"
      },{
        label: "13",
        value: "13"
      },{
        label: "14",
        value: "14"
      },{
        label: "15",
        value: "15"
      },{
        label: "16",
        value: "16"
      },{
        label: "17",
        value: "17"
      },{
        label: "18",
        value: "18"
      },{
        label: "19",
        value: "19"
      },{
        label: "20",
        value: "20"
      },{
        label: "21",
        value: "21"
      },{
        label: "22",
        value: "22"
      },{
        label: "23",
        value: "23"
      },{
        label: "24",
        value: "24"
      },{
        label: "25",
        value: "25"
      },{
        label: "26",
        value: "26"
      },{
        label: "27",
        value: "27"
      },{
        label: "28",
        value: "28"
      },{
        label: "29",
        value: "29"
      },{
        label: "30",
        value: "30"
      },{
        label: "31",
        value: "31"
      },]
      this.refundable = [{
        label: "Y",
        value: "Y"
      },
      {
        label: "N",
        value: "N"
      }]
    this.createAccounting = this._FormBuilder.group({
      organizationPartyId: [''],
      fiscalYearStart:[''],
      fiscalDay:[''],
      taxForm:[''],
      soldCOGS:[''],
      baseCurrency:[''],
      invoiceSeq:[''],
      invoiceIDPrefix:[''],
      invoiceNumber:[''],
      invoiceRestartDate:[''],
      useInvoice:[''],
      quoteSeqCust:[''],
      quotePrefix:[''],
      lastQuote:[''],
      orderSeqCust:[''],
      orderIdPrefix:[''],
      lastOrderNumber:[''],
      refundPayment:[''],
      errorGl:[''],
      enableAccounting:[''],
      oldInvoiceSequence:[''],
      oldOrderSeqEnumId:[''],
      oldQuoteSeq:['']


      
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
   

    this.getGlAccountInitialData();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
      this.show=params["show"];
      this.creditPath=params["credit"];
      if(this.creditPath=="Created"){
        this.activeCategory=8;
        this.activeCategoryValue=11;

      }
      if(this.creditPath=="Updated"){
        this.activeCategory=8;
        this.activeCategoryValue=11;
      }

    });
    this.getChartAc();this.getJournalData();
    this.getListGlAccountDefault();
    this.getAccountCompanyInitialData();this.getCurrency();
    this.getAllDocTypeTemp();
    this.getProductGlAccount();
    this.getProductCategoryGlAccountData();
    this.getFinAccountTypeGi();
    this.getGlAccountSalInv();
    this.getpaymentTypeDetail();
    this.getGlAccountMethodTypePaymentType();
    this.getVarianceReasonGlAccounts();
    this.getCreditCardTypeGlAccounts();
    this.getTaxAuthorityGlAccount();
    this.getPartyGlAccount();
    this.fixedAssetTypeGlAccount();
    this.allOpenCustomTimePeriodId();
    this.allCloseCustomTimePeriodId();
    this.getSalPurchaseItemTypeGlAssignment(); 
    if(this.show){
      this.formValue=true;
    }else{
      this.formValue=false;
      this.getCompanyDetails();
    }
    this.showNew=true;

    this.getInitialData1();
    this.getGlAccountInitialData1();
    this.getChartAc1();

    this.getGlAccountInitialData2();
    this.getInitialData2();
    this.getProducts2();
    this.getChartAc2();


    this.getCategoryList3();
    this.getChartAc3();
    this.getGlAccountInitialData3();
    this.getInitialData3();

    this.getGlAccountInitialData4();
    this.getFinAccountTypeGiAccountInitial4();
    this.getChartAc4();

    this.getInvoiceItemTypeListApiData();
    this.getInvoiceItemTypeInitialData();

    this.getInvoiceItemTypeListApiData1();
    this.getInvoiceItemTypeInitialData1();

    this.getGlAccountInitialData5();
    this.getGlAccountIni5();

    this.getGlAccountInitialData6();
    this.getPaymentMethodTypeGlSetting6();
    this.getChartAc6();

    this.getGlAccountInitialData7();
    this.getChartAc7();
    this.getVarianceReasonGlAccountsInitialData7();

    this.getGlAccountInitialData8();
    this.taxAuthorityGlAccountInitialFormData8();
    this.getChartAc8();
    this.showNew5=false;

    this.getPartyId9();
    this.getGlAccountInitialData9();
    this.getInitialData9();
    this.allPartyGlAccountFormData9();
    this.getRoleAllType9();
    this.getChartAc9();
    this.showNew7=false;

    this.getInitialDataFixedData10();

    this.initialDataTimePeriod();


  }
  initialDataTimePeriod() {
    this.spinner.show();
    this.accountsService.initialDataTimePeriod().subscribe((res: any) => {

      this.DataTimePeriod = res.data.periodType;
      this.parentPeriodId = res.data.parentPeriodId;
      this.spinner.hide();
      for (const value of this.DataTimePeriod) {
        this.DataTimePeriodArray.push({
          label: value.description,
          value: value.periodTypeId
        })
      }

      for (const value of this.parentPeriodId) {
        this.parentPeriodIdArray.push({
          label:value.customTimePeriodId,
          value: value.customTimePeriodId
        })
      }
    })
   
  }
  
  onSubmitNew15() {
    this.spinner.show();
    const fromActualdate = this.createTimePeriodNew12.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.createTimePeriodNew12.get('thruDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate": this.actualfromdate,
      "isClosed": this.createTimePeriodNew12.value.isClosed,
      "organizationPartyId": this.partyId,
      "parentPeriodId": this.createTimePeriodNew12.value.parentPeriodId,
      "periodName":  this.createTimePeriodNew12.value.periodName,
      "periodNum": this.createTimePeriodNew12.value.periodNum,
      "periodTypeId":this.createTimePeriodNew12.value.periodTypeId,
      "thruDate": this.actualthroughdate
    }
    this.accountsService.createCustomTimePeriods(requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Created Successfully");
        this.allOpenCustomTimePeriodId();
        this.allCloseCustomTimePeriodId();
        this.createTimePeriodNew12.reset();
        this.closebutton15.nativeElement.click();

        // this.router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  onSubmitNew14() {
    this.spinner.show();
    const requestData = {
      "accDepGlAccountId": this.createFixedAssest21.value.accumulated,
      "assetGlAccountId": this.createFixedAssest21.value.assestGlAccount,
      "depGlAccountId": this.createFixedAssest21.value.depreciation,
      "fixedAssetId": this.createFixedAssest21.value.asssestId,
      "fixedAssetTypeId": this.createFixedAssest21.value.fixedAssestTypeId,
      "lossGlAccountId": this.createFixedAssest21.value.loss,
      "profitGlAccountId": this.createFixedAssest21.value.profit,
      "organizationPartyId":  this.partyId,
    }
    this.accountsService.createFixedAssetTypeGlAccounts(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.createFixedAssest21.reset();
        this.closebutton14.nativeElement.click();

        // this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }}
        else{
          this._ToastrService.error(err.error.message);
        }});
  }
  getInitialDataFixedData10() {
    this.spinner.show();
    this.accountsService.getInitialDataFixedData(this.partyId).subscribe((res: any) => {
      this.depGlAccountId = res.data.depGlAccountId;this.fixedAssetTypeId = res.data.fixedAssetTypeId;
      this.profitGlAccountId = res.data.profitGlAccountId;
      this.fixedAssetId = res.data.fixedAssetId;
      this.lossGlAccountId=res.data.lossGlAccountId;
      this.accDepGlAccountId=res.data.accDepGlAccountId;
      this.assetGlAccountId=res.data.assetGlAccountId;
     
      this.spinner.hide();
      for (const value of this.depGlAccountId) {
        this.depGlAccountIdArrayNew.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
      for (const value of this.fixedAssetTypeId) {
        this.fixedAssetTypeIdArrayNew.push({
          label: value.description,
          value: value.fixedAssetTypeId
        })
      }
      for (const value of this.profitGlAccountId) {
        this.profitGlAccountIdArrayNew.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
      for (const value of this.fixedAssetId) {
        this.fixedAssetIdArrayNew.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
      for (const value of this.lossGlAccountId) {
        this.lossGlAccountIdArrayNew.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
      for (const value of this.accDepGlAccountId) {
        this.accDepGlAccountIdArrayNew.push({
          label: value.description,
          value: value.enumId
        })
      }
      for (const value of this.assetGlAccountId) {
        this.assetGlAccountIdArrayNew.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
    })
  }
  
  getChartAc9() {
    this.spinner.show();
    this.accountsService.getOrganizationMainChartOfAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.glChartOfAccount = res.data;
      this.spinner.hide();
      for (const value of this.glChartOfAccount) {
        this.glChartOfAccountArray.push({
          label: value.accountName+"["+value.glAccountId+"]",
          value: value.glAccountId
        })
      }
    })
  
  }
  getPartyId9() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  getGlAccountInitialData9() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }





    })
   
  }
  getRoleAllType9(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
  allPartyGlAccountFormData9() {
    this.spinner.show();
    this.accountsService.allPartyGlAccountFormData().subscribe(res => {
      this.roleId = res.data.roleTypeIdWithPartyId;
      this.partyIdData = res.data.roleTypeIdWithPartyId;
     
      this.spinner.hide();
    
      for (const value of this.roleId) {
        this.roleIdArray.push({
          label: value.roleTypeId,
          value: value.roleTypeId
        })
      }
      for (const value of this.partyIdData) {
        this.partyIdDataArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  getInitialData9() {
    this.spinner.show();
    this.accountsService.getInitialDataAccountTypeDefault().subscribe(res => {
      this.glAccountType = res.data.glAccountTypeList;
     
      this.spinner.hide();
    
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }
    })
   
  }
  
  onSubmitNew13() {
    this.spinner.show();
    const requestData = {
      "glAccountId":this.createParty.value.glAccountId,
      "glAccountTypeId": this.createParty.value.glAccountType,
      "partyId": this.createParty.value.partyId,
      "roleTypeId": this.createParty.value.roleType,
      "organizationPartyId":  this.partyId,
    }
    this.accountsService.createPartyGlAccounts(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.getPartyGlAccount();
        this.createParty.reset();
        this.closebutton13.nativeElement.click();

        // this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  getChartAc8() {
    this.spinner.show();
    this.accountsService.getOrganizationMainChartOfAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.glChartOfAccount = res.data;
      this.spinner.hide();
      for (const value of this.glChartOfAccount) {
        this.glChartOfAccountArray.push({
          label: value.accountName+"["+value.glAccountId+"]",
          value: value.glAccountId
        })
      }
    })
  
  }
  taxAuthorityGlAccountInitialFormData8() {
    this.spinner.show();
    this.accountsService.taxAuthorityGlAccountInitialFormData(this.finSize).subscribe((res: any) => {

      this.taxAuthorityGlAccountormData = res.data;
      this.taxAuthorityGlAccountorm = res.data;
      this.spinner.hide();
     
      for (const value of this.taxAuthorityGlAccountorm) {
        this.taxAuthorityGlAccountormArray.push({
          label: value.taxAuthGeoIdWithCountryName,
          value: value.taxAuthGeoId
        })
      }

    })
   
  }
  taxAuthorityGlAccountInitialFormDataParty() {
    this.spinner.show();
    this.accountsService.taxAuthorityGlAccountInitialFormData(this.finSize).subscribe((res: any) => {

      this.taxAuthorityGlAccountormData = res.data;
      this.taxAuthorityGlAccountorm = res.data;
      this.spinner.hide();
      
      for (const value of this.taxAuthorityGlAccountormData) {
        if(this.createTax.value.taxAuth===value.taxAuthGeoId){
        this.taxAuthorityGlAccountormDataArray.push({
          label: value.taxAuthPartyIdWithDescription,
          value: value.taxAuthPartyId
        })
      }}
    

    })
   
  }
  getGlAccountInitialData8() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }

    })
   
  }
  onSubmitNew12() {
    this.spinner.show();
    const requestData = {
      "glAccountId":this.createTax.value.glAccountType,
      "taxAuthGeoId": this.createTax.value.taxAuth,
      "taxAuthPartyId": this.createTax.value.taxAuthParty,
      "organizationPartyId":  this.partyId,
    }
    this.accountsService.createOrganizationTaxAuthorityGlAccount(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.getTaxAuthorityGlAccount();
        this.createTax.reset();
        this.closebutton12.nativeElement.click();

        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  getChartAc7() {
    this.spinner.show();
    this.accountsService.getOrganizationMainChartOfAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.glChartOfAccount = res.data;
      this.spinner.hide();
      for (const value of this.glChartOfAccount) {
        this.glChartOfAccountArray.push({
          label: value.accountName+"["+value.glAccountId+"]",
          value: value.glAccountId
        })
      }
    })
  
  }
  getGlAccountInitialData7() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
    })
   
  }
  getVarianceReasonGlAccountsInitialData7() {
    this.spinner.show();
    this.accountsService.getVarianceReasonGlAccountsInitialData().subscribe((res: any) => {

      this.varianceReasonId = res.data.varianceReasonId;
      this.spinner.hide();
      for (const value of this.varianceReasonId) {
        this.varianceReasonIdArray.push({
          label: value.description,
          value: value.varianceReasonId
        })
      }
    })
   
  }
  onSubmitNew11() {
    this.spinner.show();
    const requestData = {
      "glAccountId":this.createVaraince.value.glAccountType,
      "varianceReasonId": this.createVaraince.value.varainceType,
      "organizationPartyId":  this.partyId
    }
    this.accountsService.postVarianceReasonGlAccounts(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.getVarianceReasonGlAccounts();
        this.createVaraince.reset();
        this.closebutton11.nativeElement.click();
        // this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  getChartAc6() {
    this.spinner.show();
    this.accountsService.getOrganizationMainChartOfAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.glChartOfAccount = res.data;
      this.spinner.hide();
      for (const value of this.glChartOfAccount) {
        this.glChartOfAccountArray.push({
          label: value.accountName+"["+value.glAccountId+"]",
          value: value.glAccountId
        })
      }
    })
  
  }
  getPaymentMethodTypeGlSetting6() {
    this.spinner.show();
    this.accountsService.getPaymentMethodTypeGlSetting(this.finSize).subscribe((res: any) => {
      this.getPaymentMethodType = res.data;
      this.spinner.hide();
      for (const value of this.getPaymentMethodType) {
        this.getPaymentMethodTypeArray.push({
          label: value.description,
          value: value.paymentMethodTypeId
        })
      }
    })
  
  }
  getGlAccountInitialData6() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }

    })
   
  }
  
  onSubmitNew10() {
    this.spinner.show();
    const requestData = {
      "glAccountId":this.createPaymentNew1.value.glAccountType,
      "paymentMethodTypeId": this.createPaymentNew1.value.paymentType,
      "organizationPartyId":  this.partyId
    }

    this.accountsService.postGlAccountMethodTypePaymentType(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.getGlAccountMethodTypePaymentType();
        this.createPaymentNew1.reset();
        this.closebutton10.nativeElement.click();

        // this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
   
  getGlAccountIni5() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.glAccountType = res.data.glAccountType;
      this.spinner.hide();
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }





    })
   
  }
  
  onSubmitNew9() {
    this.spinner.show();
    const requestData = {
      "glAccountTypeId":this.createPayment.value.glAccountType,
      "paymentTypeId": this.createPayment.value.paymentType,
      "organizationPartyId":  this.partyId,
    }

    this.accountsService.postpaymentType(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.getpaymentTypeDetail();
        this.createPayment.reset();
        this.closebutton9.nativeElement.click();
        // this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getGlAccountInitialData5() {
    this.spinner.show();
    this.accountsService.getpaymentTypeInitialData().subscribe((res: any) => {

      this.paymentTypeId = res.data.paymentTypeId;
      this.glAccountTypeId = res.data.glAccountTypeId;
      this.spinner.hide();
      for (const value of this.paymentTypeId) {
        this.paymentTypeIdArray.push({
          label: value.description,
          value: value.paymentTypeId
        })
      }
      for (const value of this.glAccountTypeId) {
        this.glAccountTypeIdArray.push({
          label: value.description,
          value: value.paymentTypeId
        })
      }





    })
   
  }
  getInvoiceItemTypeInitialData1() {
    this.spinner.show();
    this.idPur='PURCHASE'
    this.accountsService.getInvoiceItemTypeInitialData(this.partyId,this.idPur).subscribe((res: any) => {

      this.glAccountId = res.data;
      this.spinner.hide();
      for (const value of this.glAccountId) {
        this.glAccountIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }





    })
   
  }
  getInvoiceItemTypeListApiData1() {
    this.spinner.show();
    this.id='PURCHASE'
    this.accountsService.getInvoiceItemTypeListApiData(this.id).subscribe((res: any) => {

      this.invoiceItemTypeId = res.data;
      this.spinner.hide();
      for (const value of this.invoiceItemTypeId) {
        this.invoiceItemTypeIdArray.push({
          label: value.description,
          value: value.invoiceItemTypeId
        })
      }
    })
   
  }
  onSubmitNew8() {
    this.spinner.show();
    const requestData = {
     
      "glAccountId":this.createPurchase.value.overrideRevenue,
      "invoiceItemTypeId": this.createPurchase.value.invoiceItemType,
      "organizationPartyId":  this.partyId
    }
    this.accountsService.addPurInvoiceItemTypeGlAssignments(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.getSalPurchaseItemTypeGlAssignment();
        this.spinner.hide();
        this.createPurchase.reset();
        this.closebutton8.nativeElement.click();
        // this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  getInvoiceItemTypeInitialData() {
    this.spinner.show();
    this.idPur='SALES'
    this.accountsService.getInvoiceItemTypeInitialData(this.partyId,this.idPur).subscribe((res: any) => {

      this.glAccountId = res.data;
      this.spinner.hide();
      for (const value of this.glAccountId) {
        this.glAccountIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }





    })
   
  }
  getInvoiceItemTypeListApiData() {
    this.spinner.show();
    this.id='SALES'
    this.accountsService.getInvoiceItemTypeListApiData(this.id).subscribe((res: any) => {

      this.invoiceItemTypeId = res.data;
      this.spinner.hide();
      for (const value of this.invoiceItemTypeId) {
        this.invoiceItemTypeIdArray.push({
          label: value.description,
          value: value.invoiceItemTypeId
        })
      }





    })
   
  }
  redirectToGL(id){
    this.router.navigate(['/financial/gl-account-summary'],{queryParams:{glAccountId : id}});

  }
  onSubmitnew7() {
    this.spinner.show();
    const requestData = {
      "glAccountId":this.createSale.value.overrideRevenue,
      "invoiceItemTypeId": this.createSale.value.invoiceItemType,
      "organizationPartyId":  this.partyId
    }
    this.accountsService.addSalInvoiceItemTypeGlAssignments(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.getGlAccountSalInv();
        this.spinner.hide();
        this.createSale.reset();
        this.closebutton7.nativeElement.click();
        // this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  getChartAc4() {
    this.spinner.show();
    this.accountsService.getOrganizationMainChartOfAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.glChartOfAccount = res.data;
      this.spinner.hide();
      for (const value of this.glChartOfAccount) {
        this.glChartOfAccountArray.push({
          label: value.accountName+"["+value.glAccountId+"]",
          value: value.glAccountId
        })
      }
    })
  
  }
  getFinAccountTypeGiAccountInitial4() {
    this.spinner.show();
    this.accountsService.getFinAccountTypeGiAccountInitialData().subscribe((res: any) => {

      this.finAccountTypeId = res.data.finAccountTypeId;
      this.spinner.hide();
      for (const value of this.finAccountTypeId) {
        this.finAccountTypeIdArray.push({
          label: value.description,
          value: value.finAccountTypeId
        })
      }
    })
   
  }
  getGlAccountInitialData4() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
    })
   
  }
  onSubmitNew6() {
    this.spinner.show();
    const requestData = {
      "glAccountId":this.createFin.value.accountId,
      "finAccountTypeId": this.createFin.value.finType,
      "organizationPartyId":  this.partyId,

    }
    this.accountsService.createFinAccountTypeGiAccount(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.getFinAccountTypeGi();
        this.spinner.hide();
        this.createFin.reset();
        this.closebutton6.nativeElement.click();
        // this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  
  
  getChartAc3() {
    this.spinner.show();
    this.accountsService.getOrganizationMainChartOfAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.glChartOfAccount = res.data;
      this.spinner.hide();
      for (const value of this.glChartOfAccount) {
        this.glChartOfAccountArray.push({
          label: value.accountName+"["+value.glAccountId+"]",
          value: value.glAccountId
        })
      }
    })
  
  }
  getCategoryList3(): void {
    this.spinner.show();
    this._MainCatalogService.getCategories(this.categorySize, this.search1)
      .then(data => {
        this.categoryList = data.data;
        for (const value of this.categoryList) {
          this.categoryListArray.push({
            label: value.productCategoryId,
            value: value.productCategoryId
          })
        }
        this.spinner.hide();
      });
     
  }
  getGlAccountInitialData3() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }





    })
   
  }
  getInitialData3() {
    this.spinner.show();
    this.accountsService.getInitialDataAccountTypeDefault().subscribe(res => {
      this.glAccountType = res.data.glAccountTypeList;
     
      this.spinner.hide();
    
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }
    })
   
  }
  onSubmitNew5() {
    this.spinner.show();
    const requestData = {   
      "glAccountId":this.createProduct1.value.accountId,
      "glAccountTypeId": this.createProduct1.value.accountType,
      "organizationPartyId":  this.partyId,
      "productCategoryId":this.createProduct1.value.productId
    }
    this.accountsService.postProductCategoryGlAccount(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.getProductCategoryGlAccountData();
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.createProduct1.reset();
        // this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  getChartAc2() {
    this.spinner.show();
    this.accountsService.getOrganizationMainChartOfAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.glChartOfAccount = res.data;
      this.spinner.hide();
      for (const value of this.glChartOfAccount) {
        this.glChartOfAccountArray.push({
          label: value.accountName+"["+value.glAccountId+"]",
          value: value.glAccountId
        })
      }
    })
  
  }
  getProducts2(): void {
    this.spinner.show();
    const data = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains",
    };
    this.accountsService.getProductsList(data).subscribe((res) => {
      
      this.spinner.hide();
      this.productListArray = res.data.map(value => {
        return {
          label: value.productId,
          value: value.productId
        };
      });
   
    });
  
  }
  getGlAccountInitialData2() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }





    })
   
  }
  getInitialData2() {
    this.spinner.show();
    this.accountsService.getInitialDataAccountTypeDefault().subscribe(res => {
      this.glAccountType = res.data.glAccountTypeList;
     
      this.spinner.hide();
    
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }
    })
   
  }
  onSubmitNew4() {
    this.spinner.show();
    const requestData = {
      "glAccountId":this.createProduct.value.accountId,
      "glAccountTypeId": this.createProduct.value.accountType,
      "organizationPartyId":  this.partyId,
      "productId":this.createProduct.value.productId
    }
    this.accountsService.postProductGlAccount(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.createProduct.reset();
        this.closebutton4.nativeElement.click();
        // this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  
  getGlAccountInitialData1() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }





    })
   
  }
  getInitialData1() {
    this.spinner.show();
    this.accountsService.getInitialDataAccountTypeDefault().subscribe(res => {
      this.glAccountType = res.data.glAccountTypeList;
     
      this.spinner.hide();
    
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }
    })
   
  }
  getChartAc1() {
    this.spinner.show();
    this.accountsService.getOrganizationMainChartOfAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.glChartOfAccount = res.data;
      this.spinner.hide();
      for (const value of this.glChartOfAccount) {
        this.glChartOfAccountArray.push({
          label: value.accountName+"["+value.glAccountId+"]",
          value: value.glAccountId
        })
      }
    })
  
  }
  onSubmitNew03() {
    this.spinner.show();
    const requestData = {
      "glAccountId":this.createAccountDefault.value.glAccountId,
      "glAccountTypeId": this.createAccountDefault.value.glAccountType,
      "organizationPartyId":  this.partyId,
    }
    this.accountsService.createGlAccountTypeDefault(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.createAccountDefault.reset();
        this.closebutton3.nativeElement.click();

        // this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }


  onSubmitNew3() {
    this.spinner.show();
    const requestData = {
      "glJournalId": "",
      "glJournalName": this.createJournal1.value.journalName,
      "organizationPartyId":  this.partyId,
      
    }
    this.accountsService.postJournal(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.getJournalData();
        this.spinner.hide();
        this.createJournal1.reset();
        this.closebutton2.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
       
  
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onChange(event){
    this.showNew=false;
    if(event.value==="InvoiceType"){
      this.labelValue='Invoice Type';
      this.InvoiceDropdown=true;
      this.getAllDocTypeTemplateInitial(event.value);
    }
    else if(event.value==="QuoteType"){
      this.labelValue='Quote Type';
      this.QuoteDropdowm=true;
      this.getAllDocTypeTemplateInitialQuto(event.value);
    }
    else if(event.value==="OrderType"){
      this.labelValue='Order Type';
      this.OrderDropdown=true;
      this.getAllDocTypeTemplateInitialOrder(event.value);
    }
    this.doctype=event.value;
   

  }
  
  getAllDocTypeTemplateInitial(id) {
    this.spinner.show();
    this.accountsService.getAllDocTypeTemplateInitialData(id).subscribe((res: any) => {

      this.typeId = res.data.docType;
      this.spinner.hide();
      for (const value of this.typeId) {
        this.typeIdArray.push({
          label: value.description,
          value: value.invoiceTypeId
        })
      }
    })
   
  }
  getAllDocTypeTemplateInitialQuto(id) {
    this.spinner.show();
    this.accountsService.getAllDocTypeTemplateInitialData(id).subscribe((res: any) => {

      this.quoteTypeId = res.data.docType;
      this.spinner.hide();
      for (const value of this.quoteTypeId) {
        this.quoteTypeIdArray.push({
          label: value.description,
          value: value.quoteTypeId
        })
      }

    })
   
  }
  getAllDocTypeTemplateInitialOrder(id) {
    this.spinner.show();
    this.accountsService.getAllDocTypeTemplateInitialData(id).subscribe((res: any) => {

      this.typeIdOrder = res.data.docType;
      this.spinner.hide();
      for (const value of this.typeIdOrder) {
        this.typeIdArrayOrder.push({
          label: value.description,
          value: value.orderTypeId
        })
      }
    })
   
  }
  onSubmitNew2() {
    this.spinner.show();
    const fromActualdate=this.createDoc.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "customScreenId":  this.createDoc.value.customScreenId,
      "fromDate": this.actualfromdate,
      "invoiceTypeId": this.createDoc.value.invoiceTypeId,
      "orderTypeId":  this.createDoc.value.orderTypeId,
      "partyId":this.partyId,
      "quoteTypeId":  this.createDoc.value.quoteTypeId,
    }
    this.accountsService.postAllDocTypeTemplate(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.getAllDocTypeTemp();
        this.spinner.hide();
        this.createDoc.reset();
        this.closebutton1.nativeElement.click();

        // this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }}
        else{
          this._ToastrService.error(err.error.message);
        }
    });
  }

  /*new2end*/

  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
    })
   
  }
  getInitialData() {
    this.spinner.show();
    this.accountsService.getInitialDataAccountTypeDefault().subscribe(res => {
      this.glAccountType = res.data.glAccountTypeList;
     
      this.spinner.hide();
    
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }
    })
   
  }
  onSubmitNew1() {
    this.spinner.show();
    const fromActualdate=this.createGlobalSettingForm.get('date').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":  this.actualfromdate,
      "glAccountId":  this.createGlobalSettingForm.value.glAccountId, 
      "organizationPartyId":this.partyId,
    }
    this.accountsService.postAssignGLAccount(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this.getChartAc();
        this.createGlobalSettingForm.reset();
        this.closebutton.nativeElement.click();
        // this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  AccountingCompany(){
    this.router.navigate(['/financial/create-organization-gl-setting']);
  }
  AccountingInfo(){
    this.router.navigate(['/financial/organization-gl-setting']);
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  accountDetails() {
    this.router.navigate(['financial/gl-account-summary']);
  }
  cancelSubmit(){
    this.router.navigate(["/financial/organization-gl-setting"])
  }  
  expireDocType(partyPrefDocTypeTplId,thruDate,fromDate,invoiceTypeId,quoteTypeId,orderTypeId,customer) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const fromActual=thruDate;
    this.actualdate=this.datePipe.transform(fromActual,"yyyy-MM-dd hh:mm:ss");
     const requestData = {
      "customScreenId": customer,"fromDate": fromDate,"invoiceTypeId": invoiceTypeId,"orderTypeId": orderTypeId,"partyPrefDocTypeTplId": partyPrefDocTypeTplId,
    "quoteTypeId": quoteTypeId,"thruDate": this.actualdate
    }
    this.accountsService.expirePartyPrefDocTypeTp(requestData).subscribe((res: any) => {  
          this.getAllDocTypeTemp();
        }, (err) => {
          this.toastr.error(err.error.message); })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )} else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}})}

  onSubmit() {
    const fromActualdate=this.createAccounting.get('invoiceRestartDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
     const requestData = {
        "baseCurrencyUomId": this.createAccounting.value.baseCurrency,
        "cogsMethodId": this.createAccounting.value.soldCOGS,
        "enableAccounting": this.createAccounting.value.enableAccounting,
        "errorGlJournalId": this.createAccounting.value.errorGl,
        "fiscalYearStartDay": this.createAccounting.value.fiscalDay,
        "fiscalYearStartMonth":this.createAccounting.value.fiscalYearStart,
        "invoiceIdPrefix":this.createAccounting.value.invoiceIDPrefix,
        "invoiceSeqCustMethId": this.createAccounting.value.invoiceSeq,
        "lastInvoiceNumber": this.createAccounting.value.invoiceNumber,
        "lastInvoiceRestartDate": this.actualfromdate,
        "lastOrderNumber":this.createAccounting.value.lastOrderNumber,
        "lastQuoteNumber":this.createAccounting.value.lastQuote,
        "oldInvoiceSequenceEnumId": this.createAccounting.value.oldInvoiceSequence,
        "oldOrderSequenceEnumId": this.createAccounting.value.oldOrderSeqEnumId,
        "oldQuoteSequenceEnumId":this.createAccounting.value.oldQuoteSeq,
        "orderIdPrefix": this.createAccounting.value.orderIdPrefix,
        "orderSeqCustMethId":this.createAccounting.value.orderSeqCust,
        "quoteIdPrefix":this.createAccounting.value.quotePrefix,
        "quoteSeqCustMethId": this.createAccounting.value.quoteSeqCust,
        "taxFormId":this.createAccounting.value.taxForm,
        "useInvoiceIdForReturns": this.createAccounting.value.useInvoice,
        "refundPaymentMethodId":this.createAccounting.value.refundPayment
    }
    this.accountsService.postOrganization(this.partyId,requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/financial/organization-gl-setting']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  onUpdate() {
    const fromActualdate=this.createAccounting.get('invoiceRestartDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
     const requestData = {
        "baseCurrencyUomId": this.createAccounting.value.baseCurrency,
        "cogsMethodId": this.createAccounting.value.soldCOGS,
        "enableAccounting": this.createAccounting.value.enableAccounting,
        "errorGlJournalId": this.createAccounting.value.errorGl,
        "fiscalYearStartDay": this.createAccounting.value.fiscalDay,
        "fiscalYearStartMonth":this.createAccounting.value.fiscalYearStart,
        "invoiceIdPrefix":this.createAccounting.value.invoiceIDPrefix,
        "invoiceSeqCustMethId": this.createAccounting.value.invoiceSeq,
        "lastInvoiceNumber": this.createAccounting.value.invoiceNumber,
        "lastInvoiceRestartDate": this.actualfromdate,
        "lastOrderNumber":this.createAccounting.value.lastOrderNumber,
        "lastQuoteNumber":this.createAccounting.value.lastQuote,
        "oldInvoiceSequenceEnumId": this.createAccounting.value.oldInvoiceSequence,
        "oldOrderSequenceEnumId": this.createAccounting.value.oldOrderSeqEnumId,
        "oldQuoteSequenceEnumId":this.createAccounting.value.oldQuoteSeq,
        "orderIdPrefix": this.createAccounting.value.orderIdPrefix,
        "orderSeqCustMethId":this.createAccounting.value.orderSeqCust,
        "quoteIdPrefix":this.createAccounting.value.quotePrefix,
        "quoteSeqCustMethId": this.createAccounting.value.quoteSeqCust,
        "taxFormId":this.createAccounting.value.taxForm,
        "useInvoiceIdForReturns": this.createAccounting.value.useInvoice,
        "refundPaymentMethodId":this.createAccounting.value.refundPayment
    }
    this.accountsService.updateOrganization(this.partyId,requestData).subscribe((res: any) => {
      
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.getProductCategoryGlAccountData();
        this.getFinAccountTypeGi();
        this.getVarianceReasonGlAccounts();
        this.getTaxAuthorityGlAccount();
        this.getPartyGlAccount();
        this.spinner.hide();
        this.router.navigate(['/financial/organization-gl-setting']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      this.spinner.hide();
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
   
  }
  getCompanyDetails(){
    this.spinner.show();
    this.accountsService.getAccountCompanyDeatilById(this.partyId).subscribe((res:any)=> {
      setTimeout(() => {
        const fromdate=res.data.companyDetail.lastInvoiceRestartDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const formValue = this.createAccounting;
        formValue.patchValue({
          fiscalYearStart:res.data.companyDetail.fiscalYearStartMonth,
          fiscalDay:res.data.companyDetail.fiscalYearStartDay,
          taxForm:res.data.companyDetail.taxFormId,soldCOGS:res.data.companyDetail.cogsMethodId,
          baseCurrency:res.data.companyDetail.baseCurrencyUomId,
          invoiceSeq:res.data.companyDetail.invoiceSeqCustMethId,
          invoiceIDPrefix:res.data.companyDetail.invoiceIdPrefix,
          invoiceNumber:res.data.companyDetail.lastInvoiceNumber,invoiceRestartDate: this.getEstimateFrom,
          useInvoice:res.data.companyDetail.useInvoiceIdForReturns,
          quoteSeqCust:res.data.companyDetail.quoteSeqCustMethId,quotePrefix:res.data.companyDetail.quoteIdPrefix,
          lastQuote:res.data.companyDetail.lastQuoteNumber,orderSeqCust:res.data.companyDetail.orderSeqCustMethId,
          orderIdPrefix:res.data.companyDetail.orderIdPrefix,lastOrderNumber:res.data.companyDetail.lastOrderNumber,
          refundPayment:res.data.companyDetail.refundPaymentMethodId,errorGl:res.data.companyDetail.errorGlJournalId,
          enableAccounting:res.data.companyDetail.enableAccounting,
          oldInvoiceSequence:res.data.companyDetail.oldInvoiceSequenceEnumId,
          oldOrderSeqEnumId:res.data.companyDetail.oldOrderSequenceEnumId,
          oldQuoteSeq:res.data.companyDetail.oldQuoteSequenceEnumId
        })
      }, 3000);
      this.spinner.hide();
    })
   }
  
  getAccountCompanyInitialData() {
    this.spinner.show();
    this.accountsService.getAccountCompanyInitialData(this.partyId).subscribe((res: any) => {
      this.refundPaymentMethod = res.data.refundPaymentMethod;
      this.taxFormOrgId = res.data.taxFormOrgId;
      this.costOfGoodsSold = res.data.costOfGoodsSold;
      this.errorGlJournal = res.data.errorGlJournal;
      this.oldInvoiceSequenceEnumId=res.data.oldInvoiceSequenceEnumId;
      this.fiscalYearStartMonth=res.data.fiscalYearStartMonth;
      this.oldQuoteSequenceEnumId=res.data.oldQuoteSequenceEnumId;
      this.oldOrderSequenceEnumId=res.data.oldOrderSequenceEnumId;
      this.fiscalYearStartDay=res.data.fiscalYearStartDay;
      this.spinner.hide();
      for (const value of this.oldOrderSequenceEnumId) {
        this.oldOrderSequenceEnumIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
      for (const value of this.oldQuoteSequenceEnumId) {
        this.oldQuoteSequenceEnumIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
      for (const value of this.oldInvoiceSequenceEnumId) {
        this.oldInvoiceSequenceEnumIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
      for (const value of this.refundPaymentMethod) {
        this.refundPaymentMethodArray.push({
          label: value.description,
          value: value.paymentMethodId
        })
      }
      for (const value of this.taxFormOrgId) {
        this.taxFormOrgIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
      for (const value of this.costOfGoodsSold) {
        this.costOfGoodsSoldArray.push({
          label: value.description,
          value: value.enumId
        })
      }
      for (const value of this.errorGlJournal) {
        this.errorGlJournalArray.push({
          label: value.glJournalName,
          value: value.glJournalId
        })
      }
      for (const value of this.fiscalYearStartMonth) {
        this.fiscalYearStartMonthArray.push({
          label:value.description ,
          value: value.value
        })
      }
  

    })
   
  }
 
  

  getSalPurchaseItemTypeGlAssignment() {
    this.spinner.show();
    this.accountsService.getSalPurchaseItemTypeGlAssignment(this.finSize,this.partyId).subscribe((res: any) => {
      this.invoiceItemTypeIdPurchase = res.data;
      this.spinner.hide();
    })
  
  }
  allOpenCustomTimePeriodId() {
    this.spinner.show();
    this.accountsService.allOpenCustomTimePeriodId(this.partyId).subscribe((res: any) => {
      this.OpenCustomTimePeriodId = res.data.openTimePeriodList;
      this.spinner.hide();
    })
  
  }
   
  allCloseCustomTimePeriodId() {
    this.spinner.show();
    this.accountsService.allCloseCustomTimePeriodId(this.partyId).subscribe((res: any) => {
      this.CloseCustomTimePeriodId = res.data.closedTimePeriodList;
      this.spinner.hide();
    })
  
  }
  
  fixedAssetTypeGlAccount() {
    this.spinner.show();
    this.accountsService.fixedAssetTypeGlAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.AssetTypeGlAccount = res.data;
      this.spinner.hide();
    })
  
  }
  getPartyGlAccount() {
    this.spinner.show();
    this.accountsService.getPartyGlAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.PartyGlAccount = res.data;
      this.spinner.hide();
    })
  
  }
  getTaxAuthorityGlAccount() {
    this.spinner.show();
    this.accountsService.getTaxAuthorityGlAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.TaxAuthorityGlAccount = res.data;
      this.spinner.hide();
    })
  
  }
  getCreditCardTypeGlAccounts() {
    this.spinner.show();
    this.accountsService.getCreditCardTypeGlAccounts(this.finSize,this.partyId).subscribe((res: any) => {
      this.CreditCardTypeGlAccounts = res.data;
      this.spinner.hide();
    })
  
  }
  getVarianceReasonGlAccounts() {
    this.spinner.show();
    this.accountsService.getVarianceReasonGlAccounts(this.finSize,this.partyId).subscribe((res: any) => {
      this.VarianceReasonGlAccounts = res.data;
      this.spinner.hide();
    })
  
  }
  getGlAccountMethodTypePaymentType() {
    this.spinner.show();
    this.accountsService.getGlAccountMethodTypePaymentType(this.finSize,this.partyId).subscribe((res: any) => {
      this.paymentMethodType = res.data;
      this.spinner.hide();
    })
  
  }
  getpaymentTypeDetail() {
    this.spinner.show();
    this.accountsService.getpaymentType(this.finSize,this.partyId).subscribe((res: any) => {
      this.paymentType = res.data;
      this.spinner.hide();
    })
  
  }
  getGlAccountSalInv() {
    this.spinner.show();
    this.accountsService.getGlAccountSalInvoice(this.finSize,this.partyId).subscribe((res: any) => {
      this.saleInvoice = res.data;
      this.spinner.hide();
    })
  
  }
  getFinAccountTypeGi() {
    this.spinner.show();
    this.accountsService.getFinAccountTypeGiAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.financialAccountType = res.data;
      this.spinner.hide();
    })
  
  }
  getProductCategoryGlAccountData() {
    this.spinner.show();
    this.accountsService.getProductCategoryGlAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.productGlAccountCategory = res.data;
      this.spinner.hide();
    })
  
  }
  getProductGlAccount() {
    this.spinner.show();
    this.accountsService.getProductGlAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.productGlAccount = res.data;
      this.spinner.hide();
    })
  
  }
  getAllDocTypeTemp() {
    this.spinner.show();
    this.accountsService.getAllDocTypeTemplate(this.finSize,this.partyId).subscribe((res: any) => {
      this.docTypeTemplate = res.data;
      this.spinner.hide();
    })
  
  }
  getChartAc() {
    this.spinner.show();
    this.accountsService.getOrganizationMainChartOfAccount(this.finSize,this.partyId).subscribe((res: any) => {
      this.glChartOfAccount = res.data;
      this.spinner.hide();
    })
  
  }
  
  getListGlAccountDefault() {
    this.spinner.show();
    this.accountsService.getListGlAccountDefault(this.finSize,this.partyId).subscribe((res: any) => {
      this.getGlAccount = res.data;
      this.spinner.hide();
    })
  
  }
  getJournalData() {
    this.spinner.show();
    this.accountsService.getJournal(this.finSize,this.partyId).subscribe((res: any) => {
      this.getJournalDeatils = res.data;
      this.spinner.hide();
    })
  
  }
  
  closeCustomTimePeriods(orgid,cid) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "customTimePeriodId": cid,
          "organizationPartyId": orgid
        }
        this.accountsService.closeCustomTimePeriods(req.organizationPartyId,req.customTimePeriodId).subscribe((res: any) => {
          this.allOpenCustomTimePeriodId();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        ) }})}
  
  removeInvoiceItemTypeGlAssignment(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "invoiceItemTypeId":id ,
          "organizationPartyId": this.partyId,
        }
        this.accountsService.removeInvoiceItemTypeGlAssignment(req.organizationPartyId,req.invoiceItemTypeId).subscribe((res: any) => {
          this.getGlAccountSalInv();
          this.getSalPurchaseItemTypeGlAssignment();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
   
  }
  deleteFixedAssetTypeGlAccounts(organizationPartyId,accDepGlAccountId,assetGlAccountId,depGlAccountId,fixedAssetId,fixedAssetTypeId,lossGlAccountId,profitGlAccountId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "accDepGlAccountId":accDepGlAccountId ,
          "assetGlAccountId": assetGlAccountId,
          "depGlAccountId": depGlAccountId,
          "fixedAssetId": fixedAssetId,
          "fixedAssetTypeId": fixedAssetTypeId,
          "lossGlAccountId": lossGlAccountId,
          "organizationPartyId": organizationPartyId,
          "profitGlAccountId": profitGlAccountId
         
        }
        this.accountsService.deleteFixedAssetTypeGlAccounts(req).subscribe((res: any) => {
         
          this.fixedAssetTypeGlAccount();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
     
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
   
  }
  deletePartyGlAccount(orgid,glaccountid,accountype,party,role) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "glAccountId": glaccountid,
          "glAccountTypeId": accountype,"partyId":party,
          "organizationPartyId": orgid,"roleTypeId":role
        }
        this.accountsService.deletePartyGlAccount(req).subscribe((res: any) => {
          this.getPartyGlAccount();
        }, (err) => {
          this.toastr.error(err.error.message); })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        ) }}) }
  deleteOrganizationTaxAuthorityGlAccounts(orgid,glaccountid,taxAuthGeoId,taxAuthPartyId) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "glAccountId": glaccountid,"taxAuthGeoId": taxAuthGeoId,
          "taxAuthPartyId":taxAuthPartyId,"organizationPartyId": orgid
        }
        this.accountsService.deleteOrganizationTaxAuthorityGlAccounts(req).subscribe((res: any) => {
          this.getTaxAuthorityGlAccount();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        ) } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  
  deleteCreditCardTypeGlAccounts(orgid,glaccountid,cardType) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "glAccountId": glaccountid,"cardType": cardType, "organizationPartyId": orgid
        }
        this.accountsService.deleteCreditCardTypeGlAccounts(req).subscribe((res: any) => {
          this.getCreditCardTypeGlAccounts();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  deleteVarianceReasonGlAccounts(orgid,glaccountid,varid) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "glAccountId": glaccountid,"varianceReasonId": varid,"organizationPartyId": orgid
        }
        this.accountsService.deleteVarianceReasonGlAccounts(req).subscribe((res: any) => { 
          this.getVarianceReasonGlAccounts();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      } })}
  removeGlAccountMethodTypePaymentType(orgid,glaccountid,paymentid) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "glAccountId": glaccountid,"paymentMethodTypeId": paymentid,"organizationPartyId": orgid
        }
        this.accountsService.removeGlAccountMethodTypePaymentType(req).subscribe((res: any) => {
          this.getGlAccountMethodTypePaymentType();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        ) }
    }) }
  deletepaymentType(orgid,glaccountid,paymentid) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "glAccountTypeId": glaccountid,"paymentTypeId": paymentid,"organizationPartyId": orgid
        }
        this.accountsService.deletepaymentType(req).subscribe((res: any) => {
          this.getpaymentTypeDetail();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}
    })}
  deleteFinancialCategoryData(orgid,glaccountid,finan) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "glAccountId": glaccountid,"finAccountTypeId": finan,"organizationPartyId": orgid
        }
        this.accountsService.removeFinAccountTypeGiAccount(req).subscribe((res: any) => {
          this.getFinAccountTypeGi();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}
    }) }
  deleteProductAccountCategoryData(orgid,glaccountid,productId,accountType) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "glAccountId": glaccountid,"glAccountTypeId": accountType,"organizationPartyId": orgid,"productCategoryId":productId
        }
        this.accountsService.removeProductCategoryGlAccount(req).subscribe((res: any) => {
          this.getProductCategoryGlAccountData();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}
    }) }
  deleteProductAccount(orgid,glaccountid,productId,accountType) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "glAccountId": glaccountid,"glAccountTypeId": accountType,"organizationPartyId": orgid,"productId":productId
        }
        this.accountsService.removeProductGlAccount(req).subscribe((res: any) => {
          this.getProductGlAccount();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}
    })}
  deleteGlAccountTypeDefault(orgid,glaccountid,glaccountypeid) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req={
          "glAccountId": glaccountid, "glAccountTypeId": glaccountypeid, "organizationPartyId": orgid
        }
        this.accountsService.removeGlAccountTypeDefault(req).subscribe((res: any) => {
          this.getListGlAccountDefault();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )}
    }) }
  deleteJournal(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.removeJournal(id).subscribe((res: any) => {
         
          this.getJournalData();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
 
  
  
  getChartPdf(): void {
    this.spinner.show();
    this.id="chartAccount"
    this.downloading = true;
    
    this.accountsService.getChartPdf(this.partyId)
        .subscribe(value => {
            const blob = new Blob([value], { type: 'application/application/pdf' });
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob);
                return;
            }
            const data = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = data;
            this.spinner.hide();
           link.download = `${this.id}.pdf`;
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
            setTimeout(() => {
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
            this.downloading = false;
        });
      
}
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  changeactiveCategoryFunctionSubMenu(tab: number) {
    this.activeCategoryValue = tab;
  }
  createChart(){
    this.router.navigate(['/financial/create-organization-chart-account'], { queryParams: { partyId:  this.partyId } });
  }
  createDocType(){
    this.router.navigate(['/financial/create-doc-type'], { queryParams: { partyId:  this.partyId } });
  }
  onUpdateJournal(ejname,ejid,epartyId){
    this.router.navigate(['/financial/create-journal'], { queryParams: { jname:ejname,jid:ejid,partyId:epartyId } });
  }
  createJournal(){
    this.router.navigate(['/financial/create-journal'], { queryParams: { partyId:  this.partyId } });
  }
  glAccountDefault(){
    this.router.navigate(['/financial/create-gl-account-type-default'], { queryParams: { partyId:  this.partyId } });
  }
  glProductAccount(){
    this.router.navigate(['/financial/create-gl-product-account'], { queryParams: { partyId:  this.partyId } });
  }
  updateProduct(eproductId){
    this.router.navigate(['/financial/create-gl-product-account'], { queryParams: { partyId:  this.partyId,productId:eproductId } });
  }
  glProductCategoryAccount(){
    this.router.navigate(['/financial/create-gl-product-category-account'],{ queryParams: { partyId:  this.partyId } });
  }
  updateProductAccountCategory(eglAccountId,eproductCatId,eTypeId){
    this.router.navigate(['/financial/create-gl-product-category-account'],{ queryParams: { partyId:  this.partyId,glAccountId:eglAccountId,productCatId:eproductCatId ,TypeId:eTypeId} });
  }
  updateFinancialCategoryData(eglAccountId,efinAccountTypeId){
    this.router.navigate(['/financial/create-fin-gl-account-type'],{ queryParams: { partyId:  this.partyId,glAccountId:eglAccountId ,finAccountTypeId:efinAccountTypeId} });
  }
  glFinAccountType(){
    this.router.navigate(['/financial/create-fin-gl-account-type'],{ queryParams: { partyId:  this.partyId } });
  }
  createSalesInvoice(){
    this.router.navigate(['/financial/create-sales-invoice-gl-account'],{ queryParams: { partyId:  this.partyId } });
  }
  createPurchaseInvoice(){
    this.router.navigate(['/financial/create-purchase-invoice-gl-account'],{ queryParams: { partyId:  this.partyId } });
  }
  createPaymentType(){
    this.router.navigate(['/financial/gl-account-type-payment-type'],{ queryParams: { partyId:  this.partyId } });
  }
  createPaymentMethod(){
    this.router.navigate(['/financial/create-payment-method-type-account'],{ queryParams: { partyId:  this.partyId } });
  }
  createVarianceReason(){
    this.router.navigate(['/financial/create-variance-reason-type'],{ queryParams: { partyId:  this.partyId } });
  }
  updateVarianceReasonData(eorg,eglAccountid,evarianceReasonId){
    this.router.navigate(['/financial/create-variance-reason-type'],{ queryParams: { partyId:eorg,glAccountid:eglAccountid,varianceReasonId:evarianceReasonId } });
  }
  createCreditCard(){
    this.router.navigate(['/financial/create-credit-card-type'],{ queryParams: { partyId:  this.partyId } });
  }
  updateCreditCardTypeGlAccounts(eorg,egid,ecardType){
    this.router.navigate(['/financial/create-credit-card-type'],{ queryParams: { partyId:eorg,gid:egid,cardType:ecardType } });

  }
  createPartyAccount(){
    this.router.navigate(['/financial/create-party-gl-account'],{ queryParams: { partyId:  this.partyId } });
  }
  updatePartyGlAccount(eorgid,eglaccountid,eaccountype,eparty,erole){
    this.router.navigate(['/financial/create-party-gl-account'],{ queryParams: { partyId: eorgid,glaccountid:eglaccountid,accountype:eaccountype,party:eparty,role:erole} });
  }
  createFixedAssest(){
    this.router.navigate(['/financial/create-gl-account-fixed-assest'],{ queryParams: { partyId:  this.partyId } });

  }
  createTaxAuthority(){
    this.router.navigate(['/financial/create-tax-authority'],{ queryParams: { partyId:  this.partyId } });
  }
  updateOrganizationTaxAuthorityGlAccounts(eorganizationPartyId,eglAccountId,etaxAuthGeoId,etaxAuthPartyId){
    this.router.navigate(['/financial/create-tax-authority'],{ queryParams: { partyId:eorganizationPartyId,glAccountId:eglAccountId,taxAuthGeoId:etaxAuthGeoId,taxAuthPartyId:etaxAuthPartyId } });
  }
  createTimePeriod(){
    this.router.navigate(['/financial/create-gl-time-period'],{ queryParams: { partyId:  this.partyId } });
  }
  
    setup(){
    this.router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:this.partyId } });
  }
  accounting(){
    this.router.navigate(['/financial/detail-accounting-summary'], { queryParams: { partyId:this.partyId } });
  }
 

 
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
