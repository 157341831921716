<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-3">
        <div class="container-fluid" style="padding: 0px 0px;">
            
           <div class="col-md-12 p-0" >
               <div class="w3-card-4 classCard  w-100">
                   
                    <div class="mt-3" style="margin-bottom: 10px !important; ">
                        <span style="font-size: 15px; font-weight: bold; margin-left: 16px!important;">Seasons Settings</span>

                    </div>                    

                    
                    <div class="col-lg-12 ">
                        <div class="row d-flex modals" style="margin-left: 0px !important;">
                            <p>This section allow you to define the period for each seasons. This will be used to trigger 
                                the Preventive Maintenance Work Order at the right time based on the Service Agreements
                                in place.

                            </p>
                        </div>
                    </div>

                   

            
                    

                    <div class="row " style="width: 100%; margin-top: 1%; margin-bottom: 1%; padding-left: 8px;">
                        <div class="col-md-12">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px; margin-bottom: 1%;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color: black; font-weight: bold !important;">
                                        Seasons Settings </p>
                                    
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row ml-2">
                                            <form class="w-100 font-12 "[formGroup]="seasonSettingsForm">
                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mb-2 modals">
                                                            <label>Pick a start month and the date for each of seasons</label>
                                                            
                                                            
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mb-2 modals">
                                                            <div class="col-lg-2 classInput">
                                                                <label>Spring </label>

                                                            </div>

                                                            <div class="col-lg-2 ml-2">
                                                                <p-dropdown filter="true"
                                                                            optionlabel="label"
                                                                            placeholder="Enter Spring Month"
                                                                            formControlName="spring">
                                                                        </p-dropdown>
                                                            </div>

                                                            <div class="col-lg-1">
                                                                <p-dropdown filter="true"
                                                                            optionlabel="label"
                                                                            placeholder="1"
                                                                            formControlName="count">
                                                                        </p-dropdown>
                                                            </div>
                                                            
                                                        </div>

                                                    </div>

                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mb-2 modals">
                                                            <div class="col-lg-2 classInput">
                                                                <label>Summer </label>

                                                            </div>

                                                            <div class="col-lg-2 ml-2">
                                                                <p-dropdown filter="true"
                                                                            optionlabel="label"
                                                                            placeholder="Enter Summer Month"
                                                                            formControlName="summer">
                                                                        </p-dropdown>
                                                            </div>

                                                            <div class="col-lg-1">
                                                                <p-dropdown filter="true"
                                                                            optionlabel="label"
                                                                            placeholder="1"
                                                                            formControlName="count">
                                                                        </p-dropdown>
                                                            </div>
                                                            
                                                        </div>

                                                    </div>


                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mb-2 modals">
                                                            <div class="col-lg-2 classInput">
                                                                <label>Fall </label>

                                                            </div>

                                                            <div class="col-lg-2 ml-2">
                                                                <p-dropdown filter="true"
                                                                            optionlabel="label"
                                                                            placeholder="Enter Fall Month"
                                                                            formControlName="fall">
                                                                        </p-dropdown>
                                                            </div>

                                                            <div class="col-lg-1">
                                                                <p-dropdown filter="true"
                                                                            optionlabel="label"
                                                                            placeholder="1"
                                                                            formControlName="count">
                                                                        </p-dropdown>
                                                            </div>
                                                            
                                                        </div>

                                                    </div>


                                                    <div class="col-lg-12">
                                                        <div class="row d-flex mb-2 modals">
                                                            <div class="col-lg-2 classInput">
                                                                <label>Winter </label>

                                                            </div>

                                                            <div class="col-lg-2 ml-2">
                                                                <p-dropdown filter="true"
                                                                            optionlabel="label"
                                                                            placeholder="Enter Winter Month"
                                                                            formControlName="winter">
                                                                        </p-dropdown>
                                                            </div>

                                                            <div class="col-lg-1">
                                                                <p-dropdown filter="true"
                                                                            optionlabel="label"
                                                                            placeholder="1"
                                                                            formControlName="count">
                                                                        </p-dropdown>
                                                            </div>
                                                            
                                                        </div>

                                                    </div>

                                                    
                                                

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            


                        </div>

                       
                    </div>

                    

                    <div class="d-flex justify-content-end  mb-1" style="margin-right: 1%;">
                        <button class="btn btn-secondary ml-2 submit-btn">Cancel</button>
                        <button class="btn btn-secondary ml-2 submit-btn">Ok</button>
                    </div>

                   
               </div>
           </div>
                        
        
        
        
        
        </div>
    
    </div>
</div>


        

<ngx-spinner></ngx-spinner>










