<div class="container-fluid main-container-wrapper">
 
    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Banking</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>
    <div class=" bg-white color-grey">
       
        <div class="w3-card-4 classCard">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                    <li [ngClass]="activeCategoryMain==1?'active':''" (click)="financialUrl()"><a>
                            Banking Account</a></li>
                    <li [ngClass]="activeCategoryMain==2?'active':''" (click)="CheckURL()"><a>
                            Check</a></li>


                </ul>
            </div>

            <div class="create-new-leade mt-2">
                <div class="container-fluid">


                    <div class="panel-group">
                        <div *ngIf="activeCategoryMain==1">
                            <div class=" bg-white color-grey">

                                <div class="create-new-leade mt-2">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100">
                                                <div class="w3-card-4  w-100">
                                                    <div class="accordion classCard" id="myAccordion">
                                                        <div class="accordion-item">
                                                            <h2 class="accordion-header" id="headingOne">
                                                                <div style="white-space: nowrap;" class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                    <button type="button" class="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalCenter">Create
                                                                        New Banking Account</button>

                                                                    <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 73%!important; padding-top: 11px !important;
                                                                        padding-bottom: 11px !important;">
                                                                    </button>									
                    
                                                                </div>
                            
                                                            </h2>
                                                            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                <div class="card-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Financial Account
                                                                                        ID</label>
                                                                                </div>
                
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.finAccountIdSearchType" [ngModelOptions]="{standalone:true}" name="finAccountIdSearchType" [options]="opportunityIdArray" optionlabel="label"></p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" name="finAccountId" class="form-control" [(ngModel)]="advanceSearch.finAccountId" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Financial Account ID">
                                                                                </div>
                
                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                    <label for="exampleInputEmail1">Financial Account
                                                                                        Name</label>
                                                                                </div>
                
                                                                                <div class="col-lg-2" style="display: none;">
                                                                                    <p-dropdown filter="true" name="finAccountNameSearchType" [(ngModel)]="advanceSearch.finAccountNameSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">
                
                                                                                    </p-dropdown>
                                                                                </div>
                                                                                <div class="col-lg-2">
                                                                                    <input type="email" class="form-control" name="finAccountName" [(ngModel)]="advanceSearch.finAccountName" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Financial Account Name">
                                                                                </div>
                
                
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-lg-12">
                                                                            <div class="row">
                
                                                                                <div class="col-lg-3 form-group classInput">
                                                                                    <label for="exampleInputEmail1">Financial Account
                                                                                        Type</label>
                                                                                </div>
                
                                                                                <div class="col-lg-2">
                                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.finAccountTypeId" [ngModelOptions]="{standalone:true}" name="finAccountTypeId" [options]="FinAccountTypeListArray" optionlabel="label" placeholder="Enter Financial Account Type">
                
                                                                                    </p-dropdown>
                                                                                </div>
                
                
                
                                                                            </div>
                                                                        </div>
                
                                                                    </div>

                                                                    <div class="col-lg-12 col-12 main-submit-button" style=" margin-left: -23%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger ml-2" (click)="reset();">Reset</button>
                                                                    </div>
                                                                    
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>

                                                <!-- <div class="w3-card-4 classCard">
                                                    <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                        <button type="button" class="btn btn-outline-secondary" data-toggle="modal" data-target="#exampleModalCenter">Create
                                                            New Banking Account</button>

                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Financial Account
                                                                        ID</label>
                                                                </div>

                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.finAccountIdSearchType" [ngModelOptions]="{standalone:true}" name="finAccountIdSearchType" [options]="opportunityIdArray" optionlabel="label"></p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" name="finAccountId" class="form-control" [(ngModel)]="advanceSearch.finAccountId" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Financial Account ID">
                                                                </div>

                                                                <div class="col-lg-3 form-group classInputA">
                                                                    <label for="exampleInputEmail1">Financial Account
                                                                        Name</label>
                                                                </div>

                                                                <div class="col-lg-2" style="display: none;">
                                                                    <p-dropdown filter="true" name="finAccountNameSearchType" [(ngModel)]="advanceSearch.finAccountNameSearchType" [ngModelOptions]="{standalone:true}" [options]="opportunityIdArray" optionlabel="label">

                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="email" class="form-control" name="finAccountName" [(ngModel)]="advanceSearch.finAccountName" [ngModelOptions]="{standalone:true}" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Financial Account Name">
                                                                </div>


                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <div class="row">

                                                                <div class="col-lg-3 form-group classInput">
                                                                    <label for="exampleInputEmail1">Financial Account
                                                                        Type</label>
                                                                </div>

                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" [(ngModel)]="advanceSearch.finAccountTypeId" [ngModelOptions]="{standalone:true}" name="finAccountTypeId" [options]="FinAccountTypeListArray" optionlabel="label" placeholder="Enter Financial Account Type">

                                                                    </p-dropdown>
                                                                </div>



                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="col-lg-12 col-12 main-submit-button" style=" margin-left: -23%;"><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn" (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                                        <button _ngcontent-cta-c128="" type="submit" class="btn btn-danger ml-2" (click)="reset();">Reset</button>
                                                    </div>
                                                </div> -->
                                                
                                                <br>
                                                
                                                <div class="w3-card-4 classCard">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Financial List
                                                            </h4>

                                                        </div>
                                                    </header>
                                                    <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                                        <div class="col-lg-12 col-12">
                                                            <div class="form-group">

                                                                <div class="card own-account-table">
                                                                    <p-table [value]="financialList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        Financial Account ID
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                    <div style="color: white;">
                                                                                        Financial Account Type
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>

                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;"> Status
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;">
                                                                                        Financial Account Name
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;">
                                                                                        Financial Account Code
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;">
                                                                                        Financial Account Pin
                                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Currency
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;">
                                                                                        Organization Party ID
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Owner Party ID
                                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Post To Gl Account ID
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> From Date
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Through Date
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Is Refundable
                                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;">
                                                                                        Replenish Payment ID
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;">
                                                                                        Replenish Level
                                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Actual Balance
                                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;">
                                                                                        Available Balance
                                                                                        <p-sortIcon field="price"></p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Created At
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon field="price">
                                                                                        </p-sortIcon>

                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'180px'}" (click)="accountDetails(product.finAccountId)">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                        <span class="account-button">
                                                                                            {{product.finAccountId}}
                                                                                        </span>
                                                                                        
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.finAccountTypeDesc}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.statusDescription}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.finAccountName}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.finAccountCode}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.finAccountPin}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.currencyUomId}}

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.organizationPartyId}}

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.ownerPartyId}}

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.postToGlAccountId}}

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.fromDate | date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.thruDate | date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.isRefundable}}

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.replenishPaymentId}}

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.replenishLevel}}

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.actualBalance}}

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.availableBalance}}

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.createdStamp| date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">

                                                                                    <svg (click)="onDelete(product.finAccountId)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }

                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                </td>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>


                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New Financial Account</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!show"><a>
                                        Add New Financial Account</a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="show"><a>
                                        Update New Financial Account</a></li>
                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createFinancial">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Financial Account Type<span
                                                                style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="FinAccountTypeListArray" optionlabel="label" placeholder="Enter Financial Account Type" filter="true" formControlName="finAccountTypeId">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Status <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="FinAccountStatusListArray" optionlabel="label" placeholder="Enter Status" filter="true" formControlName="statusId">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Financial Account Name

                                                        </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="finAccountName" placeholder="Enter Financial Account Name">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Financial Account Code </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="finAccountCode" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Financial Account Code">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Financial Account Pin</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="finAccountPin" aria-describedby="emailHelp" placeholder="Enter Financial Account Pin">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Currency<span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Currency" [options]="currencyArray" optionlabel="label" filter="true" formControlName="currencyUomId">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Organization Party ID<span
                                                                style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Organization Party ID" filter="true" formControlName="organizationPartyId" [options]="partyIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Owner Party ID
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Owner Party ID" [options]="partyIdArray" optionlabel="label" filter="true" formControlName="ownerPartyId">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Post To Gl Account ID </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Post To Gl Account ID" filter="true" [options]="giAccountArray" optionlabel="label" formControlName="postToGlAccountId">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">From Date <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Financial Account Pin" formControlName="fromDate">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Through Date </label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Financial Account Pin" formControlName="thruDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Is Refundable<span
                                                                style="color:red">*</span> </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Is Refundable" [options]="refundable" optionlabel="label" filter="true" formControlName="isRefundable">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Replenish Payment ID</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="replenishPaymentId" placeholder="Enter Replenish Payment ID">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Replenish Level</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="replenishLevel" placeholder="Enter Replenish Level">
                                                    </div>



                                                </div>
                                            </div>



                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 84%;">
                                        <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                        <button type="submit" *ngIf="!show" (click)="submit()" class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>


            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>