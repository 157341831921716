import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { HeaderService } from 'src/app/header/header.service';
import { AccountsService } from '../../accounts/accounts.service';
import { MyContactsService } from '../../contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from '../../events/create-events/create-event-service';
import { Location } from '@angular/common';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import {
  ChangeDetectionStrategy,
  TemplateRef,
} from '@angular/core';
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from 'angular-calendar';

import { FullCalendarComponent, CalendarOptions } from '@fullcalendar/angular';

import dayGridPlugin from '@fullcalendar/daygrid';
import { TrainingApprovalService } from 'src/app/human-resource/training/training-approvals/training-approval.service';


declare const $: any;

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
@Component({
  selector: 'app-preferences-all',
  templateUrl: './preferences-all.component.html',
  styleUrls: ['./preferences-all.component.css']
})
export class PreferencesAllComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  createPortalPageList: any;
  delete: boolean;
  newCommEvent: any;
  communicationEventId: any;
  showPortal: boolean;
  widthProduct: any;
  eventRoles: any;
  search = "";

  public user = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,
    search: this.search
  }
  communicationContentUploadById: any;
  communicationCalender: any;
  parentPortalPageId: any;
  createPortal: any;
  CommunicationEvents: any;
  ChildCommunicationOverviewEvent: any;
  communicationEventsBYCe: any;
  eventContentList: any;
  readonly req = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  MainPageRequestListDetails: any;
  calendarValue: any;
  trainingApproval: any;
  trainingApprovalList: any[];
  trainingClassTypes: any[];
  isForm: boolean;
  isButton: boolean;
  isUpdate: boolean;
  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };

  calendarEventForm: FormGroup;
  partyIds: any[];
  workEffortId: string;
  partyId: string;
  date: {
    startDate: string,
    endDate: string
  };
  assignList: any[];
  calendarEventForm1: FormGroup;
  ParticipantsList: any;
  personId: any;
  partyIdArray: any[] = [];
  communicationEventsReq: any;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
        private _location: Location,
        readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly trainingApprovalService: TrainingApprovalService,
  ) {
    this.createPortal = this._FormBuilder.group({
      portalPageName: [''],
      description: [''],
    });
    this.newCommEvent = this._FormBuilder.group({
      partyID: [''],
      email: [''],
      fName: [''],
      mName: [''],
      lName: [''],
    });
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.parentPortalPageId = params.parentPortalPageId;
      this.communicationEventId = params.communicationEventId;
    })
    this.getCreatePortalPage();
    this.getEventCommRoles();
    this.getCommunicationContentUploadById();
    this.getCommunicationCalender();
    this.getCommunicationEvent();
    this.getChildCommunicationEvent();
    this.getCommunicationEventsBYCe();
    this.getCrmEventContentList();
    this.getRequestListDetails();
    this.getCommunicationEventsBYCe();
    this.getPartyId();
    this.getCommunicationEventsReq();
    this.getEvents();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  cancelSubmit(){
    this._location.back();
  }
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt" (click)="update()"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    }

  ];

  getEvents(): void {
    this.spinner.show();

    switch (this.view) {
      case 'month': this.date = {
        startDate: moment().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('month').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
      case 'day': this.date = {
        startDate: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
      case 'week': this.date = {
        startDate: moment().startOf('week').format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment().endOf('week').format('YYYY-MM-DD HH:mm:ss')
      };
        break;
    }
    this.trainingApprovalService.getCommunicationCalender()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.events = data.data.map(value => {
            return {
              ...value, ...{
                start: new Date(value.fromDate),
                end: "",
                title: value.getWorkEffortName[0].workEffortName,
                color: colors.red,
                actions: this.actions,
                allDay: false,
                resizable: {
                  beforeStart: true,
                  afterEnd: true,
                },
                draggable: true,
              }
            }
          });
        }
      });

  }
 

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: any): void {


    this.workEffortId = event.event.getWorkEffortName[0].workEffortId,
    this.calendarValue = {
      eventName: event.getWorkEffortName[0].workEffortName,
      // desc: "",
      /// eventType:  "",
      // status:  event.,
      // scope: event.,
      startDate: event.fromDate ? moment(event.fromDate).format('yyyy-MM-DDTHH:mm') : '',
     // compDate: event.estimatedCompletionDate ? moment(event.estimatedCompletionDate).format('yyyy-MM-DDTHH:mm') : '',
    };
    this.isForm = true;
    this.isUpdate = true;

    this.calendarEventForm1.patchValue({
      eventName: event.getWorkEffortName[0].workEffortName,
      // desc: "",
      /// eventType:  "",
      // status:  event.,
      // scope: event.,
      startDate: event.fromDate ? moment(event.fromDate).format('yyyy-MM-DDTHH:mm') : '',
     // compDate: event.estimatedCompletionDate ? moment(event.estimatedCompletionDate).format('yyyy-MM-DDTHH:mm') : '',


    });
    this.getParticipants();

  }
  cancelCalenderEvent(): void {
    this.spinner.show();

    const requestData = {

      "currentStatusId": "CAL_CANCELLED",
      "workEffortId": this.workEffortId

    }

    this.myContactsService.cancelCalenderEvent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  addEvent(): void {
   this._Router.navigate(["/crm/Preferences/preferencesall"],
      { queryParams: { communicationEventId: this.communicationEventId } })
  }
  addNew(): void {
    this.isForm = true;
    this.isButton = false;
    this.isUpdate = false;
  }
  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }
  setView(view: CalendarView) {
    this.view = view;
  }
  activeDayIsOpen: boolean = true;
  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }
  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  getCommunicationEventsReq() {
    this.spinner.show();
    this.myContactsService.getCommunicationEventsReq(this.communicationEventId).subscribe(res => {
      this.communicationEventsReq = res.data[0].nodes;
    
      this.spinner.hide();

    })
  }
  refresh: Subject<any> = new Subject();
  events: CalendarEvent[];

 
  getParticipants() {
    this.spinner.show();
    this.myContactsService.getParticipants(this.workEffortId).subscribe(res => {
      this.ParticipantsList = res.data[0].data;
      this.spinner.hide();

    })
  }

  
  allocateMsgToPartyBy(): void {
    this.spinner.show();
    const requestData = {
      "communicationEventId": this.communicationEventId,
      "donePage": "",
      "emailAddress": this.newCommEvent.value.email,
      "firstName": this.newCommEvent.value.fName,
      "form": "list",
      "lastName": this.newCommEvent.value.lName,
      "middleName": this.newCommEvent.value.mName,
      "partyId": this.newCommEvent.value.partyID,
      "portalPageId": ""
    }
    this.myContactsService.allocateMsgToPartyBy(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getRequestListDetails() {
    this.spinner.show();
    this.myContactsService.getRequestListDetails().subscribe(res => {
      this.MainPageRequestListDetails = res.data;
      this.spinner.hide();
    })
  }

  deleteContent(id, cid, fromdate) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.myContactsService.deletegetCrmEventContentList(id, cid, fromdate).subscribe((res: any) => {

          this.getCrmEventContentList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getCrmEventContentList() {
    this.spinner.show();

    this.myContactsService.getEventContentList(this.communicationEventId, this.req).subscribe((res: any) => {
      this.eventContentList = res.data;

      this.spinner.hide();

    })

  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  getCommunicationEventsBYCe() {
    this.spinner.show();
    this.myContactsService.getCommunicationEventsBYCe(this.communicationEventId).subscribe(res => {
      this.communicationEventsBYCe = res.data[0].nodes[0];
      this.spinner.hide();
    })
  }
  getChildCommunicationEvent() {
    this.spinner.show();
    this.myContactsService.getChildCommunicationEvent(this.communicationEventId, this.communicationEventId).subscribe(res => {
      this.ChildCommunicationOverviewEvent = res.data;
      this.spinner.hide();
    })
  }

  getCommunicationEvent() {
    this.spinner.show();
    this.myContactsService.getCommunicationEvent(this.communicationEventId).subscribe(res => {
      this.CommunicationEvents = res.data;
      this.spinner.hide();
    })
  }
  myProfilePage(fromPartyId) {
    this._Router.navigate(["/psa/my-profile"], { queryParams: { partyId: fromPartyId } })
  }
  rejectCustomerReq(product) {
    this.spinner.show();
    const fromData = {

      "custRequestId": product.custRequestId,
      "statusId": "CRQ_REJECTED",
      "view_INDEX_1": "20",
      "view_SIZE_1": "0"
    }
    this.myContactsService.rejectCustomerReq(fromData).subscribe((res: any) => {

      if (res.success) {
        this.toastr.success("Rejected");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  createPortalPage(): void {
    this.spinner.show();

    const requestData = {
      "description": this.createPortal.value.description,
      "parentPortalPageId": "SFA_MAIN",
      "portalPageName": this.createPortal.value.portalPageName,
      "sequenceNum": "1"
    }

    this.myContactsService.createPortalPage(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getCreatePortalPage();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCommunicationCalender() {
    this.spinner.show();
    this.myContactsService.getCommunicationCalender().subscribe(res => {
      this.communicationCalender = res.data;
      this.spinner.hide();

    })
  }
  deleteUpload(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        this.myContactsService.deleteUpload(product.communicationContentUploadId).subscribe(res => {
          this.getCommunicationContentUploadById();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getCommunicationContentUploadById() {
    this.spinner.show();
    this.myContactsService.getCommunicationContentUploadById(this.communicationEventId).subscribe(res => {
      this.communicationContentUploadById = res.data[0].getCommunicationContentUploadById;
      this.spinner.hide();
    })
  }
  getEventCommRoles() {
    this.spinner.show();
    this.myContactsService.getEventCommRoles(this.user, this.communicationEventId).subscribe((res: any) => {
      this.eventRoles = res.data;
      this.spinner.hide();
    })
  }
  PortletPage(parentPortalPageId) {
    this._Router.navigate(["/crm/Preferences/viewPreferences"],
      { queryParams: { parentPortalPageId: parentPortalPageId } })
  }

  updatePortal(product) {
    this.widthProduct = product;
    this.spinner.show();

    this.spinner.hide();
  }
  allPage() {
    this._Router.navigate(["/crm/Preferences/preferencesall"],
      { queryParams: { communicationEventId: this.communicationEventId } })
  }
  emailPage() {
    this._Router.navigate(["/crm/Preferences/emailPreferences"],
      { queryParams: { communicationEventId: this.communicationEventId } })
  }
  notePage() {
    this._Router.navigate(["/crm/Preferences/notePreferences"],
      { queryParams: { communicationEventId: this.communicationEventId } })
  }
  getCreatePortalPage() {
    this.spinner.show();
    this.myContactsService.getCreatePortalPage().subscribe(res => {
      this.createPortalPageList = res.data[0].getCreatePortalPage;
      this.spinner.hide();

    })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}
