<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
       <span class="color-black pl-1 titlePanels" *ngIf="!show" style="cursor: pointer;">Create Return</span>
       <span class="color-black pl-1 titlePanels" *ngIf="show" style="cursor: pointer;">Update Return</span>
        <button type="submit" (click)="cancel()" class="btn btn-danger buttonclass">Cancel</button>
      </div>

      <div class=" bg-white color-grey create-new-table">

        <div>
          <div class="container-fluid">
            <div class="row">
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div class="w3-card-4 classCard">
                        <div class="container-fluid">
                          <ul class="tabbing-section tabbing-accordians tabClass">

                            <li *ngIf="show" [ngClass]="activeCategory==1?'active':''"><a>
                                Update return</a></li>
                            <li *ngIf="!show" [ngClass]="activeCategory==1?'active':''"><a>
                                Create new return</a></li>


                          </ul>
                        </div>

                        <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                          <div class="panel-body">
                            <div class="mt-2">
                              <div class="row">
                                <form class="w-100" [formGroup]="createReturn">
                                  <div class="all-users-infomation font-13 font-medium own-user-select allPad">

                                    <div class="col-lg-12">

                                      <div class="row">
                                        <div class=" col-lg-3 form-group rightForm">
                                          <label class="common-labels labelClass">
                                            Return HeaderType ID
                                          </label>
                                        </div>

                                        <div class="col-lg-2">
                                          <p-dropdown formControlName="returnHeaderTypeId"
                                            placeholder="Select Return HeaderType ID	" filter="true"
                                            [options]="headerArray" optionlabel="label"></p-dropdown>
                                        </div>


                                        <div class="col-lg-3 form-group rightFormA">
                                          <label class="common-labels labelClass" >
                                            Status ID
                                          </label>
                                        </div>

                                        <div class="col-lg-2">
                                          <p-dropdown formControlName="statusId"
                                            placeholder="Select Return HeaderType ID	" filter="true"
                                            [options]="statusIdArray" optionlabel="label"></p-dropdown>

                                        </div>
                                      </div>

                                    </div>
                                  </div>

                                  <div class="col-lg-12">
                                    <div class="row">
                                      <div class="col-lg-3 form-group rightForm">
                                        <label class="common-labels labelClass">

                                          From Party ID
                                        </label>
                                      </div>

                                      <div class="col-lg-2">

                                        <p-dropdown formControlName="fromPartyId" placeholder="Select PartyId"
                                          filter="true" [options]="partyIdArray" optionlabel="label"></p-dropdown>
                                      </div>



                                      <div class="col-lg-3 form-group rightFormA">
                                        <label class="common-labels labelClass">To Party ID
                                        </label>
                                      </div>

                                      <div class="col-lg-2">
                                        <p-dropdown formControlName="toPartyId" placeholder="Select PartyId"
                                          filter="true" [options]="partyIdArray" optionlabel="label"></p-dropdown>
                                      </div>
                                    </div>
                                  </div>




                                  <div class="col-lg-12">
                                    <div class="row">
                                      <div class="col-lg-3 form-group rightForm">
                                        <label class="common-labels labelClass">Payment Method ID </label>
                                      </div>
                                      <div class="col-lg-2">
                                        <input type="text" class="form-control" formControlName="paymentMethodId"
                                          placeholder="Enter Payment Method ID">
                                      </div>


                                      <div class="col-lg-3 form-group rightFormA">
                                        <label class="common-labels labelClass ">Find Account ID</label>
                                      </div>
                                      <div class="col-lg-2">
                                        <input type="text" class="form-control" formControlName="finAccountId"
                                          placeholder="Enter Payment Method ID">
                                      </div>
                                    </div>
                                  </div>

                                  <div class="col-lg-12">
                                    <div class="row">
                                      <div class="col-lg-3 form-group rightForm">
                                        <label class="common-labels labelClass">Billing Account ID </label>

                                      </div>
                                      <div class="col-lg-2">
                                        <input type="text" class="form-control" formControlName="billingAccountId"
                                          placeholder="Enter Billing Account ID">
                                      </div>


                                      <div class="col-lg-3 form-group rightFormA">
                                        <label class="labelClass" for="exampleInputEmail1">Entry Date </label>
                                      </div>
                                      <div class="col-lg-2">
                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                          aria-describedby="emailHelp"  formControlName="entryDate">

                                      </div>
                                    </div>
                                  </div>


                                  <div class="col-lg-12">
                                    <div class="row">
                                      <div class="col-lg-3 form-group rightForm">
                                        <label class="common-labels labelClass"> Origin Contact Mech ID</label>
                                      </div>
                                      <div class="col-lg-2">
                                        <input type="text" class="form-control" formControlName="originContactMechId"
                                          placeholder="Enter  Origin Contact Mech ID">
                                      </div>



                                      <div class="col-lg-3 form-group rightFormA">
                                        <label class="common-labels labelClass">Destination Facility ID
                                        </label>
                                      </div>
                                      <div class="col-lg-2">
                                        <p-dropdown formControlName="destinationFacilityId"
                                          placeholder="Select Destination Facility ID" filter="true"
                                          [options]="facilityArray" optionlabel="label"></p-dropdown>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-12">
                                    <div class="row">
                                      <div class="col-lg-3 form-group rightForm">
                                        <label class="common-labels labelClass">Needs Inventory Receive
                                        </label>
                                      </div>
                                      <div class="col-lg-2">
                                        <p-dropdown formControlName="needsInventoryReceive"
                                          placeholder="Select Needs Inventory Receive" filter="true"
                                          [options]="dateIdArray" optionlabel="label"></p-dropdown>
                                      </div>


                                      <div class="col-lg-3 form-group rightFormA">
                                        <label class="common-labels labelClass">Currency
                                        </label>
                                      </div>
                                      <div class="col-lg-2">
                                        <p-dropdown formControlName="currencyUomId" placeholder="Select Currency"
                                          filter="true" [options]="currencyListArray" optionlabel="label">
                                        </p-dropdown>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-lg-12">
                                    <div class="row">
                                      <div class="col-lg-3 form-group rightForm">
                                        <label class="common-labels labelClass">Supplier Rma ID </label>
                                      </div>

                                      <div class="col-lg-2">
                                        <input type="text" class="form-control" formControlName="supplierRmaId"
                                          placeholder="Enter Supplier Rma ID">
                                      </div>
                                    </div>
                                  </div>
                                  <div class="submit-btn-sales main-submit-button"
                                    style="padding-right:236px;margin-top:-8px">
                                    <button (click)="submit();" *ngIf="!show" type="submit"
                                      class="btn btn-secondary submit-btn">Submit</button>
                                    <button *ngIf="show" type="submit" (click)="update()"
                                      class="btn btn-secondary submit-btn">Update</button>
                                  </div>
                                </form>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>