import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-add-new-employment',
  templateUrl: './add-new-employment.component.html',
  styleUrls: ['./add-new-employment.component.css']
})
export class AddNewEmploymentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('.right-arrow').click(function(){
      $(this).toggleClass('rotate-up');
    });
  }

}
