<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="container own-container-warp">
                                        <div class="row">
                                            <div class="col-lg-6 col-12">
                                                <div class="own-company-info logo-company">
                                                    <h2>Phyrst</h2>
                                                    <div class="address-info">
                                                        Your Company Name Here <br>2001 open blvd
                                                    </div>
                                                    <div class="color-grey">
                                                        Emaar Digital Greens, Sector 55, Gurugram,<br>
                                                        Hayrana
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-12">
                                                <div class="own-company-invoice">
                                                    <h2>Sales Invoice</h2>
                                                    <div>
                                                        <table>
                                                            <tr>
                                                                <td>Invoice</td>
                                                                <td>215465</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Customer Id</td>
                                                                <td>215465</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Date</td>
                                                                <td>215465</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Date</td>
                                                                <td>215465</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="billing-adresses col-lg-12 col-12">
                                                <table style="width:100%">
                                                    <tr>
                                                        <th>Billing Address</th>
                                                        <th>Shipping Address</th>

                                                    </tr>
                                                    <tr>
                                                        <td>Anglea Brinkley<br>
                                                            Spaze I-tech Park, Sohna Road, <br>
                                                            Gurugram, Haryana, 120018
                                                        </td>
                                                        <td>
                                                            Anglea Brinkley<br>
                                                            Iris tech Park, Sector 48, Sohna Road,<br> Gurugram,
                                                            Haryana, 120018
                                                        </td>
                                                    </tr>

                                                </table>
                                            </div>
                                            <div class="col-lg-12 col-12 own-product-info">

                                                <table class="table">
                                                    <thead class="thead-light">
                                                        <tr>
                                                            <th scope="col">Product</th>
                                                            <th scope="col">Description</th>
                                                            <th scope="col">MSRP</th>
                                                            <th scope="col">List Price</th>
                                                            <th scope="col">Qty</th>
                                                            <th scope="col">Unit</th>
                                                            <th scope="col">Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>GZ-100</td>
                                                            <td>Tiny Gizmo</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>1</td>
                                                            <td>10.00</td>
                                                            <td>10.00</td>
                                                            
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="col-lg-12 col-12">
                                                    <div class="own-company-invoice">
                                                        <div>
                                                            <table>
                                                                <tr>
                                                                    <td>Total</td>
                                                                    <td>215465</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Adjusted</td>
                                                                    <td>215465</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Paid</td>
                                                                    <td>215465</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Outstanding</td>
                                                                    <td>215465</td>
                                                                </tr>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>