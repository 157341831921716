import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { Location } from '@angular/common';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { FinancialService } from 'src/app/services/financial.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import { AddEmployeePositionService } from 'src/app/human-resource/add-employee-position/add-employee-position.service';


@Component({
  selector: 'app-main-create-paycheck',
  templateUrl: './main-create-paycheck.component.html',
  styleUrls: ['./main-create-paycheck.component.css']
})
export class MainCreatePaycheckComponent implements OnInit {
  createPaycheck: FormGroup;
  PaytoParty: any;
  PaytoPartyArray: any[]=[];
  PaymentMethodIDArray: any[]=[];
  PaymentMethod: any;
  PaycheckTypeArray: any[]=[];
  PaycheckType: any;
  
  currencyArray: any[]=[];
  GrossAmount:any;
  hours: { label: string; value: string; }[];
  amPM: { label: string; value: string; }[];
  minute: { label: string; value: string; }[];
  effectiveDate: string;
  paymentId = "";
  paymentMethodTypeId = "";
  paymentTypeId = "";
  paycheckType: any;
  show :boolean = false;
  PaycheckExpenses: any;
  activeCategory=2;

  constructor(
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FinancialService: FinancialService,
    private _location: Location,
    readonly _Router: Router,
    readonly _ToastrService: ToastrService,

    readonly _AccountingApService: AccountingApService,
    readonly _WarehouseService: WarehouseService,
    readonly addSkillService: AddSkillService,
  ) {
    this.createPaycheck = this._FormBuilder.group({
      PaycheckType: [''],
      PaytoParty: [''],
      PaymentMethod: [''],
      Amount: [''],
      GrossAmount: [''],
      EffectiveDate: [''],
      hour: [''],
      minute: [''],
      amPm: [''],
      Comments: [''],
      RefNum: [''],
      numberDays: [''],
      numberHours: [''],
    });

    this.hours = [
      {
        label: '1',
        value: '1'
      },
      {
        label: '2',
        value: '2'
      },
      {
        label: '3',
        value: '3'
      },
      {
        label: '4',
        value: '4'
      },
      {
        label: '5',
        value: '5'
      },
      {
        label: '6',
        value: '6'
      },
      {
        label: '7',
        value: '7'
      },
      {
        label: '8',
        value: '8'
      },
      {
        label: '9',
        value: '9'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },

    ];
    this.minute = [
      {
        label: '00',
        value: '00'
      },
      {
        label: '01',
        value: '01'
      },
      {
        label: '02',
        value: '02'
      },
      {
        label: '03',
        value: '03'
      },
      {
        label: '04',
        value: '04'
      },
      {
        label: '05',
        value: '05'
      },
      {
        label: '06',
        value: '06'
      },
      {
        label: '07',
        value: '07'
      },
      {
        label: '08',
        value: '08'
      },
      {
        label: '09',
        value: '09'
      },
      {
        label: '10',
        value: '10'
      },
      {
        label: '11',
        value: '11'
      },
      {
        label: '12',
        value: '12'
      },
      {
        label: '13',
        value: '13'
      },
      {
        label: '14',
        value: '14'
      },
      {
        label: '15',
        value: '15'
      },
      {
        label: '16',
        value: '16'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '17',
        value: '17'
      },
      {
        label: '18',
        value: '18'
      },
      {
        label: '19',
        value: '19'
      },
      {
        label: '20',
        value: '20'
      },
      {
        label: '21',
        value: '21'
      },
      {
        label: '22',
        value: '22'
      },
      {
        label: '23',
        value: '23'
      },
      {
        label: '24',
        value: '24'
      },
      {
        label: '25',
        value: '25'
      },
      {
        label: '26',
        value: '26'
      },
      {
        label: '27',
        value: '27'
      },
      {
        label: '28',
        value: '28'
      },
      {
        label: '29',
        value: '29'
      },
      {
        label: '30',
        value: '30'
      },
      {
        label: '31',
        value: '31'
      },
      {
        label: '32',
        value: '32'
      },
      {
        label: '33',
        value: '33'
      }, {
        label: '34',
        value: '34'
      },
      {
        label: '35',
        value: '35'
      }, {
        label: '36',
        value: '36'
      },
      {
        label: '37',
        value: '37'
      }, {
        label: '38',
        value: '38'
      },
      {
        label: '39',
        value: '39'
      },
      {
        label: '40',
        value: '40'
      }, {
        label: '41',
        value: '41'
      },
      {
        label: '42',
        value: '42'
      },
      {
        label: '43',
        value: '43'
      }, {
        label: '44',
        value: '44'
      },
      {
        label: '45',
        value: '45'
      },
      {
        label: '46',
        value: '46'
      }, {
        label: '47',
        value: '47'
      },
      {
        label: '48',
        value: '48'
      },
      {
        label: '49',
        value: '49'
      }, {
        label: '50',
        value: '50'
      },
      {
        label: '51',
        value: '51'
      },
      {
        label: '52',
        value: '52'
      },
      {
        label: '53',
        value: '53'
      },{
        label: '54',
        value: '54'
      },{
        label: '55',
        value: '55'
      },{
        label: '56',
        value: '56'
      },{
        label: '57',
        value: '57'
      },
      {
        label: '58',
        value: '58'
      },
      {
        label: '59',
        value: '59'
      }


    ];
    this.amPM = [
      {
        label: 'AM',
        value: 'AM'
      },
      {
        label: 'PM',
        value: 'PM'
      },
    ];

  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.paycheckType = params["paycheckType"];
    });

    if(this.paycheckType){
      this.show = true;
    }
    else{
      this.show = false;
    }
    this.getPartyId() ;
    this.getPaymentMethod() ;
    this.getPaycheckType();
    this.getCurrency();
    this.getConfigPagePaycheck();
  }

  getConfigPagePaycheck() {
    this.accountsService.getConfigPagePaycheck(this.paymentId).subscribe((res: any) => {
      this.spinner.show();
      this.PaycheckExpenses = res.data[0];
      this.spinner.hide();
    })
  }
  backWindow(){
    this._location.back();
  }
    toDashboard()
{
  this._Router.navigate(['/financial/main']);
}

  
  getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.PaytoParty = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.PaytoParty) {
        this.PaytoPartyArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getPaymentMethod() {
    this.spinner.show();
    this.accountsService.getPaymentMethod().subscribe(res => {
      this.PaymentMethod= res.data;
      this.spinner.hide();
      for (const value of this.PaymentMethod) {
        this.PaymentMethodIDArray.push({
          label: value.PaymentMethods[0].description,
          value: value.PaymentMethods[0].paymentMethodId
        })
      }
    })
  }

  
getPaycheckType() {
    this.spinner.show();
    this.accountsService.getPaycheckType().subscribe(res => {
      this.PaycheckType= res.data;
      this.spinner.hide();
      for (const value of this.PaycheckType) {
        this.PaycheckTypeArray.push({
          label: value.paycheckType,
          value: value.paycheckType
        })
      }
    })
  }
  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const GrossAmount = res.data.currencyList;
      for (const value of GrossAmount) {
        this.currencyArray.push({
          label: value.uomId,
          value: value.uomId
        })
      }
    })
    this.spinner.hide();
  }
  onSubmit() {
    let eDate = this.createPaycheck.get('EffectiveDate').value;
    this.effectiveDate = this.datePipe.transform(eDate, "yyyy-MM-dd hh:mm:ss");
    
    this.spinner.show();

    const formData = {
      "actualCurrencyAmount": this.createPaycheck.value.Amount,
      "actualCurrencyUomId": this.createPaycheck.value.GrossAmount,

      "amount": this.createPaycheck.value.Amount,
      "comments": this.createPaycheck.value.Comments,

      "currencyUomId": this.createPaycheck.value.GrossAmount,

      "effectiveDate":  this.effectiveDate,

      "partyIdFrom":  "Company",
      "partyIdTo": this.createPaycheck.value.PaytoParty,
      "paycheckType": this.paycheckType,
      "paymentId": this.paymentId ,
      "paymentMethodId": this.createPaycheck.value.PaymentMethod,
      "paymentMethodTypeId": this.paymentMethodTypeId ,
      "paymentRefNum": this.createPaycheck.value.RefNum,
      "paymentTypeId": this.paymentTypeId,
      "statusId":"PMNT_NOT_PAID",
      "numberOfDays": this.createPaycheck.value.numberDays,
      "numberOfHours": this.createPaycheck.value.numberHours,


    }
    this.accountsService.createPayCheck(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Edited Successfully");
        this.spinner.hide();
        this.router.navigate(['/financial/detailed-paycheck'])

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  onSubmitPaycheck() {
    let eDate = this.createPaycheck.get('EffectiveDate').value;
    this.effectiveDate = this.datePipe.transform(eDate, "yyyy-MM-dd hh:mm:ss");
    this.spinner.show();

    const formData = {
      "actualCurrencyAmount": this.createPaycheck.value.Amount,
      "actualCurrencyUomId": this.createPaycheck.value.GrossAmount,
      "amount": this.createPaycheck.value.Amount,
      "comments": this.createPaycheck.value.Comments,
      "currencyUomId": this.createPaycheck.value.GrossAmount,
      "effectiveDate":  this.effectiveDate,
      "partyIdFrom":  "Company",
      "partyIdTo": this.createPaycheck.value.PaytoParty,
      "paycheckType": this.createPaycheck.value.PaycheckType,
      "paymentId": this.paymentId  ,
      "paymentMethodId": this.createPaycheck.value.PaymentMethod,
      "paymentMethodTypeId": this.paymentMethodTypeId ,
      "paymentRefNum": this.createPaycheck.value.RefNum,
      "paymentTypeId": this.paymentTypeId,
      "statusId":"PMNT_NOT_PAID",
      "numberOfDays": this.createPaycheck.value.numberDays,
      "numberOfHours": this.createPaycheck.value.numberHours

    }
    this.accountsService.createPayCheck(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(['/financial/detailed-paycheck'],{queryParams:{paymentId:res.data.paymentId}})



      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });

  }
  detailedPage() {
    this.router.navigate(['/financial/detailed-configuration-page'])
  }

  findPaycheck()
  {
    this.router.navigate(['financial/main-find-paycheck']);
  }

  createPaychecks()
  {
    this.router.navigate(['/financial/main-create-paycheck']);

  }
  paycheckConfiguration()
  {
    this.router.navigate(['/financial/main-configuration']);

  }
  employeess()
  {
    this.router.navigate(['/financial/employees-profile']);

  }

  contractReferencees()
  {
    this.router.navigate(['/financial/contractor']);

  }
  reports()
  {
    this.router.navigate(['/financial/main-reports']);

  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
