<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" routerLink="/facilities/catalog/products">Product </span>
                <span class="color-black pl-1"> > {{editMode ? 'Update': 'Create'}} New</span>
                <button type="button" class="btn btn-danger buttonclass"  (click)="cancelSubmit()">Cancel
                </button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
                        <li [ngClass]="activeCategory==1?'active':''"><a>
                                Price</a></li>
                    </ul>
                </div>
                <br>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="productPriceForm">
                                <div *ngIf="activeCategory==1">
                                   
                                    <div class="panel-body">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="priceType">Price Type</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="priceTypes"
                                                        formControlName="productPriceTypeId"
                                                        placeholder="Price Type" filter="true"></p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="priceType">Purpose</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="purposes" placeholder="Purpose"
                                                            formControlName="productPricePurposeId" filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div> 
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="priceType">Currency UOM ID</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="currencyUomIds"
                                                            formControlName="currencyUomId"
                                                            placeholder="Currency UOM ID" filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="priceType">Product Store Group ID</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="productStores"
                                                            formControlName="productStoreGroupId"
                                                            placeholder="Product Store Group ID" filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div> 
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="fromDate">From Date <span
                                                            class="text-danger">*</span></label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" placeholder="Form Date"
                                                        formControlName="fromDate">
                                                    <small class="text-danger"
                                                        *ngIf="!!productPriceForm.controls.fromDate.invalid && !!productPriceForm.controls.fromDate.touched">From
                                                        Date is required.</small>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="fromDate">Through Date</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" placeholder="Through Date"
                                                        formControlName="thruDate">
                                                    </div>
                                                </div>
                                            </div>
                                           
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="price">Price <span class="text-danger">*</span></label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" placeholder="Price"
                                                        formControlName="price">
                                                    <small class="text-danger"
                                                        *ngIf="!!productPriceForm.controls.price.invalid && !!productPriceForm.controls.price.touched">Price
                                                        is required.</small>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="priceType">Term UOM ID</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="itemUomIds" formControlName="termUomId"
                                                        placeholder="Term UOM ID" filter="true"></p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                         
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="priceType">Custom Price Calc Service</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="customPrice"
                                                        formControlName="customPriceCalcService"
                                                        placeholder="Custom Price Calc Service" filter="true">
                                                    </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="taxPercentage">Tax Percentage</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                        formControlName="taxPercentage" placeholder="Tax Percentage">
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="priceType">Tax Authority Party</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="taxAuthPartyIds"
                                                        formControlName="taxAuthPartyId"
                                                        placeholder="Tax Authority Party" filter="true">
                                                    </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="priceType">Tax Auth Geo ID</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="taxAuthGeoIds"
                                                            formControlName="taxAuthGeoId" placeholder="Tax Auth Geo ID"
                                                            filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                          
                                            <div class="col-lg-12">
                                                <div class="row">
                                                  
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="priceType">Tax in Price</label>
                                                    </div>
                                                  
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="array" formControlName="taxInPrice"
                                                            placeholder="Tax in Price" filter="true">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                          

                                        </div>
                                        <div class="main-submit-button" style="margin-right: 23%;">
                                            <button type="submit" (click)="submit()"
                                                class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>

        </div>
    </div>
</div> -->


<div class="modal fade" id="priceModalCenter" tabindex="-1" role="dialog" aria-labelledby="priceModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
             
                <span class="color-black pl-1">{{editMode ? 'Update': 'Create'}} New Product</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
    
                                <li [ngClass]="activeCategory==1?'active':''"><a>
                                    Price</a></li>
                            </ul>
                        </div>
						
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="productPriceForm">
                                        <div *ngIf="activeCategory==1">
                                           
                                            <div class="panel-body">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="priceType">Price Type</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-3">
                                                                <p-dropdown [options]="priceTypes"
                                                                formControlName="productPriceTypeId"
                                                                placeholder="Price Type" filter="true"></p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="priceType">Purpose</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-3">
                                                                <p-dropdown [options]="purposes" placeholder="Purpose"
                                                                    formControlName="productPricePurposeId" filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="priceType">Currency UOM ID</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-3">
                                                                <p-dropdown [options]="currencyUomIds"
                                                                    formControlName="currencyUomId"
                                                                    placeholder="Currency UOM ID" filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="priceType">Product Store Group ID</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-3">
                                                                <p-dropdown [options]="productStores"
                                                                    formControlName="productStoreGroupId"
                                                                    placeholder="Product Store Group ID" filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="fromDate">From Date <span
                                                                    class="text-danger">*</span></label>
                                                            </div>
                                                          
                                                            <div class="col-lg-3">
                                                                <input type="date" class="form-control" placeholder="Form Date"
                                                                formControlName="fromDate">
                                                            <small class="text-danger"
                                                                *ngIf="!!productPriceForm.controls.fromDate.invalid && !!productPriceForm.controls.fromDate.touched">From
                                                                Date is required.</small>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="fromDate">Through Date</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-3">
                                                                <input type="date" class="form-control" placeholder="Through Date"
                                                                formControlName="thruDate">
                                                            </div>
                                                        </div>
                                                    </div>
                                                   
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="price">Price <span class="text-danger">*</span></label>
                                                            </div>
                                                          
                                                            <div class="col-lg-3">
                                                                <input type="text" class="form-control" placeholder="Price"
                                                                formControlName="price">
                                                            <small class="text-danger"
                                                                *ngIf="!!productPriceForm.controls.price.invalid && !!productPriceForm.controls.price.touched">Price
                                                                is required.</small>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="priceType">Term UOM ID</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-3">
                                                                <p-dropdown [options]="itemUomIds" formControlName="termUomId"
                                                                placeholder="Term UOM ID" filter="true"></p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                 
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="priceType">Custom Price Calc Service</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-3">
                                                                <p-dropdown [options]="customPrice"
                                                                formControlName="customPriceCalcService"
                                                                placeholder="Custom Price Calc Service" filter="true">
                                                            </p-dropdown>
                                                            </div>
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="taxPercentage">Tax Percentage</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-3">
                                                                <input type="text" class="form-control"
                                                                formControlName="taxPercentage" placeholder="Tax Percentage">
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="priceType">Tax Authority Party</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-3">
                                                                <p-dropdown [options]="taxAuthPartyIds"
                                                                formControlName="taxAuthPartyId"
                                                                placeholder="Tax Authority Party" filter="true">
                                                            </p-dropdown>
                                                            </div>
        
                                                            <div class="col-lg-3 form-group classInputA">
                                                                <label for="priceType">Tax Auth Geo ID</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-3">
                                                                <p-dropdown [options]="taxAuthGeoIds"
                                                                    formControlName="taxAuthGeoId" placeholder="Tax Auth Geo ID"
                                                                    filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="col-lg-12">
                                                        <div class="row">
                                                          
                                                            <div class="col-lg-3 form-group classInput">
                                                                <label for="priceType">Tax in Price</label>
                                                            </div>
                                                          
                                                            <div class="col-lg-3">
                                                                <p-dropdown [options]="array" formControlName="taxInPrice"
                                                                    placeholder="Tax in Price" filter="true">
                                                                </p-dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                  
        
                                                </div>
                                                <div class="main-submit-button" style="margin-right: 23%;">
                                                    <button type="submit" (click)="submit()"
                                                        class="btn btn-secondary submit-btn">Submit</button>
        
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
						
						</div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>