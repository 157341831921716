
 <div class="container-fluid main-container-wrapper">
    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Transactions</span>
        <span>
             <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
             <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
         </span> 
     </div>
  
     <div class=" bg-white color-grey">
        
        <div class="w3-card-4 classCard classCardType" >
        <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                <li [ngClass]="activeCategory==1?'active':''" (click)="Authorize()"><a>
                    Authorize</a></li>
                <li [ngClass]="activeCategory==2?'active':''" (click)="Capture()"><a>
                    Capture</a></li>
                    <li [ngClass]="activeCategory==3?'active':''" (click)="GatewayResponses()"><a>
                        Gateway Responses</a></li>
                    <li [ngClass]="activeCategory==4?'active':''" (click)="ManualElectronicTransaction()"><a>
                        Manual Electronic Transaction</a></li>
               
            </ul>
        </div>

        <div class="create-new-leade mt-2">
            <div class="container-fluid">
              
                   
                                <div class="panel-group">
                                    <div *ngIf="activeCategory==3">
                                      
                                       
                                        <div class="panel-collapse">
                                           
                                            <div class="create-new-leade mt-2">
                                                <div class="">
                                                    <form class="w-100" [formGroup]="paymentSearch">
                                                        <div class="">

                                                            <div class="w3-card-4  w-100">
                                                                <div class="accordion classCard" id="myAccordion">
                                                                    <div class="accordion-item">
                                                                        <h2 class="accordion-header" id="headingOne">
                                                                            <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                                                    Gateway Responses
                                                                                <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 78%!important; padding-top: 11px !important;
                                                                                    padding-bottom: 11px !important;">
                                                                                </button>									
                                
                                                                            </div>
                                        
                                                                        </h2>
                                                                        <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                                            <div class="card-body">
                                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                                <label for="exampleInputEmail1">
                                                                                                    Payment Gateway Response ID</label>
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2" style="display: none;">
                                                                                                <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label"
                                                                                                formControlName="billingContains">
                                                                                            </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp" placeholder=" Billing Account IDAccounting Payment Gateway Response ID"
                                                                                                        formControlName="responseId">
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">Accounting Order Payment Preference ID</label>
                                                                                            </div>
                                                                                           
                                                                                            <div class="col-lg-2" style="display: none;">
                                                                                                <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label"
                                                                                                formControlName="accountingContains">
                                                                                            </p-dropdown>
                                                                                            </div>
                                                                    
                                                                                            <div class="col-lg-2">
                                                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                                        aria-describedby="emailHelp" formControlName="paymnetPref"
                                                                                                        placeholder="Accounting Order Payment Preference ID">
                                                                                            </div>
                                                                    
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                                <label for="accountLimit">Payment Service Type Enum ID</label>
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown filter="true" placeholder="Payment Service Type Enum ID"
                                                                                                [options]="enumDataArrayList" optionlabel="label"  formControlName="servicePayment">
                                                                                            </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">Payment Method Type</label>
                                                                                            </div>
                                                                                           
                                                                                          
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown filter="true" 
                                                                                                        formControlName="paymnetMethodType" placeholder="Payment Method Type"
                                                                                                        [options]="getPaymentMethodTypeArray" optionlabel="label">
                                                                                                    </p-dropdown>
                                                                                            </div>
                                                                    
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                    
                                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                                <label for="exampleInputEmail1">Trans Code Enum ID</label>
                                                                                            </div>
                                                                                         
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown filter="true"  placeholder="Trans Code Enum ID"
                                                                                                        formControlName="transCode"
                                                                                                        [options]="enumDataArray" optionlabel="label">
                                                                                                    </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                                <label for="exampleInputEmail1">
                                                                                                    Reference Num</label>
                                                                                            </div>
                                                                                           
                                                                                          
                                                                                            <div class="col-lg-2" style="display: none;">
                                                                                                <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label"
                                                                                                formControlName="refContains">
                                                                                            </p-dropdown>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                                aria-describedby="emailHelp" placeholder="Reference Num"
                                                                                                formControlName="ReferenceNum">
                                                                                            </div>
                                                                    
                                                                                        </div>
                                                                                    </div>
                                                                                   
                                                                                 
                                                                                  
                                                                                </div>
            
                                                                                <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -18%;">
                                                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn"
                                                                                      (click)="onSubmit()" >Find</button>&nbsp;
                                                                                    <button type="button" (click)="reset()" class="btn btn-danger">Reset</button>
                                                                                </div>
                                                                                
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>

                                                            <!-- <div class="w3-card-4 classCardA" >
                                                             
                                                                <br>
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" >
                                                                            <label for="exampleInputEmail1">
                                                                                Payment Gateway Response ID</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label"
                                                                            formControlName="billingContains">
                                                                        </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp" placeholder=" Billing Account IDAccounting Payment Gateway Response ID"
                                                                                    formControlName="responseId">
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Accounting Order Payment Preference ID</label>
                                                                        </div>
                                                                       
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label"
                                                                            formControlName="accountingContains">
                                                                        </p-dropdown>
                                                                        </div>
                                                
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                                                    aria-describedby="emailHelp" formControlName="paymnetPref"
                                                                                    placeholder="Accounting Order Payment Preference ID">
                                                                        </div>
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" >
                                                                            <label for="accountLimit">Payment Service Type Enum ID</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" placeholder="Payment Service Type Enum ID"
                                                                            [options]="enumDataArrayList" optionlabel="label"  formControlName="servicePayment">
                                                                        </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Payment Method Type</label>
                                                                        </div>
                                                                       
                                                                      
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true" 
                                                                                    formControlName="paymnetMethodType" placeholder="Payment Method Type"
                                                                                    [options]="getPaymentMethodTypeArray" optionlabel="label">
                                                                                </p-dropdown>
                                                                        </div>
                                                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                                
                                                                        <div class="col-lg-3 form-group classInput" >
                                                                            <label for="exampleInputEmail1">Trans Code Enum ID</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"  placeholder="Trans Code Enum ID"
                                                                                    formControlName="transCode"
                                                                                    [options]="enumDataArray" optionlabel="label">
                                                                                </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">
                                                                                Reference Num</label>
                                                                        </div>
                                                                       
                                                                      
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown filter="true" [options]="opportunityIdArray" optionlabel="label"
                                                                            formControlName="refContains">
                                                                        </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                                            aria-describedby="emailHelp" placeholder="Reference Num"
                                                                            formControlName="ReferenceNum">
                                                                        </div>
                                                
                                                                    </div>
                                                                </div>
                                                               
                                                             
                                                              
                                                            </div>
                                                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -18%;">
                                                                <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn"
                                                                  (click)="onSubmit()" >Find</button>&nbsp;
                                                                <button type="button" (click)="reset()" class="btn btn-danger">Reset</button>
                                                            </div>
                                                            </div> -->
                                                            
                                                            <br>
                                                            
                                                            <div class="w3-card-4 classCard" style=" width: 100%;">
                                                                <header class="w3-container w3-blue">
                                                                  <div class="header-tabs">
                                                                    <h4 class=" common-styling h4M">
                                                                        Search Results
                                                                    </h4>
                                                                 
                                                                  </div>
                                                                </header>
                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select" style="display: block!important;">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
                                                                       
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="gateway" [paginator]="true" [rows]="rows"
                                                                                scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [totalRecords]="total" [scrollable]="true"
                                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input
                                                                                                                type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                
                                    Payment Gateway Response ID <p-sortIcon field="code"></p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="name" [ngStyle]="{'width':'170px'}"><div style="color: white;">Payment Service Type Enum ID <p-sortIcon field="name">
                                                                                            </p-sortIcon></div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="category" [ngStyle]="{'width':'170px'}"><div style="color: white;"> Order Payment Preference ID <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon></div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Payment Method Type</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Payment Method ID</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Trans Code Enum ID</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Amount</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Currency</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Reference Num</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th><th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">	Alt Reference</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th><th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Sub Reference</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th><th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Gateway Code</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th><th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Gateway Flag</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th><th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Gateway Avs Result</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th><th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Gateway Cv Result</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th><th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Gateway Score Result</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th><th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Gateway Message</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th><th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Transaction Date</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th><th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Result Declined</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th><th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Result Nsf</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th><th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Result Bad Expire</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Result Bad Card Number</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input
                                                                                                                type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span class="account-button" (click)="viewPayment(product.paymentGatewayResponseId);"
                                                                                                   >{{product.paymentGatewayResponseId}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.paymentServiceTypeEnumId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.orderPaymentPreferenceId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.paymentMethodTypeId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.paymentMethodId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.transCodeEnum}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.amount}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.currencyUomId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.referenceNum}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.altReference}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.subReference}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.gatewayCode}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.gatewayFlag}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.gatewayAvsResult}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.gatewayCvResult}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.gatewayScoreResult}}</td>
                                                                                          <td [ngStyle]="{'width':'170px'}">{{product.gatewayMessage}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.transactionDate}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.resultDeclined}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.resultNsf}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.resultBadExpire}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.resultBadCardNumber}}</td>
                                                                
                                                                                    </tr>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found</h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                    
                                                        </div>
                                                    </form>
                                                </div>
                                    
                                            </div>
                                        </div>
                                    </div>
                                 


                                </div>
                          
            
            </div>
        </div>
        </div>
    </div>
 </div>
 <ngx-spinner></ngx-spinner> 