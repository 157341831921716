import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-financail-account',
  templateUrl: './add-financail-account.component.html',
  styleUrls: ['./add-financail-account.component.css']
})
export class AddFinancailAccountComponent implements OnInit {
  show: boolean;
  @ViewChild('closebutton') closebutton;
  createFinancial: FormGroup;
  FinAccountTypeList: any;
  FinAccountTypeListArray: any[] = [];
  FinAccountStatusList: any;
  FinAccountStatusListArray: any[] = [];
  currencyArray: any[] = [];
  refundable: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[] = []
  total=0;
  rowNumber=0;
  pageSize= 100;
  pageNo = 1;
  rows = 50;
  search=""
  readonly finSize={
    pageNo:this.pageNo,
    pageSize:this.pageSize,

  }
  actualfromdate: string;
  actualthroughdate: string;
  finAccountId: any;
  activeCategory = 2;
  getEstimateFrom: string;
  getEstimateTo: string;
  giAccountArray: any[]=[];
  id: any;

  advanceSearch: {

    finAccountId: string,
    finAccountIdSearchType: string,
    finAccountName: string,
    finAccountNameSearchType: string,
    finAccountTypeId: string,


  };
  activeCategoryMain=1;
  products3: any;
 
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  financialList: any;

  createUrl: string;
  constructor(readonly spinner: NgxSpinnerService, 
    readonly accountsService: AccountsService, readonly toastr: ToastrService,
    readonly datePipe: DatePipe,
 
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly addSkillService: AddSkillService,
    readonly router: Router,
    private _location: Location,
    ) {
      this.refundable = [{
        label: "Y",
        value: "Y"
      },
      {
        label: "N",
        value: "N"
      }]
      this.createFinancial = this._FormBuilder.group({
  
        actualBalance: [''],
        availableBalance: [''],
        currencyUomId: ['',[Validators.required]],
        finAccountCode: [''],
        finAccountName: [''],
        finAccountPin: [''],
        finAccountTypeId: ['',[Validators.required]],
        fromDate: ['',[Validators.required]],
        isRefundable: ['',[Validators.required]],
        organizationPartyId: ['',[Validators.required]],
        ownerPartyId: [''],
        postToGlAccountId: [''],
        replenishLevel: [''],
        replenishPaymentId: [''],
        statusId: ['',[Validators.required]],
        thruDate: ['']
  
  
      });
      this.createUrl="financial/create-financial-account";
    this.advanceSearch = {

      finAccountId: '',
      finAccountIdSearchType: 'Contains',
      finAccountName: '',
      finAccountNameSearchType: 'Contains',
      finAccountTypeId: '',


    };
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getFinancailAccount();
    this.getFinAccountTypeList();
     this.show=false;
    this.getFinAccountStatusList();
    this.getCurrency();
    this.getPartyId();
    this.getGiAccountId();
  }


  backWindow(){
    this._location.back();
  }
    toDashboard()
  {
  this.router.navigate(['/financial/main']);
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
 
  getGiAccountId() {
    this.spinner.show();
    this.accountsService.getGioAccountId().subscribe(res => {
      const giAccount = res.data.glAccounts;
      this.spinner.hide();
       for (const value of giAccount) {
        this.giAccountArray.push({
          label: value.glAccountId,
          value: value.glAccountId
        })
      } 
    })
   
  }

  getCurrency() {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe(res => {
      const currency = res.data.currencyList;
      for (const value of currency) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
    this.spinner.hide();
  }
  getFinAccountStatusList() {
    this.spinner.show();
    this.accountsService.getFinAccountStatusList().subscribe(res => {
      this.FinAccountStatusList = res.data;
      for (const value of this.FinAccountStatusList) {
        this.FinAccountStatusListArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
    this.spinner.hide();
  }


 

  submit(): void {
    this.spinner.show();
    const fromActualdate=this.createFinancial.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createFinancial.get('thruDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "actualBalance":0,"availableBalance": 0,"currencyUomId": this.createFinancial.value.currencyUomId,
      "finAccountCode": this.createFinancial.value.finAccountCode,"finAccountName": this.createFinancial.value.finAccountName,"finAccountPin": this.createFinancial.value.finAccountPin,
      "finAccountTypeId": this.createFinancial.value.finAccountTypeId, "fromDate": this.actualfromdate,
      "isRefundable": this.createFinancial.value.isRefundable,"organizationPartyId": this.createFinancial.value.organizationPartyId,
      "ownerPartyId": this.createFinancial.value.ownerPartyId,"postToGlAccountId": this.createFinancial.value.postToGlAccountId,
      "replenishLevel": this.createFinancial.value.replenishLevel,"replenishPaymentId": this.createFinancial.value.replenishPaymentId,
      "statusId": this.createFinancial.value.statusId,"thruDate":this.actualthroughdate     
    }
    this.accountsService.postFinancialAccount(requestData).subscribe((res: any) => {
      if (res.success) {
        this.id=res.data.finAccountId;
        this.toastr.success("Created Successfully");
        this.closebutton.nativeElement.click();
        this.spinner.hide();
        this.router.navigate(["financial/detail-financial-summary"], { queryParams: { finAccountId: this.id } })
      } }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    })}
  accountDetails(id) {
    this.router.navigate(["financial/detail-financial-summary"], { queryParams: { finAccountId: id } })
  }
  financialUrl(){
    this.router.navigate(["financial/financial-account"])
  }
  CheckURL(){
    this.router.navigate(["financial/financial-check"])
  }
  onUpdate(id) {
    this.router.navigate([this.createUrl], { queryParams: { finAccountId: id } })
  }
 /*  createFinancial() {
    this.router.navigate([this.createUrl])

  }
 */
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  getFinancailAccount() {
    this.spinner.show();
    const req = {
      "finAccountId": "",
      "finAccountIdSearchType": "Contains",
      "finAccountName": "",
      "finAccountNameSearchType": "Contains",
      "finAccountTypeId": "",

    }
    this.accountsService.getFinancailAccount(this.finSize, req).subscribe((res: any) => {
      this.financialList = res.data;
      this.spinner.hide();

    })
   
  }
  onDelete(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteFinancailAccount(id).subscribe((res: any) => {
          this.getFinancailAccount();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getFinAccountTypeList() {
    this.spinner.show();
    this.accountsService.getFinAccountTypeList().subscribe(res => {
      this.FinAccountTypeList = res.data;
      this.spinner.hide();
      for (const value of this.FinAccountTypeList) {
        this.FinAccountTypeListArray.push({
          label: value.description,
          value: value.finAccountTypeId
        })
      }
    })
  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.financialList = [];
    const req = {
      "finAccountId": this.advanceSearch.finAccountId ? this.advanceSearch.finAccountId : '',
      "finAccountIdSearchType": this.advanceSearch.finAccountIdSearchType ? this.advanceSearch.finAccountIdSearchType : '',
      "finAccountName": this.advanceSearch.finAccountName ? this.advanceSearch.finAccountName : '',
      "finAccountNameSearchType": this.advanceSearch.finAccountNameSearchType ? this.advanceSearch.finAccountNameSearchType : '',
      "finAccountTypeId": this.advanceSearch.finAccountTypeId ? this.advanceSearch.finAccountTypeId : '',
    }
    this.accountsService.getFinancailAccount(this.finSize, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.financialList = resp.data;
        } 
      });
     
  }
  reset() {
    this.spinner.show();
    this.advanceSearch.finAccountId = "";
    this.advanceSearch.finAccountName = "";
    this.advanceSearch.finAccountTypeId = "";
    this.financialList = []
    const req = {
      "finAccountId": "",
      "finAccountIdSearchType": "Contains",
      "finAccountName": "",
      "finAccountNameSearchType": "Contains",
      "finAccountTypeId": "",

    }
    this.accountsService.getFinancailAccount(this.finSize, req).subscribe((res: any) => {
      this.financialList = res.data;
      this.spinner.hide();

    })
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
