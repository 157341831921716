<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">

            <div class="col-md-12 p-0">
                <div class="w3-card-4 classCard  w-100">
                    <div class="col-lg-12 mt-3" style="margin-bottom: 10px !important; ">
                        <div class="row d-flex justify-content-between">
                            <div class="font-12" style="font-weight:bold; margin-left: 18px !important;">
                                <label>Default Service Team</label>
                            </div>
                            <div>
                                <button style="margin-right:12px !important"
                                    class="btn btn-secondary submit-btn" (click)="toBack()">Back</button>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-lg-12">
                        <div class="row mb-1" style="margin-top: 0px !important;margin-left: 0% !important; ">
                            <button type="submit" class="btn btn-secondary submit-btn ">Delete</button>
                            <button type="submit" class="btn btn-secondary submit-btn ml-2">Printer Friendly</button>

                        </div>

                    </div> -->

                    <div class="row " style="width: 100%; margin-top: 1%;">
                        <div class="col-md-8">
                            <div class="w3-card-4 classCard" style="margin-left: 18px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color: black;">
                                        Team Profile</p>
                                    <!-- <span style="margin-left: 1%; color :black;"> | </span>
                                     <a class="btn btn-link" style="color: black; font-weight: bold;"
                                        data-target="#editTeamProfilePopup" data-toggle="modal">
                                        Edit</a>  -->
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">

                                                    <div class="color-black container">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-12 col-12">Service Team Name</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{
                                                                            this.name}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>

                        <div class="col-md-4">
                            <div class="w3-card-4 classCard" style="margin-left: 6px;margin-right: -18px;">
                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                                    style="background-color:rgb(206, 214, 218); padding: 0px 15px; margin-top: 0px !important;">
                                    <p class="h-text color-grey"
                                        style="margin: 0px 15px -1px -2px !important; color: black;">
                                        Area Serviced</p>
                                    <!-- <span style="margin-left: 1%; color :black;"> | </span>
                                     <a class="btn btn-link" style="color: black; font-weight: bold;"
                                        data-target="#editAreaServicedPopup" data-toggle="modal">Edit</a>  -->
                                </div>
                                <div class="">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <form class="w-100">
                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                    <div class="color-black container">
                                                        <div class="row">
                                                            <div class="article-container">
                                                                <div class="article">

                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{
                                                                        this.areaServiced}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>
                    </div>



                    <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: 0%;">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="border: 1px solid lightcyan !important;">
                                    <div class="d-flex justify-content-start" style="background-color:rgb(206, 214, 218);color: black !important;
                                    border: 1px solid lightcyan !important; margin: 0px !important ; padding: 3px !important;
                                    font-size: 15px ;  font-weight: bold;
                                    padding-left: 7px !important">
                                        <span style="padding-top: 9px;">Service Team Member</span>
                                        <span class="ml-5" style="padding-top: 8px; font-weight: bold;">|</span>
                                        <span><a class="ml-2 btn btn-link" data-toggle="modal" style="font-weight:bold;"
                                                data-target="#editServiceTeamMemberPopup">Edit</a></span>
                                    </div>
                                    <p-table [value]="workZoneServiceMemberList" [paginator]="true" [rows]="rows"
                                        scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                        [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)"
                                        [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background:rgb(206, 214, 218)">
                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: black;">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        Display As
                                                        <p-sortIcon field="code">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Role
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Phone
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>


                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'150px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        <span  class="account-button"  >
                                                            {{product.memberDisplayAs}}
                                                        </span>

                                                    </div>
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.role}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.phone}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">
                                                    <button type="button" class="btn btn-secondary submit-btn ml-2"
                                                        data-toggle="modal" data-target="#editServiceTeamMemberPopup"
                                                        (click)="getFsmWorkZoneServiceMemberById(product)">Edit</button>
                                                    <button type="button" class="btn btn-secondary submit-btn ml-2"
                                                        (click)="deleteFsmWorkZoneServiceMember(product)">Delete</button>
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">No Record Found</h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <p class="paginate_data">
                                        View per page

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal fade" id="editServiceTeamMemberPopup" tabindex="-1" role="dialog"
    aria-labelledby="editServiceTeamMemberPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Service Team Member</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add Service Team Member</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="addServiceTeamMemberForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Dispaly As</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="" placeholder="Enter Name"
                                                            formControlName="displayAs">

                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Role</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="" placeholder="Enter Role"
                                                            formControlName="role">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Phone</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="" placeholder="Enter Phone"
                                                            formControlName="phone">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 46%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="!this.show"
                                                    (click)="createFsmWorkZoneServiceMember()">Save</button>
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    *ngIf="this.show"
                                                    (click)="updateFsmWorkZoneServiceMember()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="editTeamProfilePopup" tabindex="-1" role="dialog" aria-labelledby="editTeamProfilePopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Edit Team Profile</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Edit Team Profile</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editTeamProfileForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                            <div class="col-lg-5 main-submit-button" style="margin-left: 52%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Save</button>
                                                <button type="submit"
                                                    class="btn btn-secondary submit-btn">Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="editAreaServicedPopup" tabindex="-1" role="dialog" aria-labelledby="editAreaServicedPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Edit Area Serviced</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-black color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Edit Area Serviced</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editAreaServicedForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">


                                            <div class="col-lg-5 main-submit-button" style="margin-left: 52%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Save</button>
                                                <button type="submit"
                                                    class="btn btn-secondary submit-btn">Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>