import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CustomerAgreementsService } from '../customer-agreements/customer-agreements.service';
import { NewWarehouseShipmentService } from 'src/app/warehouse/new-warehouse-shipment/new-warehouse-shipment.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-create-customer-agreements',
  templateUrl: './create-customer-agreements.component.html',
  styleUrls: ['./create-customer-agreements.component.css']
})
export class CreateCustomerAgreementsComponent implements OnInit {

  createCustomerAgreements: FormGroup;
  PersonsAndPartyGroupsList: any = [];
  productList: any = [];
  AgreementTypeList: any = [];
  RoleTypesList: any = [];
  editMode: boolean;
  activeCategory = 2;
  agreementType: string;
  agreementDate: any;
  thruDate: any;
  fromDate: any;
  constructor(readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly CustomerAgreementsService: CustomerAgreementsService,
    readonly NewWarehouseShipmentService: NewWarehouseShipmentService,
    readonly toastr: ToastrService,
    readonly datePipe: DatePipe,
    readonly _FormBuilder: FormBuilder) {
    this.createCustomerAgreements = this._FormBuilder.group({
      statusId: [''],
      agreementTypeId: ['', [Validators.required]],
      agreementDate: [''],
      description: [''],
      fromDate: [''],
      partyIdFrom: [''],
      partyIdTo: [''],
      productId: [''],
      roleTypeIdFrom: [''],
      roleTypeIdTo: [''],
      textData: [''],
      thruDate: ['']
    });
    this.editMode = false;
    this.agreementType = '';
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
    this.getPersonsAndPartyGroups();
    this.getProducts();
    this.getRoleTypes();
    this.getAgreementTypeList();
  }


  submit(): void {
    this.spinner.show();
    if (this.createCustomerAgreements.valid) {
      const agreementDate = this.createCustomerAgreements.get('agreementDate').value;
      this.agreementDate = this.datePipe.transform(agreementDate, "yyyy-MM-dd hh:mm:ss");
      const thruDate = this.createCustomerAgreements.get('thruDate').value;
      this.thruDate = this.datePipe.transform(thruDate, "yyyy-MM-dd hh:mm:ss");
      const fromDate = this.createCustomerAgreements.get('fromDate').value;
      this.fromDate = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");
      const requestData = {
        agreementDate: this.agreementDate,
        agreementTypeId: this.createCustomerAgreements.value.agreementTypeId.agreementTypeId,
        description: this.createCustomerAgreements.value.description,
        fromDate: this.fromDate,
        partyIdFrom: this.createCustomerAgreements.value.partyIdFrom.partyId,
        partyIdTo: this.createCustomerAgreements.value.partyIdTo.partyId,
        productId: this.createCustomerAgreements.value.productId.productId,
        roleTypeIdFrom: this.createCustomerAgreements.value.roleTypeIdFrom.roleTypeId,
        roleTypeIdTo: this.createCustomerAgreements.value.roleTypeIdTo.roleTypeId,
        textData: this.createCustomerAgreements.value.textData,
        thruDate: this.thruDate
      };
      this.CustomerAgreementsService.createAgreementAccnAR(requestData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.router.navigate([`/financial/${this.agreementType}/customer-agreeement`]);
        } }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          } } });
    } else {
      this.spinner.hide();
      this.toastr.error('Please fill required field');
    }}

  getPersonsAndPartyGroups() {
    this.spinner.show();
    this.NewWarehouseShipmentService.getPersonsAndPartyGroups().subscribe((responce) => {
      this.spinner.hide();
      this.PersonsAndPartyGroupsList = responce.data.PersonsGroups;
    });
  
  }

  getProducts() {
    this.spinner.show();
    const requestData = {
      internalName: "",
      internalNameSearchType: "Contains",
      productId: "",
      productIdSearchType: "Contains"
    };
    this.CustomerAgreementsService.getProducts(requestData).subscribe((responce) => {
      this.spinner.hide();
      this.productList = responce.data;
    });
   
  }

  getRoleTypes() {
    this.spinner.show();
    this.CustomerAgreementsService.getRoleTypes().subscribe((responce) => {
      this.spinner.hide();
      this.RoleTypesList = responce.data;
    });
   
  }

  getAgreementTypeList() {
    this.spinner.show();
    this.CustomerAgreementsService.getAgreementTypeList().subscribe((responce) => {
      this.spinner.hide();
      this.AgreementTypeList = responce.data;
    });

  }
  reset(): void {
    this.createCustomerAgreements.reset();
    this.router.navigate([`/financial/${this.agreementType}/customer-agreeement`]);
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
