
<div class="w3-card-4 classCard">
    <!-- <div class="container-fluid">
        <ul class="tabbing-section tabbing-accordians tabClass">

            <li [ngClass]="activeCategory==2?'active':''"><a>
                User Dashboard</a></li>
           

        </ul>
    </div> -->

    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="heads"> User Dashboard</div>

                <div id="chart" style="margin:35px 0px !important;">
                    
                        <apx-chart
                          [series]="chartOptions.series"
                          [chart]="chartOptions.chart"
                          [dataLabels]="chartOptions.dataLabels"
                          [plotOptions]="chartOptions.plotOptions"
                          [yaxis]="chartOptions.yaxis"
                          [xaxis]="chartOptions.xaxis"
                          [legend]="chartOptions.legend"
                          [colors]="chartOptions.colors"
                          [grid]="chartOptions.grid"
                        ></apx-chart>
                     
                      
                  </div>

                <!-- <script src="https://cdn.amcharts.com/lib/4/core.js"></script>
                <script src="https://cdn.amcharts.com/lib/4/charts.js"></script>
                <script src="https://cdn.amcharts.com/lib/4/themes/animated.js"></script>
                <div id="chartdiv77" style="   width: 100%;
                height: 435px;"></div> -->
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 
