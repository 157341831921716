import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import * as Q from 'q';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';


import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-catalog',
  templateUrl: './detail-catalog.component.html',
  styleUrls: ['./detail-catalog.component.css']
})
export class DetailCatalogComponent implements OnInit {
  
  activeCategory = 1;
  


  @ViewChild('closebuttonA') closebuttonA;
  @ViewChild('closebuttonB') closebuttonB;
  @ViewChild('closebuttonC') closebuttonC;
  @ViewChild('closebuttonD') closebuttonD;
  @ViewChild('closebutton5') closebutton5;
  @ViewChild('closebutton6') closebutton6;
  @ViewChild('closebutton7') closebutton7;
  @ViewChild('closebutton8') closebutton8;
  @ViewChild('closebutton9') closebutton9;
  @ViewChild('closebutton10') closebutton10;
  @ViewChild('closebutton11') closebutton11;
  @ViewChild('closebutton12') closebutton12;
  @ViewChild('closebutton13') closebutton13;
  @ViewChild('closebutton14') closebutton14;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton15') closebutton15;


  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;


  addProductCatalogForm:FormGroup;
  updateProductCatalogForm:FormGroup;
  catalogForm:FormGroup;
  editProductCatalogPartiesForm:FormGroup;
  addProductCatalogStoresForm:FormGroup;
  editProductCatalogStoresForm:FormGroup;
  addProductCatalogPartiesForm:FormGroup;
  addProductCatalogCategoriesForm:FormGroup;
  editProductCatalogCategoriesForm:FormGroup;

  newProductPromo: FormGroup;
  editProductPromo: FormGroup;
  productPromoId: any;
  editProductStore: FormGroup;
  addProductStore: FormGroup;
  uploadPromotionCodes: FormGroup;
  addPromotionCodes: FormGroup;
  newPromotionCode: FormGroup;
  editContents: FormGroup;
  updateRule: FormGroup;
  conditionRuleForm: FormGroup;
  updateAction: FormGroup;
  createAction: FormGroup;
  actionCategory: FormGroup;
  createProducts: FormGroup;
  newPromoRule: FormGroup;
  addPromoCategory: FormGroup;
  addPromoProduct: FormGroup;
  arrayValue = [{
    'label': 'Y',
    'value': 'Y'
  },
  {
    'label': 'N',
    'value': 'N'
  }]

  partyId:string;
  role:string;
  fromDate:string;
  thorughDate:string;
  sequenceNum:string;
  store:string;
  categoryId:string;
  productCatalogCateogry:string;
  personId: any;
  partyIdArray: any[] = [];
  ProductByProductPromoId: any;
  getEstimateFrom: string;
  getEstimateTo: string;
  ActualCompletionDate: string;
  ActualStartDate: string;
  ProductStoreByProductList: any;
  ProductStoreId: any;
  ProductStoreIdArray: any[] = [];
  storeUpdate: any;
  PromotionCodeByProductPromoIdList: any;
  PromotionCodeListList: any;
  yesNoArray: { label: string; value: string; }[];
  PromotionCodeList: any;
  PromotionCodeListArray: any[] = [];
  fileData: File;
  showImport: boolean;
  promoCodeLayout: any;
  promoCodeLayoutArray: any[] = [];
  promotionCodeData: any;
  id: any;
  PromoId: any;
  updatePromotionCode: FormGroup;
  addPartyForm: FormGroup;
  promoCodeEmailsForm: FormGroup;
  promoCodePartiesList: any;
  productPromoCodeId: any;
  conditionValues: any;
  conditionValuesArray: any[] = [];
  enumCodeId: any;
  enumCodeIdArray: any[] = [];
  productPromoEnumTypeIdList: any;
  productPromoEnumTypeIdListArray: any[] = [];
  ShipmentMethodList: any;
  ShipmentMethodListArray: any[] = [];
  productCategoryList: any;
  productCategoryListArray: any[] = [];
  promotionCategoryList: any;
  promotionProductList: any;
  actionTypePriceRules: any;
  CatalogProductId: any;
  CatalogProductIdArray: any[] = [];
  CustomMethodIdArray: any[] = [];
  CustomMethodId: any;
  promoRulesList: any;
  updateRulesList: any;
  AddConditionCategoryForm: FormGroup;
  createConditionForm: FormGroup;
  actionData:any;
  createProductsForm: FormGroup;
  showPromo: boolean;
  show: boolean;
  prodCatalogId: any;
  fromDateCatePatch: string;
  updateProdCatalogStorepat: any;
  readOnlyCode=false;
  throughDateCatePatch: string;
  storeId: any;
  storeIdArray: any[]=[];
  editProdCatalogStoresAll: any;
  allProdCatalogStores: any;
  ProdCatalogToPartyById: any;
  updateProdCatalogToPartyPat: any;
  RoleTypeList: any;
  RoleTypeArray: any[]=[];
  RoleTypeListNew: any;
  RoleTypeListNewArray: any[]=[];
  ProductCategoryTypeList: any;
  ProductCategoryTypeListArray: any[]=[];
  ProductCategoryToProdCatalogById: any;
  updateProductCategoryToProdCatalogPat: any;
  allCategoryId: any;
  allCategoryIdArray: any[]=[];
  YesNoArray: { label: string; value: string; }[];
  productCatalogAll: any;
  updateProductCatalogNewPat: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  constructor(

    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly addSkillService: AddSkillService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
  private _location: Location,
  readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly activatedRoute: ActivatedRoute,
    readonly _Router: Router
  ) 


 {
  this.catalogForm = this._FormBuilder.group({
    catalogName: ['', [Validators.required]],
    contentPathPrefix: [''],
    headerLogo: [''],
    prodCatalogId: [''],
    purchaseAllowPermReqd: ['N'],
    styleSheet: [''],
    templatePathPrefix: [''],
    useQuickAdd: ['Y'],
    viewAllowPermReqd: ['N']
  });
  this.YesNoArray = [{
    label: 'Yes',
    value: 'Y'
  },
  {
    label: 'No',
    value: 'N'
  }];
  this.addProductCatalogForm = this._FormBuilder.group({
    catalogName: ['', [Validators.required]],
    contentPathPrefix: [''],
    headerLogo: [''],
    prodCatalogId: [''],
    purchaseAllowPermReqd: ['N'],
    styleSheet: [''],
    templatePathPrefix: [''],
    useQuickAdd: ['Y'],
    viewAllowPermReqd: ['N']
  });

    this.editProductCatalogPartiesForm = this._FormBuilder.group({
      partyId:"",
      role:"",
      fromDate:"",
      thorughDate:"",
      sequenceNum:"",
      
    });

    this.addProductCatalogPartiesForm = this._FormBuilder.group({
      partyId:"",
      role:"",
      fromDate:"",
      thorughDate:"",
      sequenceNum:"",
      
    });

    this.addProductCatalogStoresForm = this._FormBuilder.group({
      store:"",
      fromDate:"",
      thorughDate:"",
      sequenceNum:"",
      
    });

    this.editProductCatalogStoresForm = this._FormBuilder.group({
      store:"",
      fromDate:"",
      thorughDate:"",
      sequenceNum:"",

    });

    this.addProductCatalogCategoriesForm = this._FormBuilder.group({
      categoryId:"",
      productCatalogCateogry:"",
      fromDate:"",
      thorughDate:"",
      sequenceNum:"",
      
    });

    this.editProductCatalogCategoriesForm = this._FormBuilder.group({
      categoryId:"",
      productCatalogCateogry:"",
      fromDate:"",
      thorughDate:"",
      sequenceNum:"",
      
    });

    

    

    this.newProductPromo = this._FormBuilder.group({
      promoName: [''],
      promoText: [''],
      userEntered: [''],
      promoShow: [''],
      reqCode: [''],
      limitOrder: [''],
      limitCust: [''],
      limitPromo: [''],
      billbackFactor: [''],
      orgPartyID: [''],
      createdDate: [''],
      createdByUser: [''],
      modifiedDate: [''],
      modifiedByUser: [''],
    });
    this.editProductPromo = this._FormBuilder.group({
      promoName: [''],
      promoText: [''],
      userEntered: [''],
      promoShow: [''],
      reqCode: [''],
      limitOrder: [''],
      limitCust: [''],
      limitPromo: [''],
      billbackFactor: [''],
      orgPartyID: [''],
      createdDate: [''],
      createdByUser: [''],
      modifiedDate: [''],
      modifiedByUser: [''],
    });
    this.editProductStore = this._FormBuilder.group({
      storeName: [''],
      fromDate: [''],
      thruDate: [''],
      seq: [''],
    });
    this.addProductStore = this._FormBuilder.group({
      storeName: [''],
      fromDate: ['']
    });
    this.uploadPromotionCodes = this._FormBuilder.group({
      userEntered: [''],
      regEmailParty: [''],
      limitCode: [''],
      limitCust: [''],
      file: ['']
    });
    this.addPromotionCodes = this._FormBuilder.group({
      quantity: [''],
      codeLength: [''],
      codeLayout: [''],
      userEntered: [''],
      regEmailParty: [''],
      limitCode: [''],
      limitCust: [''],
    });
    this.newPromotionCode = this._FormBuilder.group({
      promoCodeID: [''],
      promoID: [''],
      userEntered: [''],
      reqEmailParty: [''],
      limitCode: [''],
      limitCust: [''],
      fromDate: [''],
      thruDate: [''],
      createdDate: [''],
      createdUser: [''],
      modifiyDate: [''],
      modifyUser: [''],
    });
    this.updatePromotionCode = this._FormBuilder.group({
      promoCodeID: [''],
      promoID: [''],
      userEntered: [''],
      reqEmailParty: [''],
      limitCode: [''],
      limitCust: [''],
      fromDate: [''],
      thruDate: [''],
      createdDate: [''],
      createdUser: [''],
      modifiyDate: [''],
      modifyUser: [''],
      LastModifiedBy: [''],
      CreatedBy: [''],
    });
    this.addPartyForm = this._FormBuilder.group({
      PartyID: [''],
    });
    this.editContents = this._FormBuilder.group({
      fromDate: [''],
      thruDate: [''],
      file: ['']
    });
    this.updateRule = this._FormBuilder.group({
      ruleName: ['']
    });
    this.newPromoRule = this._FormBuilder.group({
      ruleName: ['']
    });
    this.conditionRuleForm = this._FormBuilder.group({
      conditionFor: [''],
      conditionLogic: [''],
      conditionValue: [''],
      conditionOther: [''],
      conditionShippingMethod: [''],
    });
    this.updateAction = this._FormBuilder.group({
      actionFor: [''],
      quantity: [''],
      amount: [''],
      itemID: [''],
      party: [''],
      serviceName: [''],
      cartQty: [''],
    });
    this.createAction = this._FormBuilder.group({
      actionFor: [''],
      quantity: [''],
      amount: [''],
      itemID: [''],
      party: [''],
      serviceName: [''],
      cartQty: [''],
    });
    this.AddConditionCategoryForm = this._FormBuilder.group({
      category: [''],
      categoryType: [''],
      categoryConsent: [''],
      group: [''],
    });
    this.actionCategory = this._FormBuilder.group({
      category: [''],
      categoryType: [''],
      categoryConsent: [''],
      group: [''],
    });
    this.promoCodeEmailsForm = this._FormBuilder.group({
      AddEmail: [''],
      File: [''],
    });
    this.addPromoCategory = this._FormBuilder.group({
      category: [''],
      categoryType: [''],
      categoryConsent: [''],
      group: [''],
    });
    this.createProducts = this._FormBuilder.group({
      product: [''],
      productType: [''],
    });
    this.createProductsForm = this._FormBuilder.group({
      product: [''],
      productType: [''],
    });
    this.addPromoProduct = this._FormBuilder.group({
      product: [''],
      productType: [''],
    });
    this.createConditionForm = this._FormBuilder.group({
      enumCode: [''],
      condition: [''],
      ConditionVal: [''],
      other: [''],
      ShippingMethod: [''],
    });
    
    this.yesNoArray = [
      {
        label: "Y",
        value: "Y"
      },
      {
        label: "N",
        value: "N"
      },
    ]
   }

   ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.Hidebtn=true;
    }
    this.activatedRoute.queryParams.subscribe(params => {
      this.productPromoId = params["productPromoId"];
      this.productPromoCodeId = params["productPromoCodeId"];
      this.prodCatalogId = params["prodCatalogId"];


    });
    this.show=true;
    this.showImport = true;

    setTimeout(() => {
      const formValue = this.newPromotionCode;
      formValue.patchValue({
        promoID: this.productPromoId
      })

    }, 2000);
    this.getPartyId();
    this.getProductByProductPromoId();
    this.getProductStoreByProductPromoId();
    this.getProductstoreId();
    this.getPromotionCodeList();
    this.getPromotionCodeListNew();
    this.getPromoCodeLayout();
    this.getProductPromotionCodeById();
    this.getPromoCodePartiesList();
    this.getConditionValue();
    this.getShipmentMethodList();
    this.getEnumCodeId();
    //this.getActionTypePriceRules();
    this.getProductPromoEnumTypeIdList();
    this.getProductCategoryLists();
    this.getPromotionCategoryList();
    this.getPromotionProductList();
    this.getCatalogProductId();
    this.getCustomMethodId();
    this.getProductPromoRules();
    this.EditProdCatalogStores();
    this.getstore();

    this.getProdCatalogToPartyById();
    this.getRoleTypeListNew();
    this.getProductCategoryTypeList();
    this.getProductCategoryToProdCatalogById();
    this.getCategoryId();
    this.productCatalog();

  }
  referTocatagories(id){
    this._Router.navigate(["/facilities/catalog/catagories/detail-category-summary/",`${id}`]);
  }
  referToDetailPage(id){
    this._Router.navigate(["/facilities/catalog/catagories/detail-category-summary/",`${id}`]);
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  resetForm(){
    this.show=true;
    this.readOnlyCode=false;
    this.addProductCatalogStoresForm.reset();
    this.addProductCatalogPartiesForm.reset();
    this.editProductCatalogCategoriesForm.reset();
    this.catalogForm.reset();

  } 
  updateProductCatalogNew(): void {
    this.spinner.show();
    const requestData = {
      "catalogName": this.catalogForm.value.catalogName,
      "contentPathPrefix": this.catalogForm.value.contentPathPrefix,
      "headerLogo": this.catalogForm.value.headerLogo,
      "prodCatalogId": this.catalogForm.value.prodCatalogId,
      "purchaseAllowPermReqd": this.catalogForm.value.purchaseAllowPermReqd,
      "styleSheet": this.catalogForm.value.styleSheet,
      "templatePathPrefix": this.catalogForm.value.templatePathPrefix,
      "useQuickAdd": this.catalogForm.value.useQuickAdd,
      "viewAllowPermReqd": this.catalogForm.value.viewAllowPermReqd,
    }
    this.accountsService.updateProductCatalogNew(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.productCatalog();
        this.closebutton15.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProductCatalogNewPatch() {
    this.show=false;
    this.readOnlyCode=true;
    this.spinner.show();

    setTimeout(() => {
      const formValue = this.catalogForm;
      formValue.patchValue({
          catalogName: this.productCatalogAll.catalogName,
          contentPathPrefix: this.productCatalogAll.contentPathPrefix,
          headerLogo: this.productCatalogAll.headerLogo,
          prodCatalogId: this.productCatalogAll.prodCatalogId,
          purchaseAllowPermReqd: this.productCatalogAll.purchaseAllowPermReqd,
          styleSheet: this.productCatalogAll.styleSheet,
          templatePathPrefix: this.productCatalogAll.templatePathPrefix,
          useQuickAdd: this.productCatalogAll.useQuickAdd,
          viewAllowPermReqd: this.productCatalogAll.viewAllowPermReqd,
     
      })

    }, 1000);
    this.spinner.hide();
  }
  createProductCatalogNew(): void {
    this.spinner.show();
    const requestData = {
      "catalogName": this.catalogForm.value.catalogName,
      "contentPathPrefix": this.catalogForm.value.contentPathPrefix,
      "headerLogo": this.catalogForm.value.headerLogo,
      "prodCatalogId": this.catalogForm.value.prodCatalogId,
      "purchaseAllowPermReqd": this.catalogForm.value.purchaseAllowPermReqd,
      "styleSheet": this.catalogForm.value.styleSheet,
      "templatePathPrefix": this.catalogForm.value.templatePathPrefix,
      "useQuickAdd": this.catalogForm.value.useQuickAdd,
      "viewAllowPermReqd": this.catalogForm.value.viewAllowPermReqd,
    }
    this.accountsService.createProductCatalogNew(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(["/product-management/catalogs/detail-catalog-page"], { queryParams: { prodCatalogId: this.catalogForm.value.prodCatalogId } })
        this.catalogForm.reset();
        this.closebutton15.nativeElement.click();
        setTimeout(() => {
          this.productCatalog();
        }, 400);
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  productCatalog() {
    this.spinner.show();
    this.accountsService.productCatalog(this.prodCatalogId).subscribe(res => {
      this.productCatalogAll = res.data;
      this.spinner.hide();
    })
  }
  removeProductCategoryToProdCatalog(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const fromDateCatePatch=product.fromDate;
        this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
        let req = {
              "fromDate": this.fromDateCatePatch,
              "partyId": "",
              "prodCatalogCategoryTypeId": product.prodCatalogCategoryTypeId,
              "prodCatalogId": product.prodCatalogId,
              "productCategoryId": product.productCategoryId,
        }
        this.accountsService.removeProductCategoryToProdCatalog(req).subscribe((res: any) => {
          if (res.success) {
            this.getProductCategoryToProdCatalogById();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProductCategoryToProdCatalog(): void {
    this.spinner.show();
    const requestData = {
      "fromDate": this.datePipe.transform(this.editProductCatalogCategoriesForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "prodCatalogCategoryTypeId": this.editProductCatalogCategoriesForm.value.productCatalogCateogry,
      "prodCatalogId": this.prodCatalogId,
      "productCategoryId": this.editProductCatalogCategoriesForm.value.categoryId,
      "sequenceNum": this.editProductCatalogCategoriesForm.value.sequenceNum,
      "thruDate": this.datePipe.transform(this.editProductCatalogCategoriesForm.value.thorughDate, "yyyy-MM-dd HH:mm:ss"),
    }
    this.accountsService.updateProductCategoryToProdCatalog(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getProductCategoryToProdCatalogById();
        this.editProductCatalogCategoriesForm.reset();
        this.closebutton4.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProductCategoryToProdCatalogPatch(product) {
    this.show=false;
    this.readOnlyCode=true;
    this.updateProductCategoryToProdCatalogPat = product;
    this.spinner.show();
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");

    setTimeout(() => {
      const formValue = this.editProductCatalogCategoriesForm;
      formValue.patchValue({
          categoryId: this.updateProductCategoryToProdCatalogPat.productCategoryId,
          productCatalogCateogry: this.updateProductCategoryToProdCatalogPat.prodCatalogCategoryTypeId,
          fromDate: this.fromDateCatePatch,
          thorughDate: this.throughDateCatePatch,
          sequenceNum: this.updateProductCategoryToProdCatalogPat.sequenceNum,
      })

    }, 1000);
    this.spinner.hide();
  }
  createProductCategoryToProdCatalog(): void {
    this.spinner.show();
    const requestData = {
      "fromDate": this.datePipe.transform(this.editProductCatalogCategoriesForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "prodCatalogCategoryTypeId": this.editProductCatalogCategoriesForm.value.productCatalogCateogry,
      "prodCatalogId": this.prodCatalogId,
      "productCategoryId": this.editProductCatalogCategoriesForm.value.categoryId,
      "sequenceNum": this.editProductCatalogCategoriesForm.value.sequenceNum,
      "thruDate": this.datePipe.transform(this.editProductCatalogCategoriesForm.value.thorughDate, "yyyy-MM-dd HH:mm:ss"),
    }
    this.accountsService.createProductCategoryToProdCatalog(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getProductCategoryToProdCatalogById();
        this.editProductCatalogCategoriesForm.reset();
        this.closebutton4.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getProductCategoryToProdCatalogById() {
    this.spinner.show();
    this.accountsService.getProductCategoryToProdCatalogById(this.prodCatalogId).subscribe(res => {
      this.ProductCategoryToProdCatalogById = res.data[0].dataNode;
      this.spinner.hide();
    })
  }
  getCategoryId() {
    this.spinner.show();
    this.accountsService.getCategoryId().subscribe(res => {
      this.allCategoryId = res.data[0].dataNode;
      this.spinner.hide();
      for (const value of this.allCategoryId) {
        this.allCategoryIdArray.push({
          label: value.productCategoryId,
          value: value.productCategoryId
        })
      }
    })
  }
  getProductCategoryTypeList() {
    this.spinner.show();
    this.accountsService.getProductCategoryTypeList().subscribe(res => {
      this.ProductCategoryTypeList = res.data[0].ProdCatalogCategoryList;
      this.spinner.hide();
      for (const value of this.ProductCategoryTypeList) {
        this.ProductCategoryTypeListArray.push({
          label: value.description,
          value: value.prodCatalogCategoryTypeId
        })
      }
    })
  }
  deleteProdCatalogToParty(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const fromDateCatePatch=product.fromDate;
        this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
        let req = {
              "fromDate": this.fromDateCatePatch,
              "partyId": product.partyId,
              "prodCatalogId": product.prodCatalogId,
              "roleTypeId": product.roleTypeId,
              "view_Index_1": "",
              "view_Size_1": ""
        }
        this.accountsService.deleteProdCatalogToParty(req).subscribe((res: any) => {
          if (res.success) {
            this.getProdCatalogToPartyById();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProdCatalogToParty(): void {
    this.spinner.show();
    const requestData = {
      "fromDate": this.datePipe.transform(this.addProductCatalogPartiesForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "partyId": this.addProductCatalogPartiesForm.value.partyId,
      "prodCatalogId": this.prodCatalogId,
      "roleTypeId": this.addProductCatalogPartiesForm.value.role,
      "sequenceNum": this.addProductCatalogPartiesForm.value.sequenceNum,
      "thruDate": this.datePipe.transform(this.addProductCatalogPartiesForm.value.thorughDate, "yyyy-MM-dd HH:mm:ss"),
    }
    this.accountsService.updateProdCatalogToParty(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getProdCatalogToPartyById();
        this.addProductCatalogPartiesForm.reset();
        this.closebutton3.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProdCatalogToPartyPatch(product) {
    this.show=false;
    this.readOnlyCode=true;
    this.updateProdCatalogToPartyPat = product;
    this.spinner.show();
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");

    setTimeout(() => {
      const formValue = this.addProductCatalogPartiesForm;
      formValue.patchValue({
          partyId: this.updateProdCatalogToPartyPat.partyId,
          role: this.updateProdCatalogToPartyPat.roleTypeId,
          fromDate: this.fromDateCatePatch,
          thorughDate: this.throughDateCatePatch,
          sequenceNum: this.updateProdCatalogToPartyPat.sequenceNum,
      })

    }, 1000);
    this.spinner.hide();
  }
  addProdCatalogToParty(): void {
    this.spinner.show();
    const requestData = {
      "fromDate": this.datePipe.transform(this.addProductCatalogPartiesForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "partyId": this.addProductCatalogPartiesForm.value.partyId,
      "prodCatalogId": this.prodCatalogId,
      "roleTypeId": this.addProductCatalogPartiesForm.value.role,
      "sequenceNum": this.addProductCatalogPartiesForm.value.sequenceNum,
      "thruDate": this.datePipe.transform(this.addProductCatalogPartiesForm.value.thorughDate, "yyyy-MM-dd HH:mm:ss"),
    }
    this.accountsService.addProdCatalogToParty(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getProdCatalogToPartyById();
        this.addProductCatalogPartiesForm.reset();
        this.closebutton3.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getProdCatalogToPartyById() {
    this.spinner.show();
    this.accountsService.getProdCatalogToPartyById(this.prodCatalogId).subscribe(res => {
      this.ProdCatalogToPartyById = res.data[0].data;
      this.spinner.hide();
    })
  }
  getRoleTypeListNew() {
    this.spinner.show();
    this.accountsService.getRoleTypeListNew().subscribe(res => {
      this.RoleTypeListNew = res.data[0].roleTypeList;
      this.spinner.hide();
      for (const value of this.RoleTypeListNew) {
        this.RoleTypeListNewArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  
  deleteProdCatalogStore(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const fromDateCatePatch=product.fromDate;
        this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
        let req = {
                "fromDate": this.fromDateCatePatch,
                "prodCatalogId": product.prodCatalogId,
                "productStoreId": product.productStoreId,
                "view_INDEX_1": "",
                "view_SIZE_1": ""
        }
        this.myContactsService.deleteProdCatalogStore(req).subscribe((res: any) => {
          if (res.success) {
            this.EditProdCatalogStores();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProdCatalogStore(): void {
    this.spinner.show();
    const requestData = {
      "fromDate": this.datePipe.transform(this.addProductCatalogStoresForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "prodCatalogId": this.prodCatalogId,
      "productStoreId": this.addProductCatalogStoresForm.value.store,
      "sequenceNum": this.addProductCatalogStoresForm.value.sequenceNum,
      "thruDate": this.datePipe.transform(this.addProductCatalogStoresForm.value.thorughDate, "yyyy-MM-dd HH:mm:ss"),
    }
    this.myContactsService.updateProdCatalogStore(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.EditProdCatalogStores();
        this.addProductCatalogStoresForm.reset();
        this.closebutton2.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProdCatalogStorePatch(product) {
    this.show=false;
    this.readOnlyCode=true;
    this.updateProdCatalogStorepat = product;
    this.spinner.show();
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");

    setTimeout(() => {
      const formValue = this.addProductCatalogStoresForm;
      formValue.patchValue({
          store:  this.updateProdCatalogStorepat.productStoreId,
          fromDate: this.fromDateCatePatch,
          thorughDate: this.throughDateCatePatch,
          sequenceNum: this.updateProdCatalogStorepat.sequenceNum
      })

    }, 1000);
    this.spinner.hide();
  }
  createProdCatalogStore(): void {
    this.spinner.show();
    const requestData = {
      "fromDate": this.datePipe.transform(this.addProductCatalogStoresForm.value.fromDate, "yyyy-MM-dd HH:mm:ss"),
      "prodCatalogId": this.prodCatalogId,
      "productStoreId": this.addProductCatalogStoresForm.value.store,
      "sequenceNum": this.addProductCatalogStoresForm.value.sequenceNum,
      "thruDate": this.datePipe.transform(this.addProductCatalogStoresForm.value.thorughDate, "yyyy-MM-dd HH:mm:ss"),
    }
    this.myContactsService.createProdCatalogStore(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.EditProdCatalogStores();
        this.addProductCatalogStoresForm.reset();
        this.closebutton2.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getstore() {
    this.spinner.show();
    this.accountsService.store().subscribe(res => {
      this.storeId = res.data[0].store;
      this.spinner.hide();
      for (const value of this.storeId) {
        this.storeIdArray.push({
          label: value.productStoreId,
          value: value.productStoreId
        })
      }
    })
  }
  EditProdCatalogStores() {
    this.spinner.show();
    this.accountsService.EditProdCatalogStores(this.prodCatalogId).subscribe(res => {
      this.allProdCatalogStores = res.data[0].store;
      this.spinner.hide();
    })
  }
  
  resetPromo(){
     this.newPromotionCode.reset();
     this.showPromo =  false;

  }
  updatePromoCode(product) {
    this.storeUpdate = product;
    this.showPromo =  true;
    setTimeout(() => {
      const form = this.updatePromotionCode;
      form.patchValue({

        promoCodeID: product.promotionCodeList.productPromoCodeId,
      promoID: product.promotionCodeList.productPromoId,
      userEntered: product.promotionCodeList.userEntered,
      reqEmailParty: product.promotionCodeList.requireEmailOrParty,
      limitCode: product.promotionCodeList.useLimitPerCode,
      limitCust: product.promotionCodeList.useLimitPerCustomer,
      fromDate: this.datePipe.transform(product.promotionCodeList.fromDate, "yyyy-MM-dd"),
      thruDate:this.datePipe.transform(product.promotionCodeList.thruDate, "yyyy-MM-dd"),
      createdDate:this.datePipe.transform(product.promotionCodeList.createdDate, "yyyy-MM-dd"),
      createdUser: product.promotionCodeList.createdByUserLogin,
      modifiyDate:this.datePipe.transform(product.promotionCodeList.lastModifiedDate, "yyyy-MM-dd"), 
      modifyUser: product.promotionCodeList.lastModifiedByUserLogin,
      })

    }, 2000);
  }
  getProductPromoRules() {
    this.spinner.show();
    this.myContactsService.getProductPromoRules(this.productPromoId).subscribe(res => {
      this.promoRulesList = res.data;
      // setTimeout(() => {
      //   const form = this.updateRule;
      //   form.patchValue({
      //     ruleName: this.promoRulesList.pRules.ruleName
      //   })

      // }, 2000);
      this.spinner.hide();
    })
  }
  deleteProductPromoCond(q) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "productPromoCondSeqId": q.conditionManager.productPromoCondSeqId,
          "productPromoId": q.conditionManager.productPromoId,
          "productPromoRuleId": q.conditionManager.productPromoRuleId
        }
        this.myContactsService.deleteProductPromoCond(req).subscribe((res: any) => {
          this.getPromotionProductList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateProductPromoCond(w): void {
    this.spinner.show();
    const requestData = {
      "carrierShipmentMethod": w.conditionManager.otherValue,
      "condValue": w.conditionManager.condValue,
      "customMethodId": w.conditionManager.customMethodId,
      "operatorEnumId": w.conditionManager.operatorEnumId,
      "otherValue": w.conditionManager.otherValue,
      "productPromoCondSeqId": w.conditionManager.productPromoCondSeqId,
      "productPromoId": w.conditionManager.productPromoId,
      "productPromoRuleId": w.conditionManager.productPromoRuleId

    }
    this.myContactsService.updateProductPromoCond(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.updateRule.reset();
        this.getProductPromoRules();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createProductPromoAction(r): void {
    this.spinner.show();
    const requestData = {
      "amount": this.createAction.value.amount,
      "customMethodId": this.createAction.value.actionFor,
      "orderAdjustmentTypeId": "PROMOTION_ADJUSTMENT",
      "partyId": this.createAction.value.party,
      "productId": this.createAction.value.itemID,
      "productPromoId": this.productPromoId,
      "productPromoRuleId": r.pRules.productPromoRuleId,
      "quantity": this.createAction.value.quantity,
      "serviceName": this.createAction.value.serviceName,
      "useCartQuantity": this.createAction.value.cartQty
    }
    this.myContactsService.createProductPromoAction(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.createAction.reset();
        this.getProductPromoRules();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProductPromoAction(action): void {
    this.spinner.show();
    const requestData = {
      "amount":action.pActionDataIterate.amount,
      "customMethodId": action.pActionDataIterate.customMethodId,
      "orderAdjustmentTypeId": action.pActionDataIterate.orderAdjustmentTypeId,
      "partyId": action.pActionDataIterate.partyId,
      "productId":action.pActionDataIterate.productId,
      "productPromoId": action.pActionDataIterate.productPromoId,
      "productPromoRuleId": action.pActionDataIterate.productPromoRuleId,
      "quantity": action.pActionDataIterate.quantity,
      "serviceName":action.pActionDataIterate.serviceName,
      "useCartQuantity": action.pActionDataIterate.useCartQuantity,
      "productPromoActionSeqId": action.pActionDataIterate.productPromoActionSeqId,

    }
    this.myContactsService.updateProductPromoAction(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.updateAction.reset();
        this.getProductPromoRules();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteProductPromoAction(action) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "productPromoActionSeqId": action.pActionDataIterate.productPromoActionSeqId,
          "productPromoId": action.pActionDataIterate.productPromoId,
          "productPromoRuleId": action.pActionDataIterate.productPromoRuleId,
        }
        this.myContactsService.deleteProductPromoAction(req).subscribe((res: any) => {
          this.getPromotionProductList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createCategoryAction(action): void {
    this.spinner.show();
    const requestData = {
      "productId": this.createProductsForm.value.product,
      "productPromoActionSeqId": action.pActionDataIterate.productPromoActionSeqId,
      "productPromoApplEnumId": this.createProductsForm.value.productType,
      "productPromoCondSeqId": "_NA_" ,
      "productPromoId": this.productPromoId,
      "productPromoRuleId": action.pActionDataIterate.productPromoRuleId,
    }
    this.myContactsService.createCategoryAction(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.createProductsForm.reset();
        this.getProductPromoRules();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  createProductActionProduct(q): void {
    this.spinner.show();
    const requestData = {
      "productId": this.createProducts.value.product,
      "productPromoActionSeqId": "_NA_" ,
      "productPromoApplEnumId": this.createProducts.value.productType,
      "productPromoCondSeqId": q.conditionManager.productPromoCondSeqId ,
      "productPromoId": this.productPromoId,
      "productPromoRuleId": q.conditionManager.productPromoRuleId,
    }
    this.myContactsService.createProductActionProduct(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.createProducts.reset();
        this.getProductPromoRules();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteProductActionProduct(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "productId": product.promoProductList.productId,
          "productPromoActionSeqId": product.promoProductList.productPromoActionSeqId,
          "productPromoCondSeqId": product.promoProductList.productPromoCondSeqId,
          "productPromoId": product.promoProductList.productPromoId,
          "productPromoRuleId": product.promoProductList.productPromoRuleId
        }
        this.myContactsService.deleteProductActionProduct(req).subscribe((res: any) => {
          this.getPromotionProductList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createProductActionCategory(q): void {
    
    this.spinner.show();
    const requestData = {
      "andGroupId": this.AddConditionCategoryForm.value.group,
      "includeSubCategories": this.AddConditionCategoryForm.value.categoryConsent,
      "productCategoryId": this.AddConditionCategoryForm.value.category,
      "productPromoActionSeqId": "_NA_" ,
      "productPromoApplEnumId": this.AddConditionCategoryForm.value.categoryType,
      "productPromoCondSeqId": q.conditionManager.productPromoCondSeqId,
      "productPromoId": this.productPromoId,
      "productPromoRuleId":q.conditionManager.productPromoRuleId
    }
    this.myContactsService.createProductActionCategory(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.AddConditionCategoryForm.reset();
        this.getPromoCodePartiesList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteProductActionCategory(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "andGroupId": product.categoryList.andGroupId,
          "productCategoryId": product.categoryList.productCategoryId,
          "productPromoActionSeqId": product.categoryList.productPromoActionSeqId,
          "productPromoCondSeqId": product.categoryList.productPromoCondSeqId,
          "productPromoId": product.categoryList.productPromoId,
          "productPromoRuleId": product.categoryList.productPromoRuleId,
        }
        this.myContactsService.deleteProductActionCategory(req).subscribe((res: any) => {
          this.getProductPromoRules();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  //////--CREATE CONDITION--////////

  createProductPromoCond(main): void {
    this.spinner.show();
    const requestData = {

      "carrierShipmentMethod": this.createConditionForm.value.ShippingMethod,
      "condValue": this.createConditionForm.value.ConditionVal,
      "customMethodId": this.createConditionForm.value.enumCode,
      "operatorEnumId": this.createConditionForm.value.condition,
      "otherValue": this.createConditionForm.value.other,
      "productPromoId": this.productPromoId,
      "productPromoRuleId": main.pRules.productPromoRuleId,
    }
    this.myContactsService.createProductPromoCond(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.createConditionForm.reset();
        this.getProductPromoRules();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  deleteProductPromoRule(main) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "productPromoId": main.pRules.productPromoId,
          "productPromoRuleId": main.pRules.productPromoRuleId,
        }
        this.myContactsService.deleteProductPromoRule(req).subscribe((res: any) => {
          this.getPromotionProductList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  updateProductPromoRule(main): void {
    this.spinner.show();
    const requestData = {
      "productPromoId": this.productPromoId,
      "ruleName": main.pRules.ruleName,
      "productPromoRuleId": main.pRules.productPromoRuleId,

    }
    this.myContactsService.updateProductPromoRule(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.updateRule.reset();
        this.getProductPromoRules();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  getCustomMethodId() {
    this.spinner.show();
    this.myContactsService.getCustomMethodId().subscribe(res => {
      this.CustomMethodId = res.data[0].customMethodList;
      this.spinner.hide();
      for (const value of this.CustomMethodId) {
        this.CustomMethodIdArray.push({
          label: value.description,
          value: value.enumCode
        })
      }
    })
  }
  getProductCategoryLists() {
    this.spinner.show();
    this.myContactsService.getProductCategoryLists().subscribe(res => {
      this.productCategoryList = res.data;
      this.spinner.hide();
      for (const value of this.productCategoryList) {
        this.productCategoryListArray.push({
          label: value.genericValue.productCategoryId,
          value: value.genericValue.productCategoryId
        })
      }
    })
  }
  getShipmentMethodList() {
    this.spinner.show();
    this.myContactsService.getShipmentMethodList().subscribe(res => {
      this.ShipmentMethodList = res.data;
      this.spinner.hide();
      for (const value of this.ShipmentMethodList) {
        this.ShipmentMethodListArray.push({
          label: value.pShipmentValues,
          value: value.pShipment_key
        })
      }
    })
  }

  getEnumCodeId() {
    this.spinner.show();
    this.myContactsService.getEnumCodeId().subscribe(res => {
      this.enumCodeId = res.data[0].pEnumCodeList;
      this.spinner.hide();
      for (const value of this.enumCodeId) {
        this.enumCodeIdArray.push({
          label: value.description,
          value: value.enumCode
        })
      }
    })
  }
  getConditionValue() {
    this.spinner.show();
    this.myContactsService.getConditionValue().subscribe(res => {
      this.conditionValues = res.data[0].pConditionValues;
      this.spinner.hide();
      for (const value of this.conditionValues) {
        this.conditionValuesArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  createProductPromoRule(): void {
    this.spinner.show();
    const requestData = {
      "productPromoId": this.productPromoId,
      "ruleName": this.newPromoRule.value.ruleName
    }
    this.myContactsService.createProductPromoRule(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton11.nativeElement.click();
        this.newPromoRule.reset();
        this.getPromoCodePartiesList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  //////////////////-----------Promotion Product----------/////////////////////

  createProductPromoProduct(): void {
    this.spinner.show();
    const requestData = {
      "productId": this.addPromoProduct.value.product,
      "productPromoActionSeqId": "_NA_",
      "productPromoApplEnumId": this.addPromoProduct.value.productType,
      "productPromoCondSeqId": "_NA_",
      "productPromoId": this.productPromoId,
      "productPromoRuleId": "_NA_",
    }
    this.myContactsService.createProductPromoProduct(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton14.nativeElement.click();
        this.addPromoProduct.reset();
        this.getPromoCodePartiesList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getPromotionProductList() {
    this.spinner.show();
    this.myContactsService.getPromotionProductList(this.productPromoId).subscribe(res => {
      this.promotionProductList = res.data;
      this.spinner.hide();
    })
  }
  getCatalogProductId() {
    this.spinner.show();
    this.myContactsService.getCatalogProdId().subscribe(res => {
      this.CatalogProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.CatalogProductId) {
        this.CatalogProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
  }
  deleteProductConditionProduct(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "productId": product.productId,
          "productPromoActionSeqId": product.productPromoActionSeqId,
          "productPromoCondSeqId": product.productPromoCondSeqId,
          "productPromoId": product.productPromoId,
          "productPromoRuleId": product.productPromoRuleId
        }
        this.myContactsService.deleteProductPromoProduct(req).subscribe((res: any) => {
          this.getPromotionProductList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteProductPromoProduct(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "productId": product.genericValue.productId,
          "productPromoActionSeqId": product.genericValue.productPromoActionSeqId,
          "productPromoCondSeqId": product.genericValue.productPromoCondSeqId,
          "productPromoId": product.genericValue.productPromoId,
          "productPromoRuleId": product.genericValue.productPromoRuleId
        }
        this.myContactsService.deleteProductPromoProduct(req).subscribe((res: any) => {
          this.getPromotionProductList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  //////////////////-----------Product Category----------/////////////////////

  getPromotionCategoryList() {
    this.spinner.show();
    this.myContactsService.getPromotionCategoryList(this.productPromoId).subscribe(res => {
      this.promotionCategoryList = res.data;
      this.spinner.hide();
    })
  }
  getProductPromoEnumTypeIdList() {
    this.spinner.show();
    this.myContactsService.getProductPromoEnumTypeIdList().subscribe(res => {
      this.productPromoEnumTypeIdList = res.data[0].getProductPromoEnumTypeIdList;
      this.spinner.hide();
      for (const value of this.productPromoEnumTypeIdList) {
        this.productPromoEnumTypeIdListArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  addActionCategory(action): void {
    this.spinner.show();
    const requestData = {
      "andGroupId": this.actionCategory.value.group,
      "includeSubCategories": this.actionCategory.value.categoryConsent,
      "productCategoryId": this.actionCategory.value.category,
      "productPromoActionSeqId": action.pActionDataIterate.productPromoActionSeqId,
      "productPromoApplEnumId": this.actionCategory.value.categoryType,
      "productPromoCondSeqId":  "_NA_",
      "productPromoId": this.productPromoId,
      "productPromoRuleId": action.pActionDataIterate.productPromoRuleId,
    }
    this.myContactsService.addActionCategory(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.actionCategory.reset();
        this.getProductPromoRules();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
 
  createProductPromoCategory(): void {
    this.spinner.show();
    const requestData = {
      "andGroupId": this.addPromoCategory.value.group,
      "includeSubCategories": this.addPromoCategory.value.categoryConsent,
      "productCategoryId": this.addPromoCategory.value.category,
      "productPromoActionSeqId": "_NA_",
      "productPromoApplEnumId": this.addPromoCategory.value.categoryType,
      "productPromoCondSeqId": "_NA_",
      "productPromoId": this.productPromoId,
      "productPromoRuleId": "_NA_",
    }
    this.myContactsService.createProductPromoCategory(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton13.nativeElement.click();
        this.addPromoCategory.reset();
        this.getPromoCodePartiesList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteProductActCategory(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "andGroupId": product.andGroupId,
          "productCategoryId": product.productCategoryId,
          "productPromoActionSeqId": product.productPromoActionSeqId,
          "productPromoCondSeqId": product.productPromoCondSeqId,
          "productPromoId": product.productPromoId,
          "productPromoRuleId": product.productPromoRuleId,
        }
        this.myContactsService.deleteProductCategory(req).subscribe((res: any) => {
          this.getProductPromoRules();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  deleteProductPromoCategory(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "andGroupId": product.pPCategoryList.andGroupId,
          "productCategoryId": product.pPCategoryList.productCategoryId,
          "productPromoActionSeqId": product.pPCategoryList.productPromoActionSeqId,
          "productPromoCondSeqId": product.pPCategoryList.productPromoCondSeqId,
          "productPromoId": product.pPCategoryList.productPromoId,
          "productPromoRuleId": product.pPCategoryList.productPromoRuleId,
        }
        this.myContactsService.deleteProductPromoCategory(req).subscribe((res: any) => {
          this.getProductPromoRules();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  ///////-----------/////-------END OF PROMO RULE-------//////-----------//////////

  deleteProductPromoCodeParty(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "partyId": product.partyId,
          "productPromoCodeId": product.productPromoCodeId,
          "productPromoId": this.productPromoId,
        }
        this.myContactsService.deleteProductPromoCodeParty(req).subscribe((res: any) => {
          this.getPromoCodePartiesList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getPromoCodePartiesList() {
    this.spinner.show();
    this.myContactsService.getPromoCodePartiesList().subscribe(res => {
      this.promoCodePartiesList = res.data[0].data;
      this.spinner.hide();
    })
  }

  createProductPromoCodeParty(): void {
    this.spinner.show();
    const requestData = {
      "partyId": this.addPartyForm.value.PartyID,
      "productPromoCodeId": this.productPromoCodeId,
      "productPromoId": this.productPromoId
    }
    this.myContactsService.createProductPromoCodeParty(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton8.nativeElement.click();
        this.addPartyForm.reset();
        this.getPromoCodePartiesList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateProductCode(): void {
    this.spinner.show();
    const requestData = {
      "createdByUserLogin": this.updatePromotionCode.value.createdUser,
      "createdDate": this.datePipe.transform(this.updatePromotionCode.value.createdDate, "yyyy-MM-dd hh:mm:ss"),
      "fromDate": this.datePipe.transform(this.updatePromotionCode.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "lastModifiedByUserLogin": this.updatePromotionCode.value.modifyUser,
      "lastModifiedDate": this.datePipe.transform(this.updatePromotionCode.value.modifiyDate, "yyyy-MM-dd hh:mm:ss"),
      "productPromoCodeId": this.updatePromotionCode.value.promoCodeID,
      "productPromoId": this.updatePromotionCode.value.promoID,
      "requireEmailOrParty": this.updatePromotionCode.value.reqEmailParty,
      "thruDate": this.datePipe.transform(this.updatePromotionCode.value.thruDate, "yyyy-MM-dd hh:mm:ss"),
      "useLimitPerCode": this.updatePromotionCode.value.limitCode,
      "useLimitPerCustomer": this.updatePromotionCode.value.limitCust,
      "userEntered": this.updatePromotionCode.value.userEntered,
    }
    this.myContactsService.updateProductCode(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.closebutton9.nativeElement.click();
        this.updatePromotionCode.reset();
        this.getPromotionCodeList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getProductPromotionCodeById() {
    this.spinner.show();
    this.myContactsService.getProductPromotionCodeById(this.productPromoId).subscribe(res => {
      this.promotionCodeData = res.data[0].promotionCodeList;
      setTimeout(() => {
        const formValue = this.updatePromotionCode;
        formValue.patchValue({
          promoCodeID: this.promotionCodeData.data.productPromoCodeId,
          promoID: this.promotionCodeData.data.productPromoId,
          userEntered: this.promotionCodeData.data.userEntered,
          reqEmailParty: this.promotionCodeData.data.requireEmailOrParty,
          limitCode: this.promotionCodeData.data.useLimitPerCode,
          limitCust: this.promotionCodeData.data.useLimitPerCustomer,
          fromDate: this.datePipe.transform(this.promotionCodeData.data.fromDate, "yyyy-MM-dd"),
          thruDate: this.datePipe.transform(this.promotionCodeData.data.thruDate, "yyyy-MM-dd"),
          createdDate: this.datePipe.transform(this.promotionCodeData.data.createdDate, "yyyy-MM-dd"),
          createdUser: this.promotionCodeData.data.createdByUserLogin,
          modifiyDate: this.datePipe.transform(this.promotionCodeData.data.lastModifiedDate, "yyyy-MM-dd"),
          modifyUser: this.promotionCodeData.data.lastModifiedByUserLogin,
          LastModifiedBy: this.datePipe.transform(Date(), "yyyy-MM-dd"),
          CreatedBy: this.datePipe.transform(Date(), "yyyy-MM-dd"),
        })
      }, 2000);
      this.spinner.hide();
    })
  }
  createNewProductPromo(): void {
    this.spinner.show();
    const ActualCompletionDate = this.newProductPromo.get('createdDate').value;
    this.ActualCompletionDate = this.datePipe.transform(ActualCompletionDate, "yyyy-MM-dd hh:mm:ss");
    const ActualStartDate = this.newProductPromo.get('modifiedDate').value;
    this.ActualStartDate = this.datePipe.transform(ActualStartDate, "yyyy-MM-dd hh:mm:ss");

    const requestData = {
      "billbackFactor": this.newProductPromo.value.billbackFactor,
      "createdByUserLogin": this.newProductPromo.value.createdByUser,
      "createdDate": this.ActualCompletionDate,
      "lastModifiedByUserLogin": this.newProductPromo.value.modifiedByUser,
      "lastModifiedDate": this.ActualStartDate,
      "overrideOrgPartyId": this.newProductPromo.value.orgPartyID,
      "promoName": this.newProductPromo.value.promoName,
      "promoText": this.newProductPromo.value.promoText,
      "requireCode": this.newProductPromo.value.reqCode,
      "showToCustomer": this.newProductPromo.value.promoShow,
      "useLimitPerCustomer": this.newProductPromo.value.limitCust,
      "useLimitPerOrder": this.newProductPromo.value.limitOrder,
      "useLimitPerPromotion": this.newProductPromo.value.limitPromo,
      "userEntered": this.newProductPromo.value.userEntered,
    }

    this.myContactsService.createNewProductPromo(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton7.nativeElement.click();
        this.PromoId = res.data.productPromoId;
        this._Router.navigate(["facilities/catalog/promos/editPromo"],
          { queryParams: { productPromoId: this.PromoId } })
        this.activeCategory = 1;
        // this.getProductPromotionList(); 
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  detailStore(id) {
    this._Router.navigate(["facilities/catalog/stores/editStore"], { queryParams: { productStoreId: id } })
  }
  promoPage(productPromoCodeId,productPromoId){
    this.activeCategory =7;
    this._Router.navigate(["facilities/catalog/promos/editPromo"], { queryParams: { productPromoCodeId: productPromoCodeId, productPromoId:productPromoId} })
  }
  updateProductPromoCode(): void {
    this.spinner.show();
    const requestData = {
      "createdByUserLogin": this.newPromotionCode.value.createdUser,
      "createdDate": this.datePipe.transform(this.newPromotionCode.value.createdDate, "yyyy-MM-dd hh:mm:ss"),
      "fromDate": this.datePipe.transform(this.newPromotionCode.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "lastModifiedByUserLogin": this.newPromotionCode.value.modifyUser,
      "lastModifiedDate": this.datePipe.transform(this.newPromotionCode.value.modifiyDate, "yyyy-MM-dd hh:mm:ss"),
      "productPromoCodeId": this.newPromotionCode.value.promoCodeID,
      "productPromoId": this.newPromotionCode.value.promoID,
      "requireEmailOrParty": this.newPromotionCode.value.reqEmailParty,
      "thruDate": this.datePipe.transform(this.newPromotionCode.value.thruDate, "yyyy-MM-dd hh:mm:ss"),
      "useLimitPerCode": this.newPromotionCode.value.limitCode,
      "useLimitPerCustomer": this.newPromotionCode.value.limitCust,
      "userEntered": this.newPromotionCode.value.userEntered,
    }
    this.myContactsService.updateProductPromoCode(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Update Successfully");
        this.spinner.hide();
        this.closebutton6.nativeElement.click();
        this.newPromotionCode.reset();
        this.productPromoCodeId = this.newPromotionCode.value.promoCodeID;
        this.productPromoId = this.newPromotionCode.value.promoID;
        this.activeCategory = 7;
        // this._Router.navigate(["facilities/catalog/promos/editPromo"],
        //   {
        //     queryParams: {
        //       productPromoCodeId: this.productPromoCodeId,
        //       productPromoId: this.productPromoId
        //     }
        //   })
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  PromotionManualOnly(): void {
    this.spinner.show();
    this.myContactsService.ManualImportedOnly("Y", this.productPromoId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.showImport = true;
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  PromotionManualImported(): void {
    this.spinner.show();
    this.myContactsService.getPromotionManualImported("N", this.productPromoId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
        this.showImport = false;
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  deleteProductPromoCode(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "productPromoCodeId": product.promotionCodeList.productPromoCodeId,
          "productPromoId": product.promotionCodeList.productPromoId,
          "view_INDEX_1": "0",
          "view_SIZE_1": "20"

        }
        this.myContactsService.deleteProductPromoCode(req).subscribe((res: any) => {
          this.getPromotionCodeList();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createBulkProductPromoCode() {
    this.spinner.show();
    const formData = new FormData();
    formData.append("file", this.fileData);

    this.myContactsService.createBulkProductPromoCode(formData, this.productPromoId,
      this.uploadPromotionCodes.value.regEmailParty, this.uploadPromotionCodes.value.limitCode,
      this.uploadPromotionCodes.value.limitCust, this.uploadPromotionCodes.value.userEntered).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
          this.closebutton10.nativeElement.click();
          this.uploadPromotionCodes.reset();

        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
  }
  fileProgressCom(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
  }
  getPromotionCodeListNew() {
    this.spinner.show();
    this.myContactsService.getPromotionCodeListNew().subscribe(res => {
      this.PromotionCodeList = res.data;
      this.spinner.hide();
      for (const value of this.PromotionCodeList) {
        this.PromotionCodeListArray.push({
          label: value.promotionCodeList.productPromoId,
          value: value.promotionCodeList.productPromoId
        })
      }
    })
  }
  getPromoCodeLayout() {
    this.spinner.show();
    this.myContactsService.getPromoCodeLayout().subscribe(res => {
      this.promoCodeLayout = res.data[0].layoutList;
      this.spinner.hide();
      for (const value of this.promoCodeLayout) {
        this.promoCodeLayoutArray.push({
          label: value.promoCodeDesc,
          value: value.promoCodeValue
        })
      }
    })
  }
  createProductPromoCodeSet(): void {
    this.spinner.show();

    const requestData = {
      "codeLength": this.addPromotionCodes.value.codeLength,
      "productPromoId": this.productPromoId,
      "promoCodeLayout": this.addPromotionCodes.value.codeLayout,
      "quantity": this.addPromotionCodes.value.quantity,
      "requireEmailOrParty": this.addPromotionCodes.value.regEmailParty,
      "useLimitPerCode": this.addPromotionCodes.value.limitCode,
      "useLimitPerCustomer": this.addPromotionCodes.value.limitCust,
      "userEntered": this.addPromotionCodes.value.userEntered,
    }

    this.myContactsService.createProductPromoCodeSet(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton5.nativeElement.click();
        this.addPromotionCodes.reset();
        this.getPromotionCodeList();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getPromotionCodeList() {
    this.spinner.show();
    this.myContactsService.getPromotionCodeList(this.productPromoId).subscribe(res => {
      this.PromotionCodeListList = res.data;
      this.spinner.hide();

    })
  }
  getProductstoreId() {
    this.spinner.show();
    this.myContactsService.getProductstoreId().subscribe(res => {
      this.ProductStoreId = res.data[0].pStoreData;
      this.spinner.hide();
      for (const value of this.ProductStoreId) {
        this.ProductStoreIdArray.push({
          label: value.storeName + "[" + value.productStoreId + "]",
          value: value.productStoreId
        })
      }
    })
  }

  updateStore(product) {
    this.storeUpdate = product;

    setTimeout(() => {
      const form = this.editProductStore;
      form.patchValue({
        storeName: product.productStoreId,
        fromDate: this.datePipe.transform(product.fromDate, "yyyy-MM-dd"),
        thruDate: this.datePipe.transform(product.thruDate, "yyyy-MM-dd"),
        seq: product.sequenceNum,
      })

    }, 2000);
  }
  promo_deleteProductStorePromoAppl(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "fromDate": this.datePipe.transform(product.fromDate, "yyyy-MM-dd hh:mm:ss"),
          "productPromoId": product.productPromoId,
          "productStoreId": product.productStoreId,

        }
        this.myContactsService.promo_deleteProductStorePromoAppl(req).subscribe((res: any) => {
          this.getProductStoreByProductPromoId();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getProductStoreByProductPromoId() {
    this.spinner.show();
    this.myContactsService.getProductStoreByProductPromoId(this.productPromoId).subscribe(res => {
      this.ProductStoreByProductList = res.data[0].productStorePromoAppl;
      this.spinner.hide();

    })
  }
  promo_createProductStorePromoAppl(): void {
    this.spinner.show();
    const requestData = {
      "manualOnly": "",
      "fromDate": this.datePipe.transform(this.addProductStore.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "productPromoId": this.productPromoId,
      "productStoreId": this.addProductStore.value.storeName,
      "thruDate": ""
    }

    this.myContactsService.promo_createProductStorePromoAppl(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebuttonD.nativeElement.click();
        this.editProductStore.reset();
        this.getProductStoreByProductPromoId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  promo_updateProductStorePromoAppl(): void {
    this.spinner.show();
    const requestData = {
      "fromDate": this.datePipe.transform(this.editProductStore.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "productPromoId": this.productPromoId,
      "productStoreId": this.storeUpdate.productStoreId,
      "sequenceNum": this.editProductStore.value.seq,
      "thruDate": this.datePipe.transform(this.editProductStore.value.thruDate, "yyyy-MM-dd hh:mm:ss")
    }

    this.myContactsService.promo_updateProductStorePromoAppl(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebuttonB.nativeElement.click();
        this.editProductStore.reset();
        this.getProductStoreByProductPromoId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  getProductByProductPromoId() {
    this.spinner.show();

    this.accountsService.getProductByProductPromoId(this.productPromoId).subscribe(res => {
      this.ProductByProductPromoId = res.data[0].genericValue;
      setTimeout(() => {
        const fromdate = this.ProductByProductPromoId.lastModifiedDate
        this.getEstimateFrom = this.datePipe.transform(fromdate, "yyyy-MM-dd");
        const toEstdate = this.ProductByProductPromoId.createdDate
        this.getEstimateTo = this.datePipe.transform(toEstdate, "yyyy-MM-dd");
        const formValue = this.editProductPromo;
        formValue.patchValue({

          promoName: this.ProductByProductPromoId.promoName,
          promoText: this.ProductByProductPromoId.promoText,
          userEntered: this.ProductByProductPromoId.userEntered,
          promoShow: this.ProductByProductPromoId.showToCustomer,
          reqCode: this.ProductByProductPromoId.requireCode,
          limitOrder: this.ProductByProductPromoId.useLimitPerOrder,
          limitCust: this.ProductByProductPromoId.useLimitPerCustomer,
          limitPromo: this.ProductByProductPromoId.useLimitPerPromotion,
          billbackFactor: this.ProductByProductPromoId.billbackFactor,
          orgPartyID: this.ProductByProductPromoId.overrideOrgPartyId,
          createdDate: this.getEstimateTo,
          createdByUser: this.ProductByProductPromoId.createdByUserLogin,
          modifiedDate: this.getEstimateFrom,
          modifiedByUser: this.ProductByProductPromoId.lastModifiedByUserLogin
        })

      }, 2000);
      this.spinner.hide();

    })
  }

  updateProductPromo(): void {
    
    this.spinner.show();
    const ActualCompletionDate = this.editProductPromo.get('createdDate').value;
    this.ActualCompletionDate = this.datePipe.transform(ActualCompletionDate, "yyyy-MM-dd hh:mm:ss");
    const ActualStartDate = this.editProductPromo.get('modifiedDate').value;
    this.ActualStartDate = this.datePipe.transform(ActualStartDate, "yyyy-MM-dd hh:mm:ss");

    const requestData = {
      "billbackFactor": this.editProductPromo.value.billbackFactor,
      "createdByUserLogin": this.editProductPromo.value.createdByUser,
      "createdDate": this.ActualCompletionDate,
      "lastModifiedByUserLogin": this.editProductPromo.value.modifiedByUser,
      "lastModifiedDate": this.ActualStartDate,
      "overrideOrgPartyId": this.editProductPromo.value.orgPartyID,
      "promoName": this.editProductPromo.value.promoName,
      "promoText": this.editProductPromo.value.promoText,
      "requireCode": this.editProductPromo.value.reqCode,
      "showToCustomer": this.editProductPromo.value.promoShow,
      "useLimitPerCustomer": this.editProductPromo.value.limitCust,
      "useLimitPerOrder": this.editProductPromo.value.limitOrder,
      "useLimitPerPromotion": this.editProductPromo.value.limitPromo,
      "userEntered": this.editProductPromo.value.userEntered,
      "productPromoId": this.productPromoId,

    }

    this.myContactsService.updateProductPromo(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebuttonA.nativeElement.click();
        this.getProductByProductPromoId();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

  toBack()
  {
    this._Router.navigate(['/facilities/catalog/catalogs']);
  }

   

  

}
