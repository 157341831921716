<!-- <div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-grey breadcrumbb"  (click)="cancel()" >Role</span>
                <span class="color-black pl-1"> >  New Role Type</span>
                <button type="submit" (click)="cancel()"   class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard" >
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                       New Role Type</a></li>
                 
                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" [formGroup]="createUser">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Role Type ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Role Type ID" formControlName="roleTypeId" > 
                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">Parent Type ID</label>
                                            </div>
                                           
                                            <div class="col-lg-2">
                                                <p-dropdown placeholder="Enter Parent Type ID" formControlName="parentTypeId" filter="true" [options]="allroleArray" optionlabel="label">
                                                   
                                                </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput" >
                                                <label for="exampleInputEmail1">Description<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="description" >
                                            </div>
                                          
                                        </div>
                                    </div>
                                   
                                   
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-submit-button" style="margin: -83px 328px;">
                <button type="submit" (click)="submit()" class="btn btn-secondary submit-btn update-button">Submit</button>
              
            </div>
            </div>
        </div>
    </div>
</div> -->

<div class="modal-header">
    <span class="color-black pl-1">New Role Type</span>
    <button type="button" class="btn-close close pull-right cssButtonModel" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden" #closebutton >&times;</span>
    </button>
  </div>
  <div class="modal-body d-flex">
      <div class=" bg-white color-grey" style="width: 100%;">
          <div class="w3-card-4 classCardPopUp" style="width: 100%;">
              <div class="container-fluid">
                  <ul class="tabbing-section tabbing-accordians tabClass">

                      <li class="active"><a>
                        New Role Type</a></li>
                  </ul>
              </div>
            
              <div class="create-new-leade mt-2">
                <div class="container-fluid">
                    <div class="row">
                        <form class="w-100" [formGroup]="createUser">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                
                                        <div class="col-lg-4 form-group classInput" >
                                            <label for="exampleInputEmail1">Role Type ID<span
                                                style="color:red">*</span></label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Role Type ID" formControlName="roleTypeId" > 
                                        </div>
                                        <div class="col-lg-2 form-group classInputA">
                                            <label for="exampleInputEmail1">Parent Type ID</label>
                                        </div>
                                       
                                        <div class="col-lg-3 custom-dropdown">
                                            <p-dropdown placeholder="Enter Parent Type ID" formControlName="parentTypeId" filter="true" [options]="allroleArray" optionlabel="label">
                                               
                                            </p-dropdown>
                                        </div>
                
                                       
                
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                
                                        <div class="col-lg-4 form-group classInput" >
                                            <label for="exampleInputEmail1">Description<span
                                                style="color:red">*</span></label>
                                        </div>
                                     
                                        <div class="col-lg-3">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description" formControlName="description" >
                                        </div>
                                      
                                    </div>
                                </div>
                               
                               
                            </div>
                        </form>
                        <div class="main-submit-button" style="margin: -24px 546px;">
                            <button type="submit" (click)="submit()" class="btn btn-secondary submit-btn update-button">Submit</button>
                          
                        </div>
                    </div>
                </div>
            </div>
            
             </div>
      </div>
      
  </div>