import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HeaderService } from '../../../header/header.service';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from '../../../crm/accounts/accounts.service';
import { DropdownModule } from 'primeng/dropdown';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
@Component({
  selector: 'app-return-main',
  templateUrl: './return-main.component.html',
  styleUrls: ['./return-main.component.css']
})
export class ReturnMainComponent implements OnInit {
  products3: any;
  @ViewChild('closebutton') closebutton;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  createReturn: FormGroup;
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  personId: any;
  partyIdArray: any[]=[];
  facilities: any;
  dateIdArray: { label: string; value: string; }[];
  headerArray: any[]=[];
  statusIdArray: any[]=[];
  currencyListArray: any[]=[];
  facilityArray: any[]=[];
  estimatedReadyDate: string;
  updateId: any;
  show: boolean;
  pageNo:number = 1;
  activeCategory = 1;
  Date: string;
  createdBy: any;

  advanceSearch: {

    billingAccountId: string,
    entryDateFrom: string,
    entryDateFromSearchType: string,
    entryDateTo: string,
    entryDateToSearchType: string,
    billingAccountIdSearchType: string,
    fromPartyId: string,
    returnHeaderTypeId: string,
    returnId: string,
    returnIdSearchType: string,
    statusIds: [string],

  };

  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  getReturnOrder: any[];
  arrayStatus: any[] = [];
  offerShowingLineData: any;
  dateIdArrayNew: { label: string; value: string; }[];
  constructor(

    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly activatedRoute:ActivatedRoute,
   
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly router: Router, public addSkillService: AddSkillService,
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    private _location: Location,
) {
      this.createReturn = this._FormBuilder.group({
        billingAccountId: [''],
        currencyUomId: [''],
        destinationFacilityId: [''],
        entryDate: [''],
        finAccountId: [''],
        fromPartyId: [''],
        needsInventoryReceive: [''],
        originContactMechId: [''],
        paymentMethodId: [''],
        returnHeaderTypeId: [''],
        statusId: [''],
        supplierRmaId: [''],
        toPartyId: [''],
        
      });
      this.dateIdArrayNew = [
        {
          label: 'Y',
          value: 'Y'
        },
        {
          label: 'N',
          value: 'N'
        },
       
       
      
       
      ];
    this.advanceSearch = {

      billingAccountId: "",
      entryDateFrom: "",
      entryDateFromSearchType: "GreaterThan",
      entryDateTo: "",
      entryDateToSearchType: "LessThan",
      billingAccountIdSearchType: "Contains",
      fromPartyId: "",
      returnHeaderTypeId: "",
      returnId: "",
      returnIdSearchType: "Contains",
      statusIds: [""],

    };
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.dateIdArray = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },

      {
        label: 'Greater Than',
        value: 'GreaterThan'
      },
      {
        label: 'Greater Than Equal To',
        value: 'GreaterThanEqualTo'
      },
      {
        label: 'Less Than Equal To',
        value: 'LessThanEqualTo'
      },
      {
        label: 'Is Empty',
        value: 'IsEmpty'
      }

    ];
  };
  offerShowingLineColoumns = [
    { field: 'status', header: 'Return Id' },
    { field: 'name', header: 'Entry Date' },
    { field: 'email', header: 'Return From Party' },
    { field: 'phone', header: 'Destination Facility' },
    { field: 'type', header: 'Status' },
    { field: 'action', header: 'Action' },
  ];

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails()
   
    this.getReturnHeader();
    this.getPartyId();
    this.getReturnStatusId();
    this.getReturnOrderValue();
    this.getCurrency();
    this.getDestinationFacility();
  }


backWindow(){
  this._location.back();
}
  toDashboard()
{
this.router.navigate(['/order/main-order']);
}

  getDestinationFacility(): void {
    this.spinner.show();
    this.accountsService.getDestinationFacility().subscribe((res) => {
      let facility = res.data;
      this.spinner.hide();
    for (const value of facility) {
        this.facilityArray.push({
          label: value.facilityName,
          value: value.facilityId,
        });
      } 
    });
   
  }
 
  getCurrency(): void {
    this.spinner.show();
    this.accountsService.getCurrency().subscribe((res) => {
      let currencyList = res.data.currencyList;
      this.spinner.hide();
       for (const value of currencyList) {
        this.currencyListArray.push({
          label: value.description,
          value: value.uomId,
        });
      } 
    });
    
  }

  submit(): void {
    this.spinner.show();
   this.estimatedReadyDate=this.datePipe.transform(this.createReturn.value.entryDate,"yyyy-MM-dd hh:mm:ss")
    const requestData ={
    
        "billingAccountId":this.createReturn.value.billingAccountId,
        "currencyUomId": this.createReturn.value.currencyUomId,
        "destinationFacilityId": this.createReturn.value.destinationFacilityId,
        "entryDate": this.estimatedReadyDate,
        "finAccountId": this.createReturn.value.finAccountId,
        "fromPartyId": this.createReturn.value.fromPartyId,
        "needsInventoryReceive": this.createReturn.value.needsInventoryReceive,
        "originContactMechId": this.createReturn.value.originContactMechId,
        "paymentMethodId": this.createReturn.value.paymentMethodId,
        "returnHeaderTypeId": this.createReturn.value.returnHeaderTypeId,
        "statusId":this.createReturn.value.statusId,
        "supplierRmaId": this.createReturn.value.supplierRmaId,
        "toPartyId": this.createReturn.value.toPartyId
     
    }
    this.accountsService.createOrderReturn(requestData).subscribe((res: any) => {
 
      if (res.success) {
        this.toastr.success("Successfully Created");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.getReturnOrderValue();
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
    this.spinner.hide();
  }
  getReturnHeader(): void {
    this.spinner.show();
    this.accountsService.getReturnHeader().subscribe((res) => {
      let header = res.data;
      this.spinner.hide();
      for (const value of header) {
        this.headerArray.push({
          label: value.description,
          value: value.returnHeaderTypeId,
        });
      }
    });
    
  }
  getReturnStatusId(): void {
    this.spinner.show();
    this.accountsService.getReturnStatusId().subscribe((res) => {
      let statusId = res.data;
      this.spinner.hide();
      for (const value of statusId) {
        this.statusIdArray.push({
          label: value.description,
          value: value.statusId,
        });
      }
    });
   
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   
  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.getReturnOrder = [];
    let res = {
      "pageNo": this.pageNo,
      "pageSize": this.pageSize
    }
    let req = {
      billingAccountId: this.advanceSearch.billingAccountId ? this.advanceSearch.billingAccountId : '',
      entryDateFrom: this.datePipe.transform(this.advanceSearch.entryDateFrom, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.entryDateFrom, "yyyy-MM-dd hh:mm:ss") : '',
      entryDateFromSearchType: this.advanceSearch.entryDateFromSearchType ? this.advanceSearch.entryDateFromSearchType : '',
      entryDateTo: this.datePipe.transform(this.advanceSearch.entryDateTo, "yyyy-MM-dd hh:mm:ss") ? this.datePipe.transform(this.advanceSearch.entryDateTo, "yyyy-MM-dd hh:mm:ss") : '',
      entryDateToSearchType: this.advanceSearch.entryDateToSearchType ? this.advanceSearch.entryDateToSearchType : '',
      billingAccountIdSearchType: this.advanceSearch.billingAccountIdSearchType ? this.advanceSearch.billingAccountIdSearchType : '',
      fromPartyId: this.advanceSearch.fromPartyId ? this.advanceSearch.fromPartyId : '',
      returnHeaderTypeId: this.advanceSearch.returnHeaderTypeId ? this.advanceSearch.returnHeaderTypeId : '',
      returnId: this.advanceSearch.returnId ? this.advanceSearch.returnId : '',
      returnIdSearchType: this.advanceSearch.returnIdSearchType ? this.advanceSearch.returnIdSearchType : '',
      statusIds: this.arrayStatus,

    }
    this.accountsService.getOrderReturn(res, req)
      .subscribe(resp => {
        if (resp.success) {
          this.spinner.hide();
          this.offerShowingLineData = resp.data;

        } else {
        
        }
      }, (err) => {
        for (const value of err.error.errors) {
         
        }
      });
      this.spinner.hide();
      
  }
  onChange(value) {
    this.arrayStatus = []
    this.arrayStatus.push(value);
  }
  reset() {
    this.spinner.show();
    this.arrayStatus = [];
    this.advanceSearch.billingAccountId = "";
    this.advanceSearch.entryDateFrom = "";
    this.advanceSearch.entryDateTo = "";
    this.advanceSearch.fromPartyId = "";
    this.advanceSearch.returnHeaderTypeId = "";
    this.advanceSearch.returnId = "";
    this.advanceSearch.statusIds = [""];
    let req = {
      "pageNo": this.pageNo,
      "pageSize": this.pageSize
    }
    let form = {
      billingAccountId: "",
      entryDateFrom: "",
      entryDateFromSearchType: "Contains",
      entryDateTo: "",
      entryDateToSearchType: "Contains",
      billingAccountIdSearchType: "Contains",
      fromPartyId: "",
      returnHeaderTypeId: "",
      returnId: "",
      returnIdSearchType: "Contains",
      statusIds: [""],
    }


    this.accountsService.getOrderReturn(req, form).subscribe(res => {
      this.offerShowingLineData = res.data;
      this.spinner.hide();
    })
    
  }
  getReturnOrderValue() {
    this.spinner.show();
    let req = {
      "pageNo": this.pageNo,
      "pageSize": this.pageSize
    }
    let form = {
      billingAccountId: "",
      entryDateFrom: "",
      entryDateFromSearchType: "Contains",
      entryDateTo: "",
      entryDateToSearchType: "Contains",
      billingAccountIdSearchType: "Contains",
      fromPartyId: "",
      returnHeaderTypeId: "",
      returnId: "",
      returnIdSearchType: "Contains",
      statusIds: [""],
    }


    this.accountsService.getOrderReturn(req, form).subscribe(res => {
      this.offerShowingLineData = res.data;
      this.spinner.hide();
    })
    
  }
  onUpdate(value) {
    this.router.navigate(["/order/create-new-return-order"], { queryParams: { updateId: value } })
  }
  create() {
    this.router.navigate(["/order/create-new-return-order"])
  }
  detailPage(value) {
    this.router.navigate(["/order/return-item"], { queryParams: { returnId: value } })
  }
}