import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
@Component({
  selector: 'app-create-tax-category',
  templateUrl: './create-tax-category.component.html',
  styleUrls: ['./create-tax-category.component.css']
})
export class CreateTaxCategoryComponent implements OnInit {
  createCate:FormGroup;
  activeCategory=2;
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  categoryList: any;
  categoryListArray: any[]=[];
  party: any;
  geo: any;
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
  
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createCate = this._FormBuilder.group({
        categoryId: [''],
      
       
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getCategorySearch();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.geo = params["geo"];
   
    });
  }
  
  getCategorySearch() {
    this.spinner.show();
    const req={
   "categoryId": "",
   "categoryIdSearchType": "Contains",
   "categoryName": "",
   "categoryNameSearchType": "Contains"
    }
    this.accountsService.getCategorySearch(this.finSize,req).subscribe((res: any) => {
      this.categoryList = res.data;
      this.spinner.hide();
      for (const value of this.categoryList) {
        this.categoryListArray.push({
          label: value.productCategoryId,
          value: value.productCategoryId
        })
      }
    })
  
  }
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
      "productCategoryId": this.createCate.value.categoryId,
      "taxAuthGeoId":  this.geo,
      "taxAuthPartyId":  this.party
    }
    this.accountsService.postTaxCategory(requestData).subscribe((res: any) => {
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-tax-authority'],{ queryParams: { geo : this.geo,party : this.party}});
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-tax-authority'],{ queryParams: { geo : this.geo,party : this.party}});

  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
