import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { URLS } from 'src/app/app.constant';
import { Observable, BehaviorSubject } from 'rxjs';
import { ApiService } from 'src/app/api.service';

@Injectable({
    providedIn: 'root'
})
export class WarehouseService {
    constructor(
        readonly _HttpClient: HttpClient,
        readonly _ToastrService: ToastrService,
        readonly _ApiService: ApiService
    ) { }
    // create and update
    getFacility(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility`).toPromise();
    }
    getFacilityType(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/facilityTypeIds`).toPromise();
    }
    defaultWeight(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/defaultWeightUnit`).toPromise();
    }
    defaultInventory(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/defaultInventoryItemType`).toPromise();
    }
    areaUnit(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/areaUnit`).toPromise();
    }
    createFacility(formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    getFacilityById(facilityId: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/${facilityId}`).toPromise();
    }
    updateFacility(featureId: string, formData: any): Promise<any> {
        return this._HttpClient.put(`/phyCorp/control/api/v1/facility/${featureId}`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }

    // facility Contact Information

    getFacilityContactInformationList(facilityId: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/contactInformation?facilityId=${facilityId}`).toPromise();
    }
    getContactType(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/contactInformation/contactTypes`).toPromise();
    }
    getContactPurposeList(facilityId: string, preContactMechTypeId: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/contactInformation/contactPurpose?facilityId=${facilityId}&preContactMechTypeId=${preContactMechTypeId}`).toPromise();
    }
    createCommonElectronicAddress(formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/contactInformation/internetIpAddress`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    updateCommonElectronicAddress(contactMechId: string, contactMechTypeId: string, facilityId: string, infoString: string): Promise<any> {
        return this._HttpClient.put(`/phyCorp/control/api/v1/facility/contactInformation/internetIpAddress?contactMechId=${contactMechId}&contactMechTypeId=${contactMechTypeId}&facilityId=${facilityId}&infoString=${infoString}`, {}).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    createEmailAddress(formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/contactInformation/emailAddress`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    updateEmailAddress(formData: any): Promise<any> {
        return this._HttpClient.put(`/phyCorp/control/api/v1/facility/contactInformation/emailAddress`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    createPostalAddress(formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/contactInformation/postalAddress`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    updatePostalAddress(formData: any): Promise<any> {
        return this._HttpClient.put(`/phyCorp/control/api/v1/facility/contactInformation/postalAddress`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    createTelecomNumber(formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/contactInformation/telecomNumber`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    updateTelecomNumber(formData: any): Promise<any> {
        return this._HttpClient.put(`/phyCorp/control/api/v1/facility/contactInformation/telecomNumber`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    expireContactInformation(contactMechId: string, facilityId: string): Promise<any> {
        return this._HttpClient.delete(`/phyCorp/control/api/v1/facility/contactInformation?contactMechId=${contactMechId}&facilityId=${facilityId}`).toPromise();
    }
    getContactById(contactMechId: string, facilityId: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/contactInformation/${facilityId}?contactMechId=${contactMechId}`).toPromise();
    }
    // facility location
    createFacilityLocation(facilityId: string, formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/location/${facilityId}`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    updateFacilityLocation(facilityId: string, formData: any): Promise<any> {
        return this._HttpClient.put(`/phyCorp/control/api/v1/facility/location/${facilityId}`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    deleteFacilityLocation(facilityId: string, locationSeqId: string): Promise<any> {
        return this._HttpClient.delete(`/phyCorp/control/api/v1/facility/location/${facilityId}/${locationSeqId}`).toPromise();
    }
    getFacilityLocations(facilityId: string, pagination: { pageNo: number, pageSize: number }, searchFilter: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/location/search/${facilityId}?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    getLocationType(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/location/type`).toPromise();
    }
    getLocationById(facilityId: string, locationSeqId: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/location/${facilityId}/${locationSeqId}`).toPromise();
    }
    // Group Member
    createFacilityGroupMember(facilityId: string, facilityGroupId: string, formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/facilityGroupMember/${facilityId}/${facilityGroupId}`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    getFacilityGroupMembers(facilityId: string, pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/facilityGroupMember/facilityId/${facilityId}?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`).toPromise();
    }
    updateFacilityGroupMember(facilityId: string, facilityGroupId: string, formData: any): Promise<any> {
        return this._HttpClient.put(`/phyCorp/control/api/v1/facility/facilityGroupMember/${facilityId}/${facilityGroupId}`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    getFacilityGroupByGroupId(facilityGroupId: string, pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/facilityGroupMember/facilityGroupId/${facilityGroupId}?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`).toPromise();
    }
    deleteFacilityGroupMember(facilityId: string, facilityGroupId: string, fromDate: string): Promise<any> {
        return this._HttpClient.delete(`/phyCorp/control/api/v1/facility/facilityGroupMember/${facilityId}/${facilityGroupId}?fromDate=${fromDate}`).toPromise();
    }
    // Facility Group

    getFacilityGroups(pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/facilityGroup?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`).toPromise();
    }
    // facility inventory
    inventoryStatusId(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/inventoryItem/inventoryItemStatusIds`).toPromise();
    }
    getFacilityInventories(action: string, formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/inventory?action=${action}`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    getFacilityInventoryPDF(facilityId: string, action: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/inventory/generatePdf?action=${action}&facilityId=${facilityId}`).toPromise();
    }
    getFacilityInventoryItem(facilityId: string, action: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/inventory/inventoryItemTotals?action=${action}&facilityId=${facilityId}`).toPromise();
    }
    getFacilityGrandTotals(facilityId: string, action: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/inventory/inventoryItemGrandTotals?action=${action}&facilityId=${facilityId}`).toPromise();
    }
    getInventoryAverageCosts(facilityId: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/inventory/inventoryAverageCosts?facilityId=${facilityId}`).toPromise();
    }
    exportInventoryItem(facilityId: string, action: string): Observable<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/inventory/inventoryItemTotalsExport?action=${action}&facilityId=${facilityId}`, { responseType: 'blob' });
    }
    // Inventory Item

    createInventoryItem(formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/inventoryItem`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    getInventoryItemById(inventoryItemId: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/inventoryItem/${inventoryItemId}`).toPromise();
    }
    updateInventoryItem(inventoryId: string, formData: any): Promise<any> {
        return this._HttpClient.put(`/phyCorp/control/api/v1/facility/inventoryItem/${inventoryId}`, formData).toPromise();
    }
    getInventoryItems(pagination: { pageNo: number, pageSize: number }, searchFilter: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/facility/inventoryItem/search?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter);
    }
    getInventoryItemDetails(pagination: { pageNo: number, pageSize: number }, searchFilter: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/inventoryItem/inventoryItemsDetails?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, searchFilter).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    getInventoryReservation(inventoryItemId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/facility/inventoryItem/reservations/${inventoryItemId}`);
    }
    // Receive Inventory
    receiveInventory(facilityId: string, formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/receiveInventory/${facilityId}`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    getSuppliers(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/inventory/suppliers`).toPromise();
    }
    getOrderIds(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/inventoryItem/orderIds`).toPromise();
    }
    getShipmentIds(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/inventoryItem/shipmentIds`).toPromise();
    }
    getReturnIds(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/inventoryItem/returnIds`).toPromise();
    }
    // Physical Inventory
    getPhysicalVarianceInventory(productId: string, internalName: string, facility: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/physicalInventoryVariance?facilityId=${facility}&internalName=${internalName}&productId=${productId}`).toPromise();
    }
    // Physical Inventory
    getReturnView(facility: string, returnId: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/receiveReturn?facilityId=${facility}&returnId=${returnId}`).toPromise();
    }
    getPhysicalVarianceInventoryById(inventoryItemId: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/physicalInventoryVariance/{inventoryItemId}?inventoryItemId=${inventoryItemId}`).toPromise();
    }
    getPhysicalVarianceByInventoryItemId(inventoryItemId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/facility/physicalInventoryVariance/${inventoryItemId}`);
    }
    // Reasons Enum ID
    getReasonEnumId(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/inventoryItem/reasonEnumIds`).toPromise();
    }
    getVarianceReasonId(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/physicalInventoryVariance/varianceReasons`).toPromise();
    }
    createPhysicalVariance(inventoryItemId: string, formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/physicalInventoryVariance/${inventoryItemId}`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    createReceiveReturn(facilityId: string, returnid: string, formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/receiveReturn?facilityId=${facilityId}&returnId=${returnid}`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }

    // Inventory Transfer
    getTransferStatus(): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/resources/statusByType?statusTypes=INVENTORY_XFER_STTS`).toPromise();
    }
    createInventoryTransfer(facilityId: string, inventoryItemId: string, formData: string): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/facility/transferInventoryItem/${facilityId}/${inventoryItemId}`, formData);
    }
    getInventoryItemTransfers(facilityId: string, activeOnly: boolean): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/transferInventoryItem/transfers?activeOnly=${activeOnly}&facilityId=${facilityId}`).toPromise();
    }
    getCompleteInventoryItemTransfers(facilityId: string, activeOnly: boolean): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/transferInventoryItem/completeRequestedXfers?completeRequested=${activeOnly}&facilityId=${facilityId}`).toPromise();
    }
    updateInventoryTransfer(inventoryTransferId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/facility/transferInventoryItem?inventoryTransferId=${inventoryTransferId}`, formData);
    }
    createCompleteTransfer(formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/transferInventoryItem/completeRequestedXfers`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    getInventoryTransferById(inventoryTransferId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/facility/transferInventoryItem/transfers/{inventoryTransferId}?inventoryTransferId=${inventoryTransferId}`);
    }
    // Quick Stock Move

    createQuickStockMove(formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/stockMoves/processQuickStockMove`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    getStockMoveNeeded(facilityId: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/stockMoves/stockMovesNeeded?facilityId=${facilityId}`).toPromise();
    }
    completeQuickStockMove(formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/stockMoves/completeSelectedMoves`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }

    // verify pick



    verifyOrder(facilityId: string, orderId: string, shipGroupId: string): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/verifyPick/verifyOrder?facilityId=${facilityId}&orderId=${orderId}&shipGroupSeqId=${shipGroupId}`, {}).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    verifyOrderPick(facilityId: string, orderId: string, shipGroupId: string, picklistBinId: string): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/verifyPick/verifyOrder?facilityId=${facilityId}&orderId=${orderId}&shipGroupSeqId=${shipGroupId}&picklistBinId=${picklistBinId}`, {}).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    verifySingleItem(formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/verifyPick/verifySingleItem`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    verifyBulkItem(formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/verifyPick/verifyBulkItem`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    cancelAllRows(facilityId: string, orderId: string, shipGroupId: string): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/verifyPick/cancelRows?facilityId=${facilityId}&orderId=${orderId}&shipGroupSeqId=${shipGroupId}`, {}).toPromise();
    }

    // Packing

    packOrder(facilityId: string, orderId: string, shipGroupSeqId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/facility/packingShipment/packorder?facilityId=${facilityId}&orderId=${orderId}&shipGroupSeqId=${shipGroupSeqId}`);
    }
    clearAllPack(facilityId: string, orderId: string, shipGroupSeqId: string): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/facility/packingShipment/clearPackAll?facilityId=${facilityId}&orderId=${orderId}&shipGroupSeqId=${shipGroupSeqId}`, {});
    }
    clearIndividualPack(facilityId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/facility/packingShipment/clearPackLine?facilityId=${facilityId}`, formData);
    }
    processBulkPack(facilityId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/facility/packingShipment/processBulkPackOrder?facilityId=${facilityId}`, formData);
    }
    completePack(facilityId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/facility/packingShipment/completePack?facilityId=${facilityId}`, formData);
    }
    getStockMove(facilityId: string): Promise<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/stockMoves/stockMovesNeeded?facilityId=${facilityId}`).toPromise();
    }
    createPackItem(facilityId: string, formData: any): Promise<any> {
        return this._HttpClient.post(`/phyCorp/control/api/v1/facility/packingShipment/processPackOrder?facilityId=${facilityId}`, formData).toPromise().then(res => res).catch(err => this.handleError(err));
    }
    handleError(err: HttpErrorResponse | any): void {
       
        if (err.status === 400) {
            err.error.errors.forEach(value => {
                this._ToastrService.error(`${value.fieldName} ${value.fieldError}`);
            });
        }
    }
    // Facility Location Product

    createFacilityProduct(facilityId: string, locationSeqId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/facility/productFacilityLocation/${facilityId}/${locationSeqId}`, formData);
    }
    updateFacilityProduct(facilityId: string, locationSeqId: string, formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/facility/productFacilityLocation/${facilityId}/${locationSeqId}`, formData);
    }
    getFacilityProducts(facilityId: string, locationSeqId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/facility/productFacilityLocation/${facilityId}/${locationSeqId}`);
    }
    deleteFacilityProduct(facilityId: string, locationSeqId: string, productId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/facility/productFacilityLocation/${facilityId}/${locationSeqId}/${productId}`);
    }

    // Picking 
    gteOrdersForPicking(facilityId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/facility/picking/${facilityId}`, formData);
    }
    createPickList(facilityId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/facility/picking/createPicklist/${facilityId}`, formData);
    }
    printPicking(facilityId: string,orderid:string, maxNumberOfOrder: number): Observable<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/picking/printPicking?facilityId=${facilityId}&orderId=${orderid}&maxNumberOfOrdersToPrint=${maxNumberOfOrder}`, { responseType: 'blob' });
    }
    reviewOrderNotPicking(facilityId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/facility/picking/reviewOrdersNotPicked/${facilityId}`);
    }
    // ManagePicking 

    assignPicker(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/facility/pickList/assignPicker`);
    }
    getActivePickList(facilityId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/facility/pickList/activePickList?facilityId=${facilityId}`);
    }
    managePickList(facilityId: string, pagination: { pageNo: number, pageSize: number }): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/facility/pickList?facilityId=${facilityId}&pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`);
    }
    createAssignPicker(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/facility/pickList/assignPicker`, formData);
    }
    updatePickList(formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/facility/pickList/picklistItem`, formData);
    }
    deletePickList(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/facility/pickList/picklistItem`, formData);
    }
    updatePickListStatus(formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/facility/pickList/updatePicklist`, formData);
    }

    updatePickListBin(formData: any): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/facility/pickList/updatePicklistBin`, formData);
    }
    pickListReport(pickListId: string): Observable<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/pickList/picklistReport?picklistId=${pickListId}`, { responseType: 'blob' });
    }
}
