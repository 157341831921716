import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CreatePaymentServices } from './create-payment.service'
import {CreatePaymentFormService} from "./create-payment-service";
import { Router,ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
@Component({
  selector: 'app-create-payments',
  templateUrl: './create-payments.component.html',
  styleUrls: ['./create-payments.component.css']
})
export class CreatePaymentsComponent implements OnInit {
  
  organizationPartyId='Company';
  toCompanyId='Company'
  fromPartyId='DemoCustomer'
  statusId='Not Paid'
  fromPartyIdByApi='';
  finAccountTransIdByApi='';
  toPartyIdByApi='';

  allPaymentTypeList:any=[];
  allFinAccountIdList:any=[];
  allpaymentMethodId:any=[];
  allPartyIdList:any=[];
  currencyArray:any=[];

  public paymentId:any;
  showUpdateBtn=false;
  showCreateBtn=false;
  

  constructor( 
    public createPaymentFormService :CreatePaymentFormService,
    readonly createPaymentService:CreatePaymentServices,
    readonly toastr:ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly activatedRoute: ActivatedRoute,
    readonly router:Router
  ) { }


  public OrganizationPartyIdArray:any[]=[
    {value:"company" ,label:"Your Company Name Here"}
  ]
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.paymentId = params["paymentId"];
      if(this.paymentId) {
        this.showUpdateBtn=true;
      }else{
        this.showCreateBtn=true;
      }
    });

    this.getPaymentType();
    this.getFromPartyId();
    this.getPaymentAccountId();
    this.getCurrency();
    this.getPaymentDetailsByIdForUpdate();


    this.createPaymentFormService.create_payment_form=null;
    this.createPaymentFormService.createEventForm(this.createPaymentFormService.create_payment_form);
  }

  getPaymentType() {
    this.spinner.show();
    this.createPaymentService.getPaymentType().subscribe(res => {
      if (res.success) {
        const allPaymentType = res.data.paymentType;
        const finAccountIdList  = res.data.finAccountId
        for (const value of allPaymentType) {
          this.allPaymentTypeList.push({
            label: value.description,
            value: value.paymentTypeId
          })
        }

        for (const value of finAccountIdList) {
          this.allFinAccountIdList.push({
            label: value.finAccountName,
            value: value.finAccountId
          })
        } 

      }
    
    }, (err) => {
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + " " + value.fieldError);
    }
    }
    )
    this.spinner.hide();
  }

  getFromPartyId() {
    this.spinner.show();
  this.createPaymentService.getFromPartyId().subscribe(res => {
    if (res.success) {
      const allPartyId = res.data.PersonsGroups;
      for (const value of allPartyId) {
        this.allPartyIdList.push({
          label: value.partyId,
          value: value.partyId
          })
        }
      }
    }, (err) => {
    for (const value of err.error.errors) {
      this.toastr.error(value.fieldName + " " + value.fieldError);
    }
    }
    )
    this.spinner.hide();
    }

    getPaymentAccountId(){
      this.spinner.show();
      this.createPaymentService.getPaymentAccoundId(this.organizationPartyId).subscribe(res => {
        if (res.success) {
          const paymentMethodId = res.data.paymentMethodId;
          for (const value of paymentMethodId) {
            this.allpaymentMethodId.push({
              label: value.paymentMethodId,
              value: value.paymentMethodId
              })
            }
          }
      }
    )
    this.spinner.hide();
    }

    getPaymentDetailsById() {
      this.spinner.show();
      this.createPaymentService.getFromPartyId().subscribe(res => {
        if (res.success) {
          const allPartyId = res.data.PersonsGroups;
          for (const value of allPartyId) {
            this.allPartyIdList.push({
              label: value.partyId,
              value: value.partyId
              })
            }
          }
        }, (err) => {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + " " + value.fieldError);
        }
      }
    )
    this.spinner.hide();
  }


    onSubmit(){
      this.spinner.show();
    const partyIdTo=this.organizationPartyId
    const formValues = { ...this.createPaymentFormService.create_payment_form.value,partyIdTo };

    this.createPaymentService.createPayment(formValues).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
      }
    },(err) => {
      for (const value of err.errors) {
      this.toastr.error(value.fieldName + " " + value.fieldError);
    }
    })
    this.spinner.hide();
    } 

    getPaymentDetailsByIdForUpdate(){
      this.spinner.show();
      this.createPaymentService.getPaymentDetailsById(this.paymentId).subscribe(res => {
        if (res.success) {
      
          this.fromPartyIdByApi=res.data[0].partyIdFrom;
          this.finAccountTransIdByApi=res.data[0].financialAccountTransaction;
          this.toPartyIdByApi=res.data[0].partyIdTo;
         
        }
      }
    )
    this.spinner.hide();
    }


    getCurrency(){
      this.spinner.show();
      this.createPaymentService.getCurrency().subscribe(res=> {
        const currency = res.data.currencyList;
        for(const value of currency){
          this.currencyArray.push({
            label:value.description,
            value:value.uomId
          })
        }
      })
      this.spinner.hide();
    }

 

}
